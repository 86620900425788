import React from "react";
import { MdChat } from "react-icons/md";
import { Card, CardBody, Row, TabPane } from "reactstrap";
import Moment from 'moment';
import { MessageBox } from 'react-chat-elements';

const toLimit = (string = "") => {
    return string.substring(0, 7)+'...';
}

export const ChatsRecentes = (props) => {
    return (
        <TabPane tabId={props.active}>
            <br />
            <Card style={{filter: 'drop-shadow(#b8b8b8 1px 1px 2px)', borderRadius: '1.25rem'}}>
                <CardBody style={{paddingLeft: '39px'}}>
                    <h5>&nbsp;&nbsp;<MdChat style={{width: '30px', height: '30px'}}/> Ultimos Chats</h5>
                    <Row style={{maxHeight: '60vh', overflow: 'auto'}}>
                    <div style={{ marginTop: '15px', width: '95%'}}>
                        {props.chat !== null ? 
                            <>
                                {
                                    props.chat.usuario ?
                                        props.chat.usuario.chats_recentes.slice(0,3).map(p =>{ return (
                                            <>
                                                <Card style={{backgroundColor: '#575757', 
                                                                borderRadius: '1.25rem', 
                                                                border:'1px solid #575757', 
                                                                boxShadow: '#575757 1px 1px 5px'}}>
                                                    <CardBody > 
                                                        <h6 style={{color: '#fff'}}>
                                                            <MdChat style={{width: '25px', height: '25px'}}/> 
                                                            {Moment(new Date(p.created_at)).format('DD/MM/YYYY HH:mm')}
                                                        </h6>
                                                    </CardBody>
                                                        <div style={{ maxHeight: '200px', marginLeft: '5px', marginRight: '5px', overflow: 'auto'}}>
                                                            {p.messages.map((p) => {
                                                                if(p.type_message === 1 || p.type_message === 0){
                                                                    return <MessageBox
                                                                        copiableDate={false}
                                                                        dateString={Moment(new Date(p.created_at)).format('HH:m')}
                                                                        retracted={true}
                                                                        titleColor={p.type_message === 0 ? "black" : '#52c565'}
                                                                        avatar={p.type_message === 0 ? (p.admin !== null ? p.admin.avatar_foto: 'https://files.zohopublic.com/public?x-service=LiveDesk&event-id=1606357595010_604373000000002052&x-cli-msg=%7B%22x-siq-filetype%22%3A%22fgravatar%22%2C%22x-siq-lsid%22%3A%22604373000000002052%22%2C%22x-siq-soid%22%3A%22730799669%22%2C%22x-siq-ispreview%22%3Afalse%2C%22x-siq-pfname%22%3A%22logo-chat.png%22%2C%22x-siq-downloadtype%22%3A%22default%22%7D') : ''}
                                                                        position={p.type_message === 0 ? 'right' : 'left'}
                                                                        type={p.anexo !== null ? 'file' : 'text'}
                                                                        title={p.type_message === 0 ? (p.admin !== null ? p.admin.name : 'Atendimento') :  (p.usuario !== null ? p.usuario.nome : 'Sem informações')}
                                                                        text={p.anexo == null ? p.message : toLimit(p.message)}
                                                                    />

                                                                }
                                                                else if(p.type_message === 2){
                                                                    return (
                                                                        <center style={{color: 'white'}}>
                                                                            <small>{p.message}</small>
                                                                            <hr style={{margin: 5, borderColor: 'white'}}/>
                                                                        </center>
                                                                    )
                                                                }
                                                                return (<></>);
                                                            })}

                                                        </div>
                                                        <br/>
                                                </Card>
                                                <br/>
                                            </>
                                        )})
                                    :
                                    ''
                                }

                            </> : <></>
                        }
                    </div>
                    </Row>
                </CardBody>
            </Card>
        </TabPane>
    )
}

//TabContent