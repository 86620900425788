import { Card, CardBody, Button } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import { TablePayments } from "../../../components/Tables/TableAqMed/TablePayments";
import { useEffect, useState } from "react";
import moment from "moment";
import Cookies from "js-cookie";
import { useApiAqmed } from "../../../hooks/useApi";
import { searchWithMask, validateInputAqmed } from "../utils";
import { castToMoney, booleanToYesOrNo } from "../../../utils";
import { getStatusAqmed } from "../utils";
export function Payments() {
  const [search, setSearch] = useState(null);
  const [links, setLinks] = useState({
    currentPage: 1,
    lastPage: 1,
  });
  const [pending, setPending] = useState(false);
  const [filter, setFilter] = useState({
    document: null,
    page: null,
    dateIn: null,
    dateEnd: null,
    pending: null,
  });

  const [checkedControl, setCheckedControl] = useState({
    date: false,
    pending: false,
  });

  const checkedFilters = (event) => {
    console.log(event);
  };
  const [dateFilterInput, setDateFilterInput] = useState({
    dateIn: moment(new Date().toDateString()).format("YYYY-MM-DD"),
    dateOut: moment(new Date().toDateString()).format("YYYY-MM-DD"),
  });

  const form = {
    check: checkedControl,
    setCheckedControl,
    dateFilter: dateFilterInput,
    setDateFilterInput,
  };
console.log(form)
  const [isFetching, setIsFecthing] = useState(true);

  const [data, setData] = useState([]);

  const { apiAqmed } = useApiAqmed();
  const onSearchDocument = () => {
    const verify = validateInputAqmed(search.document);
    const documentWithoutMask = search.document.replace(/[.\-/]/g, "");

    if (verify?.valid) {
      setSearch({
        document: search.document,
        ...verify,
      });
      setIsFecthing(true);
      setData([]);
      setFilter({ page: null, document: documentWithoutMask });
    } else {
      setSearch({
        document: search.document,
        ...verify,
      });
    }
  };

  async function sendEmail(name, type, id) {
    const confirm = window.confirm(
      `Tem certeza que desaja enviar um${
        type === "remember" ? " lembrete" : "a cobrança"
      } para ${name}`
    );
    if (confirm) {
      try {
        if (type === "remember") {
          await apiAqmed.post("/crm/send/mail/waiting/payment", {
            public_id: id,
          });
        }
        if (type === "invoice") {
          await apiAqmed.post("/crm/send/mail/charging/invoice", {
            public_id: id,
          });
        }
        alert("Email enviado com sucesso!");
      } catch (e) {
        console.log(e);
        alert("Erro ao enviar e-mail");
      }
    }
  }
  const searchOnChange = (event) => {
    searchWithMask(event, setSearch);
  };
  const onPending = () => {
    console.log("ola");
  };
  useEffect(() => {
    const token = Cookies.get("aqmedtoken");
    let url = "/user/payments";
    if (filter.page) url += `?page=${filter.page}`;
    if (filter.document) url += `?document=${filter.document}`;
    apiAqmed
      .get(url, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setData(res?.data?.data?.payments);
        setLinks(res?.data?.data?.page);
      })
      .catch((error) => {
        alert("Erro ao Bucar Dados");
        console.log(error);
      })
      .finally(() => {
        setIsFecthing(false);
        setSearch(null);
      });
  }, [filter]);

  const onChangePage = (event) => {
    setIsFecthing(true);
    setData([]);
    setFilter({ page: Number(event), document: null });
  };

  const ExpandableRows = ({ data }) => {
    console.log("data = ", data);
    return (
      <div style={{ margin: "20px" }}>
        <h4>{data?.name}</h4>
        <hr />
        <p>{moment(data?.date).format("DD/MM/YYYY")}</p>
        <p>
          <b>Valor:</b>{" "}
          {parseFloat(data?.plan_total_value).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}
        </p>
        <p>
          <b>Contrato: </b>
          {data?.contract_number}
        </p>

        <p>
          <b>Nome do Plano: </b>
          {data?.plan_name}
        </p>

        <p>
          <b>Valor Total do Plano: </b>
          {castToMoney(data?.plan_total_value)}
        </p>
        <p>
          <b>Email:</b> {data?.email}
        </p>
        <p>
          <b>Documento (CPF/CNPJ):</b> {data?.document}
        </p>
        <p>
          <b>Possui TotalPass:</b> {booleanToYesOrNo(data?.has_plan_totalpass)}
        </p>
        <p>
          <b>Possui Uniodonto:</b> {booleanToYesOrNo(data?.has_plan_uniodonto)}
        </p>
        <p>
          <b>Valor dos dependentes:</b> {castToMoney(data?.dependent_value)}
        </p>
        <p>
          <b>Pago:</b> {data?.payment_id ? "Sim" : "Não"}
        </p>
        <p>
          <b>Dependentes:</b> {data?.dependents ?? "Não informado"}
        </p>
        <p>
          <b>Valor Dependentes:</b> {data?.dependents_value ?? "Não informado"}
        </p>
        <p>
          <b>Médoto de pagamento:</b> {data?.payment_method}
        </p>
        <p>
          <b>Status do Pagamento:</b> {getStatusAqmed(data?.payment_status)}
        </p>
        <p>
          <b>Fechamento da Fatura:</b>{" "}
          {moment(data?.invoice_closing).format("DD/MM/YYYY")}
        </p>
        <p>
          <b>Vencimento da Fatura:</b>{" "}
          {moment(data?.invoice_due_date).format("DD/MM/YYYY")}
        </p>

        <p>Envio por E-mail</p>

        <br />
        <br />
        <div style={{ display: "inline" }}>
          <Button
            color="success"
            onClick={() => sendEmail(data?.name, "remember", data?.public_id)}
            style={{ margin: "2px" }}
          >
            Enviar Lembrete
          </Button>
          <Button
            color="info"
            onClick={() => sendEmail(data?.name, "invoice", data?.public_id)}
            style={{ margin: "2px" }}
          >
            Enviar Fatura
          </Button>
        </div>
      </div>
    );
  };
  return (
    <div>
      <h3>Pagamentos</h3>
      <hr />
      <Card>
        <TablePayments
          form={form}
          checkedFilters={checkedFilters}
          selectableRows
          search={search}
          searchOnChange={searchOnChange}
          pending={pending}
          onPending={onPending}
          onSearchDocument={onSearchDocument}
          data={data}
          onPageChange={onChangePage}
          filter={filter}
          expandableRowsComponent={<ExpandableRows />}
          activePage={links?.currentPage}
          totalPage={links?.lastPage}
          date_from={data?.date}
          date_to={dateFilterInput?.dateOut}
          noDataComponent={
            isFetching ? (
              <Card style={{ border: 0, padding: "10vh" }}>
                <CardBody>
                  <div>
                    <div className="sweet-loading">
                      <PropagateLoader
                        color={"#52c565"}
                        loading={20}
                        size={30}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            ) : (
              <Card style={{ border: 0 }}>
                <CardBody>
                  <div>Nenhuma fatura encontrada</div>
                </CardBody>
              </Card>
            )
          }
        />
      </Card>
    </div>
  );
}
