import React from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, Row, Col, Collapse, Input, CardFooter, Button } from 'reactstrap';
import { BiMerge } from 'react-icons/bi';
import { columns } from './propsTicket';
import debounce from 'lodash/debounce';
import Pagination from '../Pagination';
import { InputText } from '../../InputText/InputText';
import { list_status } from '../../../auth/listas';
import Select from 'react-select';
import { MdClear, MdDelete } from 'react-icons/md';
import { useState } from 'react';
import { useEffect } from 'react';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import axios from 'axios';

const getOperadores = (callback) => {
    var url = ENDPOINT + "chat-atendimento/consultar-operadores";

    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        var operadores_list = [];
        data.forEach((p, i) => {
            p['value'] = p.id;
            p['label'] = p.name;
            operadores_list.push(p);
        });

        callback(operadores_list)
    });
}
  
export const TableTicket = (props) => {
    const [operadores_list, setOperadoresList] = useState([]);
    useEffect(() => {
        getOperadores(setOperadoresList);
    }, [])
    return (
        <Card>
            <Collapse isOpen={true}>
                <Card style={{ border: 'none' }}>
                    <CardBody>
                        <Row hidden={props.clientTicket}>
                            <Col md={4}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputText
                                        name="search"
                                        placeholder="Buscar"
                                        onChange={debounce((event) => props.isSearch(event), 1000)} />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputText
                                        name="document"
                                        placeholder="Documento (CPF / CNPJ)"
                                        onChange={debounce((event) => props.isSearch(event), 1000)} />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputText
                                        name="nome"
                                        placeholder="Nome"
                                        onChange={debounce((event) => props.isSearch(event), 1000)} />
                                </div>
                            </Col>
                        </Row>
                        {!props.clientTicket ? <br /> : <></>}
                        <Row hidden={props.clientTicket}>
                            <Col md={6}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputText
                                        name="protocolo_atendimento"
                                        placeholder="Protocolo de Atendimento"
                                        onChange={debounce((event) => props.isSearch(event), 1000)} />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputText
                                        name="email"
                                        placeholder="E-mail"
                                        onChange={debounce((event) => props.isSearch(event), 1000)} />
                                </div>
                            </Col>
                        </Row>
                        {!props.clientTicket ? <br /> : <></>}
                        <Row hidden={props.clientTicket}>
                            <Col md={4}>
                                <Select 
                                    options={list_status}
                                    value={props.status}
                                    isClearable
                                    onChange={props.handleSelectChange}
                                    placeholder="Status"/>
                            </Col>
                            <Col md={4}>
                                <Select
                                    className="react-select"
                                    options={operadores_list}
                                    placeholder="Operador"
                                    isSearchable={false}
                                    isClearable
                                    value={props.operador}
                                    onChange={props.handleOperadorChange}
                                />
                            </Col>
                            <Col md={4}>
                                <Select 
                                    options={props.list_departamentos}
                                    value={props.departamento}
                                    isClearable
                                    onChange={props.handleDepartamentoChange}
                                    placeholder="Departamento"/>
                            </Col>
                        </Row>
                        <Row hidden={!props.clientTicket}>
                            <Col md={6}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputText
                                        name="search"
                                        placeholder="Buscar"
                                        onChange={debounce((event) => props.isSearch(event), 1000)} />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputText
                                        name="protocolo_atendimento"
                                        placeholder="Protocolo de Atendimento"
                                        onChange={debounce((event) => props.isSearch(event), 1000)} />
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>De:
                            <Input
                                    name="date_from"
                                    type="date"
                                    //disabled
                                    disabled={!(props.filter === true)}
                                    value={props.date_from}
                                    onChange={(event) => props.isSearch(event)} /></Col>
                            <Col>Até:
                            <Input
                                    name="date_to"
                                    type="date"
                                    disabled={!(props.filter === true)}
                                    //disabled
                                    value={props.date_to}
                                    onChange={(event) => props.isSearch(event)} /></Col>
                        </Row>
                        <Row>
                            <Col md={5}>
                                <input
                                    type="checkbox"
                                    checked={props.filter}
                                    name="filter"
                                    onChange={(value) => props.onFilter(value.target.checked)} /> Filtrar por data de envio

                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Collapse>
            {props.selectableRows ? 
                <div style={{display: 'flex'}}>
                    <Button 
                        disabled={props.ticket_current.selectedRows.length < 2} 
                        color="success" 
                        onClick={() => props.mesclarTicket()}
                        style={{ 
                            marginLeft: '24px', 
                            marginTop: '5px', 
                            width: '150px', 
                            float: 'right' 
                    }}><BiMerge /> Mesclar</Button>
                    <Button 
                        disabled={props.ticket_current.selectedRows.length < 1} 
                        color="danger" 
                        onClick={() => props.apagarTicket()}
                        style={{ 
                            marginLeft: '24px', 
                            marginTop: '5px', 
                            width: '150px', 
                            float: 'right' 
                    }}><MdDelete /> Excluir</Button>
                    <Button 
                        disabled={props.ticket_current.selectedRows.length < 1} 
                        color="danger" 
                        onClick={() => props.fecharTickets()}
                        style={{ 
                            marginLeft: '24px', 
                            marginTop: '5px', 
                            width: '150px', 
                            float: 'right' 
                    }}><MdClear /> Fechar Ticket</Button>
                </div>
            :
            <></>}
            <hr style={{ marginBottom: '4px' }} />
            <DataTable
                noHeader={true}
                button={true}
                selectedData={props.selectedData}
                columns={columns(props.callbackMesclagem)}
                noDataComponent={props.noDataComponent}
                data={props.data}
                selectableRows={props.selectableRows}
                onSelectedRowsChange={(e) => props.onSelectedRows(e)}
                theme="default"
            />
            <CardFooter style={{ textAlign: 'center' }}>
                <Pagination
                    onPageChange={props.onPageChange}
                    activePage={props.activePage}
                    totalPage={props.totalPage}
                />
            </CardFooter>
        </Card>
    )
}