import React, { Component } from 'react';
import { Card, CardBody } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Moment from 'moment';
import { getRows, getRowsPerPage } from '../../components/Tables/TableMovimentacao/propsMovimentacaoFatura';
import { getPermissoes } from '../../auth/set_services';
import { TableMovimentacaoFatura } from '../../components/Tables/TableMovimentacao/TableMovimentacaoFatura';
import NewNotas from '../../components/Modals/SendsModals/NewNotas';

const getId = () => {
    if (window.location.pathname === '/movimentacao') {
        return null;
    }
    else {
        return window.location.pathname.replace('/movimentacao/', '')
    }
}
class MovimentacaoFatura extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading_table: true,
            rowData: [],
            activePage: 1,
            taggy_serial: getId(),
            totalPage: 1,
            filter: false,
            is_cnpj: false,
            document: '',
            search: '',
            id_current: '',
            visible_notas: false,
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }

    componentDidMount() {
        getRows(this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    onFilter = (filter) => {
        this.setState({ filter, loading_table: true });
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onExtorno = (visible_notas = false, id_current = '') => {
        this.setState({visible_notas , id_current});
        getRows(this.state, this.callbackRows.bind(this));
    }
    render() {
        return (
            <div>
                <h3>Movimentação de Faturas</h3>
                <hr />
                <Card>
                    <TableMovimentacaoFatura
                        isSearch={this.handleChange}
                        data={this.state.rowData}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        is_cnpj={this.state.is_cnpj}
                        filter={this.state.filter}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhuma movimentação encontrada
                            </div></CardBody></Card>
                        }
                        onExtorno={this.onExtorno}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <NewNotas
                    visible={this.state.visible_notas}
                    id={this.state.id_current}
                    onClose={() => { this.onExtorno(false, '') }}
                    desc_id="fatura_aqpasso_id"
                    title_nota="Extorno de debito na fatura"
                    placeholder_nota="Digite o motivo do extorno no debito da fatura"
                    url_api="faturas_aqpasso/extorno"
                />
            </div>
        )
    }
}

export default MovimentacaoFatura;