import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';

export const columns = () => [
    {
      name: "Local",
      selector: "name",
      sortable: false,
      width: '20%'
    },
    {
      name: "Endereço",
      selector: "vicinity",
      sortable: false,
      width: '35%'
    },
    {
      name: "Localização Geométrica",
      selector: "deposito",
      sortable: false,
      width: '25%',
      cell: row => row.geometry !== null ? row.geometry.location.lat+" | "+row.geometry.location.lng : 'Sem Informações'
    },
    {
      name: "",
      selector: "icon",
      sortable: false,
      width: '15%',
      cell: row => row.icon !== null ? <img src={row.icon} alt="icon" height={100} /> : 'Sem Informações'
    }
];

export const getRows = (state, callbackRows) => {
    //var url = ENDPOINT + "estacionamento/list/"+state.filter;
    var url = "https://apiaqpasso.aqbank.com.br/api/estabelecimentos-taggy-public/"+(state.filter == 1 ? 'estacionamento' : 'pedagio');

    if(state.search.length > 0){
        url += '?search='+state.search;
        if(state.state !== null){
          url += '&state='+state.state.value;
          if(state.city !== null){
              url += '&city='+state.city.value;
          }
        }
    }
    else if(state.state !== null){
      url += '?state='+state.state.value;
      if(state.city !== null){
          url += '&city='+state.city.value;
      }
    }


    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data)
    });
}

export const getRowsPerType = (type, state, callbackRows) => {
    //var url = ENDPOINT + "estacionamento/list/"+type;
    var url = "https://apiaqpasso.aqbank.com.br/api/estabelecimentos-taggy-public/"+(type == 1 ? 'estacionamento' : 'pedagio');
    if(state.state !== null){
      url += '?state='+state.state.value;
      if(state.city !== null){
          url += '&city='+state.city.value;
      }
    }
    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data)
    });
}

export const getCity = (state, callbackRows) => {
  //var url = ENDPOINT + "location/cities_greenpass/list/"+state.value;
    var url = "https://apiaqpasso.aqbank.com.br/api/location/cities_greenpass/list/"+state.value
  

  axios.get(url, {
    headers: {
        Authorization: 'Bearer '+getToken()
    }}).then((response) => {
      callbackRows(response.data.body)
  });
}

export const getStates = (callbackRows) => {
  //var url = ENDPOINT + "location/states_greenpass/list";
  var url = "https://apiaqpasso.aqbank.com.br/api/location/states_greenpass/list";
  

  axios.get(url, {
    headers: {
        Authorization: 'Bearer '+getToken()
    }}).then((response) => {
      callbackRows(response.data.body)
  });
}
