import { Component } from "react";
import { ToastContainer } from "react-toastify";

class WidgetLayout extends Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }
    render(){
        const { children } = this.props;
        return(
            <div>
                {children}
                <ToastContainer hideProgressBar/>
            </div>
        )
    }
}
export default WidgetLayout;