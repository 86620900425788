import { Component } from "react";
import { MdSearch } from "react-icons/md";
import { Button, Card, CardBody, Nav, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import { InputText } from "../../components/InputText/InputText";
import styled from 'styled-components';
import NumberFormat from "react-number-format";
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from "react-toastify";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import axios from "axios";
import DataTable from "react-data-table-component";
import { list_status_pagamentos } from "../../auth/listas";
const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
`
const columns = () => [
    {
        name: "Tipo de cartão",
        selector: "tipo_cartao",
        sortable: false,
        width: '12%',
        cell: p => p.tipo_cartao.toUpperCase()
    },
    {
        name: "Nome no cartão",
        selector: "nome_proprietario",
        sortable: false,
        width: '15%'
    },
    {
        name: "Número do cartão",
        selector: "numero",
        sortable: false,
        width: '150px'
    },
    {
        name: "Validade",
        selector: "tipo_cartao",
        sortable: false,
        width: '12%',
        cell: p => p.validade_mes + " / "+p.validade_ano
    },
    {
        name: "Apelido do cartão",
        selector: "apelido_cartao",
        sortable: false,
        width: '12%'
    },
    {
        name: "Bandeira",
        selector: "bandeira",
        sortable: false,
        width: '12%',
        cell: p => p.bandeira !== null ? p.bandeira.toUpperCase() : 'Sem informações'
    },
    {
        name: "Dono do Cartao",
        sortable: false,
        width: '250px',
        cell: p => <ul style={{listStyleType: 'circle', marginTop: '15px'}}>
            <li style={{marginBottom: '7px'}}>{p.user.nome} {p.user.sobrenome}</li>
            <li style={{marginBottom: '7px'}}>{p.user.email}</li>
            <li style={{marginBottom: '7px'}}><NumberFormat displayType={'text'} value={p.user.cpf} format={p.user.cpf.length > 11 ? '##.###.###/####-##' : "###.###.###-##"}/></li>
        </ul>
    },
];

const getStatus = (value, is_status) => {
    if(value === ""){
        var status_is_not_antifraude = '';
        list_status_pagamentos.map((p) => {
            if(p.value === is_status){
                status_is_not_antifraude = p.label
            }
            return null;
        })
        return status_is_not_antifraude;
    }
    else{
        var status_is = '';
        const status = [
            { value: 'NVO', label: "Análise Pendente"},
            { value: 'AMA', label: "Análise Iniciada"},
            { value: 'APA', label: 'Aprovado Automaticamente'},  
            { value: 'APM', label: "Aprovação Manual"},
            { value: 'APG', label: "Aprovação Gerencial"},
            { value: 'APE', label: "Aprovação do Estabelecimento"},
            { value: 'SUS', label: "Suspeita de Fraude"},
            { value: 'FRD', label: "Fraude Confirmada"},
            { value: 'RMA', label: 'Reprovado Manualmente'},
            { value: 'RPM', label: 'Reprovado sem contato'},
        ];

        status.forEach(p => {
            if(p.value === value){
                status_is = p.label;
            }
        });

        return status_is;

    }
}
class BuyerTransacao extends Component{
    constructor(props){
        super(props);
        this.state = {
            transacao_id: '',
            nome_completo_current: '',
            cpf_current: '',
            invalid_transacao_id: false,
            email_current: '',
            telefone_current: '',
            endereco_current: {},
            status_current: '',

            transacao_id_current: '',
            servico_current: '',
            status_transacao_current: '',
            valor_transacao_current: '0',

            nome_receita_current: '',
            nascimento_receita_current: '',
            cpf_receita_current: '',
            situacao_receita_current: '',

            loading_button: false,
            loading_buyer: true,
            loading_cartao: true,
            cartoes: [],

            activeTab: '1'
        }
    }

    listData = [
        { name: <>Dados Pessoais</>, active: '1' },
        { name: <>Receita Federal </>, active: '2' },
        { name: <>Cartões de crédito </>, active: '3' }
    ];
    valid_fields = () => {
        var invalids = 0;
        if(this.state.transacao_id.length > 0){
            this.setState({invalid_transacao_id: false})
        }
        else{
            invalids++;
            this.setState({invalid_transacao_id: true})
        }

        

        return invalids;
    }
    Navs = () => {
        return <Nav tabs>
            {this.listData.map((p, index) => {
                return <NavLink
                    key={index}
                    className={
                        classnames({ active: this.state.activeTab === p.active })}
                    onClick={() => { this.setState({ activeTab: p.active }); }}>
                    <span>{p.name}</span>
                </NavLink>
            })}
        </Nav>
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({ [name]: value });
    }
    getUserbyReceita = (cpf) => {
        var url = ENDPOINT+"user/search_cpf?cpf="+cpf;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data.data;
            this.setState({
                nome_receita_current: data.nome,
                nascimento_receita_current: data.nascimento,
                cpf_receita_current: data.ni,
                situacao_receita_current: data.situacao.descricao
            })

            return data;
        })
        .catch((error) => {
            var data = error.response.data;
            this.setState({
                nome_receita_current: '',
                nascimento_receita_current: '',
                cpf_receita_current: '',
                situacao_receita_current: data.errors,
            })
        })

    }
    getCartoes = (cpf_comprador) => {
        var url = ENDPOINT+"prevencao/consultar_cartoes/"+cpf_comprador;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({
                loading_cartao: false,
                cartoes: data
            })
        })
        .catch(() => {
            this.setState({
                loading_cartao: false,
                cartoes: []
            })
        })
    }
    getUser = () => {
        if(this.valid_fields() === 0){
            this.setState({loading_button: true})
            var url = ENDPOINT+"buyer/consultar_buyer_transacao_id/"+this.state.transacao_id;
            axios.get(url, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }
            })
            .then((response) => {
                var data = response.data;
                this.setState({
                    transacao_id_current: data.api_transacao_id,
                    servico_current: data.produto_servico,
                    status_transacao_current: getStatus(data.antifraude_status, data.status_pagamento),
                    valor_transacao_current: data.valor,
                })
                if(data.user !== null){
                    this.getUserbyReceita(data.user.cpf);
                    this.setState({
                        nome_completo_current: data.user.nome + ' '+ data.user.sobrenome,
                        cpf_current: data.user.cpf,
                        email_current: data.user.email,
                        telefone_current: data.user.telefone,
                        endereco_current: data.user.endereco,
                        status_current: data.user.status,
                        loading_buyer: false,
                        loading_button: false,
                    });
                    this.getCartoes(data.user.cpf);
                }
    
                return data;
            })
            .catch((error) => {
                this.setState({
                    nome_completo_current: '',
                    cpf_current: '',
                    email_current: '',
                    telefone_current: '',
                    endereco_current: {},
                    status_current: '',
        
                    nome_receita_current: '',
                    nascimento_receita_current: '',
                    cpf_receita_current: '',
                    situacao_receita_current: '',

                    transacao_id_current: '',
                    servico_current: '',
                    status_transacao_current: '',
                    valor_transacao_current: '0',
        
                    loading_button: false,
                    loading_buyer: true,
                    loading_cartao: true,
                    cartoes: [],
        
                    activeTab: '1'
                })
                var data = error.response.data;
                toast.error(data.errors);
            })
        }
    }
    render(){
        return(
            <Div>
                <h3>Buscar Buyer pelo ID da Transação</h3>
                <hr />
                <Card>
                    <CardBody>
                        <div>
                            <InputText
                                name="transacao_id"
                                placeholder="ID da Transação"
                                value={this.state.transacao_id}
                                invalid={this.state.invalid_transacao_id}
                                type="text"
                                onChange={this.handleChange}
                            />
                            <br/>
                            <Button disabled={this.state.loading_button} color="success" onClick={() => this.getUser()}>
                                {this.state.loading_button === true ?
                                    <ClipLoader color="#fff" size={20}/>:
                                    <MdSearch style={{ padding: 0 }}/>} Buscar</Button>
                        </div>
                        <br/>
                        <div hidden={this.state.loading_buyer} >
                            <this.Navs/>
                            <TabContent hidden={this.state.loading_buyer} activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <Card style={{borderTop: 0, borderRadius: 0}}>
                                        <CardBody>
                                            <h5>Transações</h5>
                                            <hr/>
                                            <p><b>ID da Transação:</b> {this.state.transacao_id_current}</p>
                                            <p><b>Serviço:</b> {this.state.servico_current}</p>
                                            <p><b>Valor:</b> {parseFloat(this.state.valor_transacao_current).toFixed(2).replace('.', ',')}</p>
                                            <p><b>Status:</b> {this.state.status_transacao_current}</p>
                                            <br/>
                                            <h5>Dados Pessoais</h5>
                                            <hr/>
                                            <p><b>Nome:</b> {this.state.nome_completo_current}</p>
                                            <p><b>Telefone:</b> {this.state.telefone_current}</p>
                                            <p><b>E-mail:</b> {this.state.email_current}</p>
                                            <p><b>CPF:</b> {this.state.cpf_current}</p>
                                            <p><b>Status:</b> {this.state.status_current}</p>
                                            <hr/>
                                            <p><b>CEP:</b> {this.state.endereco_current.cep}</p>
                                            <p><b>Logradouro:</b> {this.state.endereco_current.logradouro}</p>
                                            <p><b>Bairro:</b> {this.state.endereco_current.bairro}</p>
                                            <p><b>Cidade:</b> {this.state.endereco_current.cidade}</p>
                                            <p><b>Estado:</b> {this.state.endereco_current.uf}</p>
                                            <p><b>Complemento:</b> {this.state.endereco_current.complemento}</p>
                                        </CardBody>
                                    </Card>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Card style={{borderTop: 0, borderRadius: 0}}>
                                        <CardBody>
                                            <h5>Receita Federal</h5>
                                            <hr/>
                                            <p><b>Nome:</b> {this.state.nome_receita_current}</p>
                                            <p><b>Data de nascimento:</b> <NumberFormat displayType={"text"} format="##/##/####" value={this.state.nascimento_receita_current} /></p>
                                            <p><b>CPF:</b> <NumberFormat displayType={"text"} format="###.###.###-##" value={this.state.cpf_receita_current} /></p>
                                            <p><b>Situação:</b> {this.state.situacao_receita_current}</p>
                                        </CardBody>
                                    </Card>
                                </TabPane>
                                <TabPane tabId="3">
                                    <Card style={{borderTop: 0, borderRadius: 0}}>
                                        <CardBody>
                                            <h5>Cartões Cadastrados</h5>
                                            <hr/>
                                            {this.state.loading_cartao === true ? 
                                                <>Carregando Cartões...</> 
                                                : 
                                                <>
                                                    {this.state.cartoes.length === 0 ? 
                                                        'Nenhum cartão encontrado' 
                                                        : 
                                                        <DataTable
                                                            noHeader={true}
                                                            button={true}
                                                            columns={columns()}
                                                            data={this.state.cartoes}
                                                            theme="default"
                                                        />
                                                    }
                                                </>
                                            }
                                        </CardBody>
                                    </Card>
                                </TabPane>
                            </TabContent>
                        </div>
                    </CardBody>
                </Card>
            </Div>
        )
    }
}

export default BuyerTransacao;