import { Component } from "react";
import { TablePabx } from "../../components/Tables/TablePabx/TablePabx";
import { getRows, getRowsPerPage } from '../../components/Tables/TablePabx/propsPabx';
import moment from "moment";
import { Card, CardBody } from "reactstrap";
import { getUser } from "../../auth/set_services";
import { PropagateLoader } from "react-spinners";
import Pusher from "pusher-js";


class PabxCalls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            validade: false,
            activePage: 1,
            totalPage: 1,
            filter: false,
            search: '',
            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            loading_table: true,
            error_message: '',
            success_message: '',
        }
    }

    PusherInstancia = () => {
        const pusher_instance = new Pusher("2892f3b88d766b1b14d3", {
            cluster: 'us3',
        });

        var response = pusher_instance.subscribe('atualizar_analise_pabx');
        response.bind('atualizar_pabx', () => {
            setTimeout(() => getRows(this.state, this.callbackRows.bind(this)), 5000);
        });
    }

    componentDidMount(){
        getRows(this.state, this.callbackRows.bind(this));
        this.PusherInstancia();
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({rowData, activePage, totalPage, loading_table: false})
    }

    onFilter = (filter) => {
        this.setState({filter});
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }

    onPageChange = (activePage) => {
        this.setState({activePage, loading_table: true})
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }


    
    render() {
        return (
            <div>
                <h3>Pabx - Ligações</h3>
                <hr />
                <Card hidden={!getUser().permissao_gestor_aqpago}>
                    <TablePabx
                        isSearch={this.handleChange}
                        data={this.state.rowData}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        state={this.state}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhuma ligação encontrada
                            </div></CardBody></Card>
                        }
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
            </div>
        )
    }
}


export default PabxCalls;