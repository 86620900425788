import React from 'react';
import { MdClear, MdEdit, MdLock, MdLockOpen } from 'react-icons/md';
import { Button, ButtonGroup } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken, getUser, descricao_permissao, getPermissoes } from '../../../auth/set_services';
import ReactRoundedImage from "react-rounded-image";
import styled from 'styled-components';

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const get_editar_operadores = (user) => {
  if(getUser().permissao_edicao_operadores){
    return true;
  }
  else if(getUser().permissao_edicao_operadores){
    if(user.cpf_setor === getUser().cpf_setor){
      return true;
    }
    else{
      return false;
    }
  }
  else{
    return false;
  }
}

const get_excluir_operadores = (user) => {
  if(getUser().permissao_edicao_operadores === 1){
    return true;
  }
  else if(getUser().permissao_edicao_operadores === 1){
    if(user.cpf_setor === getUser().cpf_setor){
      return true;
    }
    else{
      return false;
    }
  }
  else{
    return false;
  }
}
const ChatStatus = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 12px;
    background-color: ${props => (props.color)};
`;

const ChatStatusContent = styled.div`
    display: flex;
    align-items: center;
    @media screen and (max-width: 800px) {
      b{
        display: none;
      }
    }
`;
const estado_opcoes = [
  {id: 1, value: 'online', label: <ChatStatusContent><ChatStatus color="green"/> <b>Online</b></ChatStatusContent>},
  {id: 0, value: 'offline', label: <ChatStatusContent><ChatStatus color="red"/> <b>Offline</b></ChatStatusContent>},
  {id: 2, value: 'ocupado', label: <ChatStatusContent><ChatStatus  color="orange"/> <b>Ocupado</b></ChatStatusContent>},
];
export const columns = (addAdmin, deleteAdmin, updateStatusAdmin) => [
  /* {
      name: "ID",
      selector: "id",
      sortable: true,
      width: '5%'
    }, */
    {
      name: "Foto de perfil",
      selector: "avatar_foto",
      sortable: true,
      width: '15%',
      cell: row => <div style={{ display: "flex", margin: 15 }}>
        <ReactRoundedImage
          image={row.avatar_foto}
          roundedColor="#1a1a1a"
          imageWidth="110"
          imageHeight="110"
          roundedSize="8"
        />
        </div>
    },
    
    {
      name: "Nome",
      selector: "name",
      sortable: true,
      width: '15%'
    },
    {
      name: "E-mail",
      selector: "email",
      sortable: true,
      width: '15%'
    },
    {
      name: "Telefone",
      selector: "telefone",
      sortable: true,
      width: '13%'
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      width: '13%',
      cell:  row => capitalizeFirstLetter(row.status)
    },
    {
      name: "Acesso",
      selector: "access_system",
      sortable: true,
      width: '100px',
      cell:  row => descricao_permissao[row.access_system]
    },
    {
      name: "Status de Chat",
      selector: "status",
      sortable: true,
      width: '120px',
      cell:  row => estado_opcoes.map(p => {if(p.id === row.status_chat){ return p.label } return "";})
    },
    {
        name: '',
        selector: 'functions',
        cell: row => <ButtonGroup 
        //hidden={getUser().permission['admins'] !== 2}
        >
          <Button 
            hidden={!get_editar_operadores(row)}
            outline
            color="success"
            style={{ padding: '0px', marginRight: '3px', border: 0}} 
            onClick={() => updateStatusAdmin(row.id, 'ativo')}
            >
              <MdLockOpen style={{ padding: '2px', width: '23px' }} size={20}/>
          </Button>
          <Button 
            hidden={!get_editar_operadores(row)}
            outline
            color="danger"
            style={{ padding: '0px', marginRight: '3px', border: 0}} 
            onClick={() => updateStatusAdmin(row.id, 'bloqueado')}
            >
              <MdLock style={{ padding: '2px', width: '23px' }} size={20}/>
          </Button>
            <Button 
              hidden={!get_editar_operadores(row)}
              outline
              color="primary"
              style={{ padding: '0px', marginRight: '3px', border: 0}} 
              onClick={() => addAdmin(true, row)}>
                <MdEdit style={{ padding: '2px', width: '23px' }} size={20}/>
            </Button>
            <Button 
              hidden={!get_excluir_operadores(row) || getUser().id === row.id}
              style={{ padding: '0px', marginRight: '3px', border: 0}} 
              outline
              color="danger" 
              onClick={() => deleteAdmin(row)}>
                <MdClear style={{ padding: '2px', width: '23px' }} size={20}/>
            </Button>
        </ButtonGroup>
    }
];


export const getRows = (state, callback) => {
  var url = ENDPOINT+"admin/list?page="+state.activePage+"&search="+state.search;
  
  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer '+getToken());

  var myInit = { 
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default' };
  fetch(url, myInit)
  .then(response => response.json())
  .then((p) => {
    callback(p.data, p.last_page)
  })
}

export const getRowsPerPage = (page, state, callback) => {
    var url = ENDPOINT+"admin/list?page="+page+"&search="+state.search;
    
    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer '+getToken());

    var myInit = { 
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default' };
    fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      callback(p.data, p.last_page)
    })
}