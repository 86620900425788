import React, { Component } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { Card, CardBody } from 'reactstrap';
import { getToken } from "../../auth/set_services";
import { getRows, getRowsPerPage } from "../../components/Tables/TableJuridico/propsProcesso";
import Moment from "moment";
import { TableProcesso } from "../../components/Tables/TableJuridico/TableProcesso";
import Pusher from 'pusher-js';
import ConfirmarRemoverProcesso from "../../components/Modals/Juridico/ConfirmarRemoverProcesso";
import axios from "axios";
import { toast } from "react-toastify";
import { ENDPOINT } from "../../auth/endpoint";

class ProcessosPage extends Component{
    constructor(props){
        super(props);
        this.state = {
            search: '',
            loading_table: true,
            edit_id: -1,
            edit_user: null,
            rowData: [],
            advogado_responsavel_options: [],
            advogado_responsavel: null,
            cpf_cnpj: '',
            is_seller: false,
            forma_pagamento: null, 
            data_pedido: null,
            origem_pagamento: null,

            visible_confirm: false,
            id_processo: null,

            status_processo: null,
            status_pagamento: null,
            reclamado: null,
            callback_modal: null,

            admin_inicio: null,
            admin_fim: null,
            numero_pedido: '',
            totalPage: 0,
            activePage: 1,
            total_NVO: '0.00',
            total_AMA: '0.00',
            total_RPM: '0.00',
            total_APM: '0.00',
            is_cnpj: false,
            document: '',
            status: { value: "ANALISANDO", label: "Antifraude - Analise Pendente" },
            filter: false,
            searching: false,
            show_filter: false,
            valor_de: '0.00',
            valor_ate: '0.00',
            payload_current: {},
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
        this.refDocument = React.createRef();
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    handleChange = (event) => {
        var state = this.state;
        let name = event.target.name;
        let value = event.target.value;

        if(name === 'cpf_cnpj'){
            if(value.replace(/([^\d])+/gim, '').length > 10){
                state[name] = value;
                if(state.origem_pagamento == null){
                    state.origem_pagamento = { value: "cobrancas", label: "Link de Pagamento" };
                }
                state.activePage = 1;
                state.rowData = [];
                state.loading_table = true;
                this.setState({...state});
                
                getRowsPerPage(1, state, this.callbackRows);
            }
            else if(value.replace(/([^\d])+/gim, '').length === 0){
                state[name] = value;
                state.activePage = 1;
                state.rowData = [];
                state.loading_table = true;
                this.setState({...state});
                
                getRowsPerPage(1, state, this.callbackRows);
            }
        }
        else{
            state[name] = value;
            state.activePage = 1;
            state.rowData = [];
            state.loading_table = true;
            this.setState({...state});
            
            getRowsPerPage(1, state, this.callbackRows);
        }
    }

    componentDidMount(){
        getRows(this.state, this.callbackRows.bind(this), this);
        this.PusherInstancia();
        this.handleLoadSelectData()
        
        // console.log('-----')
        // console.log(getRemove())
        // if(getRemove() !== '/juridico' && getRemove()) {

        //     this.setState({visible_confirm: true, processo_remove: getRemove()})
        // }
    }

    handleLoadSelectData = async () => {
        var url = ENDPOINT + "juridico/selects";

        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
      
        var myInit = {
          method: 'GET',
          headers: myHeaders,
          mode: 'cors',
          cache: 'default'
        };

        await fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            if(p.success == true){
                var data = p.data;

                let advogado_responsavel_options = [];

                data.advogados.forEach((key, index) => {
                    key['value'] = key.id;
                    key['label'] = key.name;
    
                    advogado_responsavel_options.push(key);
                });            

                this.setState({advogado_responsavel_options: advogado_responsavel_options});
                
            }
        })
        .catch((error) => {
            //this.handletoastMessage('Ocorreu um erro no servidor!');
        })
    }

    filterShow(){
        this.setState({
            show_filter: !this.state.show_filter
        })
    }

    onChangeSelect = (name, value) => {
        var state = this.state;
        state.rowData = [];
        state.activePage = 1;
        state.loading_table = true;
        state[name] = value;
        this.setState({...state});

        getRows(state, this.callbackRows.bind(this), this);
    }
    
    callbackRows = (rowData, totalPage) => {
        rowData.map((field, i)=>{
            if (field.numero_processo == null || field.numero_processo == 'null') {
                rowData[i].numero_processo = 'Aguardando';
            }

            if (field.nome == 'Cliente teste' || field.numero_processo == 'null') {
                rowData[i].nome = '-';
            }
            
            if (field.motivo == '1') {
                rowData[i].demanda = 'Reclamante';
            }
            else if (field.motivo == '2') {
                rowData[i].demanda = 'Reclamado';
            }
            else if (field.motivo == '3') {
                rowData[i].demanda = 'Notificação';
            }
            else {
                rowData[i].demanda = '-';
            }
        })

        this.setState({rowData, totalPage, loading_table: false, searching: false})
    }

    onPageChange = (activePage) => {
        var state = this.state;

        state.activePage = activePage;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});

        getRowsPerPage(activePage, state, this.callbackRows.bind(this));
    }
    onFilter = (filter) => {
        var state = this.state;
        state.filter = filter;
        state.activePage = 1;
        this.setState({ ...state });
        getRowsPerPage(state.activePage, state, this.callbackRows.bind(this));
    }
    onSellerDocument = (row) => {
        var document = row.individual !== null ? row.individual.taxpayer_id : row.business.ein;

        var state = this.state;
        state.rowData = [];
        state.loading_table = true;
        state.document = document;
        state.is_cnpj = document.length > 13 ? true : false;
        state.activePage = 1;
        this.setState({ ...state });

        this.refDocument.current.value = document;
        
        getRowsPerPage(1, state, this.callbackRows.bind(this));
    }
    onSellerIs = (is_seller) => {
        var state = this.state;
        state.rowData = [];
        state.loading_table = true;
        state.is_seller = is_seller;
        state.activePage = 1;
        this.setState({ ...state });

        getRowsPerPage(state.activePage, state, this.callbackRows.bind(this));
    }

    onGetRows = () => {
        if(this.state.searching == false){
            this.setState({ searching: true });
            getRows(this.state, this.callbackRows.bind(this), this);
        }

    }
    PusherInstancia = () => {
        const pusher_instance = new Pusher("2892f3b88d766b1b14d3", {
            cluster: 'us3',
        });

        var response = pusher_instance.subscribe('atualizar_analise_prevencao_risco');
        response.bind('atualizar_prevencao', () => {
            setTimeout(() => this.onGetRows(), 5000);
        });
    }

    onClose(visible_confirm){
        this.setState({visible_confirm: false})
        getRows(this.state, this.callbackRows.bind(this))
    }

    onModalConfirmDeleProcesso = (id_processo) => {
        this.setState({
            id_processo: id_processo,
            visible_confirm: true,
        })
    }

    handletoastSuccessMessage = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    handletoastMessage = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    removeProcesso = () => {
        var url = ENDPOINT + "juridico/remove/"+this.state.id_processo;
        //var url = "http://127.0.0.1:8000/juridico/remove/57";
        
        axios.delete(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            if(response.data.success) {
                var state = this.state;
                state.rowData = [];
                state.activePage = 1;
                state.loading_table = true;
                this.setState({...state});
        
                getRows(state, this.callbackRows.bind(this), this);

                this.handletoastSuccessMessage('Removido com sucesso!')
            } else {
                this.handletoastMessage('Falha ao remover');
            }
        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!')
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              // console.log(error.config);
        })

    }

    render(){
        return (
            <div> 
                <ConfirmarRemoverProcesso 
                    visible={this.state.visible_confirm}
                    onClose={() => this.onClose(false)}
                    callback={this.removeProcesso}
                /> 
                <h3>Processos Juridicos</h3>
                <hr />
                <TableProcesso
                    isSearch={this.handleChange}
                    onModalConfirmDeleProcesso={this.onModalConfirmDeleProcesso}
                    onChangeSelect={this.onChangeSelect}
                    onSearchDocument={this.onSearchDocument.bind(this)}
                    onSellerIs={this.onSellerIs.bind(this)}
                    onSellerDocument={this.onSellerDocument}
                    data={this.state.rowData}
                    state={this.state}
                    is_cnpj={this.state.is_cnpj}
                    show_filter={this.state.show_filter}
                    onFilter={this.onFilter.bind(this)}
                    date_from={this.state.date_from}
                    date_to={this.state.date_to}
                    is_seller={this.state.is_seller}
                    filter={this.state.filter}
                    refDocument={this.refDocument}
                    filterShow={this.filterShow.bind(this)}
                    onPageChange={this.onPageChange}
                    activePage={this.state.activePage}
                    totalPage={this.state.totalPage}
                    noDataComponent={ this.state.loading_table === true ? 
                        <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                            <PropagateLoader color={'#52c565'} loading={20} size={30} />
                        </div></div></CardBody></Card> :
                        <Card style={{border: 0}}><CardBody><div>
                            Nenhum processo encontrado
                        </div></CardBody></Card>
                    }
                />
            </div>
        )
    }
}

export default ProcessosPage;