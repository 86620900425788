import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { VscError } from 'react-icons/vsc';
import { ButtonDefault } from '../Button/ButtonDefault';

const BoxError = (props) => {
    return(
        <Modal isOpen={props.visible} centered={true}>
            <ModalBody>
                <center>
                    <VscError style={{height: '30%', width: '30%', opacity: '0.7'}} color="red" />
                    <br/><br/>
                    <h4 style={{fontWeight: '400'}}>{props.title}</h4>
                    <p>{props.message}</p>
                    <ButtonDefault onClick={props.onChange} style={{width: '90px', height: '40px'}}> OK </ButtonDefault>
                </center>
            </ModalBody>
        </Modal>
    )
}

export default BoxError;