import React from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, Col, Row, Collapse, Button } from 'reactstrap';
import Pagination from '../Pagination';
import debounce from 'lodash/debounce';
import { columns } from './propsUserAqpago';
import { InputText } from '../../InputText/InputText';
import { InputMasked } from '../../InputText/InputMasked';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';


const TableUsersAqpago = (props) => {
    return (
        <>
            <Card>
                <CardBody>
                    <Row>
                        <Col md={10}>
                            <div style={{ textAlign: 'left' }}>
                                <InputText
                                    name="search"
                                    placeholder="Busca por nome"
                                    onChange={debounce((event) => props.isSearch(event), 500)} />
                            </div>
                        </Col>
                        <Col md={2} style={{padding: 0, textAlign: 'right'}}>
                                <Button
                                    color="warning"
                                    onClick={props.filterShow}
                                    style={{ marginRight: '25px', marginBottom: '4px' }}>
                                    Filtrar&nbsp;
                                    <AiFillCaretDown hidden={props.show_filter} />
                                    <AiFillCaretUp hidden={!props.show_filter} />
                                </Button>
                        </Col>
                    </Row>
                    <Collapse isOpen={props.show_filter}> 
                    <br/>
                    <Card style={{border: 0}}>
                        <Row>
                            {/* <Col md={6}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputMasked
                                        name="document"
                                        mask={props.is_cnpj === false ? "999.999.999-99" : "99.999.999/9999-99"}
                                        placeholder={props.is_cnpj === false ? "CPF" : "CNPJ"}
                                        onChange={debounce((event) => props.isSearch(event), 500)}
                                        />
                                    &nbsp;&nbsp;<input 
                                        type="checkbox"
                                        checked={props.is_cnpj}
                                        onChange={() => props.onSearchDocument()}/> Pesquisar CNPJ
                                </div>
                            </Col> */}
                            <Col md={6}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputMasked
                                        name="document"
                                        mask={props.is_cnpj === false ? "999.999.999-99" : "99.999.999/9999-99"}
                                        placeholder={props.is_cnpj === false ? "CPF" : "CNPJ"}
                                        onChange={debounce((event) => props.isSearch(event), 500)}
                                        />
                                </div>
                            </Col>
                            <Col md={2}>
                                <p style={{marginTop: '16px'}}><input 
                                    type="checkbox"
                                    checked={props.is_cnpj}
                                    onChange={() => props.onSearchDocument()}/> Pesquisar CNPJ</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputText
                                        name="seller_id"
                                        placeholder={"Seller ID"}
                                        onChange={debounce((event) => props.isSearch(event), 500)}
                                        />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>

                            </Col>
                        </Row>
                        <br/>
                    </Card>
                    </Collapse>
                    <hr style={{ marginBottom: '4px' }} />
                    <DataTable
                        noHeader={true}
                        columns={
                            columns()
                        }
                        button={true}
                        responsive
                        noDataComponent={props.noDataComponent}
                        data={props.data}
                        theme="default"
                    />
                </CardBody>
                <CardFooter style={{ textAlign: 'center' }}>
                    <Pagination
                        onPageChange={props.onPageChange}
                        activePage={props.activePage}
                        totalPage={props.totalPage} />
                </CardFooter>
            </Card>
        </>
    )
}
export default TableUsersAqpago;