import React, {Component} from 'react';
import { Card, CardBody } from "reactstrap";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { TableCobrancas } from '../../components/Tables/TableCobrancas/TableCobrancas';
import Moment from 'moment';
import { getRows, getRowsPerPage } from '../../components/Tables/TableCobrancas/propsCobranca';
import PropagateLoader from "react-spinners/PropagateLoader";
import axios from 'axios';
import { ENDPOINT } from '../../auth/endpoint';
import { getPermissoes, getToken, getUser } from '../../auth/set_services';
import { toast } from 'react-toastify';

const getId = () =>{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const user_id = urlParams.get('user_id');
    if(user_id !== null){
        return user_id;
    }
    else{
        return null;
    }
}
const store = (hash, id, onData) => {
    var url = ENDPOINT+'cobranca/reenviar/'+hash+'/'+id;

    axios.post(url, [], {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });
}
class Cobrancas extends Component{
    constructor(props){
        super(props);
        this.state = {
            rowData: [],
            validade: false,
            activePage: 1,
            user_id: getId(),
            totalPage: 1,
            document: '',
            forma_pagamento: null,
            status_pagamento: null,
            is_cnpj: false,
            filter: false,
            search: '',
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            loading_table: true,
        }
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentDidMount(){
        getRows(this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({rowData, activePage, totalPage, loading_table: false})
    }

    onFilter = (filter) => {
        this.setState({filter});
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }

    onValidade = (validade) => {
        var state = this.state;
        state['validade'] = validade;
        state.loading_table = true;
        this.setState({ ...state });

        getRowsPerPage(1, this.state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    onPageChange = (activePage) => {
        this.setState({activePage, loading_table: true})
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    updateCobranca = (hash, id) => {
        store(hash, id, this.onDataToast.bind(this));
    }

    onDataToast(data) {
        if (data.success === true) {
            toast.success(data.message);
        }
        else {
            toast.error(data.errors);
        }
    }
    handleStatusSelectChange = status_pagamento => {
        var state = this.state;
        state['status_pagamento'] = status_pagamento;
        state.loading_table = true;
      this.setState({ ...state });
      getRowsPerPage(1, this.state.search, this.state.filter, state, this.callbackRows.bind(this));
    };
    handleTypePaymentSelectChange = forma_pagamento => {
        var state = this.state;
        state['forma_pagamento'] = forma_pagamento;
        state.loading_table = true;
      this.setState({ ...state });
      getRowsPerPage(1, this.state.search, this.state.filter, state, this.callbackRows.bind(this));
    };
    render(){
        return(
            <div>
                <h3>Movimentação de Saldo pré-pago</h3>
                <hr/>
                <Card hidden={!getUser().permissao_gestor_aqpasso}>
                    <TableCobrancas
                        isSearch={this.handleChange}
                        is_cnpj={this.state.is_cnpj}
                        data={this.state.rowData}
                        handleTypePaymentSelectChange={this.handleTypePaymentSelectChange.bind(this)}
                        handleStatusSelectChange={this.handleStatusSelectChange.bind(this)}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        onFilter={this.onFilter.bind(this)}
                        onValidade={this.onValidade.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        updateCobranca={this.updateCobranca.bind(this)}
                        filter={this.state.filter}
                        state={this.state}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhuma cobrança encontrada
                            </div></CardBody></Card>
                        }
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <div hidden={getUser().permissao_gestor_aqpasso}>
                  <Card>
                      <CardBody>
                          <p>Você não tem permissões para acessar essa função</p>
                      </CardBody>
                  </Card>
                </div>
            </div>
        )
    }
}

export default Cobrancas;