import React from 'react';
import { HiViewGrid } from 'react-icons/hi';
import { Button, Row } from 'reactstrap';
import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import 'react-dropdown/style.css';
import moment from 'moment';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { MdDelete, MdMoneyOff } from 'react-icons/md';
import styled from 'styled-components';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ButtonGreen } from '../../ButtonStylish/ButtonGreen';
import { ButtonGray } from '../../ButtonStylish/ButtonGray';
import { list_pagamentos_liberados } from '../../../auth/listas';


/* const capitalizeFirstLetter = (string) => {
  if(string !== null){
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "Indisponivel"
} */
const AreaIdTransacao = styled.div`
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
`

const payment = (payment_type) => {
  switch(payment_type){
      case 'credit' : return 'Crédito';
      case 'boleto' : return 'Boleto';
      case 'debit' : return 'Débito';
      default: return payment_type
  }
}

const payment_razao = (payment_type) => {
  switch(payment_type){
      case 'cartao_proprio' : return 'Cartão Próprio';
      case 'cartao_familiar' : return 'Cartão Familiar';
      case 'venda_fora_atividade' : return 'Venda Fora da Atividade';
      case 'venda_acima_media' : return 'Venda Acima da Média';
      case 'venda_cartao_media' : return 'Venda Cartão Repetido';
      case null : return 'Sem informações';
      default: return payment_type
  }
}
const getDetailsPayout = (value) => {
  var details = {
    id_transacao: value.payload.id,
    data_venda: value.data_venda,
    detalhes: value.payload.description,
    taxa: value.payload.fees,
    transaction_number: value.payload.transaction_number,
    documento: value.documento,
    telefone: value.payload.individual ? value.payload.individual.phone_number : value.payload.business.owner.phone_number,
    email: value.payload.individual ? value.payload.individual.email : value.payload.business.owner.email,
    valor: value.payload.amount,
    nome: value.nome,
    created_at: moment(value.data_venda).format('DD/MM/YYYY HH:mm')
  }
  return details;
}

export const sendSMS = (telefones, mensagem = null, callbackRows) => {
  var url = ENDPOINT + "payout/enviar_sms";
  var formData = new FormData();
  telefones.forEach((p, i) => {
    formData.append('telefones['+i+']', JSON.stringify(getDetailsPayout(p)));
  });

  if(mensagem !== null){
    formData.append('mensagem', mensagem);
  }

  axios.post(url, formData, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }
  })
  .then((response) => {
      var data = response.data;
      callbackRows(data);
      return true;
  })
  .catch((error) => {
    
    toast.error('Ocorreu um erro no servidor');
    return false;
  });
}


export const gerarFaturasLembretes = (lembretes, mensagem = null, callbackRows) => {
  var url = ENDPOINT + "faturas/gerar-varios-lembrete";

  var formData = new FormData();
  lembretes.forEach((p, i) => {
    formData.append('lembretes['+i+']', JSON.stringify(p));
  });

  axios.post(url, formData, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }
  })
  .then((response) => {
      var data = response.data;
      callbackRows(data);
      return true;
  })
  .catch((error) => {
    
    toast.error('Ocorreu um erro no servidor');
    return false;
  });
}


export const sendMails = (emails, mensagem = null, callbackRows) => {
  var url = ENDPOINT + "payout/enviar_emails";
  var formData = new FormData();
  emails.forEach((p, i) => {
    formData.append('emails['+i+']', JSON.stringify(getDetailsPayout(p)));
  });

  if(mensagem !== null){
    formData.append('mensagem', mensagem);
  }

  axios.post(url, formData, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }
  })
  .then((response) => {
      var data = response.data;
      callbackRows(data);
      return true;
  })
  .catch((error) => {
    
    toast.error('Ocorreu um erro no servidor');
    return false;
  });
}

export const setPayoutLiberado = (pagamentos, callbackRows) => {
  var url = ENDPOINT + "payout/setar_pagamento_liberado";
  var formData = new FormData();
  pagamentos.forEach((p, i) => {
    formData.append('pagamentos['+i+']', JSON.stringify(getDetailsPayout(p)));
  });

  axios.post(url, formData, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }
  })
  .then((response) => {
      var data = response.data;
      callbackRows(data);
      return true;
  })
  .catch((error) => {
    
    toast.error('Ocorreu um erro no servidor');
    return false;
  });
}

export const setPayoutLiquidado = (pagamentos, callbackRows) => {
  var url = ENDPOINT + "payout/setar_pagamento_liquidado";
  var formData = new FormData();
  pagamentos.forEach((p, i) => {
    formData.append('pagamentos['+i+']', JSON.stringify(getDetailsPayout(p)));
  });

  axios.post(url, formData, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }
  })
  .then((response) => {
      var data = response.data;
      callbackRows(data);
      return true;
  })
  .catch((error) => {
    
    toast.error('Ocorreu um erro no servidor');
    return false;
  });
}
const check_time = (created_at, dias) => {
  var date_now = Date.now();

  var date = new Date(created_at);
  date.setDate(date.getDate() + dias);

  if(date_now > date){
    return true;
  }

  return false;
}

const get_status_prev = (pagamento_liberado) => {
    var status = 'S/N'

    list_pagamentos_liberados.forEach(p => {
      if(pagamento_liberado == p.value) status = p.label;
    });

    return status;

}
export const columns = (onPayload, onDelete, onDetails) => [
  {
    name: '',
    selector: 'buttons',
    width: '90px',
    cell: row => <Row style={{margin: 2}}>
        <Button
        outline
        style={{ padding: 0, border: 0, fontWeight: '700'}}
        onClick={() => 
            {
                window.location.href = '/payout-detalhes/'+row.id_transacao
            }
        }>
        <HiViewGrid style={{ padding: '2px' }}/>
        </Button>
        {onDelete ? 
            <Button data-tip="Deletar Payout"
              outline
              style={{ padding: 0, border: 0, fontWeight: '700'}}
              color="danger"
              onClick={() => onDelete(row.id_transacao)}
            >
              <MdDelete style={{ padding: '2px' }} />
            </Button> 
          : 
          <></>}
          <ReactTooltip/>
      </Row>
  },
  {
    name: "Data",
    selector: "created_at",
    sortable: false,
    width: '145px',
    cell: row => <div>
                {row.pagamento_liberado === 'liberacao_quatorze_dias' && check_time(row.created_at, 14) == true ? 
                    <div>
                      <b style={{color: 'green'}}>Pagamento aguardando liberação pois passou os 14 dias</b>
                    </div> 
                  :
                  row.pagamento_liberado === 'liberacao_sete_dias' && check_time(row.created_at, 14) == true ? 
                    <div>
                      <b style={{color: 'green'}}>Pagamento aguardando liberação pois passou os 7 dias</b>
                    </div> 
                  : 
                    null}
                  {row.created_at !== null ? moment(row.created_at).format('DD/MM/YYYY HH:mm') : ''}
                  <br/><br/>
    </div>
  },
  {
    name: "ID da Transação",
    selector: "id",
    sortable: false,
    width: '190px',
    cell: row => <AreaIdTransacao>{row.id_transacao}<br/>
                  <div style={{textAlign: 'center'}}>
                    <CopyToClipboard onCopy={() => {toast.success('Copiado com sucesso!')}} text={row.id_transacao}>
                      <ButtonGreen>
                        Copiar
                      </ButtonGreen>
                    </CopyToClipboard>
                    <ButtonGray color="success" onClick={() => onDetails(getDetailsPayout(row))}>
                      Detalhes
                    </ButtonGray>
                  </div>
              </AreaIdTransacao>
  },
  {
    name: "Seller",
    selector: "nome",
    sortable: false,
    width: '190px',
    cell: row => <p style={{textAlign: 'center', marginTop: 10, width: '100%'}}>
                  {row.nome}
                  {row.notificacoes_pendentes === true ? 
                    <>
                      <br/>
                      <b style={{color: 'red'}}>Existem emails não lidos recebidos do cliente</b>
                    </> 
                  : null}
                </p>
  },
  {
    name: "Status",
    selector: "pagamento_liberado",
    sortable: false,
    width: '150px',
    cell: row => row.pagamento_liberado !== null ? get_status_prev(row.pagamento_liberado)  : 'Não definido'
  },
  {
    name: "Documento",
    selector: "documento",
    sortable: false,
    width: '140px'
  },
  {
    name: "Valor",
    selector: "amount",
    sortable: false,
    width: '120px',
    cell: row => <>{parseFloat(row.valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</>
  },
  {
    name: "Tipo de Venda",
    selector: "payment_type",
    sortable: false,
    width: '170px',
    cell: row => <p style={{margin: 0}}>
                    {payment(row.payload.payment_type)}<br/>
                    <small>
                        <b>Bloqueio: </b>{row.tipo_bloqueio == 'prevencao' ? 'Prevenção' : 'Cartão Próprio'}<br/>
                        <b>{row.payload.point_of_sale !== null ? 
                          (row.payload.point_of_sale.entry_mode == 'chip' ? 
                            'CP - Cartão Presente' : 'CNP - Cartão não presente'
                          ) : ''}
                        </b>
                    </small>
                  </p>
  },
  {
    name: "Razao do Bloqueio",
    selector: "razao_bloqueio",
    sortable: false,
    width: '170px',
    cell: row => payment_razao(row.razao_bloqueio)
  },
  {
    name: "Motivo do Bloqueio",
    selector: "motivo_bloqueio",
    sortable: false,
    width: '170px',
    cell: row => row.motivo_bloqueio !== null ? row.motivo_bloqueio.descricao : 'Sem Informações'
  }
  /* {
    name: "Status",
    selector: "status",
    sortable: false,
    width: '140px'
  }, */
];

export const getRows = (state, callback) => {
  var url = ENDPOINT + "payout/listar_pagamentos_bloqueados?page=" + state.activePage + "&search=" + state.search;

  var document = state.document.replace(/([^\d])+/gim, '');
  if(state.is_cnpj === false){
    if(document.length === 11){
      url += "&cpf_cnpj="+document;
    }
  }
  else{
      if(document.length === 14){
        url += "&cpf_cnpj="+document;
      }
  }
  
  if(state.filter === true){
      url += "&date_from="+state.date_from+'&date_to='+state.date_to;

  }
  
  if(parseFloat(state.valor_ate) > 0){
    url += "&valor_de="+state.valor_de;
  }

  if(parseFloat(state.valor_ate) > 0){
      url += "&valor_ate="+state.valor_ate;
  }
  
  if(state.modalidade !== null){
      url += "&modalidade="+state.modalidade.value;
  }

/*   if(state.status_user.length > 0){
    url += "&status_usuario="+state.status_user
  } */

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        var meta = 0;
        if(p.meta){
          meta = p.meta.last_page;
        }
        callback(p.data, meta);
    })
    .catch((error) => {
        toast.error(error.toString());
    })
}

export const getRowsPerPage = (page, state, callback) => {
  var url = ENDPOINT + "payout/listar_pagamentos_bloqueados?page=" + state.activePage + "&search=" + state.search;

  var document = state.document.replace(/([^\d])+/gim, '');
  if(state.is_cnpj === false){
    if(document.length === 11){
      url += "&cpf_cnpj="+document;
    }
  }
  else{
      if(document.length === 14){
        url += "&cpf_cnpj="+document;
      }
  }
  
  if(state.filter === true){
      url += "&date_from="+state.date_from+'&date_to='+state.date_to;
  }
  
  if(state.modalidade !== null){
      url += "&modalidade="+state.modalidade.value;
  }

  
  if(parseFloat(state.valor_ate) > 0){
    url += "&valor_de="+state.valor_de;
  }

  if(parseFloat(state.valor_ate) > 0){
      url += "&valor_ate="+state.valor_ate;
  }

  console.log(state)

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      var meta = 0;
      if(p.meta){
        meta = p.meta.last_page;
      }
      callback(p.data, meta);
    })
    .catch((error) => {
      toast.error(error.toString());
    })
}


export const getAllRows = (state, callback) => {
  var url = ENDPOINT + "payout/listar_pagamentos?page=" + state.activePage + "&search=" + state.search;

  var document = state.document.replace(/([^\d])+/gim, '');
  if(state.is_cnpj === false){
    if(document.length === 11){
      url += "&cpf_cnpj="+document;
    }
  }
  else{
      if(document.length === 14){
        url += "&cpf_cnpj="+document;
      }
  }
  
  if(state.filter === true){
      url += "&date_from="+state.date_from+'&date_to='+state.date_to;

  }
/*   if(state.status_user.length > 0){
    url += "&status_usuario="+state.status_user
  } */

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      var meta = 0;
      if(p.meta){
        meta = p.meta.last_page;
      }
      callback(p.data, meta);
    })
    .catch((error) => {
        toast.error(error.toString());
    })
}

export const getAllRowsPerPage = (page, state, callback) => {
  var url = ENDPOINT + "payout/listar_pagamentos?page=" + state.activePage + "&search=" + state.search;

  var document = state.document.replace(/([^\d])+/gim, '');
  if(state.is_cnpj === false){
    if(document.length === 11){
      url += "&cpf_cnpj="+document;
    }
  }
  else{
      if(document.length === 14){
        url += "&cpf_cnpj="+document;
      }
  }
  
  if(state.filter === true){
      url += "&date_from="+state.date_from+'&date_to='+state.date_to;

  }
  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      var meta = 0;
      if(p.meta){
        meta = p.meta.last_page;
      }
      callback(p.data, meta);
    })
    .catch((error) => {
      toast.error(error.toString());
    })
}