import React from 'react';
import { ImFileEmpty } from 'react-icons/im';
import { Col, Row } from "reactstrap";
import Moment from 'moment';

/* function creditsAproved(data) {
    var array = [];
    data.forEach(e => {
        if(e.pagamento!== null){
            if(e.pagamento.status === "APROVADO"){
                array.push(e);
            }
        }
    });
    return array;
}

const payment = (payment_type) => {
    switch(payment_type){
        case 'credit' : return 'Crédito';
        case 'boleto' : return 'Boleto';
        default: return payment_type
    }
}

const status_transacao = (payment_type) => {
    switch(payment_type){
        case 'dispute' : return 'Em Disputa';
        case 'charged_back' : return 'Chargeback';
        default: return payment_type
    }
} */
const ContasBancarias = (props) => {
    var isData = props.data;
    return (
        <>
            <div hidden={props.data.length === 0}>
                <Row style={{fontSize: '14px', paddingLeft: 16}}>
                    <Col md={3} className="d-none d-md-block">
                        <b style={{fontWeight: 500}}>Nome do Titular</b>
                    </Col>
                    <Col md={2} className="d-none d-md-block">
                        <b style={{fontWeight: 500}}>Status</b>
                    </Col>
                    <Col md={2} className="d-none d-md-block">
                        <b style={{fontWeight: 500}}>CPF/CNPJ</b>
                    </Col>
                    <Col md={2} className="d-none d-md-block">
                        <b style={{fontWeight: 500}}>Instituição</b>
                    </Col>
                    <Col md={3} className="d-none d-md-block">
                        <b style={{fontWeight: 500}}>Conta</b>
                    </Col>
                    {/* <Col md={2} className="d-none d-md-block">
                        <b style={{fontWeight: 500}}>Data</b>
                    </Col> */}
                </Row>
                <hr className="d-none d-md-block"/>
            </div>
            {isData.sort((a, b) => a.current_account.localeCompare(b.current_account)).map(p => {
                return (
                    <>
                    <Row style={{fontSize: '14px', paddingLeft: 16, fontWeight: (p.current_account == 'active' ? '600' : '400')}}>
                        <Col md={3} sm={12}>
                            <p style={{ marginBottom: 0 }}>{p.holder_name}</p>
                        </Col>
                        <Col md={2} sm={12}>
                            <p style={{ marginBottom: 0 }}>{p.is_active === true ? 'Ativo' : 'Inativo'} {p.current_account  == 'active' ? '(Em uso)' : null}</p>
                        </Col>
                        <Col md={2} sm={12}>
                            <p style={{ marginBottom: 0 }}>{p.taxpayer_id}</p>
                        </Col>
                        <Col md={2} sm={12}>
                            <p style={{ marginBottom: 0 }}>{p.bank_name}</p>
                        </Col>
                        <Col md={3} sm={12}>
                            <p style={{ marginBottom: 0 }}><b>Conta:</b> {p.account_number}<br/>
                            <b>Agência:</b> {p.routing_number}</p>
                        </Col>
                        {/* <Col md={2} sm={12}>
                            <p style={{ marginBottom: 0 }}>{Moment(new Date(p.created_at).toDateString()).format('DD/MM/YYYY')}</p>
                        </Col> */}
                    </Row>
                    <hr/>
                    </>
                )
            })}
            {props.data.length === 0 ?
            <div>
                <center>
                    <ImFileEmpty style={{width: '50px', height: '50px'}} /><br/>
                    Não há contas Bancarias
                </center>
            </div>:<></>}
        </>
    )
}

export default ContasBancarias;