import axios from "axios";
import React, { Component } from "react";
import Select from "react-select";
import { Button, Form, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import ModelReturn from "../ReturnsApiModals/ModelReturn";

const list_docs = [
    { value: 'Documento de Identidade (RG/CNH) - Frente', label: 'Documento de Identidade (RG/CNH) - Frente', categoria: 'identificacao' },
    { value: 'Documento de Identidade (RG/CNH) - Verso', label: 'Documento de Identidade (RG/CNH) - Verso', categoria: 'identificacao' },
    { value: 'Comprovante de Endereço', label: 'Comprovante de Endereço', categoria: 'identificacao' },
    { value: 'Selfie com você segurando seu documento de Identidade', label: 'Selfie com você segurando seu documento de Identidade', categoria: 'identificacao' },
    { value: 'Certificado MEI', label: 'Certificado MEI', categoria: 'identificacao' }
];


class UploadDocumentos extends Component{
    constructor(props){
        super(props);
        this.state = {
            documento: null,
            documento_invalid: false,
            file_document: null,
            file_document_invalid: false,

            loading: false,
            error_message: '',
            success_message: ''
        }
    }

    onClose = () => {
        this.setState({
            documento: null,
            documento_invalid: false,
            file_document: null,
            file_document_invalid: false,

            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose();
    }
    
    onData = (data) => {
        
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }

    onDocument = (documento) => {
        this.setState({documento})
    }

    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    onFile = (file) => {
        if(file.target.files[0]){
            this.setState({file_document: file.target.files[0]});
        }
        else{
            this.setState({file_document: null});
        }
    }

    onSave = () => {
        if(this.validFields() === true){
            this.storeUser();
        }
    }

    validFields = () => {
        var count_invalid = 0;
        if(this.state.documento !== null){
            this.setState({
                documento_invalid: false
            })
        }
        else{
            this.setState({
                documento_invalid: true
            });
            count_invalid++;
        }

        if(this.state.file_document !== null){
            this.setState({
                file_document_invalid: false
            })
        }
        else{
            this.setState({
                file_document_invalid: true
            });
            count_invalid++;
        }

        if(count_invalid > 0){
            return false;
        }

        return true;
    }

    storeUser = () => {
        this.setState({ loading: true })
        var url = ENDPOINT+"user/enviar_documento";

        var formData = new FormData();
        formData.append('user_id', this.props.userId);
        formData.append('aqcontabil_id', this.props.aqcontabil_id);
        formData.append('title_document', this.state.documento.value);
        formData.append('categoria_document', this.state.documento.categoria);
        formData.append('file_document', this.state.file_document);
        
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            this.onData(data);
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }

            var data = error.response.data;
            this.onData(data);
            return data;
        });
    }
    render(){
        return(
            <Modal isOpen={this.props.visible}>
                <ModalHeader>Upload de Documentos</ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <Form>
                        <FormGroup>
                            <Select
                                options={list_docs}
                                isSearchable={false}
                                placeholder="Documento"
                                onChange={this.onDocument}
                            />
                            <Input hidden invalid={this.state.documento_invalid}/>
                            <FormFeedback>Campo obrigatório</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Input 
                                name="file_document"
                                type="file"
                                onChange={this.onFile}
                            />
                            <Input hidden invalid={this.state.file_document_invalid}/>
                            <FormFeedback>Campo obrigatório</FormFeedback>
                        </FormGroup>
                    </Form>
                </ModalBody>
            
                <ModalBody style={{ minHeight: '114px', display: 'flex', alignItems: 'center', alignSelf: 'center', paddingBottom: '47px' }} hidden={!this.state.loading}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                    />
                </ModalBody>

                <ModalFooter hidden={this.state.loading}>
                    <Button color="success" onClick={() => this.onSave()}>Salvar</Button>
                    <Button color="danger" onClick={() => this.onClose()}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default UploadDocumentos;