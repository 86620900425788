import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { HiViewGrid, HiArrowLeft } from 'react-icons/hi';
import { MdClose } from 'react-icons/md';
import { getToken } from "../../auth/set_services";
import { ENDPOINT } from "../../auth/endpoint";
import { Button, Row, Card, CardBody } from "reactstrap";
import styles from "./Recargas.module.css";

const DetalhesRecarga = () => {
    const [recarga, setRecarga] = useState([]);
    const [recargasByCpf, setRecargasByCpf] = useState([]);
    const [historico, setHistorico] = useState([]);
    const [activeTab, setActiveTab] = useState('detalhesRecarga');
    const [statusSelecionado, setStatusSelecionado] = useState(recarga.status || '');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [disparoTipo, setDisparoTipo] = useState('');

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleStatusChange = async (event) => {
        const novoStatus = event.target.value;
        setStatusSelecionado(novoStatus);

        try {
            // Chamada PUT para atualizar o status da recarga com header de autorização
            await axios.put(`${ENDPOINT}recarga/${recarga.id}`, { status: novoStatus }, {
                headers: {
                    Authorization: 'Bearer ' + getToken()
                }
            });
            alert('Status atualizado com sucesso!');
        } catch (error) {
            console.error('Erro ao atualizar o status:', error);
            alert('Erro ao atualizar o status.');
        }
    };

    const getRecarga = (id) => {
        axios.get(`${ENDPOINT}recarga/get/` + id, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then(response => {
                setRecarga(response.data);
                console.log(response.data);
            })
            .catch(error => {
                console.error('Error fetching recargas:', error);
            });
    }

    const getRecargaByCpf = (cpf) => {
        axios.get(`${ENDPOINT}recarga/get_cpf/` + cpf, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then(response => {
                setRecargasByCpf(response.data);
                console.log(response.data);
            })
            .catch(error => {
                console.error('Error fetching recargas:', error);
            });
    }

    const getHistoricoRecarga = (id) => {
        axios.get(`${ENDPOINT}recarga/historico/` + id, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then(response => {
                setHistorico(response.data);
                console.log(response.data);
            })
            .catch(error => {
                console.error('Error fetching recargas:', error);
            });
    }




    const handleTabChange = (tab) => {
        setActiveTab(tab);
    }

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toLocaleString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' });
    }

    const handleCardClick = (id) => {
        window.location.href = `/recarga/${id}`; // Redireciona para /recarga/:id
    };

    useEffect(() => {
        const id = window.location.pathname.split("/")[2];
        getRecarga(id);
    }, []);

    useEffect(() => {
        if (recarga.user && recarga.user.cpf) {
            getRecargaByCpf(recarga.user.cpf)
        }
        if (recarga.status) {
            setStatusSelecionado(recarga.status);
        }
        if (recarga.id) {
            getHistoricoRecarga(recarga.id);
        }
    }, [recarga]);

    const Modal = ({ onClose, children }) => {
        return (
            <div className={styles.modalOverlay} onClick={handleCloseModal}>
                <div className={styles.modalContent} onClick={(event) => {
                    event.stopPropagation();

                }}>
                    <button className={styles.closeModalButton} onClick={handleCloseModal}>  <MdClose style={{ padding: '2px', width: '23px' }} /></button>
                    {children}
                </div>
            </div>
        );
    };

    const handleDisparoChange = (event) => {
        const { value } = event.target;
        setDisparoTipo((prevDisparoTipo) => {
            const newDisparoTipo = prevDisparoTipo.includes(value)
                ? prevDisparoTipo.filter((tipo) => tipo !== value)
                : [...prevDisparoTipo, value];
            return [...new Set(newDisparoTipo)]; // Remove duplicatas
        });
    };


    const handleEnviar = async () => {
        const idRecarga = recarga.id; // Supondo que idRecarga está disponível no objeto recarga
        const baseUrl = ENDPOINT + 'recarga/gerar_comprovante/' + idRecarga;
        const disparosSucesso = [];

        for (const tipo of disparoTipo) {
            let body = {};

            if (tipo === 'email') {
                body = { email: recarga.user.email };
            } else if (tipo === 'celular') {
                body = { telefone: recarga.user.telefone };
            } else if (tipo === 'celularRecarregado') {
                body = { telefone: recarga.telefone };
            }

            try {
                const response = await axios.post(baseUrl, body, {
                    headers: {
                        Authorization: 'Bearer ' + getToken()
                    }
                });
                console.log(`Comprovante enviado para ${tipo}:`, response.data);
                disparosSucesso.push(tipo);
            } catch (error) {
                console.error(`Erro ao enviar comprovante para ${tipo}:`, error);
            }
        }

        alert(`Disparo gerado com sucesso para: ${disparosSucesso.join(', ')}`);
        handleCloseModal();
    };


    return (
        <div>
            <div className={styles.containerRecarga}>
                <div className={styles.header}>
                    <h1>Detalhes da Recarga</h1>
                    <a href="/recargas" className={styles.backButton}>
                        <HiArrowLeft />
                        <p>Voltar</p>

                    </a>
                    <button
                        className={`${styles.comprovanteButton} ${recarga.status !== 'APROVADO' ? styles.disabledButton : ''}`}
                        onClick={handleOpenModal}
                        disabled={recarga.status !== 'APROVADO'}
                    >
                        Reenviar Comprovante de Recarga
                    </button>
                    {isModalOpen && (
                        <Modal onClose={handleCloseModal}>
                            <h2 className={styles.modalTitle}>Gerar comprovante de recarga</h2>
                            <div className={styles.infoRecarga}>
                                <div>
                                    <span>Tipo de recarga:</span>
                                    <br></br>
                                    <span style={{ textTransform: 'capitalize' }}><b>{recarga.servico_recarga}</b></span>
                                </div>
                                {recarga.servico_recarga === 'celular' && (
                                    <div>
                                        <span>Número recarregado:</span>
                                        <br></br>
                                        <span><b>{recarga.ddd + recarga.telefone}</b></span>
                                    </div>

                                )}

                            </div>
                            <p className={styles.modalSubtitle}>Como deseja enviar o comprovante?</p>
                            <div className={styles.checkboxGroup}>
                                <label className={styles.checkboxLabel}>
                                    <input
                                        type="checkbox"
                                        value="email"
                                        checked={disparoTipo.includes('email')}
                                        onChange={handleDisparoChange}
                                        className={styles.checkboxInput}
                                    />
                                    <b>Por e-mail</b>
                                    <br />
                                    <span className={styles.valueChecked}>{recarga.user && recarga.user.email ? recarga.user.email : 'N/A'}</span>
                                </label>
                                <label className={styles.checkboxLabel}>
                                    <input
                                        type="checkbox"
                                        value="celular"
                                        checked={disparoTipo.includes('celular')}
                                        onChange={handleDisparoChange}
                                        className={styles.checkboxInput}
                                    />
                                    <b>Por celular do pagador</b>
                                    <br />
                                    <span className={styles.valueChecked}>{recarga.user && recarga.user.telefone ? recarga.user.telefone : 'N/A'}</span>
                                </label>
                                {recarga.telefone && (
                                    <label className={styles.checkboxLabel}>
                                        <input
                                            type="checkbox"
                                            value="celularRecarregado"
                                            checked={disparoTipo.includes('celularRecarregado')}
                                            onChange={handleDisparoChange}
                                            className={styles.checkboxInput}
                                        />
                                        <b>Por celular recarregado</b>
                                        <br />
                                        <span className={styles.valueChecked}>{recarga.ddd + recarga.telefone}</span>
                                    </label>
                                )}
                            </div>
                            <div className={styles.buttonGroup}>
                                <button className={styles.actionButton} onClick={handleEnviar}>Enviar Comprovante</button>
                            </div>
                        </Modal>
                    )}
                </div>

                <div className={styles.cardRecarga}>
                    <div className={styles.tabButtons}>
                        <button onClick={() => handleTabChange('detalhesRecarga')} className={activeTab === 'detalhesRecarga' ? styles.activeTab : ''}>Detalhes da Recarga</button>
                        <button onClick={() => handleTabChange('detalhesPagamento')} className={activeTab === 'detalhesPagamento' ? styles.activeTab : ''}>Detalhes do Pagamento</button>
                        <button onClick={() => handleTabChange('detalhesCliente')} className={activeTab === 'detalhesCliente' ? styles.activeTab : ''}>Detalhes do Cliente</button>
                        <button onClick={() => handleTabChange('recargasCliente')} className={activeTab === 'recargasCliente' ? styles.activeTab : ''}>Recargas do Cliente</button>
                        <button onClick={() => handleTabChange('historicoAlteracoesCliente')} className={activeTab === 'historicoAlteracoesCliente' ? styles.activeTab : ''}>Histórico de Alterações</button>
                    </div>
                    {activeTab === 'detalhesRecarga' && (
                        <div>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><strong>ID Recarga:</strong> {recarga.id ? recarga.id : 'N/A'}</li>
                                <li className="list-group-item"><strong>ID Produto de Recarga:</strong> {recarga.recarga_produto && recarga.recarga_produto.codigo_produto ? recarga.recarga_produto.codigo_produto : 'N/A'}</li>
                                <li className="list-group-item"><strong>Nome Produto:</strong> {recarga.recarga_produto && recarga.recarga_produto.nome_produto ? recarga.recarga_produto.nome_produto : 'N/A'}</li>
                                <li className="list-group-item"><strong>Tipo de Recarga:</strong> {recarga.servico_recarga ? recarga.servico_recarga : 'N/A'}</li>
                                {recarga.servico_recarga === 'celular' && (
                                    <li className="list-group-item"><strong>Número do Celular para Recarregar:</strong>
                                        {recarga.ddd && recarga.telefone ? recarga.ddd + recarga.telefone : 'N/A'}</li>
                                )}
                                <li className="list-group-item"><strong>Public ID:</strong> {recarga.public_id ? recarga.public_id : 'N/A'}</li>
                                <li className="list-group-item">
                                    <strong style={{ display: 'inline-block' }}>Status Recarga:</strong>
                                    <select style={{ width: '200px', marginLeft: '15px', display: 'inline-block' }} value={statusSelecionado} onChange={handleStatusChange} className="form-control">
                                        <option value="">Selecione um status</option>
                                        <option value="APROVADO">Aprovado</option>
                                        <option value="PENDENTE">Pendente</option>
                                        <option value="CANCELADO">Cancelado</option>
                                    </select>
                                </li>
                                <li className="list-group-item"><strong>API Transação:</strong> {recarga.api_transacao ? recarga.api_transacao : 'N/A'}</li>
                                <li className="list-group-item"><strong>Valor do Produto:</strong> {recarga.valor_produto ? `R$ ${parseFloat(recarga.valor_produto).toFixed(2)}` : 'N/A'}</li>
                                <li className="list-group-item"><strong>Valor Cobrado:</strong> {recarga.valor_cobrado ? `R$ ${parseFloat(recarga.valor_cobrado).toFixed(2)}` : 'N/A'}</li>
                                <li className="list-group-item"><strong>Data última atualização:</strong> {recarga.updated_at ? formatDate(recarga.updated_at) : 'N/A'}</li>
                                <li className="list-group-item"><strong>Data de Criação:</strong> {recarga.created_at ? formatDate(recarga.created_at) : 'N/A'}</li>
                                <li className="list-group-item"><strong>Validade:</strong> {recarga.validade ? recarga.validade : 'N/A'}</li>
                            </ul>
                        </div>
                    )}
                    {activeTab === 'detalhesPagamento' && (
                        <div>
                            <ul className="list-group list-group-flush">
                                {recarga.pagamento.id && <li className="list-group-item"><strong>ID Pagamento:</strong> {recarga.pagamento.id}</li>}
                                {recarga.pagamento.produto_servico && <li className="list-group-item"><strong>Produto/Serviço:</strong> {recarga.pagamento.produto_servico}</li>}
                                {recarga.pagamento.forma_pagamento && <li className="list-group-item"><strong>Forma de Pagamento:</strong> {recarga.pagamento.forma_pagamento}</li>}
                                {recarga.pagamento.valor && <li className="list-group-item"><strong>Valor:</strong> {`R$ ${parseFloat(recarga.pagamento.valor).toFixed(2)}`}</li>}
                                {recarga.pagamento.analyst_completion_id && <li className="list-group-item"><strong>ID de Conclusão do Analista:</strong> {recarga.pagamento.analyst_completion_id}</li>}
                                {recarga.pagamento.analyst_init_id && <li className="list-group-item"><strong>ID de Início do Analista:</strong> {recarga.pagamento.analyst_init_id}</li>}
                                {recarga.pagamento.antifraude_analise_status && <li className="list-group-item"><strong>Status da Análise Antifraude:</strong> {recarga.pagamento.antifraude_analise_status}</li>}
                                {recarga.pagamento.antifraude_analise_type && <li className="list-group-item"><strong>Tipo de Análise Antifraude:</strong> {recarga.pagamento.antifraude_analise_type}</li>}
                                {recarga.pagamento.antifraude_reference_id && <li className="list-group-item"><strong>ID de Referência Antifraude:</strong> {recarga.pagamento.antifraude_reference_id}</li>}
                                {recarga.pagamento.api_transacao_id && <li className="list-group-item"><strong>ID da Transação API:</strong> {recarga.pagamento.api_transacao_id}</li>}
                                {recarga.pagamento.created_at && <li className="list-group-item"><strong>Data de Criação:</strong> {recarga.pagamento.created_at}</li>}
                                {recarga.pagamento.data_pagamento && <li className="list-group-item"><strong>Data de Pagamento:</strong> {recarga.pagamento.data_pagamento}</li>}
                                {recarga.pagamento.status_pagamento && <li className="list-group-item"><strong>Status do Pagamento:</strong> {recarga.pagamento.status_pagamento}</li>}
                                {recarga.pagamento.status_pagamento_api && <li className="list-group-item"><strong>Status do Pagamento na API:</strong> {recarga.pagamento.status_pagamento_api}</li>}
                                {recarga.pagamento.updated_at && <li className="list-group-item"><strong>Data da Última Atualização:</strong> {recarga.pagamento.updated_at}</li>}
                                {recarga.pagamento.pagamento_credito_cartao_bandeira && <li className="list-group-item"><strong>Bandeira do Cartão de Crédito:</strong> {recarga.pagamento.pagamento_credito_cartao_bandeira}</li>}
                                {recarga.pagamento.pagamento_credito_cartao_digitos && <li className="list-group-item"><strong>Dígitos do Cartão de Crédito:</strong> {recarga.pagamento.pagamento_credito_cartao_digitos}</li>}
                            </ul>
                        </div>
                    )}
                    {activeTab === 'detalhesCliente' && (
                        <div>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><strong>ID Usuário AQPAGO:</strong> {recarga.user && recarga.user.id ? recarga.user.id : 'N/A'}</li>
                                <li className="list-group-item"><strong>Nome:</strong> {recarga.user && recarga.user.nome ? recarga.user.nome : 'N/A'} {recarga.user && recarga.user.sobrenome ? recarga.user.sobrenome : ''}</li>
                                <li className="list-group-item"><strong>Email:</strong> {recarga.user && recarga.user.email ? recarga.user.email : 'N/A'}</li>
                                <li className="list-group-item"><strong>CPF:</strong> {recarga.user && recarga.user.cpf ? recarga.user.cpf : 'N/A'}</li>
                                <li className="list-group-item"><strong>Telefone:</strong> {recarga.user && recarga.user.telefone ? recarga.user.telefone : 'N/A'}</li>
                                <li className="list-group-item"><strong>Criado em:</strong> {recarga.user && recarga.user.created_at ? formatDate(recarga.user.created_at) : 'N/A'}</li>
                            </ul>
                        </div>
                    )}
                    {activeTab === 'recargasCliente' && recargasByCpf.length > 0 && (
                        <div>
                            {recargasByCpf.map((recarga) => (
                                <div key={recarga.id} className={styles.cardRecargaByCpf} onClick={() => handleCardClick(recarga.id)}>
                                    <Button
                                        className={styles.iconTopRight}
                                        data-tip="Detalhe da Recarga"
                                        outline
                                        style={{ padding: '0px', marginLeft: '3px', border: 0 }}
                                        color="primary">
                                        <HiViewGrid style={{ padding: '2px', width: '23px' }} />
                                    </Button>
                                    <div><strong>ID:</strong> {recarga.id}</div>
                                    <div><strong>Última Atualização:</strong> {recarga.updated_at ? formatDate(recarga.updated_at) : 'N/A'}</div>
                                    <div><strong>Status:</strong> {recarga.status}</div>
                                    <div><strong>Valor:</strong> {recarga.valor_cobrado ? `R$ ${parseFloat(recarga.valor_cobrado).toFixed(2)}` : 'N/A'}</div>
                                </div>
                            ))}
                        </div>
                    )}
                    {activeTab === 'historicoAlteracoesCliente' && recargasByCpf.length > 0 && (
                        <div>
                            <h2>Histórico de alterações</h2>
                            <hr></hr>

                            {historico.map((historico) => (
                                <div className='card' style={{ margin: '20px 0' }}>
                                    <div className='card-body'>
                                        <p>Data: {formatDate(historico.data_acao)}</p>
                                        <p>Tipo: {historico.tipo === 'alteracao_status' ? 'Alteração de status' : historico.tipo}</p>
                                        <p>De: {historico.status_antigo}</p>
                                        <p>Para: {historico.status_novo}</p>
                                        <p>Atualizado pelo Operador: {historico.nome_operador}</p>
                                    </div>
                                </div>

                            ))
                            }
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DetalhesRecarga;