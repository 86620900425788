import moment from 'moment';
import { HiViewGrid, HiTrash } from 'react-icons/hi';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';


export const columns = (onModalConfirmDeleProcesso) => [
    {
      name: "",
      selector: "buttons",
      sortable: false,
      width: '5%',
      cell: row => <Row>
        <Button
          outline
          style={{ padding: 2, marginLeft: 15, border: 0, fontWeight: '700'}}
          color="dark" onClick={() => 
            {
                window.location.href = '/detalhes-aceite-contrato-aqpago/'+row.documento
            }
          }>
          <HiViewGrid style={{ padding: '2px' }}/>
        </Button>
      </Row>
    },
    {
      name: "Estabelecimento",
      selector: "estabelecimento",
      sortable: false,
      width: '30%',
      cell: row => <Row>
          <Col md={12}>{row.razao_social !== undefined ? row.razao_social : row.nome + ' ' + row.sobrenome}</Col>
        </Row>
    },
    {
      name: "Documento",
      selector: "documento",
      sortable: false,
      width: '30%',
      cell: row => <Row>
          <Col md={12}>
            <NumberFormat displayType={'text'} value={row.documento} format={row.documento.length > 11 ? '##.###.###/####-##' : "###.###.###-##"}/>   
          </Col>
        </Row>
      
    },
    {
      name: "Data do Aceite",
      selector: "data_aceite",
      sortable: false,
      width: '35%',
      cell: row => <Row>
        <Col md={12}>
          {row.termos.data_aceite !== undefined ? 
             moment(row.termos.data_aceite).format('DD/MM/YYYY HH:mm:ss') 
          : 
            '-'
          }
        </Col>
      </Row>
    },
];

var numberOfAjaxCAllPending = 0;
export const getRows = (state, callback) => {
    if(numberOfAjaxCAllPending == 0){
      numberOfAjaxCAllPending = 1;
      var url = ENDPOINT + "termo_aceite/listar?page=" + state.activePage + "&cpf_cnpj=" + state.search;

      if(state.data_inicial !== undefined && state.data_inicial !== null){
        url += "&data_inicial="+state.data_inicial;
      }      
      
      if(state.data_final !== undefined && state.data_final !== null){
        url += "&data_final="+state.data_final;
      }

      var myHeaders = new Headers();
    
      myHeaders.append('Authorization', 'Bearer ' + getToken());

      var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
      };
      fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
          console.log(p)
          if (p.success == true) {
            var meta = 0;

            if(p.data.meta){
              meta = p.data.meta.last_page;
            }

            numberOfAjaxCAllPending = 0;
            // callback(p.data, meta, p.valor);
            callback(p.data.data, meta);
          }
          else{
            toast.error(p.errors);
          }
        })
        .catch((error) => {
          console.log(error)
          toast.error('Ocorreu um erro no servidor!');
        })
    }
    else{
      //callback(state.rowData, state.totalPage, []);
    }
  }
  
  export const getRowsPerPage = (page, state, callback) => {
    if(numberOfAjaxCAllPending == 0){
      numberOfAjaxCAllPending = 1;
      var url = ENDPOINT + "termo_aceite/listar?page=" + page + "&cpf_cnpj=" + state.search;


      if(state.data_inicial !== undefined && state.data_inicial !== null){
        url += "&data_inicial="+state.data_inicial;
      }      
      
      if(state.data_final !== undefined && state.data_final !== null){
        url += "&data_final="+state.data_final;
      }

      var myHeaders = new Headers();
    
      myHeaders.append('Authorization', 'Bearer ' + getToken());
      
      var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
      };
      fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
          if (p.success == true) {
            var meta = 0;

            if(p.data.meta){
              meta = p.data.meta.last_page;
            }

            numberOfAjaxCAllPending = 0;
            // callback(p.data, meta, p.valor);
            callback(p.data.data, meta);
          }
          else{
            toast.error(p.errors);
          }
        })
        .catch((error) => {
          toast.error('Ocorreu um erro no servidor!');
        })
    }
    else{
      //callback(state.rowData, state.totalPage, []);
    }
  }
