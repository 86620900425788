import React, { Component } from "react";
import { 
    Button, 
    ButtonGroup, 
    Card, 
    CardBody, 
    Col, 
    Input, 
    Row,
    TabContent, 
    Nav, 
    NavLink, 
    TabPane
} from "reactstrap";
import 'react-chat-elements/dist/main.css';
import { ChatItem } from 'react-chat-elements';
import { toast } from "react-toastify";
import styled from 'styled-components';
import Pusher from 'pusher-js';
import { ENDPOINT } from '../../auth/endpoint';
import axios from "axios";
import { getPermissoes, getToken, getUser } from "../../auth/set_services";
import ChatAtendimento from "../../components/ChatClient/ChatAtendimento";
import AsyncSelect from 'react-select/async';
import ReactRoundedImage from "react-rounded-image";
import Select from "react-select";
import { RiCustomerService2Fill } from 'react-icons/ri';
import classnames from 'classnames';
import ModalValidacaoToken from "../../components/Modals/ModalValidacaoToken/ModalValidacaoToken";

const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        padding: 4px;
        padding-right: 6px;
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
    
    .rce-mbox-right-notch {
        position: absolute;
        right: -19px;
        top: -4px;
        width: 19px;
        height: 19px;
        box-shadow: none;
        filter: none;
        fill: #52c565d1;
    }
    .rce-mbox.rce-mbox-right {
        float: right;
        margin-left: 5px;
        margin-right: 20px;
        border-top-right-radius: 0px;
        border-top-left-radius: 5px;
        box-shadow: none;
        background: #52c565d1;
        color: #fff;
    }
    .rce-mbox.rce-mbox-left {
        position: absolute;
        right: -19px;
        top: -4px;
        width: 19px;
        height: 19px;
        box-shadow: none;
        -webkit-filter: none;
        filter: none;
    }
    .rce-mbox {
        box-shadow: none;
    }
    .nav-item{
        margin: 0
    }
    .audio-react-recorder{
        display: none
    }
`;
const ChatItemContainner = styled.div`
    color: ${props => (props.cod_chat === props.cod_chat_current ? `#fff` : `#000`)};
    .rce-citem{
        padding-left: 14px;
        background: ${props => (props.cod_chat === props.cod_chat_current ? `#52c565` : `#fff`)};
    }
    .rce-citem-body--bottom-title {
        color: ${props => (props.cod_chat === props.cod_chat_current ? `#bebebe` : `#555`)};
        font-size: 15px;
    }
    .rce-citem-body--top-time {
        font-size: 12px;
        color: ${props => (props.cod_chat === props.cod_chat_current ? `rgb(240 240 240 / 40%)` : `rgba(0,0,0,0.4)`)};
    }
    .rce-citem-body--bottom-status span{
        background: #52c565;
    }
`
class MonitoramentoChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chat: [],
            file: [],
            list_departamentos: [],
            activeTab: "1",
            search: "",
            atendente: null,
            esperando_atendente: false,
            departamento: null,
            current_message: null,
            sending_message: false,
            masked_message: null,
            modal_anexo: false,
            modal_transfer: false,
            currentChat: null,
            audio_visible: false,
            recording_audio: null,
            pusher_instance: null,
            activeTabTwo: "1",
            message: '',
            consulta_disponivel: true,
            loading: true,
            isOpen: false
        }
        this.toasts = React.createRef(this);
    }
    atender(cod_chat, closeToast) {
        this.aceitarChat(cod_chat, closeToast);
    }

    AtendimentoNotificacao = (props) => {
        return(
            <div style={{ textAlign: 'center' }}>
                <p style={{ color: 'white' }}><b>Novo chat detectado:</b> {props.nome_destinatario}</p>
                <ButtonGroup>
                    <Button color="success" onClick={() => this.atender(props.codigo_chat, props.closeToast)}>Atender</Button>
                    <Button color="danger" onClick={() => props.closeToast()}>Recusar</Button>
                </ButtonGroup>
            </div>
        )
    };
    componentWillMount() {
        const pusher = new Pusher("2892f3b88d766b1b14d3", {
            cluster: 'us3',
            //authEndpoint: 'http://example.com/pusher/auth'
        });

        this.setState({pusher_instance: pusher});

        /* var response = pusher.subscribe('pusher_ligacao_chat_' + getUser().departamento_chat + "_" + getStatus());
        response.bind('chamar', e => this.bindToast(e)) */

        this.refreshMessages(pusher);
        //this.refreshAcceptMessages(pusher);
    }
    componentDidMount() {
        this.loadChat();
        this.getDepartamentos();
    }
    bindLoadChat(data) {
        if(this.state.consulta_disponivel == true){
            this.loadChat();
        }
    }
    /* bindToast(data) {
        console.log(data.cod_chat+" | "+this.state.currentChat)
        if(data.cod_chat !== this.state.currentChat){
            this.setState({ currentChat: data.cod_chat });
            this.onToast(data);
            setTimeout(() => this.setState({currentChat: null}), 10000)
        }
        else{
            console.log(data.cod_chat+" | "+this.state.currentChat)
        }
    } */
    /* refreshAcceptMessages(pusher) {
        var response = pusher.subscribe('pusher_chat_aceito_' + getUser().id);

        response.bind('atualizar', this.bindLoadChat.bind(this))
    } */

    refreshMessages(pusher) {
        var response = pusher.subscribe('atualizar_chat_monitoramento');

        response.bind('atualizar', this.bindLoadChat.bind(this))
    }

    /* bindToastCall(data) {
        toast.update(data.cod_chat, {
            type: toast.TYPE.INFO,
            render: "Chamada Atendida por outro atendente",
            autoClose: 3000
        })
    }
    
    outroAtendenteAtendeu(cod_chat) {
        var response = this.state.pusher_instance.subscribe('pusher_chat_atendido_' + cod_chat);
        response.bind('atualizar', this.bindToastCall.bind(this))
    } */

    loadChat() {
        this.setState({consulta_disponivel: false})
        var url = ENDPOINT + 'chat-atendimento/consulta-monitoramento';

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({ chat: data, consulta_disponivel: true, loading: false });
        })
        .catch(e => {
            this.setState({ consulta_disponivel: true })
        })
    }


    /* onToast(data) {
        toast.dark(<this.AtendimentoNotificacao codigo_chat={data.cod_chat} nome_destinatario={data.nome} />, {
            position: "top-right",
            autoClose: 150000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            closeButton: false,
            toastId: data.cod_chat
            //progress: undefined,
        });
        this.outroAtendenteAtendeu(data.cod_chat);
    } */

    aceitarChat(cod_chat, closeToast) {
        var url = ENDPOINT + 'chat-atendimento/aceitar-chat/' + cod_chat;

        var formData = new FormData();
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            closeToast();
        })
        .catch((error) => {
            if (error.message === 'Network Error') {
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                toast.error(obj.errors);
            }
            var data = error.response.data;

            if (data) {
                toast.error(data.errors);
            }
            else {
                toast.error('Ocorreu um erro');
            }

            closeToast();
        });

        closeToast();
    }

    onMessageCurrent(current_message) {
        this.setState({ current_message, masked_message: null });
    }
    onScroll(scroll) {
        try{
            var scroll_current = scroll.target;
            if (scroll_current.scrollHeight - scroll_current.scrollTop === scroll_current.clientHeight) {
                this.readMessageAll(this.getCurrentChat().cod_chat);
            }
        }
        catch(e){

        }
    }
    
    getCurrentChat() {
        var chat_current = [];
        this.state.chat.forEach(
            (chat) => {
                if (chat.id === this.state.current_message) {
                    chat_current = chat;
                }
            });
        return chat_current;
    }
    /* list_chats_search(){
        this.state.chat.filter()
    } */
    handleInputChange = (e) => {
        const { name, value } = e.target;
        
        this.setState({ [name]: value });
    }

    getDepartamentos = () => {
        var url = ENDPOINT+"departamento/list";
    
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            var list_departamentos = [];
            data.forEach((p, i) => {
                p['value'] = p.flag;
                p['label'] = p.nome;
                p['status'] = p.admins_online_count;
                list_departamentos.push(p);
            })
            this.setState({list_departamentos});
        })
        .catch((error) => {

        });
    }

    loadOptions = (inputValue, callback) => {
        var url = ENDPOINT + "chat-atendimento/consultar-operadores?search=" + inputValue;
        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p;
            data.forEach((p, i) => {
                /* data[i].label = <></>;
                data[i].value = p.flag; */
                
                data[i].value = p.id;
                data[i].label = <div style={{display: 'flex'}}>
                    <ReactRoundedImage
                        image={p['avatar_foto'] !== null ? p['avatar_foto'] : ''}
                        roundedColor="#8dd199"
                        roundedSize="4"
                        imageWidth="25"
                        imageHeight="25"/> 
                        <p style={{display: 'flex', margin: 0}}>
                            &nbsp;
                            {p.name}
                        </p>
                </div>;
            });

            callback(this.filter(inputValue, data));
        });
    }

    filter = (inputValue, data) => {
        return data.filter(i =>
            i.name.toLowerCase().includes(inputValue.toLowerCase())
        );
    }

    chat_em_fila(value) {
        return value.status_chat === 0;
    }

    consulta(value){
        return ((value.atendente !== null ? value.atendente.name.indexOf(this.state.search) >= 0 : '') ||
            (value.usuario !== null ? value.usuario.nome.indexOf(this.state.search) >= 0 : '') ||
            (value.usuario !== null ? value.usuario.sobrenome.indexOf(this.state.search) >= 0 : '') ||
            value.cod_chat.indexOf(this.state.search) >= 0);
    }
    consulta_atendente(value) {
        return value.atendente !== null && value.atendente.id === this.state.atendente.id;
    }

    consulta_departamento(value) {
        return value.departamento !== null && value.departamento === this.state.departamento.flag;
    }

    listagem_atendentes = () => {
        var chats = this.state.chat;

        if(this.state.search !== ''){
            chats = chats.filter(this.consulta.bind(this));
        }

        if(this.state.esperando_atendente === true){
            chats = chats.filter(this.chat_em_fila.bind(this));
        }

        if(this.state.atendente !== null){
            chats = chats.filter(this.consulta_atendente.bind(this));
        }

        if(this.state.departamento !== null){
            chats = chats.filter(this.consulta_departamento.bind(this));
        }

        console.log(chats);
        return chats;
    }
    render() {
        return (
            <Div>
                <h3>Monitoramento de chat</h3>
                <small>Bem vindo ao Monitoramento de Chat, aqui você pode ver todos os chats em andamento</small>
                <hr />
                <Card hidden={!getUser().permissao_monitoramento_chat} style={{ minHeight: '100%' }}>
                    <CardBody style={{ height: '100%' }}>
                        <Row style={{ minHeight: '76vh' }}>
                            <Col md={3} style={{ overflow: 'auto', maxHeight: '76vh' }}>
                                <Input 
                                    placeholder="Buscar Chat"
                                    name="search"
                                    style={{marginBottom: 5}}
                                    value={this.state.search}
                                    onChange={this.handleInputChange} />
                                <AsyncSelect
                                    style={{width: '100%'}}           
                                    cacheOptions
                                    isClearable
                                    loadOptions={this.loadOptions}
                                    defaultOptions
                                    placeholder="Buscar por Atendente"
                                    onChange={(atendente) => this.setState({atendente})}
                                    onInputChange={this.handleInputChangeSelect}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            boxShadow: "none"
                                        })
                                    }}
                                    theme={theme => ({
                                        ...theme
                                    })}
                                />
                                <div style={{marginTop: 5}}>
                                    <Select
                                        className="react-select"
                                        options={this.state.list_departamentos}
                                        placeholder="Buscar por Departamento"
                                        isSearchable={false}
                                        isClearable
                                        value={this.state.departamento}
                                        onChange={(departamento) => this.setState({departamento})}
                                        theme={theme => ({
                                            ...theme
                                        })}
                                    />

                                </div>
                                <div style={{textAlign: 'right', marginTop: 3}}>
                                    <Input 
                                        name="esperando_atendente"
                                        type="checkbox"
                                        value={this.state.esperando_atendente}
                                        onChange={(event) => this.setState({esperando_atendente: event.currentTarget.checked})}/>Chats em fila
                                </div>
                                <hr/>
                                <Nav tabs>
                                    <NavLink className={classnames({ active: this.state.activeTabTwo === "1" })}
                                            onClick={() => { this.setState({ activeTabTwo: "1" }); }}>
                                        <RiCustomerService2Fill style={{color: '#52c565'}}/>Em Andamento
                                    </NavLink>
                                    <NavLink className={classnames({ active: this.state.activeTabTwo === "2" })}
                                            onClick={() => { this.setState({ activeTabTwo: "2" }); }}>
                                        <RiCustomerService2Fill style={{color: '#52c565'}}/>Finalizados / Perdidos
                                    </NavLink>
                                </Nav>

                                <p hidden={this.state.loading === false}>Carregando mensagens ...</p>
                                <p hidden={this.state.loading === true || this.state.chat.length > 0}>Nenhum chat encontrado</p>
                                
                                <TabContent activeTab={this.state.activeTabTwo}>
                                    <TabPane tabId={'1'}>
                                        {this.listagem_atendentes().map((p, index) => {
                                            if(p.status_chat != 2 && p.status_chat != 3){
                                                return <ChatItemContainner
                                                    cod_chat_current={this.state.current_message}
                                                    cod_chat={p.cod_chat}
                                                >
                                                    <ChatItem
                                                        key={index}
                                                        avatar={p.atendente !== null ? p.atendente.avatar_foto: 'https://files.zohopublic.com/public?x-service=LiveDesk&event-id=1606357595010_604373000000002052&x-cli-msg=%7B%22x-siq-filetype%22%3A%22fgravatar%22%2C%22x-siq-lsid%22%3A%22604373000000002052%22%2C%22x-siq-soid%22%3A%22730799669%22%2C%22x-siq-ispreview%22%3Afalse%2C%22x-siq-pfname%22%3A%22logo-chat.png%22%2C%22x-siq-downloadtype%22%3A%22default%22%7D'}
                                                        avatarFlexible={false}
                                                        statusColor={p.fila ? "#dd85ff" : null}
                                                        statusColorType={p.fila ? "badge" : 'badge'}
                                                        statusText={p.fila ? p.fila : null}
                                                        alt={'Reactjs'}
                                                        title={p.atendente !== null ? p.atendente.name : p.status_chat === 3 ? <p style={{color: 'red', marginBottom: 0}}>Chat Perdido</p> : 'Esperando atendente'}
                                                        subtitle={p.usuario !== null ? p.usuario.nome + ' '+ p.usuario.sobrenome : 'Cliente não identificado'}
                                                        unread={p.messages_not_read_count}
                                                        onClick={() => this.onMessageCurrent(p.cod_chat)}
                                                        date={new Date(p.last_message !== null ? p.last_message.updated_at : p.updated_at)} />
                                                </ChatItemContainner>
                                            }
                                            return null;
                                        })}
                                    </TabPane>
                                    <TabPane tabId={'2'}>
                                        {this.listagem_atendentes().map((p, index) => {
                                            if(p.status_chat == 2 || p.status_chat == 3){
                                                return <ChatItemContainner
                                                    cod_chat_current={this.state.current_message}
                                                    cod_chat={p.cod_chat}
                                                >
                                                    <ChatItem
                                                        key={index}
                                                        avatar={p.atendente !== null ? p.atendente.avatar_foto: 'https://files.zohopublic.com/public?x-service=LiveDesk&event-id=1606357595010_604373000000002052&x-cli-msg=%7B%22x-siq-filetype%22%3A%22fgravatar%22%2C%22x-siq-lsid%22%3A%22604373000000002052%22%2C%22x-siq-soid%22%3A%22730799669%22%2C%22x-siq-ispreview%22%3Afalse%2C%22x-siq-pfname%22%3A%22logo-chat.png%22%2C%22x-siq-downloadtype%22%3A%22default%22%7D'}
                                                        avatarFlexible={false}
                                                        statusColor={p.fila ? "#dd85ff" : null}
                                                        statusColorType={p.fila ? "badge" : 'badge'}
                                                        statusText={p.fila ? p.fila : null}
                                                        alt={'Reactjs'}
                                                        title={p.atendente !== null ? p.atendente.name : p.status_chat === 3 ? <p style={{color: 'red', marginBottom: 0}}>Chat Perdido</p> : 'Esperando atendente'}
                                                        subtitle={p.usuario !== null ? p.usuario.nome + ' '+ p.usuario.sobrenome : 'Cliente não identificado'}
                                                        unread={p.messages_not_read_count}
                                                        onClick={() => this.onMessageCurrent(p.cod_chat)}
                                                        date={new Date(p.last_message !== null ? p.last_message.updated_at : p.updated_at)} />
                                                </ChatItemContainner>
                                            }
                                            return null;
                                        })}
                                    </TabPane>
                                </TabContent>
                            </Col>
                            <Col md={9}>
                                <Card style={{ height: '100%', maxHeight: '76vh', backgroundColor: '#343a400d' }} hidden={this.state.current_message !== null}>
                                    <CardBody>
                                        <center>
                                            <img src="https://files.zohopublic.com/public?x-service=LiveDesk&event-id=1606357595010_604373000000002052&x-cli-msg=%7B%22x-siq-filetype%22%3A%22fgravatar%22%2C%22x-siq-lsid%22%3A%22604373000000002052%22%2C%22x-siq-soid%22%3A%22730799669%22%2C%22x-siq-ispreview%22%3Afalse%2C%22x-siq-pfname%22%3A%22logo-chat.png%22%2C%22x-siq-downloadtype%22%3A%22default%22%7D" alt="logotipo" />
                                            <br /><h4>Aqui aparecerá suas mensagens</h4>
                                        </center>
                                    </CardBody>
                                </Card>
                                {this.state.current_message !== null ?
                                    <ChatAtendimento
                                        type_chat="monitoramento"
                                        cod_chat={this.state.current_message}
                                    />
                                :<></>}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <div hidden={getUser().permissao_monitoramento_chat}>
                  <Card>
                      <CardBody>
                          <p>Você não tem permissões para acessar essa função</p>
                      </CardBody>
                  </Card>
                </div>
            </Div>
        )
    }
}
export default MonitoramentoChat;