import React, {Component} from 'react';
import { CardBody, Card } from "reactstrap";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import AddAdmin from '../../components/Modals/AdminModals/AddAdmin';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { delete_admin } from '../../auth/services';
import ModalDialog from '../../components/Modals/ReturnsApiModals/ModalDialog';
import PropagateLoader from "react-spinners/PropagateLoader";
import TableAdmin from '../../components/Tables/TableAdmin/TableAdmin';
import { getRows, getRowsPerPage } from '../../components/Tables/TableAdmin/propsAdmin';
import { getPermissoes, getToken } from '../../auth/set_services';
import { ENDPOINT } from '../../auth/endpoint';
import axios from 'axios';
//import S3 from 'react-aws-s3';
//import { aws4Interceptor } from "aws4-axios";

const updateStatus = (id, status, onData) => {
    var url = ENDPOINT + "admin/update_status";

    var formData = new FormData();
    formData.append('id', id);
    formData.append('status', status);

    axios.post(url, formData, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getToken()
        }
    })
        .then((response) => {
            var data = response.data;
            onData(data);
            return data;
        })
        .catch((error) => {
            if (error.message === 'Network Error') {
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
            var data = error.response.data;
            onData(data);
            return data;
        });

}

/* const getProfile = (avatar_foto, image) => {
    var url = ENDPOINT + "admin/get_profile";
  
    var formData = new FormData();
    formData.append('url', avatar_foto);

    axios.post(url, formData, {
        responseType: 'blob',
        headers: {
            Authorization: 'Bearer '+getToken(),
        }
    })
    .then((response) => {
        //var file = new File([response.data], 'teste');
        var objectURL = URL.createObjectURL(response.data);
        image = objectURL;
        console.log(image);

    })
    .catch((error) => {
        
    });
  } */
class UserAdmin extends Component{
    constructor(props){
        super(props);
        this.state = {
            rowData: [],
            visible_store: false,
            visible_delete: false,
            loading: false,
            userEdit: null,
            error_message: '',
            success_message: '',
            deleteId: false,
            totalPage: 0,
            activePage: 1,
            search: '',
            loading_table: true,
        }
        this.addAdmin = this.addAdmin.bind(this);
        this.updateStatusAdmin = this.updateStatusAdmin.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.callbackRows = this.callbackRows.bind(this);
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value, loading_table: true});
        getRows(this.state, this.callbackRows);
    }
    
    addAdmin(value, user) {
        if(!user){
            user = null;
        }
        
        if(value === false){
            getRows(this.state, this.callbackRows);
        }
        this.setState({
            visible_store: value,
            userEdit: user
        })
    }

    deleteAdmin(value){
        if(value === true){
            this.setState({
                loading: true
            })
            delete_admin(this.state.deleteId, this.onDelete.bind(this));
        }
        else{
            this.setState({
                visible_delete: false,
                deleteId: null,
                loading: false,
                error_message: '',
                success_message: '',
            })
        }
    }

    onError(){
        this.setState({
            loading: false,
            error_message: ''
        })
    }

    componentDidMount(){
        getRows(this.state, this.callbackRows);
    }
    
    onPageChange(page){
        this.setState({
            activePage: page,
            loading_table: true
        });

        getRowsPerPage(page, this.state, this.callbackRows);
    }

    callbackRows(rowData, totalPage){
        this.setState({
            rowData, 
            totalPage, 
            loading_table: false})
    }


    onDelete(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
            toast.success(data.message);
        }
        else{
            this.setState({
                error_message: data.errors
            })
            toast.error(data.errors);
        }
        getRows(this.state,this.callbackRows);

    }
    deleteShow(row){
        this.setState({
            deleteId: row.id,
            visible_delete: true
        })
    }
    updateStatusAdmin(id, status) {
        updateStatus(id, status, this.onToasty.bind(this));
    }

    onToasty(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
            toast.success(data.message);
        }
        else{
            this.setState({
                error_message: data.errors
            })
            toast.error(data.errors);
        }
        getRows(this.state, this.callbackRows);
    }

    
    ExpandableRows = ({data}) => {
        return <div style={{margin: '20px'}}>
                <img src={data.avatar_foto} alt="avatar_foto" />
                <p><b>Nome:</b> {data.name}</p>
                <p><b>E-mail:</b> {data.email}</p>
                <p><b>Telefone:</b> {data.telefone}</p>
                <p><b>Status:</b> {data.status}</p>
                <br/>
        </div>
    }

    render(){
        return(
            <div>
                <h3>Operadores</h3>
                <hr/>
                <Card>
                    <TableAdmin
                        isSearch={this.handleChange}
                        showAdmin={this.addAdmin}
                        columns={this.state.columns}
                        updateStatusAdmin={this.updateStatusAdmin}
                        data={this.state.rowData}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhum usuário encontrado
                            </div></CardBody></Card>
                        }
                        delete={this.deleteShow.bind(this)}
                        /* expandableRowsComponent={<this.ExpandableRows />} */
                        onPageChange={this.onPageChange}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                    />
                </Card>
                <ModalDialog
                    visible={this.state.visible_delete}
                    onError={this.onError.bind(this)}
                    message="Deseja excluir esse usuário? Essa operação não poderá ser desfeita"
                    onClose={() => this.deleteAdmin(false)}
                    error_message={this.state.error_message}
                    loading={this.state.loading}
                    onSave={() => this.deleteAdmin(true)}
                    success_message={this.state.success_message}
                />
                <AddAdmin 
                    visible={this.state.visible_store}
                    editing={this.state.userEdit}
                    onClose={() => this.addAdmin(false)} />
            </div>
        )
    }
}

export default UserAdmin;