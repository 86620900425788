import axios from 'axios';
import moment from 'moment';
import { FaEdit } from 'react-icons/fa';
import { HiViewGrid } from 'react-icons/hi';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import { ENDPOINT } from '../../../../auth/endpoint';
import { getToken } from '../../../../auth/set_services';
import { fetchWithTimeout } from '../../../Tools/fetchWithTimeout';

export const columns = (props, ruleSellerData) => [
    {
      name: "",
      selector: "buttons",
      sortable: false,
      width: '10%',
      cell: row => <Row>
        <Button
          outline
          style={{ padding: 2, marginLeft: 15, border: 0, fontWeight: '700'}}
          color="dark" onClick={() => 
            {
              ruleSellerData(row)
              // window.location.href = '/aqenvio-configuracao-detalhes/'+row.id
            }
          }>
          <FaEdit style={{ padding: '2px' }}/>
        </Button>
      </Row>
    },
    {
      name: "Cliente",
      selector: "seller_name",
      sortable: false,
      width: '40%',
    },
    {
      name: "Documento",
      selector: "documento",
      sortable: false,
      width: '25%',
      cell: row => <Row>
          <Col md={12}>
            <NumberFormat 
                displayType={'text'}
                value={row.seller_document}
              />
          </Col>
      </Row>
    },
    {
      name: "Email",
      selector: "email",
      sortable: false,
      width: '25%',
      cell: row => <Row>
        <Col md={12}>
            {row.seller_email}
        </Col>
      </Row>
    },
];

var numberOfAjaxCAllPending = 0;

export const getRows = (state, callback) => {
  if(numberOfAjaxCAllPending == 0){
    numberOfAjaxCAllPending = 1;
    var url = ENDPOINT + "aqenvio/list_sellers?page=" + state.activePage + "&search=" + state.search;
    //var url = "http://localhost:8000/api/aqenvio/list_sellers?page=" + state.activePage + "&search=" + state.search;

    if(state.search_seller_email !== undefined){
      url += "&seller_email="+state.search_seller_email;
    }        
    if(state.search_seller_name !== undefined){
      url += "&seller_name="+state.search_seller_name;
    }      
    
    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        },
        mode: 'cors',
        cache: 'default'
    })
    .then((response) => {

        let p = response.data;
        if(p.success == true){
          var meta = 0;
          if(p.meta){
            meta = p.meta.last_page;
          }
          numberOfAjaxCAllPending = 0;
          // callback(p.data, meta, p.valor);
          callback(p.data, meta);
        }
        else{
          toast.error(p.errors);
        }
    })
    .catch((error) => {
        toast.error('Ocorreu um erro no servidor!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);;
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          //console.log(error.config);

      callback({}, {});
    })
  }
  else{
    //callback(state.rowData, state.totalPage, []);
  }
}

export const getRowsPerPage = (page, state, callback) => {
  if(numberOfAjaxCAllPending == 0){
    numberOfAjaxCAllPending = 1;

    var url = ENDPOINT + "aqenvio/list_sellers?page=" + state.activePage + "&search=" + state.search;
    //var url = "http://127.0.0.1:8000/api/aqenvio/list_sellers?page=" + state.activePage + "&search=" + state.search;

    if(state.search_seller_email !== undefined){
      url += "&seller_email="+state.search_seller_email;
    }        
    if(state.search_seller_name !== undefined){
      url += "&seller_name="+state.search_seller_name;
    }   

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());
    
    var myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };

    fetchWithTimeout(20000, fetch(url, myInit))
    .then(response => response.json())
    .then((p) => {
      if(p.success == true){
        var meta = 0;
        if(p.meta){
          meta = p.meta.last_page;
        }
        numberOfAjaxCAllPending = 0;
        // callback(p.data, meta, p.valor);
        callback(p.data, meta);
      }
      else{
        toast.error(p.errors);
      }
    })
    .catch((error) => {
      toast.error('Ocorreu um erro no servidor!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      
      callback({}, {});
    })
  }
  else{
    //callback(state.rowData, state.totalPage, []);
  }
}