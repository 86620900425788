import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import Moment from 'moment';
import { Button } from 'reactstrap';

export const getStatusTaggy = (value) => {
  switch(value){
       case 'aguardando_envio': return 'Aguardando envio';
       case 'aguardando_pagamento': return 'Aguardando pagamento';
       case 'inativo': return 'Aguardando ativação';
       case 'cancelado': return 'Cancelado';
       case 'ativado': return 'Ativado';
       case 'bloqueado': return 'Bloqueado';
       default: return value;
  }
} 
const forma_pagamento = [
    { value: "pix", label: "PIX" },
    { value: "boleto", label: "Boleto" },
    { value: "credit", label: "Crédito" },
    { value: "none", label: "Pagamento não necessario" },
];

export const columns = (onShowDelete) => [
    {
      name: "Data da aqsição",
      selector: "created_at",
      sortable: false,
      width: '150px',
      cell: p => Moment(p.created_at).format('DD/MM/YYYY HH:mm')
    },
    {
      name: "Transação",
      selector: "id_transacao",
      sortable: false,
      width: '270px',
      cell: p => <div style={{margin: 5, width: '100%'}}>
                    <center>
                      {p.user !== null ?
                          p.user.nome + ' '+p.user.sobrenome+" / "+(p.user.cnpj ? p.user.cnpj : p.user.cpf)
                      :null}
                      {p.pagamento !== null ? <>
                        <p>
                          <b>ID da Transação: </b>{p.pagamento.api_transacao_id}<br/>
                          <b>Valor: </b>R$ {parseFloat(p.pagamento.valor).toFixed(2)} - <b>{p.pagamento.status.toUpperCase()}</b><br/>
                          <b>Data da Venda: </b> {Moment(p.pagamento.created_at).format('DD/MM/YYYY HH:mm')}
                        </p>
                      </> 
                      : 
                        <p>SEM INFORMAÇÕES SOBRE PAGAMENTO</p>
                      }
                    </center>
                    <hr style={{margin: 5}}/>
                    <center>
                    {onShowDelete ? 
                        <Button
                          outline
                          style={{padding: 5, margin: 5, fontSize: '13px'}} 
                          color="danger"
                          onClick={() => onShowDelete(p.id)}
                        >
                          Deletar Pedido
                        </Button> 
                      : 
                      <></>}
                        <Button 
                            style={{padding: 5, margin: 5, fontSize: '13px'}} 
                            color="info"
                            outline
                            onClick={() => 
                                {
                                    window.open('/aqpasso-detalhes/'+p.id, '_blank')
                                }
                            }
                            >Detalhes</Button>
                    </center>
                </div>
    },
    {
      name: "Serial da Taggy",
      selector: "tag_serial",
      sortable: false,
      cell: p => <>{p.tag_serial == '0000000000000000' ? 'Não Atribuido' : p.tag_serial} <br/> {p.veiculo_placa}</>
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
      cell: p => getStatusTaggy(p.status)
    },
];

export const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "taggy/list?search="+state.search+"&page="+state.activePage;

    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&cpf="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&cnpj="+document;
        }
    }

    if(state.forma_pagamento !== null){
        url += '&forma_pagamento=' + state.forma_pagamento.value;
    }

    if(state.placa.length > 0){
        url += '&placa=' + state.placa;
    }

    if (state.status_pagamento !== null) {
        url += '&status_pagamento=' + state.status_pagamento.value;
    }

    if (state.status_tag !== null) {
        url += '&status_tag=' + state.status_tag.value;
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    var url = ENDPOINT + "taggy/list?search="+search+"&page="+page;

    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&cpf="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&cnpj="+document;
        }
    }

    if(state.forma_pagamento !== null){
        url += '&forma_pagamento=' + state.forma_pagamento.value;
    }

    if(state.placa.length > 0){
        url += '&placa=' + state.placa;
    }

    if (state.status_pagamento !== null) {
        url += '&status_pagamento=' + state.status_pagamento.value;
    }

    if (state.status_tag !== null) {
        url += '&status_tag=' + state.status_tag.value;
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}