import axios from "axios";
import React, { Component } from "react";
import { Button, Card, Col, FormGroup, Label, Modal, ModalFooter, ModalHeader, Input, Row, FormFeedback, CustomInput } from 'reactstrap';
import styled from "styled-components";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import { InputText } from '../../InputText/InputText';
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import Dropzone from 'react-dropzone';
import { MdAttachFile, MdClear } from "react-icons/md";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import { list_planos } from "../../../auth/listas";
import { FaCheck, FaExclamationTriangle, FaRegWindowClose } from "react-icons/fa";
import iconExclamation from '../../../assets/ico-exclamation.png';
import NumberFormat from "react-number-format";
import ReactScoreIndicator from "react-score-indicator";



const ModalBody = styled.div`
    .svg-inline--fa {
        width: 10em;
    }
    .svg-inline--fa.fa-w-14{
        width: 10em;
    }
    .prosemirror-toolbar-item{
        color: #52c565;
    }
    .prosemirror-toolbar-item[data-active='true'] {
        background-color: #52c565;
        color: #fff;
    }

    .rdw-option-wrapper{
        border: 0
    }
    .rdw-option-wrapper:active{
        background-color: #aaaaaa;
        box-shadow: none;
    }
    .rdw-option-wrapper:hover {
        background-color: #aaaaaa;
    }

    .rdw-dropdown-wrapper{
        border: 1px solid #ced4da;
    }
    .rdw-dropdown-wrapper:active{
        border: 1px solid #ced4da;
        box-shadow: none;
    }
    .rdw-dropdown-wrapper:hover{
        border: 1px solid #ced4da;
        box-shadow: none;
    }

    .rdw-dropdown-selectedtext{
        color: black
    }
    .rdw-dropdown-selectedtext:hover{
        text-decoration: none;
        color: black
    }
    .div-align {
        width: 40%;
        margin: 0px auto;
    }
    .div-align-text {
        text-align: center;
    }
    .modal-icon {
        width: 10rem;
        height: 8rem;
        padding: 1rem;
        border-radius: 50%;
        border: 5px solid #f1f3f1!important;
        box-shadow: 0px 3px 25px 0px rgb(113 106 202 / 20%);
    }
    h6 {
        display: block;
        position: relative;
        max-width: 100%;
        margin: 0 0 0.4em;
        padding: 0;
        color: #3b3f5c;
        font-size: 1.875em;
        font-weight: 600;
        text-align: center;
        text-transform: none;
        word-wrap: break-word; 
    }
    .span-info {
        justify-content: center;
        margin: 0;
        padding: 0;
        color: #e95f2b;
        font-size: 1.125em;
        font-weight: 300;
        line-height: normal;
    }
`;

class ConsultaLocalizaDataView extends Component{
    constructor(props){
        super(props);
        this.state = {
            assinaturas_email: [],
        }
        this.onClose = this.onClose.bind(this); 
        //this.props = props; 
    }

    onClose(){
        this.props.onClose(false);
    }

    callback(){
        this.props.onClose(false);
        this.props.callback();
    }

    render(){
        if (this.props.consulta_view == undefined || this.props.consulta_view.length == 0) {
            return(
                <Modal isOpen={this.props.visible}  size={'lg'}>
                    <ModalBody className="modal-body" hidden={this.state.loading}>
                        <h2>Consulta não localizada!</h2>
                    </ModalBody>
                    <ModalFooter hidden={this.state.loading}>
                        <Button color="danger" onClick={() => this.onClose(false)}><FaRegWindowClose /> Fechar</Button>
                    </ModalFooter>
                </Modal>
            );
        } else {
            let documento = '';

            if (this.props.consulta_view.payload.resposta.dadosCadastrais.cpf !== undefined) {
                documento = this.props.consulta_view.payload.resposta.dadosCadastrais.cpf;
            }

            if (this.props.consulta_view.payload.resposta.dadosCadastrais.cnpj !== undefined) {
                documento = this.props.consulta_view.payload.resposta.dadosCadastrais.cnpj;
            }

            return(
                <Modal isOpen={this.props.visible}  size={'lg'}>
                    <ModalHeader>
                        <Button 
                            color="danger" 
                            onClick={() => this.onClose(false)}><FaRegWindowClose /> Fechar</Button>
                    </ModalHeader>
                    <ModalHeader>
                        <h5>
                            {(this.props.consulta_view.payload.resposta.dadosCadastrais.nome !== undefined) ? this.props.consulta_view.payload.resposta.dadosCadastrais.nome : ''}
                        </h5>
                    </ModalHeader>
                    <ModalBody className="modal-body" hidden={this.state.loading}>

                        <Row>
                            <Col md={12}>
                                <div style={{
                                    overflow: 'auto',
                                    padding: '0.5rem', 
                                    border: '1px solid #dee2e6', 
                                    borderRadius: 5
                                    }}>
                                    <h5>Dados Cadastrais</h5><hr/>
                                    <p><b>CPF/CNPJ:</b> {documento}</p>
                                    {this.props.consulta_view.payload.resposta.dadosCadastrais.dataNascimento !== undefined ? 
                                        <p><b>Nascimento:</b> {this.props.consulta_view.payload.resposta.dadosCadastrais.dataNascimento}</p>
                                    :
                                        ''
                                    }                        
                                    {this.props.consulta_view.payload.resposta.dadosCadastrais.situacaoCadastral !== undefined ? 
                                        <p><b>Situação Cadastral:</b> {this.props.consulta_view.payload.resposta.dadosCadastrais.situacaoCadastral}</p>
                                    :
                                        ''
                                    }
                                    {this.props.consulta_view.payload.resposta.dadosCadastrais.dataSituacaoCadastral !== undefined ? 
                                        <p><b>Data da Situação:</b> {this.props.consulta_view.payload.resposta.dadosCadastrais.dataSituacaoCadastral}</p>
                                    :
                                        ''
                                    }                        
                                    {this.props.consulta_view.payload.resposta.dadosCadastrais.idade !== undefined ? 
                                        <p><b>Idade:</b> {this.props.consulta_view.payload.resposta.dadosCadastrais.idade}</p>
                                    :
                                        ''
                                    }                        
                                    {this.props.consulta_view.payload.resposta.dadosCadastrais.maeNome !== undefined ? 
                                        <p><b>Mãe:</b> {this.props.consulta_view.payload.resposta.dadosCadastrais.maeNome}</p>
                                    :
                                        ''
                                    }                        
                                    {this.props.consulta_view.payload.resposta.dadosCadastrais.obitoProvavel !== undefined ? 
                                        <p><b>Obito provável:</b> {this.props.consulta_view.payload.resposta.dadosCadastrais.obitoProvavel == "false" ? "não" : "sim"}</p>
                                    :
                                        ''
                                    }
                                    {this.props.consulta_view.payload.resposta.dadosCadastrais.sexo !== undefined ? 
                                        <p><b>Sexo:</b> {this.props.consulta_view.payload.resposta.dadosCadastrais.sexo}</p>
                                    :
                                        ''
                                    }
                                    {this.props.consulta_view.payload.resposta.dadosCadastrais.signo !== undefined ? 
                                        <p><b>Signo:</b> {this.props.consulta_view.payload.resposta.dadosCadastrais.signo}</p>
                                    :
                                        ''
                                    }
                                </div>
                            </Col>
                            
                        </Row>
                        {this.props.consulta_view.payload.resposta.participacoesEmpresas !== undefined &&
                        this.props.consulta_view.payload.resposta.participacoesEmpresas.length > 0 ?
                            <Row style={{marginBottom: '1rem'}}>
                                <Col md={12}>
                                    <div style={{
                                        overflow: 'auto',
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                        <h5>Participações em Empresas</h5><hr/>
                                        {this.props.consulta_view.payload.resposta.participacoesEmpresas.map((empresa, i) => {
                                            return (
                                                <>
                                                <p><b>Razão Social:</b> {empresa.razaoSocial !== undefined ? empresa.razaoSocial : ''}</p>
                                                <p><b>CNPJ:</b> {empresa.cnpj !== undefined ? empresa.cnpj : ''}</p>
                                                <p><b>Data de entrada:</b> {empresa.dataEntrada !== undefined ? empresa.dataEntrada : ''}</p>
                                                </>
                                            )
                                        })}
                                    </div>
                                </Col>
                            </Row>
                        :
                            <></>
                        }                        
                        
                        {this.props.consulta_view.payload.resposta.possivelHistoricoProfissional !== undefined &&
                        this.props.consulta_view.payload.resposta.possivelHistoricoProfissional.length > 0 ?
                            <Row style={{marginBottom: '1rem'}}>
                                <Col md={12}>
                                    <div style={{
                                        overflow: 'auto',
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                        <h5>Possível Histórico Profissional</h5><hr/>
                                        {this.props.consulta_view.payload.resposta.possivelHistoricoProfissional.map((empresa, i) => {
                                            return (
                                                <>
                                                <p><b>Razão Social:</b> {empresa.razaoSocial !== undefined ? empresa.razaoSocial : ''}</p>
                                                <p><b>CNPJ:</b> {empresa.cnpj !== undefined ? empresa.cnpj : ''}</p>
                                                <p><b>Setor:</b> {empresa.setor !== undefined ? empresa.setor : ''}</p>
                                                <p><b>Descrição:</b> {empresa.cboDescricao !== undefined ? empresa.cboDescricao : ''}</p>
                                                <p><b>Faixa Salarial:</b> {empresa.faixaSalarial !== undefined ? empresa.faixaSalarial : ''}</p>
                                                </>
                                            )
                                        })}
                                    </div>
                                </Col>
                            </Row>
                        :
                            <></>
                        }   

                        {this.props.consulta_view.payload.resposta.historicoConsultasPorSegmento.segmentos !== undefined &&
                        this.props.consulta_view.payload.resposta.historicoConsultasPorSegmento.segmentos.length > 0 ?
                            <Row style={{marginBottom: '1rem'}}>
                                <Col md={12}>
                                    <div style={{
                                        overflow: 'auto',
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                        <h5>Histórico Por Segmento</h5><hr/>
                                        {this.props.consulta_view.payload.resposta.historicoConsultasPorSegmento.segmentos.map((empresa, i) => {
                                            return (
                                                <>
                                                <p><b>Segmento:</b> {empresa.segmento !== undefined ? empresa.segmento : ''}</p>
                                                <p><b>Quantidade:</b> {empresa.quantidade !== undefined ? empresa.quantidade : ''}</p>
                                                </>
                                            )
                                        })}
                                    </div>
                                </Col>
                            </Row>
                        :
                            <></>
                        }

                        {this.props.consulta_view.payload.resposta.comentarios !== undefined &&
                        this.props.consulta_view.payload.resposta.comentarios.length > 0 ?
                            <Row style={{marginBottom: '1rem'}}>
                                <Col md={12}>
                                    <div style={{
                                        overflow: 'auto',
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                        <h5>Comentários</h5><hr/>
                                        {this.props.consulta_view.payload.resposta.comentarios.map((comentario, i) => {
                                            return (
                                                <>
                                                <p><b>Comentario:</b> {comentario.comentario !== undefined ? comentario.comentario : ''}</p>
                                                <p><b>Criador:</b> {comentario.criadoPor.usuario !== undefined ? comentario.criadoPor.usuario : ''}</p>
                                                <p><b>Data:</b> {comentario.criadoPor.dataHora !== undefined ? comentario.criadoPor.dataHora : ''}</p>
                                                </>
                                            )
                                        })}
                                    </div>
                                </Col>
                            </Row>
                        :
                            <></>
                        }

                        <Row style={{marginTop: '1rem', marginBottom: '1rem'}}>
                            <Col md={12}>
                                <div style={{
                                overflow: 'auto',
                                padding: '0.5rem', 
                                border: '1px solid #dee2e6', 
                                borderRadius: 5
                                }}>
                                    <h5>Emails</h5><hr/>

                                    {this.props.consulta_view.payload.resposta.emails !== undefined &&
                                    this.props.consulta_view.payload.resposta.emails.length > 0 ?
                                        
                                        this.props.consulta_view.payload.resposta.emails.map((email, i) => {
                                            return(
                                                <>
                                                {email.email !== undefined ? 
                                                    <div>
                                                        <p>{email.email}</p>
                                                    </div>
                                                :
                                                    <></>
                                                }
                                                </>
                                            )
                                        })
                                    :
                                        <p>Nenhum email encontrado</p>
                                    }

                                    {this.props.consulta_view.payload.resposta.emailsAdicionados !== undefined &&
                                    this.props.consulta_view.payload.resposta.emailsAdicionados.length > 0 ?
                                        
                                        this.props.consulta_view.payload.resposta.emailsAdicionados.map((email, i) => {
                                            return(
                                                <>
                                                {email.email !== undefined ? 
                                                    <div>
                                                        <p>{email.email}</p>
                                                    </div>
                                                :
                                                    <></>
                                                }
                                                </>
                                            )
                                        })
                                    :
                                        <p>Nenhum email encontrado</p>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {this.props.consulta_view.payload.resposta.telefones.fixos !== undefined &&
                            this.props.consulta_view.payload.resposta.telefones.fixos.length > 0 ?
                                
                                this.props.consulta_view.payload.resposta.telefones.fixos.map((telefone, i) => {
                                    return(
                                        <Col md={6}>
                                            <div style={{
                                            overflow: 'auto',
                                            padding: '0.5rem', 
                                            border: '1px solid #dee2e6', 
                                            borderRadius: 5
                                            }}>
                                                <h5>Telefone fixo</h5><hr/>
                                                {telefone.numero !== undefined ? 
                                                    <div>
                                                        <p><b>Número:</b> {telefone.numero}</p>
                                                        <p><b>Relação:</b> {telefone.relacao}</p>
                                                        <p><b>Não pertube:</b> {telefone.naoPerturbe == false ? 'Não' : 'Sim'}</p>
                                                        <p><b>WhatsApp Business:</b> {telefone.aplicativos.whatsAppBusiness == false ? 'Não' : 'Sim'}</p>
                                                    </div>
                                                :
                                                    <></>
                                                }
                                            </div>
                                        </Col>
                                    )
                                })
                            :
                                <></>
                            }

                            {this.props.consulta_view.payload.resposta.telefones.moveis !== undefined &&
                            this.props.consulta_view.payload.resposta.telefones.moveis.length > 0 ?
                                
                                this.props.consulta_view.payload.resposta.telefones.moveis.map((telefone, i) => {
                                    return(
                                        <Col md={6}>
                                        <div style={{
                                        overflow: 'auto',
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                            <h5>Celular</h5><hr/>
                                            {telefone.numero !== undefined ? 
                                                <div>
                                                    <p><b>Número:</b> {telefone.numero}</p>
                                                    <p><b>Relação:</b> {telefone.relacao}</p>
                                                    <p><b>Não pertube:</b> {telefone.naoPerturbe == false ? 'Não' : 'Sim'}</p>
                                                    <p><b>WhatsApp:</b> {telefone.aplicativos.whatsApp == false ? 'Não' : 'Sim'}</p>
                                                </div>
                                            :
                                                <></>
                                            }
                                        </div>
                                    </Col>
                                    )
                                })
                            :
                                <></>
                            }

                            {this.props.consulta_view.payload.resposta.telefonesAdicionados.fixos !== undefined &&
                            this.props.consulta_view.payload.resposta.telefonesAdicionados.fixos.length > 0 ? 
                                this.props.consulta_view.payload.resposta.telefonesAdicionados.fixos.map((telefone, i) => {
                                    return(
                                        <Col md={6}>
                                        <div style={{
                                        overflow: 'auto',
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                            <h5>Fixo adicional</h5><hr/>
                                            {telefone.numero !== undefined ? 
                                                <div>
                                                    <p><b>Número:</b> {telefone.numero}</p>
                                                    <p><b>WhatsApp:</b> {telefone.aplicativos.whatsAppBusiness == false ? 'Não' : 'Sim'}</p>
                                                </div>
                                            :
                                                <></>
                                            }
                                        </div>
                                    </Col>
                                    )
                                })
                            :
                                <></>
                            }

                            
                            {this.props.consulta_view.payload.resposta.telefonesAdicionados.moveis !== undefined &&
                            this.props.consulta_view.payload.resposta.telefonesAdicionados.moveis.length > 0 ? 
                                this.props.consulta_view.payload.resposta.telefonesAdicionados.moveis.map((telefone, i) => {
                                    return(
                                        <Col md={6}>
                                        <div style={{
                                        overflow: 'auto',
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                            <h5>Celular adicional</h5><hr/>
                                            {telefone.numero !== undefined ? 
                                                <div>
                                                    <p><b>Número:</b> {telefone.numero}</p>
                                                    <p><b>WhatsApp:</b> {telefone.aplicativos.whatsAppBusiness == false ? 'Não' : 'Sim'}</p>
                                                </div>
                                            :
                                                <></>
                                            }
                                        </div>
                                    </Col>
                                    )
                                })
                            :
                                <></>
                            } 

                            {this.props.consulta_view.payload.resposta.feedbackTelefones.fixos !== undefined &&
                            this.props.consulta_view.payload.resposta.feedbackTelefones.fixos.length > 0 ? 
                                this.props.consulta_view.payload.resposta.feedbackTelefones.fixos.map((telefone, i) => {
                                    return(
                                        <Col md={6}>
                                        <div style={{
                                        overflow: 'auto',
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                            <h5>Feedback Telefone</h5><hr/>
                                            {telefone.numero !== undefined ? 
                                                <div>
                                                    <p><b>Número:</b> {telefone.numero}</p>
                                                    <p><b>Avaliação:</b> {telefone.avaliacao}</p>
                                                    <p><b>Justificativa:</b> {telefone.justificativa}</p>
                                                </div>
                                            :
                                                <></>
                                            }
                                        </div>
                                    </Col>
                                    )
                                })
                            :
                                <></>
                            }
                            {this.props.consulta_view.payload.resposta.feedbackTelefones.moveis !== undefined &&
                            this.props.consulta_view.payload.resposta.feedbackTelefones.moveis.length > 0 ? 
                                this.props.consulta_view.payload.resposta.feedbackTelefones.moveis.map((telefone, i) => {
                                    return(
                                        <Col md={6}>
                                        <div style={{
                                        overflow: 'auto',
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                            <h5>Feedback Celular</h5><hr/>
                                            {telefone.numero !== undefined ? 
                                                <div>
                                                    <p><b>Número:</b> {telefone.numero}</p>
                                                    <p><b>Avaliação:</b> {telefone.avaliacao}</p>
                                                    <p><b>Justificativa:</b> {telefone.justificativa}</p>
                                                </div>
                                            :
                                                <></>
                                            }
                                        </div>
                                    </Col>
                                    )
                                })
                            :
                                <></>
                            }

                        </Row>
                        <Row style={{marginTop: '1rem', marginBottom: '1rem'}}>
                            {this.props.consulta_view.payload.resposta.enderecos !== undefined &&
                            this.props.consulta_view.payload.resposta.enderecos.length > 0 ?
                                
                                this.props.consulta_view.payload.resposta.enderecos.map((endereco, i) => {
                                    return(
                                        <Col md={6}>
                                            <div style={{
                                            overflow: 'auto',
                                            padding: '0.5rem', 
                                            border: '1px solid #dee2e6', 
                                            borderRadius: 5
                                            }}>
                                                <h5>Endereço</h5><hr/>
                                                {endereco.logradouro !== undefined ? 
                                                    <div>
                                                        <p>{endereco.cep}</p>
                                                        <p>{endereco.tipoLogradouro}. {endereco.logradouro}, {endereco.numero} {endereco.complemento !== undefined && endereco.complemento !== '' ? ' - ' + endereco.complemento : ''}</p>
                                                        <p>{endereco.bairro}</p>
                                                        <p>{endereco.cidade} / {endereco.uf}</p>
                                                    </div>
                                                :
                                                    ''
                                                }
                                            </div>
                                        </Col>
                                    )
                                })
                            :
                                ''
                            }                            
                            
                            
                            {this.props.consulta_view.payload.resposta.enderecosAdicionados !== undefined &&
                            this.props.consulta_view.payload.resposta.enderecosAdicionados.length > 0 ?
                                
                                this.props.consulta_view.payload.resposta.enderecosAdicionados.map((endereco, i) => {
                                    return(
                                        <Col md={6}>
                                            <div style={{
                                            overflow: 'auto',
                                            padding: '0.5rem', 
                                            border: '1px solid #dee2e6', 
                                            borderRadius: 5
                                            }}>
                                                <h5>Endereço Adicionado</h5><hr/>
                                                {endereco.logradouro !== undefined ? 
                                                    <div>
                                                        <p>{endereco.cep}</p>
                                                        <p>{endereco.tipoLogradouro}. {endereco.logradouro}, {endereco.numero} {endereco.complemento !== undefined && endereco.complemento !== '' ? ' - ' + endereco.complemento : ''}</p>
                                                        <p>{endereco.bairro}</p>
                                                        <p>{endereco.cidade} / {endereco.uf}</p>
                                                    </div>
                                                :
                                                    ''
                                                }
                                            </div>
                                        </Col>
                                    )
                                })
                            :
                                ''
                            }
                            
                        </Row>

                    </ModalBody>
                    <ModalFooter hidden={this.state.loading}>
                        <Button color="danger" onClick={() => this.onClose(false)}><FaRegWindowClose /> Fechar</Button>
                    </ModalFooter>
                </Modal>
            );
        }
    }
}

export default ConsultaLocalizaDataView;