import React, { Component } from 'react';
import { Button, Card, CardBody, Input, Collapse, Nav, NavLink, Row, TabContent, TabPane, FormGroup, Label, InputGroup, InputGroupAddon, Col } from 'reactstrap';
import classnames from 'classnames';
import styled from 'styled-components';
import { getPermissoes, getToken } from '../../auth/set_services';
import { ENDPOINT } from '../../auth/endpoint';
import { MdArchive, MdAttachment, MdClear, MdImage, MdNote, MdSurroundSound } from 'react-icons/md';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import { list_status, list_tipos } from '../../auth/listas';
import TicketOcorrencia from '../../components/ResumeClient/TicketOcorrencia';
import { Configuracoes } from './Configuracoes';
import get_extension from 'get-url-extension';
import { ImFilePdf } from 'react-icons/im';

const GetIconExtension = (props) => {
    var extension = get_extension(props.url);
    
    if(extension === '.webp' ||
        extension === '.png' ||
        extension === '.jpg' ||
        extension === '.avg' ||
        extension === '.gif' ){
        return <MdImage />;
    }
    else if(extension === '.webm' ||
        extension === '.3gp' ||
        extension === '.ogg' ||
        extension === '.mp4'){
        return <MdSurroundSound/>;;
    }
    else if(extension === '.pdf'){
        return <ImFilePdf/>;;
    }

    return <MdArchive/>;;
}

const get_protocolo = () => {
    return window.location.pathname.replace('/ocorrencia-detalhe/', '')
}

/* const getActive = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('active');
    if(id !== null){
        return id;
    }
    return "1";

} */


const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
`

const getUser = (id, callback) => {
    var url = ENDPOINT + "ocorrencia/get/" + id;

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            callback(p.data);
        })
}

export default class ResumoOcorrencia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            nome_produto: '',
            status: '',
            status_flag: '',
            origem: '',
            descricao: '',
            tipo_ocorrencia: '',
            funcionario_atribuido: '',
            funcionario_atribuido_id: '',
            razao_ocorrencia: '',
            funcionario_criador: '',
            cliente_nome: '',
            protocolo_ocorrencia: '',
            protocolo_cliente: '',
            ultima_alteracao: '',
            funcionario_ocorrencia: '',
            imagens: [],
            alteracoes: [],
            notas_privadas: [],
            protocolo: get_protocolo(),

            departamento: '',
            departamento_flag: '',
            prazo_retorno: '',
            prazo_retorno_value: '',
            fechamento_conclusao: '',

            descricao_nota: '',
            modalNota: false,

            file: null,
            descricao_image: '',
            modalImage: false,

        }
        this.myRef = React.createRef();
        this.callbackRows = this.callbackRows.bind(this);
    }
    componentDidMount() {
        getUser(
            this.state.protocolo,
            this.callbackRows
        );
    }
    callbackRows(data) {
        this.setState({
            cliente_nome: data.cliente.nome + " " + data.cliente.sobrenome,
            nome_produto: data.produto,
            descricao: data.descricao,
            origem: data.origem_ocorrencia,
            razao_ocorrencia: data.razao_ocorrencia,
            funcionario_atribuido: data.funcionario_atribuido.name,
            funcionario_atribuido_id: data.funcionario_atribuido.id,
            funcionario_criador: data.funcionario_criador.name,
            protocolo_ocorrencia: data.protocolo_ocorrencia,
            protocolo_cliente: data.protocolo_cliente,
            imagens: data.imagens,
            alteracoes: data.alteracoes,
            notas_privadas: data.notas_privadas,
            prazo_retorno: moment(new Date(data.prazo_conclusao)).format('DD/MM/YYYY'),
            prazo_retorno_value: data.prazo_conclusao
        });

        if(data.alteracoes.length > 0){
            this.setState({
                ultima_alteracao: 'Feita por '+data.alteracoes[0].funcionario.name+' às '+moment(new Date(data.alteracoes[0].created_at)).format('DD/MM/YYYY HH:mm')
            })
        }
        if(data.departamento !== null){
            this.setState({departamento: data.departamento.nome, departamento_flag: data.departamento.flag })
        }
        else{
            this.setState({departamento: 'Sem informações' })
        }

        if(data.fechamento_conclusao !== null){
            this.setState({fechamento_conclusao: data.fechamento_conclusao })
        }
        else{
            this.setState({fechamento_conclusao: 'Sem informações' })
        }

        var status = '';
        var status_flag = '';
        var tipo_ocorrencia = '';
        list_status.forEach((p) => {
            if (p.value === data.status) {
                status = p.label;
                status_flag = p.value;
            }
        });

        list_tipos.forEach((p) => {
            if (p.value === data.tipo_ocorrencia) {
                tipo_ocorrencia = p.label
            }
        });
        this.setState({
            status,
            status_flag,
            tipo_ocorrencia
        })
    }
    listData = [
        { name: 'Informações da ocorrência', active: '1' },
        { name: 'Anexos', active: '2' },
        { name: 'Linha do Tempo', active: '3' },
        { name: 'Notas (Comentarios Internos)', active: '4' },
        { name: "Ticket's", active: '5' },
        { name: "Configurações", active: '6' }
    ]

    Navs = () => {
        return <Nav tabs>
            {this.listData.map((p, index) => {
                return <NavLink
                    key={index}
                    className={
                        classnames({ active: this.state.activeTab === p.active })}
                    onClick={() => { this.setState({ activeTab: p.active }); }}>
                    <span>{p.name}</span>
                </NavLink>
            })}
        </Nav>
    }

    handleFile = (e) => {
        if(e.target.files.length > 0){
            this.setState({
                file: e.target.files[0]
            })
        }
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        
        this.setState({ [name]: value });
    }
    
    adicionarNota = () => {
        var url = ENDPOINT + "ocorrencia/nova-nota";
        var formData = new FormData();
        formData.append('descricao', this.state.descricao_nota);
        formData.append('protocolo_ocorrencia', this.state.protocolo_ocorrencia);

        axios.post(url, formData, {
          headers: {
              Authorization: 'Bearer '+getToken()
          }})
        .then((response) => {
            var data = response.data;
            toast.success(data.message);

            getUser(this.state.protocolo, this.callbackRows);

            this.setState({
                modalNota: false,
                descricao_nota: ''
            });
        })
        .catch(() => {
            toast.error('Ocorreu um erro');
        });
    }

    adicionarImagem = () => {
        var url = ENDPOINT + "ocorrencia/nova-imagem";
        var formData = new FormData();
        formData.append('descricao', this.state.descricao_image);
        formData.append('protocolo_ocorrencia', this.state.protocolo_ocorrencia);
        formData.append('image_foto', this.state.file, this.state.file.name);

        axios.post(url, formData, {
          headers: {
              Authorization: 'Bearer '+getToken()
          }})
        .then((response) => {
            var data = response.data;
            toast.success(data.message);

            getUser(this.state.protocolo, this.callbackRows);

            this.setState({
                modalImage: false,
                descricao_image: '',
                file: null
            });
        })
        .catch(() => {
            toast.error('Ocorreu um erro');
        });
    }
    getFileExtension = (url) =>{
        var extension = get_extension(url);

        if(extension === '.webp' ||
            extension === '.png' ||
            extension === '.jpg' ||
            extension === '.avg' ||
            extension === '.gif' ){
            return 'image'
        }
        else if(extension === '.webm' ||
            extension === '.3gp' ||
            extension === '.ogg' ||
            extension === '.mp4'){
                return 'video';
        }
        else if(extension === '.pdf'){
            return 'pdf';
        }

        return 'file';
    }
    
    getColorExtension = (url) =>{
        var extension = get_extension(url);

        if(extension === '.webp' ||
            extension === '.png' ||
            extension === '.jpg' ||
            extension === '.avg' ||
            extension === '.gif' ){
            return '#828282';
        }
        else if(extension === '.webm' ||
            extension === '.3gp' ||
            extension === '.ogg' ||
            extension === '.mp4'){
                return '#828282';
        }
        else if(extension === '.pdf'){
            return '#880808';
        }

        return '#828282';
    }
    render() {
        return (
            <Div>
                <h3>Ocorrência {get_protocolo()}</h3>
                <hr />
                <Card>
                    <CardBody>
                        <this.Navs />
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId={'1'}>
                                <br />
                                <div>
                                    <h5>Informações da ocorrência</h5>
                                    <hr />
                                    <p><b>Produto:</b> {this.state.nome_produto}</p>
                                    <p><b>Status:</b> {this.state.status}</p>
                                    <p><b>Tipo de ocorrência:</b> {this.state.tipo_ocorrencia}</p>
                                    <p><b>Origem da ocorrência:</b> {this.state.origem}</p>
                                    <p><b>Descrição:</b> {this.state.descricao}</p>
                                    <p><b>Razão da Ocorrência:</b> {this.state.razao_ocorrencia}</p>
                                    <p><b>Departamento:</b> {this.state.departamento}</p>
                                    <p><b>Prazo de Retorno:</b> {this.state.prazo_retorno}</p>
                                    <p><b>Fechamento de conclusão:</b> {this.state.fechamento_conclusao}</p>
                                    <p><b>Operador atribuído:</b> {this.state.funcionario_atribuido}</p>
                                    <p><b>Cliente:</b> {this.state.cliente_nome}</p>
                                    <p><b>Protocolo da ocorrência:</b> {this.state.protocolo_ocorrencia}</p>
                                    <p><b>Protocolo do cliente:</b> {this.state.protocolo_cliente}</p>
                                    <p><b>Ocorrencia criada por:</b> {this.state.funcionario_atribuido}</p>
                                    <p><b>Ultima alteração: </b>{this.state.ultima_alteracao}</p>
                                </div>
                            </TabPane>
                            <TabPane tabId={'2'}>
                                <br />
                                <div>
                                    <h5>Anexos</h5>
                                    <hr />
                                    <Card>
                                        <CardBody>
                                            <Button 
                                                color="dark" 
                                                onClick={() => this.setState({
                                                    modalImage: !this.state.modalImage, 
                                                    descricao_image:  '', 
                                                    file: null
                                            })}><MdAttachment /> Adicionar novo anexo</Button>
                                            <Collapse isOpen={this.state.modalImage}><hr />
                                                <FormGroup>
                                                    <Label>Selecione o arquivo: &nbsp;</Label>
                                                    <input 
                                                        type="file" 
                                                        hidden 
                                                        accept=".webp,.svg,.png,.jpg,.gif,.pdf,.mp4,.avi,.3gp,.ogg"
                                                        ref={this.myRef}
                                                        onChange={this.handleFile}/>
                                                    <InputGroup>
                                                        <Input 
                                                            disabled 
                                                            placeholder={this.state.file !== null ? this.state.file.name : "Selecionar Arquivo" }
                                                            style={{height: '40px'}}/>
                                                        <InputGroupAddon addonType="prepend">
                                                            <Button 
                                                                hidden={this.state.file === null} 
                                                                onClick={() => this.setState({file: null})} 
                                                                color="danger">
                                                                    <MdClear style={{padding: 0}}/>
                                                            </Button>
                                                            <Button 
                                                                style={{
                                                                    borderBottomRightRadius: '.25rem',
                                                                    borderTopRightRadius: '.25rem'
                                                                }}
                                                                onClick={() => this.myRef.current.click()} 
                                                                color="dark">
                                                                    <MdAttachment style={{padding: 0}}/>
                                                            </Button>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                    <small>Formatos aceitos: .webp, .svg, .png, .jpg, .gif, .pdf, .mp4, .avi, .3gp, .ogg</small>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Input 
                                                        type="textarea"
                                                        placeholder="Descrição da imagem"
                                                        style={{ height: 120, resize: 'none' }}
                                                        name="descricao_image"
                                                        value={this.state.descricao_image}
                                                        onChange={this.handleInputChange} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Button color="success" onClick={() => this.adicionarImagem()} disabled={this.state.file === null}>Salvar</Button>&nbsp;
                                                    <Button color="danger" onClick={() => this.setState({modalImage: false, descricao_image:  '', file: null})}>Fechar</Button>
                                                </FormGroup>
                                            </Collapse>
                                        </CardBody>
                                    </Card>
                                    <hr />
                                    <Row>
                                        {this.state.imagens.map(p => {
                                            return <Col md={6}>
                                                <Card>
                                                    <CardBody>
                                                        <center>
                                                            {this.getFileExtension(p.url_imagem) === 'image' ? 
                                                                <img alt="ocorrencia-imagem" src={p.url_imagem+'?access_token='+getToken()} style={{ maxWidth: '100%', maxHeight: '400px' }} />
                                                            :
                                                            this.getFileExtension(p.url_imagem) === 'video' ? 
                                                                <video controls src={p.url_imagem+'?access_token='+getToken()} style={{  maxWidth: '100%', height: '400px' }} />
                                                            :
                                                                <Button 
                                                                    style={{
                                                                        backgroundColor: this.getColorExtension(p.url_imagem), 
                                                                        borderColor: this.getColorExtension(p.url_imagem)}} 
                                                                        onClick={() => window.open(p.url_imagem+'?access_token='+getToken(), '_blank')}>
                                                                        <GetIconExtension url={p.url_imagem} style={{color: 'white', padding: 0}}/> Abrir Arquivo
                                                                </Button>
                                                            }
                                                        </center>
                                                        <p><b>Descrição:</b> {p.descricao}</p>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        })}
                                    </Row>
                                    {this.state.imagens.length === 0 ? <p>Sem imagens</p> : <></>}
                                </div>
                            </TabPane>
                            <TabPane tabId={'3'}>
                                <br />
                                <div>
                                    <h5>Linha do Tempo</h5>
                                    <hr />
                                    <ul>
                                        {this.state.alteracoes.map((p) => {
                                            return <><li>
                                                {p.descricao}<br/>
                                                Operador: {p.funcionario.name}<br/>
                                                {moment(new Date(p.created_at)).format('DD/MM/YYYY HH:mm')}
                                                
                                            </li><br/></>
                                        })}
                                    </ul>
                                </div>
                            </TabPane>
                            <TabPane tabId={'4'}>
                                <br />
                                <div>
                                    <h5>Notas (Comentarios Internos)</h5>
                                    <hr />
                                    <Card>
                                        <CardBody>
                                            <Button color="success" onClick={() => this.setState({modalNota: !this.state.modalNota, descricao_nota:  ''})}><MdNote /> Adicionar nota privada</Button>
                                            <Collapse isOpen={this.state.modalNota}><hr />
                                                <FormGroup>
                                                    <Input 
                                                        type="textarea"
                                                        placeholder="Descrição da nota"
                                                        style={{ height: 120, resize: 'none' }}
                                                        name="descricao_nota"
                                                        value={this.state.descricao_nota}
                                                        onChange={this.handleInputChange} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Button color="success" onClick={() => this.adicionarNota()} disabled={this.state.descricao_nota.length === 0}>Salvar</Button>&nbsp;
                                                    <Button color="danger" onClick={() => this.setState({modalNota: false, descricao_nota:  ''})}>Fechar</Button>
                                                </FormGroup>
                                            </Collapse>
                                        </CardBody>
                                    </Card>
                                    <hr />
                                    <ul>
                                        {this.state.notas_privadas.map((p) => {
                                            return <><li>
                                                {p.descricao}<br/>
                                                Operador: {p.admin !== null ? p.admin.name : 'Sem Informações'}<br/>
                                                {moment(new Date(p.created_at)).format('DD/MM/YYYY HH:mm')}
                                                
                                            </li><br/></>
                                        })}
                                    </ul>
                                    {this.state.notas_privadas.length === 0 ? <p>Nenhuma nota encontrada</p> : <></>}
                                </div>
                            </TabPane>
                            <TicketOcorrencia
                                active={'5'}
                                isCreate
                                protocolo_ocorrencia={this.state.protocolo_ocorrencia}
                            />
                            <TabPane tabId={'6'}>
                                <Configuracoes
                                    refreshUser={() => {
                                        getUser(this.state.protocolo, this.callbackRows);
                                    }}
                                    {...this.state}
                                />
                            </TabPane>
                        </TabContent>
                        {/* <AddCreditCard user_id={this.state.userId}/> */}
                    </CardBody>
                </Card>
                <Row>
                </Row>
                <br />
            </Div>
        )
    }
}