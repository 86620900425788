import React from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, Row, Col, Input, CardFooter, Collapse, Button, Label } from 'reactstrap';
import { columns } from './propsPayout';
import debounce from 'lodash/debounce';
import Pagination from '../Pagination';
import { InputText } from '../../InputText/InputText';
import InputMask from 'react-input-mask';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import Select from 'react-select';
import { MdSearch } from 'react-icons/md';

var CurrencyInput = require('react-currency-masked-input');

const status = [
    { value: "succeeded", label: "Aprovado" },
    { value: "pending", label: "Pendente" },
    { value: "failed", label: "Falhada" },
    { value: "canceled", label: "Cancelada" },
    { value: "new", label: "Nova" },
    { value: "pre_authorized", label: "Pré-Autorizada" },
    { value: "reversed", label: "Revertida" },
    { value: "refunded", label: "Estornada" },
    { value: "dispute", label: "Em Disputa" },
    { value: "charged_back", label: "Chargeback" },    
];

const forma_pagamento = [
    { value: "boleto", label: "Boleto" },
    { value: "credit", label: "Cartão de Crédito" },
    { value: "debit", label: "Cartão de Débito" },
];

const modalidades = [
    { value: "chip", label: "CP - Cartão Presente" },
    { value: "online", label: "CNP - Cartão não Presente" },
];

export const TablePayout = (props) => {
    return (
        <Card>
            <CardBody>
                <Row >
                    <Col md={10}>
                        <div style={{ textAlign: 'left' }}>
                            <InputText
                                name="search"
                                placeholder="Buscar"
                                onChange={debounce((event) => props.isSearch(event), 1000)} />
                        </div>
                    </Col>
                    <Col md={2} style={{padding: 0, textAlign: 'right'}}>
                        <Button
                            color="warning"
                            onClick={props.filterShow}
                            style={{ marginRight: '25px', marginBottom: '4px' }}>
                            Filtrar&nbsp;
                            <AiFillCaretDown hidden={props.show_filter} />
                            <AiFillCaretUp hidden={!props.show_filter} />
                        </Button>
                    </Col>
                </Row>
                <Collapse isOpen={props.show_filter}>
                    <br/>
                    <Row>
                        <Col md={4}>
                            <Select
                                placeholder="Status do pagamento"
                                options={status}
                                isClearable
                                value={props.state.status_pagamento}
                                onChange={(value) => props.onChangeSelect('status_pagamento', value)}
                            />
                        </Col>
                        <Col md={4}>
                            <Select
                                placeholder="Tipo de Transação"
                                options={modalidades}
                                isClearable
                                value={props.state.modalidade}
                                onChange={(value) => props.onChangeSelect('modalidade', value)}
                            />
                        </Col>
                        <Col md={4}>
                            <Select
                                placeholder="Forma de pagamento"
                                options={forma_pagamento}
                                isClearable
                                value={props.state.forma_pagamento}
                                onChange={(value) => props.onChangeSelect('forma_pagamento', value)}
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={4}>
                            <InputText
                                name="transacao_id"
                                value={props.state.transacao_id}
                                placeholder="ID da Transação"
                                onChange={debounce((event) => props.isSearch(event), 1000)}
                                />

                        </Col>
                        <Col md={4}>
                            <div style={{ textAlign: 'left' }}>
                                <InputMask
                                    name="document"
                                    className="form-group"
                                    ref={props.refDocument}
                                    mask={props.is_cnpj === false ? "999.999.999-99" : "99.999.999/9999-99"}
                                    placeholder={props.is_cnpj === false ? "CPF" : "CNPJ"}
                                    onChange={debounce((event) => props.isSearch(event), 1000)}
                                >
                                    {(is_props) => <InputText name="document" {...is_props} />}
                                </InputMask>
                            </div>
                            </Col>
                            <Col md={4}>
                            <div style={{marginTop: '17px'}}>
                                &nbsp;&nbsp;<input 
                                    type="checkbox"
                                    checked={props.is_cnpj}
                                    onChange={() => props.onSearchDocument()}/> Pesquisar CNPJ</div>
                        </Col>
                    </Row>
                    <br/>
                    <hr/>
                    <small>Filtro por Valor</small>
                    <Row>
                        <Col md={6}>
                            <Label>De</Label>
                            <CurrencyInput 
                                inputMode="tel"
                                className="form-control" 
                                currency="BRL"
                                name="valor_de"
                                //value={valor}
                                defaultValue={'0.00'}
                                onChange={debounce((event) => props.isSearch(event), 1500)} />
                        </Col>
                        <Col md={6}>
                            <Label>Até:</Label>
                            <CurrencyInput 
                                inputMode="tel"
                                className="form-control" 
                                currency="BRL"
                                name="valor_ate"
                                //value={valor}
                                defaultValue={'0.00'}
                                onChange={debounce((event) => props.isSearch(event), 1500)} />
                        </Col>
                    </Row>
                    <small>Obs: Para filtrar por valor, a Data inicial e a Data final deve ser maior que 0</small>
                    <hr/>
                    <small>Filtro por Data</small>
                    <Row>
                        <Col>De:
                        <Input
                                name="date_from"
                                type="date"
                                //disabled
                                disabled={!(props.filter === true)}
                                value={props.date_from}
                                onChange={(event) => props.isSearch(event)} /></Col>
                        <Col>Até:
                        <Input
                                name="date_to"
                                type="date"
                                disabled={!(props.filter === true)}
                                //disabled
                                value={props.date_to}
                                onChange={(event) => props.isSearch(event)} /></Col>
                    </Row>
                    <Row>
                        <Col md={5}>
                            <input
                                type="checkbox"
                                checked={props.filter}
                                name="filter"
                                onChange={(value) => props.onFilter(value.target.checked)} /> Habilitar filtro por data de alteração:
                        </Col>
                    </Row>
                </Collapse>
                <hr/>
                <Row>
                    <Col>
                        <Button 
                            color='success'
                            onClick={() => props.buscarVendas()}
                        >
                            <MdSearch/> Buscar
                        </Button>
                    </Col>

                </Row>
            </CardBody>
            <hr style={{ marginBottom: '4px' }} />
            <DataTable
                noHeader={true}
                button={true}
                noDataComponent={props.noDataComponent}
                columns={columns(props.onPayload, props.onSellerDocument, props.onOpenClosedComplience)}
                data={props.data}
                theme="default"
            />
            <CardFooter style={{ textAlign: 'center' }}>
                <Pagination
                    onPageChange={props.onPageChange}
                    activePage={props.activePage}
                    totalPage={props.totalPage}
                />
            </CardFooter>
        </Card>
    )
}