import moment from 'moment';
import React from 'react';
import { Button, FormFeedback, Input, TabPane } from 'reactstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import Select from 'react-select';
import { InputMasked } from "../InputText/InputMasked";

var CurrencyInput = require('react-currency-masked-input');

const get_status_auth = (payment_type) => {
    switch(payment_type){
        case 0 : return 'Aguardando Validação';
        case 1 : return 'Validado';
        case 2 : return 'Perdido';
        default: return ''
    }
}
const get_status = (payment_type) => {
    switch(payment_type){
        case 'enabled' : return 'Ativo';
        case 'inactive' : return 'Inativo';
        case 'pending' : return 'Pendente';
        case 'failed' : return 'Falhada';
        default: return payment_type
    }
}
function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

const ponto_list = [
    { value: 'shopping_nova_lapa', label: 'Shopping Nova Lapa'},
    { value: 'shopping_bahia', label: 'Shopping da Bahia'},
    { value: 'shopping_sp_market', label: 'Shopping SP Market'},
    { value: 'filial_sao_paulo', label: 'Filial São Paulo'},
    { value: 'filial_salvador', label: 'Filial Salvador'},
    { value: 'matriz_bh', label: 'Matriz BH'},
];

const origem_list = [
    {value: 'chat', label: 'Chat Site'},
    {value: 'whatsapp', label: 'WhatsApp'},
    {value: 'sac', label: 'Contato SAC'},
    {value: 'prospeccao', label: 'Prospecção Externa'},
    {value: 'rede_social', label: 'Rede Social'},
    {value: 'lista_interna', label: 'Lista Interna'}
];

const maquininhas_list = [
    {   value: 'MP5', label: 'MP5'  },
    {   value: 'D195', label: 'D195'  },
    {   value: 'S920', label: 'S920'  },
    {   value: 'C680', label: 'C680'  },
    {   value: 'D200', label: 'D200'  },
];

const prazo_entrega_list = [
    { value: 'correios', label: 'Correios - 7 dias'},
    { value: 'pdv', label: 'Ponto de Venda - Imediato'},
    { value: 'aqenvio', label: 'AQEnvio'},
];

const formas_pagamento = [
    { value: 'credit', label: 'Crédito'},
    { value: 'debito', label: 'Débito'},
    { value: 'boleto', label: 'Boleto'},
    { value: 'pix', label: 'PIX'},
];

const get_by_array = (is_value, array) => {
    var label = '';

    array.forEach(e => {
        if(e.value === is_value){
            label = e.label;
        }
    });

    return label;
}


const plans_list = [
    { value: 'pride_plans', label: 'MP5, D195, D180 e S920, Planos PayOn, PayPride'},
    { value: 'plan_plus', label: 'D200 e S920 Plano Plus'},
    { value: 'paycash_plan', label: 'C680 Plano PayCash'}
];

const getPlanValue = (value) => {
    var label = '';
    plans_list.forEach(e => {
        if(e.value == value){
            label = e.label;
        }
    });

    return label;
}
function DataPreClient(props) {
    return (
        <TabPane tabId={props.active}>
            <br />
            <div>
                <h5>Dados Pessoais</h5>
                <hr/>
                <p><b>Nome do Responsavel:</b> {props.name}</p>
                {props.status ?
                    <p><b>Status:</b> {props.status}</p>
                :<></>}
                
                {props.email_edit_enable !== true ?
                    <>
                    <p><b>E-mail:</b> {props.email} <Button outline onClick={() => props.editEmail(true)} color='success' style={{padding: '3px', fontSize: '12px'}}>Editar</Button></p>
                    </>
                    :
                    <>
                        <p style={{display: 'flex'}}><b>E-mail:</b>&nbsp;
                            <Input 
                                name="email_edit"
                                placeholder="E-mail"
                                value={props.email_edit}
                                onChange={props.handleChange} 
                                style={{width: '80%', fontSize: '12px'}}
                            />
                            <Button disabled={props.loading_edit} color='success' onClick={() => props.storeEmail()} style={{margin: 2, padding: 2, fontSize: '12px'}}>Salvar</Button>
                            <Button color='danger' onClick={() => props.editEmail(false)} style={{margin: 2, padding: 2, fontSize: '12px'}}>Cancelar</Button>
                        </p>
                    </>
                }
                {/* <p><b>Telefone:</b> {props.telefone} <Button outline color='success' style={{padding: '3px', fontSize: '12px'}}>Editar</Button></p> */}
                {props.telefone_edit_enable !== true ?
                    <>
                    <p><b>Telefone:</b> {props.telefone} <Button outline onClick={() => props.telefoneEdit(true)} color='success' style={{padding: '3px', fontSize: '12px'}}>Editar</Button></p>
                    </>
                    :
                    <>
                        <p style={{display: 'flex'}}><b>Telefone:</b>&nbsp;
                            <Input 
                                name="telefone_edit"
                                placeholder="Telefone"
                                value={props.telefone_edit}
                                onChange={props.handleChange}
                                style={{width: '80%', fontSize: '12px'}}
                            />
                            <Button disabled={props.loading_edit} color='success' onClick={() => props.storeTelefone()} style={{margin: 2, padding: 2, fontSize: '12px'}}>Salvar</Button>
                            <Button color='danger' disabled={props.loading_edit} onClick={() => props.telefoneEdit(false)} style={{margin: 2, padding: 2, fontSize: '12px'}}>Cancelar</Button>
                        </p>
                    </>
                }
                <p><b>Documento (CPF/CNPJ):</b> {props.cnpj.length === 0 ? props.cpf : props.cnpj}</p>
                <p><b>Criado em:</b> {moment(new Date(props.created_at)).format('DD/MM/YYYY HH:mm')}</p>
                <p><b>Primeiro chat:</b> {props.first_chat_date !== '' ? moment(new Date(props.first_chat_date)).format('DD/MM/YYYY HH:mm') : ''}</p>
                <p><b>Origem do primeiro chat:</b> {props.first_origin}</p>
                <p><b>Ultimo Chat:</b> {props.last_chat_date !== '' ? moment(new Date(props.last_chat_date)).format('DD/MM/YYYY HH:mm') : ''}</p>
                <p><b>Origem do ultimo chat:</b> {props.last_origin}</p>
                {typeof props.plano_anterior !== 'undefined' ?
                    <p><b>Plano Anterior:</b> {getPlanValue(props.plano_anterior)}</p>
                :
                ''}
                {props.is_title === '' && typeof props.is_title !== 'undefined' ? 
                    <>
                        {props.endereco !== null ?
                            <p><b>Endereço: </b>{props.endereco.logradouro} {props.endereco.numero}, {props.endereco.complemento} - {props.endereco.bairro}, {props.endereco.cidade}/{props.endereco.uf} - {props.endereco.cep} <Button outline color='success' style={{padding: '3px', fontSize: '12px'}} onClick={() => props.onOpen()}>Editar</Button></p>
                            :
                            <p><Button color="info" onClick={() => props.onOpen()} style={{padding: 3}}>Cadastrar Endereco</Button></p>
                        }
                        {props.account_value == true ?
                            <p><Button color="dark" onClick={() => props.onCloseAccount(true)} style={{padding: 3}}>Cadastrar Conta Bancaria</Button></p>
                        : null}
                    </>
                    :
                    <>
                        {props.endereco !== null ?
                            <p><b>Endereço: </b>{props.endereco.logradouro} {props.endereco.numero}, {props.endereco.complemento} - {props.endereco.bairro}, {props.endereco.cidade}/{props.endereco.uf} - {props.endereco.cep} <Button outline color='success' style={{padding: '3px', fontSize: '12px'}} onClick={() => props.onOpen()}>Editar</Button></p>
                            :
                            <p><Button color="info" onClick={() => props.onOpen()} style={{padding: 3}}>Cadastrar Endereco</Button></p>
                        }
                    </>
                }
                {props.empresa !== null ? 
                    <>
                        <p><b>E-mail da empresa:</b> {props.empresa.email}</p>
                        <p><b>Nome fantansia:</b> {props.empresa.nome_fantasia}</p>
                        <p><b>Nome da empresa:</b> {props.empresa.razao_social}</p>
                        <p><b>Descrição da empresa:</b> {props.empresa.tipo_negocio}</p>
                        <p><b>Natureza Juridica:</b> {props.empresa.natureza_juridica}</p>
                    </>
                : <></>}
                {props.data_venda !== null ? 
                    <>
                        <p><b>Origem:</b> {get_by_array(props.data_venda.origem, origem_list)}</p>
                        <p><b>Vendedor:</b> {props.data_venda.nome_vendedor}</p>
                        <p><b>Ponto de Venda:</b> {get_by_array(props.data_venda.ponto_venda, ponto_list)}</p>
                        <p><b>Forma de Pagamento:</b> {get_by_array(props.data_venda.forma_pgto, formas_pagamento)}</p>
                        <p><b>Entrega:</b> {get_by_array(props.data_venda.ponto_entrega, prazo_entrega_list)}</p>
                        <p><b>Parcelas:</b> {props.data_venda.parcelas}</p>
                    </>
                :null}
                <p hidden={props.alteracoes_usuario.length == 0}><b>Histórico de Alterações:</b><br/>
                {props.alteracoes_usuario.map(e => {
                    return <p>{moment(e.created_at).format('DD/MM/YYYY HH:mm')} - <div dangerouslySetInnerHTML={{ __html: replaceAll(e.descricao, "\n", "<br/>") }} /></p>
                })}
                </p>
                {props.is_title === '' && typeof props.is_title !== 'undefined' ? 
                    <>
                        {props.seller_id == null ?
                        <>
                            {props.plano === null && typeof props.plano !== 'undefined' ? 
                                <>
                                    <Select 
                                        options={props.planos_list}
                                        placeholder="Escolha o plano para o cliente"
                                        value={props.plano_current}
                                        onChange={props.onSelectPlan}
                                    />
                                    <Input hidden invalid={props.plano_current_invalid}/>
                                    <FormFeedback>Selecione o plano para o cliente</FormFeedback>
                                </>
                            :<></>}
                            <small><b>Verifique os documentos antes de cadastrar</b></small><br/>
                            {props.cnpj == '' ?
                            <>
                                <small>Informar a renda ou faturamento da empresa: </small>
                                <CurrencyInput
                                    inputMode="tel"
                                    className="form-control"
                                    currency="BRL"
                                    name="faturamento_empresa"
                                    //value={this.state.valor}
                                    defaultValue={'0.00'}
                                    onChange={props.onValor} />
                                <br/>
                            </>
                            :<></>}
                            <Button 
                                color="success" 
                                disabled={props.zoop_loading}
                                onClick={() => props.onZoop()}>
                                    {props.zoop_loading === true ? 
                                    <><ClipLoader size={15} color="#fff" />&nbsp;&nbsp;</>
                                    :
                                    <></>}
                                    Cadastrar cliente na ZOOP
                            </Button>
                            {props.cnpj !== '' && props.cpf === '' ?
                                <div style={{width: '400px'}} hidden={props.zoop_loading}>
                                    <br/>
                                    <InputMasked
                                        type="text" 
                                        mask="999.999.999-99"
                                        placeholder="Digite o CPF do Seller para cadastrar na zoop"
                                        value={props.zoop_cpf}
                                        invalid={props.zoop_cpf_invalid}
                                        onChange={(e) => props.onChangeZoop(e.target.value)}
                                    />
                                </div>
                                :
                                null
                            }
                            </>
                        :
                        <></>}
                    </>
                 : 
                 <div>
                </div>}

            </div>
        </TabPane>
    )
}

export default DataPreClient;