import { Component } from "react";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Select from 'react-select';
import { InputMasked } from "../../InputText/InputMasked";
import { InputText } from "../../InputText/InputText";
import { list_produtos } from "../../../auth/listas";
import ReactRoundedImage from "react-rounded-image";
import { getToken } from "../../../auth/set_services";
import { ENDPOINT } from "../../../auth/endpoint";
import AsyncSelect from 'react-select/async';
import axios from "axios";
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import { debounce } from "lodash";
import { toast } from "react-toastify";

const autorizacaoRegister = (state, onData) => {
    var url = ENDPOINT+"autorizacao/store";

    var formData = new FormData();
    formData.append('empresa', state.empresa.label);
    if(state.relacao_cliente !== null){
        formData.append('cpf_cnpj_cliente', state.relacao_cliente.document);
        formData.append('client_name', state.relacao_cliente.nome);
    }

    if(state.ticket !== null){
        formData.append('protocolo_ticket', state.ticket.value);
    }
    formData.append('prazo_resposta', state.prazo_resposta);

    if(state.ocorrencia !== null){
        formData.append('ocorrencia_interna',state.ocorrencia.value);
    }
    formData.append('motivo', state.motivo_solicitacao);
    formData.append('autorizador_id', state.autorizador.value);
    formData.append('autorizador_name', state.autorizador.name);


    if(state.file_01 !== null){
        formData.append('arquivo_url_one', state.file_01, state.file_01.name);
    }

    if(state.file_02 !== null){
        formData.append('arquivo_url_two', state.file_02, state.file_02.name);
    }

    if(state.file_03 !== null){
        formData.append('arquivo_url_three', state.file_03, state.file_03.name);
    }

    state.links.forEach((e, index) => {
        formData.append("links["+index+"]", JSON.stringify(e));
    });

    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        //setData(data.access_token, JSON.stringify(data.user));
        onData(data);
        return data;
    })
    .catch((error) => {
        var response = '';
        var obj;
        if(error.message === 'Network Error'){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        if(data.exception){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        if(typeof data.message !== 'undefined'){
            if(data.message === 'Server Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
        }
        onData(data);
        return data;
    });

}
const getOperadores = (callback) => {
    var url = ENDPOINT + "chat-atendimento/consultar-operadores";

    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    })
        .then((response) => {
            var data = response.data;
            var operadores_list = [];
            data.forEach((p, i) => {
                p['value'] = p.id;
                p['name'] = p.name;
                p['label'] = <div style={{ display: 'flex' }}>
                    <ReactRoundedImage
                        image={p['avatar_foto'] !== null ? p['avatar_foto'] : ''}
                        roundedColor="#8dd199"
                        roundedSize="4"
                        imageWidth="25"
                        imageHeight="25" />
                    <p style={{ display: 'flex', margin: 0 }}>
                        &nbsp;
                        {p.name}
                    </p>
                </div>;
                operadores_list.push(p);
            });

            callback(operadores_list)
        });
}
class ModalAutorizacao extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transferir_document: false,
            empresa: null,
            empresa_invalid: false,
            cpf_cnpj: '',
            cpf_cnpj_invalid: false,
            prazo_resposta: '',
            prazo_resposta_invalid: false,
            ocorrencia_interna: '',
            ocorrencia_interna_invalid: false,
            ticket_list: [],
            ocorrencia: null,
            ticket: null,
            ticket_invalid: false,
            protocolo_ticket: '',
            protocolo_ticket_invalid: false,
            motivo_solicitacao: '',
            motivo_solicitacao_invalid: false,
            autorizador: null,
            autorizador_invalid: false,
            operadores_list: [],
            relacao_cliente: null,
            tipo_cliente: 'aqpago',
            tipo_pesquisa: 'cpf',
            add_text: false,
            url_text: '',
            url_text_invalid: false,
            departamento_text: '',
            departamento_text_invalid: false,
            links: [],
            file_01: null,
            file_02: null,
            file_03: null,
            loading: false,
            error_message: '',
            success_message: ''
        }
    }

    componentDidMount() {
        getOperadores(this.callbackOperadores.bind(this));
    }
    callbackOperadores = (operadores_list) => {
        this.setState({ operadores_list });
    }

    onClose = () => {
        this.setState({
            transferir_document: false,
            empresa: null,
            empresa_invalid: false,
            cpf_cnpj: '',
            cpf_cnpj_invalid: false,
            prazo_resposta: '',
            prazo_resposta_invalid: false,
            ocorrencia_interna: '',
            ocorrencia_interna_invalid: false,
            protocolo_ticket: '',
            protocolo_ticket_invalid: false,
            motivo_solicitacao: '',
            motivo_solicitacao_invalid: false,
            autorizador: null,
            autorizador_invalid: false,
            relacao_cliente: null,
            tipo_cliente: 'aqpago',
            tipo_pesquisa: 'cpf',
            add_text: false,
            url_text: '',
            url_text_invalid: false,
            departamento_text: '',
            departamento_text_invalid: false,
            links: [],
            file_01: null,
            file_02: null,
            file_03: null,
            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose();
    }

    onRegister = () => {
        if(this.validFields() == true){
            this.setState({
                loading: true
            })
            autorizacaoRegister(this.state, this.onData);
        }
    }

    onData = (data) => {
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({ [name]: value });
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    handleFileChange = (e) => {
        var name = e.target.name;
        if(e.target.files.length > 0){
            this.setState({
                [name]: e.target.files[0]
            })
        }
    }

    validFields = () => {
        var invalids = 0;

        if(this.state.empresa !== null){
            this.setState({empresa_invalid: false})
        }
        else{
            invalids++;
            this.setState({empresa_invalid: true})
        }

        if(this.state.prazo_resposta != null && this.state.prazo_resposta != ''){
            this.setState({prazo_resposta_invalid: false})
        }
        else{
            invalids++;
            this.setState({prazo_resposta_invalid: true})
        }

        if(this.state.autorizador != null){
            this.setState({autorizador_invalid: false})
        }
        else{
            invalids++;
            this.setState({autorizador_invalid: true})
        }

        if(this.state.motivo_solicitacao != ''){
            this.setState({motivo_solicitacao_invalid: false})
        }
        else{
            invalids++;
            this.setState({motivo_solicitacao_invalid: true})
        }

        if(invalids > 0){
            return false;
        }

        return true;
    }

    loadOptionsOcorrencias = (inputValue, callback) => {
        var url = ENDPOINT + "ocorrencia/list?search=" + inputValue + "&page=1";

        if(this.state.relacao_cliente !== null){
            if(this.state.relacao_cliente.document.length > 11){
                url += "&cnpj="+this.state.relacao_cliente.document;
            }
            else{
                url += "&cpf="+this.state.relacao_cliente.document;
            }
        }

        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p.data;

            data.forEach((p, i) => {
                data[i].value = p.protocolo_ocorrencia;
                data[i].label = p.protocolo_ocorrencia +' - '+ p.razao_ocorrencia;
            });

            callback(this.filterOcorrencia(inputValue, data));
        })
        .catch(e => {
            callback(this.filterOcorrencia(inputValue, []));
        });
    }

    filterOcorrencia = (inputValue, data) => {
          return data.filter(i =>
                i.value.toLowerCase()
          );
    };

    loadOptionsTicket = (inputValue, callback) => {
        var url = ENDPOINT + "ticket-admin/list?search=" + inputValue + "&page=1";

        if(this.state.relacao_cliente !== null){
            url += "&email="+this.state.relacao_cliente.email;
        }

        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p.data;

            data.forEach((p, i) => {
                data[i].value = p.protocolo_ticket;
                data[i].label = p.protocolo_ticket +' - '+ p.assunto;
            });

            callback(this.filter(inputValue, data));
        })
        .catch(e => {
            callback(this.filter(inputValue, []));
        });
    }

    filterTicket = (inputValue, data) => {
          return data.filter(i =>
                i.nome.toLowerCase()
          );
    };

    loadOptions = (inputValue, callback) => {
        var url = ENDPOINT + "user/all?"+this.state.tipo_pesquisa+"=" + inputValue;

        if(this.state.tipo_cliente == 'aqpasso'){
            url = ENDPOINT + "user/all?empresa_relacao=aqpasso&"+this.state.tipo_pesquisa+"=" + inputValue;
        } 
        else if(this.state.tipo_cliente == 'aqcontabil'){
            url = ENDPOINT + "user/all?empresa_relacao=aqcontabil&"+this.state.tipo_pesquisa+"=" + inputValue;
        }
        else{
            url = ENDPOINT + "user/all_aqbank?page=1&"+this.state.tipo_pesquisa+"=" + inputValue;
        }

        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p.data;

            if(this.state.tipo_cliente == 'aqpasso' || this.state.tipo_cliente == 'aqcontabil'){
                data.forEach((p, i) => {
                    data[i].value = p.id;
                    data[i].document = (p.cnpj ? p.cnpj : p.cpf);
                    data[i].nome = p.nome+ ' ' +p.sobrenome;
                    data[i].label = p.nome+ ' ' +p.sobrenome + ' - ' + (p.cpf !== null ? p.cpf : p.cnpj !== null ? p.cnpj : '');;;
                });
            }
            else{
                data.forEach((p, i) => {
                    data[i].value = p.id;
                    data[i].document = (p.cnpj ? p.cnpj : p.cpf);
                    data[i].nome = p.nome+ ' ' +p.sobrenome;
                    data[i].label = (p.nome ? p.nome : p.responsavel.nome + ' '+p.responsavel.sobrenome) + ' - ' + (p.cpf !== null ? p.cpf : p.cnpj !== null ? p.cnpj : '');
                });
            }

            callback(this.filter(inputValue, data));
        })
        .catch(e => {
            callback(this.filter(inputValue, []));
        });
    }

    filter = (inputValue, data) => {
          return data.filter(i =>
                i.nome.toLowerCase()
          );
    };
    
    addAutorizacao(){
        if(this.state.departamento_text.length == 0){
            toast.error('O departamento é obrigatório');
            return;
        }

        
        if(this.state.url_text.length == 0){
            toast.error('O url é obrigatório');
            return;
        }

        var links = this.state.links;
        var new_title = {
            departamento: this.state.departamento_text,
            url: this.state.url_text,
        };

        links.push(new_title);
        this.setState({
            links: links,
            add_text: false,
            departamento_text: '',
            departamento_text_invalid: false,
            url_text: '',
            url_text_invalid: false,
        });
    }
    render() {
        return (
            <Modal isOpen={this.props.visible} size="xl">
                <ModalHeader>
                    Pedir Autorização
                </ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <Row>
                        <Col md={6}>
                            <Form>
                                <FormGroup>
                                    <Label>Empresa:</Label>
                                    <Select
                                        placeholder="Empresa"
                                        options={list_produtos}
                                        isSearchable={false}
                                        value={this.state.empresa}
                                        onChange={(empresa) => this.setState({ empresa })}
                                        noOptionsMessage={() => { return 'Sem opções' }}
                                    />
                                    <Input hidden invalid={this.state.empresa_invalid} />
                                    <FormFeedback>Selecione a empresa</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <br/>
                                    <input type="radio" name="cliente_radio" onChange={() => this.setState({tipo_cliente: 'aqpago'})} checked={this.state.tipo_cliente === 'aqpago'} />&nbsp;Clientes AQPago&nbsp;&nbsp;
                                    <input type="radio" name="cliente_radio" onChange={() => this.setState({tipo_cliente: 'aqpasso'})} checked={this.state.tipo_cliente === 'aqpasso'}/>&nbsp;Clientes AQPasso&nbsp;&nbsp;
                                    <input type="radio" name="cliente_radio" onChange={() => this.setState({tipo_cliente: 'aqcontabil'})} checked={this.state.tipo_cliente === 'aqcontabil'}/>&nbsp;Clientes AQContabil
                                    <AsyncSelect
                                        key={this.state.tipo_cliente+' - '+this.state.tipo_pesquisa}
                                        style={{width: '100%'}}           
                                        cacheOptions
                                        loadOptions={debounce(this.loadOptions, 1000)}
                                        defaultOptions
                                        isOptionDisabled={(option) => option.document === null}
                                        placeholder="Cliente"
                                        onChange={(relacao_cliente) => this.setState({ relacao_cliente })}
                                    />
                                    <small>Pesquisa por: &nbsp;
                                    <input type="radio" name="pesquisa_radio" onChange={() => this.setState({tipo_pesquisa: 'cpf'})} checked={this.state.tipo_pesquisa === 'cpf'}/>&nbsp;CPF&nbsp;&nbsp;
                                    <input type="radio" name="pesquisa_radio" onChange={() => this.setState({tipo_pesquisa: 'cnpj'})} checked={this.state.tipo_pesquisa === 'cnpj'}/>&nbsp;CNPJ&nbsp;&nbsp;
                                    <input type="radio" name="pesquisa_radio" onChange={() => this.setState({tipo_pesquisa: 'nome'})} checked={this.state.tipo_pesquisa === 'nome'} />&nbsp;Nome&nbsp;&nbsp; </small>
                                    <Input hidden invalid={this.state.relacao_cliente_invalid}/>
                                    <FormFeedback>Campo obrigatório</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Prazo de Resposta:</Label>
                                    <InputText
                                        type="datetime-local"
                                        placeholder="Prazo de Resposta"
                                        name="prazo_resposta"
                                        value={this.state.prazo_resposta}
                                        invalid={this.state.prazo_resposta_invalid}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Ticket:</Label>
                                    <AsyncSelect
                                        key={this.state.relacao_cliente !== null ? "key-"+this.state.relacao_cliente.document : 'key-ticket-list'}
                                        style={{width: '100%'}}
                                        cacheOptions
                                        loadOptions={debounce(this.loadOptionsTicket, 1000)}
                                        defaultOptions
                                        placeholder="Selecione o ticket"
                                        onChange={(ticket) => this.setState({ ticket })}
                                    />
                                    <Input hidden invalid={this.state.ticket_invalid} />
                                    <FormFeedback>Selecione a empresa</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    {/* <Label>Protocolo de ocorrencia interna:</Label>
                                    <InputText
                                        placeholder="Protocolo de ocorrencia interna"
                                        name="ocorrencia_interna"
                                        value={this.state.ocorrencia_interna}
                                        invalid={this.state.ocorrencia_interna_invalid}
                                        onChange={this.handleChange}
                                    /> */}
                                    
                                    <Label>Ocorrência interna:</Label>
                                    <AsyncSelect
                                        key={this.state.relacao_cliente !== null ? "key-ocorrencia-"+this.state.relacao_cliente.document : 'key-ocorrencia-list'}
                                        style={{width: '100%'}}
                                        cacheOptions
                                        loadOptions={debounce(this.loadOptionsOcorrencias, 1000)}
                                        defaultOptions
                                        placeholder="Selecione a ocorrência interna"
                                        onChange={(ocorrencia) => this.setState({ ocorrencia })}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Pedir autorizaçao para:</Label>
                                    <Select
                                        placeholder="Autorizador"
                                        isSearchable={false}
                                        options={this.state.operadores_list}
                                        value={this.state.autorizador}
                                        onChange={(autorizador) => this.setState({ autorizador })}
                                        noOptionsMessage={() => { return 'Sem opções' }}
                                    />
                                    <Input hidden invalid={this.state.autorizador_invalid}/>
                                    <FormFeedback>Campo obrigatório</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Motivo da solicitação:</Label>
                                    <Input
                                        type="textarea"
                                        placeholder="Motivo da solicitação"
                                        name="motivo_solicitacao"
                                        value={this.state.motivo_solicitacao}
                                        invalid={this.state.motivo_solicitacao_invalid}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Demanda interna no CRM do Departamento:</Label>
                                    {this.state.links.map(e => (
                                        <p><b>LINK: </b>{e.url}<br/>
                                        <b>Departamento: </b>{e.departamento}</p>
                                    ))}<br/>
                                    <small>{this.state.links.length == 0 ? 
                                    <><small>Nenhuma Demanda Interna adicionada</small><br/></> : null}</small>
                                    <Button color="info" onClick={() => this.setState({ add_text: true })}>Nova demanda interna</Button>
                                    <Form hidden={!this.state.add_text}>
                                        <br/>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                placeholder="URL"
                                                name="url_text"
                                                value={this.state.url_text}
                                                invalid={this.state.url_text_invalid}
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                type="select"
                                                placeholder="Departamento"
                                                name="departamento_text"
                                                value={this.state.departamento_text}
                                                invalid={this.state.departamento_text_invalid}
                                                onChange={this.handleChange}
                                            >
                                                <option>Selecione o Departamento</option>
                                                <option value="Juridico">Juridico</option>
                                                <option value="Prevenção de Transação">Prevenção de Transação</option>
                                                <option value="Prevenção de Autorização">Prevenção de Autorização</option>
                                                <option value="Logística">Logística</option>
                                                <option value="Atendimento SAC">Atendimento SAC</option>
                                                <option value="Marketing">Marketing</option>
                                                <option value="Desenvolvimento">Desenvolvimento</option>
                                                <option value="Liquidação de Pagamentos">Liquidação de Pagamentos</option>
                                                <option value="Cadastro AQPago">Cadastro AQPago</option>
                                                <option value="Crédito TAG">Crédito TAG</option>
                                            </Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Button color="success" onClick={() => this.addAutorizacao()}>Salvar Demanda</Button>
                                        </FormGroup>
                                    </Form>
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col md={6}>
                            <Form>
                                <FormGroup>
                                    <Label>Arquivo 1:</Label>
                                    <Input 
                                        type="file"
                                        name="file_01"
                                        onChange={this.handleFileChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Arquivo 2:</Label>
                                    <Input 
                                        type="file"
                                        name="file_02"
                                        onChange={this.handleFileChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Arquivo 3:</Label>
                                    <Input 
                                        type="file"
                                        name="file_03"
                                        onChange={this.handleFileChange}
                                    />
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalBody
                    style={{
                        minHeight: '114px',
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                    />
                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose()}>Cancelar</Button>
                    <Button color="success" onClick={this.onRegister}>Salvar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ModalAutorizacao;