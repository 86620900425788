import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import Moment from 'moment';
import { Button } from 'reactstrap';

export const getStatusTaggy = (value) => {
  switch(value){
       case 'aguardando_envio': return 'Aguardando envio';
       case 'aguardando_pagamento': return 'Aguardando pagamento';
       case 'inativo': return 'Aguardando ativação';
       case 'cancelado': return 'Cancelado';
       case 'ativado': return 'Ativado';
       case 'bloqueado': return 'Bloqueado';
       default: return value;
  }
} 

export const getStatus = (value) => {
  if (value.status == 'fechada') {
    if (new Date(value.vencimento_fatura) < new Date()) {
      return { status: 'Fatura vencida', cor: 'red' };
    }
  }
  switch (value.status) {
    case 'a vencer': return { status: 'Fatura a vencer', cor: 'blue' };
    case 'fechada': return { status: 'Fatura fechada', cor: '#b7a700' };
    case 'paga': return { status: 'Fatura paga', cor: '#10a100' };
    default: return { status: 'Fatura ' + value.status, cor: 'black' };
  }
} 


const forma_pagamento = [
    { value: "pix", label: "PIX" },
    { value: "boleto", label: "Boleto" },
    { value: "credit", label: "Crédito" },
    { value: "none", label: "Pagamento não necessario" },
];

export const columns = () => [
    {
      name: "Data",
      selector: "created_at",
      sortable: false,
      width: '150px',
      cell: p => Moment(p.created_at).format('DD/MM/YYYY')
    },
    {
      name: "Cliente",
      selector: "cliente",
      sortable: false,
      width: '270px',
      cell: p => <div style={{margin: 5, width: '100%'}}>
                    <center>
                      {p.conta.nome_fatura}<br/>
                      <b>{p.conta.user !== null ? p.conta.user.cnpj ? p.conta.user.cnpj : p.conta.user.cpf : ''}</b>
                    </center>
                </div>
    },
    {
      name: "Valor",
      selector: "valor",
      sortable: false,
      cell: p => 'R$ ' + parseFloat(p.valor).toFixed(2).replace('.', ',')
    },
    {
      name: "ID",
      selector: "id",
      sortable: false,
      cell: p => '#'+p.id
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
      cell: p => <b style={{color: getStatus(p).cor}}>{getStatus(p).status}</b>
    },
    {
      name: "Vencimento",
      selector: "created_at",
      sortable: false,
      width: '150px',
      cell: p => Moment(p.vencimento_fatura).format('DD/MM/YYYY')
    },
    {
      name: "Próximo Vencimento",
      selector: "created_at",
      sortable: false,
      width: '150px',
      cell: p => Moment(p.vencimento_fatura).add(1, 'M').format('DD/MM/YYYY')
    },
];

export const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "faturas/list?search="+state.search+"&page="+state.activePage;

    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&cpf="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&cnpj="+document;
        }
    }

    if(state.cod_cliente != ''){
        url += "&cod_cliente="+state.cod_cliente;
    }

    if(state.cod_fatura != ''){
        url += "&cod_fatura="+state.cod_fatura;
    }

    if (state.filter === true) {
        url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    var url = ENDPOINT + "faturas/list?search="+search+"&page="+page;

    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&cpf="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&cnpj="+document;
        }
    }

    if(state.status_envio != null){
        url += "&status_envio="+state.status_envio.value;
    }

    if(state.status_fatura != null){
        url += "&status_fatura="+state.status_fatura.value;
    }
    
    if(state.cod_cliente != ''){
        url += "&cod_cliente="+state.cod_cliente;
    }

    if(state.cod_fatura != ''){
        url += "&cod_fatura="+state.cod_fatura;
    }
    
    if (filter === true) {
        url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}