import React, { Component } from "react";
import { Button, Form, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import GoogleMapReact from 'google-map-react';
import { FaMapMarkerAlt } from "react-icons/fa";

const AnyReactComponent = ({ text }) => <div style={{width: '126px', textAlign: 'center', fontWeight: '800'}}><FaMapMarkerAlt style={{color: 'red'}}/> <br/>{text}</div>;


class MapsMeubus extends Component{
    constructor(props){
        super(props);
        this.state = {
            message: '',
            message_invalid: false,
            loading: false,
            error_message: '',
            success_message: '',
            select_fraud: {label: 'Ativo', value: true},
        }
    }

    onClose = () => {
        this.props.onClose();
    }
    
    render(){
        return(
            <Modal isOpen={this.props.visible}>
                <ModalHeader>Mapa</ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <Form>
                        <FormGroup>
                            <div style={{ height: '340px', width: '100%' }}>

                                {this.props?.row?.lat ? (
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyCNBu6Af086YRI-3EdGrmsqgdBvjfL7Mdg" }}
                                        defaultCenter={{
                                            lat: Number.parseFloat(this.props.row.lat),
                                            lng: Number.parseFloat(this.props.row.lon)
                                        }}
                                        defaultZoom={16}
                                        >
                                        <AnyReactComponent
                                            lat={Number.parseFloat(this.props.row.lat)}
                                            lng={Number.parseFloat(this.props.row.lon)}
                                            text={this.props.row.city + ' - ' + this.props.row.region}
                                        />
                                    </GoogleMapReact>
                              
                                ) : (<></>)}

                            </div>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose()}>Fechar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default MapsMeubus;