
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { Card, CardBody, Row, Col, Input, CardFooter, Collapse, Button, Label } from 'reactstrap';
import { columns } from './propsVendedoresExtrato';
import debounce from 'lodash/debounce';
import Pagination from '../../Pagination';
import { InputText } from '../../../InputText/InputText';
import InputMask from 'react-input-mask';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import Select from 'react-select';
import { ENDPOINT } from '../../../../auth/endpoint';
import { getToken } from '../../../../auth/set_services';
import { FaSearch } from 'react-icons/fa';



export const TableVendedoresExtrato = (props) => {
    return (
        <Card>
            <CardBody>
                <Row style={{marginBottom: '1.2rem'}}>
                    <Col md={6}>
                        <Select
                            placeholder="Tipo de pagamento"
                            options={[
                                {label: 'Pagar por Etiqueta', value: 'pay_now'},
                                {label: 'Pré Pago', value: 'pay_pre'},
                                {label: 'Pós Pago', value: 'pay_pos'},
                                {label: 'Debito', value: 'debit'},
                            ]}
                            value={props.state.filter_type_payment_select}
                            isClearable
                            onChange={(value) => props.onChangeSelect('filter_type_payment_select', value)}
                        />
                    </Col>
                    <Col md={6}>
                        <Select
                            placeholder="Ação"
                            options={[
                                {label: 'Pagamentos Realizados', value: 'payments'},
                                {label: 'Lançamento', value: 'releases'},
                                {label: 'Créditos Extra', value: 'credit_extra'},
                            ]}
                            value={props.state.filter_action_select}
                            isClearable
                            onChange={(value) => props.onChangeSelect('filter_action_select', value)}
                        />
                    </Col>
                </Row>
                <Row style={{marginBottom: '1.2rem'}}>
                    <Col md={6}>
                        <div style={{ textAlign: 'left' }}>
                            <label htmlFor="period_start">Período Inicial</label>
                            <Input
                                name="period_start"
                                value={props.state.period_start}
                                type="date"
                                onChange={(event) => props.isSearch(event)} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div style={{ textAlign: 'left' }}>
                            <label htmlFor="period_end">Período Final</label>
                            <Input
                                name="period_end"
                                value={props.state.period_end}
                                type="date"
                                onChange={(event) => props.isSearch(event)} />
                        </div>
                    </Col>
                </Row>
            </CardBody>
            <hr style={{ marginBottom: '4px' }} />
            <DataTable
                noHeader={true}
                button={true}
                noDataComponent={props.noDataComponent}
                columns={columns(props, props.ruleSellerData)}
                data={props.data}
                theme="default"
            />
            <CardFooter style={{ textAlign: 'center' }}>
                <Pagination
                    onPageChange={props.onPageChange}
                    activePage={props.activePage}
                    totalPage={props.totalPage}
                />
            </CardFooter>
        </Card>
    )
}