import React, {Component} from 'react';
import { Button, Card, CardBody } from "reactstrap";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getRows, getRowsPerPage } from '../../components/Tables/TableUsers/propsUserAqpago';
import PropagateLoader from "react-spinners/PropagateLoader";
import { getPermissoes, getUser } from '../../auth/set_services';
import TableUsersAqpago from '../../components/Tables/TableUsers/TableUsersAqpago';
import UserModal from '../../components/Modals/UserModal/UserModal';


class ClientesAqpago extends Component{
    constructor(props){
        super(props);
        this.state = {
            show_activity: false,
            show_filter: false,
            user_change: null,
            search: '',
            seller_id: '',
            loading_table: true,
            edit_id: -1,
            edit_user: null,
            rowData: [],
            cpf_current: null,
            cnpj_current: null,
            phone_current: null,
            status_user: '',
            type_user: '',
            totalPage: 0,
            activePage: 1,
            is_cnpj: false,
            document: '',
            visible_taggy_add: false,
            visible_user: false
        }
        this.onPageChange = this.onPageChange.bind(this);
        this.callbackRows = this.callbackRows.bind(this);
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    filterShow(){
        this.setState({
            show_filter: !this.state.show_filter
        })
    }
    handleChange = (event) => {
        var state = this.state;
        let name = event.target.name;
        let value = event.target.value;

        if(name === 'document'){
            if(value.replace(/([^\d])+/gim, '').length > 10){
                state[name] = value;
                state.activePage = 1;
                state.rowData = [];
                state.loading_table = true;
                this.setState({...state});
                
                getRowsPerPage(1, state, this.callbackRows);
            }
            else if(value.replace(/([^\d])+/gim, '').length === 0){
                if(state[name].replace(/([^\d])+/gim, '').length > 0){
                    state[name] = value;
                    state.activePage = 1;
                    state.rowData = [];
                    state.loading_table = true;
                    this.setState({...state});
                    
                    getRowsPerPage(1, state, this.callbackRows);
                }
            }
        }
        else{
            state[name] = value;
            state.activePage = 1;
            state.rowData = [];
            state.loading_table = true;
            this.setState({...state});
            
            getRowsPerPage(1, state, this.callbackRows);
        }
    }

    componentDidMount(){
        getRows(this.state, this.callbackRows.bind(this));
    }
    
    
    callbackRows(rowData, totalPage){
        this.setState({rowData, totalPage, loading_table: false})
    }
    onStatusChange(status_user){
        var state = this.state;
        state.status_user = status_user
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});
        
        getRows(state, this.callbackRows);
    }

    onPageChange = (activePage) => {
        var state = this.state;

        state.activePage = activePage;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});

        getRowsPerPage(activePage, state, this.callbackRows.bind(this));
    }
    onClose = () => {
        this.setState({
            visible_user: false
        });

        getRows(this.state, this.callbackRows);
    }
    render(){
        return(
            <div>
                <h3>Clientes AQPago</h3>
                <hr/>
                <div style={{marginBottom: '7px'}} hidden={!getUser().permissao_gestor_aqpago}>
                    <Button color="success" onClick={() => this.setState({visible_user: true})}>Novo Cliente</Button>
                    <br/>
                </div>
                <Card hidden={!getUser().permissao_gestor_aqpago}>
                    <TableUsersAqpago
                        isSearch={this.handleChange}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        data={this.state.rowData}
                        is_cnpj={this.state.is_cnpj}
                        status_user={this.state.status_user}
                        onStatusChange={this.onStatusChange.bind(this)}
                        type_user={this.state.type_user}
                        show_filter={this.state.show_filter}
                        filterShow={this.filterShow.bind(this)}
                        onPageChange={this.onPageChange}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhum usuário encontrado
                            </div></CardBody></Card>
                        }
                    />
                </Card>
                <UserModal
                    visible={this.state.visible_user}
                    onClose={this.onClose}
                />
                <div hidden={getUser().permissao_gestor_aqpago}>
                  <Card>
                      <CardBody>
                          <p>Você não tem permissões para acessar essa função</p>
                      </CardBody>
                  </Card>
                </div>
            </div>
        )
    }
}

export default ClientesAqpago;