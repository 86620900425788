import axios from 'axios';
import moment from 'moment';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';

export const columns = () => [
    {
      name: "Data",
      selector: "created_at",
      sortable: false,
      width: '150px',
      cell: row => row.created_at !== null ? moment(row.created_at).format('DD/MM/YYYY HH:mm') : ''
    },
    {
      name: "CPF/CNPJ",
      selector: "documento",
      sortable: false
    },
    {
      name: "Score",
      sortable: false,
      cell: p => {
        var p = JSON.parse(p.consulta_serasa);
        return p.score.pontos + ' - ' + p.score.faixa.titulo;
      }
    },
    {
      name: "Produtos",
      selector: "produtos",
      sortable: false,
      cell: p => p.produtos !== null ? <ul>{JSON.parse(p.produtos).map(e => <li>{e.texto}</li>)}</ul> : 'Sem informações'
    }
];

export const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "serasa/list";

    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&cpf_cnpj="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&cpf_cnpj="+document;
        }
    }

    if(state.status !== null){
      url += '&step='+state.status.value;
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    
    var url = ENDPOINT + "serasa/list?search="+search+"&page="+page;

    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&cpf_cnpj="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&cpf_cnpj="+document;
        }
    }

    if(state.status !== null){
      url += '&step='+state.status.value;
    }

    if(state.service_select !== null){
      url += '&tipo_servico='+state.service_select.value;
    }

    if(filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}