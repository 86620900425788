import { Component } from "react";
import { Button, ButtonGroup, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import { InputMasked } from "../../InputText/InputMasked";
import AsyncSelect from 'react-select/async';
import { InputText } from "../../InputText/InputText";
import { toast } from "react-toastify";
import axios from "axios";
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import { debounce } from "lodash";

class ModalValidacaoToken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            forma_envio: null,
            tipo_cliente: 'aqpago',
            tipo_pesquisa: 'nome',
            hash: null,
            relacao_cliente: null,
            relacao_cliente_invalid: false,
            token_enviado: '',
            loading_validacao: false,
            loading: false,
            error_message: '',
            success_message: ''
        }
    }

    gerarToken = (forma_envio = 'email') => {
        this.setState({
            loading: true
        })
        var url = ENDPOINT + "sessao_token/gerar_token";
        
        var formData = new FormData();

        if(this.props.user){
            formData.append('user_id', this.props.user.id);
            formData.append('user_tipo', this.props.tipo_cliente);
        }
        else{
            formData.append('user_id', this.state.relacao_cliente.id);
            formData.append('user_tipo', this.state.tipo_cliente);
        }

        formData.append('forma_envio', forma_envio);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({
                ...data.data,
                forma_envio: forma_envio,
                loading: false
            })

        })
        .catch((error) => {
            var data = error.response.data;
            toast.error(data.message);
        });
    }

    onData = (data) => {
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }

    validarToken = () => {
        this.setState({
            loading: true
        })
        var url = ENDPOINT + "sessao_token/validar_token";
        
        var formData = new FormData();
        formData.append('hash', this.state.hash);
        formData.append('code', this.state.token_enviado);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.onData(data);

        })
        .catch((error) => {
            var data = error.response.data;
            this.onData(data);
        });
    }

    onClose = () => {
        this.setState({
            forma_envio: null,
            tipo_cliente: 'aqpago',
            tipo_pesquisa: 'nome',
            hash: null,
            relacao_cliente: null,
            relacao_cliente_invalid: false,
            token_enviado: '',
            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose();
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }
    loadOptions = (inputValue, callback) => {
        var url = ENDPOINT + "user/all?"+this.state.tipo_pesquisa+"=" + inputValue;

        if(this.state.tipo_cliente == 'aqpasso'){
            url = ENDPOINT + "user/all?empresa_relacao=aqpasso&"+this.state.tipo_pesquisa+"=" + inputValue;
        } 
        else if(this.state.tipo_cliente == 'aqcontabil'){
            url = ENDPOINT + "user/all?empresa_relacao=aqcontabil&"+this.state.tipo_pesquisa+"=" + inputValue;
        }
        else{
            url = ENDPOINT + "user/all_aqbank?page=1&"+this.state.tipo_pesquisa+"=" + inputValue;
        }

        var myHeaders = new Headers();

        myHeaders.append('Authorization', 'Bearer ' + getToken());

        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
            .then(response => response.json())
            .then((p) => {
                var data = p.data;

                if(this.state.tipo_cliente == 'aqpasso' || this.state.tipo_cliente == 'aqcontabil'){
                    data.forEach((p, i) => {
                        data[i].value = p.id;
                        data[i].document = (p.cnpj ? p.cnpj : p.cpf);
                        data[i].nome = p.nome+ ' ' +p.sobrenome;
                        data[i].user_tipo = p.user_tipo;
                        data[i].label = (p.nome ? p.nome : p.responsavel.nome + ' '+p.responsavel.sobrenome) + ' - ' + (p.cnpj ? p.cnpj : p.cpf);
                    });
                }
                else{
                    data.forEach((p, i) => {
                        data[i].value = p.id;
                        data[i].document = (p.cnpj ? p.cnpj : p.cpf);
                        data[i].nome = p.nome;
                        data[i].user_tipo = 'aqpago';
                        data[i].label = p.nome + ' - ' +  (p.cnpj ? p.cnpj : p.cpf);
                    });
                }

                callback(this.filter(inputValue, data));
            })
            .catch(e => {
                callback(this.filter(inputValue, []));
            });
    }

    filter = (inputValue, data) => {
        return data.filter(i =>
            i.nome.toLowerCase()
        );
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }

    render() {
        return (
            <Modal isOpen={this.props.visible}>
                <ModalHeader>
                    Validação de Cliente
                </ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <div hidden={this.state.forma_envio !== null}>
                        <Form hidden={this.props.user}>
                            <FormGroup>
                                <Label>Relacionado a:</Label>
                                <br />
                                    <input type="radio" name="cliente_radio" onChange={() => this.setState({tipo_cliente: 'aqpago'})} checked={this.state.tipo_cliente === 'aqpago'} />&nbsp;Clientes AQPago&nbsp;&nbsp;
                                    <input type="radio" name="cliente_radio" onChange={() => this.setState({tipo_cliente: 'aqpasso'})} checked={this.state.tipo_cliente === 'aqpasso'}/>&nbsp;Clientes AQPasso&nbsp;&nbsp;
                                    <input type="radio" name="cliente_radio" onChange={() => this.setState({tipo_cliente: 'aqcontabil'})} checked={this.state.tipo_cliente === 'aqcontabil'}/>&nbsp;Clientes AQContabil
                                <AsyncSelect
                                    key={this.state.tipo_cliente + ' - ' + this.state.tipo_pesquisa}
                                    style={{ width: '100%' }}
                                    cacheOptions
                                    loadOptions={debounce(this.loadOptions, 1000)}
                                    defaultOptions
                                    isOptionDisabled={(option) => option.document === null}
                                    placeholder="Cliente"
                                    onChange={(relacao_cliente) => this.setState({ relacao_cliente })}
                                />
                                <small>Pesquisa por: &nbsp;
                                    <input type="radio" name="pesquisa_radio" onChange={() => this.setState({ tipo_pesquisa: 'nome' })} checked={this.state.tipo_pesquisa === 'nome'} />&nbsp;Nome&nbsp;&nbsp;
                                    <input type="radio" name="pesquisa_radio" onChange={() => this.setState({ tipo_pesquisa: 'cpf' })} checked={this.state.tipo_pesquisa === 'cpf'} />&nbsp;CPF&nbsp;&nbsp;
                                    <input type="radio" name="pesquisa_radio" onChange={() => this.setState({ tipo_pesquisa: 'cnpj' })} checked={this.state.tipo_pesquisa === 'cnpj'} />&nbsp;CNPJ&nbsp;&nbsp; </small>
                                <Input hidden invalid={this.state.relacao_cliente_invalid} />
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                        </Form>
                        <center>
                            <ButtonGroup>
                                <Button 
                                    color="success" 
                                    onClick={() => this.gerarToken('email')}>
                                    E-mail
                                </Button>
                                <Button 
                                    color="success" 
                                    onClick={() => this.gerarToken('telefone')}>
                                    SMS
                                </Button>
                                <Button color="success" disabled>
                                    Whatsapp (em breve)
                                </Button>
                            </ButtonGroup>
                        </center>
                    </div>
                    <div hidden={this.state.forma_envio == null}>
                        <center>
                            <Form>
                                <FormGroup>
                                    <InputText
                                        placeholder="Token"
                                        name="token_enviado"
                                        value={this.state.token_enviado}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Button 
                                        color="success"
                                        onClick={() => this.validarToken()}
                                    >
                                        Confirmar Token
                                    </Button>
                                </FormGroup>
                            </Form>
                        </center>
                    </div>
                </ModalBody>
                <ModalBody
                    style={{
                        minHeight: '114px',
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                    />

                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose()}>Fechar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ModalValidacaoToken;