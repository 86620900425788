import React, { useEffect, useState } from 'react';
import { NavbarBrand, Container, Button, Row, Col, ButtonGroup } from 'reactstrap';
import { FaBars } from 'react-icons/fa';
import './navbar-style.css';
import styled from "styled-components";
import { MdEdit, MdVpnKey } from 'react-icons/md';
import AddAdmin from '../Modals/AdminModals/AddAdmin';
import { getStatus, getToken, getUser, setStatusChat } from '../../auth/set_services';
import { requestInfo } from '../../auth/services';
import ModalNewPassword from '../Modals/AuthModals/ModalNewPassword';
import logo from '../../assets/logo_b_horizontal.png';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { ENDPOINT } from '../../auth/endpoint';
import axios from 'axios';


const Navb = styled.div`
  right: 0;
  width: ${props => (props.closed ? "100%" : "82%")};
  position: fixed;
  background-color: #52c565;
  transition: all 0.2s;
  z-index: 100;
  
  @media screen and (max-width: 1313px) {
    width: ${props => (props.closed ? "100%" : "79%")};
  }
  @media screen and (max-width: 1070px) {
    width: ${props => (props.closed ? "100%" : "74%")};
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const ChatStatus = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 12px;
    background-color: ${props => (props.color)};
`;

const ChatStatusContent = styled.div`
    display: flex;
    align-items: center;
    @media screen and (max-width: 800px) {
      b{
        display: none;
      }
    }
`;
  const estado_opcoes = [
    {id: 1, value: 'online', label: <ChatStatusContent><ChatStatus color="green"/> <b>Online</b></ChatStatusContent>},
    {id: 0, value: 'offline', label: <ChatStatusContent><ChatStatus color="red"/> <b>Offline</b></ChatStatusContent>},
    {id: 2, value: 'ocupado', label: <ChatStatusContent><ChatStatus  color="orange"/> <b>Ocupado</b></ChatStatusContent>},
  ];
const NavbarCurrent = props => {
    const [visible_password, setVisiblePassword] = useState(false);
    const [visible_data, setVisibleData] = useState(false);
    const [estado, setEstado] = useState(null);


    useEffect(() => {
      estado_opcoes.map(p => {
        if(p.id === getStatus()){
          return setEstado(p);
        }
        return '';
      });
    },[]);

    const onClose = (value) => {
        setVisibleData(value);
        setVisiblePassword(value);
        requestInfo(getToken());
    }

    const alterStatus = (status) => {
      var url = ENDPOINT+"admin/update-status-chat/"+status.id;
  
      var formData = new FormData();
      axios.post(url, formData, {
          headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer '+getToken()
          }
      })
      .then((response) => {
          var data = response.data;
          toast.success(data.message);
          setStatusChat(status.id);
          setEstado(status);
      })
      .catch((error) => {
          if(error.message === 'Network Error'){
              var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
              var obj = JSON.parse(response);
              toast.error(obj.errors);
          }

          var data = error.response.data;
          if(typeof data.errors !== 'undefined'){
            toast.error(data.errors);
          }
      });
  
  }

    return(
        <Container>
            <Navb className="navbar" bg="dark" closed={props.closed}>
              <Row style={{width: '100%'}}>
                <Col md={8} sm={8} xs={8}>
                  <NavbarBrand>
                      <Button color="light" outline onClick={() => props.onClosed()} ><FaBars style={{padding: 0}} size={17}/></Button>&nbsp;
                      &nbsp;&nbsp;<img height="38" alt="logo" src={logo}/>
                      &nbsp;
                  </NavbarBrand>
                </Col>
                <Col md={4} className="d-none d-md-table" style={{textAlign: 'left', padding: '0', display: 'table'}}>
                  <div style={{display: 'table-cell', width: '100%', paddingRight: '15px'}}>
                    <Select
                        components={{ DropdownIndicator:() => null,IndicatorSeparator:() => null }}
                        placeholder="Estado"
                        options={estado_opcoes}
                        isSearchable={false}
                        value={estado}
                        onChange={(e) => alterStatus(e)}
                    />
                  </div>
                  <NavbarBrand>
                    <ButtonGroup>
                      <Button color="light" outline onClick={() => setVisiblePassword(true)} ><MdVpnKey style={{padding: 0}} size={17}/></Button>
                      <Button color="light" outline onClick={() => setVisibleData(true)} ><MdEdit style={{padding: 0}} size={17}/></Button>
                    </ButtonGroup>
                  </NavbarBrand>
                </Col>
              </Row>
            </Navb>
            <AddAdmin
                  visible={visible_data}
                  editing={getUser()}
                  onClose={() => onClose(false)}
            />
            <ModalNewPassword
                visible={visible_password}
                editing={getUser()}
                onClose={() => onClose(false)}
            />
        </Container>
    );
}
export default NavbarCurrent;