import axios from "axios";
import { Component } from "react"
import { FaUser } from "react-icons/fa";
import AsyncSelect from 'react-select/async';
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken, getUser } from "../../auth/set_services";
import ClipLoader from 'react-spinners/ClipLoader'

class PermissoesPage extends Component{
    constructor(props){
        super(props);
        this.state = {
            activeTab: "1",
            nome: "",
            visible_email: false,
            /* funcionario_criador_nome: '',
            funcionario_conferencia_nome: '',
            funcionario_liberacao_nome: '',
            razao_bloqueio: "",
            data_hora_pagamento_conta: "",
            pagamento_liberado: "", */
            amount: "",
            fees: "",
            original_amount: "",
            status: "",
            confirmed: "",
            description: "",
            currency: "",
            payment_type: "",
            detalhes_liq: null,
            statement_descriptor: "",
            transaction_number: "",
            status: "",
            history: [],
            payment_method: null,
            point_of_sale: null,
            user: null,
            fee_details: [],
            contas_bancaria: [],
            anexos: [],
            emails_recebidos: [],
            transacoes: [],
            email: '',
            loading_payout: false,
            loading_liberacao: false,
            liberacao_permitida: false,
            pagamento_liquidado: false,
            operadores_permitidos: [],
            data_hora_pagamento_liquidado: null,
            motivo_pagamento_liquidado: null,
            pagamento_aqpago: null,
            pagamento_estornado: false,
            loading_user: false,
            
            file: null,
            file_64: '',
            descricao_image: '',
            modalImage: false,

            descricao_nota: '',
            modalNota: false,
            notas_privadas: [],
            notificacoes_bloqueio: [],
            emails_enviados: [],

            
            pagamento_liberado: null,
            pagamento_liberado_invalid: false,
            operador_liberacao: null,
            responsavel_liberacao: null,
            responsavel_liberacao_invalid: false,
            responsavel_conferencia: null,
            responsavel_conferencia_invalid: false,
            razao_bloqueio: '',
            razao_bloqueio_invalid: false,
            data_hora_docs: null,
            data_hora_docs_invalid: false,
            data_hora_liquidacao: null,
            data_hora_liquidacao_invalid: false,
            data_pagamento_conta: null,
            data_pagamento_conta_invalid: false,
            banco_liquidacao: null,
            liquidacao_pagamento_loading: false,

            estorno_date: null,
            estorno_date_invalid: false,
            estorno_resposta: '',
            estorno_resposta_invalid: false,
            estorno_arquivo: null,
            estorno_arquivo_invalid: false,
            url_arquivo: null,

            historico_prevencao: [],
            descricao: null,

            visible_nota: false,
        }
    }
    componentDidMount(){
        this.getRows();
    }
    loadAtendentesOptions = (inputValue, callback) => {
        var url = ENDPOINT + "admin/list?search=" + inputValue;
        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p.data;

            data.forEach((p, i) => {
                data[i].value = p.id;
                data[i].label = p.name;
            });

            callback(this.filterAtendentes(inputValue, p.data));
        });
    }

    filterAtendentes = (inputValue, data) => {
        return data.filter(i =>
            i.name.toLowerCase().includes(inputValue.toLowerCase())
        );
    }

    adicionarPermissao = () => {
        this.setState({
            loading_liberacao: true
        })

        var url = ENDPOINT + "juridico/add_op";
        var formData = new FormData();
        formData.append('admin_id', this.state.operador_liberacao.id);
        axios.post(url, formData, {
          headers: {
              Authorization: 'Bearer '+getToken()
          }})
        .then((response) => {
            this.setState({
                loading_liberacao: false
            })
        

            var data = response.data;
            toast.success(data.message);
            this.getRows();

        })
        .catch((error) => {
            this.setState({
                loading_liberacao: false
            })

            var data = error.response.data;
            toast.error(data.errors);
        })
    }
    removerPermissao = (admin_id) => {
        this.setState({
            loading_liberacao: true
        })

        var url = ENDPOINT + "juridico/remove_op";
        var formData = new FormData();
        formData.append('admin_id', admin_id);
        axios.post(url, formData, {
          headers: {
              Authorization: 'Bearer '+getToken()
          }})
        .then((response) => {
            this.setState({
                loading_liberacao: false
            })

            var data = response.data;
            toast.success(data.message);
            this.getRows();

        })
        .catch((error) => {
            this.setState({
                loading_liberacao: false
            })

            var data = error.response.data;
            toast.error(data.errors);
        });
    }
    getRows = () => {
        var url = ENDPOINT+"juridico/list_op_permission";

        axios.get(url, {
            headers: {
            Authorization: 'Bearer ' + getToken()
            }
        }).then((response) => {
            this.callbackRows(response.data)
        });
    }

    callbackRows(data) {
        this.setState({
            operadores_permitidos: data.data
        })
    }
    render(){
        return(
            <div>
                <h3>Permissões Juridico</h3>
                <hr />
                <div style={{display: 'flex'}} hidden={getUser().access_system != 'super_user'}>
                    <FaUser style={{padding: 0, margin: '9px'}}/> 
                    <div style={{width: '50%'}}>
                        {/* <Select 
                            placeholder="Usuário"
                            isSearchable={false} /> */}
                        <AsyncSelect
                            style={{width: '100%'}}           
                            cacheOptions
                            loadOptions={this.loadAtendentesOptions}
                            defaultOptions
                            placeholder="Operador"
                            isDisabled={this.state.loading_liberacao === true}
                            value={this.state.operador_liberacao}
                            onChange={(operador_liberacao) => this.setState({operador_liberacao})}
                        />
                    </div> 
                    <Button 
                        color='success'
                        onClick={() => this.adicionarPermissao()}
                        disabled={this.state.operador_liberacao == null || this.state.loading_liberacao === true}
                        style={{marginLeft: '9px'}}
                    >
                    {this.state.loading_liberacao === true ?
                        <ClipLoader color="#fff" size={15}/>
                        :
                        ''} Autorizar
                    </Button>
                </div>
                <hr/>
                <ul hidden={getUser().access_system != 'super_user'}>
                    {this.state.operadores_permitidos !== null ?
                        this.state.operadores_permitidos.map(e => {
                            return <li>
                                {e.operador !== null ? e.operador.name : ''} &nbsp;&nbsp;
                                <Button 
                                    color='danger'
                                    style={{
                                        padding: 2,
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                        fontSize: '12px'
                                    }}
                                    disabled={this.state.loading_liberacao === true}
                                    onClick={() => this.removerPermissao(e.id)}
                                >
                                    {this.state.loading_liberacao === true ?
                                        <ClipLoader color="#fff" size={15}/>
                                        :
                                        ''} Remover Autorização
                                </Button>
                            </li>
                        })
                        :
                        <></>
                    }
                    {/*  */}
                </ul>
            </div>

        )
    }
}

export default PermissoesPage;