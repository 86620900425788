import React from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, Row, Col, Input, CardFooter, Button, Label } from 'reactstrap';
import { columns } from './propsPayoutBlock';
import debounce from 'lodash/debounce';
import Pagination from '../Pagination';
import { InputText } from '../../InputText/InputText';
import { InputMasked } from '../../InputText/InputMasked';
import { MdAttachMoney, MdMail, MdSearch, MdSms } from 'react-icons/md';
import Select from 'react-select';
import { list_pagamentos_liberados, list_razao_bloqueio } from '../../../auth/listas';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import axios from 'axios';

var CurrencyInput = require('react-currency-masked-input');

const exportar_pagamentos = (status) => {
    toast.success("O relatório será baixado em breve");
    var url = ENDPOINT + "payout/exportar_pagamentos";

    if(status != null){
        url += '?status='+status.value
    }
      axios({
        url: url, //your url
        method: 'GET',
        responseType: 'blob', // important
        headers: {
          Authorization: 'Bearer '+getToken()
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'relatorio_de_pagamentos.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
  }


const modalidades = [
    { value: "chip", label: "CP - Cartão Presente" },
    { value: "online", label: "CNP - Cartão não Presente" },
];

const motivos = [
    { value: "cartao_proprio", label: "Cartão Próprio" },
    { value: "prevencao", label: "Prevencao" },
];

const getTotalValor = (pgamentos_array = []) => {
    var total = 0;
    pgamentos_array.forEach(e => {
        total += e.valor;
    });

    return total;
} 

export const TablePayoutBlock = (props) => {
    return (
        <Card>
            <CardBody>
                <Row >
                    <Col md={5}>
                        <div style={{ textAlign: 'left' }}>
                            <InputText
                                name="search"
                                placeholder="Buscar"
                                onChange={debounce((event) => props.isSearch(event), 1000)} />
                        </div>
                    </Col>
                </Row>
                <br />
                <div>
                    <br/>
                        {props.relatorio ?
                            <Row>
                                {Object.keys(props.relatorio).map(p => {
                                    return <Col style={{textAlign: 'center', marginBottom: '20px'}}>
                                        <h4 style={{margin: 0}}>{parseFloat(props.relatorio[p]).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h4>
                                        <small>{p}</small>
                                    </Col>
                                })}
                            </Row>
                        :
                        null}
                    {/* <Row>
                        <Col md={3} style={{textAlign: 'center'}}>
                            <h4 style={{margin: 0}}>{parseFloat(props.state.total_NVO).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h4>
                            <small>Análise Pendente Mês</small>
                        </Col>
                        <Col md={3} style={{textAlign: 'center'}}>
                            <h4 style={{margin: 0}}>{parseFloat(props.state.total_AMA).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h4>
                            <small>Análise Iniciada Mês</small>
                        </Col>
                        <Col md={3} style={{textAlign: 'center'}}>
                            <h4 style={{margin: 0}}>{parseFloat(props.state.total_APM).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h4>
                            <small>Análise Aprovada Mês</small>
                        </Col>
                        <Col md={3} style={{textAlign: 'center'}}>
                            <h4 style={{margin: 0}}>{parseFloat(props.state.total_RPM).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h4>
                            <small>Análise Reprovado</small>
                        </Col>
                    </Row> */}
                </div>
                <br/>
                <Row>
                    <Col md={6}>
                        <Select
                            placeholder="Status de Prevenção"
                            options={list_pagamentos_liberados}
                            value={props.pagamento_liberado}
                            onChange={props.onPagamentoLiberado}
                        />
                    </Col>
                    <Col md={6}>
                        <Select
                            placeholder="Modalidade"
                            options={modalidades}
                            isClearable
                            value={props.modalidade}
                            onChange={(value) => props.onChangeSelect('modalidade', value)}
                        />
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col md={6}>
                        <Select
                            placeholder="Tipo de Bloqueio"
                            options={motivos}
                            isClearable
                            value={props.motivo}
                            onChange={(value) => props.onChangeSelect('motivo', value)}
                        />
                    </Col>
                    <Col md={6}>
                        <Select
                            placeholder="Razao de Bloqueio"
                            options={list_razao_bloqueio}
                            isClearable
                            value={props.razao_bloqueio}
                            onChange={(value) => props.onChangeSelect('razao_bloqueio', value)}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={4}>
                        <div style={{ textAlign: 'left' }}>
                            <InputMasked
                                name="document"
                                mask={props.is_cnpj === false ? "999.999.999-99" : "99.999.999/9999-99"}
                                placeholder={props.is_cnpj === false ? "CPF" : "CNPJ"}
                                onChange={debounce((event) => props.isSearch(event), 500)}
                            />
                        </div>
                        </Col>
                        <Col md={3}>
                        <div style={{marginTop: '17px'}}>
                            &nbsp;&nbsp;<input 
                                type="checkbox"
                                checked={props.is_cnpj}
                                onChange={() => props.onSearchDocument()}/> Pesquisar CNPJ</div>
                    </Col>
                    <Col>
                        <Button color='success' onClick={() => exportar_pagamentos(props.pagamento_liberado)}>Exportar Pagamentos</Button>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={5}>
                            <input
                                type="checkbox"
                                checked={props.mail_received}
                                name="mail_received"
                                onChange={(value) => props.onMailReceived(value.target.checked)} /> Filtrar por Pagamentos com e-mails pendentes
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={5}>
                            <input
                                type="checkbox"
                                checked={props.filter}
                                name="filter"
                                onChange={(value) => props.onFilter(value.target.checked)} /> Filtrar por data de bloqueio:
                    </Col>
                </Row>
                <Row>
                    <Col>De:
                    <Input
                            name="date_from"
                            type="date"
                            //disabled
                            disabled={!(props.filter === true)}
                            value={props.date_from}
                            onChange={(event) => props.isSearch(event)} /></Col>
                    <Col>Até:
                    <Input
                            name="date_to"
                            type="date"
                            disabled={!(props.filter === true)}
                            //disabled
                            value={props.date_to}
                            onChange={(event) => props.isSearch(event)} /></Col>
                </Row>
                <br/>
                <small>Filtro por Valor</small>
                <Row>
                    <Col md={6}>
                        <Label>De:</Label>
                        <CurrencyInput 
                            inputMode="tel"
                            className="form-control" 
                            currency="BRL"
                            name="valor_de"
                            //value={valor}
                            defaultValue={'0.00'}
                            onChange={debounce((event) => props.isSearch(event), 500)} />
                    </Col>
                    <Col md={6}>
                        <Label>Até:</Label>
                        <CurrencyInput 
                            inputMode="tel"
                            className="form-control" 
                            currency="BRL"
                            name="valor_ate"
                            //value={valor}
                            defaultValue={'0.00'}
                            onChange={debounce((event) => props.isSearch(event), 500)} />
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col>
                        <Button 
                            color='success'
                            style={{fontSize: '19px'}}
                            onClick={() => props.buscarPayout()}
                        >
                            <MdSearch style={{padding: 0}}/> Buscar
                        </Button>
                    </Col>

                </Row>
            </CardBody>
            <hr style={{ marginBottom: '4px' }} />
            {props.selectableRows ? 
                <>
                    <div style={{textAlign: 'center'}}>
                        <Button 
                            disabled={props.payouts_current.selectedRows.length < 1 || props.loading_sms} 
                            color="success" 
                            onClick={() => props.enviarSMS()}
                            style={{ 
                                marginLeft: '24px', 
                                marginTop: '5px', 
                                width: '260px'
                        }}> 
                            {props.loading_sms ?
                            <>
                                <ClipLoader color="#ffffff" size={18}/>
                                &nbsp;
                            </>
                            :   <MdSms />}
                            Re-Enviar SMS de Bloqueio
                        </Button>
                        <Button 
                            disabled={props.payouts_current.selectedRows.length < 1 || props.loading_emails} 
                            color="success" 
                            onClick={() => props.enviarEmail()}
                            style={{ 
                                marginLeft: '24px', 
                                marginTop: '5px', 
                                width: '260px'
                        }}>{props.loading_emails ?
                            <>
                                <ClipLoader color="#ffffff" size={18}/>
                                &nbsp;
                            </>
                            :<MdMail />}
                            Re-Enviar Email de Bloqueio
                        </Button>
                        <Button
                            color="info"
                            onClick={() => props.modalSendEmail(true)} 
                            disabled={props.payouts_current.selectedRows.length < 1 || props.loading_manual_emails} 
                            style={{ 
                                    marginLeft: '24px', 
                                    marginTop: '5px', 
                                    width: '160px'
                            }}>
                            {props.loading_manual_emails ?
                                <>
                                    <ClipLoader color="#ffffff" size={18}/>
                                    &nbsp;
                                </>
                                :<MdMail />}
                            Enviar E-mail's
                        </Button>
                        <Button
                            color="info"
                            disabled={props.payouts_current.selectedRows.length < 1 || props.loading_sms}
                            onClick={() => props.modalSendSms(true)} 
                            style={{ 
                                    marginLeft: '24px', 
                                    marginTop: '5px', 
                                    width: '150px'
                            }}>
                            {props.loading_sms ?
                                <>
                                    <ClipLoader color="#ffffff" size={18}/>
                                    &nbsp;
                                </>
                                :<MdSms />}
                            Enviar SMS's
                        </Button>
                        <Button 
                            color='warning'
                            disabled={props.payouts_current.selectedRows.length < 1 || props.loading_emails} 
                            onClick={() => props.liberarPagamentos()}
                            style={{ 
                                marginLeft: '24px', 
                                marginRight: '24px', 
                                marginTop: '5px', 
                                width: '260px'
                            }}
                            >{props.loading_emails ?
                                <>
                                    <ClipLoader color="#ffffff" size={18}/>
                                    &nbsp;
                                </>
                                :<MdAttachMoney />}
                                Liberar Pagamento
                        </Button>
                        <Button 
                            color='warning'
                            disabled={props.payouts_current.selectedRows.length < 1 || props.loading_emails} 
                            onClick={() => props.liquidarPagamentos()}
                            style={{ 
                                marginLeft: '24px', 
                                marginRight: '24px', 
                                marginTop: '5px', 
                                width: '260px'
                            }}
                            >{props.loading_emails ?
                                <>
                                    <ClipLoader color="#ffffff" size={18}/>
                                    &nbsp;
                                </>
                                :<MdAttachMoney />}
                                Liquidar Pagamento
                        </Button>
                    </div>
                    <hr style={{ marginBottom: '4px' }} />
                    <center>
                        {getTotalValor(props.payouts_current.selectedRows) > 0 ?
                        <div>
                            <small>Valor Total:</small>
                            <h4>{parseFloat(getTotalValor(props.payouts_current.selectedRows)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h4>
                            <hr style={{ marginBottom: '4px' }} />
                        </div>
                        :<></>}
                    </center>
                </>
            :
            <></>}
            <DataTable
                noHeader={true}
                button={true}
                selectableRows={props.selectableRows}
                onSelectedRowsChange={(e) => props.onSelectedRows(e)}
                noDataComponent={props.noDataComponent}
                columns={columns(props.onPayload, props.onDelete, props.onDetails)}
                data={props.data}
                theme="default"
            />
            <CardFooter style={{ textAlign: 'center' }}>
                <Pagination
                    onPageChange={props.onPageChange}
                    activePage={props.activePage}
                    totalPage={props.totalPage}
                />
            </CardFooter>
        </Card>
    )
}