import Moment from 'moment';


export const columns = (onMessage) => [
    {
        name: "Descrição",
        selector: "descricao",
        sortable: false,
    },
    {
        name: "Criada por",
        selector: "empresa",
        sortable: false,
        cell: row => row.usuario !== null ? row.usuario.nome+' '+row.usuario.sobrenome : (row.admin != null ? row.admin.name : 'Sem informações')
    },
    {
        name: "Protocolo de Atendimento (Chat)",
        selector: "protocolo",
        sortable: false,
        cell: row => row.chat !== null ? row.chat.protocolo_atendimento : (row.protocolo_atendimento !== null ? row.protocolo_atendimento : 'Sem informações')
    },
    {
        name: "Protocolo Personalizado",
        selector: "protocolo_personalizado",
        sortable: false,
        cell: row => row.protocolo_personalizado !== null ? row.protocolo_personalizado : 'Sem informação'
    },
    /* {
        name: 'Departamento',
        selector: 'departamento',
        sortable: false,
        cell: row => row.chat !== null ? row.chat.nome : 'Sem informação'
    }, */
    {
        name: 'Data de criação da nota',
        selector: 'created_at',
        sortable: false,
        cell: row =>  Moment(new Date(row.created_at)).format('DD/MM/YYYY HH:mm')
    },
  ];
/* const getStatusChat = (status) => {
    switch(status){
        case 0: return <div style={{fontSize: '14px'}}><BsChatFill/>Aguardando Atendente</div>;
        case 1: return <div style={{color: '#2196f3', fontSize: '14px'}}><BsChatFill/>Em andamento</div>;
        case 2: return <div style={{color: '#2a770a', fontSize: '14px'}}><BsChatFill/>Finalizado</div>;
        case 3: return <div style={{color: '#df1818', fontSize: '14px'}}><BsChatFill/>Perdido</div>;
        default : return status;
    }
} */