import { Component } from "react";
import { MdSend } from "react-icons/md";
import { Button, Card, CardBody } from "reactstrap";
import Moment from 'moment';
import { getPermissoes, getToken } from '../../auth/set_services';
import PropagateLoader from "react-spinners/PropagateLoader";
import { GiBoxUnpacking } from "react-icons/gi";
import { FaBox } from "react-icons/fa";
import axios from "axios";
import { ENDPOINT } from "../../auth/endpoint";
import { toast } from "react-toastify";
import { TableNotasFiscais } from "../../components/Tables/TableNotasFiscais/TableNotasFiscais";
import { getRows, getRowsPerPage } from '../../components/Tables/TableNotasFiscais/propsNotasFiscais';
import ModalDialog from "../../components/Modals/ReturnsApiModals/ModalDialog";

class NotaFiscal extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading_table: true,
            rowData: [],
            activePage: 1,
            status_envio: '',
            cpf_current: null,
            cnpj_current: '',
            document: '',
            motivo_cancelamento: '',
            totalPage: 1,
            filter: false,
            is_cnpj: false,
            search: '',
            visible_contestacao: false,
            current_transacao: null,

            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),

            delete_id: -1,
            error_message: '',
            success_message: '',
            loading: false,
            visible_delete: false,

        }
    }
    componentDidMount() {
        getRows(this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.rowData = [];
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    handleChangeCancel = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        this.setState({ ...state });
    }
    
    onShowDelete = (delete_id) => {
        this.setState({
            delete_id,
            visible_delete: true
        })
    }

    onFilter = (filter) => {
        this.setState({ filter, loading_table: true });
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onError(){
        this.setState({
            loading: false,
            error_message: ''
        })
    }


    onData = (data) => {
        if(data.success === true){
            this.setState({
                success_message: data.message
            });
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onClose(){
        this.setState({
            delete_id: -1,
            error_message: '',
            success_message: '',
            motivo_cancelamento: '',
            loading: false,
            visible_delete: false,
        })
    }

    onDelete = () => {
        this.setState({loading: true})
        var url = ENDPOINT+"nota_fiscal/cancelar_nota/"+this.state.delete_id+"?motivo_cancelamento="+this.state.motivo_cancelamento;
        axios.delete(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.onClose();
            getRows(this.state, this.callbackRows.bind(this));
            return data;
        })
        .catch((error) => {
            var response = '';
            var obj;
            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            if(data.exception){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            this.onData(data);
            return data;
        });
    }

    render(){
        return(
            <div>
                <h3>Notas Fiscais</h3>
                <hr />
                <Card>
                    <TableNotasFiscais
                        isSearch={this.handleChange}
                        data={this.state.rowData}
                        is_cnpj={this.state.is_cnpj}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        onShowDelete={this.onShowDelete}
                        filter={this.state.filter}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhuma remessa encontrada
                            </div></CardBody></Card>
                        }
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <ModalDialog
                    visible={this.state.visible_delete}
                    onError={this.onError.bind(this)}
                    message="Deseja remover esse operador do grupo ?"
                    onClose={() => this.onClose()}
                    error_message={this.state.error_message}
                    loading={this.state.loading}
                    input_is={true}
                    onChange={this.handleChangeCancel}
                    onSave={this.onDelete.bind(this)}
                    success_message={this.state.success_message}
                />
            </div>
        )
    }
}
export default NotaFiscal;