import React, { Component } from 'react';
import { Card, CardBody, Col, Nav, NavLink, Row, TabPane,FormFeedback, TabContent, Button, Collapse, FormGroup, Label, InputGroup, Input, InputGroupAddon, Form } from "reactstrap";
import DataTable from 'react-data-table-component';
import { MdAttachment, MdNote, MdClear, MdMoneyOff, MdSurroundSound, MdImage, MdArchive, MdArrowBack, MdCheck, MdCancel, MdVerifiedUser } from "react-icons/md";
import classnames from 'classnames';
import styled from "styled-components";
import { ENDPOINT } from "../../auth/endpoint";
import { getPermissoes, getToken, getUser } from "../../auth/set_services";
import moment from "moment";
import { list_bancos_retidos, list_pagamentos_liberados, list_razao_bloqueio } from "../../auth/listas";
import get_extension from 'get-url-extension';
import { toast } from "react-toastify";
import axios from "axios";
import { ImFilePdf } from 'react-icons/im';
import ContasBancarias from '../../components/UsersList/ContasBancarias';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import sanitizeHtml from 'sanitize-html';
import SendEmail from '../../components/Modals/SendsModals/SendEmail';
import SendEmailByTransacao from '../../components/Modals/TransacoesModal/SendEmailByTransacao';
import ClipLoader from 'react-spinners/ClipLoader'
import { FaUser } from 'react-icons/fa';
import FadeLoader from "react-spinners/FadeLoader";

const GetIconExtension = (props) => {
    var extension = get_extension(props.url);
    
    if(extension === '.webp' ||
        extension === '.png' ||
        extension === '.jpg' ||
        extension === '.avg' ||
        extension === '.gif' ){
        return <MdImage />;
    }
    else if(extension === '.webm' ||
        extension === '.3gp' ||
        extension === '.ogg' ||
        extension === '.mp4'){
        return <MdSurroundSound/>;;
    }
    else if(extension === '.pdf'){
        return <ImFilePdf/>;;
    }

    return <MdArchive/>;;
}


const colunas_transacoes = [
    {
        name: "ID",
        selector: "api_transacao_id",
        width: '265px',
        sortable: false,
    },
    {
        name: "Data",
        selector: "created_at",
        sortable: false,
        width: '150px',
        cell: row => row.created_at !== null ? moment(row.created_at).format('DD/MM/YYYY HH:mm') : ''
    },
    {
        name: "Serviço",
        selector: "produto_servico",
        sortable: false,
        width: '150px',
        cell: p => produto_servico_format(p.produto_servico)
    },
    {
        name: "Forma de Pagamento",
        selector: "forma_pagamento",
        sortable: false,
        width: '100px',
        cell: p => payment(p.forma_pagamento)
    },
    {
        name: 'Status de Pagamento',
        selector: 'status_pagamento',
        sortable: false
    },
    {
        name: "Valor",
        selector: "valor",
        sortable: false,
        width: '150px',
        cell: p => <>R$ {parseFloat(p.valor).toFixed(2).toString().replace('.', ',')}</>
    },
];

const colunas_pagamentos = [
    {
        name: "ID",
        selector: "id",
        width: '280px',
        sortable: false,
    },
    {
        name: "Data",
        selector: "created_at",
        sortable: false,
        width: '150px',
        cell: row => row.created_at !== null ? moment(row.created_at).format('DD/MM/YYYY HH:mm') : ''
    },
    {
        name: "Operação",
        selector: "operation_type",
        sortable: false,
        width: '15%',
        cell: p => operation(p.operation_type)
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: false,
        cell: row => row.status !== 'succeeded' && row.error_message !== null ? <>{status_transacao(row.status)} - ERRO NA TRANSAÇÃO: {row.response_message}</> : status_transacao(row.status),
    },
    {
        name: "Valor",
        selector: "amount",
        sortable: false,
        width: '170px',
        cell: p => <>R$ {parseFloat(p.amount).toFixed(2).toString().replace('.', ',')}</>
    },
];

const produto_servico_format = (produto_servico) => {
    switch(produto_servico){
        case 'cobrancas' : return 'Cobranças / Link de Pagamento';
        case 'recargas' : return 'Recarga';
        case 'ecommerce' : return 'Eccommerce';
        case 'whmcs' : return 'WHMCS';
        case 'maquinas': return 'Compra de Maquina';
        case 'taxa': return 'Taxa';
        case 'validacao_cartao': return 'Validação de Cartão';
        default: return produto_servico
    }

}
const status_transacao = (payment_type) => {
    switch(payment_type){
        case 'succeeded' : return <><MdCheck />Aprovada</>;
        case 'canceled' : return <><MdClear/>Cancelada</>;
        case 'pending' : return 'Pendente';
        case 'failed' : return <><MdClear/>Falhada</>;
        case 'disputed': return 'Em disputa';
        default: return payment_type
    }
}
const status_transacao_not_svg = (payment_type) => {
    switch(payment_type){
        case 'succeeded' : return 'Aprovada';
        case 'canceled' : return 'Cancelada';
        case 'pending' : return 'Pendente';
        case 'failed' : return 'Falhada';
        case 'disputed': return 'Em disputa';
        default: return payment_type
    }
}
const payment = (payment_type) => {
    switch(payment_type){
        case 'credit' : return 'Crédito';
        case 'boleto' : return 'Boleto';
        case 'debit' : return 'Débito';
        default: return payment_type
    }
}
const operation = (operation_type) => {
    switch(operation_type){
        case 'created' : return 'Criado';
        case 'authorization' : return 'Autorizado';
        case 'pre_authorization' : return 'Pré Autorizado';
        case 'capture' : return 'Capturado';
        case 'void' : return 'Estornado';
        default: return operation_type
    }

}
const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
`;

const getPayout = (id, callback) => {
    var url = ENDPOINT + "payout/get/" + id;

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
        .then(response => response.json())
        .then((data) => {
            callback(data);
        })
}


const getUserAqbank = (id, callback) => {
    var url = ENDPOINT + "user/get_aqbank/"+id;

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        callback(p);
    })
}

const get_payout = () => {
    return window.location.pathname.replace('/payout-detalhes/', '')
}

class ResumoPayoutBloqueado extends Component{
    constructor(props){
        super(props);
        this.state = {
            payout_id: get_payout(),
            activeTab: "1",
            nome: "",
            visible_email: false,
            /* funcionario_criador_nome: '',
            funcionario_conferencia_nome: '',
            funcionario_liberacao_nome: '',
            razao_bloqueio: "",
            data_hora_pagamento_conta: "",
            pagamento_liberado: "", */
            amount: "",
            fees: "",
            original_amount: "",
            status: "",
            confirmed: "",
            description: "",
            currency: "",
            payment_type: "",
            detalhes_liq: null,
            statement_descriptor: "",
            transaction_number: "",
            status: "",
            history: [],
            payment_method: null,
            point_of_sale: null,
            user: null,
            fee_details: [],
            contas_bancaria: [],
            anexos: [],
            emails_recebidos: [],
            transacoes: [],
            email: '',
            loading_payout: false,
            loading_liberacao: false,
            liberacao_permitida: false,
            pagamento_liquidado: false,
            operadores_permitidos: [],
            data_hora_pagamento_liquidado: null,
            motivo_pagamento_liquidado: null,
            pagamento_aqpago: null,
            pagamento_estornado: false,
            loading_user: false,
            
            file: null,
            file_64: '',
            descricao_image: '',
            modalImage: false,

            descricao_nota: '',
            modalNota: false,
            notas_privadas: [],
            notificacoes_bloqueio: [],
            emails_enviados: [],

            
            pagamento_liberado: null,
            pagamento_liberado_invalid: false,
            operador_liberacao: null,
            responsavel_liberacao: null,
            responsavel_liberacao_invalid: false,
            responsavel_conferencia: null,
            responsavel_conferencia_invalid: false,
            razao_bloqueio: '',
            razao_bloqueio_invalid: false,
            data_hora_docs: null,
            data_hora_docs_invalid: false,
            data_hora_liquidacao: null,
            data_hora_liquidacao_invalid: false,
            data_pagamento_conta: null,
            data_pagamento_conta_invalid: false,
            banco_liquidacao: null,
            liquidacao_pagamento_loading: false,

            estorno_date: null,
            estorno_date_invalid: false,
            estorno_resposta: '',
            estorno_resposta_invalid: false,
            estorno_arquivo: null,
            estorno_arquivo_invalid: false,
            url_arquivo: null,

            historico_prevencao: [],
            descricao: null,

            visible_nota: false,
        }
        this.myRef = React.createRef();
    }

    componentDidMount() {
        getPayout(this.state.payout_id, this.callbackRows.bind(this));
    }

    listData = [
        { name: <><MdMoneyOff/>Informações de Payout Bloqueado</>, active: '1' },
        { name: 'Transações', active: '2' },
        { name: <><MdAttachment />Anexos </>, active: '3' },
        { name: <><MdNote />Notas </>, active: '4' },
        { name: <>Status de Prevenção</>, active: '5' },
        { name: <>Estorno de Transação </>, active: '6' },
        { name: <>Liquidação de Pagamento</>, active: '7' },
        getUser().access_system == 'super_user' ? { name: <>Permissão para Liquidação de Pagamento</>, active: '8' } : {},
    ];

    callbackRows(data) {
        var data_hora_docs = '';
        var data_pagamento_conta = '';
        var is_date = '';
        var pagamento_liberado = null;
        var razao_bloqueio = null;
        var banco_liquidacao = null;

        if(data.anexos !== undefined && data.anexos.length) {
            var urlApi = ENDPOINT.replace('api/','')

            data.anexos.map((anexo, ps) => {
                if(data.anexos[ps].url_anexo !== undefined) {
                    data.anexos[ps].url_anexo = anexo.url_anexo.replace('https://apicrm.aqbank.io/', urlApi)
                }
            })
        }

        list_pagamentos_liberados.forEach(p => {
            if(p.value === data.pagamento_liberado){
                pagamento_liberado = p; 
           } 
        });

        list_razao_bloqueio.forEach(p => { 
            if(p.value === data.razao_bloqueio){ 
                razao_bloqueio = p; 
            }
        });

        list_bancos_retidos.forEach(p => {
            if(p.value === data.banco_liquidacao){ 
                banco_liquidacao = p; 
            }

        })

        
        if(data.data_hora_documentos !== null){
            is_date = new Date(data.data_hora_documentos);
            console.log(is_date);
            if(is_date != 'Invalid Date'){
                is_date.setMinutes(is_date.getMinutes() - is_date.getTimezoneOffset());
                data_hora_docs = is_date.toISOString().slice(0,16);
            }
        }
        if(data.data_hora_pagamento_conta !== null){
            is_date = new Date(data.data_hora_pagamento_conta);
            console.log(is_date);
            if(is_date != 'Invalid Date'){
                is_date.setMinutes(is_date.getMinutes() - is_date.getTimezoneOffset());
                data_pagamento_conta = is_date.toISOString().slice(0,16);
            }
        }
        
        this.setState({
            pagamento_liberado: pagamento_liberado,
            banco_liquidacao: banco_liquidacao,
            responsavel_liberacao: data.funcionario_liberacao !== null ? { value: data.funcionario_liberacao.id, label: data.funcionario_liberacao.name } : null,
            responsavel_conferencia: data.funcionario_conferencia !== null ? { value: data.funcionario_conferencia.id, label: data.funcionario_conferencia.name } : null,
            razao_bloqueio: razao_bloqueio, //data.razao_bloqueio,
            data_hora_docs: data_hora_docs,
            estorno_date: data.data_estorno,
            liberacao_permitida: data.liberacao_permitida,
            pagamento_liquidado: Boolean(data.pagamento_liquidado),
            pagamento_estornado: Boolean(data.pagamento_estornado),
            pagamento_aqpago: data.pagamento_aqpago,
            motivo_pagamento_liquidado: data.motivo_pagamento_liquidado !== null ? data.motivo_pagamento_liquidado : '',
            data_hora_pagamento_liquidado: data.data_hora_pagamento_liquidado,
            operadores_permitidos: data.operadores_permitidos,
            estorno_resposta: data.resposta_solicitacao_estorno,
            url_arquivo: data.pedido_solicitacao_estorno,
            data_pagamento_conta:data_pagamento_conta,
            detalhes_liq: data.detalhes_liq,
            anexos: data.anexos,
            id_payout: data.id,
            nome: data.nome,
            notas_privadas: data.notas_privadas,
            email: data.email,
            historico_prevencao: data.historico_prevencao,
            emails_recebidos: data.notificacoes_bloqueio,
            emails_enviados: data.emails_enviados,
            transacoes: data.transacoes,
            user: data.user,
            loading_user: true
        });

        var payload = data.payout_detalhes;
        if(payload !== null){
            var transation = payload;
            if(transation.payment_method){
                this.setState({
                    payment_method: transation.payment_method,
                })
            }
            console.log(transation)
            this.setState({
                amount: transation.amount,
                fees: transation.fees,
                descricao: transation.description,
                original_amount: transation.original_amount,
                status: transation.status,
                confirmed: transation.confirmed,
                currency: transation.currency,
                payment_type: transation.payment_type,
                statement_descriptor: transation.statement_descriptor,
                transaction_number: transation.id,
                status: transation.status,
                history: transation.history,
                point_of_sale: transation.point_of_sale
            });
        }
        getUserAqbank(data.documento, this.callbackContaBancaria);
    }
    callbackContaBancaria = (data) => {
        
        if(typeof data.data !== 'undefined'){
            this.setState({
                contas_bancaria: data.data.bank_accounts
            });
        }

    }

    handleFile = (e) => {
        if(e.target.files.length > 0){
            this.setState({
                file: e.target.files[0]
            });
            this.toBase64(e.target.files[0]);
        }
    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(
            this.setState({file_64: reader.result})
        );
        reader.onerror = error => reject(error);
    });

    Navs = () => {
        return <Nav tabs>
            {this.listData.map((p, index) => {
                return <NavLink
                    key={index}
                    className={
                        classnames({ active: this.state.activeTab === p.active })}
                    onClick={() => { this.setState({ activeTab: p.active }); }}>
                    <span>{p.name}</span>
                </NavLink>
            })}
        </Nav>
    }
    getFileExtension = (url) =>{
        var extension = get_extension(url);

        if(extension === '.webp' ||
            extension === '.png' ||
            extension === '.jpg' ||
            extension === '.avg' ||
            extension === '.gif' ){
            return 'image'
        }
        else if(extension === '.webm' ||
            extension === '.3gp' ||
            extension === '.ogg' ||
            extension === '.mp4'){
                return 'video';
        }
        else if(extension === '.pdf'){
            return 'pdf';
        }

        return 'pdf';
    }

    getColorExtension = (url) =>{
        var extension = get_extension(url);

        if(extension === '.webp' ||
            extension === '.png' ||
            extension === '.jpg' ||
            extension === '.avg' ||
            extension === '.gif' ){
            return '#828282';
        }
        else if(extension === '.webm' ||
            extension === '.3gp' ||
            extension === '.ogg' ||
            extension === '.mp4'){
                return '#828282';
        }
        else if(extension === '.pdf'){
            return '#880808';
        }

        return '#828282';
    }

    adicionarAnexo = () => {
        var url = ENDPOINT + "payout/novo-anexo";
        var formData = new FormData();
        formData.append('descricao', this.state.descricao_image);
        formData.append('id_payout', this.state.id_payout);
        formData.append('anexo', this.state.file_64);

        axios.post(url, formData, {
          headers: {
              Authorization: 'Bearer '+getToken()
          }})
        .then((response) => {
            var data = response.data;
            toast.success(data.message);

            getPayout(this.state.payout_id, this.callbackRows.bind(this));

            this.setState({
                modalImage: false,
                descricao_image: '',
                file: null,
                file_64: ''
            });
        })
        .catch(() => {
            toast.error('Ocorreu um erro');
        });
    }

    adicionarNota = () => {
        var url = ENDPOINT + "payout/nova-nota";
        var formData = new FormData();
        formData.append('descricao', this.state.descricao_nota);
        formData.append('id_payout', this.state.id_payout);

        axios.post(url, formData, {
          headers: {
              Authorization: 'Bearer '+getToken()
          }})
        .then((response) => {
            var data = response.data;
            toast.success(data.message);

            getPayout(this.state.payout_id, this.callbackRows.bind(this));

            this.setState({
                modalNota: false,
                descricao_nota: ''
            });
        })
        .catch(() => {
            toast.error('Ocorreu um erro');
        });
    }
    handleInputChange = (e) => {
      const { name, value } = e.target;
      
      this.setState({ [name]: value });
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({ [name]: value });
    }

    validFieldsEditPagamento = () => {
        var invalids = 0;

        /* if(this.state.pagamento_liberado !== null){
            this.setState({
                pagamento_liberado_invalid: false
            })
        }
        else{
            invalids++;
            this.setState({
                pagamento_liberado_invalid: true
            })
        }

        if(this.state.responsavel_conferencia !== null){
            this.setState({
                responsavel_conferencia_invalid: false
            })
        }
        else{
            invalids++;
            this.setState({
                responsavel_conferencia_invalid: true
            })
        }

        if(this.state.responsavel_liberacao !== null){
            this.setState({
                responsavel_liberacao_invalid: false
            })
        }
        else{
            invalids++;
            this.setState({
                responsavel_liberacao_invalid: true
            })
        }

        if(this.state.razao_bloqueio !== null){
            this.setState({
                razao_bloqueio_invalid: false
            })
        }
        else{
            invalids++;
            this.setState({
                razao_bloqueio_invalid: true
            })
        }

        if(this.state.data_hora_docs !== null){
            this.setState({
                data_hora_docs_invalid: false
            })
        }
        else{
            invalids++;
            this.setState({
                data_hora_docs_invalid: true
            })
        }

        if(this.state.data_pagamento_conta !== null){
            this.setState({
                data_pagamento_conta_invalid: false
            })
        }
        else{
            invalids++;
            this.setState({
                data_pagamento_conta_invalid: true
            })
        } */

        if(invalids > 0){
            return false;
        }

        return true;
    }

    validFieldsEditTransacoes = () => {
        var invalids = 0;

        if(this.state.estorno_resposta.length > 0){
            this.setState({
                estorno_resposta_invalid: false
            })
        }
        else{
            invalids++;
            this.setState({
                estorno_resposta_invalid: true
            })
        }

        if(this.state.estorno_date !== null){
            this.setState({
                estorno_date_invalid: false
            })
        }
        else{
            invalids++;
            this.setState({
                estorno_date_invalid: true
            })
        }

        if(invalids > 0){
            return false;
        }

        return true;
    }
    payoutEditTransações = () => {
        if(this.validFieldsEditTransacoes() === true){
            var url = ENDPOINT + "payout/edit_transacoes";
            var myHeaders = new Headers();
            
            myHeaders.append('Authorization', 'Bearer ' + getToken());
            var formData= new FormData();
            formData.append('id_transacao', this.state.payout_id);
            if(this.state.url_arquivo === null && this.state.estorno_arquivo !== null){
                formData.append('pedido_solicitacao_estorno', this.state.estorno_arquivo);
            }
            formData.append('resposta_solicitacao_estorno', this.state.estorno_resposta);
            formData.append('data_estorno', this.state.estorno_date) ;
            
            
            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }
            })
            .then((response) => {
                var data = response.data;
                toast.success(data.message);
                getPayout(this.state.payout_id, this.callbackRows.bind(this));
                return data;
            })
            .catch((error) => {
                if(error.message === 'Network Error'){
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    toast.error(obj.errors)
                    return obj;
                }
                var data = error.response.data;
                toast.error(data.errors);
                return data;
            });
        }
    }

    payoutEditPagamento = () => {
        if(this.validFieldsEditPagamento() === true){
            var url = ENDPOINT + "payout/edit_pagamento";
            var myHeaders = new Headers();
            
            myHeaders.append('Authorization', 'Bearer ' + getToken());
            var formData= new FormData();
            formData.append('id_transacao', this.state.payout_id);
            if(this.state.razao_bloqueio !== null){
                formData.append('razao_bloqueio', this.state.razao_bloqueio.value);
            }

            if(this.state.responsavel_conferencia !== null){
                formData.append('responsavel_conferencia_id', this.state.responsavel_conferencia.value);
            }

            if(this.state.responsavel_liberacao !== null){
                formData.append('responsavel_liberacao_id', this.state.responsavel_liberacao.value);
            }

            if(this.state.pagamento_liberado !== null){
                formData.append('pagamento_liberado', this.state.pagamento_liberado.value);
            }

            if(this.state.data_hora_docs !== null){
                formData.append('data_hora_documentos', this.state.data_hora_docs);
            }
            
            if(this.state.banco_liquidacao !== null){
                formData.append('banco_liquidacao', this.state.banco_liquidacao.value);
            }

            if(this.state.data_pagamento_conta !== null){
                formData.append('data_hora_pagamento_conta', this.state.data_pagamento_conta);
            }
            if(this.state.data_hora_liquidacao !== null){
                formData.append('data_hora_liquidacao', this.state.data_hora_liquidacao);
            }
            
            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }
            })
            .then((response) => {
                var data = response.data;
                toast.success(data.message);
                getPayout(this.state.payout_id, this.callbackRows.bind(this));
                return data;
            })
            .catch((error) => {
                if(error.message === 'Network Error'){
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    toast.error(obj.errors)
                    return obj;
                }
                var data = error.response.data;
                toast.error(data.errors);
                return data;
            });
        }
    }
    payoutExtornoTransações = () => {
        if(this.validFieldsEditPagamento() === true){
            var url = ENDPOINT + "payout/extornar_pagamento";
            var myHeaders = new Headers();
            
            myHeaders.append('Authorization', 'Bearer ' + getToken());
            var formData= new FormData();
            formData.append('id_transacao', this.state.payout_id);
            if(this.state.url_arquivo === null && this.state.estorno_arquivo !== null){
                formData.append('pedido_solicitacao_estorno', this.state.estorno_arquivo);
            }
            formData.append('resposta_solicitacao_estorno', this.state.estorno_resposta);
            formData.append('data_estorno', this.state.estorno_date) ;
            
            
            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }
            })
            .then((response) => {
                var data = response.data;
                toast.success(data.message);
                getPayout(this.state.payout_id, this.callbackRows.bind(this));
                return data;
            })
            .catch((error) => {
                if(error.message === 'Network Error'){
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    toast.error(obj.errors)
                    return obj;
                }
                var data = error.response.data;
                toast.error(data.errors);
                return data;
            });
            

        }
    }

    loadAtendentesOptions = (inputValue, callback) => {
        var url = ENDPOINT + "admin/list?search=" + inputValue;
        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p.data;

            data.forEach((p, i) => {
                data[i].value = p.id;
                data[i].label = p.name;
            });

            callback(this.filterAtendentes(inputValue, p.data));
        });
    }

    filterAtendentes = (inputValue, data) => {
        return data.filter(i =>
            i.name.toLowerCase().includes(inputValue.toLowerCase())
        );
    }


    onCloseOpenSendEmail = (visible_email) => {
        this.setState({visible_email});

        if(visible_email === false){
            getPayout(this.state.payout_id, this.callbackRows.bind(this));
        }
    }

    onPagamento = (pagamento_liberado) => {
        var  pagamento_liberado_label = '';
        list_pagamentos_liberados.forEach(p => {
            if(p.value == pagamento_liberado){
                pagamento_liberado_label = p.label; 
           } 
        });

        return pagamento_liberado_label;
    }

    liquidarPagamento = () => {
        this.setState({
            liquidacao_pagamento_loading: true
        })

        var url = ENDPOINT + "payout/liquidar_pagamentos/"+this.state.payout_id;
        var formData = new FormData();
        formData.append('motivo_liquidacao', this.state.motivo_pagamento_liquidado);

        if(this.state.banco_liquidacao !== null){
            formData.append('banco_liquidacao', this.state.banco_liquidacao.value);
        }

        if(this.state.data_pagamento_conta !== null){
            formData.append('data_hora_pagamento_conta', this.state.data_pagamento_conta);
        }

        axios.post(url, formData, {
          headers: {
              Authorization: 'Bearer '+getToken()
          }})
        .then((response) => {
            this.setState({
                liquidacao_pagamento_loading: false
            })
        

            var data = response.data;
            toast.success(data.message);
            getPayout(this.state.payout_id, this.callbackRows.bind(this));

        })
        .catch((error) => {
            this.setState({
                liquidacao_pagamento_loading: false
            })

            var data = error.response.data;
            toast.error(data.errors);
        })

    }
    adicionarPermissao = () => {
        this.setState({
            loading_liberacao: true
        })

        var url = ENDPOINT + "payout/adicionar_permissao";
        var formData = new FormData();
        formData.append('admin_id', this.state.operador_liberacao.id);
        axios.post(url, formData, {
          headers: {
              Authorization: 'Bearer '+getToken()
          }})
        .then((response) => {
            this.setState({
                loading_liberacao: false
            })
        

            var data = response.data;
            toast.success(data.message);
            getPayout(this.state.payout_id, this.callbackRows.bind(this));

        })
        .catch((error) => {
            this.setState({
                loading_liberacao: false
            })

            var data = error.response.data;
            toast.error(data.errors);
        })
    }

    removerPermissao = (admin_id) => {
        this.setState({
            loading_liberacao: true
        })

        var url = ENDPOINT + "payout/remover_permissao";
        var formData = new FormData();
        formData.append('admin_id', admin_id);
        axios.post(url, formData, {
          headers: {
              Authorization: 'Bearer '+getToken()
          }})
        .then((response) => {
            this.setState({
                loading_liberacao: false
            })

            var data = response.data;
            toast.success(data.message);
            getPayout(this.state.payout_id, this.callbackRows.bind(this));

        })
        .catch((error) => {
            this.setState({
                loading_liberacao: false
            })

            var data = error.response.data;
            toast.error(data.errors);
        });
    }

    render(){
        return(
            <Div>
                <h3>
                    <Button 
                        outline 
                        style={{border: 'none'}}
                        onClick={() => window.location.href = '/payout'}
                    >
                        <MdArrowBack style={{padding: 0}}/>
                    </Button>&nbsp;&nbsp;Detalhes do Pagamento Bloqueado</h3>
                <hr />
                <this.Navs />
                <Card hidden={this.state.loading_user == true}>
                    <CardBody style={{height: '120px'}}>
                        <center>
                            <FadeLoader color={'#52c565'} margin={0} loading={50} size={60} />
                        </center>
                    </CardBody>
                </Card>
                <TabContent activeTab={this.state.activeTab} hidden={this.state.loading_user == false}>
                    <TabPane tabId={'1'}>
                        <div style={{padding: '15px', border: '1px solid #dee2e6', borderTop: 0}}>
                            <Row>
                                <Col md={12}>
                                    <p><b>Status:</b> {status_transacao_not_svg(this.state.status)}</p>
                                    <p><b>ID da Transação:</b> {this.state.transaction_number}</p>
                                    <p><b>Cliente:</b> {this.state.nome}</p>
                                    <p><b>Valor da venda:</b> R$ {parseFloat(this.state.amount).toFixed(2).replace('.', ',')}</p>
                                    <p><b>Taxa:</b> R$ {parseFloat(this.state.fees).toFixed(2).replace('.', ',')}</p>
                                    <p><b>Valor Liquido:</b> R$ {(parseFloat(this.state.original_amount) - parseFloat(this.state.fees)).toFixed(2).replace('.', ',')}</p>
                                    <p><b>Tipo de Pagamento:</b> {payment(this.state.payment_type)}</p>
                                    {this.state.pagamento_aqpago !== null ? 
                                        <p><b>Parcelas:</b> {this.state.pagamento_aqpago.numero_parcelas}x</p>
                                    : <></>}
                                    <p><b>Descrição:</b> {this.state.descricao}</p>
                                    <p><b>Tipo de Transação:</b> {this.state.point_of_sale !== null ? (this.state.point_of_sale.entry_mode == 'chip' ? 'CP - Cartão Presente' : 'CNP - Cartão não presente') : ''}</p>
                                    {this.state.payment_method !== null ?
                                        this.state.payment_type !== 'boleto' ?
                                            <>
                                                <p><b>Portador do Cartão:</b> {this.state.payment_method.holder_name}</p>
                                                <p><b>Bandeira:</b> {this.state.payment_method.card_brand}</p>
                                                <p><b>Número:</b> {this.state.payment_method.first4_digits} **** **** {this.state.payment_method.last4_digits}</p>
                                                <p><b>Validade:</b> {this.state.payment_method.expiration_month < 10 ? '0' : ''}{this.state.payment_method.expiration_month}/{this.state.payment_method.expiration_year}</p>
                                            </>
                                            :
                                            <>
                                                <p><b>Nome no Boleto:</b> {this.state.payment_method.recipient}</p>
                                                <p><b>Código de Barras do Boleto: </b>{this.state.payment_method.barcode}</p>
                                            </>
                                        :
                                    <></>}
                                    {this.state.user !== null ? 
                                        <>
                                            <p><b>Atividade do Seller/MCC do Seller:</b> {this.state.user.atividade_principal}</p>
                                            <p><b>Data de cadastro do seller:</b> {moment(this.state.user.created_at).format('DD/MM/YYYY HH:mm')}</p>
                                        </>
                                    : <></>}
                                </Col>
                                {/* <Col md={6}>
                                    <p><b>Razão do Bloqueio:</b> {this.state.razao_bloqueio}</p>
                                    <p><b>Pagamento Liberado:</b> {list_pagamentos_liberados.map(p => {if(p.value === this.state.pagamento_liberado){ return p.label } } )}</p>
                                    <p><b>Responsável pela conferência:</b> {this.state.funcionario_conferencia_nome}</p>
                                    <p><b>Responsável pela liberação:</b> {this.state.funcionario_liberacao_nome}</p>
                                    <p><b>Criador do Payout Bloqueado:</b> {this.state.funcionario_criador_nome}</p>
                                    <p><b>Data de recebimento em conta:</b> {moment(this.state.data_hora_pagamento_conta).format('DD/MM/YYYY HH:mm')}</p>
                                </Col> */}
                            </Row>
                            <hr/>
                            <Card>
                                <CardBody>
                                    <h6>Contas Bancarias</h6>
                                    <hr/>
                                    <ContasBancarias data={this.state.contas_bancaria} />
                                </CardBody>
                            </Card>
                            <hr />
                            <Row>
                                <Col lg={12} md={12}>
                                    <Card>
                                        <CardBody>
                                            <h6>Histórico de vendas</h6>
                                            <hr style={{ marginBottom: 0 }}/>
                                            <DataTable
                                                noHeader={true}
                                                columns={colunas_pagamentos}
                                                data={this.state.history}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <br/>
                    </TabPane>
                    <TabPane tabId={'2'}>
                        <div style={{padding: '15px', border: '1px solid #dee2e6', borderTop: 0}}>
                            <h5>Transações</h5>
                            <hr/>
                            <Card>
                                <CardBody>
                                    <h6>Últimas transações ({this.state.point_of_sale !== null ? (this.state.point_of_sale.entry_mode == 'chip' ? 'Mesmo cartão ' : 'Mesmo Buyer') : ''})</h6>
                                    <hr style={{ marginBottom: 0 }}/>
                                    <DataTable
                                        noHeader={true}
                                        columns={colunas_transacoes}
                                        data={this.state.transacoes.filter(p => p.status_pagamento == 'APROVADO' || p.status_pagamento == 'PENDENTE')}
                                    />
                                </CardBody>
                            </Card>
                            <br/>
                            <Card>
                                <CardBody>
                                    <h6>Transações Falhadas</h6>
                                    <hr style={{ marginBottom: 0 }}/>
                                    <DataTable
                                        noHeader={true}
                                        columns={colunas_transacoes}
                                        data={this.state.transacoes.filter(p => p.status_pagamento != 'APROVADO')}
                                    />
                                </CardBody>
                            </Card>
                        </div>
                    </TabPane>
                    <TabPane tabId={'3'}>
                        <div style={{padding: '15px', border: '1px solid #dee2e6', borderTop: 0}}>
                            <h5>Anexos</h5>
                            <hr />
                            <Card>
                                <CardBody>
                                    <Button 
                                        color="dark" 
                                        onClick={() => this.setState({
                                            modalImage: !this.state.modalImage, 
                                            descricao_image:  '', 
                                            file: null,
                                            file_64: ''
                                    })}>
                                        <MdAttachment /> Adicionar novo anexo
                                    </Button>
                                    <Collapse isOpen={this.state.modalImage}><hr />
                                        <FormGroup>
                                            <Label>Selecione o arquivo: &nbsp;</Label>
                                            <input 
                                                type="file" 
                                                hidden 
                                                accept=".webp,.svg,.png,.jpg,.gif,.pdf,.mp4,.avi,.3gp,.ogg"
                                                ref={this.myRef}
                                                onChange={this.handleFile}/>
                                            <InputGroup>
                                                <Input 
                                                    disabled 
                                                    placeholder={this.state.file !== null ? this.state.file.name : "Selecionar Arquivo" }
                                                    style={{height: '40px'}}/>
                                                <InputGroupAddon addonType="prepend">
                                                    <Button 
                                                        hidden={this.state.file === null} 
                                                        onClick={() => this.setState({file: null, file_64: ''})} 
                                                        color="danger">
                                                            <MdClear style={{padding: 0}}/>
                                                    </Button>
                                                    <Button 
                                                        style={{
                                                            borderBottomRightRadius: '.25rem',
                                                            borderTopRightRadius: '.25rem'
                                                        }}
                                                        onClick={() => this.myRef.current.click()} 
                                                        color="dark">
                                                            <MdAttachment style={{padding: 0}}/>
                                                    </Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                            <small>Formatos aceitos: .webp, .svg, .png, .jpg, .gif, .pdf, .mp4, .avi, .3gp, .ogg</small>
                                        </FormGroup>
                                        <FormGroup>
                                            <Input 
                                                type="textarea"
                                                placeholder="Descrição do arquivo"
                                                style={{ height: 120, resize: 'none' }}
                                                name="descricao_image"
                                                value={this.state.descricao_image}
                                                onChange={this.handleInputChange} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Button color="success" onClick={() => this.adicionarAnexo()} disabled={this.state.file === null}>Salvar</Button>&nbsp;
                                            <Button color="danger" onClick={() => this.setState({modalImage: false, descricao_image:  '', file: null, file_64: ''})}>Fechar</Button>
                                        </FormGroup>
                                    </Collapse>
                                </CardBody>
                            </Card>
                            <hr />
                            <Row>
                                {this.state.anexos.map(p => {
                                    return <Col md={6}>
                                        <Card style={{margin: 6}}>
                                            <CardBody>
                                                <center>
                                                    {this.getFileExtension(p.url_anexo) === 'image' ? 
                                                        <img alt="ocorrencia-imagem" src={p.url_anexo+'?access_token='+getToken()} style={{ maxWidth: '100%', maxHeight: '400px' }} />
                                                    :
                                                    this.getFileExtension(p.url_anexo) === 'video' ? 
                                                        <video controls src={p.url_anexo+'?access_token='+getToken()} style={{  maxWidth: '100%', height: '400px' }} />
                                                    :
                                                        <Button 
                                                            style={{
                                                                backgroundColor: this.getColorExtension(p.url_anexo), 
                                                                borderColor: this.getColorExtension(p.url_anexo)}} 
                                                            onClick={() => window.open(p.url_anexo+'?access_token='+getToken(), '_blank')}>
                                                                <GetIconExtension url={p.url_anexo} style={{color: 'white', padding: 0}}/> Abrir Arquivo
                                                        </Button>
                                                    }
                                                </center>
                                                <hr/>
                                                <p><b>Descrição:</b> {p.descricao}</p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                })}
                            </Row>
                            {this.state.anexos.length === 0 ? <p>Sem anexos</p> : <></>}
                        </div>
                    </TabPane>
                    <TabPane tabId={'4'}>
                        <div style={{padding: '15px', border: '1px solid #dee2e6', borderTop: 0}}>
                            <h5>Notas (Comentarios Internos)</h5>
                            <hr />
                            <Card>
                                <CardBody>
                                    <Button color="success" onClick={() => this.setState({modalNota: !this.state.modalNota, descricao_nota:  ''})}><MdNote /> Adicionar nota privada</Button>
                                    <Collapse isOpen={this.state.modalNota}><hr />
                                        <FormGroup>
                                            <Input 
                                                type="textarea"
                                                placeholder="Descrição da nota"
                                                style={{ height: 120, resize: 'none' }}
                                                name="descricao_nota"
                                                value={this.state.descricao_nota}
                                                onChange={this.handleInputChange} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Button color="success" onClick={() => this.adicionarNota()} disabled={this.state.descricao_nota.length === 0}>Salvar</Button>&nbsp;
                                            <Button color="danger" onClick={() => this.setState({modalNota: false, descricao_nota:  ''})}>Fechar</Button>
                                        </FormGroup>
                                    </Collapse>
                                </CardBody>
                            </Card>
                            <hr />
                            <ul>
                                {this.state.notas_privadas.map((p) => {
                                    return <><li>
                                        {p.descricao}<br/>
                                        Operador: {p.admin !== null ? p.admin.name : 'Sem Informações'}<br/>
                                        {moment(new Date(p.created_at)).format('DD/MM/YYYY HH:mm')}
                                        
                                    </li><br/></>
                                })}
                            </ul>
                            {this.state.notas_privadas.length === 0 ? <p>Nenhuma nota encontrada</p> : <></>}
                        </div>
                    </TabPane>
                    <TabPane tabId={"5"}>
                        <div style={{padding: '15px', border: '1px solid #dee2e6', borderTop: 0}}>
                            <h5>Status de Prevenção</h5>
                            <hr />
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Status de Prevenção</Label>
                                        <Select 
                                            placeholder="Status de Prevenção"
                                            options={list_pagamentos_liberados}
                                            value={this.state.pagamento_liberado}
                                            onChange={(pagamento_liberado) => this.setState({pagamento_liberado})}
                                        />
                                        <Input hidden invalid={this.state.pagamento_liberado_invalid}/>
                                        <FormFeedback>Campo obrigatório</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Responsável pela conferência</Label>
                                        <AsyncSelect
                                            style={{width: '100%'}}           
                                            cacheOptions
                                            loadOptions={this.loadAtendentesOptions}
                                            defaultOptions
                                            placeholder="Responsável pela conferência"
                                            value={this.state.responsavel_conferencia}
                                            onChange={(responsavel_conferencia) => this.setState({responsavel_conferencia})}
                                        />
                                        <Input hidden invalid={this.state.responsavel_conferencia_invalid}/>
                                        <FormFeedback>Campo obrigatório</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Data/Hora Documentos</Label>
                                        <Input 
                                            type="datetime-local"
                                            name="data_hora_docs"
                                            invalid={this.state.data_hora_docs_invalid}
                                            value={this.state.data_hora_docs}
                                            onChange={this.handleChange} />
                                        <FormFeedback>Campo obrigatório</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>Razão do Bloqueio</Label>
                                       {/*  <Input 
                                            placeholder="Razão do Bloqueio" 
                                            name="razao_bloqueio"
                                            invalid={this.state.razao_bloqueio_invalid}
                                            value={this.state.razao_bloqueio}
                                            onChange={this.handleChange}
                                        /> */}
                                        <Select 
                                            placeholder="Razão do Bloqueio"
                                            options={list_razao_bloqueio}
                                            value={this.state.razao_bloqueio}
                                            onChange={(razao_bloqueio) => this.setState({razao_bloqueio})}
                                        />
                                        <FormFeedback>Campo obrigatório</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Responsável pela liberação</Label>
                                        <AsyncSelect
                                            style={{width: '100%'}}           
                                            cacheOptions
                                            loadOptions={this.loadAtendentesOptions}
                                            defaultOptions
                                            placeholder="Responsável pela liberação"
                                            value={this.state.responsavel_liberacao}
                                            onChange={(responsavel_liberacao) => this.setState({responsavel_liberacao})}
                                        />
                                        <Input hidden invalid={this.state.responsavel_liberacao_invalid}/>
                                        <FormFeedback>Campo obrigatório</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Data/Hora Liberação</Label>
                                        <Input 
                                            type="datetime-local"
                                            name="data_hora_liquidacao"
                                            invalid={this.state.data_hora_liquidacao_invalid}
                                            value={this.state.data_hora_liquidacao}
                                            onChange={this.handleChange} />
                                        <FormFeedback>Campo obrigatório</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Button color="success" onClick={() => this.payoutEditPagamento()}>Salvar</Button>
                        </div>
                        <br/>
                        <div style={{padding: '15px', border: '1px solid #dee2e6'}}>
                            <h5>Historico de Prevenção</h5>
                            <hr />
                            {this.state.historico_prevencao.map(e => {
                                return <p><b>{this.onPagamento(e.status_prevencao)}</b> - {e.analista !== null ? e.analista.name : 'Sem informações'} - {moment(e.created_at).format('DD/MM/YYYY HH:mm')}</p>
                            })}
                        </div>
                        <br/>
                        <div style={{padding: '15px', border: '1px solid #dee2e6'}}>
                            <h5>E-mails enviados</h5>
                            <hr/>
                            {this.state.emails_enviados.map(e => 
                                <small>
                                    <b>{moment(e.created_at).format('DD/MM/YYYY HH:mm')}: </b>
                                    <div 
                                        style={{
                                            border: '1px solid #7f7f7f66', 
                                            borderRadius: '15px', 
                                            padding: '19px',
                                            height: '150px',
                                            overflow: 'auto'
                                        }}>
                                        <div dangerouslySetInnerHTML={{__html: sanitizeHtml(e.message)}}/>
                                    </div>
                                    <br/>
                                </small>
                            )}
                            {this.state.emails_enviados.length === 0 ? 'Nenhum e-mail enviado' : ''} 
                            <hr/>
                            <Button color="info" onClick={() => this.onCloseOpenSendEmail(true)}>Enviar E-mail</Button>
                            <SendEmailByTransacao
                                visible={this.state.visible_email}
                                onClose={this.onCloseOpenSendEmail}
                                email={this.state.email}
                                id={this.state.payout_id}
                            />
                        </div>
                        <br/>
                        <div style={{padding: '15px', border: '1px solid #dee2e6'}}>
                            <h5>E-mails recebidos</h5>
                            <hr/>
                            {this.state.emails_recebidos.map(e => 
                                <small>
                                    <b>{moment(e.created_at).format('DD/MM/YYYY HH:mm')}: </b>
                                    <div 
                                        style={{
                                            border: '1px solid #7f7f7f66', 
                                            borderRadius: '15px', 
                                            padding: '19px',
                                            height: '150px',
                                            overflow: 'auto'
                                        }}>
                                        <div dangerouslySetInnerHTML={{__html: sanitizeHtml(e.descricao)}}/>
                                    </div>
                                    <br/>
                                </small>
                            )}
                            {this.state.emails_recebidos.length === 0 ? 'Nenhum e-mail recebido' : ''}
                        </div>
                        <br/>
                        <br/>
                    </TabPane>
                    <TabPane tabId={"6"}>
                        <div style={{padding: '15px', border: '1px solid #dee2e6', borderTop: 0}}>
                            <h5>Extorno de transações</h5>
                            <hr />
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Data de Estorno</Label>
                                        <Input 
                                            type="date"
                                            name="estorno_date"
                                            value={this.state.estorno_date}
                                            invalid={this.state.estorno_date_invalid}
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Pedido de solicitação do estorno: </Label>
                                        {this.state.url_arquivo === null ?
                                            <Input 
                                                type="file" 
                                                name="estorno_arquivo"
                                                value={this.state.estorno_arquivo}
                                                invalid={this.state.estorno_arquivo}
                                                onChange={this.handleChange}
                                            />
                                            :
                                            <><br/><a href={this.state.url_arquivo+'?access_token='+getToken()}>Abrir Solicitação</a></>
                                        }
                                        
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>Resposta do Estorno</Label>
                                        <Input 
                                            type="text"
                                            name="estorno_resposta"
                                            placeholder="Resposta do estorno"
                                            value={this.state.estorno_resposta}
                                            onChange={this.handleChange} 
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            
                            <Button 
                                onClick={() => this.payoutExtornoTransações()} 
                                disabled={this.state.loading_payout} 
                                hidden={this.state.pagamento_estornado}
                                style={{
                                    marginRight: 8
                                }}
                                color="warning">
                                {this.state.loading_payout === true ?
                                    <ClipLoader color="#fff" size={15}/>
                                    :
                                    ''} Confirmar Estorno
                            </Button>
                            <Button 
                                color="success" 
                                onClick={() => this.payoutEditTransações()}>Salvar</Button>
                        </div>
                    </TabPane>
                    <TabPane tabId={"7"}>
                        <Card>
                            <CardBody>
                                <h4>Liquidação de Pagamento</h4>
                                {this.state.liberacao_permitida  == true ?
                                    <div>
                                        <hr/>
                                        <Form>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>Data de Pagamento em conta</Label>
                                                        <Input 
                                                            type="datetime-local"
                                                            name="data_pagamento_conta"
                                                            //disabled={this.state.pagamento_liquidado == true}
                                                            invalid={this.state.data_pagamento_conta_invalid}
                                                            value={this.state.data_pagamento_conta}
                                                            onChange={this.handleChange} />
                                                        <FormFeedback>Campo obrigatório</FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>Banco de Liquidação</Label>
                                                        <Select 
                                                            placeholder="Banco de Liquidação"
                                                            value={this.state.banco_liquidacao}
                                                            options={list_bancos_retidos}
                                                            //isDisabled={this.state.pagamento_liquidado == true}
                                                            onChange={(banco_liquidacao) => this.setState({banco_liquidacao})}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                        <Form hidden={this.state.pagamento_liquidado == true}>
                                            {/* <FormGroup>
                                                <Input
                                                    type='textarea'
                                                    name='motivo_pagamento_liquidado'
                                                    placeholder='Motivo da liquidação'
                                                    value={this.state.motivo_pagamento_liquidado}
                                                    onChange={this.handleChange}
                                                />
                                            </FormGroup> */}
                                            <FormGroup>
                                                <Button 
                                                    color='warning' 
                                                    onClick={() => this.liquidarPagamento()}
                                                    disabled={this.state.liquidacao_pagamento_loading}>
                                                    {this.state.liquidacao_pagamento_loading === true ?
                                                        <ClipLoader color="#fff" size={15}/>
                                                        :
                                                    ''}
                                                    Liquidar Pagamento
                                                </Button>
                                            </FormGroup>
                                        </Form>
                                        <div hidden={this.state.pagamento_liquidado == false}>
                                            <Button 
                                                color="success" 
                                                onClick={() => this.payoutEditPagamento()}>Salvar</Button>
                                            <hr/>
                                            <p><b>Data da Liquidação:</b> {this.state.data_hora_pagamento_liquidado}</p>
                                            {this.state.detalhes_liq !== null ?
                                                <>
                                                    <p><b>Operador que realizou a transferência:</b> {this.state.detalhes_liq.admin_name}</p>
                                                    <p><b>ID da Transferência:</b> {this.state.detalhes_liq.id_transferencia}</p>
                                                    <p><b>Status da Transferência:</b> {this.state.detalhes_liq.status} {this.state.detalhes_liq.status == 'TRANS_FALHADA' ? 
                                                                                                                                <Button 
                                                                                                                                    color='warning'
                                                                                                                                    onClick={() => this.liquidarPagamento()}
                                                                                                                                    disabled={this.state.liquidacao_pagamento_loading}>
                                                                                                                                    {this.state.liquidacao_pagamento_loading === true ?
                                                                                                                                        <ClipLoader color="#fff" size={15}/>
                                                                                                                                        :
                                                                                                                                    ''} Refazer Transação</Button> : ''}</p>
                                                    <p><b>Detalhes do banco:</b></p>
                                                    <div style={{marginLeft: '15px'}}>
                                                        <p><b>Número da conta bancária:</b> {JSON.parse(this.state.detalhes_liq.payload_atividade).accountNumber}</p>
                                                        <p><b>Agencia:</b> {JSON.parse(this.state.detalhes_liq.payload_atividade).branchCode}</p>
                                                        <p><b>Código do banco:</b> {JSON.parse(this.state.detalhes_liq.payload_atividade).bankCode}</p>
                                                        <p><b>CPF:</b> {JSON.parse(this.state.detalhes_liq.payload_atividade).taxId}</p>
                                                        <p><b>Valor Transferido:</b> R$ {(parseFloat(this.state.original_amount) - parseFloat(this.state.fees)).toFixed(2).replace('.', ',')}</p>
                                                    </div>
                                                </>
                                            :<></>}
                                            {/* <p><b>Motivo:</b> {this.state.motivo_pagamento_liquidado}</p> */}
                                        </div>
                                    </div>
                                :
                                <>
                                    <h4>
                                        Você não está autorizado para acessar essa função
                                    </h4>
                                </>}
                            </CardBody>
                        </Card>
                    </TabPane>

                    {getUser() !== null && getUser().access_system == 'super_user' ?
                        <TabPane tabId={"8"}>
                            <Card>
                                <CardBody>
                                    <div style={{display: 'flex'}}>
                                        <FaUser style={{padding: 0, margin: '9px'}}/> 
                                        <div style={{width: '50%'}}>
                                            {/* <Select 
                                                placeholder="Usuário"
                                                isSearchable={false} /> */}
                                            <AsyncSelect
                                                style={{width: '100%'}}           
                                                cacheOptions
                                                loadOptions={this.loadAtendentesOptions}
                                                defaultOptions
                                                placeholder="Operador"
                                                isDisabled={this.state.loading_liberacao === true}
                                                value={this.state.operador_liberacao}
                                                onChange={(operador_liberacao) => this.setState({operador_liberacao})}
                                            />
                                        </div> 
                                        <Button 
                                            color='success'
                                            onClick={() => this.adicionarPermissao()}
                                            disabled={this.state.operador_liberacao == null || this.state.loading_liberacao === true}
                                            style={{marginLeft: '9px'}}
                                        >
                                        {this.state.loading_liberacao === true ?
                                            <ClipLoader color="#fff" size={15}/>
                                            :
                                            ''} Autorizar
                                        </Button>
                                    </div>
                                    <hr/>
                                    <ul>
                                        {this.state.operadores_permitidos !== null ?
                                            this.state.operadores_permitidos.map(e => {
                                                return <li>
                                                    {e.name} &nbsp;&nbsp;
                                                    <Button 
                                                        color='danger'
                                                        style={{
                                                            padding: 2,
                                                            paddingLeft: 5,
                                                            paddingRight: 5,
                                                            fontSize: '12px'
                                                        }}
                                                        disabled={this.state.loading_liberacao === true}
                                                        onClick={() => this.removerPermissao(e.id)}
                                                    >
                                                        {this.state.loading_liberacao === true ?
                                                            <ClipLoader color="#fff" size={15}/>
                                                            :
                                                            ''} Remover Autorização
                                                    </Button>
                                                </li>
                                            })
                                            :
                                            <></>
                                        }
                                        {/*  */}
                                    </ul>
                                </CardBody>
                            </Card>
                        </TabPane>
                        :
                        <></>
                    }
                </TabContent>
            </Div>
        )
    }
}

export default ResumoPayoutBloqueado;