import React, { useEffect, useState } from "react";
import { Collapse, Nav, NavItem, NavLink } from "reactstrap";
import dashboardRoutes from "../../routes";
//import { withRouter } from "react-router";
import './sidebar-style.css';
import styled from "styled-components";
import { MdExitToApp } from "react-icons/md";
import { getPermissoes, getToken, getUser, logout } from '../../auth/set_services';
import { ENDPOINT } from "../../auth/endpoint";
import Pusher from 'pusher-js';
import { FaAngleDown, FaAngleLeft, FaAngleRight } from "react-icons/fa";

const getPathUrl = () => {
    return window.location.pathname;
}

const Content = styled.div`
  opacity: ${props => (props.closed ? "0" : "1")};
  visibility: ${props => (props.closed === true ? "hidden" : "visible")};
  transition: all 0.2s;
`;



const Sidebar = props => {
    const [em_analise, setAnalise] = useState(0);
    const [consulta_disponivel, setConsultaDisponivel] = useState(true);
    const [menus, setMenus] = useState([]);

    useEffect(() => {
        //getAnalises(callback);
        PusherInstancia();
        newSidebar();
        // eslint-disable-next-line
    }, []);

    const callback = (data) => {
        if(data.success === true){
            setAnalise(data.data.analises);
        }
    }

    const getAnalises = (callback) => {
        if(consulta_disponivel == true){
            setConsultaDisponivel(false);

            var url = ENDPOINT + "prevencao/novas_analises";
        
            var myHeaders = new Headers();
        
            myHeaders.append('Authorization', 'Bearer ' + getToken());
        
            var myInit = {
                method: 'GET',
                headers: myHeaders,
                mode: 'cors',
                cache: 'default'
            };
            fetch(url, myInit)
            .then(response => response.json())
            .then((data) => {
                callback(data);
                setConsultaDisponivel(true)
            })
            .catch(e => {
                var data = {
                    success: false
                }
                
                callback(data);
                setConsultaDisponivel(true)
            })
        }

    }

    const PusherInstancia = () => {
        const pusher_instance = new Pusher("2892f3b88d766b1b14d3", {
            cluster: 'us3',
        });

        var response = pusher_instance.subscribe('atualizar_analise_prevencao_risco');
        response.bind('atualizar_prevencao', () => {
            setTimeout(() => { 
                //getAnalises(callback) 
            } , 10000);
        });
    }

    const newSidebar = () => {
        let sideBars = [];

        dashboardRoutes(em_analise).map((p, i) => {
            //console.log(p)
            if(p){
                let check = p.hasOwnProperty('sidebar');
                if (check) {
                    if (!p.sidebar.subnav) {
                        let sort = (p.sidebar.sortorder !== undefined) ? p.sidebar.sortorder : i;
    
                        sideBars[sort] = []; 
                        sideBars[sort] = p; 
                        sideBars[sort]['subnav'] = []; 
                        sideBars[sort]['open'] = false;
                        sideBars[sort]['visible'] = false;
                        if(getUser()[p.permission]) {
                            sideBars[sort]['visible'] = true; 
                        }
                    }
                }
            }
        });

        dashboardRoutes(em_analise).map((p, i) => { 
            //console.log(p)
            if(p){
                let check = p.hasOwnProperty('sidebar');
                if (check) {
                    if (p.sidebar.subnav) {
                        sideBars.map((menu, item) => {
                            let sort = (menu.sidebar.sortorder !== undefined) ? menu.sidebar.sortorder : item;

                            if (p.sidebar.nav == menu.sidebar.nav) {                            
                                if(getUser()[p.permission]){
                                    sideBars[sort]['visible'] = true; 
                                }
                                if (getPathUrl() === p.path) {
                                    sideBars[sort]['open'] = true; 
                                }
                                sideBars[sort]['subnav'].push(p); 

                            }
                        })
                    }
                }
            }
        });
        //console.log(sideBars)
        setMenus(prevMenus => ([...prevMenus, ...sideBars]))
    }

    const handleOpenMenu = (nav, status) => {
        menus.map((p, i) => {
            let check = p ? p.hasOwnProperty('sidebar') : false
            if (check) {
                if (!p.sidebar.subnav && p.sidebar.nav === nav) {
                    p.open = status;
                    setMenus((prevMenus) => prevMenus.map((t) => {
                        return (t?.sidebar.nav === p?.sidebar.nav ? (t = p) : t)
                    }
                    ))
                }
            }
        })
    }

    return (
        <Content closed={props.closed}>
            <Nav className={"col-md-2 col-sm-9 d-md-block sidebar"}>
                {menus.map((menu, i) => {
                    if(menu){
                        if (typeof menu.visible == 'undefined' || !menu.visible) {
                            return '';
                        }
                        if(menu.type === 'link'){
                            if(getUser()[menu.permission]){
                                return (<div key={String(i + 'div-menu')}>
                                    {<NavItem 
                                        key={String(i + 'nav-item')} 
                                        style={{ paddingLeft: '5px' }}
                                        className={getPathUrl() === menu.path ? 'active' : ''}>
                                        <NavLink
                                            href={menu.path}>
                                            <menu.icon size={12} />
                                            <span style={{fontWeight: 700}}>{menu.name}</span>
                                        </NavLink>
                                    </NavItem >}
                                    {menu.scroll ?
                                        menu.scroll.show === true ?
                                            <NavItem 
                                                key={String(i + 'sub-nav-item')} 
                                                className={getPathUrl() === menu.scroll.path ? 'active' : ''} 
                                                style={{ paddingLeft: '25px' }}
                                                >
                                                <NavLink
                                                    key={String(i + 'sub2-nav-item')} 
                                                    href={menu.scroll.path}>
                                                    <menu.scroll.icon size={12} />
                                                    <span style={{fontWeight: 500}}>{menu.scroll.name}</span>
                                                </NavLink>

                                            </NavItem>
                                            : <></>
                                        :
                                        <></>}
                                </div>);
                            }
                        }
                        else if(menu.type === 'subtext'){
                            return <div key={String(i + 'div-item')}>
                                <NavItem 
                                    key={String(i + 'nav-item')} 
                                    style={{
                                        marginTop: '16px',
                                        marginBottom: '12px'}}
                                    >
                                        <ul>
                                            <li key={String(i + 'nav-item-li')}>
                                                <span className="menu-nav" onClick={() => {return handleOpenMenu(menu.sidebar.nav, !menu.open)}} style={{fontWeight: 500}}>{menu.name}</span>
                                            </li>
                                            <hr style={{
                                                backgroundColor: 'white', 
                                                marginTop: '4px',
                                                marginBottom: '8px'
                                                }} />
                                        </ul>
                                {
                                            <Collapse isOpen={menu.open} navbar>
                                            {menu.subnav.map((submenu, i) => {
                                                if(submenu.type === 'link'){
                                                    if(getUser()[submenu.permission]){
                                                        return (
                                                        <div key={String(i + '-div-item')}>
                                                            {<NavItem 
                                                                key={String(i + '-nav-item')} 
                                                                style={{ paddingLeft: '5px' }}
                                                                className={getPathUrl() === submenu.path ? 'active' : ''}>
                                                                <NavLink
                                                                    key={String(i + '-sub-nav-item')}
                                                                    href={submenu.path}>
                                                                    <submenu.icon size={12} />
                                                                    <span style={{fontWeight: 700}}>{submenu.name}</span>
                                                                </NavLink>
                                                            </NavItem >}
                                                            {submenu.scroll ?
                                                                submenu.scroll.show === true ?
                                                                    <NavItem 
                                                                        key={String(i + '-nav-item')}
                                                                        className={getPathUrl() === submenu.scroll.path ? 'active' : ''} 
                                                                        style={{ paddingLeft: '25px' }}>
                                                                        <NavLink
                                                                            key={String(i + '-sub-nav-item')}
                                                                            href={submenu.scroll.path}>
                                                                            <submenu.scroll.icon size={12} />
                                                                            <span style={{fontWeight: 700}}>{submenu.scroll.name}</span>
                                                                        </NavLink>
                            
                                                                    </NavItem>
                                                                    : <></>
                                                                :
                                                                <></>}
                                                        </div>);
                            
                                                    }
                                                }
                                                else if(submenu.type === 'subtext'){
                                                    return <div key={String(i + 'div-item')}><NavItem 
                                                                key={String(i + 'nav-item')}
                                                                style={{
                                                                marginTop: '16px',
                                                                marginBottom: '12px'}}>
                                                                <ul >
                                                                    <li key={String(i + '-nav-item-li')}>
                                                                        <span style={{fontWeight: 500}}>{submenu.name}</span>
                                                                    </li>
                                                                    <hr style={{
                                                                        backgroundColor: 'white', 
                                                                        marginTop: '4px',
                                                                        marginBottom: '8px'
                                                                }} />
                                                                </ul>
                                                                
                                                            </NavItem></div>
                                                }
                                                else{
                                                    return <NavItem key={String(i + '-nav-item')}>
                                                        <NavLink 
                                                            key={String(i + '-nav-link')}
                                                            style={{paddingBottom: 0}}>
                                                            <span style={{fontWeight: 500}}>{submenu.name}</span>
                                                            <hr style={{
                                                                backgroundColor: 'white', 
                                                                marginTop: '4px',
                                                                marginBottom: '8px'
                                                                }} />
                                                        </NavLink>
                                                    </NavItem>
                                                }
                                            })}
                                            </Collapse>
                                        }
                            </NavItem></div>
                        }
                        else {

                            return <NavItem key={String(i + 'nav-item')}>
                                    <NavLink key={String(i + 'nav-link')} style={{paddingBottom: 0}}>
                                        <span key={String(i + 'nav-span')} className="menu-nav" style={{fontWeight: 700}}>
                                            <div className={menu.open ? 'menu-nav-item' : 'menu-nav-item'} onClick={() => {return handleOpenMenu(menu.sidebar.nav, !menu.open)}}>
                                            {menu.icon !== undefined ?
                                            <menu.icon size={12} />
                                            :
                                            <></>
                                            }
                                            {menu.name}

                                            <span className="icon-down">
                                                {menu.open ?
                                                <FaAngleDown />
                                                :
                                                <FaAngleRight />
                                                }
                                            </span>
                                            </div>
                                        </span>
                                        <hr style={{
                                            backgroundColor: 'white', 
                                            marginTop: '4px',
                                            marginBottom: '8px'
                                            }} />
                                        {
                                            <Collapse isOpen={menu.open} navbar>
                                            {menu.subnav.map((submenu, i) => {
                                                if(submenu.type === 'link'){
                                                    if(getUser()[submenu.permission]){
                                                        return (<div key={String(i + '-div-item-2')}>
                                                            {<NavItem 
                                                                key={String(i + '-nav-item-2')}
                                                                style={{ paddingLeft: '5px' }}
                                                                className={getPathUrl() === submenu.path ? 'active' : ''}>
                                                                <NavLink
                                                                    key={String(i + '-nav-link-2')}
                                                                    href={submenu.path}>
                                                                    <submenu.icon size={12} />
                                                                    <span style={{fontWeight: 700}}>{submenu.name}</span>
                                                                </NavLink>
                                                            </NavItem >}
                                                            {submenu.scroll ?
                                                                submenu.scroll.show === true ?
                                                                    <NavItem 
                                                                        key={String(i + '-nav-item-3')}
                                                                        className={getPathUrl() === submenu.scroll.path ? 'active' : ''} 
                                                                        style={{ paddingLeft: '25px' }}>
                                                                        <NavLink
                                                                            key={String(i + '-nav-link-3')}
                                                                            href={submenu.scroll.path}>
                                                                            <submenu.scroll.icon size={12} />
                                                                            <span style={{fontWeight: 700}}>{submenu.scroll.name}</span>
                                                                        </NavLink>
                            
                                                                    </NavItem>
                                                                    : <></>
                                                                :
                                                                <></>}
                                                        </div>);
                            
                                                    }
                                                }
                                                else if(submenu.type === 'subtext'){
                                                    return <div key={String(i + '-div-item-4')}><NavItem 
                                                                key={String(i + '-nav-item-4')}
                                                                style={{
                                                                    marginTop: '16px',
                                                                    marginBottom: '12px'}}>
                                                                <ul >
                                                                    <li key={String(i + '-nav-li-4')}>
                                                                        <span style={{fontWeight: 500}}>{submenu.name}</span>
                                                                    </li>
                                                                    <hr style={{
                                                                        backgroundColor: 'white', 
                                                                        marginTop: '4px',
                                                                        marginBottom: '8px'
                                                                }} />
                                                                </ul>
                                                                
                                                            </NavItem></div>
                                                }
                                                else{
                                                    return <NavItem key={String(i + '-nav-item-5')}>
                                                        <NavLink key={String(i + '-nav-link-5')} style={{paddingBottom: 0}}>
                                                            <span style={{fontWeight: 500}}>{submenu.name}</span>
                                                            <hr style={{
                                                                backgroundColor: 'white', 
                                                                marginTop: '4px',
                                                                marginBottom: '8px'
                                                                }} />
                                                        </NavLink>
                                                    </NavItem>
                                                }
                                            })}
                                            </Collapse>
                                        }
                                    </NavLink>
                                </NavItem>   
                                
                        }
                    }
                })}
                <NavItem key={String('nav-item-6')} style={{ cursor: 'pointer' }}>
                    <NavLink key={String('nav-link-6')} onClick={() => logout()}>
                        <MdExitToApp size={12} />
                        <span style={{fontWeight: 700}}>Sair</span>
                    </NavLink>
                </NavItem>
            </Nav>
        </Content>
    );
};
//const Sidebar = withRouter(Side);
export default Sidebar