import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import { MdAlarm, MdClear, MdSend } from 'react-icons/md';
import { Button, ButtonGroup, Row } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { HiViewGrid } from 'react-icons/hi';

const status_cadastro = (status) => {
    if (status == 'chat_pending_titular' || status == 'chat_pending_responsavel'){
        return "Aguardando Credenciamento";
    }
    if (status == 'titular' || status == 'responsavel'){
        return "Credenciado";
    }

    return status;
}
export const columns = (onShowDelete) => [
    {
      name: '',
      selector: 'buttons',
      width: '80px',
      cell: row => <Row>
        <Button data-tip="Informações"
          outline
          style={{ marginLeft: '10px',marginRight: '3px', padding: '2px', border: 0}}
          color="primary" 
          onClick={() => 
            {
                window.location.href = '/resumo-loja/'+row.id;
            }
          }>
          <HiViewGrid style={{ padding: '2px' }} />
        </Button>
        <Button data-tip="Deletar Loja"
          outline
          style={{ marginRight: '3px', padding: '2px', border: 0}}
          color="danger" 
          onClick={() => 
            {
                onShowDelete(row.id);
            }
          }>
          <MdClear style={{ padding: '2px' }} />
        </Button>
        <ReactTooltip/>
      </Row>
    },
    {
        name: 'ID da transação',
        selector: 'id_transacao',
        width: '250px',
        sortable: false,
        cell: row => row.id_transacao
    },
    {
        name: "Nome da Loja",
        selector: "nome_loja",
        sortable: false,
        cell: row => row.nome_loja
    },
    {
        name: 'Nome do cliente',
        selector: 'nome_cliente',
        sortable: false,
        cell: row => row.venda.user ? row.venda.user.nome + ' ' + row.venda.user.sobrenome : ''
    },
    {
        name: "Status",
        selector: "status",
        sortable: false,
        cell: row => row.status
    },
    {
        name: "Status de Cadastro",
        selector: "status",
        sortable: false,
        cell: row => row.venda.user ? status_cadastro(row.venda.user.user_tipo) : ''
    },
    {
        name: "Tema",
        selector: "tema",
        width: '130px',
        sortable: false,
        cell: row => row.tema
    }
];

export const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "loja/list?search=" + state.search + "&page=" + state.activePage;

    if (state.validade === true) {
        url += '&validade=';
    }
    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
        if(document.length === 11){
            url += "&cpf="+document;
        }
    }
    else{
        if(document.length === 14){
        url += "&cnpj="+document;
        }
    }

    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {    
    var url = ENDPOINT + "loja/list?search=" + search + "&page=" + page;

    if (filter === true) {
        url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }

    if (state.status_loja !== null) {
        url += '&status=' + state.status_loja.value;
    }

    if (state.forma_pagamento !== null) {
        url += '&forma_pagamento=' + state.forma_pagamento.value;
    }
    
    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
        if(document.length === 11){
            url += "&cpf_cnpj="+document;
        }
    }
    else{
        if(document.length === 14){
            url += "&cpf_cnpj="+document;
        }
    }

    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}