import { Card, CardBody } from "reactstrap"
import PropagateLoader from "react-spinners/PropagateLoader";

export const MessageNoTable = (props) => {
    return <>
        {props.loading_table === true ?
            <Card style={{ border: 0, padding: '10vh' }}>
                <CardBody>
                <div>
                    <div className="sweet-loading">
                        <PropagateLoader color={'#52c565'} loading={20} size={30} />
                    </div>
                </div>
                </CardBody>
            </Card> 
            :
            <Card style={{ border: 0 }}>
                <CardBody>
                    <div>
                        {props.message}
                    </div>
                </CardBody>
            </Card>
        }
    </>
}