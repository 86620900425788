import { Component } from "react";
import { BsFillPeopleFill, BsPhone } from "react-icons/bs";
import { Button, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components";
import aqpago from '../../../assets/aqpago.webp';
import aqpasso from '../../../assets/icone2-1.png';
import aqbank from '../../../assets/aqbank-white.webp';
import aqcontabil from '../../../assets/aqcontabil.svg';
import meubus from '../../../assets/meubus.svg';
import Select from "react-select";
import { list_planos } from "../../../auth/listas";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import AsyncSelect from 'react-select/async';
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import axios from "axios";
import { MdPhoneAndroid } from "react-icons/md";
import CreatableSelect from 'react-select/creatable';

const ButtonOption = styled.button`
    padding: 20px;
    background: ${props => get_color(props.type)};
    border-radius: 100%;
    border: none;
    outline: none;
    box-shadow: none;

    &:hover{
        opacity: 0.8;
    }
`;
const OptionArea = styled.div`
    margin: 15px;
    p{
        padding: 15px;
        font-weight: 600;
    }
`;
const OptionsArea = styled.div`
    display: flex;
    text-align: center;
    place-content: center;
`;
const OptionsSMS = styled.div`
    display: flex;
    margin-bottom: 8px;

    button{
        margin-right: 4px;
    }

    .select-option{
        width: 100%;
    }
`;
const OptionLogo = styled.div`
    padding: 8px;
    height: 42px;
    background: ${props => get_color(props.type)};
    border-radius: 100%;
    margin-left: 10px;
    border: none;
    outline: none;
    box-shadow: none;
`;


const get_color = (type) => {
    switch(type){
        case 'aqpago': return '#4f076be3';
        case 'aqbank': return '#02cda4';
        case 'aqpasso': return '#42005d';
        case 'aqcontabil': return '#b3e5e1';
        case 'meubus': return '#daedff';
        case 'todos': return '#25292d';
        default: return '#757678'
    }
}



class SendSms extends Component{
    constructor(props){
        super(props);
        this.state = {
            step: 1,
            option_select: 'aqpago',
            message_selected: 'all',
            plano: null,
            plano_invalid: false,
            phone: [],
            phone_invalid: '',
            telefone: [],
            telefone_invalid: false,
            mensagem: '',
            mensagem_invalid: false,

            loading: false,
            error_message: '',
            success_message: ''
        }
        this.onRegister = this.onRegister.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }

    onSelectPlan = (option_select, step) => {
        this.setState({option_select, step})
    }

    loadOptions = (inputValue, callback) => {
        var url = ENDPOINT + "user/";
        if(this.state.option_select === 'aqpago'){
            url += "all_aqbank?nome=" + inputValue;
        }
        else{
            url += "all?empresa_relacao=aqpasso&search=" + inputValue;
        }
        
        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p.data;

            

            if(this.state.option_select === 'aqpago'){
                data.forEach((p, i) => {
                    data[i].value = p.id;
                    data[i].label = (p.nome ? p.nome : p.responsavel.nome + ' '+p.responsavel.sobrenome) + ' - ' + (p.telefone);
                });
            }
            else{
                data.forEach((p, i) => {
                    data[i].value = p.id;
                    data[i].label = (p.nome !== null ? p.nome+" "+p.sobrenome : p.nome_fatura) +" - "+ p.telefone;
                });
            }

            this.state.telefone.forEach((p, i) => {
                data.forEach((p2, i2) => {
                    /* eslint eqeqeq: 0 */
                    if(p2.telefone == p.value){
                        data.splice(i2, 1);
                    }
                });
            });

            callback(this.filter(inputValue, p.data));
        });
    }

    filter = (inputValue, data) => {
        return data.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }
    StepOne = () => {
        return(
            <>
                <OptionsArea>
                    <OptionArea>
                        <ButtonOption type="aqpago" onClick={() => this.onSelectPlan('aqpago', 2)}>
                            <img 
                                alt="aqpago-logo"
                                width="50" 
                                src={aqpago}
                            />
                        </ButtonOption>
                        <p>Selecionar clientes <br/>AQPago</p>
                    </OptionArea>
                    <OptionArea>
                        <ButtonOption type="aqbank" onClick={() => this.onSelectPlan('aqbank', 2)}>
                            <img 
                                alt="aqbank-logo"
                                width="50" 
                                src={aqbank}
                            />
                        </ButtonOption>
                        <p>Selecionar clientes <br/>AQBank</p>
                    </OptionArea>
                    <OptionArea>
                        <ButtonOption type="aqpasso" onClick={() => this.onSelectPlan('aqpasso', 2)}>
                            <img 
                                alt="aqpasso-logo"
                                width="50" 
                                src={aqpasso}
                            />
                        </ButtonOption>
                        <p>Selecionar clientes <br/>AQPasso</p>
                    </OptionArea>
                </OptionsArea>
                <OptionsArea>
                    <OptionArea>
                        <ButtonOption type="aqcontabil" onClick={() => this.onSelectPlan('aqcontabil', 2)}>
                            <img 
                                alt="aqcontabil-logo"
                                width="50" 
                                height="46" 
                                src={aqcontabil}
                            />
                        </ButtonOption>
                        <p>Selecionar clientes <br/>AQContabil</p>
                    </OptionArea>
                    <OptionArea>
                        <ButtonOption type="meubus" onClick={() => this.onSelectPlan('meubus', 2)}>
                            <img 
                                alt="mebus-logo"
                                width="50" 
                                height="46" 
                                src={meubus}
                            />
                        </ButtonOption>
                        <p>Selecionar clientes <br/>MeuBus</p>
                    </OptionArea>
                </OptionsArea>
                <OptionsArea>
                    <OptionArea>
                        <ButtonOption type="manual" onClick={() => this.onSelectPlan('manual', 3)}>
                            <MdPhoneAndroid style={{color: 'white', padding: 0, width: '50px', height: '46px'}}/>
                        </ButtonOption>
                        <p>Selecionar telefones<br/>manualmente</p>
                    </OptionArea>
                    <OptionArea>
                        <ButtonOption type="todos" onClick={() => this.onSelectPlan('todos', 4)}>
                            <BsFillPeopleFill style={{color: 'white', padding: 0, width: '50px', height: '46px'}}/>
                        </ButtonOption>
                        <p>Selecionar todos</p>
                    </OptionArea>
                </OptionsArea>
            </>
        )
    }
    StepTwo = () => {
        return (
            <div>
                <OptionsSMS>
                    <Button color="dark" onClick={() => this.setState({message_selected: 'all'})} outline={this.state.message_selected === 'all' ? false : true}>Todos</Button>
                    <Button color="dark" onClick={() => this.setState({message_selected: 'plano'})} outline={this.state.message_selected === 'plano' ? false : true}>Por Plano</Button>
                    <Button color="dark" onClick={() => this.setState({message_selected: 'manualmente'})} outline={this.state.message_selected === 'manualmente' ? false : true}>Manualmente</Button>
                </OptionsSMS>
                {this.state.message_selected === 'plano' ? 
                    <>
                        <OptionsSMS>
                            <Select
                                className="select-option"
                                options={list_planos}
                                placeholder="Plano (opcional)"
                                onChange={(plano) => this.setState({plano})}
                                isSearchable={false}
                                theme={theme => ({
                                    ...theme
                                })}
                            />
                            <OptionLogo type={this.state.option_select}>
                                <img alt="sellected-logo" src={this.selectedImage()} width="25"/></OptionLogo>
                        </OptionsSMS>
                        <Input hidden invalid={this.state.plano_invalid} />
                        <FormFeedback>Campo obrigatório</FormFeedback>
                    </>
                : this.state.message_selected === 'manualmente' ?
                    <>
                        <OptionsSMS>
                            <AsyncSelect
                                className="select-option"
                                cacheOptions
                                loadOptions={this.loadOptions}
                                defaultOptions
                                isMulti
                                value={this.state.telefone} 
                                placeholder="Usuários"
                                onChange={(telefone) => this.setState({telefone})}
                                onInputChange={this.handleInputChange}
                            />
                            <OptionLogo type={this.state.option_select}><img alt="sellected-logo" src={this.selectedImage()} width="25"/></OptionLogo>
                        </OptionsSMS>
                        <Input hidden invalid={this.state.telefone_invalid} />
                        <FormFeedback>Campo obrigatório</FormFeedback>
                    </>
                :
                    <></>
                }
                <hr/>
                <FormGroup>
                    <Input
                        type="textarea"
                        placeholder="Mensagem"
                        name="mensagem"
                        onChange={this.handleChange}
                        maxLength={160}
                        value={this.state.mensagem} 
                        invalid={this.state.mensagem_invalid}
                        style={{ height: "110px", resize: 'none' }}
                    />
                    <small style={{float: 'right'}}>{this.state.mensagem.length}/160</small>
                </FormGroup>
                <OptionsSMS>
                    <Button color="dark" onClick={() => this.adicionarMensagem('{{ NOME }}')} outline>&#x0007B;&#x0007B; NOME &#x0007D;&#x0007D;</Button>
                    <Button color="dark" onClick={() => this.adicionarMensagem('{{ PLANO }}')} outline>&#x0007B;&#x0007B; PLANO &#x0007D;&#x0007D;</Button>
                </OptionsSMS>
            </div>
        )
    }
    StepTree = () => {
        return (
            <div>
                <CreatableSelect
                    isMulti
                    placeholder="Telefones"
                    onChange={this.handlePhonesChange}
                    formatCreateLabel={(inputText) => `Selecionar telefone: "${inputText}"`}
                />
                <Input hidden invalid={this.state.phone_invalid} />
                <FormFeedback>Campo obrigatório</FormFeedback>
                <hr/>
                <FormGroup>
                    <Input
                        type="textarea"
                        placeholder="Mensagem"
                        name="mensagem"
                        onChange={this.handleChange}
                        maxLength={160}
                        value={this.state.mensagem} 
                        invalid={this.state.mensagem_invalid}
                        style={{ height: "110px", resize: 'none' }}
                    />
                    <small style={{float: 'right'}}>{this.state.mensagem.length}/160</small>
                </FormGroup>
            </div>
        );
    }
    StepFour = () => {
        return (
            <div>
                <FormGroup>
                    <Input
                        type="textarea"
                        placeholder="Mensagem"
                        name="mensagem"
                        onChange={this.handleChange}
                        maxLength={160}
                        value={this.state.mensagem} 
                        invalid={this.state.mensagem_invalid}
                        style={{ height: "110px", resize: 'none' }}
                    />
                    <small style={{float: 'right'}}>{this.state.mensagem.length}/160</small>
                </FormGroup>
                <OptionsSMS>
                    <Button color="dark" onClick={() => this.adicionarMensagem('{{ NOME }}')} outline>&#x0007B;&#x0007B; NOME &#x0007D;&#x0007D;</Button>
                    <Button color="dark" onClick={() => this.adicionarMensagem('{{ PLANO }}')} outline>&#x0007B;&#x0007B; PLANO &#x0007D;&#x0007D;</Button>
                </OptionsSMS>
            </div>
        );
    }
    TelefoneOneStep = () => {
        return (
            <div>
                <FormGroup>
                    <Input
                        type="textarea"
                        placeholder="Mensagem"
                        name="mensagem"
                        onChange={this.handleChange}
                        maxLength={160}
                        value={this.state.mensagem} 
                        invalid={this.state.mensagem_invalid}
                        style={{ height: "110px", resize: 'none' }}
                    />
                    <small style={{float: 'right'}}>{this.state.mensagem.length}/160</small>
                </FormGroup>
                {/* <OptionsSMS>
                    <Button color="dark" onClick={() => this.adicionarMensagem('{{ NOME }}')} outline>&#x0007B;&#x0007B; NOME &#x0007D;&#x0007D;</Button>
                    <Button color="dark" onClick={() => this.adicionarMensagem('{{ PLANO }}')} outline>&#x0007B;&#x0007B; PLANO &#x0007D;&#x0007D;</Button>
                </OptionsSMS> */}
            </div>
        );
    }
    handlePhonesChange = (phone) => {
        this.setState({phone});
    };
    selectedImage = () => {
        if(this.state.option_select === 'aqpago'){
            return aqpago;
        }
        else{
            return aqpasso;
        }
    }
    adicionarMensagem = (value) => {
        this.setState({mensagem: this.state.mensagem+''+value})
    }
    
    onData(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    onRegister() {
        if(this.validateFields() === 0){
            this.setState({loading: true});
            this.store();
        }
    }

    validateFields(){
        var invalid = 0;
        if(!this.props.is_phone){
            if(this.state.option_select === 'aqpasso' || this.state.option_select === 'aqpago'){
                if(this.state.message_selected === 'manualmente'){
                    if(this.state.telefone.length > 0){
                        this.setState({
                            telefone_invalid: false
                        })
                    }
                    else{
                        invalid++;
                        this.setState({
                            telefone_invalid: true
                        })
                    }
                }
                else if(this.state.message_selected === 'plano'){
                    if(this.state.plano !== null){
                        this.setState({
                            plano_invalid: false
                        })
                    }
                    else{
                        invalid++;
                        this.setState({
                            plano_invalid: true
                        })
                    }
                }
            }
            else if(this.state.option_select === 'manual'){
                if(this.state.phone.length > 0){
                    this.setState({
                        phone_invalid: false
                    })
                }
                else{
                    invalid++;
                    this.setState({
                        phone_invalid: true
                    })
                }
            }
    
            if(this.state.mensagem.length > 0){
                this.setState({
                    mensagem_invalid: false
                })
            }
            else{
                invalid++;
                this.setState({
                    mensagem_invalid: true
                })
            }
        }

        return invalid;
    }
    store = () => {
        var url = ENDPOINT+"sms/store";

        var formData = new FormData();
        if(this.props.is_phone){
            var telefone = {
                value: this.props.is_phone
            }
            formData.append('numero_telefone[0]', JSON.stringify(telefone));
            formData.append('tipo', 'manual');
            formData.append('mensagem', this.state.mensagem);

        }
        else{
            if(
                this.state.option_select === 'aqpasso' || 
                this.state.option_select === 'aqpago' ||
                this.state.option_select === 'aqbank' ||
                this.state.option_select === 'aqcontabil' ||
                this.state.option_select === 'meubus'
            ){
                if(this.state.message_selected === 'all'){
                    formData.append('todos', true);
                }
                if(this.state.message_selected === 'manualmente'){
                    this.state.telefone.forEach((p, index) => {
                        formData.append('telefones['+index+']', JSON.stringify(p));
                    })
                }
        
                if(this.state.message_selected === 'plano'){
                    if(this.state.plano !== null){
                        formData.append('todos', true);
                        formData.append('plano', this.state.plano.label)
                    }
                }
        
                formData.append('tipo', this.state.option_select)
                formData.append('mensagem', this.state.mensagem);
    
            }
            else if(this.state.option_select === 'manual'){
                if(this.state.phone.length > 0){
                    this.state.phone.forEach((p, index) => {
                        formData.append('numero_telefone['+index+']', JSON.stringify(p));
                    })
                }
                formData.append('tipo', this.state.option_select);
                formData.append('mensagem', this.state.mensagem);
            }
            else{
                formData.append('todos', true);
                formData.append('tipo', this.state.option_select);
                formData.append('mensagem', this.state.mensagem);
            }

        }
        
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            this.onData(data);
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onData(obj);
            }
            var data = error.response.data;
            this.onData(data);
        });
    }
    onClose(){
        this.setState({
            step: 1,
            option_select: 'aqpago',
            message_selected: 'all',
            plano: null,
            plano_invalid: false,
            telefone: [],
            telefone_invalid: false,
            mensagem: '',
            mensagem_invalid: false,

            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose(false);
    }
    onBack = () => {
        if(this.state.step > 1){
            this.setState({
                step: 1,
                option_select: 'aqpago',
                message_selected: 'all',
                plano: null,
                plano_invalid: false,
                telefone: [],
                telefone_invalid: false,
                mensagem: '',
                mensagem_invalid: false,
    
                loading: false,
                error_message: '',
                success_message: ''
            });
        }
        else{
            this.onClose();
        }
    }
    render(){
        return(
            <Modal isOpen={this.props.visible} size="md">
                <ModalHeader>
                    <BsPhone style={{padding: '2px', bottom: '1.8px', position: 'relative'}}/> Enviar SMS
                </ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    {!this.props.is_phone ?
                        (this.state.step === 1 ? 
                            <this.StepOne/>
                            :
                                this.state.step === 2 ? 
                                    <this.StepTwo/>
                                :
                                    this.state.step === 3 ? 
                                        <this.StepTree/>
                                    :
                                        <this.StepFour/>
                        )
                        :
                        <this.TelefoneOneStep/>
                    }
                </ModalBody>
                <ModalBody 
                    style={{
                        minHeight: '114px', 
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn 
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                        />
                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    {this.state.step > 1 || this.props.is_phone ? 
                        <>
                            <Button color="danger" onClick={this.onBack}>Voltar</Button>
                            <Button color="success" onClick={this.onRegister}>Salvar</Button>
                        </>
                    :
                        <>
                            <Button color="danger" onClick={this.onBack}>Cancelar</Button>
                        </>
                    }
                </ModalFooter>
            </Modal>
        )
    }
}

export default SendSms;