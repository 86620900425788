import { Component } from "react";
import Select from "react-select";
import { Card, CardBody, Col, FormGroup, Row, Input, Button } from "reactstrap";
import { MdAdd } from "react-icons/md";
import { InputText } from "../../components/InputText/InputText";
import axios from "axios";
import { getPermissoes, getToken } from "../../auth/set_services";
import { ENDPOINT } from "../../auth/endpoint";
import { toast} from 'react-toastify'
import { ButtonOutline } from "../../components/Button/ButtonOutline";
import ModalDialog from "../../components/Modals/ReturnsApiModals/ModalDialog";

class RespostasProntas extends Component{
    constructor(props){
        super(props);
        this.state = {
            titulo_modelo: '',
            modelo: null,
            mensagem: '',
            error_message: '',
            success_message: '',
            loading: false,
            visible_delete: false,
            list_respostas: [
                {
                    conteudo: '',
                    value: 'nova_resposta',
                    label: <><MdAdd/> Adicionar nova resposta pronta</>
                }
            ]
        }
        this.onToast = this.onToast.bind(this);
        this.atualizarModel = this.atualizarModel.bind(this);
        this.addString = this.addString.bind(this);
    }
    componentDidMount(){
        this.getModelos();
    }
    handleChangeEmail = (mensagem) => {
        this.setState({mensagem});
    }
    atualizarModel = () => {
        if(this.state.modelo.value === 'nova_resposta'){
            var list_respostas = this.state.list_respostas;
            var newModelo = true;
            list_respostas.forEach((p) => {
                console.log(this.formatflag(p.label) + "|" + this.formatflag(this.state.titulo_modelo));
                if(this.formatflag(p.label) === this.formatflag(this.state.titulo_modelo)){
                    newModelo = false;
                }
            });

            if(newModelo === false){
                var data = {
                    code: 400,
                    success: false,
                    errors: 'Já existe uma resposta pronta com esse nome!'
                }
                this.onToast(data);
                return false;
            }
            else{
                this.setModelos();
                return true;
            }
        }
        else{
            this.setModelos();
            return true;
        }

    }
    formatflag = (titulo) => {
        return this.retira_acentos(titulo).replaceAll(' ', '_').replace(/[^a-z0-9]/gi,'').toLowerCase()
    }
    setModelos = () => {
        var url = ENDPOINT+"respostas/store";
    
        var formData = new FormData();
        if(this.state.modelo.value !== 'nova_resposta'){
            formData.append('nome', this.state.modelo.label);
            formData.append('flag', this.state.modelo.value);
        }
        else{
            formData.append('nome', this.state.titulo_modelo);
            formData.append('flag', this.formatflag(this.state.titulo_modelo));
        }
        formData.append('conteudo', this.state.mensagem);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.onToast(data);
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onToast(obj);
                return obj;
            }
            var data = error.response.data;
            this.onToast(data);
            return data;
        });
    }

    atualizarStateModel = (data) => {
        var list_respostas = this.state.list_respostas;
        var nao_existe = true;
        list_respostas.forEach((p, i) => {
            if(p.flag === data.flag){
                list_respostas[i].conteudo = data.conteudo;
                nao_existe = false;
            }
        });

        if(nao_existe === true){
            data.label = data.nome;
            data.value = data.flag;
            list_respostas.push(data);
        }

        this.setState({list_respostas});
    }

    onToast(data) {
        if (data.success === true) {
            toast.success(data.message);
            if(data.data !== null){
                this.atualizarStateModel(data.data);
            }
        }
        else {
            toast.error(data.errors);
        }
    }

    getModelos = () => {
        var url = ENDPOINT+"respostas/list";
    
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            var list_respostas = this.state.list_respostas.slice(0, 1);
            data.forEach((p, i) => {
                p['value'] = p.flag;
                p['label'] = p.nome;
                list_respostas.push(p);
            })
            this.setState({list_respostas});
        })
        .catch((error) => {

        });
    }

    habilitar_titulo_modelo = () => {
        if(this.state.modelo !== null){
            if(this.state.modelo.value === 'nova_resposta'){
                return true;
            }
            else{
                return false;
            }
        }
        return false;
    }
    handleSelectChange = modelo => {
      this.setState({ modelo });
      if(modelo !== null){
            let contentState = modelo.conteudo;
            this.setState({mensagem: contentState});
      }
    };
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({ [name]: value });
    }
    retira_acentos(str) {
        var com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
        var sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
        var novastr="";
        for(var i=0; i<str.length; i++) {
            var troca=false;
            for (var a = 0 ; a < com_acento.length; a++) {
                /* eslint eqeqeq: 0 */
                if (str.substr(i,1)==com_acento.substr(a,1)) {
                    novastr+=sem_acento.substr(a,1);
                    troca=true;
                    break;
                }
            }
            /* eslint eqeqeq: 0 */
            if (troca==false) {
                novastr+=str.substr(i,1);
            }
        }

        return novastr;
    }   
    addString = (string) => {
        let atualizeString = String(this.state.mensagem)+string;
        let contentState = atualizeString;
        this.setState({mensagem:  contentState });
    }
    onError(){
        this.setState({
            loading: false,
            error_message: ''
        })
    }
    onClose(){
        this.setState({
            visible_delete: false,
            loading: false,
            error_message: '',
            success_message: '',
        })
    }

    onDelete(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            });
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    deletarResposta = () => {
        this.setState({loading: true})
        var url = ENDPOINT+"respostas/delete";
    
        var formData = new FormData();
        formData.append('flag', this.state.modelo.value);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.onToast(data);
            this.onDelete(data);
            this.setState({
                modelo: null
            });
            this.getModelos();
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "data": null, "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onToast(obj);
                this.onDelete(obj);
                return obj;
            }
            var data = error.response.data;
            this.onToast(data);
            this.onDelete(data);
            return data;
        });
    }
    render(){
        return(
            <div>
                <h3>Respostas Prontas</h3>
                <hr />
                <Card>
                    <CardBody>
                        <FormGroup>
                            <Select 
                                options={this.state.list_respostas} 
                                value={this.state.modelo}
                                onChange={this.handleSelectChange}
                                isSearchable={false}
                                placeholder="Selecione a resposta pronta"
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0
                                })} />
                        </FormGroup>
                        <FormGroup>
                            <InputText 
                                name="titulo_modelo"
                                placeholder="Titulo da Resposta Pronta"
                                onChange={this.handleChange}
                                value={this.state.titulo_modelo}
                                hidden={!this.habilitar_titulo_modelo()}
                             />
                        </FormGroup>
                        <FormGroup>
                            <Card hidden={this.state.modelo === null} style={{zIndex: 0}}>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <p style={{margin: 2}}>Preencher com: </p>
                                            <ButtonOutline style={{margin: 2}} onClick={() => this.addString("{{ $Nome }}")}>
                                                &#x0007B;
                                                &#x0007B; 
                                                $Nome 
                                                &#x0007D;
                                                &#x0007D;
                                            </ButtonOutline>
                                            <ButtonOutline style={{margin: 2}} onClick={() => this.addString("{{ $Telefone }}")}>
                                                &#x0007B;
                                                &#x0007B; 
                                                $Telefone 
                                                &#x0007D;
                                                &#x0007D;
                                            </ButtonOutline>
                                            <ButtonOutline style={{margin: 2}} onClick={() => this.addString("{{ $E-mail }}")}>
                                                &#x0007B;
                                                &#x0007B; 
                                                $E-mail 
                                                &#x0007D;
                                                &#x0007D;
                                            </ButtonOutline>
                                        </Col>
                                    </Row>
                                </CardBody>
                                
                                <FormGroup style={{padding: '15px'}}>
                                    <Input
                                        type="textarea"
                                        style={{
                                            height: '50vh'
                                        }}
                                        value={this.state.mensagem}
                                        defaultValue={this.state.mensagem}
                                        onChange={(e) => this.handleChangeEmail(e.target.value)}
                                    />
                                </FormGroup>
                                <Row>
                                    <Col md={7}></Col>
                                    <Col md={5} sm={12} style={{display: 'inline'}}>
                                        <ButtonOutline style={{width: '150px', margin: 0, float: 'right', marginRight: '15px'}} onClick={this.atualizarModel}>Salvar</ButtonOutline>
                                        <Button onClick={() => this.setState({visible_delete: true})} style={{width: '150px', marginRight: '15px', float: 'right'}} hidden={this.state.modelo !== null ? (this.state.modelo.value === 'nova_resposta' ? true : false) : true} outline color="danger" >Excluir</Button>
                                    </Col>
                                    <ModalDialog
                                        visible={this.state.visible_delete}
                                        onError={this.onError.bind(this)}
                                        message="Deseja excluir essa Resposta Pronta? Essa operação não poderá ser desfeita"
                                        onClose={() => this.onClose()}
                                        error_message={this.state.error_message}
                                        loading={this.state.loading}
                                        onSave={() => this.deletarResposta()}
                                        success_message={this.state.success_message}
                                    />
                                </Row>
                                <br/>
                                
                            </Card>
                        </FormGroup>
                    </CardBody>
                </Card>
            </div>
        )
    }
}
export default RespostasProntas;