import { useState } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Button,
  Col,
  Modal,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormGroup,
  FormFeedback,
} from "reactstrap";

import { InputText } from "../../InputText/InputText";
import { getUser } from "../../../auth/set_services";
import { Loader } from "../../Loaders/Loader";
import { useApiAqmed } from "../../../hooks/useApi";
import { validateInputAqmed,  searchWithMask} from "../../../pages/AQMed/utils"

export function ModalAlterCompany({ isOpen, size, onClose, config = null }) {

  const [document, setDocument] = useState(config?.data?.document);
  const [socialReason, setSocialReason] = useState(config?.data?.social_reason);
  const [fantasy, setFantasy] = useState(config?.data?.fantasy_name);
  const [industry, setIndustry] = useState(config?.data?.industry);
  const [colaborators, setColaborators] = useState(config?.data?.number_collaborators);
  const [isFetching, setIsFecthing] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const { apiAqmed } = useApiAqmed();
  const history = useHistory();

  const handleSubmit = async () => {
    const confirm = window.confirm("Tem certeza que deseja alterar os dados da empresa?");
    if (!confirm) {
      return;
    }

    const formData = {
      document: document,
      social_reason: socialReason,
      fantasy_name: fantasy,
      industry: industry,
      number_collaborators: colaborators,
    };

    console.log("formData = ", formData);
    const token = Cookies.get("aqmedtoken");
    apiAqmed
      .put(`/company/${config?.data?.public_id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((send) => {
        if (send.status === 200) {
          alert("Empresa alterada com sucesso");
          onClose();
          window.location.reload();
        } else {
          alert("Erro ao alterar empresa");
          onClose();
        }
      })
      .catch((error) => {
        alert("Erro ao alterar empresa");
        onClose();
      })
      .finally(() => setIsFecthing(false));
  };

  return (
    <Modal isOpen={isOpen} size={size}>
      <ModalHeader>Alterar Empresa</ModalHeader>

      {!isFetching ? (
        <>
          <ModalBody>
            <FormGroup>
              <Row>
                <Col md={10} sm={10} xs={10}>
                  <h6 style={{ marginTop: "10px" }}>Documento</h6>
                  <InputText
                    type="number"
                    value={document}
                    invalid={!isValid}
                    name="fee"
                    onChange={(event) => setDocument(event.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={10} sm={10} xs={10}>
                  <h6 style={{ marginTop: "10px" }}>Razão Social</h6>
                  <InputText
                    name="social-reason"
                    value={socialReason}
                    onChange={(event) => setSocialReason(event.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={10} sm={10} xs={10}>
                  <h6 style={{ marginTop: "10px" }}>Nome Fantasia</h6>
                  <InputText
                    name="fantasy-name"
                    value={fantasy}
                    onChange={(event) => setFantasy(event.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={10} sm={10} xs={10}>
                  <h6 style={{ marginTop: "10px" }}>Atividade</h6>
                  <InputText
                    name="industry"
                    value={industry}
                    onChange={(event) => setIndustry(event.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={10} sm={10} xs={10}>
                  <h6 style={{ marginTop: "10px" }}>Colaboradores</h6>
                  <InputText
                    type="number"
                    name="industry"
                    value={colaborators}
                    onChange={(event) => setColaborators(event.target.value)}
                  />
                </Col>
              </Row>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={onClose}>
              Cancelar
            </Button>
            <Button color="success" onClick={handleSubmit}>
              Cadastrar
            </Button>
          </ModalFooter>
        </>
      ) : (
        <Loader />
      )}
    </Modal>
  );
}
