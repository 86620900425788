import React from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, Row, Col, Collapse, Input, CardFooter, Button } from 'reactstrap';
import { columns } from './propsGiftCard';
import debounce from 'lodash/debounce';
import Pagination from '../Pagination';
import { InputText } from '../../InputText/InputText';
import { toast } from "react-toastify";
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import axios from 'axios';

const exportar_pagamentos = (status) => {
    toast.success("O relatório será baixado em breve");
    var url = ENDPOINT + "giftcard/exportar_giftcard";

    if(status != null){
        url += 'status='+status.value
    }
      axios({
        url: url, //your url
        method: 'GET',
        responseType: 'blob', // important
        headers: {
          Authorization: 'Bearer '+getToken()
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'relatorio_de_giftcard.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
}

export const TableGiftCard = (props) => {
    return (
        <Card>
            <Collapse isOpen={true}>
                <Card style={{ border: 'none' }}>
                    <CardBody>
                        <Row >
                            <Col md={5}>
                                <Button color='success' onClick={() => exportar_pagamentos()}>Exportar GiftCard</Button>
                                <br/><br/>
                                <div style={{ textAlign: 'left' }}>
                                    <InputText
                                        name="search"
                                        placeholder="Buscar"
                                        onChange={debounce((event) => props.isSearch(event), 1000)} />
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>De:
                            <Input
                                    name="date_from"
                                    type="date"
                                    //disabled
                                    disabled={!(props.filter === true)}
                                    value={props.date_from}
                                    onChange={(event) => props.isSearch(event)} /></Col>
                            <Col>Até:
                            <Input
                                    name="date_to"
                                    type="date"
                                    disabled={!(props.filter === true)}
                                    //disabled
                                    value={props.date_to}
                                    onChange={(event) => props.isSearch(event)} /></Col>
                        </Row>
                        <Row>
                            <Col md={5}>
                                <input
                                    type="checkbox"
                                    checked={props.filter}
                                    name="filter"
                                    onChange={(value) => props.onFilter(value.target.checked)} /> Filtrar por data de alteração

                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Collapse>
            <hr style={{ marginBottom: '4px' }} />
            <DataTable
                noHeader={true}
                button={true}
                noDataComponent={props.noDataComponent}
                columns={columns()}
                data={props.data}
                theme="default"
            />
            <CardFooter style={{ textAlign: 'center' }}>
                <Pagination
                    onPageChange={props.onPageChange}
                    activePage={props.activePage}
                    totalPage={props.totalPage}
                />

            </CardFooter>
        </Card>
    )
}