import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import PropagateLoader from "react-spinners/PropagateLoader";

import { FormFeedback, Form, FormGroup, Row, Col, Card, CardBody } from 'reactstrap';
import BoxError from '../../components/BoxError/BoxError';
import { login } from '../../auth/services';
import { ButtonDefault } from '../../components/Button/ButtonDefault';
import { InputText } from '../../components/InputText/InputText';
import { ButtonOutline } from '../../components/Button/ButtonOutline';
import { LineAuth } from '../../components/LineAuth/LineAuth';
import { removeKeys } from '../../auth/set_services';
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { toast, ToastContainer } from 'react-toastify';

class Login extends Component{
    constructor(props){
        super(props);
        this.state = {
            email: '',
            email_invalid: false,
            password: '',
            password_invalid: false,
            loading: false,
            token: null,
            error_message: ''
        }
        this.onLogin = this.onLogin.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.captchaRef = React.createRef();
    }
    componentDidMount(){
        removeKeys();
    }
    getPathUrl = () =>{
        return window.location.pathname;
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }

    onLoad = () => {
      // this reaches out to the hCaptcha JS API and runs the
      // execute function on it. you can use other functions as
      // documented here:
      // https://docs.hcaptcha.com/configuration#jsapi
      this.captchaRef.current.execute();
    };
    
    onEnter = (evt) => {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        if(key === 13){
            this.onLogin();
        }
     }
    closedError = () => {
        this.setState({
            loading: false,
            error_message: ''
        })
    }
    validateFields(){
        var invalid = 0;

        // eslint-disable-next-line
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re.test(this.state.email)){
            this.setState({email_invalid: false})
        }
        else{
            invalid++;
            this.setState({email_invalid: true})
        }

        //Validar senha
        if(this.state.password.length > 0){
            this.setState({password_invalid: false})
        }
        else{
            invalid++;
            this.setState({password_invalid: true})
        }

        return invalid;

    }
    onData(data){
        
        if(data.success === true){
            if(data.user.new_user === 1){
                localStorage.setItem('@api_token_sms', btoa(data.user.token_sms_code));
                window.location.replace("/first-password?token="+btoa(data.user.new_user_token));
            }
            else{
                window.location.replace("/token?token_sms="+btoa(data.user.token_sms_code));
            }
        }
        else{
            this.setState({
                loading: false,
                error_message: data.errors
            })
        }
    }
    onLogin() {
        if(this.validateFields() === 0){
            this.setState({loading: true});
            login(this.state.email, this.state.password, this.state.token, this.onData.bind(this));
           /* if(this.state.token !== null){
                this.setState({loading: true});
                login(this.state.email, this.state.password, this.state.token, this.onData.bind(this));
            }
            else{
                toast.error('Captcha obrigatório!');
            }  */
        }
    }
    render(){
        return(
            <>
                <Form hidden={this.state.loading}>
                    <h4 style={{margin: '25px', textAlign: 'right'}}>Login</h4>
                    <FormGroup>
                        <InputText 
                            name="email"
                            type="email"
                            placeholder="E-mail"
                            invalid={this.state.email_invalid}
                            value={this.state.email} 
                            onChange={this.handleChange} 
                            onKeyPress={this.onEnter}
                        />
                        <FormFeedback>E-mail Inválido</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <InputText 
                            name="password"
                            type="password"
                            placeholder="Senha"
                            invalid={this.state.password_invalid}
                            value={this.state.password}
                            onChange={this.handleChange}
                            onKeyPress={this.onEnter} />
                        <FormFeedback>A senha deve ser inserida</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <HCaptcha
                            sitekey="65e5075f-3188-4518-a12f-5f25cb1e4acf"
                            onLoad={this.onLoad}
                            onVerify={(token) => this.setState({ token })}
                            ref={this.captchaRef}
                            onExpire={() => this.setState({ token: null })}
                        />
                    </FormGroup>
                    <FormGroup>
                        <center>
                            <ButtonDefault color="success" style={{width: '95%'}} onClick={this.onLogin}>
                                Entrar
                            </ButtonDefault>
                        <LineAuth/>
                        <Row>
                            <Col></Col>
                            <Col>
                                <Link to="recover">
                                    <ButtonOutline href={"#"}>Esqueci minha senha</ButtonOutline>
                                </Link>
                            </Col>
                        </Row>
                        </center>
                    </FormGroup>
                </Form>
                <Card hidden={!this.state.loading} style={{border: 'none', padding: '10%'}}>
                    <CardBody>
                        <center>
                            <div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div>
                        </center>
                    </CardBody>
                </Card>
                <BoxError
                    visible={this.state.error_message.length > 0 ? true : false}
                    title="Falha ao realizar login"
                    message={String(this.state.error_message)}
                    onChange={this.closedError.bind(this)}
                />
                <ToastContainer hideProgressBar/>
            </>
        );
    }
}

export default Login;
