import React from "react";
import DataTable from "react-data-table-component";
import {
  Card,
  CardBody,
  Row,
  Col,
  Collapse,
  Input,
  CardFooter,
  Button,
} from "reactstrap";
import { columns } from "../../../pages/AQMed/Payments/propsFaturas";
import Pagination from "../../../components/Tables/Pagination";
import { InputText } from "../../../components/InputText/InputText";
import { FaSearch } from "react-icons/fa";


export const TablePayments = (props) => {
  return (
    <Card>
      <Collapse isOpen={true}>
        <Card style={{ border: "none" }}>
          <CardBody>
            <Row>
              <Col md={6}>
                <div style={{ textAlign: "left" }}>
                  <InputText
                    name="search"
                    placeholder={"Busca por Documento"}
                    valid={props.search?.valid}
                    invalid={props.search?.invalid}
                    value={props.search?.document}
                    onChange={props?.searchOnChange}
                  />
                </div>
                {props.search?.invalid && (
                  <div
                    style={{
                      color: "#DC3545",
                      fontSize: "0.75rem",
                      marginTop: "2px",
                    }}
                  >
                    Documento Inválido!
                  </div>
                )}
              </Col>
              <Col md={2}>
                <Button color="info" onClick={props?.onSearchDocument}>
                  <FaSearch style={{ fontSize: "1em" }} />
                </Button>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <input
                style={{cursor: "pointer"}}
                  type="checkbox"
                  checked={props?.form?.checked?.pending}
                  name="pending"
                  onChange={(event) => props?.form?.setCheckedControl({
                    date: props?.form?.check?.date,
                    pending: !props?.form?.check?.pending
                  })}
                />{" "}
                Filtrar por documentos pendentes
              </Col>
            </Row>
            <Row></Row>
            <Row>
              <Col>
                De:
                <Input
                style={{cursor: "pointer"}}
                  name="date_from"
                  type="date"
                  disabled={!(props.filter)}
                  value={props.date_from}
                  onChange={(event) => props.isSearch(event)}
                />
              </Col>
              <Col>
                Até:
                <Input
                style={{cursor: "pointer"}}
                  name="date_to"
                  type="date"
                  disabled={!(props.filter)}
                  //disabled
                  value={props.date_to}
                  onChange={(event) => props.isSearch(event)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <input
                style={{cursor: "pointer"}}
                  type="checkbox"
                  checked={props?.form?.checked?.date}
                  name="filter"
                  onChange={(event) => props?.form?.setCheckedControl({
                    date: !props?.form?.check?.date,
                    pending: props?.form?.check?.pending
                  })}
                />{" "}
                Filtrar por data de validade
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>
      <hr style={{ marginBottom: "4px" }} />

      <DataTable
        noHeader={true}
        button={true}
        noDataComponent={props.noDataComponent}
        columns={columns()}
        expandableRows
        expandableRowsComponent={props.expandableRowsComponent}
        data={props.data}
        theme="default"
      />
      <CardFooter style={{ textAlign: "center" }}>
        <Pagination
          onPageChange={props.onPageChange}
          activePage={props.activePage}
          totalPage={props.totalPage}
        />
      </CardFooter>
    </Card>
  );
};