import axios from "axios";
import React, { Component } from "react";
import { Button, Card, Col, FormGroup, Label, Modal, ModalFooter, ModalHeader, Input, Row, FormFeedback, CustomInput } from 'reactstrap';
import styled from "styled-components";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import { InputText } from '../../InputText/InputText';
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import Dropzone from 'react-dropzone';
import { MdAttachFile, MdClear } from "react-icons/md";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import { list_planos } from "../../../auth/listas";
import { FaCheck, FaExclamationTriangle, FaRegWindowClose } from "react-icons/fa";
import iconExclamation from '../../../assets/ico-exclamation.png';
import NumberFormat from "react-number-format";
import ReactScoreIndicator from "react-score-indicator";



const ModalBody = styled.div`
    .svg-inline--fa {
        width: 10em;
    }
    .svg-inline--fa.fa-w-14{
        width: 10em;
    }
    .prosemirror-toolbar-item{
        color: #52c565;
    }
    .prosemirror-toolbar-item[data-active='true'] {
        background-color: #52c565;
        color: #fff;
    }

    .rdw-option-wrapper{
        border: 0
    }
    .rdw-option-wrapper:active{
        background-color: #aaaaaa;
        box-shadow: none;
    }
    .rdw-option-wrapper:hover {
        background-color: #aaaaaa;
    }

    .rdw-dropdown-wrapper{
        border: 1px solid #ced4da;
    }
    .rdw-dropdown-wrapper:active{
        border: 1px solid #ced4da;
        box-shadow: none;
    }
    .rdw-dropdown-wrapper:hover{
        border: 1px solid #ced4da;
        box-shadow: none;
    }

    .rdw-dropdown-selectedtext{
        color: black
    }
    .rdw-dropdown-selectedtext:hover{
        text-decoration: none;
        color: black
    }
    .div-align {
        width: 40%;
        margin: 0px auto;
    }
    .div-align-text {
        text-align: center;
    }
    .modal-icon {
        width: 10rem;
        height: 8rem;
        padding: 1rem;
        border-radius: 50%;
        border: 5px solid #f1f3f1!important;
        box-shadow: 0px 3px 25px 0px rgb(113 106 202 / 20%);
    }
    h6 {
        display: block;
        position: relative;
        max-width: 100%;
        margin: 0 0 0.4em;
        padding: 0;
        color: #3b3f5c;
        font-size: 1.875em;
        font-weight: 600;
        text-align: center;
        text-transform: none;
        word-wrap: break-word; 
    }
    .span-info {
        justify-content: center;
        margin: 0;
        padding: 0;
        color: #e95f2b;
        font-size: 1.125em;
        font-weight: 300;
        line-height: normal;
    }
`;

class ConsultaMixDataView extends Component{
    constructor(props){
        super(props);
        this.state = {
            assinaturas_email: [],
        }
        this.onClose = this.onClose.bind(this); 
        //this.props = props; 
    }

    onClose(){
        this.props.onClose(false);
    }

    callback(){
        this.props.onClose(false);
        this.props.callback();
    }

    render(){

        if (this.props.consulta_view === undefined || this.props.consulta_view.length == 0) {
            return(
                <Modal isOpen={this.props.visible}  size={'lg'}>
                    <ModalBody className="modal-body" hidden={this.state.loading}>
                        <h2>Consulta não localizada!</h2>
                    </ModalBody>
                    <ModalFooter hidden={this.state.loading}>
                        <Button color="danger" onClick={() => this.onClose(false)}><FaRegWindowClose /> Fechar</Button>
                    </ModalFooter>
                </Modal>
            );
        } else {
            let documento = '';

            if (this.props.consulta_view.payload.resposta.ocorrencias.cadastro.cpf !== undefined) {
                documento = this.props.consulta_view.payload.resposta.ocorrencias.cadastro.cpf;
            }

            if (this.props.consulta_view.payload.resposta.ocorrencias.cadastro.cnpj !== undefined) {
                documento = this.props.consulta_view.payload.resposta.ocorrencias.cadastro.cnpj;
            }

            return(
                <Modal isOpen={this.props.visible}  size={'lg'}>
                    <ModalHeader>
                        <Button 
                            color="danger" 
                            onClick={() => this.onClose(false)}><FaRegWindowClose /> Fechar</Button>
                    </ModalHeader>
                    <ModalHeader>
                        <h5>
                            {(this.props.consulta_view.payload.resposta.ocorrencias.cadastro.nome !== undefined) ? this.props.consulta_view.payload.resposta.ocorrencias.cadastro.nome : ''}
                        </h5>
                    </ModalHeader>
                    <ModalBody className="modal-body" hidden={this.state.loading}>
                        
                        <Row>
                            <Col md={12}>
                                <div style={{
                                    overflow: 'auto',
                                    padding: '0.5rem', 
                                    border: '1px solid #dee2e6', 
                                    borderRadius: 5,
                                    marginBottom: '1rem',
                                    }}>
                                        <h5>Pontuação de Score</h5><hr/>
                                        <div key="score_bar_div" style={{position: 'relative'}}>
                                            <ReactScoreIndicator
                                                stepsColors={[
                                                    '#d12000',
                                                    '#ed8d00',
                                                    '#f1bc00',
                                                    '#84c42b',
                                                    '#84c42b',
                                                    '#84c42b',
                                                    '#3da940',
                                                    '#3da940',
                                                    ]}
                                                lineWidth={30}
                                                value={this.props.consulta_view.payload.resposta.ocorrencias.score.score.pontos}
                                                maxValue={1000}
                                                />
                                                <span style={{
                                                    position: 'absolute',
                                                    top: '2rem', 
                                                    width: '100%',
                                                    fontSize: '55px',
                                                    textAlign: 'center',
                                                    fontWeight: 'bold'
                                                }}>
                                                    {this.props.consulta_view.payload.resposta.ocorrencias.score.score.classe}
                                                </span>
                                                <h5 style={{textAlign: 'center'}}>{this.props.consulta_view.payload.resposta.ocorrencias.score.score.faixa.titulo}</h5>
                                                <p>{this.props.consulta_view.payload.resposta.ocorrencias.score.score.faixa.descricao}</p>
                                        </div>

                                    </div>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '1rem'}}>
                            <Col md={12}>
                                <div style={{
                                    overflow: 'auto',
                                    padding: '0.5rem', 
                                    border: '1px solid #dee2e6', 
                                    borderRadius: 5
                                    }}>
                                    <h5>
                                        Renda Presumida:&nbsp;
                                        <NumberFormat 
                                            prefix="R$ " 
                                            displayType="text" 
                                            value={parseFloat(this.props.consulta_view.payload.resposta.resumos.rendaPresumida)} 
                                            decimalSeparator=","/>
                                    </h5>
                                </div>
                            </Col>
                        </Row>
                        
                        {this.props.consulta_view.payload.resposta.ocorrencias.participacoesEmpresas !== undefined &&
                        this.props.consulta_view.payload.resposta.ocorrencias.participacoesEmpresas.length > 0 ?
                            <Row style={{marginBottom: '1rem'}}>
                                <Col md={12}>
                                    <div style={{
                                        overflow: 'auto',
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                        <h5>Participações em Empresas</h5><hr/>
                                        {this.props.consulta_view.payload.resposta.ocorrencias.participacoesEmpresas.map((empresa, i) => {
                                            return (
                                                <>
                                                <p><b>Razão Social:</b> {empresa.razaoSocial !== undefined ? empresa.razaoSocial : ''}</p>
                                                <p><b>CNPJ:</b> {empresa.cnpj !== undefined ? empresa.cnpj : ''}</p>
                                                <p><b>Data de entrada:</b> {empresa.dataEntrada !== undefined ? empresa.dataEntrada : ''}</p>
                                                </>
                                            )
                                        })}
                                    </div>
                                </Col>
                            </Row>
                        :
                            <></>
                        }

                        {this.props.consulta_view.payload.resposta.ocorrencias.debitos !== undefined &&
                        this.props.consulta_view.payload.resposta.ocorrencias.debitos.length > 0 ?
                            <Row style={{marginBottom: '1rem'}}>
                                <Col md={12}>
                                    <div style={{
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                        <h5>{this.props.consulta_view.payload.resposta.resumos.debitos.sumQuantidade} {this.props.consulta_view.payload.resposta.resumos.debitos.sumQuantidade > 1 ? 'Débitos' : 'Débito'}</h5><hr/>
                                        <Row>
                                            {this.props.consulta_view.payload.resposta.ocorrencias.debitos.map((debito, i) => {
                                                return (
                                                    <Col md={6}>
                                                        <div style={{
                                                            padding: '0.5rem', 
                                                            border: '1px solid #dee2e6', 
                                                            borderRadius: 5
                                                            }}>
                                                                <h5>{debito.credor}</h5><hr />
                                                                <p><b>Valor:</b> 
                                                                    <NumberFormat 
                                                                    prefix="R$ " 
                                                                    displayType="text" 
                                                                    value={parseFloat(debito.valor)} 
                                                                    decimalSeparator=","/>
                                                                </p>
                                                                <p><b>Data de Ocorrência:</b> {debito.dataOcorrencia}</p>
                                                                <p><b>Data de Inclusão:</b> {debito.dataInclusao}</p>
                                                                <p><b>Contrato/Fatura:</b> {debito.contratoFatura}</p>
                                                                <p><b>Tipo Devedor:</b> {debito.tipoDevedor}</p>
                                                                <p><b>Tipo:</b> {debito.tipo}</p>
                                                                <p><b>Cidade:</b> {debito.cidade}</p>
                                                                <p><b>UF:</b> {debito.uf}</p>
                                                        </div>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        :
                            <Row style={{marginBottom: '1rem'}}>
                                <Col md={12}>
                                    <div style={{
                                        overflow: 'auto',
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                        <h5>{this.props.consulta_view.payload.resposta.resumos.debitos.sumQuantidade} Débitos</h5><hr/>
                                        <p>Nada Consta</p>
                                    </div>
                                </Col>
                            </Row>
                        }

                        {this.props.consulta_view.payload.resposta.ocorrencias.protestos !== undefined &&
                        this.props.consulta_view.payload.resposta.ocorrencias.protestos.length > 0 ?
                            <Row style={{marginBottom: '1rem'}}>
                                <Col md={12}>
                                    <div style={{
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                        <h5>{this.props.consulta_view.payload.resposta.resumos.protestos.sumQuantidade} {this.props.consulta_view.payload.resposta.resumos.protestos.sumQuantidade > 1 ? 'Protestos' : 'Protesto'}</h5><hr/>
                                        <Row>
                                            {this.props.consulta_view.payload.resposta.ocorrencias.protestos.map((protesto, i) => {
                                                return (
                                                    <Col md={6}>
                                                        <div style={{
                                                            padding: '0.5rem', 
                                                            border: '1px solid #dee2e6', 
                                                            borderRadius: 5
                                                            }}>
                                                                <h5>{protesto.cidade} - {protesto.uf}</h5><hr />
                                                                <p><b>Valor:</b> 
                                                                    <NumberFormat 
                                                                    prefix="R$ " 
                                                                    displayType="text" 
                                                                    value={parseFloat(protesto.valor)} 
                                                                    decimalSeparator=","/>
                                                                </p>
                                                                <p><b>Data:</b> {protesto.data}</p>
                                                                <p><b>Cartório:</b> {protesto.cartorio}</p>
                                                        </div>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        :
                            <Row style={{marginBottom: '1rem'}}>
                                <Col md={12}>
                                    <div style={{
                                        overflow: 'auto',
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                        <h5>{this.props.consulta_view.payload.resposta.resumos.protestos.sumQuantidade} Protestos</h5><hr/>
                                        <p>Nada Consta</p>
                                    </div>
                                </Col>
                            </Row>
                        }

                        {this.props.consulta_view.payload.resposta.ocorrencias.acoes &&
                        this.props.consulta_view.payload.resposta.ocorrencias.acoes.length > 0 ?
                            <Row style={{marginBottom: '1rem'}}>
                                <Col md={12}>
                                    <div style={{
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                        {this.props.consulta_view.payload.resposta.resumos.acoes ?  
                                            <><h5>{this.props.consulta_view.payload.resposta.resumos.acoes.sumQuantidade} {this.props.consulta_view.payload.resposta.resumos.acoes.sumQuantidade > 1 ? 'Ações' : 'Ação'}</h5><hr/></>
                                            :
                                            <></>
                                        }
                                        <Row>
                                            {this.props.consulta_view.payload.resposta.ocorrencias.acoes.map((acao, i) => {
                                                return (
                                                    <Col md={6}>
                                                        <div style={{
                                                            padding: '0.5rem', 
                                                            border: '1px solid #dee2e6', 
                                                            borderRadius: 5
                                                            }}>
                                                                <h5>{acao.vara !== undefined ? acao.vara : ''} - {acao.cidade !== undefined ? acao.cidade : ''}</h5><hr />
                                                                <p><b>Valor:</b> 
                                                                    <NumberFormat 
                                                                    prefix="R$ " 
                                                                    displayType="text" 
                                                                    value={parseFloat(acao.valor !== undefined ? acao.valor : 0.00)} 
                                                                    decimalSeparator=","/>
                                                                </p>
                                                                <p><b>Data:</b> {acao.dataAcao !== undefined ? acao.dataAcao : ''}</p>
                                                                <p><b>Tipo ação:</b> {acao.tipoAcao !== undefined ? acao.tipoAcao : ''}</p>
                                                                <p><b>Avalista:</b> {acao.avalista !== undefined ? acao.avalista : ''}</p>
                                                                <p><b>Fórum:</b> {acao.forum !== undefined ? acao.forum : ''}</p>
                                                                <p><b>UF:</b> {acao.uf !== undefined ? acao.uf : ''}</p>
                                                                <p><b>Quantidade:</b> {acao.quantidade !== undefined ? acao.quantidade : ''}</p>
                                                                <p><b>Informações:</b> {acao.informacoesAdicionais !== undefined ? acao.informacoesAdicionais : ''}</p>
                                                                <p><b>Posição:</b> {acao.posicao !== undefined ? acao.posicao : ''}</p>
                                                        </div>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        :
                            (this.props.consulta_view.payload.resposta.resumos.acoes ?         
                                <Row style={{marginBottom: '1rem'}}>
                                    <Col md={12}>
                                        <div style={{
                                            overflow: 'auto',
                                            padding: '0.5rem', 
                                            border: '1px solid #dee2e6', 
                                            borderRadius: 5
                                            }}>
                                            <h5>{this.props.consulta_view.payload.resposta.resumos.acoes.sumQuantidade} Ações</h5><hr/>
                                            <p>Nada Consta</p>
                                        </div>
                                    </Col>
                                </Row>
                                :
                                <></>
                            )
                        }


                        {this.props.consulta_view.payload.resposta.ocorrencias.cheques !== undefined &&
                        this.props.consulta_view.payload.resposta.ocorrencias.cheques.length > 0 ?
                            <Row style={{marginBottom: '1rem'}}>
                                <Col md={12}>
                                    <div style={{
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                        <h5>{this.props.consulta_view.payload.resposta.resumos.cheques.sumQuantidade} {this.props.consulta_view.payload.resposta.resumos.acoes.sumQuantidade > 1 ? 'Ações' : 'Ação'}</h5><hr/>
                                        <Row>
                                            {this.props.consulta_view.payload.resposta.ocorrencias.cheques.map((cheque, i) => {
                                                return (
                                                    <Col md={6}>
                                                        <div style={{
                                                            padding: '0.5rem', 
                                                            border: '1px solid #dee2e6', 
                                                            borderRadius: 5
                                                            }}>
                                                                <h5>{cheque.banco !== undefined ? cheque.banco : ''}</h5><hr />
                                                                <p><b>Agência:</b> {cheque.agencia !== undefined ? cheque.agencia : ''}</p>
                                                                <p><b>Motivo:</b> {cheque.motivo !== undefined ? cheque.motivo : ''}</p>
                                                                <p><b>Origem:</b> {cheque.origem !== undefined ? cheque.origem : ''}</p>
                                                                <p><b>Posição:</b> {cheque.posicao !== undefined ? cheque.posicao : ''}</p>
                                                                <p><b>Quantidade:</b> {cheque.quantidade !== undefined ? cheque.quantidade : ''}</p>
                                                                <p><b>Último:</b> {cheque.ultimoEm !== undefined ? cheque.ultimoEm : ''}</p>
                                                        </div>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        :
                            <Row style={{marginBottom: '1rem'}}>
                                <Col md={12}>
                                    <div style={{
                                        overflow: 'auto',
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                        <h5>{this.props.consulta_view.payload.resposta.resumos.cheques.sumQuantidade} Cheques</h5><hr/>
                                        <p>Nada Consta</p>
                                    </div>
                                </Col>
                            </Row>
                        }

                        <Row>
                            <Col md={12}>
                                <div style={{
                                    overflow: 'auto',
                                    padding: '0.5rem', 
                                    border: '1px solid #dee2e6', 
                                    borderRadius: 5
                                    }}>
                                    <h5>Dados Cadastrais</h5><hr/>
                                    <p><b>CPF/CNPJ:</b> {documento}</p>
                                    {this.props.consulta_view.payload.resposta.ocorrencias.cadastro.dataNascimento !== undefined ? 
                                        <p><b>Nascimento:</b> {this.props.consulta_view.payload.resposta.ocorrencias.cadastro.dataNascimento}</p>
                                    :
                                        ''
                                    }                        
                                    {this.props.consulta_view.payload.resposta.ocorrencias.cadastro.situacaoCadastral !== undefined ? 
                                        <p><b>Situação Cadastral:</b> {this.props.consulta_view.payload.resposta.ocorrencias.cadastro.situacaoCadastral}</p>
                                    :
                                        ''
                                    }
                                    {this.props.consulta_view.payload.resposta.ocorrencias.cadastro.dataSituacaoCadastral !== undefined ? 
                                        <p><b>Data da Situação:</b> {this.props.consulta_view.payload.resposta.ocorrencias.cadastro.dataSituacaoCadastral}</p>
                                    :
                                        ''
                                    }                        
                                    {this.props.consulta_view.payload.resposta.ocorrencias.cadastro.idade !== undefined ? 
                                        <p><b>Idade:</b> {this.props.consulta_view.payload.resposta.ocorrencias.cadastro.idade}</p>
                                    :
                                        ''
                                    }                        
                                    {this.props.consulta_view.payload.resposta.ocorrencias.cadastro.maeNome !== undefined ? 
                                        <p><b>Mãe:</b> {this.props.consulta_view.payload.resposta.ocorrencias.cadastro.maeNome}</p>
                                    :
                                        ''
                                    }                        
                                    {this.props.consulta_view.payload.resposta.ocorrencias.cadastro.obitoProvavel !== undefined ? 
                                        <p><b>Obito provável:</b> {this.props.consulta_view.payload.resposta.ocorrencias.cadastro.obitoProvavel == "false" ? "não" : "sim"}</p>
                                    :
                                        ''
                                    }
                                    {this.props.consulta_view.payload.resposta.ocorrencias.cadastro.sexo !== undefined ? 
                                        <p><b>Sexo:</b> {this.props.consulta_view.payload.resposta.ocorrencias.cadastro.sexo}</p>
                                    :
                                        ''
                                    }
                                    {this.props.consulta_view.payload.resposta.ocorrencias.cadastro.signo !== undefined ? 
                                        <p><b>Signo:</b> {this.props.consulta_view.payload.resposta.ocorrencias.cadastro.signo}</p>
                                    :
                                        ''
                                    }
                                </div>
                            </Col>
                            
                        </Row>
                        <Row style={{marginTop: '1rem', marginBottom: '1rem'}}>
                            <Col md={12}>
                                <div style={{
                                overflow: 'auto',
                                padding: '0.5rem', 
                                border: '1px solid #dee2e6', 
                                borderRadius: 5
                                }}>
                                    <h5>Emails</h5><hr/>

                                    {this.props.consulta_view.payload.resposta.ocorrencias.emails !== undefined &&
                                    this.props.consulta_view.payload.resposta.ocorrencias.emails.length > 0 ?
                                        
                                        this.props.consulta_view.payload.resposta.ocorrencias.emails.map((email, i) => {
                                            return(
                                                <>
                                                {email.email !== undefined ? 
                                                    <div>
                                                        <p>{email.email}</p>
                                                    </div>
                                                :
                                                    <></>
                                                }
                                                </>
                                            )
                                        })
                                    :
                                        <p>Nenhum email encontrado</p>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {this.props.consulta_view.payload.resposta.ocorrencias.telefones.fixos !== undefined &&
                            this.props.consulta_view.payload.resposta.ocorrencias.telefones.fixos.length > 0 ?
                                
                                this.props.consulta_view.payload.resposta.ocorrencias.telefones.fixos.map((telefone, i) => {
                                    return(
                                        <Col md={6}>
                                            <div style={{
                                            overflow: 'auto',
                                            padding: '0.5rem', 
                                            border: '1px solid #dee2e6', 
                                            borderRadius: 5
                                            }}>
                                                <h5>Telefone fixo</h5><hr/>
                                                {telefone.numero !== undefined ? 
                                                    <div>
                                                        <p><b>Número:</b> {telefone.numero}</p>
                                                        <p><b>Relação:</b> {telefone.relacao}</p>
                                                        <p><b>Não pertube:</b> {telefone.naoPerturbe == false ? 'Não' : 'Sim'}</p>
                                                        <p><b>WhatsApp Business:</b> {telefone.aplicativos.whatsAppBusiness == false ? 'Não' : 'Sim'}</p>
                                                    </div>
                                                :
                                                    <></>
                                                }
                                            </div>
                                        </Col>
                                    )
                                })
                            :
                                <></>
                            }

                            {this.props.consulta_view.payload.resposta.ocorrencias.telefones.moveis !== undefined &&
                            this.props.consulta_view.payload.resposta.ocorrencias.telefones.moveis.length > 0 ?
                                
                                this.props.consulta_view.payload.resposta.ocorrencias.telefones.moveis.map((telefone, i) => {
                                    return(
                                        <Col md={6}>
                                        <div style={{
                                        overflow: 'auto',
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                            <h5>Celular</h5><hr/>
                                            {telefone.numero !== undefined ? 
                                                <div>
                                                    <p><b>Número:</b> {telefone.numero}</p>
                                                    <p><b>Relação:</b> {telefone.relacao}</p>
                                                    <p><b>Não pertube:</b> {telefone.naoPerturbe == false ? 'Não' : 'Sim'}</p>
                                                    <p><b>WhatsApp:</b> {telefone.aplicativos.whatsApp == false ? 'Não' : 'Sim'}</p>
                                                </div>
                                            :
                                                <></>
                                            }
                                        </div>
                                    </Col>
                                    )
                                })
                            :
                                <></>
                            }

                        </Row>
                        <Row style={{marginTop: '1rem', marginBottom: '1rem'}}>
                            {this.props.consulta_view.payload.resposta.ocorrencias.enderecos !== undefined &&
                            this.props.consulta_view.payload.resposta.ocorrencias.enderecos.length > 0 ?
                                
                                this.props.consulta_view.payload.resposta.ocorrencias.enderecos.map((endereco, i) => {
                                    return(
                                        <Col md={6}>
                                            <div style={{
                                            overflow: 'auto',
                                            padding: '0.5rem', 
                                            border: '1px solid #dee2e6', 
                                            borderRadius: 5
                                            }}>
                                                <h5>Endereço</h5><hr/>
                                                {endereco.logradouro !== undefined ? 
                                                    <div>
                                                        <p>{endereco.cep}</p>
                                                        <p>{endereco.tipoLogradouro}. {endereco.logradouro}, {endereco.numero} {endereco.complemento !== undefined && endereco.complemento !== '' ? ' - ' + endereco.complemento : ''}</p>
                                                        <p>{endereco.bairro}</p>
                                                        <p>{endereco.cidade} / {endereco.uf}</p>
                                                    </div>
                                                :
                                                    ''
                                                }
                                            </div>
                                        </Col>
                                    )
                                })
                            :
                                ''
                            }
                            
                        </Row>
                        {this.props.consulta_view.payload.resposta.ocorrencias.consultasAnteriores !== undefined &&
                        this.props.consulta_view.payload.resposta.ocorrencias.consultasAnteriores.length > 0 ?
                            <Row style={{marginBottom: '1rem'}}>
                                <Col md={12}>
                                    <div style={{
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                        <h5>{this.props.consulta_view.payload.resposta.resumos.consultasAnteriores.sumQuantidade} {this.props.consulta_view.payload.resposta.resumos.consultasAnteriores.sumQuantidade > 1 ? 'Consultas Anteriores' : 'Consulta Anterior'} </h5><hr/>
                                        <Row>
                                            {this.props.consulta_view.payload.resposta.ocorrencias.consultasAnteriores.map((consulta, i) => {
                                                return (
                                                    <Col md={6}>
                                                        <div style={{
                                                            padding: '0.5rem', 
                                                            border: '1px solid #dee2e6', 
                                                            borderRadius: 5
                                                            }}>
                                                                <h5>{consulta.consultante !== undefined ? consulta.consultante : ''}</h5><hr />
                                                                <p><b>Data:</b> {consulta.dataOcorrencia !== undefined ? consulta.dataOcorrencia : ''}</p>
                                                        </div>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        :
                            <Row style={{marginBottom: '1rem'}}>
                                <Col md={12}>
                                    <div style={{
                                        overflow: 'auto',
                                        padding: '0.5rem', 
                                        border: '1px solid #dee2e6', 
                                        borderRadius: 5
                                        }}>
                                        <h5>{this.props.consulta_view.payload.resposta.resumos.consultasAnteriores.sumQuantidade} Consultas Anteriores</h5><hr/>
                                        <p>Nada Consta</p>
                                    </div>
                                </Col>
                            </Row>
                        }

                    </ModalBody>
                    <ModalFooter hidden={this.state.loading}>
                        <Button color="danger" onClick={() => this.onClose(false)}><FaRegWindowClose /> Fechar</Button>
                    </ModalFooter>
                </Modal>
            );
        }
    }
}

export default ConsultaMixDataView;