import React from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, Row, Col, Input, CardFooter } from 'reactstrap';
import { columns } from './propsMovimentacao';
import debounce from 'lodash/debounce';
import Pagination from '../Pagination';
import { InputText } from '../../InputText/InputText';
import { InputMasked } from '../../InputText/InputMasked';

export const TableMovimentacao = (props) => {
    return (
        <Card>
            <CardBody>
                <Row >
                    <Col md={5}>
                        <div style={{ textAlign: 'left' }}>
                            <InputText
                                name="search"
                                placeholder="Buscar"
                                onChange={debounce((event) => props.isSearch(event), 1000)} />
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={6}>
                        <div style={{ textAlign: 'left' }}>
                            <InputMasked
                                name="document"
                                mask={props.is_cnpj === false ? "999.999.999-99" : "99.999.999/9999-99"}
                                placeholder={props.is_cnpj === false ? "CPF" : "CNPJ"}
                                onChange={debounce((event) => props.isSearch(event), 500)}
                                />
                        </div>
                        </Col>
                        <Col md={6}>
                        <div>
                            &nbsp;&nbsp;<input 
                                type="checkbox"
                                checked={props.is_cnpj}
                                onChange={() => props.onSearchDocument()}/> Pesquisar CNPJ</div>
                    </Col>
                </Row>
                {/* <br />
                <Row>
                    <Col md={6}>
                        <div style={{ textAlign: 'left' }}>
                            <InputText
                                name="taggy_serial"
                                placeholder="Número de Série da Taggy"
                                defaultValue={props.taggy_serial}
                                onChange={debounce((event) => props.isSearch(event), 500)}
                        />
                        </div>
                    </Col>
                </Row> */}
                <br />
                <Row>
                    <Col>De:
                    <Input
                            name="date_from"
                            type="date"
                            //disabled
                            disabled={!(props.filter === true)}
                            value={props.date_from}
                            onChange={(event) => props.isSearch(event)} /></Col>
                    <Col>Até:
                    <Input
                            name="date_to"
                            type="date"
                            disabled={!(props.filter === true)}
                            //disabled
                            value={props.date_to}
                            onChange={(event) => props.isSearch(event)} /></Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <input
                            type="checkbox"
                            checked={props.filter}
                            name="filter"
                            onChange={(value) => props.onFilter(value.target.checked)} /> Filtrar por data de alteração
                    </Col>
                </Row>
            </CardBody>
            <hr style={{ marginBottom: '4px' }} />
            <DataTable
                noHeader={true}
                button={true}
                noDataComponent={props.noDataComponent}
                columns={columns()}
                data={props.data}
                theme="default"
            />
            <CardFooter style={{ textAlign: 'center' }}>
                <Pagination
                    onPageChange={props.onPageChange}
                    activePage={props.activePage}
                    totalPage={props.totalPage}
                />
            </CardFooter>
        </Card>
    )
}