//LOCAL
//export const ENDPOINT = 'http://127.0.0.1:8000/api/';

//PRODUCTION
//export const ENDPOINT = 'https://apiadmin.aqpasso.com.br/api/';

//ENDPOINT Chat
export const ENDPOINT = 'https://apicrm.aqbank.com.br/api/';

// ENPOINT SHOPAQPAGO API
//export const ENDPOINT_SHOP = 'http://127.0.0.1:8000/api/';
export const ENDPOINT_SHOP = 'https://apishopaqpago.aqbank.com.br/api/';


export const ENDPOINT_AQPASSO = 'https://apiaqpasso.aqbank.com.br/api/';