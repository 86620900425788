import React, { Component } from 'react';
import PropagateLoader from "react-spinners/PropagateLoader";

import { Form, FormGroup, Row, Col } from 'reactstrap';
import BoxError from '../../components/BoxError/BoxError';
import { rendToken, secoundLogin } from '../../auth/services';
import { ButtonDefault } from '../../components/Button/ButtonDefault';
import { ButtonOutline } from '../../components/Button/ButtonOutline';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import { removeKeys } from '../../auth/set_services';


const CardBody = styled.div`
    font-size: 29px;
    margin: 0;
    padding: 12px;
`;
const Card = styled.div`    
    place-items: center;
    padding: 0;
    margin: 0;
    border-radius: 13px;
`;
const Input = styled.input`   
    width: 55px;
    height: 57px;
    border-radius: 8px;
    font-size: 31px;
    text-align: center;   
    border-color: ${props => (props.invalid ? "#dc3545" : "")};
`;
const OutTime = styled.div`
    text-align: right;
    display: ${props => (props.secounds === 0 ? "block" : "none")};
`
const Timer = styled.div`
    color: #808080;
    text-align: right;
    display: ${props => (props.secounds === 0 ? "none" : "block")};
`
const ALink = styled.a`
    color: #8cbd93;
    width: 117px;
    &:hover{
        color: #52c565;
        text-decoration: none;
    }
`
const MessageSMS = styled.p`
    position: relative;
    text-align: right;
    max-width: 410px;
`


class Token extends Component {
    constructor(props) {
        super(props);
        this.state = {
            number_1: '',
            number_1_invalid: false,
            number_2: '',
            number_2_invalid: false,
            number_3: '',
            number_3_invalid: false,
            number_4: '',
            number_4_invalid: false,
            number_5: '',
            number_5_invalid: false,
            secounds: 5,
            loading: false,
            error_message: ''
        }
        this.number1Input = React.createRef();
        this.number2Input = React.createRef();
        this.number3Input = React.createRef();
        this.number4Input = React.createRef();
        this.number5Input = React.createRef();
        this.onLogin = this.onLogin.bind(this);
        this.validateFields = this.validateFields.bind(this);
    }
    componentDidMount() {
        this.checkRedirect();
        setInterval(() => {
            if(this.state.secounds > 0){
                this.setState({
                    secounds: this.state.secounds-1
                });
            }
            
        }, 1000);
    }
    checkRedirect = () => {
        var url = new URL(window.location.href);
        var token = url.searchParams.get("token_sms");
        if(!token){
            window.location.href = '/login';
        }
    }
    getPathUrl = () => {
        return window.location.pathname;
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({ [name]: value });

        if(name === 'number_1'){
            if(value.length > 0){
                this.number2Input.focus();
            }
            else{
                //this.number1Input.current.focus();
            }
        }
        else if(name === 'number_2'){
            if(value.length > 0){
                this.number3Input.focus();
            }
            else{
                this.number2Input.focus();
                return;
            }
        }
        if(name === 'number_3'){
            if(value.length > 0){
                this.number4Input.focus();
            }
            else{
                this.number3Input.focus();
                return;
            }
        }
        if(name === 'number_4'){
            if(value.length > 0){
                this.number5Input.focus();
            }
            else{
                this.number4Input.focus();
                return;
            }
        }

    }
    closedError = () => {
        this.setState({
            loading: false,
            error_message: ''
        })
    }
    onlynumber = (evt) => {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^[0-9.]+$/;
        if( !regex.test(key) ) {
           theEvent.returnValue = false;
           if(theEvent.preventDefault) theEvent.preventDefault();
        }
     }
     onBackspace = (event) => {
        var key = event.keyCode || event.charCode;
        /* eslint eqeqeq: 0 */
        if( key == 8 ){
            let name = event.target.name;
            this.setState({ [name]: '' });
            event.preventDefault();
            if(name === 'number_2'){
                this.number1Input.focus();
            }
            if(name === 'number_3'){
                this.number2Input.focus();
            }
            if(name === 'number_4'){
                this.number3Input.focus();
            }
            if(name === 'number_5'){
                this.number4Input.focus();
            }

        }
     }
    validateFields() {
        var invalid = 0;

        if (this.state.number_1.length > 0) {
            this.setState({ number_1_invalid: false })
        }
        else {
            invalid++;
            this.setState({ number_1_invalid: true })
        }

        if (this.state.number_2.length > 0) {
            this.setState({ number_2_invalid: false })
        }
        else {
            invalid++;
            this.setState({ number_2_invalid: true })
        }

        if (this.state.number_3.length > 0) {
            this.setState({ number_3_invalid: false })
        }
        else {
            invalid++;
            this.setState({ number_3_invalid: true })
        }

        if (this.state.number_4.length > 0) {
            this.setState({ number_4_invalid: false })
        }
        else {
            invalid++;
            this.setState({ number_4_invalid: true })
        }

        if (this.state.number_5.length > 0) {
            this.setState({ number_5_invalid: false })
        }
        else {
            invalid++;
            this.setState({ number_5_invalid: true })
        }

        return invalid;

    }
    onData(data) {
        if (data.success === true) {
            window.location.replace("/dashboard");
        }
        else {
            this.setState({
                loading: false,
                error_message: data.errors
            })
        }
    }
    onLogin() {
        if(this.validateFields() === 0){
            this.setState({loading: true});
            var code = this.state.number_1 + this.state.number_2 + this.state.number_3 + this.state.number_4 + this.state.number_5;
            
            var url = new URL(window.location.href);
            var token = url.searchParams.get("token_sms");
            
            secoundLogin(code, token, this.onData.bind(this));
        }
    }

    onBack() {
        removeKeys();
        window.location.href = '/login';
    }
    onRend(){
        var url = new URL(window.location.href);
        var token = url.searchParams.get("token_sms");
        this.setState({secounds: 120})
        rendToken(token, this.onToast.bind(this));

    }
    onToast(data) {
        if (data.success === true) {
            toast.success(data.message);
        }
        else {
            toast.success(data.errors);
        }
    }
    limparCampos() {
        this.number1Input.focus();
        this.setState({
            number_1: '',
            number_2: '',
            number_3: '',
            number_4: '',
            number_5: '',
        })
    }
    render() {
        return (
            <>
                <Form hidden={this.state.loading}>
                    <h4 style={{ margin: '25px', textAlign: 'right' }}>Confirmação de Token</h4>
                    <MessageSMS>Foi enviado um código por e-mail e sms para você, confirme o código abaixo</MessageSMS>
                    <FormGroup>
                        <Row style={{ placeContent: 'center' }}>
                            <Col md={2} sm={2} xs={2}>
                                <Input 
                                autoFocus
                                    //ref={this.number1Input}
                                    ref={(input) => { this.number1Input = input; }}
                                    name="number_1"
                                    type="text"
                                    className="form-control" 
                                    value={this.state.number_1}
                                    invalid={this.state.number_1_invalid}
                                    maxLength={1} 
                                    onKeyPress={this.onlynumber}
                                    onChange={this.handleChange}
                                    onKeyDown={this.onBackspace}
                                />
                            </Col>
                            <Col md={2} sm={2} xs={2}>
                                <Input 
                                    //ref={this.number2Input}
                                    ref={(input) => { this.number2Input = input; }}
                                    name="number_2"
                                    type="text"
                                    className="form-control" 
                                    value={this.state.number_2}
                                    invalid={this.state.number_2_invalid}
                                    maxLength={1} 
                                    onKeyPress={this.onlynumber}
                                    onChange={this.handleChange}
                                    onKeyDown={this.onBackspace} />
                            </Col>
                            <Col md={2} sm={2} xs={2}>
                                <Input 
                                   //ref={this.number3Input}
                                    ref={(input) => { this.number3Input = input; }}
                                    name="number_3"
                                    type="text"
                                    className="form-control" 
                                    value={this.state.number_3}
                                    invalid={this.state.number_3_invalid}
                                    maxLength={1} 
                                    onKeyPress={this.onlynumber}
                                    onChange={this.handleChange}
                                    onKeyDown={this.onBackspace}  />
                            </Col>
                            <Col md={2} sm={2} xs={2}>
                                <Input 
                                    //ref={this.number4Input}
                                    ref={(input) => { this.number4Input = input; }}
                                    name="number_4"
                                    type="text"
                                    className="form-control" 
                                    value={this.state.number_4}
                                    invalid={this.state.number_4_invalid}
                                    maxLength={1} 
                                    onKeyPress={this.onlynumber}
                                    onChange={this.handleChange}
                                    onKeyDown={this.onBackspace} />
                            </Col>
                            <Col md={2} sm={2} xs={2}>
                                <Input 
                                    //ref={this.number5Input}
                                    ref={(input) => { this.number5Input = input; }}
                                    name="number_5"
                                    type="text"
                                    className="form-control" 
                                    value={this.state.number_5}
                                    invalid={this.state.number_5_invalid}
                                    maxLength={1} 
                                    onKeyPress={this.onlynumber}
                                    onChange={this.handleChange}
                                    onKeyDown={this.onBackspace} />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <div style={{display: 'inline'}}>
                            <OutTime secounds={0}><ALink href={"#"} onClick={this.limparCampos.bind(this)}><b>Limpar Token</b></ALink></OutTime>
                            <Timer secounds={this.state.secounds}><b>Reenvie o código em: {this.state.secounds}</b></Timer>
                            <OutTime secounds={this.state.secounds}><ALink href={"#"} onClick={this.onRend.bind(this)}><b>Reenviar token</b></ALink></OutTime>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <center>
                            <Row>
                                <Col>
                                    <ButtonOutline color="success" style={{ width: '95%' }} onClick={this.onBack.bind(this)}>
                                        Cancelar
                                    </ButtonOutline>
                                </Col>
                                <Col>
                                    <ButtonDefault color="success" style={{ width: '95%' }} onClick={this.onLogin}>
                                        Confirmar
                                    </ButtonDefault>
                                </Col>
                            </Row>
                        </center>
                    </FormGroup>
                </Form>
                <Card hidden={!this.state.loading} style={{ border: 'none', padding: '10%' }}>
                    <CardBody>
                        <center>
                            <div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div>
                        </center>
                    </CardBody>
                </Card>
                <BoxError
                    visible={this.state.error_message.length > 0 ? true : false}
                    title="Falha ao realizar login"
                    message={String(this.state.error_message)}
                    onChange={this.closedError.bind(this)}
                />
                <ToastContainer hideProgressBar/>
            </>
        );
    }
}

export default Token;
