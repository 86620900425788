import moment from 'moment';
import React, { Component   } from 'react';
import { Card, CardBody, Button } from "reactstrap";
import CupomModal from '../../components/Modals/CupomModals/CupomModal';
import { TableVendas } from '../../components/Tables/TableVendas/TableVendas';
import { getRows, getRowsPerPage } from '../../components/Tables/TableContabilidade/propsContabilidade';
import PropagateLoader from "react-spinners/PropagateLoader";
import { toast } from 'react-toastify';
import axios from 'axios';
import { getToken } from '../../auth/set_services';
import { ENDPOINT } from '../../auth/endpoint';
import { TableContabilidade } from '../../components/Tables/TableContabilidade/TableContabilidade';
import ModalDialog from '../../components/Modals/ReturnsApiModals/ModalDialog';

import ClipLoader from 'react-spinners/ClipLoader';

class Contabil extends Component{
    constructor(props){
        super(props);
        this.state = {
            modal_cupom: false,
            search: '',
            document: '',
            is_cnpj: false,
            rowData: [],
            relatorio: [],
            status: null,
            plano: null,
            forma_pagamento: null,
            totalPage: 0,
            activePage: 1,
            pending: false,
            filter: false,
            loading_table: true,
            loading_faturas: false,
            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            loading: false,
            error_message: '',
            success_message: '',
        }
    }
    onClose = () => {
        this.setState({modal_cupom: false});
        getRows(this.state, this.callbackRows.bind(this));
    }
    selectModeloMaquininha = (value) => {
        var state = this.state;
        state.plano = value;
        state.activePage = 1;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});
        
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }
    selectStatus = (value) => {
        var state = this.state;
        state.status = value;
        state.activePage = 1;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});
        
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }
    
    onRelatorio = (relatorio) => {
        this.setState({relatorio})
    }

    selectFormaPagamento = (value) => {
        var state = this.state;
        state.forma_pagamento = value;
        state.activePage = 1;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});
        
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }
    handleChange = (event) => {
       
        var state = this.state;
        let name = event.target.name;
        let value = event.target.value;

        if(name === 'document'){
            if(value.replace(/([^\d])+/gim, '').length > 10){
                state[name] = value;
                state.activePage = 1;
                state.rowData = [];
                state.loading_table = true;
                this.setState({...state});

                getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
            }
            else if(value.replace(/([^\d])+/gim, '').length === 0){
                if(state[name].replace(/([^\d])+/gim, '').length > 0){
                    state[name] = value;
                    state.activePage = 1;
                    state.rowData = [];
                    state.loading_table = true;
                    this.setState({...state});
                    
                    getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
                }
            }
        }
        else{
            state[name] = value;
            state.activePage = 1;
            state.rowData = [];
            state.loading_table = true;
            this.setState({...state});
            
            getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
        }
    }

    componentDidMount(){
        getRows(this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({rowData, activePage, totalPage, loading_table: false})
    }

    onFilter = (filter) => {
        this.setState({filter});
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    

    onPending = (pending) => {
        //this.setState({pending});
        var state = this.state;
        state.pending= pending;
        state.activePage = 1;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});
        getRowsPerPage(1, state.search, state.filter, state, this.callbackRows.bind(this));
    }

    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    

    onShowDelete(delete_id, visible_delete = true){
        this.setState({
            delete_id,
            visible_delete
        })
    }

    onError(){
        this.setState({
            loading: false,
            error_message: ''
        })
    }

    onDelete = () => {
        this.setState({loading: true})
        var url = ENDPOINT+"aqcontabil-admin/delete/"+this.state.delete_id;
        axios.delete(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.onCloseDelete();
            return data;
        })
        .catch((error) => {
            console.log(error)
            var response = '';
            var obj;
            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            if(data.exception){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            this.onData(data);
            return data;
        });
    }


    onData(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            });
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onCloseDelete = () => {
        this.setState({visible_delete: false, delete_id: -1});
        getRows(this.state, this.callbackRows.bind(this));
    }
    
    fecharFaturas = () => {
        toast.info("Fechando faturas pendentes");
        this.setState({
            loading_faturas: true
        })
        var url = ENDPOINT+"fecharFaturasAQContabil";
        var formData = new FormData();
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.setState({
                loading_faturas: false
            })
            getRows(this.state, this.callbackRows.bind(this));
            return data;
        })
        .catch((error) => {
            console.log(error);
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                toast.success(obj.errors);
                this.setState({
                    loading_faturas: false
                })
                return obj;
            }
            var data = error.response.data;
            toast.success(response.errors);
            this.setState({
                loading_faturas: false
            })
            return data;
        });
    }
    render(){
        return(
            <div>
                <h3>Gerenciamento AQContabil</h3>
                <hr/>
                <Button 
                    color='info' 
                    disabled={this.state.loading_faturas}
                    onClick={() => this.fecharFaturas()}>
                        {this.state.loading_faturas === true ? <ClipLoader color="#fff" size={15}/> :<></> } Fechar Faturas Pendentes
                </Button>
                <hr/>
                <Card >
                    <TableContabilidade
                        relatorio={this.state.relatorio}
                        isSearch={this.handleChange}
                        selectModeloMaquininha={this.selectModeloMaquininha}
                        selectStatus={this.selectStatus}
                        selectFormaPagamento={this.selectFormaPagamento}
                        is_cnpj={this.state.is_cnpj}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        data={this.state.rowData}
                        onFilter={this.onFilter.bind(this)}
                        onPending={this.onPending.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        onShowDelete={this.onShowDelete.bind(this)}
                        filter={this.state.filter}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhum pedido encontrado
                            </div></CardBody></Card>
                        }
                        pending={this.state.pending}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <ModalDialog
                    visible={this.state.visible_delete}
                    onError={this.onError.bind(this)}
                    message="Deseja remover esse pedido ?"
                    onClose={() => this.onShowDelete(-1, false)}
                    error_message={this.state.error_message}
                    loading={this.state.loading}
                    onSave={this.onDelete.bind(this)}
                    success_message={this.state.success_message}
                />
            </div>
        )
    }
}

export default Contabil;