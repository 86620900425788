import moment from 'moment';
import React, { Component   } from 'react';
import { Card, CardBody, Button, Modal, ModalBody, ListGroupItem, ListGroup } from "reactstrap";
import CupomModal from '../../components/Modals/CupomModals/CupomModal';
import PropagateLoader from "react-spinners/PropagateLoader";
import { toast } from 'react-toastify';
import axios from 'axios';
import { getToken } from '../../auth/set_services';
import { ENDPOINT } from '../../auth/endpoint';
import ModalDialog from '../../components/Modals/ReturnsApiModals/ModalDialog';
import { TableCuponsAqcontabil } from '../../components/Tables/TableCuponsAqcontabil/TableCuponsAqcontabil'
import { getRows, getRowsPerPage, getUsosCupom } from '../../components/Tables/TableCuponsAqcontabil/propsCuponsAqcontabil';
import CupomModalAQContabil from '../../components/Modals/CupomModals/CupomModalAQContabil';

const ModalShowUsers = (props) => {
    return <Modal isOpen={props.visible} size="lg">
        <ModalBody>
            <div style={{float: 'right'}}>
                <Button
                    outline
                    onClick={props.onClose}
                    style={{paddingTop: '3px', paddingBottom: '3px', fontSize: '19px', paddingLeft: '5px', paddingRight: '5px', fontWeight: '600', border: 'none'}}
                    color='danger'>
                        X
                    </Button>
            </div>
            <div>
                <h5 style={{paddingTop: '3px', paddingBottom: '3px', paddingLeft: '5px', paddingRight: '5px'}}>Cupom {props.hash} usado por:</h5>
            </div>
            <hr/>
            <ListGroup>
                {props.list.map(p => (
                    <ListGroupItem>
                        {p.usuario !== null ? p.usuario.nome+' '+p.usuario.sobrenome+' - '+(p.usuario.cnpj ? p.usuario.cnpj : p.usuario.cpf) : ''} às {moment(p.created_at).format('DD/MM/YYYY HH:mm')}
                    </ListGroupItem>
                ))}
            </ListGroup>
            {props.list.length == 0 ?
                <ListGroup>
                    <ListGroupItem>
                        Nenhum uso encontrado
                    </ListGroupItem>
                </ListGroup>
            :
            null}
        </ModalBody>
    </Modal>
}
class ClientesCuponsAqContabil extends Component{
    constructor(props){
        super(props);
        this.state = {
            modal_show_user: false,
            users_lists_user: [],
            hash_user: '',
            modal_cupom: false,
            search: '',
            rowData: [],
            totalPage: 0,
            activePage: 1,
            filter: false,
            loading_table: true,
            column: '',
            sortDirection: '',
            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            
            delete_id: -1,
            error_message: '',
            success_message: '',
            loading: false,
            visible_delete: false,
        }
    }
    onClose = () => {
        this.setState({modal_cupom: false});
        getRows(this.state, this.callbackRows.bind(this));
    }
    bloquear_ativar_cupom = (hash, status) => {
        var url = ENDPOINT+"cupons/bloquear_ativar_cupom/"+hash;
        var formData = new FormData();
        formData.append('status', status);
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then(() => {
            toast.success(`Buyer ${status == 1 ? 'ativado' : 'bloqueado'} com sucesso`);
            getRows(this.state, this.callbackRows.bind(this));
        })
        .catch((error) => {
            var data = error.response.data;
            toast.error(data.errors);
        })
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentDidMount(){
        getRows(this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({rowData, activePage, totalPage, loading_table: false})
    }

    onFilter = (filter) => {
        this.setState({filter});
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    

    onCloseDelete = () => {
        this.setState({visible_delete: false, delete_id: -1});
        getRows(this.state, this.callbackRows.bind(this));
    }


    onData(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            });
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }

    onShowDelete(delete_id, visible_delete = true){
        this.setState({
            delete_id,
            visible_delete
        })
    }

    onError(){
        this.setState({
            loading: false,
            error_message: ''
        })
    }

    onDelete = () => {
        this.setState({loading: true})
        var url = ENDPOINT+"cupons-aqcontabil/delete/"+this.state.delete_id;
        axios.delete(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.onCloseDelete();
            return data;
        })
        .catch((error) => {
            var response = '';
            var obj;
            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            if(data.exception){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            this.onData(data);
            return data;
        });
    }
    customSort = (column, sortDirection) => {
        var state = this.state;
        state.column = column.selector;
        state.sortDirection = sortDirection;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
        return true;
    };

    callbackCupomUso = (hash, data) => {
        this.setState({
            users_lists_user: data.data,
            hash_user: hash,
            modal_show_user: true
        })
    }
    setCupomUso = (hash) => {
        getUsosCupom(hash, this.callbackCupomUso);
    }

   /*  handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        var state = this.state;
        state.column = column.selector;
        state.sortDirection = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    } */
    render(){
        return(
            <div>
                <h3>Gerenciamento de cupons</h3>
                <hr/>
                <Button onClick={() => this.setState({modal_cupom: true})}>Criar Cupom</Button>
                <br/>
                <br/>
                <Card >
                    <TableCuponsAqcontabil
                        isSearch={this.handleChange}
                        customSort={this.customSort}
                        setCupomUso={this.setCupomUso}
                        data={this.state.rowData}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhum cupom encontrado
                            </div></CardBody></Card>
                        }
                        onDelete={this.onShowDelete.bind(this)}
                        bloquear_ativar_cupom={this.bloquear_ativar_cupom}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <CupomModalAQContabil 
                    visible={this.state.modal_cupom}
                    onClose={this.onClose}/>
                <ModalShowUsers
                    visible={this.state.modal_show_user}
                    list={this.state.users_lists_user}
                    hash={this.state.hash_user}
                    onClose={() => this.setState({ modal_show_user: false, users_lists_user: [], hash_user: '' })}
                />
                <ModalDialog
                    visible={this.state.visible_delete}
                    onError={this.onError.bind(this)}
                    message="Deseja remover esse cupom ?"
                    onClose={() => this.onShowDelete(-1, false)}
                    error_message={this.state.error_message}
                    loading={this.state.loading}
                    onSave={this.onDelete.bind(this)}
                    success_message={this.state.success_message}
                />
            </div>
        )
    }
}

export default ClientesCuponsAqContabil;