import React from 'react';
import { HiViewGrid } from 'react-icons/hi';
import { Button, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getPermissoes, getToken } from '../../../auth/set_services';
import ReactTooltip from 'react-tooltip';
import 'react-dropdown/style.css';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';


/* const capitalizeFirstLetter = (string) => {
  if(string !== null){
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "Indisponivel"
} */
/* const Div = styled.div`
  .dropdown-toggle:after { 
    content: none 
  }
  .dropdown-toggle:before { 
    content: none 
  }
  .btn-primary {
    color: #007bff;
    background-color: #ffffff;
    border-color: #ffffff;
    padding: 0;
  }
  .btn-primary:hover {
    background-color: #007bff;
    color: #ffffff;
    border-color: #007bff;
    padding: 0;
  }
`;

const getTimeline = (type, empresa, userId) =>{
  if(empresa !== null){
    switch(type){
      case 'email': return '/empresa/'+empresa.id+'?active=9';
      case 'sms': return '/empresa/'+empresa.id+'?active=10'; 
    }
  }
  else{
    switch(type){
      case 'email': return '/user/'+userId+'?active=6'; 
      case 'sms': return '/user/'+userId+'?active=7'; 
    }

  }
  return '';
}
 */
export const columns = () => [
  {
    name: '',
    selector: 'buttons',
    width: '5%',
    cell: row => <Row>
      <Button data-tip="Resumo do Cliente"
        outline
        style={{ padding: '0px', marginRight: '3px', border: 0 }}
        disabled={row.user_tipo === 'leitura'}
        color="primary" onClick={() => {
          if (row.cliente_existe === false) {
            window.location.href = '/aqpago/' + (typeof (row.cpf) !== 'undefined' && row.cpf !== null ? row.cpf : row.cnpj)
          }
          else {
            window.location.href = '/user/' + row.cliente_existe_id
          }
        }
        }>
        <HiViewGrid style={{ padding: '2px', width: '23px' }} />
      </Button>
      <ReactTooltip />
    </Row>
  },
  {
    name: "Nome / Empresa",
    selector: "nome",
    sortable: false,
    width: '20%',
    cell: row => row.nome
  },
  {
    name: "Email do Responsavel",
    selector: "email",
    sortable: false,
    width: '20%',
    cell: row => row.email
  },
  {
    name: "Aceite de termo",
    selector: "termos_aceito",
    sortable: false,
    width: '15%',
    cell: row => <div>
      {row.termos_aceito == true ? 'Sim' : 'Pendente'}
    </div>
  },
  {
    name: "Telefone",
    selector: "telefone",
    sortable: false,
    width: '20%',
    cell: row => <NumberFormat displayType={'text'} value={row.telefone} format="(##) #####-####" />
  },
  {
    name: "Documento",
    selector: "cpf",
    sortable: false,
    width: '20%',
    cell: row => {
      var value = row.cnpj !== null ? row.cnpj : row.cpf
      if (value !== null) {
        return <NumberFormat displayType={'text'} value={value} format={value.replace(/([^\d])+/gim, '').length > 11 ? '##.###.###/####-##' : "###.###.###-##"} />
      }
      return "S/N";
    }
  }
];

export const getRows = (state, callback) => {
  var url = ENDPOINT + "user/all_aqbank?page=" + state.activePage + "&search=" + state.search;

  var document = state.document.replace(/([^\d])+/gim, '');
  if (state.is_cnpj === false) {
    url += "&cpf=" + document;
  }
  else {
    url += "&cnpj=" + document;
  }

  if (state.seller_id) {
    url += "&seller_id=" + state.seller_id
  }

  if (state.status_user.length > 0) {
    url += "&status_usuario=" + state.status_user
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      callback(p.data, p.meta.last_page);
    })
    .catch(() => {
      toast.error('Ocorreu um erro no servidor!');
    })
}

export const getRowsPerPage = (page, state, callback) => {
  var url = ENDPOINT + "user/all_aqbank?page=" + page + "&search=" + state.search;
  
  var document = state.document.replace(/([^\d])+/gim, '');
  if (state.is_cnpj === false) {
    url += "&cpf=" + document;
  }
  else {
    url += "&cnpj=" + document;
  }


  if (state.seller_id) {
    url += "&seller_id=" + state.seller_id
  }


  if (state.status_user.length > 0) {
    url += "&status_usuario=" + state.status_user
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      callback(p.data, p.meta.last_page)
    })
    .catch(() => {
      toast.error('Ocorreu um erro no servidor!');
    })
}