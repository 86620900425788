import React from 'react';
import ReactTooltip from 'react-tooltip';

export const UniodontoIcon = ({ onClick, active = false }) => {
  return (
    <div style={{ position: "relative" }}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="191.000000pt"
        height="169.000000pt"
        viewBox="0 0 191.000000 169.000000"
        preserveAspectRatio="xMidYMid meet"
        style={{
          cursor: active ? "pointer" : "not-allowed",
          transition: "opacity 0.3s",
        }}
        onClick={active ? onClick : () => {}}
        data-tip={active ? "Contrato Uniodonto": ""}
      >
        <style>
          {
            `
            svg:hover {
              opacity: 0.5;
            }
            `
          }
        </style>
        <g
          transform="translate(0.000000,169.000000) scale(0.120000,-0.120000)"
          fill={"#A60169"}
          stroke="none"
        >
          <path
            d="M846 1515 c-64 -14 -73 -18 -157 -59 -141 -68 -253 -200 -316 -371
       -14 -40 -18 -79 -18 -191 0 -137 1 -143 35 -228 87 -218 280 -371 500 -397
       203 -25 384 31 522 160 37 35 78 80 90 100 12 20 24 38 27 41 8 6 34 64 54
       115 25 65 40 179 34 242 -10 82 -24 158 -35 179 -71 148 -82 164 -153 236 -61
       61 -94 85 -165 119 -49 24 -109 47 -134 51 -81 16 -222 17 -284 3z m-80 -442
       c-3 -60 -9 -151 -12 -203 -9 -137 9 -163 134 -196 67 -18 117 -18 184 0 136
       36 143 49 133 243 -4 81 -8 173 -8 203 l-2 55 65 0 65 0 7 -60 c4 -33 11 -131
       14 -217 6 -145 5 -160 -14 -200 -51 -109 -184 -173 -352 -172 -96 1 -151 13
       -220 48 -72 36 -95 59 -126 125 -28 59 -28 50 -9 351 9 137 5 130 82 130 l66
       0 -7 -107z"
          />
        </g>
      </svg>
      <ReactTooltip />
    </div>
  );
};
