import axios from 'axios';
import moment from 'moment';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { HiViewGrid } from 'react-icons/hi';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import { ENDPOINT } from '../../../../auth/endpoint';
import { getToken } from '../../../../auth/set_services';
import { fetchWithTimeout } from '../../../Tools/fetchWithTimeout';

export const columns = (props, editRuleShipping, cancelSaveFreeShipping) => [
    {
      name: "",
      selector: "buttons",
      sortable: false,
      width: '10%',
      cell: row => <Row>
        <Button
          outline
          style={{ padding: 2, marginLeft: 15, border: 0, fontWeight: '700'}}
          color="dark" onClick={() => 
            {
              editRuleShipping(row)
            }
          }>
          <FaEdit style={{ padding: '2px' }}/>
        </Button>
      </Row>
    },
    {
      name: "Mínimo",
      selector: "minimo",
      sortable: false,
      width: '15%',
      cell: row =>
            <NumberFormat 
                displayType={'text'}
                prefix={'R$ '}
                decimalSeparator={'.'}
                decimalScale={2}
                value={row.min_amount}
              />
    },
    {
      name: "Máximo",
      selector: "maximo",
      sortable: false,
      width: '15%',
      cell: row =>
            <NumberFormat 
              displayType={'text'}
              prefix={'R$ '}
              decimalSeparator={'.'}
              decimalScale={2}
              value={row.max_amount}
              />
    },
    {
      name: "Peso máximo",
      selector: "taxas",
      sortable: false,
      width: '20%',
      cell: row => <Row>
          <Col md={12}>
            <NumberFormat 
              displayType={'text'}
              prefix={'Kg: '}
              decimalSeparator={'.'}
              decimalScale={3}
              value={row.weight}
              />
          </Col>
      </Row>
    },
    {
        name: "Dias para utilizar",
        selector: "taxas",
        sortable: false,
        width: '15%',
        cell: row => <Row>
            <Col md={12}>
                {(row.expire == 1) ? row.expire + ' dia' : row.expire + ' dias'}
            </Col>
        </Row>
    },
    {
        name: "Parcela mínima",
        selector: "installments_min",
        sortable: false,
        width: '15%',
        cell: row => <Row>
            <Col md={12}>
                {row.installments_min}X
            </Col>
        </Row>
    },
    {
      name: "",
      selector: "cancel",
      sortable: false,
      width: '10%',
      cell: row => <Row>
        <Button
          outline
          style={{ padding: 2, marginLeft: 15, border: 0, fontWeight: '700'}}
          color="dark" onClick={() => 
            {
              cancelSaveFreeShipping(row.id)
            }
          }>
          <FaTrash style={{ padding: '2px' }}/>
        </Button>
      </Row>
    },
];

var numberOfAjaxCAllPending = 0;

export const getRows = (state, callback) => {
  if(numberOfAjaxCAllPending == 0){
    numberOfAjaxCAllPending = 1;
    var url = ENDPOINT + "aqenvio/list_rules_free?page=" + state.activePage + "&search=" + state.search;
    //var url = "http://localhost:8000/api/aqenvio/list_rules_free?page=" + state.activePage + "&search=" + state.search;

    if(state.rule_for !== null){
      url += "&rule_for="+state.rule_for.value;
    }      
    
    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        },
        mode: 'cors',
        cache: 'default'
    })
    .then((response) => {
        let p = response.data;
        if(p.success == true){
          var meta = 0;
          if(p.meta){
            meta = p.meta.last_page;
          }
          numberOfAjaxCAllPending = 0;
          // callback(p.data, meta, p.valor);
          callback(p.data, meta);
        }
        else{
          toast.error(p.errors);
        }
    })
    .catch((error) => {
        toast.error('Ocorreu um erro no servidor!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);

      callback({}, {});
    })
  }
  else{
    callback(state.rowData, state.totalPage, []);
  }
}

export const getRowsPerPage = (page, state, callback) => {
  if(numberOfAjaxCAllPending == 0){
    numberOfAjaxCAllPending = 1;

    var url = ENDPOINT + "aqenvio/list_rules_free?page=" + state.activePage + "&search=" + state.search;
    //var url = "http://127.0.0.1:8000/api/aqenvio/list_rules_free?page=" + state.activePage + "&search=" + state.search;

    if(state.rule_for !== null){
      url += "&rule_for="+state.rule_for.value;
    }

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());
    
    var myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };

    fetchWithTimeout(20000, fetch(url, myInit))
    .then(response => response.json())
    .then((p) => {
      if(p.success == true){
        var meta = 0;
        if(p.meta){
          meta = p.meta.last_page;
        }
        numberOfAjaxCAllPending = 0;
        // callback(p.data, meta, p.valor);
        callback(p.data, meta);
      }
      else{
        toast.error(p.errors);
      }
    })
    .catch((error) => {
      toast.error('Ocorreu um erro no servidor!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        //console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);

      
      callback({}, {});
    })
  }
  else{
    callback(state.rowData, state.totalPage, []);
  }
}