import axios from 'axios';
import moment from 'moment';
import { Button } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';


/* const getStatusTransaction = (status) => {
  if(status === 'authorized'){
      return 'Autorizado';
  }
  else if(status === 'refunded'){
      return 'Devolvido';
  }
  else{
      return 'Capturado';
  }
} */

const capitalize = str => {
	if (typeof str !== 'string') {
		return '';
	}
	return str.charAt(0).toUpperCase() + str.substr(1);
}
export const columns = (onExtorno) => [
    {
      name: "",
      selector: "buttons",
      sortable: false,
      width: '100px',
      cell: p => <>
        {p.status_extorno !== null ? <p>{p.status_extorno}</p> : <></>}
        <Button hidden={p.status_extorno !== null || p.valor == 0} color='danger' style={{fontSize: '11px'}} onClick={() => onExtorno(true, p.id)}>Extornar</Button>
      </>
    },
    {
      name: "Data",
      selector: "created_at",
      sortable: false,
      width: '100px',
      cell: p => moment(p.created_at).format('DD/MM/YYYY HH:mm')
    },
    {
      name: "ID da Fatura",
      selector: "fatura_id",
      sortable: false,
      width: '80px',
      cell: p => '#'+p.fatura_id
    },
    {
      name: "Descrição de Movimentação",
      selector: "descricao",
      sortable: false,
      width: '25%',
      cell: p => p.descricao
    },
    {
      name: "Tipo de Movimentação",
      selector: "tipo",
      sortable: false,
      width: '15%',
      cell: p => p.tipo
    },
    {
      name: "Valor anterior",
      selector: "valor_anterior",
      sortable: false,
      width: '100px',
      cell: row => row.valor_anterior !== null ? parseFloat(row.valor_anterior).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : 'Sem Informações'
    },
    {
      name: "Valor atual",
      selector: "valor_atual",
      sortable: false,
      width: '100px',
      cell: row => row.valor_atual !== null ? parseFloat(row.valor_atual).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : 'Sem Informações'
    },
    {
      name: "",
      selector: "status",
      sortable: false,
      width: '20%',
      cell: row => row.fatura.conta.user ? <center style={{margin: '5px'}}><b>{row.fatura.conta.nome_fatura}</b><br/>{row.fatura.conta.user.cnpj ? row.fatura.conta.user.cnpj :row.fatura.conta.user.cpf}</center> : 'Sem Informações'
    }
];

export const getRows = (state, callbackRows) => {
    
    var url = ENDPOINT + "faturas_aqpasso/list";


    if(state.document.replace(/([^\d])+/gim, '').length > 0){
      if(state.document.replace(/([^\d])+/gim, '').length === 11){
          url +='&cpf='+state.document.replace(/([^\d])+/gim, '');
      }
      else if(state.document.replace(/([^\d])+/gim, '').length === 14){
          url +='&cnpj='+state.document.replace(/([^\d])+/gim, '');
      }
    }
    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    
    var url = ENDPOINT + "faturas_aqpasso/list?search="+search+"&page="+page;

    if(filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    
    if(state.document.replace(/([^\d])+/gim, '').length > 0){
      if(state.document.replace(/([^\d])+/gim, '').length === 11){
          url +='&cpf='+state.document.replace(/([^\d])+/gim, '');
      }
      else if(state.document.replace(/([^\d])+/gim, '').length === 14){
          url +='&cnpj='+state.document.replace(/([^\d])+/gim, '');
      }
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}