import React, { createRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import styled from "styled-components";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PDFDocumentWrapper = styled.div`
  canvas {
    width: 100% !important;
    height: auto !important;
  }
  button{
    border: none;
    color: white;
    background-color: blue;
    padding-right: 9px;
    padding-left: 9px;
    border-radius: 25px;
    margin: 3px;

    &:disabled {
      opacity: 0.8;
    }
  }
`;
export default function PDFDocument(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page
  const pdfWrapper = createRef();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <PDFDocumentWrapper>
      <Document
        file={{ url: props.pdf }}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error) => {console.log(error)}}
      >
        <Page 
          ref={pdfWrapper}
          pageNumber={pageNumber}
        />
      </Document>
      <div>
        <p>
          Pagina {pageNumber || (numPages ? 1 : "--")} de {numPages || "--"}
        </p>
        <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
          Voltar
        </button>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Proxima
        </button>
      </div>
    </PDFDocumentWrapper>
  );
}
