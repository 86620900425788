import React, { Component } from 'react';
import { MdAttachMoney } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import { Button, FormGroup, Modal, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import { InputText } from '../../InputText/InputText';
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import { ENDPOINT } from '../../../auth/endpoint';
import axios from 'axios';
import { getToken } from '../../../auth/set_services';

const ModalBody = styled.div`
    svg {
        color: #52c565;
        width: 2rem;
        height: 2rem;
    }
    small {
        color: #52c565;
    }
`;

/* const payment_list = [
    { label: "PIX" ,value: "PIX" },
    { label: "TED" ,value: "TED" },
    { label: "Boleto" ,value: "Boleto" },
    { label: "Cartão" ,value: "Cartão" },
    { label: "Campanha de Marketing" ,value: "Campanha de Marketing" },
    { label: "Ajuste de Contestação" ,value: "Ajuste de Contestação" },
]; */

class AddTagCredit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valor_deposito: '',
            valor_deposito_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }
    validFields(){
        var invalid = 0;
        if(this.state.valor_deposito !== ""){
            this.setState({valor_deposito_invalid: false})
        }
        else{
            invalid++;
            this.setState({valor_deposito_invalid: true})
        }
        
        return invalid;
    }

    onRegister(){
        if(this.validFields() === 0){
            this.saldo_add(this.state, this.props.serial, this.onReturn.bind(this))
        }
    }
    onReturn(data) {
        if (data.success === true) {
            this.setState({
                success_message: data.message
            })
        }
        else {
            this.setState({
                error_message: data.errors
            })
        }
    }
    
    saldo_add = (state, serial, onData) => {
        this.setState({loading: true})
        var url = ENDPOINT+"taggys/store-taggy";
    
        var formData = new FormData();
        formData.append('serial', serial);
        formData.append('valor_recarga', state.valor_deposito.replaceAll(',', ''));
        
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            onData(data);
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
            var data = error.response.data;
            onData(data);
            return data;
        });
    
    }
    
    onClose() {
        this.setState({
            valor_deposito: '',
            valor_deposito_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose(false);
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }
    render() {
        return (
            <Modal isOpen={this.props.visible}>
                <ModalBody className="modal-body" hidden={this.state.loading}>
                    <div style={{ display: 'inline-flex' }}>
                        <MdAttachMoney  /><h4>RECARGA DA TAGGY </h4>
                    </div>
                    <hr/>
                        <FormGroup>
                            <NumberFormat
                                name="valor_deposito"
                                placeholder="Valor a depositar"
                                customInput={InputText}
                                decimalScale={2}
                                value={this.state.valor_deposito}
                                invalid={this.state.valor_deposito_invalid}
                                fixedDecimalScale={true}
                                thousandSeparator={true}
                                onChange={this.handleChange}/>
                        </FormGroup>
                </ModalBody>
                <div 
                    className="modal-body"
                    hidden={!this.state.loading}
                    style={{
                        minHeight: '114px',
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose.bind(this)}
                        onError={this.onError.bind(this)}
                    />

                </div>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="success" onClick={this.onRegister.bind(this)}>Avançar</Button>
                    <Button color="danger" onClick={this.onClose.bind(this)}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default AddTagCredit;