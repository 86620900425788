import moment from 'moment';
import React, { Component } from 'react';
import { Card, CardBody, Button, Input, Form, FormGroup, Collapse } from "reactstrap";
import CupomModal from '../../components/Modals/CupomModals/CupomModal';
import { ImBarcode } from "react-icons/im";
import { MdAttachMoney, MdReceipt } from "react-icons/md";
import { getRows, getRowsPerPage } from '../../components/Tables/TableFaturas/propsFaturas';
import PropagateLoader from "react-spinners/PropagateLoader";
import { toast } from 'react-toastify';
import axios from 'axios';
import { getToken } from '../../auth/set_services';
import { ENDPOINT } from '../../auth/endpoint';
import { TableContabilidade } from '../../components/Tables/TableContabilidade/TableContabilidade';
import { TableFaturas } from '../../components/Tables/TableFaturas/TableFaturas';
import NewNotas from '../../components/Modals/SendsModals/NewNotas';
import { gerarFaturasLembretes } from '../../components/Tables/TablePayout/propsPayoutBlock';
import ClipLoader from 'react-spinners/ClipLoader'

class PosPago extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_cupom: false,
            search: '',
            document: '',
            is_cnpj: false,
            rowData: [],
            relatorio: [],
            status: null,
            plano: null,
            forma_pagamento: null,
            status_fatura: null,
            totalPage: 0,
            activePage: 1,
            cod_cliente: '',
            cod_fatura: '',
            pending: false,
            filter: false,
            loading_table: true,
            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            status_envio: null,
            loading: false,
            cancela_fatura_show: false,
            motivo_cancelamento: '',
            abater_fatura_show: false,
            motivo_abatimento: '',
            refazer_fatura_show: false,
            motivo_refazer: '',
            desc_id: '',
            id_current: '',
            selected_rows: {
                allSelected: false,
                selectedCount: 0,
                selectedRows: []
            },
            loading_fat_pendente: null,
            status_envio: null,
            error_message: '',
            success_message: '',
        }
    }

    updateStatusFatura = (id, status) => {
        var url = ENDPOINT + 'faturas/alterar-status-fatura';

        var formData = new FormData();
        formData.append('id', id);
        formData.append('status', status);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                toast.success(data.message);
                getRows(this.state, this.callbackRows.bind(this));
                return data;
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    toast.error(obj.errors);
                    return obj;
                }
                var data = error.response.data;
                toast.error(data.errors);
                return data;
            });
    }

    enviarLembrete = (id) => {
        var url = ENDPOINT + 'faturas/enviar-lembrete';

        var formData = new FormData();
        formData.append('id', id);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                toast.success(data.message);
                return data;
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    toast.error(obj.errors);
                    return obj;
                }
                var data = error.response.data;
                toast.error(data.errors);
                return data;
            });
    }

    gerarFatura = (id) => {
        var url = ENDPOINT + 'faturas/gerar-lembrete';

        var formData = new FormData();
        formData.append('id', id);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                toast.success(data.message);
                getRows(this.state, this.callbackRows.bind(this));
                return data;
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    toast.error(obj.errors);
                    return obj;
                }
                var data = error.response.data;
                toast.error(data.errors);
                return data;
            });
    }

    onChangeSelect = (name, value) => {
        var state = this.state;
        state[name] = value;
        this.setState({ ...state });

        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    bloquearTaggys = (id) => {
        var url = ENDPOINT + 'faturas/bloquear-taggys';

        var formData = new FormData();
        formData.append('id', id);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                toast.success(data.message);
                return data;
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    toast.error(obj.errors);
                    return obj;
                }
                var data = error.response.data;
                toast.error(data.errors);
                return data;
            });
    }

    handleChange = (event) => {

        var state = this.state;
        let name = event.target.name;
        let value = event.target.value;

        if (name === 'document') {
            if (value.replace(/([^\d])+/gim, '').length > 10) {
                state[name] = value;
                state.activePage = 1;
                state.rowData = [];
                state.loading_table = true;
                this.setState({ ...state });

                getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
            }
            else if (value.replace(/([^\d])+/gim, '').length === 0) {
                if (state[name].replace(/([^\d])+/gim, '').length > 0) {
                    state[name] = value;
                    state.activePage = 1;
                    state.rowData = [];
                    state.loading_table = true;
                    this.setState({ ...state });

                    getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
                }
            }
        }
        else {
            state[name] = value;
            state.activePage = 1;
            state.rowData = [];
            state.loading_table = true;
            this.setState({ ...state });

            getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
        }
    }

    onSelectedRows = (selected_rows) => {
        console.log(selected_rows)
        this.setState({selected_rows});
    }

    sendFaturas = () => {
        toast.dark(<><ClipLoader color="#ffffff" size={20}/> Faturas sendo re-enviadas, por favor aguarde</>, {
            toastId: 'enviarFaturas'
        });

        this.setState({loading_faturas: true});

        gerarFaturasLembretes(
            this.state.selected_rows.selectedRows, 
            null,
            this.onCallback
        );
    }

    componentDidMount() {
        getRows(this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    onFilter = (filter) => {
        this.setState({ filter });
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }

    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onSearchDocument() {
        this.setState({ is_cnpj: !this.state.is_cnpj })
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }


    onData = (data) => {
        if (data.success === true) {
            toast.success(data.message);
        }
        else {
            toast.error(data.errors);
        }
    }
    onRefazer = (id) => {
        var url = ENDPOINT + "faturas/refazer/" + id;

        var formData = new FormData();
        formData.append('motivo_cancelamento', this.state.motivo_refazer);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                this.onData(data);
                getRows(this.state, this.callbackRows.bind(this));
                this.setState({
                    motivo_refazer: '',
                    refazer_fatura_show: false
                })
                return data;
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    this.onData(obj);
                }
                var data = error.response.data;
                this.onData(data);
            });
    }
    onAbater = (id) => {
        var url = ENDPOINT + "faturas/abater-fatura/" + id;

        var formData = new FormData();
        formData.append('motivo_abatimento', this.state.motivo_abatimento);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                this.onData(data);
                getRows(this.state, this.callbackRows.bind(this));
                this.setState({
                    motivo_abatimento: '',
                    abater_fatura_show: false
                })
                return data;
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    this.onData(obj);
                }
                var data = error.response.data;
                this.onData(data);
            });
    }
    onAprovved = (id) => {
        var url = ENDPOINT + "faturas/cancelar/" + id;

        var formData = new FormData();
        formData.append('motivo_cancelamento', this.state.motivo_cancelamento);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                this.onData(data);
                getRows(this.state, this.callbackRows.bind(this));
                this.setState({
                    motivo_cancelamento: '',
                    cancela_fatura_show: false
                })
                return data;
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    this.onData(obj);
                }
                var data = error.response.data;
                this.onData(data);
            });
    }
    ExpandableRows = ({ data }) => {
        return <div style={{ margin: '20px' }}>
            <h4>Fatura {data.status} - #{data.id}</h4>
            <hr />
            <p>{moment(data.created_at).format('DD/MM/YYYY')}</p>
            <p><b>Valor:</b> {parseFloat(data.valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
            <p><b>Nome: </b>{data.conta.nome_fatura}</p>
            <p><b>Documento (CPF/CNPJ):</b> {data.conta.user !== null ? data.conta.user.cnpj ? data.conta.user.cnpj : data.conta.user.cpf : ''}</p>
            <p><b>Código do cliente:</b> {data.conta.user !== null ? data.conta.user.id : ''}</p>
            <p><b>Código da fatura:</b> {data.id}</p>
            <p><b>Fechamento da Fatura:</b> {moment(data.fechamento_fatura).format('DD/MM/YYYY')}</p>
            <p><b>Vencimento da Fatura:</b> {moment(data.vencimento_fatura).format('DD/MM/YYYY')}</p>
            {data.notas ?
                <>
                    {data.notas.map(e => <p>
                        <b>{moment(e.created_at).format('DD/MM/YYYY')}</b> - {e.descricao}
                    </p>)}
                </>
                :
                <></>}
            {data.pagamento ?
                <p><b>URL da Fatura:</b> <a href={data.pagamento.fatura_url ? data.pagamento.fatura_url : data.pagamento.boleto_url} target="_blank">Abrir Fatura</a> </p>
                :
                <>
                    <Button
                        onClick={() => this.gerarFatura(data.id)}
                        color='primary'
                        style={{ margin: '2px' }}
                        hidden={data.status != 'fechada'}
                    >
                        <ImBarcode /> Gerar Fatura
                    </Button>
                </>}
            {data.status_envio == 'correios' ?
                <>
                    <p>Envio via Correios</p>
                    <Input
                        type='select'
                        value={data.status_fatura_envio}
                        onChange={(e) => this.updateStatusFatura(data.id, e.target.value)}
                    >
                        <option value="aguardando_envio">Fatura aguardando envio</option>
                        <option value="enviado">Fatura enviada</option>
                        <option value="entregue">Fatura entregue</option>
                    </Input>
                </>
                :
                <p>Envio por E-mail</p>
            }
            <br /><br />
                <div style={{ display: 'inline' }}>
                <Button
                    color='success'
                    disabled={data.status != 'fechada'}
                    onClick={() => this.enviarLembrete(data.id)}
                    style={{ margin: '2px' }}>
                    Enviar Lembrete
                </Button>
                <Button
                    color='info'
                    disabled={data.status != 'fechada'}
                    onClick={() => this.gerarFatura(data.id)}
                    style={{ margin: '2px' }}>
                    Enviar Fatura
                </Button>
                <Button
                    color='warning'
                    onClick={() => this.bloquearTaggys(data.id)}
                    style={{ margin: '2px' }}>
                    Bloquear Taggys
                </Button>
                <Button hidden={data.status != 'fechada'} style={{ margin: '2px' }} color='info' onClick={() => this.setState({ abater_fatura_show: true })}><MdAttachMoney /> Abater fatura</Button>
                <Button hidden={data.status != 'fechada'} style={{ margin: '2px' }} color='dark' onClick={() => this.setState({ refazer_fatura_show: true })}>Refazer fatura</Button>
                <Button hidden={data.status != 'fechada'} style={{ margin: '2px' }} color='danger' onClick={() => this.setState({ cancela_fatura_show: true })}><MdReceipt />Cancelar fatura</Button>
            </div>
            <div hidden={data.status != 'fechada'}>
                <Collapse isOpen={this.state.cancela_fatura_show}>
                    <br />
                    <Form>
                        <FormGroup>
                            <Input
                                type='textarea'
                                placeholder="Motivo do cancelamento da fatura"
                                name="motivo_cancelamento"
                                value={this.state.motivo_cancelamento}
                                onChange={this.handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button style={{ margin: '2px' }} color='success' onClick={() => this.onAprovved(data.id)}>Salvar</Button>
                            <Button style={{ margin: '2px' }} color='danger' onClick={() => this.setState({ cancela_fatura_show: false })}>Cancelar</Button>
                        </FormGroup>
                    </Form>
                </Collapse>
            </div>
            <div hidden={data.status != 'fechada'}>
                <Collapse isOpen={this.state.abater_fatura_show}>
                    <br />
                    <Form>
                        <FormGroup>
                            <Input
                                type='textarea'
                                placeholder="Motivo do abatimento da fatura"
                                name="motivo_abatimento"
                                value={this.state.motivo_abatimento}
                                onChange={this.handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button style={{ margin: '2px' }} color='success' onClick={() => this.onAbater(data.id)}>Salvar</Button>
                            <Button style={{ margin: '2px' }} color='danger' onClick={() => this.setState({ abater_fatura_show: false })}>Cancelar</Button>
                        </FormGroup>
                    </Form>
                </Collapse>
            </div>
            <div hidden={data.status != 'fechada'}>
                <Collapse isOpen={this.state.refazer_fatura_show}>
                    <br />
                    <Form>
                        <FormGroup>
                            <Input
                                type='textarea'
                                placeholder="Motivo para refazer da fatura"
                                name="motivo_refazer"
                                value={this.state.motivo_refazer}
                                onChange={this.handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button style={{ margin: '2px' }} color='success' onClick={() => this.onRefazer(data.id)}>Salvar</Button>
                            <Button style={{ margin: '2px' }} color='danger' onClick={() => this.setState({ refazer_fatura_show: false })}>Cancelar</Button>
                        </FormGroup>
                    </Form>
                </Collapse>
            </div>
        </div>
    }
    fecharFaturas = () => {
        toast.info("Fechando faturas pendentes");
        this.setState({
            loading_fat_pendente: true
        })
        var url = ENDPOINT+"reenvio_faturas/fechar_faturas_aqpasso";
        var formData = new FormData();
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            toast.success(data.msg);
            getRows(this.state, this.callbackRows.bind(this));
            this.setState({
                loading_fat_pendente: true
            })
            return data;
        })
        .catch((error) => {
            console.log(error);
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                toast.success(obj.errors);
                this.setState({
                    loading_fat_pendente: true
                })
                return obj;
            }
            var data = error.response.data;
            toast.success(response.errors);
            this.setState({
                loading_fat_pendente: true
            })
            return data;
        });
    }
    render() {
        return (
            <div>
                <h3>Faturas</h3>
                <hr/>
                <Button 
                    color='info' 
                    disabled={this.state.loading_fat_pendente}
                    onClick={() => this.fecharFaturas()}>
                        {this.state.loading_fat_pendente === true ? <ClipLoader color="#fff" size={15}/> :<></> } Fechar Faturas Pendentes
                </Button>
                <hr/>
                <Card >
                    <TableFaturas
                        selectableRows
                        relatorio={this.state.relatorio}
                        isSearch={this.handleChange}
                        is_cnpj={this.state.is_cnpj}
                        onChangeSelect={this.onChangeSelect}
                        sendFaturas={this.sendFaturas}
                        loading_faturas={this.state.loading_faturas}
                        state={this.state}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        data={this.state.rowData}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        onSelectedRows={this.onSelectedRows.bind(this)}
                        selected_rows={this.state.selected_rows}
                        filter={this.state.filter}
                        noDataComponent={this.state.loading_table === true ?
                            <Card style={{ border: 0, padding: '10vh' }}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{ border: 0 }}><CardBody><div>
                                Nenhuma fatura encontrada
                            </div></CardBody></Card>
                        }
                        pending={this.state.pending}
                        expandableRowsComponent={<this.ExpandableRows />}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <NewNotas
                    visible={this.state.visible_notas}
                    id={this.state.id_current}
                    desc_id="fatura_aqpasso_id"
                    title_nota="Cancelamento de Fatura"
                    placeholder_nota="Digite o motivo do cancelamento da fatura"
                    url_api="fatura/cancelar"
                />
            </div>
        )
    }
}

export default PosPago;