import React from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import Pagination from '../Pagination';
import debounce from 'lodash/debounce';
import { MdAdd } from 'react-icons/md';
import { columns } from './propsAdmin';
import { ButtonDefault } from '../../Button/ButtonDefault';
import { InputText } from '../../InputText/InputText';
import { getPermissoes, getUser } from '../../../auth/set_services';


const get_permissoes_operadores = () => {
    if(getUser().permissao_edicao_operadores){
      return true;
    }
    else if(getUser().permissao_edicao_operadores){
        return true;
    }
    else{
      return false;
    }
  }
const TableAdmin = (props) => {
    return (
        <>
            <Card>
                <CardBody>
                    <Row >
                        <Col md={5} xs={6}>
                            <div style={{ textAlign: 'left' }}>
                                <InputText
                                    name="search"
                                    placeholder="Buscar"
                                    onChange={debounce((event) => props.isSearch(event), 1000)} />
                            </div>
                        </Col>
                        <Col md={7} xs={6}>
                            <div style={{ textAlign: 'right' }}>
                                <ButtonDefault
                                    hidden={!get_permissoes_operadores()}
                                    onClick={() => props.showAdmin(true)}
                                    style={{ width: '125px', margin: '0px' }}>
                                    <MdAdd style={{ padding: 0 }} size={15} />Adicionar
                                </ButtonDefault>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                <hr style={{ marginBottom: '4px' }} />
                <DataTable
                    noHeader={true}
                    columns={columns(props.showAdmin, props.delete, props.updateStatusAdmin)}
                    button={true}
                    noDataComponent={props.noDataComponent}
                    data={props.data}
                    /* expandableRows
                    expandableRowsComponent={props.expandableRowsComponent} */
                    theme="default"
                />

                <CardFooter style={{ textAlign: 'center' }}>
                    <Pagination
                        onPageChange={props.onPageChange}
                        activePage={props.activePage}
                        totalPage={props.totalPage} />
                </CardFooter>
            </Card>
        </>
    )
}
export default TableAdmin;