
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { Card, CardBody, Row, Col, Input, CardFooter, Collapse, Button, Label } from 'reactstrap';
import { columns } from './propsImpostos';
import debounce from 'lodash/debounce';
import Pagination from '../../Pagination';
import { InputText } from '../../../InputText/InputText';
import InputMask from 'react-input-mask';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import Select from 'react-select';
import { ENDPOINT } from '../../../../auth/endpoint';
import { getToken } from '../../../../auth/set_services';

const getOptionSelects = (setStatusProcessoOptions, setStatusPagamentoOptions, setReclamadoOptions) => {

}

export const TableImpostos = (props) => {
    const [status_processo_options, setStatusProcessoOptions] = useState([]);
    const [status_pagamento_options, setStatusPagamentoOptions] = useState([]);
    const [reclamado_options, setReclamadoOptions] = useState([]);

    useEffect(() => {
        getOptionSelects(setStatusProcessoOptions, setStatusPagamentoOptions, setReclamadoOptions);
    }, []);

    return (
        <Card>
            <CardBody>
                <Row >
                    <Col md={8}>
                        <div style={{ textAlign: 'left' }}>
                            <InputText
                                name="search"
                                placeholder="Imposto"
                                onChange={debounce((event) => props.isSearch(event), 1000)} />
                        </div>
                    </Col>
                </Row>
            </CardBody>
            <hr style={{ marginBottom: '4px' }} />
            <DataTable
                noHeader={true}
                button={true}
                noDataComponent={props.noDataComponent}
                columns={columns(props, props.editImposto)}
                data={props.data}
                theme="default"
            />
            <CardFooter style={{ textAlign: 'center' }}>
                <Pagination
                    onPageChange={props.onPageChange}
                    activePage={props.activePage}
                    totalPage={props.totalPage}
                />
            </CardFooter>
        </Card>
    )
}