import React, { Component } from "react";
import classnames from 'classnames';
import { FaBox, FaUser } from "react-icons/fa";
import { MdMail, MdMoneyOff } from "react-icons/md";
import { Button, Card, CardBody, CardColumns, CardFooter, Col, Collapse, Form, FormGroup, Input, InputGroup, InputGroupAddon, Label, Nav, NavLink, Row, TabContent, TabPane } from "reactstrap";
import styled from "styled-components";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import DataPreClient from "../../components/ResumeClient/DataPreClient";
import DataClientVenda from "../../components/ResumeClient/DataClientVenda";
import sanitizeHtml from 'sanitize-html';
import { InputText } from "../../components/InputText/InputText";
import axios from "axios";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import EnviarEmailVenda from "../../components/Modals/Vendas/EnviarEmailVenda";
import ecommerce_logo from '../../assets/ecommerce-aqpago.svg';
import Select from "react-select";
import { toast } from "react-toastify";
import ClipLoader from 'react-spinners/ClipLoader';
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

const status_transacao_not_svg = (payment_type) => {
    switch(payment_type){
        case 'succeeded' : return 'Aprovada';
        case 'canceled' : return 'Cancelada';
        case 'pending' : return 'Pendente';
        case 'failed' : return 'Falhada';
        case 'disputed': return 'Em disputa';
        default: return payment_type
    }
}

const payment = (payment_type) => {
    switch(payment_type){
        case 'credit' : return 'Crédito';
        case 'boleto' : return 'Boleto';
        case 'debit' : return 'Débito';
        default: return payment_type
    }
}

const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
`;


const getSolicitacao = (id, callback) => {
    var url = ENDPOINT + "loja/consultar/" + id;

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((data) => {
        callback(data);
    })
}


const iniciarMigracao = (id, formData = [], callback, callbackError) => {
    var url = ENDPOINT + "solicitacao/iniciar_migracao/" + id;

    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((data) => {
        callback(data.data);
    })
    .catch((error) => {
        var data = error.response.data;
        callbackError(data);
    })
}

const get_id = () => {
    return window.location.pathname.replace('/resumo-loja/', '')
}

const status_solicitacao_list = [
    { value: 'AGUARDANDO REGISTRO', label: 'AGUARDANDO REGISTRO' },
    { value: 'AGUARDANDO DNS CLIENTE', label: 'AGUARDANDO DNS CLIENTE' },
    { value: 'VERIFICANDO DOMINIO', label: 'VERIFICANDO DOMINIO' },
    { value: 'DOMINIO REGISTRADO', label: 'DOMINIO REGISTRADO' },
    { value: 'FINALIZADO', label: 'FINALIZADO' }
];

const getStringMaps = (mail) => {
    var string_mail = "";
    mail.forEach((p) => {
      string_mail += getTranslate(p.recordType) + " " + moment(new Date(p.dateAt).toString()).format('DD/MM/YYYY h:mm:ss a') + "<br /> "
    });
  
    return string_mail;
}

const getTranslate = (name) => {
    switch (name) {
        case 'Delivery': return 'Entregue'
        case 'Open': return 'Aberto'
        case 'Click': return 'Clicado'
        case 'SpamComplaint': return 'Caixa de SPAM'
        case 'Bounce': return 'Mensagem devolvida'
        default : return name;
    }
}

class LojaResumo extends Component{
    constructor(props){
        super(props);
        this.state = {
            activeTab: "1",
            motivo_estorno: '',
            visible_email: false,
            venda_id: null,
            nome_loja: '',
            nome_cliente: '',
            status: '',
            status_pagamento: '',
            servidor_dns_one: '',
            servidor_dns_two: '',
            subdominio: '',
            status_label: null,
            dominio: null,
            id: get_id(),
            user: {
                nome: '',
                sobrenome: '',
                cpf: '',
                cnpj: ''
            },
            mails: [],
            forma_pagamento: '',
            estornado_por: null,
            prevencao_show: false,
            total_express_loading: false,
            loading_migracao: false,
            certificado_ssl_proprio: '',
            certificado_ssl_proprio_one: null,
            certificado_ssl_proprio_two: null,
            certificado_ssl_proprio_tree: null,
            crs_certificado: '',
            ip_dominio: ''
        }
    }

    componentDidMount() {
        getSolicitacao(this.state.id, this.callbackRows.bind(this));
    }

    callbackRows(data) {
        this.setState({ ...data.data });

        if(data.data.status !== null){
            var status_label = {
                value: data.data.status, 
                label: data.data.status
            };

            this.setState({
                status_label
            })
        }
    }

    onCloseEmail = (visible_email = false) => {
        this.setState({ visible_email });

        if(visible_email == false){
            getSolicitacao(this.state.id, this.callbackRows.bind(this));
        }
    }

    listData = [
        { name: 'Informações da Solicitação', active: '1' },
        { name: 'Configurações', active: '2' },
        { name: 'E-mails', active: '3' }
    ];

    configuracoes = () => {
        var url = ENDPOINT+"loja/configuracoes_loja/"+this.state.id;
    
        var formData = new FormData();
        if(this.state.dominio == null){
            if(this.state.subdominio !== null){
                if(this.state.subdominio.length > 0){
                    formData.append('subdominio', this.state.subdominio);
                }
            }
        }

        if(this.state.servidor_dns_one !== null){
            if(this.state.servidor_dns_one.length > 0){
                formData.append('servidor_dns_one', this.state.servidor_dns_one);
            }
        }
        
        if(this.state.servidor_dns_two !== null){
            if(this.state.servidor_dns_two.length > 0){
                formData.append('servidor_dns_two', this.state.servidor_dns_two);
            }
        }
        
        if(this.state.ip_dominio !== null){
            if(this.state.ip_dominio.length > 0){
                formData.append('ip_dominio', this.state.ip_dominio);
            }
        }
        
        if(this.state.certificado_ssl_proprio !== null){
            if(this.state.certificado_ssl_proprio.length > 0){
                formData.append('certificado_ssl_proprio', this.state.certificado_ssl_proprio);
            }
        }
        
        if(this.state.crs_certificado !== null){
            if(this.state.crs_certificado.length > 0){
                formData.append('crs_certificado', this.state.crs_certificado);
            }
        }
        
        if(this.state.certificado_ssl_proprio_one !== null && !(typeof this.state.certificado_ssl_proprio_one == 'string' || this.state.certificado_ssl_proprio_one instanceof String) ){
            formData.append('certificado_ssl_proprio_one', this.state.certificado_ssl_proprio_one);
        }
        
        if(this.state.certificado_ssl_proprio_two !== null && !(typeof this.state.certificado_ssl_proprio_two == 'string' || this.state.certificado_ssl_proprio_two instanceof String) ){
            formData.append('certificado_ssl_proprio_two', this.state.certificado_ssl_proprio_two);
        }
        
        if(this.state.certificado_ssl_proprio_tree !== null && !(typeof this.state.certificado_ssl_proprio_tree == 'string' || this.state.certificado_ssl_proprio_tree instanceof String) ){
            formData.append('certificado_ssl_proprio_tree', this.state.certificado_ssl_proprio_tree);
        }

        

        axios.post(url, formData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            getSolicitacao(this.state.id, this.callbackRows.bind(this));
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                toast.error(obj.errors);
            }
  
            var data = error.response.data;
            if(typeof data.errors !== 'undefined'){
              toast.error(data.errors);
            }
        });
    
    }

    alterStatus = (status) => {
        this.setState({ status_label: status });

        var url = ENDPOINT+"loja/alterar_status/"+this.state.id;
    
        var formData = new FormData();
        formData.append('status', status.value);
        axios.post(url, formData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            getSolicitacao(this.state.id, this.callbackRows.bind(this));
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                toast.error(obj.errors);
            }
  
            var data = error.response.data;
            if(typeof data.errors !== 'undefined'){
              toast.error(data.errors);
            }
        });
    
    }

    handleChangeEmail = (mensagem_email) => {
        this.setState({mensagem_email});
    }

    onToast = (data) => {
        if(data.success === true){
            toast.success(data.message);
        }
        else{
            toast.error(data.errors);
        }
        //this.props.refresh();
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }
    render(){
        const Navs = () => {
            return <Nav tabs>
                {this.listData.map((p, index) => {
                    return <NavLink
                        key={index}
                        className={
                            classnames({ active: this.state.activeTab === p.active })}
                        onClick={() => { this.setState({ activeTab: p.active }); }}>
                        <span>{p.name}</span>
                    </NavLink>
                })}
            </Nav>
        }
        return(<Div>
            <h3>Resumo da Loja</h3>
            <Navs />
            <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId={'1'}>
                    <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                        <h5>Dados do Pagamento</h5>
                        <hr/>
                        {this.state.dominio != null ?
                            <p><b>Dominio Personalizado:</b> {this.state.dominio}</p>
                        :null}
                        <p><b>Nome da Loja:</b> {this.state.nome_loja}</p>
                        <p><b>Nome do Cliente:</b> {this.state.user.nome + ' ' + this.state.user.sobrenome}</p>
                        <p><b>E-mail do Cliente:</b> {this.state.user.email}</p>
                        <p><b>Documento(CPF/CNPJ):</b> {this.state.user.cpf.length > 0 ? this.state.user.cpf : this.state.user.cnpj}</p>
                        <p><b>Forma de Pagamento:</b> {payment(this.state.forma_pagamento)}</p>
                        <p style={{margin: 0}}><b>Status:</b>
                            <Select
                                value={this.state.status_label}
                                placeholder="Status da Loja"
                                options={status_solicitacao_list}
                                onChange={this.alterStatus}
                            />
                        </p>
                    </div>
                </TabPane>
                <TabPane tabId={'2'}>
                    <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                        {/* <EnviarEmailVenda
                            id={this.state.venda_id}
                            visible={this.state.visible_email}
                            onClose={this.onCloseEmail}
                        /> */}
                        <h5>Configurações</h5>
                        <hr/>
                        <div>
                            <Form> 
                                {this.state.dominio != null ?
                                    <p><b>Dominio Personalizado:</b> {this.state.dominio}</p>
                                :null}
                                <p hidden={this.state.dominio != null}><b>Dominio:</b></p>
                                <InputGroup hidden={this.state.dominio != null}>
                                    <Input 
                                        name="subdominio"
                                        value={this.state.subdominio}
                                        placeholder="Sub-dominio"
                                        onChange={this.handleChange}
                                    />
                                    <InputGroupAddon addonType="append">
                                        .shopaqpago.com.br
                                    </InputGroupAddon>
                                </InputGroup>
                            </Form>
                            <br/>
                            <p><b>Servidor DNS:</b></p>
                            <Form>
                                <FormGroup>
                                    <Input 
                                        placeholder="Servidor 1"
                                        name="servidor_dns_one"
                                        value={this.state.servidor_dns_one}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                                <FormGroup>
                                    <Input 
                                        placeholder="Servidor 2"
                                        name="servidor_dns_two"
                                        value={this.state.servidor_dns_two}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            </Form>
                            <br/>
                            <p><b>SSL:</b></p>
                            <Form>
                                <FormGroup>
                                    <Input 
                                        placeholder="Certificado SSL Próprio"
                                        name="certificado_ssl_proprio"
                                        value={this.state.certificado_ssl_proprio}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    {!(typeof this.state.certificado_ssl_proprio_one == 'string' || this.state.certificado_ssl_proprio_one instanceof String) ?
                                        <>
                                            <Label>1 - Certificado SSL</Label>
                                            <Input 
                                                type="file"
                                                name="certificado_ssl_proprio_one"
                                                value={this.state.certificado_ssl_proprio_one}
                                                onChange={this.handleChange}
                                            />
                                        </>
                                    :
                                    <b style={{color: 'green'}}>1 - Certificado SSL Enviado</b>}
                                </FormGroup>
                                <FormGroup>
                                    {!(typeof this.state.certificado_ssl_proprio_two == 'string' || this.state.certificado_ssl_proprio_two instanceof String) ?
                                        <>
                                            <Label>2 - Certificado SSL</Label>
                                            <Input 
                                                type="file"
                                                name="certificado_ssl_proprio_two"
                                                value={this.state.certificado_ssl_proprio_two}
                                                onChange={this.handleChange}
                                            />
                                        </>
                                    :
                                    <b style={{color: 'green'}}>1 - Certificado SSL Enviado</b>}
                                </FormGroup>
                                <FormGroup>
                                    {!(typeof this.state.certificado_ssl_proprio_tree == 'string' || this.state.certificado_ssl_proprio_tree instanceof String) ?
                                        <>
                                            <Label>3 - Certificado SSL</Label>
                                            <Input 
                                                type="file"
                                                name="certificado_ssl_proprio_tree"
                                                value={this.state.certificado_ssl_proprio_tree}
                                                onChange={this.handleChange}
                                            />
                                        </>
                                    :
                                    <b style={{color: 'green'}}>1 - Certificado SSL Enviado</b>}
                                </FormGroup>
                                <FormGroup>
                                    <Input 
                                        placeholder="CRS do certificado"
                                        name="crs_certificado"
                                        value={this.state.crs_certificado}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>
                            </Form>
                            <hr/>
                            <Button color="success" onClick={() => this.configuracoes()}>Salvar</Button>
                        </div>
                    </div>
                </TabPane>
                <TabPane tabId={'3'}>
                    <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                        {/* <EnviarEmailVenda
                            id={this.state.venda_id}
                            visible={this.state.visible_email}
                            onClose={this.onCloseEmail}
                        /> */}
                        <h5>E-mails</h5>
                        <hr/>
                       {/*  <Button color="success" onClick={() => this.onCloseEmail(true)}>Enviar E-mail</Button> */}
                        {/* <br/><br/> */}
                        <ul style={{listStyleType: 'circle'}}>
                        {this.state.mails.map(p => {
                            return <li style={{marginBottom: '7px'}}>
                                {p.subject}<br/>
                                <small>Conteúdo de E-mail:</small>
                                <div style={{border: '1px solid #7f7f7f66', borderRadius: '15px', padding: '19px'}} dangerouslySetInnerHTML={{ __html: sanitizeHtml(p.message)}}/>
                                <small>{p.responsavel !== null ? <><b>Operador:</b> {p.responsavel.name}</> : ''}</small>
                                <small>{p.mail.length > 0 ?
                                    <div data-tip={getStringMaps(p.mail)} style={{ maxHeight: '200px', 
                                    cursor: 'pointer', overflow: 'auto', width: '100%', margin: '12px' }}>
                                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                                        {p.mail.slice(0, 1).map((p) => {
                                            return <li>
                                            <p><MdMail /> <b>{getTranslate(p.recordType)}</b>
                                                <br />
                                                {moment(new Date(p.dateAt).toString()).format('DD/MM/YYYY h:mm:ss a')}</p>
                                            </li>
                                        })}
                                        </ul>
                                        <ReactTooltip multiline={true} />
                                    </div>
                                    :
                                    <div data-tip={"Postado " + moment(new Date(p.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')} style={{ cursor: 'pointer', maxHeight: '200px', overflow: 'auto', width: '100%', margin: '12px' }}>
                                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                                        <li style={{ listStyleType: 'none' }}><MdMail /> <b>Postado</b><br />{moment(new Date(p.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')}
                                        </li>
                                        </ul>
                                        <ReactTooltip multiline={true} />
                                    </div>
                                }</small>
                            </li>
                        })}
                    </ul>
                    {this.state.mails.length === 0 ? 'Nenhum email encontrado' : ''}
                    </div>
                </TabPane>
            </TabContent>
        </Div>)
    }
}

export default LojaResumo;