import React from 'react';
import { HiViewGrid } from 'react-icons/hi';
import { Button, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getPermissoes, getToken } from '../../../auth/set_services';
import ReactTooltip from 'react-tooltip';
import { MdDelete } from 'react-icons/md';
import 'react-dropdown/style.css';
import moment from 'moment';


const capitalizeFirstLetter = (string) => {
  if(string !== null){
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string
}

export const columns = (onShowDelete) => [
  {
    name: '',
    selector: 'buttons',
    width: '100px',
    cell: row => <div style={{ float: 'left'}}>
      <Button data-tip="Resumo do Cliente"
        outline
        //disabled={!row.cliente_existe}
        style={{ padding: '0px', marginRight: '3px', border: 0}}
        hidden={row.contabilidade == null}
        color="primary" onClick={() => 
          {
              window.location.href = '/aqcontabil/'+row.contabilidade.id
          }
        }>
        <HiViewGrid style={{ padding: '2px', width: '23px' }} />
      </Button>
      {onShowDelete ? 
          <Button
            outline
            style={{ padding: 2, marginLeft: 7, border: 0, fontWeight: '700'}}
            color="danger"
            onClick={() => onShowDelete(row.id)}
          >
            <MdDelete style={{ padding: '2px' }} />
          </Button> 
        : 
        <></>}
      <ReactTooltip/>
    </div>
  },
  {
    name: "Data",
    selector: "created_at",
    sortable: false,
    width: '140px',
    cell: row => moment(new Date(row.created_at)).format('DD/MM/YYYY HH:mm')
  },
  {
    name: "Nome do Responsavel",
    selector: "nome",
    sortable: false,
    width: '18%',
    cell: row => row.sobrenome !== null && row.nome !== null ? row.nome+" "+row.sobrenome : (row.nome_fatura !== null ? row.nome_fatura : '')
  },
  {
    name: "Telefone",
    selector: "telefone",
    sortable: false,
    width: '160px',
    cell: row => row.telefone ? row.telefone : 'Sem informações'
  },
  {
    name: "Email do Responsavel",
    selector: "email",
    sortable: false,
    cell: row => row.email ? row.email : 'Sem informações'
  },
  {
    name: "Documento",
    selector: "cpf",
    sortable: false,
    width: '180px',
    cell: row => row.cnpj ? row.cnpj : row.cpf
  },
  {
    name: "Situação do Cadastro",
    selector: "cpf",
    sortable: false,
    width: '180px',
    cell: row => 
      row.user_tipo == 'aqcontabil_responsavel' || row.user_tipo == 'aqcontabil_titular'  ? 
      'Cadastro concluído ' + (row.contabilidade == null ? 'Pedido não concluído' : 'Pedido Realizado') 
      : 
      'Cadastro incompleto'
  },
];


export const getRows = (state, callback) => {//
  var url = ENDPOINT + "user/all?empresa_relacao=aqcontabil&page=" + state.activePage + "&search=" + state.search;

  var document = state.document.replace(/([^\d])+/gim, '');
  if(state.is_cnpj === false){
    if(document.length === 11){
      url += "&cpf="+document;
    }
  }
  else{
      if(document.length === 14){
        url += "&cnpj="+document;
      }
  }

  /* if(state.type_user.length > 0){
    url += "&tipo_usuario="+state.type_user
  } */

  if(state.status_user.length > 0){
    url += "&status_usuario="+state.status_user
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      callback(p.data, p.last_page)
    })
}

export const getRowsPerPage = (page, state, callback) => {
    var url = ENDPOINT + "user/all?empresa_relacao=aqcontabil&page=" + page + "&search=" + state.search;
    console.log(state.document)
    var document = state.document.replace(/([^\d])+/gim, '');

    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&cpf="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&cnpj="+document;
        }
    }

/*   if(state.type_user.length > 0){
    url += "&tipo_usuario="+state.type_user
  } */


  if(state.status_user.length > 0){
    url += "&status_usuario="+state.status_user
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      callback(p.data, p.last_page)
    })
}