import React, { Component } from "react";
import { toast } from "react-toastify";
import { ENDPOINT } from "../../auth/endpoint";
import { getPermissoes, getToken } from "../../auth/set_services";
import ImportTaggy from "../../components/Modals/TaggysModals/ImportTaggy";
import TaggyAdd from "../../components/Modals/TaggysModals/TaggyAdd";
import { TableTaggy } from "../../components/Tables/TableTaggy/TableTaggy";
import { update } from "../../services/request_taggys";
import PropagateLoader from "react-spinners/PropagateLoader";
import Moment from 'moment';
import axios from "axios";
import { Card, CardBody } from "reactstrap";
import AddTagCredit from "../../components/Modals/TaggysModals/AddTagCredit";

class Taggys extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible_tag: false,
            visible_export: false,
            visible_credit_tags: false,
            loading_table: true,
            is_cnpj: false,
            tagEdit: null,
            serial_current: null,
            filter: 'all',
            document: '',
            show_filter: false,
            rowData: [],
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            totalPage: 0,
            activePage: 1,
            is_date: false,
            search: ''
        }
        this.addTaggy = this.addTaggy.bind(this);
        this.exportTaggy = this.exportTaggy.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }
    componentDidMount() {
        this.getRows();
        //console.log(new Date('0000-00-00').toJSON()())
    }

    addTaggy(value, taggy) {
        if (!taggy) {
            taggy = null;
        }
        if (value === false) {
            this.getRows(this.state, this.callbackRows);
        }
        this.setState({
            visible_tag: value,
            tagEdit: taggy
        })

    }
    onToasty(data) {
        if (data.success === true) {
            this.setState({
                success_message: data.message
            })
            toast.success(data.message);
        }
        else {
            this.setState({
                error_message: data.errors
            })
            toast.error(data.errors);
        }
        this.getRows(this.state, this.callbackRows);

    }

    exportTaggy(value, taggy) {
        if (!taggy) {
            taggy = null;
        }
        /*
        if(value === false){
            getRows(this.state, this.callbackRows);
        } */
        if (value === false) {
            this.getRows(this.state, this.callbackRows);
        }
        this.setState({
            visible_export: value,
            tagEdit: taggy
        })

    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        console.log(value)

        this.setState({ [name]: value });
        this.getRowsPerPage(1, this.state.filter);
    }

    onTaggyFilter = (filter) => {
        this.setState({ filter });
        this.getRowsPerPage(1, filter);
    }

    getRows() {
        this.setState({ loading_table: true });
        var url = ENDPOINT + "taggys/list?page=" + this.state.activePage
            + "&search=" + this.state.search
            + "&filter=" + this.state.filter;

        if (this.state.is_date === true) {
            url += '&date_from=' + this.state.date_from + '&date_to=' + this.state.date_to
        }
        var document = this.state.document.replace(/([^\d])+/gim, '');
        if (this.state.is_cnpj === false) {
            if (document.length === 11) {
                url += "&cpf=" + document;
            }
        }
        else {
            if (document.length === 14) {
                url += "&cnpj=" + document;
            }
        }

        var myHeaders = new Headers();

        myHeaders.append('Authorization', 'Bearer ' + getToken());

        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
            .then(response => response.json())
            .then((p) => {
                this.setState({ loading_table: false, rowData: p.data, totalPage: p.last_page })
            })
    }
    onPageChange(page) {
        this.setState({
            activePage: page,
            rowData: []
        });

        this.getRowsPerPage(page, this.state.filter);
    }
    filterShow() {
        this.setState({
            show_filter: !this.state.show_filter
        });
    }

    isDateShow() {
        var state = this.state;
        state.is_date = !this.state.is_date

        this.setState({ ...state });

        this.getRows(state, this.callbackRows);
    }
    getRowsPerPage(page, filter) {
        this.setState({ loading_table: true });
        var url = ENDPOINT + "taggys/list?page=" + page
            + "&search=" + this.state.search
            + "&filter=" + filter;

        if (this.state.is_date === true) {
            url += '&date_from=' + this.state.date_from + '&date_to=' + this.state.date_to
        }
        var document = this.state.document.replace(/([^\d])+/gim, '');
        if (this.state.is_cnpj === false) {
            if (document.length === 11) {
                url += "&cpf=" + document;
            }
        }
        else {
            if (document.length === 14) {
                url += "&cnpj=" + document;
            }
        }

        var myHeaders = new Headers();

        myHeaders.append('Authorization', 'Bearer ' + getToken());

        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
            .then(response => response.json())
            .then((p) => {
                this.setState({ loading_table: false, rowData: p.data, totalPage: p.last_page })
            })
    }
    updateTaggy(taggy_serial, serial, statusId) {
        update(taggy_serial, statusId, serial, this.onToasty.bind(this));
    }

    replaceTaggy(taggy) {
        this.setState({
            tagEdit: taggy,
            visible_tag: true
        });
    }

    export() {
        toast.success("O relatório será baixado em breve");
        var url = ENDPOINT + "taggys/exports_taggys?filter=" + this.state.filter;
        url += '&date_from=' + this.state.date_from + '&date_to=' + this.state.date_to

        axios({
            url: url, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Lista_de_tags.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
    }
    onSearchDocument() {
        this.setState({ is_cnpj: !this.state.is_cnpj })
    }
    add_credit_taggy(serial_current, visible_credit_tags){
        this.setState({ visible_credit_tags, serial_current});
        if(visible_credit_tags === false){
            this.getRows();
        }
    }
    render() {
        return (
            <div>
                <h3>Taggys</h3>
                <hr />
                <div>
                    <TableTaggy
                        isSearch={this.handleChange}
                        is_cnpj={this.state.is_cnpj}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        data={this.state.rowData}
                        onPageChange={this.onPageChange}
                        showTaggy={this.addTaggy}
                        add_credit_taggy={this.add_credit_taggy.bind(this)}
                        export={this.export.bind(this)}
                        isDateShow={this.isDateShow.bind(this)}
                        replaceTaggy={this.replaceTaggy.bind(this)}
                        onTaggyFilter={this.onTaggyFilter.bind(this)}
                        filter={this.state.filter}
                        is_date={this.state.is_date}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                        noDataComponent={this.state.loading_table === true ?
                            <Card style={{ border: 0, padding: '10vh' }}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{ border: 0 }}><CardBody><div>
                                Nenhuma taggy encontrada
                            </div></CardBody></Card>
                        }
                        filterShow={this.filterShow.bind(this)}
                        show_filter={this.state.show_filter}
                        updateTaggy={this.updateTaggy.bind(this)}
                        showExport={this.exportTaggy}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                    />
                </div>
                <AddTagCredit
                    visible={this.state.visible_credit_tags}
                    serial={this.state.serial_current}
                    onClose={() => this.add_credit_taggy(null, false)}
                />
                <ImportTaggy
                    visible={this.state.visible_export}
                    onClose={() => this.exportTaggy(false)} />
                <TaggyAdd
                    visible={this.state.visible_tag}
                    editing={this.state.tagEdit}
                    onClose={() => this.addTaggy(false)} />
            </div>
        )
    }
}

export default Taggys;