import Dashboard from "./pages/Dashboard/Dashboard";
import { BsFillChatDotsFill, BsGraphUp } from 'react-icons/bs';
import { ImUsers } from 'react-icons/im';
import { CgCreditCard } from 'react-icons/cg';
import { MdAssessment, MdAttachMoney, MdBusinessCenter, MdCreditCard, MdLock, MdMessage, MdMoneyOff, MdPhone, MdQuestionAnswer, MdSms, MdStore, MdTimer } from 'react-icons/md';
import { BiCreditCard, BiNotepad, BiTimeFive } from 'react-icons/bi';
import { RiAdminFill } from 'react-icons/ri';
import { MdLockOutline } from 'react-icons/md';
import { CgMenuRound } from 'react-icons/cg';
import { HiDocumentReport, HiOutlineSpeakerphone, HiTicket } from 'react-icons/hi';
import Users from "./pages/Users/Users";
import UserAdmin from "./pages/UserAdmin/UserAdmin";
import DataUsers from "./pages/Users/DataUsers";
import Protocolos from "./pages/Protocolos/Protocolos";
import Emails from "./pages/Emails/Emails";
import PesquisaNPS from "./pages/PesquisaNPS/PesquisaNPS";
import { FaAsterisk, FaBox, FaBuilding, FaCarAlt, FaTicketAlt, FaUser, FaFile, FaGavel, FaTruck, FaUserLock, FaHandHoldingUsd, FaMoneyBillWave, FaAddressCard, FaDesktop, FaMap, FaUserEdit, FaFileAlt, FaEdit, FaShoppingBasket, FaMoneyBillAlt, FaListAlt, FaCashRegister, FaDollyFlatbed, FaFileInvoiceDollar, FaCog, FaMoneyCheck, FaMoneyBill, FaMoneyBillWaveAlt, FaCogs, FaCheckSquare, FaFileSignature, FaBusinessTime, FaShippingFast, FaUsers, FaTag, FaDoorClosed, FaPercent, FaStar } from "react-icons/fa";
import { GoAlert } from "react-icons/go";
import Empresa from "./pages/Users/Empresa";
import Tarefas from "./pages/Tarefas/Tarefas";
import SMS from "./pages/SMS/SMS";
import Permissoes from "./pages/Permissoes/Permissoes";
import Chat from "./pages/Chat/Chat"; 
import MonitoramentoChat from './pages/Chat/MonitoramentoChat';
import Ticket from "./pages/Ticket/Ticket";
import { VscMailRead } from "react-icons/vsc";
import ModelosAssinatura from "./pages/ModelosAssinatura/ModelosAssinatura";
import ClientesPreVenda from "./pages/ClientesPreVenda/ClientesPreVenda";
import ResumoClientePreVenda from "./pages/Users/ResumoClientePreVenda";
import HorarioAtendimento from "./pages/HorarioAtendimento/HorarioAtendimento";
import RelatorioChat from "./pages/Chat/RelatorioChat";
import { AiOutlinePicture } from "react-icons/ai";
import { IoChatbubblesSharp, IoTicketOutline } from "react-icons/io5";
import Departamento from "./pages/Departamento/Departamento";
import RespostasProntas from "./pages/RespostasProntas/RespostasProntas";
import Pendencia from "./pages/Pendencias/Pendencia";
import Ocorrencia from "./pages/Ocorrencias/Ocorrencia";
import ResumoOcorrencia from "./pages/Ocorrencias/ResumoOcorrencia";
import { AiOutlineException } from "react-icons/ai";
import ResumoTicket from "./pages/Ticket/ResumoTicket";
import ClientesAqpago from "./pages/ClientesAqpago/ClientesAqpago";
import ResumoAQPago from "./pages/Users/ResumoAQPago";
import PayoutBloqueados from "./pages/Payout/PayoutBloqueados";
import ResumoPayoutBloqueado from "./pages/Payout/ResumoPayoutBloqueado";
import TodosPayouts from "./pages/Payout/TodosPayouts";
import ResumoPayout from "./pages/Payout/ResumoPayout";
import PayoutCartao from "./pages/Payout/PayoutCartao";
import ClientesDocumentos from "./pages/ClientesDocumentos/ClientesDocumentos";
import PrevencaoRiscoPage from "./pages/PrevencaoRisco/PrevencaoRiscoPage";
import ResumoPrevencao from "./pages/Payout/ResumoPrevencao";
import ProcessosPage from "./pages/Juridico/ProcessosPage";
import ResumoProcesso from "./pages/Juridico/ResumoProcesso";
import BuyerSearch from "./pages/Payout/BuyerSearch";
import BuyerTransacao from "./pages/Payout/BuyerTransacao";
import BuyersBloqueados from "./pages/Buyers/BuyersBloqueados";
import BuyersSuspeito from "./pages/Buyers/BuyersSuspeito";
import SellersSuspeitos from "./pages/Sellers/SellersSuspeitos";
import SellersBloqueados from "./pages/Sellers/SellersBloqueados";
import { ConsultarClienteAqpago } from "./pages/ClientesAqpago/ConsultarClienteAqpago";
import ClientesPedidos from "./pages/ClientesDocumentos/ClientesPedidos";
import TrocarPlanos from "./pages/ClientesAqpago/TrocarPlanos";
import PedidosTransferencias from "./pages/PedidosTransferencia/PedidosTransferencias";
import MudancaEndereco from "./pages/MudancaEndereco/MudancaEndereco";
import ClientesPDV from "./pages/ClientesDocumentos/ClientesPDV";
import EnviarInvite from "./pages/ClientesAqpago/EnviarInvite";
import ClientesCupons from "./pages/ClientesCupons/ClientesCupons";
import ClientesVendas from "./pages/ClientesCupons/ClientesVendas";
import ResumoVenda from "./pages/Vendas/ResumoVenda";
import Logistica from "./pages/Logistica/Logistica";
import PostagemResumo from "./pages/Postagem/PostagemResumo";
import SolicitacaoPlano from "./pages/SolicitacaoPlano/SolicitacaoPlano";
import SolicitacaoPlanoResumo from "./pages/SolicitacaoPlano/SolicitacaoPlanoResumo";
import Loja from "./pages/Loja/Loja";
import LojaResumo from "./pages/Loja/LojaResumo";
import Taggys from "./pages/Taggys/Taggys";
import Transacao from "./pages/Transacao/Transacao";
import { GiBarrier, GiTicket } from "react-icons/gi";
import Contestacoes from "./pages/Contestações/Contestacoes";
import Cobrancas from "./pages/Cobrancas/Cobrancas";
import PrePago from "./pages/PrePago/PrePago";
import PosPago from "./pages/PosPago/PosPago";
import Estacionamento from "./pages/Estacionamento/Estacionamento";
import Relatorios from "./pages/Relatorios/Relatorios";
import { createTrue } from "typescript";
import NotaFiscal from "./pages/NotaFiscal/NotaFiscal";
import ConsultarReceiraFederal from "./pages/ConsultarReceitaFederal/ConsultarReceiraFederal";
import Contabil from "./pages/AQContabil/Contabil";
import ResumoAqContabil from "./pages/AQContabil/ResumoAqContabil";
import Fornecedor from "./pages/Financeiro/Fornecedor";
import ResumoFornecedor from "./pages/Financeiro/ResumoFornecedor";
import ContasPagar from "./pages/Financeiro/ContasPagar";
import ResumoContasPagar from "./pages/Financeiro/ResumoContasPagar";
import PermissoesPage from "./pages/Juridico/PermissoesPage";
import ConfiguracaoFinanceiro from "./pages/Financeiro/Configuracao";
import Serasa from "./pages/Serasa/Serasa";
import AceiteContratoAqpago from "./pages/AceiteContrato/AceiteContratoAqpago";
import ResumoAceiteContratoAqpago from "./pages/AceiteContrato/ResumoAceiteContratoAqpago";
import AQPasso from "./pages/AQPasso/Aqpasso";
import ResumoAQPasso from "./pages/AQPasso/ResumoAQPasso";
import ResumoClienteAQPasso from "./pages/AQPasso/ResumoCliente";
import Empresas from "./pages/Financeiro/Empresas/Empresas";
import ResumoEmpresa from "./pages/Financeiro/Empresas/ResumoEmpresa";
import AdvogadosParceiros from "./pages/Juridico/AdvogadosParceiros";
import AdvogadosParceirosPage from "./pages/Juridico/AdvogadosParceirosPage";
import AQEnvioConfiguracao from "./pages/AQEnvio/Configuracoes";
import AQEnvioVendedores from "./pages/AQEnvio/Vendedores";
import ResumoVendedor from "./pages/AQEnvio/Vendedores/ResumoVendedor";
import ClientesCuponsAqpasso from "./pages/ClientesCupons/ClientesCuponsAqpasso";
import GiftCard from "./pages/GiftCard/GiftCard";
import ClientesCuponsAqContabil from "./pages/ClientesCupons/ClientesCuponsAqContabil";
import UsersAQContabil from "./pages/Users/UsersAQContabil";
import GestorAutorizacao from "./pages/GestorAutorizacao/GestorAutorizacao";
import ResumoGestorAutorizacao from "./pages/GestorAutorizacao/ResumoGestorAutorizacao";
import AQEnvioPostagens from "./pages/AQEnvio/Postagens";
import ResumoPostagem from "./pages/AQEnvio/Postagens/ResumoPostagem";
import PosPagoAQContabil from "./pages/PosPago/PosPagoAQContabil";
import Campanha from "./pages/Campanha/Campanha";
import MovimentacaoFatura from "./pages/Movimentacao/MovimentacaoFatura";
import ClientesQueroBus from "./pages/ClientesQueroBus/ClientesQueroBus";
import ResumoClienteQueroBus from "./pages/AQPasso/ResumoClienteQueroBus";
import PassagensQueroBus from "./pages/QueroBus/PassagensQueroBus";
import ResumoPassagemQueroBus from "./pages/AQPasso/ResumoPassagemQueroBus";
import Leads from './pages/AQBank/Leads';
import ResumoLead from './pages/AQBank/ResumoLead';
import CupomQueroBus from "./pages/QueroBus/CupomQueroBus";
import ResumoCupomQueroBus from "./pages/QueroBus/ResumoCupomQueroBus";
import OfertasQueroBus from "./pages/QueroBus/OfertasQueroBus";
import NewDealQueroBus from "./pages/QueroBus/NewDealQueroBus";
import PagamentosQueroBus from "./pages/QueroBus/PagamentosQueroBus";
import Multibeneficio from "./pages/Multibeneficio/Multibeneficio";
import Recargas from "./pages/Recargas/Recargas";
import DetalhesRecarga from "./pages/Recargas/DetalhesRecarga";
import { Onboarding } from "./pages/Onboarding/Onboarding";
import { UserDetails } from "./pages/Onboarding/UserDetails";
import { ListClients } from "./pages/AQMed/Clients/ListClients";
import { Uniodonto } from "./pages/AQMed/Uniodonto/Uniodonto";
import { UniodontoUserData } from "./pages/AQMed/Uniodonto/UniodontoUserData";
import { TotalPass } from "./pages/AQMed/TotalPass/TotalPass";
import { TotalPassUserData } from "./pages/AQMed/TotalPass/TotalPassUserData";
import { ListCancelRequest } from "./pages/AQMed/Clients/ListCancelRequest";
import { ViewClient } from "./pages/AQMed/Clients/ViewClients"; 
import { ListCompanys } from "./pages/AQMed/Company/ListCompanys";
import { ViewCompany } from "./pages/AQMed/Company/ViewCompany";
import { Payments } from "./pages/AQMed/Payments/Payments";
import LeadsSantosFC from "./pages/AQBank/SantosFC/LeadsSantosFC";
import LeadsDrCury from "./pages/AQBank/DRCury/LeadsDrCury";
import PabxCalls from "./pages/Pabx/PabxCalls";
import PabxDetails from "./pages/Pabx/PabxDetails";


const dashboardRoutes = (em_analise = 0) => [
    {
      path: "/dashboard",
      name: "Dashboard",
      type: 'link',
      sidebar: {
        nav: "menu",
        subnav: false,
        sortorder: 1,
      },
      show: true,
      permission: 'id',
      icon: BsGraphUp,
      component: Dashboard
    },
    {
      path: "/autorizacao-funcao",
      name: "Gestor de Solicitações",
      type: 'link',
      sidebar: {
        nav: "menu",
        subnav: false,
        sortorder: 2,
      },
      show: true,
      permission: 'id',
      scroll: {
        path: "/gestor-licencas/:id",
        name: "Relatório",
        type: 'link',
        show: false,
        icon: HiDocumentReport,
        component: ResumoGestorAutorizacao,
      },
      icon: MdLockOutline,
      component: GestorAutorizacao
    },
    {
      name: "MeuBus",
      type: 'text',
      sidebar: {
        nav: "gestor_querobus",
        subnav: false,
        sortorder: 3,
      },
      //permission: 'permissao_gestor_querobus',
      permission: 'permissao_gestor_aqpago',
      icon: FaBuilding,
    },
    {
      path: '/users-querobus',
      name: "Clientes MeuBus",
      type: 'link',
      sidebar: {
        nav: "gestor_querobus",
        subnav: true,
        sortorder: 4,
      },
      show: true,
      //permission: 'permissao_gestor_querobus',
      permission: 'permissao_gestor_aqpago',
      icon: ImUsers,
      component: ClientesQueroBus,
      scroll: {
        path: "/querobus-user/:id",
        name: "QueroBusResumo",
        type: 'link',
        show: false,
        icon: HiDocumentReport,
        component: ResumoClienteQueroBus,
      }
    },
    {
      path: '/passagens-querobus',
      name: "Passagens MeuBus",
      type: 'link',
      sidebar: {
        nav: "gestor_querobus",
        subnav: true,
        sortorder: 5,
      },
      show: FaDoorClosed,
      //permission: 'permissao_gestor_querobus',
      permission: 'permissao_gestor_aqpago',
      icon: FaTicketAlt,
      component: PassagensQueroBus,
      scroll: {
        path: "/querobus-passagem/:id",
        name: "QueroBusResumo",
        type: 'link',
        show: false,
        icon: HiDocumentReport,
        component: ResumoPassagemQueroBus,
      }
    },
    {
      path: '/cupom-querobus',
      name: "Cupom MeuBus",
      type: 'link',
      sidebar: {
        nav: "gestor_querobus",
        subnav: true,
        sortorder: 10,
      },
      show: FaDoorClosed,
      //permission: 'permissao_gestor_querobus',
      permission: 'permissao_gestor_aqpago',
      icon: FaPercent,
      component: CupomQueroBus,
      scroll: {
        path: "/querobus-cupom/:id",
        name: "cupom resumo",
        type: 'link',
        show: false,
        icon: HiDocumentReport,
        component: ResumoCupomQueroBus,
      }
    },
    {
      path: '/ofertas-querobus',
      name: "Ofertas MeuBus",
      type: 'link',
      sidebar: {
        nav: "gestor_querobus",
        subnav: true,
        sortorder: 15,
      },
      show: FaDoorClosed,
      //permission: 'permissao_gestor_querobus',
      permission: 'permissao_gestor_aqpago',
      icon: FaStar,
      component: OfertasQueroBus,
      scroll: {
        path: "/querobus-new-deal",
        name: "nova oferta",
        type: 'link',
        show: false,
        icon: HiDocumentReport,
        component: NewDealQueroBus,
      }
    },
    
    /*
    {
      path: "/prevencao-querobus",
      name: "PAGAMENTOS MEUBUS",
      type: 'link',
      sidebar: {
        nav: "gestor_querobus",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: FaAsterisk,
      component: PagamentosQueroBus
    }, */

    {
      name: "AQBANK",
      type: 'text',
      sidebar: {
        nav: "gestor_aqbank",
        subnav: false,
        sortorder: 12,
      },
      permission: 'permissao_gestor_aqpago',
      icon: FaBuilding,
    },
    {
      path: '/aqbank-leads',
      name: "Lista de Espera",
      type: 'link',
      sidebar: {
        nav: "gestor_aqbank",
        subnav: true,
        sortorder: 10,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: ImUsers,
      component: Leads,
      scroll: {
        path: "/aqbank-lead/:id",
        name: "Dados",
        type: 'link',
        show: false,
        icon: HiDocumentReport,
        component: ResumoLead,
      }
    },
    {
      path: '/aqbank-leads-santos-fc',
      name: "Lista de Espera - Santos FC",
      type: 'link',
      sidebar: {
        nav: "gestor_aqbank",
        subnav: true,
        sortorder: 10,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: ImUsers,
      component: LeadsSantosFC,
      scroll: {
        path: "/aqbank-lead/:id",
        name: "Dados",
        type: 'link',
        show: false,
        icon: HiDocumentReport,
        component: ResumoLead,
      }
    },
    {
      path: '/aqbank-leads-dr-cury',
      name: "Lista de Espera - DR. José Cury",
      type: 'link',
      sidebar: {
        nav: "gestor_aqbank",
        subnav: true,
        sortorder: 10,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: ImUsers,
      component: LeadsDrCury,
      scroll: {
        path: "/aqbank-lead/:id",
        name: "Dados",
        type: 'link',
        show: false,
        icon: HiDocumentReport,
        component: ResumoLead,
      }
    },

    {
      name: "MULTIBENEFICIOS",
      type: 'text',
      sidebar: {
        nav: "gestor_multibeneficios",
        subnav: false,
        sortorder: 13,
      },
      permission: 'permissao_gestor_aqpago',
      icon: FaBuilding,
    },
    {
      path: '/multibeneficios-leads',
      name: "Lista de Espera",
      type: 'link',
      sidebar: {
        nav: "gestor_multibeneficios",
        subnav: true,
        sortorder: 10,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: ImUsers,
      component: Multibeneficio
    },
    {
      name: "AQPago",
      type: 'text',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: false,
        sortorder: 10,
      },
      permission: 'permissao_gestor_aqpago',
      icon: FaBuilding,
    },
    {
      path: '/users-aqpago',
      name: "Clientes AQPago",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
        sortorder: 10,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: ImUsers,
      component: ClientesAqpago,
      scroll: {
        path: "/aqpago/:document",
        name: "Relatório",
        type: 'link',
        show: false,
        icon: HiDocumentReport,
        component: ResumoAQPago,
      }
    },
    {
      path: "/consultar-clientes",
      name: "Consultar Clientes",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: ImUsers,
      component: ConsultarClienteAqpago
    },
    {
      path: "/onboarding",
      name: "Onboarding",
      type: "link",
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: "permissao_gestor_aqpago",
      icon: ImUsers,
      component: Onboarding,
    },
    {
      path: "/usuario/onboarding/:id",
      name: "Detalhes do Usuário",
      type: "link",
      show: true,
      permission: "permissao_gestor_aqpago",
      icon: ImUsers,
      component: UserDetails,
    },
    {
      path: "/users-pre-venda",
      name: "Clientes Pré-venda",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
        sortorder: 20,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: ImUsers,
      component: ClientesPreVenda,
      scroll: {
        path: "/user-pre-venda/:id",
        name: "Relatório",
        type: 'link',
        show: false,
        icon: HiDocumentReport,
        component: ResumoClientePreVenda,
      }
    },
    {
      path: '/reprovados-credito',
      name: "Reprovados na analise de crédito",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: ImUsers,
      scroll: {
        path: "/user/:id",
        name: "AQPasso",
        type: 'link',
        show: false,
        icon: HiDocumentReport,
        component: DataUsers,
      },
      component: Serasa,
    },
    {
      path: "/users-pendentes",
      name: "Clientes Pendentes de Documentos",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: ImUsers,
      component: ClientesDocumentos
    },   
    {
      path: "/aceite-contrato-aqpago",
      name: "Aceites de Contrato AQPago",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true
      },
      permission: 'permissao_gestor_aqpago',
      scroll: {
        path: "/detalhes-aceite-contrato-aqpago/:id",
        name: "Relatório",
        type: 'link',
        show: false,
        icon: HiDocumentReport,
        component: ResumoAceiteContratoAqpago,
      },
      show: true,
      permission: 'ver_clientes',
      icon: FaFileSignature,
      component: AceiteContratoAqpago,
    },
    {
      path: "/aqmed/faturas",
      name: "Faturas",
      type: "link",
      sidebar: {
        nav: "gestor_aqmed",
        subnav: true,
      },
      show: true,
      permission: "permissao_gestor_aqpago",
      icon: ImUsers,
      component: Payments,
    },
    {
      name: "AQMED",
      type: "text",
      sidebar: {
        nav: "gestor_aqmed",
        subnav: false,
        sortorder: 19,
      },
      permission: "permissao_gestor_aqpago",
      icon: FaBuilding,
    },
    {
      path: "/aqmed/clientes",
      name: "Clientes",
      type: "link",
      sidebar: {
        nav: "gestor_aqmed",
        subnav: true,
      },
      show: true,
      permission: "permissao_gestor_aqpago",
      icon: ImUsers,
      component: ListClients,
    },
    {
      path: "/aqmed/clients/:id",
      name: "Cliente",
      type: "link",
      show: true,
      permission: "permissao_gestor_aqpago",
      icon: ImUsers,
      component: ViewClient,
    },
    {
      path: "/aqmed/empresas",
      name: "Empresas",
      type: "link",
      sidebar: {
        nav: "gestor_aqmed",
        subnav: true,
      },
      show: true,
      permission: "permissao_gestor_aqpago",
      icon: ImUsers,
      component: ListCompanys,
    },
    {
      path: "/aqmed/empresa/:id",
      name: "Empresa",
      type: "link",
      show: true,
      permission: "permissao_gestor_aqpago",
      icon: ImUsers,
      component: ViewCompany,
    },
    {
      path: "/aqmed/cancelamentos",
      name: "Pedidos de Cancelamento",
      type: "link",
      sidebar: {
        nav: "gestor_aqmed",
        subnav: true,
      },
      show: true,
      permission: "permissao_gestor_aqpago",
      icon: ImUsers,
      component: ListCancelRequest,
    },
    {
      path: "/aqmed/totalpass",
      name: "TotalPass",
      type: "link",
      sidebar: {
        nav: "gestor_aqmed",
        subnav: true,
      },
      show: true,
      permission: "permissao_gestor_aqpago",
      icon: ImUsers,
      component: TotalPass,
    },
    {
      path: "/aqmed/uniodonto",
      name: "Uniodonto",
      type: "link",
      sidebar: {
        nav: "gestor_aqmed",
        subnav: true,
      },
      show: true,
      permission: "permissao_gestor_aqpago",
      icon: ImUsers,
      component: Uniodonto,
    },
    {
      path: "/aqmed/usuario/uniodonto/:id",
      name: "Uniodonto Usuários",
      type: "link",
      show: true,
      permission: "permissao_gestor_aqpago",
      icon: ImUsers,
      component: UniodontoUserData,
    },
    {
      path: "/aqmed/usuario/totalpass/:id",
      name: "TotalPass Usuários",
      type: "link",
      show: true,
      permission: "permissao_gestor_aqpago",
      icon: ImUsers,
      component: TotalPassUserData,
    },
    {
      name: "Pedidos",
      type: 'subtext',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
    },
    {
      path: "/users-pedidos",
      name: "Pedidos de cadastro",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: FaAddressCard,
      component: ClientesPedidos
    },
    {
      path: "/users-pdv",
      name: "Pedidos por PDV",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: FaDesktop,
      component: ClientesPDV
    },
    {
      path: "/mudanca-endereco",
      name: "Solicitações de mudança de enderecos",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: FaMap,
      component: MudancaEndereco
    },
    {
      path: "/transferir-titularidade",
      name: "Transferir Titularidade",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: FaUserEdit,
      component: PedidosTransferencias
    },
    {
      path: "/users-plano",
      name: "Trocar Plano",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: FaFileAlt,
      component: TrocarPlanos
    },
    {
      path: "/acesso-app-aqpago",
      name: "Acesso APP AQPago",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: ImUsers,
      component: EnviarInvite
    },
    {
      path: "/solicitacoes-plano",
      name: "Solicitações de Mudanças de Plano",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: FaEdit,
      scroll: {
        path: "/resumo-solicitacao/:id",
        name: "Solicitação",
        type: 'link',
        show: false,
        icon: FaBox,
        component: SolicitacaoPlanoResumo,
      },
      component: SolicitacaoPlano
    },
    {
      path: "/clientes-vendas",
      name: "Vendas de Maquininha",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      scroll: {
        path: "/venda/:id",
        component: ResumoVenda,
      },
      icon: FaMoneyBillWave,
      component: ClientesVendas
    },
    {
      name: "AQContabil",
      type: 'text',
      sidebar: {
        nav: "aqpacontabil",
        subnav: false,
        sortorder: 50,
      },
      icon: FaBuilding,
    },
    {
      path: "/aqcontabil-principal",
      name: "Vendas AQContabil",
      type: 'link',
      sidebar: {
        nav: "aqpacontabil",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqcontabil',
      scroll: {
        path: "/aqcontabil/:id",
        component: ResumoAqContabil,
      },
      icon: FaMoneyBillAlt,
      component: Contabil
    },
    {
      path: "/aqcontabil-clientes",
      name: "Clientes AQContabil",
      type: 'link',
      sidebar: {
        nav: "aqpacontabil",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqcontabil',
      icon: FaUser,
      component: UsersAQContabil
    },
    {
      path: "/aqcontabil-pospago",
      name: "Faturas AQContabil",
      type: 'link',
      sidebar: {
        nav: "aqpacontabil",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqcontabil',
      icon: BiCreditCard,
      component: PosPagoAQContabil
    },
    {
      path: "/clientes-cupons",
      name: "Cupons",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: IoTicketOutline,
      component: ClientesCupons
    },
    {
      name: "E-commerces",
      type: 'subtext',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
    },
    {
      path: "/lojas-registradas",
      name: "Gerenciamento de Lojas",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: MdStore,
      scroll: {
        path: "/resumo-loja/:id",
        name: "Resumo de loja",
        type: 'link',
        show: false,
        icon: MdStore,
        component: LojaResumo,
      },
      component: Loja
    },
    {
      path: "/clientes-cupons-aqcontabil",
      name: "Cupons",
      type: 'link',
      sidebar: {
        nav: "aqpacontabil",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqcontabil',
      icon: IoTicketOutline,
      component: ClientesCuponsAqContabil
    },
    {
      name: "AQPasso",
      type: 'text',
      sidebar: {
        nav: "aqpasso",
        subnav: false,
        sortorder: 75,
      },
      permission: 'permissao_gestor_aqpasso',
      icon: FaBuilding,
    },
    {
      path: "/users",
      name: "Clientes AQPasso",
      type: 'link',
      sidebar: {
        nav: "aqpasso",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpasso',
      icon: ImUsers,
      component: Users,
      scroll: {
        path: "/user-aqpasso/:id",
        name: "AQPasso",
        type: 'link',
        show: false,
        icon: HiDocumentReport,
        component: ResumoClienteAQPasso,
      }
    },
    
    {
      path: "/aqpasso-campanha",
      name: "Campanha AQPasso",
      type: 'link',
      sidebar: {
        nav: "aqpasso",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpasso',
      icon: AiOutlinePicture,
      component: Campanha
    },
    {
      path: "/clientes-cupons-aqpasso",
      name: "Cupons AQPasso",
      type: 'link',
      sidebar: {
        nav: "aqpasso",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpasso',
      icon: IoTicketOutline,
      component: ClientesCuponsAqpasso
    },
    {
      path: "/gift-card",
      name: "GiftCard",
      type: 'link',
      sidebar: {
        nav: "aqpasso",
        subnav: true,
      },
      show: false,
      permission: 'permissao_gestor_aqpasso',
      //permission: 'payout_bloqueados',,
      /* scroll: {
        path: "/payout-detalhes/:id",
        component: ResumoPayoutBloqueado,
      }, */
      icon: MdAttachMoney,
      component: GiftCard
    },
    {
      path: "/taggys",
      name: "Taggys",
      type: 'link',
      sidebar: {
        nav: "aqpasso",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpasso',
      icon: IoTicketOutline,
      component: AQPasso
    },
    {
      path: "/transacao",
      name: "Transacao",
      type: 'link',
      sidebar: {
        nav: "aqpasso",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpasso',
      icon: GiBarrier,
      component: Transacao
    },
    {
      path: "/contestacoes",
      name: "Contestações",
      type: 'link',
      sidebar: {
        nav: "aqpasso",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpasso',
      icon: HiOutlineSpeakerphone,
      component: Contestacoes
    },
    {
      path: "/cobrancas",
      name: "Movimentação de Saldo pré-pago",
      type: 'link',
      sidebar: {
        nav: "aqpasso",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpasso',
      icon: MdAttachMoney,
      component: Cobrancas
    },
    {
      path: "/cobrancas-fatura",
      name: "Movimentação de Faturas",
      type: 'link',
      sidebar: {
        nav: "aqpasso",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpasso',
      icon: MdAttachMoney,
      component: MovimentacaoFatura
    },
    {
      path: "/prepago",
      name: "Créditos Pré-Pago",
      type: 'link',
      sidebar: {
        nav: "aqpasso",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpasso',
      icon: GiTicket,
      component: PrePago
    },
    {
      path: "/pospago",
      name: "Faturas",
      type: 'link',
      sidebar: {
        nav: "aqpasso",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpasso',
      icon: BiCreditCard,
      component: PosPago
    },
    {
      path: "/estacionamento",
      name: "Redes de Atendimento",
      type: 'link',
      sidebar: {
        nav: "aqpasso",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpasso',
      scroll: {
        path: "/aqpasso-detalhes/:id",
        component: ResumoAQPasso,
      },
      icon: FaCarAlt,
      component: Estacionamento
    },
    /* {
      path: "/relatorios",
      name: "Relatorios",
      type: 'link',
      sidebar: {
        nav: "aqpasso",
        subnav: true,
      },
      show: true,
      permission: 'ver_clientes',
      icon: HiDocumentReport,
      component: Relatorios
    },
    {
      name: "JÚRIDICO",
      type: 'text',
      sidebar: {
        nav: "juridico",
        subnav: false,
        sortorder: 45,
      },
      icon: FaGavel,
    },
    {
      path: "/juridico",
      name: "PROCESSOS",
      type: 'link',
      sidebar: {
        nav: "juridico",
        subnav: true,
      },
      show: true,
      permission: 'ver_sms',
      scroll: {
        path: "/juridico-detalhes/:id",
        component: ResumoProcesso,
      },
      icon: FaListAlt,
      component: ProcessosPage
    },
    {
      path: "/advogado-parceiro",
      name: "ADVOGADOS PARCEIROS",
      type: 'link',
      sidebar: {
        nav: "juridico",
        subnav: true,
      },
      show: true,
      permission: 'ver_sms',
      scroll: {
        path: "/advogado-parceiro-detalhes/:id",
        component: AdvogadosParceiros,
      },
      icon: FaUser,
      component: AdvogadosParceirosPage
    },
    {
      path: "/juridico-permissoes",
      name: "PERMISSÕES",
      type: 'link',
      sidebar: {
        nav: "juridico",
        subnav: true,
      },
      show: true,
      permission: 'gerenciar_permissoes_operador',
      icon: MdLock,
      component: PermissoesPage
    },
    {
      name: "EMPRESA AQPago",
      type: 'text',
      sidebar: {
        nav: "aqpago",
        subnav: false,
        sortorder: 75,
      },
      permission: 'permissao_gestor_aqpago',
      icon: FaBuilding,
    },
    */
    {
      name: "AQEnvio",
      type: 'text',
      sidebar: {
        nav: "aqenvio",
        subnav: false,
        sortorder: 78,
      },
      permission: 'permissao_gestor_aqpago',
      icon: FaBuilding,
    },
    {
      path: "/aqenvio-vendedores",
      name: "Vendedores",
      type: 'link',
      sidebar: {
        nav: "aqenvio",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      scroll: {
        path: "/aqenvio-vendedor-detalhes/:id",
        component: ResumoVendedor,
      },
      icon: FaUsers,
      component: AQEnvioVendedores
    },
    {
      path: "/aqenvio-etiquetas",
      name: "Postagens",
      type: 'link',
      sidebar: {
        nav: "aqenvio",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      scroll: {
        path: "/aqenvio-etiqueta-detalhes/:id",
        component: ResumoPostagem,
      },
      icon: FaTag,
      component: AQEnvioPostagens
    },
    {
      path: "/aqenvio-configuracao",
      name: "Configurações",
      type: 'link',
      sidebar: {
        nav: "aqenvio",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      // scroll: {
      //   path: "/aqenvio-rules-detalhes/:id",
      //   component: ResumoEmpresa,
      // },
      icon: FaCogs,
      component: AQEnvioConfiguracao
    },
    {
      name: "Financeiro",
      type: 'text',
      sidebar: {
        nav: "financeiro",
        subnav: false,
        sortorder: 79,
      },
      permission: 'permissao_gestor_aqpago',
      icon: FaMoneyBillAlt,
    },
    {
      path: "/empresas",
      name: "Empresas",
      type: 'link',
      sidebar: {
        nav: "financeiro",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      scroll: {
        path: "/empresa-detalhes/:id",
        component: ResumoEmpresa,
      },
      icon: FaBusinessTime,
      component: Empresas
    },
    {
      path: "/fornecedor",
      name: "Fornecedores",
      type: 'link',
      sidebar: {
        nav: "financeiro",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      scroll: {
        path: "/fornecedor-detalhes/:id",
        component: ResumoFornecedor,
      },
      icon: FaDollyFlatbed,
      component: Fornecedor
    },   
    {
      path: "/contas-a-pagar",
      name: "Contas a pagar",
      type: 'link',
      sidebar: {
        nav: "financeiro",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      scroll: {
        path: "/contas-a-pagar-detalhes/:id",
        component: ResumoContasPagar,
      },
      icon: FaFileInvoiceDollar,
      component: ContasPagar
    },
    {
      path: "/contas-a-receber",
      name: "Contas a receber",
      type: 'link',
      sidebar: {
        nav: "financeiro",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      scroll: {
        path: "/contas-a-receber-detalhes/:id",
        component: ResumoPayout,
      },
      icon: FaHandHoldingUsd,
      component: TodosPayouts
    },    
    {
      path: "/configuracao-financeiro",
      name: "Configurações",
      type: 'link',
      sidebar: {
        nav: "financeiro",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: FaCogs,
      component: ConfiguracaoFinanceiro
    },
/*
    {
      name: "ESTABELECIMENTOS",
      type: 'subtext',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
    },
    {
      path: "/buscar-receita",
      name: "BUSCAR RECEITA FEDERAL",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: FaUser,
      component: ConsultarReceiraFederal
    },
    {
      path: "/payout-card",
      name: "PAGAMENTOS POR CARTÃO",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: MdCreditCard,
      component: PayoutCartao
    },
    {
      name: "BUYER AQPAGO",
      type: 'subtext',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
    },
    /* {
      path: "/buyers-bloqueados",
      name: "BUYERS BLOQUEADOS",
      type: 'link',
      sidebar: {
        nav: "aqpago",
        subnav: true,
      },
      show: true,
      permission: 'ver_emails',
      icon: FaUserLock,
      component: BuyersBloqueados
    }, */
    /* {
      path: "/buyers-suspeitos",
      name: "BUYERS SUSPEITOS",
      type: 'link',
      sidebar: {
        nav: "aqpago",
        subnav: true,
      },
      show: true,
      permission: 'ver_emails',
      icon: GoAlert,
      component: BuyersSuspeito
    }, */
    {
      path: "/buscar-buyer",
      name: "BUSCAR BUYER",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: CgCreditCard,
      component: BuyerSearch
    },
    {
      path: "/buscar-buyer-transacao",
      name: "BUSCAR BUYER POR ID DA TRANSAÇÂO",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      icon: CgCreditCard,
      component: BuyerTransacao
    },
    {
      path: "/gestao-adquirencia",
      name: "Gestão de Adquirência",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      scroll: {
        path: "/payout-aqpago-detalhes/:id",
        component: ResumoPayout,
      },
      icon: FaHandHoldingUsd,
      component: TodosPayouts
    }, 
    /* {
      name: "SELLER AQPAGO",
      type: 'subtext',
    },
    {
      path: "/sellers-bloqueados",
      name: "SELLERS BLOQUEADOS",
      type: 'link',
      show: true,
      permission: 'ver_emails',
      icon: FaUserLock,
      component: SellersBloqueados
    },
    {
      path: "/sellers-suspeitos",
      name: "SELLERS SUSPEITOS",
      type: 'link',
      show: true,
      permission: 'ver_emails',
      icon: GoAlert,
      component: SellersSuspeitos
    }, */
    {
      name: "PREVENÇÃO & RISCO",
      type: 'subtext',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
    },
    {
      path: "/payout",
      name: "Pagamentos Bloqueados",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      //permission: 'payout_bloqueados',,
      scroll: {
        path: "/payout-detalhes/:id",
        component: ResumoPayoutBloqueado,
      },
      icon: MdMoneyOff,
      component: PayoutBloqueados
    },
    {
      path: "/prevencao",
      name: "PREVENÇÃO ANALISE ONLINE"+(em_analise > 0 ? ' ('+em_analise+')' : ''),
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      scroll: {
        path: "/prevencao-detalhes/:id",
        component: ResumoPrevencao,
      },
      icon: FaAsterisk,
      component: PrevencaoRiscoPage
    },
    {
      name: "RECARGAS",
      type: 'subtext',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
    },
    {
      path: "/recargas",
      name: "GERENCIAMENTO DE RECARGAS",
      type: 'link',
      sidebar: {
        nav: "gestor_aqpago",
        subnav: true,
      },
      show: true,
      permission: 'permissao_gestor_aqpago',
      //permission: 'payout_bloqueados',,
      scroll: {
        path: "/recarga/:id",
        component: DetalhesRecarga,
      },
      icon: FaAsterisk,
      component: Recargas
    },
    
    // {
    //   name: "FINANCEIRO",
    //   type: 'subtext',
    //   sidebar: {
    //     nav: "gestor_aqpago",
    //     subnav: true,
    //   },
    //   permission: 'permissao_gestor_aqpago',
    // },
    // {
    //   path: "/fornecedor",
    //   name: "Fornecedor",
    //   type: 'link',
    //   sidebar: {
    //     nav: "gestor_aqpago",
    //     subnav: true,
    //   },
    //   show: true,
    //   permission: 'permissao_gestor_aqpago',
    //   scroll: {
    //     path: "/fornecedor-detalhes/:id",
    //     component: ResumoFornecedor,
    //   },
    //   icon: FaDollyFlatbed,
    //   component: Fornecedor
    // },
    // {
    //   path: "/contas-a-pagar",
    //   name: "Contas a pagar",
    //   type: 'link',
    //   sidebar: {
    //     nav: "gestor_aqpago",
    //     subnav: true,
    //   },
    //   show: true,
    //   permission: 'permissao_gestor_aqpago',
    //   scroll: {
    //     path: "/contas-a-pagar-detalhes/:id",
    //     component: ResumoContasPagar,
    //   },
    //   icon: FaFileInvoiceDollar,
    //   component: ContasPagar
    // },
    // {
    //   path: "/contas-a-receber",
    //   name: "Contas a receber",
    //   type: 'link',
    //   sidebar: {
    //     nav: "gestor_aqpago",
    //     subnav: true,
    //   },
    //   show: true,
    //   permission: 'permissao_gestor_aqpago',
    //   scroll: {
    //     path: "/payout-aqpago-detalhes/:id",
    //     component: ResumoPayout,
    //   },
    //   icon: FaHandHoldingUsd,
    //   component: TodosPayouts
    // },    
    // {
    //   path: "/configuracao-financeiro",
    //   name: "Configurações",
    //   type: 'link',
    //   sidebar: {
    //     nav: "gestor_aqpago",
    //     subnav: true,
    //   },
    //   show: true,
    //   permission: 'permissao_gestor_aqpago',
    //   icon: FaCogs,
    //   component: ConfiguracaoFinanceiro
    // },
    
    {
      name: "LOGISTICA",
      type: 'text',
      sidebar: {
        nav: "lojistica",
        subnav: false,
        sortorder: 80,
      },
      permission: 'permissao_gestor_aqpago',
      icon: FaTruck,
    },
    {
      path: "/logistica",
      name: "Gerenciamento Logistica",
      type: 'link',
      sidebar: {
        nav: "lojistica",
        subnav: true,
      },
      show: true,
      permission: 'permissao_logistica',
      icon: FaBox,
      scroll: {
        path: "/resumo-postagem/:id",
        name: "Relatório",
        type: 'link',
        show: false,
        icon: HiDocumentReport,
        component: PostagemResumo,
      },
      component: Logistica
    },
    {
      path: "/nota_fiscal",
      name: "Gerenciamento de Notas Fiscais",
      type: 'link',
      sidebar: {
        nav: "lojistica",
        subnav: true,
      },
      show: true,
      permission: 'permissao_logistica',
      icon: FaTicketAlt,
      component: NotaFiscal
    },
    
    
    {
      name: "JURÍDICO",
      type: 'text',
      sidebar: {
        nav: "juridico",
        subnav: false,
        sortorder: 85,
      },
      icon: FaGavel,
    },
    {
      path: "/juridico",
      name: "PROCESSOS",
      type: 'link',
      sidebar: {
        nav: "juridico",
        subnav: true,
      },
      show: true,
      permission: 'permissao_juridico',
      scroll: {
        path: "/juridico-detalhes/:id",
        component: ResumoProcesso,
      },
      icon: FaListAlt,
      component: ProcessosPage
    },
    {
      path: "/juridico-permissoes",
      name: "PERMISSÕES",
      type: 'link',
      sidebar: {
        nav: "juridico",
        subnav: true,
      },
      show: true,
      permission: 'permissao_juridico',
      icon: MdLock,
      component: PermissoesPage
    },
    {
      name: "OMNICHANNEL",
      type: 'text',
      sidebar: {
        nav: "atendimento",
        subnav: false,
        sortorder: 90,
      },
      permission: 'permissao_omnichannel',
      icon: MdMessage,
    },
    
    {
      path: "/buscar-receita",
      name: "BUSCAR RECEITA FEDERAL",
      type: 'link',
      sidebar: {
        nav: "atendimento",
        subnav: true,
      },
      show: true,
      permission: 'permissao_omnichannel',
      icon: FaUser,
      component: ConsultarReceiraFederal
    },
    {
      path: "/emails",
      name: "Emails",
      type: 'link',
      sidebar: {
        nav: "atendimento",
        subnav: true,
      },
      show: true,
      permission: 'permissao_omnichannel',
      icon: MdMessage,
      component: Emails
    },
    {
      path: "/sms",
      name: "SMS",
      type: 'link',
      sidebar: {
        nav: "atendimento",
        subnav: true,
      },
      show: true,
      permission: 'permissao_omnichannel',
      icon: MdSms,
      component: SMS
    },
    {
      path: "/nps",
      name: "Pesquisa NPS",
      type: 'link',
      sidebar: {
        nav: "atendimento",
        subnav: true,
      },
      show: true,
      permission: 'permissao_omnichannel',
      icon: MdAssessment,
      component: PesquisaNPS
    },
    {
      path: "/pendencias",
      name: "Clientes com Pendências",
      type: 'link',
      sidebar: {
        nav: "atendimento",
        subnav: true,
      },
      show: true,
      permission: 'permissao_omnichannel',
      icon: BiTimeFive,
      component: Pendencia
    },
    {
      path: "/atividades",
      name: "Atividades do Operador",
      type: 'link',
      sidebar: {
        nav: "atendimento",
        subnav: true,
      },
      show: true,
      permission: 'permissao_omnichannel',
      icon: BiNotepad,
      component: Tarefas,
    },
    {
      path: "/relatorio_chat",
      name: "Relatorio de Chat",
      type: 'link',
      sidebar: {
        nav: "atendimento",
        subnav: true,
      },
      show: true,
      permission: 'permissao_omnichannel',
      icon: HiDocumentReport,
      component: RelatorioChat
    },
    {
      path: "/ocorrencias",
      name: "Ocorrências",
      type: 'link',
      sidebar: {
        nav: "atendimento",
        subnav: true,
      },
      show: true,
      permission: 'permissao_omnichannel',
      icon: AiOutlineException,
      component: Ocorrencia,
      scroll: {
        path: "/ocorrencia-detalhe/:id",
        component: ResumoOcorrencia,
      }
    },
    {
      name: "CHAT & ATENDIMENTO",
      type: 'text',
      sidebar: {
        nav: "dash_atendimento",
        subnav: false,
        sortorder: 100,
      },
      permission: 'permissao_atendimento_cliente',
      icon: BsFillChatDotsFill,
    },
    {
      path: "/pabx-call",
      name: "Pabx Ligações",
      type: 'link',
      sidebar: {
        nav: "dash_atendimento",
        subnav: true,
      },
      show: true,
      permission: 'permissao_atendimento_cliente',
      icon: MdPhone,
      component: PabxCalls,
      scroll: {
        path: "/pabx-info/:id",
        component: PabxDetails,
      }
    },
    {
      path: "/chat",
      name: "Janela de Chat",
      type: 'link',
      sidebar: {
        nav: "dash_atendimento",
        subnav: true,
      },
      show: true,
      permission: 'permissao_atendimento_cliente',
      icon: BsFillChatDotsFill,
      component: Chat
    },
    {
      path: "/monitoramento_chat",
      name: "Monitoramento de Chat",
      type: 'link',
      sidebar: {
        nav: "dash_atendimento",
        subnav: true,
      },
      show: true,
      permission: 'permissao_monitoramento_chat',
      icon: IoChatbubblesSharp,
      component: MonitoramentoChat
    },
    {
      path: "/protocolos",
      name: "Protocolos",
      type: 'link',
      sidebar: {
        nav: "dash_atendimento",
        subnav: true,
      },
      show: true,
      permission: 'permissao_atendimento_cliente',
      icon: CgMenuRound,
      component: Protocolos
    },
    {
      path: "/ticket",
      name: "Ticket",
      type: 'link',
      sidebar: {
        nav: "dash_atendimento",
        subnav: true,
      },
      show: true,
      permission: 'permissao_atendimento_cliente',
      icon: HiTicket,
      component: Ticket,
      scroll: {
        path: "/ticket-detalhe/:id",
        component: ResumoTicket,
      }
    },
    {
      name: "CONFIGURAÇÕES",
      type: 'text',
      sidebar: {
        nav: "configuracao",
        subnav: false,
        sortorder: 120,
      },
      icon: FaCog
    },
    {
      path: "/permissoes",
      name: "Permissões",
      type: 'link',
      sidebar: {
        nav: "configuracao",
        subnav: true,
      },
      show: true,
      permission: 'permissao_configuracoes',
      icon: MdLock,
      component: Permissoes,
    },
    /* {
      path: "/controle_ip",
      name: "Controle de Acesso IP's",
      type: 'link',
      sidebar: {
        nav: "configuracao",
        subnav: true,
      },
      show: true,
      permission: 'gerenciar_permissoes_operador',
      icon: BiMap,
      component: ControleIP,
    }, */
    {
      path: "/operadores",
      name: "Operadores",
      type: 'link',
      sidebar: {
        nav: "configuracao",
        subnav: true,
      },
      show: true,
      permission: 'permissao_configuracoes',
      icon: RiAdminFill,
      component: UserAdmin
    },
    {
      path: "/horario",
      name: "Horario de Atendimento",
      type: 'link',
      sidebar: {
        nav: "configuracao",
        subnav: true,
      },
      show: true,
      permission: 'permissao_configuracoes',
      icon: MdTimer,
      component: HorarioAtendimento,
    },
    /* {
      path: "/controle_chat",
      name: "Controle de Atendimento",
      type: 'link',
      sidebar: {
        nav: "dash_atendimento",
        subnav: true,
      },
      show: true,
      permission: 'gerenciamento_chat',
      icon: RiCustomerService2Fill,
      component: ControleChat
    }, */
    {
      path: "/controle_departamento",
      name: "Controle de Departamento",
      type: 'link',
      sidebar: {
        nav: "configuracao",
        subnav: true,
      },
      show: true,
      permission: 'permissao_configuracoes',
      icon: MdBusinessCenter,
      component: Departamento
    },
    {
      path: "/assinaturas",
      name: "Modelos de Assinaturas",
      type: 'link',
      sidebar: {
        nav: "configuracao",
        subnav: true,
      },
      show: true,
      permission: 'permissao_configuracoes',
      icon: VscMailRead,
      component: ModelosAssinatura
    },
    {
      path: "/respostas_prontas",
      name: "Respostas Prontas",
      type: 'link',
      sidebar: {
        nav: "configuracao",
        subnav: true,
      },
      show: true,
      permission: 'permissao_configuracoes',
      icon: MdQuestionAnswer,
      component: RespostasProntas
    },

    //Definir
    /* */
    /* {
      path: "/whatsapp",
      name: "WhatsApp",
      type: 'link',
      sidebar: {
        nav: "configuracao",
        subnav: true,
      },
      show: true,
      permission: 'ver_whatsapp',
      icon: FaWhatsapp,
      component: WhatsApp
    }, */
    /* {
      path: "/telefonia",
      name: "Telefonia",
      type: 'link',
      sidebar: {
        nav: "configuracao",
        subnav: true,
      },
      show: true,
      permission: 'id',
      icon: FaPhone,
      component: Telefonia
    }, */
    /* {
      path: "/ticket",
      name: "Ticket",
      type: 'link',
      sidebar: {
        nav: "configuracao",
        subnav: true,
      },
      show: true,
      permission: 'gerenciar_ticket',
      icon: HiTicket,
      component: Ticket
    }, */
    /* {
      path: "/postagem",
      name: "Postagem",
      type: 'link',
      sidebar: {
        nav: "configuracao",
        subnav: true,
      },
      show: true,
      permission: 'ver_postagem',
      icon: BiPackage,
      component: Postagem
    }, */
    /* {
      path: "/empresa/:id",
      name: "EMP Operadores",
      type: 'link',
      sidebar: {
        nav: "dash_atendimento",
        subnav: true,
      },
      show: false,
      permission: 'permissao_configuracoes',
      icon: MdMessage,
      component: Empresa
    }, */
    /* {
      path: "/movimentacao",
      name: "Movimentação de Contas",
      type: 'link',
      sidebar: {
        nav: "dash_atendimento",
        subnav: true,
      },
      show: true,
      permission: 'ver_movimentacao_contas',
      icon: FaMoneyCheck,
      component: Movimentacao
    } */
  ];
  
  export default dashboardRoutes;
  