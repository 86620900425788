import axios from "axios";
import moment from "moment";
import  React, { Component } from "react";
import { MdArrowBack, MdCheck, MdClear, MdMail, MdWarning, MdAttachFile, MdFileUpload } from "react-icons/md";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardFooter, Col, Nav, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import classnames from 'classnames';
import styled from "styled-components";
import { getUser } from "../../auth/set_services";
import { getRowsPerPage, getRows } from "../../components/Tables/TableQueroBus/propsPassagens";
import TablePassagens from "../../components/Tables/TableQueroBus/TablePassagens";
import PropagateLoader from "react-spinners/PropagateLoader";
import ListaDeOfertas from "./ListaDeOfertas";
import Select from 'react-select';
import TableCidades from "../../components/Tables/TableQueroBus/TableCidades";
import Dropzone from 'react-dropzone';
import aqpasso_icon from '../../assets/aqbank-icon.png';

const getColor = (status) => {
    switch(status){
        case 'DEVOLVIDO' : return 'danger';
        case 'CANCELADA' : return 'dark';
        case 'APROVADA' : return 'success';
        default : return 'info'
    }
}
const ButtonUpload = styled.button`
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0;
    padding-left: 7px;
    padding-right: 7px;
    top: -1px;
    position: relative;
`;

const DropArea = styled.div`
    border-style: dashed;
    border-color: #38B76E;
    padding: 1%;
    cursor: pointer;
    &:drag {
        opacity: 0.5;
    }
`;
const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
        padding: 0.5rem;
        border: 1px solid #ccc;
        margin: 0.2rem 0.3rem;
        border-radius: 5px;
    }
    .nav-tabs .nav-item.show .nav-link, 
    .nav-tabs .nav-link.active {
        background: #28a745;
        color: #fff;
    }
    .nav-link:hover{
        background: #70b580;
        color: #fff;
    }
    .nav-tabs {
        padding-bottom: 1rem;
    }
    p {
        margin-bottom: 0.5rem;
    }
`

const get_id = () => {
    return window.location.pathname.replace('/aqbank-lead/', '')
}

class OfertasQueroBus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            json_response: null,
            activeTab: "1",
            reservas: [],
            cities_origin: [],
            types_options: [
                {value: 'passage', label: 'Passagem'},
                {value: 'origin_destiny', label: 'Origem e Destino'},
            ],
            rowData: [],
            show_activity: false,
            show_filter: false,
            user_change: null,
            type_insert: {value: 'passage', label: 'Passagem'},
            search: '',
            seller_id: '',
            valor_de: '0.00',
            valor_ate: '0.00',
            loading_table: true,
            edit_id: -1,
            edit_user: null,
            cpf_current: null,
            cnpj_current: null,
            phone_current: null,
            status_user: '',
            type_user: '',
            type: { value: 'p', label: 'Porcentagem'},
            limit: { value: '0', label: 'Não'},
            status: null,
            totalPage: 0,
            activePage: 1,
            is_cnpj: false,
            document: '',
            filter: false,
            date_accommodation: null,
            origin: [],
            origin_id: null,
            search_origin: null,
            destiny: [],
            destiny_id: null,
            visible_taggy_add: false,
            visible_user: false,
            image_current: 'https://apicrm.aqbank.com.br/profiles/20/aw2Dxd9JBg28Ig96GF9C.png',
            file: {
                cidade_img: []
            }
        }

        this.onDrop = this.onDrop.bind(this);

        this.inputFileAnexo = React.createRef();
    }
    onDragOver = (event) => {
        event.stopPropagation();
        event.preventDefault();
    }
    onDrop =  (event) => {
        event.preventDefault();
        event.stopPropagation();
        var file = (event.dataTransfer.files[0]);
        if(typeof file !== 'undefined'){
            console.log(btoa(aqpasso_icon));
            this.toBase64(file);
        }
    };
    removeDropAnexoAttach = (index) => {
        var state = this.state;
        state.file.cidade_img.splice(index, 1);
        this.setState({
            ...state
        })
    }
    handleFileAnexoChange = (event) => {
        const files = event.target.files;

        var state = this.state;
        Array.from(files).forEach(file_current => {
            state.file.cidade_img.push(file_current);
        });
        this.setState({
            ...state
        })
    }

    componentDidMount(){
        this.loadCitiesorigem();
        getRows(this.state, this.callbackRows.bind(this));
    }

    loadCitiesorigem = () => {
        let url = `https://apimeubus.aqbank.com.br/api/accomodation/search-city-origin-all`;

        if (this.state.search_origin) {
            url += `?search=${this.state.search_origin}`;
        } 
        

        let myInit = {
            method: 'GET',
            mode: 'cors',
            cache: 'default'
        };
    
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            p.data.map((city, i) => {
                this.state.cities_origin.push({
                    value: city.flag,
                    label: city.city,
                })
            })
        })
        .catch(() => {
          toast.error('Ocorreu um erro no servidor!');
        })
    }

    onFilter = (filter) => {
        this.setState({filter});
        getRows(this.state, this.callbackRows.bind(this));
    }

    callbackRows = (rowData, totalPage) => {
        this.setState({rowData, totalPage, loading_table: false})
    }

    onPageChange = (activePage) => {
        var state = this.state;

        state.activePage = activePage;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});

        getRowsPerPage(activePage, state, this.callbackRows.bind(this));
    }

    handleChange = (event) => {
        var state = this.state;
        let name = event.target.name;
        let value = event.target.value;

        state[name] = value;
        state.activePage = 1;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});
        
        getRowsPerPage(1, state, this.callbackRows);
            
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    onStatusChange(status_user){
        var state = this.state;
        state.status_user = status_user;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});
        
        getRows(state, this.callbackRows);
    }
    filterShow(){
        this.setState({
            show_filter: !this.state.show_filter
        })
    }
    onChangeSelect = (name, value) => {
        var state = this.state;
        state.rowData = [];
        state.activePage = 1;
        state.loading_table = true;
        state[name] = value;
        this.setState({ ...state });

        getRows(state, this.callbackRows.bind(this));
    }

    handleTypeStatusChange = status => {
        var state = this.state;
        state['status'] = status;
        state.loading_table = true;
        this.setState({ ...state });
        getRows(state, this.callbackRows);
    };

    handletoastSuccessMessage = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    handletoastMessage = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    handleNewDealRedirect = (row) => {
        this.props.history.push({ 
            pathname: '/querobus-new-deal', 
            booking: row,
            date_accommodation: this.state.date_accommodation,
            origin: this.state.origin.value,
            destiny: this.state.destiny.value,
        });
    }

    handleNewOriginDestinyRedirect = (row) => {
        this.props.history.push({ 
            pathname: '/querobus-new-destiny', 
            booking: row,
            date_accommodation: this.state.date_accommodation,
            origin: this.state.origin.value,
            destiny: this.state.destiny.value,
        });
    }
    
    listData = [
        { name: 'Consultar Passagens', active: '1' },
        { name: 'Lista de Ofertas', active: '2' },
        { name: 'Lista de Cidades', active: '3' },
    ];

    Navs = () => {
        return <Nav tabs>
            {this.listData.map((p, index) => {
                return <NavLink
                    key={index}
                    className={
                        classnames({ active: this.state.activeTab === p.active })}

                    onClick={() => { 
                        if (p.active == 19) {
                            this.setState({ activeTab: p.active })
                        } else {
                            this.setState({ activeTab: p.active })
                        }
                    }}
                    
                    >
                    <span>{p.name}</span>
                </NavLink>
            })}
        </Nav>
    }
    render(){
        return(
            <Div>
                <h3>Ofertas MeuBus</h3>
                <hr/>
                <Card>
                    <CardBody>
                        <TabContent activeTab={this.state.activeTab}>
                            <this.Navs />
                            <TabPane tabId={'1'}>
                                <Card hidden={!getUser().permissao_gestor_aqpago}>
                                    <TablePassagens
                                        isSearch={this.handleChange}
                                        onFilter={this.onFilter}
                                        onChangeSelect={this.onChangeSelect}
                                        newDeal={this.handleNewDealRedirect.bind(this)}
                                        newOriginDestiny={this.handleNewOriginDestinyRedirect.bind(this)}
                                        filter={this.state.filter}
                                        onSearchDocument={this.onSearchDocument.bind(this)}
                                        data={this.state.rowData}
                                        type_insert={this.state.type_insert}
                                        types_options={this.state.types_options}
                                        cities_origin={this.state.cities_origin}
                                        date_accommodation={this.state.date_accommodation}
                                        origin={this.state.origin}
                                        origin_id={this.state.origin_id}
                                        destiny={this.state.destiny}
                                        destiny_id={this.state.destiny_id}
                                        handleTypeStatusChange={this.handleTypeStatusChange}
                                        status_user={this.state.status_user}
                                        onStatusChange={this.onStatusChange.bind(this)}
                                        type_user={this.state.type_user}
                                        show_filter={this.state.show_filter}
                                        filterShow={this.filterShow.bind(this)}
                                        onPageChange={this.onPageChange}
                                        activePage={this.state.activePage}
                                        state={this.state}
                                        totalPage={this.state.totalPage}
                                        noDataComponent={ this.state.loading_table === true ? 
                                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                                            </div></div></CardBody></Card> :
                                            <Card style={{border: 0}}><CardBody><div>
                                                {this.state.origin_id && this.state.destiny_id && this.state.date_accommodation ?
                                                (<>Nenhuma passagem encontrada</>) : (<>Selecione Origem e Destino com a Data</>)}
                                                
                                            </div></CardBody></Card>
                                        }
                                    />
                                </Card>
                            </TabPane>
                            <TabPane tabId={'2'}>
                                {this.state.activeTab == 2 ? (
                                    <ListaDeOfertas 
                                        history={this.props.history}
                                        />
                                ) : (<></>)}

                            </TabPane>
                            <TabPane tabId={'3'}>
                                <Row style={{marginBottom: '1rem'}}>
                                    <Col md={6}>
                                        <label htmlFor='origin'>Selecione a cidade</label>
                                        <Select
                                            placeholder='Origem de saída'
                                            options={this.state.cities_origin}
                                            isClearable={true}
                                            value={this.state.origin}
                                            onChange={(value) => this.onChangeSelect('origin', value)}
                                            />
                                    </Col>
                                    <Col md={6}>
                                        <Card 
                                            onDragEnter={(e) => {}}
                                            onDragOver={this.onDragOver} 
                                            onDrop={this.onDrop}>
                                            <CardBody style={{height: '200px', alignSelf: 'center'}}>
                                                <img src={this.state.image_current} style={{maxWidth: '100%', maxHeight: '168px'}} alt="profile-admin"/>
                                            </CardBody>
                                            <CardFooter>
                                                <Row>
                                                    <Col></Col>
                                                    <Col>
                                                        
                                                    </Col>
                                                    <Col>
                                                        <Button color="success" onClick={() => this.inputFile.current.click()} ><MdFileUpload style={{padding: 0}}/></Button>
                                                        <input type="file" ref={this.inputFile} hidden multiple onChange={this.handleFileChange} />
                                                    </Col>
                                                    <Col></Col>
                                                </Row>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row> 

                                    <TableCidades
                                        isSearch={this.handleChange}
                                        onFilter={this.onFilter}
                                        onChangeSelect={this.onChangeSelect}
                                        newDeal={this.handleNewDealRedirect.bind(this)}
                                        newOriginDestiny={this.handleNewOriginDestinyRedirect.bind(this)}
                                        filter={this.state.filter}
                                        onSearchDocument={this.onSearchDocument.bind(this)}
                                        data={this.state.rowData}
                                        type_insert={this.state.type_insert}
                                        types_options={this.state.types_options}
                                        cities_origin={this.state.cities_origin}
                                        date_accommodation={this.state.date_accommodation}
                                        origin={this.state.origin}
                                        origin_id={this.state.origin_id}
                                        destiny={this.state.destiny}
                                        destiny_id={this.state.destiny_id}
                                        handleTypeStatusChange={this.handleTypeStatusChange}
                                        status_user={this.state.status_user}
                                        onStatusChange={this.onStatusChange.bind(this)}
                                        type_user={this.state.type_user}
                                        show_filter={this.state.show_filter}
                                        filterShow={this.filterShow.bind(this)}
                                        onPageChange={this.onPageChange}
                                        activePage={this.state.activePage}
                                        state={this.state}
                                        totalPage={this.state.totalPage}
                                        noDataComponent={ this.state.loading_table === true ? 
                                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                                            </div></div></CardBody></Card> :
                                            <Card style={{border: 0}}><CardBody><div>
                                                Nenhuma cidade encontrada
                                            </div></CardBody></Card>
                                        }
                                    />
     

                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </Div>
        )
    }
}

export default OfertasQueroBus;