import axios from 'axios';
import { HiViewGrid } from 'react-icons/hi';
import ReactTooltip from 'react-tooltip';
import { Button, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';

export const columns = () => [
    {
      name: '',
      selector: 'buttons',
      width: '50px',
      cell: row => <Row>
        <Button data-tip="Resumo da Postagem"
          outline
          //disabled={!row.cliente_existe}
          style={{ marginRight: '3px', border: 0}}
          color="primary" onClick={() => 
            {
                window.location.href = '/resumo-postagem/'+row.id
            }
          }>
          <HiViewGrid style={{ padding: '2px', width: '23px' }} />
        </Button>
        <ReactTooltip/>
      </Row>
    },
    {
      name: "Hash do Pedido",
      selector: "hash_produto",
      sortable: false,
    },
    {
      name: "Código de Rastreio",
      selector: "cod_rastreio",
      sortable: false,
    },
    {
      name: "Status de entrega",
      selector: "status_entrega",
      sortable: false
    },
    {
      name: "Serviço de entrega",
      selector: "servico_entrega",
      sortable: false,
      cell: row => row.servico_entrega.toUpperCase()
    }
];

export const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "remessa/list";

    if(state.document.replace(/([^\d])+/gim, '').length > 0){
      if(state.document.replace(/([^\d])+/gim, '').length === 11){
          url +='&cpf='+state.document.replace(/([^\d])+/gim, '');
      }
      else if(state.document.replace(/([^\d])+/gim, '').length === 14){
          url +='&cnpj='+state.document.replace(/([^\d])+/gim, '');
      }
    }

    if(state.servico_entrega !== null){
      url +='&servico_entrega='+state.servico_entrega.value;
    }

    if(state.status_entrega !== null){
      url +='&status_entrega='+state.status_entrega.value;
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.meta.current_page, response.data.meta.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    
    var url = ENDPOINT + "remessa/list?search="+search+"&page="+page;

    if(filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    if(state.document.replace(/([^\d])+/gim, '').length > 0){
      if(state.document.replace(/([^\d])+/gim, '').length === 11){
          url +='&cpf='+state.document.replace(/([^\d])+/gim, '');
      }
      else if(state.document.replace(/([^\d])+/gim, '').length === 14){
          url +='&cnpj='+state.document.replace(/([^\d])+/gim, '');
      }
    }

    if(state.servico_entrega !== null){
      url +='&servico_entrega='+state.servico_entrega.value;
    }

    if(state.status_entrega !== null){
      url +='&status_entrega='+state.status_entrega.value;
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.meta.current_page, response.data.meta.last_page)
    });
}