import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TableCustomized } from "../../../components/Tables/TableCustomized/TableCustomized";
import { Card, CardBody, Row, Button } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";

import NumberFormat from "react-number-format";
import { MdDelete } from "react-icons/md";
import Cookies from "js-cookie";
import { useApiAqmed } from "../../../hooks/useApi";
import { searchWithMask, validateInputAqmed, formatDate } from "../utils";

import { HiViewGrid } from "react-icons/hi";

export function ListCompanys() {
  const [search, setSearch] = useState(null);
  const [links, setLinks] = useState(null);
  const [filter, setFilter] = useState({
    document: null,
    page: null,
  });

  const [isFetching, setIsFecthing] = useState(true);

  const [data, setData] = useState([]);

  const history = useHistory();
  const { apiAqmed } = useApiAqmed();
  useEffect(() => {
    const token = Cookies.get("aqmedtoken");
    let url = "/company";
    if (filter.page) url += `?page=${filter.page}`;
    if (filter.document) url += `?document=${filter.document}`;
    apiAqmed
      .get(url, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res?.data?.data)
        setData(res?.data?.data?.companies);
        setLinks(res?.data?.data?.pagination);
      })
      .catch((error) => {
        alert("Erro ao Bucar Dados");
        console.log(error);
      })
      .finally(() => {
        setIsFecthing(false);
        setSearch(null);
      });
  }, [filter]);

  const onSearchDocument = () => {
    
    const verify = validateInputAqmed(search.document)
    const documentWithoutMask = search.document.replace(/[.\-/]/g, "");

    if (verify?.valid) {
      setSearch({
        document: search.document,
        ...verify,
      });
      setIsFecthing(true);
      setData([]);
      setFilter({ page: null, document: documentWithoutMask });
    } else {
      setSearch({
        document: search.document,
        ...verify,
      });
    }
  };

  const searchOnChange = (event) => {
    searchWithMask(event, setSearch);
  };

  const onChangePage = (event) => {
    setIsFecthing(true);
    setData([]);
    setFilter({ page: Number(event), document: null });
  };

  const handleDeleteCompany = async (id) => {
    const confirmDelete = window.confirm(
      "Tem certeza que deseja remover essa empresa?"
    );
    if (!confirmDelete) {
      return;
    }

    const token = Cookies.get("aqmedtoken");
    await apiAqmed
      .delete(
        `/company/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setIsFecthing(true);
        setData([]);
        setFilter({ document: null, page: null });
      })
      .catch((e) => {
        alert("Erro ao excluir empresa");
      });
  };


  const columns = () => [
    {
      name: "",
      selector: "view",
      sortable: false,
      width: "10%",
      cell: (row) => (
        <Row>
        <Button
          data-tip="Resumo da Empresa"
          outline
          style={{ padding: "0px", marginRight: "3px", marginLeft: "10px", border: 0 }}
          color="primary"
          onClick={() => history.push(`/aqmed/empresa/${row?.public_id}`)}
        >
          <HiViewGrid style={{ padding: "2px", width: "23px" }} />
        </Button>
        
        <Button
         data-tip="Remover Empresa"
         outline
         style={{ padding: "0px", marginRight: "3px", border: 0 }}
         color="danger"
         onClick={() => handleDeleteCompany(row?.public_id)}
       >
         <MdDelete style={{ padding: "2px", width: "23px" }} />
       </Button>
       </Row>
      ),
    },
    {
      name: "Documento",
      selector: "cnpj",
      sortable: false,
      width: "15%",
      cell: (row) => {
        if (row?.document) {
          return (
            <NumberFormat
              displayType={"text"}
              value={row?.document}
              format={
                row?.document.replace(/([^\d])+/gim, "").length > 11
                  ? "##.###.###/####-##"
                  : "###.###.###-##"
              }
            />
          );
        }
        return "S/N";
      },
    },
    {
      name: "Nome",
      selector: "nome",
      sortable: false,
      width: "15%",
      cell: (row) => row?.fantasy_name,
    },
    {
      name: "Razão Social",
      selector: "plan",
      sortable: false,
      width: "35%",
      cell: (row) => <div>{row?.industry}</div>,
    },
    {
      name: "Cadastros",
      selector: "plan",
      sortable: false,
      width: "10%",
      cell: (row) => <div>{row?.collaborators_count}</div>,
    },
    {
      name: "Data de Cadastro",
      selector: "created_at",
      sortable: false,
      width: "15%",
      cell: (row) => <div>{formatDate(row?.created_at)}</div>,
    }
  ];
  return (
    <>
      <h3>Empresas</h3>
      <hr />
      <TableCustomized
        search={search}
        onSearchDocument={onSearchDocument}
        searchOnChange={searchOnChange}
        data={data}
        onPageChange={onChangePage}
        activePage={links?.current_page}
        totalPage={links?.last_page}
        columns={columns}
        noDataComponent={
          isFetching ? (
            <Card style={{ border: 0, padding: "10vh" }}>
              <CardBody>
                <div>
                  <div className="sweet-loading">
                    <PropagateLoader color={"#52c565"} loading={20} size={30} />
                  </div>
                </div>
              </CardBody>
            </Card>
          ) : (
            <Card style={{ border: 0 }}>
              <CardBody>
                <div>Nenhum usuário encontrado</div>
              </CardBody>
            </Card>
          )
        }
      />
    </>
  );
}
