import styled from "styled-components";
import ClipLoader from 'react-spinners/ClipLoader'

const ButtonD = styled.button`
    font-size: 13px;
    border: 2px solid #b10000;
    margin: 2px;
    background-color: #ffffff;
    color: #b10000;
    border-radius: 20px;
    margin-top: 5px;
    
    &:disabled{
        opacity: 0.5;
    }

    &:not(.is_disabled):hover{
        background-color: #b10000;
        color: #ffffff;
    }
    &:focus{
        box-shadow: 0 0 0.3em #b10000;
    }
`

export const ButtonRed = (props) => {
    return(
        <ButtonD
            className={props.disabled ? 'is_disabled' : null}
            disabled={props.disabled}
            type="button"
            {...props}
        >
            {props.disabled ?
            <>
                <ClipLoader color="#b10000" size={12}/>
                &nbsp;
            </>
            : null}{props.children}
        </ButtonD>
    )

}