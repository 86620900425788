import axios from "axios";
import moment from "moment";
import { Component } from "react";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Collapse, Nav, NavLink, Row, TabContent, TabPane, Input } from "reactstrap";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import classnames from 'classnames';
import styled from "styled-components";
import EmailClient from "../../components/ResumeClient/EmailClient";
import SmsClient from "../../components/ResumeClient/SmsClient";
import ChatsClient from "../../components/ResumeClient/ChatsClient";
import NotasAtendimento from "../../components/ChatClient/NotasAtendimento";
import ProtocoloClient from "../../components/ResumeClient/ProtocoloClient";
import Select from "react-select";
import NotasMeuBus from "../../components/ChatClient/NotasMeubus";
import HistoricoQueroBus from "../QueroBus/HistoricoQueroBus"
import EnderecoCadastroMeuBus from '../../components/Modals/EnderecoCadastro/EnderecoCadastroMeuBus';



import { getUser } from "../../auth/set_services";
import { getRowsPerPage, getRows } from "../../components/Tables/TableUsers/propsReservas";
import TableReservas from "../../components/Tables/TableUsers/TableReservas";
import PropagateLoader from "react-spinners/PropagateLoader";
import BloquearUsuario from "../../components/Modals/Meubus/BloquearUsuario";
import HistoricoAtividadesQueroBus from "../QueroBus/HistoricoAtividadesQueroBus";

const getColor = (status) => {
    switch(status){
        case 'DEVOLVIDO' : return 'danger';
        case 'CANCELADA' : return 'dark';
        case 'APROVADA' : return 'success';
        default : return 'info'
    }
}
const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
        padding: 0.5rem;
        border: 1px solid #ccc;
        margin: 0.2rem 0.3rem;
        border-radius: 5px;
    }
    .nav-tabs .nav-item.show .nav-link, 
    .nav-tabs .nav-link.active {
        background: #28a745;
        color: #fff;
    }
    .nav-link:hover{
        background: #70b580;
        color: #fff;
    }
    .nav-tabs {
        padding-bottom: 1rem;
    }
`

const get_id = () => {
    return window.location.pathname.replace('/querobus-user/', '')
}
const getUserData = (id, callback) => {
    var url = ENDPOINT+"querobus/users/get/"+id;
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        callback(data);
    })
}


class ResumoClienteQueroBus extends Component{
    constructor(props){
        super(props);
        this.state = {
            json_response: null,
            activeTab: "1",
            fraud: false,
            select_fraud: null,
            open_block: false,
            block_note: '',
            block_invalid: false,
            reservas: [],
            user_id: null,
            show_activity: false,
            show_filter: false,
            user_change: null,
            search: '',
            seller_id: '',
            valor_de: '0.00',
            valor_ate: '0.00',
            loading_table: true,
            edit_id: -1,
            edit_user: null,
            rowData: [],
            cpf_current: null,
            cnpj_current: null,
            phone_current: null,
            status_user: '',
            type_user: '',
            status: null,
            totalPage: 0,
            activePage: 1,
            is_cnpj: false,
            document: '',
            filter: false,
            mail_edit_enable: false,
            phone_edit_enable: false,
            visible_address: false,
            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            visible_taggy_add: false,
            visible_user: false,
            user_id_block: null,
            user_block: false,
        }
    }

    componentDidMount(){
        getUserData(get_id(), this.callbackUser)
    }

    callbackUser = (data) => {
        this.setState({ json_response: data, user_id: data.id })

        if (data.fraud) {
            this.setState({select_fraud: {label: 'Ativo', value: true}})
        } else {
            this.setState({select_fraud: {label: 'Inativo', value: false}})
        }

        getRows(this.state, this.callbackRows.bind(this))

        /* if(data.success == true){
            toast.success(data.message)
            this.setState({ json_response: data.data, user_id: data.id })
        }
        else{
            toast.success(data.errors)
        } */
    }

    confirmBlock = () => {
        this.setState({open_block: !this.state.open_block})
    }

    listData = [
        { name: 'Dados do Cliente', active: '1' },
        { name: 'Histórico de Alterações', active: '2' },
        { name: 'E-mails', active: '3' },
        { name: 'SMS', active: '4' },
        { name: 'Chats', active: '5' },
        { name: 'Notas', active: '6' },
        { name: 'Protocolos ', active: '7' },
        { name: 'Reservas', active: '8' },
        { name: 'Termos de Uso', active: '9' },
        { name: 'Histórico de Sessão', active: '10' },
    ];

    desbloquearUsuario = (id) => {
        var url = ENDPOINT+"querobus/user/"+id;

        if (!this.state.block_note) {
            this.setState({block_invalid: true})
            toast.error('Digite o motivo do desbloqueio!');
            return;
        }

        axios.put(url, {
                fraud: this.state.fraud,
                block_note: this.state.block_note,
            }, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
          })
        .then((response) => {
            toast.success('Desbloqueado com sucesso!');
            this.setState({open_block: false})
            getUserData(get_id(), this.callbackUser)
        })
        .catch(error => {
            var data = error.response.data;
            toast.error(data.errors);
        })
    }

    bloquearUsuario = (id) => {
        var url = ENDPOINT+"querobus/user/"+id;

        if (!this.state.block_note) {
            this.setState({block_invalid: true})
            toast.error('Digite o motivo do bloqueio!');
            return;
        }

        axios.delete(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            },
            data: {
                fraud: this.state.fraud,
                block_note: this.state.block_note,
            }
          })
        .then((response) => {
            toast.success('Bloqueado com sucesso!');
            this.setState({open_block: false})
            getUserData(get_id(), this.callbackUser)
        })
        .catch(error => {
            var data = error.response.data;
            toast.error(data.errors);
        })
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }

    onFilter = (filter) => {
        this.setState({filter});
        getRows(this.state, this.callbackRows.bind(this));
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }

    handleTypeStatusChange = status => {
        var state = this.state;
        state['status'] = status;
        state.loading_table = true;
        this.setState({ ...state });
        getRows(state, this.callbackRows);
    }
    onStatusChange(status_user){
        var state = this.state;
        state.status_user = status_user;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});
        
        getRows(state, this.callbackRows);
    }
    filterShow(){
        this.setState({
            show_filter: !this.state.show_filter
        })
    }
    callbackRows = (rowData, totalPage) => {
        this.setState({rowData, totalPage, loading_table: false})
    }

    onPageChange = (activePage) => {
        var state = this.state;

        state.activePage = activePage;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});

        getRowsPerPage(activePage, state, this.callbackRows.bind(this));
    }

    handleOpenBlockUser = (user) => {
        this.setState({
            user_id_block: user.id,
            user_block: true,
        })
    }
    handleChange = (event) => {
        var state = this.state;
        let name = event.target.name;
        let value = event.target.value;

        state[name] = value;
        state.activePage = 1;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});
        
        getRowsPerPage(1, state, this.callbackRows);
            
    }
    Navs = () => {
        return <Nav tabs>
            {this.listData.map((p, index) => {
                return <NavLink
                    key={index}
                    className={
                        classnames({ active: this.state.activeTab === p.active })}

                    onClick={() => { 
                        if (p.active == 19) {
                            this.setState({ activeTab: p.active })
                        } else {
                            this.setState({ activeTab: p.active })
                        }
                    }}
                    
                    >
                    <span>{p.name}</span>
                </NavLink>
            })}
        </Nav>
    }
    
    onPenEditMail = () => {
        this.setState({
            mail_edit_enable: true,
        })
    }

    onCloseEditMail = () => {
        this.setState({mail_edit_enable: false});
    }

    sendEditEmail = () => {
        let url = `${ENDPOINT}querobus/user_email/${this.state.json_response.id}`;

        axios.put(url, {
            email: this.state.json_response.email
        }, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            this.onCloseEditMail()
            toast.success('E-mail alterado com sucesso!');
        })
        .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              //console.log(error.response.data);
              toast.error(error.response.data.errors);
              //console.log(error.response.status); // status code
              //console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              toast.error('Falha ao atualizar');
              // http.ClientRequest in node.js
              //console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              toast.error(error.message);
              //console.log('Error', error.message);
            }
            // console.log(error.config);
        })
    }

    sendEditPhone = () => {
        let url = `${ENDPOINT}querobus/user_phone/${this.state.json_response.id}`;

        axios.put(url, {
            phone: this.state.json_response.phone
        }, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            this.onCloseEditPhone()
            toast.success('Telefone alterado com sucesso!');
        })
        .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              //console.log(error.response.data);
              toast.error(error.response.data.errors);
              //console.log(error.response.status); // status code
              //console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              toast.error('Falha ao atualizar');
              // http.ClientRequest in node.js
              //console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              toast.error(error.message);
              //console.log('Error', error.message);
            }
            // console.log(error.config);
        })
    }

    onPenEditPhone = () => {
        this.setState({
            phone_edit_enable: true,
        })
    }

    onCloseEditPhone = () => {
        this.setState({phone_edit_enable: false});
    }

    onCloseAddress = () => {
        this.setState({visible_address: false});
    }

    onOpenAddress = () => {
        this.setState({visible_address: true});
    }

    onRefreshAddress = () => {
        getUserData(get_id(), this.callbackUser)
    }

    onChangeValEdit = (key, value) => {
        let state = this.state;
        state.json_response[key] = value;

        this.setState({json_response: state.json_response});
    }

    render(){
        return(
            <Div>
                <h3>Resumo do cliente MeuBus</h3>
                <hr/>
                <Card>
                    <CardBody>
                        <TabContent activeTab={this.state.activeTab}>
                            <this.Navs />
                            <TabPane tabId={'1'}>
                                <br/>
                                {this.state.json_response !== null ?
                                    <div>
                                        <Row style={{marginBottom: '1rem'}}>
                                            <Col md={12}>
                                            {this.state.json_response.status == 'blocked' ? (<h3 style={{margin: 0,color: 'red'}}>Bloqueado</h3>) : (<></>)}
                                            </Col>
                                            <Col md={12}>
                                                {this.state.json_response.status == 'blocked' ? (
                                                    <Button 
                                                        color={this.state.open_block ? 'danger' : 'success'} 
                                                        style={{float: 'right',position: 'relative',top: '-5px'}}
                                                        disabled={this.state.cancelar_loading} 
                                                        onClick={() => this.confirmBlock()}>
                                                            {this.state.open_block ? 'Cancelar' : 'Liberar Usuário'} 
                                                    </Button>
                                                ) : (
                                                    <Button 
                                                        color="danger" 
                                                        style={{float: 'right',position: 'relative',top: '-5px'}}
                                                        disabled={this.state.cancelar_loading} 
                                                        onClick={() => this.confirmBlock()}>
                                                            {this.state.open_block ? 'Cancelar' : 'Bloquear Usuário'} 
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                        <Collapse isOpen={this.state.open_block}>

                                            <Row style={{marginBottom: '1rem'}}>
                                                <Col md={6}>
                                                    <label htmlFor="conta_banco">Bloqueio por Fraude ?</label>
                                                    <Select
                                                        name="fraud"
                                                        placeholder="Selecione..."
                                                        options={[
                                                            {label: 'Ativo', value: true},
                                                            {label: 'Inativo', value: false},
                                                        ]}
                                                        onChange={(opt) => {
                                                            this.setState({select_fraud: opt})
                                                            this.setState({fraud: opt.value})
                                                        }}
                                                        value={this.state.select_fraud}
                                                            />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <label htmlFor="notas_bloqueio">Descrição</label>
                                                    <Input
                                                        type="textarea"
                                                        placeholder="Descreva o motivo"
                                                        invalid={this.state.block_invalid}
                                                        value={this.state.block_note}
                                                        onChange={(e) => this.setState({block_note: e.target.value})}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                <label>&nbsp;</label>
                                                <Button 
                                                    color="success" 
                                                    style={{float: 'right',position: 'relative',top: '15px'}}
                                                    disabled={this.state.cancelar_loading} 
                                                    //hidden={!(this.state.json_response !== null ? (this.state.json_response.status_booking == 'APROVADA' ? true : false) : false)}
                                                    onClick={() => {
                                                        if (this.state.json_response.status == 'blocked') {
                                                            this.desbloquearUsuario(this.state.json_response.id)
                                                        } else {
                                                            this.bloquearUsuario(this.state.json_response.id)
                                                        }
                                                    }}>
                                                        {this.state.json_response.status == 'blocked' ? 'Confirmar Liberação' : 'Confirmar Bloqueio'}
                                                </Button>
                                                </Col>
                                            </Row>

                                        </Collapse>

                                        <p><b>Nome: </b> {this.state.json_response.social_name != null ? this.state.json_response.social_name + ' | ' + this.state.json_response.name : this.state.json_response.name}</p>
                                            

                                            {this.state.mail_edit_enable !== true ?
                                                <>
                                                    <p><b>E-mail: </b> {this.state.json_response.email}&nbsp;<Button outline onClick={() => this.onPenEditMail()} color='success' style={{padding: '3px', fontSize: '12px'}}>Editar</Button></p>
                                                </>
                                                :
                                                <>
                                                    <p><b>E-mail: </b>&nbsp;
                                                    <Input 
                                                        name="telefone_edit"
                                                        placeholder="Telefone"
                                                        value={this.state.json_response.email}
                                                        onChange={(event) => this.onChangeValEdit('email', event.target.value)}
                                                        style={{maxWidth: '300px', width: '100%'}}
                                                        /><br/>
                                                        <Button color='success' onClick={() => this.sendEditEmail()} style={{marginLeft: 2, marginRight: 2, padding: 2, fontSize: '12px'}}>Salvar</Button>
                                                        <Button color='danger' onClick={() => this.onCloseEditMail()} style={{marginLeft: 2, marginRight: 2, padding: 2, fontSize: '12px'}}>Cancelar</Button>
                                                    </p>
                                                </>
                                            }
                                            
                                            {this.state.phone_edit_enable !== true ?
                                                <>
                                                    <p><b>Telefone: </b>&nbsp;
                                                    <NumberFormat displayType={'text'} value={this.state.json_response.phone} format="(##) #####-####" />&nbsp;
                                                    <Button outline onClick={() => this.onPenEditPhone()} color='success' style={{padding: '3px', fontSize: '12px'}}>Editar</Button>
                                                    </p>
                                                </>
                                                :
                                                <>
                                                    <p><b>Telefone: </b>&nbsp;<br/>
                                                        <NumberFormat 
                                                            displayType={'input'} 
                                                            className="form-control"
                                                            value={this.state.json_response.phone} 
                                                            format="(##) #####-####" 
                                                            onChange={(event) => this.onChangeValEdit('phone', event.target.value)}
                                                            style={{maxWidth: '200px', width: '100%'}}
                                                            /><br/>
                                                        <Button color='success' onClick={() => this.sendEditPhone()} style={{marginLeft: 2, marginRight: 2, padding: 2, fontSize: '12px'}}>Salvar</Button>
                                                        <Button color='danger' onClick={() => this.onCloseEditPhone()} style={{marginLeft: 2, marginRight: 2, padding: 2, fontSize: '12px'}}>Cancelar</Button>
                                                    </p>
                                                </>
                                            }

                                            <p><b>Documento (CPF/CNPJ): </b> {this.state.json_response.cnpj != null ? this.state.json_response.cnpj : this.state.json_response.cpf}</p>
                                            <p><b>Data de nascimento: </b> {moment(new Date(this.state.json_response.birthdate).toDateString()).format('DD/MM/YYYY')}</p>

                                            <p>
                                                <b>Endereço: </b>{this.state.json_response.address.street_address} {this.state.json_response.address.number_address} - {this.state.json_response.address.neighborhood_address}, {this.state.json_response.address.city}/{this.state.json_response.address.state} - {this.state.json_response.address.zipcode}&nbsp;
                                                <Button outline color='success' style={{padding: '3px', fontSize: '12px'}} onClick={() => this.onOpenAddress()}>Editar</Button>
                                            </p>

                                            
                                            <EnderecoCadastroMeuBus
                                                user_id={this.state.json_response ? this.state.json_response.id : null}
                                                address={this.state.json_response?.address ? this.state.json_response.address : null}
                                                visible={this.state.visible_address}
                                                onClose={this.onCloseAddress}
                                                onRefresh={this.onRefreshAddress}
                                            />
                                    </div>
                                :
                                null}

                            </TabPane>
                            <HistoricoAtividadesQueroBus
                                active={'2'}
                                name={this.state.json_response ? (this.state.json_response.social_name != null ? this.state.json_response.social_name + ' | ' + this.state.json_response.name : this.state.json_response.name) : null}
                                user_id={this.state.json_response ? this.state.json_response.id : null}
                                communication_relacao_id={this.state.json_response ? this.state.json_response.communication_relacao_id : null}
                            />
                            <EmailClient
                                active={'3'}
                                name={this.state.json_response ? (this.state.json_response.social_name != null ? this.state.json_response.social_name + ' | ' + this.state.json_response.name : this.state.json_response.name) : null}
                                email={this.state.json_response ? this.state.json_response.email : null}
                                communication_relacao_id={this.state.json_response ? this.state.json_response.communication_relacao_id : null}
                                send_client_meubus={true}
                            />
                            <SmsClient
                                active={'4'}
                                name={this.state.json_response ? (this.state.json_response.social_name != null ? this.state.json_response.social_name + ' | ' + this.state.json_response.name : this.state.json_response.name) : null}
                                telefone={this.state.json_response ? this.state.json_response.phone : null}
                                communication_relacao_id={this.state.json_response ? this.state.json_response.communication_relacao_id : null}
                            />
                            <ChatsClient 
                                active={'5'}
                                name={this.state.json_response ? (this.state.json_response.social_name != null ? this.state.json_response.social_name + ' | ' + this.state.json_response.name : this.state.json_response.name) : null}
                                communication_relacao_id={this.state.json_response ? this.state.json_response.communication_relacao_id : null}
                            />
                            <NotasMeuBus
                                active={'6'}
                                name={this.state.json_response ? (this.state.json_response.social_name != null ? this.state.json_response.social_name + ' | ' + this.state.json_response.name : this.state.json_response.name) : null}
                                user_id={this.state.json_response ? this.state.json_response.id : null}
                                communication_relacao_id={this.state.json_response ? this.state.json_response.communication_relacao_id : null}
                            />
                            {this.state.json_response?.cpf ? (
                                <ProtocoloClient
                                    active={'7'}
                                    document={this.state.json_response?.cpf}
                                    name={this.state.json_response ? (this.state.json_response.social_name != null ? this.state.json_response.social_name + ' | ' + this.state.json_response.name : this.state.json_response.name) : null}
                                    communication_relacao_id={this.state.json_response ? this.state.json_response.communication_relacao_id : null}
                                />
                            ) : (<></>)}
                            {this.state.json_response?.cnpj ? (
                                <ProtocoloClient
                                    active={'7'}
                                    document={this.state.json_response?.cnpj}
                                    name={this.state.json_response ? (this.state.json_response.social_name != null ? this.state.json_response.social_name + ' | ' + this.state.json_response.name : this.state.json_response.name) : null}
                                    communication_relacao_id={this.state.json_response ? this.state.json_response.communication_relacao_id : null}
                                />
                            ) : (<></>)}

                            <TabPane tabId={'8'}>
                                <h3>Passagens MeuBus</h3>
                                <hr/>
                                <Card hidden={!getUser().permissao_gestor_aqpago}>
                                    <TableReservas
                                        isSearch={this.handleChange}
                                        onFilter={this.onFilter}
                                        filter={this.state.filter}
                                        onSearchDocument={this.onSearchDocument.bind(this)}
                                        data={this.state.rowData}
                                        is_cnpj={this.state.is_cnpj}
                                        handleTypeStatusChange={this.handleTypeStatusChange}
                                        status_user={this.state.status_user}
                                        onStatusChange={this.onStatusChange.bind(this)}
                                        type_user={this.state.type_user}
                                        show_filter={this.state.show_filter}
                                        filterShow={this.filterShow.bind(this)}
                                        onPageChange={this.onPageChange}
                                        handleOpenBlockUser={this.handleOpenBlockUser.bind(this)}
                                        activePage={this.state.activePage}
                                        state={this.state}
                                        totalPage={this.state.totalPage}
                                        noDataComponent={ this.state.loading_table === true ? 
                                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                                            </div></div></CardBody></Card> :
                                            <Card style={{border: 0}}><CardBody><div>
                                                Nenhum usuário encontrado
                                            </div></CardBody></Card>
                                        }
                                    />
                                </Card>

                                <BloquearUsuario 
                                    visible={this.state.user_block}
                                    title_nota={'Bloquear Usuário'}
                                    user_id={this.state.user_id_block}
                                    onClose={this.onClose}
                                    />
                                
                                {/* {this.state.json_response ? 
                                <div>
                                    {this.state.json_response.bookings.map(e => (
                                        <Card body color={getColor(e.status_booking)} style={{margin: 15, borderRadius: 15, color: getColor(e.status_booking) != 'success' ? 'white' : 'black' }}>
                                            <CardBody>
                                                <b>Status da Reserva: </b>{e.status_booking}<br/>
                                                <b>Status do Pagamento: </b>{e.status_payment}<br/>
                                                <b>Valor: </b>{parseFloat(e.toll_price)}<br/>
                                                <b>Data da Reserva: </b>{moment(e.booking_date).format('DD/MM/YYYY')}<br/>
                                                <b>Assentos:</b><br/>
                                                <Row>
                                                    {e.seats.map(es => (
                                                        <Col md={6} style={{ border: '1px solid', borderRadius: '10px', padding: '12px' }}>
                                                            <b>Assento:</b> {es.seat}<br/>
                                                            <b>ID da Transação:</b> {es.booking_id.toUpperCase()}<br/>
                                                            <b>Nome do Passageiro:</b> {es.name_client}<br/>
                                                            <b>CPF do Passageiro:</b> {es.document}<br/>
                                                            <b>Valor da Passagem:</b> {es.total_price_with_insurance}<br/>
                                                            {es.boleto_url !== null ?
                                                                <b><a target="_blank" style={{color: getColor(e.status_booking) != 'success' ? 'white' : 'black'}} href={es.boleto_url}>Ver passagem</a></b>
                                                            : null}
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    ))}
                                    {this.state.json_response.bookings.length == 0 ?
                                        <b>Nenhuma Reserva encontrada</b>
                                    :null}
                                </div>
                                 : 
                                 null} */}

                            </TabPane>
                            <TabPane tabId={'9'}>
                                {this.state.json_response != null?
                                    <div className="box-div-page" style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                        <h3 style={{marginBottom: '2rem', fontSize: '24px', textAlign: 'center'}}>Termo de Aceite do Contrato ao Sistema de QueroBus</h3>
            
                                        <p><b>ID Aceite:</b> {this.state.json_response?.terms?.id}</p>
                                        <p><b>Código Hash Digital de Assinatura do Aceite ID:</b> {this.state.json_response?.terms?.device_id}</p>
                                        <p><b>Nome do Cliente/Estabelecimento Credenciamento:</b> {this.state.json_response?.terms?.name}</p>
                                        <p><b>CPF/CNPJ:</b>&nbsp;
                                            {this.state.json_response.terms !== null && this.state.json_response.terms !== undefined ?
                                                <NumberFormat displayType={'text'} value={this.state.json_response.terms.document} format={this.state.json_response.terms.document.length > 11 ? '##.###.###/####-##' : "###.###.###-##"}/>
                                                :
                                                ''
                                            }
                                        </p>
                                        <p><b>Tipo de Dispositivo Usado:</b> {this.state.json_response?.terms?.type_device}</p>
                                        <p><b>Sistema Operacional do Dispositivo:</b>{this.state.json_response?.terms?.operating_system}</p>
                                        <p><b>Endereço IP de Internet:</b> {this.state.json_response?.terms?.ip}</p>
                                        <p><b>Latitude de Geolocalização:</b> {this.state.json_response?.terms?.latitude}</p>
                                        <p><b>Longitude Geolocalização:</b> {this.state.json_response?.terms?.longitude}</p>
                                        <p><b>Data do Aceite:</b>&nbsp;
                                            {this.state.json_response.terms.accept_date !== null ?
                                                moment(this.state.json_response.terms.accept_date).format('DD/MM/YYYY')
                                            :
                                                ''
                                            }
                                        </p>
                                        <p><b>Hora do Aceite:</b>&nbsp;
                                            {this.state.json_response.terms.accept_date !== null ?
                                                moment(this.state.json_response.terms.accept_date).format('HH:mm')
                                            :
                                                ''
                                            }
                                        </p>
                                        <p><b>Carimbo de Data/Hora:</b>&nbsp;
                                            {this.state.json_response.terms.accept_date !== null ?
                                                moment(this.state.json_response.terms.accept_date).format('DD/MM/YYYY HH:mm:ss')
                                            :
                                                ''
                                            }
                                        </p>
            
                                    </div>
                                :
                                null}
                            </TabPane>
                            <HistoricoQueroBus
                                active={'10'}
                                name={this.state.json_response ? (this.state.json_response.social_name != null ? this.state.json_response.social_name + ' | ' + this.state.json_response.name : this.state.json_response.name) : null}
                                user_id={this.state.json_response ? this.state.json_response.id : null}
                                communication_relacao_id={this.state.json_response ? this.state.json_response.communication_relacao_id : null}
                            />
                        </TabContent>
                    </CardBody>
                </Card>
            </Div>
        )
    }
}

export default ResumoClienteQueroBus;