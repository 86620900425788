import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import Moment from 'moment';
import { Button } from 'reactstrap';
import ecommerce_logo from '../../../assets/ecommerce-aqpago.svg'
import { toast } from 'react-toastify';

const maquininhas_list = [
  {
      modelo: 'MP5',
      label: 'Maquininha MP5',
      value: 'MP5',
      valor: 199.00,
      image: 'https://aqpago.com.br/assets/img/maquinas/mp5.webp'
  },
  {
      modelo: 'D195',
      label: 'Maquininha D195',
      value: 'D195',
      valor: 399.00,
      image: 'https://aqpago.com.br/assets/img/maquinas/d195.webp'
  },
  {
      modelo: 'MP35P',
      label: 'Maquininha MP35P',
      value: 'MP35P',
      valor: 999.00,
      image: 'https://aqpago.com.br/assets/img/maquinas/d195.webp'
  },
  {
      modelo: 'S920',
      label: 'Maquininha S920',
      value: 'S920',
      valor: 599.00,
      image: 'https://aqpago.com.br/assets/img/maquinas/s920.webp'
  },
  {
      modelo: 'linkpay',
      label: 'Link de Pagamento',
      value: 'linkpay',
      valor: 99.00,
      image: 'https://aqpago.com.br/assets/img/home/melhor-escolha/link-pagamento/link-pagamento.svg'
  },
  {
      modelo: 'shopaqpago',
      label: 'E-commerce',
      value: 'shopaqpago',
      valor: 99.00,
      image: ecommerce_logo
  },
  {
      modelo: 'integração pagamento',
      label: 'Integração de Pagamento',
      value: 'integração pagamento',
      valor: 0.00,
  }
];

const forma_pagamento = [
    { value: "pix", label: "PIX" },
    { value: "boleto", label: "Boleto" },
    { value: "credit", label: "Crédito" },
    { value: "none", label: "Pagamento não necessario" },
];

const check_eccomerce = (produtos) => {
  var existe_loja = false;

  produtos.forEach(p => {
    if(p.produto.toLowerCase() == 'shopaqpago'){
      existe_loja = true;
    }
  });

  return existe_loja;

}

const reenviar_link_eccommerce = (venda_id) => {
  var url = ENDPOINT+"loja/reenviar_link_loja/"+venda_id;
  
  var formData = new FormData();
  axios.post(url, formData, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }
  })
  .then((response) => {
      var data = response.data;
      toast.success(data.message);
      return data;
  })
  .catch((error) => {
      var data = error.response.data;
      toast.error(data.errors);
  });

}
export const columns = () => [
    {
      name: "Data",
      selector: "created_at",
      sortable: false,
      width: '150px',
      cell: p => Moment(p.created_at).format('DD/MM/YYYY HH:mm')
    },
    {
      name: "Transação",
      selector: "id_transacao",
      sortable: false,
      width: '270px',
      cell: p => <div style={{margin: 5, width: '100%'}}>
                    {p.id_transacao}<br/>
                    <center>
                      {p.user !== null ?
                          p.user.nome + ' '+p.user.sobrenome
                      :null}
                    </center>
                    <hr style={{margin: 5}}/>
                    <center>
                        <Button 
                            style={{padding: 5, margin: 5, fontSize: '13px'}} 
                            color="info"
                            outline
                            hidden={p.valor < 0.01 && p?.produto?.produto != 'LINKPAY'}
                            onClick={() => 
                                {
                                    window.open('/venda/'+p.id, '_blank')
                                }
                            }
                            >Ver transação</Button>
                    {p.user !== null && (p.user.user_tipo == 'chat_pending_titular' || p.user.user_tipo == 'chat_pending_responsavel') ?
                          <Button 
                              style={{padding: 5, margin: 5, fontSize: '13px'}} 
                              color="success"
                              outline
                              onClick={() => 
                                  {
                                      window.location.href = '/user-pre-venda/'+p.user.id
                                  }
                              }
                              >Credenciar</Button>
                    
                    :null}
                    {p.lojas == null && check_eccomerce(p.produtos) == true ?
                      <Button
                        style={{padding: 5, margin: 5, fontSize: '13px'}} 
                        color="dark"
                        onClick={() => reenviar_link_eccommerce(p.id)}
                        outline>Re-enviar link para registro de dominio</Button>
                    : null}
                    </center>
                </div>
    },
    {
      name: "Valor",
      selector: "condicao",
      sortable: false,
      width: '130px',
      cell: p => 'R$ ' + parseFloat(p.valor).toFixed(2).replace('.', ',')
    },
    {
      name: "Status Pagamento",
      selector: "status",
      sortable: false,
      width: '230px',
      cell: p => <div style={{margin: 5}}>
                      <b>Pagamento:</b> {p.status}
                  </div>
    },
    {
      name: "Status do Pedido",
      selector: "status",
      sortable: false,
      width: '230px',
      cell: p => <div style={{margin: 5}}>
                      {p.status_pedido !== null ? 
                          <>{getStatus(p.status_pedido)}</>
                      :
                      <>Aguardando Alteração</>}
                      {p.produto !== null ? 
                        (p.produto.remessa !== null ?
                          <><br/><b>Status da entrega:</b> {p.produto.remessa.status_entrega}</>
                          : 
                          null)
                      : null}
                  </div>
    },
    {
      name: "Pagamento",
      selector: "forma_pagamento",
      sortable: false,
      cell: p => forma_pagamento.map(e => {
          if(e.value == p.forma_pagamento){
              return e.label;
          }
      })
    },
    {
      name: "Produtos Adquiridos",
      selector: "produtos",
      sortable: false,
      cell: p => <ul style={{padding:0, margin: 0}}>
                    {getProdutos(p.produtos)}
      </ul>
    },
];

const pedido_list = [
  { value: "aprovado", label: "Pedido aprovado" },
  { value: "reprovado", label: "Pedido reprovado" },
  { value: "cancelado", label: "Pedido cancelado" },
  { value: "estornado", label: "Pedido estornado" },
  { value: "chargeback", label: "Pedido chargeback" },
  { value: "entrega", label: "Aguardando entrega" },
  { value: "enviado", label: "Pedido enviado" },
  { value: "entregue", label: "Pedido entregue" },
  { value: "devolvido", label: "Pedido devolvido" },
  { value: "cliente", label: "Aguardando cliente" },
  { value: "analise", label: "Pedido em análise" },
  { value: "descredenciado", label: "Descredenciado" },
];

const getStatus = (status_pedido) => {
    var status = null;
    pedido_list.forEach(e => {
        if(e.value == status_pedido){
            status = e.label;
        }
    });

    if (status == null) {
      status = status_pedido.toUpperCase();
    }

    return status;
}

const getProdutos = (produtos_is) => {
  
  var produtos = [];
  const exist_array = (value, array) => {
      var existe = false;
      array.forEach(e => {
        if(value == e.produto){
          existe = true;
        }
      });
      return existe;
  }

  const index_array = (value, array) => {
      var index = 0;
      array.forEach((e, i) => {
        if(value == e.produto){
          index = i;
        }
      });
      return index;
  }

  produtos_is.forEach((e, i) => {
      var produto = '';
        maquininhas_list.forEach(m => {
          if(m.modelo.toUpperCase() == e.produto.toUpperCase()){
            produto = m.label;
          }
        });

        if(exist_array(produto, produtos) == false){
            produtos.push({
              quantidade: 1,
              produto: produto
            })
        }
        else{
          produtos[index_array(produto, produtos)].quantidade = produtos[index_array(produto, produtos)].quantidade + 1
        }
    });

    return produtos.map(e => {
      return <li>{e.quantidade}x - {e.produto}</li>;
    })
}

export const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "maquininhas/list?search="+state.search+"&page="+state.activePage+"&pendente="+state.pending;

    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&documento="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&documento="+document;
        }
    }

    if(state.modelo_maquininha !== null){
      url += "&maquininha="+state.modelo_maquininha.value
    }

    if(state.pedido !== null){
      url += "&pedido="+state.pedido.value
    }
    
    if(state.forma_pagamento !== null){
      url += "&forma_pagamento="+state.forma_pagamento.value
    }

    if(state.status !== null){
      url += "&status="+state.status.value
    }
    
    if(state.status_entrega !== null){
      url += "&status_entrega="+state.status_entrega.value
    }

    if(state.filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}


export const getRelatorio = (callback) => {
  var url = ENDPOINT + "vendas/relatorio";
  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      console.log(p)
      callback(p);
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    
    var url = ENDPOINT + "maquininhas/list?search="+search+"&page="+page+"&pendente="+state.pending;
    console.log(state)
    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&documento="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&documento="+document;
        }
    }


    if(state.modelo_maquininha !== null){
      url += "&maquininha="+state.modelo_maquininha.value
    }
    if(state.pedido !== null){
      url += "&pedido="+state.pedido.value
    }
    if(state.forma_pagamento !== null){
      url += "&forma_pagamento="+state.forma_pagamento.value
    }
    if(state.status !== null){
      url += "&status="+state.status.value
    }
    if(state.status_entrega !== null){
      url += "&status_entrega="+state.status_entrega.value
    }
    if(filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}