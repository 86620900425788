import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai"

export const DropdownIcon = (props) => {
    return(
        <>
            <AiFillCaretDown hidden={props.dropdown} style={{padding: 0}}/>
            <AiFillCaretUp hidden={!props.dropdown} style={{padding: 0}}/>
        
        </>
    )

}