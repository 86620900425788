import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import { MdAlarm, MdClear, MdSend } from 'react-icons/md';
import { Button, ButtonGroup, Row } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { HiViewGrid } from 'react-icons/hi';

const status_cadastro = (status) => {
    if (status == 'chat_pending_titular' || status == 'chat_pending_responsavel'){
        return "Aguardando Credenciamento";
    }
    if (status == 'titular' || status == 'responsavel'){
        return "Credenciado";
    }

    return status;
}

function formatPhoneNumber(phoneNumber) {
    // Remove todos os caracteres que não são dígitos
    const digits = phoneNumber.replace(/\D/g, '');

    // Verifica se o número tem a quantidade esperada de dígitos (11 para o Brasil)
    if (digits.length === 11) {
        // Aplica o formato (99) 99999-9999
        return `(${digits.substring(0, 2)}) ${digits.substring(2, 7)}-${digits.substring(7)}`;
    }

    // Retorna o número original se não tiver o tamanho esperado
    return phoneNumber;
}

export const columns = () => [
    {
      name: '',
      selector: 'buttons',
      width: '80px',
      cell: row => <Row>
        <Button data-tip="Informações"
          outline
          style={{ marginLeft: '10px',marginRight: '3px', padding: '2px', border: 0}}
          color="primary" 
          onClick={() => 
            {
                window.location.href = '/pabx-info/'+row.id;
            }
          }>
          <HiViewGrid style={{ padding: '2px' }} />
        </Button>
        <ReactTooltip/>
      </Row>
    },
    {
        name: 'Data',
        selector: 'created_at',
        width: '200px',
        sortable: false,
        cell: row => moment(new Date(row.created_at)).format('DD/MM/YYYY HH:mm')
    },
    {
        name: "Assunto",
        selector: "assunto",
        sortable: false,
        cell: row => row.assunto
    },
    {
        name: "Status da Chamada",
        selector: "status_chamada",
        sortable: false,
        cell: row => row.status_chamada
    },
    {
        name: "Duração",
        selector: "duracao",
        sortable: false,
        cell: row => row.duracao
    },
    {
        name: 'Telefone',
        selector: 'telefone',
        sortable: false,
        cell: row => row.contact ? formatPhoneNumber(row.contact.telefone) : ''
    },
    {
        name: "Nome do contato",
        selector: "nome_completo",
        sortable: false,
        cell: row => row.contact ? row.contact.nome_completo : ''
    }
];

export const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "telefonia/ligacoes/list-admin?search=" + state.search + "&page=" + state.activePage;


    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {    
    var url = ENDPOINT + "telefonia/ligacoes/list-admin?search=" + search + "&page=" + page;

    if (filter === true) {
        url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }

    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}