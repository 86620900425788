import React from 'react';
import { FaAsterisk } from 'react-icons/fa';
import { TabPane } from 'reactstrap';
import PrevencaoRisco from '../UsersList/PrevencaoRisco';

const PrevencaoRiscoClient = (props) => {
    return (
        <TabPane tabId={props.active}>
            <br />
            <h5>&nbsp;&nbsp;
                <FaAsterisk style={{width: '30px', height: '30px'}}/> 
                Histórico de prevenção e risco de {props.name} </h5>
            <br/>
            <PrevencaoRisco data={props.data} />
        </TabPane>
    )
}

export default PrevencaoRiscoClient;