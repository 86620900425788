import React from 'react';
import { GrTransaction } from 'react-icons/gr';
import { TabPane } from 'reactstrap';
import Transacoes from '../UsersList/Transacoes';

const TransacoesClient = (props) => {
    return (
        <TabPane tabId={props.active}>
            <br />
            <h5>&nbsp;&nbsp;
                <GrTransaction style={{width: '30px', height: '30px'}}/> 
                Histórico de transações de {props.name} </h5>
            <br/>
            <Transacoes data={props.data} />
        </TabPane>
    )
}

export default TransacoesClient;