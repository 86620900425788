import { Component } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { TableCartao } from "../../components/Tables/TablePayout/TableCartao";
import { getRowsPerPage } from "../../components/Tables/TablePayout/propsCartao";
import { Card, CardBody } from 'reactstrap';
import PayoutModal from "../../components/Modals/PayoutModal/PayoutModal";
import Moment from "moment";
import { toast } from "react-toastify";

class PayoutCartao extends Component{
    constructor(props){
        super(props);
        this.state = {
            search: '',
            loading_table: true,
            edit_id: -1,
            edit_user: null,
            rowData: [],
            cpf_current: null,
            cnpj_current: null,
            phone_current: null,
            table_show: false,
            numeros_iniciais: '',
            numeros_iniciais_invalid: false,
            numeros_finais: '',
            numeros_finais_invalid: false,
            totalPage: 0,
            activePage: 1,
            is_cnpj: false,
            document: '',
            filter: false,
            payload_current: {},
            selected_rows: {
                allSelected: false,
                selectedCount: 0,
                selectedRows: []
            },
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
    }
    onSearchDocument(){
        
    }

    handleChange = (event) => {
        var state = this.state;
        let name = event.target.name;
        let value = event.target.value;

        state[name] = value;
        this.setState({...state});
    }

    validNumeros = () => {
        var inv = 0;
        if(this.state.numeros_iniciais.replace(/([^\d])+/gim, '').length > 3){
            this.setState({numeros_iniciais_invalid: false})
        }
        else{
            inv++;
            this.setState({numeros_iniciais_invalid: true})
        }

        if(this.state.numeros_finais.replace(/([^\d])+/gim, '').length > 3){
            this.setState({numeros_finais_invalid: false})
        }
        else{
            inv++;
            this.setState({numeros_finais_invalid: true})
        }

        if(inv > 0){
            return false;
        }

        return true;
    }
    search = () => {
        if(this.validNumeros() === true){
            var state = this.state;
            state.activePage = 1;
            state.rowData = [];
            state.loading_table = true;
            state.table_show = true;
            
            this.setState({...state});
    
            getRowsPerPage(1, state, this.callbackRows);
        }
    }

    componentDidMount(){
        //getRows(this.state, this.callbackRows.bind(this));
    }

    callbackRows = (rowData, totalPage) => {
        this.setState({rowData, totalPage, loading_table: false})
    }

    onPageChange = (activePage) => {
        var state = this.state;

        state.activePage = activePage;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});

        getRowsPerPage(activePage, state, this.callbackRows.bind(this));
    }
    onClose = () => {
        getRowsPerPage(this.state.activePage, this.state, this.callbackRows.bind(this));
        this.setState({visible_payload: false, payload_current: {}});
    }
    onCallback = (data) => {
        toast.success(data.message);
        return data;
    }
    render(){
        return (
            <div>
                <h3>Pagamentos por Cartão</h3>
                <hr />
                <PayoutModal 
                    novoBloqueio={false}
                    payload={this.state.payload_current}
                    visible={this.state.visible_payload}
                    onClose={() => this.onClose()}
                />
                <TableCartao
                    selectableRows
                    isSearch={this.handleChange}
                    onSearchDocument={this.onSearchDocument.bind(this)}
                    search={this.search}
                    table_show={this.state.table_show}
                    data={this.state.rowData}
                    show_filter={this.state.show_filter}
                    onPageChange={this.onPageChange}
                    state={this.state}
                    date_from={this.state.date_from}
                    date_to={this.state.date_to}
                    filter={this.state.filter}
                    activePage={this.state.activePage}
                    totalPage={this.state.totalPage}
                    noDataComponent={ this.state.loading_table === true ? 
                        <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                            <PropagateLoader color={'#52c565'} loading={20} size={30} />
                        </div></div></CardBody></Card> :
                        <Card style={{border: 0}}><CardBody><div>
                            Nenhum usuário encontrado
                        </div></CardBody></Card>
                    }
                />
            </div>
        )
    }
}

export default PayoutCartao;