import React, { Component } from "react";
import { Button, ButtonGroup, Card, CardBody, FormGroup, Input, Label } from "reactstrap";
import ModalTitularidade from "../../components/Modals/Titularidade/ModalTitularidade";
import { TableTitularidade } from "../../components/Tables/TableTransferenciaTitularidade/TableTitularidade";
import { getRows, getRowsPerPage } from '../../components/Tables/TableTransferenciaTitularidade/propsTitularidade';
import moment from "moment";
import PropagateLoader from "react-spinners/PropagateLoader";
import { toast } from "react-toastify";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import { InputMasked } from "../../components/InputText/InputMasked";
import { cpf } from "cpf-cnpj-validator";

const zoopUser = (id, callback) => {
    var url = ENDPOINT + "titularidade/zoop_user/"+id;
    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        callback(p);
    })
}

const zoopUserEmp = (id, cpf_is, callback) => {
    var url = ENDPOINT + "titularidade/zoop_user/"+id+"?cpf="+cpf_is.replace(/([^\d])+/gim, '');
    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        callback(p);
    })
}

const apagarCliente = (id, callback) => {
    var url = ENDPOINT + "titularidade/apagar_cliente/"+id;
    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        callback(p);
    })
}
const alterarStatus = (id, status, callback) => {
    var url = ENDPOINT + "titularidade/alterar_status?id="+id+"&status="+status;
    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        callback(p);
    })
}
class PedidosTransferencias extends Component{
    constructor(props){
        super(props);
        this.state = {
            visible_modal: false,
            activePage: 1,
            loading_table: true,
            cpf_current: null,
            cnpj_current: '',
            totalPage: 1,
            document: '',
            is_cnpj: false,
            filter: false,
            apagar_loading: false,
            zoop_loading: false,
            search: '',
            status: null,
            service_select: null,
            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
    }
    onClose = (visible_modal) => {
        this.setState({ visible_modal })
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.rowData = [];
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }


    componentDidMount() {
        getRows(this.state, this.callbackRows.bind(this));
    }

    handleChangeCpf = (event, index) => {
        let value = event.target.value;

        let rowData = this.state.rowData;
        rowData[index].cpf_string = value

        this.setState({ rowData });

    }
    callbackRows = (rowData, activePage, totalPage) => {
        rowData.forEach((e, index) => {
            e.index = index;
            e.cpf_string = '';
        });

        console.log(rowData);
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    callbackToast = (data) => {
        this.setState({zoop_loading: false, apagar_loading: false});

        if(data.success === true){
            toast.success(data.message);
        }
        else{
            toast.error(data.errors);
        }

        getRows(this.state, this.callbackRows.bind(this));
    }

    onFilter = (filter) => {
        this.setState({ filter });
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    onZoop = (id) => {
        this.setState({zoop_loading: true});
        zoopUser(id, this.callbackToast);
    }
    
    onZoopEmp = (id, cpf_is) => {
        if(cpf.isValid(cpf_is.replace(/([^\d])+/gim, ''))){
            this.setState({zoop_loading: true});
            zoopUserEmp(id, cpf_is, this.callbackToast);
        }
        else{
            toast.error('CPF Inválido');
        }

    }
    
    onDelete = (id) => {
        this.setState({apagar_loading: true});
        apagarCliente(id, this.callbackToast);
    }
    
    isTransfer = (value) => {
        switch(value){
            case 'migrar_pj': return 'Migrar para PJ';
            case 'troca_titularidade': return 'Trocar Titularidade';
            default: return ''
        }
    }

    passoStep = (value) => {
        switch(value){
            case 1: return 'Aguardando cadastro';
            case 2: return 'Aguardando Documentos';
            case 3: return 'Aguardando pagamento';
            case 4: return 'Aguardando aprovação';
            case 5: return 'Finalizado';
            case 6: return 'Reprovado';
            default: return ''
        }
    }
    ExpandableRows = ({data}) => {
        //const dataAll = JSON.parse(data.resposta);
        return <div style={{margin: '20px'}}>
            <b>Informações de Mudança</b>
            <p>
                <b>Etapa:</b> {this.passoStep(data.step)}<br/>
                <b>Tipo de transferência:</b> {this.isTransfer(data.tipo_transferencia)}<br/>
                <b>Documento de origem:</b> {data.cpf_cnpj_origem}<br/>
                <b>Documento transferido:</b> {data.cpf_cnpj_transferido}<br/>
                <b>Serial da maquininha:</b> {data.serial_maquininha}<br/>
                <b>Motivo da transfêrencia:</b> {data.motivo_transferencia}<br/>
                {data.usuario !== null ?
                    <>
                        <hr/>
                        <b>Informações do novo cliente</b><br/>
                        <b>Nome:</b> {data.usuario.nome+ ' '+data.usuario.sobrenome}<br/>
                        <b>E-mail:</b> {data.usuario.email}<br/>
                        <b>Telefone:</b> {data.usuario.telefone}<br/>
                    </>
                : null}
                <FormGroup>
                    <Label>Status da titularidade:</Label>
                    <Input 
                        type="select" 
                        placeholder="Status da titularidade"
                        name="step"
                        onChange={(e) => {
                            const callbackReturnToast = (data) => {
                                if(data.success === true){toast.success(data.message);}
                                else{toast.error(data.errors);}
                            }

                            var name = e.target.name;
                            var value = e.target.value;
                            var rowData = this.state.rowData;
                            rowData[data.index][name] = value;
                            alterarStatus(data.id, value, callbackReturnToast)
                            this.setState({ rowData })
                        }}
                    >
                        <option value="1">Aguardando cadastro</option>
                        <option value="2">Aguardando documentos</option>
                        <option value="3">Aguardando pagamento</option>
                        <option value="4">Aguardando aprovação</option>
                        <option value="5">Finalizado</option>
                        <option value="6">Reprovado</option>
                    </Input>
                </FormGroup>
            </p>
            {/* 
                <b>Documentos</b>
                <p>{data.documentos.map(p => (
                <><a href={p.url} target="_blank">{p.descricao}</a><br/></>
            ))}
            </p> 
            */}
            {data.cpf_cnpj_origem.length > 11 && data.cpf_cnpj_transferido.length > 11 ?
            <FormGroup>
                <InputMasked
                    mask="999.999.999-99"
                    placeholder="CPF do usuário a transferir" 
                    value={data.cpf_string}
                    onChange={(e) => this.handleChangeCpf(e, data.index)}
                />
            </FormGroup>
            : null}
            <ButtonGroup>
                {data.seller_novo_credenciado == 0 && data.step != 5 ?
                    /* data.cpf_cnpj_origem.length > 11 && data.cpf_cnpj_transferido.length > 11 ? */
                        <Button 
                            color="info" 
                            disabled={this.state.zoop_loading}
                            onClick={() => window.location.href = '/user-pre-venda/'+data.user_id}>Informações do novo seller</Button>
                        /* :
                        <Button 
                            color="success" 
                            disabled={this.state.zoop_loading}
                            onClick={() => this.onZoop(data.id)}>Credenciar novo seller</Button> */
                    :
                    null
                }
                {/* {data.seller_antigo_apagado == 0 && data.step != 5 ? */}
                    <Button 
                        color="danger"
                        disabled={this.state.apagar_loading}
                        onClick={() => this.onDelete(data.id)}>Apagar seller antigo</Button>
                    {/* :
                    null
                } */}
            </ButtonGroup>
        </div>
    }

    
    handleSelectChange = status => {
        var state = this.state;
        state['status'] = status;
        state.loading_table = true;
        state.rowData = [];
        this.setState({ ...state });
        getRowsPerPage(1, state.search, state.filter, state, this.callbackRows.bind(this));
    };
    
    handleServiceSelectChange = service_select => {
        var state = this.state;
        state['service_select'] = service_select;
        state.loading_table = true;
        state.rowData = [];
        this.setState({ ...state });
        getRowsPerPage(1, state.search, state.filter, state, this.callbackRows.bind(this));
    };

    render(){
        return(
            <div>
                <h3>Transfêrencia de Titularidade</h3>
                <hr/>
                <Button color="success" onClick={() => this.onClose(true)}>Transferir Titularidade</Button>
                <ModalTitularidade 
                    visible={this.state.visible_modal}
                    onClosed={this.onClose}
                />
                <hr/>
                <TableTitularidade
                    isSearch={this.handleChange}
                    onSearchDocument={this.onSearchDocument.bind(this)}
                    data={this.state.rowData}
                    is_cnpj={this.state.is_cnpj}
                    onFilter={this.onFilter.bind(this)}
                    onPageChange={this.onPageChange.bind(this)}
                    handleSelectChange={this.handleSelectChange.bind(this)}
                    handleServiceSelectChange={this.handleServiceSelectChange.bind(this)}
                    filter={this.state.filter}
                    status={this.state.status}
                    service_select={this.state.service_select}
                    expandableRowsComponent={<this.ExpandableRows />}
                    noDataComponent={this.state.loading_table === true ?
                        <Card style={{ border: 0, padding: '10vh' }}><CardBody>
                            <div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                        <Card style={{ border: 0 }}><CardBody><div>
                            Nenhuma tarefa encontrado
                        </div></CardBody></Card>
                    }
                    activePage={this.state.activePage}
                    totalPage={this.state.totalPage}
                    date_from={this.state.date_from}
                    date_to={this.state.date_to}
                />
            </div>
        )
    }
}

export default PedidosTransferencias;