import React from 'react';
import { HiViewGrid } from 'react-icons/hi';
import { Button, Row, Col } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getPermissoes, getToken } from '../../../auth/set_services';
import ReactTooltip from 'react-tooltip';
import 'react-dropdown/style.css';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import moment from 'moment';

const redirectPrev = (id_prevencao) => {
  if(id_prevencao) {
    let url = window.location.href;
    url = url.replace('/passagens-querobus', '/prevencao-detalhes/'+id_prevencao);
    return window.open(url, '_blank', 'noreferrer');
  }
};


const origens = [
  { value: "cobrancas", label: "Link de Pagamento" },
  /* { value: "magento2", label: "Loja Magento" }, */
  { value: "magento2", label: "SHOPAQPago" },
  { value: "woocommerce", label: "WooCommerce" },
  { value: "opencard", label: "OpenCart" },
  { value: "prestashop", label: "PrestaShop" },
  { value: "api", label: "Integração API" },
  { value: "meubus", label: "MeuBus" },
];


const get_origem = (produto_servico) => {
  var produto = '';

  origens.forEach(e => {
    if(produto_servico == e.value){
      produto = e.label;
    }
  });

  if (!produto) produto = 'Integração';

  return produto;
}


const getStatus = (value, status_current) => {
  if(value === ""){
      var status_is_not_antifraude = '';
      list_status_pagamentos.map((p) => {
          if(p.value === status_current){
              status_is_not_antifraude = p.label
          }
          return null;
      })
      return status_is_not_antifraude;
  }
  else{
      var status_is = '';
      const status = [
          { 
            value: 'INC', 
            label: 
              <div style={{color: 'rgb(195 144 0)',fontSize: '16px',fontWeight: 'bold'}}>
                Pagamento Incompleto
              </div>
          },
          { 
            value: 'NVO', 
            label:
            <div style={{color: 'rgb(55 58 159)',fontSize: '16px',fontWeight: 'bold'}}>
              Análise Pendente
            </div>
          },
          { 
            value: 'AMA', 
            label:
            <div style={{color: 'rgb(55 58 159)',fontSize: '16px',fontWeight: 'bold'}}>
              Análise Iniciada
            </div>
          },
          //{ value: 'APA', label: 'Aprovado Automaticamente'},  
          { 
            value: 'APA', 
            label: 
            <div style={{color: '#3d8149',fontSize: '16px',fontWeight: 'bold'}}>
              Aprovado Automaticamente
            </div>
          },  
          { 
            value: 'APM', 
            label:
            <div style={{color: '#3d8149',fontSize: '16px',fontWeight: 'bold'}}>
              Aprovado Manualmente
            </div>
          },
          { 
            value: 'SUS', 
            label:
            <div style={{color: '#ff5707',fontSize: '16px',fontWeight: 'bold'}}>
              Suspeita de Fraude
            </div>
          },
          { 
            value: 'FRD', 
            label:
            <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
              Fraude Confirmada
            </div>
          },
          { 
            value: 'RMA', 
            label:
            <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
              Reprovado Manualmente
            </div>
          },
          { 
            value: 'RPM', 
            label:
            <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
              Reprovado sem contato
            </div>
          },
          { 
            value: 'RPA', 
            label:
            <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
              Reprovado Automático
            </div>
          },
      ];

      status.forEach(p => {
          if(p.value === value){
              status_is = p.label;
          }
      });

      return status_is;

  }
}


const list_status_pagamentos = [
  { value: 0, label: "Todos" },
  { value: "CANCELADA", 
    label: <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Pagamento cancelado
    </div>
  },
  { value: "PENDENTE", 
    label: "Pagamento pendente" 
  },
  { value: "APROVADO", 
  label: "Pagamento aprovado" 
  },
  { value: "FALHA NO PAGAMENTO", 
    label: <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Falha no pagamento
    </div>
  },
  { value: "ANALISANDO", 
    label: 
    <div style={{fontSize: '16px',fontWeight: 'bold'}}>
      Analise Pendente
    </div>  
  },
  { value: "EM ANALISE", 
    label: 
    <div style={{fontSize: '16px',fontWeight: 'bold'}}>
      Analise Iniciada
    </div> 
  },
  { value: "APROVADO AUTOMATICA", 
    label:
    <div style={{color: 'rgb(61, 129, 73)',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude aprovado automaticamente
    </div> 
  },
  { 
    value: "APROVADO MANUALMENTE", 
    label:
    <div style={{color: 'rgb(61, 129, 73)',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude aprovado manualmente
    </div> 
  },
  { value: "REPROVADO AUTOMATICAMENTE", 
    label: 
    <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude - Reprovado automaticamente
    </div>
  },
  { 
    value: "FRAUDE COMFIRMADA", 
    label:
    <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude reprovado por confirmada
    </div> 
  },
  { 
    value: "FRAUDE SUSPEITA", 
    label:
    <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude reprovado por suspeita
    </div> 
  },
  { 
    value: 'REPROVADO MANUALMENTE', 
    label:
    <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude Reprovado Manualmente
    </div> 
  },
  { 
    value: "FRAUDE SEM SUSPEITA", 
    label:
    <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude reprovado sem contato
    </div> 
  }    
];

const status_validacao_card = (payment_type) => {
  switch(payment_type){
      case null : return 'Cartão pedente de validação';
      case 'pending' : return 'Cartão pedente de validação';
      case 'success' : return 'Validação de cartão aprovada';
      case 'payment_refused' : return 'Pagamento de validação recusada';
      default: return payment_type
  }
}

const payment = (payment_type) => {
  switch(payment_type){
      case 'credit' : return 'Crédito';
      case 'boleto' : return 'Boleto';
      case 'debit' : return 'Débito';
      default: return payment_type
  }
}

export const columns = (handleOpenBlockUser) => [
  {
    name: 'Informações das passagens',
    selector: 'buttons',
    width: '100%',
    cell: row => <div style={{paddingBottom: '1rem'}}>
    <Row>
      <Col md={1} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
        <Button data-tip="Informações da passagem"
          outline
          style={{ padding: '0px', marginRight: '3px', border: 0 }}
          color="primary" onClick={() => {
              let url = `http://${window.location.host}/querobus-passagem/${row.id}`;
              console.log(url)
              return window.open(url, '_blank', 'noreferrer');
            }
          }>
          <HiViewGrid style={{ padding: '2px', width: '23px' }} />
        </Button>
        <ReactTooltip />
      </Col>
      <Col md={4} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
        <Row>
          {row.user?.fraud ? (
            <Col md={12} style={{color: 'rgb(211 29 29)', fontSize: '16px', paddingTop: '0.5rem', paddingBottom: '0.5rem'}}><strong>Bloqueado Por Fraude:</strong>
            </Col>
          ) : (<></>)}
          
          <Col md={12} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}><strong>Nome:</strong> {row.user?.name}</Col>
          <Col md={12} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}><strong>E-mail:</strong> {row.user?.email}</Col>
          <Col md={12} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}><strong>Telefone:</strong> 
          <NumberFormat 
              displayType={'text'} 
              value={row.user?.phone} 
              format={row.user?.phone?.length == 11 ? '(##) #####-####' : "(##) ####-####"}
              />
          </Col>
          <Col md={12} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}><strong>Documento:</strong> 
            <NumberFormat 
              displayType={'text'} 
              value={row.user?.cnpj !== null ? row.user?.cnpj : row.user?.cpf} 
              format={row.user?.cnpj !== null ? '##.###.###/####-##' : "###.###.###-##"}
              />
          </Col>
          <Col md={12} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
            <Button
                color="danger"
                onClick={() => handleOpenBlockUser(row.user)}>
                    Bloquear Usuário 
            </Button>
          </Col>
        </Row>
      </Col>
      <Col md={1} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
        <Row>
          <Col md={12} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}><strong>Data:</strong><br/>{ moment(row.created_at).format('DD/MM/YYYY')}</Col>
          <Col md={12} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}><strong>Hora:</strong><br/>{ moment(row.created_at).format('HH:mm')}</Col>
        </Row>
      </Col>
      <Col md={2} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
        <Row>
          <Col md={12} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}><strong>Origem:</strong><br/>{row.travel?.origin_id}</Col>
          <Col md={12} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}><strong>Destino:</strong><br/>{row.travel?.destiny_id}</Col>
            {row.payment?.public_id ? (
              <Col md={12} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}><strong>ID MeuBus:</strong><br/>{row.payment?.public_id}
            </Col>
            ) : (<></>)}
        </Row>
      </Col>
      <Col md={1} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
        <Row>
          <Col md={12} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}><strong>Data:</strong><br/>{moment(row.booking_date).format('DD/MM/YYYY')}</Col>
          <Col md={12} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}><strong>Hora Saída:</strong><br/>{row.travel?.exit_hour}</Col>
        </Row>
      </Col>
      <Col md={2} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
        <Row>
         <Col md={12} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
            <strong>Status:</strong><br/>{row.status_booking}
         </Col>
         {row.aqpago_transcation_id ? (
          <Col md={12} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
            <strong>ID Transação:</strong>
            <div onClick={()=>redirectPrev(row.id_prevencao)} style={{color: 'blue', cursor: 'pointer'}}>
              {row.aqpago_transcation_id}
            </div>
          </Col>
          ) : (<></>)}

          {row?.payment?.form_of_payment == 'pix' ? (
            <Col md={12} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
              <strong>ID Transação pix:</strong>
              <div style={{color: 'blue'}}>
                {row?.payment?.transaction_id}
              </div>
            </Col>
          ) : (<></>)} 
          
        </Row>
      </Col>
      <Col md={1} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
        <strong>Valor:</strong><br/>
        <span style={{color: 'rgb(61, 129, 73)',fontSize: '16px',fontWeight: 'bold'}}>
          {parseFloat(row.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
        </span>
      </Col>

    </Row>

    {row.id_prevencao ? (
      <div>
        <Row>
          <Col md={12}>
              <h3 style={{margim: '0px',fontSize: '16px'}}>Dados de pagamento</h3>
          </Col>
        </Row>

        <Row>
          <Col md={1}>
            <Button
              outline
              style={{ padding: 2, marginLeft: 15, border: 0, fontWeight: '700'}}
              color="dark" onClick={() =>  {
                  let url = window.location.href.replace('/passagens-querobus', '/prevencao-detalhes/'+row.prevencao.id);
                  return window.open(url, '_blank', 'noreferrer');
                }
              }>
              <HiViewGrid style={{ padding: '2px' }}/>
            </Button>
          </Col>
          <Col md={3} style={{marginBottom: '0.5rem'}}>
            <p style={{marginBottom: '0.2rem'}}>
              <b>Seller:</b>
            </p>
            <p style={{marginBottom: '1rem'}}>{row.prevencao?.estabelecimento?.toUpperCase()}</p> 

            {row.prevencao?.seller_documento !== undefined ? 
              <div md={12} style={{margin: '0.5rem 0px'}}>
                <p style={{marginBottom: '0.2rem'}}>
                  <b>Documento:</b>
                </p>
                <p style={{marginBottom: '1rem'}}>
                  <NumberFormat displayType={'text'} value={row.prevencao.seller_documento} format={row.prevencao?.seller_documento.length > 11 ? '##.###.###/####-##' : "###.###.###-##"} />
                </p>  
              </div>
            : 
              ''
            }
          </Col>
          <Col md={3} style={{marginBottom: '0.5rem'}}>

           <p style={{marginBottom: '0.2rem'}}>
             <b>Forma de pagamento:</b>
           </p>
           <p style={{marginBottom: '1rem'}}>
             {row.prevencao?.pagamento_multiplo === false ? 
              <div>
                {payment(row.prevencao?.forma_pagamento)}&nbsp;
                
                {row.prevencao?.forma_pagamento == 'credit' ?
                  row.prevencao?.numero_parcelas !== undefined ? 
                    row.prevencao?.numero_parcelas > 1 ?
                      row.prevencao?.numero_parcelas + 'x' 
                    :
                    'À vista'
                  : 
                    ''
                :
                  ''
                }
              </div>
            :
              'Múltiplo'
            }
          </p>
          <p style={{marginBottom: '0.2rem'}}>
            <b>Data do pedido:</b>
          </p>
          <p style={{marginBottom: '1rem'}}>
            { moment(row.prevencao?.data_pedido).format('DD/MM/YYYY HH:mm')}
          </p>
          <p style={{marginBottom: '0.2rem'}}>
            <b>ID da transação:</b>
          </p>
          <p style={{marginBottom: '1rem'}}>
            {row.prevencao?.transacao_id}
          </p>
        </Col>
        <Col md={3} style={{marginBottom: '0.5rem'}}>

           <p style={{marginBottom: '0.2rem'}}>
             <b>Origem da venda:</b>
           </p>
           <p style={{marginBottom: '1rem'}}>
             {get_origem(row.prevencao?.produto_servico)}
           </p>
           <p style={{marginBottom: '0.2rem'}}>
             <b>Status:</b>
           </p>
           <p style={{marginBottom: '1rem'}}>
             {getStatus(row.prevencao?.antifraude_code, row.prevencao?.status)}
           </p>
           <p style={{marginBottom: '0.2rem'}}>
             <b>Status validação cartão:</b>
           </p>
           <p style={{marginBottom: '1rem'}}>{status_validacao_card(row.prevencao?.validacao_card)}</p>
           <p style={{marginBottom: '0.2rem'}}>
             <b>Envio KOIN:</b>
           </p>
           <p style={{marginBottom: '1rem'}}>
             {row.prevencao?.envio_service_antifraud !== undefined &&  row.prevencao?.envio_service_antifraud == true ? 'Sim' : 'Não'}
           </p>
           <p style={{marginBottom: '0.2rem'}}>
             <b>Status KOIN:</b>
           </p>
           <p style={{marginBottom: '1rem'}}>
            {row.prevencao?.status_service_antrifraud !== undefined && row.prevencao?.status_service_antrifraud !== null ? row.prevencao?.status_service_antrifraud.replace('_', ' ') : '-'}
           </p>
        </Col>
        <Col md={2} style={{marginBottom: '0.5rem'}}>
            <p style={{marginBottom: '0.2rem'}}>
             <b>Buyer:</b>
            </p>
            <p>{row.prevencao?.cliente_nome.toUpperCase()}</p>
            <p style={{marginBottom: '0.2rem'}}>
             <b>Documento:</b>
            </p>
            <p>
             <NumberFormat displayType={'text'} value={row.prevencao?.cpf_cnpj} format={row.prevencao?.cpf_cnpj.length > 11 ? '##.###.###/####-##' : "###.###.###-##"} /> 
           </p>

         <p style={{marginBottom: '0.2rem'}}>
           <b>{row.prevencao?.analista_inicio_name.length > 0 ? 'Iniciado por:' : ''}</b>
         </p>
         <p>
           {row.prevencao?.analista_inicio_name.length > 0 ? row.prevencao?.analista_inicio_name : ''}
         </p>

         <p style={{marginBottom: '0.2rem'}}>
           <b>{row.prevencao?.analista_fim_name.length > 0 ? 'Finalizado por: ' : ''}</b>
         </p>
         <p>
           {row.prevencao?.analista_inicio_name.length > 0 ? row.prevencao?.analista_fim_name : ''}
         </p>

        </Col>


        </Row>
      </div>
    ) : (<></>)}

    <Row>
        <Col md={12}>
          <h3 style={{margim: '0px',fontSize: '16px'}}>Dados dos Passageiros</h3>
        </Col>
    </Row>
    
    {row.seats.length ? (
      row.seats.map((seat, i)=>{
        return(
          <Row style={
            row.user?.fraud ?
            {background: 'rgb(211 29 29)',color: '#fff', borderRadius: '5px',boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',padding: '0.5rem',marginBottom: '1rem'}
            :
            {background: '#e7e4e4',borderRadius: '5px',boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',padding: '0.5rem',marginBottom: '1rem'}
            }>
            <Col md={1} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
              <strong>Passageiro:</strong>
            </Col>
            <Col md={3} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
              {seat.name_client}
            </Col>
            <Col md={1} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
              <strong>Documento:</strong>
            </Col>
            <Col md={2} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
              {seat?.type_document != null ? seat?.type_document : 'CPF'} {seat?.document} 
            </Col>
            <Col md={1} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
              <strong>Assento:</strong>
            </Col>
            <Col md={1} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
              {seat.seat}
            </Col>
            <Col md={1} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
              <strong>Localizador:</strong>
            </Col>
            <Col md={2} style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
              {seat.localization}
            </Col>
          </Row>
        )
      })
    ) : (<></>)}

    </div>
  },
];


export const getRows = (state, callback) => {
  var url = `${ENDPOINT}querobus/booking/list?page=${state.activePage}&search=${state.search}`;
  var document = state.document.replace(/([^\d])+/gim, '');

  if (state.user_id !== null) {
    url += "&user_id=" + state.user_id ;
  } 

  if (state.is_cnpj === false) {
    url += "&cpf=" + document;
  }
  else {
    url += "&cnpj=" + document;
  }

  if(parseFloat(state.valor_ate) > 0){
    url += "&valor_de="+state.valor_de;
  }

  if(parseFloat(state.valor_ate) > 0){
      url += "&valor_ate="+state.valor_ate;
  }

  if (state.status !== null && state.status !== 'null') {
    url += "&status=" + state.status.value
  }

  if (state.filter === true) {
    if(state.date_from ){
      url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }
  }
  var myHeaders = new Headers();
  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      console.log(p)
      callback(p.data, p.last_page);
    })
    .catch(() => {
      toast.error('Ocorreu um erro no servidor!');
    })
}

export const getRowsPerPage = (page, state, callback) => {
  var url = `${ENDPOINT}querobus/booking/list?page=${state.activePage}&search=${state.search}`;
  var document = state.document.replace(/([^\d])+/gim, '');

  if (state.user_id !== null) {
    url += "&user_id=" + state.user_id ;
  } 
  
  if (state.is_cnpj === false) {
    url += "&cpf=" + document;
  }
  else {
    url += "&cnpj=" + document;
  }

  if(parseFloat(state.valor_ate) > 0){
      url += "&valor_de="+state.valor_de;
  }
  
  if(parseFloat(state.valor_ate) > 0){
      url += "&valor_ate="+state.valor_ate;
  }

  if (state.status !== null) {
    url += "&status=" + state.status.value
  }
  if (state.filter === true) {
    if(state.date_from ){
      url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      callback(p.data, p.last_page)
    })
    .catch(() => {
      toast.error('Ocorreu um erro no servidor!');
    })
}
