import React from 'react';
import { BiTransferAlt } from 'react-icons/bi';
import { TabPane } from 'reactstrap';
import Transferencia from '../UsersList/Transferencia';

const TransferenciaClient = (props) => {
    return (
        <TabPane tabId={props.active}>
            <br />
            <h5>&nbsp;&nbsp;
                <BiTransferAlt style={{width: '30px', height: '30px'}}/> 
                Histórico de transferências de {props.name} </h5>
            <br/>
            <Transferencia data={props.data} />
        </TabPane>
    )
}

export default TransferenciaClient;