import axios from 'axios';
import moment from 'moment';
import { FaEdit, FaEye, FaRegFilePdf, FaTrash } from 'react-icons/fa';
import { HiViewGrid } from 'react-icons/hi';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import { ENDPOINT } from '../../../../auth/endpoint';
import { getToken } from '../../../../auth/set_services';
import { fetchWithTimeout } from '../../../Tools/fetchWithTimeout';
import ClipLoader from 'react-spinners/ClipLoader';

const actionSwitch = (param) => {
  switch(param) {
    case 'C':
      return 'Coletado';
    case 'T':
      return 'Transferido';
    case 'E':
      return 'Entregue';
    case 'R':
      return 'Retirado';
    default:
      return param;
  }
}

export const columns = (props, handleOpenLabelPDF, handleDownloadLabelPDF) => [
    {
        name: "",
        selector: "buttons",
        sortable: false,
        width: '10%',
        cell: row => 
        <Row>
            <Button
            outline
            style={{ padding: 2, marginLeft: 15, border: 0, fontWeight: '700'}}
            onClick={() => {
                window.location.href = '/aqenvio-etiqueta-detalhes/'+row.id
            }}>
                <HiViewGrid style={{ padding: '2px' }}/>
            </Button>
        </Row>
    },

    {
        name: "",
        selector: "etiquetas",
        sortable: false,
        width: '90%',
        cell: row => 
        <Row style={{width: '100%'}}>
            <Col md={2}>{row.code}</Col>
            <Col md={3}>{moment(row.created_at).format('DD/MM/YYYY HH:mm')}</Col>
            <Col md={3}>{row.status}</Col>
            <Col md={2}>
              {row.label ? (
                    <Button
                        outline
                        color="info"
                        onClick={() => {
                            return handleDownloadLabelPDF(row.code)
                        }}>
                        {props.state.loading_download ? (
                          <>
                            <ClipLoader color="#fff" size={12}/>
                            &nbsp;Abrindo...
                          </>
                        ) : (
                          <>
                            <FaRegFilePdf />&nbsp;Download
                          </>
                        )}
                    </Button>
                ) : (
                    <></>
                )}
            </Col>
            <Col md={2}>
                {row.label ? (
                    <Button
                      outline
                      color="info"
                      onClick={() => {
                          return handleOpenLabelPDF(row.code)
                      }}>
                        {props.state.loading_view ? (
                          <>
                            <ClipLoader color="#fff" size={12}/>
                            &nbsp;Abrindo...
                          </>
                        ) : (
                          <>
                            <FaRegFilePdf />&nbsp;Visualizar
                          </>
                        )}
                    </Button>
                ) : (
                    <></>
                )}
            </Col>
            {row.tracking_history?.situacao?.ocorrencia ? (
              <>
                <hr/>
                <Col md={6} style={{margin: '1rem 0'}}>
                  <p><strong>Ocorrência:</strong> {row.tracking_history?.situacao?.ocorrencia}</p>
                  <p><strong>Observacao:</strong> {row.tracking_history?.situacao?.observacao}</p>
                </Col>
                <Col md={6} style={{margin: '1rem 0'}}>
                  <p><strong>Ação:</strong> {actionSwitch(row.tracking_history?.situacao?.acao)}</p>
                  <p><strong>Data:</strong> {moment(row.tracking_history?.situacao?.dataHora).format('DD/MM/YYYY HH:mm')}</p>
                </Col>
              </>
            ) : (
              <></>
            )}
            
        </Row>

    },

];

var numberOfAjaxCAllPending = 0;

export const getRows = (state, callback) => {
  if(numberOfAjaxCAllPending == 0){
    numberOfAjaxCAllPending = 1;

    var url = ENDPOINT + "aqenvio/show_seller_labels/" + state.seller_id + "?page=" + state.activePage + "&search=" + state.search;
    //var url = "http://localhost:8000/api/aqenvio/show_seller_labels/" + state.seller_id + "?page=" + state.activePage + "&search=" + state.search;

    if (state.filter_type_payment_select?.value && state.filter_type_payment_select.value !== null) {
      url += "&type_payment="+state.filter_type_payment_select.value;
    }      
    if (state.filter_action_select?.value && state.filter_action_select.value !== null) {
      url += "&action="+state.filter_action_select.value;
    }      
    if (state?.period_start && state.period_start !== null) {
      url += "&period_start="+state.period_start;
    }  
    if (state?.period_end && state.period_end !== null) {
      url += "&period_end="+state.period_end;
    }  


    
    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        },
        mode: 'cors',
        cache: 'default'
    })
    .then((response) => {

        let p = response.data;
        if(p.success == true){
          var meta = 0;
          if(p.meta){
            meta = p.meta.last_page;
          }
          numberOfAjaxCAllPending = 0;
          // callback(p.data, meta, p.valor);
          callback(p.data, meta);
        }
        else{
          toast.error(p.errors);
        }
    })
    .catch((error) => {
        toast.error('Ocorreu um erro no servidor!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          //console.log(error.config);

      callback({}, {});
    })
  }
  else{
    //callback(state.rowData, state.totalPage, []);
  }
}

export const getRowsPerPage = (page, state, callback) => {
  //if(numberOfAjaxCAllPending == 0){
    numberOfAjaxCAllPending = 1;

    var url = ENDPOINT + "aqenvio/show_seller_labels/" + state.seller_id + "?page=" + state.activePage + "&search=" + state.search;
    //var url = "http://localhost:8000/api/aqenvio/show_seller_labels/" + state.seller_id + "?page=" + state.activePage + "&search=" + state.search;
   
    
    if (state.filter_type_payment_select?.value && state.filter_type_payment_select.value !== null) {
      url += "&type_payment="+state.filter_type_payment_select.value;
    }      
    if (state.filter_action_select?.value && state.filter_action_select.value !== null) {
      url += "&action="+state.filter_action_select.value;
    }      
    if (state?.period_start && state.period_start !== null) {
      url += "&period_start="+state.period_start;
    }  
    if (state?.period_end && state.period_end !== null) {
      url += "&period_end="+state.period_end;
    }  

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());
    
    var myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };

    fetchWithTimeout(20000, fetch(url, myInit))
    .then(response => response.json())
    .then((p) => {
      if(p.success == true){
        var meta = 0;
        if(p.meta){
          meta = p.meta.last_page;
        }
        numberOfAjaxCAllPending = 0;
        // callback(p.data, meta, p.valor);
        callback(p.data, meta);
      }
      else{
        toast.error(p.errors);
      }
    })
    .catch((error) => {
      toast.error('Ocorreu um erro no servidor!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      
      callback({}, {});
    })
  // }
  // else{
  //   //callback(state.rowData, state.totalPage, []);
  // }
}