import axios from "axios";
import moment from "moment";
import { Component } from "react";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Nav, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import classnames from 'classnames';
import styled from "styled-components";
import EmailClient from "../../components/ResumeClient/EmailClient";
import SmsClient from "../../components/ResumeClient/SmsClient";
import ChatsClient from "../../components/ResumeClient/ChatsClient";
import NotasAtendimento from "../../components/ChatClient/NotasAtendimento";
import ProtocoloClient from "../../components/ResumeClient/ProtocoloClient";

const getColor = (status) => {
    switch(status){
        case 'DEVOLVIDO' : return 'danger';
        case 'CANCELADA' : return 'dark';
        case 'APROVADA' : return 'success';
        default : return 'info'
    }
}
const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
        padding: 0.5rem;
        border: 1px solid #ccc;
        margin: 0.2rem 0.3rem;
        border-radius: 5px;
    }
    .nav-tabs .nav-item.show .nav-link, 
    .nav-tabs .nav-link.active {
        background: #28a745;
        color: #fff;
    }
    .nav-link:hover{
        background: #70b580;
        color: #fff;
    }
    .nav-tabs {
        padding-bottom: 1rem;
    }
    p {
        margin-bottom: 0.5rem;
    }
`

const get_id = () => {
    return window.location.pathname.replace('/aqbank-lead/', '')
}

const getUser = (id, callback) => {
    var url = ENDPOINT + "aqbank/lead/" + id;
    
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        console.log(response.data)
        var data = response.data;
        callback(data);
    })
}

class ResumoLead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            json_response: null,
            activeTab: "1",
            reservas: []
        }
    }

    componentDidMount(){
        getUser(get_id(), this.callbackUser)
    }

    callbackUser = (data) => {
        //console.log(data)
        this.setState({ json_response: data })
    }

    listData = [
        { name: 'Dados do Cliente', active: '1' },
        { name: 'Termos de Uso', active: '9' }
    ];

    Navs = () => {
        return <Nav tabs>
            {this.listData.map((p, index) => {
                return <NavLink
                    key={index}
                    className={
                        classnames({ active: this.state.activeTab === p.active })}

                    onClick={() => { 
                        if (p.active == 19) {
                            this.setState({ activeTab: p.active })
                        } else {
                            this.setState({ activeTab: p.active })
                        }
                    }}
                    
                    >
                    <span>{p.name}</span>
                </NavLink>
            })}
        </Nav>
    }
    render(){
        return(
            <Div>
                <h3>Resumo do cliente na lista</h3>
                <hr/>
                <Card>
                    <CardBody>
                        <TabContent activeTab={this.state.activeTab}>
                            <this.Navs />
                            <TabPane tabId={'1'}>
                                <br/>
                                {this.state.json_response !== null ?
                                    <div>
                                        <br/>
                                        <h4>{this.state.json_response?.lead?.customer?.customer_name}</h4>
                                        <br/>
                                        <p><b>Nome: </b> {this.state.json_response?.lead?.name}</p>
                                        <p><b>Email: </b> {this.state.json_response?.lead?.email}</p>
                                        <p><b>Telefone: </b> <NumberFormat displayType={'text'} value={this.state.json_response?.lead?.ddd + this.state.json_response?.lead?.phone} format="(##) #####-####" /></p>
                                        <p><b>Documento (CPF/CNPJ): </b> {this.state.json_response?.lead?.document}</p>
                                        <p><b>Data: </b> {moment(this.state.json_response?.lead?.created_at).format('DD/MM/YYYY HH:mm:ss')}</p>
                                        <p><b>Tag Auto: </b>{this.state.json_response?.lead?.is_tag_auto ? this.state.json_response?.lead?.license_plate.toUpperCase() : "S/N"}</p>
                                        <p><b>Cartão Black: </b>{this.state.json_response?.lead?.interest_black_card ? "Sim" : "Não"}</p>
                                        <p><b>Cartão Pessoal: </b>{this.state.json_response?.lead?.interest_personal_card ? "Sim" : "Não"}</p>
                                        <p><b>Cartão Empresarial: </b>{this.state.json_response?.lead?.interest_company_card ? "Sim" : "Não"}</p>
                                    </div>
                                :
                                null}
                            </TabPane>
                            <TabPane tabId={'9'}>
                            <br/>
                                {this.state.json_response !== null ?
                                    <div>
                                        <h3>Autorizo SCR do Banco Central para análise de risco de crédito.</h3>
                                        <p><b>Data: </b> {moment(this.state.json_response?.finger_print?.created_at).format('DD/MM/YYYY HH:mm:ss')}</p>
                                        <p><b>Device ID: </b> {this.state.json_response?.finger_print?.device}</p>
                                        <p><b>Dispositivo: </b> {this.state.json_response?.finger_print?.is_mobile ? "Mobile" : "Desktop"}</p>
                                        <p><b>Lat: </b> {this.state.json_response?.finger_print?.lat}</p>
                                        <p><b>Long: </b> {this.state.json_response?.finger_print?.long}</p>
                                        <p><b>Nome Navegador: </b> {this.state.json_response?.finger_print?.navigator_name}</p>
                                        <p><b>Agente Navegador: </b> {this.state.json_response?.finger_print?.navigator_agent}</p>
                                        <p><b>Versão Navegador: </b> {this.state.json_response?.finger_print?.navigator_version}</p>
                                        <p><b>Versão Navegador: </b> {this.state.json_response?.finger_print?.navigator_version}</p>
                                        <p><b>Empresa Navegador: </b> {this.state.json_response?.finger_print?.vendor}</p>
                                        <p><b>SO: </b> {this.state.json_response?.finger_print?.platform}</p>
                                        <p><b>Sessão gerada: </b> {this.state.json_response?.finger_print?.session_id}</p>
                                        <p><b>URL: </b> {this.state.json_response?.finger_print?.url}</p>
                                        <p><b>IP do Usuário: </b> {this.state.json_response?.finger_print?.user_ip}</p>
                                    </div>
                                :
                                null}
                            </TabPane>
                            
                        </TabContent>
                    </CardBody>
                </Card>
            </Div>
        )
    }
}

export default ResumoLead;