import React from 'react';
import { HiViewGrid } from 'react-icons/hi';
import { Button, Col, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getPermissoes, getToken } from '../../../auth/set_services';
import ReactTooltip from 'react-tooltip';
import 'react-dropdown/style.css';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import moment from 'moment';

export const columns = () => [
  /* {
    name: '',
    selector: 'buttons',
    width: '5%',
    cell: row => <Row>
      <Button data-tip="Resumo do Cliente"
        outline
        style={{ padding: '0px', marginRight: '3px', border: 0 }}
        color="primary" onClick={() => {
          window.location.href = '/aqbank-lead/' + row.id
        }}>
          <HiViewGrid style={{ padding: '2px', width: '23px' }} />
      </Button>
      <ReactTooltip />
    </Row>
  }, */
  {
    name: "Dados",
    selector: "name",
    sortable: false,
    width: '35%',
    cell: row => {
      return <Row>
        <Col md="12">
          <p style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}><b>Nome:</b> {row.social_name != null ? row.social_name + ' | ' + row.name : row.name.toUpperCase()}</p>
        </Col>
        <Col md="12">
          <p><b>Documento:</b> <NumberFormat 
                    displayType={'text'} 
                    value={row.document} 
                    format={row.document.replace(/([^\d])+/gim, '').length > 11 ? '##.###.###/####-##' : "###.###.###-##"} 
                    /></p>
        </Col>

      </Row>
    } 
  },
  /* {
    name: "Contatos",
    selector: "contatos",
    sortable: false,
    width: '20%',
    cell: row => {
      return <Row>
        <Col md="12">
          <p style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}><b>Email:</b> {row.email}</p>
        </Col>
        <Col md="12">
          <p><b>Telefone:</b> <NumberFormat 
                    displayType={'text'} 
                    value={row.ddd + row.phone} 
                    format={"(##) #####-####"} 
                    /></p>
        </Col>

      </Row>
    } 
  },
  {
    name: "Cartões",
    selector: "cartao",
    sortable: false,
    cell: row => {

      return <Row>
        <Col md="12">
          <p style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}><b>{row.interest_black_card ? 'Cartão Black' : ''}</b></p>
        </Col>

        <Col md="12">
          <p style={{marginBottom: '0.5rem'}}><b>{row.interest_company_card ? 'Cartão Empresarial' : ''}</b></p>
        </Col>
        <Col md="12">
          <p style={{marginBottom: '0.5rem'}}><b>{row.interest_personal_card ? 'Cartão Pessoal' : ''}</b></p>
        </Col>
      </Row>

    }
  },
  {
    name: "Tag Auto",
    selector: "auto",
    sortable: false,
    width: '100px',
    cell: row => {
      if (row.is_tag_auto) {
        return row.license_plate ? row.license_plate.toUpperCase() : row.license_plate;
      }

      return  "S/N";
    }
  }, */
  /* {
    name: "Cargo",
    selector: "office",
    sortable: false,
    width: '100px',
    cell: row => row.office
  }, */
  {
    name: "Data",
    selector: "birthdate",
    sortable: false,
    width: '150px',
    cell: row => moment(row.created_at).format('DD/MM/YYYY HH:mm:ss')
  },
  {
    name: "Dados empresa",
    selector: "is_benefit",
    sortable: false,
    width: '200px',
    cell: row => {
      if (row.business_name) {
        return (row.business_name ? row.business_name.toUpperCase() : row.business_name) + '' + (row.collaborators ? '- Colaboradores: ' + row.collaborators : '');
      }

      return  "S/N";
    }
  },
  {
    name: "Empresa possui beneficio?",
    selector: "is_benefit",
    sortable: false,
    width: '150px',
    cell: row => {
      if (row.is_name_benefit) {
        return row.is_name_benefit ? row.is_name_benefit.toUpperCase() : row.is_name_benefit;
      }

      return  "S/N";
    }
  },
  {
    name: "Endereço",
    selector: "address",
    sortable: false,
    cell: row => {
      if (row.address) {
        return <div style={{ margin: 8 }}>
                <p>
                  <b>
                    <NumberFormat 
                      displayType={'text'} 
                      value={row.address.cep.replace(/([^\d])+/gim, '')}
                      format={"#####-###"} 
                      />,
                  </b>
                  <br/>{row.address.logradouro}, {row.address.numero} {row.address.complemento !== null && row.address.complemento !== undefined && row.address.complemento !== '' ? ' - ' + row.address.complemento : ''}
                  <br/>{row.address.bairro}
                  <br/>{row.address.cidade} / {row.address.uf}</p>
              </div>
      }

      return  "S/N";
    }
  }
];

export const getRows = (state, callback) => {
  var url = ENDPOINT + "multibeneficio/leads/listar?page=" + state.activePage + "&search=" + state.search;

  var document = state.document.replace(/([^\d])+/gim, '');
  if (state.is_cnpj === false) {
    url += "&document=" + document;
  }
  else {
    url += "&document=" + document;
  }

  if (state.seller_id) {
    url += "&seller_id=" + state.seller_id
  }

  if (state.status_user.length > 0) {
    url += "&status_usuario=" + state.status_user
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      //console.log(p)
      callback(p.data, p.last_page, p.total);
    })
    .catch(() => {
      toast.error('Ocorreu um erro no servidor!');
    })
}

export const getRowsPerPage = (page, state, callback) => {
  var url = ENDPOINT + "multibeneficio/leads/listar?page=" + page + "&search=" + state.search;

  var document = state.document.replace(/([^\d])+/gim, '');
  if (state.is_cnpj === false) {
    url += "&document=" + document;
  }
  else {
    url += "&document=" + document;
  }


  if (state.seller_id) {
    url += "&seller_id=" + state.seller_id
  }


  if (state.status_user.length > 0) {
    url += "&status_usuario=" + state.status_user
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      callback(p.data, p.last_page, p.total);
    })
    .catch(() => {
      toast.error('Ocorreu um erro no servidor!');
    })
}