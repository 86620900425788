import { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { getUser } from "../../auth/set_services";
import { getRowsPerPage, getRows } from "../../components/Tables/TableUsers/propsReservas";
import TableReservas from "../../components/Tables/TableUsers/TableReservas";
import PropagateLoader from "react-spinners/PropagateLoader";
import moment from "moment";
import BloquearUsuario from "../../components/Modals/Meubus/BloquearUsuario";

class PassagensQueroBus extends Component{
    constructor(props){
        super(props);
        this.state = {
            show_activity: false,
            show_filter: false,
            user_change: null,
            search: '',
            seller_id: '',
            valor_de: '0.00',
            valor_ate: '0.00',
            loading_table: true,
            edit_id: -1,
            edit_user: null,
            rowData: [],
            user_id: null,
            cpf_current: null,
            cnpj_current: null,
            phone_current: null,
            status_user: '',
            type_user: '',
            status: null,
            totalPage: 0,
            activePage: 1,
            is_cnpj: false,
            document: '',
            filter: false,
            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            visible_taggy_add: false,
            visible_user: false,
            user_id_block: null,
            user_block: false,
        }
    }
    
    componentDidMount(){
        getRows(this.state, this.callbackRows.bind(this));
    }

    onFilter = (filter) => {
        this.setState({filter});
        getRows(this.state, this.callbackRows.bind(this));
    }

    callbackRows = (rowData, totalPage) => {
        this.setState({rowData, totalPage, loading_table: false})
    }

    onPageChange = (activePage) => {
        var state = this.state;

        state.activePage = activePage;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});

        getRowsPerPage(activePage, state, this.callbackRows.bind(this));
    }

    handleChange = (event) => {
        var state = this.state;
        let name = event.target.name;
        let value = event.target.value;

        state[name] = value;
        state.activePage = 1;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});
        
        getRowsPerPage(1, state, this.callbackRows);
            
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    onStatusChange(status_user){
        var state = this.state;
        state.status_user = status_user;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});
        
        getRows(state, this.callbackRows);
    }
    filterShow(){
        this.setState({
            show_filter: !this.state.show_filter
        })
    }
    handleTypeStatusChange = status => {
        var state = this.state;
        state['status'] = status;
        state.loading_table = true;
        this.setState({ ...state });
        getRows(state, this.callbackRows);
    };

    handleOpenBlockUser = (user) => {
        this.setState({
            user_id_block: user.id,
            user_block: true,
        })
    }

    onClose = () => {
        this.setState({
            user_block: false,
        })
    }

    render(){
        return (
            <div>
                <h3>Passagens MeuBus</h3>
                <hr/>
                <Card hidden={!getUser().permissao_gestor_aqpago}>
                    <TableReservas
                        isSearch={this.handleChange}
                        onFilter={this.onFilter}
                        filter={this.state.filter}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        data={this.state.rowData}
                        is_cnpj={this.state.is_cnpj}
                        handleTypeStatusChange={this.handleTypeStatusChange}
                        status_user={this.state.status_user}
                        onStatusChange={this.onStatusChange.bind(this)}
                        type_user={this.state.type_user}
                        show_filter={this.state.show_filter}
                        filterShow={this.filterShow.bind(this)}
                        onPageChange={this.onPageChange}
                        handleOpenBlockUser={this.handleOpenBlockUser.bind(this)}
                        activePage={this.state.activePage}
                        state={this.state}
                        totalPage={this.state.totalPage}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhum usuário encontrado
                            </div></CardBody></Card>
                        }
                    />
                </Card>

                <BloquearUsuario 
                    visible={this.state.user_block}
                    title_nota={'Bloquear Usuário'}
                    user_id={this.state.user_id_block}
                    onClose={this.onClose}
                    />
            </div>
        )
    }
}

export default PassagensQueroBus;