import React, { Component } from 'react';
import { Card, CardBody, TabPane } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { TableProtocolo } from '../../components/Tables/TableProtocolos/TableProtocolo';
import Moment from 'moment';
import { getToken } from '../../auth/set_services';
import { ENDPOINT } from '../../auth/endpoint';
import axios from 'axios';
import { CgMenuRound } from 'react-icons/cg';
import { toast } from 'react-toastify';

/* const getId = () =>{
    if(window.location.pathname === '/contestacoes'){
        return null;
    }
    else{
        return window.location.pathname.replace('/contestacoes/', '')
    }
} */
const getOperadores = (callback) => {
    var url = ENDPOINT + "chat-atendimento/consultar-operadores";
    
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        var operadores_list = [];
        data.forEach((p, i) => {
            p['value'] = p.id;
            p['label'] = p.name;
            operadores_list.push(p);
        });

        callback(operadores_list)
    });
}

const getRows = (communication_relacao_id, state, callbackRows) => {

    if(typeof communication_relacao_id === 'undefined'){
        communication_relacao_id = false;
    }
    // else if(communication_relacao_id === -1){
    //     return null;
    // }

    var url = ENDPOINT + "protocolos/list?list=true";

    if (communication_relacao_id) {
        url += '&communication_relacao_id=' + communication_relacao_id
    }

    if (state.protocolo_atendimento.length > 0) {
        url += '&protocolo_atendimento=' + state.protocolo_atendimento
    }
    
    if (state.operador !== null) {
        url += '&operador=' + state.operador.id
    }
    
    if (state.document !== null) {
        url += '&document=' + state.document
    }

    console.log('endpoint', url)

    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        console.log(response)
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}
  
const getRowsPerPage = (communication_relacao_id, page, search, filter, state, callbackRows) => {
    if(typeof communication_relacao_id === 'undefined'){
        communication_relacao_id = null;
    }
    // else if(communication_relacao_id === -1){
    //     return null;
    // }

    var url = ENDPOINT + "protocolos/list?search=" + search + "&page=" + page;
  

    if (communication_relacao_id) {
        url += '&communication_relacao_id=' + communication_relacao_id
    }

    if (state.protocolo_atendimento.length > 0) {
        url += '&protocolo_atendimento=' + state.protocolo_atendimento
    }

    if (state.operador !== null) {
        url += '&operador=' + state.operador.id;
    }

    if (state.document.length > 0) {
        url += '&document=' + state.document;
    }

    if (filter === true) {
      url += '&date_from=' + state.date_from + '&date_to=' + state.date_to;
    }

    console.log('endpoint', url)
  
    axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + getToken()
      }
    }).then((response) => {
      callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

/* const send_messages_mail = (cod_chat, onToast) => {
    var url = ENDPOINT + "chat-atendimento/enviar-historico/"+cod_chat;
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        onToast(data)
    })
    .catch((error) => {
        var response = '';
        var obj; 
        if (error.message === 'Network Error') {
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onToast(obj);
            return obj;
        }
        var data = error.response.data;
        if(data){
            onToast(data);
            return data;
        }
        else{
             response = '{"success": "false", "errors": "Ocorreu um erro desconhecido" }';
             obj = JSON.parse(response);
            onToast(obj);
            return obj;
        }
    });
} */
class ProtocoloClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cod_chat: null,
            rowData: [],
            activePage: 1,
            loading_table: true,
            //email: getId(),
            document: props?.document ? props.document : '',
            protocolo_atendimento: '',
            operadores_list: [],
            operador: null,
            totalPage: 1,
            filter: false,
            mensagens_modal: false,
            mensagens_current: [],
            search: '',
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
        this.onMessage = this.onMessage.bind(this);
    }
    
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(this.props.communication_relacao_id, 1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentDidMount() {
        getOperadores(this.callbackOperadores.bind(this));
        getRows(this.props.communication_relacao_id, this.state, this.callbackRows.bind(this));
    }
    componentWillUpdate(nextProps, nextState) {
       if (nextProps.communication_relacao_id !== this.props.communication_relacao_id) {
            getRows(nextProps.communication_relacao_id, this.state, this.callbackRows.bind(this))
        }
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }
    callbackOperadores = (operadores_list) => {
        this.setState({operadores_list});
    }

    onFilter = (filter) => {
        this.setState({ filter });
        getRowsPerPage(this.props.communication_relacao_id, 1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(this.props.communication_relacao_id, activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onMessage = (mensagens_current, cod_chat, mensagens_modal) => {
        this.setState({ mensagens_current, cod_chat, mensagens_modal });
    }
    onToast = (data) => {
        if(data.success === true){
            toast.success(data.message);
        }
        else{
            toast.error(data.errors);
        }
        //this.props.refresh();
    }

    handleSelectChange = operador => {
        var state = this.state;
        state['operador'] = operador;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(this.props.communication_relacao_id, 1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    };

    render() {
        return (
            
            <TabPane tabId={this.props.active}>
            <br />
            <h5>&nbsp;&nbsp;
                <CgMenuRound style={{width: '30px', height: '30px'}}/> 
                    Protocolos de Atendimento {this.props.razao_social}
                </h5>
                <hr />
                <Card>
                    <TableProtocolo
                        clientChat
                        isSearch={this.handleChange}
                        operadores_list={this.state.operadores_list}
                        data={this.state.rowData}
                        operador={this.state.operador}
                        handleSelectChange={this.handleSelectChange.bind(this)}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        onMessage={this.onMessage.bind(this)}
                        noDataComponent={this.state.loading_table === true ?
                            <Card style={{ border: 0, padding: '10vh' }}><CardBody>
                                <div><div className="sweet-loading">
                                    <PropagateLoader color={'#52c565'} loading={20} size={30} />
                                </div></div></CardBody></Card> :
                            <Card style={{ border: 0 }}><CardBody><div>
                                Nenhum chat encontrado
                            </div></CardBody></Card>
                        }
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
            </TabPane>
        )
    }
}

export default ProtocoloClient;