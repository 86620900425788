import { Component } from "react";
import { FaUser } from "react-icons/fa";
import { MdPhone } from "react-icons/md";
import { VscNewFile } from "react-icons/vsc";
import Select from "react-select";
import { Button, ButtonGroup, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import { InputMasked } from "../../InputText/InputMasked";
import { InputText } from "../../InputText/InputText";
import AsyncSelect from 'react-select/async';
import axios from "axios";
import ModelReturn from "../ReturnsApiModals/ModelReturn";

const atendimento_realizado = [
    {   value: 'ligacao', label: 'Ligação telefônica' },
    {   value: "chat", label: "Atendimento por chat" }
]
class AddProtocolo extends Component{
    constructor(props){
        super(props);
        this.state = {
            usuario: null,
            usuario_invalid: '',
            email: '',
            email_invalid: false,
            document: '',
            document_invalid: false,
            telefone: '',
            telefone_invalid: false,
            option_name: '',
            atendimento_realizado: null,
            loading: false,
            error_message: '',
            success_message: ''
        }
    }
    getOption = (value) => {
        if(this.state.option_name === value) return true;
        return false;
    }
    setOption = (option_name) => {
        this.setState({option_name})
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({ [name]: value });
    }

    loadOptions = (inputValue, callback) => {
        var url = ENDPOINT + "user/all?search=" + inputValue;
        
        if(this.state.option_name === 'preuser'){
            url = ENDPOINT + "user_chat/all?search=" + inputValue;
        }
        else{
            url = ENDPOINT + "user/all_aqbank?page=1&search=" + inputValue;
        }

        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p.data;
            if(this.state.option_name === 'preuser'){
                data.forEach((p, i) => {
                    data[i].value = p.id;
                    data[i].document = (p.cnpj ? p.cnpj : p.cpf);
                    data[i].label = p.nome+' '+p.sobrenome + (p.empresa !== null ? ' - ' + p.empresa.razao_social : '');
                });
            }
            else{
                data.forEach((p, i) => {
                    data[i].value = p.id;
                    data[i].document = (p.cnpj ? p.cnpj : p.cpf);
                    data[i].label = (p.responsavel !== null? 
                        p.responsavel.nome + ' '+p.responsavel.sobrenome +
                        (p.nome_fatura !== null ? ' - '+p.nome_fatura : '')
                            : 
                        p.nome) + ' - ' + (p.cnpj ? p.cnpj : p.cpf);
                });
            }

            callback(this.filter(inputValue, data));
        })
        .catch(() => {
            callback(this.filter(inputValue, []));
        })
    }

    filter = (inputValue, data) => {
        return data.filter(i =>
            (i.label || i.responsavel).toLowerCase().includes(inputValue.toLowerCase())
        );
    }

    onRegister() {
        //if(this.validateFields() === 0){
            this.setState({loading: true});
            this.store();
        //}
    }

    store = () => {
        var url = ENDPOINT+"protocolos/store";

        var formData = new FormData();
        formData.append('tipo_protocolo', this.state.option_name);
        formData.append('atendimento_realizado', this.state.atendimento_realizado.label);
        switch(this.state.option_name){
            case 'new': {
                formData.append('document', this.state.document);
                formData.append('email', this.state.email);
                formData.append('telefone', this.state.telefone.replace(/([^\d])+/gim, ''));
                break;
            }
            case 'phone': {
                formData.append('telefone', this.state.telefone.replace(/([^\d])+/gim, ''));
                break;
            }
            default: {
                formData.append('document', this.state.usuario.document);
                break;
            }
        }
    
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            this.onData(data);
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onData(obj);
            }
            var data = error.response.data;
            this.onData(data);
        });
    }

    onData = (data) => {
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onError = () => {
        this.setState({
            error_message: '',
            loading: false
        })
    }
    onClose = () =>{
        this.setState({
            usuario: null,
            usuario_invalid: '',
            email: '',
            email_invalid: false,
            document: '',
            document_invalid: false,
            telefone: '',
            telefone_invalid: false,
            option_name: '',
            atendimento_realizado: null,
            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose(false);
    }

    selectedStep = () => {
        switch(this.state.option_name){
            case 'phone': return (
                <> 
                    <FormGroup>
                        <InputMasked
                            mask={"(99) 99999-9999"}
                            type="text" 
                            placeholder="Telefone" 
                            name="telefone"
                            value={this.state.telefone}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                </>
            );
            case 'user': return (
                <> 
                    <FormGroup>
                        <AsyncSelect
                            key={this.state.option_name}
                            cacheOptions
                            loadOptions={this.loadOptions}
                            defaultOptions
                            value={this.state.usuario} 
                            isOptionDisabled={(option) => option.document === null}
                            placeholder="Escolha o usuário"
                            onChange={(usuario) => this.setState({usuario})}
                        />
                    </FormGroup>
                </>
            );
            case 'preuser': return (
                <> 
                    <FormGroup>
                        <AsyncSelect
                            key={this.state.option_name}
                            cacheOptions
                            loadOptions={this.loadOptions}
                            defaultOptions
                            isOptionDisabled={(option) => option.document === null}
                            value={this.state.usuario} 
                            placeholder="Escolha o usuário"
                            onChange={(usuario) => this.setState({usuario})}
                        />
                    </FormGroup>
                </>
            );
            case 'new': return (
                <> 
                    <FormGroup>
                        <InputMasked
                            mask={"999.999.999-99"} 
                            type="text" 
                            placeholder="CPF/CNPJ" 
                            name="document"
                            value={this.state.document}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <InputText
                            type="email" 
                            placeholder="E-mail" 
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <InputMasked 
                            mask={"(99) 99999-9999"} 
                            type="text" 
                            placeholder="Telefone" 
                            name="telefone"
                            value={this.state.telefone}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                </>
            )
            default: return (<></>)
        }
    }
    render(){
        return (
            <Modal isOpen={this.props.visible}>
                <ModalHeader>Gerar Protocolo</ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <Form>
                        <FormGroup>
                            <center>
                                <ButtonGroup size="sm" style={{width: '100%', border: '0'}}>
                                    <Button onClick={() => this.setOption('new')} color="dark" outline={!this.getOption('new')}><VscNewFile/><br/>Novo<br/>usuário</Button>
                                    <Button onClick={() => this.setOption('user')} color="dark" outline={!this.getOption('user')}><FaUser/><br/>Usuário <br/>cadastrado</Button>
                                    <Button onClick={() => this.setOption('preuser')} color="dark" outline={!this.getOption('preuser')}><FaUser/><br/>Usuário<br/> pré-cadastrado</Button>
                                    <Button onClick={() => this.setOption('phone')} color="dark" outline={!this.getOption('phone')}><MdPhone/> <br/>Somente<br/>Telefone</Button>
                                </ButtonGroup>
                            </center>
                        </FormGroup>
                        {this.state.option_name !== '' ? 
                        <>
                            {this.selectedStep()}
                            <FormGroup>
                                <Select
                                    placeholder="Atendimento"
                                    options={atendimento_realizado}
                                    onChange={(atendimento_realizado) => this.setState({atendimento_realizado})}
                                    value={this.state.atendimento_realizado}
                                />
                            </FormGroup>
                        </>
                        :
                        <></>}
                    </Form>
                </ModalBody>
                <ModalBody
                    style={{
                        minHeight: '114px',
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                    />

                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose()}>Fechar</Button>
                    <Button color="success" onClick={() => this.onRegister()}>Salvar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default AddProtocolo;