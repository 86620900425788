import axios from "axios";
import React, { Component } from "react";
import { MdArrowBack, MdCheck, MdClear, MdMail, MdWarning, MdAttachFile } from "react-icons/md";
import { BsLock, BsUnlock } from 'react-icons/bs';
import { IoReceiptOutline } from 'react-icons/io5';
import { Button, ButtonGroup, Card, CardBody, CardFooter, Col, Collapse, Form, FormFeedback, FormGroup, Input, Row } from "reactstrap";
import Dropzone from 'react-dropzone';
import styled from "styled-components";
import { ENDPOINT } from "../../../auth/endpoint";
import { getPermissoes, getToken } from "../../../auth/set_services";
import Select from "react-select";
import { list_prevencao_status, list_status_pagamentos } from "../../../auth/listas";
import { InputMasked } from '../../../components/InputText/InputMasked';
import { InputText } from "../../../components/InputText/InputText";
import { toast } from 'react-toastify';
import PropagateLoader from "react-spinners/PropagateLoader";
import ClipLoader from 'react-spinners/ClipLoader'
import moment from "moment";
import NumberFormat from "react-number-format";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import sanitizeHtml from 'sanitize-html';
import ReactTooltip from "react-tooltip";
import GoogleMapReact from 'google-map-react';
import DataTable from 'react-data-table-component';
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { FaMapMarkerAlt, FaPen, FaPlus, FaSleigh, FaTrash } from "react-icons/fa";
import Pusher from 'pusher-js';
import { ButtonRed } from "../../../components/ButtonStylish/ButtonRed";
import { DropdownIcon } from "../../../components/DropdownIcon/DropdownIcon";
import SendEmailByCard from "../../../components/Modals/CreditCard/SendEmailByCard";
import { MessageNoTable } from "../../MessageTable/MessageNoTable";

import { Enderecos } from "../Fornecedores/Enderecos";
import { Contatos } from "../Fornecedores/Contatos";

var CurrencyInput = require('react-currency-masked-input');


const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
    .reclamado {
        display: none;
    }
    .row {
        margin: 10px 0px;
    }
    .btn-save {
        float: right;
    }
    .btn-nota {
        margin-top: 10px;
    }
    .file-iframe {
        width: 100%;
        height: 800px; 
    }
    .btn-arquivo {
        margin-right: 10px;
        color: #fff;
        border: 0px;
    }
    .subtitle {
        font-weight: 700;
        margin-top: 20px !important;
    }
    .error-active {
        display: block;
        color: red;
    }
    .error-none {
        display: none;
    }
    .input-error {
        border: 1px solid red;
    }
    .label-error {
        color: red;
    }
    .select-error {
        border: 1px solid red;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
    }
    .card {
        border: 1px solid #ced4da;
        border-left: 5px solid #28a745;
        border-radius: 0.25rem;
        -webkit-border-radius: 0.25rem;
        -moz-border-radius: 0.25rem;
        
    }
    .card h6 {
        font-weight: 600;
        padding: 10px;
        border-radius: 3px 3px 0px 0px;
        margin: 0px;
    }
    .card-box {
        padding: 1rem;
        height: auto;
        min-height: 180px;
    }
    .card-box div {
        font-size: 14px;
    }
    .button-edit {
        position: absolute;
        bottom: 0.5rem;
        right: 1rem;
    }
    .button-remove {
        position: absolute;
        bottom: 0.5rem;
        left: 1rem;
    }
    .btn-cancel {
        float: right;
    }
    .add-address-btn {
        position: absolute;
        top: 0.5rem;
        right: 2rem;
    }
    textarea {
        text-transform: uppercase;
    }
    .website {
        text-transform: lowercase;
    }
    .col-cards {
        margin-bottom: 1rem;
    }
`;


const DropArea = styled.div`
    border-style: dashed;
    border-color: #38B76E;
    padding: 1%;
    cursor: pointer;
    &:drag {
        opacity: 0.5;
    }
`;

const ButtonUpload = styled.button`
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0;
    padding-left: 7px;
    padding-right: 7px;
    top: -1px;
    position: relative;
`;


const getId = () => {
    return window.location.pathname.replace('/empresa-detalhes/', '')
}

class ResumoEmpresa extends Component {
    constructor(props){
        super(props);
        this.state = {
            show_form: true,
            loading_page: false,
            loading_button: false,
            files: [],
            notas: [],
            nota_mensagem: '',


            id_empresa: null,
            razao_social: null,
            cnpj: null,
            regime_id: null,
            data_criacao: null,
        }

        this.inputFile = React.createRef();
    }

    onDrop = (file) => {
        var state = this.state;
        file.forEach(file_current => {
            state.files.push(file_current);
        });
        this.setState({
            ...state
        })
    }
    removeAttach = (index) => {
        var state = this.state;
        state.files.splice(index, 1);
        this.setState({
            ...state
        })
    }

    handleFileChange = (event) => {
        const files = event.target.files;

        var state = this.state;
        Array.from(files).forEach(file_current => {
            state.files.push(file_current);
        });
        this.setState({
            ...state
        })
    }

    handletoastSuccessMessage = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    handletoastMessage = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    handleSendFormEmpresa = (ev) => {
        // var url = ENDPOINT + "/financeiro/empresa/enviar";
        var url = "http://localhost:8000/financeiro/empresa/enviar";

        ev.preventDefault();
        //this.setState({disable_send_button: true})
        this.setState({loading_page: true})
        this.setState({loading_btn_save_form: true})

        let formData = new FormData();

        Object.entries(this.state).forEach(([field, val]) => {
            if (field !== 'anexos') {
                formData.append(field, val);
            }
        })

        if(this.state.files.length > 0){
            this.state.files.forEach((file, index) => {
                formData.append('anexos[]', file);
            });
        }

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }})
        .then((response) => {
            
            console.log(response)
        })
        .catch(function (error) {

            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              //console.log(error.response.status); // status code
              //console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              //console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error);
        })
    }

    componentDidMount(){
        
        if (getId() !== 'novo') {
            this.handleLoadDataEmpresa();
        } else {
            this.setState({
                
                // tipo_pessoa: 'F',
                // tipo_pessoa_select: {
                //     'label': 'Pessoa fisica',
                //     'value': 'F'
                // },
                // documento: '000.000.001-91',
                // nome: 'Fornecedor de teste',
                // codigo: '12345',
            })
        }
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        
        this.setState({ [name]: value });
    }   

    handleLoadDataEmpresa = () => {
        //var url = ENDPOINT + "financeiro/empresa/" + getId();
        var url = "http://localhost:8000/financeiro/empresa/" + getId();

        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
    
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            if (p.success === true) {
                let data = p.data;
                let state = this.state;

                state.id_empresa = data.id;
                state.razao_social = data.razao_social;
                state.cnpj = data.cnpj;
                state.regime_id = data.regime_id;
                state.data_criacao = data.data_criacao;

                this.setState({...state})
            }  
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!');
            console.log(error)
        })
    }

    deletarNota = (id) => {
        this.setState({
            loading_nota_id: id
        });
        
        // var url = ENDPOINT+"notas/delete/"+id;
        // axios.delete(url, {
        //     headers: {
        //         Authorization: 'Bearer '+getToken()
        //     }
        // })
        // .then(() => {
        //     this.getNotas(this.state.id);
        //     toast.success('Deletado com sucesso');
        //     this.setState({
        //         loading_nota_id: null
        //     });
        // })
        // .catch((error) => {
        //     var data = error.response.data;
        //     toast.error(data.errors);
        //     this.setState({
        //         loading_nota_id: null
        //     });
        // })
    }

    handleAddNota = () => {
        var url = ENDPOINT + "financeiro/nota";

        var formData = new FormData();

        formData.append('id_processo', getId());
        formData.append('nota_mensagem', this.state.nota_mensagem);

        this.setState({loading_btn_add_nota: true})

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var notas_priv = this.state.notas_privadas;
            notas_priv.push(response.data.data);

            this.setState({notas_privadas: notas_priv});
            this.setState({is_open_nota: false});
            this.setState({nota_mensagem: null});
            this.setState({loading_btn_add_nota: false})
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!');
        })
    }

    render(){
        return(
            <Div>
                <h3>
                    <Button 
                        outline 
                        style={{border: 'none'}}
                        onClick={() => window.location.href = '/empresas'}
                    >
                        <MdArrowBack style={{padding: 0}}/>
                    </Button>&nbsp;&nbsp;Cadastro de Empresa
                </h3>
                <hr />
                <Row hidden={!this.state.loading_page}>
                    <Col md={12}>
                        <center>
                            <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                        </center>
                        <br/>
                    </Col>
                </Row>
            <Div hidden={!this.state.show_form}>
                <Row hidden={!this.state.loading_seller_data}>
                    <Col md={12}>
                        <center>
                            <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                        </center>
                        <br/>
                    </Col>
                </Row> 
                <Row>
                    <Col md={12}>
                        <Button 
                            className="btn btn-success btn-save" 
                            onClick={this.handleSendFormEmpresa}>
                            {this.state.loading_btn_save_form === true ?
                                <>
                                    <ClipLoader color="#fff" size={12}/>
                                    &nbsp;Salvando...
                                </>
                            : 
                                'Salvar Empresa'
                            }
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                            
                            <Row>
                                <Col md={6}>
                                    <label htmlFor="razao_social">Razão Social</label>
                                    <Input
                                        name="razao_social"
                                        placeholder=""
                                        value={this.state.razao_social}
                                        type="text"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                                <Col md={6}>
                                    <label htmlFor="cnpj">CNPJ</label>
                                    <InputMasked
                                        name="cnpj"
                                        mask={'99.999.999/9999-99'}
                                        placeholder={'00.000.000/0000-00'}
                                        value={this.state.cnpj}
                                        onChange={this.handleInputChange}
                                        onBlur={this.handleChangeDocument}
                                        /> 
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <label htmlFor="regime">Regime</label>
                                    <Select
                                    name="regime"
                                    placeholder="Selecione..."
                                    options={[]}
                                    onChange={(tipo_pessoa_select) => {
                                        //this.setState({tipo_pessoa_select})
                                        //this.setState({tipo_pessoa: tipo_pessoa_select.value});
                                    }}

                                    value={this.state.tipo_pessoa_select}
                                        />
                                </Col>
                                <Col md={6}>
                                    <label htmlFor="criacao">Data de Criação</label>
                                    <Input
                                        name="data_criacao"
                                        placeholder=""
                                        value={this.state.data_criacao}
                                        type="date"
                                        onChange={this.handleInputChange}
                                        />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <label htmlFor="aneoxs">Anexos</label>
                                    <Dropzone onDrop={(drop) => this.onDrop(drop)}>
                                        {({ getRootProps, getInputProps }) => (
                                        <DropArea {...getRootProps({
                                            onClick: event => event.stopPropagation()
                                        })}>
                                            <input {...getInputProps()} />
                                                {this.state.files.length > 0 ? 
                                                    <>
                                                        <Row> 
                                                            {this.state.files.map((e, index) => {
                                                                return <Col key={index} md={4} style={{paddingBottom: 4}}>
                                                                    <Card>
                                                                        <p style={{margin: '3px'}}>
                                                                            <MdAttachFile style={{width: '32px'}}/>
                                                                            {e.name}&nbsp; 
                                                                            <Button 
                                                                                style={{padding: 0, border: 3, float: 'right'}} 
                                                                                color="danger" 
                                                                                outline 
                                                                                onClick={
                                                                                    () => this.removeAttach(index)
                                                                                }>
                                                                                    <MdClear style={{padding: 0}}/>
                                                                            </Button>
                                                                        </p>
                                                                    </Card>
                                                                </Col>
                                                            })}
                                                            <br/>
                                                        </Row>
                                                        <p style={{ margin: 0 }}>
                                                        <MdAttachFile style={{ width: '32px' }} />
                                                            Arraste o arquivo ou&nbsp;
                                                            <ButtonUpload 
                                                                onClick={() => this.inputFile.current.click()} 
                                                                className="btn btn-dark" 
                                                                color="primary">
                                                                Clique Aqui
                                                            </ButtonUpload> para adicionar mais anexos
                                                            <input type="file" ref={this.inputFile} hidden onChange={this.handleFileChange} />
                                                    </p>
                                                </>
                                                :
                                                <>
                                                    <p style={{ margin: 0 }}>
                                                        <MdAttachFile style={{ width: '32px' }} />
                                                            Arraste o arquivo ou&nbsp;
                                                            <ButtonUpload 
                                                                onClick={() => this.inputFile.current.click()} 
                                                                className="btn btn-dark" 
                                                                color="primary">
                                                                Clique Aqui
                                                            </ButtonUpload> para adicionar anexos
                                                            <input type="file" ref={this.inputFile} hidden onChange={this.handleFileChange} />
                                                    </p>
                                                </>
                                            }
                                        </DropArea>
                                    )}
                                    </Dropzone>
                                </Col>
                            </Row>


                            <Row>
                                <Col md={12}>
                                    <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                        <h5>Notas</h5><hr/>
                                        <ul style={{listStyleType: 'circle'}}>
                                            {this.state.notas.map(p => {
                                                return <li key={p.id} style={{marginBottom: '7px'}}>
                                                    <b>Operador:</b> {p.operador !== null ? p.operador : 'Sem Informações'} <br/>
                                                    <div style={{textTransform: 'uppercase', border: '1px solid #7f7f7f66', borderRadius: 5, padding: '15px'}}>
                                                        {p.nota.split('\n').map((x, i, {length}) => (
                                                            <p style={{margin: (i + 1 === length ? 0 : 3), fontSize: 14}}>{x}</p>
                                                        ))}
                                                    </div>
                                                    <small>
                                                        {moment(p.data).format('DD/MM/YYYY HH:mm')}&nbsp;
                                                        {/* <ButtonRed 
                                                            hidden={!this.hiddenDelete(p.admin_id)}
                                                            disabled={this.state.loading_nota_id === p.id}
                                                            onClick={() => this.deletarNota(p.id)}>
                                                                Apagar        
                                                        </ButtonRed> */}
                                                    </small>
                                                </li>
                                            })}
                                        </ul>
                                        {this.state.notas.length === 0 ? <p>Nenhuma nota</p> : ''}
                                        <Button 
                                            color="success" 
                                            onClick={() => this.setState({is_open_nota: !this.state.is_open_nota})}>
                                                <FaPen /> Adicionar Nota
                                        </Button>
                                        <Collapse isOpen={this.state.is_open_nota}>
                                            <br/>
                                            <Card>
                                                <CardBody>
                                                    <Input
                                                        name="nota_mensagem"
                                                        placeholder="Descrição"
                                                        value={this.state.nota_mensagem}
                                                        type="textarea"
                                                        style={{
                                                            height: '124px',
                                                            resize: 'none'
                                                        }}
                                                        onChange={this.handleInputChange}
                                                    />
                                                    {this.state.notas.length > 0 ? 
                                                        <Button 
                                                            className="btn btn-success btn-nota"
                                                            onClick={this.handleAddNota}>
                                                            {this.state.loading_btn_add_nota === true ?
                                                                <>
                                                                    <ClipLoader color="#fff" size={12}/>
                                                                    &nbsp;Adicionando...
                                                                </>
                                                            : 
                                                                'Adicionar.'

                                                            }
                                                        </Button>
                                                    : 
                                                    ''
                                                    }

                                                </CardBody>
                                            </Card>
                                        </Collapse>

                                    </div>
                                    
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <br/>
                <br/>
                
                <Row>
                    <Col md={12}>
                        <Button 
                            className="btn btn-success btn-save" 
                            onClick={this.handleSendFormEmpresa}>
                            {this.state.loading_btn_save_form === true ?
                                <>
                                    <ClipLoader color="#fff" size={12}/>
                                    &nbsp;Salvando...
                                </>
                            : 
                                'Salvar Empresa'
                            }
                        </Button>
                    </Col>
                </Row>
            </Div>
            <br/>
            <br/>
        </Div>

        )
    }
}

export default ResumoEmpresa;