import React, { Component } from 'react';
import { Button, Card, CardBody } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { TableEmails } from '../../components/Tables/TableEmails/TableEmails';
import Moment from 'moment';
import { getRows, getRowsPerPage } from '../../components/Tables/TableEmails/propsEmails';
import { getPermissoes, getUser } from '../../auth/set_services';
import { MdEmail } from 'react-icons/md';
import SendEmail from '../../components/Modals/SendsModals/SendEmail';

/* const getId = () =>{
    if(window.location.pathname === '/contestacoes'){
        return null;
    }
    else{
        return window.location.pathname.replace('/contestacoes/', '')
    }
} */
const getEmail = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const get_email = urlParams.get('email');
    if(get_email !== null){
        return get_email;
    }
    else{
        return "";
    }
}
class Emails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            activePage: 1,
            loading_table: true,
            email: getEmail(),
            totalPage: 1,
            filter: false,
            search: '',
            cpf_cnpj: '',
            visible_email: false,
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentDidMount() {
        getRows(this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    onFilter = (filter) => {
        this.setState({ filter });
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onClose(visible_email){
        this.setState({visible_email})

        if(visible_email === false){
            getRows(this.state, this.callbackRows.bind(this));
        }
    }
    render() {
        return (
            <div>
                <h3>Emails</h3>
                <hr />
                <div hidden={!getUser().permissao_omnichannel}>
                    <Button onClick={() => this.onClose(true)} style={{marginBottom: 10}} color="success"><MdEmail /> Novo e-mail</Button>
                </div>
                <Card hidden={!getUser().permissao_omnichannel}>
                    <TableEmails
                        isSearch={this.handleChange}
                        data={this.state.rowData}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        email={this.state.email}
                        noDataComponent={this.state.loading_table === true ?
                            <Card style={{ border: 0, padding: '10vh' }}><CardBody>
                                <div><div className="sweet-loading">
                                    <PropagateLoader color={'#52c565'} loading={20} size={30} />
                                </div></div></CardBody></Card> :
                            <Card style={{ border: 0 }}><CardBody><div>
                                Nenhum e-mail encontrado
                            </div></CardBody></Card>
                        }
                        cpf_cnpj={this.state.cpf_cnpj}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <SendEmail
                    email_current={this.props.email}
                    visible={this.state.visible_email}
                    communication_relacao_id={this.props.communication_relacao_id}
                    onClose={() => this.onClose(false)}
                />
                <Card hidden={getUser().permissao_omnichannel}>
                    <CardBody>
                        <p>Você não tem permissões para acessar essa função</p>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default Emails;