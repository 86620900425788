import { Component } from "react";
import { Button, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select from 'react-select';
import { InputText } from "../../InputText/InputText";
import { getToken } from "../../../auth/set_services";
import { ENDPOINT } from "../../../auth/endpoint";
import AsyncSelect from 'react-select/async';
import moment from "moment";
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import axios from "axios";
import { maquininhas_list } from "../../../auth/listas";
import { InputMasked } from "../../InputText/InputMasked";
import { cpf, cnpj } from 'cpf-cnpj-validator';

const transporte_list = [
    {   value: 'aqenvio', label: 'AQEnvio'  },
    {   value: 'correios', label: 'Correios'  },
    {   value: 'totalexpress', label: 'Total Express'  },
];

class RemessaModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            is_cnpj: false,
            is_venda: true,
            cpf_cnpj: '',
            cpf_cnpj_invalid: false,
            email: '',
            telefone: '',
            cliente: null,
            cliente_invalid: false,
            id_venda: null,
            id_venda_invalid: false,
            produtos_list: [],
            produtos: [],
            produtos_invalid: false,
            servico_transporte: null,
            servico_transporte_invalid: false,
            rastreio_transporte: '',
            loading: false,
            error_message: '',
            success_message: '',
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.remessa !== null && nextProps.remessa !== this.props.remessa) {
            this.onIdVenda(nextProps.remessa);
        }
    }

    loadOptions = (inputValue, callback) => {
        var url = ENDPOINT + "maquininhas/list?search="+inputValue+"&status=APROVADO&remessa_cad=true";
        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p.data;

            data.forEach((p, i) => {
                data[i].value = p.id;
                data[i].label = moment(p.created_at).format('DD/MM/YYYY HH:mm') + ' > ' +p.id_transacao + ' - Realizada por: ' + (p.user !== null ? p.user.nome + ' ' + p.user.sobrenome : 'Sem informações');
                data[i].produtos = p.produtos;
            });

            callback(this.filter(inputValue, p.data));
        })
        .catch(() => {
            callback(this.filter(inputValue, []));
        });
    }

    filter = (inputValue, data) => {
        return data.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }
    
    onIdVenda = (venda) => {
        var data = {};
        data.value = venda.id;
        data.label = moment(venda.created_at).format('DD/MM/YYYY HH:mm') + ' > ' +venda.id_transacao + ' - Realizada por: ' + (venda.user !== null ? venda.user.nome + ' ' + venda.user.sobrenome : 'Sem informações');

        this.setState({id_venda: data, produtos: []});

        var produtos_list = [];

        venda.produtos.forEach(e => {
            if(e.produto != 'SHOPAQPAGO' && e.produto != 'LINKPAY'){
                var prod = e;
                prod.label = e.produto;
                prod.value = e.id;
                produtos_list.push(prod);
            }
        });

        this.setState({produtos_list})
    }
    onClose = () => {
        this.setState({
            is_cnpj: false,
            is_venda: true,
            cpf_cnpj: '',
            cpf_cnpj_invalid: false,
            email: '',
            telefone: '',
            cliente: null,
            cliente_invalid: false,
            id_venda: null,
            id_venda_invalid: false,
            produtos_list: [],
            produtos: [],
            produtos_invalid: false,
            servico_transporte: null,
            servico_transporte_invalid: false,
            rastreio_transporte: '',
            loading: false,
            error_message: '',
            success_message: '',
        });
        this.props.onClose(false);
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }
    onRegister = () => {
        if (this.validateFields() === 0) {
            this.registrarRemessa(this.onReturn.bind(this));
        }
    }

    validateFields = () => {
        var invalids = 0;

        if(this.state.is_venda == true){
            if(this.state.id_venda !== null){
                this.setState({id_venda_invalid: false});
            }
            else{
                invalids++;
                this.setState({id_venda_invalid: true});
            }
        }
        else{
            if(this.state.is_cnpj == false && cpf.isValid(this.state.cpf_cnpj.replace(/([^\d])+/gim, ''))){
                this.setState({cpf_cnpj_invalid: false});
            }
            else if(this.state.is_cnpj == true && cnpj.isValid(this.state.cpf_cnpj.replace(/([^\d])+/gim, ''))){
                this.setState({cpf_cnpj_invalid: false});
            }
            else{
                invalids++;
                this.setState({cpf_cnpj_invalid: true});
            }
        }

        if(this.state.produtos.length > 0){
            this.setState({produtos_invalid: false});
        }
        else{
            invalids++;
            this.setState({produtos_invalid: true});
        }

        if(this.state.servico_transporte !== null){
            this.setState({servico_transporte_invalid: false});
        }
        else{
            invalids++;
            this.setState({servico_transporte_invalid: true});
        }

        return invalids;
    }

    onReturn(data) {
        if (data.success === true) {
            this.setState({
                success_message: data.message
            })
        }
        else {
            this.setState({
                error_message: data.errors
            })
        }
    }
    

    registrarRemessa = (onData) => {
        this.setState({loading: true})
        var url = ENDPOINT+"remessa/create";
    
        var formData = new FormData();
        formData.append('servico', this.state.servico_transporte.value);
        //formData.append('descricao', state.descricao);
        if(this.state.is_venda == true){
            this.state.produtos.forEach((e, i) => {
                formData.append('produtos['+i+']', e.value);
            });
        }
        else{
            formData.append('cpf_cnpj', this.state.cpf_cnpj.replace(/([^\d])+/gim, ''));
            formData.append('email', this.state.email);
            formData.append('telefone', this.state.telefone.replace(/([^\d])+/gim, ''));
            this.state.produtos.forEach((e, i) => {
                formData.append('produtos_add['+i+']', e.value);
            });
        }

        if(this.state.rastreio_transporte.length > 0){
            formData.append('cod_rastreio', this.state.rastreio_transporte);

        }

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            onData(data);
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
            var data = error.response.data;
            onData(data);
            return data;
        });
    
    }
    loadOptionsClientes = (inputValue, callback) => {
        //var url = ENDPOINT + "user/all_aqbank?nome=" + inputValue;
        var url = ENDPOINT + "user/all?search=" + inputValue;
        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p.data;

            data.forEach((p, i) => {
                data[i].value = p.id;
                data[i].email = p.email;
                data[i].label = (p.nome !== null ? p.nome+" "+p.sobrenome : p.nome_fatura) +" - "+ p.email;
            });

            callback(this.filterClientes(inputValue, p.data));
        });
    }

    filterClientes = (inputValue, data) => {
        return data.filter(i =>
            (i.nome !== null ? i.nome : '').toLowerCase().includes(inputValue.toLowerCase())
        );
    }

    selectVenda = (is_venda) => {
        this.setState({
            is_venda,
            id_venda: null, 
            cliente: null,
            produtos: []
        });


        if(is_venda == false){
            this.setState({
                produtos_list: maquininhas_list
            })
        }
        else{
            this.setState({
                produtos_list: []
            })
        }
    }
    
    handleInputChange = (e) => {
        const { name, value } = e.target;
        
        this.setState({ [name]: value });
      }
    render(){
        return(
            <Modal isOpen={this.props.visible}>
                <ModalHeader>
                    Nova Remessa
                </ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <FormGroup>
                        {/* <Select
                            placeholder="Selecione o ID da Venda"
                        /> */}
                        <input 
                            type="radio" 
                            name="remessa_tipo" 
                            checked={this.state.is_venda == true}
                            onChange={() => this.selectVenda(true)}/> 
                        <label>&nbsp;Por Venda &nbsp;&nbsp;</label>
                        <input 
                            type="radio" 
                            name="remessa_tipo" 
                            checked={this.state.is_venda == false}
                            onChange={() => this.selectVenda(false)}/>
                        <label>&nbsp;Por Cliente &nbsp;&nbsp;</label> 
                    </FormGroup>
                    <FormGroup hidden={this.state.is_venda == false}>
                        <AsyncSelect
                            className="select-option"
                            cacheOptions
                            loadOptions={this.loadOptions}
                            defaultOptions
                            isDisabled={this.props.remessa !== null}
                            value={this.state.id_venda} 
                            placeholder="Selecione o ID da Transação"
                            onChange={(id_venda) => this.onIdVenda(id_venda)}
                        />
                        <Input hidden invalid={this.state.id_venda_invalid} />
                        <FormFeedback>O ID da transação é obrigatório</FormFeedback>
                    </FormGroup>
                    <div hidden={this.state.is_venda == true}>
                        <FormGroup>
                            {/* <AsyncSelect
                                cacheOptions
                                loadOptions={this.loadOptionsClientes}
                                defaultOptions
                                value={this.state.cliente} 
                                placeholder="Selecione o cliente"
                                onChange={(cliente) => this.setState({cliente})}
                            /> */}
                            <InputMasked
                                name="cpf_cnpj"
                                mask={this.state.is_cnpj == false ? "999.999.999-99" : "99.999.999/9999-99"}
                                placeholder="Documento (CPF ou CNPJ)"
                                value={this.state.cpf_cnpj}
                                invalid={this.state.cpf_cnpj_invalid}
                                onChange={this.handleInputChange}
                            />
                            <input 
                                type="radio"
                                checked={this.state.is_cnpj == false}
                                onChange={() => this.setState({ is_cnpj: false })}/>
                            <label>&nbsp;CPF &nbsp;&nbsp;</label> 
                            <input 
                                type="radio"
                                checked={this.state.is_cnpj == true}
                                onChange={() => this.setState({ is_cnpj: true })}/> 
                            <label>&nbsp;CNPJ &nbsp;&nbsp;</label>
                        </FormGroup>
                        <FormGroup>
                            <InputText
                                name="email"
                                placeholder="E-mail"
                                value={this.state.email}
                                onChange={this.handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <InputMasked
                                name="telefone"
                                placeholder="Telefone"
                                mask="(99) 99999-9999"
                                value={this.state.telefone}
                                onChange={this.handleInputChange}
                            />
                        </FormGroup>
                    </div>
                    <FormGroup>
                        <Select
                            isMulti
                            placeholder="Produtos"
                            options={this.state.produtos_list}
                            value={this.state.produtos}
                            onChange={(produtos) => this.setState({produtos})}
                        />
                        <Input hidden invalid={this.state.produtos_invalid} />
                        <FormFeedback>Selecione os produtos a serem enviados nessa remessa</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Select
                            placeholder="Serviço de Transporte"
                            options={transporte_list}
                            value={this.state.servico_transporte}
                            onChange={(servico_transporte) => this.setState({servico_transporte})}
                        />
                        <Input hidden invalid={this.state.servico_transporte_invalid} />
                        <FormFeedback>Selecione o serviço de transporte a ser utilizado</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <InputText
                            placeholder="Código de Rastreio (opcional)"
                            value={this.state.rastreio_transporte}
                            onChange={(e) => this.setState({rastreio_transporte: e.target.value})}
                        />
                        <small><b>Quando não adicionado, será gerado um código de rastreio segundo o meio de pagamento.</b></small>
                    </FormGroup>
                    
                </ModalBody>
                <ModalBody
                    style={{
                        minHeight: '114px',
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                    />

                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose()}>Cancelar</Button>
                    <Button color="success" onClick={() => this.onRegister()}>Salvar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default RemessaModal;