import styled from "styled-components"

const Text = styled.p`
    position: relative;
    top: 14px;
    width: 47px;
    background-color: white;
    color: #52c565;
    font-weight: 700;
    z-index: 900;
    padding: 3px;
`;
const HR = styled.hr`
    margin-top: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
    position: relative;
    bottom: 17px;
`
export const LineAuth = () => {
    return (
        <div>
            <Text>OU</Text>
            <HR/>
        </div>
    )
}