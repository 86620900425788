import styled from "styled-components";

const ButtonD = styled.button`
    background-color: transparent;
    border: 0;
    padding: 0;
`

export const AButton = (props) => {
    return(
        <ButtonD
            type="button"
            {...props}
        />
    )

}