import React, { Component } from 'react';
import { Card, CardBody, Col, Row, Button, Collapse, FormGroup, ButtonGroup, Input } from "reactstrap";
import DataTable from 'react-data-table-component';
import { MdAttachment, MdNote, MdMoneyOff, MdArrowBack, MdCheck, MdCancel, MdClear, MdWarning, MdAttachMoney } from "react-icons/md";
import styled from "styled-components";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import moment from "moment";
import sanitizeHtml from 'sanitize-html';
import Select from "react-select";
import GoogleMapReact from 'google-map-react';
import { list_prevencao_status, list_status_pagamentos } from "../../auth/listas";
import ContasBancarias from '../../components/UsersList/ContasBancarias';
import FadeLoader from "react-spinners/FadeLoader";
import ReactScoreIndicator from 'react-score-indicator';
import NumberFormat from 'react-number-format';
import { ClipLoader } from 'react-spinners';
import { FaEye, FaMapMarkerAlt, FaSearch } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { DropdownIcon } from '../../components/DropdownIcon/DropdownIcon';
import axios from 'axios';
import ConsultarSeller from '../../components/Modals/Prevencao/ConsultarSeller';
import ConsultarSellerLocaliza from '../../components/Modals/Prevencao/ConsultarSellerLocaliza';
import { ModalComplience } from '../../components/Modals/ModalComplience/ModalComplience';

var CurrencyInput = require('react-currency-masked-input');

const maskDate = value => {
    let v = value.replace(/\D/g,'').slice(0, 10);
    if (v.length >= 5) {
      return `${v.slice(0,2)}/${v.slice(2,4)}/${v.slice(4)}`;
    }
    else if (v.length >= 3) {
      return `${v.slice(0,2)}/${v.slice(2)}`;
    }
    return v
  }

const colunas_pagamentos = [
    {
        name: "ID",
        selector: "id",
        width: '280px',
        sortable: false,
    },
    {
        name: "Data",
        selector: "created_at",
        sortable: false,
        width: '150px',
        cell: row => row.created_at !== null ? moment(row.created_at).format('DD/MM/YYYY HH:mm') : ''
    },
    {
        name: "Operação",
        selector: "operation_type",
        sortable: false,
        width: '150px',
        cell: p => operation(p.operation_type)
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: false,
        cell: row => row.status !== 'succeeded' && row.error_message !== null ? <>{status_transacao(row.status)} - ERRO NA TRANSAÇÃO: {row.response_message}</> : status_transacao(row.status),
    },
    {
        name: "Valor",
        selector: "amount",
        sortable: false,
        width: '170px',
        cell: p => <>R$ {p.amount.replace('.', ',')}</>
    },
];
const status_transacao = (payment_type) => {
    switch(payment_type){
        case 'succeeded' : return <><MdCheck />Aprovada</>;
        case 'paid' : return <><MdCheck />Pago</>;
        case 'canceled' : return <><MdClear/>Cancelada</>;
        case 'pending' : return 'Pendente';
        case 'failed' : return <><MdClear/>Falhada</>;
        case 'disputed': return 'Em disputa';
        default: return payment_type
    }
}
const status_transacao_not_svg = (payment_type) => {
    switch(payment_type){
        case 'succeeded' : return 'Aprovada';
        case 'canceled' : return 'Cancelada';
        case 'pending' : return 'Pendente';
        case 'failed' : return 'Falhada';
        case 'disputed': return 'Em disputa';
        default: return payment_type
    }
}
const payment = (payment_type) => {
    switch(payment_type){
        case 'credit' : return 'Crédito';
        case 'boleto' : return 'Boleto';
        case 'debit' : return 'Débito';
        default: return payment_type
    }
}
const operation = (operation_type) => {
    switch(operation_type){
        case 'created' : return 'Criação';
        case 'pre_authorization' : return 'Pré Autorização';
        case 'authorization' : return 'Autorização';
        case 'void' : return 'Estorno';
        default: return operation_type
    }

}
const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
`;

const getPayout = (id, callback) => {
    var url = ENDPOINT + "payout/get-aqpago/" + id;

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((data) => {
        console.log(data)
        callback(data);
    })
}
const getSellerPayout = (id, callback) => {
    var url = ENDPOINT + "payout/get-aqpago-seller/" + id;

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((data) => {
        callback(data);
    })
}

const getUser = (id, callback) => {
    var url = ENDPOINT + "user/get_aqbank/"+id;

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        callback(p);
    })
}


const get_payout = () => {
    return window.location.pathname.replace('/contas-a-receber-detalhes/', '')
}

const AnyReactComponent = ({ text }) => <div style={{width: '126px', textAlign: 'center', fontWeight: '800'}}><FaMapMarkerAlt style={{color: 'red'}}/> <br/>{text}</div>;
class ResumoPayout extends Component{
    constructor(props){
        super(props);
        this.state = {
            payout_id: get_payout(),
            activeTab: "1",
            nome: "",
            email: "",
            data_hora_pagamento_conta: "",
            amount: "",
            fees: "",
            original_amount: "",
            status: "",
            confirmed: "",
            description: "",
            currency: "",
            payment_type: "",
            statement_descriptor: "",
            transaction_number: "",
            document: "",
            buyer: [],
            history: [],
            payment_method: {},
            contas_bancaria: [],
            conta_bancaria_loading: true,
            compliance: null,
            is_open_nota: false,
            fee_details: [],
            anexos: [],
            distract_nota: '',
            visible_complience: false,

            ip_transacoes_count: 1,
            ip_client: '',
            
            file: null,
            descricao_image: '',
            modalImage: false,

            descricao_nota: '',
            modalNota: false,
            notas_privadas: [],
            hitorico_consultas_vendedor: [],

            visible_nota: false,
            id_online: null,
        }

        this.callback_consultar_seller = this.handleNewConsultaSeller.bind(this);
        this.callback_consultar_seller_localiza = this.handleNewConsultaSellerLocaliza.bind(this);
        this.myRef = React.createRef();
    }

    handleNewConsultaSeller = () => {
        var url = ENDPOINT+"prevencao/consultar_credito";
        //var url = "http://127.0.0.1:8000/consultar_credito";

        if (
            this.state.document === undefined || 
            this.state.document === '' || 
            this.state.document === null
        ) {
            toast.error('Falha ao consultar crédito, aguarde o carregamento dos dados', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })

            return false;
        }

        this.setState({loading_consultar_vendedor: true})

        var formData = new FormData();
        formData.append('documento', this.state.document);
        formData.append('id_pagamento', this.state.id_online);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;

            this.setState({loading_consultar_vendedor: false})
            if (data.success) {
                toast.success('Consulta de crédito realizada com sucesso!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                this.getHitoricoConsultasVendedor()
            } else {
                toast.error('Falha ao consultar crédito', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                this.getHitoricoConsultasVendedor()
            }
        })
        .catch((error) => {
            this.setState({loading_consultar_comprador: false})
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              // console.log(error.config);
        })
    }

    handleNewConsultaSellerLocaliza = () => {
        var url = ENDPOINT+"prevencao/consultar_localiza";
        //var url = "http://127.0.0.1:8000/consultar_localiza";

        if (
            this.state.document === undefined || 
            this.state.document === '' || 
            this.state.document === null
        ) {
            toast.error('Falha ao consultar crédito, aguarde o carregamento dos dados', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })

            return false;
        }

        this.setState({loading_consultar_vendedor_localiza: true})
        
        var formData = new FormData();
        formData.append('documento', this.state.document);
        formData.append('id_pagamento',  this.state.id_online);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;

            this.setState({loading_consultar_vendedor_localiza: false})
            if (data.success) {
                toast.success('Consulta de crédito realizada com sucesso!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                this.getHitoricoConsultasVendedorLocaliza()
            } else {
                toast.error('Falha ao consultar crédito', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                this.getHitoricoConsultasVendedorLocaliza()
            }
        })
        .catch((error) => {
            this.setState({loading_consultar_comprador: false})
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              // console.log(error.config);
        })
    }

    componentDidMount() {
        getPayout(this.state.payout_id, this.callbackRows.bind(this));
    }

    getHitoricoConsultasVendedor = () => {
        if (this.state.document === undefined ||
            this.state.document == false ||
            this.state.document == null ||
            this.state.document == '') {
            return false;
        }
        var url = ENDPOINT+"prevencao/consultar_historico_mix/"+this.state.document;
        // var url = "http://127.0.0.1:8000/juridico/teste";

        var myHeaders = new Headers();

        myHeaders.append('Authorization', 'Bearer ' + getToken());
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            let response = p.data;

            if (response.data !== undefined && response.data.length > 0) {
                this.setState({hitorico_consultas_vendedor: response.data})
            }
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!') 
        })
    }

    listData = [
        { name: <><MdAttachMoney/>Informações de Payout Bloqueado</>, active: '1' },
        { name: <><MdAttachment />Anexos </>, active: '2' },
        { name: <><MdNote />Notas </>, active: '3' }
    ];
    callbackSeller(data){
        if(data.body){
            var seller = data.body;
            
            this.setState({
                nome: seller.type === 'individual' ? (seller.first_name + ' '+ seller.last_name) : (seller.business_name),
                email: seller.type === 'individual' ? (seller.email) : (seller.business_email),
                document: seller.type === 'individual' ? (seller.taxpayer_id) : (seller.ein)
            });
            getUser((seller.type === 'individual' ? (seller.taxpayer_id) : (seller.ein)), this.callbackContaBancaria);
        }
    }
    callbackContaBancaria = (data) => {
        if(typeof data.data !== 'undefined'){
            this.setState({
                contas_bancaria: data.data.bank_accounts,
                conta_bancaria_loading: false
            });
        }

    }

    callbackRows(data) {
        if(data.body){
            var transation = data.body;

            this.setState({
                amount: transation.amount,
                data_hora_pagamento_conta: transation.create,
                fees: transation.fees,
                original_amount: transation.original_amount,
                status: transation.status,
                confirmed: transation.confirmed,
                currency: transation.currency,
                payment_type: transation.payment_type,
                statement_descriptor: transation.statement_descriptor,
                transaction_number: transation.id,
                history: transation.history,
                payment_method: transation.payment_method,
                description: transation.description,
                compliance: transation.compliance,
                buyer: transation?.buyer ? transation?.buyer : null,
            });

            if (transation.id_online !== undefined && transation.id_online !== null) {
                this.setState({id_online: transation.id_online})
                this.getPaymentData(transation.id_online)
            }

            getSellerPayout(transation.on_behalf_of, this.callbackSeller.bind(this))
        }
    }
    primeira_validacao = () => (<>
        <small>Insira o valor debitado no cartão</small>

            <CurrencyInput 
                inputMode="tel"
                className="form-control" 
                currency="BRL"
                name="valor_validacao_primeiro"
                //value={this.state.valor_validacao_primeiro}
                defaultValue={'0.00'}
                onChange={this.handleInputChange}
            />
            {/* {this.state.mensagens_validacao_cartao.map(e => 
                <small><b>{moment(e.created_at).format('DD/MM/YYYY HH:mm')}: </b>
                <div style={{border: '1px solid #7f7f7f66', borderRadius: '15px', padding: '19px'}}>
                    <div dangerouslySetInnerHTML={{__html: sanitizeHtml(e.descricao)}}/>
                </div>
                <br/></small>
            )} */}
            <b>E-mails de validação recebidos do cliente:</b><br/>
            {this.state.mensagens_validacao_cartao.map(e => 
                <small><b>{moment(e.created_at).format('DD/MM/YYYY HH:mm')}: </b>
                <div 
                    style={{
                        border: '1px solid #7f7f7f66', 
                        borderRadius: '15px', 
                        padding: '19px',
                        height: '150px',
                        overflow: 'auto'
                    }}>
                    <div dangerouslySetInnerHTML={{__html: sanitizeHtml(e.descricao)}}/>
                </div>
                <br/></small>
            )}
            <hr/>
            <ButtonGroup>
                <Button 
                    color="success"
                    disabled={this.state.loading_primeiro}
                    onClick={() => this.debitarCartao()}
                >
                {this.state.loading_primeiro === true ?
                    <ClipLoader color="#fff" size={15}/>
                :
                ''} Confirmar
                </Button>
                <Button 
                    color="dark"
                    disabled={this.state.loading_primeiro}
                    onClick={() => this.validarCartao()}>
                    {this.state.loading_primeiro === true ?
                        <ClipLoader color="#fff" size={15}/>
                    :
                    ''} Debitar novamente</Button>
                    <Button 
                        color="success"
                        disabled={this.state.loading_email_reenviar}
                        onClick={() => this.re_enviar_email()}
                    >
                    {this.state.loading_email_reenviar === true ?
                        <ClipLoader color="#fff" size={15}/>
                    :
                    ''} Re-enviar E-mail
                </Button>
                <Button
                    color="info"
                    onClick={() => this.onCloseOpenSendEmail(true, null)}>Enviar SMS</Button>
            </ButtonGroup>
            <br/>
            <br/>
    </>
    )

    getCartoes = (cpf_comprador, produto_servico, id_online) => {
        var url = ENDPOINT+"prevencao/consultar_cartoes/"+cpf_comprador+'?produto_servico='+produto_servico+'&pagamento_id='+id_online;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({
                loading_cartao: false,
                cartoes: data
            })
        })
        .catch(() => {
            this.setState({
                loading_cartao: false,
                cartoes: []
            })
        })

    }
    getCPFReceita = (cpf_comprador) => {
        var url = ENDPOINT+"user/search_cpf?cpf="+cpf_comprador;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            if(data.success === true){
                this.setState({
                    nome_receita: data.data.nome,
                    data_nascimento: data.data.nascimento,
                    situacao_cpf: data.data.situacao.descricao
                })
            }
            else{
                this.setState({situacao_cpf: data.errors})
            }
        })
    }

    getHitoricoConsultasVendedorLocaliza = () => {
        if (this.state.cpf_cnpj_estabelecimento === undefined ||
            this.state.cpf_cnpj_estabelecimento == false ||
            this.state.cpf_cnpj_estabelecimento == null ||
            this.state.cpf_cnpj_estabelecimento == '') {
            return false;
        }

        var url = ENDPOINT+"prevencao/consultar_historico_localiza/"+this.state.cpf_cnpj_estabelecimento;
        //var url = "http://127.0.0.1:8000/juridico/teste";

        var myHeaders = new Headers();
        
        // console.log(url)
        // return false;

        myHeaders.append('Authorization', 'Bearer ' + getToken());
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            let response = p.data;
            if (response.data !== undefined && response.data.length > 0) {
                this.setState({hitorico_consultas_localiza: response.data})
            }
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!') 
        })
    }
    
    getPaymentData = (id_online) => {
        var url = ENDPOINT+"prevencao/consultar_prevencao/" + id_online;

        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;

            this.setState({
                ...data.data
            })

            let state = this.state;
            state.historico_service_antifraud = (response.data.data.historico_service_antifraud !== undefined && response.data.data.historico_service_antifraud.length > 0) ? response.data.data.historico_service_antifraud : [];
            state.reference_id_service_antifraud = (response.data.data.reference_id_service_antifraud !== undefined) ? response.data.data.reference_id_service_antifraud : null;
            this.setState({...state})

            //this.getNotasBuyer(data.data.cpf_comprador);
            //this.getNotasSeller(data.data.cpf_cnpj_estabelecimento);
            //this.getCartoes(data.data.cpf_comprador, data.data.produto_servico, id_online);
            //this.getCPFReceita(data.data.cpf_comprador);
            
            if(data.data.dados_transacao !== null){
                this.setState({
                    history: data.data.dados_transacao.history
                })
            }
            if(data.data.aqbank_request !== null){
                if(data.data.aqbank_request.finalizado === 0){
                    this.setState({
                        inicio_analise: data.data.aqbank_request.inicio_analise,
                        status: data.data.aqbank_request.status,
                        prevencao_exists: true
                    })
                }
                else{
                    this.setState({
                        antifraude_finalizada: Boolean(data.data.aqbank_request.finalizado),
                        inicio_analise: data.data.aqbank_request.inicio_analise,
                        final_analise: data.data.aqbank_request.final_analise,
                        status: data.data.aqbank_request.status,
                        prevencao_exists: true
                    })

                }
            }
            // this.getHitoricoConsultasComprador()
            this.getHitoricoConsultasVendedor()
            this.getHitoricoConsultasVendedorLocaliza()

            return data;
        })
        .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              //console.log(error.response.status); // status code
              //console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              //console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            // console.log(error.config);
        })
        // .catch(e => {
        //     console.log(e)
        // })
    }
    bloquearPagamento = () => {
        this.setState({loading_payout: true})
        var url = ENDPOINT+"payout/store";

        var formData = new FormData();
        formData.append('nome', this.state.nome_fatura);
        formData.append('email', this.state.email_vendedor);
        formData.append('documento', this.state.cpf_cnpj_estabelecimento);
        formData.append('valor_bloqueado', this.state.valor);
        formData.append('id_transacao', this.state.id_transacao);
        formData.append('data_venda', moment(this.state.data_pagamento).format('YYYY-MM-DD HH:mm'));
        formData.append('data_liquidacao', moment(this.state.data_pagamento).format('YYYY-MM-DD'));
        formData.append('cliente_notificado_document', this.state.cpf_cnpj_estabelecimento);
        formData.append('novo_bloqueio', true);
        formData.append('tipo_bloqueio', this.state.tipo_pagamento);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({
                loading_payout: false,
                solicitacao_bloqueio: true
            });
            return data;
        })
        .catch((error) => {
            var data = error.response.data;
            this.setState({
                loading_payout: false
            });
            toast.error(data.errors);
        });

    }
    validarCartao = (position = 'primeiro') => {
        var id = 0;

        if(position === 'primeiro'){
            this.setState({loading_primeiro: true});
            id = this.state.cartao_credito.id;
        }
        else{
            this.setState({loading_segundo: true});
            id = this.state.pagamento_secundario.cartao_credito.id;
        }

        var url = ENDPOINT+"prevencao/validar_cartao/"+id;
        var formData = new FormData();
        formData.append('request_id', this.state.id);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then(() => {
            if(position === 'primeiro'){
                this.setState({loading_primeiro: false, show_primeiro: true});
            }
            else{
                this.setState({loading_segundo: false, show_segundo: true});
            }
            toast.success('Cobrança feita em cartão');
        })
        .catch((error) => {
            this.setState({
                loading_primeiro: false,
                loading_segundo: false
            });
            var data = error.response.data;
            toast.error(data.errors);
        })
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }

    modalConsultaSeller = (status) => {
        this.setState({callback_modal: this.callback_consultar_seller})
        this.setState({visible_consulta_vendedor: status})
    }   

    modalConsultaSellerLocalizador = (status) => {
        this.setState({callback_modal: this.callback_consultar_seller_localiza})
        this.setState({visible_consulta_localiza_vendedor: status})
    }

    store = (compliance) => {
        var url = ENDPOINT+"complience/store_note";

        var formData = new FormData();
        formData.append('descricao', this.state.distract_nota);
        formData.append('id', compliance);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.setState({
                distract_nota: '',
                is_open_nota: false,
            });
            
            getPayout(this.state.payout_id, this.callbackRows.bind(this));
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                toast.success(obj.errors);
                return false;
            }
            var data = error.response.data;
            toast.success(data.errors);
            return false;
        });
    }
    
    onOpenClosedComplience = (visible_complience = false) => {
        this.setState({ visible_complience })
    }

    render(){
        return(
            <Div>
                <h3>
                    <Button 
                        outline 
                        style={{border: 'none'}}
                        onClick={() => window.location.href = '/contas-a-receber'}
                    >
                        <MdArrowBack style={{padding: 0}}/>
                    </Button>&nbsp;&nbsp;Detalhes do Pagamento</h3>
                <hr />

                <ConsultarSeller 
                        visible={this.state.visible_consulta_vendedor}
                        onClose={() => this.modalConsultaSeller(false)}
                        callback={this.callback_consultar_seller}
                        nome_comprador={this.state.nome}
                        cpf_comprador={this.state.document}
                        /> 

                <ConsultarSellerLocaliza
                    visible={this.state.visible_consulta_localiza_vendedor}
                    onClose={() => this.modalConsultaSellerLocalizador(false)}
                    callback={this.callback_consultar_seller_localiza}
                    nome_comprador={this.state.nome}
                    cpf_comprador={this.state.document}
                    /> 

                <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                    <Row>
                        <Col md={6}>
                            <p><b>Status da Transação:</b> {status_transacao_not_svg(this.state.status)}</p>
                            <p><b>Valor da venda:</b> R$ {parseFloat(this.state.amount).toFixed(2).replace('.', ',')}</p>
                            <p><b>Taxa:</b> R$ {parseFloat(this.state.fees).toFixed(2).replace('.', ',')}</p>
                            <p><b>Valor Liquido:</b> R$ {(parseFloat(this.state.original_amount) - parseFloat(this.state.fees)).toFixed(2).replace('.', ',')}</p>
                            <p><b>Tipo de Pagamento:</b> {payment(this.state.payment_type)}</p>
                            {this.state.payment_type !== 'boleto' ?
                                <>
                                    <p><b>Portador do Cartão:</b> {this.state.payment_method.holder_name}</p>
                                    <p><b>Bandeira:</b> {this.state.payment_method.card_brand}</p>
                                    <p><b>Número:</b> {this.state.payment_method.first4_digits} **** **** {this.state.payment_method.last4_digits}</p>
                                    <p><b>Validade:</b> {this.state.payment_method.expiration_month < 10 ? '0' : ''}{this.state.payment_method.expiration_month}/{this.state.payment_method.expiration_year}</p>
                                </>
                                :
                                <>
                                    <p><b>Nome no Boleto:</b> {this.state.payment_method.recipient}</p>
                                    <p><b>Descrição:</b> {this.state.payment_method.description}</p>
                                    <p><b>Código de Barras do Boleto: </b>{this.state.payment_method.barcode}</p>
                                    <p><b>Link: </b><a href={this.state.payment_method.url} target='_blank'>Abrir Boleto</a></p>
                                </>
                            }
                        </Col>
                        <Col md={6}>
                            <p><b>ID da Transação:</b> {this.state.transaction_number}</p>
                            <p><b>Estabelecimento: </b>{this.state.nome}</p>
                            <p><b>E-mail: </b>{this.state.email}</p>
                            <p><b>Dodumento (CPF / CNPJ): </b>{this.state.document}</p>
                            <p><b>Data de recebimento em conta:</b> {moment(this.state.data_hora_pagamento_conta).format('DD/MM/YYYY HH:mm')}</p>
                            <p>
                                <Button 
                                    color="success" 
                                    onClick={() => {this.modalConsultaSeller(true)}} 
                                    disabled={this.state.loading_consultar_vendedor}>
                                    {this.state.loading_consultar_vendedor === true ? 
                                    <ClipLoader color="#fff" size={15}/>
                                    :
                                    <FaSearch />
                                    }
                                    Consultar Crédito
                                </Button>
                            </p>
                            {this.state.id_online !== undefined && this.state.id_online != null ?
                                    <Button 
                                        disabled={this.state.loading_consultar_localiza_vendedor} 
                                        onClick={() => {this.modalConsultaSellerLocalizador(true)}} 
                                        color="success">
                                        {this.state.loading_consultar_localiza_vendedor === true ?
                                            <ClipLoader color="#fff" size={15}/>
                                            :
                                            <FaSearch />
                                        } AQBank Localiza
                                    </Button>
                            :
                                <></>
                            }
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        {this.state.payment_type == 'boleto' ? (
                            <Col md={6}>
                                <div style={{
                                overflow: 'auto',
                                height: this.state.hitorico_consultas_vendedor !== undefined && this.state.hitorico_consultas_vendedor.length > 0 ? '855px' : 'auto', 
                                padding: '15px', 
                                border: '1px solid #dee2e6', 
                                borderRadius: 5}}>
                                    <h5>Dados do Buyer</h5><hr/>
                                    <p><b>Nome:</b> {this.state?.buyer[0]?.first_name + ' ' + this.state?.buyer[0]?.last_name }</p>
                                    <p><b>Documento:</b> {this.state?.buyer[0]?.taxpayer_id }</p>
                                    <p><b>E-mail:</b> {this.state?.buyer[0]?.email }</p>
                                    <p><b>Cep:</b>{this.state?.buyer[0]?.address?.postal_code }</p>
                                    <p><b>Endereço:</b>{this.state?.buyer[0]?.address?.line1 }</p>
                                    <p><b>Número:</b>{this.state?.buyer[0]?.address?.line2 }</p>
                                    <p><b>Complemento:</b>{this.state?.buyer[0]?.address?.line3 }</p>
                                    <p><b>Bairro:</b>{this.state?.buyer[0]?.address?.neighborhood }</p>
                                    <p><b>Estado:</b>{this.state?.buyer[0]?.address?.state }</p>
                                </div>
                            </Col>
                        ) : (<></>)}


                        <Col md={6}>
                            <div style={{
                                overflow: 'auto',
                                height: this.state.hitorico_consultas_vendedor !== undefined && this.state.hitorico_consultas_vendedor.length > 0 ? '855px' : 'auto', 
                                padding: '15px', 
                                border: '1px solid #dee2e6', 
                                borderRadius: 5}}>
                                <h5>Histórico de consultas</h5><hr/>
                                {this.state.hitorico_consultas_vendedor !== undefined && this.state.hitorico_consultas_vendedor.length > 0 ?
                                    
                                    this.state.hitorico_consultas_vendedor.map((consulta, i) => {

                                        if (i == 0) {
                                            return (
                                            <div key={'div_historico_vendedor_'+i}>
                                                    <Button 
                                                        disabled={this.state.loading_analises_ip} 
                                                        onClick={(event) => {this.handleOpenModalDetalhado(consulta)}} 
                                                        style={{margunTop: '7px',float: 'right',position: 'relative',top: '-5px'}}
                                                        color="info">
                                                        {this.state.loading_analises_ip === true ?
                                                            <ClipLoader color="#fff" size={15}/>
                                                            :
                                                            <FaEye />
                                                        } Ver Detalhado
                                                    </Button>
                                                <p>
                                                    <b>Data da consulta:</b> {moment(consulta.created_at).format('DD/MM/YYYY HH:mm')}
                                                </p>
                                                <Card style={{marginBottom: '1rem', padding: '1rem'}}>
                                                    <div key={"score_bar"+consulta.id} style={{position: 'relative'}}>
                                                        <ReactScoreIndicator 
                                                            stepsColors={[
                                                                '#d12000',
                                                                '#ed8d00',
                                                                '#f1bc00',
                                                                '#84c42b',
                                                                '#84c42b',
                                                                '#84c42b',
                                                                '#3da940',
                                                                '#3da940',
                                                              ]}
                                                            lineWidth={30}
                                                            value={consulta.payload.resposta.ocorrencias.score.score.pontos}
                                                            maxValue={1000}
                                                            />
                                                        <span style={{
                                                            position: 'absolute',
                                                            top: '2rem', 
                                                            width: '100%',
                                                            fontSize: '55px',
                                                            textAlign: 'center',
                                                            fontWeight: 'bold'
                                                        }}>
                                                            {consulta.payload.resposta.ocorrencias.score.score.classe}
                                                        </span>
                                                        <h5 style={{textAlign: 'center'}}>{consulta.payload.resposta.ocorrencias.score.score.faixa.titulo}</h5>
                                                        <p>{consulta.payload.resposta.ocorrencias.score.score.faixa.descricao}</p>
                                                    </div>
                                                    <p>
                                                        <b>Renda Presumida:</b>&nbsp;                                                      
                                                        <NumberFormat 
                                                            prefix="R$ " 
                                                            displayType="text" 
                                                            value={parseFloat(consulta.payload.resposta.resumos.rendaPresumida)} 
                                                            decimalSeparator=","/>
                                                    </p>
                                                    <p>
                                                        <b>{consulta.payload.resposta.resumos.debitos.sumQuantidade} Débito{consulta.payload.resposta.resumos.debitos.sumQuantidade > 1 ? 's' : ''}:</b>&nbsp;                                                         
                                                        <NumberFormat 
                                                            prefix="R$ " 
                                                            displayType="text" 
                                                            value={parseFloat(consulta.payload.resposta.resumos.debitos.sumValorTotal)} 
                                                            decimalSeparator=","/>
                                                    </p>
                                                    <p>
                                                        <b>{consulta.payload.resposta.resumos.protestos.sumQuantidade} Protesto{consulta.payload.resposta.resumos.protestos.sumQuantidade > 1 ? 's' : ''} :</b>&nbsp;                                                         
                                                        <NumberFormat 
                                                            prefix="R$ " 
                                                            displayType="text" 
                                                            value={parseFloat(consulta.payload.resposta.resumos.protestos.sumValorTotal)} 
                                                            decimalSeparator=","/>
                                                    </p>
                                                    {consulta.payload.resposta.resumos.acoes ?
                                                        <p>
                                                            <b>{consulta.payload.resposta.resumos.acoes.sumQuantidade} Aç{consulta.payload.resposta.resumos.acoes.sumQuantidade > 1 ? 'ões' : 'ão'}:</b>&nbsp;                                                         
                                                            <NumberFormat 
                                                                prefix="R$ " 
                                                                displayType="text" 
                                                                value={parseFloat(consulta.payload.resposta.resumos.acoes.sumValorTotal)} 
                                                                decimalSeparator=","/>
                                                        </p>
                                                        :
                                                        <></>
                                                    }
                                                    <p>
                                                        <b>{consulta.payload.resposta.resumos.cheques.sumQuantidade} Cheque{consulta.payload.resposta.resumos.cheques.sumQuantidade > 1 ? 's' : ''}</b>&nbsp;                                                         
                                                    </p>
                                                </Card>

                                                <hr />
                                                
                                            </div>
                                            )
                                        } else {
                                            return (
                                                <div key={'div_historico_vendedor_'+i}>
                                                        <Button 
                                                            disabled={this.state.loading_analises_ip} 
                                                            onClick={(event) => {this.handleOpenModalDetalhado(consulta)}} 
                                                            style={{margunTop: '7px',float: 'right',position: 'relative',top: '-5px'}}
                                                            color="info">
                                                            {this.state.loading_analises_ip === true ?
                                                                <ClipLoader color="#fff" size={15}/>
                                                                :
                                                                <FaEye />
                                                            } Ver Detalhado
                                                        </Button>
                                                    <p>
                                                        <b>Data da consulta:</b> {moment(consulta.created_at).format('DD/MM/YYYY HH:mm')}
                                                    </p>

                                                    <hr />
                                                </div>
                                            )
                                        }
                                    })
                                :
                                    <p>Nenhuma consulta encontrada</p>
                                }
                            </div>
                        </Col>
                        {this.state.id_online !== undefined && this.state.id_online != null ?
                        <Col md={6}>

                        <div style={{
                                overflow: 'auto',
                                height: this.state.hitorico_consultas_localiza !== undefined && this.state.hitorico_consultas_localiza.length > 0 ? '855px' : 'auto',                                
                                padding: '15px', 
                                border: '1px solid #dee2e6', 
                                borderRadius: 5
                                }}>

                                <h5>Consultas localiza</h5><hr/>
                                {this.state.hitorico_consultas_localiza !== undefined && this.state.hitorico_consultas_localiza.length > 0 ?
                                    
                                    this.state.hitorico_consultas_localiza.map((consulta, i) => {
                                        if (i == 0) {
                                            return (
                                            <div key={'div_historico_comprador_'+i}>
                                                    <Button 
                                                        disabled={this.state.loading_analises_ip} 
                                                        onClick={(event) => {this.handleOpenModalLocalizaDetalhado(consulta)}}  
                                                        style={{margunTop: '7px',float: 'right',position: 'relative',top: '-5px'}}
                                                        color="info">
                                                        {this.state.loading_analises_ip === true ?
                                                            <ClipLoader color="#fff" size={15}/>
                                                            :
                                                            <FaEye />
                                                        } Ver Detalhado
                                                    </Button>
                                                <p>
                                                    <b>Data da consulta:</b> {moment(consulta.created_at).format('DD/MM/YYYY HH:mm')}
                                                </p>
                                                <Card style={{marginBottom: '1rem', padding: '1rem'}}>
                                                    <div key={"localiza_bar_"+consulta.id} style={{position: 'relative'}}>
                                                        <h5>Endereços</h5><hr/>
                                                        {consulta.payload.resposta.enderecos !== undefined && consulta.payload.resposta.enderecos.length > 0 ?
                                                            consulta.payload.resposta.enderecos.map((endereco, ps) => {
                                                                return (
                                                                    <div>
                                                                        <p>{endereco.cep}</p>
                                                                        <p>{endereco.tipoLogradouro}. {endereco.logradouro}, {endereco.numero} {endereco.complemento !== undefined && endereco.complemento !== '' ? ' - ' + endereco.complemento : ''}</p>
                                                                        <p>{endereco.bairro}</p>
                                                                        <p>{endereco.cidade} / {endereco.uf}</p><hr/>
                                                                    </div>
                                                                )
                                                            })
                                                        :
                                                        <></>
                                                        }
                                                        
                                                    </div>
                                                </Card>
                                                
                                                <Card style={{marginBottom: '1rem', padding: '1rem'}}>
                                                    {consulta.payload.resposta.telefones.fixos !== undefined &&
                                                    consulta.payload.resposta.telefones.fixos.length > 0 ?
                                                        
                                                    consulta.payload.resposta.telefones.fixos.map((telefone, ps) => {
                                                            return(
                                                                <div>
                                                                    <h5>Fixo: {telefone.numero}</h5><hr/>
                                                                    {telefone.numero !== undefined ? 
                                                                        <div>
                                                                            {/* <p><b>Número:</b> </p> */}
                                                                            <p><b>Relação:</b> {telefone.relacao}</p>
                                                                            <p><b>Não pertube:</b> {telefone.naoPerturbe == false ? 'Não' : 'Sim'}</p>
                                                                            <p><b>WhatsApp Business:</b> {telefone.aplicativos.whatsAppBusiness == false ? 'Não' : 'Sim'}</p>
                                                                        </div>
                                                                    :
                                                                        <></>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    :
                                                        <></>
                                                    }

                                                    {consulta.payload.resposta.telefones.moveis !== undefined &&
                                                    consulta.payload.resposta.telefones.moveis.length > 0 ?
                                                        
                                                    consulta.payload.resposta.telefones.moveis.map((telefone, i) => {
                                                            return(
                                                                <div>
                                                                    <h5>Celular: {telefone.numero}</h5><hr/>
                                                                    {telefone.numero !== undefined ? 
                                                                        <div>
                                                                            {/* <p><b>Número:</b> </p> */}
                                                                            <p><b>Relação:</b> {telefone.relacao}</p>
                                                                            <p><b>Não pertube:</b> {telefone.naoPerturbe == false ? 'Não' : 'Sim'}</p>
                                                                            <p><b>WhatsApp:</b> {telefone.aplicativos.whatsApp == false ? 'Não' : 'Sim'}</p>
                                                                        </div>
                                                                    :
                                                                        <></>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    :
                                                        <></>
                                                    }
                                                </Card>                                                
                                            </div>
                                            )
                                        } else {
                                            return (
                                                <div style={{marginTop: '1rem'}} key={'div_historico_vendedor_localiza_'+consulta.id}>
                                                    <hr/>
                                                        <Button 
                                                            disabled={this.state.loading_analises_ip} 
                                                            onClick={(event) => {this.handleOpenModalLocalizaDetalhado(consulta)}} 
                                                            style={{margunTop: '7px',float: 'right',position: 'relative',top: '-5px'}}
                                                            color="info">
                                                            {this.state.loading_analises_ip === true ?
                                                                <ClipLoader color="#fff" size={15}/>
                                                                :
                                                                <FaEye />
                                                            } Ver Detalhado
                                                        </Button>
                                                    <p>
                                                        <b>Data da consulta:</b> {moment(consulta.created_at).format('DD/MM/YYYY HH:mm')}
                                                    </p>

                                                    <hr />
                                                </div>
                                            )
                                        }
                                    })
                                :
                                    <p>Nenhuma consulta encontrada</p>
                                }
                            </div>
                        </Col>
                        :
                        <></>
                        }
                    </Row>
                    <hr/>
                    {this.state.id_online !== undefined && this.state.id_online != null ?
                        <Row>
                            <Col md={6}>
                                <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                    <h5>Dados do Compliance</h5><hr/>
                                    {this.state.compliance !== null ?
                                            <div>
                                                <p><b>Limite liberado On-line: </b>{parseFloat(this.state.compliance.limit_online).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                                <p><b>Limite liberado terminal POS: </b>{parseFloat(this.state.compliance.limit_pos).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                                <b>Distrato:</b>
                                                {this.state.compliance.documents.map(p => {
                                                    return <Card style={{margin: 5}}>
                                                                <CardBody>
                                                                        <p>
                                                                            <b>Descrição: </b>{p.descricao}<br/>
                                                                            <b>Distrato:</b> {p.distract}<br/>
                                                                            <b>Enviado pelo Operador:</b> {p.auth_name}<br/>
                                                                            <hr/>
                                                                            <Button 
                                                                                color='success'
                                                                                onClick={() => window.open(p.url+'?access_token='+getToken(), '_blank')}
                                                                            >
                                                                                Abrir Documento
                                                                            </Button>
                                                                        </p>
                                                                </CardBody>
                                                            </Card>
                                                })}
                                                <br/>
                                                <Button color='info' hidden={this.state.is_open_nota} onClick={() => this.setState({ is_open_nota: true })}>Nova Nota</Button>
                                                <Collapse isOpen={this.state.is_open_nota}>
                                                    <label htmlFor="for_desc">Nova nota</label>
                                                    <Input
                                                        name={"distract_nota"}
                                                        placeholder="Descrição da Nota"
                                                        type="textarea"
                                                        style={{
                                                            height: '124px',
                                                            resize: 'none'
                                                        }}
                                                        onChange={(event) => {this.handleChange(event)}}
                                                    />
                                                    <ButtonGroup>
                                                        <Button color='info' onClick={() => this.setState({ is_open_nota: false, distract_nota: '' })}>Cancelar</Button>
                                                        <Button color='success' onClick={() => this.store(this.state.compliance.id)}>Salvar</Button>
                                                    </ButtonGroup>
                                                </Collapse>
                                                <br/>
                                                <br/>
                                                {this.state.compliance.notas_privadas.map(e => (
                                                    <p><b>{moment(e.created_at).format('DD/MM/YYYY HH:mm')}</b> {e.descricao}</p>
                                                ))}
                                                <Button color='info' onClick={() => this.onOpenClosedComplience(true)}>Alterar</Button>
                                                <ModalComplience
                                                    visible={this.state.visible_complience}
                                                    onClose={() => this.onOpenClosedComplience()}
                                                    onRefresh={() => getPayout(this.state.payout_id, this.callbackRows.bind(this))}
                                                    document={this.state.document}
                                                />
                                            </div>
                                            :
                                            <div>
                                                <p><b>Limite liberado On-line: </b>{parseFloat(0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                                <p><b>Limite liberado terminal POS: </b>{parseFloat(0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                                <Button color='info' onClick={() => this.onOpenClosedComplience(true)}>Alterar</Button>
                                                <ModalComplience
                                                    visible={this.state.visible_complience}
                                                    onClose={() => this.onOpenClosedComplience()}
                                                    onRefresh={() => getPayout(this.state.payout_id, this.callbackRows.bind(this))}
                                                    document={this.state.document}
                                                />
                                            </div>}
                                    </div>
                            </Col>
                            <Col md={6}>
                                <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                    <h5>Dados do Buyer</h5><hr/>
                                    {this.state.suspeito_comprador === 1 ?
                                        <p>
                                            <b style={{color: 'red'}}><MdWarning style={{padding : 0}}/> 
                                                Buyer com suspeita&nbsp;
                                            </b>
                                            <Button 
                                                color="warning" 
                                                disabled={this.state.loading_suspeito_buyer}
                                                onClick={() => this.setState({show_suspeita_buyer: !this.state.show_suspeita_buyer})}
                                                style={{
                                                    padding: 2, 
                                                    paddingLeft: 8, 
                                                    paddingRight: 8, 
                                                    fontSize: '80%'
                                                }}><b>Remover suspeita de buyer</b></Button>
                                                <Collapse isOpen={this.state.show_suspeita_buyer}>
                                                    <div>
                                                        <hr/>
                                                        <Input
                                                            name="nota_suspeita_buyer"
                                                            placeholder="Por qual motivo você deseja remover a suspeita de buyer?"
                                                            value={this.state.nota_suspeita_buyer}
                                                            type="textarea"
                                                            style={{
                                                                height: '124px',
                                                                resize: 'none'
                                                            }}
                                                            onChange={this.handleInputChange}
                                                        />
                                                    </div>
                                                    <br/>
                                                    <Button 
                                                        disabled={this.state.loading_suspeito_buyer}
                                                        color="success"
                                                        onClick={this.removerSuspeitaBuyer}
                                                    >Salvar</Button>
                                                </Collapse>
                                            <br/>
                                            </p>
                                        :
                                        <></>
                                    }
                                    <p><b>Nome:</b> {this.state.nome_comprador}</p>
                                    <p><b>Nome (Receita Federal):</b> {this.state.nome_receita}</p>
                                    <p><b>CPF (Receita Federal):</b> 
                                    {this.state.cpf_comprador !== undefined && this.state.cpf_comprador != 'null' ?
                                        <div>
                                        <NumberFormat 
                                            displayType={'text'} value={this.state.cpf_comprador} 
                                            format={this.state.cpf_comprador.length > 11 ? '##.###.###/####-##' : "###.###.###-##"}/> 
                                        {this.state.situacao_cpf !== undefined && this.state.situacao_cpf.length > 0 ? ' - '+this.state.situacao_cpf : ''}
                                        </div>
                                    :
                                        <></>
                                    }
                                    </p>
                                    <p><b>Nascimento (Receita Federal):</b> {this.state.data_nascimento !== undefined ? maskDate(this.state.data_nascimento) : ''}</p>
                                    <p hidden={this.state.idade_comprador == 0}><b>Idade:</b> {this.state.idade_comprador} anos</p>
                                    <p><b>Endereco:</b> {this.state.endereco_comprador}</p>
                                    <p><b>Telefone:</b> <a href={"tel:"+this.state.telefone_comprador}><NumberFormat displayType={"text"} value={this.state.telefone_comprador} format="(##) #####-####" /></a></p>
                                    <p><b>Email: </b>{this.state.email_comprador}</p>
                                    
                                    {this.state.status_bloqueio_comprador === false ?
                                        <div key="btn_comprador">
                                        <Button color="success" onClick={() => this.bloquearBuyer()} disabled={this.state.loading_bloqueio_buyer}>
                                            {this.state.loading_bloqueio_buyer === true ? 
                                            <ClipLoader color="#fff" size={15}/>
                                            :
                                            <></>
                                            }
                                            Bloquear Buyer
                                        </Button>
                                        </div>
                                        :
                                        <p style={{color: 'red'}}><b>Buyer bloqueado</b></p>
                                    }
                                </div>
                            </Col>

                            <Col md={6}>
                                <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                    <h5>Pagamento usado</h5><hr/>
                                    {this.state.ip_transacoes_count > 1 ?
                                        <p>
                                            <b style={{color: 'red'}}><MdWarning style={{padding : 0}}/> Existe transações com o mesmo IP ({this.state.ip_client})</b>
                                            <br/>
                                            <Button disabled={this.state.loading_analises_ip} onClick={() => this.getTransacoesByIp()} style={{margunTop: '7px'}} color="warning">
                                                {this.state.loading_analises_ip === true ?
                                                    <ClipLoader color="#fff" size={15}/>
                                                    :
                                                    ''} Ver Transações do IP
                                            </Button>
                                        </p>
                                        :
                                        <></>
                                    }
                                    {this.state.solicitacao_bloqueio === true ?
                                        <p><b style={{color: 'red'}}>Bloqueio Solicitado</b></p>
                                    :
                                    <>
                                        <p>
                                            <Button
                                                color="warning"
                                                disabled={this.state.loading_payout}
                                                onClick={() => this.setState({prevencao_show: !this.state.prevencao_show})}>
                                                Bloquear Pagamento&nbsp;
                                                {this.state.loading_payout === true ?
                                                    <ClipLoader color="#fff" size={15}/>
                                                    :
                                                    <>
                                                        <AiFillCaretDown hidden={this.state.prevencao_show}/>
                                                        <AiFillCaretUp hidden={!this.state.prevencao_show}/>
                                                    </>
                                                }
                                            </Button>
                                            <Collapse isOpen={this.state.prevencao_show}>
                                                <hr/>
                                                <div>
                                                    <input 
                                                        type="radio" 
                                                        name="block-type"
                                                        checked={this.state.tipo_pagamento === 'prevencao'}
                                                        onChange={() => this.setState({tipo_pagamento: 'prevencao'})}
                                                    /> Prevenção &nbsp;
                                                    <input 
                                                        type="radio" 
                                                        name="block-type"
                                                        checked={this.state.tipo_pagamento === 'cartao_proprio'}
                                                        onChange={() => this.setState({tipo_pagamento: 'cartao_proprio'})}
                                                    /> Cartão Proprio
                                                </div>
                                                <hr/>
                                                <Button 
                                                    onClick={() => this.bloquearPagamento()} 
                                                    disabled={this.state.loading_payout}
                                                    color="warning">
                                                    {this.state.loading_payout === true ?
                                                        <ClipLoader color="#fff" size={15}/>
                                                        :
                                                        ''} Confirmar Bloqueio
                                                </Button>
                                            </Collapse>
                                        </p>
                                    </>}

                                    <p><b><u>{this.state.pagamento_parcial === false ? 'Pagamento Integral' : 'Multiplos Pagamentos'}</u></b></p>
                                    <p><b>Valor Bruto:</b> R$ {parseFloat(this.state.valor).toFixed(2).replace('.', ',')}</p>
                                    {this.state.pagamento_parcial === true ? 
                                    <>
                                        <small><b><u>Primeiro pagamento</u></b></small><br/>
                                        <Button 
                                            color="dark" 
                                            style={{
                                                margin: 15
                                            }}
                                            onClick={() => this.setState({primeiro_pagamento_show: !this.state.primeiro_pagamento_show})}>
                                                <DropdownIcon dropdown={this.state.primeiro_pagamento_show} />&nbsp;
                                                Visualizar Primeiro Pagamento
                                        </Button><br/>
                                    </> 
                                    : 
                                    <><Button color="dark" onClick={() => this.setState({primeiro_pagamento_show: !this.state.primeiro_pagamento_show})}>Visualizar Pagamento</Button><br/></>}
                                    
                                    <Collapse isOpen={this.state.primeiro_pagamento_show}>
                                        <p><b>ID da transação:</b> {this.state.id_transacao}</p>
                                        <p><b>Forma de Pagamento:</b> {payment(this.state.forma_pagamento)}</p>
                                        <p><b>Data de Pagamento:</b> {moment(this.state.data_pagamento).format('DD/MM/YYYY HH:mm')}</p>
                                        {this.state.pagamento_parcial === true ? <>
                                            <p><b>Valor:</b> R$ {parseFloat(this.state.valor_primario).toFixed(2).replace('.', ',')} / {this.state.numero_parcelas}x</p>
                                        </> 
                                        : 
                                        <>
                                            <p><b>Valor:</b> R$ {parseFloat(this.state.valor).toFixed(2).replace('.', ',')} / {this.state.numero_parcelas}x</p>
                                        </>
                                        }
                                        {this.state.cartao_credito !== undefined && this.state.cartao_credito !== null ?
                                            <div>
                                                    <p><b>Tipo de cartão:</b> {this.state.cartao_credito.tipo_cartao !== undefined ? this.state.cartao_credito.tipo_cartao.toUpperCase() : ''}</p>
                                                    <p><b>Nome no cartão:</b> {this.state.cartao_credito.nome_proprietario}</p>
                                                    <p><b>Número do cartão:</b> {this.state.cartao_credito.numero}</p>
                                                    <p><b>Validade:</b> {this.state.cartao_credito.validade_mes}/{this.state.cartao_credito.validade_ano}</p>
                                                    <p><b>Apelido do cartão:</b> {this.state.apelido_cartao}</p>
                                                    <p><b>Bandeira:</b> {this.state.cartao_credito.bandeira !== null ? this.state.cartao_credito.bandeira.toUpperCase() : 'Sem informações'}</p>
                                                    {this.state.cartao_credito.validate_card == 1 ?
                                                        <>
                                                            <p style={{color: 'green'}}><b>Cartão Validado</b></p>
                                                            <b>E-mails de validação recebidos do cliente:</b><br/>
                                                            {this.state.mensagens_validacao_cartao.map(e => 
                                                                <small><b>{moment(e.created_at).format('DD/MM/YYYY HH:mm')}: </b>
                                                                <div 
                                                                    style={{
                                                                        border: '1px solid #7f7f7f66', 
                                                                        borderRadius: '15px', 
                                                                        padding: '19px',
                                                                        height: '150px',
                                                                        overflow: 'auto'
                                                                    }}>
                                                                    <div dangerouslySetInnerHTML={{__html: sanitizeHtml(e.descricao)}}/>
                                                                </div>
                                                                <br/></small>
                                                            )}
                                                        </>
                                                        :
                                                        <>
                                                            {this.state.cartao_credito.pagamento_validacao_card === 0 || this.state.cartao_credito.validate_card == false ?
                                                                <>  
                                                                    <Button 
                                                                        color="dark" 
                                                                        style={{marginBottom: 10}} 
                                                                        hidden={this.state.show_primeiro}
                                                                        disabled={this.state.loading_primeiro}
                                                                        onClick={() => this.validarCartao()}
                                                                    >
                                                                        {this.state.loading_primeiro === true ?
                                                                            <ClipLoader color="#fff" size={15}/>
                                                                        :
                                                                        ''} Validar cartão
                                                                    </Button>
                                                                    <Collapse isOpen={this.state.show_primeiro} style={{marginBottom: 10}} >
                                                                        {this.primeira_validacao()}
                                                                    </Collapse>
                                                                </>
                                                            :
                                                            <>
                                                                {this.primeira_validacao()}
                                                        </>}
                                                    </>}
                                                {/* */} 
                                            </div>
                                            :
                                            <></>
                                        }
                                        <p><b>Nome do Produto: </b> {this.state.produto_nome}</p>
                                        <p><b>Navegador:</b> {this.state.browser !== undefined && this.state.browser.length > 0 ? this.state.browser : 'Sem informações'}</p>
                                        <p><b>Aparelho utilizado:</b> {this.state.device !== undefined && this.state.device.length > 0 ? this.state.device : 'Sem informações'}</p>
                                        <p><b>Endereço de IP:</b> {this.state.ip_client !== undefined && this.state.ip_client.length > 0 ? this.state.ip_client : 'Sem informações'}</p>
                                    </Collapse>
                                    {this.state.pagamento_parcial === true && this.state.aqbank_request_primario !== null ?
                                        <>
                                            {this.state.prevencao_exists === true && this.state.antifraude_finalizada === false ?
                                                <>
                                                    <br/>
                                                    <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                                    <h5>Análise Antifraude do primeiro pagamento</h5><hr/>
                                                        {this.state.aqbank_request_primario.finalizado == 0 ?
                                                            <>
                                                                <p><b>Status:</b> {this.state.aqbank_request_primario.status}</p>
                                                                <p><b>Chegada em Fila:</b> {moment(this.state.data_pagamento).format('DD/MM/YYYY HH:mm')}</p>
                                                                <p><b>Inicio de Análise:</b> {moment(this.state.aqbank_request_primario.inicio_analise).format('DD/MM/YYYY HH:mm')} 
                                                                - 
                                                                {this.state.aqbank_request_primario.responsavel_analise === null ? '' : this.state.aqbank_request_primario.responsavel_inicio_analise.name}</p>
                                                                <FormGroup>
                                                                    <Select 
                                                                        placeholder="Status" 
                                                                        options={list_prevencao_status}
                                                                        onChange={(status_current_first) => this.setState({status_current_first})}
                                                                        value={this.state.status_current_first}
                                                                    />
                                                                </FormGroup>
                                                                <div>
                                                                    {this.state.status_current_first !== null ?
                                                                        this.stepPrevencao(this.state.status_current_first)
                                                                        :
                                                                        <></>}
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <p><b>Status:</b> {this.getStatus(this.state.aqbank_request_primario.status)}</p>
                                                                {/* <p><b>Chegada em Fila:</b> {this.state.chegada_fila}</p> */}
                                                                <p><b>Chegada em Fila:</b> {moment(this.state.data_pagamento).format('DD/MM/YYYY HH:mm')}</p>
                                                                <p><b>Inicio de Análise:</b> {moment(this.state.aqbank_request_primario.inicio_analise).format('DD/MM/YYYY HH:mm')} 
                                                                &nbsp;-&nbsp;
                                                                {this.state.aqbank_request_primario.responsavel_inicio_analise === null ? 
                                                                    '' : this.state.aqbank_request_primario.responsavel_inicio_analise.name
                                                                }</p>
                                                                <p><b>Final de Análise:</b> {moment(this.state.aqbank_request_primario.final_analise).format('DD/MM/YYYY HH:mm')} - {this.state.aqbank_request_primario.responsavel_analise !== null ? this.state.aqbank_request_primario.responsavel_analise.name : ''}</p>
                                                            </>
                                                        }
                                                        {/* <div>
                                                            {this.state.status_current_first !== null ?
                                                            this.stepPrevencao(this.state.status_current_first)
                                                            :
                                                            <></>}
                                                        </div> */}
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <p><b>Status do pagamento: </b> {this.getStatus(this.state.antifraude_code)}</p>
                                                    {this.state.aqbank_request_primario !== null ? 
                                                        <>
                                                            <p>
                                                                <b>Inicio de Análise:</b> {moment(this.state.aqbank_request_primario.inicio_analise).format('DD/MM/YYYY HH:mm')} 
                                                                &nbsp;-&nbsp;
                                                                {this.state.aqbank_request_primario.responsavel_inicio_analise === null ? 
                                                                    '' : this.state.aqbank_request_primario.responsavel_inicio_analise.name
                                                                }
                                                            </p>
                                                            <p><b>Final de Análise:</b> {moment(this.state.aqbank_request_primario.final_analise).format('DD/MM/YYYY HH:mm')}
                                                                &nbsp;-&nbsp;
                                                                {this.state.aqbank_request_primario.responsavel_inicio_analise === null ? 
                                                                    '' : this.state.aqbank_request_primario.responsavel_inicio_analise.name
                                                                }
                                                            </p>

                                                        </>
                                                    :
                                                    <></>}
                                                </>
                                            }
                                        </>
                                    :<></>}
                                    {this.state.pagamento_secundario !== undefined && this.state.pagamento_secundario !== null ? <>
                                        <hr/>
                                        <small><b><u>Segundo pagamento</u></b></small><br/>
                                        <Button 
                                            color="dark" 
                                            style={{
                                                margin: 15
                                            }}
                                            onClick={() => this.setState({segundo_pagamento_show: !this.state.segundo_pagamento_show})}>
                                                <DropdownIcon dropdown={this.state.segundo_pagamento_show} />&nbsp;
                                                Visualizar Segundo Pagamento
                                        </Button>
                                        <br/>
                                        <Collapse isOpen={this.state.segundo_pagamento_show}>
                                            <p><b>ID da transação:</b> {this.state.pagamento_secundario.id_transacao}</p>
                                            <p><b>Forma de Pagamento:</b> {payment(this.state.pagamento_secundario.forma_pagamento)}</p>
                                            <p><b>Data de Pagamento:</b> {moment(this.state.pagamento_secundario.data_pagamento).format('DD/MM/YYYY HH:mm')}</p>
                                            <p><b>Valor:</b> R$ {parseFloat(this.state.pagamento_secundario.valor).toFixed(2).replace('.', ',')} / {this.state.pagamento_secundario.numero_parcelas}x</p>
                                            {this.state.pagamento_secundario.cartao_credito !== null ?
                                                <div>
                                                    <p><b>Tipo de cartão:</b> {this.state.pagamento_secundario.cartao_credito.tipo_cartao.toUpperCase()}</p>
                                                    <p><b>Nome no cartão:</b> {this.state.pagamento_secundario.cartao_credito.nome_proprietario}</p>
                                                    <p><b>Número do cartão:</b> {this.state.pagamento_secundario.cartao_credito.numero}</p>
                                                    <p><b>Validade:</b> {this.state.pagamento_secundario.cartao_credito.validade_mes}/{this.state.pagamento_secundario.cartao_credito.validade_ano}</p>
                                                    <p><b>Apelido do cartão:</b> {this.state.pagamento_secundario.apelido_cartao}</p>
                                                    <p><b>Bandeira:</b> {this.state.pagamento_secundario.cartao_credito.bandeira !== null ? this.state.pagamento_secundario.cartao_credito.bandeira.toUpperCase() : 'Sem informações'}</p>
                                                    {this.state.pagamento_secundario.cartao_credito.validate_card === 1 ?
                                                        <>
                                                            <p style={{color: 'green'}}><b>Cartão Validado</b></p>
                                                            <b>E-mails de validação recebidos do cliente:</b><br/>
                                                            {this.state.pagamento_secundario.mensagens_validacao_cartao.map(e => 
                                                                <small><b>{moment(e.created_at).format('DD/MM/YYYY HH:mm')}: </b>
                                                                <div 
                                                                    style={{
                                                                        border: '1px solid #7f7f7f66', 
                                                                        borderRadius: '15px', 
                                                                        padding: '19px',
                                                                        height: '150px',
                                                                        overflow: 'auto'
                                                                    }}>
                                                                    <div dangerouslySetInnerHTML={{__html: sanitizeHtml(e.descricao)}}/>
                                                                </div>
                                                                <br/></small>
                                                            )}
                                                        </>
                                                        :
                                                        <>
                                                            {this.state.pagamento_secundario.cartao_credito.pagamento_validacao_card === 0 || this.state.pagamento_secundario.cartao_credito.validate_card == false ?
                                                                <>
                                                                    <Button 
                                                                        color="dark" 
                                                                        style={{marginBottom: 10}} 
                                                                        hidden={this.state.show_segundo}
                                                                        disabled={this.state.loading_segundo}
                                                                        onClick={() => this.validarCartao('segundo')}
                                                                    >
                                                                        {this.state.loading_segundo === true ?
                                                                            <ClipLoader color="#fff" size={15}/>
                                                                        :
                                                                        ''} Validar cartão
                                                                    </Button>
                                                                    <Collapse isOpen={this.state.show_segundo} style={{marginBottom: 10}} >
                                                                        {this.segundo_pagamento()}
                                                                    </Collapse>
                                                                </>
                                                            :
                                                            <>
                                                                {this.segundo_pagamento()}
                                                            </>}
                                                        </>}
                                                </div>
                                                :
                                                <></>
                                            }
                                            <p><b>Navegador:</b> {this.state.pagamento_secundario.browser.length > 0 ? this.state.pagamento_secundario.browser : 'Sem informações'}</p>
                                            <p><b>Aparelho utilizado:</b> {this.state.pagamento_secundario.device.length > 0 ? this.state.pagamento_secundario.device : 'Sem informações'}</p>
                                            <p><b>Endereço de IP:</b> {this.state.pagamento_secundario.ip_client.length > 0 ? this.state.pagamento_secundario.ip_client : 'Sem informações'}</p>
                                        </Collapse>
                                        {this.state.pagamento_parcial === true ?
                                        <>
                                            {this.state.prevencao_exists === true && this.state.antifraude_finalizada === false ?
                                                <>
                                                {this.state.aqbank_request_secundario !== null ?
                                                    (<div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                                    <h5>Análise Antifraude do segundo pagamento</h5><hr/>
                                                        {this.state.aqbank_request_secundario.finalizado === 0 ?
                                                            <>
                                                                <p><b>Status:</b> {this.state.aqbank_request_secundario.status}</p>
                                                                <p><b>Chegada em Fila:</b> {moment(this.state.pagamento_secundario.data_pagamento).format('DD/MM/YYYY HH:mm')}</p>
                                                                <p><b>Inicio de Análise:</b> 
                                                                    {moment(this.state.aqbank_request_secundario.inicio_analise).format('DD/MM/YYYY HH:mm')} 
                                                                    &nbsp;-&nbsp;
                                                                    {this.state.aqbank_request_secundario.responsavel_inicio_analise === null ? '' : this.state.aqbank_request_secundario.responsavel_inicio_analise.name}</p>
                                                                <FormGroup>
                                                                    <Select 
                                                                        placeholder="Status" 
                                                                        options={list_prevencao_status}
                                                                        onChange={(status_current_secound) => this.setState({status_current_secound})}
                                                                        value={this.state.status_current_secound}
                                                                    />
                                                                </FormGroup>
                                                                <div>
                                                                    {this.state.status_current_secound !== null ?
                                                                        this.stepPrevencao(this.state.status_current_secound)
                                                                        :
                                                                        <></>}
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <p><b>Status:</b> {this.getStatus(this.state.aqbank_request_secundario.status)}</p>
                                                                {/* <p><b>Chegada em Fila:</b> {this.state.chegada_fila}</p> */}
                                                                <p><b>Chegada em Fila:</b> {moment(this.state.data_pagamento).format('DD/MM/YYYY HH:mm')}</p>
                                                                <p><b>Inicio de Análise:</b> {moment(this.state.aqbank_request_secundario.inicio_analise).format('DD/MM/YYYY HH:mm')} 
                                                                &nbsp;-&nbsp;
                                                                {this.state.aqbank_request_secundario.responsavel_inicio_analise === null ? 
                                                                    '' : this.state.aqbank_request_secundario.responsavel_inicio_analise.name
                                                                }</p>
                                                                <p><b>Final de Análise:</b> {moment(this.state.aqbank_request_secundario.final_analise).format('DD/MM/YYYY HH:mm')} - {this.state.aqbank_request_primario.responsavel_analise !== null ? this.state.aqbank_request_primario.responsavel_analise.name : ''}</p>
                                                            </>
                                                        }
                                                    </div>)
                                                    :
                                                    <>
                                                        <Button 
                                                            color="success" 
                                                            disabled={this.state.loading_verificacao_secound} 
                                                            style={{marginBottom: 5}} 
                                                            onClick={() => this.iniciar_segunda_verificacao(this.state.pagamento_secundario.id)}>
                                                            {this.state.loading_verificacao_secound === true ? <ClipLoader color="#fff" size={15}/> : ''}
                                                            &nbsp;Iniciar antifraude do segundo pagamento
                                                        </Button>
                                                    </>}
                                                </>
                                                :
                                                <>
                                                    <p><b>Status do pagamento: </b> {this.getStatus(this.state.pagamento_secundario.antifraude_code)}</p>
                                                    {this.state.aqbank_request_secundario !== null ? 
                                                        <>
                                                            <p>
                                                                <b>Inicio de Análise:</b> {moment(this.state.aqbank_request_secundario.inicio_analise).format('DD/MM/YYYY HH:mm')} 
                                                                &nbsp;-&nbsp;
                                                                {this.state.aqbank_request_secundario.responsavel_inicio_analise === null ? 
                                                                    '' : this.state.aqbank_request_secundario.responsavel_inicio_analise.name
                                                                }
                                                            </p>
                                                            <p><b>Final de Análise:</b> {moment(this.state.aqbank_request_secundario.final_analise).format('DD/MM/YYYY HH:mm')}
                                                                &nbsp;-&nbsp;
                                                                {this.state.aqbank_request_secundario.responsavel_inicio_analise === null ? 
                                                                    '' : this.state.aqbank_request_secundario.responsavel_inicio_analise.name
                                                                }
                                                            </p>

                                                        </>
                                                    :
                                                    <></>}
                                                </>
                                            }
                                        </>
                                    :<></>}
                                    </> : null}
                                    {this.state.pagamento_parcial === true &&
                                    this.state.prevencao_exists === true && 
                                    this.state.antifraude_finalizada === false && 
                                    (this.state.status_current_secound !== null || this.state.status_current_first !== null)
                                    ?
                                        <>
                                        <br/>
                                            <FormGroup>
                                                <Button disabled={this.state.loading_button} color="success" onClick={() => this.registrarPrevencaoMultipla()}>
                                                    {this.state.loading_button === true ? <ClipLoader color="#fff" size={15}/> : ''}
                                                    &nbsp;Salvar Antifraude 
                                                </Button>
                                            </FormGroup>
                                        </>
                                    :
                                    <></>
                                    }
                                </div>
                            </Col>
                        </Row>
                    :
                        <></>
                    }
                    <hr/>
                    <Card>
                        <CardBody style={{minHeight: '150px'}}>
                            <h6>Contas Bancarias</h6>
                            <hr/>
                            {this.state.conta_bancaria_loading === false ? 
                                <ContasBancarias data={this.state.contas_bancaria} /> :
                                <center><FadeLoader color={'#52c565'} margin={0} loading={50} size={60} /></center>
                            }
                            {/*  */}
                        </CardBody>
                    </Card>
                    <hr/>
                    <Row>
                        <Col lg={12} md={12}>
                            <Card>
                                <CardBody>
                                    <h6>Histórico</h6>
                                    <hr/>
                                    <DataTable
                                        noHeader={true}
                                        columns={colunas_pagamentos}
                                        data={this.state.history}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Div>
        )
    }
}

export default ResumoPayout;