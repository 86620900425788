import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { VscCheck } from 'react-icons/vsc';

const BoxSuccess = (props) => {
    return(
        <Modal isOpen={props.visible} centered={true}>
            <ModalBody>
                <center>
                    <VscCheck style={{height: '30%', width: '30%', opacity: '0.7'}} color="green" />
                    <br/><br/>
                    <h4 style={{fontWeight: '400'}}>{props.title}</h4>
                    <p>{props.message}</p>
                    <Button color="info" onClick={props.onChange} style={{width: '90px', height: '40px'}}>OK</Button>
                </center>
            </ModalBody>
        </Modal>
    )
}

export default BoxSuccess;