import { Component } from "react";
import Select from "react-select";
import { Button, FormFeedback, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ENDPOINT } from "../../../auth/endpoint";
import { list_planos } from "../../../auth/listas";
import { getToken } from "../../../auth/set_services";
import AsyncSelect from 'react-select/async';

const tipos = [
    {
        value: 'aqpago',
        label: 'Clientes AQPago'
    },
    {
        value: 'aqpasso',
        label: 'Cliente AQPasso'
    }
]

class SendNps extends Component{
    constructor(props){
        super(props);
        this.state = {
            cliente_tipo: null,
            cliente_tipo_invalid: false,
            tipo_envio: null,
            tipo_envio_invalid: false,
            plano: null,
            plano_invalid: false,
            tipo_pesquisa: 'nome',
            clientes: [],
            clientes_invalid: false,
            option_select: 'aqpago'
        }
    }
    loadOptions = (inputValue, callback) => {
        var url = ENDPOINT + "user/";
        if(this.state.option_select === 'aqpago'){
            url += "all_aqbank?"+this.state.tipo_pesquisa+"=" + inputValue;
        }
        else{
            url += "all?empresa_relacao=aqpasso&search=" + inputValue;
        }
        
        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p.data;

            if(this.state.option_select === 'aqpago'){
                data.forEach((p, i) => {
                    data[i].value = p.id;
                    data[i].label = (p.nome ? p.nome : p.responsavel.nome + ' '+p.responsavel.sobrenome) + ' - ' + (p.cpf !== null ? p.cpf : p.cnpj !== null ? p.cnpj : '');
                });
            }
            else{
                data.forEach((p, i) => {
                    data[i].value = p.id;
                    data[i].label = (p.nome !== null ? p.nome+" "+p.sobrenome : p.nome_fatura) +" - "+ p.email;
                });
            }
            callback(this.filter(inputValue, p.data));
        })
        .catch(() => {
            callback(this.filter(inputValue, []));
        });
    }

    filter = (inputValue, data) => {
        return data.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }
    onClose = () => {
        this.setState({
            cliente_tipo: null,
            cliente_tipo_invalid: false,
            tipo_envio: null,
            tipo_envio_invalid: false,
            plano: null,
            plano_invalid: false,
            tipo_pesquisa: 'nome',
            clientes: [],
            clientes_invalid: false,
            option_select: 'aqpago'
        });
        this.props.onClose(false)
    }

    render(){
        return(
            <Modal isOpen={this.props.visible}>
                <ModalHeader>Enviar Pesquisa</ModalHeader>
                <ModalBody hidden={!this.state.loading}>
                    <Select
                        placeholder="Enviar para:"
                        options={tipos}
                        onChange={(cliente_tipo) => this.setState({cliente_tipo})}
                    />
                    {this.state.cliente_tipo !== null ? 
                        <div style={{display: 'inline'}}>
                            <hr/>
                            <input type="radio" checked={this.state.tipo_envio === 'todos'} name="nps_tipo" onChange={() => this.setState({tipo_envio: 'todos'})}/>&nbsp;Todos&nbsp;&nbsp;
                            <input type="radio" checked={this.state.tipo_envio === 'manualmente'} name="nps_tipo" onChange={() => this.setState({tipo_envio: 'manualmente'})}/>&nbsp;Manualmente&nbsp;&nbsp;
                            <input type="radio" checked={this.state.tipo_envio === 'plano'} name="nps_tipo" onChange={() => this.setState({tipo_envio: 'plano'})}/>&nbsp;Por Plano
                        </div>
                        :
                        <></>
                    }
                    {this.state.tipo_envio === 'plano' ? 
                    <>
                        <>
                            <Select
                                className="select-option"
                                options={list_planos}
                                placeholder="Plano (opcional)"
                                onChange={(plano) => this.setState({plano})}
                                isSearchable={false}
                                theme={theme => ({
                                    ...theme
                                })}
                            />
                        </>
                        <Input hidden invalid={this.state.plano_invalid} />
                        <FormFeedback>Campo obrigatório</FormFeedback>
                    </>
                : this.state.tipo_envio === 'manualmente' ?
                    <>
                        <>
                            <AsyncSelect
                                className="select-option"
                                cacheOptions
                                loadOptions={this.loadOptions}
                                defaultOptions
                                isMulti
                                value={this.state.clientes} 
                                placeholder="Usuários"
                                onChange={(clientes) => this.setState({clientes})}
                            />
                        </>
                        <small>Pesquisa por: &nbsp;
                            <input type="radio" name="pesquisa_radio" onChange={() => this.setState({tipo_pesquisa: 'nome'})} checked={this.state.tipo_pesquisa === 'nome'} />&nbsp;Nome&nbsp;&nbsp;
                            <input type="radio" name="pesquisa_radio" onChange={() => this.setState({tipo_pesquisa: 'nome_empresarial'})} checked={this.state.tipo_pesquisa === 'nome_empresarial'}/>&nbsp;Nome Empresa&nbsp;&nbsp;
                            <input type="radio" name="pesquisa_radio" onChange={() => this.setState({tipo_pesquisa: 'cpf'})} checked={this.state.tipo_pesquisa === 'cpf'}/>&nbsp;CPF&nbsp;&nbsp;
                            <input type="radio" name="pesquisa_radio" onChange={() => this.setState({tipo_pesquisa: 'cnpj'})} checked={this.state.tipo_pesquisa === 'cnpj'}/>&nbsp;CNPJ&nbsp;&nbsp; 
                        </small>
                        <Input hidden invalid={this.state.telefone_invalid} />
                        <FormFeedback>Campo obrigatório</FormFeedback>
                    </>
                :
                    <></>
                }
                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose()}>Cancelar</Button>
                    <Button color="success">Salvar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default SendNps;