import axios from "axios";
import moment from "moment";
import { Component } from "react";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Collapse, Input, Nav, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import classnames from 'classnames';
import styled from "styled-components";
import EmailClient from "../../components/ResumeClient/EmailClient";
import SmsClient from "../../components/ResumeClient/SmsClient";
import ChatsClient from "../../components/ResumeClient/ChatsClient";
import NotasAtendimento from "../../components/ChatClient/NotasAtendimento";
import ProtocoloClient from "../../components/ResumeClient/ProtocoloClient";

const getColor = (status) => {
    switch(status){
        case 'DEVOLVIDO' : return 'danger';
        case 'CANCELADA' : return 'dark';
        case 'APROVADA' : return 'success';
        default : return 'info'
    }
}
const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
        padding: 0.5rem;
        border: 1px solid #ccc;
        margin: 0.2rem 0.3rem;
        border-radius: 5px;
    }
    .nav-tabs .nav-item.show .nav-link, 
    .nav-tabs .nav-link.active {
        background: #28a745;
        color: #fff;
    }
    .nav-link:hover{
        background: #70b580;
        color: #fff;
    }
    .nav-tabs {
        padding-bottom: 1rem;
    }
    informations {
        font-size: 18px !important;
        margin-bottom: 0.2rem;
    }
    .title-info {
        background: #f9f9f9;
        padding: 1rem;
    }
`

const get_id = () => {
    return window.location.pathname.replace('/querobus-passagem/', '')
}
const getUser = (id, callback) => {
    var url = ENDPOINT+"querobus/booking/get/"+id;
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        console.log('user: ', data)
        callback(data);
    })
}

const getStatusPaymet = (status) => {
    switch(status) {
        case 'ORDER_PAID':
            return 'PAGO';
        case 'ORDER_IN_ANALYSIS':
            return 'EM ANALISE';
        case 'ORDER_CREATE':
            return 'CRIADO';
        case 'ORDER_WAITING':
            return 'AGUARDANDO PAGAMENTO';
        case 'ORDER_WAITING':
            return 'AGUARDANDO PAGAMENTO';
        case 'ORDER_NOT_PAID':
            return 'NÃO PAGO';
        case 'ORDER_PARTIAL_PAID':
            return 'PAGO PARCIALMENTO';
        case 'ORDER_CANCELED':
            return 'CANCELADO';
        case 'ORDER_REVERSED':
            return 'REEMBOLSADO';
        case 'ORDER_PARTIAL_REVERSED':
            return 'REEMBOLSADO PARCIAL';
        case 'ORDER_CHARGE_BACK':
            return 'CHARGE BACK';
        case 'ORDER_DISPUTE':
            return 'DISPUTA';
        case 'ORDER_FAILED':
            return 'FALHA';
        default:
            return status;
    }
}

class ResumoPassagemQueroBus extends Component{
    constructor(props){
        super(props);
        this.state = {
            json_response: null,
            activeTab: "1",
            motivo_cancelamento: '',
            show_pass: false,
            cancelar_loading: false,
            reservas: []
        }
    }

    solicitarCancelamento = () => {
        this.setState({cancelar_loading: true})
        var url = ENDPOINT + "querobus/booking/cancelar/"+get_id();
        
        var formData = new FormData();
        formData.append('motivo_cancelamento', this.state.motivo_cancelamento);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.setState({
                motivo_cancelamento: '',
                show_pass: false,
                cancelar_loading: false
            })
            getUser(get_id(), this.callbackUser)
        })
        .catch(error => {
            var data = error.response.data;
            toast.error(data.errors);
        })
    }

    reenviarBilhete = () => {
        this.setState({cancelar_loading: true})
        var url = ENDPOINT + "querobus/booking/reenviar/"+get_id();

        var formData = new FormData();

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.setState({
                motivo_cancelamento: '',
                show_pass: false,
                cancelar_loading: false
            })
            getUser(get_id(), this.callbackUser)
        })
        .catch(error => {
            var data = error.response.data;
            toast.error(data.errors);
        })
    }

    componentDidMount(){
        getUser(get_id(), this.callbackUser)
    }
    callbackUser = (data) => {
        this.setState({ json_response: data });
    }

    listData = [
        { name: 'Dados do Cliente', active: '1' },
        { name: 'Histórico de Alterações', active: '2' },
        { name: 'E-mails', active: '3' },
        { name: 'SMS', active: '4' },
        { name: 'Chats', active: '5' },
        { name: 'Notas', active: '6' },
        { name: 'Protocolos ', active: '7' },
        { name: 'Reservas', active: '8' }
    ];

    Navs = () => {
        return <Nav tabs>
            {this.listData.map((p, index) => {
                return <NavLink
                    key={index}
                    className={
                        classnames({ active: this.state.activeTab === p.active })}

                    onClick={() => { 
                        if (p.active == 19) {
                            this.setState({ activeTab: p.active })
                        } else {
                            this.setState({ activeTab: p.active })
                        }
                    }}
                    
                    >
                    <span>{p.name}</span>
                </NavLink>
            })}
        </Nav>
    }
    render(){
        return(
            <Div>
                <h3>Resumo da passagem QUEROBUS</h3>
                <hr/>
                <Card>
                    <CardBody>
                        <h5 className="title-info">DADOS DO BUYER</h5>
                        {this.state.json_response ? 
                        <div className="infos">
                            <div style={{marginBottom: '1rem'}}>
                                <p style={{fontSize: '18px',marginBottom: '0.5rem'}}><b>Nome: </b> {this.state.json_response.user.social_name != null ? this.state.json_response.user.social_name + ' | ' + this.state.json_response.user.name : this.state.json_response.user.name}</p>
                                <p style={{fontSize: '18px',marginBottom: '0.5rem'}}><b>Email: </b> {this.state.json_response.user.email}</p>
                                <p style={{fontSize: '18px',marginBottom: '0.5rem'}}><b>Telefone: </b> <NumberFormat displayType={'text'} value={this.state.json_response.user.phone} format="(##) #####-####" /></p>
                                <p style={{fontSize: '18px',marginBottom: '1rem'}}><b>Documento (CPF/CNPJ): </b> {this.state.json_response.user.cnpj != null ? this.state.json_response.user.cnpj : this.state.json_response.user.cpf}</p>
                                <Button 
                                    color="success" 
                                    disabled={this.state.cancelar_loading} 
                                    hidden={!(this.state.json_response !== null ? (this.state.json_response.status_booking == 'APROVADA' ? true : false) : false)}
                                    onClick={() => this.reenviarBilhete()}>Reenviar Bilhete</Button>
                            </div>

                            
                            {/* color={getColor(this.state.json_response.status_booking)} */}
                            {/* color: getColor(this.state.json_response.status_booking) != 'success' ? 'white' : 'black' */}
                            {/* style={{margin: 15, borderRadius: 15 }} */}
                            <h5 className="title-info">
                                DADOS DA RESERVA

                                {this.state.json_response.status_booking != 'DEVOLVIDO' ? (
                                    <Button 
                                    color="danger" 
                                    style={{float: 'right',position: 'relative',top: '-5px'}}
                                    disabled={this.state.cancelar_loading} 
                                    //hidden={!(this.state.json_response !== null ? (this.state.json_response.status_booking == 'APROVADA' ? true : false) : false)}
                                    onClick={() => this.setState({ show_pass: !this.state.show_pass })}>
                                        Cancelar Passagem
                                    </Button>
                                ) : (<>
                                    <span style={{float: 'right', color: '#ff0000'}}>Reserva Cancelada</span>
                                    </>)}

                            </h5>
                            <Collapse isOpen={this.state.show_pass}>
                                <hr/>
                                <Input 
                                    name="motivo_cancelamento"
                                    type="textarea"
                                    placeholder="Motivo do Cancelamento"
                                    value={this.state.motivo_cancelamento}
                                    onChange={(e) => {
                                        var name = e.target.name;
                                        var value = e.target.value;
                                        this.setState({ [name]: value });
                                    }}
                                />
                                <br/>
                                <Button 
                                    color="warning" 
                                    disabled={this.state.cancelar_loading}
                                    onClick={() => this.solicitarCancelamento()}>
                                    Confirmar Cancelamento
                                </Button>
                            </Collapse>
                            <div style={{marginBottom: '1rem'}}>
                                <Row>
                                    <Col md={12} style={{padding: '1rem'}}>
                                        <img src={this.state.json_response.travel.url_banner} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <p style={{fontSize: '18px',marginBottom: '0.5rem'}}>
                                            <b>Valor Parceiro: </b> 
                                            <NumberFormat 
                                                displayType={'text'}
                                                prefix={'R$ '}
                                                decimalSeparator={','}
                                                thousandSeparator={'.'}
                                                value={parseFloat(this.state.json_response.order.partner_price)}
                                                />
                                        </p>        
                                        <p style={{fontSize: '18px',marginBottom: '0.5rem'}}>
                                            <b>Valor Taxa: </b>
                                            <NumberFormat 
                                                displayType={'text'}
                                                prefix={'R$ '}
                                                decimalSeparator={','}
                                                thousandSeparator={'.'}
                                                value={parseFloat(this.state.json_response.order.fees)}
                                                />
                                        </p>
                                        <p style={{fontSize: '18px',marginBottom: '0.5rem'}}>
                                            <b>Desconto Oferta: </b>
                                            <NumberFormat 
                                                displayType={'text'}
                                                prefix={'R$ '}
                                                decimalSeparator={','}
                                                thousandSeparator={'.'}
                                                value={(parseFloat(this.state.json_response.order.discounts) - parseFloat(this.state.json_response.order.discounts_cupom))}
                                                />
                                        </p>
                                        <p style={{fontSize: '18px',marginBottom: '0.5rem'}}>
                                            <b>Desconto Cupom: </b>
                                            <NumberFormat 
                                                displayType={'text'}
                                                prefix={'R$ '}
                                                decimalSeparator={','}
                                                thousandSeparator={'.'}
                                                value={parseFloat(this.state.json_response.order.discounts_cupom)}
                                                />

                                        </p>
                                        <p style={{fontSize: '18px',marginBottom: '0.5rem'}}>
                                            <b>Total: </b>
                                            <NumberFormat 
                                                displayType={'text'}
                                                prefix={'R$ '}
                                                decimalSeparator={','}
                                                thousandSeparator={'.'}
                                                value={parseFloat(this.state.json_response.order.amount)}
                                                />
                                        </p>        
                                    </Col>
                                    <Col md={6}>
                                        <p style={{fontSize: '18px',marginBottom: '0.5rem'}}><b>Data: </b> {moment(this.state.json_response.travel.date_accommodation).format('DD/MM/YYYY')}</p>
                                        <p style={{fontSize: '18px',marginBottom: '0.5rem'}}><b>Horário: </b> {this.state.json_response.travel.exit_hour}</p>
                                        <p style={{fontSize: '18px',marginBottom: '0.5rem'}}><b>Destino: </b> {this.state.json_response.travel.destiny_id}</p>
                                        <p style={{fontSize: '18px',marginBottom: '0.5rem'}}><b>Origem: </b> {this.state.json_response.travel.origin_id}</p>
                                        <p style={{fontSize: '18px',marginBottom: '0.5rem'}}><b>ID de reserva: </b>  {this.state.json_response.payment?.public_id}</p>        
                                    </Col>
                                </Row>
                            </div>

                            <h5 className="title-info">DADOS DO PASSAGEIIRO E PASSAGEM</h5>
                            
                            {this.state.json_response.seats.length ? (<>
                                <Card body >
                                    <CardBody>
                                        <p><b>Status da Reserva: </b>{this.state.json_response.status_booking}</p>
                                        <p><b>Status do Pagamento: </b>{getStatusPaymet(this.state.json_response.status_payment)}</p>
                                        <p><b>Valor: </b>{parseFloat(this.state.json_response.toll_price)}</p>
                                        <p><b>Data da Reserva: </b>{moment(this.state.json_response.booking_date).format('DD/MM/YYYY')}</p>
                                        <h5 className="title-info">ASSENTOS</h5>
                                        <Row>
                                            {this.state.json_response.seats.map(es => (
                                                <Col md={6} style={{ border: '1px solid', borderRadius: '10px', padding: '12px' }}>
                                                    <p><b>Assento:</b> {es.seat}</p>
                                                    <p><b>ID da Transação:</b> {es.booking_id.toUpperCase()}</p>
                                                    <p><b>Nome do Passageiro:</b> {es.name_client}</p>
                                                    <p><b>CPF do Passageiro:</b> {es.document}</p>
                                                    <p><b>Localizador:</b> {es.localization}</p>
                                                    <p>
                                                        <b>Valor da Taxas:</b>
                                                        <NumberFormat 
                                                            displayType={'text'}
                                                            prefix={'R$ '}
                                                            decimalSeparator={','}
                                                            thousandSeparator={'.'}
                                                            value={parseFloat(es.taxes)}
                                                            />
                                                    </p>
                                                    <p>
                                                        <b>Valor do Seguro:</b>
                                                        <NumberFormat 
                                                            displayType={'text'}
                                                            prefix={'R$ '}
                                                            decimalSeparator={','}
                                                            thousandSeparator={'.'}
                                                            value={parseFloat(es.insurance_price)}
                                                            />
                                                    </p>
                                                    <p>
                                                        <b>Valor do Desconto:</b>
                                                        <NumberFormat 
                                                            displayType={'text'}
                                                            prefix={'R$ '}
                                                            decimalSeparator={','}
                                                            thousandSeparator={'.'}
                                                            value={parseFloat(es.discounts)}
                                                            />
                                                    </p>
                                                    <p>
                                                        <b>Valor Pago:</b>
                                                        <NumberFormat 
                                                            displayType={'text'}
                                                            prefix={'R$ '}
                                                            decimalSeparator={','}
                                                            thousandSeparator={'.'}
                                                            value={parseFloat(es.amount)}
                                                            />
                                                    </p>
                                                    {es.boleto_url !== null ?
                                                        <b><a target="_blank" href={es.boleto_url}>Ver PDF da passagem</a></b>
                                                    : null}
                                                </Col>
                                            ))}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </>) : (<></>)}
                        </div>
                        :null}
                        <br/>
                        {this.state.json_response !== null ?
                            this.state.json_response.notas.map(e => <p>
                                <b>{moment(e.created_at).format('DD/MM/YYYY')}</b> - {e.descricao}
                            </p>)
                            :
                            null
                        }


                    </CardBody>
                </Card>
            </Div>
        )
    }
}

export default ResumoPassagemQueroBus;