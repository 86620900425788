import styled from "styled-components";

const ButtonD = styled.button`
    font-size: 13px;
    margin: 2px;
    border: 2px solid #8e959b;
    background-color: #ffffff;
    color: #5c5c5c;
    border-radius: 20px;
    margin-top: 5px;
    &:hover{
    background-color: #8e959b;
    color: #ffffff;
    }
    &:focus{
    box-shadow: 0 0 0.3em #343a40;
    }
`

export const ButtonGray = (props) => {
    return(
        <ButtonD
            type="button"
            {...props}
        />
    )

}