import axios from 'axios';
import moment from 'moment';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';


/* const getStatusTransaction = (status) => {
  if(status === 'authorized'){
      return 'Autorizado';
  }
  else if(status === 'refunded'){
      return 'Devolvido';
  }
  else{
      return 'Capturado';
  }
} */

const capitalize = str => {
	if (typeof str !== 'string') {
		return '';
	}
	return str.charAt(0).toUpperCase() + str.substr(1);
}
export const columns = () => [
    {
      name: "Data",
      selector: "created_at",
      sortable: false,
      width: '150px',
      cell: p => moment(p.created_at).format('DD/MM/YYYY HH:mm')
    },
    {
      name: "Tipo de Movimentação",
      selector: "tipo_deposito",
      sortable: false,
      width: '200px',
      cell: row => capitalize(row.tipo_deposito)
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
      width: '150px',
      cell: row => row.pagamento !== null ? row.pagamento.status : 'Sem Informações'
    },
    {
      name: "Nome na Fatura",
      selector: "nome_fatura",
      sortable: false,
      width: '25%',
      cell: row => row.conta !== null ? row.conta.nome_fatura : 'Sem Informações'
    },
    {
      name: "Documento",
      selector: "status",
      sortable: false,
      width: '150px',
      cell: row => row.conta !== null ? (row.conta.user !== null ? (row.conta.user.cnpj ? row.conta.user.cnpj : row.conta.user.cpf) : '')  : 'Sem Informações'
    },
    {
      name: "Número da Conta",
      selector: "status",
      sortable: false,
      width: '150px',
      cell: row => row.conta !== null ? row.conta.numero_conta : 'Sem Informações'
    },
    {
      name: "Valor de Depósito",
      selector: "valor",
      sortable: false,
      width: '150px',
      cell: row => row.valor !== null ? "R$ "+Number(row.valor).toFixed(2) : 'Sem Informações'
    }
    /* {
      name: "Saldo Anterior",
      selector: "saldo_anterior",
      sortable: false,
      width: '15%',
      cell: row => row.saldo_anterior !== null ? "R$ "+Number(row.saldo_anterior).toFixed(2) : 'Sem Informações'
    },
    {
      name: "Saldo Posterior",
      selector: "saldo_posterior",
      sortable: false,
      width: '15%',
      cell: row => row.saldo_posterior !== null ? "R$ "+Number(row.saldo_posterior).toFixed(2) : 'Sem Informações'
    } */
];

export const getRows = (state, callbackRows) => {
    
    var url = ENDPOINT + "taggy_recarga/list";

    if(state.taggy_serial !== null){
        url += '?taggy_serial='+state.taggy_serial;
    }

    if(state.document.replace(/([^\d])+/gim, '').length > 0){
      if(state.document.replace(/([^\d])+/gim, '').length === 11){
          url +='&cpf='+state.document.replace(/([^\d])+/gim, '');
      }
      else if(state.document.replace(/([^\d])+/gim, '').length === 14){
          url +='&cnpj='+state.document.replace(/([^\d])+/gim, '');
      }
    }
    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    
    var url = ENDPOINT + "taggy_recarga/list?search="+search+"&page="+page;

    if(state.taggy_serial !== null){
        url += '&taggy_serial='+state.taggy_serial;
    }
    if(filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    
    if(state.document.replace(/([^\d])+/gim, '').length > 0){
      if(state.document.replace(/([^\d])+/gim, '').length === 11){
          url +='&cpf='+state.document.replace(/([^\d])+/gim, '');
      }
      else if(state.document.replace(/([^\d])+/gim, '').length === 14){
          url +='&cnpj='+state.document.replace(/([^\d])+/gim, '');
      }
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}