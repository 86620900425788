import axios from "axios";
import React, { Component } from "react";
import { Button, Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import Select from "react-select";
import { InputText } from "../../InputText/InputText";
import ModelReturn from "../ReturnsApiModals/ModelReturn";


const conta_tipo = [
    {value: 'checking', label: 'Conta Poupança'},
    {value: 'savings', label: 'Conta Corrente'}
]

class ContaBancaria extends Component{
    constructor(props){
        super(props);
        this.state = {
            holder_name: '',
            holder_name_invalid: false,
            bank_code: '',
            bank_code_invalid: false,
            routing_number: '',
            routing_number_invalid: false,
            account_number: '',
            account_number_invalid: false,
            type: '',
            type_invalid: false,
            loading: false,
            error_message: '',
            success_message: '',
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        
        this.setState({[name]: value});
    }

    storeUser = () => {
        var url = ENDPOINT+"user/associar_conta_bancaria";
        //formData.append('telefone', this.state.telefone);
        var formData = new FormData();
        formData.append('user_id', this.props.userId);
        formData.append('bank_code', this.state.bank_code);
        formData.append('routing_number', this.state.routing_number.replace(/([^\d])+/gim, ''));
        formData.append('account_number', this.state.account_number.replace(/([^\d])+/gim, ''));
        formData.append('type', this.state.type.value);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            this.onData(data);
            this.props.onRefresh();
            return data;
        })
        .catch((error) => {
            console.log(error)
            var data = error.response.data;
            this.onData(data);
            return data;
        });
    }

    onData = (data) => {
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onRegister = () => {
        if(this.validateFields() === 0){
            this.setState({loading: true});
            this.storeUser();
        }
    }
    validateFields = () => {
        var invalids = 0;
        if(this.state.account_number.replace(/([^\d])+/gim, '').length > 3){
            this.setState({account_number_invalid: false})
        }
        else{
            invalids++;
            this.setState({account_number_invalid: true})
        }


        if(this.state.routing_number.replace(/([^\d])+/gim, '').length > 3){
            this.setState({routing_number_invalid: false})
        }
        else{
            invalids++;
            this.setState({routing_number_invalid: true})
        }

        if(this.state.bank_code !== null){
            this.setState({bank_code_invalid: false})
        }
        else{
            invalids++;
            this.setState({bank_code_invalid: true})
        }

        if(this.state.type !== null){
            this.setState({type_invalid: false})
        }
        else{
            invalids++;
            this.setState({type_invalid: true})
        }

        return invalids
    }
    onClose = () => {
        this.setState({
            holder_name: '',
            holder_name_invalid: false,
            bank_code: '',
            bank_code_invalid: false,
            routing_number: '',
            routing_number_invalid: false,
            account_number: '',
            account_number_invalid: false,
            type: '',
            type_invalid: false,
            loading: false,
            error_message: '',
            success_message: '',
        });
        this.props.onClose(false);
    }

    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    handleSelect = (name, type) => {
        this.setState({[name]: type})
    }
    render(){
        return (
            <Modal isOpen={this.props.visible}>
                <ModalHeader>Associar Conta Bancaria</ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <h6><b>Nome do portador da conta bancaria: </b> </h6>
                                <InputText 
                                    name="holder_name"
                                    value={this.state.holder_name}
                                    placeholder="Digite o portador da conta"
                                    invalid={this.state.holder_name_invalid}
                                    onChange={this.handleChange}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <h6><b>Agencia sem DV (4 Digitos): </b> </h6>
                                <InputText 
                                    name="routing_number"
                                    type="number"
                                    value={this.state.routing_number}
                                    placeholder="Digite a agencia sem o digito"
                                    invalid={this.state.routing_number_invalid}
                                    onChange={this.handleChange}/>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <h6><b>Número da Conta: </b> </h6>
                                <InputText 
                                    name="account_number"
                                    type="number"
                                    value={this.state.account_number}
                                    placeholder="Digite o Número da Conta"
                                    invalid={this.state.account_number_invalid}
                                    onChange={this.handleChange}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6><b>Tipo de conta: </b> </h6>
                            <Select
                                onChange={(value) => this.handleSelect('type', value)}
                                value={this.state.type}
                                options={conta_tipo}
                                placeholder="Tipo de conta"/>
                        </Col>
                        <Col>
                            <FormGroup>
                                <h6><b>Código do banco: </b> </h6>
                                <InputText 
                                    name="bank_code"
                                    type="number"
                                    value={this.state.bank_code}
                                    placeholder="Digite o código do banco"
                                    invalid={this.state.bank_code_invalid}
                                    onChange={this.handleChange}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalBody 
                    style={{
                        minHeight: '114px', 
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn 
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                        />
                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose()}>Cancelar</Button>
                    <Button color="success" onClick={() => this.onRegister()}>Registrar Conta Bancaria</Button>
                </ModalFooter>
            </Modal>
        )
    }

}

export default ContaBancaria;