import { Component } from "react";
import { Card, CardBody } from "reactstrap";
import OcorrenciaModal from "../../components/Modals/OcorrenciaModal/OcorrenciaModal";
import { TableOcorrencia } from "../../components/Tables/TableOcorrencia/TableOcorrencia";
import PropagateLoader from "react-spinners/PropagateLoader";
import { getRows, getRowsPerPage } from "../../components/Tables/TableOcorrencia/propsOcorrencia";
import { getPermissoes, getToken } from "../../auth/set_services";
import axios from "axios";
import { ENDPOINT } from "../../auth/endpoint";
import { toast } from "react-toastify";
import ModalDialog from "../../components/Modals/ReturnsApiModals/ModalDialog";

class Ocorrencia extends Component{
    constructor(props){
        super(props);
        this.state = {
            rowData: [],
            activePage: 1,
            loading_table: true,
            cpf_current: null,
            cnpj_current: '',
            totalPage: 1,
            document: '',
            produto: null,
            tipo_ocorrencia: null,
            razao: '',
            protocolo_ocorrencia: '',
            protocolo_cliente: '',
            is_cnpj: false,
            filter: false,
            visibled_add: false,
            visibled_taggy: false,
            search: '',
            visible_ocorrencia: false,
            operador: null,

            delete_id: -1,
            error_message: '',
            success_message: '',
            loading: false,
            visible_delete: false,
        }
    }
    onShowDelete(delete_id){
        console.log(delete_id)
        this.setState({
            delete_id,
            visible_delete: true
        })
    }
    onClose(){
        this.setState({
            delete_id: -1,
            error_message: '',
            success_message: '',
            loading: false,
            visible_delete: false,
        })
    }
    onError(){
        this.setState({
            loading: false,
            error_message: ''
        })
    }

    onDelete = () => {
        this.setState({loading: true})
        var url = ENDPOINT+"ocorrencia/delete/"+this.state.delete_id;
        axios.delete(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.onClose();
            getRows(this.state, this.callbackRows.bind(this));
            return data;
        })
        .catch((error) => {
            var response = '';
            var obj;
            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            if(data.exception){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            this.onData(data);
            return data;
        });
    }

    onData(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            });
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }

    componentDidMount() {
        getRows(this.state, this.callbackRows.bind(this));
    }
    onShowStore = (visible_ocorrencia) => {
        this.setState({visible_ocorrencia})
        getRowsPerPage(this.state.activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.rowData = [];
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }
    handleSelect = (name, value) => {
        var state = this.state;
        state[name] = value;
        state.rowData = [];
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    onFilter = (filter) => {
        this.setState({ filter });
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    render(){
        return(
            <div>
                <h3>Ocorrências</h3>
                <hr />
                <Card>
                    <TableOcorrencia
                        isSearch={this.handleChange}
                        isSearchSelect={this.handleSelect}
                        isShowNew={this.onShowStore}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        data={this.state.rowData}
                        produto={this.state.produto}
                        tipo_ocorrencia={this.state.tipo_ocorrencia}
                        is_cnpj={this.state.is_cnpj}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        noDataComponent={this.state.loading_table === true ?
                            <Card style={{ border: 0, padding: '10vh' }}><CardBody>
                                <div><div className="sweet-loading">
                                    <PropagateLoader color={'#52c565'} loading={20} size={30} />
                                </div></div></CardBody></Card> :
                            <Card style={{ border: 0 }}><CardBody><div>
                                Nenhuma ocorrência encontrada
                            </div></CardBody></Card>
                        }
                        /* onAdd={this.onClose.bind(this)} */
                        onDelete={this.onShowDelete.bind(this)}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <OcorrenciaModal
                    visible={this.state.visible_ocorrencia}
                    onClose={() => this.onShowStore(false)}
                />
                <ModalDialog
                    visible={this.state.visible_delete}
                    onError={this.onError.bind(this)}
                    message="Deseja remover esse operador do grupo ?"
                    onClose={() => this.onClose()}
                    error_message={this.state.error_message}
                    loading={this.state.loading}
                    onSave={this.onDelete.bind(this)}
                    success_message={this.state.success_message}
                />
            </div>
        )
    }
}
export default Ocorrencia;