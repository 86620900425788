import React from 'react';
import { ImFileEmpty } from 'react-icons/im';
import { Col, Row } from "reactstrap";
import Moment from 'moment';
import { GrTransaction } from 'react-icons/gr';

/* function creditsAproved(data) {
    var array = [];
    data.forEach(e => {
        if(e.pagamento!== null){
            if(e.pagamento.status === "APROVADO"){
                array.push(e);
            }
        }
    });
    return array;
}
 */
const payment = (payment_type) => {
    switch(payment_type){
        case 'credit' : return 'Crédito';
        case 'boleto' : return 'Boleto';
        case 'debit' : return 'Débito';
        default: return payment_type
    }
}

const status_transacao = (payment_type) => {
    switch(payment_type){
        case 'succeeded' : return 'Aprovada';
        case 'canceled' : return 'Cancelada';
        case 'pending' : return 'Pendente';
        case 'failed' : return 'Falhada';
        default: return payment_type
    }
}
const Transacoes = (props) => {
    var isData = props.data;
    return (
        <>
            <div hidden={props.data.length === 0}>
                <Row style={{fontSize: '14px', paddingLeft: 16}}>
                    <Col md={1} className="d-none d-md-block">
                    </Col>
                    <Col md={2} className="d-none d-md-block" style={{alignSelf: 'center'}}>
                        <b style={{fontWeight: 500}}>Data</b>
                    </Col>
                    <Col md={2} className="d-none d-md-block">
                        <b style={{fontWeight: 500}}>Status</b>
                    </Col>
                    <Col md={3} className="d-none d-md-block">
                        <b style={{fontWeight: 500}}>Numero de transação</b>
                    </Col>
                    <Col md={2} className="d-none d-md-block">
                        <b style={{fontWeight: 500}}>Tipo</b>
                    </Col>
                    <Col md={2} className="d-none d-md-block">
                        <b style={{fontWeight: 500}}>Valor</b>
                    </Col>
                </Row>
                <hr className="d-none d-md-block"/>
            </div>
            {isData.map(p => {
                return (
                    <>
                    <Row style={{fontSize: '14px', paddingLeft: 16}}>
                        <Col md={1}>
                            <GrTransaction style={{ width: '31px', height: '31px' }}/>
                        </Col>
                        <Col md={2}>
                            <p style={{ marginBottom: 0 }}>{Moment(new Date(p.created_at).toDateString()).format('DD/MM/YYYY')}</p>
                        </Col>
                        <Col md={2}>
                            <p style={{ marginBottom: 0 }}>{status_transacao(p.status)}</p>
                        </Col>
                        <Col md={3}>
                            <p style={{ marginBottom: 0 }}>{p.transaction_number !== null ? p.transaction_number : 'Sem Informação'}</p>
                        </Col>
                        <Col md={2}>
                            <p style={{ marginBottom: 0 }}>{payment(p.payment_type)}</p>
                        </Col>
                        <Col md={2}>
                            <p style={{ marginBottom: 0 }}><b>Valor:</b> {p.amount}<br/>
                            <b>Valor Líquido:</b> {p.original_amount}</p>
                        </Col>
                    </Row>
                    <hr/>
                    </>
                )
            })}
            {props.data.length === 0 ?
            <div>
                <center>
                    <ImFileEmpty style={{width: '50px', height: '50px'}} /><br/>
                    Não há histórico de transações
                </center>
            </div>:<></>}
        </>
    )
}

export default Transacoes;