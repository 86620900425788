import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Loader } from "../../components/Loaders/Loader";
import { ButtonOnboarding } from "../../components/Button/ButtonOnboarding";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import { HiOutlineIdentification } from "react-icons/hi";
import { TiDeleteOutline } from "react-icons/ti";
import { TiDocumentText } from "react-icons/ti";
import { IoEllipsisVerticalOutline } from "react-icons/io5";
import { useApiOnboarding } from "../../hooks/useApi";
import {
  formatDocument,
  booleanToYesOrNo,
  castingDate,
  statusCafTranslate,
} from "../../utils";
import { useLocation } from "react-router-dom";
import ActivateOnboardingUser from "../../components/Modals/ActivateOnboardingUser/ActivateOnboardingUser";

const Board = styled.div`
  background-color: #f5f5f5;
  margin-top: 30px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h2 {
    margin-bottom: 10px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin-bottom: 5px;
  }
`;

const Texto = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  /* flex: 1; */
`;

const Imagens = styled.div`
  display: flex;
  width: 100%;
  gap: 4%;
`;

const Imagem = styled.img`
  height: auto;
  ${({ qtd }) => {
    if (qtd === 1) {
      return "max-width: 90%;";
    } else if (qtd === 2) {
      return "width: 45%;";
    } else if (qtd === 3) {
      return "width: 30%;";
    } else {
      return "width: 100%;";
    }
  }}
`;

const ListStatusCaf = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  margin-top: 1%;
  border: 1px solid;
  border-radius: 12px;
  width: 30%;
`;

const CompliceLi = styled.li`
  &.approved {
    background-color: rgba(40, 167, 69, 0.05);
  }
  &.status-alert {
    background-color: rgba(255, 193, 7, 0.1);
  }
  &.status-reproved {
    background-color: rgba(255, 0, 0, 0.1);
  }
  div {
    display: flex;
    align-items: flex-start;
  }
`;

const getCompliceData = (complice) => {
  if (complice.resultStatus === "APPROVED") {
    return (
      <CompliceLi className="approved">
        <div>
          <FaCheck color="#28a745" />
          {complice.description}
        </div>
      </CompliceLi>
    );
  }
  if (complice.resultStatus === "PENDING") {
    return (
      <CompliceLi className="status-alert">
        <div>
          <FiAlertTriangle color="#ffc107" />
          {complice.description}
        </div>
      </CompliceLi>
    );
  }
  if (complice.resultStatus === "REPROVED") {
    return (
      <CompliceLi className="status-reproved">
        <div>
          <TiDeleteOutline color="#ff0000" />
          {complice.description}
        </div>
      </CompliceLi>
    );
  }

  if(complice.resultStatus === "") {
    return false;
  }

  return (
    <CompliceLi>
      <div>
        <FaCheck />
      </div>
      {complice.description}
    </CompliceLi>
  );
};

export function UserDetails() {
  const url = useLocation().pathname;
  const urlArray = url.split("/");
  const id = Number(urlArray[urlArray.length - 1]);
  const [userData, setUserData] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [isFetching, setIsFetiching] = useState(true);

  const [activateModal, setActivateModal] = useState(false);

  const { apiOnboarding } = useApiOnboarding();

  const getUserData = (id) => {
    const token = Cookies.get("onboardingtoken");

    const urlRequest = `/crm/onboarding/${id}`;

    apiOnboarding
      .get(urlRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUserData(res.data);
      })
      .catch((e) => {
        console.log(e);
        alert("Erro ao obter dados!");
      })
      .finally(() => {
        setIsFetiching(false);
      });
  };
  useEffect(() => {
    getUserData(id);
  }, [isActive]);

  function calcularIdade(dataNascimento) {
    const hoje = new Date();
    const nascimento = new Date(dataNascimento);
    let idade = hoje.getFullYear() - nascimento.getFullYear();
    const mes = hoje.getMonth() - nascimento.getMonth();

    if (mes < 0 || (mes === 0 && hoje.getDate() < nascimento.getDate())) {
      idade--;
    }

    return idade;
  }

  function formatarMoeda(valor) {
    // Certificar-se de que o valor é um número
    const numero = parseFloat(valor);

    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(numero);
  }



  function activeUser() {
    const confirm = window.confirm(
      "Tem certeza que deseja ativar esse usuario?"
    );
    if (!confirm) {
      return;
    }
    const token = Cookies.get("token");
    const urlRequest = "/crm/onboarding/activate";
    apiOnboarding
      .post(
        urlRequest,
        { identification: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setIsActive(true);
      })
      .catch((e) => {
        console.log(e);
        alert("Erro ao obter dados!");
      })
      .finally(() => {
        //   setIsFetiching(false);
      });
  }
  const getCafCard = (props) => {
    return (
      <ListStatusCaf>
        <div>
          <HiOutlineIdentification
            style={{ width: "50px", height: "50px" }}
            color={props?.is_verify_identity ? "#28a745" : "#ff0000"}
          />
          {props?.is_verify_identity ? (
            <FaCheck color="#28a745" />
          ) : (
            <FiAlertTriangle
              color="#ff0000"
              style={{ width: "30px", height: "30px" }}
            />
          )}

          <strong style={{ fontSize: "1.25rem" }}>
            {props?.is_verify_identity
              ? "Identidade Verificada"
              : "Erro na verficação"}
          </strong>
        </div>
        <IoEllipsisVerticalOutline style={{ width: "50px", height: "50px" }} />

        <div>
          <HiOutlineIdentification
            style={{ width: "50px", height: "50px" }}
            color={props?.is_authentic_identity ? "#28a745" : "#ff0000"}
          />
          {props?.is_authentic_identity ? (
            <FaCheck color="#28a745" />
          ) : (
            <FiAlertTriangle
              color="#ff0000"
              style={{ width: "30px", height: "30px" }}
            />
          )}

          <strong style={{ fontSize: "1.25rem" }}>
            {props?.is_authentic_identity
              ? "Identidade Autenticada"
              : "Erro na Autenticação"}
          </strong>
        </div>

        <IoEllipsisVerticalOutline style={{ width: "50px", height: "50px" }} />

        <div>
          <TiDocumentText
            style={{ width: "50px", height: "50px" }}
            color={props?.is_unsatisfied_rules ? "#28a745" : "#ffc107"}
          />
          {props?.is_unsatisfied_rules ? (
            <FaCheck color="#28a745" />
          ) : (
            <FiAlertTriangle
              color="#ffc107"
              style={{ width: "30px", height: "30px" }}
            />
          )}

          <strong style={{ fontSize: "1.25rem" }}>Regras de Complice</strong>
        </div>
      </ListStatusCaf>
    );
  };


  function getCafIcon(cafData) {
    const CafContent = {
      is_verify_identity: { text: "Verificação de Identidade" },
      is_authentic_identity: { text: "Autenticação de Identidade" },
      is_unsatisfied_rules: { text: "Regras de Complice" },
    };
    if (cafData.report.consult_validation.is_verify_identity) {
      CafContent.verify_identity.icon = (
        <HiOutlineIdentification
          style={{ width: "70px", height: "70px" }}
          color="#28a745"
        />
      );
    }

    if (cafData.report.consult_validation.is_authentic_identity) {
      CafContent.is_authentic_identity.icon = (
        <HiOutlineIdentification
          style={{ width: "70px", height: "70px" }}
          color="#28a745"
        />
      );
      CafContent.is_authentic_identity.checkIcon = <FaCheck color="#28a745" />;
    }

    if (cafData.report.consult_validation.is_unsatisfied_rules) {
      CafContent.is_unsatisfied_rules.icon = (
        <TiDocumentText
          style={{ width: "70px", height: "70px" }}
          color="#28a745"
        />
      );
      CafContent.is_authentic_identity.checkIcon = <FaCheck color="#28a745" />;
    }

    return CafContent;
  }
  if (isFetching) {
    return <Loader />;
  } else {
    console.log(userData);
    return (
      <>
        {activateModal && (
          <ActivateOnboardingUser
            activateModal={activateModal}
            setActivateModal={setActivateModal}
            user={userData && userData}
          />
        )}

        {Object.keys(userData).length !== 0 ? (
          <div>
            <h3>Dados do Cliente</h3>
            <hr />

            <Board>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4>
                  <strong>{userData?.dataUserCard?.name}</strong>
                </h4>
                <div
                  style={{ display: "flex", gap: "8px", alignItems: "center" }}
                >
                  {userData?.dataZoopCard &&
                    Array.isArray(userData?.dataZoopCard) &&
                    userData?.dataZoopCard?.length === 0 && (
                      <ButtonOnboarding
                        onClick={() => setActivateModal(true)}
                        disabled={
                          !userData?.dataAccountCard ||
                          (Array.isArray(userData?.dataAccountCard) &&
                            userData?.dataAccountCard?.length === 0)
                        }
                      >
                        Credenciar usuário
                      </ButtonOnboarding>
                    )}
                  {!userData?.dataUserCard?.user_is_active && (
                    <ButtonOnboarding
                      onClick={() => activeUser()}
                      disabled={
                        !userData?.dataZoopCard ||
                        (Array.isArray(userData?.dataZoopCard) &&
                          userData?.dataZoopCard?.length === 0)
                      }
                    >
                      Ativar usuário
                    </ButtonOnboarding>
                  )}
                </div>
              </div>
              <h6>
                {userData?.dataUserCard?.user_type.toLocaleUpperCase() === 'PJ' ? 'Cliente CNPJ' : 'Cliente CPF'}
              </h6>
              <br></br>

              <h5>
                {userData?.dataUserCard?.user_type.toLocaleUpperCase() === 'PJ' ? 'Dados do Solicitante' : 'Dados Pessoais'}
              </h5>
              <hr></hr>
              <ul>
                <li>
                  <strong>Usuário ativo: </strong>
                  {booleanToYesOrNo(userData?.dataUserCard?.user_is_active)}
                </li>
                <li>
                  <strong>Tipo:</strong>{" "}
                  {userData?.dataUserCard?.user_type.toLocaleUpperCase()}
                </li>
                <li>
                  <strong>Documento:</strong>{" "}
                  {formatDocument(
                    userData?.dataUserCard?.type_document,
                    userData?.dataUserCard?.document
                  )}
                </li>
                {userData?.dataUserCard?.birthdate && (
                  <li>
                    <strong>Data de Nascimento: </strong>{" "}
                    {castingDate(userData?.dataUserCard?.birthdate)}
                  </li>
                )}
                {userData?.dataUserCard?.birthdate && (
                  <li>
                    <strong>Idade: </strong>{" "}
                    {calcularIdade(userData?.dataUserCard?.birthdate)}
                  </li>
                )}
                <li><strong>Profissão: </strong>{" "}
                  {userData?.dataUserCard?.profession ? userData?.dataUserCard?.profession : 'N/A'}
                </li>

                <li><strong>Endereço Completo: </strong>
                  {userData?.dataUserCard?.address}, {userData?.dataUserCard.address_number}  {userData?.dataUserCard?.additional_address} , {userData?.dataUserCard?.neighborhood} - {userData?.dataUserCard?.city} - {userData?.dataUserCard?.state} - CEP: {userData?.dataUserCard?.zip_code}
                </li>

                {userData?.dataUserCard?.mother_name && (
                  <li>
                    <strong>Nome da Mãe: </strong>
                    {userData?.dataUserCard?.mother_name}
                  </li>
                )}

                {userData?.dataUserCard?.email && (
                  <li>
                    <strong>Email: </strong>
                    {userData.dataUserCard.email}
                  </li>
                )}

                {userData?.dataUserCard?.phone && (
                  <li>
                    <strong>Telefone/Celular: </strong>
                    {userData?.dataUserCard?.phone}
                  </li>
                )}
                {userData?.dataUserCard?.revenue && (
                  <li>
                    <strong>Renda: </strong>
                    {formatarMoeda(userData?.dataUserCard?.revenue)}
                  </li>
                )}
                <li>
                  <strong>Enviou Documento: </strong>
                  {booleanToYesOrNo(userData?.dataUserCard?.documents_sent)}
                </li>
                <li>
                  <strong>Enviou Selfie: </strong>
                  {booleanToYesOrNo(userData?.dataUserCard?.selfie_sent)}
                </li>
                <li>
                  <strong>Criado em: </strong>
                  {castingDate(userData?.dataUserCard?.created_at)}
                </li>

                {userData?.dataBankingCard && (
                  <ul>
                    <br></br>
                    <strong>Dados Bancários:</strong>
                    <br></br>
                    <br></br>
                    <li style={{ marginBottom: '20px' }}>
                      <ul>
                        <li><strong>- Nome do Portador:</strong> {userData.dataBankingCard.nome_portador}</li>
                        <li><strong>- Código do Banco:</strong> {userData.dataBankingCard.bank_code}</li>
                        <li><strong>- Conta Número:</strong> {userData.dataBankingCard.conta_numero}</li>
                        <li><strong>- Agência:</strong> {userData.dataBankingCard.agencia}</li>
                        <li><strong>- Tipo:</strong> {userData.dataBankingCard.tipo}</li>
                        <li><strong>- CPF:</strong> {userData.dataBankingCard.cpf}</li>
                        <li><strong>- CNPJ:</strong> {userData.dataBankingCard.cnpj}</li>
                      </ul>
                    </li>
                  </ul>
                )}

              </ul>
              {userData?.dataUserCard?.user_type.toLocaleUpperCase() === 'PJ' && (
                <div>
                  <h5>Dados da Empresa</h5>
                  <hr />
                  <ul>
                    {userData?.dataCompanyCard?.nature_code + userData?.dataCompanyCard.nature_desc && (
                      <li><strong>Código e Descrição da Natureza Jurídica: </strong>
                        {userData?.dataCompanyCard?.nature_code} - {userData?.dataCompanyCard?.nature_desc}
                      </li>
                    )}


                    {userData?.dataCompanyCard?.is_mei !== undefined && (
                      <li><strong>Empresa é Mei: </strong>
                        {userData.dataCompanyCard.is_mei ? 'Sim' : 'Não'}
                      </li>
                    )}

                    {userData?.dataCompanyCard?.is_simples !== undefined && (
                      <li><strong>Empresa é Simples: </strong>
                        {userData.dataCompanyCard.is_simples ? 'Sim' : 'Não'}
                      </li>
                    )}


                    {userData?.dataCompanyCard?.cnpj && (
                      <li><strong>CNPJ da empresa: </strong>
                        {userData?.dataCompanyCard?.cnpj}
                      </li>
                    )}
                    {userData?.dataCompanyCard?.owner_cpf && (
                      <li><strong>CPF do representante legal ou administrador: </strong>
                        {userData?.dataCompanyCard?.owner_cpf}
                      </li>
                    )}
                    {userData?.dataCompanyCard?.activity_branch && (
                      <li><strong>Código Ramo de Atividade: </strong>
                        {userData?.dataCompanyCard?.activity_branch}
                      </li>
                    )}
                    {userData?.dataCompanyCard?.activity_branch_desc && (
                      <li><strong>Nome Ramo de Atividade: </strong>
                        {userData?.dataCompanyCard?.activity_branch_desc}
                      </li>
                    )}

                    <li><strong>Telefone da Empresa: </strong>
                      {userData?.dataCompanyCard?.phone ? userData?.dataCompanyCard?.phone : 'N/A'}
                    </li>

                    <li><strong>Razão Social: </strong>
                      {userData?.dataCompanyCard?.company ? userData?.dataCompanyCard?.company : 'N/A'}
                    </li>

                    {userData?.dataCompanyCard?.trading_name && (
                      <li><strong>Nome Fantasia: </strong>
                        {userData?.dataCompanyCard?.trading_name}
                      </li>
                    )}
                    <li><strong>Data da Constituição: </strong>
                      {userData?.dataCompanyCard?.incorporation_date ? userData?.dataCompanyCard?.incorporation_date : 'N/A'}
                    </li>

                    <li><strong>Endereço da Empresa: </strong>
                      {userData?.dataCompanyCard?.address ?
                        userData?.dataCompanyCard?.address + ',' + userData?.dataCompanyCard.address_number + userData?.dataCompanyCard?.additional_address + ',' + userData?.dataCompanyCard?.neighborhood + '-' + userData?.dataCompanyCard?.city + '-' + userData?.dataCompanyCard?.state + '- CEP' + userData?.dataCompanyCard?.zip_code : 'N/A'}
                    </li>

                    {userData?.dataCompanyCard?.revenue && (
                      <li><strong>Faturamento Mensal da Empresa: </strong>
                        {formatarMoeda(userData?.dataCompanyCard?.revenue)}
                      </li>
                    )}

                    <li><strong>Email da empresa: </strong>
                      {userData?.dataCompanyCard?.email ? userData?.dataCompanyCard?.email : 'N/A'}
                    </li>

                    <br></br>

                    {userData?.dataCompanyCard?.cnaes && (
                      <ul>
                        <strong>CNAEs da empresa:</strong>
                        <br></br>
                        <br></br>
                        {userData.dataCompanyCard.cnaes.map((cnae, index) => (
                          <li key={index} style={{ marginBottom: '20px' }}>
                            <ul>
                              <li><strong>- Código:</strong> {cnae.code}</li>
                              <li><strong>- Descrição:</strong> {cnae.description}</li>
                            </ul>
                          </li>
                        ))}
                      </ul>
                    )}

                    <br></br>

                    {userData?.dataCompanyCard?.partners && (
                      <ul>
                        <strong>Sócios da empresa:</strong>

                        <br></br>
                        {userData.dataCompanyCard.partners.map((partner, index) => (

                          <li key={index} style={{ marginBottom: '20px' }}>
                            <br></br>
                            <ul>
                              <li><strong>- CPF:</strong> {partner.cpf}</li>
                              <li><strong>- Nome:</strong> {partner.name}</li>
                              <li><strong>- Qualificação:</strong> {partner.qualification}</li>
                              <li><strong>- Percentual:</strong> {partner.percentage}%</li>
                            </ul>
                            <br></br>
                          </li>
                        ))}
                      </ul>
                    )}



                  </ul>
                </div>
              )}
              <hr style={{ marginBottom: '40px' }}></hr>
              {userData?.dataUserCard?.images.length > 0 && (
                <div>
                  <Texto>
                    <h4> Imagens Enviadas </h4>
                  </Texto>
                  <br />
                  <Imagens>
                    {userData?.dataUserCard.images.length >= 1 && (
                      <Imagem
                        src={userData?.dataUserCard.images[0].replace(
                          /\\/g,
                          ""
                        )}
                        qtd={userData?.dataUserCard.images.length}
                      />
                    )}
                    {userData?.dataUserCard.images.length >= 2 && (
                      <Imagem
                        src={userData?.dataUserCard.images[1].replace(
                          /\\/g,
                          ""
                        )}
                        qtd={userData?.dataUserCard.images.length}
                      />
                    )}
                    {userData?.dataUserCard.images.length == 3 && (
                      <Imagem
                        src={userData?.dataUserCard.images[2].replace(
                          /\\/g,
                          ""
                        )}
                        qtd={userData?.dataUserCard.images.length}
                      />
                    )}
                  </Imagens>
                </div>
              )}
            </Board>

            <hr />
            {!Array.isArray(userData.dataUnicoCard) && (
              <Board>
                <h4>Único</h4>
                <hr />
                <ul>
                  <li>
                    <strong>Id: </strong>
                    {userData.dataUnicoCard?.unico_id}
                  </li>
                  <li>
                    <strong>Face Match: </strong>
                    {userData.dataUnicoCard?.unico_face_match === "1"
                      ? "Sim"
                      : "Não"}
                  </li>
                  <li>
                    <strong>Face Liveness: </strong>
                    {userData.dataUnicoCard?.unico_liveness === "1"
                      ? "Sim"
                      : "Não"}
                  </li>
                  <li>
                    <strong>Score: </strong>
                    {userData.dataUnicoCard?.unico_score}
                  </li>
                  <li>
                    <strong>Status: </strong>
                    {userData.dataUnicoCard?.unico_status}
                  </li>
                </ul>
              </Board>
            )}

            {!Array.isArray(userData?.dataCafMessagesCard) && (
              <Board>
                <h4>CAF</h4>
                <hr />
                <div>
                  <ul>
                    <li>
                      <strong>Id: </strong>
                      {userData.dataCafMessagesCard?.caf_id}
                    </li>
                    <li>
                      <strong>Status: </strong>
                      {statusCafTranslate(
                        userData?.dataCafMessagesCard?.status
                      )}
                    </li>
                    <li>
                      <strong>Data: </strong>
                      {castingDate(userData?.dataCafMessagesCard?.date)}
                    </li>
                  </ul>
                  {userData.dataCafMessagesCard?.report?.consult_validation &&
                    getCafCard(
                      userData.dataCafMessagesCard?.report?.consult_validation
                    )}
                </div>

              </Board>
            )}
            {userData?.dataAllCafMessagesCard?.length > 0 && (
              <div>                
                <Board>
                <h4 style={{ marginBottom: "20px" }}>Complice</h4>
                {userData.dataAllCafMessagesCard.map((cafMessage, index) => (
                  <div key={index}>
                    {cafMessage?.report?.compliance_rules?.length > 0 && (
                      <div>
 
                        <ul>
                          {cafMessage.report.compliance_rules.map((complice) =>
                            getCompliceData(complice)
                          )}
                        </ul>
                        </div>
                    )}
                  </div>
                ))}
                </Board>
              </div>
            )}
          </div>
        ) : (
          <div>Usúario não encontrado</div>
        )}
      </>
    );
  }
}
