import axios from "axios";
import moment from "moment";
import { Component } from "react"
import Select from "react-select"
import { Col, Form, FormGroup, Row, Label, Input, Button } from "reactstrap";
import { ENDPOINT } from "../../auth/endpoint";
import { list_origem, list_tipos, list_status, list_razao } from "../../auth/listas";
import { getToken } from "../../auth/set_services";
import AsyncSelect from 'react-select/async';
import { toast } from "react-toastify";

const getDepartamentos = (callback) => {
    var url = ENDPOINT + "departamento/list";
    
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        var list_departamentos = [];
        data.forEach((p, i) => {
            p['value'] = p.flag;
            p['label'] = p.nome;
            list_departamentos.push(p);
        });

        callback(list_departamentos)
    });
}
export class Configuracoes extends Component {
    constructor(props){
        super(props);
        this.state = {
            status: null,
            status_invalid: false,
            tipo_ocorrencia: null,
            tipo_ocorrencia_invalid: false,
            razao_ocorrencia: null,
            razao_ocorrencia_invalid: false,
            origem_ocorrencia: null,
            origem_ocorrencia_invalid: false,
            departamento: null,
            departamento_invalid: false,
            prazo_retorno: '',
            prazo_retorno_invalid: false,
            funcionario_atribuido: null,
            funcionario_atribuido_invalid: false,
            loading: false,
            
            list_departamentos: []
        }
    }
    handleInputChange = (e) => {
        const { name, value } = e.target;
        
        this.setState({ [name]: value });
    }
    componentDidMount(){
        getDepartamentos(this.callbackDepartamentos.bind(this));
    }
    callbackDepartamentos(list_departamentos){
        this.setState({list_departamentos});
    }
    componentWillUpdate(nextProps, nextState){
        if(nextProps !== this.props){
            list_status.forEach(e => {
                if(nextProps.status_flag === e.value){
                    var status = e;
                    status.label = e.label;
                    status.value = e.value;
                    this.setState({status})
                }
            });

            list_origem.forEach(e => {
                if(nextProps.origem.replace(/[\u0300-\u036f]/g, "") === e.label.replace(/[\u0300-\u036f]/g, "")){
                    this.setState({origem_ocorrencia: e})
                }
            })

            list_tipos.forEach(e => {
                console.log(nextProps.tipo_ocorrencia.replace(/[\u0300-\u036f]/g, ""))
                if(nextProps.tipo_ocorrencia.replace(/[\u0300-\u036f]/g, "") === e.label.replace(/[\u0300-\u036f]/g, "")){
                    this.setState({tipo_ocorrencia: e})
                }
            })

            list_razao.forEach(e => {
                if(nextProps.razao_ocorrencia.replace(/[\u0300-\u036f]/g, "") === e.label.replace(/[\u0300-\u036f]/g, "")){
                    this.setState({razao_ocorrencia: e})
                }
            });

            var departamento = null;
            var funcionario_atribuido = null;

            if(nextProps.departamento !== '' && nextProps.departamento !== null){
                departamento = {
                    label: nextProps.departamento,
                    value: nextProps.departamento_flag

                }
            }

            if(nextProps.funcionario_atribuido !== '' && nextProps.funcionario_atribuido !== null){
                funcionario_atribuido = {
                    value: nextProps.funcionario_atribuido_id,
                    label: nextProps.funcionario_atribuido,
                }
            }

            this.setState({
                departamento: departamento,
                funcionario_atribuido: funcionario_atribuido,
                prazo_retorno: moment(nextProps.prazo_retorno_value).format('YYYY-MM-DD'),
            })
        }
    }

    configuracoes = () => {
        this.setState({loading: true})
        var url = ENDPOINT+"ocorrencia/configuracoes";
        var formData = new FormData();
        formData.append('protocolo', this.props.protocolo);

        if(this.state.departamento !== null){
            formData.append('departamento', this.state.departamento.value);
        }

        if(this.state.funcionario_atribuido !== null){
            formData.append('funcionario_atribuido_id', this.state.funcionario_atribuido.value);
        }

        if(this.state.tipo_ocorrencia !== null){
            formData.append('tipo_ocorrencia', this.state.tipo_ocorrencia.value);
        }

        if(this.state.status !== null){
            formData.append('status', this.state.status.value);
        }

        if(this.state.prazo_retorno !== null && this.state.prazo_retorno !== ''){
            formData.append('prazo_conclusao', this.state.prazo_retorno);
        }

        if(this.state.origem_ocorrencia !== null){
            formData.append('origem_ocorrencia', this.state.origem_ocorrencia.label);
        }

        if(this.state.razao_ocorrencia !== null){
            formData.append('razao_ocorrencia', this.state.razao_ocorrencia.label);
        }

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({loading: false});
            this.props.refreshUser();
            toast.success(data.message);
            return true;
        })
        .catch((error) => {
            this.setState({loading: false});
            var is_response = error.response.data;
            if(is_response){
                toast.error(is_response.errors);
                return;
            }

            toast.error('Ocorreu um erro no servidor');
            return;
        });
    }

    loadAtendentesOptions = (inputValue, callback) => {
        var url = ENDPOINT + "admin/list?search=" + inputValue;
        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p.data;

            data.forEach((p, i) => {
                data[i].value = p.id;
                data[i].label = p.name;
            });

            callback(this.filterAtendentes(inputValue, p.data));
        });
    }

    filterAtendentes = (inputValue, data) => {
        return data.filter(i =>
            i.name.toLowerCase().includes(inputValue.toLowerCase())
        );
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }
    render(){
        return (
            <div style={{padding: '15px'}}>
                <h5>Configurações</h5>
                <hr />
                <Form>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Status:</Label>
                                <Select 
                                    placeholder="Status"
                                    isSearchable={false}
                                    options={list_status}
                                    onChange={(status) => this.setState({status})}
                                    value={this.state.status}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Tipo de Ocorrência:</Label>
                                <Select 
                                    placeholder="Tipo de Ocorrência"
                                    isSearchable={false}
                                    options={list_tipos}
                                    onChange={(tipo_ocorrencia) => this.setState({tipo_ocorrencia})}
                                    value={this.state.tipo_ocorrencia}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Origêm da Ocorrência:</Label>
                                <Select 
                                    placeholder="Origêm da Ocorrência"
                                    isSearchable={false}
                                    options={list_origem}
                                    onChange={(origem_ocorrencia) => this.setState({origem_ocorrencia})}
                                    value={this.state.origem_ocorrencia}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Razão da Ocorrência:</Label>
                                <Select 
                                    placeholder="Razão da Ocorrência"
                                    isSearchable={false}
                                    options={list_razao}
                                    onChange={(razao_ocorrencia) => this.setState({razao_ocorrencia})}
                                    value={this.state.razao_ocorrencia}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Departamento:</Label>
                                <Select 
                                    placeholder="Departamento"
                                    isSearchable={false}
                                    options={this.state.list_departamentos}
                                    onChange={(departamento) => this.setState({departamento})}
                                    value={this.state.departamento}
                                    //options={list_origem}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Prazo de retorno:</Label>
                                <Input
                                    type="date"
                                    min={new Date().toISOString().split("T")[0]}
                                    name="prazo_retorno"
                                    value={this.state.prazo_retorno}
                                    invalid={this.state.prazo_retorno_invalid}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                                <FormGroup>
                                    <Label>Operador Atribuido:</Label>
                                    <AsyncSelect
                                        style={{width: '100%'}}           
                                        cacheOptions
                                        loadOptions={this.loadAtendentesOptions}
                                        defaultOptions
                                        placeholder="Funcionário"
                                        value={this.state.funcionario_atribuido}
                                        onChange={(funcionario_atribuido) => this.setState({funcionario_atribuido})}
                                    />
                                    {/* <Select 
                                        placeholder="Operador Atribuido"
                                        isSearchable={false}
                                        //options={list_origem}
                                    /> */}
                                </FormGroup>
                        </Col>
                    </Row>
                    <div style={{textAlign: 'right'}}>
                        <Button disabled={this.state.loading} color="success" onClick={() => this.configuracoes()}>Salvar</Button>
                    </div>
                </Form>
            </div>
        )
    }
}