import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, Col, Row, Collapse, Button } from 'reactstrap';
import Pagination from '../Pagination';
import debounce from 'lodash/debounce';
import { columns } from './propsUsersPreVenda';
import { InputText } from '../../InputText/InputText';
import { InputMasked } from '../../InputText/InputMasked';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import axios from 'axios';
import Select from 'react-select';


const getOperadores = (callback) => {
    var url = ENDPOINT + "chat-atendimento/consultar-operadores";

    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        var operadores_list = [];
        data.forEach((p, i) => {
            p['value'] = p.id;
            p['label'] = p.name;
            operadores_list.push(p);
        });

        callback(operadores_list)
    });
}
  

const TableUsersPreVenda = (props) => {
    const [operadores_list, setOperadoresList] = useState([]);
    useEffect(() => {
        getOperadores(setOperadoresList);
    }, [])
    return (
        <>
            <Card>
                <CardBody>
                    <Row>
                        <Col sm={10}>
                            <div style={{ textAlign: 'left' }}>
                                <InputText
                                    name="search"
                                    placeholder="Busca Geral"
                                    onChange={debounce((event) => props.isSearch(event), 500)} />
                            </div>
                        </Col>
                        <Col sm={2} style={{padding: 0, textAlign: 'right'}}>
                                <Button
                                    color="warning"
                                    onClick={props.filterShow}
                                    style={{ marginRight: '25px', marginBottom: '4px' }}>
                                    Filtrar&nbsp;
                                    <AiFillCaretDown hidden={props.show_filter} />
                                    <AiFillCaretUp hidden={!props.show_filter} />
                                </Button>
                        </Col>
                    </Row>
                    <Collapse isOpen={props.show_filter}> 
                    <br/>
                    <Card style={{border: 0}}>
                        <Row>
                            <Col md={4}>
                                <Select
                                    className="react-select"
                                    options={operadores_list}
                                    placeholder="Operador"
                                    isSearchable={false}
                                    isClearable
                                    value={props.operador}
                                    onChange={props.handleOperadorChange}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={6}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputMasked
                                        name="document"
                                        mask={props.is_cnpj === false ? "999.999.999-99" : "99.999.999/9999-99"}
                                        placeholder={props.is_cnpj === false ? "CPF" : "CNPJ"}
                                        onChange={debounce((event) => props.isSearch(event), 500)}
                                        />
                                </div>
                            </Col>
                            <Col md={2}>
                                <p style={{marginTop: '16px'}}><input 
                                    type="checkbox"
                                    checked={props.is_cnpj}
                                    onChange={() => props.onSearchDocument()}/> Pesquisar CNPJ</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <p style={{marginTop: '10px', marginBottom: 0}}>&nbsp;&nbsp;<b>Tipo de cliente:</b>&nbsp;&nbsp;
                                    <input
                                        type="radio"
                                        name="filter_type"
                                        checked={props.status_user === 'cpf'}
                                        onChange={() => props.onStatusChange('cpf')} /> Usuário Comum (CPF)&nbsp;&nbsp;
                                    <input
                                        type="radio"
                                        name="filter_type"
                                        checked={props.status_user === 'cnpj'}
                                        onChange={() => props.onStatusChange('cnpj')} /> Usuário Corporativo (CNPJ)&nbsp;&nbsp;</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>

                            </Col>
                        </Row>
                        <br/>
                    </Card>
                    </Collapse>
                    <hr style={{ marginBottom: '4px' }} />
                    <DataTable
                        noHeader={true}
                        columns={
                            columns(props.onDelete)
                        }
                        button={true}
                        responsive
                        noDataComponent={props.noDataComponent}
                        data={props.data}
                        theme="default"
                    />
                </CardBody>
                <CardFooter style={{ textAlign: 'center' }}>
                    <Pagination
                        onPageChange={props.onPageChange}
                        activePage={props.activePage}
                        totalPage={props.totalPage} />
                </CardFooter>
            </Card>
        </>
    )
}
export default TableUsersPreVenda;