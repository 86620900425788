import React, { Component } from 'react';
import { Button, Card, CardBody, CardColumns, Col, Nav, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import styled from 'styled-components';
import DataPreClient from '../../components/ResumeClient/DataPreClient';
import ChatsClient from '../../components/ResumeClient/ChatsClient';
import EmailClient from '../../components/ResumeClient/EmailClient';
import SmsClient from '../../components/ResumeClient/SmsClient';
import { getPermissoes, getToken, getUser } from '../../auth/set_services';
import { ENDPOINT } from '../../auth/endpoint';
import NotasAtendimento from '../../components/ChatClient/NotasAtendimento';
import ProtocoloClient from '../../components/ResumeClient/ProtocoloClient';
import TicketClient from '../../components/ResumeClient/TicketClient';
import RespostaNPS from '../../components/ResumeClient/RespostaNPS';
import OcorrenciasClient from '../../components/ResumeClient/OcorrenciasClient';
import { MdFileDownload, MdPictureAsPdf } from 'react-icons/md';
import get_extension from 'get-url-extension';
import { toast } from 'react-toastify';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import moment from 'moment';
import UploadDocumentos from '../../components/Modals/SendsModals/UploadDocumentos';
import EnderecoCadastro from '../../components/Modals/EnderecoCadastro/EnderecoCadastro';
import NewNotas from '../../components/Modals/SendsModals/NewNotas';
import ModalShow from '../../components/Modals/ModalShow/ModalShow';
import { AiFillFilePdf, AiFillFileUnknown } from 'react-icons/ai';
import ContaBancaria from '../../components/Modals/ContaBancaria/ContaBancaria';
import { maquininhas_list } from '../../auth/listas';
import FadeLoader from "react-spinners/FadeLoader";
import error_image from '../../assets/error-image.png'
import ReactStoreIndicator from 'react-score-indicator';
import NumberFormat from 'react-number-format';



const get_status_auth = (payment_type) => {
    switch(payment_type){
        case 0 : return 'Aguardando Validação';
        case 1 : return 'Validado';
        case 2 : return 'Perdido';
        default: return ''
    }
}
const get_status = (payment_type) => {
    switch(payment_type){
        case 'enabled' : return 'Ativo';
        case 'inactive' : return 'Inativo';
        case 'pending' : return 'Pendente';
        case 'failed' : return 'Falhada';
        default: return payment_type
    }
}
function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}


const getId = () => {
    return window.location.pathname.replace('/user-pre-venda/', '')
}
/* const getActive = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('active');
    if(id !== null){
        return id;
    }
    return "1";

} */

const thumb = {
    //display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 305,
    height: 'auto',
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 200,
    overflow: 'hidden',
    placeContent: 'center'
};

const img = {
    display: 'block',
    width: '100%',
    maxHeight: '330px'
};
  
const pdf = {
    display: 'block',
    width: 'auto',
    height: '120px',
    color: '#af0000'
};
const file_style = {
    display: 'block',
    width: 'auto',
    height: '120px',
    color: '#484848'
};

const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
`

const getUserAQPago = (id, callback) => {
    var url = ENDPOINT + "user/get_pre/"+id;
  
    var myHeaders = new Headers();
  
    myHeaders.append('Authorization', 'Bearer ' + getToken());
  
    var myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };
    fetch(url, myInit)
      .then(response => response.json())
      .then((p) => {
          callback(p.data);
      })
      .catch((error) => {
          console.log(error)
        var data = error.response.data;
        toast.error(data.errors);
      })
  }

const getPlanos = (callback) => {
    var url = ENDPOINT+"planos_list";
    axios.get(url)
    .then((response) => {
        var data = response.data;
        var planos_list = []
        data.data.forEach(e => {
            planos_list.push({
                value: e.id,
                label: e.name
            })
        });
        //console.log(planos_list)
        callback(planos_list);
    })
}
const iniciarAnalise = (id, callback) => {
    var url = ENDPOINT + "user/iniciar_analise_docs/"+id;

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        callback(p.data);
    })
}
const apagarDoc = (id, callback) => {
    var url = ENDPOINT + "user/apagar_documento";

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var formData = new FormData();
    formData.append('id', id);

    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    })
    .then((response) => {
        callback(response.data)
    })
}
const reprovarDoc = (id, callback) => {
    var url = ENDPOINT + "user/reprovar_documento";

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var formData = new FormData();
    formData.append('id', id);
    formData.append('bloqueio', 1);

    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    })
    .then((response) => {
        callback(response.data)
    })
}
const aprovarDoc = (id, callback) => {
    var url = ENDPOINT + "user/reprovar_documento";

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var formData = new FormData();
    formData.append('id', id);
    formData.append('bloqueio', 0);

    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    })
    .then((response) => {
        callback(response.data)
    });
}

const zoopTitularidadeUser = (id, plano, callback, faturamento_empresa = '0.00') => {
    var url = ENDPOINT + "titularidade/zoop_user/"+id+"?faturamento_empresa="+faturamento_empresa;

    if(plano !== null){
        url += "&plano="+plano.label
    }

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        callback(p);
    })
}


const zoopTitularidadeUserEmp = (id, cpf_is, callback) => {
    var url = ENDPOINT + "titularidade/zoop_user/"+id+"?cpf="+cpf_is.replace(/([^\d])+/gim, '');
    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        callback(p);
    })
}

const zoopUser = (id, plano, callback, faturamento_empresa = '0.00') => {
    var url = ENDPOINT + "user/zoop_user/"+id+"?faturamento_empresa="+faturamento_empresa;

    if(plano !== null){
        url += "&plano="+plano.label
    }

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        callback(p);
    })
}

const zoopUserByCnpj = (id, cpf, plano = null, callback) => {
    var url = ENDPOINT + "user/zoop_user/"+id+"?cpf="+cpf.replace(/([^\d])+/gim, '');

    if(plano !== null){
        url += "&plano="+plano.label
    }
    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        callback(p);
    })
}
const getSerasa = (documento, callback, callbackError) => {
    var url = ENDPOINT + "consultar_serasa/"+documento;
  
    var myHeaders = new Headers();
  
    myHeaders.append('Authorization', 'Bearer ' + getToken());
  
    var myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };
    fetch(url, myInit)
      .then(response => response.json())
      .then(() => {
        getUserAQPago(getId(), callback);
      })
      .catch((error) => {
        callbackError();
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            toast.error(obj.errors);
            return obj;
        }
        var data = error.response.data;
        toast.error(data.errors);
      })
}

const getSerasaNew = (documento, callback, callbackError) => {
    var url = ENDPOINT + "nova_consulta_serasa/"+documento;
  
    var myHeaders = new Headers();
  
    myHeaders.append('Authorization', 'Bearer ' + getToken());
  
    var myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };
    fetch(url, myInit)
      .then(response => response.json())
      .then(() => {
        getUserAQPago(getId(), callback);
      })
      .catch((error) => {
        callbackError();
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            toast.error(obj.errors);
            return obj;
        }
        var data = error.response.data;
        toast.error(data.errors);
      })
}
export default class ResumoClientePreVenda extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_title: '',
            name: '',
            email: '',
            cpf: '',
            cnpj: '',
            created_at: '2021-01-01',
            first_chat_date: '',
            last_chat_date: '',
            first_origin: '',
            last_origin: '',
            telefone: '',
            userCode: '',
            cep: '',
            cidade: '',
            estado: '',
            endereco: null,
            bairro: '',
            empresa: null,
            plano: null,
            visible_cadastro: false,
            plano_current: null,
            plano_current_invalid: false,
            loading_edit: false,
            planos_list: [],
            userId: getId(),
            documents: [],
            compras: [],
            maquininhas: [],
            account: null,
            data_vendas: null,
            status_analise_docs: 'pending',
            activeTab: "1",
            zoop_loading: false,
            zoop_cpf:'',
            zoop_cpf_invalid: false,
            account_value: false,
            loading_btn_init: false,
            visible_cadastro: false,
            responsavel_inicio_analise: null,
            responsavel_final_analise: null,
            inicio_analise: '',
            communication_relacao_id: -1,
            serasa: [],
            documento_id: -1,
            modal_upload: false,
            modal_document: false,
            plano_anterior: '',
            loading_user: false,

            document_file: '',
            modal_show_document: false,
            modal_account:false, 

            email_edit_enable: false,
            email_edit: '',
            
            telefone_edit_enable: false,
            telefone_edit: '',
            seller_id: null,

            faturamento_empresa: '0.00',

            alteracoes_usuario: []
        }
        //this.showUsers = this.showUsers.bind(this);
        //this.onTaggyChange = this.onTaggyChange.bind(this);
        this.callbackRows = this.callbackRows.bind(this);
    }
    componentDidMount() {
        getUserAQPago(
            this.state.userId,
            this.callbackRows
        );

        getPlanos(this.callbackPlanos)
    }

    callbackPlanos = (planos_list) => {
        this.setState({planos_list})
    }
  
    getFileExtension = (url) =>{
        var extension = get_extension(url);
    
        if(extension === '.png' ||
            extension === '.PNG' ||
            extension === '.jpg' ||
            extension === '.jpeg' ||
            extension === '.svg' ||
            extension === '.webp'){
            return 'image'
        }
        else if(extension === '.pdf' ||
            extension === '.PDF'){
            return 'pdf';
        }
    
        return 'file';
      }
    thumbs = () => { 
        return this.state.documents.map((file, i) => (
            <>
            <div style={thumb} key={i}>
                <div style={thumbInner}>
                    {this.getFileExtension(file.url) === 'image' ?
                        <>
                        {console.log('image', file.url)}
                        <a href="#" onClick={() => this.onCloseShowDocument(true, file.url)}>
                            <img
                                alt="logotipo-documento"
                                src={file.url+'?access_token='+getToken()}
                                style={img}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = error_image;
                                }}
                            />
                        </a>
                        </>
                        :
                            this.getFileExtension(file.url) === 'pdf' ?
                            <a href="#" onClick={() => this.onCloseShowDocument(true, file.url)}>
                                {/* <Button color="danger" style={{margin: '15px'}} onClick={() => window.open(file.url, '_blank')}>
                                    <MdPictureAsPdf /> Abrir PDF
                                </Button> */}
                                <AiFillFilePdf style={pdf}/>
                            </a>
                            :
                            <a href={file.url+'?access_token='+getToken()}>
                                <AiFillFileUnknown style={file_style}/>
                                {/* <Button color="danger" style={{margin: '15px'}} onClick={() => window.open(file.url, '_blank')}>
                                    <MdFileDownload style={pdf}/> Abrir Arquivo
                                </Button> */}
                            </a>
                    }
                    <br/>
                </div>
                <center>
                    <small><b>{file.descricao}</b></small>
                    {file.status_analise_docs === 'pending' ?
                    <>
                        <Row>
                            <Col md={12} style={{textAlign: 'center'}}>
                                <Button color="success" style={{padding: 5, margin: 8}} onClick={() => this.onDesbloqueio(file.id)}>
                                    Aprovar
                                </Button>
                                <Button color="danger" style={{padding: 5, margin: 8}} onClick={() => this.onBloqueio(file.id)}>
                                    Reprovar
                                </Button>
                                <Button color="danger" style={{padding: 5, margin: 8}} onClick={() => this.onDelete(file.id)}>
                                    Apagar
                                </Button>
                            </Col>
                            <Col md={12} style={{textAlign: 'center'}}>
                                <Button color="info" style={{padding: 5, margin: 8}} onClick={() => this.onCloseDocument(file.id, true)}>
                                    Adicionar nota
                                </Button>
                            </Col>
                        </Row>
                    </>
                    :
                    file.status_analise_docs === 'aprovado' ?
                    <>
                        <p style={{color: 'green', margin: 0}}><b>Documento Aprovado</b></p>
                        <small>Analisado por: {file.responsavel_analise !== null ? file.responsavel_analise.name : 'Sem informações'}</small><br/><br/>
                        <Button color="danger" style={{padding: 3, margin: 3}} onClick={() => this.onDelete(file.id)}>
                            Apagar
                        </Button>
                        <Button color="info" style={{padding: 3, margin: 3}} onClick={() => this.onCloseDocument(file.id, true)}>
                            Adicionar nota
                        </Button>
                    </>
                    :
                    <>
                        <p style={{color: 'red', margin: 0}}><b>Documento Reprovado</b></p>
                        <small>Analisado por: {file.responsavel_analise !== null ? file.responsavel_analise.name : 'Sem informações'}</small><br/><br/>
                        <Button color="danger" style={{padding: 3, margin: 3}} onClick={() => this.onDelete(file.id)}>
                            Apagar
                        </Button>
                        <Button color="info" style={{padding: 3, margin: 3}} onClick={() => this.onCloseDocument(file.id, true)}>
                            Adicionar nota
                        </Button>
                    </>
                    }
                    <br/>
                    <br/>
                    <p style={{margin: 0}}>Notas:</p>
                    <small>
                        {file.notas.map(e => {
                            return <><b>{e.admin !== null ? e.admin.name : 'Sem Informações'}:</b> {e.descricao}<br/></>
                        })}
                        {file.notas.length === 0 ? <><b>Nenhuma nota</b></> : ''}
                    </small>
                </center>
                <br/>
            </div>
            </>
        ))
    };
    callbackToast= (data) => {
        this.setState({zoop_loading: false});

        if(data.success === true){
            toast.success(data.message);
        }
        else{
            toast.error(data.errors);
        }
    }
    onZoop = () => {
        if(this.state.plano === null){
            if(this.state.plano_current === null){
                this.setState({plano_current_invalid: true});
                return '';
            }
            else{
                this.setState({plano_current_invalid: false});
            }
        }
        if(this.state.user_tipo == 'titular_pending_titular' || this.state.user_tipo == 'titular_pending_responsavel'){
            if(this.state.cnpj !== '' && this.state.cpf === ''){
                if(this.state.zoop_cpf.length > 13){
                    this.setState({zoop_cpf_invalid: false})
                    this.setState({zoop_loading: true})
                    
                    zoopUserByCnpj(
                        this.state.titularidade.id,
                        this.state.zoop_cpf,
                        this.state.plano_current,
                        this.callbackToast
                    )
                }
                else{
                    this.setState({zoop_cpf_invalid: true})
                }
    
            }
            else{
                this.setState({zoop_loading: true})
                zoopTitularidadeUser(
                    this.state.titularidade.id,
                    this.state.plano_current,
                    this.callbackToast
                )
            }
        }
        else{
            if(this.state.cnpj !== '' && this.state.cpf === ''){
                if(this.state.zoop_cpf.length > 13){
                    this.setState({zoop_cpf_invalid: false})
                    this.setState({zoop_loading: true})
                    
                    zoopUserByCnpj(
                        this.state.userId,
                        this.state.zoop_cpf,
                        this.state.plano_current,
                        this.callbackToast
                    )
                }
                else{
                    this.setState({zoop_cpf_invalid: true})
                }
    
            }
            else{
                this.setState({zoop_loading: true})
                zoopUser(
                    this.state.userId,
                    this.state.plano_current,
                    this.callbackToast,
                    this.state.faturamento_empresa
                )
            }
        }
    }
    callbackRows(data){
        if(data.documentos !== undefined && data.documentos.length) {
            var urlApi = ENDPOINT.replace('api/','')

            data.documentos.map((documento, ps) => {
                if(data.documentos[ps].url !== undefined) {
                    data.documentos[ps].url = documento.url.replace('https://apicrm.aqbank.io/', urlApi)
                }
            })
        }

        this.setState({
            loading_user: true,
            loading_serasa: false,
            name: data.nome+" "+data.sobrenome,
            email: data.email,
            created_at: data.created_at,
            cpf: data.cpf,
            cnpj: data.cnpj,
            telefone: data.telefone,
            communication_relacao_id: data.communication_relacao_id,
            empresa: data.empresa,
            documents: data.documentos,
            plano: data.plano,
            inicio_analise: data.inicio_analise,
            final_analise: data.final_analise,
            status_analise_docs: data.status_analise_docs,
            alteracoes_usuario: data.alteracoes_usuario,
            responsavel_inicio_analise: data.responsavel_inicio_analise,
            responsavel_final_analise: data.responsavel_final_analise,
            endereco: data.endereco,
            compras: data.compras,
            maquininhas: data.maquininhas,
            account_value: data.account_value,
            serasa: data.serasa,
            seller_id: data.seller_id,
            user_tipo: data.user_tipo,
            is_title: (data.user_tipo === 'chat_titular' || data.user_tipo === 'chat_responsavel' ? 'pré-venda' : '')
        });

        if(data.solicitacao !== null){
            this.setState({plano_anterior: data.solicitacao.plano})
        }
        if(data.vendedor !== null){
            this.setState({
                data_vendas: {
                    origem: data.origem,
                    ponto_venda: data.ponto_venda,
                    nome_vendedor: data.vendedor.name,
                    forma_pgto: data.forma_pgto,
                    modelo_maquininha: data.modelo_maquininha,
                    ponto_entrega: data.ponto_entrega,
                    parcelas: data.parcelas,
                }
            })

        }
        if(data.account_number !== null){
            this.setState({
                account: {
                    account_number: data.account_number,
                    routing_number: data.routing_number,
                    account_number: data.account_number,
                    tipo_conta: data.type_bank_account,
                }
            })

        }
        if(data.chats.length > 0){
            this.setState({
                first_chat_date: data.chats[0].created_at,
                first_origin: data.chats[0].dominio,
                last_chat_date: data.chats[data.chats.length - 1].created_at,
                last_origin: data.chats[data.chats.length - 1].dominio
            });
        }
    }
    listData = [
        { name: 'Dados do Cliente', active: '1' },
        { name: 'Histórico de Alterações', active: '2' },
        { name: 'E-mails', active: '3' },
        { name: 'SMS', active: '4' },
        { name: 'Chats', active: '5' },
        { name: 'Notas', active: '6' },
        { name: 'Protocolos', active: '7' },
        { name: 'Tickets', active: '8' },
        { name: "NPS", active: '9' },
        { name: "Ocorrências", active: '10' },
        { name: "Documentos", active: '11' },
        { name: "Compras", active: '12' },
        { name: "Maquininhas", active: '13' },
        { name: "Bureau de Crédito", active: '14' },
        { name: "Validações de cliente", active: '15' }
    ]

    Navs = () => {
        return <Nav tabs>
            {this.listData.map((p, index) => {
                return <NavLink
                    key={index}
                    className={
                        classnames({ active: this.state.activeTab === p.active })}
                    onClick={() => { this.setState({ activeTab: p.active }); }}>
                    <span>{p.name}</span>
                </NavLink>
            })}
        </Nav>
    }
    onChangeZoop = (value) =>{
        this.setState({zoop_cpf: value})
    }
    iniciarAnaliseDocumentos = () => {
        this.setState({loading_btn_init: true})
        iniciarAnalise(this.state.userId, this.callbackDocs);
    }

    onBloqueio = (id) => {
        reprovarDoc(id, this.callbackBloqueio);
    }
    onDesbloqueio = (id) => {
        aprovarDoc(id, this.callbackBloqueio);
    }
    onDelete = (id) => {
        apagarDoc(id, this.callbackBloqueio);
    }
    callbackDocs = (data) => {
        toast.success(data.message);
        this.setState({loading_btn_init: false})
        getUserAQPago(
            this.state.userId,
            this.callbackRows
        );
    }
    callbackBloqueio = (data) => {
        toast.success(data.message);
        getUserAQPago(
            this.state.userId,
            this.callbackRows
        );
    }
    onClose = () => {
        this.setState({visible_cadastro: false});
    }
    onOpen = () => {
        this.setState({visible_cadastro: true});
    }
    onRefresh = () => {
        getUserAQPago(
            this.state.userId,
            this.callbackRows
        );
    }
    onSelectPlan = (plano_current) => {
        this.setState({plano_current})
    }
    onCloseDocument = (documento_id = -1, modal_document = false) => {
        this.setState({
            documento_id,
            modal_document
        })
    }
    onCloseUpload = (modal_upload = false) => {
        this.setState({
            modal_upload
        });

        if(modal_upload === false){
            getUserAQPago(
                this.state.userId,
                this.callbackRows
            );
        }
    }
    onCloseShowDocument = (modal_show_document, document_file) => {
        this.setState({ modal_show_document, document_file })
    }
    onCloseAccount = (modal_account = false) => {
        this.setState({ modal_account });
    }
    editEmail = (email_edit_enable) => {
        this.setState({email_edit_enable, email_edit: ''})
    }

    telefoneEdit = (telefone_edit_enable) => {
        this.setState({telefone_edit_enable, telefone_edit: ''})
    }

    onValor = (event) => {
        var valor_current = event.target.value;
        if (valor_current.length > 1) {
            valor_current = valor_current.replaceAll('.', '');
            var digits = valor_current.substr(valor_current.length - 2);
            valor_current = valor_current.slice(0, -2);
            valor_current = valor_current + '.' + digits;
        }

        var valido = /^[\d,.?!]+$/.test(valor_current);
        if (valido === true || valor_current === '') {
            this.setState({ faturamento_empresa: valor_current });
        }
    };

    storeEmail = () => {
        this.setState({loading_edit: true})
        var url = ENDPOINT+`user/update_user/${this.state.userId}`;

        var formData = new FormData();
        formData.append('email', this.state.email_edit);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data.data;
            this.setState({
                email_edit_enable: false,
                email_edit: '',
                email: data.email,
                loading_edit: false
            })
            
            getUserAQPago(
                this.state.userId,
                this.callbackRows
            );
        })
        .catch((error) => {
            var data = error.response.data;
            toast.error(data.errors);
            this.setState({
                loading_edit: false
            });
            return data;
        });
    }

    storeTelefone = () => {
        this.setState({loading_edit: true})
        var url = ENDPOINT+'user/update_user/'+this.state.userId;
        console.log(url);

        var formData = new FormData();
        formData.append('telefone', this.state.telefone_edit);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data.data;
            this.setState({
                telefone_edit_enable: false,
                telefone_edit: '',
                telefone: data.telefone,
                loading_edit: false
            })

            
            getUserAQPago(
                this.state.userId,
                this.callbackRows
            );
        })
        .catch((error) => {
            var data = error.response.data;
            toast.error(data.errors);
            this.setState({
                loading_edit: false
            });
            return data;
        });
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }
    render() {
        return (
            <Div>
                <h3>Resumo do Cliente {this.state.is_title}</h3>
                <hr />
                <Card hidden={this.state.loading_user == true}>
                    <CardBody style={{height: '120px'}}>
                        <center>
                            <FadeLoader color={'#52c565'} margin={0} loading={50} size={60} />
                        </center>
                    </CardBody>
                </Card>
                <EnderecoCadastro
                    user_id={this.state.userId}
                    visible={this.state.visible_cadastro}
                    onClose={this.onClose}
                    onRefresh={this.onRefresh}
                />
                <Card hidden={this.state.loading_user == false}>
                    <CardBody hidden={!getUser().permissao_gestor_aqpago}>
                        <this.Navs />
                        <TabContent activeTab={this.state.activeTab}>
                            <DataPreClient
                                active={'1'}
                                data_venda={this.state.data_vendas}
                                name={this.state.name}
                                account_value={this.state.account_value}
                                email={this.state.email}
                                empresa={this.state.empresa}
                                planos_list={this.state.planos_list}
                                plano={this.state.plano}
                                plano_current_invalid={this.state.plano_current_invalid}
                                plano_current={this.state.plano_current}
                                cpf={this.state.cpf}
                                cnpj={this.state.cnpj}
                                seller_id={this.state.seller_id}
                                alteracoes_usuario={this.state.alteracoes_usuario}
                                first_chat_date={this.state.first_chat_date}
                                last_chat_date={this.state.last_chat_date}
                                first_origin={this.state.first_origin}
                                last_origin={this.state.last_origin}
                                created_at={this.state.created_at}
                                telefone={this.state.telefone}
                                userCode={this.state.userCode}
                                cep={this.state.cep}
                                cidade={this.state.cidade}
                                estado={this.state.estado}
                                endereco={this.state.endereco}
                                bairro={this.state.bairro}
                                is_title={this.state.is_title}
                                zoop_cpf={this.state.zoop_cpf}
                                zoop_cpf_invalid={this.state.zoop_cpf_invalid}
                                onZoop={this.onZoop}
                                onOpen={this.onOpen}
                                onSelectPlan={this.onSelectPlan}
                                onChangeZoop={this.onChangeZoop}
                                zoop_loading={this.state.zoop_loading}
                                modal_account={this.state.modal_account}
                                onCloseAccount={this.onCloseAccount}
                                plano_anterior={this.state.plano_anterior}

                                
                                email_edit={this.state.email_edit}
                                email_edit_enable={this.state.email_edit_enable}
                                editEmail={this.editEmail}
                                
                                telefone_edit={this.state.telefone_edit}
                                telefone_edit_enable={this.state.telefone_edit_enable}
                                telefoneEdit={this.telefoneEdit}
                                handleChange={this.handleChange}
                                loading_edit={this.state.loading_edit}
                                onValor={this.onValor}
                                
                                storeTelefone={this.storeTelefone}
                                storeEmail={this.storeEmail}
                                
                            />
                            <TabPane tabId={'2'}>
                                <Card style={{border: 'none'}}>
                                    <CardBody>
                                        <h5>Historico de Alterações</h5>
                                        <hr/>
                                        <div>
                                            {this.state.alteracoes_usuario.map(e => {
                                                return <p>{moment(e.created_at).format('DD/MM/YYYY HH:mm')} - <div dangerouslySetInnerHTML={{ __html: replaceAll(e.descricao, "\n", "<br/>") }} /></p>
                                            })}
                                        </div>
                                    </CardBody>
                                </Card>
                            </TabPane>
                            <EmailClient
                                active={'3'}
                                name={this.state.name}
                                email={this.state.email}
                                communication_relacao_id={this.state.communication_relacao_id}
                            />
                            <SmsClient
                                active={'4'}
                                name={this.state.name}
                                telefone={this.state.telefone}
                                communication_relacao_id={this.state.communication_relacao_id}
                            />
                            <ChatsClient 
                                active={'5'}
                                name={this.state.name}
                                communication_relacao_id={this.state.communication_relacao_id}
                            />
                            <NotasAtendimento
                                active={'6'}
                                name={this.state.name}
                                user_id={this.state.userId}
                                communication_relacao_id={this.state.communication_relacao_id}
                            />
                            <ProtocoloClient
                                active={'7'}
                                name={this.state.name}
                                communication_relacao_id={this.state.communication_relacao_id}
                            />
                            <TicketClient
                                active={'8'}
                                name={this.state.name}
                                email={this.state.email}
                            />
                            <RespostaNPS
                                active={'9'}
                                name={this.state.name}
                                user_id={this.state.userId}
                            />
                            <OcorrenciasClient
                                active={'10'}
                                name={this.state.name}
                                user_id={this.state.userId}
                            />
                            <TabPane tabId={'11'}>
                                <Card style={{border: 'none'}}>
                                    <CardBody>
                                        <h5>Documentos Enviados</h5>
                                        <hr/>
                                        <center>
                                            {/* <UploadDocumentos /> */}
                                            {this.state.status_analise_docs === 'pending' ?
                                                <Button 
                                                    color="info" 
                                                    onClick={() => this.iniciarAnaliseDocumentos()}
                                                    disabled={this.state.loading_btn_init}>
                                                {this.state.loading_btn_init === true ?
                                                    <>
                                                        <ClipLoader color="#fff" size={12}/>
                                                        &nbsp;Iniciando Análise
                                                    </>
                                                : 'Iniciar análise de documentos'}
                                                </Button>
                                                :
                                                <></>
                                            }
                                            </center>
                                            {this.state.status_analise_docs !== 'pending' ?
                                                <>
                                                    
                                                    {this.state.status_analise_docs === 'analise_iniciada' ?
                                                        <>
                                                            <p><b>Analise iníciada em:</b> {moment(this.state.inicio_analise).format('DD/MM/YYYY HH:mm')} - {this.state.responsavel_inicio_analise !== null ? this.state.responsavel_inicio_analise.name : ''}</p>
                                                            <Button 
                                                                onClick={() => this.onCloseUpload(true)} 
                                                                color="info" 
                                                                style={{padding: 5, marginBottom: 15}}>Upload de Documentos</Button>
                                                        </>
                                                        :
                                                        <>
                                                            <p><b>Analise iníciada em:</b> {moment(this.state.inicio_analise).format('DD/MM/YYYY HH:mm')} - {this.state.responsavel_inicio_analise !== null ? this.state.responsavel_inicio_analise.name : ''}</p>
                                                            <p><b>Analise finalizada em:</b> {moment(this.state.final_analise).format('DD/MM/YYYY HH:mm')} - {this.state.responsavel_final_analise !== null ? this.state.responsavel_final_analise.name : ''}</p>
                                                        </>
                                                    }
                                                    <Row>
                                                        {this.thumbs()} 
                                                    </Row>
                                                    <p><br/><br/>{this.state.documents.length === 0 ? 'Nenhum Documento' : ''}</p>
                                                </>
                                                : 
                                                <></>
                                            }  
                                            <br/>
                                    </CardBody>
                                </Card>
                                <UploadDocumentos
                                    visible={this.state.modal_upload}
                                    userId={this.state.userId}
                                    onClose={this.onCloseUpload}
                                />
                                <NewNotas
                                    visible={this.state.modal_document}
                                    id={this.state.documento_id}
                                    desc_id="documento_cadastro_id"
                                    user_id={this.state.userId}
                                    onClose={this.onCloseDocument}
                                />
                                <ModalShow
                                    visible={this.state.modal_show_document}
                                    url={this.state.document_file}
                                    onClose={this.onCloseShowDocument}
                                />
                                <ContaBancaria
                                    visible={this.state.modal_account}
                                    userId={this.state.userId}
                                    onClose={this.onCloseAccount}
                                    onRefresh={this.onRefresh}
                                />
                            </TabPane>
                            <TabPane tabId={'12'}>
                                <Card style={{border: 'none'}}>
                                    <CardBody>
                                        <h5>Compras realizadas</h5>
                                        <hr/>
                                        {this.state.compras.map(e => 
                                            <div>
                                                <h6>Compra: #{e.hash}</h6>
                                                <h6>Status: {e.status}</h6>
                                                <CardColumns>
                                                    {e.produtos.map(p => <Card>
                                                        <CardBody>
                                                            
                                                            {maquininhas_list.map(m => {
                                                                if(m.modelo.toUpperCase() == p.produto.toUpperCase()){
                                                                    if(p.produto.toUpperCase() != 'LINKPAY' && p.produto.toUpperCase() != 'SHOPAQPAGO'){
                                                                        return <Row>
                                                                            <Col md={12} style={{textAlign: 'center'}}>
                                                                                <img src={m.image} height="100px"/>
                                                                            </Col>
                                                                            <Col md={12} style={{marginTop: 5}}>
                                                                                <p><b>Produto: </b>{m.label}</p>
                                                                                <p><b>Serie: </b>{p.serial}</p>
                                                                                <p><b>Chip: </b>{p.chip}</p>
                                                                                <p><b>NF: </b>{p.nf}</p>
                                                                            </Col>
                                                                        </Row>
                                                                    }
                                                                    else{
                                                                        return <Row>
                                                                            <Col md={12} style={{textAlign: 'center'}}>
                                                                                <img src={m.image} height="100px"/>
                                                                            </Col>
                                                                            <Col md={12} style={{marginTop: 5}}>
                                                                                <p><b>Produto: </b>{m.label}</p>
                                                                                <p><b>NF: </b>{p.nf}</p>
                                                                            </Col>
                                                                        </Row>
                                                                    }
                                                                }
                                                            })}
                                                        </CardBody>
                                                    </Card>)}
                                                </CardColumns>
                                                <hr/>
                                            </div>
                                        )}
                                        {this.state.compras.length == 0 ? 'Nenhuma compra encontrada' : ''}
                                    </CardBody>
                                </Card>
                            </TabPane>
                            
                            <TabPane tabId={'13'}>
                                <Card style={{border: 'none'}}>
                                    <CardBody>
                                        <h5>Maquininhas do cliente</h5>
                                        <hr/>
                                        <div>
                                            <CardColumns>
                                                {this.state.maquininhas.map(p => <Card>
                                                    <CardBody>
                                                        
                                                        {maquininhas_list.map(m => {
                                                            if(m.modelo.toUpperCase() == p.produto.toUpperCase()){
                                                                if(p.produto.toUpperCase() != 'LINKPAY' && p.produto.toUpperCase() != 'SHOPAQPAGO'){
                                                                    return <Row>
                                                                        <Col md={12} style={{textAlign: 'center'}}>
                                                                            <img src={m.image} height="100px"/>
                                                                        </Col>
                                                                        <Col md={12} style={{marginTop: 5}}>
                                                                            <p><b>Produto: </b>{m.label}</p>
                                                                            <p><b>Serie: </b>{p.serial}</p>
                                                                            <p><b>Chip: </b>{p.chip}</p>
                                                                            <p><b>NF: </b>{p.nf}</p>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                                else{
                                                                    return <Row>
                                                                        <Col md={12} style={{textAlign: 'center'}}>
                                                                            <img src={m.image} height="100px"/>
                                                                        </Col>
                                                                        <Col md={12} style={{marginTop: 5}}>
                                                                            <p><b>Produto: </b>{m.label}</p>
                                                                            <p><b>NF: </b>{p.nf}</p>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                            }
                                                        })}
                                                    </CardBody>
                                                </Card>)}
                                            </CardColumns>
                                            {this.state.maquininhas.length == 0 ? 'Nenhuma maquininha encontrada' : ''}
                                            <hr/>
                                        </div>
                                    </CardBody>
                                </Card>
                            </TabPane>
                            <TabPane tabId={"14"}>
                                <Card style={{border: 'none'}}>
                                    <CardBody>
                                    <h5>Bureau de Crédito</h5>
                                    <hr/>
                                        
                                    {this.state.serasa.length == 0 ? <div>
                                            <Button 
                                                color="success"
                                                disabled={this.state.loading_serasa}
                                                onClick={() => {
                                                    this.setState({
                                                        loading_serasa: true
                                                    });

                                                    getSerasa(
                                                        this.state.cnpj.length > 0 ? this.state.cnpj: this.state.cpf, 
                                                        this.callbackRows,
                                                        () => this.setState({loading_serasa: false })
                                                    )
                                                }}
                                            >
                                                Consultar Bureau
                                            </Button>
                                        </div> 
                                        : 
                                            <div>
                                                <Button 
                                                    color="success"
                                                    disabled={this.state.loading_serasa}
                                                    onClick={() => {
                                                        this.setState({
                                                            loading_serasa: true
                                                        });

                                                        getSerasaNew(
                                                            getId(), 
                                                            this.callbackRows,
                                                            () => this.setState({loading_serasa: false })
                                                        )
                                                    }}
                                                >
                                                    Nova Consulta Bureau
                                                </Button>
                                            </div>
                                    }
                                    <br/>
                                    <Row>
                                        {this.state.serasa.map(e => {
                                            var score = JSON.parse(e.consulta_serasa);
                                            var resumo = JSON.parse(e.resumo_serasa);
                                            return(
                                                <Col md={6}>
                                                    <Card>
                                                        <CardBody>
                                                            <ReactStoreIndicator
                                                                value={score.score.pontos}
                                                                maxValue={1000}
                                                                lineWidth={25}
                                                            />
                                                            <span style={{
                                                                position: 'absolute',
                                                                top: '4rem', 
                                                                width: '100%',
                                                                left: 0,
                                                                fontSize: '55px',
                                                                textAlign: 'center',
                                                                fontWeight: 'bold'
                                                            }}>
                                                                {score.score.classe}
                                                            </span>
                                                            <center>
                                                                <h5>{score.score.faixa.titulo}</h5>
                                                            </center>
                                                            <hr/>
                                                            <div>
                                                                <p>{score.score.faixa.descricao}</p>
                                                                <p><b>Data da Consulta:</b> {moment(new Date(e.created_at).toString()).format('DD/MM/YYYY HH:m')}</p>
                                                                <p>
                                                                    <b>Renda Presumida:</b>&nbsp;                                                         
                                                                    <NumberFormat 
                                                                        prefix="R$ " 
                                                                        displayType="text" 
                                                                        value={parseFloat(resumo.rendaPresumida)} 
                                                                        decimalSeparator=","/>
                                                                </p>
                                                                <p>
                                                                    <b>{resumo.debitos.sumQuantidade} Débito{resumo.debitos.sumQuantidade > 1 ? 's' : ''}:</b>&nbsp;                                                         
                                                                    <NumberFormat 
                                                                        prefix="R$ " 
                                                                        displayType="text" 
                                                                        value={parseFloat(resumo.debitos.sumValorTotal)} 
                                                                        decimalSeparator=","/>
                                                                </p>
                                                                <p>
                                                                    <b>{resumo.protestos.sumQuantidade} Protesto{resumo.protestos.sumQuantidade > 1 ? 's' : ''} :</b>&nbsp;                                                         
                                                                    <NumberFormat 
                                                                        prefix="R$ " 
                                                                        displayType="text" 
                                                                        value={parseFloat(resumo.protestos.sumValorTotal)} 
                                                                        decimalSeparator=","/>
                                                                </p>
                                                                {resumo.acoes ? 
                                                                    <p>
                                                                        <b>{resumo.acoes.sumQuantidade} Aç{resumo.acoes.sumQuantidade > 1 ? 'ões' : 'ão'}:</b>&nbsp;                                                         
                                                                        <NumberFormat 
                                                                            prefix="R$ " 
                                                                            displayType="text" 
                                                                            value={parseFloat(resumo.acoes.sumValorTotal)} 
                                                                            decimalSeparator=","/>
                                                                    </p>
                                                                    :
                                                                    <></>
                                                                }
                                                                <p>
                                                                    <b>{resumo.cheques.sumQuantidade} Cheque{resumo.cheques.sumQuantidade > 1 ? 's' : ''}</b>&nbsp;                                                         
                                                                </p>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                    </CardBody>
                                </Card>
                            </TabPane>
                            <TabPane  
                                tabId={'15'}>
                                    <div>
                                        <br/>
                                        <h5>Validações de Cliente</h5>
                                        <hr/>
                                        <Button color='success' onClick={() => this.changeVisible(true)}>Validar Cliente</Button>
                                        <hr/>
                                        {this.state.autorizacoes ?
                                            <p>{this.state.autorizacoes.map(e => (
                                                <p>Token gerado em {moment(e.created_at).format('DD/MM/YYYY HH:mm') } com o status de {get_status_auth(e.status)}
                                                <br/><small>Gerado por: <b>{e.operador_name}</b></small></p>
                                            ))}</p>
                                            :
                                            null
                                        }
                                    </div>
                            </TabPane>
                        </TabContent>
                        {/* <AddCreditCard user_id={this.state.userId}/> */}
                    </CardBody>
                    <CardBody hidden={getUser().permissao_gestor_aqpago}>
                        <p>Você não tem permissões para acessar essa função</p>
                    </CardBody>
                </Card>
                <Row>
                </Row>
                <br />
            </Div>
        )
    }
}