import axios from "axios";
import { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Nav, NavLink, Row, TabContent, TabPane, Collapse, Input, CardHeader } from "reactstrap";
import ClipLoader from 'react-spinners/ClipLoader'
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import NotasAtendimento from "../../components/ChatClient/NotasAtendimento";
import ChatsClient from "../../components/ResumeClient/ChatsClient"
import EmailClient from "../../components/ResumeClient/EmailClient";
import SmsClient from "../../components/ResumeClient/SmsClient";
import NumberFormat from 'react-number-format';
import ReactStoreIndicator from 'react-score-indicator';
import moment from "moment";
import classnames from 'classnames';
import styled from "styled-components";
import { toast } from 'react-toastify';
import PropagateLoader from "react-spinners/PropagateLoader";
import MovimentacaoContas from "../../components/ChatClient/MovimentacaoContas";
import ModalValidacaoToken from "../../components/Modals/ModalValidacaoToken/ModalValidacaoToken";
import MovimentacaoFaturas from "../../components/ChatClient/MovimentacaoFaturas";
import TransacaoPedagio from "../../components/ChatClient/TransacaoPedagio";
import EnderecoCadastroAQPasso from "../../components/Modals/EnderecoCadastro/EnderecoCadastroAQPasso";
import HistoricoAlteracoesAQPasso from "./HistoricoAlteracoesAQPasso";

var CurrencyInput = require('react-currency-masked-input');


function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}
function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
const getSerasa = (documento, callback, callbackError) => {
    var url = ENDPOINT + "consultar_serasa/"+documento;
  
    var myHeaders = new Headers();
  
    myHeaders.append('Authorization', 'Bearer ' + getToken());
  
    var myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };
    fetch(url, myInit)
      .then(response => response.json())
      .then(() => {
        getUser(get_id(), callback);
      })
      .catch(() => {
        callbackError();
      })
}

const getSerasaNew = (documento, callback, callbackError) => {
    var url = ENDPOINT + "nova_consulta_serasa/"+documento;
  
    var myHeaders = new Headers();
  
    myHeaders.append('Authorization', 'Bearer ' + getToken());
  
    var myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };
    fetch(url, myInit)
      .then(response => response.json())
      .then(() => {
        getUser(get_id(), callback);
      })
      .catch(() => {
        callbackError();
      })
}

const get_id = () => {
    return window.location.pathname.replace('/user-aqpasso/', '')
}

const getUser = (id, callback) => {
    var url = ENDPOINT+"user/get_aqpasso/"+id;
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        callback(data.data);
    })
}


const novoLimite = (id, formData, callback, callbackError) => {
    var url = ENDPOINT+"faturas/novo-limite/"+id;
    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        callback(data);
    })
    .catch(() => {
      callbackError();
    })
}

const novoCredito = (id, formData, callback, callbackError) => {
    var url = ENDPOINT+"faturas/novo-credito/"+id;
    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        callback(data);
    })
    .catch(() => {
      callbackError();
    })
}

const novoDebito = (id, formData, callback, callbackError) => {
    var url = ENDPOINT+"faturas/novo-debito/"+id;
    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        callback(data);
    })
    .catch(() => {
      callbackError();
    })
}


const migrarPos = (id, callback, callbackError) => {
    var url = ENDPOINT+"faturas/migrar-pos";
    var formData = new FormData;
    formData.append('id', id);
    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        toast.success(data.message);
        callback(data);
    })
    .catch(() => {
      callbackError();
    })
}

const migrarPre = (id, callback, callbackError) => {
    var url = ENDPOINT+"faturas/migrar-pre";
    var formData = new FormData;
    formData.append('id', id);
    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        toast.success(data.message);
        callback(data);
    })
    .catch(() => {
      callbackError();
    })
}


const exportarTags = (id) => {
    toast.info("Aguarde o documento ser baixado")
    var url = ENDPOINT+"taggy/exportar_taggys/"+id;
    axios({
      url: url, //your url
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        Authorization: 'Bearer '+getToken()
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'relatorio_de_pagamentos.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
}
const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
        padding: 0.5rem;
        border: 1px solid #ccc;
        margin: 0.2rem 0.3rem;
        border-radius: 5px;
    }
    .nav-tabs .nav-item.show .nav-link, 
    .nav-tabs .nav-link.active {
        background: #28a745;
        color: #fff;
    }
    .nav-link:hover{
        background: #70b580;
        color: #fff;
    }
    .nav-tabs {
        padding-bottom: 1rem;
    }
`;


const getGreenpass = (userCode, callback, callbackError) => {
    var url = ENDPOINT + "taggy/consulta_greenpass/"+userCode;

    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        callback(data);
    })
    .catch((error) => {
        var data = error.response.data;
        toast.error('Ocorreu um erro ao realizar a pesquisa, tente novamente mais tarde');
        callbackError();
    });
}

const motivos_limite = () => {
    const motivo = new Array();
    motivo['scr']= "Ré-análise de Risco SCR";
    motivo['pontuacao_cadastro']= "Pontuação Cadastro Positivo";
    motivo['score_risco']= "Pontuação de Risco Score de Crédito Bureau";
    motivo['inadiplente_aqbank']= "Indiplente Holding AQBank";
    motivo['inadiplente_fatura']= "Inadiplência de Fatura";
    motivo['cumprimento_bloqueio_judicial']= "Cumprimento de Bloqueio Judicial";
    motivo['cancelamento']= "Cancelamento de Serviço Pós-Pago";
    return motivo;
}
const ResumoClienteAQPasso = (props) => {
    const [cliente, setCliente] = useState({
        name: '',
        communication_relacao_id: null,
        email: '',
        userId: null,
        id_crm: null,
        pagamento_assistencia: null,
        serasa: [],
        tags: [],
        limites: [],
        creditos: [],
        servicos: [],
        historico_alteracoes: [],
        plano: '',
        saldo_pre_pago: 0.00,
        limite_pos : 0.00,
        contas_depositos: [],
        movimentacao_conta: [],
        mail_edit_enable: false,
        address: {},
    });

    const [mail_edit_enable, setMailEditEnable] = useState(false);
    const [phone_edit_enable, setPhoneEditEnable] = useState(false);
    const [visible_address, setVisibleAddress] = useState(false);
    const [loading_termo_tab, setLoadingTermo] = useState(false);
    const [loading_serasa, setLoadingSerasa] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [active, setActive] = useState('1');
    const [termos, setTermos] = useState([]);
    const [documento_seller, setDocumentoSeller] = useState('');
    const [estabelecimento, setEstabelecimento] = useState('');
    const [loading_page, setLoadingPage] = useState(false);
    const [loading_m, setLoadingM] = useState(false);
    const [message_termos, setMessageTermos] = useState('');
    const [limite_show, setLimiteShow] = useState(false);
    const [descricao_limite, setDescricaoLimite] = useState('');
    const [motivo_limi, setMotivoLimite] = useState('');
    const [notaAlteracaoLimite, setNotaAlteracaoLimite] = useState(false);
    const [valor_limite, setValorLimite] = useState(0);
    const [credito_show, setCreditoShow] = useState(false);
    const [notaAlteracaoCredito, setNotaAlteracaoCredito] = useState('');
    const [descricao_credito, setDescricaoCredito] = useState('');

    
    const [debito_show, setDebitoShow] = useState(false);
    const [notaAlteracaoDebito, setNotaAlteracaoDebito] = useState('');
    const [descricao_debito, setDescricaoDebito] = useState('');

    
    const [visible_greenpass, setVisibleGreenpass] = useState(false);
    const [loading_greenpass, setLoadingGreenpass] = useState(false);
    const [tags_greenpass, setTagsGreenpass] = useState([]);
    
    useEffect(() => {
        getUser(get_id(), setCliente);
    },[]);

    const get_status_auth = (payment_type) => {
        switch(payment_type){
            case 0 : return 'Aguardando Validação';
            case 1 : return 'Validado';
            case 2 : return 'Perdido';
            default: return ''
        }
    }
    const listData = [
        { name: 'Dados do Cliente', active: '1' },
        { name: 'Chats', active: '2' },
        { name: 'E-mails', active: '3' },
        { name: 'SMS', active: '4' },
        { name: 'Notas', active: '5' },
        { name: 'Serasa', active: '6' },
        { name: 'Tags', active: '7' },
        { name: "Aceites de Termos", active: '8' },
        { name: "Limite de crédito", active: '9' },
        { name: "Movimentação de Saldo pré-pago", active: '10' },
        { name: "Movimentação de Faturas", active: '11' },
        { name: "Abono de Crédito", active: '12' },
        { name: 'Histórico de Alterações', active: '13' },
        { name: 'Validações de cliente', active: '14' },
        { name: 'Transações', active: '15' },
        { name: 'Greenpass', active: '16' }
    ]

    const changeGreenpass = () => {
        setLoadingGreenpass(true);
        setVisibleGreenpass(true)
        getGreenpass(cliente.userCode, callbackGreenpass, callbackGreenpassError)
    }
    
    const callbackGreenpassError = (greenpass) => {
        setVisibleGreenpass(false);
        setLoadingGreenpass(false);
    }
    const callbackGreenpass = (greenpass) => {
        setVisibleGreenpass(true);
        setLoadingGreenpass(false);
        setTagsGreenpass(greenpass.body.tags)
    }

    const handleLoadDataTermos = () => {
        setLoadingTermo(true)

        var url = ENDPOINT + "termo_aqpasso/detalhes/";
        url += cliente.documento;
        var myHeaders = new Headers();

        myHeaders.append('Authorization', 'Bearer ' + getToken());
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            if (p.success !== undefined && p.success == true) {
                let response = p.data.data;
                let estabelecimento = '';

                if (response.razao_social !== undefined) {
                    estabelecimento = response.razao_social;
                } else {
                    estabelecimento = response.nome + ' ' + response.sobrenome;
                }

                setTermos(response.termos);
                setEstabelecimento(estabelecimento);
                setLoadingTermo(false);

                
            } else {
                setMessageTermos(p.data.error);
                setLoadingTermo(false);
            }
        })
        .catch((error) => {
            setLoadingTermo(false);
            console.log(error)
            toast.error('Ocorreu um erro no servidor!');
        })
    }

    const Navs = () => {
        return <Nav tabs>
            {listData.map((p, index) => {
                return <NavLink
                    key={index}
                    className={
                        classnames({ active: active === p.active })}
                    onClick={() => { { 
                        if (p.active == 8) {
                            handleLoadDataTermos()
                            setActive(p.active)
                        } else {
                            setActive(p.active)
                        }
                        }
                     }}>
                    <span>{p.name}</span>
                </NavLink>
            })}
        </Nav>
    }

    const changeVisible = (isVisible) => {
        setIsVisible(isVisible);
        getUser(get_id(), setCliente);
    }

    
    const onPenEditMail = () => {
        setMailEditEnable(true)
        return true;
    }


    const onPenEditPhone = () => {
        setPhoneEditEnable(true)
        return true;
    }

    const onCloseEditPhone = () => {
        setPhoneEditEnable(false)
        return true;
    }

    const sendEditPhone = () => {
        let url = `${ENDPOINT}aqpasso_edit/user_phone/${cliente.id}`;

        axios.put(url, {
            phone: cliente.telefone
        }, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            onCloseEditPhone()
            toast.success('Telefone alterado com sucesso!');
        })
        .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              //console.log(error.response.data);
              toast.error(error.response.data.errors);
              //console.log(error.response.status); // status code
              //console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              toast.error('Falha ao atualizar');
              // http.ClientRequest in node.js
              //console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              toast.error(error.message);
              //console.log('Error', error.message);
            }
            // console.log(error.config);
        })
    }

    const sendEditEmail = () => {
        let url = `${ENDPOINT}aqpasso_edit/user_email/${cliente.id}`;

        axios.put(url, {
            email: cliente.email
        }, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            onCloseEditMail()
            toast.success('E-mail alterado com sucesso!');
        })
        .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              //console.log(error.response.data);
              toast.error(error.response.data.errors);
              //console.log(error.response.status); // status code
              //console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              toast.error('Falha ao atualizar');
              // http.ClientRequest in node.js
              //console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              toast.error(error.message);
              //console.log('Error', error.message);
            }
            // console.log(error.config);
        })
    }

    const onCloseEditMail = () => {
        setMailEditEnable(false)
        return true;
    }


    const onOpenAddress = () => {
        setVisibleAddress(true)
    }

    const onCloseAddress = () => {
        setVisibleAddress(false)
    }

    const onRefreshAddress = () => {
       getUser(get_id(), setCliente);
    }

    const onChangeValEdit = (key, value) => {
        cliente[key] = value;
        setCliente({...cliente})
    }

    const onValor = (event) => {
        var valor_current = event.target.value;
        if (valor_current.length > 1) {
        valor_current = valor_current.replaceAll('.', '');
        var digits = valor_current.substr(valor_current.length - 2);
        valor_current = valor_current.slice(0, -2);
        valor_current = valor_current + '.' + digits;
        }

        var valido = /^[\d,.?!]+$/.test(valor_current);
        if (valido === true || valor_current === '') {
            setValorLimite(valor_current);
        }
    };
    return <Div>
        <Card>
            <CardBody>
                <Navs />
                <TabContent activeTab={active}>
                    <br />
                    <TabPane tabId={"1"}>
                        <h5>Dados Pessoais</h5>
                        <hr/>
                        <p><b>Nome:</b> {cliente.nome}</p>
                        <p><b>Documento:</b> {cliente.documento}</p>
                        <p><b>Plano:</b> {cliente.plano}</p>

                        {mail_edit_enable !== true ?
                            <>
                                <p><b>E-mail: </b> {cliente.email}&nbsp;<Button outline onClick={() => {return onPenEditMail()}} color='success' style={{padding: '3px', fontSize: '12px'}}>Editar</Button></p>
                            </>
                            :
                            <>
                                <p><b>E-mail: </b>&nbsp;
                                <Input 
                                    name="telefone_edit"
                                    placeholder="Telefone"
                                    value={cliente.email}
                                    onChange={(event) => onChangeValEdit('email', event.target.value)}
                                    style={{maxWidth: '300px', width: '100%'}}
                                    /><br/>
                                    <Button color='success' onClick={() => sendEditEmail()} style={{marginLeft: 2, marginRight: 2, padding: 2, fontSize: '12px'}}>Salvar</Button>
                                    <Button color='danger' onClick={() => onCloseEditMail()} style={{marginLeft: 2, marginRight: 2, padding: 2, fontSize: '12px'}}>Cancelar</Button>
                                </p>
                            </>
                        }

                        <p><b>Data de Nascimento:</b> {moment(cliente.data_nascimento).format('DD/MM/YYYY')}</p>
                        {/* <p><b>Telefone:</b> {cliente.telefone}</p> */}
                        {phone_edit_enable !== true ?
                            <>
                                <p><b>Telefone: </b>&nbsp;
                                <NumberFormat displayType={'text'} value={cliente.telefone} format="(##) #####-####" />&nbsp;
                                <Button outline onClick={() => onPenEditPhone()} color='success' style={{padding: '3px', fontSize: '12px'}}>Editar</Button>
                                </p>
                            </>
                            :
                            <>
                                <p><b>Telefone: </b>&nbsp;<br/>
                                    <NumberFormat 
                                        displayType={'input'} 
                                        className="form-control"
                                        value={cliente.telefone} 
                                        format="(##) #####-####" 
                                        onChange={(event) => onChangeValEdit('telefone', event.target.value)}
                                        style={{maxWidth: '200px', width: '100%'}}
                                        /><br/>
                                    <Button color='success' onClick={() => sendEditPhone()} style={{marginLeft: 2, marginRight: 2, padding: 2, fontSize: '12px'}}>Salvar</Button>
                                    <Button color='danger' onClick={() => onCloseEditPhone()} style={{marginLeft: 2, marginRight: 2, padding: 2, fontSize: '12px'}}>Cancelar</Button>
                                </p>
                            </>
                        }


                        <p>
                            <b>Endereço:</b> {cliente.endereco}&nbsp;
                            <Button outline color='success' style={{padding: '3px', fontSize: '12px'}} onClick={() => onOpenAddress()}>Editar</Button>
                        </p>

                        {cliente.address?.cep ? (
                            <EnderecoCadastroAQPasso
                                user_id={cliente?.id ? cliente.id : null}
                                address={cliente?.address ? cliente.address : null}
                                visible={visible_address}
                                onClose={onCloseAddress}
                                onRefresh={onRefreshAddress}
                            />
                        ) : (<></>)}



                        <p>{cliente.pagamento_assistencia !== null ?
                            <div>
                                <p><b>Assistencia 24 Horas contratado</b></p>
                                <p><b>ID de Pagamento de Assistencia 24 Horas:</b> {cliente.pagamento_assistencia_vintequatro_horas}</p>
                                <p><b>Status de Pagamento de Assistencia 24 Horas:</b> {cliente.pagamento_assistencia.status}</p>
                            </div>
                        :<></>}</p>
                        <p><b>Saldo Pré Pago:</b> {parseFloat(cliente.saldo_pre_pago).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                        <p><b>Limite Pós Pago:</b> {parseFloat(cliente.limite_pos).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                        {cliente.plano == 'Plano Pós Pago' ?
                            <div>
                                <Button 
                                    primary="primary"
                                    disabled={loading_m}
                                    onClick={() => {
                                        setLoadingM(true)
                                        migrarPre(get_id(), 
                                            () => {
                                                getUser(get_id(), setCliente);
                                                setLoadingM(false);
                                            },
                                            () => {
                                                setLoadingM(false);
                                            })
                                        }
                                    }
                                >
                                    Migrar cliente para Plano Pré Pago (Recusar Pós Pago)
                                </Button>
                            </div>
                            :
                            <Button 
                                primary="primary"
                                disabled={loading_m}
                                onClick={() => {
                                    setLoadingM(true)
                                    migrarPos(get_id(), 
                                        () => {
                                            getUser(get_id(), setCliente);
                                            setLoadingM(false);
                                        },
                                        () => {
                                            setLoadingM(false);
                                        })
                                    }
                                }
                            >
                                Migrar cliente para Plano Pós Pago
                            </Button>
                        }
                        <br/>
                        <p><b>Serviços Adicionados: </b>{cliente.servicos.map(e => (
                            <div>
                                <p>{e.servico.servico} - {parseFloat(e.valor_pago).toFixed(2)}</p>
                            </div>
                        ))}</p>
                    </TabPane>
                    <ChatsClient 
                        active={'2'}
                        name={cliente.nome}
                        communication_relacao_id={cliente.communication_relacao_id}
                    />
                    <EmailClient
                        active={'3'}
                        name={cliente.nome}
                        email={cliente.email}
                        communication_relacao_id={cliente.communication_relacao_id}
                    />
                    <SmsClient
                        active={'4'}
                        name={cliente.nome}
                        telefone={cliente.telefone}
                        communication_relacao_id={cliente.communication_relacao_id}
                    />
                    <NotasAtendimento
                        active={'5'}
                        name={cliente.nome}
                        user_id={cliente.id_crm}
                        desc_id="user_id"
                        communication_relacao_id={cliente.communication_relacao_id}
                    />
                    <TabPane tabId={"6"}>
                        <Card style={{border: 'none'}}>
                            <CardBody>
                            <h5>Bureau de Crédito</h5>
                            <hr/>
                                {cliente.serasa.length == 0 ? <div>
                                    <Button 
                                        color="success"
                                        disabled={loading_serasa}
                                        onClick={() => {
                                            setLoadingSerasa(true);

                                            getSerasa(
                                                cliente.documento, 
                                                setCliente,
                                                () => setLoadingSerasa(false)
                                            )
                                        }}
                                    >
                                        Consultar Bureau
                                    </Button>
                                </div> 
                                : 
                                    <div>
                                        <Button 
                                            color="success"
                                            disabled={loading_serasa}
                                            onClick={() => {
                                                setLoadingSerasa(true);

                                                getSerasaNew(
                                                    cliente.documento, 
                                                    setCliente,
                                                    () => setLoadingSerasa(false)
                                                )
                                            }}
                                        >
                                            Nova Consulta Bureau
                                        </Button>
                                    </div>
                                }
                                <br/>
                            <Row>
                                {cliente.serasa.map(e => {
                                    var score = JSON.parse(e.consulta_serasa);
                                    var resumo = JSON.parse(e.resumo_serasa);
                                    return(
                                        <Col md={6}>
                                            <Card>
                                                <CardBody>
                                                    <ReactStoreIndicator
                                                        value={score.score.pontos}
                                                        maxValue={1000}
                                                        lineWidth={25}
                                                    />
                                                    <span style={{
                                                        position: 'absolute',
                                                        top: '4rem', 
                                                        width: '100%',
                                                        left: 0,
                                                        fontSize: '55px',
                                                        textAlign: 'center',
                                                        fontWeight: 'bold'
                                                    }}>
                                                        {score.score.classe}
                                                    </span>
                                                    <center>
                                                        <h5>{score.score.faixa.titulo}</h5>
                                                    </center>
                                                    <hr/>
                                                    <div>
                                                        <p>{score.score.faixa.descricao}</p>
                                                        <p><b>Data da Consulta:</b> {moment(new Date(e.created_at).toString()).format('DD/MM/YYYY HH:m')}</p>
                                                        <p>
                                                            <b>Renda Presumida:</b>&nbsp;                                                         
                                                            <NumberFormat 
                                                                prefix="R$ " 
                                                                displayType="text" 
                                                                value={parseFloat(resumo.rendaPresumida)} 
                                                                decimalSeparator=","/>
                                                        </p>
                                                        <p>
                                                            <b>{resumo.debitos.sumQuantidade} Débito{resumo.debitos.sumQuantidade > 1 ? 's' : ''}:</b>&nbsp;                                                         
                                                            <NumberFormat 
                                                                prefix="R$ " 
                                                                displayType="text" 
                                                                value={parseFloat(resumo.debitos.sumValorTotal)} 
                                                                decimalSeparator=","/>
                                                        </p>
                                                        <p>
                                                            <b>{resumo.protestos.sumQuantidade} Protesto{resumo.protestos.sumQuantidade > 1 ? 's' : ''} :</b>&nbsp;                                                         
                                                            <NumberFormat 
                                                                prefix="R$ " 
                                                                displayType="text" 
                                                                value={parseFloat(resumo.protestos.sumValorTotal)} 
                                                                decimalSeparator=","/>
                                                        </p>
                                                        {resumo.acoes ?
                                                            <p>
                                                                <b>{resumo.acoes.sumQuantidade} Aç{resumo.acoes.sumQuantidade > 1 ? 'ões' : 'ão'}:</b>&nbsp;                                                         
                                                                <NumberFormat 
                                                                    prefix="R$ " 
                                                                    displayType="text" 
                                                                    value={parseFloat(resumo.acoes.sumValorTotal)} 
                                                                    decimalSeparator=","/>
                                                            </p>
                                                            :
                                                            <></>
                                                        }
                                                        <p>
                                                            <b>{resumo.cheques.sumQuantidade} Cheque{resumo.cheques.sumQuantidade > 1 ? 's' : ''}</b>&nbsp;                                                         
                                                        </p>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )
                                })}
                            </Row>
                            </CardBody>
                        </Card>
                    </TabPane>
                    <TabPane tabId={"7"}>
                        <Card style={{border: 'none'}}>
                            <CardBody>
                                <h5>Tags</h5>
                                <Button color="success" onClick={() => exportarTags(cliente.conta_id)}>Exportar</Button>
                                <hr/>
                                <Row>
                                    {cliente.tags.map(e => (
                                        <Col md={6}>
                                            <Card>
                                                <CardBody>
                                                    <p><b>Serial da Tag:</b> {e.tag_serial == '0000000000000000' ? 'Não Atribuido' : e.tag_serial}</p>
                                                    <p><b>Veículo</b> {e.veiculo_fabricante} {e.veiculo_modelo}</p>
                                                    <p><b>Placa:</b> {e.veiculo_placa}</p>
                                                    <Button 
                                                        style={{padding: 5, margin: 5, fontSize: '13px'}} 
                                                        color="info"
                                                        outline
                                                        onClick={() => 
                                                            {
                                                                window.open('/aqpasso-detalhes/'+e.id, '_blank')
                                                            }
                                                        }
                                                        >
                                                            Detalhes
                                                    </Button>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </CardBody>
                        </Card>
                    </TabPane>
                    <TabPane tabId={'8'}>
                        <Card style={{border: 'none'}}>
                            <CardBody>
                                <h5>Lista de Termos</h5>
                                <hr/>
                                {termos.length > 0 ?
                                    termos.map((termo, i) => {
                                        return (
                                        <Row>
                                            <Col md={12}>
                                                <div className="box-div-page" style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                                    <p><b>ID Aceite:</b> {termo.id}</p>
                                                    <p><b>Código Hash Digital de Assinatura do Aceite ID:</b> {termo.id_dispositivo}</p>
                                                    <p><b>Nome do Cliente/Estabelecimento Credenciamento:</b> {estabelecimento}</p>
                                                    <p><b>CPF/CNPJ:</b>&nbsp;
                                                        {termo.cpf_cnpj_owner_accept !== null ?
                                                            <NumberFormat displayType={'text'} value={termo.cpf_cnpj_owner_accept} format={termo.cpf_cnpj_owner_accept.length > 11 ? '##.###.###/####-##' : "###.###.###-##"}/>
                                                            :
                                                            ''
                                                        }
                                                    </p>
                                                    <p><b>Meio de Acesso:</b>&nbsp; 
                                                        {termo.meio_acesso !== null ?
                                                            termo.meio_acesso.replace('_', ' ')
                                                        :
                                                            ''
                                                        }
                                                    </p>
                                                    <p><b>Tipo de Dispositivo Usado:</b> {termo.tipo_dipositivo}</p>
                                                    <p><b>Modelo do Dispositivo:</b> {termo.modelo_dispositivo}</p>
                                                    <p><b>Sistema Operacional do Dispositivo:</b>&nbsp;
                                                        {termo.sistema_operacional !== null ?
                                                            termo.sistema_operacional.toUpperCase()
                                                        :
                                                            ''
                                                        }
                                                    </p>
                                                    <p><b>Endereço IP de Internet:</b> {termo.ip}</p>
                                                    <p><b>Latitude de Geolocalização:</b> {termo.latitude}</p>
                                                    <p><b>Longitude Geolocalização:</b> {termo.longitude}</p>
                                                    <p><b>Data do Aceite:</b>&nbsp;
                                                        {termo.data_aceite !== null ?
                                                            moment(termo.data_aceite).format('DD/MM/YYYY')
                                                        :
                                                            ''
                                                        }
                                                    </p>
                                                    <p><b>Hora do Aceite:</b>&nbsp;
                                                        {termo.data_aceite !== null ?
                                                            moment(termo.data_aceite).format('HH:mm')
                                                        :
                                                            ''
                                                        }
                                                    </p>
                                                    <p><b>Carimbo de Data/Hora:</b>&nbsp;
                                                        {termo.data_aceite !== null ?
                                                            moment(termo.data_aceite).format('DD/MM/YYYY HH:mm:ss')
                                                        :
                                                            ''
                                                        }
                                                    </p>
                        
                                                </div>
                                                <hr />
                                            </Col>
                                        </Row>
                                        )
                                    })

                                    
                                :
                                <>
                                <Row hidden={!loading_termo_tab}>
                                    <Col md={12}>
                                        <center>
                                            <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                                        </center>
                                        <br/>
                                    </Col>
                                </Row>
                                <Row hidden={!loading_page}>
                                    <Col md={12}><p>Nenhum termo lozalizado!</p></Col>
                                </Row>
                                </>
                                
                                }
                                <Row>
                                    <Col md={12}>
                                        {message_termos}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </TabPane>
                    <TabPane tabId={'9'}>
                        <Card style={{border: 'none'}}>
                            <CardBody>
                                <Button color="success" onClick={() => setLimiteShow(!limite_show)}>Atribuir Limite</Button>
                                <br/><br/>
                                
                                <Collapse isOpen={limite_show}>
                                    <Card style={{width: '100%', borderRadius: '1.25rem'}}>
                                        <CardBody > 
                                            <h6>
                                                Novo Limite
                                            </h6>
                                            <hr/>
                                            <CurrencyInput 
                                                inputMode="tel"
                                                className="form-control" 
                                                currency="BRL"
                                                name="valor_de"
                                                //value={this.state.valor}
                                                defaultValue={'0.00'}
                                                onChange={onValor} />
                                            <br/>
                                            <div style={{ maxHeight: '150px', overflow: 'auto'}}>
                                                <b>Motivo de Alteração de Limite</b>
                                                <Input 
                                                    type="select" 
                                                    value={motivo_limi}
                                                    onChange={e => setMotivoLimite(e.target.value)}>
                                                    {Object.keys(motivos_limite()).map((key) => {
                                                        <option>Selecione o Motivo de Alteração de Limite</option>
                                                        return <option value={key}>{motivos_limite()[key]}</option>
                                                    })}
                                                </Input>
                                            </div>
                                            <div>
                                                <b>Deseja incluir uma nota: </b>
                                                <input 
                                                    type="radio" 
                                                    checked={notaAlteracaoLimite === false} 
                                                    onChange={() => setNotaAlteracaoLimite(false)}/>
                                                    &nbsp;Não &nbsp;&nbsp;
                                                <input 
                                                    type="radio" 
                                                    checked={notaAlteracaoLimite} 
                                                    onChange={() => setNotaAlteracaoLimite(true)}/>&nbsp;Sim
                                            </div>
                                            <br/>
                                            <div hidden={!notaAlteracaoLimite} style={{ maxHeight: '150px', overflow: 'auto'}}>
                                                <Input 
                                                    type="textarea"
                                                    name="descricao"
                                                    value={descricao_limite}
                                                    placeholder="Insira a descrição da nota"
                                                    onChange={e => setDescricaoLimite(e.target.value)} />
                                            </div>
                                            <hr/>
                                            <Button color="success" onClick={()=> {
                                                var formData = new FormData;
                                                formData.append('valor', valor_limite);
                                                formData.append('motivo', motivos_limite()[motivo_limi]);
                                                if(notaAlteracaoLimite == true){
                                                    formData.append('descricao', descricao_limite);
                                                }

                                                if(motivo_limi.length == 0){
                                                    toast.error('Selecione o Motivo')
                                                    return;
                                                }
                                                else{
                                                    novoLimite(get_id(), formData, () => {
                                                        setLimiteShow(false);
                                                        getUser(get_id(), setCliente)
                                                    });
                                                }
                                            }}>Criar</Button>
                                        </CardBody>
                                    </Card>
                                    <hr/>
                                </Collapse>
                                <Row>
                                    {cliente.limites.map(e => (
                                        <Col md={4}>
                                            <Card>
                                                <CardBody>
                                                    Valor: {parseFloat(e.valor_limite).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}<br/>
                                                    Motivo: {e.motivo}<br/>
                                                    Operador: {e.operador_name}<br/>
                                                    Descrição: {e.descricao}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                    {cliente.length == 0 ? 'Nenhum Historico de limite encontrado' : ''}
                                </Row>
                            </CardBody>
                        </Card>
                    </TabPane>
                    <MovimentacaoContas 
                        active={'10'}
                        name={cliente.nome}
                        user_id={cliente.userId}
                        documento={cliente.documento} />
                    <MovimentacaoFaturas 
                        active={'11'}
                        name={cliente.nome}
                        user_id={cliente.userId}
                        documento={cliente.documento} 
                    />
                    <TabPane tabId={'12'}>
                        <Card style={{border: 'none'}}>
                            <CardBody>
                                <Button color="success" style={{margin: 3}} onClick={() => setCreditoShow(!credito_show)}>Atribuir Crédito ao cliente</Button><br/>
                                <Button color="warning" style={{margin: 3}} onClick={() => setDebitoShow(!debito_show)}>Debitar da conta cliente</Button>
                                <br/><br/>
                                
                                <Collapse isOpen={debito_show}>
                                    <Card style={{width: '100%', borderRadius: '1.25rem'}}>
                                        <CardBody > 
                                            <h6>
                                                
                                            </h6>
                                            <hr/>
                                            <CurrencyInput 
                                                inputMode="tel"
                                                className="form-control" 
                                                currency="BRL"
                                                name="valor_de"
                                                //value={this.state.valor}
                                                defaultValue={'0.00'}
                                                onChange={onValor} />
                                            <br/>
                                            <div>
                                                <b>Deseja incluir o motivo: </b>
                                                <input 
                                                    type="radio" 
                                                    checked={notaAlteracaoDebito === false} 
                                                    onChange={() => setNotaAlteracaoDebito(false)}/>
                                                    &nbsp;Não &nbsp;&nbsp;
                                                <input 
                                                    type="radio" 
                                                    checked={notaAlteracaoDebito} 
                                                    onChange={() => setNotaAlteracaoDebito(true)}/>&nbsp;Sim
                                            </div>
                                            <br/>
                                            <div hidden={!notaAlteracaoDebito} style={{ maxHeight: '150px', overflow: 'auto'}}>
                                                <Input 
                                                    type="textarea"
                                                    name="descricao"
                                                    value={descricao_debito}
                                                    placeholder="Descreva o motivo"
                                                    onChange={e => setDescricaoDebito(e.target.value)} />
                                            </div>
                                            <hr/>
                                            <Button color="success" onClick={()=> {
                                                var formData = new FormData;
                                                formData.append('valor', valor_limite);
                                                //formData.append('motivo', motivos_limite()[motivo_limi]);
                                                if(notaAlteracaoDebito == true){
                                                    formData.append('descricao', descricao_debito);
                                                }

                                                /* if(motivo_limi.length == 0){
                                                    toast.error('Selecione o Motivo')
                                                    return;
                                                }
                                                else{ */
                                                    novoDebito(get_id(), formData, () => {
                                                        setDebitoShow(false);
                                                        getUser(get_id(), setCliente)
                                                    });
                                                //}
                                            }}>Criar</Button>
                                        </CardBody>
                                    </Card>
                                    <hr/>
                                </Collapse>
                                <Collapse isOpen={credito_show}>
                                    <Card style={{width: '100%', borderRadius: '1.25rem'}}>
                                        <CardBody > 
                                            <h6>
                                                Novo Crédito
                                            </h6>
                                            <hr/>
                                            <CurrencyInput 
                                                inputMode="tel"
                                                className="form-control" 
                                                currency="BRL"
                                                name="valor_de"
                                                //value={this.state.valor}
                                                defaultValue={'0.00'}
                                                onChange={onValor} />
                                            <br/>
                                            <div>
                                                <b>Deseja incluir o motivo: </b>
                                                <input 
                                                    type="radio" 
                                                    checked={notaAlteracaoCredito === false} 
                                                    onChange={() => setNotaAlteracaoCredito(false)}/>
                                                    &nbsp;Não &nbsp;&nbsp;
                                                <input 
                                                    type="radio" 
                                                    checked={notaAlteracaoCredito} 
                                                    onChange={() => setNotaAlteracaoCredito(true)}/>&nbsp;Sim
                                            </div>
                                            <br/>
                                            <div hidden={!notaAlteracaoCredito} style={{ maxHeight: '150px', overflow: 'auto'}}>
                                                <Input 
                                                    type="textarea"
                                                    name="descricao"
                                                    value={descricao_credito}
                                                    placeholder="Descreva o motivo"
                                                    onChange={e => setDescricaoCredito(e.target.value)} />
                                            </div>
                                            <hr/>
                                            <Button color="success" onClick={()=> {
                                                var formData = new FormData;
                                                formData.append('valor', valor_limite);
                                                //formData.append('motivo', motivos_limite()[motivo_limi]);
                                                if(notaAlteracaoCredito == true){
                                                    formData.append('descricao', descricao_credito);
                                                }

                                                /* if(motivo_limi.length == 0){
                                                    toast.error('Selecione o Motivo')
                                                    return;
                                                }
                                                else{ */
                                                    novoCredito(get_id(), formData, () => {
                                                        setCreditoShow(false);
                                                        getUser(get_id(), setCliente)
                                                    });
                                                //}
                                            }}>Criar</Button>
                                        </CardBody>
                                    </Card>
                                    <hr/>
                                </Collapse>
                                <Row>
                                    {cliente.creditos.map(e => (
                                        <Col md={4}>
                                            <Card>
                                                <CardBody>
                                                    Valor: {parseFloat(e.valor_credito).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}<br/>
                                                    {/* Motivo: {e.motivo}<br/> */}
                                                    Operador: {e.operador_name}<br/>
                                                    Descrição: {e.descricao}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                    {cliente.creditos.length == 0 ? 'Nenhum Historico de limite encontrado' : ''}
                                </Row>
                            </CardBody>
                        </Card>
                    </TabPane>
                    <TabPane tabId={"13"}>
                        <p hidden={cliente.historico_alteracoes.length == 0}><b>Histórico de Alterações:</b><br/>
                            {cliente.historico_alteracoes.map(e => {
                                return <p>{moment(e.created_at).format('DD/MM/YYYY HH:mm')} - <div dangerouslySetInnerHTML={{ __html: replaceAll(e.descricao, "\n", "<br/>") }} /></p>
                            })}
                        </p>
                    </TabPane>
                    <HistoricoAlteracoesAQPasso
                        active={'13'}
                        name={cliente?.nome ? cliente?.nome : '' }
                        user_id={cliente?.id ? cliente.id : null}
                        communication_relacao_id={null}
                    />
                    <TabPane  
                        tabId={'14'}>
                            <div>
                                <br/>
                                <h5>Validações de Cliente</h5>
                                <hr/>
                                <Button color='success' onClick={() => changeVisible(true)}>Validar Cliente</Button>
                                <hr/>
                                {cliente.autorizacoes ?
                                    <p>{cliente.autorizacoes.map(e => (
                                        <p>Token gerado em {moment(e.created_at).format('DD/MM/YYYY HH:mm') } com o status de {get_status_auth(e.status)}
                                        <br/><small>Gerado por: <b>{e.operador_name}</b></small></p>
                                    ))}</p>
                                    :
                                    null
                                }
                            </div>
                    </TabPane>
                    <TabPane
                        tabId={'16'}>
                        <div style={{ padding: '15px', border: '1px solid #dee2e6', borderRadius: 5 }}>
                            <h5>Greenpass</h5>
                            <hr />
                            {cliente.userCode !== null ?
                                <div>
                                    <Button 
                                        color='success' 
                                        disabled={visible_greenpass} 
                                        onClick={() => changeGreenpass()}>
                                            {loading_greenpass === true ? <ClipLoader color="#fff" size={15}/> :<></> } Consultar na Greenpass
                                    </Button>
                                    <br/>
                                    <br/>
                                    <Row>
                                        {tags_greenpass.map(e => (
                                            <Col md={4}>
                                                <Card>
                                                    <CardHeader>
                                                        <b>Serial da TAG - {e.serialNumber}</b>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <b>Categoria: </b>{e.categoryCode} - {e.category} <br/>
                                                        <b>Placa: </b>{e.licensePlate} <br/>
                                                        <b>Status: </b>{e.status} <br/>
                                                        <b>Código de Status: </b>{e.statusCode}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            : 
                            <p>Cliente não vinculado a greenpass, para vincular e necessario que uma Tag seja ativada</p>}
                            <hr />
                        </div>
                    </TabPane>
                    <TransacaoPedagio
                        active={'15'}
                        name={cliente.nome}
                        user_id={cliente.userId}
                        documento={cliente.documento} 
                    />
                </TabContent>
            </CardBody>
        </Card>
        <ModalValidacaoToken
            user={{
                id: get_id()
            }}
            tipo_cliente="aqpasso"
            visible={isVisible}
            onClose={() => setIsVisible(false)}
        />
    </Div>
}

export default ResumoClienteAQPasso;

const capitalize = str => {
	if (typeof str !== 'string') {
		return '';
	}
	return str.charAt(0).toUpperCase() + str.substr(1);
}