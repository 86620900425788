import React, { Component } from "react";
import { MdGroup } from 'react-icons/md';
import { Modal, ModalBody, ModalFooter, Form, FormGroup, FormFeedback, Label, Card, CardBody, Row, Col, Button, Input } from "reactstrap";
import { InputText } from "../../../components/InputText/InputText";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import ReactRoundedImage from "react-rounded-image";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import axios from "axios";
import ModelReturn from "../../../components/Modals/ReturnsApiModals/ModelReturn";

const users_grupos = [
    { label: 'Todos', value: 'todos'},
    { label: 'Vários', value: 'varios'},
    { label: 'Apenas um atendente', value: 'um_atendente'},
    { label: 'Departamento', value: 'departamento'},
];

const getDepartamentos = (callback) => {
    var url = ENDPOINT + "departamento/list";
    
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        var operadores_list = [];
        data.forEach((p, i) => {
            p['value'] = p.flag;
            p['label'] = p.nome;
            operadores_list.push(p);
        });

        callback(operadores_list)
    });
}
class CreateGroupModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            nome_grupo: '',
            nome_grupo_invalid: false,
            descricao_grupo: '',
            descricao_grupo_invalid: false,
            atendente: null,
            atendente_invalid: false,
            departamento: null,
            departamento_invalid: false,
            tipo_atendente: null,
            tipo_atendente_invalid: false,
            inputValue: '',
            atendentes: [],
            departamentos: [],
            add_atendentes: [],
            add_atendentes_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        }
        this.onClose = this.onClose.bind(this);
        this.onError = this.onError.bind(this);
    }
    componentDidMount(){
        getDepartamentos(this.callbackDepartamentos.bind(this));
    }

    callbackDepartamentos = (departamentos) => {
        this.setState({departamentos});
    }

    onRegister() {
        if(this.valid_fields() === 0){
            this.setState({ loading: true });
            this.store();
        }
    }

    onData(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }

    store = () => {
        var url = ENDPOINT+"chat-grupo/criar-grupo";

        var formData = new FormData();
        formData.append('nome_grupo', this.state.nome_grupo);
        formData.append('descricao_grupo', this.state.descricao_grupo);
        formData.append('tipo_atendente', this.state.tipo_atendente.value);

        
        switch(this.state.tipo_atendente.value){
            case 'varios': {
                this.state.add_atendentes.forEach((p, key) => {
                    formData.append('participantes['+key+']', JSON.stringify(p));
                })
                break;
            }
            case 'um_atendente' : {
                
                formData.append('participante', JSON.stringify(this.state.atendente));
                break;
            }
            case 'departamento' : {
                formData.append('departamento', JSON.stringify(this.state.departamento));
                break;
            }
            default: {
                break;
            }
        }
    
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            this.onData(data);
            return data;
        })
        .catch((error) => {
            var obj;
            var response;

            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                this.onData(obj);
                return;
            }
            var data = error.response.data;
            if(typeof data.exception !== 'undefined'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                this.onData(obj);
                return;
            }
            this.onData(data);
        });
    }
    handleTypeAtendente = value => {
        var state = this.state;
        state.tipo_atendente = value;
        this.setState({ ...state });
    }
    
    handleDepartamento = value => {
        var state = this.state;
        state.departamento = value;
        this.setState({ ...state });
    }
    valid_fields = () => {
        var invalids = 0;

        if(this.state.nome_grupo.length < 2){
            invalids++;
            this.setState({
                nome_grupo_invalid: true
            })
        }
        else{
            this.setState({
                nome_grupo_invalid: false
            })
        }

        if(this.state.descricao_grupo.length < 2){
            invalids++;
            this.setState({
                descricao_grupo_invalid: true
            })
        }
        else{
            this.setState({
                descricao_grupo_invalid: false
            })
        }

        if(this.state.tipo_atendente === null){
            invalids++;
            this.setState({
                tipo_atendente_invalid: true
            });
        }
        else{
            this.setState({
                tipo_atendente_invalid: false
            });

            switch(this.state.tipo_atendente.value){
                case 'varios': {
                    if(this.state.add_atendentes.length > 0){
                        this.setState({
                            add_atendentes_invalid: false
                        });
                    }
                    else{
                        invalids++;
                        this.setState({
                            add_atendentes_invalid: true
                        });
                    }
                    break;
                }
                case 'um_atendente' : {
                    if(this.state.atendente !== null){
                        this.setState({
                            atendente_invalid: false
                        });
                    }
                    else{
                        invalids++;
                        this.setState({
                            atendente_invalid: true
                        });
                    }
                    break;
                }
                case 'departamento' : {
                    if(this.state.departamento !== null){
                        this.setState({
                            departamento_invalid: false
                        });
                    }
                    else{
                        invalids++;
                        this.setState({
                            departamento_invalid: true
                        });
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        }

        return invalids
    }
    loadOptions = (inputValue, callback) => {
        var url = ENDPOINT + "chat-atendimento/consultar-operadores?search=" + inputValue;
        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p;
            data.forEach((p, i) => {
                /* data[i].label = <></>;
                data[i].value = p.flag; */
                
                data[i].value = p.id;
                data[i].label = <div style={{display: 'flex'}}>
                    <ReactRoundedImage
                        image={p['avatar_foto'] !== null ? p['avatar_foto'] : ''}
                        roundedColor="#8dd199"
                        roundedSize="4"
                        imageWidth="25"
                        imageHeight="25"/> 
                        <p style={{display: 'flex', margin: 0}}>
                            &nbsp;
                            {p.name}
                        </p>
                </div>;
            });

            this.state.atendentes.forEach((p, i) => {
                data.forEach((p2, i2) => {
                    /* eslint eqeqeq: 0 */
                    if(p2.value == p.value){
                        data.splice(i2, 1);
                    }
                });
            });

            callback(this.filter(inputValue, p));
        });
    }

    filter = (inputValue, data) => {
        return data.filter(i =>
            i.name.toLowerCase().includes(inputValue.toLowerCase())
        );
    }

    onClose() {
        this.setState({
            nome_grupo: '',
            nome_grupo_invalid: false,
            descricao_grupo: '',
            descricao_grupo_invalid: false,
            atendente: null,
            atendente_invalid: false,
            departamento: null,
            departamento_invalid: false,
            tipo_atendente: null,
            tipo_atendente_invalid: false,
            inputValue: '',
            atendentes: [],
            add_atendentes: [],
            add_atendentes_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose(false);
    }

    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }
    addAtendentes = (new_a) => {
        var list = this.state.add_atendentes;
        var existe = false;
        list.forEach(p => {
            if(p.id == new_a.id){
                existe = true
            }
        });

        if(existe == false){
            list.push(new_a);
        }

        this.setState({add_atendentes: list})
    }

    removeAtendentes = (selected) => {
        var list = this.state.add_atendentes;
        list.forEach((p, key) => {
            if(p.id == selected.id){
                list.splice(key, 1);
            }
        });

        this.setState({add_atendentes: list})
    }
    
    handleInputChangeSelect = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }

    render(){
        return(
            <Modal isOpen={this.props.visible}>
                <ModalBody hidden={this.state.loading}>
                        <div style={{ display: 'inline-flex' }}>
                            <MdGroup style={{width: '2rem', height: '2rem'}}/>&nbsp;<h4>Criar Grupo</h4>
                        </div>
                        <hr/>
                        <Form>
                            <FormGroup>
                                <InputText 
                                    placeholder="Nome do grupo"
                                    name="nome_grupo"
                                    type="text"
                                    invalid={this.state.nome_grupo_invalid}
                                    value={this.state.nome_grupo}
                                    onChange={this.handleChange}
                                />
                                <FormFeedback>Insira o nome</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <InputText 
                                    placeholder="Descrição do grupo"
                                    name="descricao_grupo"
                                    type="text"
                                    invalid={this.state.descricao_grupo_invalid}
                                    value={this.state.descricao_grupo}
                                    onChange={this.handleChange} 
                                />
                                <FormFeedback>Insira a descrição</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Select 
                                    placeholder="Tipo de atendentes a selecionar"
                                    onChange={this.handleTypeAtendente}
                                    isSearchable={false}
                                    value={this.state.tipo_atendente}
                                    options={users_grupos} />
                                
                                <Input hidden invalid={this.state.tipo_atendente_invalid}/>
                                <FormFeedback>É necessario especificar o tipo de usuários a serem selecionados</FormFeedback>
                            </FormGroup>
                            <FormGroup hidden={this.state.tipo_atendente !== null ? (this.state.tipo_atendente.value === 'um_atendente' ? false : true) : true}>
                                <Label>Selecione o Atendentes</Label>
                                <AsyncSelect
                                    style={{width: '100%'}}           
                                    cacheOptions
                                    loadOptions={this.loadOptions}
                                    defaultOptions
                                    placeholder="Atendentes"
                                    onChange={(atendente) => this.setState({atendente})}
                                    onInputChange={this.handleInputChangeSelect}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            boxShadow: "none"
                                        })
                                    }}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 0
                                    })}
                                />
                                <Input hidden invalid={this.state.atendente_invalid}/>
                                <FormFeedback>É necessario selecionar um atendente</FormFeedback>
                            </FormGroup>
                            <FormGroup hidden={this.state.tipo_atendente !== null ? (this.state.tipo_atendente.value === 'varios' ? false : true) : true}>
                                <Label>Adicione o atendente</Label>
                                <AsyncSelect
                                    style={{width: '100%'}}           
                                    cacheOptions
                                    loadOptions={this.loadOptions}
                                    defaultOptions
                                    placeholder="Atendentes"
                                    onChange={(new_a) => this.addAtendentes(new_a)}
                                    onInputChange={this.handleInputChangeSelect}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            boxShadow: "none"
                                        })
                                    }}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 0
                                    })}
                                />
                                <br/>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            {this.state.add_atendentes.map((p, index) => {
                                                return <Col md={4}>
                                                    <center>
                                                        <ReactRoundedImage
                                                            image={p.avatar_foto}
                                                            roundedColor="#8dd199"
                                                            roundedSize="4"
                                                            imageWidth="40"
                                                            imageHeight="40"/> 
                                                        <h6>{p.name}</h6>
                                                        <Button 
                                                            outline 
                                                            onClick={() => this.removeAtendentes(p)} 
                                                            color="danger">Remover</Button>
                                                    </center>
                                                </Col>
                                            })}
                                            <center>{this.state.add_atendentes.length === 0 ? 'Nenhum atendente selecionado' : <></>}</center>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Input hidden invalid={this.state.add_atendentes_invalid}/>
                                <FormFeedback>É necessario selecionar os atendente</FormFeedback>
                            </FormGroup>
                            <FormGroup hidden={this.state.tipo_atendente !== null ? (this.state.tipo_atendente.value === 'departamento' ? false : true) : true}>
                                <Label>Selecione o Departamento</Label>
                                <Select 
                                    placeholder="Departamentos"
                                    options={this.state.departamentos}
                                    onChange={this.handleDepartamento}
                                    isSearchable={false}
                                    value={this.state.departamento} />
                                <Input hidden invalid={this.state.departamento_invalid}/>
                                <FormFeedback>É necessario selecionar o departamento</FormFeedback>
                            </FormGroup>
                        </Form>
                </ModalBody>
                <ModalBody
                    style={{
                        minHeight: '114px',
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                    />

                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose(false)}>Fechar</Button>
                    <Button color="success" onClick={() => this.onRegister()}>Salvar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default CreateGroupModal;