import React, {Component} from 'react';
import { Card, CardBody } from "reactstrap";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getRows, getRowsPerPage } from '../../components/Tables/TableUsers/propsUsersPreVenda';
import PropagateLoader from "react-spinners/PropagateLoader";
import { getPermissoes, getToken, getUser } from '../../auth/set_services';
import TableUsersDocumentos from '../../components/Tables/TableUsers/TableUsersDocumentos';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ENDPOINT } from '../../auth/endpoint';
import ModalDialog from '../../components/Modals/ReturnsApiModals/ModalDialog';
import moment from 'moment';


class ClientesPedidos extends Component{
    constructor(props){
        super(props);
        this.state = {
            show_activity: false,
            show_filter: false,
            user_change: null,
            search: '',
            loading_table: true,
            edit_id: -1,
            edit_user: null,
            rowData: [],
            cpf_current: null,
            cnpj_current: null,
            phone_current: null,
            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            status_user: '',
            type_user: '',
            totalPage: 0,
            activePage: 1,
            is_cnpj: false,
            document: '',
            feito_por_pedido: 1,
            visible_taggy_add: false,
            filter: false,
            
            delete_id: -1,
            error_message: '',
            success_message: '',
            loading: false,
            visible_delete: false,
        }
        this.onPageChange = this.onPageChange.bind(this);
        this.callbackRows = this.callbackRows.bind(this);
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }

    onFilter = (value) => {
        var state = this.state;
        state.filter = value;
        state.rowData = [];
        state.loading_table = true;

        this.setState({ ...state });
        getRowsPerPage(1, state, this.callbackRows, true);
    }

    filterShow(){
        this.setState({
            show_filter: !this.state.show_filter
        })
    }
    handleChange = (event) => {
        var state = this.state;
        let name = event.target.name;
        let value = event.target.value;

        state[name] = value;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});
        
        getRowsPerPage(1, state, this.callbackRows, true);
    }

    componentDidMount(){
        getRows(this.state, this.callbackRows.bind(this), true);
    }
    
    
    callbackRows(rowData, totalPage){
        this.setState({rowData, totalPage, loading_table: false})
    }
    onStatusChange(status_user){
        var state = this.state;
        state.status_user = status_user
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state})
        getRows(state, this.callbackRows, true);
    }

    onPageChange = (activePage) => {
        this.setState({activePage, loading_table: true, rowData: []})
        getRowsPerPage(activePage, this.state, this.callbackRows.bind(this), true);
    }
    onShowDelete(delete_id){
        console.log(delete_id)
        this.setState({
            delete_id,
            visible_delete: true
        })
    }
    
    onClose(){
        this.setState({
            delete_id: -1,
            error_message: '',
            success_message: '',
            loading: false,
            visible_delete: false,
        })
    }
    onError(){
        this.setState({
            loading: false,
            error_message: ''
        })
    }

    onDelete = () => {
        this.setState({loading: true})
        var url = ENDPOINT+"user/delete/"+this.state.delete_id;
        axios.delete(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.onClose();
            getRows(this.state, this.callbackRows.bind(this), true);
            return data;
        })
        .catch((error) => {
            var response = '';
            var obj;
            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            if(data.exception){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            this.onData(data);
            return data;
        });
    }

    onData(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            });
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }

    render(){
        return(
            <div>
                <h3>Pedidos</h3>
                <hr/>
                <Card hidden={!getUser().permissao_gestor_aqpago}>
                    <TableUsersDocumentos
                        isSearch={this.handleChange}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        data={this.state.rowData}
                        is_cnpj={this.state.is_cnpj}
                        status_user={this.state.status_user}
                        onDelete={this.onShowDelete.bind(this)}
                        onStatusChange={this.onStatusChange.bind(this)}
                        type_user={this.state.type_user}
                        show_filter={this.state.show_filter}
                        filterShow={this.filterShow.bind(this)}
                        filter={this.state.filter}
                        onFilter={this.onFilter}
                        onPageChange={this.onPageChange}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhum usuário encontrado
                            </div></CardBody></Card>
                        }
                    />
                </Card>
                <br/>
                <ModalDialog
                    visible={this.state.visible_delete}
                    onError={this.onError.bind(this)}
                    message="Deseja remover esse cliente ?"
                    onClose={() => this.onClose()}
                    error_message={this.state.error_message}
                    loading={this.state.loading}
                    onSave={this.onDelete.bind(this)}
                    success_message={this.state.success_message}
                />
                <div hidden={getUser().permissao_gestor_aqpago}>
                  <Card>
                      <CardBody>
                          <p>Você não tem permissões para acessar essa função</p>
                      </CardBody>
                  </Card>
                </div>
            </div>
        )
    }
}

export default ClientesPedidos;