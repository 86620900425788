import React from 'react';
import { HiViewGrid } from 'react-icons/hi';
import { Button, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getPermissoes, getToken } from '../../../auth/set_services';
import ReactTooltip from 'react-tooltip';
import 'react-dropdown/style.css';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import moment from 'moment';

export const columns = (newDealRoute) => [
  {
    name: '',
    selector: 'buttons',
    width: '5%',
    cell: row => <Row>
      <Button data-tip="Resumo da passagem"
        outline
        style={{ padding: '0px', marginRight: '3px', border: 0 }}
        color="primary" 
        onClick={() => {
            return newDealRoute(row)
        }}>
        <HiViewGrid style={{ padding: '2px', width: '23px' }} />
      </Button>
      <ReactTooltip />
    </Row>
  },
  {
    name: "Empresa",
    selector: "business",
    sortable: false,
    cell: row => {
        if (row?.search?.url_banner) {
            return <img src={row?.search?.url_banner} style={{width: '200px', margin: '1rem'}} />
        } else {
            return row.business;
        }
    }
  },
  {
    name: "Origem",
    selector: "origin",
    sortable: false,
    cell: row => row.origin.city,
  },
  {
    name: "Destino",
    selector: "destiny",
    sortable: false,
    cell: row => row.destiny.city,
  },
  {
    name: "Data",
    selector: "date_accommodation",
    sortable: false,
    cell: row => moment(row.date_accommodation).format('DD/MM/YYYY')
  },
  {
    name: "Desconto",
    selector: "discount",
    sortable: false,
    cell: row => {
        if (row?.type == 'p') {
            return row.value + '%';
        } else {
            parseFloat(row?.search?.value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
        }
    }
  },
  {
    name: "Valor",
    selector: "amount",
    sortable: false,
    cell: row => parseFloat(row?.search?.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
  },
  {
    name: "Seguro",
    selector: "optional_insurance",
    sortable: false,
    cell: row => parseFloat(row?.search?.optional_insurance).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
  },
];

export const getRows = (state, callback) => {
    let url = ENDPOINT + "querobus/deal/list?page=" + state.activePage + "&search=" + state.search;

    console.log(getToken())

    if (state.origin?.value) {
        url += `&origin_id=${state.origin?.value}`;
    }

    if (state.destiny?.value) {
        url += `&destiny_id=${state.destiny?.value}`;
    }

    if (state.date_accommodation) {
        url += `&date_accommodation=${state.date_accommodation}`;
    }

    let myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());
  
    let myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };
    
    fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        callback(p.data, p.last_page);
    })
    .catch(() => {
        toast.error('Ocorreu um erro no servidor!');
    })
}

export const getRowsPerPage = (page, state, callback) => {
    let url = ENDPOINT + "querobus/deal/list?page=" + state.activePage + "&search=" + state.search;
    console.log(getToken())
    if (state.origin?.value) {
        url += `&origin_id=${state.origin?.value}`;
    }

    if (state.destiny?.value) {
        url += `&destiny_id=${state.destiny?.value}`;
    }

    if (state.date_accommodation) {
        url += `&date_accommodation=${state.date_accommodation}`;
    }

    let myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());
    
    let myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };

    fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        callback(p.going_booking, p.last_page);
    })
    .catch(() => {
        toast.error('Ocorreu um erro no servidor!');
    })
}