import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Form, FormGroup, FormFeedback, Card, CardBody } from 'reactstrap';
import BoxSuccess from '../../components/BoxSuccess/BoxSuccess';
import BoxError from '../../components/BoxError/BoxError';
import PropagateLoader from "react-spinners/PropagateLoader";
import { resetPassword } from '../../auth/services';
import { InputText } from '../../components/InputText/InputText';
import { ButtonDefault } from '../../components/Button/ButtonDefault';
import { ButtonOutline } from '../../components/Button/ButtonOutline';
import { LineAuth } from '../../components/LineAuth/LineAuth';

class Login extends Component{
    constructor(props){
        super(props);
        this.state = {
            email: '',
            email_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        }
        this.onRegister = this.onRegister.bind(this);
    }
    getPathUrl = () =>{
        return window.location.pathname;
    }
    closedError = () => {
        this.setState({
            loading: false,
            error_message: ''
        })
    }
    validateFields(){
        var invalid = 0;

        // eslint-disable-next-line
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re.test(this.state.email)){
            this.setState({email_invalid: false})
        }
        else{
            invalid++;
            this.setState({email_invalid: true})
        }

        return invalid;

    }
    onRegister() {
        if(this.validateFields() === 0){
            this.setState({loading: true});
            resetPassword(this.state.email, this.onData.bind(this));
        }
    }
    onData(data){
        if(data.success === true){
            this.setState({
                loading: false,
                success_message: data.message
            })
        }
        else{
            this.setState({
                loading: false,
                error_message: data.errors
            })
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }
    onEnter = (evt) => {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        if(key === 13){
            this.onRegister();
        }
     }
    render(){
        return(
            <>
                {/* <Form hidden={this.state.loading} onSubmit={this.onEnter}>
                    <h4 style={{margin: '25px', textAlign: 'right'}}>Recuperar Senha</h4>
                    <FormGroup>
                        <InputText
                            name="email"
                            type="email"
                            placeholder="E-mail"
                            invalid={this.state.email_invalid}
                            value={this.state.email} 
                            onChange={this.handleChange}
                            onKeyPress={this.onEnter} />
                    </FormGroup>
                    <FormGroup>
                        <center>
                            <ButtonDefault color="success" style={{width: '95%'}} onClick={this.onRegister}>
                                Enviar
                            </ButtonDefault>
                            <LineAuth/>
                            <Row>
                                <Col></Col>
                                <Col>
                                    <Link to="login">
                                        <ButtonOutline href={"#"}>Fazer Login</ButtonOutline>
                                    </Link>
                                </Col>
                            </Row>
                        </center>
                    </FormGroup>
                </Form> */}
                <Form hidden={this.state.loading}>
                    <h4 style={{margin: '25px', textAlign: 'right'}}>Recuperar Senha</h4>
                    <FormGroup>
                        <InputText 
                            name="email"
                            type="email"
                            placeholder="E-mail"
                            invalid={this.state.email_invalid}
                            value={this.state.email} 
                            onChange={this.handleChange} 
                            onKeyPress={this.onEnter}
                        />
                        <FormFeedback>E-mail Inválido</FormFeedback>
                        <InputText 
                            hidden
                        />
                    </FormGroup>
                    <FormGroup>
                        <center>
                            <ButtonDefault color="success" style={{width: '95%'}} onClick={this.onRegister}>
                                Enviar
                            </ButtonDefault>
                            <LineAuth/>
                            <Row>
                                <Col></Col>
                                <Col>
                                    <Link to="login">
                                        <ButtonOutline href={"#"}>Fazer Login</ButtonOutline>
                                    </Link>
                                </Col>
                            </Row>
                        </center>
                    </FormGroup>
                </Form>
                <Card hidden={!this.state.loading} style={{border: 'none', padding: '10%'}}>
                    <CardBody>
                        <center>
                            <div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div>
                        </center>
                    </CardBody>
                </Card>
                <BoxError
                    visible={this.state.error_message.length > 0 ? true : false}
                    title="Ocorreu um erro"
                    message={this.state.error_message}
                    onChange={this.closedError.bind(this)}
                />
                <BoxSuccess
                    visible={this.state.success_message.length > 0 ? true : false}
                    title="Recuperação realizada com sucesso"
                    message={<p>Foi enviado à seu e-mail um link para recuperação de senha. <br/> Se não receber, fale com o Gestor de seu Setor.</p>}
                    onChange={() => { window.location.href = "login" }}
                />
            </>
        );
    }
}

export default Login;
