import axios from "axios";
import React from "react";
import Select from "react-select";
import { Button, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import { InputMasked } from "../../InputText/InputMasked";
import { InputText } from "../../InputText/InputText";
import ModelReturn from "../ReturnsApiModals/ModelReturn";

const maquininhas_list = [
    {   value: 'MP5', label: 'MP5'  },
    {   value: 'D195', label: 'D195'  },
    {   value: 'MP35P', label: 'MP35P'  },
    {   value: 'S920', label: 'S920'  },
    {   value: 'C680', label: 'C680'  },
    {   value: 'D200', label: 'D200'  },
];

class ModalTitularidade extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            transferir_tipo: null,
            transferir_cpf_cnpj: '',
            transferir_cpf_cnpj_invalid: false,
            modelo_maquininha: null,
            modelo_maquininha_invalid: false,
            serial_maquininha: '',
            serial_maquininha_invalid: false,
            motivo: '',
            motivo_invalid: false,
            transferido_tipo: null,
            transferido_cpf_cnpj: '',
            transferido_cpf_cnpj_invalid: false,
            is_titularidade: null,
            loading: false,
            error_message: '',
            success_message: '',
        }
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }

    onClosed = () => {
        this.setState({
            transferir_tipo: null,
            transferir_cpf_cnpj: '',
            transferir_cpf_cnpj_invalid: false,
            modelo_maquininha: null,
            modelo_maquininha_invalid: false,
            serial_maquininha: '',
            serial_maquininha_invalid: false,
            motivo: '',
            motivo_invalid: false,
            transferido_tipo: null,
            transferido_cpf_cnpj: '',
            transferido_cpf_cnpj_invalid: false,
            is_titularidade: null,
            loading: false,
            error_message: '',
            success_message: '',
        });
        this.props.onClosed(false);
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }
    onData = (data) => {
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onRegister = () => {
        if(this.validFields() === true){
            this.setState({loading: true});
            this.store();
        }
    }
    validFields = () => {
        var invalids = 0;

        if(this.state.transferir_tipo !== null){
            if(this.state.transferir_tipo === false &&
                this.state.transferir_cpf_cnpj.replace(/([^\d])+/gim, '').length === 11){
                this.setState({transferir_cpf_cnpj_invalid: false})
            }
            else if(this.state.transferir_tipo === true &&
                this.state.transferir_cpf_cnpj.replace(/([^\d])+/gim, '').length === 14){
                this.setState({transferir_cpf_cnpj_invalid: false})
            }
            else{
                invalids++;
                this.setState({transferir_cpf_cnpj_invalid: true})
            }
        }
        else{
            invalids++;
            this.setState({transferir_cpf_cnpj_invalid: true})
        }

        if(this.state.transferido_tipo !== null){
            if(this.state.transferido_tipo === false &&
                this.state.transferido_cpf_cnpj.replace(/([^\d])+/gim, '').length === 11){
                this.setState({transferido_cpf_cnpj_invalid: false})
            }
            else if(this.state.transferido_tipo === true &&
                this.state.transferido_cpf_cnpj.replace(/([^\d])+/gim, '').length === 14){
                this.setState({transferido_cpf_cnpj_invalid: false})
            }
            else{
                invalids++;
                this.setState({transferido_cpf_cnpj_invalid: true})
            }
        }
        else{
            invalids++;
            this.setState({transferido_cpf_cnpj_invalid: true})
        }

        if(this.state.modelo_maquininha !== null){
            this.setState({modelo_maquininha_invalid: false})
        }
        else{
            invalids++;
            this.setState({modelo_maquininha_invalid: true})
        }

        if(this.state.serial_maquininha.length > 0){
            this.setState({serial_maquininha_invalid: false})
        }
        else{
            invalids++;
            this.setState({serial_maquininha_invalid: true})
        }

        if(this.state.motivo.length > 0){
            this.setState({motivo_invalid: false})
        }
        else{
            invalids++;
            this.setState({motivo_invalid: true})
        }

        console.log(invalids);
        return invalids === 0 ? true : false;

    }

    store = () => {
        var url = ENDPOINT+"titularidade/store";

        var formData = new FormData();
        formData.append('cpf_cnpj_origem', this.state.transferir_cpf_cnpj.replace(/([^\d])+/gim, ''));
        formData.append('cpf_cnpj_transferido', this.state.transferido_cpf_cnpj.replace(/([^\d])+/gim, ''));
        formData.append('modelo_maquininha', this.state.modelo_maquininha.value);
        formData.append('serial_maquininha', this.state.serial_maquininha);
        formData.append('motivo_transferencia', this.state.motivo);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            this.onData(data);
            return data;
        })
        .catch((error) => {
            var response;
            var obj;

            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                this.onData(obj);
                return;
            }
            var data = error.response.data;
            if(typeof data.exception !== 'undefined'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                this.onData(obj);
                return;
            }
            this.onData(data);
        });
    }
    render(){
        return(
            <Modal isOpen={this.props.visible} size="lg">
                <ModalHeader>Transferir Titularidade</ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <Form>
                        <FormGroup>
                            <input 
                                type="radio" 
                                checked={this.state.is_titularidade === false} 
                                onChange={() => this.setState({ 
                                    is_titularidade: false, 
                                    transferido_cpf_cnpj: '',
                                    transferido_tipo: null 
                                })}/>&nbsp;Transferir para outro titular <br/>
                            <input 
                                type="radio" 
                                checked={this.state.is_titularidade} 
                                onChange={() => {
                                    this.setState({
                                        transferido_tipo: true,
                                        transferido_cpf_cnpj: '',
                                        is_titularidade: true
                                    })
                                }}/>&nbsp;Migrar para PJ
                        </FormGroup>
                        <div hidden={this.state.is_titularidade === null}>
                            <FormGroup>
                                <Label>Informe o CPF ou CNPJ do estabelecimento/vendedor credenciado</Label>
                                <br/>
                                <input 
                                    type="radio" 
                                    checked={this.state.transferir_tipo === false}
                                    onChange={(e) => this.setState({transferir_tipo: false})}
                                /> CPF &nbsp;
                                <input 
                                    type="radio" 
                                    checked={this.state.transferir_tipo === true}
                                    onChange={(e) => this.setState({transferir_tipo: true})}
                                /> CNPJ&nbsp;&nbsp;
                                
                                {this.state.transferir_tipo !== null ?
                                    <InputMasked
                                        name="transferir_cpf_cnpj"
                                        mask={this.state.transferir_tipo === true ? "99.999.999/9999-99" : "999.999.999-99"}
                                        placeholder={this.state.transferir_tipo === true ? "CNPJ do titular atual" : "CPF do titular atual"}
                                        invalid={this.state.transferir_cpf_cnpj_invalid}
                                        value={this.state.transferir_cpf_cnpj}
                                        type="text"
                                        onChange={this.handleChange}
                                    />
                                :null}
                                <Input hidden invalid={this.state.transferir_cpf_cnpj_invalid}/>
                                <FormFeedback>O {this.state.transferir_tipo === true ? 'CNPJ' : 'CPF'}
                                &nbsp;é obrigatório e deve ser preenchido corretamente</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>Modelo da maquininha</Label>
                                <Select
                                    options={maquininhas_list}
                                    placeholder="Selecione a Maquininha"
                                    value={this.state.modelo_maquininha}
                                    onChange={(modelo_maquininha) => this.setState({modelo_maquininha})}
                                />
                                <Input hidden invalid={this.state.modelo_maquininha_invalid}/>
                                <FormFeedback>Selecione o modelo da maquininha</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>Serial da maquininha</Label>
                                <InputText
                                    name="serial_maquininha"
                                    type="text" 
                                    placeholder="Serial" 
                                    value={this.state.serial_maquininha}
                                    invalid={this.state.serial_maquininha_invalid}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Motivo da transferencia</Label>
                                <Input
                                    name="motivo"
                                    type="textarea" 
                                    placeholder="Motivo da transferencia" 
                                    value={this.state.motivo}
                                    invalid={this.state.motivo_invalid}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Dados do Novo Titular</Label>
                                <br/>
                                <div hidden={this.state.is_titularidade === true}>
                                    <input 
                                        type="radio" 
                                        checked={this.state.transferido_tipo === false}
                                        onChange={(e) => this.setState({transferido_tipo: false})}
                                    /> CPF &nbsp;
                                    <input 
                                        type="radio" 
                                        checked={this.state.transferido_tipo === true}
                                        onChange={(e) => this.setState({transferido_tipo: true})}
                                    /> CNPJ&nbsp;&nbsp;
                                </div>
                                {this.state.transferido_tipo !== null ?
                                    <InputMasked
                                        name="transferido_cpf_cnpj"
                                        mask={this.state.transferido_tipo === true ? "99.999.999/9999-99" : "999.999.999-99"}
                                        placeholder={this.state.transferido_tipo === true ? "CNPJ a ser transferido" : "CPF a ser transferido"}
                                        invalid={this.state.transferido_cpf_cnpj_invalid}
                                        value={this.state.transferido_cpf_cnpj}
                                        type="text"
                                        onChange={this.handleChange}
                                    />
                                :null}
                                <Input hidden invalid={this.state.transferido_cpf_cnpj_invalid}/>
                                <FormFeedback>O {this.state.transferido_tipo === true ? 'CNPJ' : 'CPF'}
                                &nbsp;é obrigatório e deve ser preenchido corretamente, também deve ser diferente do CPF/CNPJ do titular</FormFeedback>
                                {/* <input 
                                    type="checkbox" 
                                    checked={this.state.transferido_tipo}
                                    onChange={(e) => this.setState({transferido_tipo: e.target.checked})}
                                /> Mudar para CNPJ */}
                            </FormGroup>
                        </div>
                    </Form>
                </ModalBody>
                <ModalBody
                    style={{
                        minHeight: '114px',
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClosed}
                        onError={this.onError.bind(this)}
                    />

                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={this.onClosed}>Cancelar</Button>
                    <Button color="success" onClick={() => this.onRegister()}>Salvar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ModalTitularidade;