import React, { Component } from "react";
import { Button, Card, CardBody, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import axios from "axios";
import { getToken } from "../../../auth/set_services";
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import { ENDPOINT } from "../../../auth/endpoint";

class SendEmailByTransacao extends Component{
    constructor(props){
        super(props);
        this.state = {
            mensagem_email: '',
            mensagem: '',
            mensagem_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        }
    }

    handleChangeEmail = (mensagem_email) => {
        this.setState({mensagem_email});
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }

    onData(data) {
        if (data.success === true) {
            this.setState({
                success_message: data.message
            });
        }
        else {
            this.setState({
                error_message: data.errors
            })
        }
    }

    store = () => {
        this.setState({loading: true});
        
        var url = ENDPOINT+"payout/enviar_email/"+this.props.id;

        var formData = new FormData();
        formData.append('mensagem', draftToHtml(convertToRaw(this.state.mensagem_email.getCurrentContent())));
        formData.append('email', this.props.email);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.onData(data);
        })
        .catch((error) => {
            var data = error.response.data;
            this.onData(data);
            return data;
        });
    }
    onClose = () => {
        this.setState({
            mensagem_email: '',
            mensagem: '',
            mensagem_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose(false);
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    render(){
        return(
            <Modal isOpen={this.props.visible} size="lg">
                <ModalHeader>Enviar E-mail</ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <Card style={{minHeight: 250}}>
                        <Editor
                            initialEditorState={this.state.mensagem_email}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={this.handleChangeEmail}
                            editorStyle={{
                                fontSize: 12,
                                fontFamily: "Arial"
                            }}
                        />
                        {/* <Input
                            type="textarea"
                            placeholder="Mensagem"
                            name="mensagem"
                            onChange={this.handleChange}
                            maxLength={160}
                            value={this.state.mensagem} 
                            invalid={this.state.mensagem_invalid}
                            style={{ height: "250px", resize: 'none' }}
                        /> */}
                    </Card>
                </ModalBody>
                <ModalBody
                    style={{
                        minHeight: '114px',
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                    />

                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose()}>Cancelar</Button>
                    <Button color="success" onClick={() => this.store()}>Enviar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default SendEmailByTransacao;