import React from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, Col, Row, Collapse, Button, Label, Input } from 'reactstrap';
import Pagination from '../Pagination';
import { columns } from './propsPassagens';
import Select from 'react-select';

var CurrencyInput = require('react-currency-masked-input');

const formas_pagamentos = [
    { value: 'sao_paulo_tiete_sp', label: 'São Paulo (Tiete)' },
    { value: 'curitiba_pr', label: 'Curitiba (Pr)' },
];

const TablePassagens = (props) => {
    return (
        <>
            <Card>
                <CardBody>
                    <Row style={{marginBottom: '1rem'}}>
                        <Col md={6}>
                            <label htmlFor='origin'>Origem de partida</label>
                            <Select
                                placeholder='Origem de saída'
                                options={props.cities_origin}
                                isClearable={true}
                                value={props.state.origin}
                                onChange={(value) => props.onChangeSelect('origin', value)}
                                />
                        </Col>
                        <Col md={6}>
                            <label htmlFor='destiny'>Destino de chegada</label>
                            <Select
                                placeholder='Destino de chegada'
                                options={props.cities_origin}
                                isClearable={true}
                                value={props.state.destiny}
                                onChange={(value) => props.onChangeSelect('destiny', value)}
                                
                                />
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '1rem'}}>
                        <Col md={3}>
                            <label htmlFor='date_accommodation'>Data de partida</label>
                            <Input
                                name="date_accommodation"
                                type="date"
                                value={props.date_accommodation}
                                onChange={(event) => props.isSearch(event)} />
                        </Col>
                    </Row>
                    <hr style={{ marginBottom: '4px' }} />
                    <DataTable
                        noHeader={true}
                        columns={columns(props.newDeal, props.newOriginDestiny, props.state.type_insert)}
                        button={true}
                        responsive
                        noDataComponent={props.noDataComponent}
                        data={props.data}
                        theme="default"
                    />
                </CardBody>
                <CardFooter style={{ textAlign: 'center' }}>
                    <Pagination
                        onPageChange={props.onPageChange}
                        activePage={props.activePage}
                        totalPage={props.totalPage} />
                </CardFooter>
            </Card>
        </>
    )
}

export default TablePassagens;