import { Component } from "react";
import { MdSearch } from "react-icons/md";
import Select from "react-select";
import { Button, Col, Modal, Input, ModalBody, ModalFooter, ModalHeader, Row, FormGroup, FormFeedback } from "reactstrap";
import { InputMasked } from "../../InputText/InputMasked";
import { InputText } from "../../InputText/InputText";
import axios from 'axios';
import { getToken } from '../../../auth/set_services';
import { ENDPOINT } from "../../../auth/endpoint";
import NumberFormat from "react-number-format";
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import ClipLoader from 'react-spinners/ClipLoader';

const getBusiness = (cnpj, onData) => {
    var url = ENDPOINT+"user/search_cnpj?cnpj="+cnpj.replace(/([^\d])+/gim, '');
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        console.log(error)
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });
}

const getUser = (cpf, onData) => {
    var url = ENDPOINT+"user/search_cpf_new?cpf="+cpf.replace(/([^\d])+/gim, '');
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });

}

//retirado do site: http://www.receita.fazenda.gov.br/aplicacoes/atcta/cpf/funcoes.js
function validarCPF(strCPF) {
    strCPF = strCPF.replace(/[^0-9]/g,'');
    
    var Soma;
    var Resto;
    Soma = 0;   
    //strCPF  = RetiraCaracteresInvalidos(strCPF,11);
    if (strCPF == "00000000000")
        return false;
    for (var i=1; i<=9; i++)
    Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i); 
    Resto = (Soma * 10) % 11;
    if ((Resto == 10) || (Resto == 11)) 
    Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10)) )
    return false;
    Soma = 0;
    for (i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;
    if ((Resto == 10) || (Resto == 11)) 
    Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11) ) )
        return false;
    return true;
}
class AQPassoModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            isCnpj: false,
            step: 0,
            socios: [],
            request: {},
            type: '',
            type_invalid: false,
            email: '',
            email_invalid: false,
            data_nascimento: null,
            data_nascimento_invalid: false,
            cpf_pessoal: '',
            cpf_pessoal_invalid: false,
            telefone: '',
            telefone_invalid: false,
            cpf_pessoal: '', 
            cpf_pessoal_invalid: false,
            cep: '',
            logradouro: '',
            logradouro_invalid: false,
            numero: '',
            numero_invalid: false,
            complemento: '',
            complemento_invalid: false,
            bairro: '',
            bairro_invalid: false,
            cidade: '',
            cidade_invalid: false,
            uf: '',
            uf_invalid: false,
            socio: null,
            socio_invalid: false,
            document: '',
            document_invalid: false,
            document_loading: false,
            loading: false,
            error_message: '',
            success_message: '',
        }
    }
    componentDidMount(){

    }
    handleSelect = (type) => {
        this.setState({type})
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        
        this.setState({[name]: value});

        if(name === 'cep'){
            if(value.replace(/([^\d])+/gim, '').length > 7){
                this.getCep(value)
            }
        }
    }
    onSearch(){
        this.setState({document_loading: true})
        if(this.state.isCnpj === true){
            getBusiness(this.state.document, this.onCNPJ.bind(this));
        }
        else{
            getUser(this.state.document, this.onCPF.bind(this));
        }
    }
    onCNPJ(data){
        if(data.success === true){
            var socios = [];
            var socio = null;
            if(data.data.socios){
                data.data.socios.forEach(e => {
                    socios.push({
                        value: e.nome,
                        label: e.nome
                    })
                });
    
                socio = {
                    value: data.data.socios[0].nome,
                    label: data.data.socios[0].nome
                }
            }
            else{
                socios.push({
                    value: data.data.nomeEmpresarial,
                    label: data.data.nomeEmpresarial
                })
    
                socio = {
                    value: data.data.nomeEmpresarial,
                    label: data.data.nomeEmpresarial
                }
            }
            this.setState({
                document_invalid: false,
                document_loading: false,
                step: 2,
                request: data.data,
                email: '',
                socios,
                socio
            });
        }
        else{
            this.setState({
                document_invalid: true,
                document_loading: false,
                step: 0,
                request: {},
                email: '',
                socios: [],
                socio: null
            });
        }
    }
    onCPF(data){
        if(data.success === true){
            this.setState({
                document_invalid: false,
                document_loading: false,
                step: 1,
                request: data.data,
                email: '',
                socios: [],
                socio: null
            });
        }
        else{
            this.setState({
                document_invalid: true,
                document_loading: false,
                step: 0,
                request: {},
                email: '',
                socios: [],
                socio: null
            });
        }
    }
    getCep = (value) => {
        var url = ENDPOINT+'user/search_cep?cep='+value;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            if(data.success === true){
                this.setState({
                    logradouro: data.data.end,
                    complemento: data.data.complemento2,
                    uf: data.data.uf,
                    bairro: data.data.bairro,
                    cidade: data.data.cidade
                })
            }
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            this.onData(data);
            return data;
        });

    }
    storeUser = () => {
        var url = ENDPOINT+"user/store_user_aqpasso";

        var formData = new FormData();
        formData.append('document', this.state.request.ni.replace(/([^\d])+/gim, ''));
        if(this.state.step === 1){
            formData.append('telefone', this.state.telefone.replace(/([^\d])+/gim, ''));
        }
        else{
            formData.append('socio_name', this.state.socio_name);
            if(this.state.request.telefones){
                formData.append('telefone', this.state.request.telefones[0].ddd + ''+this.state.request.telefones[0].numero);
            }
            else{
                formData.append('telefone', this.state.telefone.replace(/([^\d])+/gim, ''));
            }
            formData.append('data_nascimento', this.state.data_nascimento);
            formData.append('cpf_pessoal', this.state.cpf_pessoal.replace(/([^\d])+/gim, ''));
        }

        formData.append('email', this.state.email);

        formData.append('cep', this.state.cep);
        formData.append('logradouro', this.state.logradouro);
        formData.append('numero', this.state.numero);
        formData.append('complemento', this.state.complemento);
        formData.append('bairro', this.state.bairro);
        formData.append('cidade', this.state.cidade);
        formData.append('uf', this.state.uf);
        formData.append('pais', 'Brasil');

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            this.onData(data);
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            this.onData(data);
            return data;
        });
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    validateFields = () => {
        var invalids = 0;
        if(this.state.step === 1){
            if(this.state.email.length > 0){
                this.setState({email_invalid: false})
            }
            else{
                invalids++;
                this.setState({email_invalid: true})
            }
            if(this.state.telefone.replace(/([^\d])+/gim, '').length > 9){
                this.setState({telefone_invalid: false})
            }
            else{
                invalids++;
                this.setState({telefone_invalid: true})
            }
    
    
            if(this.state.logradouro.length > 0){
                this.setState({logradouro_invalid: false})
            }
            else{
                invalids++;
                this.setState({logradouro_invalid: true})
            }
    
            if(this.state.numero.length > 0){
                this.setState({numero_invalid: false})
            }
            else{
                invalids++;
                this.setState({numero_invalid: true})
            }
    
            /* if(this.state.complemento.length > 0){
                this.setState({complemento_invalid: false})
            }
            else{
                invalids++;
                this.setState({complemento_invalid: true})
            } */
    
            if(this.state.bairro.length > 0){
                this.setState({bairro_invalid: false})
            }
            else{
                invalids++;
                this.setState({bairro_invalid: true})
            }
    
            if(this.state.cidade.length > 0){
                this.setState({cidade_invalid: false})
            }
            else{
                invalids++;
                this.setState({cidade_invalid: true})
            }
    
            if(this.state.uf.length > 0){
                this.setState({uf_invalid: false})
            }
            else{
                invalids++;
                this.setState({uf_invalid: true})
            }
        }
        else if(this.state.step === 2){
            if(!this.state.request.telefones){
                if(this.state.telefone.replace(/([^\d])+/gim, '').length > 9){
                    this.setState({telefone_invalid: false})
                }
                else{
                    invalids++;
                    this.setState({telefone_invalid: true})
                }
            }
            
            if(this.state.socio_name.length > 0){
                this.setState({socio_name_invalid: false})
            }
            else{
                invalids++;
                this.setState({socio_name_invalid: true})
            }
            
            if(validarCPF(this.state.cpf_pessoal) == true){
                this.setState({cpf_pessoal_invalid: false})
            }
            else{
                invalids++;
                this.setState({cpf_pessoal_invalid: true})
            }
            
            if(this.state.email.length > 0){
                this.setState({email_invalid: false})
            }
            else{
                invalids++;
                this.setState({email_invalid: true})
            }
            if(this.state.data_nascimento !== null){
                this.setState({data_nascimento_invalid: false})
            }
            else{
                invalids++;
                this.setState({data_nascimento_invalid: true})
            }
    
    
            if(this.state.logradouro.length > 0){
                this.setState({logradouro_invalid: false})
            }
            else{
                invalids++;
                this.setState({logradouro_invalid: true})
            }
    
            if(this.state.numero.length > 0){
                this.setState({numero_invalid: false})
            }
            else{
                invalids++;
                this.setState({numero_invalid: true})
            }
    
            /* if(this.state.complemento.length > 0){
                this.setState({complemento_invalid: false})
            }
            else{
                invalids++;
                this.setState({complemento_invalid: true})
            } */
    
            if(this.state.bairro.length > 0){
                this.setState({bairro_invalid: false})
            }
            else{
                invalids++;
                this.setState({bairro_invalid: true})
            }
    
            if(this.state.cidade.length > 0){
                this.setState({cidade_invalid: false})
            }
            else{
                invalids++;
                this.setState({cidade_invalid: true})
            }
    
            if(this.state.uf.length > 0){
                this.setState({uf_invalid: false})
            }
            else{
                invalids++;
                this.setState({uf_invalid: true})
            }
            
        }
        else{
            this.onSearch();
            invalids++
        }

        return invalids
    }
    onRegister = () => {
        if(this.validateFields() === 0){
            this.setState({loading: true});
            this.storeUser();
        }
    }
    onData = (data) => {
        
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onClose = () => {
        this.setState({
            isCnpj: false,
            step: 0,
            socios: [],
            request: {},
            type: '',
            type_invalid: false,
            email: '',
            email_invalid: false,
            telefone: '',
            telefone_invalid: false,
            socio_name: '',
            socio_name_invalid: false,
            cpf_pessoal: '', 
            cpf_pessoal_invalid: false,
            cep: '',
            logradouro: '',
            logradouro_invalid: false,
            numero: '',
            numero_invalid: false,
            complemento: '',
            complemento_invalid: false,
            bairro: '',
            bairro_invalid: false,
            cidade: '',
            cidade_invalid: false,
            uf: '',
            uf_invalid: false,
            socio: null,
            socio_invalid: false,
            document: '',
            document_invalid: false,
            document_loading: false,
            loading: false,
            error_message: '',
            success_message: '',
        });
        this.props.onClose(false);
    }

    render(){
        return <Modal isOpen={this.props.visible} size={"lg"}>
            <ModalHeader>Novo Cliente</ModalHeader>
            <ModalBody hidden={this.state.loading}>
                <FormGroup>
                    <Row>
                        <Col md={10} sm={10} xs={10}>
                            <InputMasked
                                name="document"
                                value={this.state.document}
                                mask={this.state.isCnpj === true ? "99.999.999/9999-99" : "999.999.999-99"}
                                placeholder={this.state.isCnpj === true ? "CNPJ" : "CPF"}
                                onChange={this.handleChange}
                            />
                        </Col>
                        <Col md={1} sm={1} xs={1}>
                            <Button color="info" onClick={() => this.onSearch()}>
                                {this.state.document_loading === true ?
                                    <ClipLoader color="#fff" size={20}/>:
                                    <MdSearch style={{ padding: 0 }}/>}
                            </Button>
                        </Col>
                    </Row>
                    <input 
                        type="checkbox" 
                        checked={this.state.isCnpj}
                        onChange={(e) => this.setState({isCnpj: e.target.checked})}
                    /> Mudar para CNPJ
                </FormGroup>
                {this.state.step === 1 ?
                    <div>
                        <Row>
                            <Col md={12}>
                                <h6><b>Nome: </b> {this.state.request.nome}</h6>
                                <h6><b>CPF: </b> 
                                    <NumberFormat 
                                        displayType={'text'}  
                                        format="###.###.###-##" 
                                        value={this.state.request.ni} /></h6>
                                <h6><b>Situação: </b> {this.state.request.situacao.descricao}</h6>
                                <h6><b>Data de Nascimento: </b> 
                                    <NumberFormat 
                                        displayType={'text'}  
                                        format="##/##/####" 
                                        value={this.state.request.nascimento} /></h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <h6><b>E-mail: </b> </h6>
                                    <InputText 
                                        name="email"
                                        value={this.state.email}
                                        placeholder="Digite o e-mail"
                                        invalid={this.state.email_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <h6><b>Telefone:</b> </h6>
                                    {/* <InputText placeholder="Telefone"/> */}
                                    <InputMasked
                                        mask="(99) 99999-9999"
                                        name="telefone"
                                        value={this.state.telefone}
                                        placeholder="Digite o telefone"
                                        invalid={this.state.telefone_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr/>
                        <b>Dados de endereço</b>
                        <br/>
                        <br/>
                        <FormGroup>
                            <h6><b>CEP: </b> </h6>
                            <InputMasked 
                                name="cep"
                                mask="99999-999"
                                value={this.state.cep}
                                placeholder="Digite o cep"
                                invalid={this.state.cep_invalid}
                                onChange={this.handleChange}/>
                        </FormGroup>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <h6><b>Logradouro: </b> </h6>
                                    <InputText 
                                        name="logradouro"
                                        value={this.state.logradouro}
                                        placeholder="Digite o nome da Rua"
                                        invalid={this.state.logradouro_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                                <FormGroup>
                                    <h6><b>Número: </b> </h6>
                                    <InputText 
                                        name="numero"
                                        value={this.state.número}
                                        placeholder="Digite o numero"
                                        invalid={this.state.numero_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                                <FormGroup>
                                    <h6><b>Complemento: </b> </h6>
                                    <InputText 
                                        name="complemento"
                                        value={this.state.complemento}
                                        placeholder="Digite o complemento"
                                        invalid={this.state.complemento_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <h6><b>Bairro: </b> </h6>
                                    <InputText 
                                        name="bairro"
                                        value={this.state.bairro}
                                        placeholder="Digite o bairro"
                                        invalid={this.state.bairro_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                                <FormGroup>
                                    <h6><b>Estado (UF): </b> </h6>
                                    <InputText 
                                        name="uf"
                                        maxLength={2}
                                        value={this.state.uf}
                                        placeholder="Digite o uf"
                                        invalid={this.state.uf_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                                <FormGroup>
                                    <h6><b>Cidade: </b> </h6>
                                    <InputText 
                                        name="cidade"
                                        value={this.state.cidade}
                                        placeholder="Digite a cidade"
                                        invalid={this.state.cidade_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    :
                    this.state.step === 2 ?
                        <div>
                            <hr/>
                            <h6><b>Nome da Empresa: </b> {this.state.request.nomeEmpresarial}</h6>
                            <h6><b>Data de Constituição: </b> {this.state.request.dataAbertura}</h6>
                            <h6><b>Natureza Juridica: </b>{this.state.request.naturezaJuridica.descricao}</h6>
                            {this.state.request.telefones ?
                                <h6><b>Telefone: </b> {'('+this.state.request.telefones[0].ddd + ') '+this.state.request.telefones[0].numero}</h6>
                                :
                                <FormGroup>
                                    <h6><b>Telefone:</b> </h6>
                                    {/* <InputText placeholder="Telefone"/> */}
                                    <InputMasked
                                        mask="(99) 99999-9999"
                                        name="telefone"
                                        value={this.state.telefone}
                                        placeholder="Digite o telefone"
                                        invalid={this.state.telefone_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            }
                            
                            <FormGroup>
                                <h6><b>Nome completo: </b> </h6>
                                <InputText 
                                    name="socio_name"
                                    value={this.state.socio_name}
                                    placeholder="Digite o Nome completo"
                                    invalid={this.state.socio_name_invalid}
                                    onChange={this.handleChange}
                                />
                                <Input hidden invalid={this.state.socio_name_invalid} />
                                <FormFeedback>Campo Obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>E-mail Pessoal: </b> </h6>
                                <InputText 
                                    name="email"
                                    value={this.state.email}
                                    placeholder="Digite o e-mail"
                                    invalid={this.state.email_invalid}
                                    onChange={this.handleChange}
                                />
                                <Input hidden invalid={this.state.email_invalid} />
                                <FormFeedback>Campo Obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Data de Nascimento:</b> </h6>
                                {/* <InputText placeholder="Telefone"/> */}
                                <InputText
                                    type="date"
                                    name="data_nascimento"
                                    value={this.state.data_nascimento}
                                    placeholder="Digite a data de nascimento"
                                    invalid={this.state.data_nascimento_invalid}
                                    onChange={this.handleChange}/>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>CPF Pessoal:</b> </h6>
                                {/* <InputText placeholder="Telefone"/> */}
                                <InputMasked
                                    mask="999.999.999-99"
                                    name="cpf_pessoal"
                                    value={this.state.cpf_pessoal}
                                    placeholder="Digite seu CPF"
                                    invalid={this.state.cpf_pessoal_invalid}
                                    onChange={this.handleChange}/>
                            </FormGroup>
                            <hr/>
                            <b>Dados de endereço</b>
                            <br/>
                            <br/>
                            <FormGroup>
                                <h6><b>CEP: </b> </h6>
                                <InputMasked 
                                    name="cep"
                                    mask="99999-999"
                                    value={this.state.cep}
                                    placeholder="Digite o cep"
                                    invalid={this.state.cep_invalid}
                                    onChange={this.handleChange}/>
                            </FormGroup>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <h6><b>Logradouro: </b> </h6>
                                        <InputText 
                                            name="logradouro"
                                            value={this.state.logradouro}
                                            placeholder="Digite o nome da Rua"
                                            invalid={this.state.logradouro_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <h6><b>Número: </b> </h6>
                                        <InputText 
                                            name="numero"
                                            value={this.state.número}
                                            placeholder="Digite o numero"
                                            invalid={this.state.numero_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <h6><b>Complemento: </b> </h6>
                                        <InputText 
                                            name="complemento"
                                            value={this.state.complemento}
                                            placeholder="Digite o complemento"
                                            invalid={this.state.complemento_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <h6><b>Bairro: </b> </h6>
                                        <InputText 
                                            name="bairro"
                                            value={this.state.bairro}
                                            placeholder="Digite o bairro"
                                            invalid={this.state.bairro_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <h6><b>Estado (UF): </b> </h6>
                                        <InputText 
                                            name="uf"
                                            maxLength={2}
                                            value={this.state.uf}
                                            placeholder="Digite o uf"
                                            invalid={this.state.uf_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <h6><b>Cidade: </b> </h6>
                                        <InputText 
                                            name="cidade"
                                            value={this.state.cidade}
                                            placeholder="Digite a cidade"
                                            invalid={this.state.cidade_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    :
                    <></>
                }
            </ModalBody>
                <ModalBody 
                    style={{
                        minHeight: '114px', 
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn 
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                        />
                </ModalBody>
            <ModalFooter hidden={this.state.loading}>
                <Button color="danger" onClick={() => this.onClose()}>Cancelar</Button>
                <Button color="success" onClick={() => this.onRegister()}>{this.state.step === 0 ? 'Avançar' : 'Cadastrar'}</Button>
            </ModalFooter>
        </Modal>
    }
}

export default AQPassoModal;