import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { Col, Form, FormGroup, Input, Card, Label, Modal, ModalFooter, ModalBody, ModalHeader, Row, Button, FormFeedback, CustomInput } from "reactstrap";
import { MdAttachFile, MdClear } from 'react-icons/md';
import styled from "styled-components";
//import { InputMasked } from "../../InputText/InputMasked";
import { InputText } from "../../InputText/InputText";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import Select from 'react-select';
import axios from "axios";
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import AsyncSelect from 'react-select/async';
import { InputMasked } from "../../InputText/InputMasked";
import { list_origem_ticket } from "../../../auth/listas";

const DropArea = styled.div`
    border-style: dashed;
    border-color: #38B76E;
    padding: 1%;
    cursor: pointer;
    &:drag {
        opacity: 0.5;
    }
`;
const ButtonUpload = styled.button`
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0;
    padding-left: 7px;
    padding-right: 7px;
    top: -1px;
    position: relative;
`;

const getDepartamentos = (callback) => {
    var url = ENDPOINT + "departamento/list";
    
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        var list_departamentos = [];
        data.forEach((p, i) => {
            p['value'] = p.flag;
            p['label'] = p.nome;
            list_departamentos.push(p);
        });

        callback(list_departamentos)
    });
}

class CreateTicket extends Component{
    constructor(props){
        super(props);
        this.state = {
            nome: '',
            nome_invalid: false,
            email: '',
            email_invalid: false,
            document: '',
            document_invalid: false,
            telefone: '',
            telefone_invalid: false,
            ticket_manual: false,
            all_users: false,
            usuario: null,
            usuario_invalid: false,
            assunto: '',
            assunto_invalid: false,
            departamento: null,
            departamento_invalid: false,
            descricao: '',
            descricao_invalid: false,
            anexos: [],
            anexos_invalid: false,
            assinatura_email: null,
            assinatura_email_invalid: false,
            origem: null,
            origem_invalid: false,
            mask: '(99) 9999-99999',
            list_departamentos: [],
            assinaturas_email: [],
            error_message: '',
            success_message: '',
            is_cnpj: false,
            loading: false
        }
        this.inputFile = React.createRef();
    }
    componentDidMount(){
        getDepartamentos(this.callbackDepartamentos.bind(this));
        this.getModelos();
    }
    getModelos = () => {
        var url = ENDPOINT+"modelos/list";
    
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            var assinaturas_email = [];
            data.forEach((p, i) => {
                p['value'] = p.flag;
                p['label'] = p.nome;
                assinaturas_email.push(p);
            })
            this.setState({assinaturas_email});
        })
        .catch((error) => {

        });
    }
    componentWillUpdate(nextProps, nextState) {
        if (nextProps.visible !== false && nextProps.visible !== this.props.visible) {
            getDepartamentos(this.callbackDepartamentos.bind(this));
            this.getModelos();
        }
    }
    callbackDepartamentos(list_departamentos){
        this.setState({list_departamentos});
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    handleFileChange = (event) => {
        const files = event.target.files;
        var state = this.state;
        Array.from(files).forEach(file_current => {
            state.anexos.push(file_current);
        });
        this.setState({
            ...state
        })
    }
    maskPhonePress = (e) => {
        var check = e.target.value.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll('_', '').replaceAll(' ', '').length;
        var tecla = e.key;
        /* eslint eqeqeq: 0 */
        if((!isNaN(tecla)) || tecla == "Backspace"){
            if(check >= 10){
                e.target.mask = '(99) 99999-9999';
                this.setState({mask: '(99) 99999-9999'});
            }
            else{
                e.target.mask = '(99) 9999-99999';
                this.setState({mask: '(99) 9999-99999'});
            }

            if(tecla == "Backspace"){
                if(check >= 13){
                    e.target.mask = '(99) 99999-9999';
                    this.setState({mask: '(99) 99999-9999'});
                }
                else{
                    e.target.mask = '(99) 9999-99999';
                    this.setState({mask: '(99) 9999-99999'});
                }
            }

        }
    }
    onClose() {
        this.setState({
            nome: '',
            nome_invalid: false,
            email: '',
            email_invalid: false,
            document: '',
            document_invalid: false,
            telefone: '',
            telefone_invalid: false,
            ticket_manual: false,
            all_users: false,
            usuario: null,
            usuario_invalid: false,
            departamento: null,
            departamento_invalid: false,
            descricao: '',
            descricao_invalid: false,
            origem: null,
            origem_invalid: false,
            assunto: '',
            assunto_invalid: false,
            anexos: [],
            anexos_invalid: false,
            assinatura_email: null,
            assinatura_email_invalid: false,
            mask: '(99) 9999-99999',
            list_departamentos: [],
            assinaturas_email: [],
            error_message: '',
            success_message: '',
            is_cnpj: false,
            loading: false
        });
        this.props.onClose(false);
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    onAll = (all_users) => {
        if(all_users === true){
            this.setState({ 
                usuario: { label: "Todos os clientes", value: 'all'}
            });
        }
        else{
            this.setState({usuario: null})
        }
        this.setState({all_users})
    }
    loadOptions = (inputValue, callback) => {
        //var url = ENDPOINT + "user/all_aqbank?nome=" + inputValue;
        var url = ENDPOINT + "user/all?search=" + inputValue;
        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p.data;

            data.forEach((p, i) => {
                data[i].value = p.id;
                data[i].label = p.nome !== null ? p.nome+" "+p.sobrenome : p.nome_fatura;
            });

            callback(this.filter(inputValue, p.data));
        });
    }

    filter = (inputValue, data) => {
        return data.filter(i =>
            (i.nome !== null ? i.nome : '').toLowerCase().includes(inputValue.toLowerCase())
        );
    }
    onData(data) {
        
        if (data.success === true) {
            this.setState({
                success_message: data.message
            });
        }
        else {
            this.setState({
                error_message: data.errors
            })
        }
    }
    validarEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    //retirado do site: http://www.receita.fazenda.gov.br/aplicacoes/atcta/cpf/funcoes.js
    validarCPF(strCPF) {
        var Soma;
        var Resto;
        Soma = 0;   
        //strCPF  = RetiraCaracteresInvalidos(strCPF,11);
        if (strCPF == "00000000000")
            return false;
        for (var i=1; i<=9; i++)
        Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i); 
        Resto = (Soma * 10) % 11;
        if ((Resto == 10) || (Resto == 11)) 
        Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10)) )
        return false;
        Soma = 0;
        for (i = 1; i <= 10; i++)
           Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
        if ((Resto == 10) || (Resto == 11)) 
        Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11) ) )
            return false;
        return true;
    }

    validarCNPJ(cnpj) {
        cnpj = cnpj.replace(/[^\d]+/g, '');
        // verificando se tem a quantidade certa de caracter e se não tem todos caracteres iguais
        if(cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj))
            return false;
        let t = cnpj.length - 2,
            d = cnpj.substring(t),
            d1 = parseInt(d.charAt(0)),
            d2 = parseInt(d.charAt(1)),
            calc = x => {
                let n = cnpj.substring(0, x),
                    y = x - 7,
                    s = 0,
                    r = 0;
                for (let i = x; i >= 1; i--) {
                    s += n.charAt(x - i) * y--;
                    if (y < 2)
                        y = 9;
                }
                r = 11 - s % 11;
                return r > 9 ? 0 : r;
            }
        return calc(t) === d1 && calc(t + 1) === d2;
    }
    validarCampos = () => {
        var invalids = 0;
        if(this.state.ticket_manual === false){
            if(this.state.usuario !== null){
                this.setState({ usuario_invalid: false });
            }
            else{
                invalids++;
                this.setState({ usuario_invalid: true });
            }
        }
        else{
            if(this.state.nome.length > 0){
                this.setState({ nome_invalid: false });
            }
            else{
                invalids++;
                this.setState({ nome_invalid: true });
            }

            if(this.state.email.length > 0){
                this.setState({ email_invalid: false });
            }
            else{
                invalids++;
                this.setState({ email_invalid: true });
            }
            var document = this.state.document.replace(/([^\d])+/gim, '');
            console.log(document);

            if(document.length === 11){
                if(this.validarCPF(document) === true){
                    this.setState({ document_invalid: false });
                }
                else{
                    invalids++;
                    this.setState({ document_invalid: true });
                }
            }
            else if(document.length === 14){
                if(this.validarCNPJ(document) === true){
                    this.setState({ document_invalid: false });
                }
                else{
                    invalids++;
                    this.setState({ document_invalid: true });
                }
            }
            else{
                invalids++;
                this.setState({ document_invalid: true });
            }

        }

        if(this.state.departamento !== null){
            this.setState({ departamento_invalid: false });
        }
        else{
            invalids++;
            this.setState({ departamento_invalid: true });
        }

        if(this.state.origem !== null){
            this.setState({ origem_invalid: false });
        }
        else{
            invalids++;
            this.setState({ origem_invalid: true });
        }

        if(this.state.descricao.length > 0){
            this.setState({ descricao_invalid: false });
        }
        else{
            invalids++;
            this.setState({ descricao_invalid: true });
        }

        if(invalids > 0){
            return false;
        }

        return true;
    }
    onDrop = (file) => {
        var state = this.state;
        file.forEach(file_current => {
            state.anexos.push(file_current);
        });

        this.setState({
            ...state
        })
    }
    removeAttach = (index) => {
        var state = this.state;
        state.anexos.splice(index, 1);
        this.setState({
            ...state
        })
    }
    onSave(){
        if(this.validarCampos() === true){
            this.setState({loading: true})
            var url = ENDPOINT+"ticket-admin/novo_ticket";
            var formData = new FormData();

            var nome_completo = '';
            if(this.state.ticket_manual === false){
                if(this.state.usuario !== null){
                    nome_completo = this.state.usuario.nome !== null ? this.state.usuario.nome+" "+this.state.usuario.sobrenome : this.state.usuario.nome_completo;

                    formData.append('nome', nome_completo);
                    formData.append('email', this.state.usuario.email);
                    formData.append('document', this.state.usuario.cpf !== "" ? this.state.usuario.cpf :  this.state.usuario.cnpj);
                    formData.append('telefone', this.state.usuario.telefone.replace(/([^\d])+/gim, ''));
                }
            }
            else{
                nome_completo = this.state.nome;
                var document = this.state.document.replace(/([^\d])+/gim, '');

                formData.append('nome', nome_completo);
                formData.append('email', this.state.email);
                formData.append('document', document);
                formData.append('telefone', this.state.telefone.replace(/([^\d])+/gim, ''));
            }
            
            //formData.append('nome', this.state.nome);
            formData.append('assunto', this.state.assunto);
            formData.append('departamento', this.state.departamento.value);
            formData.append('origem', this.state.origem.value);
            formData.append('descricao', this.state.descricao);

            if(this.state.anexos.length > 0){
                this.state.anexos.forEach((file, index) => {
                    formData.append('anexo['+index+']', file);
                });
            }
            if(this.props.protocolo_ocorrencia !== null && typeof this.props.protocolo_ocorrencia !== 'undefined'){
                formData.append('protocolo_ocorrencia', this.props.protocolo_ocorrencia);
            }

            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }})
            .then((response) => {
                var data = response.data;
                this.onData(data);
                return data;
            })
            .catch((error) => {
                if(error.message === 'Network Error'){
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    this.onData(obj);
                }
                var data = error.response.data;
                this.onData(data);
            });

        }
    }
    handleManual = (ticket_manual) => {
        this.setState({
            nome: '',
            nome_invalid: false,
            email: '',
            email_invalid: false,
            document: '',
            document_invalid: false,
            telefone: '',
            telefone_invalid: false,
            all_users: false,
            usuario: null,
            usuario_invalid: false,
            ticket_manual
        })
    }

    render(){
        return (
            <Modal isOpen={this.props.visible} size="lg">
                <ModalHeader>Criar Ticket</ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <Form>
                        <FormGroup>
                            <CustomInput 
                                type="switch" 
                                id="exampleCustomSwitch" 
                                name="customSwitch"
                                label="Selecionar dados manualmente" 
                                checked={this.state.email_manual}
                                onChange={(e) => this.handleManual(e.target.checked)}
                            />
                        </FormGroup>
                        <div hidden={!this.state.ticket_manual}>
                            <FormGroup>
                                <InputText 
                                    name="nome"
                                    value={this.state.nome}
                                    onChange={this.handleChange}
                                    invalid={this.state.nome_invalid}
                                    placeholder="Nome" />
                            </FormGroup>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <InputText 
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            invalid={this.state.email_invalid}
                                            placeholder="E-mail" />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <InputMasked 
                                            name="document"
                                            mask={this.state.is_cnpj === false ? "999.999.999-99" : "99.999.999/9999-99"}
                                            value={this.state.document}
                                            onChange={this.handleChange}
                                            invalid={this.state.document_invalid}
                                            placeholder="Documento (CPF ou CNPJ)" />
                                        <p style={{margin: 0}}><input 
                                            type="checkbox"
                                            checked={this.state.is_cnpj}
                                            onChange={() => this.onSearchDocument()}/> Mudar para CNPJ</p>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <InputMasked 
                                            name="telefone"
                                            value={this.state.telefone}
                                            onChange={this.handleChange}
                                            onKeyDown={(e) => this.maskPhonePress(e)}
                                            mask={this.state.mask}
                                            invalid={this.state.telefone_invalid}
                                            placeholder="Telefone" />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </div>
                        <div hidden={this.state.ticket_manual}>
                            <FormGroup>
                                <Label>Usuário:</Label>
                                <AsyncSelect
                                    cacheOptions
                                    loadOptions={this.loadOptions}
                                    defaultOptions
                                    isDisabled={this.state.all_users}
                                    value={this.state.usuario} 
                                    placeholder="Escolha o usuário"
                                    isOptionDisabled={(usuario) => usuario.email !== null ? false : true}
                                    onChange={(usuario) => this.setState({usuario})}
                                    onInputChange={this.handleInputChange}
                                />
                                <Input hidden invalid={this.state.usuario_invalid} />
                                <FormFeedback>Campo obrigatório</FormFeedback>
                                {/* <p style={{marginLeft: '22px'}} ><Input type="checkbox" onChange={(e) => this.onAll(e.target.checked)} checked={this.state.all_users}/>Todos os usuários</p> */}
                            </FormGroup>
                        </div>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Select 
                                        placeholder="Origem"
                                        options={list_origem_ticket}
                                        isSearchable={false}
                                        onChange={(origem) => this.setState({origem})}
                                        noOptionsMessage={() => {return 'Sem opções'}}
                                    />
                                    <Input hidden invalid={this.state.origem_invalid} />
                                    <FormFeedback>Campo obrigatório</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Select 
                                    placeholder="Departamento"
                                    options={this.state.list_departamentos}
                                    isSearchable={false}
                                    onChange={(departamento) => this.setState({departamento})}
                                    noOptionsMessage={() => {return 'Sem opções'}}
                                />
                                <Input hidden invalid={this.state.departamento_invalid} />
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </Col>
                        </Row>
                        <FormGroup style={{marginTop: 10}}>
                            <InputText 
                                name="assunto"
                                value={this.state.assunto}
                                onChange={this.handleChange}
                                invalid={this.state.assunto_invalid}
                                placeholder="Assunto" />
                        </FormGroup>
                        <FormGroup>
                            <Input 
                                name="descricao"
                                type="textarea"
                                value={this.state.descricao}
                                onChange={this.handleChange}
                                style={{
                                    resize: 'none',
                                    height: '30vh'
                                }}
                                invalid={this.state.descricao_invalid}
                                placeholder="Descrição" />
                        </FormGroup>
                    </Form>
                    <Dropzone onDrop={(drop) => this.onDrop(drop)}>
                            {({ getRootProps, getInputProps }) => (
                                <DropArea {...getRootProps({
                                    onClick: event => event.stopPropagation()
                                })}>
                                    <input {...getInputProps()} />
                                    {this.state.anexos.length > 0 ?
                                    <>
                                            <Row>
                                                {this.state.anexos.map((e, index) => {
                                                    return <Col key={index} md={4} style={{ paddingBottom: 4 }}>
                                                        <Card>
                                                            <p style={{ margin: '3px' }}>
                                                                <MdAttachFile style={{ width: '32px' }} />
                                                                <small>{e.name}</small>
                                                                <Button
                                                                    style={{ padding: 0, border: 3, float: 'right' }}
                                                                    color="danger"
                                                                    outline
                                                                    onClick={
                                                                        () => this.removeAttach(index)
                                                                    }>
                                                                    <MdClear style={{ padding: 0 }} />
                                                                </Button>
                                                            </p>
                                                        </Card>
                                                    </Col>
                                                })}
                                                <br />
                                            </Row>
                                            <br/>
                                            <p style={{ margin: 0 }}>
                                                <MdAttachFile style={{ width: '32px' }} />
                                                    Arraste arquivos ou&nbsp;
                                                    <ButtonUpload 
                                                        onClick={() => this.inputFile.current.click()} 
                                                        className="btn btn-dark" 
                                                        color="primary">
                                                        Clique Aqui
                                                    </ButtonUpload> para adicionar mais anexos
                                                    <input type="file" ref={this.inputFile} multiple hidden onChange={this.handleFileChange} />
                                            </p>
                                        </>
                                        :
                                        <>
                                            <p style={{ margin: 0 }}>
                                                <MdAttachFile style={{ width: '32px' }} />
                                                    Arraste arquivos ou&nbsp;
                                                    <ButtonUpload 
                                                        onClick={() => this.inputFile.current.click()} 
                                                        className="btn btn-dark" 
                                                        color="primary">
                                                        Clique Aqui
                                                    </ButtonUpload> para adicionar anexos
                                                    <input type="file" ref={this.inputFile} multiple hidden onChange={this.handleFileChange} />
                                            </p>
                                        </>
                                    }
                                </DropArea>
                            )}
                        </Dropzone>
                        <input type="file" ref={this.inputFile} multiple hidden onChange={this.handleFileChange} />
                </ModalBody>
                <ModalBody
                    hidden={!this.state.loading}
                    style={{
                        minHeight: '114px',
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose.bind(this)}
                        onError={this.onError.bind(this)}
                    />
                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose()}>Fechar</Button>
                    <Button color="success" onClick={() => this.onSave()}>Salvar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
export default CreateTicket;