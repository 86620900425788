import React from 'react';
import { ImFileEmpty } from 'react-icons/im';
import Moment from 'moment';
import { FaMoneyBillWave } from 'react-icons/fa';
import { Row, Col } from 'reactstrap';
/* 
function creditsAproved(data) {
    var array = [];
    data.forEach(e => {
        if(e.pagamento!== null){
            if(e.pagamento.status === "APROVADO"){
                array.push(e);
            }
        }
    });
    return array;
} */

/* const payment = (payment_type) => {
    switch(payment_type){
        case 'credit' : return 'Crédito';
        case 'boleto' : return 'Boleto';
        default: return payment_type
    }
}

const status_transacao = (payment_type) => {
    switch(payment_type){
        case 'succeeded' : return 'Aprovada';
        case 'canceled' : return 'Cancelada';
        case 'pending' : return 'Pendente';
        case 'failed' : return 'Falhada';
        default: return payment_type
    }
} */
const Lancamentos = (props) => {
    var isData = props.data;
    return (
        <>
            <div hidden={props.data.length === 0}>
                <Row style={{fontSize: '14px', paddingLeft: 16}}>
                    <Col md={1} className="d-none d-md-block" style={{alignSelf: 'center', textAlign: 'center'}}>
                        
                    </Col>
                    <Col md={2} className="d-none d-md-block" style={{alignSelf: 'center', textAlign: 'center'}}>
                        <b style={{fontWeight: 500}}>Próximo pagamento</b>
                    </Col>
                    <Col md={3} className="d-none d-md-block" style={{alignSelf: 'center', textAlign: 'center'}}>
                        <b style={{fontWeight: 500}}>Total de lançamentos futuros</b>
                    </Col>
                </Row>
                <hr className="d-none d-md-block"/>
            </div>
            {isData.map(p => {
                return (
                    <>
                        {/* <center>
                            <FaMoneyBill style={{ width: '50px', height: '50px' }}/><br/>
                            <h5><b>Próximo pagamento em: </b> {Moment(new Date(p.date).toDateString()).format('DD/MM/YYYY')}</h5>
                            <h5><b>Total de lançamentos futuros:</b> R$ {p.total_amount}</h5>
                        </center> */}
                        <Row style={{fontSize: '14px', paddingLeft: 16}}>
                            <Col md={1} style={{alignSelf: 'center', textAlign: 'center'}}>
                                <FaMoneyBillWave style={{ width: '31px', height: '31px' }}/>
                            </Col>
                            <Col md={2} style={{alignSelf: 'center', textAlign: 'center'}}>
                                <p style={{ marginBottom: 0 }}>{Moment(new Date(p.date).toDateString()).format('DD/MM/YYYY')}</p>
                            </Col>
                            <Col md={3} style={{alignSelf: 'center', textAlign: 'center'}}>
                                <p style={{ marginBottom: 0 }}>R$ {p.total_amount}</p>
                            </Col>
                        </Row>
                    <hr/>
                    </>
                )
            })}
            {props.data.length === 0 ?
            <div>
                <center>
                    <ImFileEmpty style={{width: '50px', height: '50px'}} /><br/>
                    Não há histórico de transações
                </center>
            </div>:<></>}
        </>
    )
}

export default Lancamentos;