import React from 'react';
import { AiFillBank } from 'react-icons/ai';
import { TabPane } from 'reactstrap';
import ContasBancarias from '../UsersList/ContasBancarias';

const ContasBancariasClient = (props) => {
    return (
        <TabPane tabId={props.active}>
            <br />
            <h5>&nbsp;&nbsp;
                <AiFillBank style={{width: '30px', height: '30px'}}/> 
                Conta Bancaria de {props.name} </h5>
            <br/>
            <ContasBancarias data={props.data} />
        </TabPane>
    )
}

export default ContasBancariasClient;