import moment from 'moment';
import { HiViewGrid, HiTrash } from 'react-icons/hi';
import { toast } from 'react-toastify';
import { Button, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';

export const columns = (onModalConfirmDeleProcesso) => [
    {
      name: "",
      selector: "buttons",
      sortable: false,
      width: '5%',
      cell: row => <Row>
        <Button
          outline
          style={{ padding: 2, marginLeft: 15, border: 0, fontWeight: '700'}}
          color="dark" onClick={() => 
            {
                window.location.href = '/juridico-detalhes/'+row.id
            }
          }>
          <HiViewGrid style={{ padding: '2px' }}/>
        </Button>
      </Row>
    },
    {
      name: "Processo",
      selector: "numero_processo",
      sortable: false,
      width: '13%',
    },
    {
      name: "Documento",
      selector: "documento",
      sortable: false,
      width: '14%',
    },
    {
      name: "Nome / Empresa",
      selector: "nome",
      sortable: false,
      width: '20%',
    },
    {
      name: 'Responsável',
      selector: 'reclamado',
      sortable: false,
      width: '9%',
    },
    {
      name: 'Demanda',
      selector: 'demanda',
      sortable: false,
      width: '10%',
    },
    {
      name: "Status do Processo",
      selector: "status",
      sortable: false,
      width: '14%',
    },
    {
      name: "Pagamento",
      selector: "pagamento",
      sortable: false,
      width: '10%',
    },
    {
      name: "",
      selector: "buttons",
      sortable: false,
      width: '5%',
      cell: row => <Row>
        <Button
          outline
          style={{ padding: 2, marginLeft: 15, border: 0, fontWeight: '700'}}
          color="dark" 
          onClick={() => onModalConfirmDeleProcesso(row.id)}
          >
            <HiTrash style={{ padding: '2px' }}/>
        </Button>
      </Row>
    },
];

var numberOfAjaxCAllPending = 0;

export const getRows = (state, callback) => {
    if(numberOfAjaxCAllPending == 0){
      numberOfAjaxCAllPending = 1;
      var url = ENDPOINT + "juridico/listar?page=" + state.activePage + "&search=" + state.search;

      if(state.status_processo !== null){
        url += "&status_processo="+state.status_processo.id;
      }      
      
      if(state.status_pagamento !== null){
        url += "&status_pagamento="+state.status_pagamento.id;
      }

      if(state.reclamado !== null){
        url += "&reclamado="+state.reclamado.id;
      }

      if(state.advogado_responsavel !== null){
        url += "&advogado="+state.advogado_responsavel.id;
      }

      var myHeaders = new Headers();
    
      myHeaders.append('Authorization', 'Bearer ' + getToken());

      var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
      };
      fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
          if(p.success == true){
            var meta = 0;
            if(p.meta){
              meta = p.meta.last_page;
            }
            numberOfAjaxCAllPending = 0;
            // callback(p.data, meta, p.valor);
            callback(p.data, meta);
          }
          else{
            toast.error(p.errors);
          }
        })
        .catch((error) => {
          toast.error('Ocorreu um erro no servidor!');
        })
    }
    else{
      //callback(state.rowData, state.totalPage, []);
    }
  }
  
  export const getRowsPerPage = (page, state, callback) => {
    if(numberOfAjaxCAllPending == 0){
      numberOfAjaxCAllPending = 1;
      var url = ENDPOINT + "juridico/listar?page=" + page + "&search=" + state.search;

      if(state.status_processo !== null){
        url += "&status_processo="+state.status_processo.id;
      }      
      if(state.status_pagamento !== null){
        url += "&status_pagamento="+state.status_pagamento.id;
      }      
      if(state.reclamado !== null){
        url += "&reclamado="+state.reclamado.id;
      }

      if(state.advogado_responsavel !== null){
        url += "&advogado="+state.advogado_responsavel.id;
      }

      var myHeaders = new Headers();
    
      myHeaders.append('Authorization', 'Bearer ' + getToken());
      
      var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
      };
      fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
          if(p.success == true){
            var meta = 0;
            if(p.meta){
              meta = p.meta.last_page;
            }
            numberOfAjaxCAllPending = 0;
            // callback(p.data, meta, p.valor);
            callback(p.data, meta);
          }
          else{
            toast.error(p.errors);
          }
        })
        .catch((error) => {
          toast.error('Ocorreu um erro no servidor!');
        })
    }
    else{
      //callback(state.rowData, state.totalPage, []);
    }
  }