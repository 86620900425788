import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import moment from 'moment'


/* const getStatusTransaction = (status) => {
  if(status === 'authorized'){
      return 'Autorizado';
  }
  else if(status === 'refunded'){
      return 'Devolvido';
  }
  else{
      return 'Capturado';
  }
} */
const getEstabelecimentoTipo = (status) => {
  if(status === 'TOLL_OPERATOR'){
      return 'Pedágio';
  }
  else if(status === 'PARKING'){
      return 'Estacionamento';
  }
  else{
      return status;
  }
}
const isNull = (string) => {
  if(string === null){
      return 'Sem Informações';
  }
  return string;
}
export const columns = () => [
    {
      name: "Data da Transação",
      selector: "transacao_data",
      sortable: false,
      cell: row => moment(row.transacao_data).format('DD/MM/yyyy H:mm')
    },
    {
      name: "Taggy",
      selector: "serial",
      sortable: false,
    },
    /* {
      name: "TIPO",
      selector: "pagamento_tipo",
      sortable: false,
    }, */
    {
      name: "Cliente",
      selector: "sumario",
      sortable: false,
      width: '250px',
      cell: row => row.tag_conta ? <><p>{row.tag_conta.user ? <><b>Usuário: </b> {row.tag_conta.user.nome + ' ' + row.tag_conta.user.sobrenome}<br/><b>CPF/CNPJ: </b>{row.tag_conta.user.cnpj ? row.tag_conta.user.cnpj : row.tag_conta.user.cpf}</> : 'Sem informações'}</p></> : 'Sem Informações'
    },
    {
      name: "Estabelecimento",
      selector: "nome_estabelecimento",
      sortable: false,
      width: '250px',
      cell: row => <>
              <p>
                <b>Tipo de Estabelecimento:</b> {getEstabelecimentoTipo(row.tipo_estabelecimento)}<br/>
                <b>Conveniado:</b> {row.nome_estabelecimento}<br/>
                <b>Local:</b> {row.sumario}
              </p>
            </>
    },
    /* {
      name: "Tipo de Estabelecimento",
      selector: "tipo_estabelecimento",
      sortable: false,
      width: '150px',
      cell: row => getEstabelecimentoTipo(row.tipo_estabelecimento)
    },
    {
      name: "Conveniado",
      selector: "nome_estabelecimento",
      sortable: false,
    },
    {
      name: "Local",
      selector: "sumario",
      sortable: false,
      cell: row => row.sumario
    }, */
    {
      name: "Placa",
      selector: "sumario",
      sortable: false,
      cell: row => row.tag_conta ? isNull(row.tag_conta.veiculo_placa) : 'Sem Informações'
    },
    {
      name: "Valor da Transação",
      selector: "valor_sinal",
      sortable: false,
      cell: row => "R$ "+parseFloat(row.valor_sinal).toFixed(2)
    }
];

export const getRows = (state, callbackRows) => {
    
    var url = ENDPOINT + "transacao/list";

    if(state.taggy_serial !== null){
        url += '?taggy_serial='+state.taggy_serial;
    }
    
    if(state.document.replace(/([^\d])+/gim, '').length > 0){
      if(state.document.replace(/([^\d])+/gim, '').length === 11){
          url +='&cpf='+state.document.replace(/([^\d])+/gim, '');
      }
      else if(state.document.replace(/([^\d])+/gim, '').length === 14){
          url +='&cnpj='+state.document.replace(/([^\d])+/gim, '');
      }
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    
    var url = ENDPOINT + "transacao/list?search="+search+"&page="+page;

    if(state.taggy_serial !== null){
        url += '&taggy_serial='+state.taggy_serial;
    }
    if(filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    if(state.document.replace(/([^\d])+/gim, '').length > 0){
      if(state.document.replace(/([^\d])+/gim, '').length === 11){
          url +='&cpf='+state.document.replace(/([^\d])+/gim, '');
      }
      else if(state.document.replace(/([^\d])+/gim, '').length === 14){
          url +='&cnpj='+state.document.replace(/([^\d])+/gim, '');
      }
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}