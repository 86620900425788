import axios from "axios";
import React, { Component, useMemo, useState } from "react";
import { MdArrowBack, MdCheck, MdClear, MdMail, MdWarning, MdAttachFile } from "react-icons/md";
import { BsLock, BsUnlock } from 'react-icons/bs';
import { IoReceiptOutline } from 'react-icons/io5';
import { Button, ButtonGroup, Card, CardBody, CardFooter, Col, Collapse, Form, FormFeedback, FormGroup, Input, Row } from "reactstrap";
import Dropzone from 'react-dropzone';
import styled from "styled-components";
import { ENDPOINT } from "../../auth/endpoint";
import { getPermissoes, getToken } from "../../auth/set_services";
import Select from "react-select";
import { list_prevencao_status, list_status_pagamentos } from "../../auth/listas";
import { InputMasked } from '../../components/InputText/InputMasked';
import { InputText } from "../../components/InputText/InputText";
import { toast, useToastContainer } from 'react-toastify';
import PropagateLoader from "react-spinners/PropagateLoader";
import ClipLoader from 'react-spinners/ClipLoader'
import moment from "moment";
import NumberFormat from "react-number-format";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import sanitizeHtml from 'sanitize-html';
import ReactTooltip from "react-tooltip";
import GoogleMapReact from 'google-map-react';
import DataTable from 'react-data-table-component';
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { FaAngleUp, FaClosedCaptioning, FaEye, FaFilePdf, FaMapMarkerAlt, FaPen, FaPlus, FaRegWindowClose, FaSave, FaTrash, FaUps } from "react-icons/fa";
import Pusher from 'pusher-js';
import { ButtonRed } from "../../components/ButtonStylish/ButtonRed";
import { DropdownIcon } from "../../components/DropdownIcon/DropdownIcon";
import SendEmailByCard from "../../components/Modals/CreditCard/SendEmailByCard";
import { MessageNoTable } from "../MessageTable/MessageNoTable";
import PDFViewer from 'pdf-viewer-reactjs';
import ConfirmarRemove from "../../components/Modals/Juridico/ConfirmarRemove";
import { list_estados_cidades } from "../../auth/estados_cidades"; 
import { TableCidadesAtendidas } from "../../components/Tables/TableJuridico/TableCidadesAtendidas";
import { getRows, getRowsPerPage } from "../../components/Tables/TableJuridico/propsCidadesAtendidas";

var CurrencyInput = require('react-currency-masked-input');

const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
    .reclamado {
        display: none;
    }
    .row {
        margin: 10px 0px;
    }
    .btn-save {
        float: right;
    }
    .btn-nota {
        margin-top: 10px;
    }
    .file-iframe {
        width: 100%;
        height: 800px; 
    }
    .btn-arquivo {
        margin-right: 10px;
        color: #fff;
        border: 0px;
    }

    .tabela-parcelamento {
        width: 100%;
        border: 1px solid black;
    }
    
    .tabela-parcelamento th, 
    .tabela-parcelamento td {
        border: 1px solid black;
        border-collapse: collapse;
    }
    
    .tabela-parcelamento .tr-par {
        background: #eeeeee;
    }

    /* .tabela-parcelamento tr: hover {
        background: #cccccc;
        cursor: pointer;
    } */

    input, textarea {
        text-transform: uppercase;
    }
    .form-control {
        height: 42px;
    }
    .input-currency {
        padding-left: 30px;
    }
    .currency-simbol {
        color: #999b9d;
        position: absolute;
        left: 25px;
        top: 43px;
    }
    .card {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        -webkit-border-radius: 0.25rem;
        -moz-border-radius: 0.25rem;
        
    }
    .col-cards {
        margin-bottom: 1rem;
    }
    .card h6 {
        background: #28a745;
        color: #fff;
        text-align: center;
    }
    .row-files {
        border-style: dashed;
        border-color: #38B76E;
        border-top: 0px;
        margin: 0px;
    }
    .invalid {
        border: 1px solid #ff0000;
    }
`;

const tipo_custas_options = [
    {"label": "Civel", "value": "civel"},
    {"label": "Consumidor", "value": "consumidor"},
    {"label": "Trabalhista", "value": "trabalhista"},
    {"label": "Criminal", "value": "criminal"},
    {"label": "Tributário", "value": "tributario"},
];

const areas_atuacoes_options = [
    {"label": "Inicial", "value": "inicial"},
    {"label": "Citação", "value": "citacao"},
    {"label": "Apelação", "value": "apelacao"},
    {"label": "Agravo de instrumento", "value": "agravao_instrumento"},
    {"label": "Recurso Especial", "value": "recurso_especial"},
];

const estados_options = [
    {"label": "Acre", "value": "AC"},
    {"label": "Alagoas", "value": "AL"},
    {"label": "Amapá", "value": "AP"},
    {"label": "Amazonas", "value": "AM"},
    {"label": "Bahia", "value": "BA"},
    {"label": "Ceará", "value": "CE"},
    {"label": "Distrito Federal", "value": "DF"},
    {"label": "Espírito Santo", "value": "ES"},
    {"label": "Goiás", "value": "GO"},
    {"label": "Maranhão", "value": "MA"},
    {"label": "Mato Grosso", "value": "MT"},
    {"label": "Mato Grosso do Sul", "value": "MS"},
    {"label": "Minas Gerais", "value": "MG"},
    {"label": "Pará", "value": "PA"},
    {"label": "Paraíba", "value": "PB"},
    {"label": "Paraná", "value": "PR"},
    {"label": "Pernambuco", "value": "PE"},
    {"label": "Piauí", "value": "PI"},
    {"label": "Rio de Janeiro", "value": "RJ"},
    {"label": "Rio Grande do Norte", "value": "RN"},
    {"label": "Rio Grande do Sul", "value": "RS"},
    {"label": "Rondônia", "value": "RO"},
    {"label": "Roraima", "value": "RR"},
    {"label": "Santa Catarina", "value": "SC"},
    {"label": "São Paulo", "value": "SP"},
    {"label": "Sergipe", "value": "SE"},
    {"label": "Tocantins", "value": "TO"}
];

const resultadoAudienciaOptions = [
    { value: '1', label: 'Acordo com Pagamento'},
    { value: '2', label: 'Com acordo'},
    { value: '0', label: 'Sem acordo'},
];


const custasActive = [];


const getId = () => {
    return window.location.pathname.replace('/juridico-detalhes/', '')
}

const optionsYesNo = [
    { value: '1', label: 'Sim'},
    { value: '0', label: 'Não'},
];
const statusDeEnvio = [
    { value: '1', label: 'Aguardando entrega'},
    { value: '2', label: 'Recebido'},
    { value: '3', label: 'Devolvido'},
    { value: '4', label: 'Mudou-se'},
    { value: '5', label: 'Não existe'},
];

const FormasDePagamentos = [
    { value: 'ted', label: 'TED'},
    { value: 'pix', label: 'PIX'},
    { value: 'deposito_judicial', label: 'Depósito Judicial'},
];

const numeroParcelas = [
    { value: '1', label: '1x'},
    { value: '2', label: '2x'},
    { value: '3', label: '3x'},
    { value: '4', label: '4x'},
    { value: '5', label: '5x'},
    { value: '6', label: '6x'},
    { value: '7', label: '7x'},
    { value: '8', label: '8x'},
    { value: '9', label: '9x'},
    { value: '10', label: '10x'},
    { value: '11', label: '11x'},
    { value: '12', label: '12x'},
];

const DropArea = styled.div`
    border-style: dashed;
    border-color: #38B76E;
    padding: 1%;
    cursor: pointer;
    &:drag {
        opacity: 0.5;
    }
`;

const ButtonUpload = styled.button`
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0;
    padding-left: 7px;
    padding-right: 7px;
    top: -1px;
    position: relative;
`;

class AdvogadosParceiros extends Component {
    constructor(props){
        super(props);
        this.state = {
            show_form: false,
            loading_page: true,
            loading_button: false,
            cidades_atendidas_options: [
                {'label' : 'selecione o estado','value':''},
            ],

            estado_atendido_select: [],
            estado_atendido: null,
            
            cidade_atendida_select: [],
            cidade_atendida: null,
        }
    }
    
    componentDidMount(){
        this.setState({
            show_form: true,
            loading_page: false,
        })

        if (getId() !== 'novo') {
            this.handleLoadDataProcesso();
        } else {
        }
    }
    handleLoadDataProcesso = async () => {
    }
    handleChange = (event) => {
        var state = this.state;
        let name = event.target.name;
        let value = event.target.value;

        if(name === 'cpf_cnpj'){
            if(value.replace(/([^\d])+/gim, '').length > 10){
                state[name] = value;
                if(state.origem_pagamento == null){
                    state.origem_pagamento = { value: "cobrancas", label: "Link de Pagamento" };
                }
                state.activePage = 1;
                state.rowData = [];
                state.loading_table = true;
                this.setState({...state});
                
                getRowsPerPage(1, state, this.callbackRows);
            }
            else if(value.replace(/([^\d])+/gim, '').length === 0){
                state[name] = value;
                state.activePage = 1;
                state.rowData = [];
                state.loading_table = true;
                this.setState({...state});
                
                getRowsPerPage(1, state, this.callbackRows);
            }
        }
        else{
            state[name] = value;
            state.activePage = 1;
            state.rowData = [];
            state.loading_table = true;
            this.setState({...state});
            
            getRowsPerPage(1, state, this.callbackRows);
        }
    }
    onFilter = (filter) => {
        var state = this.state;
        state.filter = filter;
        state.activePage = 1;
        this.setState({ ...state });
        getRowsPerPage(state.activePage, state, this.callbackRows.bind(this));
    }
    filterShow(){
        this.setState({
            show_filter: !this.state.show_filter
        })
    }

    render(){
        console.log(list_estados_cidades)
        const custas_tipo_refs = [];
        const custas_valor_refs = [];

        return(
            <Div key="div_main_page">
                <h3>
                    <Button 
                        outline 
                        style={{border: 'none'}}
                        onClick={() => window.location.href = '/juridico'}
                    >
                        <MdArrowBack style={{padding: 0}}/>
                    </Button>&nbsp;&nbsp;Formulário de Advogados
                </h3>
                <hr />
                <Row>
                    <Col md={12}>  
                        <ConfirmarRemove 
                            visible={this.state.visible_confirm}
                            onClose={() => this.modalConfirm(false)}
                            callback={this.state.callback_modal}
                        />              
                    </Col>
                </Row>    
                <Row hidden={!this.state.loading_page}>
                    <Col md={12}>
                        <center>
                            <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                        </center>
                        <br/>
                    </Col>
                </Row>
                <Div hidden={!this.state.show_form}>
                    <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                        <Row>
                            <Col md={6}>
                                <label htmlFor="nome">
                                    Nome
                                </label>
                                <Input
                                    name="nome"
                                    placeholder="Nome completo"
                                    value={this.state.nome}
                                    type="text"
                                />
                            </Col>
                            <Col md={6}>
                                <label htmlFor="nome">
                                    OAB
                                </label>
                                <Input
                                    name="oab"
                                    placeholder="Número da OAB"
                                    value={this.state.oab}
                                    type="text"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <label htmlFor="celular">
                                    Celular
                                </label>
                                <Input
                                    name="celular"
                                    placeholder="(00) 00000-0000"
                                    value={this.state.celular}
                                    type="text"
                                />
                            </Col>
                            <Col md={2}>
                                <br/><br/>
                                <Input
                                    type="checkbox"
                                    checked={true}
                                    onChange={(e) => console.log(e.target.checked)}
                                    />
                                <label htmlFor="celular">
                                    WhatsApp
                                </label>

                            </Col>
                            <Col md={6}>
                                <label htmlFor="area_atuacao">
                                    Area de Atuação
                                </label>
                                <Select
                                    name="area_atuacao"
                                    placeholder="Selecione..."
                                    options={areas_atuacoes_options}
                                    onChange={(areas_atuacoes_select) => {
                                        this.setState({areas_atuacoes_select})
                                        this.setState({area_atuacao: areas_atuacoes_select.value});
                                    }}
                                    // value={this.state.areas_atuacoes_select}
                                        />
                            </Col>  
                        </Row>
                        <Row>                          
                            {/* <Col md={6}>
                                <label htmlFor="whatsapp">
                                    Número do WhatsApp
                                </label>
                                <Input
                                    name="oab"
                                    className={"form-control"}
                                    placeholder="(00) 00000-0000"
                                    value={this.state.whatsapp}
                                    type="text"
                                />
                            </Col> */}
                        </Row>
                        <Row>
                            <Col md={6}>
                                <label htmlFor="cidade">
                                    Cidade
                                </label>
                                <Input
                                    name="cidade"
                                    placeholder=""
                                    value={this.state.nome}
                                    type="text"
                                />
                            </Col>
                            <Col md={6}>
                                <label htmlFor="estado">
                                    Estado
                                </label>
                                <Select
                                    name="estado"
                                    placeholder="Selecione..."
                                    options={estados_options}
                                    onChange={(estado_select) => {
                                        this.setState({estado_select})
                                        this.setState({estado: estado_select.value});
                                    }}
                                    // value={this.state.estado_select}
                                        />
                            </Col>
                        </Row>
                    </div>
                </Div>
                <Div hidden={!this.state.show_form}>
                    <div style={{padding: '15px', marginTop: '2rem', border: '1px solid #dee2e6', borderRadius: 5}}>
                        <h5>Localidades Atendidas</h5>
                        <Row>
                            <Col md={5}>
                                <label htmlFor="estado_atendido">
                                    Estado
                                </label>
                                <Select
                                    name="estado_atendido"
                                    placeholder="Selecione..."
                                    options={estados_options}
                                    onChange={(estado_atendido_select) => {
                                        let state = this.state;
                                        let cidades = [];
                                        let cidadesOptions = [];

                                        list_estados_cidades.estados.map((field, i) => {
                                            if (field.sigla == estado_atendido_select.value) {
                                                cidades = field.cidades;
                                            }
                                        })

                                        cidades.map((cidade, i) => {
                                            cidadesOptions.push({
                                                'label':cidade,
                                                'value':cidade,
                                            })
                                        })

                                        state.cidades_atendidas_options = cidadesOptions;
                                        state.estado_atendido_select = estado_atendido_select;
                                        state.estado_atendido = estado_atendido_select.value;

                                        this.setState({...state})
                                    }}
                                    // value={this.state.estado_select}
                                        />
                            </Col>
                            <Col md={5}>
                                <label htmlFor="cidade_atendida">
                                    Cidade
                                </label>
                                <Select
                                    name="cidade_atendida"
                                    placeholder="Selecione..."
                                    options={this.state.cidades_atendidas_options}
                                    onChange={(cidade_atendida_select) => {
                                        this.setState({cidade_atendida_select})
                                        this.setState({cidade_atendida: cidade_atendida_select.value})
                                    }}
                                    // value={this.state.estado_select}
                                        />
                            </Col>
                            <Col md={2}>
                                <Button 
                                    className="btn btn-success btn-save" 
                                    style={{marginTop: '2rem'}}
                                    onClick={() => {  }}
                                    >
                                        <FaPlus /> Adicionar
                                </Button>
                            </Col>
                        </Row>       
                    </div>
                </Div>
                <br/>
                <br/>                
                <TableCidadesAtendidas
                    isSearch={this.handleChange}
                    onChangeSelect={this.onChangeSelect}
                    data={this.state.rowData}
                    state={this.state}
                    is_cnpj={this.state.is_cnpj}
                    show_filter={this.state.show_filter}
                    onFilter={this.onFilter.bind(this)}
                    date_from={this.state.date_from}
                    date_to={this.state.date_to}
                    is_seller={this.state.is_seller}
                    filter={this.state.filter}
                    refDocument={this.refDocument}
                    filterShow={this.filterShow.bind(this)}
                    onPageChange={this.onPageChange}
                    activePage={this.state.activePage}
                    totalPage={this.state.totalPage}
                    noDataComponent={ this.state.loading_table === true ? 
                        <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                            <PropagateLoader color={'#52c565'} loading={20} size={30} />
                        </div></div></CardBody></Card> :
                        <Card style={{border: 0}}><CardBody><div>
                            Nenhuma localidade atendida
                        </div></CardBody></Card>
                    }
                />
                <br/>
                <br/>
            </Div>

        )
    }
}

export default AdvogadosParceiros;