import { Component } from "react"
import Select from "react-select"
import { Col, Form, FormGroup, Row, Input, Button } from "reactstrap"
import { ENDPOINT } from "../../../auth/endpoint";
import { list_status } from "../../../auth/listas";
import { getToken } from "../../../auth/set_services";
import { InputMasked } from "../../../components/InputText/InputMasked";
import { InputText } from "../../../components/InputText/InputText";
import AsyncSelect from 'react-select/async';
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";

export class Configuracoes extends Component {
    constructor(props){
        super(props);
        this.state = {
            status: null,
            departamento: null,
            funcionario_atribuido: null,
            ocorrencia: null,
            ticket: null,
            is_cnpj: false,
            telefone: '',
            validade: '',
            assunto: '',
            documento: '',
            loading: false
        }
    }
    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }
    componentDidMount(){
        var validade = '';

        if(this.props.validade !== ''){
            var is_date = new Date(this.props.validade);
            is_date.setMinutes(is_date.getMinutes() - is_date.getTimezoneOffset());
            validade = is_date.toISOString().slice(0,16);
        }

        this.setState({
            telefone: this.props.telefone,
            assunto: this.props.assunto,
            documento: this.props.documento,
            validade: validade,
            is_cnpj: this.props.documento.length > 11 ? true : false
        })

        if(this.props.protocolo_ocorrencia !== null && this.props.protocolo_ocorrencia.length > 0){
            this.setState({
                ocorrencia: { value: this.props.protocolo_ocorrencia, label:this.props.protocolo_ocorrencia},
            })
        }

        if(this.props.departamento_value !== null && this.props.departamento_value.length > 0){
            this.setState({
                departamento: { value: this.props.departamento_value, label: this.props.departamento},
            })
        }

        if(this.props.funcionario !== null){
            this.setState({
                funcionario_atribuido: { value: this.props.funcionario.id, label:this.props.funcionario.name},
            })
        }
    }
    configuracoes = () => {
        this.setState({loading: true})
        var url = ENDPOINT+"ticket-admin/configuracoes";
        var formData = new FormData();
        formData.append('cod_ticket', this.props.cod_ticket);
        if(this.state.departamento !== null){
            formData.append('departamento', this.state.departamento.value);
        }

        if(this.state.ocorrencia !== null){
            formData.append('ocorrencia', this.state.ocorrencia.value);
        }

        formData.append('telefone', this.state.telefone.replace(/([^\d])+/gim, ''));
        if(this.state.funcionario_atribuido !== null){
            formData.append('funcionario_id', this.state.funcionario_atribuido.value);
        }
        if(this.state.status !== null){
            formData.append('status', this.state.status.value);
        }
        if(this.state.assunto !== ''){
            formData.append('assunto', this.state.assunto);
        }
        if(this.state.validade !== null){
            formData.append('validade', moment(this.state.validade).format('YYYY-MM-DD HH:m'));
        }
        if(this.state.is_cnpj === false){
            if(this.state.documento.replace(/([^\d])+/gim, '').length > 10){
                formData.append('documento', this.state.documento.replace(/([^\d])+/gim, ''));
            }
        }
        else{
            if(this.state.documento.replace(/([^\d])+/gim, '').length > 13){
                formData.append('documento', this.state.documento.replace(/([^\d])+/gim, ''));
            }
        }

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({loading: false});
            this.props.refreshUser();
            toast.success(data.message);
            return true;
        })
        .catch((error) => {
            this.setState({loading: false});
            var is_response = error.response.data;
            if(is_response){
                toast.error(is_response.errors);
                return;
            }

            toast.error('Ocorreu um erro no servidor');
            return;
        });
    }

    mesclar = () => {
        this.setState({loading: true})
        var url = ENDPOINT+"ticket-admin/mesclar";
        var formData = new FormData();
        formData.append('ticket_principal', this.props.cod_ticket);
        if(this.state.ticket !== null){
            formData.append('tickets_secundarios[0]', this.state.ticket.value);
        }

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({ loading: false, ticket: null });
            this.props.refreshUser();
            toast.success(data.message);
            return true;
        })
        .catch(() => {
            this.setState({loading: false});
            toast.error('Ocorreu um erro no servidor');
        });
    }

    componentWillUpdate(nextProps, nextState){
        if(nextProps.status !== this.props.status){
            this.setState({status: { value: this.props.status_flag, label: nextProps.status} })
        }
        if(nextProps !== this.props){
            var validade = '';

            if(nextProps.validade !== ''){
                var is_date = new Date(nextProps.validade);
                is_date.setMinutes(is_date.getMinutes() - is_date.getTimezoneOffset());
                validade = is_date.toISOString().slice(0,16);
            }

            this.setState({
                documento: nextProps.documento !== null ? nextProps.documento : '',
                telefone: nextProps.telefone,
                assunto: nextProps.assunto,
                validade: validade,
                is_cnpj: nextProps.documento ? (nextProps.documento.length > 11 ? true : false) : false,
            });

            if(nextProps.protocolo_ocorrencia !== null  && nextProps.protocolo_ocorrencia.length > 0){
                this.setState({
                    ocorrencia: { value: nextProps.protocolo_ocorrencia, label: nextProps.protocolo_ocorrencia},
                })
            }

            if(nextProps.departamento_value !== null && nextProps.departamento_value.length > 0){
                this.setState({
                    departamento: { value: nextProps.departamento_value, label: nextProps.departamento},
                })
            }

            if(nextProps.funcionario !== null){
                this.setState({
                    funcionario_atribuido: { value: nextProps.funcionario.id, label:nextProps.funcionario.name},
                })
            }
        }
    }
    handleInputChangeSelect = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    loadOptions = (inputValue, callback) => {
        var url = ENDPOINT + "ocorrencia/list?page=1&search=" + inputValue + '&email='+this.props.email;
        var myHeaders = new Headers();
      
        myHeaders.append('Authorization', 'Bearer ' + getToken());
      
        var myInit = {
          method: 'GET',
          headers: myHeaders,
          mode: 'cors',
          cache: 'default'
        };
        fetch(url, myInit)
          .then(response => response.json())
          .then((p) => {
              var data = p.data;
              data.forEach((p, i) => {
                  data[i].label = p.protocolo_ocorrencia;
                  data[i].value = p.protocolo_ocorrencia;
              });
              callback(this.filter(inputValue, data));
          });
    };

    filter = (inputValue, data) => {
        return data.filter(i =>
            i.protocolo_ocorrencia.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    loadAtendentesOptions = (inputValue, callback) => {
        var url = ENDPOINT + "admin/list?search=" + inputValue;
        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p.data;

            data.forEach((p, i) => {
                data[i].value = p.id;
                data[i].label = p.name;
            });

            callback(this.filterAtendentes(inputValue, p.data));
        });
    }
    
    filterAtendentes = (inputValue, data) => {
        return data.filter(i =>
            i.name.toLowerCase().includes(inputValue.toLowerCase())
        );
    }
    
    loadTicketOptions = (inputValue, callback) => {
        var url = ENDPOINT + "ticket-admin/list?search=" + inputValue + '&email='+this.props.email;
        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p.data;

            data.forEach((p, i) => {
                data[i].value = p.cod_ticket;
                data[i].label = p.protocolo_ticket + ' ' +p.assunto;
            });

            data.forEach((p2, i2) => {
                /* eslint eqeqeq: 0 */
                if(p2.value == this.props.cod_ticket){
                    data.splice(i2, 1);
                }
            });

            callback(this.filterTicket(inputValue, p.data));
        });
    }
    
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    
    filterTicket = (inputValue, data) => {
        return data.filter(i =>
            i.protocolo_ticket.toLowerCase().includes(inputValue.toLowerCase()) + ' '+i.assunto.toLowerCase().includes(inputValue.toLowerCase())
        );
    }
    render(){
        return (
            <div style={{padding: '15px'}}>
                <h5>Configurações</h5>
                <hr />
                <Form>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Select 
                                    placeholder="Status"
                                    isSearchable={false}
                                    options={list_status}
                                    onChange={(status) => this.setState({status})}
                                    value={this.state.status}/>
                            </FormGroup>
                            <FormGroup>
                                <Select 
                                    placeholder="Departamento"
                                    options={this.props.list_departamentos}
                                    value={this.state.departamento}
                                    onChange={(departamento) => this.setState({departamento})}
                                />
                            </FormGroup>
                            <FormGroup>
                                {/* <Select placeholder="Funcionario Atríbuido"/> */}
                                <AsyncSelect
                                    style={{width: '100%'}}           
                                    cacheOptions
                                    loadOptions={this.loadAtendentesOptions}
                                    defaultOptions
                                    placeholder="Funcionário"
                                    value={this.state.funcionario_atribuido}
                                    onChange={(funcionario_atribuido) => this.setState({funcionario_atribuido})}
                                />
                            </FormGroup>
                            <FormGroup>
                                <InputMasked
                                    placeholder="Documento"
                                    name="documento"
                                    mask={this.state.is_cnpj === false ? "999.999.999-99" : "99.999.999/9999-99"}
                                    value={this.state.documento} 
                                    onChange={this.handleInputChange} />
                                <p style={{margin: 0}}><input 
                                    type="checkbox"
                                    checked={this.state.is_cnpj}
                                    onChange={() => this.onSearchDocument()}/> Mudar para CNPJ</p>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <AsyncSelect
                                    style={{width: '100%'}}           
                                    cacheOptions
                                    loadOptions={this.loadOptions}
                                    defaultOptions
                                    value={this.state.ocorrencia} 
                                    placeholder="Ocorrência"
                                    onChange={(ocorrencia) => this.setState({ocorrencia})}
                                    onInputChange={this.handleInputChangeSelect}
                                />
                                {/* <Select placeholder="Ocorrência"/> */}
                            </FormGroup>
                            <FormGroup>
                                <InputMasked 
                                    mask="(99) 99999-9999"
                                    placeholder="Telefone"
                                    name="telefone"
                                    value={this.state.telefone} 
                                    onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <InputText
                                    placeholder="Assunto"
                                    name="assunto"
                                    value={this.state.assunto} 
                                    onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="datetime-local"
                                    placeholder="Expira em"
                                    name="validade"
                                    value={this.state.validade} 
                                    onChange={this.handleInputChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div style={{textAlign: 'right'}}>
                        <Button disabled={this.state.loading} color="success" onClick={() => this.configuracoes()}>Salvar</Button>
                    </div>
                </Form>
                <hr/>
                <h6>Mesclar Ticket</h6>
                <Form>
                    <Row>
                        <Col>
                            <FormGroup>
                                {/* <InputText
                                    placeholder="Assunto"
                                    name="assunto"
                                    value={this.state.assunto} 
                                    onChange={this.handleInputChange} /> */}
                                <AsyncSelect
                                    style={{width: '100%'}}           
                                    cacheOptions
                                    loadOptions={this.loadTicketOptions}
                                    defaultOptions
                                    value={this.state.ticket} 
                                    placeholder="Ticket"
                                    onChange={(ticket) => this.setState({ticket})}
                                    onInputChange={this.handleInputChangeSelect}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div style={{textAlign: 'right'}}>
                        <Button 
                            disabled={this.state.loading || this.state.ticket === null} 
                            color="success" onClick={() => this.mesclar()}>Mesclar</Button>
                    </div>
                </Form>
            </div>
        )
    }
}