import React, { Component } from "react";
import {
    Button, 
    ButtonGroup, 
    Card, 
    CardBody, 
    Col, 
    Input, 
    Row, 
    TabContent, 
    Nav, 
    NavLink, 
    TabPane } from "reactstrap";
// RCE CSS
import 'react-chat-elements/dist/main.css';
import { ChatItem } from 'react-chat-elements';
import { MdAudiotrack, MdFileUpload, MdImage, MdVideoCall } from 'react-icons/md';
import { toast } from "react-toastify";
import styled from 'styled-components';
import Pusher from 'pusher-js';
import { ENDPOINT } from '../../auth/endpoint';
import axios from "axios";
import { getUser, getToken, getStatus, getPermissoes } from "../../auth/set_services";
import classnames from 'classnames';
import get_extension from 'get-url-extension';
import ChatAtendimento from "../../components/ChatClient/ChatAtendimento";
import moment from "moment";
import { RiCustomerService2Fill } from 'react-icons/ri';
import { HiOutlineChatAlt2 } from "react-icons/hi";
import ReactRoundedImage from "react-rounded-image";
import CreateGroupModal from "./components_chat/CreateGroupModal";
import ChatGrupo from "../../components/ChatClient/ChatGrupo";
import notification from "../../assets/notification.wav";
import { ImWhatsapp } from "react-icons/im";
import ModalValidacaoToken from "../../components/Modals/ModalValidacaoToken/ModalValidacaoToken";

const getDepartamentos = (callback) => {
    var url = ENDPOINT + "departamento/list";
    
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        var operadores_list = [];
        data.forEach((p, i) => {
            p['value'] = p.flag;
            p['label'] = p.nome;
            operadores_list.push(p);
        });

        callback(operadores_list)
    });
}

const getOperadores = (callback) => {
    var url = ENDPOINT + "chat-atendimento/consultar-operadores";
    
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        var operadores_list = [];
        data.forEach((p, i) => {
            p['value'] = p.id;
            p['label'] = <div style={{display: 'flex'}}>
                <ReactRoundedImage
                    image={p['avatar_foto'] !== null ? p['avatar_foto'] : ''}
                    roundedColor="#8dd199"
                    roundedSize="4"
                    imageWidth="25"
                    imageHeight="25"/> 
                    <p style={{display: 'flex', margin: 0}}>
                        &nbsp;
                        {p.name}
                    </p>
            </div>;
            operadores_list.push(p);
        });

        callback(operadores_list)
    });
}

const Div = styled.div`
    .rce-mbox-right-notch {
        position: absolute;
        right: -19px;
        top: -4px;
        width: 19px;
        height: 19px;
        box-shadow: none;
        filter: none;
        fill: #D5F6E3;
    }
    .rce-mbox.rce-mbox-right {
        float: right;
        margin-left: 5px;
        margin-right: 20px;
        border-top-right-radius: 0px;
        border-top-left-radius: 5px;
        box-shadow: none;
        background: #D5F6E3 0% 0% no-repeat padding-box;
        color: #707070;
    }
    .rce-mbox.rce-mbox-left {
        position: absolute;
        right: -19px;
        top: -4px;
        width: 19px;
        height: 19px;
        box-shadow: none;
        -webkit-filter: none;
        filter: none;
    }
    .rce-mbox {
        box-shadow: none;
    }
    .nav-item{
        margin: 0
    }
    .nav-link{
        padding: 4px;
        padding-right: 6px;
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
    .rce-citem-avatar{
        display: none;
    }
    .rce-mbox-left-notch {
        position: absolute;
        left: -11px;
        top: -4px;
        width: 20px;
        height: 20px;
        box-shadow: none;
        -webkit-filter: none;
        filter: none;
    }
    .rce-mbox-photo--img__block {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        border-radius: 5px;
        display: flex;
        max-width: 300px;
    }
    .rce-mbox-photo--img img {
        height: 100%;
        min-height: 100px;
        -webkit-user-select: none;
        user-select: none;
        max-width: 246px;
    }
    .rce-mbox-photo--download {
        color: #efe;
        display: flex;
        justify-content: center;
        background: none;
        border: none;
        font-size: 3.2em;
        outline: none;
        border: 1px solid #eee;
        border-radius: 100%;
        height: 92px;
        width: 92px;
    }
    
    .rce-mbox-photo--download svg{
        vertical-align: middle;
        place-self: center;
        padding: 0;
    }
    .rce-mbox-text img{
        height: 143px
    }
    .rce-mbox-text video{
        height: 143px
    }
    .audio-react-recorder{
        display: none
    }
`;
const ChatItemContainner = styled.div`
    color: ${props => (props.cod_chat === props.cod_chat_current ? `#fff` : `#000`)};
    .rce-citem{
        padding-left: 14px;
        background: ${props => (props.cod_chat === props.cod_chat_current ? `#343a40` : `#fff`)};
    }
    .rce-citem-body--bottom-title {
        color: ${props => (props.cod_chat === props.cod_chat_current ? `#bebebe` : `#555`)};
        font-size: 15px;
    }
    .rce-citem-body--top-time {
        font-size: 12px;
        color: ${props => (props.cod_chat === props.cod_chat_current ? `rgb(240 240 240 / 40%)` : `rgba(0,0,0,0.4)`)};
    }
`;

class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chat: [],
            grupos: [],
            file: [],
            activeTab: "1",
            search: '',
            chats_tamanho: 1,
            toasts_abertos: 0,
            loading: true,
            departamento: null,
            current_message: null,
            current_chat: null,
            sending_message: false,
            masked_message: null,
            modal_anexo: false,
            modal_transfer: false,
            currentChat: null,
            audio_visible: false,
            recording_audio: null,
            pusher_instance: null,
            tipo_atendente: null,
            ligacao_current: true,
            message: '',
            activeTabTwo: "1",
            active: true,
            isOpen: false,
            isVisible: false,

            //criacao_grupo
            modal_group: false
        }
        this.toasts = React.createRef(this);
        this.notificacao = React.createRef(this);
    }
    atender(cod_chat, finalizacao, closeToast) {
        this.aceitarChat(cod_chat, finalizacao, closeToast);
    }
    fecharTransferencia() {
        this.setState({ current_message: null });
    }
    fecharChat() {
        this.setState({ current_chat: null });
    }
    callbackAtendentes = (atendentes) => {
        this.setState({ atendentes });
    }
    AtendimentoNotificacao = (props) => {
        return(
            <div style={{ textAlign: 'center' }}>
                <p style={{ color: 'white' }}><b>Novo chat detectado:</b> {props.nome_destinatario}</p>
                <ButtonGroup>
                    <Button color="success" onClick={() => this.atender(props.codigo_chat, false, props.closeToast)}>Atender</Button>
                    <Button color="danger" onClick={() => props.closeToast()}>Recusar</Button>
                </ButtonGroup>
            </div>
        )
    };

    AtendimentoWhatsAppNotificacao = (props) => {
        return(
            <div style={{ textAlign: 'center' }}>
                <p style={{ color: 'white' }}><b><ImWhatsapp /> Novo chat por whatsapp detectado:</b> {props.nome_destinatario}</p>
                <ButtonGroup>
                    <Button color="success" onClick={() => this.atender(props.codigo_chat, false, props.closeToast)}>Atender</Button>
                    <Button color="danger" onClick={() => props.closeToast()}>Recusar</Button>
                </ButtonGroup>
            </div>
        )
    };
    
    
    AtendimentoNotificacaoPosFinalizacao = (props) => {
        return(
            <div style={{ textAlign: 'center' }}>
                <p style={{ color: 'white' }}><b>Chat pendente:</b> {props.nome_destinatario}</p>
                <ButtonGroup>
                    <Button color="success" onClick={() => this.atender(props.codigo_chat, true, props.closeToast)}>Atender</Button>
                    <Button color="danger" onClick={() => props.closeToast()}>Recusar</Button>
                </ButtonGroup>
            </div>
        )
    };

    AtendimentoNotificacaoTransferencia = (props) => {
        return(
            <div style={{ textAlign: 'center' }}>
                <p style={{ color: 'white' }}><b>Chat sendo transferido para você:</b> {props.nome_destinatario}</p>
                <ButtonGroup>
                    <Button color="success" onClick={() => this.respostaChatTransferido(props.codigo_chat, true, props.closeToast)}>Atender</Button>
                    <Button color="danger" onClick={() => this.respostaChatTransferido(props.codigo_chat, false, props.closeToast)}>Recusar</Button>
                </ButtonGroup>
            </div>
        )
    };

    componentWillMount() {
        const pusher = new Pusher("2892f3b88d766b1b14d3", {
            cluster: 'us3',
            //authEndpoint: 'http://example.com/pusher/auth'
        });

        this.setState({pusher_instance: pusher});

        this.ligacaoChat(pusher);
        this.ligacaoTransferenciaDepartamentoChat(pusher);
        this.ligacaoSpecificChat(pusher);
        this.ligacaoTransferenciaChat(pusher);
        this.refreshMessages(pusher);
        this.refreshAcceptMessages(pusher);
    }
    componentDidMount() {
        this.pegar_primeiro_chat();
        this.loadChat();
        getOperadores(this.callbackAtendentes.bind(this));
        getDepartamentos(this.callbackDepartamentos.bind(this));
    }
    bindLoadChat(data) {
        this.loadChat();
    }
    onOpenCloseNewGroup = (visible) => {
        this.setState({
            modal_group: visible
        })
    }
    callbackDepartamentos = (departamentos) => {
        this.setState({departamentos});
    }
    bindToast(data) {
        if(this.state.ligacao_current == false){
            console.log("Ligação não recebida por chats estarem no limite");
        }
        else if(data.cod_chat !== this.state.currentChat){
            console.log(this.state.toasts_abertos);
            if(this.state.chats_tamanho - this.state.toasts_abertos > 0){
                this.setState({ currentChat: data.cod_chat });
                this.onToast(data);
                setTimeout(() => this.setState({currentChat: null}), 10000);
            }
            else{
                console.log("Não é possivel exibir mais um chamado.");
            }
        }
        else{
            console.log(data.cod_chat+" | "+this.state.currentChat)
        }
    }

    bindToastTransferencia(data) {
        
        if(this.state.ligacao_current == false){
            console.log("Ligação não recebida por chats estarem no limite");
        }
        else if(data.cod_chat !== this.state.currentChat){
            console.log(this.state.toasts_abertos);
            if(this.state.chats_tamanho - this.state.toasts_abertos > 0){
                this.setState({ currentChat: data.cod_chat });
                this.onToastTransferencia(data);
                setTimeout(() => this.setState({currentChat: null}), 10000)
            }
            else{
                console.log("Não é possivel exibir mais um chamado.");
            }
        }
        else{
            console.log(data.cod_chat+" | "+this.state.currentChat)
        }
    }

    bindToastSpecific(data) {
        /* eslint eqeqeq: 0 */
        if(this.state.ligacao_current == false){
            console.log("Ligação não recebida por chats estarem no limite");
        }
        else if(data.cod_chat !== this.state.currentChat){
            console.log(this.state.toasts_abertos);
            if(this.state.chats_tamanho - this.state.toasts_abertos > 0){
                this.setState({ currentChat: data.cod_chat });
                this.onToastSpecific(data);
                setTimeout(() => this.setState({currentChat: null}), 10000)
            }
            else{
                console.log("Não é possivel exibir mais um chamado.");
            }
        }
        else{
            console.log(data.cod_chat+" | "+this.state.currentChat)
        }
    }

    ligacaoChat(pusher) {
        var response = pusher.subscribe('pusher_ligacao_chat_' + getUser().departamento_chat + "_" + getStatus());
        response.bind('chamar', e => this.bindToast(e))
    }

    ligacaoTransferenciaDepartamentoChat(pusher) {
        var response = pusher.subscribe('pusher_transferencia_departamento_' + getUser().departamento_chat + "_" + getStatus());
        response.bind('chamar', e => this.bindToastTransferencia(e))
    }

    ligacaoSpecificChat(pusher) {
        var response = pusher.subscribe('pusher_ligacao_atendente_' + getUser().id);
        response.bind('chamar', e => this.bindToastSpecific(e))
    }

    ligacaoTransferenciaChat(pusher) {
        var response = pusher.subscribe('pusher_transferencia_atendente_' + getUser().id + "_" + getStatus());
        response.bind('chamar', e => this.bindToastTransferencia(e))
    }

    refreshAcceptMessages(pusher) {
        var response = pusher.subscribe('pusher_chat_aceito_' + getUser().id);

        response.bind('atualizar', this.bindLoadChat.bind(this))
    }

    refreshMessages(pusher) {
        var response = pusher.subscribe('pusher_atualizar_contatos_atendente_' + getUser().id);

        response.bind('atualizar', this.bindLoadChat.bind(this))
    }

    bindToastCall(data) {
        toast.dismiss(data.cod_chat);
    }
    
    outroAtendenteAtendeu(cod_chat) {
        var response = this.state.pusher_instance.subscribe('pusher_chat_atendido_' + cod_chat);
        response.bind('atualizar', this.bindToastCall.bind(this))
    }

    loadChat() {
        var url = ENDPOINT + 'chat-atendimento/consultar-chats-de-hoje';

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            var chats_tamanho = data.limite_chat - data.chats_ativos;
            
            if(data.chats_ativos >= data.limite_chat){
                this.setState({ligacao_current: false})
            }
            else{
                this.setState({ligacao_current: true})
            }
            this.setState({ chat: data.chats, grupos: data.grupos, chats_tamanho, loading: false });
        })
    }

    pegar_primeiro_chat() {
        console.log('chegou, aqui')
        var url = ENDPOINT + 'chat-atendimento/pegar-primeiro-chat/'+getUser().departamento_chat;

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            if(data !== ""){
                if(data.status_chat === 0){
                    this.bindToast(data);
                }
                else{
                    this.bindToastTransferencia(data);
                }
            }
        })
    }



    onToast(data) {
        try{
            this.notificacao.play();
        }
        catch(e){

        }
        console.log(data);
        if(data.tipo_chat == 'whatsapp'){
            toast.success(<this.AtendimentoWhatsAppNotificacao codigo_chat={data.cod_chat} nome_destinatario={data.nome} />, {
                position: "top-right",
                autoClose: 150000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                closeButton: false,
                onOpen: () => { this.setState({ toasts_abertos: this.state.toasts_abertos + 1 }) },
                onClose: () => {  this.setState({ toasts_abertos: this.state.toasts_abertos - 1 })},
                toastId: data.cod_chat
                //progress: undefined,
            });
        }
        else{
            toast.dark(<this.AtendimentoNotificacao codigo_chat={data.cod_chat} nome_destinatario={data.nome} />, {
                position: "top-right",
                autoClose: 150000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                closeButton: false,
                onOpen: () => { this.setState({ toasts_abertos: this.state.toasts_abertos + 1 }) },
                onClose: () => {  this.setState({ toasts_abertos: this.state.toasts_abertos - 1 })},
                toastId: data.cod_chat
                //progress: undefined,
            });
        }
        this.outroAtendenteAtendeu(data.cod_chat);
    }
    
    onToastSpecific(data) {
        try{
            this.notificacao.play();
        }
        catch(e){
            
        }
        toast.info(<this.AtendimentoNotificacaoPosFinalizacao codigo_chat={data.cod_chat} nome_destinatario={data.nome} />, 
        {
            position: "top-right",
            autoClose: 150000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            closeButton: false,
            onOpen: () => { this.setState({ toasts_abertos: this.state.toasts_abertos + 1 }) },
            onClose: () => {  this.setState({ toasts_abertos: this.state.toasts_abertos - 1 })},
            toastId: data.cod_chat
        });
        this.outroAtendenteAtendeu(data.cod_chat);
    }

    onToastTransferencia(data) {
        try{
            this.notificacao.play();
        }
        catch(e){
            
        }
        toast.info(<this.AtendimentoNotificacaoTransferencia codigo_chat={data.cod_chat} nome_destinatario={data.nome} />, {
            position: "top-right",
            autoClose: 150000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            closeButton: false,
            onOpen: () => { this.setState({ toasts_abertos: this.state.toasts_abertos + 1 }) },
            onClose: () => {  this.setState({ toasts_abertos: this.state.toasts_abertos - 1 })},
            toastId: data.cod_chat
        });
        this.outroAtendenteAtendeu(data.cod_chat);
    }

    respostaChatTransferido(cod_chat, finalizacao, closeToast) {
        var url = ENDPOINT + 'chat-atendimento/responder-transferencia/' + cod_chat;

        var formData = new FormData();
        formData.append('resposta', Number(finalizacao));

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            try{closeToast();}catch(e){}
        })
        .catch((error) => {
            if (error.message === 'Network Error') {
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                toast.error(obj.errors);
            }
            var data = error.response.data;

            if (data) {
                toast.error(data.errors);
            }
            else {
                toast.error('Ocorreu um erro');
            }
            try{closeToast();}catch(e){}
        });

        try{closeToast();}catch(e){}
    }

    aceitarChat(cod_chat, finalizacao, closeToast) {
        var url = ENDPOINT + 'chat-atendimento/aceitar-chat/' + cod_chat;

        var formData = new FormData();
        formData.append('is_realizado', Number(finalizacao));

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            try{closeToast();}catch(e){}
        })
        .catch((error) => {
            if (error.message === 'Network Error') {
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                toast.error(obj.errors);
            }
            var data = error.response.data;

            if (data) {
                toast.error(data.errors);
            }
            else {
                toast.error('Ocorreu um erro');
            }
            try{closeToast();}catch(e){}
        });

        try{closeToast();}catch(e){}
    }

    onMessageCurrent(current_message) {
        this.setState({ current_message, current_chat: null, masked_message: null });
    }

    onMessageGroupCurrent(current_chat) {
        this.setState({ current_chat, current_message: null, masked_message: null });
    }

    onScroll(scroll) {
        try{
            var scroll_current = scroll.target;
            if (scroll_current.scrollHeight - scroll_current.scrollTop === scroll_current.clientHeight) {
                this.readMessageAll(this.getCurrentChat().cod_chat);
            }
        }
        catch(e){

        }
    }
    
    getCurrentChat() {
        var chat_current = [];
        this.state.chat.forEach(
            (chat) => {
                if (chat.id === this.state.current_message) {
                    chat_current = chat;
                }
            });
        return chat_current;
    }
    handleInputChange = (e) => {
        const { name, value } = e.target;
        
        this.setState({ [name]: value });
      }
      
    getFileExtension = (url) =>{
        var extension = get_extension(url);

        if(extension === '.png' ||
            extension === '.PNG' ||
            extension === '.jpg' ||
            extension === '.jpeg' ||
            extension === '.svg' ||
            extension === '.webp'){
            return 'image'
        }
        else if(extension === '.webm' ||
            extension === '.3gp' ||
            extension === '.ogg' ||
            extension === '.mp4'){
                return 'video';
        }
        else if(extension === '.mp3' ||
            extension === '.wav' ||
            extension === '.ogg'){
            return 'audio';
        }

        return 'file';
    }
    chatMessageSubtitle(last_message){
        if(last_message.anexo !== null){
            if(this.getFileExtension(last_message.anexo) === 'image'){
                return <><MdImage /> Imagem</>
            }
            if(this.getFileExtension(last_message.anexo) === 'video'){
                return <><MdVideoCall /> Video</>
            }
            if(this.getFileExtension(last_message.anexo) === 'audio'){
                return <><MdAudiotrack /> Audio</>
            }

            return <><MdFileUpload /> Arquivo</>
        }

        return <>{last_message.message}</>
    }

    chatGroupMessageSubtitle(last_message){
        if(last_message.anexo_url !== null){
            if(this.getFileExtension(last_message.anexo_url) === 'image'){
                return <><MdImage /> Imagem</>
            }
            if(this.getFileExtension(last_message.anexo_url) === 'video'){
                return <><MdVideoCall /> Video</>
            }
            if(this.getFileExtension(last_message.anexo_url) === 'audio'){
                return <><MdAudiotrack /> Audio</>
            }

            return <><MdFileUpload /> Arquivo</>
        }

        return <>{last_message.message}</>
    }
    handleInputChangeSelect = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };
    checkNotFinalize = (chat) => {
        return chat.status_chat !== 2;
    }
    checkFinalize = (chat) => {
        return chat.status_chat === 2;
    }
    render() {
        return (
            <Div>
                <h3>Chat</h3>
                <hr />
                <ModalValidacaoToken
                    visible={this.state.isVisible}
                    onClose={() => this.setState({ isVisible: false })}
                />
                <Button 
                    color="success"
                    onClick={() => this.setState({ isVisible: true })}>Validar Cliente</Button>
                <Card hidden={!getUser().permissao_atendimento_cliente} style={{ minHeight: '100%' }}>
                    <CardBody style={{ height: '100%' }}>
                        <Row style={{ minHeight: '76vh' }}>
                            <Col md={3} style={{ overflow: 'auto', maxHeight: '76vh' }}>
                                <Input 
                                    placeholder="Buscar Chat"
                                    name="search"
                                    value={this.state.search}
                                    onChange={this.handleInputChange} />
                                <hr/>
                                <Nav tabs>
                                    <NavLink className={classnames({ active: this.state.activeTabTwo === "1" })}
                                            onClick={() => { this.setState({ activeTabTwo: "1" }); }}>
                                        <RiCustomerService2Fill style={{color: '#52c565'}}/>Atendimento
                                    </NavLink>
                                    <NavLink className={classnames({ active: this.state.activeTabTwo === "2" })}
                                            onClick={() => { this.setState({ activeTabTwo: "2" }); }}>
                                        <HiOutlineChatAlt2 style={{color: '#52c565'}}/>Grupos
                                    </NavLink>
                                    <NavLink className={classnames({ active: this.state.activeTabTwo === "3" })}
                                            onClick={() => { this.setState({ activeTabTwo: "3" }); }}>
                                        <RiCustomerService2Fill style={{color: '#52c565'}}/>Finalizados
                                    </NavLink>
                                </Nav>
                                <br/>
                                <TabContent activeTab={this.state.activeTabTwo}>
                                    <TabPane tabId={'1'}>
                                        
                                <p hidden={this.state.loading === false}>Carregando mensagens ...</p>
                                <p hidden={this.state.loading === true || this.state.chat.filter(this.checkNotFinalize).length > 0}>Nenhum chat encontrado</p>
                                {this.state.chat.map((p, index) => {
                                    if(p.status_chat !== 2){
                                        if(this.state.search === ''){
                                            return (
                                                <ChatItemContainner
                                                    cod_chat_current={this.state.current_message}
                                                    cod_chat={p.cod_chat}
                                                >
                                                    <ChatItem
                                                        key={index}
                                                        dateString={p.last_message !== null ? moment(new Date(p.created_at)).format('HH:m') : ' '}
                                                        //avatar={'https://files.zohopublic.com/public?x-service=LiveDesk&event-id=1606357595010_604373000000002052&x-cli-msg=%7B%22x-siq-filetype%22%3A%22fgravatar%22%2C%22x-siq-lsid%22%3A%22604373000000002052%22%2C%22x-siq-soid%22%3A%22730799669%22%2C%22x-siq-ispreview%22%3Afalse%2C%22x-siq-pfname%22%3A%22logo-chat.png%22%2C%22x-siq-downloadtype%22%3A%22default%22%7D'}
                                                        avatarFlexible={true}
                                                        alt={'Reactjs'}
                                                        title={(p.tipo_chat == 'whatsapp' ? '[WhatsApp] ': '')+ p.nome}
                                                        unread={p.messages_not_read_count}
                                                        onClick={() => this.onMessageCurrent(p.cod_chat)}
                                                        subtitle={p.last_message !== null ? this.chatMessageSubtitle(p.last_message) : ''}
                                                        //date={new Date(p.last_message !== null ? p.last_message.updated_at : p.updated_at)} 
                                                        />
                                                </ChatItemContainner>
                                            );
    
                                        }
                                        else if(
                                            (p.usuario !== null ? p.usuario.nome.indexOf(this.state.search) >= 0 : '') ||
                                            (p.usuario !== null ? p.usuario.sobrenome.indexOf(this.state.search) >= 0 : '') ||
                                            p.cod_chat.indexOf(this.state.search) >= 0
                                        ){
                                            return (
                                                <ChatItemContainner
                                                    cod_chat_current={this.state.current_message}
                                                    cod_chat={p.cod_chat}
                                                >
                                                    <ChatItem
                                                        key={index}
                                                        //avatar={'https://files.zohopublic.com/public?x-service=LiveDesk&event-id=1606357595010_604373000000002052&x-cli-msg=%7B%22x-siq-filetype%22%3A%22fgravatar%22%2C%22x-siq-lsid%22%3A%22604373000000002052%22%2C%22x-siq-soid%22%3A%22730799669%22%2C%22x-siq-ispreview%22%3Afalse%2C%22x-siq-pfname%22%3A%22logo-chat.png%22%2C%22x-siq-downloadtype%22%3A%22default%22%7D'}
                                                        avatarFlexible={true}
                                                        alt={'Reactjs'}
                                                        title={p.nome}
                                                        unread={p.messages_not_read_count}
                                                        onClick={() => this.onMessageCurrent(p.cod_chat)}
                                                        subtitle={p.last_message !== null ? this.chatMessageSubtitle(p.last_message) : ''}
                                                        date={new Date(p.last_message !== null ? p.last_message.updated_at : p.updated_at)} />
                                                </ChatItemContainner>
                                            );
                                        }
                                        return (<></>)
                                    }
                                    else{
                                        return (<></>)
                                    }
                                }
                                )}
                                
                                </TabPane>
                                <TabPane tabId={'2'}>
                                    <ButtonGroup>
                                        <Button color="success" onClick={() => this.onOpenCloseNewGroup(true)}>Criar novo grupo</Button>
                                    </ButtonGroup>
                                    <hr/>
                                    <p hidden={this.state.loading === true || this.state.grupos.length > 0}>Nenhum grupo encontrado</p>
                                    {this.state.grupos.map((p, index) => {
                                        return (
                                            <ChatItemContainner
                                                cod_chat_current={this.state.current_chat}
                                                cod_chat={p.cod_grupo}
                                            >
                                                <ChatItem
                                                    key={index}
                                                    dateString={p.last_message !== null ? moment(new Date(p.last_message.created_at)).format('HH:m') : moment(new Date(p.created_at)).format('HH:m')}
                                                    //avatar={'https://files.zohopublic.com/public?x-service=LiveDesk&event-id=1606357595010_604373000000002052&x-cli-msg=%7B%22x-siq-filetype%22%3A%22fgravatar%22%2C%22x-siq-lsid%22%3A%22604373000000002052%22%2C%22x-siq-soid%22%3A%22730799669%22%2C%22x-siq-ispreview%22%3Afalse%2C%22x-siq-pfname%22%3A%22logo-chat.png%22%2C%22x-siq-downloadtype%22%3A%22default%22%7D'}
                                                    avatarFlexible={true}
                                                    alt={'Reactjs'}
                                                    title={p.nome_grupo}
                                                    unread={p.mensagens_pendentes}
                                                    onClick={() => this.onMessageGroupCurrent(p.cod_grupo)}
                                                    subtitle={p.last_message !== null ? this.chatGroupMessageSubtitle(p.last_message) : ''}
                                                    //date={new Date(p.last_message !== null ? p.last_message.updated_at : p.updated_at)} 
                                                    />
                                            </ChatItemContainner>
                                            );
                                    })}
                                </TabPane>
                                    <TabPane tabId={'3'}>
                                        
                                <p hidden={this.state.loading === false}>Carregando mensagens ...</p>
                                <p hidden={this.state.loading === true || this.state.chat.filter(this.checkFinalize).length > 0}>Nenhum chat encontrado</p>
                                {this.state.chat.map((p, index) => {
                                    if(p.status_chat === 2){
                                        if(this.state.search === ''){
                                            return (
                                                <ChatItemContainner
                                                    cod_chat_current={this.state.current_message}
                                                    cod_chat={p.cod_chat}
                                                >
                                                    <ChatItem
                                                        key={index}
                                                        dateString={p.last_message !== null ? moment(new Date(p.created_at)).format('HH:m') : ' '}
                                                        //avatar={'https://files.zohopublic.com/public?x-service=LiveDesk&event-id=1606357595010_604373000000002052&x-cli-msg=%7B%22x-siq-filetype%22%3A%22fgravatar%22%2C%22x-siq-lsid%22%3A%22604373000000002052%22%2C%22x-siq-soid%22%3A%22730799669%22%2C%22x-siq-ispreview%22%3Afalse%2C%22x-siq-pfname%22%3A%22logo-chat.png%22%2C%22x-siq-downloadtype%22%3A%22default%22%7D'}
                                                        avatarFlexible={true}
                                                        alt={'Reactjs'}
                                                        title={p.nome}
                                                        unread={p.messages_not_read_count}
                                                        onClick={() => this.onMessageCurrent(p.cod_chat)}
                                                        subtitle={p.last_message !== null ? this.chatMessageSubtitle(p.last_message) : ''}
                                                        //date={new Date(p.last_message !== null ? p.last_message.updated_at : p.updated_at)} 
                                                        />
                                                </ChatItemContainner>
                                            );
    
                                        }
                                        else if(
                                            (p.usuario !== null ? p.usuario.nome.indexOf(this.state.search) >= 0 : '') ||
                                            (p.usuario !== null ? p.usuario.sobrenome.indexOf(this.state.search) >= 0 : '') ||
                                            p.cod_chat.indexOf(this.state.search) >= 0
                                        ){
                                            return (
                                                <ChatItemContainner
                                                    cod_chat_current={this.state.current_message}
                                                    cod_chat={p.cod_chat}
                                                >
                                                    <ChatItem
                                                        key={index}
                                                        //avatar={'https://files.zohopublic.com/public?x-service=LiveDesk&event-id=1606357595010_604373000000002052&x-cli-msg=%7B%22x-siq-filetype%22%3A%22fgravatar%22%2C%22x-siq-lsid%22%3A%22604373000000002052%22%2C%22x-siq-soid%22%3A%22730799669%22%2C%22x-siq-ispreview%22%3Afalse%2C%22x-siq-pfname%22%3A%22logo-chat.png%22%2C%22x-siq-downloadtype%22%3A%22default%22%7D'}
                                                        avatarFlexible={true}
                                                        alt={'Reactjs'}
                                                        title={p.nome}
                                                        unread={p.messages_not_read_count}
                                                        onClick={() => this.onMessageCurrent(p.cod_chat)}
                                                        subtitle={p.last_message !== null ? this.chatMessageSubtitle(p.last_message) : ''}
                                                        date={new Date(p.last_message !== null ? p.last_message.updated_at : p.updated_at)} />
                                                </ChatItemContainner>
                                            );
                                        }
                                        return (<></>)
                                    }
                                    else{
                                        return (<></>)
                                    }
                                }
                                )}
                                
                                </TabPane>
                                </TabContent>
                            </Col>
                            <Col md={9}>
                                <Card style={{ height: '100%', maxHeight: '76vh', backgroundColor: '#343a400d' }} hidden={this.state.current_message !== null || this.state.current_chat !== null }>
                                    <CardBody>
                                        <center>
                                            <img src="https://files.zohopublic.com/public?x-service=LiveDesk&event-id=1606357595010_604373000000002052&x-cli-msg=%7B%22x-siq-filetype%22%3A%22fgravatar%22%2C%22x-siq-lsid%22%3A%22604373000000002052%22%2C%22x-siq-soid%22%3A%22730799669%22%2C%22x-siq-ispreview%22%3Afalse%2C%22x-siq-pfname%22%3A%22logo-chat.png%22%2C%22x-siq-downloadtype%22%3A%22default%22%7D" alt="logo"/>
                                            <br /><h4>Aqui aparecerá suas mensagens</h4>
                                        </center>
                                    </CardBody>
                                </Card> 
                                {this.state.current_chat !== null ?
                                    <ChatGrupo
                                        cod_grupo={this.state.current_chat}
                                        fecharChat={this.fecharChat.bind(this)}
                                    />
                                :   <></>}
                                {this.state.current_message !== null ?
                                    <ChatAtendimento
                                        fecharTransferencia={this.fecharTransferencia.bind(this)}
                                        type_chat="atendimento"
                                        cod_chat={this.state.current_message}
                                    />
                                :<></>}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <CreateGroupModal 
                    visible={this.state.modal_group}
                    onClose={this.onOpenCloseNewGroup}
                />
                <div hidden={getUser().permissao_atendimento_cliente}>
                  <Card>
                      <CardBody>
                          <p>Você não tem permissões para acessar essa função</p>
                      </CardBody>
                  </Card>
                </div>
                <audio hidden ref={(input) => {this.notificacao = input}} src={notification} />
            </Div>
        )
    }
}
export default Chat;