import React from 'react';
import { HiViewGrid } from 'react-icons/hi';
import { Button, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getPermissoes, getToken } from '../../../auth/set_services';
import ReactTooltip from 'react-tooltip';

import { IoDocumentsOutline } from 'react-icons/io5';


import { MdChat, MdDelete } from 'react-icons/md';
import 'react-dropdown/style.css';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { DropdownButton } from 'react-bootstrap';
import styled from 'styled-components';


const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const DocumentosDiv = styled.div`
    button{
        padding: 3px;
    }
    .dropdown-menu{
        min-width: 325px;
    }
    .dropdown-toggle::after{
        top: 3px;
        position: relative;
    }
`

export const columns = (onShowDelete) => [
  {
    name: '',
    selector: 'buttons',
    width: '70px',
    cell: row => <Row>
      {onShowDelete ? 
          <Button data-tip="Deletar Cliente"
            outline
            style={{ padding: 2, marginLeft: 7, border: 0, fontWeight: '700'}}
            color="danger"
            onClick={() => onShowDelete(row.id)}
          >
            <MdDelete style={{ padding: '2px' }} />
          </Button> 
        : 
        <></>}
      <Button data-tip="Resumo do Cliente"
        outline
        style={{ padding: '0px', marginRight: '3px', border: 0}}
        color="primary" onClick={() => 
          {
              window.location.href = '/user-pre-venda/'+row.id
          }
        }>
        <HiViewGrid style={{ padding: '2px', width: '23px' }} />
      </Button>
      <ReactTooltip/>
    </Row>
  },
  {
    name: "Data",
    selector: "created_at",
    sortable: false,
    width: '140px',
    cell: row => <div style={{marginTop: 10, marginBottom: 10}}>
      {row.documentos.length > 0 ? 
      <>
        <b>Doc. enviado:</b> {moment(new Date(row.documentos[0].created_at)).format('DD/MM/YYYY HH:mm')} 
        <br/><br/>
      </>: null}
      <b>Data de criação:</b> {moment(new Date(row.created_at)).format('DD/MM/YYYY HH:mm')} 
    </div>
  },
  {
    name: "Nome do Responsavel",
    selector: "nome",
    sortable: false,
    cell: row => <div>
      {row.sobrenome !== null ? row.nome+" "+row.sobrenome : row.nome}<br/>
      <small><b>{row.empresa !== null ? row.empresa.razao_social : 'Conta Pessoal'}</b></small>
    </div>
  },
  /* {
    name: "Empresa",
    selector: "user_tipo",
    sortable: false,
    cell: row => row.empresa !== null ? row.empresa.razao_social : 'Conta Pessoal'
  }, */
  {
    name: "Email do Responsavel",
    selector: "email",
    sortable: false,
    cell: row => row.email
  },
  {
    name: "Documento",
    selector: "cpf",
    sortable: false,
    width: '162px',
    cell: row => {
      var value = row.cpf !== '' && row.cpf !== null ? row.cpf : row.cnpj;
      if(value !== null){
        return <NumberFormat displayType={'text'} value={value} format={value.replace(/([^\d])+/gim, '').length > 11 ?  '##.###.###/####-##' : "###.###.###-##"} />
      }
      return "";
    }
  },
  {
      name: 'Status de Documentos',
      selector: "documents",
      sortable: false,
      width: '162px',
      cell: row =>  <DocumentosDiv>
        {row.documentos_count > 0 ? (
            <DropdownButton 
                outline
                style={{padding: 0}}
                title={<small>Documentos
                <br/>Enviados</small>}
                noCaret={true}
            >
                <div style={{ padding: '10px'}}>
                <small style={{color: 'white'}}>
                    {row.documentos.map((e, i) => (
                        <p style={{ color: getColor(e.status_analise_docs) }}>{i+1} - <b>{e.descricao}</b></p>
                    ))}
                </small>
                </div>
            </DropdownButton>)
            :
            <>Nenhum Documento enviado</>
        }
      </DocumentosDiv>
  },
  {
    name: "Analise de Documentos",
    selector: "cpf",
    sortable: false,
    cell: row => <div>
      <p><b>{getAnalise(row.status_analise_docs)}</b></p>
      <p>{getResponsavel(row)}</p>
    </div>
  },
];

const getColor = (value) => {
  switch(value){
    case 'pending': return '#ffff00';
    case 'aprovado': return '#00ff00';
    case 'reprovado': return '#ff0400';
    default: return 'white';
  }
}

const getAnalise = (value) => {
  switch(value){
    case 'analise_iniciada': return 'Analise de documentos iniciada';
    case 'analise_finalizada': return 'Análise de documentos finalizada';
    case 'pending': return 'Análise de documentos pendente';
    default: return value;
  }
}

const getResponsavel = (row) => {
  /* switch(value){
    case 'analise_iniciada': return 'Analise de documentos iniciada';
    case 'analise_finalizada': return 'Análise de documentos finalizada';
    case 'pending': return 'Análise de documentos pendente';
    default: return value;
  } */
  if(row.status_analise_docs === 'analise_iniciada'){
    return <><b>Analise Iniciada por:</b> {row.responsavel_inicio_analise !== null ? row.responsavel_inicio_analise.name : ''}</>
  }
  else if(row.status_analise_docs === 'analise_finalizada'){
    return <><b>Analise Iniciada por:</b>{row.responsavel_inicio_analise !== null ? row.responsavel_inicio_analise.name : ''}<br/>
             <b>Analise Finalizada por:</b>{row.responsavel_final_analise !== null ? row.responsavel_final_analise.name : ''}</>

  }
  else{
    return '-';
  }
}

export const getRows = (state, callback, pending = false) => {
  var url = ENDPOINT + "user_chat/all?page=" + state.activePage + "&search=" + state.search;
  if(pending === true){
    url+= "&is_pending="+pending;
  }
  var document = state.document.replace(/([^\d])+/gim, '');
  if(state.is_cnpj === false){
    if(document.length === 11){
      url += "&cpf="+document;
    }
  }
  else{
      if(document.length === 14){
        url += "&cnpj="+document;
      }
  }

  /* if(state.type_user.length > 0){
    url += "&tipo_usuario="+state.type_user
  } */

  if(state.status_user.length > 0){
    url += "&status_usuario="+state.status_user
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      callback(p.data, p.last_page)
    })
}

export const getRowsPerPage = (page, state, callback, pending = false) => {
    var url = ENDPOINT + "user_chat/all?page=" + page + "&search=" + state.search;
    if(pending === true){
      url+= "&is_pending="+pending;
    }
  var document = state.document.replace(/([^\d])+/gim, '');

  if(state.is_cnpj === false){
    if(document.length === 11){
      url += "&cpf="+document;
    }
  }
  else{
      if(document.length === 14){
        url += "&cnpj="+document;
      }
  }

/*   if(state.type_user.length > 0){
    url += "&tipo_usuario="+state.type_user
  } */


  if(state.status_user.length > 0){
    url += "&status_usuario="+state.status_user
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      callback(p.data, p.last_page)
    })
}