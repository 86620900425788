import { useState } from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

export function ListReasons({ reasons }) {
  const reasonsValues = Object.values(reasons);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
     
    <Button
      data-tip="Motivos"
      outline
      style={{ padding: "0px", marginRight: "3px", border: 0 }}
      color="primary"
      onClick={() => setIsOpen(!isOpen)}
    >
        
        <IoMdInformationCircleOutline style={{ padding: "2px", width: "23px" }} />
        { !isOpen &&<IoIosArrowDown/>}
        { isOpen &&<IoIosArrowUp/>}
    </Button>
    <ListGroup>
        {isOpen && reasonsValues?.map((reason, index) => {
            if(!reason) return
            return(
            <ListGroupItem key={index}>
                {reason}
            </ListGroupItem>)
        })}
    </ListGroup>
    </div>
  );
}
