import axios from "axios";
import React, { Component } from "react";
import { Button, Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import { InputMasked } from "../../InputText/InputMasked";
import { InputText } from "../../InputText/InputText";
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import { toast } from "react-toastify";

class EnderecoCadastroAQPasso extends Component{
    constructor(props){
        super(props);
        //console.log('props', props.address)
        this.state = {
            cep: props.address?.cep ? props.address?.cep : '',
            logradouro: props.address?.logradouro ? props.address?.logradouro : '',
            logradouro_invalid: false,
            numero: props.address?.numero ? props.address?.numero : '',
            numero_invalid: false,
            complemento: props.address?.complemento ? props.address?.complemento : '',
            complemento_invalid: false,
            bairro: props.address?.bairro ? props.address?.bairro : '',
            bairro_invalid: false,
            cidade: props.address?.cidade ? props.address?.cidade : '',
            cidade_invalid: false,
            uf: props.address?.uf ? props.address?.uf : '',
            uf_invalid: false,
            loading: false,
            error_message: '',
            success_message: '',
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        
        this.setState({[name]: value});

        if(name === 'cep'){
            if(value.replace(/([^\d])+/gim, '').length > 7){
                this.getCep(value)
            }
        }
    }
    getCep = (value) => {
        var url = ENDPOINT+'user/search_cep?cep='+value;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            if(data.success === true){
                this.setState({
                    logradouro: data.data.end,
                    complemento: data.data.complemento2,
                    uf: data.data.uf,
                    bairro: data.data.bairro,
                    cidade: data.data.cidade
                })
            }
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            this.onData(data);
            return data;
        });

    }

    storeUser = () => {
        var url = `${ENDPOINT}aqpasso_edit/user_address/${this.props.user_id}`;

        axios.put(url, {
            zip: this.state.cep,
            street: this.state.logradouro,
            number: this.state.numero,
            complement: this.state.complemento,
            district: this.state.bairro,
            city: this.state.cidade,
            uf: this.state.uf,
            user_id: this.props.user_id,
            country: 'Brasil',
        }, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            this.props.onRefresh();
            toast.success('Atualizado com sucesso!')
            this.onClose()
        })
        .catch((error) => {
            toast.error('Falha ao atualizar!')
            console.log(error)
            this.onClose()
        });
    }

    onData = (data) => {
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onEdit = () => {
        if(this.validateFields() === 0){
            this.setState({loading: true});
            this.storeUser();
        }
    }
    validateFields = () => {
        var invalids = 0;
        if(this.state.cep.replace(/([^\d])+/gim, '').length > 7){
            this.setState({cep_invalid: false})
        }
        else{
            invalids++;
            this.setState({cep_invalid: true})
        }


        if(this.state.logradouro.length > 0){
            this.setState({logradouro_invalid: false})
        }
        else{
            invalids++;
            this.setState({logradouro_invalid: true})
        }

        if(this.state.numero.length > 0){
            this.setState({numero_invalid: false})
        }
        else{
            invalids++;
            this.setState({numero_invalid: true})
        }

        /* if(this.state.complemento.length > 0){
            this.setState({complemento_invalid: false})
        }
        else{
            invalids++;
            this.setState({complemento_invalid: true})
        } */

        if(this.state.bairro.length > 0){
            this.setState({bairro_invalid: false})
        }
        else{
            invalids++;
            this.setState({bairro_invalid: true})
        }

        if(this.state.cidade.length > 0){
            this.setState({cidade_invalid: false})
        }
        else{
            invalids++;
            this.setState({cidade_invalid: true})
        }

        if(this.state.uf.length > 0){
            this.setState({uf_invalid: false})
        }
        else{
            invalids++;
            this.setState({uf_invalid: true})
        }

        return invalids
    }
    onClose = () => {
        this.setState({
            logradouro_invalid: false,
            numero_invalid: false,
            complemento_invalid: false,
            bairro_invalid: false,
            cidade_invalid: false,
            uf_invalid: false,
            loading: false,
            error_message: '',
            success_message: '',
        });
        this.props.onClose(false);
    }

    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }
    
    render(){
        return (
            <Modal isOpen={this.props.visible}>
                <ModalHeader>Editar Endereco</ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <h6><b>CEP: </b> </h6>
                                <InputMasked
                                    name="cep"
                                    mask="99999-999"
                                    value={this.state.cep}
                                    placeholder="Digite o cep"
                                    invalid={this.state.cep_invalid}
                                    onChange={this.handleChange}/>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Logradouro: </b> </h6>
                                <InputText 
                                    name="logradouro"
                                    value={this.state.logradouro}
                                    placeholder="Digite o nome da Rua"
                                    invalid={this.state.logradouro_invalid}
                                    onChange={this.handleChange}/>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Número: </b> </h6>
                                <InputText 
                                    name="numero"
                                    value={this.state.numero}
                                    placeholder="Digite o numero"
                                    invalid={this.state.numero_invalid}
                                    onChange={this.handleChange}/>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Complemento: </b> </h6>
                                <InputText 
                                    name="complemento"
                                    value={this.state.complemento}
                                    placeholder="Digite o complemento"
                                    invalid={this.state.complemento_invalid}
                                    onChange={this.handleChange}/>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <h6><b>Bairro: </b> </h6>
                                <InputText 
                                    name="bairro"
                                    value={this.state.bairro}
                                    placeholder="Digite o bairro"
                                    invalid={this.state.bairro_invalid}
                                    onChange={this.handleChange}/>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Cidade: </b> </h6>
                                <InputText 
                                    name="cidade"
                                    value={this.state.cidade}
                                    placeholder="Digite o cidade"
                                    invalid={this.state.cidade_invalid}
                                    onChange={this.handleChange}/>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Estado (UF): </b> </h6>
                                <InputText 
                                    name="uf"
                                    maxLength={2}
                                    value={this.state.uf}
                                    placeholder="Digite o uf"
                                    invalid={this.state.uf_invalid}
                                    onChange={this.handleChange}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalBody 
                    style={{
                        minHeight: '114px', 
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn 
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                        />
                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose()}>Cancelar</Button>
                    <Button color="success" onClick={() => this.onEdit()}>Editar Endereço</Button>
                </ModalFooter>
            </Modal>
        )
    }

}

export default EnderecoCadastroAQPasso;