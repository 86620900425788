import React, { Component } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { Card, CardBody, Col, Row } from 'reactstrap';
import { getRows, getRowsPerPage } from "../../../components/Tables/TableFinanceiro/Regimes/propsRegimes";
import { TableRegimes } from "../../../components/Tables/TableFinanceiro/Regimes/TableRegimes";
import Moment from "moment";
import Pusher from 'pusher-js';

class Regimes extends Component {
    constructor(props){
        super(props);
        this.state = {
            search: '',
            loading_table: true,
            edit_id: -1,
            edit_user: null,
            rowData: [],
            cpf_cnpj: '',
            is_seller: false,
            forma_pagamento: null, 
            data_pedido: null,
            origem_pagamento: null,

            status_processo: null,
            status_pagamento: null,
            reclamado: null,

            admin_inicio: null,
            admin_fim: null,
            numero_pedido: '',
            totalPage: 0,
            activePage: 1,
            total_NVO: '0.00',
            total_AMA: '0.00',
            total_RPM: '0.00',
            total_APM: '0.00',
            is_cnpj: false,
            document: '',
            status: { value: "ANALISANDO", label: "Antifraude - Analise Pendente" },
            filter: false,
            searching: false,
            show_filter: false,
            valor_de: '0.00',
            valor_ate: '0.00',
            payload_current: {},
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
        this.refDocument = React.createRef();
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    handleChange = (event) => {
        var state = this.state;
        let name = event.target.name;
        let value = event.target.value;

        if(name === 'cpf_cnpj'){
            if(value.replace(/([^\d])+/gim, '').length > 10){
                state[name] = value;
                if(state.origem_pagamento == null){
                    state.origem_pagamento = { value: "cobrancas", label: "Link de Pagamento" };
                }
                state.activePage = 1;
                state.rowData = [];
                state.loading_table = true;
                this.setState({...state});
                
                getRowsPerPage(1, state, this.callbackRows);
            }
            else if(value.replace(/([^\d])+/gim, '').length === 0){
                state[name] = value;
                state.activePage = 1;
                state.rowData = [];
                state.loading_table = true;
                this.setState({...state});
                
                getRowsPerPage(1, state, this.callbackRows);
            }
        }
        else{
            state[name] = value;
            state.activePage = 1;
            state.rowData = [];
            state.loading_table = true;
            this.setState({...state});
            
            getRowsPerPage(1, state, this.callbackRows);
        }
    }

    componentDidMount(){
        getRows(this.state, this.callbackRows.bind(this));
    }
    filterShow(){
        this.setState({
            show_filter: !this.state.show_filter
        })
    }

    onChangeSelect = (name, value) => {
        var state = this.state;
        state.rowData = [];
        state.activePage = 1;
        state.loading_table = true;
        state[name] = value;
        this.setState({...state});

        getRows(state, this.callbackRows.bind(this));
    }

    callbackRows = (rowData, totalPage) => {
        this.setState({rowData, totalPage, loading_table: false, searching: false})
    }

    onPageChange = (activePage) => {
        var state = this.state;

        state.activePage = activePage;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});

        getRowsPerPage(activePage, state, this.callbackRows.bind(this));
    }

    onFilter = (filter) => {
        var state = this.state;
        state.filter = filter;
        state.activePage = 1;
        this.setState({ ...state });
        getRowsPerPage(state.activePage, state, this.callbackRows.bind(this));
    }
    onSellerDocument = (row) => {
        var document = row.individual !== null ? row.individual.taxpayer_id : row.business.ein;

        var state = this.state;
        state.rowData = [];
        state.loading_table = true;
        state.document = document;
        state.is_cnpj = document.length > 13 ? true : false;
        state.activePage = 1;
        this.setState({ ...state });

        this.refDocument.current.value = document;
        
        getRowsPerPage(1, state, this.callbackRows.bind(this));
    }
    onSellerIs = (is_seller) => {
        var state = this.state;
        state.rowData = [];
        state.loading_table = true;
        state.is_seller = is_seller;
        state.activePage = 1;
        this.setState({ ...state });

        getRowsPerPage(state.activePage, state, this.callbackRows.bind(this));
    }

    onGetRows = () => {
        if(this.state.searching == false){
            console.log('Okay')
            this.setState({ searching: true });
            getRows(this.state, this.callbackRows.bind(this));
        }

    }


    render(){
        return (
            <Row>
                <Col md={12}>
                    <TableRegimes
                        isSearch={this.handleChange}
                        onChangeSelect={this.onChangeSelect}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        onSellerIs={this.onSellerIs.bind(this)}
                        onSellerDocument={this.onSellerDocument}
                        data={this.state.rowData}
                        state={this.state}
                        is_cnpj={this.state.is_cnpj}
                        show_filter={this.state.show_filter}
                        onFilter={this.onFilter.bind(this)}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                        is_seller={this.state.is_seller}
                        filter={this.state.filter}
                        refDocument={this.refDocument}
                        filterShow={this.filterShow.bind(this)}
                        onPageChange={this.onPageChange}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        editRegime={this.props.editRegime.bind(this)}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhum regime encontrado
                            </div></CardBody></Card>
                        }
                    />
                </Col>
            </Row>            
        )
    } 
}

export default Regimes;