import axios from "axios";
import moment from "moment";
import { Component } from "react";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import Select from 'react-select';
import ReactRoundedImage from "react-rounded-image";
import TicketClientFromDocument from "../../components/ResumeClient/TicketClientFromDocument";
import OcorrenciasClientFromCpf from "../../components/ResumeClient/OcorrenciasClientFromCpf";

const get_id = () => {
    return window.location.pathname.replace('/gestor-licencas/', '')
}

const getOperadores = (callback) => {
    var url = ENDPOINT + "chat-atendimento/consultar-operadores";

    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    })
        .then((response) => {
            var data = response.data;
            var operadores_list = [];
            data.forEach((p, i) => {
                p['value'] = p.id;
                p['name'] = p.name;
                p['label'] = <div style={{ display: 'flex' }}>
                    <ReactRoundedImage
                        image={p['avatar_foto'] !== null ? p['avatar_foto'] : ''}
                        roundedColor="#8dd199"
                        roundedSize="4"
                        imageWidth="25"
                        imageHeight="25" />
                    <p style={{ display: 'flex', margin: 0 }}>
                        &nbsp;
                        {p.name}
                    </p>
                </div>;
                operadores_list.push(p);
            });

            callback(operadores_list)
        });
}
const getUser = (id, onData) => {
    var url = ENDPOINT+"autorizacao/get/"+id;
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });

}
class ResumoGestorAutorizacao extends Component{
    constructor(props){
        super(props);
        this.state = {
            autorizacao_id: get_id(),
            empresa: '',
            data_abertura: '2022-01-01 00:0',
            solicitante_name: '',
            cpf_cnpj_cliente: null,
            protocolo_ticket: '',
            client_name: '',
            ocorrencia_interna: '',
            protocolo: '',
            motivo: '',
            prazo_resposta: '2022-01-21 00:00',
            autorizador_name: '',
            status_solicitacao: '',
            message_auth: '',
            nota_text: '',
            ver_tickets: false,
            ver_correncias: false,
            prazo_message: '',
            cod_ticket: null,
            arquivo_url_one: null,
            arquivo_url_two: null,
            arquivo_url_three: null,
            responder: false,
            autorizado: null,
            autorizado_invalid: false,
            messages: [],
            notas: [],
            links: [],
            auth_request: null,
            operador_solicitado: false,
            edit_socilitacao: false
        }
    }

    componentDidMount(){
        getUser(this.state.autorizacao_id, this.callbackRows.bind(this));
        getOperadores(this.callbackOperadores.bind(this));
    }

    callbackOperadores = (operadores_list) => {
        this.setState({ operadores_list });
    }

    callbackRows(data) {
        this.setState({
            ...data.data,
            auth_request: data.data
        })
    }

    responderAutorizacao = (id, onData) => {
        toast.info('Enviando, por Favor aguarde');
        var url = ENDPOINT + "autorizacao/responder/"+id;
    
        var formData = new FormData();
        formData.append('message', this.state.message_auth);
        axios.post(url, formData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                onData(data);
                return data;
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    onData(obj);
                    return obj;
                }
                var data = error.response.data;
                onData(data);
                return data;
            });
    
    }

    updateStatus = (id, status, onData) => {
        if(this.state.nota_text.length == 0){
            if(this.state.status_solicitacao == 'DEVOLVER INFORMAÇÕES' || this.state.status_solicitacao == 'INFORMAÇÃO DE SETOR'){
                toast.success('É necessario incluir a mensagem ')
            }
            else{
                toast.success('É necessario incluir a nota ')
            }

            return;
        }
        var url = ENDPOINT + "autorizacao/update_status/"+id;
    
        var formData = new FormData();
        formData.append('id', id);
        formData.append('status_solicitacao', status);

        if(this.state.status_solicitacao == 'DEVOLVER INFORMAÇÕES'){
            formData.append('message', this.state.nota_text);
        }
        else if(this.state.status_solicitacao == 'INFORMAÇÃO DE SETOR'){
            formData.append('message', this.state.nota_text);

            if(this.state.autorizado == null){
                toast.success('É necessario selecionar o operador')
            }
            else{
                formData.append('autorizado_id', this.state.autorizado.id);
            }
            formData.append('prazo_message', this.state.prazo_message);
        }
        else{
            formData.append('nota', this.state.nota_text);
        }
    
        toast.info('Enviando, por Favor aguarde');
        axios.post(url, formData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                onData(data);
                return data;
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    onData(obj);
                    return obj;
                }
                var data = error.response.data;
                onData(data);
                return data;
            });
    
    }
    onDataToast(data) {
        if (data.success === true) {
            toast.success(data.message);

            this.setState({
                edit_socilitacao: false
            })
            getUser(this.state.autorizacao_id, this.callbackRows.bind(this));
        }
        else {
            toast.error(data.errors);
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({ [name]: value });
    }
    render(){
        return (
            <div>
                <h3>Resumo Autorização</h3>
                <br/>
                <Card>
                    <CardBody>
                        {this.state.responder == true ?
                        <Form>
                            <FormGroup>
                                <Input
                                    name="message_auth"
                                    value={this.state.message_auth}
                                    type="textarea"
                                    placeholder="Resposta"
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Button 
                                    color="success"
                                    onClick={() => {
                                        this.responderAutorizacao(this.state.autorizacao_id, this.onDataToast.bind(this))
                                    }}>
                                        Salvar
                                </Button>
                            </FormGroup>
                        </Form>
                        :
                        null}
                        <p><b>Empresa:</b> {this.state.empresa}</p>
                        <p><b>Data de Abertura:</b> {moment(this.state.data_abertura).format('DD/MM/YYYY HH:mm')}</p>
                        <p><b>Solicitante:</b> {this.state.solicitante_name}</p>
                        <p><b>Nome do cliente:</b> {this.state.client_name}</p>
                        <p><b>Processo Relativo ao cliente:</b> {this.state.cpf_cnpj_cliente}</p>
                        <p><b>Protocolo de Ticket:</b> {this.state.protocolo_ticket} <a hidden={this.state.protocolo_ticket == null} href={"/ticket-detalhe/"+this.state.protocolo_ticket}>Ver ticket</a> 
                        {this.state.cpf_cnpj_cliente ? 
                            <Button color="primary" style={{ padding: '3px'}} onClick={() => this.setState({ ver_tickets: true})}>Ver Tickets do cliente</Button>
                            :
                            null
                        }
                        </p>
                        <p><b>Ocorrencia Interna:</b> {this.state.ocorrencia_interna} <a hidden={this.state.ocorrencia_interna == null}href={"/ocorrencia-detalhe/"+this.state.ocorrencia_interna}>Ver ocorrência</a>
                        {this.state.cpf_cnpj_cliente ? 
                            <Button color="primary" style={{ padding: '3px'}} onClick={() => this.setState({ ver_correncias: true})}>Ver Ocorrências do cliente</Button>
                            :
                            null
                        }
                        </p>
                        <p><b>Protocolo:</b> {this.state.protocolo}</p>
                        <p><b>Motivo da Solicitação:</b> {this.state.motivo}</p>
                        <p><b>Prazo de Resposta:</b> {moment(this.state.prazo_resposta).format('DD/MM/YYYY HH:mm')}</p>
                        <p><b>Autorizador:</b> {this.state.autorizador_name}</p>
                        {this.state.arquivo_url_one !== null ?
                            <p><b>Anexo enviado:</b> <a href={this.state.arquivo_url_one+'?access_token='+getToken()} target="_blank">Abrir</a> </p>
                            :
                            <></>
                        }
                        {this.state.arquivo_url_two !== null ?
                            <p><b>Anexo enviado:</b> <a href={this.state.arquivo_url_two+'?access_token='+getToken()} target="_blank">Abrir</a> </p>
                            :
                            <></>
                        }
                        {this.state.arquivo_url_three !== null ?
                            <p><b>Anexo enviado:</b> <a href={this.state.arquivo_url_three+'?access_token='+getToken()} target="_blank">Abrir</a> </p>
                            :
                            <></>
                        }
                        {this.state.edit_socilitacao == true ?
                        <div>
                            <Form>
                                <FormGroup>
                                    <Input 
                                        type="select" 
                                        style={{borderRadius: '0'}} 
                                        value={this.state.status_solicitacao}
                                        onChange={e => {
                                            this.setState({ status_solicitacao: e.target.value })
                                            //this.updateStatus(this.state.autorizacao_id, e.target.value, this.onDataToast.bind(this))
                                        }}>
                                        <option value="AGUARDANDO RESPOSTA">Aguardando resposta</option>
                                        <option value="REPROVADO">Reprovado</option>
                                        <option value="APROVADO">Aprovado</option>
                                        <option value="DEVOLVER INFORMAÇÕES">Devolver mais Informações</option>
                                        <option value="INFORMAÇÃO DE SETOR">Informação de Setor</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        name="nota_text"
                                        value={this.state.nota_text}
                                        type={this.state.status_solicitacao == 'DEVOLVER INFORMAÇÕES' || this.state.status_solicitacao == 'INFORMAÇÃO DE SETOR' ? "textarea" : "text"}
                                        placeholder={this.state.status_solicitacao == 'DEVOLVER INFORMAÇÕES' || this.state.status_solicitacao == 'INFORMAÇÃO DE SETOR' ? "Insira sua mensagem" : "Insira a nota"}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>
                                <FormGroup hidden={this.state.status_solicitacao != 'INFORMAÇÃO DE SETOR'}>
                                    <Label>Pedir autorizaçao para:</Label>
                                    <Select
                                        placeholder="Autorizador"
                                        isSearchable={false}
                                        options={this.state.operadores_list}
                                        value={this.state.autorizado}
                                        onChange={(autorizado) => this.setState({ autorizado })}
                                        noOptionsMessage={() => { return 'Sem opções' }}
                                    />
                                    <Input hidden invalid={this.state.autorizado_invalid}/>
                                    <FormFeedback>Campo obrigatório</FormFeedback>
                                </FormGroup>
                                <FormGroup hidden={this.state.status_solicitacao != 'INFORMAÇÃO DE SETOR'}>
                                    <b><small>Prazo de retorno:</small></b>
                                    <Input
                                        name="prazo_message"
                                        value={this.state.prazo_message}
                                        type="datetime-local"
                                        placeholder={"Insira o prazo de retorno"}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Button 
                                        color="success"
                                        onClick={() => {
                                            this.updateStatus(this.state.autorizacao_id, this.state.status_solicitacao, this.onDataToast.bind(this))
                                        }}>
                                            Salvar
                                    </Button>
                                </FormGroup>
                            </Form>
                        </div>
                            :
                            <p>
                                <b>Status da solicitacao:</b> {this.state.status_solicitacao}
                                <br/>
                                <Button hidden={!this.state.operador_solicitado} onClick={() => this.setState({edit_socilitacao: true})}>Autorizar/Reprovar acesso</Button>
                            </p>
                        }
                        <p><b>Notas:</b> </p>
                        <small>
                            {this.state.notas.map(e => {
                                return <>{moment(e.created_at).format('DD/MM/YYYY HH:mm')} - <b>{e.admin !== null ? e.admin.name : 'Sem Informações'}:</b> {e.descricao}<br/></>
                            })}
                            {this.state.notas.length === 0 ? <><b>Nenhuma nota</b></> : ''}
                        </small>
                        <br/>
                        <p><b>Mensagens:</b> </p>
                        <small>
                            {this.state.messages.map(e => {
                                return <>{moment(e.created_at).format('DD/MM/YYYY HH:mm')} - <b>{e.admin_name}:</b> {e.message}<br/></>
                            })}
                            {this.state.messages.length === 0 ? <><b>Nenhuma nota</b></> : ''}
                        </small>
                        <br/>
                        <p><b>Demanda interna no CRM do Departamento:</b> </p>
                        <small>
                            {this.state.links.map(e => {
                                return <><b>{e.departamento}:</b> <a href={e.url} target="_blank">{e.url}</a><br/></>
                            })}
                            {this.state.links.length === 0 ? <><b>Nenhuma nota</b></> : ''}
                        </small>
                    </CardBody>
                </Card>
                {this.state.cpf_cnpj_cliente ? 
                    <div>
                        <Modal isOpen={this.state.ver_tickets} size="xl">
                            <ModalHeader>
                                <Button color="danger" onClick={() => this.setState({ ver_tickets: false })}>Fechar</Button>
                            </ModalHeader>
                            <ModalBody>
                                <TicketClientFromDocument
                                    cpf_cnpj={this.state.cpf_cnpj_cliente}
                                />
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={this.state.ver_correncias} size="xl">
                            <ModalHeader>
                                <Button color="danger" onClick={() => this.setState({ ver_correncias: false })}>Fechar</Button>
                            </ModalHeader>
                            <ModalBody>
                                <OcorrenciasClientFromCpf
                                    cpf_cnpj={this.state.cpf_cnpj_cliente}
                                />
                            </ModalBody>
                        </Modal>
                    </div>
                    :
                    <></>
                }
            </div>
        )
    }
}

export default ResumoGestorAutorizacao;