import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Card, CardBody } from "reactstrap"
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import { InputMasked } from "../../components/InputText/InputMasked"

export const ConsultarClienteAqpago = () => {
    const [is_cnpj, setIsCnpj] = useState(false);
    const [cpf_cnpj, setCpfCnpj] = useState('');

    const getUser = (callback) => {
        var url = ENDPOINT + "user/get_aqbank/"+cpf_cnpj.replace(/([^\d])+/gim, '');
    
        var myHeaders = new Headers();
    
        myHeaders.append('Authorization', 'Bearer ' + getToken());
    
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            callback(p);
        })
    }

    const callback_aqbank = (data) => {
        if(data.success === true){
            window.location.href = '/aqpago/'+cpf_cnpj.replace(/([^\d])+/gim, '')
        }
        else{
            toast.error(data.errors);
        }
    }
    const onGet = () => {
        getUser(callback_aqbank);
    }

    return (
        <>
        <h3>Consultar Clientes AQPago</h3>
        <hr />
        <Card>
            <CardBody>
                <InputMasked
                    mask={is_cnpj === true ? "99.999.999/9999-99" : "999.999.999-99"}
                    onChange={(e) => setCpfCnpj(e.target.value)}
                />
                <div>
                    <input 
                        type="checkbox"
                        checked={is_cnpj}
                        value={cpf_cnpj}
                        onChange={() => setIsCnpj(!is_cnpj)}/> Pesquisar CNPJ
                </div>
                    <br/>
                <Button onClick={() => onGet()}>Buscar</Button>
            </CardBody>
        </Card>
        </>
    )
}