import React, { Component } from "react";
import { Button, ButtonGroup, Collapse, Card, CardFooter, Col, Input, Modal, ModalFooter, Row, TabContent, Nav, NavLink } from "reactstrap";
// RCE CSS
import 'react-chat-elements/dist/main.css';
import { MessageBox } from 'react-chat-elements';
import { MdAttachFile, MdAttachMoney, MdBlock, MdCallMade, MdChat, MdClear, MdFileDownload, MdImage, MdMail, MdMic, MdNote, MdOpenInBrowser, MdReply, MdSend, MdSupervisorAccount, MdViewList } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import { BiTransfer } from 'react-icons/bi';
import { toast } from "react-toastify";
import styled, {keyframes} from 'styled-components';
import Dropzone from "react-dropzone";
import Pusher from 'pusher-js';
import { ENDPOINT } from '../../auth/endpoint';
import axios from "axios";
import { getUser, getToken } from "../../auth/set_services";
import PropagateLoader from "react-spinners/PropagateLoader";
import Select from "react-select";
import { ResumoClient } from "../../components/ChatClient/ResumoClient";
import classnames from 'classnames';
import StarRating from 'react-star-ratings';
import { Dropdown, DropdownButton, Form, FormGroup } from 'react-bootstrap';
import get_extension from 'get-url-extension';
import FadeLoader from "react-spinners/FadeLoader";
import { ChatsRecentes } from "./ChatsRecentes";
import { NotasPrivadas } from "./NotasPrivadas";
import { LineAuth } from "../LineAuth/LineAuth";
import ReactRoundedImage from "react-rounded-image";
import TextareaAutosize from 'react-textarea-autosize';
import { RiChatHistoryFill, RiChatOffFill } from "react-icons/ri";
import Moment from 'moment';
import AsyncSelect from 'react-select/async';
import { InformacoesAqbank } from "./InformacoesAqbank";

const Div = styled.div`
    .msg{
        margin-top: 7px;
        font-size: 14px;
        color: black !important;
        margin-right: 5px;
        resize: none;
        height: auto !important;
        max-height: 93px !important;
        border-right: none;
        border-left: none;
        border-top: none;
        border-bottom: none;
        overflow: auto;
        box-shadow: none !important;
        border: none !important;
        line-height: 14px;
        outline: 0px solid transparent !important;
    }
    .msg:focus{
        box-shadow: none !important;
        border: none !important;
    }
    .widget_btn{
        padding: 0;
        padding-left: 2.5px;
        padding-right: 2.5px;
        border: none;
        box-shadow: none !important;
    }
    .talk_btn:hover{
        color: black;
        background: transparent !important;
        box-shadow: none !important;
    }
    
    .talk_btn:active{
        background: transparent !important;
        box-shadow: none !important;
    }
    
    .talk_btn:visited{
        background: transparent !important;
        box-shadow: none !important;
    }
    
    .talk_btn:active{
        background: transparent !important;
        box-shadow: none !important;
    }
    audio{
        height: 35px;
    }
    .nav-item{
        margin: 0
    }
    .nav-link{
        padding: 4px;
        padding-right: 6px;
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
    .rce-citem-avatar{
        display: none;
    }
    .rce-mbox-left-notch {
        position: absolute;
        left: -14px;
        top: 0px;
        width: 24px;
        height: 15px;
        fill: white;
    }
    .rce-mbox-right .rce-mbox-time{
        color: rgba(0, 0, 0, 0.45);
    }
    .rce-mbox-photo--img__block {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        border-radius: 5px;
        display: flex;
        max-width: 300px;
    }
    .rce-mbox-photo--img {
        min-height: 180px;
    }
    .rce-mbox-photo--img img {
        min-height: 180px;
        -webkit-user-select: none;
        user-select: none;
        object-fit: cover;
    }
    .rce-mbox-photo--download {
        color: #efe;
        display: flex;
        justify-content: center;
        background: none;
        border: none;
        font-size: 3.2em;
        outline: none;
        border: 1px solid #eee;
        border-radius: 100%;
        height: 92px;
        width: 92px;
    }
    
    .rce-mbox-photo--download svg{
        vertical-align: middle;
        place-self: center;
        padding: 0;
    }
    .dropdown-image .btn{
        background: transparent;
        border-radius: 50%;
        border: 1px solid;
        height: 50%;
        width: 50%;
    }
    .rce-mbox-text img{
        height: 143px
    }
    .rce-mbox-text video{
        height: 143px
    }
    .audio-react-recorder{
        display: none
    }
`;

const DropdownCurrent = styled.div`
    .btn{
        display: inline-grid;
        width: 24px;
        border: 0;
        transform-style: flat;
        background-color: transparent;
        padding: 0;
        position: relative;
        float: ${props => (props.type_message === 0 ? 'right' : 'left' )};
    }
    svg {
        width: 1.3rem;
        height: 1.3rem;
    }
  
    .chat-reposta { 
        display: grid;
        position: relative;
        top: ${props => (props.type_message === 0 ? '5px' : '5px' )};
        float: ${props => (props.type_message === 0 ? 'right' : 'left' )};
    }
    .chat-reposta .btn-danger svg{
        color: #e11a1a;
        width: 1.3rem;
        height: 1.3rem;
        margin-left: 5px;
    }

    .chat-reposta .btn-info svg{
        color: #0066ff;
        width: 1.5rem;
        height: 1.5rem;
        padding: 3px;
    }
  
`;
const DropArea = styled.div`
    border-style: dashed;
    border-color: #8300b6;
    padding: 1%;
    cursor: pointer;
    &:drag {
        opacity: 0.5;
    }
`;
const ButtonUpload = styled.button`
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0;
    padding-left: 7px;
    padding-right: 7px;
    top: -1px;
    position: relative;
`;

const breatheAnimation = keyframes`
    0% { opacity: 1; }
    100% { opacity: 0; }
`;

const Recorder = styled.div`
 height: 30px;
 width: 30px;
 animation-name: ${breatheAnimation};
 animation-duration: 2s;
 animation-iteration-count: infinite;
 svg {
     width: 2rem;
     color: red;
}
`;
const ModalBody = styled.div`
    svg {
        color: #52c565;
        width: 2rem;
        height: 1.5rem;
    }
    small {
        color: #52c565;
    }
`;

const MessageBoxArea = styled.div`
  .dropdown-toggle:after { 
    content: none 
  }
  .dropdown-toggle:before { 
    content: none 
  }
  .dropdown-image{
      text-align: center;
  }
`;

const getOperadores = (callback) => {
    var url = ENDPOINT + "chat-atendimento/consultar-operadores";
    
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        var operadores_list = [];
        data.forEach((p, i) => {
            p['value'] = p.id;
            p['label'] = <div style={{display: 'flex'}}>
                <ReactRoundedImage
                    image={p['avatar_foto'] !== null ? p['avatar_foto'] : ''}
                    roundedColor="#8dd199"
                    roundedSize="4"
                    imageWidth="25"
                    imageHeight="25"/> 
                    <p style={{display: 'flex', margin: 0}}>
                        &nbsp;
                        {p.name}
                    </p>
            </div>;
            operadores_list.push(p);
        });

        callback(operadores_list)
    });
}
class ChatAtendimento extends Component {
    constructor(props){
        super(props);
        this.state = {
            chat: [],
            file: [],
            activeTab: "1",
            descricao: '',
            modal_image: false,
            urlImage: '',
            motivo: '',
            latitude: null,
            longitude: null,
            loading_note: false,
            departamento: null,
            operador: null,
            operadores_list: [],
            departamento_chat: [],
            current_message: null,
            sending_message: false,
            masked_message: null,
            respostas_prontas: [],
            modal_anexo: false,
            modal_transfer: false,
            currentChat: null,
            chat_info: null,
            is_type_message: false,
            audio_visible: false,
            recording_audio: null,
            pusher_instance: null,
            loading_chat: true,
            pendencia_current: null,
            message: '',
            check_status_user: {
                vendas: 0,
                suporte: 0
            },
            consulta_mensagens_enabled: true,
            list_departamentos: [],
            isOpen: false,
            disabled_pending: false,
            record_instance: null
        }
        this.sendMessage = this.sendMessage.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.setLocation = this.setLocation.bind(this);
        this.getLocation = this.getLocation.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.myRef = React.createRef();
        this.inputFile = React.createRef();
        this.stream_video_ref = React.createRef();
        this.stream_video_client_ref = React.createRef();
    }
    getLocation = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(this.setLocation);
        } 
        else { 
            console.log('localização não encontrada')
        }
    }

    atualizar_todos_status = (status, type_message) => {
        var chat_current = this.state.chat;

        chat_current.forEach((e, index) => {
            if(e.type_message == type_message){
                e.status_message = status;
            }
        });

        this.setState({chat: chat_current});
        //this.setState({chat: chat_current});
    }

    atualizar_chat = (mensagem) => {
        if(mensagem.cod_chat == this.props.cod_chat){
            var message_exists = null;
            this.state.chat.forEach(e => {
                console.log(mensagem)
                if(e.id == mensagem.id){
                    message_exists = e;
                }
            });
    
            if(message_exists !== null){
                this.setDeleteMessage(message_exists.cod_chat_message);
            }
            else{
                var chat_current = this.state.chat;
                chat_current.push(mensagem);
                this.setState({chat: chat_current});
    
                
                if (this.myRef.current !== null) {
                    setTimeout(() => 
                    {
                        try{
                            if(this.myRef.current.scrollTop){
                                this.myRef.current.scrollTop = this.myRef.current.scrollHeight;
                            }
    
                        }
                        catch(e){
                            
                        }
                    },
                    500);
                }
            }

        }
    }

    

    atualizar_todos_status = (status, type_message) => {
        var chat_current = this.state.chat;

        chat_current.forEach((e, index) => {
            if(e.type_message == type_message){
                e.status_message = status;
                chat_current[index] = e;
            }
        });

        this.setState({chat: chat_current});
    }

    atualizar_status = (newis) => {
        var chat_current = this.state.chat;

        chat_current.forEach((e, index) => {
            if(e.id == newis.id){
                e.status_message = newis.status_message;
                chat_current[index] = e;
            }
        });

        this.setState({chat: chat_current});
    }

    load_messages = (cod_chat, callback, new_id = false) => {
        if(cod_chat !== null){
            if(this.state.consulta_mensagens_enabled == true || new_id == true){
                this.setState({ consulta_mensagens_enabled: false });
                var url = ENDPOINT + 'chat-atendimento/exibir-mensagens/' + cod_chat;
                axios.get(url, {
                    headers: {
                        Authorization: 'Bearer ' + getToken()
                    }
                })
                .then((response) => {
                    var data = response.data;
                    this.setState({ consulta_mensagens_enabled: true });
                    callback(data);
                })
                .catch((error) => {
                    console.log(error)
                })
            }
        }
    }


    informacoes_chat = (cod_chat, callback) => {
        if(cod_chat !== null){
            //if(this.state.consulta_mensagens_enabled == true || new_id == true){
                this.setState({ consulta_mensagens_enabled: false });
                var url = ENDPOINT + 'chat-atendimento/informacoes/' + cod_chat;
                axios.get(url, {
                    headers: {
                        Authorization: 'Bearer ' + getToken()
                    }
                })
                .then((response) => {
                    var data = response.data;
                    console.log(data);
                    //this.setState({ consulta_mensagens_enabled: true });
                    this.setState({ chat_info: data.data })
                })
                .catch((error) => {
                    console.log(error)
                })
            //}
        }
    }
    
    load_message_unique = (cod_chat, id, callback) => {
        console.log(id);
        if(cod_chat !== null){
            //if(this.state.consulta_mensagens_enabled == true || new_id == true){
                //this.setState({ consulta_mensagens_enabled: false });
                var url = ENDPOINT + 'chat-atendimento/exibir-mensagem/' + cod_chat + '/' + id;
                axios.get(url, {
                    headers: {
                        Authorization: 'Bearer ' + getToken()
                    }
                })
                .then((response) => {
                    var data = response.data;
                    //this.setState({ consulta_mensagens_enabled: true });
                    callback(data.data);
                })
                .catch((error) => {
                    console.log(error)
                })
            //}
        }
    }
      
    setLocation(position) {
        this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        })
    }
    iniciar_chamada_por_video = () => {
        const mediaStreamConstraints = { video: true };
        const localVideo = this.stream_video_ref;

        function obter_local_streaming_local (mediaStream) {
            localVideo.current.srcObject = mediaStream;
            localVideo.current.autoplay = true;
        }
        
        function tratar_erro_streaming (error) {
            console.log('navigator.getUserMedia error', error);
        }
        console.log(localVideo);

        navigator.mediaDevices.getUserMedia(mediaStreamConstraints).then(obter_local_streaming_local).catch(tratar_erro_streaming);

    }

    loadOptions = (inputValue, callback) => {
        var url = ENDPOINT + "respostas/list_for_chat?page=1&search=" + inputValue;
        var myHeaders = new Headers();
      
        myHeaders.append('Authorization', 'Bearer ' + getToken());
      
        var myInit = {
          method: 'GET',
          headers: myHeaders,
          mode: 'cors',
          cache: 'default'
        };
        fetch(url, myInit)
          .then(response => response.json())
          .then((p) => {
              var data = p;
              data.forEach((p, i) => {
                  data[i].conteudo = this.tratar_string(p.conteudo);
                  data[i].label = p.nome;
                  data[i].value = p.flag;
              });
  
              this.state.respostas_prontas.forEach((p, i) => {
                  data.forEach((p2, i2) => {
                      /* eslint eqeqeq: 0 */
                      if(p2.value == p.value){
                          data.splice(i2, 1);
                      }
                  });
              });
  
              callback(this.filter(inputValue, p));
          });
      };
    tratar_string = (string) => {
        if(this.state.chat_info !== null){
            string = string.replace('{{ $Nome }}', this.state.chat_info.nome);
            string = string.replace('{{ $Telefone }}', this.state.chat_info.telefone);
            string = string.replace('{{ $E-mail }}', this.state.chat_info.email);
        }

        return string;
    }
    filter = (inputValue, data) => {
          return data.filter(i =>
            i.flag.toLowerCase().includes(inputValue.toLowerCase())
          );
    };
    handleInputChangeSelect = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
      };
    
    pegar_conexao_do_cliente_chamada_por_video = (id) => {
        const mediaStreamConstraints = { video: true };
        const localVideo = this.stream_video_client_ref;

        function obter_local_streaming_local (mediaStream) {
            localVideo.current.srcObject = mediaStream;
            localVideo.current.autoplay = true;
        }
        
        function tratar_erro_streaming (error) {
            console.log('navigator.getUserMedia error', error);
        }
        console.log(localVideo);

        navigator.mediaDevices.getUserMedia(mediaStreamConstraints).then(obter_local_streaming_local).catch(tratar_erro_streaming);

    }
    
    PusherInstancia(cod_chat, callback) {
        const pusher = new Pusher("2892f3b88d766b1b14d3", {
            cluster: 'us3',
        });

        this.setState({pusher_instance: pusher});

        var atualizar_chat = pusher.subscribe('pusher_atualizar_chat_'+cod_chat);
        atualizar_chat.bind('atualizar', () => {
            if(cod_chat === this.state.current_message){
                this.load_messages(cod_chat, callback);
            }
        });

        
        var atualizar_mensagem = pusher.subscribe('pusher_atualizar_mensagem_'+cod_chat);
        atualizar_mensagem.bind('atualizar', (e) => {
            this.load_message_unique(cod_chat, e, this.atualizar_chat)
        });

        
        var atualizar_todos_status = pusher.subscribe('pusher_atualizar_todos_status_'+cod_chat);
        atualizar_todos_status.bind('atendentes', (e) => {
            this.atualizar_todos_status(e, 0)
        });
        atualizar_todos_status.bind('clientes', (e) => {
            this.atualizar_todos_status(e, 1)
        });
        
        
        var atualizar_status = pusher.subscribe('pusher_atualizar_status_'+cod_chat);
        atualizar_status.bind('atendentes', (e) => {
            this.atualizar_status(e)
        });

        
        var infos = pusher.subscribe('pusher_atualizar_info_'+cod_chat);
        infos.bind('atualizar', () => {
            this.informacoes_chat(cod_chat)
        });
    }

    PusherNotBind(cod_chat) {
        var response = this.state.pusher_instance.unsubscribe('pusher_atualizar_chat_'+cod_chat);
        var response = this.state.pusher_instance.unsubscribe('pusher_atualizar_info_'+cod_chat);
        var response = this.state.pusher_instance.unsubscribe('pusher_atualizar_todos_status_'+cod_chat);
        var response = this.state.pusher_instance.unsubscribe('pusher_atualizar_status_'+cod_chat);
        var response = this.state.pusher_instance.unsubscribe('pusher_atualizar_mensagem_'+cod_chat);
        //response.unbind();
    }

    componentDidMount(){
        this.setState({
            current_message: this.props.cod_chat
        })
        this.PusherInstancia(this.props.cod_chat, this.callbackChat.bind(this));
        //getOperadores(this.callbackOperadores.bind(this));
        this.load_messages(this.props.cod_chat, this.callbackChat.bind(this), true);
        this.getLocation();
        //setTimeout(() => this.iniciar_chamada_por_video(), 2000);
    }
    callbackOperadores = (operadores_list) => {
        this.setState({operadores_list});
    }
    componentWillUpdate(nextProps, nextState) {
        if (nextProps.cod_chat !== null && nextProps.cod_chat !== this.props.cod_chat) {
            this.PusherNotBind(this.state.current_message);
            this.setState({
                chat: [],
                chat_info: null,
                masked_message: null,
                isOpen: false,
                pendencia_current: null,
                motivo: '',
                current_message: nextProps.cod_chat,
                is_type_message: false,
                disabled_pending: false,
                consulta_mensagens_enabled: true,
                loading_chat: true
            })
            this.PusherInstancia(nextProps.cod_chat, this.callbackChat.bind(this));
            this.load_messages(nextProps.cod_chat, this.callbackChat.bind(this), true);
        }
    }
    onDrop = (file) => {
        var state = this.state;
        file.forEach(file_current => {
            state.file.push(file_current);
        });
        this.setState({
            ...state
        })
    }

    checkStatusChat = () => {
        var url = ENDPOINT + 'checar-se-existe-operadores-online';
        
        axios.get(url)
        .then((response) => {
            var data = response.data;
            this.setState({check_status_user: data})
        })
        .catch((error) => {});
    }
    callbackChat(data){
        if(this.props.type_chat !== 'monitoramento'){
            if(data.chat_info.id_admin !== getUser().id){
                this.PusherNotBind(this.state.current_message);

                this.setState({
                    chat: [],
                    file: [],
                    activeTab: "1",
                    descricao: '',
                    loading_note: false,
                    departamento: null,
                    current_message: null,
                    sending_message: false,
                    masked_message: null,
                    modal_anexo: false,
                    modal_transfer: false,
                    currentChat: null,
                    chat_info: null,
                    audio_visible: false,
                    recording_audio: null,
                    pusher_instance: null,
                    loading_chat: true,
                    consulta_mensagens_enabled: true,
                    message: '',
                    pendencia_current: 'yes',
                    isOpen: false
                });
                this.props.fecharTransferencia();
            }
        }

        if(data.chat_info.cod_chat !== null ? data.chat_info.cod_chat === this.state.current_message : ''){
            if(data.mensagens.length !== this.state.chat.length){
                if (typeof this.myRef !== 'undefined') {
                    if(typeof this.myRef.current  !== 'undefined'){
                        if (this.myRef.current !== null) {
                            setTimeout(() => 
                            {
                                try{
                                    if(this.myRef.current.scrollTop){
                                        this.myRef.current.scrollTop = this.myRef.current.scrollHeight;
                                    }

                                }
                                catch(e){
                                    
                                }
                            },
                            500);
                        }
                    }
                }
            }

            try{
                if (typeof this.myRef !== 'undefined') {
                    if(typeof this.myRef.current  !== 'undefined'){
                        if (this.myRef.current !== null) {
                            if( (data.mensagens.length !== this.state.chat.length) || 
                                (this.state.chat_info !== null ? data.chat_info.cod_chat !== this.state.chat_info.cod_chat: '')
                            )
                            {
                                setTimeout(() => {
                                    if(this.myRef.current.scrollHeight <= this.myRef.current.clientHeight){
                                        this.readMessageAll(this.state.current_message)
                                    }
                                },
                                1000);

                            }
                        }
                    }
                }
            }
            catch(e){

            }
            data.chat_info.notas_privadas.reverse();
            this.setState({
                chat_info: data.chat_info,
                chat: data.mensagens,
                loading_note: false,
                loading_chat: false
            });

        }

    }

    onMaskedMessage(data, nome) {
        var json = {
            cod_chat_message: data.cod_chat_message,
            nome: nome,
            message: data.message
        }

        this.setState({ masked_message: json });
    }
    deleteMasked() {
        this.setState({ masked_message: null });
    }
    removeAttach = (index) => {
        var state = this.state;
        state.file.splice(index, 1);
        this.setState({
            ...state
        })
    }
    startAudio = () => {
        let constraints={audio:true};
        navigator.mediaDevices.getUserMedia(constraints)
        .then((stream)=>{
            if(stream.getAudioTracks().length > 0){
                var record_instance;

                if(this.state.audio_visible === true){
                     record_instance = this.state.record_instance;

                    if(record_instance !== null){
                        record_instance.pause();
                        record_instance.stream.getTracks().forEach( track => track.stop() );
                    }

                    this.setState({ audio_visible: false, recording_audio: null });
                }
                else{
                     record_instance = this.state.record_instance;

                    if(this.state.record_instance === null){
                        let recorder = new MediaRecorder(stream);

                        recorder.addEventListener("stop", e => {
                            recorder = null;
                        });

                        recorder.addEventListener("dataavailable", e => {
                            this.onData(e);
                        });
                        
                        record_instance = recorder;

                        this.setState({record_instance: recorder})
                    }
                    record_instance.start(); 

                    this.setState({ audio_visible: true, recording_audio: record_instance });
                }
            }
            else{
                alert('Por favor, autorize o microfone para continuar');
            }
        })
        .catch((e) => {
            alert('Nenhum microfone detectado')
        });
    }
    onKeyUp(event) {
        if (event.key === "Enter" && event.shiftKey === false) {
            this.sendValid(this.state.chat_info.cod_chat)
            event.preventDefault();
        }
    }
    
    getDepartamentos = () => {
        var url = ENDPOINT+"departamento/list";
    
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            var list_departamentos = [];
            data.forEach((p, i) => {
                p['value'] = p.flag;
                p['label'] = p.nome;
                p['status'] = p.admins_online_count;
                list_departamentos.push(p);
            })
            this.setState({list_departamentos});
        })
        .catch((error) => {

        });
    }
    
    
    onData(event) {
        if (event.data.size > 0) {
            var files = [];
            var audio_file = this.blobToFile(event.data);
            files.push(audio_file);

            this.sendMessageOnFile(files);

            this.setState({record_instance: null});

        } else {

        }
    }
    stopAudio = () => {
        var record_instance = this.state.record_instance;

        if(record_instance === null){
            alert('Ocorreu um erro');
            return;
        }

        record_instance.requestData();
        
        this.setState({ audio_visible: false });
    }
    removeAudio = () => {
        if(this.state.record_instance !== null){
            var record_instance = this.state.record_instance;
            record_instance.pause();
            //record_instance.stop();
            record_instance.stream.getTracks().forEach( track => track.stop() );
        }

        this.setState({ audio_visible: false, recording_audio: null });
    }
    sendMessageOnFile = (files) => {
        this.setState({ sending_message: true });
        var url = ENDPOINT + 'chat-atendimento/enviar-mensagem/' + this.state.chat_info.cod_chat;

        var formData = new FormData();
        formData.append('message', '');
        formData.append('cod_chat', this.state.chat_info.cod_chat);
        formData.append('type_message', 0);


        if (this.state.masked_message !== null) {
            formData.append('codigo_message_masked', this.state.masked_message.cod_chat_message);
        }

        if(files.length > 0){
            files.forEach((file, index) => {
                formData.append('anexo['+index+']', file);
            });
        }

        if(this.state.latitude !== null || this.state.longitude !== null){
            formData.append('latitude', this.state.latitude);
            formData.append('longitude', this.state.longitude);
        }
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                this.callbackChat(data);
                this.setState({ 
                    sending_message: false, 
                    message: '', 
                    masked_message: null,
                    file: [],
                    is_type_message: false,
                });
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    sending_message: false, 
                    message: '', 
                    masked_message: null,
                    file: []
                });

                toast.error('Ocorreu um erro no servidor');

            });
    }
    getFileExtension = (url) =>{
        var extension = get_extension(url);

        if(extension === '.png' ||
            extension === '.PNG' ||
            extension === '.jpg' ||
            extension === '.jpeg' ||
            extension === '.svg' ||
            extension === '.webp'){
            return 'text'
        }
        else if(extension === '.webm' ||
            extension === '.3gp' ||
            extension === '.ogg' ||
            extension === '.mp4'){
                return 'text';
        }
        else if(extension === '.mp3' ||
            extension === '.wav' ||
            extension === '.ogg'){
            return 'text';
        }

        return 'file';
    }
    onImageModal = (modal_image, urlImage) =>{
        this.setState({modal_image, urlImage});
    }
    getHtmlExtension = (anexo, url, message) =>{
        if(anexo !== null){
            var extension = get_extension(anexo);

            if(extension === '.png' ||
                extension === '.PNG' ||
                extension === '.jpg' ||
                extension === '.jpeg' ||
                extension === '.svg' ||
                extension === '.webp'){
                //return <img src={url} />
                return <div class="rce-mbox-photo">
                        <div class="rce-mbox-photo--img">
                            <img src={url+'?access_token='+getToken()} alt="archive-rce"/>
                            <div class="rce-mbox-photo--img__block">
                                <DropdownButton 
                                    outline
                                    className="dropdown-image rce-mbox-photo--img__block-item"
                                    title={<MdImage style={{ padding: '3px', width: '23px' }}/>}
                                    noCaret={true}
                                >
                                    <Dropdown.Item onClick={() => this.onImageModal(true, url)}><MdOpenInBrowser />Abrir</Dropdown.Item>
                                    <Dropdown.Item onClick={() => window.open(url+'?access_token='+getToken(), '_blank')}><MdFileDownload />Download</Dropdown.Item>
                                </DropdownButton>
                                {/* <button 
                                    class="rce-mbox-photo--img__block-item rce-mbox-photo--download"
                                    onClick={() => window.open(url, '_blank')}>
                                    <MdFileDownload />
                                </button> */}
                            </div>
                        </div>
                    </div>
            }
            else if(extension === '.webm' ||
                extension === '.3gp' ||
                extension === '.ogg' ||
                extension === '.mp4'){
                return (<center><video controls>
                    <source src={url+'?access_token='+getToken()} />
                </video></center>)
            }
            else if(extension === '.mp3' ||
                extension === '.wav' ||
                extension === '.ogg'){

                return (<center><audio controls>
                    <source src={url+'?access_token='+getToken()} />
                </audio></center>)
            }

            return message;
        }

        if(message === 'ESSA MENSAGEM FOI APAGADA'){
            return <div style={{fontStyle: 'italic', margin: 0, opacity: 0.8}}><MdBlock style={{ height: '1rem', marginTop: '-2px', padding: 0 }} /> ESSA MENSAGEM FOI APAGADA</div>
        }
        
        return message.split('\n').map((x, i, {length}) => (
            <p style={{margin: (i + 1 === length ? 0 : 3)}}>{x}</p>
        ));
    }
    listData = [
        { name: <><MdSupervisorAccount/>Resumo</>, active: '1' },
        { name: <><MdChat/>Chats</>, active: '2' },
        { name: <><MdNote/>Notas</>, active: '3' },
        { name: <><MdAttachMoney />Pagamentos</>, active: '4' }
    ];
    Navs = () => {
        return <Nav tabs>
            {this.listData.map((p, index) => {
                return <NavLink
                    key={index}
                    className={
                        classnames({ active: this.state.activeTab === p.active })}
                    onClick={() => { this.setState({ activeTab: p.active }); }}>
                    <span>{p.name}</span>
                </NavLink>
            })}
        </Nav>
    }
    isOpenCurrent() {
        this.setState({ isOpen: !this.state.isOpen });
        //setTimeout(() => this.myRef.current.scrollTop = this.myRef.current.scrollHeight, 100)
    }
    handleFileChange = (event) => {
        const files = event.target.files;
        var state = this.state;
        Array.from(files).forEach(file_current => {
            state.file.push(file_current);
        });
        this.setState({
            ...state
        })
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }
    sendValid = (cod_chat) => {
        var invalid = 0;
        if(this.state.file.length === 0){
            if(typeof this.state.message === 'undefined'){
                invalid++;
            }
            else if(this.state.message == null){
                invalid++;
            }
            else if(this.state.message.length === 0){
                invalid++;
            }
        }

        if(invalid === 0){
            this.sendMessage(cod_chat);
        }
    }

    modalAttach(value){
        this.setState({modal_anexo: value, file: []})
    }

    sendMessage(cod_chat) {
        this.setState({ sending_message: true, modal_anexo: false });
        var url = ENDPOINT + 'chat-atendimento/enviar-mensagem/' + cod_chat;

        var formData = new FormData();
        formData.append('message', this.state.message);
        formData.append('cod_chat', cod_chat);
        formData.append('type_message', 0);
        if (this.state.masked_message !== null) {
            formData.append('codigo_message_masked', this.state.masked_message.cod_chat_message);
        }
        if(this.state.latitude !== null || this.state.longitude !== null){
            formData.append('latitude', this.state.latitude);
            formData.append('longitude', this.state.longitude);
        }

        if(this.state.file.length > 0){
            this.state.file.forEach((file, index) => {
                formData.append('anexo['+index+']', file);
            });
        }

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                //this.callbackChat(data);
                this.setState({ 
                    sending_message: false, 
                    message: '', 
                    masked_message: null, 
                    file: [],
                    is_type_message: false,
                });
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    sending_message: false, 
                    message: '', 
                    masked_message: null,
                    file: []
                });

                toast.error('Ocorreu um erro no servidor');
            });
    }

    sendMessageByResposta(message) {
        this.setState({ sending_message: true, modal_anexo: false });
        var url = ENDPOINT + 'chat-atendimento/enviar-mensagem/' + this.state.chat_info.cod_chat;

        var formData = new FormData();
        formData.append('message', message);
        formData.append('cod_chat', this.state.chat_info.cod_chat);
        formData.append('type_message', 0);

        if (this.state.masked_message !== null) {
            formData.append('codigo_message_masked', this.state.masked_message.cod_chat_message);
        }

        if(this.state.latitude !== null || this.state.longitude !== null){
            formData.append('latitude', this.state.latitude);
            formData.append('longitude', this.state.longitude);
        }
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                //this.callbackChat(data);
                this.setState({ 
                    sending_message: false, 
                    message: '', 
                    masked_message: null, 
                    file: [],
                    is_type_message: false,
                });
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    sending_message: false, 
                    message: '', 
                    masked_message: null,
                    file: []
                });

                toast.error('Ocorreu um erro no servidor');
            });
    }

    scroll_novas_mensagens = () =>{
        var verificar_referencia = this.myRef.current;
        if(typeof verificar_referencia !== 'undefined'){
            var scroll_current = this.myRef.current;
            if(scroll_current){
                if (scroll_current.scrollTop - scroll_current.scrollTop === scroll_current.clientHeight) {
                    return false;
                }
            }
        }
        
        //return true;

        return this.checar_se_existe_mensagens_nao_lidas();

    }
    checar_se_existe_mensagens_nao_lidas = () => {
        var messages_pending = 0;

        this.state.chat.forEach((p, i) => {
            if(p.type_message === 1){
                if(p.status_message !== 2){
                    messages_pending++;
                }
            }
        });

        if(messages_pending === 0){
            return false;
        }

        return true;
    }

    readMessageAll(cod_chat) {
        if(this.props.type_chat === "atendimento"){
            if(this.checar_se_existe_mensagens_nao_lidas() === true){
                var url = ENDPOINT + 'chat-atendimento/ler-todas-as-mensagens';
    
                var formData = new FormData();
                formData.append('message', this.state.message);
                formData.append('cod_chat', cod_chat);
                formData.append('type_message', 0);
    
                axios.post(url, formData, {
                    headers: {
                        Authorization: 'Bearer ' + getToken()
                    }
                })
                .then((response) => {
                    this.atualizar_todos_status(2, 1);
                })
                .catch((error) => {
                    //toast.error('Ocorreu um erro ao enviar mensagem, tente novamente');
                });
            }
        }
    }
    readMessageSpecific(message) {
        if (message.status_message !== 2 && message.type_message === 1 && this.props.type_chat === "atendimento") {
            var url = ENDPOINT + 'chat-atendimento/ler-mensagem-especifica/' + message.cod_chat_message;

            var formData = new FormData();

            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer ' + getToken()
                }
            })
            .then((response) => {

            })
            .catch((error) => {

            });

        }
    }
    setDeleteMessage = (cod_chat_message) => {
        var chat = this.state.chat;

        chat.forEach((p) => {
            if(p.cod_chat_message === cod_chat_message){
                p.message = 'ESSA MENSAGEM FOI APAGADA'
            }
        });

        this.setState({chat})
    }
    setLoadingInMessage = (cod_chat_message) => {
        var chat = this.state.chat;

        chat.forEach((p) => {
            if(p.cod_chat_message === cod_chat_message){
                p.message = 'APAGANDO MENSAGEM...'
            }
        });

        this.setState({chat})
    }
    apagar_mensagem(message) {
        if(new Date(message.created_at) > new Date().setMinutes(new Date().getMinutes() - 1)){
            this.setLoadingInMessage(message.cod_chat_message);
            var url = ENDPOINT + 'chat-atendimento/apagar-mensagem/' + message.cod_chat_message;

            var formData = new FormData();

            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer ' + getToken()
                }
            })
            .then((response) => {
                toast.success('Mensagem apagada com exito');
            })
            .catch(() => {});
        }
    }

    transferirChat = () => {
        if(this.state.departamento !== null || this.state.operador !== null){
            var url = ENDPOINT + 'chat-atendimento/transferir-chat/' + this.state.chat_info.cod_chat;

            var formData = new FormData();
            if(this.state.departamento !== null){
                formData.append('departamento', this.state.departamento.value);
            }
            else{
                formData.append('operador', this.state.operador.value);
            }

            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer ' + getToken()
                }
            })
            .then((response) => {
                this.setState({
                    departamento: null,
                    modal_transfer: false
                });
                toast('Transferido com sucesso');
            })
            .catch((error) => {

            });
        }
        else{
            toast.error('Você deve selecionar o departamento');
        }
    }

    chamarAtendente = () => {
        var url = ENDPOINT + 'chat-atendimento/transferir-chat/' + this.state.chat_info.cod_chat;

        var formData = new FormData();
        if( this.state.chat_info.departamento !== null){
            formData.append('departamento', this.state.chat_info.departamento);
        }

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            this.informacoes_chat(this.props.cod_chat);

            this.setState({
                departamento: null,
                modal_transfer: false
            });
            toast('Atendente sendo chamado');
        })
        .catch((error) => {
            toast('Ocorreu um erro');
        });
    }
    
    finalizarChat = () => {
        var url = ENDPOINT + 'chat-atendimento/finalizar-chat/' + this.state.chat_info.cod_chat;

        var formData = new FormData();

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            this.informacoes_chat(this.props.cod_chat);
        })
        .catch((error) => {

        });

    }
    chat_perdido = () => {
        var url = ENDPOINT + 'chat-atendimento/setar-chat-como-perdido/' + this.state.chat_info.cod_chat;

        var formData = new FormData();

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            this.informacoes_chat(this.props.cod_chat);
        })
        .catch((error) => {

        });

    }
    blobToFile = (theBlob) => {
        return new File([theBlob], 'audio.wav', { type: theBlob.type });
    }
    onScroll(scroll) {
        try{
            var scroll_current = scroll.target;
            //if (scroll_current.scrollHeight - scroll_current.scrollTop === scroll_current.clientHeight) {
            if (scroll_current.offsetHeight + scroll_current.scrollTop >= scroll_current.scrollHeight){
                console.log('Final')
                this.readMessageAll(this.state.chat_info.cod_chat);
            }
        }
        catch(ex){
            
        }

    }
    createNote = () => {
        this.setState({loading_note: true});

        var url = ENDPOINT + 'chat-atendimento/criar-nota-privada/' + this.state.chat_info.cod_chat;

        var formData = new FormData();
        formData.append('descricao', this.state.descricao)

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            this.setState({descricao: ''});
            toast.success('Nota criada com sucesso');
            this.informacoes_chat(this.props.cod_chat);
            this.setState({loading_note: false});
        })
        .catch((error) => {

        });

    }
    responderRetorno = () => {
        if(this.state.pendencia_current === null){
            toast.error('Selecione se irá necessitar de retorno');
            return;
        }
        else if(this.state.pendencia_current === 'yes'){
            if(this.state.motivo.length === 0){
                toast.error('Descreva o motivo do retorno');
                return;
            }
            if(this.state.departamento === null){
                toast.error('Selecione o departamento');
                return;
            }
        }
        this.setState({
            disabled_pending: true
        });

        var url = ENDPOINT + 'chat-atendimento/responder-pendencia/' + this.state.chat_info.cod_chat;

        var formData = new FormData();
        formData.append('resposta_pendencia', this.state.pendencia_current);
        if(this.state.pendencia_current === 'yes'){
            formData.append('motivo_pendencia', this.state.motivo);
            formData.append('departamento', this.state.departamento.value)
        }

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            this.setState({
                disabled_pending: false,
                departamento: null,
                motivo: '',
                pendencia_current: null,
            });

            toast.success('Feito');
            this.informacoes_chat(this.props.cod_chat);
        })
        .catch((error) => {
            console.log(error)
            this.setState({
                disabled_pending: false
            });
            toast.success('Ocorreu um erro no servidor');

        });

    }
    handleInputChange = (e) => {
      const { name, value } = e.target;
      
      this.setState({ [name]: value });
    }
    getStatusMessage = (status, message) => {
        if(message === 'ESSA MENSAGEM FOI APAGADA'){
            return null;
        }
        else if(status === 0){
            return 'sent';
        }
        else if(status === 1){
            return 'received';
        }
        else if(status === 2){
            return 'read';
        }
        

        return 'sent';
    }
    validDate(date_create){
        var hoje = new Date();
        hoje.setMinutes(hoje.getMinutes() - 1);

        if(new Date(date_create) > hoje){
            return true;
        }
        return false;
    }
    onDepartamentoList = (departamento_chat) => {
        this.setState({ departamento_chat });
    }
    render(){
        return(
            <Div>
                <Row style={{ height: '100%' }}>
                    <Col md={this.state.isOpen === true ? 8 : 12} >
                        <Card style={{ height: '76vh' }} >
                            <div
                                className="card-body"
                                ref={this.myRef}
                                onScroll={this.onScroll.bind(this)}
                                style={{
                                    border: '1px solid #dfdfdf',
                                    maxWidth: '100%',
                                    backgroundColor: '#343a4024',
                                    height: '100%',
                                    //minHeight: '80%', 
                                    overflow: 'auto'
                                }}>
                                    {this.state.loading_chat === true ? 
                                        <div style={{height: '100%'}} hidden={!this.state.current_message === null}>
                                            <center>
                                                <small style={{
                                                    bottom: '60%',
                                                    position: 'absolute'
                                                }}>
                                                    <FadeLoader color={'#52c565'} margin={0} loading={50} size={60} /><br />
                                                </small>
                                            </center>
                                        </div>
                                    :
                                        <></>}
                                {this.state.chat.map((p, index) => {
                                    if(p.type_message === 0 || p.type_message === 1){
                                        return (
                                            <>
                                            <div key={index} 
                                                //onClick={() => this.readMessageSpecific(p)} 
                                                style={{ backgroundColor: p.type_message === 1 ? (p.status_message !== 2 ? '#52c56517' : 'transparent') : 'transparent' }}>
                                                    {/* <ReplyButton onClick={() => this.onMaskedMessage(p, p.type_message === 0 ? 'Atendimento' : chat.nome)} type_message={p.type_message}><FaReply /></ReplyButton> */}
                                                    <DropdownCurrent type_message={p.type_message} hidden={this.state.chat_info.status_chat === 2 || this.state.chat_info.status_chat === 3}>
                                                        <div class="chat-reposta" type_message={p.type_message}>
                                                            { p.type_message === 0 ? <><Button 
                                                                        disabled={new Date(p.created_at) > new Date().setMinutes(new Date().getMinutes() - 1)
                                                                             ? 
                                                                            (p.message === 'ESSA MENSAGEM FOI APAGADA' || p.message === 'APAGANDO MENSAGEM...') : true} color="danger" onClick={() => this.apagar_mensagem(p)}>
                                                                <FaTrash/>
                                                            </Button></> : <></>}
                                                            
                                                            <Button disabled={p.message === 'ESSA MENSAGEM FOI APAGADA' || p.message === 'APAGANDO MENSAGEM...'} color="info" onClick={() => this.onMaskedMessage(p, p.type_message === 0 ? (p.admin !== null ? p.admin.name : 'Atendimento') : (p.usuario !== null ? p.usuario.nome : 'Sem informações'))}>
                                                                <MdReply/>
                                                            </Button>
                                                        </div>
                                                    </DropdownCurrent>
                                                    <MessageBoxArea>
                                                        <MessageBox
                                                        //multiline={true}
                                                            copiableDate={false}
                                                            dateString={Moment(new Date(p.created_at)).format('HH:m')}
                                                            status={p.type_message === 0 ? this.getStatusMessage(p.status_message, p.message) : null}
                                                            //retracted={true}
                                                            titleColor={p.type_message === 0 ? "#343a40" : '#52c565'}
                                                            data={p.anexo !== null ? this.getFileExtension(p.anexo) !== 'text' ? ({
                                                                uri: ENDPOINT+'archive_chat/'+p.cod_chat_message+'/'+p.message+'?access_token='+getToken(),
                                                            } ) : null  : null}
                                                            avatar={p.type_message === 0 ? (p.admin !== null ? p.admin.avatar_foto: 'https://files.zohopublic.com/public?x-service=LiveDesk&event-id=1606357595010_604373000000002052&x-cli-msg=%7B%22x-siq-filetype%22%3A%22fgravatar%22%2C%22x-siq-lsid%22%3A%22604373000000002052%22%2C%22x-siq-soid%22%3A%22730799669%22%2C%22x-siq-ispreview%22%3Afalse%2C%22x-siq-pfname%22%3A%22logo-chat.png%22%2C%22x-siq-downloadtype%22%3A%22default%22%7D') : ''}
                                                            position={p.type_message === 0 ? 'right' : 'left'}
                                                            reply={p.mask_messages !== null ? {
                                                                title: p.mask_messages.type_message === 0 ? (p.admin !== null ? p.admin.name : 'Atendimento') : this.state.chat_info.nome,
                                                                titleColor: '#38B76E',
                                                                message: p.mask_messages.message,
                                                            } : null}
                                                            type={p.anexo !== null ? this.getFileExtension(p.anexo) : 'text'}
                                                            onClick={() =>  {
                                                                if(p.anexo !== null){
                                                                    if(this.getFileExtension(p.anexo) === 'file'){
                                                                        window.open(ENDPOINT+'archive_chat/'+p.cod_chat_message+'/'+p.message+'?access_token='+getToken(), '_blank')
                                                                    }
                                                                }
                                                            }}
                                                            title={p.type_message === 0 ? (p.admin !== null ? p.admin.name : 'Atendimento') :  this.state.chat_info.nome}
                                                            text={this.getHtmlExtension(p.anexo, ENDPOINT+'archive_chat/'+p.cod_chat_message+'/'+p.message, p.message)}
                                                            //date={new Date(p.updated_at)}
                                                        />
                                                    </MessageBoxArea>
                                            </div>
                                                <br/>
                                            </>
                                        );
                                    }
                                    else if(p.type_message === 2){
                                        return(
                                            <center>
                                                <small style={{color: 'dimgray', padding: 0}}>{p.message}</small>
                                                <hr style={{color: 'dimgray', marginTop: '3px'}}/>
                                            </center>
                                        )
                                    }
                                    else{
                                        return(<></>);
                                    }
                                })}
                                
                                <div hidden={this.props.type_chat === 'monitoramento'} 
                                    style={{position: 'absolute', top: '20px', right: '24px', textAlign: 'center', zIndex: 9999}}>
                                    <div 
                                        hidden={!this.scroll_novas_mensagens()} 
                                        style={{
                                            border: '1px solid rgb(113 207 129)',
                                            borderRadius: '25px', 
                                            padding: 4, 
                                            backgroundColor: 'rgb(113 207 129)', 
                                            color: '#fff'}}><small><MdMail/>Você tem novas mensagens</small></div>
                                </div>
                                {/* <div style={{textAlign: 'center'}}>
                                    <p style={{marginBottom: '6px'}}>Chamada de voz em andamento: </p>
                                    <Button color="success" style={{borderRadius: '26px'}}>
                                        Atender
                                    </Button>
                                    &nbsp;
                                    <Button color="danger" style={{borderRadius: '26px'}}>
                                        Recusar
                                    </Button>
                                    <hr style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}/>
                                </div> */}
                            </div>
                            <CardFooter>
                                <Row hidden={!this.state.sending_message}>
                                    <Col md={12}>
                                        <center>
                                            <small><PropagateLoader color={'#52c565'} loading={50} size={15} /><br />
                                                <b>Enviando mensagem</b><p></p></small>
                                        </center>
                                    </Col>
                                </Row>
                                <Collapse isOpen={this.state.audio_visible}>
                                    <Row>
                                        <Col style={{display: 'inline-flex', placeItems: 'center'}}>
                                            <Recorder><MdMic /> </Recorder> Gravando Audio
                                            <p style={{padding: 0, margin: 0}}></p>
                                        </Col>
                                        <Col style={{textAlign: 'right'}}>
                                            <Button outline color="danger" onClick={() => this.removeAudio()}>Parar</Button>&nbsp;
                                            <Button outline color="success" onClick={() => {this.stopAudio()}}>Enviar</Button>
                                        </Col>
                                    </Row> 
                                    <hr/>
                                </Collapse>
                                <Row>
                                    <Col md={12}>
                                        <Collapse isOpen={this.state.masked_message !== null} 
                                            style={{ 
                                                borderLeft: '4px solid #28a745', 
                                                paddingLeft: '6px', 
                                                marginBottom: 10, 
                                                width: '100%', 
                                                backgroundColor: '#cccccc' 
                                            }}>
                                            <small hidden={this.state.sending_message}>
                                                <b>{this.state.masked_message !== null ? 
                                                    this.state.masked_message.nome : ''} 
                                                    <Button color="danger" 
                                                        onClick={() => this.deleteMasked()} 
                                                        outline
                                                        style={{ padding: 0, float: 'right', margin: '11px', borderRadius: '50%', height: '27px', width: '27px' }}>
                                                            <MdClear style={{ padding: 3 }} />
                                                    </Button>
                                                </b>
                                            <br />{this.state.masked_message !== null ? 
                                            this.state.masked_message.message : ''}</small>
                                        </Collapse>
                                    </Col>
                                </Row>
                                <Row>
                                    
                                {this.state.chat_info !== null ? 
                                    this.state.chat_info.status_chat !== 2 &&  this.state.chat_info.status_chat !== 3 ? 
                                        <>   
                                            <Col hidden={this.state.sending_message} style={{marginLeft: '15px', marginBottom: '6px', fontSize: '14px'}}>
                                                <input type="checkbox" style={{position: 'relative', top: '2px'}} checked={this.state.is_type_message} onChange={(e) => this.setState({ is_type_message: e.target.checked })} /> Resposta Pronta
                                            </Col>
                                            <Col md={12} style={{ display: 'inherit' }} hidden={this.state.sending_message}>
                                                <div style={{display: 'inherit', border: '1px solid #dfdfdf', width: '100%', minHeight: '50px', padding: '7px', borderRadius: '15px'}}>
                                                    <ButtonGroup>
                                                        <Button outline style={{borderRadius: 0}} className="widget_btn talk_btn" color="danger" onClick={() => this.startAudio()}><MdMic style={{ padding: 0, margin: '2px' }} /></Button>
                                                    </ButtonGroup>
                                                    
                                                    <TextareaAutosize
                                                        type="textarea"
                                                        placeholder="Escreva sua mensagem"
                                                        className="msg"
                                                        style={{ resize: 'none', background: 'transparent', width: '100%' }} 
                                                        name="message"
                                                        hidden={this.state.is_type_message}
                                                        onKeyPress={this.onKeyUp}
                                                        value={this.state.message}
                                                        onChange={this.handleInputChange} />
                                                    <div style={{width: '100%', height: '34px'}} hidden={!this.state.is_type_message}>
                                                        <AsyncSelect
                                                            style={{width: '100%'}}           
                                                            cacheOptions
                                                            loadOptions={this.loadOptions}
                                                            defaultOptions
                                                            menuPosition="top"
                                                            menuPlacement="auto"
                                                            value={this.state.respostas_prontas} 
                                                            placeholder="Respostas Prontas"
                                                            onChange={(respostas_prontas) => this.sendMessageByResposta(respostas_prontas.conteudo)}
                                                            onInputChange={this.handleInputChangeSelect}
                                                            styles={{
                                                                control: (provided, state) => ({
                                                                    ...provided,
                                                                    boxShadow: "none",
                                                                    border: 0,
                                                                    marginTop: '-6px',
                                                                    paddingLeft: '0px',
                                                                    background: "transparent",
                                                                    fontSize: '14px',
                                                                })
                                                            }}
                                                            theme={theme => ({
                                                                ...theme,
                                                                borderRadius: 0
                                                            })}
                                                        />

                                                    </div>
                                                    <ButtonGroup style={{ float: 'right', borderRadius: 0 }}>
                                                        <Button outline style={{borderRadius: 0}} className="widget_btn talk_btn" onClick={() => this.modalAttach(true)}><MdAttachFile style={{ width: 20, padding: 0 }} /></Button>
                                                        <Button outline style={{borderRadius: 0}} className="widget_btn talk_btn" color="dark" onClick={() => {
                                                            this.checkStatusChat();
                                                            this.getDepartamentos();
                                                            getOperadores(this.callbackOperadores.bind(this));
                                                            this.setState({modal_transfer: !this.state.modal_transfer}) 
                                                        }}><MdCallMade style={{ width: 20, padding: 0 }} /></Button>
                                                        <Button outline style={{borderRadius: 0}} className="widget_btn talk_btn" color="info" onClick={() => { this.isOpenCurrent() }}><MdViewList style={{ width: 20, padding: 0 }} /></Button>
                                                        <Button outline style={{borderRadius: 0}} className="widget_btn talk_btn" color="success" onClick={() => this.sendValid(this.state.chat_info.cod_chat)}><MdSend style={{ width: 20, padding: 0 }} /></Button>
                                                    </ButtonGroup>

                                                </div>
                                            </Col>     
                                        </>
                                    :
                                        <Col>
                                            {this.state.chat_info.avaliacao !== null ? <>
                                                    <b style={{marginRight: 35, fontSize: '15px'}}>
                                                        <RiChatHistoryFill style={{width: '22px', height: '22px'}}/>Chat Finalizado</b>
                                                        <StarRating
                                                            name="handler" 
                                                            caption="Use onClick Handlers!" 
                                                            totalStars={5} 
                                                            starDimension="28px"
                                                            starSpacing="0px"
                                                            starRatedColor="#38B76E"
                                                            rating={this.state.chat_info.avaliacao}
                                                            disabled
                                                        />
                                                    <Button className="widget_btn talk_btn" outline color="info" style={{float: 'right'}} onClick={() => { this.isOpenCurrent() }}><BiTransfer style={{ width: 20, padding: 0 }} /></Button>
                                                    
                                                <p style={{
                                                    marginTop: '8px',
                                                    marginBottom: 0,
                                                    fontSize: '15px'
                                                }}><b>Observação do cliente: </b>{this.state.chat_info.observacao_chat}</p>
                                                
                                                <div hidden={this.state.chat_info.pendencia_respondida || this.props.type_chat === 'monitoramento'}>
                                                    <hr/>
                                                    <Form>
                                                        <p>O cliente possui pendencia?</p>
                                                        <FormGroup>
                                                            <ButtonGroup>
                                                                <Button outline={!(this.state.pendencia_current === 'not')} onClick={() => this.setState({pendencia_current: 'not'})} color="danger">Não</Button>
                                                                <Button outline={!(this.state.pendencia_current === 'yes')} onClick={() => {
                                                                    this.getDepartamentos();
                                                                    this.setState({pendencia_current: 'yes'})}
                                                                 } color="success">Sim</Button>
                                                            </ButtonGroup>
                                                        </FormGroup>
                                                        <FormGroup hidden={this.state.pendencia_current !== 'yes'}>
                                                            <Input 
                                                                type="textarea"
                                                                name="motivo"
                                                                value={this.state.motivo}
                                                                disabled={this.state.disabled_pending}
                                                                onChange={this.handleChange}
                                                                placeholder="Descreva o motivo da pendencia" />
                                                            <br/>
                                                            <Select
                                                                className="react-select"
                                                                options={this.state.list_departamentos}
                                                                placeholder="Departamento (Chat)"
                                                                isSearchable={false}
                                                                value={this.state.setor}
                                                                onChange={(departamento) => this.setState({departamento})}
                                                                theme={theme => ({
                                                                    ...theme,
                                                                    borderRadius: 0
                                                                })}
                                                            />
                                                            <br/>
                                                            <Button color="success" onClick={() => this.responderRetorno()}>Confirmar</Button>
                                                        </FormGroup>
                                                        <FormGroup hidden={this.state.pendencia_current !== 'not'}>
                                                            <Button 
                                                                color="success" 
                                                                disabled={this.state.disabled_pending}
                                                                onClick={() => this.responderRetorno()}>Confirmar</Button>
                                                        </FormGroup>
                                                    </Form>
                                                </div>
                                            </>: <>
                                                { this.state.chat_info.status_chat  === 3 ? 
                                                    <p style={{color: 'red', margin: 0, fontSize: '16px'}}>
                                                        <RiChatOffFill style={{padding: '3px', margin: 0, top: '-1', position: 'relative'}}/> Chat Perdido
                                                        <Button className="widget_btn talk_btn" outline color="info" style={{float: 'right'}} onClick={() => { this.isOpenCurrent() }}><BiTransfer style={{ width: 20, padding: 0 }} /></Button></p>
                                                    :
                                                    <>
                                                        <RiChatHistoryFill/>Chat Finalizado
                                                        <Button className="widget_btn talk_btn" outline color="info" style={{float: 'right'}} onClick={() => { this.isOpenCurrent() }}><BiTransfer style={{ width: 20, padding: 0 }} /></Button>
                                                        <div hidden={this.state.chat_info.pendencia_respondida || this.props.type_chat === 'monitoramento'}>
                                                            <hr/>
                                                            <Form>
                                                                <p>O cliente possui pendencia?</p>
                                                                <FormGroup>
                                                                    <ButtonGroup>
                                                                        <Button outline={!(this.state.pendencia_current === 'not')} onClick={() => this.setState({pendencia_current: 'not'})} color="danger">Não</Button>
                                                                        <Button outline={!(this.state.pendencia_current === 'yes')} onClick={() => {
                                                                            this.getDepartamentos();
                                                                            this.setState({pendencia_current: 'yes'})}
                                                                        } color="success">Sim</Button>
                                                                    </ButtonGroup>
                                                                </FormGroup>
                                                                <FormGroup hidden={this.state.pendencia_current !== 'yes'}>
                                                                    <Input 
                                                                        type="textarea"
                                                                        name="motivo"
                                                                        value={this.state.motivo}
                                                                        disabled={this.state.disabled_pending}
                                                                        onChange={this.handleChange}
                                                                        placeholder="Descreva o motivo da pendencia" />
                                                                    <br/>
                                                                    <Select
                                                                        className="react-select"
                                                                        options={this.state.list_departamentos}
                                                                        placeholder="Departamento (Chat)"
                                                                        isSearchable={false}
                                                                        value={this.state.setor}
                                                                        onChange={(departamento) => this.setState({departamento})}
                                                                        theme={theme => ({
                                                                            ...theme,
                                                                            borderRadius: 0
                                                                        })}
                                                                    />
                                                                    <br/>
                                                                    <Button color="success" onClick={() => this.responderRetorno()}>Confirmar</Button>
                                                                </FormGroup>
                                                                <FormGroup hidden={this.state.pendencia_current !== 'not'}>
                                                                    <Button 
                                                                        color="success" 
                                                                        disabled={this.state.disabled_pending}
                                                                        onClick={() => this.responderRetorno()}>Confirmar</Button>
                                                                </FormGroup>
                                                            </Form>
                                                        </div>
                                                    </>
                                                }
                                            </>}
                                        </Col>
                                : <> </> }
                                    
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={this.state.isOpen === true ? 4 : 0} hidden={!this.state.isOpen}>
                        <this.Navs />
                        <TabContent  activeTab={this.state.activeTab}>
                            <ResumoClient 
                                active={'1'}
                                type_chat={this.props.type_chat}
                                chat={this.state.chat_info}
                                finalizarChat={this.finalizarChat}
                                chat_perdido={this.chat_perdido}
                                chamarAtendente={this.chamarAtendente}
                                transferClose={() => { 
                                        this.checkStatusChat();
                                        this.getDepartamentos();
                                        getOperadores(this.callbackOperadores.bind(this));
                                        this.setState({modal_transfer: !this.state.modal_transfer});
                                    }
                                }
                            />
                            <ChatsRecentes 
                                active={'2'}
                                chat={this.state.chat_info}
                            />
                            <NotasPrivadas
                                active={'3'}
                                chat={this.state.chat_info}
                                handleInputChange={this.handleInputChange}
                                loading_note={this.state.loading_note}
                                descricao={this.state.descricao}
                                createNote={this.createNote}
                            />
                            <InformacoesAqbank
                                active={'4'}
                                chat={this.state.chat_info}
                                activeCurrent={this.state.activeTab}
                            />
                        </TabContent>
                    </Col>
                </Row>
                <Modal isOpen={this.state.modal_anexo}>
                    <ModalBody className="modal-body">
                        <div style={{ display: 'inline-flex' }}>
                            <MdAttachFile /><h4>ADICIONAR ARQUIVOS</h4>
                        </div>
                        <hr />
                        <Dropzone onDrop={(drop) => this.onDrop(drop)}>
                            {({ getRootProps, getInputProps }) => (
                                <DropArea {...getRootProps({
                                    onClick: event => event.stopPropagation()
                                })}>
                                    <input {...getInputProps()} />
                                    {this.state.file.length > 0 ?
                                    <>
                                            <Row>
                                                {this.state.file.map((e, index) => {
                                                    return <Col key={index} md={4} style={{ paddingBottom: 4 }}>
                                                        <Card>
                                                            <p style={{ margin: '3px' }}>
                                                                <MdAttachFile style={{ width: '32px' }} />
                                                                {e.name}
                                                                <Button
                                                                    style={{ padding: 0, border: 3, float: 'right' }}
                                                                    color="danger"
                                                                    outline
                                                                    onClick={
                                                                        () => this.removeAttach(index)
                                                                    }>
                                                                    <MdClear style={{ padding: 0 }} />
                                                                </Button>
                                                            </p>
                                                        </Card>
                                                    </Col>
                                                })}
                                                <br />
                                            </Row>
                                            <p style={{ margin: 0 }}>
                                            <MdAttachFile style={{ width: '32px' }} />
                                                Arraste arquivos ou&nbsp;
                                                <ButtonUpload 
                                                    onClick={() => this.inputFile.current.click()} 
                                                    className="btn btn-dark" 
                                                    color="primary">
                                                    Clique Aqui
                                                </ButtonUpload> para adicionar mais anexos
                                                <input type="file" ref={this.inputFile} multiple hidden onChange={this.handleFileChange} />
                                        </p>
                                    </>
                                    :
                                    <>
                                        <p style={{ margin: 0 }}>
                                            <MdAttachFile style={{ width: '32px' }} />
                                                Arraste arquivos ou&nbsp;
                                                <ButtonUpload 
                                                    onClick={() => this.inputFile.current.click()} 
                                                    className="btn btn-dark" 
                                                    color="primary">
                                                    Clique Aqui
                                                </ButtonUpload> para adicionar anexos
                                                <input type="file" ref={this.inputFile} multiple hidden onChange={this.handleFileChange} />
                                        </p>
                                    </>
                                    }
                                </DropArea>
                            )}
                        </Dropzone>
                        <br />
                        <Input
                            type="textarea"
                            placeholder="Escreva sua mensagem"
                            style={{ height: 40, resize: 'none' }}
                            name="message"
                            value={this.state.message}
                            onChange={this.handleInputChange} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => this.modalAttach(false)}>Cancelar</Button>
                        <Button color="success" onClick={() => this.sendMessage(this.state.chat_info.cod_chat)}>Enviar</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.modal_transfer}>
                    <ModalBody className="modal-body">
                        <center>
                            <div>
                                <h4>TRANSFERIR CHAT PARA:</h4>
                            </div>
                            <div style={{marginLeft: '12px', marginRight: '12px', width: '80%'}}>
                                <Select 
                                    styles={{
                                        // Fixes the overlapping problem of the component
                                        menu: provided => ({ ...provided, zIndex: 9999 })
                                    }}
                                    placeholder="Departamento"
                                    options={this.state.list_departamentos}
                                    isSearchable={false}
                                    onChange={(departamento) => this.setState({departamento})}
                                    isOptionDisabled={(option) => option.status === 0}
                                />
                            </div>
                            <LineAuth/>
                            <div style={{marginLeft: '12px', marginRight: '12px', width: '80%'}}>
                                <Select
                                    placeholder="Operador"
                                    isSearchable={false}
                                    options={this.state.operadores_list}
                                    isOptionDisabled={(option) => option.status_chat === 0}
                                    onChange={(operador) => this.setState({operador})}
                                    />
                            </div>
                        </center>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => this.setState({ departamento: null, modal_transfer: false, operador: null})}>Cancelar</Button>
                        <Button color="success" onClick={() => this.transferirChat()}>Transferir</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.modal_image} centered size="lg">
                    <ModalBody>
                        <center>
                            <img 
                                style={{
                                    maxWidth: '100%'
                                }}
                                alt="ver-imagem"
                                src={this.state.urlImage}
                            />
                        </center>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => this.onImageModal(false, '')}>Fechar</Button>
                    </ModalFooter>
                </Modal>
            </Div>
        )
    }
}

export default ChatAtendimento;