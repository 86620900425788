import React from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, Col, Row, Collapse, Button, Label, Input } from 'reactstrap';
import Pagination from '../Pagination';
import debounce from 'lodash/debounce';
import { columns } from './propsCupom';
import { InputText } from '../../InputText/InputText';
import { InputMasked } from '../../InputText/InputMasked';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import Select from 'react-select';

var CurrencyInput = require('react-currency-masked-input');

const formas_pagamentos = [
    { value: 'DEVOLVIDO', label: 'DEVOLVIDO' },
    { value: 'CANCELADA', label: 'CANCELADA' },
    { value: 'APROVADO', label: 'APROVADO' },
    { value: 'RESERVANDO', label: 'PROCESSANDO' }
];

const TableCupom = (props) => {
    return (
        <>
            <Card>
                <CardBody>
                    <Row>
                        <Col md={10}>
                            <div style={{ textAlign: 'left' }}>
                                <InputText
                                    name="search"
                                    placeholder="Busca por código"
                                    onChange={debounce((event) => props.isSearch(event), 500)} />
                            </div>
                        </Col>
                        <Col md={2} style={{padding: 0, textAlign: 'right'}}>
                                <Button
                                    color="warning"
                                    onClick={props.filterShow}
                                    style={{ marginRight: '25px', marginBottom: '4px' }}>
                                    Filtrar&nbsp;
                                    <AiFillCaretDown hidden={props.show_filter} />
                                    <AiFillCaretUp hidden={!props.show_filter} />
                                </Button>
                        </Col>
                    </Row>
                    <Collapse isOpen={props.show_filter}> 
                    <br/>
                    <Card style={{border: 0}}>
                        <Row>
                            {/* <Col md={6}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputMasked
                                        name="document"
                                        mask={props.is_cnpj === false ? "999.999.999-99" : "99.999.999/9999-99"}
                                        placeholder={props.is_cnpj === false ? "CPF" : "CNPJ"}
                                        onChange={debounce((event) => props.isSearch(event), 500)}
                                        />
                                    &nbsp;&nbsp;<input 
                                        type="checkbox"
                                        checked={props.is_cnpj}
                                        onChange={() => props.onSearchDocument()}/> Pesquisar CNPJ
                                </div>
                            </Col> */}
                            <Col md={6}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputMasked
                                        name="document"
                                        mask={props.is_cnpj === false ? "999.999.999-99" : "99.999.999/9999-99"}
                                        placeholder={props.is_cnpj === false ? "CPF" : "CNPJ"}
                                        onChange={debounce((event) => props.isSearch(event), 500)}
                                        />
                                </div>
                            </Col>
                            <Col md={2}>
                                <p style={{marginTop: '16px'}}><input 
                                    type="checkbox"
                                    checked={props.is_cnpj}
                                    onChange={() => props.onSearchDocument()}/> Pesquisar CNPJ</p>
                            </Col>
                        </Row>
                        <Row >
                            <Col md={6}>
                                <div style={{ textAlign: 'left' }}>
                                <small>Status do cliente</small>
                                <Select
                                    className="react-select"
                                    options={formas_pagamentos}
                                    placeholder="Status do cliente"
                                    isSearchable={false}
                                    isClearable
                                    value={props.state.status}
                                    onChange={props.handleTypeStatusChange}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 0
                                    })}
                                />
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        <small>Filtro por Valor</small>
                        <Row>
                            <Col md={6}>
                                <Label>De:</Label>
                                <CurrencyInput 
                                    inputMode="tel"
                                    className="form-control" 
                                    currency="BRL"
                                    name="valor_de"
                                    //value={valor}
                                    defaultValue={'0.00'}
                                    onChange={debounce((event) => props.isSearch(event), 500)} />
                            </Col>
                            <Col md={6}>
                                <Label>Até:</Label>
                                <CurrencyInput 
                                    inputMode="tel"
                                    className="form-control" 
                                    currency="BRL"
                                    name="valor_ate"
                                    //value={valor}
                                    defaultValue={'0.00'}
                                    onChange={debounce((event) => props.isSearch(event), 500)} />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>De:
                            <Input
                                    name="date_from"
                                    type="date"
                                    //disabled
                                    disabled={!(props.filter === true)}
                                    value={props.date_from}
                                    onChange={(event) => props.isSearch(event)} /></Col>
                            <Col>Até:
                            <Input
                                    name="date_to"
                                    type="date"
                                    disabled={!(props.filter === true)}
                                    //disabled
                                    value={props.date_to}
                                    onChange={(event) => props.isSearch(event)} /></Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <input
                                    type="checkbox"
                                    checked={props.filter}
                                    name="filter"
                                    onChange={(value) => props.onFilter(value.target.checked)} /> Filtrar por data
                            </Col>
                        </Row>
                    </Card>
                    </Collapse>
                    <hr style={{ marginBottom: '4px' }} />
                    <DataTable
                        noHeader={true}
                        columns={
                            columns(props.handleOpenUsers)
                        }
                        button={true}
                        responsive
                        noDataComponent={props.noDataComponent}
                        data={props.data}
                        theme="default"
                    />
                </CardBody>
                <CardFooter style={{ textAlign: 'center' }}>
                    <Pagination
                        onPageChange={props.onPageChange}
                        activePage={props.activePage}
                        totalPage={props.totalPage} />
                </CardFooter>
            </Card>
        </>
    )
}
export default TableCupom;