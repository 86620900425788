import { Component } from "react";
import TableSellers from "../../components/Tables/TableSellers/TableSellers";
import { MessageNoTable } from "../MessageTable/MessageNoTable";

class SellersSuspeitos extends Component{
    constructor(props){
        super(props);
        this.state = {
            search: '',
            show_filter: false,
            rowData: [],
            loading_table: false,
            activePage: 1,
            document: '',
            is_cnpj: false,
            totalPage: 1
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        //getRowsPerPage(this.props.communication_relacao_id, 1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }
    filterShow(){
        this.setState({
            show_filter: !this.state.show_filter
        })
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        //getRowsPerPage(this.props.communication_relacao_id, activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    render(){
        return (
            <div>
                <h3>Sellers Suspeitos</h3>
                <hr />
                <TableSellers
                    isSearch={this.handleChange}
                    noDataComponent={<MessageNoTable message="Nenhum seller encontrado" loading_table={this.state.loading_table}/>}
                    show_filter={this.state.show_filter}
                    filterShow={this.filterShow.bind(this)}
                    document={this.state.document}
                    is_cnpj={this.state.is_cnpj}
                    onSearchDocument={this.onSearchDocument.bind(this)}
                    activePage={this.state.activePage}
                    totalPage={this.state.totalPage}
                />
            </div>
        )
    }
}

export default SellersSuspeitos;