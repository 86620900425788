import React from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, Row, Col, Input, CardFooter, Button } from 'reactstrap';
import { columns } from './propsOcorrencia';
import debounce from 'lodash/debounce';
import Pagination from '../Pagination';
import { InputText } from '../../InputText/InputText';
import { InputMasked } from '../../InputText/InputMasked';
import Select from "react-select";
import { MdAdd } from 'react-icons/md';
import { list_produtos, list_tipos } from '../../../auth/listas';
import { useState } from 'react';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import axios from 'axios';
import { useEffect } from 'react';

const getOperadores = (callback) => {
    var url = ENDPOINT + "chat-atendimento/consultar-operadores";

    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        var operadores_list = [];
        data.forEach((p, i) => {
            p['value'] = p.id;
            p['label'] = p.name;
            operadores_list.push(p);
        });

        callback(operadores_list)
    });
}

export const TableOcorrencia = (props) => {
    const [operadores_list, setOperadoresList] = useState([]);

    useEffect(() => {
        getOperadores(setOperadoresList);
    }, []);

    return (
        <Card>
            <CardBody>
                {props.isShowNew ?
                    <div>
                        <Button color="success" onClick={() => props.isShowNew(true)}><MdAdd />Nova Ocorrencia</Button>
                        <hr />
                    </div>
                    :
                    <></>
                }
                <Row >
                    <Col md={4}>
                        <div style={{ textAlign: 'left' }}>
                            <InputText
                                name="search"
                                placeholder="Buscar"
                                onChange={debounce((event) => props.isSearch(event), 1000)} />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div style={{ textAlign: 'left' }}>
                            <InputText
                                name="protocolo_ocorrencia"
                                placeholder="Protocolo da ocorrência"
                                onChange={debounce((event) => props.isSearch(event), 1000)} />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div style={{ textAlign: 'left' }}>
                            <InputText
                                name="protocolo_cliente"
                                placeholder="Protocolo do cliente"
                                onChange={debounce((event) => props.isSearch(event), 1000)} />
                        </div>
                    </Col>
                </Row>
                <br />
                {props.isShowNew ?
                    <div>
                        <Row>
                            <Col md={6}>
                                <Select
                                    placeholder="Produto"
                                    options={list_produtos}
                                    isSearchable={false}
                                    value={props.produto}
                                    onChange={(product) => props.isSearchSelect('produto', product)}
                                    noOptionsMessage={() => { return 'Sem opções' }} />
                            </Col>
                            <Col md={6}>
                                <Select
                                    placeholder="Tipo"
                                    options={list_tipos}
                                    isSearchable={false}
                                    value={props.tipo_ocorrencia}
                                    onChange={(value) => props.isSearchSelect('tipo_ocorrencia', value)}
                                    noOptionsMessage={() => { return 'Sem opções' }} />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={6}>
                                <Select
                                    className="react-select"
                                    options={operadores_list}
                                    placeholder="Operador Atribuido"
                                    isSearchable={false}
                                    isClearable
                                    onChange={(value) => props.isSearchSelect('operador', value)}
                                />
                            </Col>
                            <Col md={4}>
                                <InputMasked
                                    name="document"
                                    mask={props.is_cnpj === false ? "999.999.999-99" : "99.999.999/9999-99"}
                                    placeholder={props.is_cnpj === false ? "CPF" : "CNPJ"}
                                    onChange={debounce((event) => props.isSearch(event), 500)}
                                />
                                <input
                                    type="checkbox"
                                    checked={props.is_cnpj}
                                    onChange={() => props.onSearchDocument()} /> Pesquisar CNPJ
                            </Col>
                        </Row>
                    </div>
                    :
                    <></>
                }
                <br />
                <Row>
                    <Col>De:
                        <Input
                            name="date_from"
                            type="date"
                            //disabled
                            disabled={!(props.filter === true)}
                            value={props.date_from}
                            onChange={(event) => props.isSearch(event)} /></Col>
                    <Col>Até:
                        <Input
                            name="date_to"
                            type="date"
                            disabled={!(props.filter === true)}
                            //disabled
                            value={props.date_to}
                            onChange={(event) => props.isSearch(event)} /></Col>
                </Row>
                {props.isShowNew ?
                    <Row>
                        <Col md={5}>
                            <input
                                type="checkbox"
                                checked={props.filter}
                                name="filter"
                                onChange={(value) => props.onFilter(value.target.checked)} /> Filtrar por data de alteração
                        </Col>
                    </Row>
                    :
                    <></>
                }
            </CardBody>
            <hr style={{ marginBottom: '4px' }} />
            <DataTable
                noHeader={true}
                button={true}
                noDataComponent={props.noDataComponent}
                columns={columns(props.onDelete)}
                data={props.data}
                theme="default"
            />
            <CardFooter style={{ textAlign: 'center' }}>
                <Pagination
                    onPageChange={props.onPageChange}
                    activePage={props.activePage}
                    totalPage={props.totalPage}
                />
            </CardFooter>
        </Card>
    )
}