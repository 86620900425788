import React, { Component } from 'react';
import { Card, CardBody, TabPane } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { TableOcorrencia } from "../../components/Tables/TableOcorrencia/TableOcorrencia";
import Moment from 'moment';
import { getToken } from '../../auth/set_services';
import { ENDPOINT } from '../../auth/endpoint';
import axios from 'axios';
import { AiOutlineException } from 'react-icons/ai';

/* const getId = () =>{
    if(window.location.pathname === '/contestacoes'){
        return null;
    }
    else{
        return window.location.pathname.replace('/contestacoes/', '')
    }
} */

const getRows = (cpf_cnpj, state, callbackRows) => {
    if(typeof cpf_cnpj === 'undefined'){
        return null;
    }
    
    if(cpf_cnpj.length === 0){
        return null;
    }

    var url = ENDPOINT + "ocorrencia/list?search=" + state.search + "&page="+state.activePage;
    if(cpf_cnpj.length > 11){
        url += '&cnpj='+cpf_cnpj
    }
    else{
        url += '&cpf='+cpf_cnpj
    }
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}
  
const getRowsPerPage = (cpf_cnpj, page, search, filter, state, callbackRows) => {
    if(typeof cpf_cnpj === 'undefined'){
        return null;
    }
    
    if(cpf_cnpj.length === 0){
        return null;
    }

    var url = ENDPOINT + "ocorrencia/list?search=" + search + "&page=" + page;

    if(cpf_cnpj.length > 11){
        url += '&cnpj='+cpf_cnpj
    }
    else{
        url += '&cpf='+cpf_cnpj
    }
  
    if (filter === true) {
      url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }
  
    axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + getToken()
      }
    }).then((response) => {
      callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}
class OcorrenciasClientFromCpf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            activePage: 1,
            loading_table: true,
            //email: getId(),
            totalPage: 1,
            filter: false,
            search: '',
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
    }
    
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(this.props.cpf_cnpj, 1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentDidMount() {
        getRows(this.props.cpf_cnpj, this.state, this.callbackRows.bind(this));
    }
    componentWillUpdate(nextProps, nextState) {
        if (nextProps.cpf_cnpj !== this.props.cpf_cnpj) {
            getRows(nextProps.cpf_cnpj, this.state, this.callbackRows.bind(this))
        }
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    onFilter = (filter) => {
        this.setState({ filter });
        getRowsPerPage(this.props.cpf_cnpj, 1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(this.props.cpf_cnpj, activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    handleSelect = (name, value) => {
        var state = this.state;
        state[name] = value;
        state.rowData = [];
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(this.props.cpf_cnpj, 1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }
    render() {
        return (
            <TabPane tabId={this.props.active}>
                <h5>&nbsp;&nbsp;<AiOutlineException style={{width: '30px', height: '30px'}}/> Ocorrências</h5>
                <Card>
                    <TableOcorrencia
                        clientEmail
                        isSearch={this.handleChange}
                        isSearchSelect={this.handleSelect}
                        data={this.state.rowData}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        noDataComponent={this.state.loading_table === true ?
                            <Card style={{ border: 0, padding: '10vh' }}><CardBody>
                                <div><div className="sweet-loading">
                                    <PropagateLoader color={'#52c565'} loading={20} size={30} />
                                </div></div></CardBody></Card> :
                            <Card style={{ border: 0 }}><CardBody><div>
                                Nenhum e-mail encontrado
                            </div></CardBody></Card>
                        }
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
            </TabPane>
        )
    }
}

export default OcorrenciasClientFromCpf;