import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Loader } from "../../../components/Loaders/Loader";
import styled from "styled-components";
import { booleanToYesOrNo, formatPhone } from "../../../utils";
import { useLocation } from "react-router-dom";
import NumberFormat from "react-number-format";
import { useApiAqmed } from "../../../hooks/useApi";

const Board = styled.div`
  background-color: #f5f5f5;
  margin-top: 30px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h2 {
    margin-bottom: 10px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin-bottom: 5px;
  }
`;

export function UniodontoUserData() {
  const url = useLocation().pathname;
  const urlArray = url.split("/");
  const id = urlArray[urlArray.length - 1];

  const { apiAqmed } = useApiAqmed()
  const [isFetching, setIsFetching] = useState(true);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const token = Cookies.get("aqmedtoken");
    console.log("token = ", token);

    apiAqmed
      .get(`/crm/uniodonto/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        const client = res?.data?.uniodonto_contract;
        setUserData(client);
      })
      .catch((e) => {
        alert("Erro ao buscar dados");
        console.log(e);
      })
      .finally(() => setIsFetching(false));
  }, []);

  if (isFetching) {
    return <Loader />;
  } else {
    return (
      <div>
        <h3>Dados do Cliente</h3>
        <hr />

        <Board>
          <div>
            <h4>
              <strong>{userData?.owner?.name}</strong>
            </h4>
          </div>
          <ul>
            <li>
              <strong>Número do contrato: </strong>
              {userData?.contract_number}
            </li>
            <li>
              <strong>Data de Criação: </strong>
              {userData?.created_at}
            </li>
            <li>
              <strong>Descrição: </strong>
              {userData?.description}
            </li>
            <li>
              <strong>Nome da Operação: </strong>
              {userData?.operation_name}
            </li>
            <hr />
            <li>
              <strong>Email: </strong>
              {userData?.user?.email}
            </li>
            <li>
              <strong>Débito Automático: </strong>
              {booleanToYesOrNo(userData?.user?.automatic_billing)}
            </li>
            <li>
              <strong>Data de nascimento: </strong>
              {userData?.user?.date_birth}
            </li>
            <li>
              <strong>Documento:</strong>{" "}
              {userData?.user?.document && (
                <NumberFormat
                  displayType={"text"}
                  value={userData?.user?.document}
                  format={
                    userData?.user?.document.replace(/([^\d])+/gim, "").length >
                    11
                      ? "##.###.###/####-##"
                      : "###.###.###-##"
                  }
                />
              )}
            </li>
            <li>
              <strong>Telefone: </strong>
              {formatPhone(userData?.user?.phone_number)}
            </li>
            <li>
              <strong>Status: </strong>
              {userData?.user?.status}
            </li>
            <li>
              <strong>Sexo: </strong>
              {userData?.user?.gender.toUpperCase()}
            </li>
            <li>
              <strong>Plano uniodonto: </strong>
              {booleanToYesOrNo(userData?.user?.plan_has_uniodonto)}
            </li>
            <li>
              <strong>Usuário uniodonto: </strong>
              {booleanToYesOrNo(userData?.user?.user_has_uniodonto)}
            </li>
            <li>
              <strong>Nome do Plano do usuário: </strong>
              {userData?.user?.userPlan_name}
            </li>
            <li>
              <strong>Email Verificado: </strong>
              {booleanToYesOrNo(userData?.user?.email_verified)}
            </li>
            <li>
              <strong>Telefone Verificado: </strong>
              {booleanToYesOrNo(userData?.user?.verify_phone)}
            </li>
          </ul>
        </Board>
      </div>
    );
  }
}
