import React from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, Row, Col, Collapse, Input, CardFooter, Button } from 'reactstrap';
import { FaFileExcel } from 'react-icons/fa' 
import { columns } from './propsFaturaAQContabil';
import debounce from 'lodash/debounce';
import Pagination from '../Pagination';
import { InputText } from '../../InputText/InputText';
import { InputMasked } from '../../InputText/InputMasked';
import Select from 'react-select';
import ecommerce_logo from '../../../assets/ecommerce-aqpago.svg';

const status_envio_list = [
    {
        value: 'email',
        label: 'E-mail'
    },
    {
        value: 'correios',
        label: 'Correios'
    }
];

const status_fatura_list = [
    {
        value: 'a vencer',
        label: 'Faturas a vencer'
    },
    {
        value: 'fechada',
        label: 'Faturas fechadas'
    },
    {
        value: 'paga',
        label: 'Faturas pagas'
    },
    {
        value: 'vencidas',
        label: 'Faturas vencidas'
    }
]
const status_pagamentos = [
    { value: 'PENDENTE', label: 'PENDENTE' },
    { value: 'APROVADO', label: 'APROVADO' },
    { value: 'ESTORNADO', label: 'ESTORNADO' },
    { value: 'FALHA DE PAGAMENTO', label: 'FALHA DE PAGAMENTO' }
];
export const TableFaturaAQContabil = (props) => {
    return (
        <Card>
            <Collapse isOpen={true}>
                <Card style={{ border: 'none' }}>
                    <CardBody>
                        <Row >
                            <Col md={5}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputText
                                        name="search"
                                        placeholder="Buscar"
                                        onChange={debounce((event) => props.isSearch(event), 1000)} />
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={4}>
                                <div style={{ textAlign: 'left' }}>
                                <Select
                                    className="react-select"
                                    options={status_pagamentos}
                                    placeholder="Status de Pagamento"
                                    isSearchable={false}
                                    value={props.state.status_pagamento}
                                    onChange={props.handleStatusSelectChange}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 0
                                    })}
                                />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputMasked
                                        name="document"
                                        mask={props.is_cnpj === false ? "999.999.999-99" : "99.999.999/9999-99"}
                                        placeholder={props.is_cnpj === false ? "CPF" : "CNPJ"}
                                        onChange={debounce((event) => props.isSearch(event), 500)}
                                        />
                                </div>
                            </Col>
                            <Col md={2}>
                                <p style={{marginTop: '16px'}}><input 
                                    type="checkbox"
                                    checked={props.is_cnpj}
                                    onChange={() => props.onSearchDocument()}/> Pesquisar CNPJ</p>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>De:
                            <Input
                                    name="date_from"
                                    type="date"
                                    //disabled
                                    disabled={!(props.filter === true)}
                                    value={props.date_from}
                                    onChange={(event) => props.isSearch(event)} /></Col>
                            <Col>Até:
                            <Input
                                    name="date_to"
                                    type="date"
                                    disabled={!(props.filter === true)}
                                    //disabled
                                    value={props.date_to}
                                    onChange={(event) => props.isSearch(event)} /></Col>
                        </Row>
                        <Row>
                            <Col md={5}>
                                <input
                                    type="checkbox"
                                    checked={props.filter}
                                    name="filter"
                                    onChange={(value) => props.onFilter(value.target.checked)} /> Filtrar por data

                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Collapse>
            <hr style={{ marginBottom: '4px' }} />
            <DataTable
                noHeader={true}
                button={true}
                noDataComponent={props.noDataComponent}
                columns={columns()}
                expandableRows
                expandableRowsComponent={props.expandableRowsComponent}
                data={props.data}
                theme="default"
            />
            <CardFooter style={{ textAlign: 'center' }}>
                <Pagination
                    onPageChange={props.onPageChange}
                    activePage={props.activePage}
                    totalPage={props.totalPage}
                />

            </CardFooter>
        </Card>
    )
}