import React, { Component } from "react";
import { Button, ButtonGroup, Card, CardBody, FormGroup, Input, Label } from "reactstrap";
import { getRows, getRowsPerPage } from '../../components/Tables/TableSerasa/propsSerasa';
import moment from "moment";
import PropagateLoader from "react-spinners/PropagateLoader";
import { toast } from "react-toastify";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import { InputMasked } from "../../components/InputText/InputMasked";
import { cpf } from "cpf-cnpj-validator";
import { TableSerasa } from "../../components/Tables/TableSerasa/TableSerasa";
import ReactScoreIndicator from "react-score-indicator";
import NumberFormat from "react-number-format";

const zoopUser = (id, callback) => {
    var url = ENDPOINT + "titularidade/zoop_user/"+id;
    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        callback(p);
    })
}

const zoopUserEmp = (id, cpf_is, callback) => {
    var url = ENDPOINT + "titularidade/zoop_user/"+id+"?cpf="+cpf_is.replace(/([^\d])+/gim, '');
    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        callback(p);
    })
}

const apagarCliente = (id, callback) => {
    var url = ENDPOINT + "titularidade/apagar_cliente/"+id;
    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        callback(p);
    })
}
const alterarStatus = (id, status, callback) => {
    var url = ENDPOINT + "titularidade/alterar_status?id="+id+"&status="+status;
    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        callback(p);
    })
}
class Serasa extends Component{
    constructor(props){
        super(props);
        this.state = {
            visible_modal: false,
            activePage: 1,
            loading_table: true,
            cpf_current: null,
            cnpj_current: '',
            totalPage: 1,
            document: '',
            is_cnpj: false,
            filter: false,
            apagar_loading: false,
            zoop_loading: false,
            search: '',
            status: null,
            service_select: null,
            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
    }
    onClose = (visible_modal) => {
        this.setState({ visible_modal })
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.rowData = [];
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }


    componentDidMount() {
        getRows(this.state, this.callbackRows.bind(this));
    }

    handleChangeCpf = (event, index) => {
        let value = event.target.value;

        let rowData = this.state.rowData;
        rowData[index].cpf_string = value

        this.setState({ rowData });

    }
    callbackRows = (rowData, activePage, totalPage) => {
        rowData.forEach((e, index) => {
            e.index = index;
            e.cpf_string = '';
        });

        console.log(rowData);
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    callbackToast = (data) => {
        this.setState({zoop_loading: false, apagar_loading: false});

        if(data.success === true){
            toast.success(data.message);
        }
        else{
            toast.error(data.errors);
        }

        getRows(this.state, this.callbackRows.bind(this));
    }

    onFilter = (filter) => {
        this.setState({ filter });
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    onZoop = (id) => {
        this.setState({zoop_loading: true});
        zoopUser(id, this.callbackToast);
    }
    
    onZoopEmp = (id, cpf_is) => {
        if(cpf.isValid(cpf_is.replace(/([^\d])+/gim, ''))){
            this.setState({zoop_loading: true});
            zoopUserEmp(id, cpf_is, this.callbackToast);
        }
        else{
            toast.error('CPF Inválido');
        }

    }
    
    onDelete = (id) => {
        this.setState({apagar_loading: true});
        apagarCliente(id, this.callbackToast);
    }
    
    isTransfer = (value) => {
        switch(value){
            case 'migrar_pj': return 'Migrar para PJ';
            case 'troca_titularidade': return 'Trocar Titularidade';
            default: return ''
        }
    }

    passoStep = (value) => {
        switch(value){
            case 1: return 'Aguardando cadastro';
            case 2: return 'Aguardando Documentos';
            case 3: return 'Aguardando pagamento';
            case 4: return 'Aguardando aprovação';
            case 5: return 'Finalizado';
            case 6: return 'Reprovado';
            default: return ''
        }
    }
    ExpandableRows = ({data}) => {
        //const dataAll = JSON.parse(data.resposta);
        var score = JSON.parse(data.consulta_serasa);
        var resumo = JSON.parse(data.resumo_serasa);
        return <div style={{margin: '20px'}}>
            <Card>
                <CardBody>
                    <ReactScoreIndicator
                        value={score.score.pontos}
                        maxValue={1000}
                        lineWidth={25}
                    />
                    <span style={{
                        position: 'absolute',
                        top: '4rem', 
                        width: '100%',
                        left: 0,
                        fontSize: '55px',
                        textAlign: 'center',
                        fontWeight: 'bold'
                    }}>
                        {score.score.classe}
                    </span>
                    <center>
                        <h5>{score.score.faixa.titulo}</h5>
                    </center>
                    <hr/>
                    <div>
                        <p>{score.score.faixa.descricao}</p>
                        <p><b>Data da Consulta:</b> {moment(new Date(data.created_at).toString()).format('DD/MM/YYYY HH:m')}</p>
                        <p>
                            <b>Renda Presumida:</b>&nbsp;                                                         
                            <NumberFormat 
                                prefix="R$ " 
                                displayType="text" 
                                value={parseFloat(resumo.rendaPresumida)} 
                                decimalSeparator=","/>
                        </p>
                        <p>
                            <b>{resumo.debitos.sumQuantidade} Débito{resumo.debitos.sumQuantidade > 1 ? 's' : ''}:</b>&nbsp;                                                         
                            <NumberFormat 
                                prefix="R$ " 
                                displayType="text" 
                                value={parseFloat(resumo.debitos.sumValorTotal)} 
                                decimalSeparator=","/>
                        </p>
                        <p>
                            <b>{resumo.protestos.sumQuantidade} Protesto{resumo.protestos.sumQuantidade > 1 ? 's' : ''} :</b>&nbsp;                                                         
                            <NumberFormat 
                                prefix="R$ " 
                                displayType="text" 
                                value={parseFloat(resumo.protestos.sumValorTotal)} 
                                decimalSeparator=","/>
                        </p>
                        {resumo.acoes ?
                            <p>
                                <b>{resumo.acoes.sumQuantidade} Aç{resumo.acoes.sumQuantidade > 1 ? 'ões' : 'ão'}:</b>&nbsp;                                                         
                                <NumberFormat 
                                    prefix="R$ " 
                                    displayType="text" 
                                    value={parseFloat(resumo.acoes.sumValorTotal)} 
                                    decimalSeparator=","/>
                            </p>
                            :
                            <></>
                        }
                        <p>
                            <b>{resumo.cheques.sumQuantidade} Cheque{resumo.cheques.sumQuantidade > 1 ? 's' : ''}</b>&nbsp;                                                         
                        </p>
                    </div>
                </CardBody>
            </Card>
        </div>
    }

    
    handleSelectChange = status => {
        var state = this.state;
        state['status'] = status;
        state.loading_table = true;
        state.rowData = [];
        this.setState({ ...state });
        getRowsPerPage(1, state.search, state.filter, state, this.callbackRows.bind(this));
    };
    
    handleServiceSelectChange = service_select => {
        var state = this.state;
        state['service_select'] = service_select;
        state.loading_table = true;
        state.rowData = [];
        this.setState({ ...state });
        getRowsPerPage(1, state.search, state.filter, state, this.callbackRows.bind(this));
    };

    render(){
        return(
            <div>
                <h3>Reprovados na análise de crédito</h3>
                <hr/>
                <TableSerasa
                    isSearch={this.handleChange}
                    onSearchDocument={this.onSearchDocument.bind(this)}
                    data={this.state.rowData}
                    is_cnpj={this.state.is_cnpj}
                    onFilter={this.onFilter.bind(this)}
                    onPageChange={this.onPageChange.bind(this)}
                    handleSelectChange={this.handleSelectChange.bind(this)}
                    handleServiceSelectChange={this.handleServiceSelectChange.bind(this)}
                    filter={this.state.filter}
                    status={this.state.status}
                    service_select={this.state.service_select}
                    expandableRowsComponent={<this.ExpandableRows />}
                    noDataComponent={this.state.loading_table === true ?
                        <Card style={{ border: 0, padding: '10vh' }}><CardBody>
                            <div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                        <Card style={{ border: 0 }}><CardBody><div>
                            Nenhuma tarefa encontrado
                        </div></CardBody></Card>
                    }
                    activePage={this.state.activePage}
                    totalPage={this.state.totalPage}
                    date_from={this.state.date_from}
                    date_to={this.state.date_to}
                />
            </div>
        )
    }
}

export default Serasa;