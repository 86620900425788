
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { Card, CardBody, Row, Col, Input, CardFooter, Collapse, Button, Label } from 'reactstrap';
import { columns } from './propsVendedores';
import debounce from 'lodash/debounce';
import Pagination from '../../Pagination';
import { InputText } from '../../../InputText/InputText';
import InputMask from 'react-input-mask';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import Select from 'react-select';
import { ENDPOINT } from '../../../../auth/endpoint';
import { getToken } from '../../../../auth/set_services';
import { FaSearch } from 'react-icons/fa';



export const TableVendedores = (props) => {
    return (
        <Card>
            <CardBody>
                <Row>
                    <Col md={12}>
                        <h5>Selecione o Vendedor para criar uma regra personalizada:</h5><hr />
                    </Col>
                </Row>
                <Row style={{marginBottom: '1.2rem'}}>
                    <Col md={6}>
                        <div style={{ textAlign: 'left' }}>
                            <InputText
                                name="search"
                                placeholder="CPF / CNPJ"
                                onChange={debounce((event) => props.isSearch(event), 1000)} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div style={{ textAlign: 'left' }}>
                            <InputText
                                name="email"
                                placeholder="Email"
                                onChange={(value) => props.onChangeSelect('search_seller_email', value.target.value)}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div style={{ textAlign: 'left' }}>
                            <InputText
                                name="nome"
                                placeholder="Nome / Razão Social"
                                onChange={(value) => props.onChangeSelect('search_seller_name', value.target.value)}/>
                        </div>
                    </Col>
                </Row>
            </CardBody>
            <hr style={{ marginBottom: '4px' }} />
            <DataTable
                noHeader={true}
                button={true}
                noDataComponent={props.noDataComponent}
                columns={columns(props, props.ruleSellerData)}
                data={props.data}
                theme="default"
            />
            <CardFooter style={{ textAlign: 'center' }}>
                <Pagination
                    onPageChange={props.onPageChange}
                    activePage={props.activePage}
                    totalPage={props.totalPage}
                />
            </CardFooter>
        </Card>
    )
}