import moment from 'moment';
import React from 'react';
import { Button, FormFeedback, Input, TabPane } from 'reactstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import Select from 'react-select';
import { InputMasked } from "../InputText/InputMasked";

const ponto_list = [
    { value: 'shopping_nova_lapa', label: 'Shopping Nova Lapa'},
    { value: 'shopping_bahia', label: 'Shopping da Bahia'},
    { value: 'shopping_sp_market', label: 'Shopping SP Market'},
    { value: 'filial_sao_paulo', label: 'Filial São Paulo'},
    { value: 'filial_salvador', label: 'Filial Salvador'},
    { value: 'matriz_bh', label: 'Matriz BH'},
];

const maquininhas_list = [
    {   value: 'MP5', label: 'MP5'  },
    {   value: 'D195', label: 'D195'  },
    {   value: 'S920', label: 'S920'  },
    {   value: 'C680', label: 'C680'  },
    {   value: 'D200', label: 'D200'  },
];

const prazo_entrega_list = [
    { value: 'correios', label: 'Correios - 7 dias'},
    { value: 'pdv', label: 'Ponto de Venda - Imediato'},
    { value: 'aqenvio', label: 'AQEnvio'},
];

const formas_pagamento = [
    { value: 'credit', label: 'Crédito'},
    { value: 'debito', label: 'Débito'},
    { value: 'boleto', label: 'Boleto'},
    { value: 'pix', label: 'PIX'},
];

const get_by_array = (is_value, array) => {
    var label = '';

    array.forEach(e => {
        if(e.value === is_value){
            label = e.label;
        }
    });

    return label;
}

const DataClientVenda = (props) => {
    return (
        <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
            {typeof(props.id) !== 'undefined' && props.id !== null ? 
                <>
                    <h5>Dados Pessoais</h5>
                    <hr/>
                    <p><b>Nome do Responsavel:</b> {props.nome + ' ' + props.sobrenome}</p>
                    {props.status ?
                        <p><b>Status:</b> {props.status}</p>
                    :<></>}
                    
                    <p><b>E-mail:</b> {props.email} </p>
                    <p><b>Telefone:</b> {props.telefone} </p>
                    {props.endereco !== null ?
                        <p><b>Endereço: </b>{props.endereco.logradouro} {props.endereco.numero}, {props.endereco.complemento} - {props.endereco.bairro}, {props.endereco.cidade}/{props.endereco.uf} - {props.endereco.cep} <Button outline color='success' style={{padding: '3px', fontSize: '12px'}} onClick={() => props.onOpen()}>Editar</Button></p>
                        :
                        <p><Button color="info" onClick={() => props.onOpen()} style={{padding: 3}}>Cadastrar Endereco</Button></p>
                    }
                    <p><b>Documento (CPF/CNPJ):</b> {props.cnpj.length === 0 ? props.cpf : props.cnpj}</p>
                    <p><b>Criado em:</b> {moment(new Date(props.created_at)).format('DD/MM/YYYY HH:mm')}</p>
                    {props.empresa !== null ? 
                        <>
                            <p><b>E-mail da empresa:</b> {props.empresa.email}</p>
                            <p><b>Nome fantansia:</b> {props.empresa.nome_fantasia}</p>
                            <p><b>Nome da empresa:</b> {props.empresa.razao_social}</p>
                            <p><b>Descrição da empresa:</b> {props.empresa.tipo_negocio}</p>
                            <p><b>Natureza Juridica:</b> {props.empresa.natureza_juridica}</p>
                            <p><b>Patrimonio:</b> {props.empresa.valor_patrimonio}</p>
                        </>
                    : <></>}

                </>
            :
            null
            }
        </div>
    )
}

export default DataClientVenda;