
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { Card, CardBody, Row, Col, Input, CardFooter, Collapse, Button, Label } from 'reactstrap';
import { columns } from './propsVendedoresEtiquetas';
import debounce from 'lodash/debounce';
import Pagination from '../../Pagination';
import { InputText } from '../../../InputText/InputText';
import InputMask from 'react-input-mask';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import Select from 'react-select';
import { ENDPOINT } from '../../../../auth/endpoint';
import { getToken } from '../../../../auth/set_services';
import { FaSearch } from 'react-icons/fa';



export const TableVendedoresEtiquetas = (props) => {
    const [loadingView, setLoadingView] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);

    

    return (
        <Card style={{margin: '0 1rem'}}>
            <CardBody>
                <Row style={{marginBottom: '1.2rem'}}>
                    <Col md={6}>
                        <InputText
                            name="search"
                            placeholder="Código"
                            onChange={debounce((event) => props.isSearch(event), 1000)} />
                    </Col>
                    <Col md={6}>
                        <Select
                            placeholder="Status"
                            options={[
                                {label: 'Pagamentos Realizados', value: 'payments'},
                                {label: 'Lançamento', value: 'releases'},
                                {label: 'Créditos Extra', value: 'credit_extra'},
                            ]}
                            value={props.state.filter_action_select}
                            isClearable
                            onChange={(value) => props.onChangeSelect('filter_action_select', value)}
                        />
                    </Col>
                </Row>
            </CardBody>
            <hr style={{ marginBottom: '4px' }} />
            <DataTable
                noHeader={true}
                button={true}
                noDataComponent={props.noDataComponent}
                columns={columns(props, props.handleOpenLabelPDF, props.handleDownloadLabelPDF)}
                data={props.data}
                theme="default"
            />
            <CardFooter style={{ textAlign: 'center' }}>
                <Pagination
                    onPageChange={props.onPageChange}
                    activePage={props.activePage}
                    totalPage={props.totalPage}
                />
            </CardFooter>
        </Card>
    )
}