//import "leaflet/dist/leaflet.css";
import React, { Component } from 'react';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, TabPane } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { TableChat } from '../../components/Tables/TableChat/TableChat';
import Moment from 'moment';
import { getToken } from '../../auth/set_services';
import { ENDPOINT } from '../../auth/endpoint';
import axios from 'axios';
import { MdChat } from 'react-icons/md';
import { toast } from 'react-toastify';
import styled from "styled-components";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
let DefaultIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: icon,
    iconRetinaUrl: iconRetina,
    shadowUrl: iconShadow
});
L.Marker.prototype.options.icon = DefaultIcon;
//import 'https://unpkg.com/leaflet@1.5.1/dist/leaflet.css';

const AreaMensagem = styled.div`
    .leaflet-container {
        width: 100%;
        height: 250px !important;
    }
    .dropdown-menu.show {
        display: block;
        font-size: 16px;
        border-radius: 0;
        background-color: #ffffff;
        width: 90%;
    }
    .dropdown-toggle{
        padding: 5px;
    }
`;

const getOperadores = (callback) => {
    var url = ENDPOINT + "chat-atendimento/consultar-operadores";
    
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        var operadores_list = [];
        data.forEach((p, i) => {
            p['value'] = p.id;
            p['label'] = p.name;
            operadores_list.push(p);
        });

        callback(operadores_list)
    });
}

const getRows = (communication_relacao_id, state, callbackRows) => {
    if(typeof communication_relacao_id === 'undefined'){
        return null;
    }
    else if(communication_relacao_id === -1){
        return null;
    }

    var url = ENDPOINT + "chat-atendimento/consulta-chats?communication_relacao_id="+communication_relacao_id;

    if (state.protocolo_atendimento.length > 0) {
        url += '&protocolo_atendimento=' + state.protocolo_atendimento
    }
    
    if (state.operador !== null) {
        url += '&operador=' + state.operador.id
    }

    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}
  
const getRowsPerPage = (communication_relacao_id, page, search, filter, state, callbackRows) => {
    if(typeof communication_relacao_id === 'undefined'){
        return null;
    }
    else if(communication_relacao_id === -1){
        return null;
    }

    var url = ENDPOINT + "chat-atendimento/consulta-chats?communication_relacao_id="+communication_relacao_id+"&search=" + search + "&page=" + page;
  
    if (state.protocolo_atendimento.length > 0) {
        url += '&protocolo_atendimento=' + state.protocolo_atendimento
    }

    if (state.operador !== null) {
        url += '&operador=' + state.operador.id
    }

    if (filter === true) {
      url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }
  
    axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + getToken()
      }
    }).then((response) => {
      callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

const send_messages_mail = (cod_chat, onToast) => {
    var url = ENDPOINT + "chat-atendimento/enviar-historico/"+cod_chat;
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        onToast(data)
    })
    .catch((error) => {
        var response = '';
        var obj; 
        if (error.message === 'Network Error') {
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onToast(obj);
            return obj;
        }
        var data = error.response.data;
        if(data){
            onToast(data);
            return data;
        }
        else{
             response = '{"success": "false", "errors": "Ocorreu um erro desconhecido" }';
             obj = JSON.parse(response);
            onToast(obj);
            return obj;
        }
    });
}

class ChatsClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cod_chat: null,
            rowData: [],
            activePage: 1,
            loading_table: true,
            //email: getId(),
            protocolo_atendimento: '',
            operadores_list: [],
            operador: null,
            totalPage: 1,
            filter: false,
            mensagens_modal: false,
            mensagens_current: [],
            search: '',
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
        this.onMessage = this.onMessage.bind(this);
    }
    getMap = (latitude, longitude) => {
        //var position = [latitude, longitude];
        
        //var position = { lat: 51.505, lng: -0.09 };

        return <MapContainer 
                center={{ lat: latitude, lng: longitude }}
                zoom={13}
                //center={position}
                >
                <TileLayer
                    attribution='&copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
                />
                <Marker position={{ lat: latitude, lng: longitude }}>
                <Popup>
                    
                </Popup>
                </Marker>
            </MapContainer>
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(this.props.communication_relacao_id, 1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }
    checarLocalizacao(lat, lon){
        if(lat == null || lon == null){
            return false;
        }
        return true;
    }
    componentDidMount() {
        getOperadores(this.callbackOperadores.bind(this));
        getRows(this.props.communication_relacao_id, this.state, this.callbackRows.bind(this));
    }
    componentWillUpdate(nextProps, nextState) {
        if (nextProps.communication_relacao_id !== this.props.communication_relacao_id) {
            getRows(nextProps.communication_relacao_id, this.state, this.callbackRows.bind(this))
        }
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }
    callbackOperadores = (operadores_list) => {
        this.setState({operadores_list});
    }

    onFilter = (filter) => {
        this.setState({ filter });
        getRowsPerPage(this.props.communication_relacao_id, 1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(this.props.communication_relacao_id, activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onMessage = (mensagens_current, cod_chat, mensagens_modal) => {
        this.setState({ 
            mensagens_current, 
            cod_chat,  
            mensagens_modal 
        });
    }
    onToast = (data) => {
        if(data.success === true){
            toast.success(data.message);
        }
        else{
            toast.error(data.errors);
        }
        //this.props.refresh();
    }

    handleSelectChange = operador => {
        var state = this.state;
        state['operador'] = operador;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(this.props.communication_relacao_id, 1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    };
    render() {
        return (
            <TabPane tabId={this.props.active}>
                <br />
                <h5>&nbsp;&nbsp;<MdChat style={{width: '30px', height: '30px'}}/> Chats de {this.props.name}</h5>
                <Card>
                    <TableChat
                        clientChat
                        isSearch={this.handleChange}
                        operadores_list={this.state.operadores_list}
                        data={this.state.rowData}
                        operador={this.state.operador}
                        handleSelectChange={this.handleSelectChange.bind(this)}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        onMessage={this.onMessage.bind(this)}
                        noDataComponent={this.state.loading_table === true ?
                            <Card style={{ border: 0, padding: '10vh' }}><CardBody>
                                <div><div className="sweet-loading">
                                    <PropagateLoader color={'#52c565'} loading={20} size={30} />
                                </div></div></CardBody></Card> :
                            <Card style={{ border: 0 }}><CardBody><div>
                                Nenhum chat encontrado
                            </div></CardBody></Card>
                        }
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />

                    <Modal isOpen={this.state.mensagens_modal} size="lg">
                        <ModalHeader>
                            Mensagens
                        </ModalHeader>
                        <ModalBody>
                            <AreaMensagem style={{overflow: 'auto', maxHeight: '60vh'}}>
                                {this.state.mensagens_current.map((p, i) => {
                                    if(p.type_message === 1){
                                        if(p.anexo !== null){
                                            return(
                                                <>
                                                    <p style={{color: '#2e7b51'}}><b>{p.usuario !== null ? p.usuario.nome+' '+p.usuario.sobrenome : 'Usuário'} {p.usuario.sobrenome}:</b> {p.message} 
                                                    <DropdownButton 
                                                        title="Ver Localização" 
                                                        variant="dark" hidden={!this.checarLocalizacao(p.latitude, p.longitude)}>
                                                            <Dropdown.ItemText>
                                                                {this.checarLocalizacao(p.latitude, p.longitude) === true ? 
                                                                    this.getMap(p.latitude, p.longitude)
                                                                : <></>}
                                                            </Dropdown.ItemText>
                                                        </DropdownButton >
                                                    </p>
                                                </>
                                            )
                                        }
                                        else{
                                            return(
                                                <>
                                                    <p style={{color: '#2e7b51'}}><b>{p.usuario !== null ? p.usuario.nome+' '+p.usuario.sobrenome : 'Usuário'}:</b> {p.message} 
                                                        <DropdownButton title="Ver Localização" variant="dark" hidden={!this.checarLocalizacao(p.latitude, p.longitude)}>
                                                        <Dropdown.ItemText>
                                                                {this.checarLocalizacao(p.latitude, p.longitude) === true ? 
                                                                    this.getMap(p.latitude, p.longitude)
                                                                : <></>}
                                                            </Dropdown.ItemText>

                                                        </DropdownButton >
                                                    </p>
                                                </>
                                            )
                                        }
                                    }
                                    else{
                                        if(p.anexo !== null){
                                            return(
                                                <>
                                                    <p style={{color: '#004c9e'}}><b>{p.admin !== null ? p.admin.name : 'Atendimento'}:</b> <a href={p.anexo} rel="noreferrer" target="_blank">{p.message}</a> 
                                                        <DropdownButton title="Ver Localização" variant="dark" hidden={!this.checarLocalizacao(p.latitude, p.longitude)}>
                                                        <Dropdown.ItemText>
                                                                {this.checarLocalizacao(p.latitude, p.longitude) === true ? 
                                                                    this.getMap(p.latitude, p.longitude)
                                                                : <></>}
                                                            </Dropdown.ItemText>

                                                        </DropdownButton >
                                                    </p>
                                                </>
                                            )
                                        }
                                        else{
                                            return(
                                                <>
                                                    <p style={{color: '#004c9e'}}><b>{p.admin !== null ? p.admin.name : 'Atendimento'}:</b> {p.message} 
                                                        <DropdownButton title="Ver Localização" variant="dark" hidden={!this.checarLocalizacao(p.latitude, p.longitude)}>
                                                            <Dropdown.ItemText>
                                                                {this.checarLocalizacao(p.latitude, p.longitude) === true ? 
                                                                    this.getMap(p.latitude, p.longitude)
                                                                : <></>}
                                                            </Dropdown.ItemText>

                                                        </DropdownButton >
                                                    </p>
                                                </>
                                            )
                                        }
                                    }
                                })}
                            </AreaMensagem>
                        </ModalBody>
                        <ModalFooter>
                        
                            <Button color="success" onClick={() => send_messages_mail(this.state.cod_chat, this.onToast.bind(this))}>Enviar chat por e-mail</Button>
                            <Button color="danger" onClick={() => this.onMessage([], null, false)}>Fechar</Button>
                        </ModalFooter>
                    </Modal>
                </Card>
            </TabPane>
        )
    }
}

export default ChatsClient;