import React, { Component } from 'react';
import { Button, Card, CardBody, TabPane } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Moment from 'moment';
import { getToken } from '../../auth/set_services';
import { ENDPOINT } from '../../auth/endpoint';
import axios from 'axios';
import { MdNote } from 'react-icons/md';
import { toast } from 'react-toastify';
import MapsMeubus from '../../components/Modals/Meubus/MapsMeubus';
import { TableHistoricoAtividadesMeuBus } from '../../components/Tables/TableQueroBus/TableHistoricoAtividadesMeuBus';


const getOperadores = (callback) => {
    var url = ENDPOINT + "chat-atendimento/consultar-operadores";
    
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        var operadores_list = [];
        data.forEach((p, i) => {
            p['value'] = p.id;
            p['label'] = p.name;
            operadores_list.push(p);
        });

        callback(operadores_list)
    });
}

const getRows = (user_id, state, callbackRows) => {
    if(typeof user_id === 'undefined'){
        return null;
    }
    else if(user_id === -1){
        return null;
    }
    else if(user_id === null){
        return null;
    }

    var url = `${ENDPOINT}aqpasso_edit/user_activities/${user_id}`;
    
    
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        //console.log('user_activities', response)
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

const getRowsPerPage = (user_id, page, search, filter, state, callbackRows) => {
    if(typeof user_id === 'undefined'){
        return null;
    }
    else if(user_id === -1){
        return null;
    }

    var url = `${ENDPOINT}aqpasso_edit/user_activities/${user_id}?search=search&page=page`;



    axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + getToken()
      }
    }).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

class HistoricoAlteracoesAQPasso extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cod_chat: null,
            rowData: [],
            activePage: 1,
            loading_table: true,
            //email: getId(),
            protocolo_atendimento: '',
            operadores_list: [],
            operador: null,
            totalPage: 1,
            filter: false,
            open_map: false,
            map_row: [],
            mensagens_modal: false,
            mensagens_current: [],
            search: '',
            visible_notas: false,
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
        this.onMessage = this.onMessage.bind(this);
    }
    
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(this.props.user_id, 1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentDidMount() {
        getOperadores(this.callbackOperadores.bind(this));
        getRows(this.props.user_id, this.state, this.callbackRows.bind(this));
    }
    componentWillUpdate(nextProps, nextState) {
        if (nextProps.user_id !== this.props.user_id) {
            getRows(nextProps.user_id, this.state, this.callbackRows.bind(this))
        }
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }
    callbackOperadores = (operadores_list) => {
        this.setState({operadores_list});
    }

    onFilter = (filter) => {
        this.setState({ filter });
        getRowsPerPage(this.props.user_id, 1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(this.props.user_id, activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onMessage = (mensagens_current, cod_chat, mensagens_modal) => {
        this.setState({ mensagens_current, cod_chat, mensagens_modal });
    }
    onToast = (data) => {
        if(data.success === true){
            toast.success(data.message);
        }
        else{
            toast.error(data.errors);
        }
        //this.props.refresh();
    }

    handleSelectChange = operador => {
        var state = this.state;
        state['operador'] = operador;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(this.props.user_id, 1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    };
    onCloseDocument = (visible_notas = false) => {
        this.setState({
            visible_notas
        })

        if(visible_notas == false){
            getRowsPerPage(this.props.user_id, 1, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
        }
    }
    openMaps = (row) => {
        this.setState({
            open_map: true,
            map_row: row,
        })
    }
    onClose = () => {
        this.setState({
            open_map: false,
            map_row: [],
        })
    }
    render() {
        return (
            <TabPane tabId={this.props.active}>
                <br />
                <h5>&nbsp;&nbsp;<MdNote style={{width: '30px', height: '30px'}}/> Histórico de Alterações</h5>
                <br/>
                <br/>
                <Card>
                    <TableHistoricoAtividadesMeuBus
                        clientChat
                        isSearch={this.handleChange}
                        operadores_list={this.state.operadores_list}
                        data={this.state.rowData}
                        operador={this.state.operador}
                        handleSelectChange={this.handleSelectChange.bind(this)}
                        openMaps={this.openMaps.bind(this)}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        onMessage={this.onMessage.bind(this)}
                        noDataComponent={this.state.loading_table === true ?
                            <Card style={{ border: 0, padding: '10vh' }}><CardBody>
                                <div><div className="sweet-loading">
                                    <PropagateLoader color={'#52c565'} loading={20} size={30} />
                                </div></div></CardBody></Card> :
                            <Card style={{ border: 0 }}><CardBody><div>
                                Nenhuma nota encontrado
                            </div></CardBody></Card>
                        }
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>

                <MapsMeubus 
                    visible={this.state.open_map} 
                    row={this.state.map_row}
                    onClose={this.onClose}
                    />
            </TabPane>
        )
    }
}

export default HistoricoAlteracoesAQPasso;