import React, { Component } from 'react';
import {  Modal, ModalBody, Button, ModalFooter, Container } from 'reactstrap';
// import Sidebar from '../components/Sidebar/sidebar';
import Sidebar from '../components/Sidebar/newSidebar';
import Navbar from '../components/Navbar/navbar';
import styled from "styled-components";
import { ToastContainer } from 'react-toastify';
import { ENDPOINT } from '../auth/endpoint';
import axios from "axios";
import { getToken } from '../auth/set_services';

const Content = styled.div`
    max-width: 98%;
    padding-left:  ${props => (props.closed === true ? '0' : '20%' )};
    margin-top: 82px;
    left: 0;
    width: 96vw;
    margin-left:  ${props => (props.closed === true ? '15px' : '' )};
    position: absolute;
    transition: all 0.1s;

    @media screen and (max-width: 1313px) {
        padding-left:  ${props => (props.closed === true ? '0' : '23%' )};
    }
    @media screen and (max-width: 1129px) {
        padding-left:  ${props => (props.closed === true ? '0' : '25%' )};
    }
    @media screen and (max-width: 1070px) {
        padding-left:  ${props => (props.closed === true ? '0' : '28%' )};
    }
`;
class Pages extends Component {
    constructor(props){
        super(props);
        this.state = {
            closed: document.body.clientWidth > 768 ? false : true,
            closed_modal: false,
            img_campanha: ''
        }
        this.onClosed = this.onClosed.bind(this);
    }
    onClosed(){
        this.setState({closed: !this.state.closed })
    }
    componentDidMount(){
        console.log();
        this.getCampanha()
    }

    getCampanha = () => {
        var url = ENDPOINT + 'admin/last_notification';

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({
                img_campanha: data.image_url,
                closed_modal: true
            })
        })
        .catch((error) => {

        })
    }
    render(){
        const { children } = this.props;
        return(
            <Container>
                <Navbar onClosed={this.onClosed} closed={this.state.closed}/>
                <Sidebar onClosed={this.onClosed} closed={this.state.closed}/>
                <Content className="box-content" closed={this.state.closed}>
                    {children}
                </Content>
                <Modal isOpen={this.state.closed_modal} size="lg">
                    <ModalBody>
                        <img width={"100%"} src={this.state.img_campanha} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => this.setState({closed_modal: false})}>Fechar</Button>
                    </ModalFooter>
                </Modal>
                <ToastContainer hideProgressBar/>
            </Container>
            
        );
    }
}
export default Pages;