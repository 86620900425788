import React, {Component} from 'react';
import { Form, FormGroup, Card, CardBody, FormFeedback } from 'reactstrap';
import { alterPassword } from '../../auth/services';
import BoxSuccess from '../../components/BoxSuccess/BoxSuccess';
import BoxError from '../../components/BoxError/BoxError';
import PropagateLoader from "react-spinners/PropagateLoader";
import { InputText } from '../../components/InputText/InputText';
import { ButtonDefault } from '../../components/Button/ButtonDefault';

class Login extends Component{
    constructor(props){
        super(props);
        this.state = {
            token: new URLSearchParams(this.props.location.search).get("token"),
            new_password: '',
            new_password_invalid: false,
            repeat_password: '',
            repeat_password_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        }
        this.onRegister = this.onRegister.bind(this);
    }
    componentDidMount(){
        console.log(new URLSearchParams(this.props.location.search).get("token"))
    }
    getPathUrl = () =>{
        return window.location.pathname;
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }
    onRegister() {
        if(this.validateFields() === 0){
            this.setState({loading: true});
            alterPassword(this.state, this.onData.bind(this));
        }
    }
    onData(data){
        if(data.success === true){
            this.setState({
                loading: false,
                success_message: data.message
            })
        }
        else{
            this.setState({
                loading: false,
                error_message: data.errors
            })
        }
    }
    validateFields(){
        var invalid = 0;

        //Validar senha
        if(this.state.new_password.length > 6){
            this.setState({new_password_invalid: false})
        }
        else{
            invalid++;
            this.setState({new_password_invalid: true})
        }

        if(this.state.repeat_password.length > 6){
            this.setState({repeat_password_invalid: false})
        }
        else{
            invalid++;
            this.setState({repeat_password_invalid: true})
        }

        if(this.state.new_password !== this.state.repeat_password){
            invalid++;
            this.setState({repeat_password_invalid: true})
        }
        else{
            this.setState({repeat_password_invalid: false})
        }

        return invalid;

    }
    closedError = () => {
        this.setState({
            loading: false,
            error_message: ''
        })
    }
    onEnter = (evt) => {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        if(key === 13){
            this.onRegister();
        }
     }
    render(){
        return(
            <>
                <Form hidden={this.state.loading}>
                    <h4 style={{margin: '25px', textAlign: 'right'}}>Alterar Senha</h4>
                    <FormGroup>
                        <InputText
                                name="new_password"
                                type="password"
                                placeholder="Nova Senha"
                                invalid={this.state.new_password_invalid}
                                value={this.state.new_password}
                                onChange={this.handleChange}
                                onKeyPress={this.onEnter}/>
                        <FormFeedback>A Senha deve ter no minimo 6 caracteres</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <InputText 
                            placeholder="Repetir Senha"
                            name="repeat_password"
                            type="password"
                            invalid={this.state.repeat_password_invalid}
                            value={this.state.repeat_password}
                            onChange={this.handleChange}
                            onKeyPress={this.onEnter}/>
                        <FormFeedback>Senhas divergentes</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <center>
                            <ButtonDefault onClick={this.onRegister} color="success" style={{width: '95%'}}>
                                Alterar Senha
                            </ButtonDefault>
                        </center>
                    </FormGroup>
                </Form>
                <Card hidden={!this.state.loading} style={{border: 'none', padding: '10%'}}>
                    <CardBody>
                        <center>
                            <div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div>
                        </center>
                    </CardBody>
                </Card>
                <BoxError
                    visible={this.state.error_message.length > 0 ? true : false}
                    title="Ocorreu um erro"
                    message={this.state.error_message}
                    onChange={this.closedError.bind(this)}
                />
                <BoxSuccess
                    visible={this.state.success_message.length > 0 ? true : false}
                    title="Alteração realizada com sucesso"
                    message="Sua senha foi alterada com successo"
                    onChange={() => { window.location.href = "login" }}
                />
            </>
        );
    }
}

export default Login;
