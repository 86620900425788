import React, {Component} from 'react';
import { FaUser } from 'react-icons/fa';

import { Col, Row, CardBody, Container } from 'reactstrap';
import styled from 'styled-components';
import logo from '../assets/aqbank.png';
import { BoxText } from '../components/BoxText/BoxText';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import logoFooter from '../assets/footer-ps.webp';

const Background = styled.div`
    background-attachment: fixed;
    background-size: cover;
    height: 100%;
    content: "";
    background-image: url(${logoFooter});
    opacity: 0.5; 
`
const ImageBackground = styled.img`
    position: absolute;
    width: 161.027px;
    height: 53.619px;
    left: 6%;
    top: 6%;
`;
const CardAuth = styled.div`
    min-width: 474px;
    border-radius: 20px;
    background-color: white;
    padding: 5px;
    box-shadow: rgb(174 174 174) 1px 1px 7px;
    
    @media (max-width: 996px) {
        min-width:  380px;
    }

    @media (max-width: 768px) {
        min-width:  474px;
    }

    @media (max-width: 475px) {
        min-width: 100%;
    }
`;
const ColBackground = styled.div`
    padding: 0;
    background-color: #8cbd93;

    @media (max-width: 768px) {
        display: none;
    }
`;
const ColAuth = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
    @media (max-width: 768px) {
        background-attachment: fixed;
        background-size: cover;
        background-image: url(${logoFooter});
    }
`;
class Login extends Component{
    constructor(props){
        super(props);
        this.state = {
            cpf: '',
            cpf_invalid: false,
            cnpj: '',
            cnpj_invalid: false,
            password: '',
            password_invalid: false
        }
    }
    getPathUrl = () =>{
        return window.location.pathname;
    }
    render(){
        const { children } = this.props;
        return(
        <Container style={{margin: 0, height: '100%', maxWidth: '100%'}}>
                <Row style={{ height: '100vh' }}>
                    <ColBackground className="col-md-6">
                        <ImageBackground src={logo}/>
                        <Background id="col-login-background">
                            <ImageBackground src={logo}/>
                        </Background>
                    </ColBackground>
                    
                    <LazyLoadComponent visibleByDefault={true}>
                        <ColAuth className="col-md-6 col-sm-12">
                            <CardAuth >
                                <CardBody style={{verticalAlign: 'middle'}}>
                                    <center>
                                        <Row>
                                            <Col>
                                            </Col>
                                            <Col md={8}>
                                                <BoxText icon={FaUser} text="Painel Administrativo"/>
                                            </Col>
                                        </Row>
                                    </center>
                                    {children}
                                </CardBody>
                            </CardAuth>
                        </ColAuth>
                    </LazyLoadComponent>
                </Row>
            </Container>
        );
    }
}

export default Login;
