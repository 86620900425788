import React, {Component} from 'react';
import { Button, Card, CardBody } from "reactstrap";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getRows, getRowsPerPage } from '../../components/Tables/TableUsers/propsUsers';
import PropagateLoader from "react-spinners/PropagateLoader";
import { getPermissoes, getToken } from '../../auth/set_services';
import TableUsers from '../../components/Tables/TableUsers/TableUsers';
import ModalDialog from '../../components/Modals/ReturnsApiModals/ModalDialog';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ENDPOINT } from '../../auth/endpoint';
import AQPassoModal from '../../components/Modals/UserModal/AQPassoModal';


class Users extends Component{
    constructor(props){
        super(props);
        this.state = {
            show_activity: false,
            show_filter: false,
            user_change: null,
            search: '',
            loading_table: true,
            edit_id: -1,
            edit_user: null,
            rowData: [],
            cpf_current: null,
            cnpj_current: null,
            phone_current: null,
            status_user: '',
            type_user: '',
            totalPage: 0,
            activePage: 1,
            is_cnpj: false,
            hours:false,
            document: '',
            visible_taggy_add: false,
            visible_user: false,

            loading: false,
            error_message: '',
            success_message: '',
            
            delete_id: -1,
            visible_delete: false,
        }
        this.onPageChange = this.onPageChange.bind(this);
        this.callbackRows = this.callbackRows.bind(this);
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    on24Hours(){
        var state = this.state;
        state['hours'] =  !this.state.hours;
        this.setState({...state});
        
        getRows(state, this.callbackRows.bind(this));
    }
    filterShow(){
        this.setState({
            show_filter: !this.state.show_filter
        })
    }
    handleChange = (event) => {
        var state = this.state;
        let name = event.target.name;
        let value = event.target.value;

        state[name] = value;
        state.activePage = 1;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});
        
        getRowsPerPage(1, state, this.callbackRows);
    }


    onCloseAQ = () => {
        this.setState({
            visible_user: false
        });

        getRows(this.state, this.callbackRows.bind(this));
    }

    componentDidMount(){
        getRows(this.state, this.callbackRows.bind(this));
    }
    
    
    callbackRows(rowData, totalPage){
        this.setState({rowData, totalPage, loading_table: false})
    }
    onStatusChange(status_user){
        var state = this.state;
        state.status_user = status_user
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state})
        getRows(state, this.callbackRows);
    }

    onPageChange = (activePage) => {
        var state = this.state;

        state.activePage = activePage;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});

        getRowsPerPage(activePage, state, this.callbackRows.bind(this));
    }


    onCloseDelete = () => {
        this.setState({visible_delete: false, delete_id: -1});
        getRows(this.state, this.callbackRows.bind(this));
    }


    onData(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            });
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }

    onShowDelete(delete_id, visible_delete = true){
        this.setState({
            delete_id,
            visible_delete
        })
    }

    onError(){
        this.setState({
            loading: false,
            error_message: ''
        })
    }

    onDelete = () => {
        this.setState({loading: true})
        var url = ENDPOINT+"user/delete_aqpasso/"+this.state.delete_id;
        axios.delete(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.onCloseDelete();
            return data;
        })
        .catch((error) => {
            var response = '';
            var obj;
            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            if(data.exception){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            this.onData(data);
            return data;
        });
    }

    render(){
        return(
            <div>
                <h3>Clientes</h3>
                <hr/>
                <div>
                    <Button color="success" onClick={() => this.setState({visible_user: true})}>Novo Cliente</Button>
                    <br/>
                </div>
                <hr/>
                <Card>
                    <AQPassoModal
                        visible={this.state.visible_user}
                        onClose={this.onCloseAQ}
                    />
                    <TableUsers
                        isSearch={this.handleChange}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        on24Hours={this.on24Hours.bind(this)}
                        data={this.state.rowData}
                        hours={this.state.hours}
                        is_cnpj={this.state.is_cnpj}
                        status_user={this.state.status_user}
                        onStatusChange={this.onStatusChange.bind(this)}
                        type_user={this.state.type_user}
                        show_filter={this.state.show_filter}
                        filterShow={this.filterShow.bind(this)}
                        onPageChange={this.onPageChange}
                        onShowDelete={this.onShowDelete.bind(this)}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhum usuário encontrado
                            </div></CardBody></Card>
                        }
                    />
                </Card>
                <ModalDialog
                    visible={this.state.visible_delete}
                    onError={this.onError.bind(this)}
                    message="Deseja remover esse cliente?"
                    onClose={() => this.onShowDelete(-1, false)}
                    error_message={this.state.error_message}
                    loading={this.state.loading}
                    onSave={this.onDelete.bind(this)}
                    success_message={this.state.success_message}
                />
            </div>
        )
    }
}

export default Users;