import React, { Component, useState } from 'react';
import { Button, Card, CardBody, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, TabPane } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Moment from 'moment';
import { getPermissoes, getToken } from '../../auth/set_services';
import { TableMovimentacaoFatura } from '../../components/Tables/TableMovimentacao/TableMovimentacaoFatura';
import NewNotas from '../../components/Modals/SendsModals/NewNotas';
import { ENDPOINT } from '../../auth/endpoint';
import axios from 'axios';
import { MdNote } from 'react-icons/md';
import Select from 'react-select';
import { toast } from 'react-toastify';

const getId = () => {
    if (window.location.pathname === '/movimentacao') {
        return null;
    }
    else {
        return window.location.pathname.replace('/movimentacao/', '')
    }
}


 const getRowsPerPage = (document, page, search, filter, state, callbackRows) => {
    if(typeof document === 'undefined'){
        return null;
    }
    else if(document === -1){
        return null;
    }
    
    var url = ENDPOINT + "faturas_aqpasso/list?search="+search+"&page="+page;

    if(document.replace(/([^\d])+/gim, '').length > 0){
      if(document.replace(/([^\d])+/gim, '').length === 11){
          url +='&cpf='+document.replace(/([^\d])+/gim, '');
      }
      else if(document.replace(/([^\d])+/gim, '').length === 14){
          url +='&cnpj='+document.replace(/([^\d])+/gim, '');
      }
    }

    if(filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}
 const getRows = (document, state, callbackRows) => {
    if(typeof document === 'undefined'){
        return null;
    }
    else if(document === -1){
        return null;
    }

    var url = ENDPOINT + "faturas_aqpasso/list?search=";


    if(document.replace(/([^\d])+/gim, '').length > 0){
        if(document.replace(/([^\d])+/gim, '').length === 11){
            url +='&cpf='+document.replace(/([^\d])+/gim, '');
        }
        else if(document.replace(/([^\d])+/gim, '').length === 14){
            url +='&cnpj='+document.replace(/([^\d])+/gim, '');
        }
      }

      if(state.filter === true){
          url += '&date_from='+state.date_from+'&date_to='+state.date_to
      }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

const ModalVencimentoFatura = (props) => {
    const [vencimento_fatura, setVencimentoFatura] = useState(null);
    const [protocolo_atendimento, setProtocolo] = useState('');
    const [loading, setLoading] = useState(false);
    
    const list_vencimento = [
        {
            label: 'Vencimento dia 05 / Fechamento dia 01',
            value: '05'
        },
        {
            label: 'Vencimento dia 10 / Fechamento dia 01',
            value: '10'
        },
        {
            label: 'Vencimento dia 15 / Fechamento dia 04',
            value: '15'
        },
        {
            label: 'Vencimento dia 20 / Fechamento dia 09',
            value: '20'
        }
    ];

    const onClose = () => {
        setVencimentoFatura(null);
        setLoading(false);
        setProtocolo('');
        props.onClose();
    }

    const onDataToast = (data) => {
        if (data.success === true) {
            toast.success(data.message);
        }
        else {
            toast.warning(data.errors);
        }
    }

    const setVencimento = () => {
        var url = ENDPOINT + "faturas_aqpasso/change_vencimento";
        var formData = new FormData();
        formData.append('document_client', props.document);
        formData.append('vencimento', vencimento_fatura.value);
        formData.append('protocolo_atendimento', protocolo_atendimento);
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        }).then((response) => {
            var data = response.data;
            onDataToast(data);
            onClose();
            return data;
        })
        .catch((error) => {
            setLoading(false);
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                onDataToast(obj);
                return obj;
            }
            var data = error.response.data;
            onDataToast(data);
            setLoading(false);
            return data;
        });
    }

    return (
        <Modal isOpen={props.visible}>
            <ModalHeader>Novo vencimento de Fatura</ModalHeader>
            <ModalBody>
            <Form>
                <FormGroup>
                    <Select 
                        placeholder="Vencimento de Fatura"
                        options={list_vencimento}
                        isSearchable={false}
                        value={vencimento_fatura}
                        onChange={setVencimentoFatura}
                        noOptionsMessage={() => {   return 'Sem opções' }}
                    />
                </FormGroup>
                <FormGroup>
                    <Input
                        value={protocolo_atendimento}
                        onChange={(event) => setProtocolo(event.target.value)}
                        placeholder="Protocolo do Ticket" 
                    />
                </FormGroup>
            </Form>
            </ModalBody>
            <ModalFooter>
                <Button color='danger' onClick={() => onClose()}>Fechar</Button>
                <Button color='success' disabled={loading} hidden={vencimento_fatura == null} onClick={() => setVencimento()}>Salvar</Button>
            </ModalFooter>
        </Modal>
    )
}

class MovimentacaoFaturas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading_table: true,
            rowData: [],
            activePage: 1,
            taggy_serial: '',
            totalPage: 1,
            filter: false,
            is_cnpj: false,
            modal_show: false,
            document: '',
            search: '',
            id_current: '',
            visible_notas: false,
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }

    componentDidMount() {
        getRows(this.props.documento, this.state, this.callbackRows.bind(this));
    }
    componentWillUpdate(nextProps, nextState) {
        if (nextProps.documento !== this.props.documento) {
            getRows(nextProps.documento, this.state, this.callbackRows.bind(this))
        }
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    onFilter = (filter) => {
        this.setState({ filter, loading_table: true });
        getRowsPerPage(this.props.documento, 1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(this.props.documento, activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onExtorno = (visible_notas = false, id_current = '') => {
        this.setState({visible_notas , id_current});
        getRows(this.props.documento, this.state, this.callbackRows.bind(this));
    }
    render() {
        return (
            <TabPane tabId={this.props.active}>
            <br />
            <h5>&nbsp;&nbsp;<MdNote style={{width: '30px', height: '30px'}}/> Movimentacao de Faturas de {this.props.name}</h5>
            <br/>
                <Button color='primary' onClick={() => this.setState({ modal_show: true}) }>Mudar data da Fatura</Button>
                <hr />
                <Card>
                    <TableMovimentacaoFatura
                        isSearch={this.handleChange}
                        data={this.state.rowData}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        is_cnpj={this.state.is_cnpj}
                        filter={this.state.filter}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhuma movimentação encontrada
                            </div></CardBody></Card>
                        }
                        onExtorno={this.onExtorno}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <ModalVencimentoFatura
                    visible={this.state.modal_show}
                    document={this.props.documento}
                    onClose={() => this.setState({ modal_show: false})}
                />
                <NewNotas
                    visible={this.state.visible_notas}
                    id={this.state.id_current}
                    onClose={() => { this.onExtorno(false, '') }}
                    desc_id="fatura_aqpasso_id"
                    title_nota="Extorno de debito na fatura"
                    placeholder_nota="Digite o motivo do extorno no debito da fatura"
                    url_api="faturas_aqpasso/extorno"
                />
                </TabPane>
        )
    }
}

export default MovimentacaoFaturas;