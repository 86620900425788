import React, { Component } from 'react';
import { ButtonGroup, Card, CardBody, CardColumns, Col, Collapse, Input, Nav, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import styled from 'styled-components';
import DataClient from '../../components/ResumeClient/DataClient';
import ChatsClient from '../../components/ResumeClient/ChatsClient';
import EmailClient from '../../components/ResumeClient/EmailClient';
import TransacoesClient from '../../components/ResumeClient/TransacoesClient';
import TransferenciaClient from '../../components/ResumeClient/TransferenciaClient';
import SmsClient from '../../components/ResumeClient/SmsClient';
import { getPermissoes, getToken, getUser } from '../../auth/set_services';
import { ENDPOINT } from '../../auth/endpoint';
import NotasAtendimento from '../../components/ChatClient/NotasAtendimento';
import ProtocoloClient from '../../components/ResumeClient/ProtocoloClient';
import PrevencaoRiscoClient from '../../components/ResumeClient/PrevencaoRiscoClient';
import ProximosLancamentos from '../../components/ResumeClient/ProximosLancamentos';
import ContasBancariasClient from '../../components/ResumeClient/ContasBancariasClient';
import TicketClient from '../../components/ResumeClient/TicketClient';
import RespostaNPS from '../../components/ResumeClient/RespostaNPS';
import OcorrenciasClient from '../../components/ResumeClient/OcorrenciasClient';
import { maquininhas_list } from '../../auth/listas';
import axios from 'axios';
import { toast } from 'react-toastify';
import FadeLoader from "react-spinners/FadeLoader";
import error_image from '../../assets/error-image.png'
import EnderecoCadastro from '../../components/Modals/EnderecoCadastro/EnderecoCadastro';
import moment from 'moment';
import ReactStoreIndicator from 'react-score-indicator';
import NumberFormat from 'react-number-format';
import { Button } from 'react-bootstrap';
import { PropagateLoader } from 'react-spinners';
import ModalValidacaoToken from '../../components/Modals/ModalValidacaoToken/ModalValidacaoToken';
import get_extension from 'get-url-extension';
import { AiFillFilePdf, AiFillFileUnknown } from 'react-icons/ai';
import NewNotas from '../../components/Modals/SendsModals/NewNotas';
import UploadDocumentos from '../../components/Modals/SendsModals/UploadDocumentos';
import ModalShow from '../../components/Modals/ModalShow/ModalShow';
import { ModalComplience } from '../../components/Modals/ModalComplience/ModalComplience';


const get_status_auth = (payment_type) => {
    switch(payment_type){
        case 0 : return 'Aguardando Validação';
        case 1 : return 'Validado';
        case 2 : return 'Perdido';
        default: return ''
    }
}
const get_status = (payment_type) => {
    switch(payment_type){
        case 'enabled' : return 'Ativo';
        case 'inactive' : return 'Inativo';
        case 'pending' : return 'Pendente';
        case 'failed' : return 'Falhada';
        default: return payment_type
    }
}

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

const getId = () => {
    return window.location.pathname.replace('/user/', '')
}
/* const getActive = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('active');
    if(id !== null){
        return id;
    }
    return "1";

} */


const thumb = {
    //display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 305,
    height: 'auto',
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 200,
    overflow: 'hidden',
    placeContent: 'center'
};

const img = {
    display: 'block',
    width: '100%',
    maxHeight: '330px'
};
  
const pdf = {
    display: 'block',
    width: 'auto',
    height: '120px',
    color: '#af0000'
};
const file_style = {
    display: 'block',
    width: 'auto',
    height: '120px',
    color: '#484848'
};

const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
        padding: 0.5rem;
        border: 1px solid #ccc;
        margin: 0.2rem 0.3rem;
        border-radius: 5px;
    }
    .nav-tabs .nav-item.show .nav-link, 
    .nav-tabs .nav-link.active {
        background: #28a745;
        color: #fff;
    }
    .nav-link:hover{
        background: #70b580;
        color: #fff;
    }
    .nav-tabs {
        padding-bottom: 1rem;
    }
`

const getUserAQPago = (id, callback) => {
    var url = ENDPOINT + "user/get/"+id;
  
    var myHeaders = new Headers();
  
    myHeaders.append('Authorization', 'Bearer ' + getToken());
  
    var myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };
    fetch(url, myInit)
      .then(response => response.json())
      .then((p) => {
          callback(p.data);
      })
  }


const getSerasa = (documento, callback, callbackError) => {
    var url = ENDPOINT + "consultar_serasa/"+documento;
  
    var myHeaders = new Headers();
  
    myHeaders.append('Authorization', 'Bearer ' + getToken());
  
    var myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };
    fetch(url, myInit)
      .then(response => response.json())
      .then(() => {
        getUserAQPago(getId(), callback);
      })
      .catch(() => {
        callbackError();
      })
}

const getSerasaNew = (documento, callback, callbackError) => {
    var url = ENDPOINT + "nova_consulta_serasa/"+documento;
  
    var myHeaders = new Headers();
  
    myHeaders.append('Authorization', 'Bearer ' + getToken());
  
    var myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };
    fetch(url, myInit)
      .then(response => response.json())
      .then(() => {
        getUserAQPago(getId(), callback);
      })
      .catch(() => {
        callbackError();
      })
}
export default class DataUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            cpf: '',
            cnpj: '',
            status: '',
            created_at: '2021-01-01',
            first_chat_date: '',
            distract_nota: '',
            last_chat_date: '',
            first_origin: '',
            last_origin: '',
            telefone: '',
            userCode: '',
            atividade_principal: '',
            cep: '',
            cidade: '',
            estado: '',
            endereco: null,
            bairro: '',
            plan_seller: '',
            account_balance: '',
            current_balance: '',
            empresa: null,
            userId: getId(),
            maquininhas: [],
            alteracoes_usuario: [],
            transactions: [],
            documents: [],
            compras: [],
            transfers: [],
            prevention: [],
            bank_accounts: [],
            activeTab: "1",
            future_releases: [],
            communication_relacao_id: -1,
            serasa: [],
            autorizacoes: null,
            compliance: null,
            is_open_nota: false,
            compliance: null,
            
            termos: [],
            estabeleciomento: '',
            documento_seller: '',
            message_termos: '',
            loading_termo_tab: false,
            isVisible: false,

            email_edit_enable: false,
            email_edit: '',
            
            telefone_edit_enable: false,
            loading_user: false,
            telefone_edit: '',
            visible_cadastro: false,

            
            is_title: '',
            name: '',
            email: '',
            cpf: '',
            cnpj: '',
            created_at: '2021-01-01',
            first_chat_date: '',
            last_chat_date: '',
            first_origin: '',
            last_origin: '',
            telefone: '',
            userCode: '',
            cep: '',
            cidade: '',
            estado: '',
            endereco: null,
            bairro: '',
            empresa: null,
            plano: null,
            visible_cadastro: false,
            plano_current: null,
            plano_current_invalid: false,
            loading_edit: false,
            planos_list: [],
            userId: getId(),
            documents: [],
            compras: [],
            maquininhas: [],
            account: null,
            data_vendas: null,
            status_analise_docs: 'pending',
            status_crm: '',
            activeTab: "1",
            zoop_loading: false,
            zoop_cpf:'',
            distract_nota: '',
            zoop_cpf_invalid: false,
            account_value: false,
            loading_btn_init: false,
            visible_cadastro: false,
            responsavel_inicio_analise: null,
            responsavel_final_analise: null,
            inicio_analise: '',
            communication_relacao_id: -1,
            serasa: [],
            documento_id: -1,
            modal_upload: false,
            modal_document: false,
            plano_anterior: '',
            loading_user: false,

            document_file: '',
            modal_show_document: false,
            modal_account:false, 

            email_edit_enable: false,
            email_edit: '',
            
            telefone_edit_enable: false,
            telefone_edit: '',

            alteracoes_usuario: []
        }
        //this.showUsers = this.showUsers.bind(this);
        //this.onTaggyChange = this.onTaggyChange.bind(this);
        this.callbackRows = this.callbackRows.bind(this);
    }
    componentDidMount() {
        getUserAQPago(
            this.state.userId,
            this.callbackRows
        );
    }
    
    onCloseUpload = (modal_upload = false) => {
        this.setState({
            modal_upload
        });

        if(modal_upload === false){
            getUserAQPago(
                this.state.userId,
                this.callbackRows
            );
        }
    }
    callbackRows(data){
        
        this.setState({
            name: data.nome+" "+data.sobrenome,
            email: data.email,
            created_at: data.created_at,
            cpf: data.cpf,
            cnpj: data.cnpj,
            status: data.status,
            atividade_principal: data.atividade_principal,
            status_crm: data.status_crm,
            compras: data.compras,
            endereco: data.endereco,
            maquininhas: data.maquininhas,
            telefone: data.telefone,
            communication_relacao_id: data.communication_relacao_id,
            empresa: data.empresa,
            documents: data.documentos,
            compliance: data.compliance,
            alteracoes_usuario: data.alteracoes_usuario,
            serasa: data.serasa,
            loading_user: true,
        });

        if(data.autorizacoes){
            this.setState({
                autorizacoes: data.autorizacoes
            })
        }

        if(typeof data.request_aqbank.data !== 'undefined'){
            var future_releases = [];
            if(data.request_aqbank.data.future_releases !== null){
                Object.keys(data.request_aqbank.data.future_releases).forEach((p) => {
                    future_releases.push({
                        date: p,
                        ...data.request_aqbank.data.future_releases[p]
                    })
                });
            }


            this.setState({
                id_zoop: data.request_aqbank.data.id,
                transactions: data.request_aqbank.data.transactions,
                transfers: data.request_aqbank.data.transfers,
                prevention: data.request_aqbank.data.prevention,
                plan_seller: data.request_aqbank.data.plan_seller ? data.request_aqbank.data.plan_seller : data.plano,
                account_balance: data.request_aqbank.data.account_balance,
                current_balance: data.request_aqbank.data.current_balance,
                bank_accounts: data.request_aqbank.data.bank_accounts,
                created_at: data.request_aqbank.data.created_at,
                future_releases
            });
            
        }
        if(data.chats.length > 0){
            this.setState({
                first_chat_date: data.chats[0].created_at,
                first_origin: data.chats[0].dominio,
                last_chat_date: data.chats[data.chats.length - 1].created_at,
                last_origin: data.chats[data.chats.length - 1].dominio
            });
        }
    }
    listData = [
        { name: 'Dados do Cliente', active: '1' },
        { name: 'Histórico de Alterações', active: '2' },
        { name: 'E-mails', active: '3' },
        { name: 'SMS', active: '4' },
        { name: 'Chats', active: '5' },
        { name: 'Notas', active: '6' },
        { name: 'Protocolos', active: '7' },
        { name: 'Transações', active: '8' },
        { name: 'Transferências', active: '9' },
        { name: 'Prevenção e Risco', active: '10' },
        { name: 'Proximos Lançamentos', active: '11' },
        { name: 'Contas Bancárias', active: '12' },
        { name: "Ticket's", active: '13' },
        { name: "Respostas NPS", active: '14' },
        { name: "Ocorrências", active: '15' },
        { name: "Compras", active: '16' },
        { name: "Maquininhas", active: '17' },
        { name: "Bureau de Crédito", active: '18' },
        { name: "Aceite de termos", active: '19' },
        { name: "Validações de Cliente", active: '20' },
        { name: "Documentos", active: '21' },
        { name: "Time de Compliance", active: '22' },
    ]

    handleLoadDataTermos = () => {
        this.setState({loading_termo_tab: true})

        var url = ENDPOINT + "termo_aceite/detalhes/";
        url += this.state.cnpj !== undefined && this.state.cnpj != '' ? this.state.cnpj : this.state.cpf;
        var myHeaders = new Headers();

        myHeaders.append('Authorization', 'Bearer ' + getToken());
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            // console.log(p)
            if (p.success !== undefined && p.success == true) {
                let response = p.data.data;
                let estabelecimento = '';

                if (response.razao_social !== undefined) {
                    estabelecimento = response.razao_social;
                } else {
                    estabelecimento = response.nome + ' ' + response.sobrenome;
                }

                this.setState({termos: response.termos})

                this.setState({
                    estabeleciomento: estabelecimento,
                    documento_seller: response.documento,
                    loading_termo_tab: false
                })

                
            } else {
                this.setState({
                    loading_termo_tab: false,
                    message_termos: p.data.error,
                })
            }
        })
        .catch((error) => {
            this.setState({loading_termo_tab: false})
            console.log(error)
            toast.error('Ocorreu um erro no servidor!');
        })
    }
    Navs = () => {
        return <Nav tabs>
            {this.listData.map((p, index) => {
                return <NavLink
                    key={index}
                    className={
                        classnames({ active: this.state.activeTab === p.active })}

                    onClick={() => { 
                        if (p.active == 19) {
                            this.handleLoadDataTermos()
                            this.setState({ activeTab: p.active })
                        } else {
                            this.setState({ activeTab: p.active })
                        }
                    }}
                    
                    >
                    <span>{p.name}</span>
                </NavLink>
            })}
        </Nav>
    }
    editEmail = (email_edit_enable) => {
        this.setState({email_edit_enable, email_edit: ''})
    }

    telefoneEdit = (telefone_edit_enable) => {
        this.setState({telefone_edit_enable, telefone_edit: ''})
    }

    updateStatus = (status) => {
        var url = ENDPOINT+`user/update_user_status/${this.state.userId}`;

        var formData = new FormData();
        formData.append('status', status);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data.data;
            toast.success('Status editado com sucesso');
            
            getUserAQPago(
                this.state.userId,
                this.callbackRows
            );

            return data;
        })
        .catch((error) => {
            var data = error.response.data;
            toast.error(data.errors);
            return data;
        });
    }


    storeEmail = () => {
        this.setState({loading_edit: true})
        var url = ENDPOINT+`user/update_user_zoop/${this.state.cnpj.length === 0 ? this.state.cpf : this.state.cnpj}`;

        var formData = new FormData();
        formData.append('email', this.state.email_edit);
        formData.append('user_tipo', this.state.cnpj.length === 0 ? 'titular' : 'responsavel');

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data.data;
            this.setState({
                email_edit_enable: false,
                email_edit: '',
                email: data.email,
                loading_edit: false
            });

            toast.success('E-mail Editado com sucesso');
            
            getUserAQPago(
                this.state.userId,
                this.callbackRows
            );
        })
        .catch((error) => {
            var data = error.response.data;
            toast.error(data.errors);
            this.setState({
                loading_edit: false
            });
            return data;
        });
    }

    storeTelefone = () => {
        var url = ENDPOINT + "user/update_user_zoop/" + (this.state.cnpj.length === 0 ? this.state.cpf : this.state.cnpj);
        console.log(url);
        var formData = new FormData();
        formData.append('telefone', this.state.telefone_edit);
        formData.append('user_tipo', this.state.cnpj.length === 0 ? 'titular' : 'responsavel');

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({
                telefone_edit_enable: false,
                telefone_edit: '',
                telefone: data.telefone,
                loading_edit: false
            })

            
            getUserAQPago(
                this.state.userId,
                this.callbackRows
            );
        })
        .catch((error) => {
            var data = error.response.data;
            toast.error(data.errors);
            this.setState({
                loading_edit: false
            });
            return data;
        });
    }


    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        if(name == 'status_crm'){
            this.updateStatus(value);
        }
        this.setState({[name]: value});
    }

    
    onClose = () => {
        this.setState({visible_cadastro: false});
    }
    onOpen = () => {
        this.setState({visible_cadastro: true});
    }
    onRefresh = () => {
        getUserAQPago(
            this.state.userId,
            this.callbackRows
        );
    }

    changeVisible = (isVisible) => {
        this.setState({ isVisible });
        getUserAQPago(
            this.state.userId,
            this.callbackRows
        );
    }

    getFileExtension = (url) =>{
        var extension = get_extension(url);
    
        if(extension === '.png' ||
            extension === '.PNG' ||
            extension === '.jpg' ||
            extension === '.jpeg' ||
            extension === '.svg' ||
            extension === '.webp'){
            return 'image'
        }
        else if(extension === '.pdf' ||
            extension === '.PDF'){
            return 'pdf';
        }
    
        return 'file';
      }
      onCloseShowDocument = (modal_show_document, document_file) => {
          this.setState({ modal_show_document, document_file })
      }
      onCloseDocument = (documento_id = -1, modal_document = false) => {
          this.setState({
              documento_id,
              modal_document
          })
      }

      store_note = (compliance) => {
        this.setState({
            loading_compliance: true
        });
          var url = ENDPOINT+"complience/store_note";
  
          var formData = new FormData();
          formData.append('descricao', this.state.distract_nota);
          formData.append('id', compliance);
  
          axios.post(url, formData, {
              headers: {
                  Authorization: 'Bearer '+getToken()
              }})
          .then((response) => {
              var data = response.data;
              toast.success(data.message);
              this.setState({
                  distract_nota: '',
                  is_open_nota: false,
                  loading_compliance: false
              });
              
                getUserAQPago(
                    this.state.userId,
                    this.callbackRows
                );
              return data;
          })
          .catch((error) => {
            this.setState({
                loading_compliance: false
            });
              if(error.message === 'Network Error'){
                  var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                  var obj = JSON.parse(response);
                  toast.success(obj.errors);
                  return false;
              }
              var data = error.response.data;
              toast.success(data.errors);
              return false;
          });
      }
    thumbs = () => { 
        return this.state.documents.map((file, i) => (
            <>
            <div style={thumb} key={i}>
                <div style={thumbInner}>
                    {this.getFileExtension(file.url) === 'image' ?
                        <>
                        <a href="#" onClick={() => this.onCloseShowDocument(true, file.url)}>
                            <img
                                alt="logotipo-documento"
                                src={file.url+'?access_token='+getToken()}
                                style={img}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = error_image;
                                }}
                            />
                        </a>
                        </>
                        :
                        this.getFileExtension(file.url) === 'pdf' ?
                        <a href="#" onClick={() => this.onCloseShowDocument(true, file.url)}>
                            {/* <Button color="danger" style={{margin: '15px'}} onClick={() => window.open(file.url, '_blank')}>
                                <MdPictureAsPdf /> Abrir PDF
                            </Button> */}
                            <AiFillFilePdf style={pdf}/>
                        </a>
                        :
                        <a href={file.url+'?access_token='+getToken()}>
                            <AiFillFileUnknown style={file_style}/>
                            {/* <Button color="danger" style={{margin: '15px'}} onClick={() => window.open(file.url, '_blank')}>
                                <MdFileDownload style={pdf}/> Abrir Arquivo
                            </Button> */}
                        </a>
                    }
                    <br/>
                </div>
                <center>
                    <small><b>{file.descricao}</b></small>
                    {file.status_analise_docs === 'pending' ?
                    <>
                        <p style={{color: 'green', margin: 0}}><b>Pendente de aprovação</b></p>
                    </>
                    :
                    file.status_analise_docs === 'aprovado' ?
                    <>
                        <p style={{color: 'green', margin: 0}}><b>Documento Aprovado</b></p>
                    </>
                    :
                    <>
                        <p style={{color: 'red', margin: 0}}><b>Documento Reprovado</b></p>
                    </>
                    }
                    <Button color="info" style={{padding: 3, margin: 3}} onClick={() => this.onCloseDocument(file.id, true)}>
                        Adicionar nota
                    </Button>
                    <br/>
                    <br/>
                    <p style={{margin: 0}}>Notas:</p>
                    <small>
                        {file.notas.map(e => {
                            return <><b>{e.admin !== null ? e.admin.name : 'Sem Informações'}:</b> {e.descricao}<br/></>
                        })}
                        {file.notas.length === 0 ? <><b>Nenhuma nota</b></> : ''}
                    </small>
                </center>
                <br/>
            </div>
            </>
        ))
    };
    onOpenClosedComplience = (visible_complience = false) => {
        this.setState({ visible_complience })
    }
    render() {
        return (
            <Div>
                <h3>Resumo do Cliente</h3>
                <hr />
                <Card hidden={this.state.loading_user == true}>
                    <CardBody style={{height: '120px'}}>
                        <center>
                            <FadeLoader color={'#52c565'} margin={0} loading={50} size={60} />
                        </center>
                    </CardBody>
                </Card>
                
                <EnderecoCadastro
                    user_id={this.state.userId}
                    visible={this.state.visible_cadastro}
                    onClose={this.onClose}
                    onRefresh={this.onRefresh}
                />
                <Card hidden={this.state.loading_user == false}>
                    <CardBody hidden={!getUser().permissao_gestor_aqpago}>
                        <this.Navs />
                        <TabContent activeTab={this.state.activeTab}>
                            <DataClient
                                active={'1'}
                                name={this.state.name}
                                email={this.state.email}
                                atividade_principal={this.state.atividade_principal}
                                empresa={this.state.empresa}
                                cpf={this.state.cpf}
                                cnpj={this.state.cnpj}
                                status={this.state.status}
                                status_crm={this.state.status_crm}
                                plan_seller={this.state.plan_seller}
                                current_balance={this.state.current_balance}
                                account_balance={this.state.account_balance}
                                first_chat_date={this.state.first_chat_date}
                                last_chat_date={this.state.last_chat_date}
                                first_origin={this.state.first_origin}
                                last_origin={this.state.last_origin}
                                created_at={this.state.created_at}
                                telefone={this.state.telefone}
                                userCode={this.state.userCode}
                                alteracoes_usuario={this.state.alteracoes_usuario}
                                autorizacoes={this.state.autorizacoes}
                                cep={this.state.cep}
                                cidade={this.state.cidade}
                                estado={this.state.estado}
                                endereco={this.state.endereco}
                                bairro={this.state.bairro}
                                changeVisible={this.changeVisible}

                                email_edit={this.state.email_edit}
                                email_edit_enable={this.state.email_edit_enable}
                                editEmail={this.editEmail}
                                storeEmail={this.storeEmail}
                                storeTelefone={this.storeTelefone}
                                onOpen={this.onOpen}
                                
                                telefone_edit={this.state.telefone_edit}
                                telefone_edit_enable={this.state.telefone_edit_enable}
                                telefoneEdit={this.telefoneEdit}
                                handleChange={this.handleChange}
                                loading_edit={this.state.loading_edit}
                            />
                            <TabPane tabId={'2'}>
                                <Card style={{border: 'none'}}>
                                    <CardBody>
                                        <h5>Historico de Alterações</h5>
                                        <hr/>
                                        <div>
                                            {this.state.alteracoes_usuario.map(e => {
                                                return <p>{moment(e.created_at).format('DD/MM/YYYY HH:mm')} - <div dangerouslySetInnerHTML={{ __html: replaceAll(e.descricao, "\n", "<br/>") }} /></p>
                                            })}
                                        </div>
                                    </CardBody>
                                </Card>
                            </TabPane>
                            <EmailClient
                                active={'3'}
                                name={this.state.name}
                                email={this.state.email}
                                communication_relacao_id={this.state.communication_relacao_id}
                            />
                            <SmsClient
                                active={'4'}
                                name={this.state.name}
                                telefone={this.state.telefone}
                                communication_relacao_id={this.state.communication_relacao_id}
                            />
                            <ChatsClient 
                                active={'5'}
                                name={this.state.name}
                                communication_relacao_id={this.state.communication_relacao_id}
                            />
                            <NotasAtendimento
                                active={'6'}
                                name={this.state.name}
                                user_id={this.state.userId}
                                desc_id="communication_relacao_id"
                                communication_relacao_id={this.state.communication_relacao_id}
                            />
                            <ProtocoloClient
                                active={'7'}
                                name={this.state.name}
                                communication_relacao_id={this.state.communication_relacao_id}
                            />
                            <TransacoesClient
                                active={'8'}
                                name={this.state.name}
                                data={this.state.transactions}
                            />
                            <TransferenciaClient
                                active={'9'}
                                name={this.state.name}
                                data={this.state.transfers}
                            />
                            <PrevencaoRiscoClient
                                active={'10'}
                                name={this.state.name}
                                data={this.state.prevention}
                            />
                            <ProximosLancamentos
                                active={'11'}
                                name={this.state.name}
                                data={this.state.future_releases}
                            />
                            <ContasBancariasClient
                                active={'12'}
                                name={this.state.name}
                                data={this.state.bank_accounts}
                            />
                            <TicketClient
                                active={'13'}
                                name={this.state.name}
                                email={this.state.email}
                            />
                            <RespostaNPS
                                active={'14'}
                                name={this.state.name}
                                user_id={this.state.userId}
                            />
                            <OcorrenciasClient
                                active={'15'}
                                name={this.state.name}
                                user_id={this.state.userId}
                            />
                            
                            <TabPane tabId={'16'}>
                                <Card style={{border: 'none'}}>
                                    <CardBody>
                                        <h5>Compras realizadas</h5>
                                        <hr/>
                                        {this.state.compras.map(e => 
                                            <div>
                                                <h6>Compra: #{e.hash}</h6>
                                                <h6>Status: {e.status}</h6>
                                                <CardColumns>
                                                    {e.produtos.map(p => <Card>
                                                        <CardBody>
                                                            
                                                            {maquininhas_list.map(m => {
                                                                if(m.modelo.toUpperCase() == p.produto.toUpperCase()){
                                                                    if(p.produto.toUpperCase() != 'LINKPAY' && p.produto.toUpperCase() != 'SHOPAQPAGO'){
                                                                        return <Row>
                                                                            <Col md={12} style={{textAlign: 'center'}}>
                                                                                <img 
                                                                                    src={m.image} 
                                                                                    height="100px"
                                                                                    onError={({ currentTarget }) => {
                                                                                        console.log(currentTarget)
                                                                                        currentTarget.src = error_image;
                                                                                        currentTarget.onerror = null; // prevents looping
                                                                                    }}
                                                                                />
                                                                            </Col>
                                                                            <Col md={12} style={{marginTop: 5}}>
                                                                                <p><b>Produto: </b>{m.label}</p>
                                                                                <p><b>Serie: </b>{p.serial}</p>
                                                                                <p><b>Chip: </b>{p.chip}</p>
                                                                                <p><b>NF: </b>{p.nf}</p>
                                                                            </Col>
                                                                        </Row>
                                                                    }
                                                                    else{
                                                                        return <Row>
                                                                            <Col md={12} style={{textAlign: 'center'}}>
                                                                                <img 
                                                                                    src={m.image} 
                                                                                    height="100px"
                                                                                    onError={({ currentTarget }) => {
                                                                                        console.log(currentTarget)
                                                                                        currentTarget.onerror = null; // prevents looping
                                                                                        currentTarget.src = error_image;
                                                                                    }}
                                                                                />
                                                                            </Col>
                                                                            <Col md={12} style={{marginTop: 5}}>
                                                                                <p><b>Produto: </b>{m.label}</p>
                                                                                <p><b>NF: </b>{p.nf}</p>
                                                                            </Col>
                                                                        </Row>
                                                                    }
                                                                }
                                                            })}
                                                        </CardBody>
                                                    </Card>)}
                                                </CardColumns>
                                                <hr/>
                                            </div>
                                        )}
                                    </CardBody>
                                </Card>
                            </TabPane>
                            <TabPane tabId={'17'}>
                                <Card style={{border: 'none'}}>
                                    <CardBody>
                                        <h5>Maquininhas do cliente</h5>
                                        <hr/>
                                        <div>
                                            <CardColumns>
                                                {this.state.maquininhas.map(p => <Card>
                                                    <CardBody>
                                                        
                                                        {maquininhas_list.map(m => {
                                                            if(m.modelo.toUpperCase() == p.produto.toUpperCase()){
                                                                if(p.produto.toUpperCase() != 'LINKPAY' && p.produto.toUpperCase() != 'SHOPAQPAGO'){
                                                                    return <Row>
                                                                        <Col md={12} style={{textAlign: 'center'}}>
                                                                            <img src={m.image} height="100px"/>
                                                                        </Col>
                                                                        <Col md={12} style={{marginTop: 5}}>
                                                                            <p><b>Produto: </b>{m.label}</p>
                                                                            <p><b>Serie: </b>{p.serial}</p>
                                                                            <p><b>Chip: </b>{p.chip}</p>
                                                                            <p><b>NF: </b>{p.nf}</p>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                                else{
                                                                    return <Row>
                                                                        <Col md={12} style={{textAlign: 'center'}}>
                                                                            <img src={m.image} height="100px"/>
                                                                        </Col>
                                                                        <Col md={12} style={{marginTop: 5}}>
                                                                            <p><b>Produto: </b>{m.label}</p>
                                                                            <p><b>NF: </b>{p.nf}</p>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                            }
                                                        })}
                                                    </CardBody>
                                                </Card>)}
                                            </CardColumns>
                                            <hr/>
                                        </div>
                                    </CardBody>
                                </Card>
                            </TabPane>
                            <TabPane tabId={"18"}>
                                <Card style={{border: 'none'}}>
                                    <CardBody>
                                    <h5>Bureau de Crédito</h5>
                                    <hr/>
                                        {this.state.serasa.length == 0 ? <div>
                                            <Button 
                                                color="success"
                                                disabled={this.state.loading_serasa}
                                                onClick={() => {
                                                    this.setState({
                                                        loading_serasa: true
                                                    });

                                                    getSerasa(
                                                        this.state.cnpj.length > 0 ? this.state.cnpj: this.state.cpf, 
                                                        this.callbackRows,
                                                        () => this.setState({loading_serasa: false })
                                                    )
                                                }}
                                            >
                                                Consultar Bureau
                                            </Button>
                                        </div> 
                                        : 
                                            <div>
                                                <Button 
                                                    color="success"
                                                    disabled={this.state.loading_serasa}
                                                    onClick={() => {
                                                        this.setState({
                                                            loading_serasa: true
                                                        });

                                                        getSerasaNew(
                                                            getId(), 
                                                            this.callbackRows,
                                                            () => this.setState({loading_serasa: false })
                                                        )
                                                    }}
                                                >
                                                    Nova Consulta Bureau
                                                </Button>
                                            </div>
                                        }
                                        <br/>
                                    <Row>
                                        {this.state.serasa.map(e => {
                                            var score = JSON.parse(e.consulta_serasa);
                                            var resumo = JSON.parse(e.resumo_serasa);
                                            return(
                                                <Col md={6}>
                                                    <Card>
                                                        <CardBody>
                                                            <ReactStoreIndicator
                                                                value={score.score.pontos}
                                                                maxValue={1000}
                                                                lineWidth={25}
                                                            />
                                                            <span style={{
                                                                position: 'absolute',
                                                                top: '4rem', 
                                                                width: '100%',
                                                                left: 0,
                                                                fontSize: '55px',
                                                                textAlign: 'center',
                                                                fontWeight: 'bold'
                                                            }}>
                                                                {score.score.classe}
                                                            </span>
                                                            <center>
                                                                <h5>{score.score.faixa.titulo}</h5>
                                                            </center>
                                                            <hr/>
                                                            <div>
                                                                <p>{score.score.faixa.descricao}</p>
                                                                <p><b>Data da Consulta:</b> {moment(new Date(e.created_at).toString()).format('DD/MM/YYYY HH:m')}</p>
                                                                <p>
                                                                    <b>Renda Presumida:</b>&nbsp;                                                         
                                                                    <NumberFormat 
                                                                        prefix="R$ " 
                                                                        displayType="text" 
                                                                        fixedDecimalScale={true}
                                                                        decimalScale={2}
                                                                        value={parseFloat(resumo.rendaPresumida)} 
                                                                        decimalSeparator=","/>
                                                                </p>
                                                                <p>
                                                                    <b>{resumo.debitos.sumQuantidade} Débito{resumo.debitos.sumQuantidade > 1 ? 's' : ''}:</b>&nbsp;                                                         
                                                                    <NumberFormat 
                                                                        prefix="R$ " 
                                                                        displayType="text" 
                                                                        fixedDecimalScale={true}
                                                                        decimalScale={2}
                                                                        value={parseFloat(resumo.debitos.sumValorTotal)} 
                                                                        decimalSeparator=","/>
                                                                </p>
                                                                <p>
                                                                    <b>{resumo.protestos.sumQuantidade} Protesto{resumo.protestos.sumQuantidade > 1 ? 's' : ''} :</b>&nbsp;                                                         
                                                                    <NumberFormat 
                                                                        prefix="R$ " 
                                                                        displayType="text" 
                                                                        fixedDecimalScale={true}
                                                                        decimalScale={2}
                                                                        value={parseFloat(resumo.protestos.sumValorTotal)} 
                                                                        decimalSeparator=","/>
                                                                </p>
                                                                {resumo.acoes ? 
                                                                    <p>
                                                                        <b>{resumo.acoes.sumQuantidade} Aç{resumo.acoes.sumQuantidade > 1 ? 'ões' : 'ão'}:</b>&nbsp;                                                         
                                                                        <NumberFormat 
                                                                            prefix="R$ " 
                                                                            displayType="text" 
                                                                            fixedDecimalScale={true}
                                                                            decimalScale={2}
                                                                            value={parseFloat(resumo.acoes.sumValorTotal)} 
                                                                            decimalSeparator=","/>
                                                                    </p>
                                                                    :
                                                                    <></>
                                                                }
                                                                <p>
                                                                    <b>{resumo.cheques.sumQuantidade} Cheque{resumo.cheques.sumQuantidade > 1 ? 's' : ''}</b>&nbsp;                                                         
                                                                </p>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                    </CardBody>
                                </Card>
                            </TabPane>
                            <TabPane tabId={'19'}>
                                <Card style={{border: 'none'}}>
                                    <CardBody>
                                        <h5>Lista de Termos</h5>
                                        <hr/>
                                        {this.state.termos !== undefined && this.state.termos.length > 0 ?
                                            this.state.termos.map((termo, i) => {
                                                return (
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="box-div-page" style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                                            <p><b>ID Aceite:</b> {termo.id}</p>
                                                            <p><b>Código Hash Digital de Assinatura do Aceite ID:</b> {termo.assinatura_dispositivo}</p>
                                                            <p><b>Nome do Cliente/Estabelecimento Credenciamento:</b> {this.state.estabeleciomento}</p>
                                                            <p><b>CPF/CNPJ:</b>&nbsp;
                                                                {this.state.documento_seller !== null && this.state.documento_seller !== undefined ?
                                                                    <NumberFormat displayType={'text'} value={this.state.documento_seller} format={this.state.documento_seller.length > 11 ? '##.###.###/####-##' : "###.###.###-##"}/>
                                                                    :
                                                                    ''
                                                                }
                                                            </p>
                                                            <p><b>Meio de Acesso:</b>&nbsp; 
                                                                {termo.meio_acesso !== null ?
                                                                    termo.meio_acesso.replace('_', ' ')
                                                                :
                                                                    ''
                                                                }
                                                            </p>
                                                            <p><b>Tipo de Dispositivo Usado:</b> {termo.tipo_dispositivo}</p>
                                                            <p><b>Modelo do Dispositivo:</b> {termo.modelo_dispositivo}</p>
                                                            <p><b>Sistema Operacional do Dispositivo:</b>&nbsp;
                                                                {termo.sistema_operacional !== null ?
                                                                    termo.sistema_operacional.toUpperCase()
                                                                :
                                                                    ''
                                                                }
                                                            </p>
                                                            <p><b>Endereço IP de Internet:</b> {termo.ip_internet}</p>
                                                            <p><b>Latitude de Geolocalização:</b> {termo.latitude}</p>
                                                            <p><b>Longitude Geolocalização:</b> {termo.longitude}</p>
                                                            <p><b>Data do Aceite:</b>&nbsp;
                                                                {termo.data_aceite !== null ?
                                                                    moment(termo.data_aceite).format('DD/MM/YYYY')
                                                                :
                                                                    ''
                                                                }
                                                            </p>
                                                            <p><b>Hora do Aceite:</b>&nbsp;
                                                                {termo.data_aceite !== null ?
                                                                    moment(termo.data_aceite).format('HH:mm')
                                                                :
                                                                    ''
                                                                }
                                                            </p>
                                                            <p><b>Carimbo de Data/Hora:</b>&nbsp;
                                                                {termo.data_aceite !== null ?
                                                                    moment(termo.data_aceite).format('DD/MM/YYYY HH:mm:ss')
                                                                :
                                                                    ''
                                                                }
                                                            </p>
                                
                                                        </div>
                                                        <hr />
                                                    </Col>
                                                </Row>
                                                )
                                            })

                                            
                                        :
                                        <>
                                        <Row hidden={!this.state.loading_termo_tab}>
                                            <Col md={12}>
                                                <center>
                                                    <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                                                </center>
                                                <br/>
                                            </Col>
                                        </Row>
                                        <Row hidden={!this.state.loading_page}>
                                            <Col md={12}><p>Nenhum termo lozalizado!</p></Col>
                                        </Row>
                                        </>
                                        
                                        }
                                        <Row>
                                            <Col md={12}>
                                                {this.state.message_termos}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </TabPane>
                            <TabPane  
                                tabId={'20'}>
                                    <div>
                                        <br/>
                                        <h5>Validações de Cliente</h5>
                                        <hr/>
                                        <Button color='success' onClick={() => this.changeVisible(true)}>Validar Cliente</Button>
                                        <hr/>
                                        {this.state.autorizacoes ?
                                            <p>{this.state.autorizacoes.map(e => (
                                                <p>Token gerado em {moment(e.created_at).format('DD/MM/YYYY HH:mm') } com o status de {get_status_auth(e.status)}
                                                <br/><small>Gerado por: <b>{e.operador_name}</b></small></p>
                                            ))}</p>
                                            :
                                            null
                                        }
                                    </div>
                            </TabPane>
                            <TabPane  
                            tabId={'21'}>
                                <div>
                                    <br/>
                                    <h5>Documentos</h5>
                                            <hr/>
                                    <Button 
                                        onClick={() => this.onCloseUpload(true)} 
                                        color="info" 
                                        style={{padding: 5, marginBottom: 15}}>Upload de Documentos</Button>
                                    <Row>
                                        {this.thumbs()} 
                                    </Row>
                                    <p><br/><br/>{this.state.documents.length === 0 ? 'Nenhum Documento' : ''}</p>
                                    <UploadDocumentos
                                        visible={this.state.modal_upload}
                                        userId={this.state.userId}
                                        onClose={this.onCloseUpload}
                                    />
                                    <ModalShow
                                        visible={this.state.modal_show_document}
                                        url={this.state.document_file}
                                        onClose={this.onCloseShowDocument}
                                    />
                                </div>
                            </TabPane>
                            <TabPane  
                            tabId={'22'}>
                                <div>
                                    <br/>
                                    <h5>Time de Compliance</h5>
                                    <hr/>
                                    {this.state.compliance !== null ?
                                        <div>
                                            <p><b>Limite liberado On-line: </b>{parseFloat(this.state.compliance.limit_online).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                            <p><b>Limite liberado terminal POS: </b>{parseFloat(this.state.compliance.limit_pos).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                            <b>Distrato:</b>
                                            {this.state.compliance.documents.map(p => {
                                                return <Card style={{margin: 5}}>
                                                            <CardBody>
                                                                    <p>
                                                                        <b>Descrição: </b>{p.descricao}<br/>
                                                                        <b>Distrato:</b> {p.distract}<br/>
                                                                        <b>Enviado pelo Operador:</b> {p.auth_name}<br/>
                                                                        <hr/>
                                                                        <Button 
                                                                            color='success'
                                                                            onClick={() => window.open(p.url+'?access_token='+getToken(), '_blank')}
                                                                        >
                                                                            Abrir Documento
                                                                        </Button>
                                                                    </p>
                                                            </CardBody>
                                                        </Card>
                                            })}
                                            <br/>
                                            <Button color='info' hidden={this.state.is_open_nota} onClick={() => this.setState({ is_open_nota: true })}>Nova Nota</Button>
                                            <Collapse isOpen={this.state.is_open_nota}>
                                                <label htmlFor="for_desc">Nova nota</label>
                                                <Input
                                                    name={"distract_nota"}
                                                    placeholder="Descrição da Nota"
                                                    type="textarea"
                                                    style={{
                                                        height: '124px',
                                                        resize: 'none'
                                                    }}
                                                    onChange={(event) => {this.handleChange(event)}}
                                                />
                                                <ButtonGroup>
                                                    <Button color='info' onClick={() => this.setState({ is_open_nota: false, distract_nota: '' })}>Cancelar</Button>
                                                    <Button color='success' disabled={this.state.loading_compliance} onClick={() => this.store_note(this.state.compliance.id)}>Salvar</Button>
                                                </ButtonGroup>
                                            </Collapse>
                                            <br/>
                                            <br/>
                                            {this.state.compliance.notas_privadas.map(e => (
                                                <p><b>{moment(e.created_at).format('DD/MM/YYYY HH:mm')}</b> {e.descricao}</p>
                                            ))}
                                            <Button color='info' onClick={() => this.onOpenClosedComplience(true)}>Alterar</Button>
                                            <ModalComplience
                                                visible={this.state.visible_complience}
                                                onClose={() => this.onOpenClosedComplience()}
                                                onRefresh={() => getUserAQPago(this.state.userId,this.callbackRows)}
                                                document={this.state.cnpj.length > 0 ? this.state.cnpj: this.state.cpf}
                                            />
                                        </div>
                                    : <div>
                                        <p><b>Limite liberado On-line: </b>{parseFloat(0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                        <p><b>Limite liberado terminal POS: </b>{parseFloat(0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                            <Button color='info' onClick={() => this.onOpenClosedComplience(true)}>Alterar</Button>
                                            <ModalComplience
                                                visible={this.state.visible_complience}
                                                onClose={() => this.onOpenClosedComplience()}
                                                onRefresh={() => getUserAQPago(this.state.userId,this.callbackRows)}
                                                document={this.state.cnpj.length > 0 ? this.state.cnpj: this.state.cpf}
                                            />
                                    </div> }
                                </div>
                            </TabPane>
                        </TabContent>
                        {/* <AddCreditCard user_id={this.state.userId}/> */}
                    </CardBody>
                </Card>
                <ModalValidacaoToken
                    user={{
                        id: this.state.userId   
                    }}
                    tipo_cliente="crm"
                    visible={this.state.isVisible}
                    onClose={() => this.setState({ isVisible: false })}
                />
                <NewNotas
                    visible={this.state.modal_document}
                    id={this.state.documento_id}
                    desc_id="documento_cadastro_id"
                    user_id={this.state.userId}
                    onClose={this.onCloseDocument}
                />
                <Row>
                </Row>
                <br />
            </Div>
        )
    }
}