import React, { Component } from 'react';
import { Button, Card, CardBody, TabPane } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Moment from 'moment';
import { getToken } from '../../auth/set_services';
import { ENDPOINT } from '../../auth/endpoint';
import axios from 'axios';
import { MdNote } from 'react-icons/md';
import { toast } from 'react-toastify';
import NewNotas from '../Modals/SendsModals/NewNotas';
import NewNotasPersonalizadas from '../Modals/SendsModals/NewNotasPersonalizadas';
import { TableCobrancas } from '../Tables/TableCobrancas/TableCobrancas';

/* const getId = () =>{
    if(window.location.pathname === '/contestacoes'){
        return null;
    }
    else{
        return window.location.pathname.replace('/contestacoes/', '')
    }
} */
const getOperadores = (callback) => {
    var url = ENDPOINT + "chat-atendimento/consultar-operadores";
    
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        var operadores_list = [];
        data.forEach((p, i) => {
            p['value'] = p.id;
            p['label'] = p.name;
            operadores_list.push(p);
        });

        callback(operadores_list)
    });
}

/* const getRows = (documento, state, callbackRows) => {
    if(typeof documento === 'undefined'){
        return null;
    }
    else if(documento === -1){
        return null;
    }
    var url = ENDPOINT + "notas/list?documento="+communication_relacao_id;

    if (state.protocolo_atendimento.length > 0) {
        url += '&protocolo_atendimento=' + state.protocolo_atendimento
    }
    
    if (state.operador !== null) {
        url += '&operador=' + state.operador.id
    }

    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}
  
const getRowsPerPage = (communication_relacao_id, page, search, filter, state, callbackRows) => {
    if(typeof communication_relacao_id === 'undefined'){
        return null;
    }
    else if(communication_relacao_id === -1){
        return null;
    }

    var url = ENDPOINT + "notas/list?communication_relacao_id="+communication_relacao_id+"&search=" + search + "&page=" + page;
  
    if (state.protocolo_atendimento.length > 0) {
        url += '&protocolo_atendimento=' + state.protocolo_atendimento
    }

    if (state.operador !== null) {
        url += '&operador=' + state.operador.id
    }

    if (filter === true) {
      url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }
  
    axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + getToken()
      }
    }).then((response) => {
      callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
} */

const getRows = (documento, state, callbackRows) => {
    if(typeof documento === 'undefined'){
        return null;
    }
    else if(documento === -1){
        return null;
    }
    var url = ENDPOINT + "cobranca/list?search=" + state.search;

    if (state.validade === true) {
        url += '&validade=';
    }
    
    var document = documento.replace(/([^\d])+/gim, '');
    if(document.length === 11){
        url += "&cpf="+document;
    }
    if(document.length === 14){
        url += "&cnpj="+document;
    }

    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (documento, page, search, filter, state, callbackRows) => {
    if(typeof documento === 'undefined'){
        return null;
    }
    else if(documento === -1){
        return null;
    }
    
    var url = ENDPOINT + "cobranca/list?search=" + search + "&page=" + page;

    if (filter === true) {
        url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }

    if (state.validade === true) {
        url += '&validade=true';
    }

    if (state.status_pagamento !== null) {
        url += '&status_pagamento=' + state.status_pagamento.value;
    }

    if (state.forma_pagamento !== null) {
        url += '&forma_pagamento=' + state.forma_pagamento.value;
    }
    
    var document = documento.replace(/([^\d])+/gim, '');
    if(document.length === 11){
        url += "&cpf="+document;
    }
    if(document.length === 14){
        url += "&cnpj="+document;
    }

    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}
class MovimentacaoContas extends Component {
    constructor(props){
        super(props);
        this.state = {
            rowData: [],
            validade: false,
            activePage: 1,
            totalPage: 1,
            document: '',
            forma_pagamento: null,
            status_pagamento: null,
            is_cnpj: false,
            filter: false,
            search: '',
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            loading_table: true,
        }
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(this.props.documento, 1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentDidMount(){
        getRows(this.props.documento, this.state, this.callbackRows.bind(this));
    }
    componentWillUpdate(nextProps, nextState) {
        if (nextProps.documento !== this.props.documento) {
            getRows(nextProps.documento, this.state, this.callbackRows.bind(this))
        }
    }

    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({rowData, activePage, totalPage, loading_table: false})
    }

    onFilter = (filter) => {
        this.setState({filter});
        getRowsPerPage(this.props.documento, 1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }

    onValidade = (validade) => {
        var state = this.state;
        state['validade'] = validade;
        state.loading_table = true;
        this.setState({ ...state });

        getRowsPerPage(this.props.documento, 1, this.state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    onPageChange = (activePage) => {
        this.setState({activePage, loading_table: true})
        getRowsPerPage(this.props.documento, activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    

    onDataToast(data) {
        if (data.success === true) {
            toast.success(data.message);
        }
        else {
            toast.error(data.errors);
        }
    }
    handleStatusSelectChange = status_pagamento => {
        var state = this.state;
        state['status_pagamento'] = status_pagamento;
        state.loading_table = true;
      this.setState({ ...state });
      getRowsPerPage(this.props.documento, 1, this.state.search, this.state.filter, state, this.callbackRows.bind(this));
    };
    handleTypePaymentSelectChange = forma_pagamento => {
        var state = this.state;
        state['forma_pagamento'] = forma_pagamento;
        state.loading_table = true;
      this.setState({ ...state });
      getRowsPerPage(this.props.documento, this.state.search, this.state.filter, state, this.callbackRows.bind(this));
    };
    render() {
        return (
            <TabPane tabId={this.props.active}>
                <br />
                <h5>&nbsp;&nbsp;<MdNote style={{width: '30px', height: '30px'}}/> Movimentacao de Contas de {this.props.name}</h5>
                <br/>
                <Card>
                    <TableCobrancas
                        isSearch={this.handleChange}
                        is_cnpj={this.state.is_cnpj}
                        data={this.state.rowData}
                        handleTypePaymentSelectChange={this.handleTypePaymentSelectChange.bind(this)}
                        handleStatusSelectChange={this.handleStatusSelectChange.bind(this)}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        onFilter={this.onFilter.bind(this)}
                        onValidade={this.onValidade.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        state={this.state}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhuma cobrança encontrada
                            </div></CardBody></Card>
                        }
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
            </TabPane>
        )
    }
}

export default MovimentacaoContas;