import Moment from 'moment';
import { MdDelete } from 'react-icons/md';
import { Button, Row } from 'reactstrap';

export const columns = (onShowDelete) => [
    {
        name: '',
        selector: 'buttons',
        width: '40px',
        cell: row => <Row>
          {onShowDelete ? 
              <Button data-tip="Deletar Cliente"
                outline
                style={{ padding: 2, marginLeft: 7, border: 0, fontWeight: '700'}}
                color="danger"
                onClick={() => {
                    row.retorno.forEach(e => {
                        onShowDelete(e.id)
                    });
                }}
              >
                <MdDelete style={{ padding: '2px' }} />
              </Button> 
            : 
            <></>}
        </Row>
    },
    {
      name: "Protocolo do atêndimento realizado",
      selector: "protocolo",
      sortable: false,
      cell: row => row.protocolo_atendimento
    },
    {
        name: "Departamento",
        selector: "departamento",
        sortable: false,
        cell: row => row.departamento_ !== null ? row.departamento_.nome : 'Sem finformações'
    },
    {
        name: "CPF/CNPJ Cliente",
        selector: "document",
        sortable: false,
        cell: row => row.cpf_cnpj
    },
    {
        name: "Nome do Cliente",
        selector: "nome_cliente",
        sortable: false,
        cell: row => row.nome
    },
    {
        name: 'Motivos da pendencia',
        selector: 'motivo',
        sortable: false,
        width: '30%',
        cell: row => getPendenciaClient(row)
    },
    {
        name: 'Data do atendimento',
        selector: 'created_at',
        sortable: false,
        cell: row =>  Moment(new Date(row.created_at)).format('DD/MM/YYYY HH:mm')
    }
  ];

const getPendenciaClient = (row) => {
    var retorno = <div>
        { row.retorno.map((p) => {
            if(row.observacao_chat !== null){
                if(p.motivo === row.observacao_chat.replace('Pediu retorno devido: ', '').trim()){
                    return <><b>Pedido de retorno de Cliente:</b> {p.motivo}<br/></>
                }
                else{
                    return <><b>Pedido de retorno de Atendente:</b> {p.motivo}<br/></>
                }
            }
            else{
                return <><b>Pedido de retorno de Atendente:</b> {p.motivo}<br/></>
            }
        })}
    </div>

    return retorno;
}