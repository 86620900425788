import React, { useCallback, useEffect, useState } from "react";
import Cookies from "js-cookie";
import Select from "react-select";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useApiOnboarding } from "../../../hooks/useApi";

import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

const Card = styled.div`
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  margin-top: 16px;
  padding: 8px;

  p {
    margin-bottom: 0;
    font-size: 12px;
    color: #6c757d;
  }
  span {
    font-weight: 600;
    color: #3b3f5c;
    margin-top: 4px;
  }
`;

const ActivateOnboardingUser = ({ activateModal, setActivateModal, user }) => {
  const { apiOnboarding } = useApiOnboarding();

  const [mccSelected, setMccSelected] = useState(null);
  const [planSelected, setPlanSelected] = useState(null);

  const [mccList, setMccList] = useState([]);
  const [planList, setPlanList] = useState([]);

  const mccOptions = mccList.map((item) => ({
    value: item.code,
    label: item.description,
  }));

  const planOptions = planList.map((item, index) => ({
    value: index,
    label: item.name,
  }));

  const getMccList = useCallback(async () => {
    const token = Cookies.get("onboardingtoken");
    const urlRequest = "/crm/onboarding/zoop/activities";
    apiOnboarding
      .get(urlRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMccList(res.data);
      })
      .catch((e) => {
        const data = e.response.data;
        toast.error(data.message);
      })
  }, []);

  const getPlanList = useCallback(async () => {
    const token = Cookies.get("onboardingtoken");
    const urlRequest = "/crm/onboarding/zoop/plans";
    apiOnboarding
      .get(urlRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setPlanList(res.data);
      })
      .catch((e) => {
        const data = e.response.data;
        toast.error(data.message);
      })
  }, []);

  const handleForm = async () => {
    const token = Cookies.get("onboardingtoken");
    const urlRequest = "/crm/onboarding/zoop/create_user";
    apiOnboarding
      .post(
        urlRequest,
        {
          user_id: user?.dataAccountCard.aqpago_id,
          activity_code: mccSelected.value,
          plan_id: planSelected.label,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setActivateModal(false);
      })
      .catch((e) => {
        const data = e.response.data;
        toast.error(data.message);
      })
  };

  useEffect(() => {
    getMccList();
    getPlanList();
  }, [getMccList, getPlanList]);

  useEffect(() => {
    if (user.dataUserCard.user_type === "pf") {
      setMccSelected({
        label: "Serviços Profissionais, Categorias Especiais - Outros",
        value: "8999",
      });
    }
  }, [user.dataUserCard.user_type]);

  return (
    <Modal isOpen={activateModal}>
      <ModalHeader>Dados de credenciamento</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            {user.dataUserCard.user_type === "pf" && (
              <Card>
                <p>Ramo de atividade:</p>
                <span>
                  Serviços Profissionais, Categorias Especiais - Outros
                </span>
              </Card>
            )}

            {user.dataUserCard.user_type === "pj" && (
              <>
                <label htmlFor="mcc">Ramo de atividade</label>
                <Select
                  name="mcc"
                  placeholder="Selecione uma opção"
                  options={mccOptions}
                  isSearchable={true}
                  value={mccSelected}
                  onChange={(mcc) => setMccSelected(mcc)}
                />

                <Card>
                  <p>Ramo de atividade informado pelo cliente:</p>
                  <span>
                    {user ? user.dataCompanyCard.cnaes[0].description : ""}
                  </span>
                </Card>
              </>
            )}
          </FormGroup>
          <FormGroup>
            <label htmlFor="plan">Selecione o plano para esse cliente:</label>
            <Select
              name="plan"
              placeholder="Selecione uma opção"
              options={planOptions}
              isSearchable={true}
              value={planSelected}
              onChange={(plan) => setPlanSelected(plan)}
            />
          </FormGroup>
        </Form>
      </ModalBody>

      <ModalFooter>
        <Button color="danger" onClick={() => setActivateModal(false)}>
          Cancelar
        </Button>
        <Button
          color="success"
          onClick={() => handleForm()}
          disabled={mccSelected === null || planSelected === null}
        >
          Cadastrar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ActivateOnboardingUser;
