import React, { Component   } from 'react';
import moment from 'moment';
import styled from "styled-components";
import { Button, Card, CardBody, CardColumns, CardFooter, Col, Collapse, FormGroup, Input, Label, Nav, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { FaArrowLeft, FaGlobeAmericas, FaMoneyBillAlt, FaPercent, FaPlus, FaSave, FaWindowClose } from 'react-icons/fa';
import classnames from 'classnames';
import PropagateLoader from "react-spinners/PropagateLoader";
import ListarTaxasRegras from './Configuracao/ListarTaxasRegras';
import ListarFreeRules from './Configuracao/ListarFreeRules';
import Select from "react-select";
import axios from "axios";
import { getPermissoes, getToken } from "../../auth/set_services";
import { toast } from 'react-toastify';
import ListarVendedores from './Configuracao/ListarVendedores';
import { ENDPOINT } from '../../auth/endpoint';
import ConfirmarRemoveFree from "../../components/Modals/AQEnvio/ConfirmarRemoveFree";

var CurrencyInput = require('react-currency-masked-input');

const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
    .reclamado {
        display: none;
    }
    .row {
        margin: 10px 0px;
    }
    .btn-save {
        float: right;
    }
    .btn-nota {
        margin-top: 10px;
    }
    .file-iframe {
        width: 100%;
        height: 800px; 
    }
    .btn-arquivo {
        margin-right: 10px;
        color: #fff;
        border: 0px;
    }
    .subtitle {
        font-weight: 700;
        margin-top: 20px !important;
    }
    .error-active {
        display: block;
        color: red;
    }
    .error-none {
        display: none;
    }
    .input-error {
        border: 1px solid red;
    }
    .label-error {
        color: red;
    }
    .select-error {
        border: 1px solid red;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
    }
    .card {
        border: 1px solid #ced4da;
        border-left: 5px solid #28a745;
        border-radius: 0.25rem;
        -webkit-border-radius: 0.25rem;
        -moz-border-radius: 0.25rem;
        
    }
    .card h6 {
        font-weight: 600;
        padding: 10px;
        border-radius: 3px 3px 0px 0px;
        margin: 0px;
    }
    .card-box {
        padding: 1rem;
        height: auto;
        min-height: 180px;
    }
    .card-box div {
        font-size: 14px;
    }
    .button-edit {
        position: absolute;
        bottom: 0.5rem;
        right: 1rem;
    }
    .button-remove {
        position: absolute;
        bottom: 0.5rem;
        left: 1rem;
    }
    .btn-salvar {
        float: right;
    }
    .add-address-btn {
        position: absolute;
        top: 0.5rem;
        right: 2rem;
    }
    .col-cards {
        margin-bottom: 1rem;
    }
    .btn-float {
        float: right;
    }
    .nav-link {
        padding: 1rem;
        font-size: 14px; 
        border-bottom: 0px;
    }
    .active.nav-link {
        background: #58d174;
        color: #fff;
    }
    .tab-content {
        border: 1px solid rgb(222, 226, 230);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        position: relative;
        top: 0px;
    }
    .nav.nav-tabs {
        border-bottom: 0px;
    }
    .currency-simbol {
        color: #999b9d;
        position: absolute;
        left: 25px;
        top: 43px;
    }
    .captalize {
        text-transform: capitalize;
    }

    table tr th,
    table tr td {
        font-weight: normal;
        padding: 0.5rem 1.5rem;
        border: 1px solid #dee2e6;
    }

    table tr td { 
        font-weight: bold;
    }

    .input-erro {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ff0000;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    .select-erro {
        border: 1px solid #ff0000;
        border-radius: 0.25rem;
    }
    .currency-simbol {
        color: #999b9d;
        position: absolute;
        left: 25px;
        top: 43px;
    }
    .input-currency {
        padding-left: 30px;
    }
`;

class AQEnvioConfiguracao extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading_page: false,
            table_categorias: true,

            tax_type_options: [
                {value: 'V', label: 'Valor (R$)'},
                {value: 'P', label: 'Procentagem (%)'},
            ],

            shipping_free: 0,
            shipping_free_select: {value: '0', label: 'Frete Pago'},

            pay_type_select: {value: 'V', label: 'Valor (R$)'},

            visible_confirm: false,
            show_free_form: false,
            show_free_table: true,
            free_id: null,
            seller_conta_id: null,
            seller_name: null,
            seller_email: null,
            seller_document: null,
            select_free_disabled: false,
            create_new_rule: null,
            expire_days: null,
            installments_min: {label: '8x', value:'8'},

            activeTabConfig: "tax_rules",
            activeTab: "1",
            list_tabs_config: [
                { name: <><FaGlobeAmericas style={{ padding: '2px' }}/> Frete Pago Global</>, active: 'tax_rules'},
                { name: <><FaPercent style={{ padding: '2px' }}/> Frete Grátis Global</>, active: 'free_rules'},
                //{ name: <><FaPercent style={{ padding: '2px' }}/> Regras & Taxas Vendedores</>, active: 'custom_rules'},
            ],

            ruleSellerData: this.handleLoadRuleSellerData.bind(this),
            handleEditRuleShipping: this.handleEditRuleShipping.bind(this),
            handleCancelSaveFreeShipping: this.handleCancelSaveFreeShipping.bind(this),
            handleRemoveFreeRule: this.handleRemoveFreeRule.bind(this),
        }
    }

    handletoastSuccessMessage = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    handletoastMessage = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    componentDidMount = () => {
        this.setState({loading_page: true})

        this.loadDataGlobal();
    }

    loadDataGlobal = () => {
        var url = ENDPOINT + "aqenvio/list_global_rules";
        //var url = "http://127.0.0.1:8000/api/aqenvio/list_global_rules";

        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            let data = response.data;

            if (data.success) {
                data.data.map((rule, i) => {
                    if (rule.free === 1) {
                        
                        this.setState({
                            free_min_amount: rule.min_amount.replace('.', ','),
                            free_max_amount: rule.max_amount.replace('.', ','),
                            free_weight: rule.weight.replace('.', ','),
                            expire_days: (rule?.expire) ? rule?.expire : null,
                        })
                    } else {
                        this.setState({
                            pay_min_amount: rule.min_amount.replace('.', ','),
                            pay_max_amount: rule.max_amount.replace('.', ','),
                            pay_weight: rule.weight.replace('.', ','),
                            pay_tax_amount: rule.tax_amount.replace('.', ','),
                            pay_tax_percent: rule.tax_percent.replace('.', ','),
                        })
                        

                        this.state.tax_type_options.map((option, i) => {
                            if (option.value == rule.tax_type) {
                                this.setState({
                                    pay_type: option.value,
                                    pay_type_select: option,
                                })
                            }
                        })
                    }
                })
            }
        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        })
    }

    handleSavePayShipping = () => {
        var url = ENDPOINT + "aqenvio/rules_global_pay";
        //var url = "http://127.0.0.1:8000/api/aqenvio/rules_global_pay";
        
        axios.post(url, {
            'min_amount': this.state.pay_min_amount,
            'max_amount': this.state.pay_max_amount,
            'weight': this.state.pay_weight,
            'tax_type': this.state.pay_type,
            'tax_amount': this.state.pay_tax_amount,
            'tax_percent': this.state.pay_tax_percent,
        }, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            let data = response.data;

            if (data.success) {
                this.handletoastSuccessMessage('Salvo com sucesso!')
            }

        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              //console.log(error.config);
        })
    }

    handleSetFreeId = (freeID) => {
        this.setState({free_id: freeID})
    }

    handleLoadCloseRuleSellerData = () => {
        this.setState({
            seller_conta_id: null,
            seller_name: null,
            seller_document: null,
            seller_email: null,
            select_free_disabled: false,
        })
    }

    handleLoadRuleSellerData = (data) => {
        let state = this.state;

        let shippingFreeSelect = {};

        if (data.free !== undefined && data.free == 1) {
            shippingFreeSelect = {value: '1', label: 'Frete Grátis'};
        } else {
            shippingFreeSelect = {value: '0', label: 'Frete Pago'};
        }

        if (data.free !== undefined) {
            state.seller_conta_id = data.seller_conta_id;
            state.seller_name = data.seller_name;
            state.seller_document = data.seller_document;
            state.seller_email =  data.seller_email;
            state.shipping_free_select = shippingFreeSelect;
            state.select_free_disabled = true;
        } else {
            state.seller_conta_id = data.seller_conta_id;
            state.seller_name = data.seller_name;
            state.seller_document = data.seller_document;
            state.seller_email =  data.seller_email;
            state.shipping_free_select = shippingFreeSelect;
            state.select_free_disabled = false;
            state.create_new_rule = null;
        }

        this.setState({...state})

    }
    
    handleEditRuleShipping = (rule) => {

        this.setState({
            free_id: rule.id,
            free_min_amount: rule.min_amount,
            free_max_amount: rule.max_amount,
            free_weight: rule.weight,
            expire_days: rule.expire,
            installments_min: {label: rule.installments_min + 'x', value: rule.installments_min},
            create_new_rule: true,
            show_free_form: true,
            show_free_table: false,
        })
    }

    handleRemoveFreeRule = () => {
        this.setState({show_free_table: false})
        var url = ENDPOINT + "aqenvio/rules_cancel_global_free/" + this.state.free_id;
        //var url = "http://127.0.0.1:8000/api/aqenvio/rules_cancel_global_free/" + this.state.free_id;
        
        axios.delete(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            let data = response.data;
            
            this.setState({show_free_table: true})

            if (data.success) {
                this.loadDataGlobal()
                this.handletoastSuccessMessage('Removido com sucesso!')
            } else {
                this.handletoastMessage('Falha ao remover!');
            }

        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              //console.log(error.config);
        })
    }

    handleCancelSaveFreeShipping = (idRule) => {
        this.setState({free_id: idRule})
        this.modalConfirm(true, this.handleRemoveFreeRule)
    }

    handleSaveFreeShipping = () => {
        var url = ENDPOINT + "aqenvio/rules_global_free";
        //var url = "http://127.0.0.1:8000/api/aqenvio/rules_global_free";

        axios.post(url, {
            'id': this.state.free_id,
            'min_amount': this.state.free_min_amount,
            'max_amount': this.state.free_max_amount,
            'weight': this.state.free_weight,
            'expire': this.state.expire_days,
            'installments_min': this.state.installments_min.value,
        }, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            let data = response.data;

            this.setState({
                create_new_rule: false,
                show_free_form: false,
                show_free_table: true,
            })
            if (data.success) {
                this.loadDataGlobal()
                this.handletoastSuccessMessage('Salvo com sucesso!')
            } else {
                this.handletoastMessage('Falha ao salvar!');
            }

        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);
        })
    }

    handleNewRuleShipping = () => {
        this.setState({
            create_new_rule: true,
            show_free_form: true,
            show_free_table: false,
            free_id: null,
        })
    }

    handleSaveFreeShippingCancel = () => {
        this.setState({
            free_id: null,
            create_new_rule: false,
            show_free_form: false,
            show_free_table: true,
        })
    }

    onChangeSelect = (name, value) => {
        var state = this.state;
        state[name] = value;
        this.setState({...state});
    }

    modalConfirm(visible_confirm, callback = false){
        this.setState({visible_confirm: visible_confirm, callback_modal: callback})
    }

    render() {
        const NavsConfig = () => {
            if (this.state.activeTabConfig === 'regimes') {
                if (!this.state.loaded_regime) {
                    this.handleLoadRegimes()
                }
            }  

            if (this.state.activeTabConfig === 'forma_pagamento') {
                if (!this.state.load_formas_pagamento) {
                    this.setState({load_formas_pagamento: true})
                }
            }

            if (this.state.activeTabConfig === 'imposto_fixo') {
                if (!this.state.list_impostos) {
                    this.setState({list_impostos: true, reload_impostos: true})
                }
            }

            return <Nav tabs>
                {this.state.list_tabs_config.map((p, index) => {
                    let ClassNames = classnames({ active: this.state.activeTabConfig === p.active });
                    return <NavLink
                        key={index}
                        className={ClassNames}
                        onClick={() => { this.setState({ activeTabConfig: p.active }); }}>
                        <span>{p.name}</span>
                    </NavLink>
                })}
            </Nav>
        }

        return(
        <Div>
            <h3>Configuração AQEnvio</h3><hr />
            <NavsConfig />
            <TabContent activeTab={this.state.activeTabConfig}>
                <TabPane tabId={'tax_rules'}>
                    <Row hidden={this.state.loading_page}>
                        <Col md={12}>
                            <center>
                                <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                            </center>
                            <br/>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                <Row>
                                    <Col md={12}>
                                        <h5>Regra para fretes pagos global</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <label htmlFor="min_amount">Valor mínimo</label>
                                        <Input 
                                            className="form-control input-currency" 
                                            name="min_amount"
                                            value={this.state.pay_min_amount}
                                            onChange={(event) => {
                                                this.setState({
                                                    pay_min_amount: event.target.value
                                                })
                                            }}
                                            />
                                            <small className="currency-simbol">R$</small>
                                    </Col>
                                    <Col md={6}>
                                        <label htmlFor="max_amount">Valor máximo</label>
                                        <Input 
                                            className="form-control input-currency" 
                                            name="max_amount"
                                            value={this.state.pay_max_amount}
                                            onChange={(event) => {
                                                this.setState({
                                                    pay_max_amount: event.target.value
                                                })
                                            }}
                                            />
                                            <small className="currency-simbol">R$</small>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <label htmlFor="tax_type">Tipo de taxa</label>
                                        <Select
                                            name="tax_type"
                                            placeholder="Tipo de taxa usada"
                                            options={this.state.tax_type_options}
                                            onChange={(select_option) => {
                                                this.setState({
                                                    pay_type: select_option.value,
                                                    pay_type_select: select_option,
                                                })
                                            }}
                                            value={this.state.pay_type_select}
                                        />
                                    </Col>
                                    {this.state.pay_type_select.value === 'V' ?
                                        <Col md={6}>
                                            <label htmlFor="tax_amount">Valor da taxa</label>
                                            <CurrencyInput 
                                                inputMode="tax_amount"
                                                className="form-control input-currency" 
                                                currency="BRL"
                                                name="min_amount"
                                                value={this.state.pay_tax_amount}
                                                onChange={(event) => {
                                                    this.setState({
                                                        pay_tax_amount: event.target.value
                                                    })
                                                }}
                                                />
                                                <small className="currency-simbol">R$</small>
                                        </Col>
                                        :
                                        <Col md={6}>
                                            <label htmlFor="tax_percent">Porcentagem da taxa</label>
                                            <Input 
                                                className="form-control input-currency" 
                                                name="min_amount"
                                                value={this.state.pay_tax_percent}
                                                onChange={(event) => {
                                                    this.setState({
                                                        pay_tax_percent: event.target.value
                                                    })
                                                }}
                                                />
                                                <small className="currency-simbol">%</small>
                                        </Col>
                                    }
                                </Row> 
                                <Row> 
                                    <Col md={6}>
                                        <label htmlFor="weight">Peso máximo</label>
                                        <Input 
                                            className="form-control input-currency" 
                                            name="weight"
                                            value={this.state.pay_weight}
                                            onChange={(event) => {
                                                this.setState({
                                                    pay_weight: event.target.value
                                                })
                                            }}
                                            />
                                            <small className="currency-simbol">Kg</small>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Button 
                                            className="btn btn-success btn-save" 
                                            onClick={(event) => this.handleSavePayShipping()}
                                        >
                                            <FaSave style={{padding: 0}}/> Salvar
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>


                </TabPane> 
                <TabPane tabId={'free_rules'}>
                    <Row>
                        <Col md={12}>
                            <Collapse isOpen={this.state.show_free_form}>
                                <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                    <Row>
                                        <Col md={12}>
                                            <h5>Regra global para fretes grátis</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <label htmlFor="min_amount">Valor mínimo</label>
                                            <Input 
                                                className="form-control input-currency" 
                                                name="min_amount"
                                                value={this.state.free_min_amount}
                                                onChange={(event) => {
                                                    this.setState({
                                                        free_min_amount: event.target.value
                                                    })
                                                }}
                                                />
                                                <small className="currency-simbol">R$</small>
                                        </Col>
                                        <Col md={6}>
                                            <label htmlFor="max_amount">Valor máximo</label>
                                            <Input 
                                                className="form-control input-currency" 
                                                name="max_amount"
                                                value={this.state.free_max_amount}
                                                onChange={(event) => {
                                                    this.setState({
                                                        free_max_amount: event.target.value
                                                    })
                                                }}
                                                />
                                                <small className="currency-simbol">R$</small>
                                        </Col>
                                    </Row>
                                    <Row> 
                                        <Col md={6}>
                                            <label htmlFor="weight">Peso máximo</label>
                                            <Input 
                                                className="form-control input-currency" 
                                                name="weight"
                                                value={this.state.free_weight}
                                                onChange={(event) => {
                                                    this.setState({
                                                        free_weight: event.target.value
                                                    })
                                                }}
                                                />
                                                <small className="currency-simbol">Kg</small>
                                        </Col>
                                        <Col md={6}>
                                            <label htmlFor="weight">Dias para utilizar</label>
                                            <Input 
                                                className="form-control" 
                                                name="weight"
                                                value={this.state.expire_days}
                                                onChange={(event) => {
                                                    this.setState({
                                                        expire_days: event.target.value
                                                    })
                                                }}
                                                />
                                        </Col>
                                    </Row> 
                                    <Row>
                                        <Col md={6}>
                                            <label htmlFor="installments_min">Parcela mínima</label>
                                            <Select
                                                placeholder="Regra para"
                                                options={[
                                                    {label: '1x', value:'1'},
                                                    {label: '2x', value:'2'},
                                                    {label: '3x', value:'3'},
                                                    {label: '4x', value:'4'},
                                                    {label: '5x', value:'5'},
                                                    {label: '6x', value:'6'},
                                                    {label: '7x', value:'7'},
                                                    {label: '8x', value:'8'},
                                                    {label: '9x', value:'9'},
                                                    {label: '10x', value:'10'},
                                                    {label: '11x', value:'11'},
                                                    {label: '12x', value:'12'},
                                                ]}
                                                value={this.state.installments_min}
                                                onChange={(value) => this.onChangeSelect('installments_min', value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Button 
                                                style={{float: 'left'}}
                                                className="btn btn-danger" 
                                                onClick={(event) => this.handleSaveFreeShippingCancel()}
                                            >
                                                Cancelar
                                            </Button>

                                            <Button 
                                                className="btn btn-success btn-save" 
                                                onClick={(event) => this.handleSaveFreeShipping()}
                                            >
                                                <FaSave style={{padding: 0}}/> Salvar
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                        </Col>
                    </Row>
                    {this.state.show_free_table ?
                    <>
                        <Row style={{marginTop: '2rem'}}>
                            <Col md={12}>
                                <Button 
                                    className="btn btn-success btn-save" 
                                    onClick={(event) => this.handleNewRuleShipping()}
                                >
                                    <FaPlus style={{height: '1rem',padding: 0}}/> Cadastrar Regra
                                </Button>
                            </Col>
                        </Row>
                        <Row style={{marginTop: '2rem'}}>
                            <Col md={12}>
                                <ListarFreeRules 
                                    state={this.state}
                                    ruleSellerData={this.state.ruleSellerData}
                                    editRuleShipping={this.state.handleEditRuleShipping}
                                    cancelSaveFreeShipping={this.state.handleCancelSaveFreeShipping}
                                    />
                            </Col>
                        </Row>
                    </>
                    :
                    <></>
                    }

                </TabPane>
                <TabPane tabId={'custom_rules'}>
                    <Row hidden={this.state.loading_page}>
                        <Col md={12}>
                            <center>
                                <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                            </center>
                            <br/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>

                            {this.state.table_categorias === true ?
                                <>
                                    {this.state.create_new_rule !== null ?
                                        <>
                                            <ListarVendedores 
                                                state={this.state}
                                                ruleSellerData={this.state.ruleSellerData}
                                                />
                                        </>
                                    :
                                        <>
                                        {this.state.seller_conta_id !== null ?
                                            <>
                                                <Row>
                                                    <Col md={12}>
                                                        <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <h5>Regra para</h5>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <b>Cliente:</b> {this.state.seller_name}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <b>Documento:</b> {this.state.seller_document}
                                                                </Col>
                                                            </Row>
                                                            <Row style={{marginBottom:'2rem'}}>
                                                                <Col md={12}>
                                                                    <b>Email:</b> {this.state.seller_email}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <label htmlFor="min_amount">Valor mínimo</label>
                                                                    <Input 
                                                                        className="form-control input-currency" 
                                                                        name="min_amount"
                                                                        value={this.state.pay_min_amount}
                                                                        onChange={(event) => {
                                                                            this.setState({
                                                                                pay_min_amount: event.target.value
                                                                            })
                                                                        }}
                                                                        />
                                                                        <small className="currency-simbol">R$</small>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <label htmlFor="max_amount">Valor máximo</label>
                                                                    <Input 
                                                                        className="form-control input-currency" 
                                                                        name="max_amount"
                                                                        value={this.state.pay_max_amount}
                                                                        onChange={(event) => {
                                                                            this.setState({
                                                                                pay_max_amount: event.target.value
                                                                            })
                                                                        }}
                                                                        />
                                                                        <small className="currency-simbol">R$</small>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <label htmlFor="tax_type">Tipo de taxa</label>
                                                                    <Select
                                                                        name="tax_type"
                                                                        placeholder="Tipo de taxa usada"
                                                                        options={this.state.tax_type_options}
                                                                        onChange={(select_option) => {
                                                                            this.setState({
                                                                                pay_type: select_option.value,
                                                                                pay_type_select: select_option,
                                                                            })
                                                                        }}
                                                                        value={this.state.pay_type_select}
                                                                    />
                                                                </Col>
                                                                {this.state.pay_type_select.value === 'V' ?
                                                                    <Col md={6}>
                                                                        <label htmlFor="tax_amount">Valor da taxa</label>
                                                                        <CurrencyInput 
                                                                            inputMode="tax_amount"
                                                                            className="form-control input-currency" 
                                                                            currency="BRL"
                                                                            name="min_amount"
                                                                            value={this.state.pay_tax_amount}
                                                                            onChange={(event) => {
                                                                                this.setState({
                                                                                    pay_tax_amount: event.target.value
                                                                                })
                                                                            }}
                                                                            />
                                                                            <small className="currency-simbol">R$</small>
                                                                    </Col>
                                                                    :
                                                                    <Col md={6}>
                                                                        <label htmlFor="tax_percent">Porcentagem da taxa</label>
                                                                        <Input 
                                                                            className="form-control input-currency" 
                                                                            name="min_amount"
                                                                            value={this.state.pay_tax_percent}
                                                                            onChange={(event) => {
                                                                                this.setState({
                                                                                    pay_tax_percent: event.target.value
                                                                                })
                                                                            }}
                                                                            />
                                                                            <small className="currency-simbol">%</small>
                                                                    </Col>
                                                                }
                                                            </Row> 
                                                            <Row> 
                                                                <Col md={6}>
                                                                    <label htmlFor="weight">Peso máximo</label>
                                                                    <Input 
                                                                        className="form-control input-currency" 
                                                                        name="weight"
                                                                        value={this.state.pay_weight}
                                                                        onChange={(event) => {
                                                                            this.setState({
                                                                                pay_weight: event.target.value
                                                                            })
                                                                        }}
                                                                        />
                                                                        <small className="currency-simbol">Kg</small>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <label htmlFor="tax_type">Tipo de frete</label>
                                                                    <Select
                                                                        name="tax_type"
                                                                        placeholder="Tipo de frete"
                                                                        isDisabled={this.state.select_free_disabled}
                                                                        options={[
                                                                            {label: 'Frete Pago',value: 0},
                                                                            {label: 'Frete Grátis',value: 1},
                                                                        ]}
                                                                        onChange={(select_option) => {
                                                                            this.setState({
                                                                                shipping_free: select_option.value,
                                                                                shipping_free_select: select_option,
                                                                            })
                                                                        }}
                                                                        value={this.state.shipping_free_select}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row style={{marginTop: '2rem'}}>
                                                                <Col md={12}>
                                                                    
                                                                    <Button 
                                                                        className="btn btn-danger" 
                                                                        onClick={(event) => this.handleLoadCloseRuleSellerData()}
                                                                    >
                                                                        <FaArrowLeft style={{height: '1rem',padding: 0}}/> Voltar
                                                                    </Button>  

                                                                    <Button 
                                                                        className="btn btn-success btn-save" 
                                                                        onClick={(event) => this.handleSavePayShipping()}
                                                                    >
                                                                        <FaSave style={{height: '1rem',padding: 0}}/> Salvar
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        :
                                            <>
                                                <Row style={{marginTop: '2rem'}}>
                                                    <Col md={12}>
                                                        <Button 
                                                            className="btn btn-success btn-save" 
                                                            onClick={(event) => this.handleNewRuleShipping()}
                                                        >
                                                            <FaPlus style={{height: '1rem',padding: 0}}/> Cadastrar Regra
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <ListarTaxasRegras 
                                                    state={this.state}
                                                    ruleSellerData={this.state.ruleSellerData}
                                                    />
                                            </>
                                        }
                                        </>
                                    }


                                </>

                            :
                                <></>
                            }

                        </Col>
                    </Row>


                </TabPane> 
            </TabContent>
            <br />
            <br />
            <ConfirmarRemoveFree 
                visible={this.state.visible_confirm}
                onClose={() => this.modalConfirm(false)}
                callback={this.state.callback_modal}
                /> 
        </Div>
        )
    }
}

export default AQEnvioConfiguracao;