export function validateInputAqmed(document) {
  if (document.length === 14) {
    return {
      message: "",
      valid: true,
      invalid: false,
    };
  }
  if (document.length === 18) {
    return {
      message: "",
      valid: true,
      invalid: false,
    };
  }

  return {
    message: "Documento Inválido",
    valid: false,
    invalid: true,
  };
}

export function getStatusAqmed(statusEnum) {
  const status = {
      "ORDER_CREATE": "Criado",
      "ORDER_WAITING": "Aguardando",
      "ORDER_IN_ANALYSIS": "Em análise",
      "ORDER_NOT_PAID": "Não pago",
      "ORDER_PAID": "Pago",
      "ORDER_PARTIAL_PAID": "Pago parcialmente",
      "ORDER_CANCELED": "Cancelado",
      "ORDER_REVERSED": "Revertido",
      "ORDER_PARTIAL_REVERSED": "Revertido parcialmente",
      "ORDER_CHARGE_BACK": "Chargeback",
      "ORDER_DISPUTE": "Disputa",
      "ORDER_FAILED": "Falha",
      "ORDER_TEST_CREDIT_CARD": "Teste de cartão de crédito"
  };

  return status[statusEnum] || null;
}
export function formatDate(dataString) {
  if (!dataString) return "";
  const data = new Date(dataString);
  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0');
  const ano = data.getFullYear();

  return `${dia}/${mes}/${ano}`;
}
export function searchWithMask(event, setSearch) {
  let document = event.target.value;
    if (event.nativeEvent.inputType === "deleteContentBackward") {
      if (document.length === 14) {
        document = document.replace(/[.-/]/g, "");
        document = `${document.substring(0, 3)}.${document.substring(
          3,
          6
        )}.${document.substring(6, 9)}-${document.substring(9, 11)}`;
      }
      setSearch({ document: document });
      return;
    }
    if (document.length === 4) {
      const firstDigits = document.substring(0, 3);
      document = `${firstDigits}.${document.substring(3, 4)}`;
    } else if (document.length === 8) {
      document = `${document.substring(0, 7)}.${document.substring(7, 8)}`;
    } else if (document.length === 12) {
      document = `${document.substring(0, 11)}-${document.substring(11, 12)}`;
    } else if (document.length === 15) {
      document = document.replace(/[.-]/g, "");
      document = `${document.substring(0, 2)}.${document.substring(
        2,
        5
      )}.${document.substring(5, 8)}/${document.substring(8, 12)}`;
    } else if (document.length === 16) {
      document = `${document.substring(0, 15)}-${document.substring(15, 16)}`;
    }
    setSearch({ document: document });
}