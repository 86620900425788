import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { ENDPOINT } from '../../auth/endpoint';
import { getToken } from '../../auth/set_services';
import ModalAutorizacao from '../../components/Modals/ModalAutorizacao/ModalAutorizacao';
import { getRows, getRowsPerPage } from '../../components/Tables/TableAutorizacao/propsAutorizacao';
import { TableAutorizacao } from '../../components/Tables/TableAutorizacao/TableAutorizacao';
import PropagateLoader from "react-spinners/PropagateLoader";

class GestorAutorizacao extends Component {
    constructor(props){
        super(props);
        this.state = {
            rowData: [],
            activePage: 1,
            totalPage: 1,
            filter: false,
            search: '',
            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            loading_table: true,
            visible_tag: false,
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentDidMount(){
        getRows(this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({rowData, activePage, totalPage, loading_table: false})
    }

    onFilter = (filter) => {
        this.setState({filter});
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }

    onClose = (visible_tag = false) => {
        this.setState({visible_tag})
    }
    render(){
        return(
            <div>
                <h3>Gestor de Solicitações</h3>
                <hr/>
                <Button onClick={() => this.setState({visible_tag: true})}>Nova Solicitação Interna</Button>
                <br/><br/>
                <Card>
                    <TableAutorizacao
                        isSearch={this.handleChange}
                        data={this.state.rowData}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhuma solicitação encontrada
                            </div></CardBody></Card>
                        }
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <ModalAutorizacao 
                    visible={this.state.visible_tag}
                    onClose={() => this.setState({visible_tag: false})}
                />
            </div>
        )
    }
}

export default GestorAutorizacao;