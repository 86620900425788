import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import Moment from 'moment';
import { Button, ButtonGroup, Row } from 'reactstrap';
import { MdDelete } from 'react-icons/md';
import { HiViewGrid } from 'react-icons/hi';

const condicao_cupom = (p) => {
    var condicao_string = '';
    switch(p.condicao){
        case 'plano': {
            condicao_string = <p>{p.habilitar_ja_existente == false ? 'Somente para novos clientes' : 'Para novos e já clientes'}<br/> Cupom para plano especifico: {p.plano}</p>
            break;
        }
        case 'planos': {
            condicao_string = <p>{p.habilitar_ja_existente == false ? 'Somente para novos clientes' : 'Para novos e já clientes'}<br/> Cupom para varios plano: <ul>{p.planos.map(e => <li>{e.plano}</li>)}</ul></p>
            break;
        }
        case 'maquininha': {
            condicao_string = <p>{p.habilitar_ja_existente == false ? 'Somente para novos clientes' : 'Para novos e já clientes'}<br/> Cupom para maquininha especifica: {p.maquininha}</p>
            break;
        }
        case 'maquininhas': {
            condicao_string = <p>{p.habilitar_ja_existente == false ? 'Somente para novos clientes' : 'Para novos e já clientes'}<br/> Cupom para varias maquininhas: <ul>{p.maquininhas.map(e => <li>{e.maquininha}</li>)}</ul></p>
            break;
        }
        case 'cliente': {
            condicao_string = <p>{p.habilitar_ja_existente == false ? 'Somente para novos clientes' : 'Para novos e já clientes'}<br/> Cupom para cliente especifico com o documento: {p.documento}</p>
            break;
        }
    }

    return condicao_string;
}
export const columns = (bloquear_ativar_cupom, onShowDelete, customSort, setCupomUso) => [
  {
    name: '',
    selector: 'buttons',
    width: '70px',
    cell: row => <Row>
          <ButtonGroup>
          {onShowDelete ? 
              <Button
                outline
                style={{ padding: 2, border: 0, fontWeight: '700'}}
                color="danger"
                onClick={() => onShowDelete(row.id)}
              >
                <MdDelete style={{ padding: '2px' }} />
              </Button> 
            : 
            <></>}
            <Button
              outline
              style={{ padding: 2, border: 0, fontWeight: '700'}}
              color="primary"
              onClick={() => setCupomUso(row.hash)}
            >
              <HiViewGrid style={{ padding: '2px' }} />
            </Button> 
          </ButtonGroup>
    </Row>
  },
  
    {
      name: "Data de criação",
      selector: "created_at",
      sortable: false,
      width: '150px',
      cell: p => Moment(p.created_at).format('DD/MM/YYYY HH:mm')
    },
    {
      name: "Hash",
      selector: "hash",
      sortable: false,
      width: '150px'
    },
    {
      name: "Tipo de cupom",
      selector: "condicao",
      sortable: false,
      width: '23%',
      cell: p => <p style={{marginTop: 10}}>{condicao_cupom(p)}</p>
    },
    {
      name: "Valor",
      selector: "valor",
      sortable: false,
      cell: p => <>{p.valor_tipo == 'moeda' ? 'R$ ' + parseFloat(p.valor).toFixed(2).replace('.', ',') : p.valor + '%'} de desconto</>
    }, 
    {
      name: "Validade",
      selector: "validade",
      sortable: false,
      cell: p => p.validate !== null ? Moment(p.validate).format('DD/MM/YYYY') : 'Indefinido'
    },
    {
      name: "Usos do cupom",
      selector: "usos_cupom_count",
      sortable: true,
      cell: p => p.usos_cupom_count
    },
    {
      name: "",
      selector: "status",
      sortable: false,
      cell: p => p.status === 1 ? <b style={{color: 'green'}}>Cupom ativo</b> : <b style={{color: 'red'}}>Cupom bloqueado</b>
    },
    {
      name: "",
      selector: "status",
      sortable: false,
      width: '170px',
      cell: p => p.status === 0 ? <Button color="success" onClick={() => bloquear_ativar_cupom(p.hash, 1)}>Ativar cupom</Button> : <Button color="danger" onClick={() => bloquear_ativar_cupom(p.hash, 0)}>Bloquear</Button>
    },
];

export const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "cupons/list?search="+state.search+"&page="+state.activePage;

    /* var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&documento="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&documento="+document;
        }
    } */
    if(state.column.length > 1){
      url += "&column="+state.column+"&direction="+state.sortDirection;
    }

    if(state.filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    
    var url = ENDPOINT + "cupons/list?search="+search+"&page="+page;

    /* var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&documento="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&documento="+document;
        }
    } */
      if(state.column.length > 1){
        url += "&column="+state.column+"&direction="+state.sortDirection;
      }


    if(filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getUsosCuom = (hash, callback) => {
    var url = ENDPOINT + "cupons/consultar_usos_do_cupom/"+hash;

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callback(hash, response.data)
    });
}
