import React, {Component} from 'react';
import { Card, Col, Row, CardBody, CardHeader, FormGroup, Label } from "reactstrap";
import { Doughnut, HorizontalBar } from 'react-chartjs-2'

import { MdSearch } from 'react-icons/md';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { ENDPOINT } from '../../auth/endpoint';
import axios from 'axios';
import { getPermissoes, getToken } from '../../auth/set_services';
import { Form } from 'react-bootstrap';
import Select from "react-select";
import { InputText } from "../../components/InputText/InputText";
import { ButtonDefault } from '../../components/Button/ButtonDefault';

const decimal_remove = {
  scales: {
      yAxes: [{
          ticks: {
              beginAtZero: true,
              userCallback: function(label, index, labels) {
                  // when the floored value is the same as the value we have a whole number
                  if (Math.floor(label) === label) {
                      return label;
                  }

              },
          }
      }],
  },
}


const avaliacao_list = [
  { value: '1', label: '1 Estrela' },
  { value: '2', label: '2 Estrelas' },
  { value: '3', label: '3 Estrelas' },
  { value: '4', label: '4 Estrelas' },
  { value: '5', label: '5 Estrelas' },
  { value: '6', label: 'Não avaliado' },
];
const getOperadores = (callback) => {
  var url = ENDPOINT + "chat-atendimento/consultar-operadores";
  
  axios.get(url, {
      headers: {
      Authorization: 'Bearer ' + getToken()
      }
  }).then((response) => {
      var data = response.data;
      var operadores_list = [];
      data.forEach((p, i) => {
          p['value'] = p.id;
          p['label'] = p.name;
          operadores_list.push(p);
      });

      callback(operadores_list)
  });
}

class Dashboard extends Component{
    constructor(props){
        super(props);
        this.state = {
          departamento: null,
          date_from: null,
          date_to: null,
          avaliacao: null,
          operador: null,
          cnpj: '',
          cpf: '',
          operadores_list: [],
          list_departamentos: [],
          protocolo_atendimento: '',
          departamento_atendendo: {labels: [], datasets: [] },
          departamento_perdido: {labels: [], datasets: [] },
          status_de_chat: {labels: [], datasets: [] },
          avaliacoes: {labels: [], datasets: [] }
        }
    }
    componentDidMount(){
      this.getStatistics();
      getOperadores(this.callbackOperadores.bind(this));
      this.getDepartamentos();
    }

    callbackOperadores = (operadores_list) => {
        this.setState({operadores_list});
    }

    getDepartamentos = () => {
        var url = ENDPOINT+"departamento/list";
    
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            var list_departamentos = [];
            data.forEach((p, i) => {
                p['value'] = p.flag;
                p['label'] = p.nome;
                p['status'] = p.admins_online_count;
                list_departamentos.push(p);
            })
            this.setState({list_departamentos});
        })
        .catch((error) => {

        });
    }
    getStatistics() {
        var url = ENDPOINT + "chat-atendimento/relarios-chat?search=";

        if(this.state.departamento !== null){
            url += "&departamento="+this.state.departamento.value
        }

        if(this.state.date_from !== null){
            url += "&date_from="+this.state.date_from
        }

        if(this.state.date_to !== null){
            url += "&date_to="+this.state.date_to
        }
        
        if(this.state.protocolo_atendimento.length > 0){
            url += "&protocolo_atendimento="+this.state.protocolo_atendimento
        }
        
        if(this.state.operador !== null){
            url += "&operador="+this.state.operador.value
        }

        if(this.state.avaliacao !== null){
            url += "&avaliacao="+this.state.avaliacao.value
        }

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        }).then((response) => {
            console.log(response)
            this.setState({
                departamento_atendendo: response.data.data.departamento_atendendo,
                status_de_chat: response.data.data.status_de_chat,
                avaliacoes: response.data.data.avaliacoes,
                departamento_perdido: response.data.data.departamento_perdido,
            });
        })
    }

    data_departamento_atendendo = () => {
        return {
            labels: this.state.departamento_atendendo.labels,
            datasets: [
                {
                    label: 'Chats atendidos por Departamento',
                    data: this.state.departamento_atendendo.datasets,
                    fill: false,
                    backgroundColor: 'rgb(111, 66, 193)',
                    borderColor:  'rgba(111, 66, 193, 0.4)',
                },
            ],

        }
    }
    data_departamento_perdidos = () => {
        return {
            labels: this.state.departamento_perdido.labels,
            datasets: [
                {
                    label: 'Chats atendidos por Departamento',
                    data: this.state.departamento_perdido.datasets,
                    fill: false,
                    backgroundColor: 'red',
                    borderColor:  'red',
                },
            ],

        }
    }

 /*    data_status_chat = () => {
        return {
            labels: this.state.status_de_chat.labels,
            datasets: [
                {
                    label: 'Status de Chat',
                    data: this.state.status_de_chat.datasets,
                    fill: false,
                    backgroundColor:'rgb(15, 154, 73)',
                    borderColor: 'rgb(15, 154, 73, 0.4)',
                },
            ],

        }
    }
 */
    data_status_chat = () => {
        return {
          labels: this.state.status_de_chat.labels,
          datasets: [
            {
              label: 'Status de Chat',
              data: this.state.status_de_chat.datasets,
              fill: false,
              backgroundColor: [ 
                  'rgb(66, 149, 193)','green', 'rgb(111, 66, 193)', 'rgb(193, 66, 66)',
              ]
            },
          ],
    
        }
      }

      data_avaliações = () => {
          return {
            labels: this.state.avaliacoes.labels,
            datasets: [
              {
                label: 'Status de Chat',
                data: this.state.avaliacoes.datasets,
                fill: false,
                backgroundColor: [ 
                     'green', 'rgb(66, 149, 193)', 'rgb(191, 133, 25)', 'rgb(191, 86, 25)', 'rgb(191, 28, 25)', 'rgb(59, 59, 59)'
                ]
              },
            ],
      
          }
        }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }

    handleSelectChange = (name, value) => {
        var state = this.state;
        state[name] = value;
        this.setState({ ...state });
    };
    render(){
        return(
            <div>
                <h3>Dashboard</h3>
                <hr/>
                <div>
                    <Card hidden>
                        <CardBody >
                            <Form>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Departamento (Chat):</Label>
                                            <Select
                                                className="react-select"
                                                options={this.state.list_departamentos}
                                                placeholder="Departamento"
                                                isSearchable={false}
                                                isClearable
                                                value={this.state.departamento}
                                                onChange={(value) => this.handleSelectChange('departamento', value)}
                                                theme={theme => ({
                                                    ...theme,
                                                    borderRadius: 0
                                                })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label>Avaliação:</Label>
                                            <Select
                                                className="react-select"
                                                options={avaliacao_list}
                                                placeholder="Avaliação"
                                                isSearchable={false}
                                                isClearable
                                                value={this.state.avaliacao}
                                                onChange={(value) => this.handleSelectChange('avaliacao', value)}
                                                theme={theme => ({
                                                    ...theme,
                                                    borderRadius: 0
                                                })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label>Operador:</Label>
                                            <Select
                                                className="react-select"
                                                options={this.state.operadores_list}
                                                placeholder="Operador"
                                                isSearchable={false}
                                                isClearable
                                                value={this.state.operador}
                                                onChange={(value) => this.handleSelectChange('operador', value)}
                                                theme={theme => ({
                                                    ...theme,
                                                    borderRadius: 0
                                                })}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Protocolo de Atendimento:</Label>
                                            <InputText 
                                                name="protocolo_atendimento"
                                                placeholder="Protocolo de Atendimento"
                                                value={this.state.protocolo_atendimento}
                                                type="text"
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label>Data Inicial:</Label>
                                            <InputText 
                                                name="date_from"
                                                value={this.state.date_from}
                                                type="date"
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label>Data Final:</Label>
                                            <InputText 
                                                name="date_to"
                                                value={this.state.date_to}
                                                type="date"
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                            <ButtonDefault onClick={() => this.getStatistics()} style={{float: 'right'}}><MdSearch />Buscar</ButtonDefault>
                        </CardBody>
                    </Card>
                    <br/>
                    <Row>
                        <Col md={6}>
                            <Card style={{marginTop: 15}}>
                                <CardHeader>Status de Chat</CardHeader>
                                <CardBody>
                                    <Doughnut
                                        //options={decimal_remove}
                                        data={this.data_status_chat}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card style={{marginTop: 15}}>
                                <CardHeader>Avaliações</CardHeader>
                                <CardBody>
                                    <Doughnut
                                        //options={decimal_remove}
                                        data={this.data_avaliações}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={6}>
                            <Card style={{marginTop: 15}}>
                                <CardHeader>Chats sendo atendidos por Departamento</CardHeader>
                                <CardBody>
                                    <HorizontalBar
                                        options={decimal_remove}
                                        data={this.data_departamento_atendendo}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card style={{marginTop: 15}}>
                                <CardHeader>Chats perdidos por Departamento</CardHeader>
                                <CardBody>
                                    <HorizontalBar
                                        options={decimal_remove}
                                        data={this.data_departamento_perdidos}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                </div>
            </div>
        )
    }
}

export default Dashboard;