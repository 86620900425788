import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { Loader } from "../../../components/Loaders/Loader";
import styled from "styled-components";
import { Button } from "reactstrap";
import { useLocation } from "react-router-dom";
import { formatDate } from "../utils";
import NumberFormat from "react-number-format";
import { useApiAqmed } from "../../../hooks/useApi";
import { ModalAlterCompany } from "../../../components/Modals/AQMed/ModalAlterCompany";

const Board = styled.div`
  background-color: #f5f5f5;
  margin-top: 30px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h2 {
    margin-bottom: 10px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin-bottom: 5px;
  }
`;

export function ViewCompany() {
  const url = useLocation().pathname;
  const urlArray = url.split("/");
  const id = urlArray[urlArray.length - 1];

  const [isFetching, setIsFetching] = useState(true);
  const [data, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { apiAqmed } = useApiAqmed();
  const onClose = () => setIsOpen(false);
  useEffect(() => {
    const token = Cookies.get("aqmedtoken");
    console.log("token = ", token);
    apiAqmed
      .get(`/company/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data?.data);
        const company = res?.data?.data?.company;
        setData(company);
      })
      .catch((e) => {
        alert("Erro ao buscar dados");
        console.log(e);
      })
      .finally(() => setIsFetching(false));
  }, []);
  if (isFetching) {
    return <Loader />;
  } else {
    return (
      <>
        {Object.keys(data).length > 0 ? (
          <div>
            <h3>Dados da Empresa</h3>
            <hr />
            <Board>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4>
                  <strong>{data?.fantasy_name}</strong>
                </h4>
                <Button
                  style={{ justifyContent: "center" }}
                  color="info"
                  onClick={() => setIsOpen(true)}
                >
                  Alterar Empresa
                </Button>
              </div>
              <ul>
                <li>
                  <strong>Razão Social: </strong>
                  {data?.social_reason}
                </li>
                <li>
                  {data?.document && (
                    <div>
                      <strong>Documento: </strong>
                      <NumberFormat
                        displayType={"text"}
                        value={data?.document}
                        format={
                          data?.document.replace(/([^\d])+/gim, "").length > 11
                            ? "##.###.###/####-##"
                            : "###.###.###-##"
                        }
                      />
                    </div>
                  )}
                </li>
                <li>
                  <strong>Atividade: </strong>
                  {data?.industry}
                </li>
                <li>
                  <strong>Colaboradores: </strong>
                  {data?.number_collaborators}
                </li>
                <li>
                  <strong>Data de Cadastro no Sistema: </strong>
                  {formatDate(data?.created_at)}
                </li>
              </ul>
            </Board>
          </div>
        ) : (
          <div>Dados não Encontrados</div>
        )}
        <ModalAlterCompany
          isOpen={isOpen}
          onClose={onClose}
          size={"lg"}
          config={{ userId: data?.public_id, data: data }}
        />
      </>
    );
  }
}
