import axios from 'axios';
import { HiViewGrid } from 'react-icons/hi';
import ReactTooltip from 'react-tooltip';
import { Button, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { flag_origem, list_status } from '../../../auth/listas';
import { getToken } from '../../../auth/set_services';
import StarRating from 'react-star-ratings';
import { toast } from 'react-toastify';
import { MdClear, MdTimer } from 'react-icons/md';

const getTime = (date) => {
  if(new Date(date) > new Date()){
    return <div style={{color: 'green', fontWeight: '700'}}><MdTimer /> {new Date(date).toLocaleString()}</div>
  }

  return <div style={{color: 'red', fontWeight: '700'}}><MdTimer /> {new Date(date).toLocaleString()}</div>
}
const getOrigem = (origem) => {
  return <div style={{
      padding: '10px', 
      margin: '13px 21px 13px 0px',
      border: '1px solid', 
      borderRadius: '100%', 
      color: '#212529',
      textAlign: 'center'}}
  >
    {flag_origem.map(p => {
      if(p.value === origem){
        if(p.src){
          return <p.label src={p.src} style={{width: 27}}/>;
        }
        return <p.label style={{padding: 0}}/>;
      }
      return null;
    })}
  </div>
}
export const columns = (callbackRows) => [
    {
      name: '',
      selector: 'buttons',
      width: '189px',
      cell: row => (
        <>
          {getOrigem(row.origem)}&nbsp;&nbsp;
        <Row style={{justifyContent: 'flex-start'}}>
          <Button data-tip="Detalhes do Ticket"
            outline
            style={{ padding: '3px', marginRight: '3px', border: 0, fontWeight: '700'}}
            color="dark" onClick={() => 
              {
                  window.location.href = '/ticket-detalhe/'+row.protocolo_ticket
              }
            }>
            <HiViewGrid style={{ padding: '2px', width: '23px' }} /> Detalhes&nbsp;
          </Button>
          <br/>
          <Button data-tip="Fechar Ticket"
            outline
            style={{ padding: '3px', marginRight: '3px', border: 0, fontWeight: '700'}}
            hidden={row.status === 'fechado'}
            color="danger" onClick={() => fecharTicket(row.cod_ticket, callbackRows)}>
            <MdClear style={{ padding: '2px', width: '23px' }} /> Fechar&nbsp;
          </Button>
          <ReactTooltip/>
        </Row>
      </>)
    },
    {
      name: "Aberto em:",
      selector: "validade_ticket",
      sortable: false,
      width: '190px',
      cell: p => <>{new Date(p.created_at).toLocaleString()}</>,
    },
    {
      name: "Expira em:",
      selector: "validade_ticket",
      sortable: false,
      width: '190px',
      cell: p => <>{getTime(p.validade_ticket)}</>,
    },
    {
      name: "Protocolo",
      selector: "protocolo_ticket",
      sortable: false,
      width: '160px'
    },
    {
      name: "Nome",
      selector: "nome",
      sortable: false,
      width: '12%',
      cell: p => <>{p.nome}</>,
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
      width: '130px',
      cell: p => getStatus(p.status)
    },
    {
      name: "Assunto",
      selector: "assunto",
      sortable: false,
      cell: p => <>{p.assunto}</>,
      width: '20%'
    },
    {
      name: "Departamento",
      selector: "departamento",
      sortable: false,
      width: '180px',
      cell: p => <>{p.departamento_ !== null ? p.departamento_.nome : 'Sem Informações'}</>,
    },
    {
      name: "E-mail",
      selector: "email",
      sortable: false,
      width: '16%',
      cell: p => <>{p.email}</>,
    },
    {
      name: "Documento",
      selector: "document",
      sortable: false,
      cell: p => <>{p.document}</>,
      width: '140px'
    },
    {
      name: "Funcionário Designado",
      selector: "status",
      sortable: false,
      width: '15%',
      cell: p => p.funcionario !== null ? p.funcionario.name : 'Sem funcionário atribuido'
    },
    {
        name: 'Avaliação',
        selector: 'avaliacao',
        width: '200px',
        cell: row => row.avaliacao !== null ? <><StarRating 
                            totalStars={5} 
                            starSpacing="2px"
                            starDimension='30px'
                            starRatedColor="#ccb00f"
                            rating={row.avaliacao}
                        />
                        </> : 'Não definido'
    }
];

const getStatus = (status) => {
  var is_status = status;
  list_status.forEach(p => {
    if(status === p.value){
      is_status = p.label;
    }
  });

  return is_status;
}

export const apagarRows = (tickets, callbackRows) => {
  var tickets_add = [];
  var url = ENDPOINT+"ticket-admin/apagar";
  tickets.forEach((p) => {
    tickets_add.push(p.cod_ticket);
  });

  var formData = new FormData();
  tickets_add.forEach((p, i) => {
    formData.append('tickets['+i+']', p);
  });

  axios.post(url, formData, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }
  })
  .then((response) => {
      var data = response.data;
      callbackRows(data);
      return true;
  })
  .catch((error) => {
    
    toast.error('Ocorreu um erro no servidor');
    return false;
  });
}

export const fecharMultiplosTickets = (tickets, callbackRows) => {
  var tickets_add = [];
  var url = ENDPOINT+"ticket-admin/fechar-multiplos-tickets";
  tickets.forEach((p) => {
    tickets_add.push(p.cod_ticket);
  });

  var formData = new FormData();
  tickets_add.forEach((p, i) => {
    formData.append('tickets['+i+']', p);
  });

  axios.post(url, formData, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }
  })
  .then((response) => {
      var data = response.data;
      callbackRows(data);
      return true;
  })
  .catch((error) => {
    
    toast.error('Ocorreu um erro no servidor');
    return false;
  });
}


export const fecharTicket = (cod_ticket, callbackRows) => {
  var url = ENDPOINT + "ticket-admin/fechar_ticket";
  var formData = new FormData();
  formData.append('cod_ticket', cod_ticket);

  axios.post(url, formData, {
    headers: {
        Authorization: 'Bearer '+getToken()
    }})
  .then((response) => {
      var data = response.data;
      callbackRows(data);
      return true;
  })
  .catch((error) => {
      
      toast.error('Ocorreu um erro no servidor');
      return false;
  });
}

export const mesclarRows = (tickets, callbackRows) => {
  var ticket_principal = '';
  var ticket_secundario = [];
  var url = ENDPOINT+"ticket-admin/mesclar";
  console.log(tickets)
  tickets.forEach((p, i) => {
    if(i === tickets.length - 1){
      ticket_principal = p.cod_ticket;
    }
    else{
      ticket_secundario.push(p.cod_ticket);
    }
  })

  var formData = new FormData();
  formData.append('ticket_principal', ticket_principal);
  ticket_secundario.forEach((p, i) => {
    formData.append('tickets_secundarios['+i+']', p);
  });

  axios.post(url, formData, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }
  })
  .then((response) => {
      var data = response.data;
      callbackRows(data);
      return true;
  })
  .catch((error) => {
    
    toast.error('Ocorreu um erro no servidor');
    return false;
  });
}
export const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "ticket-admin/list?search="+state.search+"&page="+state.activePage;

    if (state.protocolo_atendimento.length > 0) {
        url += '&protocolo_atendimento=' + state.protocolo_atendimento
    }

    if (state.document.length > 0) {
        url += '&document=' + state.document;
    }

    if (state.nome.length > 0) {
      url += '&nome=' + state.nome;
    }

    if (state.email.length > 0) {
      url += '&email=' + state.email;
    }
    
    if (state.assunto.length > 0) {
      url += '&assunto=' + state.assunto;
    }
    
    if (state.status !== null) {
      url += '&status=' + state.status.value;
    }

    if (state.operador !== null) {
      url += '&operador=' + state.operador.value;
    }

    if (state.filter === true) {
      url += '&date_from=' + state.date_from + '&date_to=' + state.date_to;
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    var url = ENDPOINT + "ticket-admin/list?search="+search+"&page="+page;
    
    
    if (state.protocolo_atendimento.length > 0) {
        url += '&protocolo_atendimento=' + state.protocolo_atendimento
    }

    if (state.document.length > 0) {
        url += '&document=' + state.document;
    }

    if (state.nome.length > 0) {
        url += '&nome=' + state.nome;
    }

    if (state.email.length > 0) {
        url += '&email=' + state.email;
    }
    
    if (state.assunto.length > 0) {
        url += '&assunto=' + state.assunto;
    }
    
    if (state.status !== null) {
        url += '&status=' + state.status.value;
    }

    if (state.departamento !== null) {
      url += '&departamento=' + state.departamento.value;
    }

    if (state.operador !== null) {
      url += '&operador=' + state.operador.value;
    }
    
    if (filter === true) {
      url += '&date_from=' + state.date_from + '&date_to=' + state.date_to;
    }

    console.log(url)

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}