import axios from "axios";
import React, { Component, useMemo, useState } from "react";
import { MdArrowBack, MdCheck, MdClear, MdMail, MdWarning, MdAttachFile } from "react-icons/md";
import { BsLock, BsUnlock } from 'react-icons/bs';
import { IoReceiptOutline } from 'react-icons/io5';
import { Button, ButtonGroup, Card, CardBody, CardFooter, Col, Collapse, Form, FormFeedback, FormGroup, Input, Row } from "reactstrap";
import Dropzone from 'react-dropzone';
import styled from "styled-components";
import { ENDPOINT } from "../../auth/endpoint";
import { getPermissoes, getToken, getUser } from "../../auth/set_services";
import Select from "react-select";
import { list_prevencao_status, list_status_pagamentos } from "../../auth/listas";
import { InputMasked } from '../../components/InputText/InputMasked';
import { InputText } from "../../components/InputText/InputText";
import { toast, useToastContainer } from 'react-toastify';
import PropagateLoader from "react-spinners/PropagateLoader";
import ClipLoader from 'react-spinners/ClipLoader'
import moment from "moment";
import NumberFormat from "react-number-format";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import sanitizeHtml from 'sanitize-html';
import ReactTooltip from "react-tooltip";
import GoogleMapReact from 'google-map-react';
import DataTable from 'react-data-table-component';
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { FaAngleUp, FaClosedCaptioning, FaEye, FaFilePdf, FaMapMarkerAlt, FaPen, FaPlus, FaRegWindowClose, FaSave, FaTrash, FaUps } from "react-icons/fa";
import Pusher from 'pusher-js';
import { ButtonRed } from "../../components/ButtonStylish/ButtonRed";
import { DropdownIcon } from "../../components/DropdownIcon/DropdownIcon";
import SendEmailByCard from "../../components/Modals/CreditCard/SendEmailByCard";
import { MessageNoTable } from "../MessageTable/MessageNoTable";
import PDFViewer from 'pdf-viewer-reactjs';
import ConfirmarRemove from "../../components/Modals/Juridico/ConfirmarRemove";

var CurrencyInput = require('react-currency-masked-input');

const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
    .reclamado {
        display: none;
    }
    .row {
        margin: 10px 0px;
    }
    .btn-save {
        float: right;
    }
    .btn-nota {
        margin-top: 10px;
    }
    .file-iframe {
        width: 100%;
        height: 800px; 
    }
    .btn-arquivo {
        margin-right: 10px;
        color: #fff;
        border: 0px;
    }

    .tabela-parcelamento {
        width: 100%;
        border: 1px solid black;
    }
    
    .tabela-parcelamento th, 
    .tabela-parcelamento td {
        border: 1px solid black;
        border-collapse: collapse;
    }
    
    .tabela-parcelamento .tr-par {
        background: #eeeeee;
    }

    .tabela-parcelamento tr:hover {
        background: #cccccc;
        cursor: pointer;
    }

    input, textarea {
        text-transform: uppercase;
    }
    .form-control {
        height: 42px;
    }
    .input-currency {
        padding-left: 30px;
    }
    .currency-simbol {
        color: #999b9d;
        position: absolute;
        left: 25px;
        top: 43px;
    }
    .card {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        -webkit-border-radius: 0.25rem;
        -moz-border-radius: 0.25rem;
        
    }
    .col-cards {
        margin-bottom: 1rem;
    }
    .card h6 {
        background: #28a745;
        color: #fff;
        text-align: center;
    }
    .row-files {
        border-style: dashed;
        border-color: #38B76E;
        border-top: 0px;
        margin: 0px;
    }
    .invalid {
        border: 1px solid #ff0000;
    }
`;

const tipo_custas_options = [
    {"label": "Inicial", "value": "inicial"},
    {"label": "Citação", "value": "citacao"},
    {"label": "Apelação", "value": "apelacao"},
    {"label": "Agravo de instrumento", "value": "agravao_instrumento"},
    {"label": "Recurso Especial", "value": "recurso_especial"},
];

const estados_options = [
    {"label": "Acre", "value": "AC"},
    {"label": "Alagoas", "value": "AL"},
    {"label": "Amapá", "value": "AP"},
    {"label": "Amazonas", "value": "AM"},
    {"label": "Bahia", "value": "BA"},
    {"label": "Ceará", "value": "CE"},
    {"label": "Distrito Federal", "value": "DF"},
    {"label": "Espírito Santo", "value": "ES"},
    {"label": "Goiás", "value": "GO"},
    {"label": "Maranhão", "value": "MA"},
    {"label": "Mato Grosso", "value": "MT"},
    {"label": "Mato Grosso do Sul", "value": "MS"},
    {"label": "Minas Gerais", "value": "MG"},
    {"label": "Pará", "value": "PA"},
    {"label": "Paraíba", "value": "PB"},
    {"label": "Paraná", "value": "PR"},
    {"label": "Pernambuco", "value": "PE"},
    {"label": "Piauí", "value": "PI"},
    {"label": "Rio de Janeiro", "value": "RJ"},
    {"label": "Rio Grande do Norte", "value": "RN"},
    {"label": "Rio Grande do Sul", "value": "RS"},
    {"label": "Rondônia", "value": "RO"},
    {"label": "Roraima", "value": "RR"},
    {"label": "Santa Catarina", "value": "SC"},
    {"label": "São Paulo", "value": "SP"},
    {"label": "Sergipe", "value": "SE"},
    {"label": "Tocantins", "value": "TO"}
];

const resultadoAudienciaOptions = [
    { value: '1', label: 'Acordo com Pagamento'},
    { value: '2', label: 'Com acordo'},
    { value: '0', label: 'Sem acordo'},
];


const custasActive = [];


const getId = () => {
    return window.location.pathname.replace('/juridico-detalhes/', '')
}

const optionsYesNo = [
    { value: '1', label: 'Sim'},
    { value: '0', label: 'Não'},
];
const statusDeEnvio = [
    { value: '1', label: 'Aguardando entrega'},
    { value: '2', label: 'Recebido'},
    { value: '3', label: 'Devolvido'},
    { value: '4', label: 'Mudou-se'},
    { value: '5', label: 'Não existe'},
];

const FormasDePagamentos = [
    { value: 'ted', label: 'TED'},
    { value: 'pix', label: 'PIX'},
    { value: 'deposito_judicial', label: 'Depósito Judicial'},
];

const numeroParcelas = [
    { value: '1', label: '1x'},
    { value: '2', label: '2x'},
    { value: '3', label: '3x'},
    { value: '4', label: '4x'},
    { value: '5', label: '5x'},
    { value: '6', label: '6x'},
    { value: '7', label: '7x'},
    { value: '8', label: '8x'},
    { value: '9', label: '9x'},
    { value: '10', label: '10x'},
    { value: '11', label: '11x'},
    { value: '12', label: '12x'},
];

const DropArea = styled.div`
    border-style: dashed;
    border-color: #38B76E;
    padding: 1%;
    cursor: pointer;
    &:drag {
        opacity: 0.5;
    }
`;

const ButtonUpload = styled.button`
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0;
    padding-left: 7px;
    padding-right: 7px;
    top: -1px;
    position: relative;
`;

class ResumoProcesso extends Component {
    constructor(props){
        super(props);
        this.state = {
            show_form: false,
            loading_page: true,
            loading_button: false,
            loading_seller_data: false,
            seller_data_load: false,
            loading_btn_add_nota: false,
            loading_btn_save_form: false,
            pdf_inicial_open: false,
            pdf_ata_open: false,
            pdf_custas_open: false,
            custas_position: 1,
            pdf_documentos_processo_open: false,
            iframes_custas: [],
            iframes_documentos_processo: [],
            iframes_contestacoes_processo: [],
            is_open_vencimento_table: false,
            disable_send_button: false,
            open_iframe_custa: [],
            url_iframe_custas: [],
            url_iframe_contestacao: [],

            is_open_reclamado: false,
            is_open_reclamante: false,
            is_open_acordo_extrajudicial: false,
            visible_confirm: false,
            infos_contestacao: true,
            load_parcelas_animation: false,
            load_parc: true,
            loading_btn_save_parcelamento: false,
            is_open_nota_vencimento: [],

            seller_name: null,
            seller_company: null,
            seller_email: null,
            seller_phone: null,
            callback_modal: null,

            id_processo: null,
            motivo: null,
            documento: null,
            nome_vendor: '',
            numero_processo: null,
            vara: null,
            estado: null,
            tipo_acao: null,
            empresa_credora: null,
            reclamante: null,
            reclamado: null,
            advogado_reclamante: null,
            advogado_contato: null,
            advogado_aqbank: null,
            forma_citacao: null,
            numero_ar: null,
            data_citacao: null,
            data_publicacao_edital: null,
            pedido_reclamante: null,
            alegacao_departamento: null,
            pedido_procedente: null,
            departamento: null,
            prazo_defesa: null,
            audiencia_marcada: null,
            negativado_serasa: null,
            baixa_serasa: null,
            audiencia_data: null,
            audiencia_tipo: null,
            audiencia_link: null,
            hora_audiencia: null,
            arquivo_inicial_processo: null,
            arquivo_ata_audiencia: null,
            arquivo_custas_anexo: null,
            arquivo_documentos_processo: null,
            arquivo_confissao_anexo: null,
            valor_debito_original: 0.00,
            envio_notificacao: null,
            status_envio: null,
            numero_parcelas: '',
            proposta_acordo: 0.00,
            valor_debito_atualizado: 0.00,
            valor_debito_parcela: 0.00,
            valor_acao: null,
            atraso: '',
            
            file: {
                inicial_processo: [],
                ata_audiencia: [],
                custas_anexo: [],
                custas_comprovante_pagamento: [],
                confissao_anexo: [],
                documentos_processo: [],
                deposito_judicial_anexo: [],
                peca_defesa: [],
                pagamento_parcelas: [],
            },
            file_invalid: false,

            motivos_options: [
                { value: '1', label: 'Como Reclamante'},
                { value: '2', label: 'Como Reclamado'},
                { value: '3', label: 'Acordo Extrajudicial'},
            ],
            pedido_procedente_options: [
                { value: '1', label: 'Sim'},
                { value: '0', label: 'Não'},
            ],            
            audiencia_tipo_options: [
                { value: 'nenhum', label: 'Nenhum'},
                { value: 'presencial', label: 'Presencial'},
                { value: 'video', label: 'Video'},
            ],
            reclamados_options: [],
            advogado_aqbank_options: [],
            forma_citacao_options: [],
            departamento_options: [],
            audiencia_preposto_options: [],
            notas_privadas: [],
            status_processo_options: [],
            status_pagamento_options: [],
            bancos_options: [],
            list_contestacao: [],
            parcelamento: [],
            prazo_contestacao: '',
            notas_contestacao: '',
            motivo_processual: '',

            valid_input: [],

            add_custas: false,
            tipo_custas: '',
            tipo_custas_select: [],
            list_tipo_custas: [],

            custas_tipo: [],
            custas_valor: [],
            custa_vencimento: [],
            custa_vencimento_valor: null,

            showReclamado: false,

            /* notas */
            nota_mensagem: '',
            notas_index: [],

            forma_pagamento_select: [],
            numero_parcelas_select: [],
            status_envio_select: [],
            envio_notificacao_select: [],
            empresa_credora_select: [],
            reclamado_select: [],
            estado_select: [], 
            motivo_select: [],
            pedido_procedente_select: [], 
            forma_citacao_select: [], 
            advogado_aqbank_select: [],
            audiencia_tipo_select: [], 
            audiencia_marcada_select: [], 
            departamento_select: [],
            audiencia_resultado_select: [], 
            havera_acordo_select: [], 
            audiencia_preposto_select: [],
            houve_parcelamento_select: [], 
            status_pagamento_select: [], 
            status_processo_select: [],
            negativado_serasa_select: [],
            rows_vencimento: [],
            data_vencimento: [],
            
            custas_processuais: '',
            honorarios_valor: 0.00,
            valor_debito: 0.00,
            vencimento_original: '',
            honorarios_cobrados: null,
        }
          
        this.onDrop = this.onDrop.bind(this);
        this.onDropAta = this.onDropAta.bind(this);
        this.onDropCustas = this.onDropCustas.bind(this);
        this.onDropCustasComprovante = this.onDropCustasComprovante.bind(this);
        this.onDropConfissao = this.onDropConfissao.bind(this);
        this.onDropDocumentosProcesso = this.onDropDocumentosProcesso.bind(this);
        this.onDropPecaoDefesaProcesso = this.onDropPecaoDefesaProcesso.bind(this);
        this.onDropComprovantePagamento = this.onDropComprovantePagamento.bind(this);
        this.onDropPagamentoParcelas = this.onDropPagamentoParcelas.bind(this);
        this.onDropPecaoDefesaMultiProcesso = this.onDropPecaoDefesaMultiProcesso.bind(this);
        this.onDropDepositoJudicialAnexo = this.onDropDepositoJudicialAnexo.bind(this);

        this.onRemoveContestacaoFiles = this.onRemoveContestacaoFiles.bind(this);


        this.inputFile = React.createRef();
        this.inputFileAta = React.createRef();
        this.inputFileDocumentosProcesso = React.createRef();
        this.inputFilePecaDefesaProcesso = React.createRef();
        
        this.inputFilePecaDefesaMultiProcesso = [];
        this.inputFileInserCusta = React.createRef();
        this.inputFileInserCustaPagamento = React.createRef();
        this.inputFileCustas = [];
        this.inputFileComprovantePagamento = [];
        this.inputFileConfissao = React.createRef();
        this.inputFileDepositoJudicialAnexo = React.createRef();

        this.refValorAcao = React.createRef();
        this.refValorCustaProcessual = React.createRef();

    }
    handleCreateRef = () => {

    }

    handleFileChange = (event) => {
        const files = event.target.files;

        var state = this.state;
        Array.from(files).forEach(file_current => {
            state.file.inicial_processo.push(file_current);
        });
        this.setState({
            ...state
        })
    }
    handleFileAtaChange = (event) => {
        const files = event.target.files;

        var state = this.state;
        Array.from(files).forEach(file_current => {
            state.file.ata_audiencia.push(file_current);
        });
        this.setState({
            ...state
        })
    }        
    handleFileDocumentosProcessoChange = (event) => {
        const files = event.target.files;

        var state = this.state;
        Array.from(files).forEach(file_current => {
            state.file.documentos_processo.push(file_current);
        });
        this.setState({
            ...state
        })
    }   
    handleFilePecaDefesaProcessoChange = (event) => {
        const files = event.target.files;

        var state = this.state;
        Array.from(files).forEach(file_current => {
            state.file.peca_defesa.push(file_current);
        });
        this.setState({
            ...state
        })
    }       
     handleFileParcelamentoComprovanteChange = (event, index) => {
        const files = event.target.files;
        
        var state = this.state;
        state.rows_vencimento[index].files = [];

        Array.from(files).forEach(file_current => {
            state.rows_vencimento[index].files.push(file_current);
        });
        this.setState({
            ...state
        })
    }    
    handleFilePecaDefesaProcessoMultiChange = (event, index) => {
        const files = event.target.files;
        var state = this.state;

        Array.from(files).forEach(file_current => {
            state.list_contestacao[index].files_contestacao.push(file_current);
        });
        this.setState({
            ...state
        })
    }

    handleFileCustasChange = (event, custas_position = 'new') => {
        const files = event.target.files;

        var state = this.state;

        if (custas_position === 'new') {
            custas_position = state.custas_position;
        }

        if(state.file.custas_anexo[custas_position] === undefined) {
            state.file.custas_anexo[custas_position] = [];
        }
        if(state.file.custas_anexo[custas_position][0] === undefined) {
            state.file.custas_anexo[custas_position][0] = [];
        }

        Array.from(files).forEach(file_current => {
            state.file.custas_anexo[custas_position][0] = file_current;
        });
        this.setState({
            ...state
        })
    }
    handleFileCustasComprovanteChange = (event, custas_position = 'new') => {
        const files = event.target.files;

        var state = this.state;

        if (custas_position === 'new') {
            custas_position = state.custas_position;
        }

        if(state.file.custas_comprovante_pagamento[custas_position] === undefined) {
            state.file.custas_comprovante_pagamento[custas_position] = [];
        }
        if(state.file.custas_comprovante_pagamento[custas_position][0] === undefined) {
            state.file.custas_comprovante_pagamento[custas_position][0] = [];
        }

        Array.from(files).forEach(file_current => {
            state.file.custas_comprovante_pagamento[custas_position][0] = file_current;
        });
        this.setState({
            ...state
        })
    }

    handleFileConfissaoChange = (event) => {
        const files = event.target.files;

        var state = this.state;
        Array.from(files).forEach(file_current => {
            state.file.confissao_anexo.push(file_current);
        });
        this.setState({
            ...state
        })
    }    
    handleFileDepositoJudicialAnexoChange = (event) => {
        const files = event.target.files;

        var state = this.state;
        Array.from(files).forEach(file_current => {
            state.file.deposito_judicial_anexo.push(file_current);
        });
        this.setState({
            ...state
        })
    }
    onDrop = (file) => {
        var state = this.state;
        file.forEach(file_current => {
            state.file.inicial_processo.push(file_current);
        });
        this.setState({
            ...state
        })
    }
    onDropAta = (file) => {
        var state = this.state;
        file.forEach(file_current => {
            state.file.ata_audiencia.push(file_current);
        });
        this.setState({
            ...state
        })
    }    
    onDropCustas = (file) => {
        var state = this.state;
        
        if (state.file.custas_anexo[state.custas_position] === undefined) {
            state.file.custas_anexo[state.custas_position] = [];
        }
        if (state.file.custas_anexo[state.custas_position][0] === undefined) {
            state.file.custas_anexo[state.custas_position][0] = [];
        }

        file.forEach(file_current => {  
            state.file.custas_anexo[state.custas_position][0] = file_current;
        });

        this.setState({
            ...state
        })
    }
    onDropCustasComprovante = (file) => {
        var state = this.state;
        
        if (state.file.custas_comprovante_pagamento[state.custas_position] === undefined) {
            state.file.custas_comprovante_pagamento[state.custas_position] = [];
        }
        if (state.file.custas_comprovante_pagamento[state.custas_position][0] === undefined) {
            state.file.custas_comprovante_pagamento[state.custas_position][0] = [];
        }

        file.forEach(file_current => {  
            state.file.custas_comprovante_pagamento[state.custas_position][0] = file_current;
        });

        this.setState({
            ...state
        })
    }

    onDropConfissao = (file) => {
        var state = this.state;
        file.forEach(file_current => {
            state.file.confissao_anexo.push(file_current);
        });
        this.setState({
            ...state
        })
    }   
    onDropDocumentosProcesso = (file) => {
        var state = this.state;
        file.forEach(file_current => {
            state.file.documentos_processo.push(file_current);
        });
        this.setState({
            ...state
        })
    }      
    onDropPecaoDefesaProcesso = (file) => {
        var state = this.state;
        file.forEach(file_current => {
            state.file.peca_defesa.push(file_current);
        });
        this.setState({
            ...state
        })
    }           
    onDropComprovantePagamento = (file, index) => {
        var state = this.state;
        file.forEach(file_current => {
            state.rows_vencimento[index].files.push(file_current);
        });
        this.setState({
            ...state
        })
    }    
    onDropPagamentoParcelas = (file) => {
        var state = this.state;
        file.forEach(file_current => {
            state.file.pagamento_parcelas.push(file_current);
        });
        this.setState({
            ...state
        })
    }       
    onDropPecaoDefesaMultiProcesso = (file, index) => {
        var state = this.state;
        file.forEach(file_current => {
            state.list_contestacao[index].files_contestacao.push(file_current);
        });
        this.setState({
            ...state
        })
    }    
    onDropDepositoJudicialAnexo = (file) => {
        var state = this.state;
        file.forEach(file_current => {
            state.file.deposito_judicial_anexo.push(file_current);
        });
        this.setState({
            ...state
        })
    }
    removeAttach = (index) => {
        var state = this.state;
        state.file.inicial_processo.splice(index, 1);
        this.setState({
            ...state
        })
    }

    removeAtaAttach = (index) => {
        var state = this.state;
        state.file.ata_audiencia.splice(index, 1);
        this.setState({
            ...state
        })
    }
    removeDocumentosProcessoAttach = (index) => {
        var state = this.state;
        state.file.documentos_processo.splice(index, 1);
        this.setState({
            ...state
        })
    }    
    removePecaDefesaAttach = (index) => {
        var state = this.state;
        state.file.peca_defesa.splice(index, 1);
        this.setState({
            ...state
        })
    }
    removeParcelamentoComprovantePagamentoAttach = (index) => {
        var state = this.state;
        state.rows_vencimento[index].files.splice(index, 1);

        this.setState({
            ...state
        })
    }

    onRemoveContestacaoFiles = () => {
        var url = ENDPOINT + "juridico/contestacao/remove";
        // var url = "http://127.0.0.1:8000/juridico/contestacao/remove";

        axios.put(url, {
            'processo_id': getId(),
            'contestacao_id': this.state.removefile.contestacao_id,
            'position': this.state.removefile.ps,
        }, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            if(response.data.success) {
                let state = this.state;

                state.list_contestacao[this.state.removefile.index].arquivos_contestacao[this.state.removefile.ps].status = 0;
                this.setState({...state})

                this.handletoastSuccessMessage('Removido com sucesso!')
            } else {
                this.handletoastMessage('Falha ao remover');
            }
        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            // if (error.response) {
            //     // The request was made and the server responded with a status code
            //     // that falls out of the range of 2xx
            //     console.log(error.response.data);
            //     // console.log(error.response.status); // status code
            //     // console.log(error.response.headers);
            //   } else if (error.request) {
            //     // The request was made but no response was received
            //     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            //     // http.ClientRequest in node.js
            //     //console.log(error.request);
            //   } else {
            //     // Something happened in setting up the request that triggered an Error
            //     console.log('Error', error.message);
            //   }
            //   // console.log(error.config);
        })

    }
    removeCustasFileAttach = (custa, index, ps) => {
        let state = this.state;

        if (window.confirm("Tem certeza que deseja remover?")) {
            if (custa.id !== undefined && custa.id !== null) {
                var url = ENDPOINT + "juridico/custas/" + getId() + "/file/" + custa.id;
                //var url = "http://127.0.0.1:8000/juridico/custas/57/file/74";
        
                let formData = new FormData();
    
                axios.delete(url, formData, {
                    headers: {
                        Authorization: 'Bearer '+getToken()
                    }})
                .then((response) => {

                    if(response.data.success) {
                        state.list_tipo_custas[index].files.splice(ps, 1)
                        this.setState({...state})
    
                        this.handletoastSuccessMessage('Removido com sucesso!')
                    } else {
                        this.handletoastMessage('Falha ao remover');
                    }
                })
                .catch((error) => {
                    this.handletoastMessage('Ocorreu um erro no servidor!');
                    // if (error.response) {
                    //     // The request was made and the server responded with a status code
                    //     // that falls out of the range of 2xx
                    //     console.log(error.response.data);
                    //     // console.log(error.response.status); // status code
                    //     // console.log(error.response.headers);
                    //   } else if (error.request) {
                    //     // The request was made but no response was received
                    //     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    //     // http.ClientRequest in node.js
                    //     //console.log(error.request);
                    //   } else {
                    //     // Something happened in setting up the request that triggered an Error
                    //     console.log('Error', error.message);
                    //   }
                    //   // console.log(error.config);
                })
            } else {
                state.list_tipo_custas[index].files.splice(ps, 1)
                this.setState({...state})
                this.handletoastSuccessMessage('Removido com sucesso!')
            }
        }
    }    
    
    removePecaDefesaMultiDelAttach = (contestacao_id, index, ps) => {
        let state = this.state;

        state.removefile = {
            contestacao_id: contestacao_id,
            index: index,
            ps: ps,
        };

        this.setState({...state})

        this.modalConfirm(true, this.onRemoveContestacaoFiles)
    }

    
    removePecaDefesaMultiAttach = (index, ps) => {
        var state = this.state;
        state.list_contestacao[index].files_contestacao.splice(ps, 1)

        this.setState({
            ...state
        })
    }
    removeCustasAttach = (custas_position, index) => {
        var state = this.state;
        state.file.custas_anexo[custas_position].splice(index, 1);

        this.setState({
            ...state
        })
    }        
    removeCustasComprovanteAttach = (custas_position, index) => {
        var state = this.state;
        state.file.custas_comprovante_pagamento[custas_position].splice(index, 1);

        this.setState({
            ...state
        })
    }    
    removeConfissaoAttach = (index) => {
        var state = this.state;
        state.file.confissao_anexo.splice(index, 1);
        this.setState({
            ...state
        })
    }    
    removeDepositoJudicialAnexoAttach = (index) => {
        var state = this.state;
        state.file.deposito_judicial_anexo.splice(index, 1);
        this.setState({
            ...state
        })
    }

    componentDidMount(){
        this.getSelectsInfos();
        this.handleLoadBancos();
        if (getId() !== 'novo') {
            this.handleLoadDataProcesso();
        } else {
        /** Load Data */
        this.setState({
            show_form: true,
            loading_page: false,
            loading_button: false,
            
            // is_open_reclamante: true,
            // motivo_select: {
            //     label: 'Como Reclamante',
            //     value: '1',
            // },
            // motivo: '1',
            // documento: '00.377.239/0001-85',
            // numero_processo: '10289798210',
            // vara: '1ª Vara',
            // estado_select: {
            //     label: 'São Paulo',
            //     value: 'SP',
            // },
            // estado: 'SP',
            // tipo_acao: 'Danos morais',
            // reclamante: 'Jose da silva',
            // reclamado: '1',
            // reclamado_select: {
            //     label: 'AQBANK',
            //     value: '1',
            // },
            // advogado_reclamante: 'Fernado dias',
            // advogado_contato: '11912341234',
            // advogado_aqbank: '1',
            // advogado_aqbank_select: {
            //     label: 'Fabio Henrique Evangelista',
            //     value: '1',
            // },
            // forma_citacao: '1',
            // forma_citacao_select: {
            //     label: 'AR Correios',
            //     value: '1',
            // },
            // data_publicacao_edital: '2022-05-10',
            // data_citacao: '2022-05-10',
            // audiencia_nova_agendada: '2022-05-31',
            // hora_citacao: '10:00',

            // pedido_reclamante: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In interdum massa orci, id tincidunt arcu ornare eget. Donec in laoreet neque, nec vulputate eros. Pellentesque id placerat ipsum',
            // alegacao_departamento: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In interdum massa orci, id tincidunt arcu ornare eget. Donec in laoreet neque, nec vulputate eros. Pellentesque id placerat ipsum',
            // pedido_procedente: 1,
            // pedido_procedente_select: {
            //     label: 'Sim',
            //     value: '1',
            // },
            // departamento: 1,
            // departamento_select: {
            //     label: 'Prevenção de pagamentos',
            //     value: '1',
            // },
            // prazo_defesa: '2022-05-31',
            // audiencia_marcada: '1',
            // audiencia_marcada_select: {
            //     label: 'Sim',
            //     value: '1',
            // },
            // audiencia_data: '2022-05-20',
            // hora_audiencia: '10:00',
            // audiencia_tipo: 'video',
            // audiencia_tipo_select: {
            //     label: 'Video',
            //     value: 'video',
            // },
            // audiencia_link: 'https://www.google.com.br/',
            // audiencia_preposto: '1',
            // audiencia_preposto_select: {
            //     label: 'Fabio Henrique Evangelista',
            //     value: '1',
            // },
            // havera_acordo: '1',
            // havera_acordo_select: {
            //     label: 'Sim',
            //     value: '1',
            // },
            // proposta_acordo: '20.000,00',
            // pagamento_acordo: '20.000,00',
            // forma_pagamento: 'Crédito',
            // audiencia_resultado: '1',
            // audiencia_resultado_select: {
            //     label: 'Com acordo',
            //     value: '1',
            // },
            // status_processo: '1',
            // status_processo_select: {
            //     label: 'Processo Arquivado',
            //     value: '6',
            // },
            // status_pagamento: '1',
            // status_pagamento_select: {
            //     label: 'Em Pagamento',
            //     value: '2',
            // },
            // houve_parcelamento: '1',
            // houve_parcelamento_select: {
            //     label: 'Sim',
            //     value: '1',
            // },
            // numero_parcelas: '10',
            // primeiro_vencimento: '2022-05-31',
        });
        }
    }

    handleLoadBancos = async () => {
        var url = ENDPOINT + "bancos_list";

        var myHeaders = new Headers();

        myHeaders.append('Authorization', 'Bearer ' + getToken());
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        await fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            if (p.data !== undefined) {

                var state = this.state;
                p.data.map((field, i) => {
                    state.bancos_options.push({
                        'label': field.Nome_Reduzido,
                        'value': field.Número_Código,
                    })
                })

                this.setState({...state})
            }
        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            this.setState({loading_seller_data: false})        
        })
    }
     
    handleLoadDataProcesso = async () => {
        var url = ENDPOINT + "juridico/processo/" + getId();
        //var url = ENDPOINT + "juridico/show_view/" + getId();
        // var url = "http://127.0.0.1:8000/juridico/processo/57";



        var myHeaders = new Headers();

        myHeaders.append('Authorization', 'Bearer ' + getToken());
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        await fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {


            if (p.success === true) {
                this.setState({notas_privadas: p.data.notas})
                this.setState({id_processo: getId()})

                Object.entries(p.data).forEach(([field, val]) => {
                    if (val !== null && val !== 'null') {
                        this.setState({[field]: val})

                        if (field == 'documento') {
                            this.handleLoadDocument( val );
                        }
                        if (field == 'motivo') {
                            this.state.motivos_options.map((p, k) => {
                                if (val == p.value) {
                                    this.setState({motivo: val})
                                    this.setState({motivo_select: p})


                                    if (val == 2) {
                                        this.setState({is_open_acordo_extrajudicial: false})
                                        this.setState({is_open_reclamado: true})
                                        this.setState({is_open_reclamante: false})
                                    } else if (val == 1) {
                                        this.setState({is_open_acordo_extrajudicial: false})
                                        this.setState({is_open_reclamado: false})
                                        this.setState({is_open_reclamante: true}) 
                                    } else if (val == 3) {
                                        this.setState({is_open_acordo_extrajudicial: true})
                                        this.setState({is_open_reclamado: false})
                                        this.setState({is_open_reclamante: false}) 
                                    }
                                }
                            })
                        }

                        if (field == 'data_citacao' 
                            || field == 'data_publicacao_edital'
                            || field == 'prazo_defesa'
                            || field == 'audiencia_nova_agendada'
                            || field == 'baixa_serasa'
                            || field == 'audiencia_data'
                            || field == 'primeiro_vencimento'
                            || field == 'vencimento'
                            || field == 'data_postagem'
                            || field == 'prazo_notificacao'
                            ) {

                            if (val == '1969-12-31') {
                                this.setState({[field]: ''}) 
                            }

                        }
                    }
                })

                Object.entries(this.state.motivos_options).forEach(([field, val]) => {
                    if (val.value == p.data.motivo) {
                        this.setState({motivo_select: val})
                    }
                }) 

                Object.entries(estados_options).forEach(([field, val]) => {
                    if (val.value == p.data.estado) {
                        this.setState({estado_select: val})
                    }
                }) 

                Object.entries(this.state.reclamados_options).forEach(([field, val]) => {
                    if (val.value == p.data.reclamado) {
                        this.setState({reclamado_select: val})
                    }
                }) 

                Object.entries(this.state.advogado_aqbank_options).forEach(([field, val]) => {
                    if (val.value == p.data.advogado_aqbank) {
                        this.setState({advogado_aqbank_select: val})
                    }
                })       

                Object.entries(this.state.forma_citacao_options).forEach(([field, val]) => {
                    if (val.value == p.data.forma_citacao) {
                        this.setState({forma_citacao_select: val})
                    }
                }) 

                Object.entries(this.state.pedido_procedente_options).forEach(([field, val]) => {
                    if (val.value == p.data.pedido_procedente) {
                        this.setState({pedido_procedente_select: val})
                    }
                }) 

                Object.entries(this.state.departamento_options).forEach(([field, val]) => {
                    if (val.value == p.data.departamento) {
                        this.setState({departamento_select: val})
                    }
                }) 

                Object.entries(optionsYesNo).forEach(([field, val]) => {
                    if (val.value == p.data.audiencia_marcada) {
                        this.setState({audiencia_marcada_select: val})
                    }
                }) 

                Object.entries(this.state.audiencia_tipo_options).forEach(([field, val]) => {
                    if (val.value == p.data.audiencia_tipo) {
                        this.setState({audiencia_tipo: p.data.audiencia_tipo})
                        this.setState({audiencia_tipo_select: val})
                    }
                }) 

                Object.entries(this.state.audiencia_preposto_options).forEach(([field, val]) => {
                    if (val.value == p.data.audiencia_preposto) {
                        this.setState({audiencia_preposto_select: val})
                    }
                }) 

                Object.entries(optionsYesNo).forEach(([field, val]) => {
                    if (val.value == p.data.havera_acordo) {
                        this.setState({havera_acordo: p.data.havera_acordo})
                        this.setState({havera_acordo_select: val})
                    }
                    if (val.value == p.data.negativado_serasa) {
                        this.setState({negativado_serasa: p.data.negativado_serasa})
                        this.setState({negativado_serasa_select: val})
                    }
                })

                Object.entries(resultadoAudienciaOptions).forEach(([field, val]) => {
                    if (val.value == p.data.audiencia_resultado) {
                        this.setState({audiencia_resultado_select: val})
                    }
                }) 

                Object.entries(this.state.status_processo_options).forEach(([field, val]) => {
                    if (val.value == p.data.status_processo) {
                        this.setState({status_processo_select: val})
                    }
                }) 

                Object.entries(FormasDePagamentos).forEach(([field, val]) => {
                    if (val.value == p.data.forma_pagamento) {
                        this.setState({forma_pagamento_select: val})
                        this.setState({forma_pagamento: val.value})
                    }
                }) 

                Object.entries(this.state.status_pagamento_options).forEach(([field, val]) => {
                    if (val.value == p.data.status_pagamento) {
                        this.setState({status_pagamento_select: val})
                    }
                }) 

                Object.entries(optionsYesNo).forEach(([field, val]) => {
                    if (val.value == p.data.houve_parcelamento) {
                        this.setState({houve_parcelamento_select: val})
                    }
                }) 

                Object.entries(this.state.bancos_options).forEach(([field, val]) => {
                    if (val.value == p.data.conta_banco) {
                        this.setState({conta_banco_select: val})
                        this.setState({conta_banco: val.value})
                    }
                }) 


                let state = this.state;
                if (p.data.custas_processuais_valores !== undefined) {
                    let custasValores = [];

                    if (p.data.custas_processuais_valores !== undefined) {

                        state.custas_position = 0;
                        Object.entries(p.data.custas_processuais_valores).forEach(([edit, custa]) => {

                            if (custa.valor !== undefined) {
                                custasValores.push({
                                    'id_custa': edit,
                                    'edit': false,
                                    'custas_valor': custa.valor,
                                    'custas_tipo': custa.tipo,
                                    'custas_vencimento': custa.vencimento,
                                    'custa_data_pagamento': custa.data_pagamento,
                                    'custa_codigo_barras': custa.codigo_barras,
                                    'files': custa.files,
                                    'custas_select': {
                                        'label': custa.tipo.replace('_', ' '),
                                        'value': custa.tipo
                                    }
                                })

                                state.custas_position = state.custas_position + 1;
                            }
                        })
                    }

                    this.setState({...state})  

                    Object.entries(optionsYesNo).forEach(([p, op]) => {
                        if (this.state.honorarios_cobrados == op.value) {
                            this.setState({
                                honorarios_cobrados_select: op,
                            })
                        }
                    })

                    this.setState({list_tipo_custas: custasValores})
                    this.handleLoadIframe()
                    this.handleLoadContestacoesFiles()
                }


                if (p.data.contestacoes !== undefined) {
                    let state = this.state;
                    p.data.contestacoes.map((contestacao, i) => {
                        state.list_contestacao.push({
                                'id': contestacao.id,
                                'prazo_contestacao': contestacao.prazo,
                                'notas_contestacao': '',
                                'notas_salvas': contestacao.notas,
                                'files_contestacao': [],
                                'arquivos_contestacao': contestacao.arquivos,
                                'inputFilePecaDefesaMultiProcesso': React.createRef()
                            })

                        state.iframes_contestacoes_processo[i] = false;
                    })

                    this.setState({...state})
                }

                this.setState({
                    show_form: true,
                    loading_page: false,
                    loading_button: false,
                })

                this.handleInputParcelasChange()
                this.handleCalcValorAtualizado()
            }
        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            this.setState({loading_seller_data: false})
            
        })
    }

    handleLoadDocument = (doc) => {
        var documento = doc.replace(/[^0-9]/g,'');

        this.setState({loading_seller_data: true})

        var url = ENDPOINT + "user/all_aqbank?page=1&search=";
        
        if (documento.length === 11) {
            url += "&cpf="+documento;
        } else {
            url += "&cnpj="+documento;
        }

        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
    
        var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            this.setState({loading_seller_data: false})
            if (p.meta.total > 0) {
                this.setState({seller_name: p.data[0].nome})
                if (p.data[0].empresa !== null) {
                    this.setState({seller_company: p.data[0].empresa.razao_social})
                }
                this.setState({seller_email: p.data[0].email})
                this.setState({seller_phone: p.data[0].telefone})
                this.setState({seller_data_load: true})
            } else {
                // this.setState({documento: ''})
                this.handletoastMessage('Documento não localizado em nossa base!');
            }
        })
        .catch((error) => {
            this.setState({loading_seller_data: false})
            this.handletoastMessage('Ocorreu um erro no servidor!');
        })
    }
    handleChangeDocument = (e) => {
        var documento = e.target.value.replace(/[^0-9]/g,'');

        if (documento.length === 11 || documento.length === 14) {
            this.setState({loading_seller_data: true})
    
            var url = ENDPOINT + "user/all_aqbank?page=1&search=";
            
            if (documento.length === 11) {
                url += "&cpf="+documento;
            } else {
                url += "&cnpj="+documento;
            }

            var myHeaders = new Headers();
            myHeaders.append('Authorization', 'Bearer ' + getToken());
            
            var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
            };
            fetch(url, myInit)
            .then(response => response.json())
            .then((p) => {
                this.setState({loading_seller_data: false})
                if (p.meta.total > 0) {
                    this.setState({seller_name: p.data[0].nome})
                    if (p.data[0].empresa !== null) {
                        this.setState({seller_company: p.data[0].empresa.razao_social})
                    }

                    if (this.state.motivo == '2') {
                        this.setState({reclamante: p.data[0].nome})
                    }

                    this.setState({seller_email: p.data[0].email})
                    this.setState({seller_phone: p.data[0].telefone})
                    this.setState({seller_data_load: true})
                } else {
                    // this.setState({documento: ''})
                    this.handletoastMessage('Documento não localizado em nossa base!');
                }
            })
            .catch((error) => {
                this.setState({loading_seller_data: false})
                this.handletoastMessage('Ocorreu um erro no servidor!');
            })
        }
    }
    handleInputParcelasChange = () => {

        if (this.state.primeiro_vencimento === undefined || this.state.primeiro_vencimento == null) {
            return false;
        } 
        if (this.state.numero_parcelas === undefined || this.state.numero_parcelas == null) {
            return false;
        }

        this.setState({load_parcelas_animation: true})

        var vencimento = this.state.primeiro_vencimento.replace("/", "-");
        var url = ENDPOINT + "juridico/calcular_vencimento?parcelas="+this.state.numero_parcelas+"&primeiro_vencimento="+vencimento;
        //var url = "http://localhost:8000/juridico/calcular_vencimento?parcelas="+this.state.numero_parcelas+"&primeiro_vencimento="+vencimento;
        

        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
    
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            if (p.success == true) {
                // this.setState({data_vencimento: p.data, is_open_vencimento_table: true})
                this.setState({rows_vencimento: p.data, is_open_vencimento_table: true})

                let state = this.state;
                state.rows_vencimento.map((rowparcela, ch) => {
                      
                    if(state.rows_vencimento[ch].id === undefined) state.rows_vencimento[ch]['id'] = ''; 
                    if(state.rows_vencimento[ch].data_pagamento === undefined) state.rows_vencimento[ch]['data_pagamento'] = ''; 
                    if(state.rows_vencimento[ch].valor === undefined) state.rows_vencimento[ch]['valor'] = ''; 
                    if(state.rows_vencimento[ch].notas === undefined) state.rows_vencimento[ch]['notas'] = []; 
                    if(state.rows_vencimento[ch].parcelamento_notas === undefined) state.rows_vencimento[ch]['parcelamento_notas'] = null; 
                    if(state.rows_vencimento[ch].is_open_nota_vencimento === undefined) state.rows_vencimento[ch]['is_open_nota_vencimento'] = false; 
                    if(state.rows_vencimento[ch].files === undefined) state.rows_vencimento[ch]['files'] = []; 
                    if(state.rows_vencimento[ch].inputFileComprovantePagamento === undefined) state.rows_vencimento[ch]['inputFileComprovantePagamento'] = React.createRef();
                    if(state.rows_vencimento[ch].open_iframe === undefined) state.rows_vencimento[ch]['open_iframe'] = false;
                    if(state.rows_vencimento[ch].upload_comprovante === undefined) state.rows_vencimento[ch]['upload_comprovante'] = true;
                })

                this.setState({...state})

                var url = ENDPOINT + "juridico/parcelamento/" + getId();
                //var url = "http://localhost:8000/juridico/parcelamento/57";

                var myHeaders = new Headers();
                myHeaders.append('Authorization', 'Bearer ' + getToken());
            
                var myInit = {
                    method: 'GET',
                    headers: myHeaders,
                    mode: 'cors',
                    cache: 'default'
                };
                fetch(url, myInit)
                .then(response => response.json())
                .then((r) => {
                    if (r.success !== undefined && r.success == true) {
                        let state = this.state;
                        if (r.data.length > 0) {
                            r.data.map((dataparcela, i) => {
                                this.state.rows_vencimento.map((rowparcela, ch) => {
                                    if (rowparcela.parcela == dataparcela.parcela) {
                                        state.rows_vencimento[ch]['id'] = dataparcela.id;
                                        state.rows_vencimento[ch]['valor'] = parseFloat(dataparcela.valor).toFixed(2).replace('.', ',');
                                        state.rows_vencimento[ch]['data_vencimento'] = dataparcela.data_vencimento;
                                        state.rows_vencimento[ch]['data_pagamento'] = dataparcela.data_pagamento;
                                        state.rows_vencimento[ch]['parcela'] = dataparcela.parcela;
                                        state.rows_vencimento[ch]['comprovante'] = dataparcela.comprovante;
                                        state.rows_vencimento[ch]['notas'] = dataparcela.notas;
                                        state.rows_vencimento[ch]['open_iframe'] = false;
                                        state.rows_vencimento[ch]['upload_comprovante'] = (dataparcela.comprovante != '') ? false : true;
                                    }
                                })
                            })
                        }
                        state.load_parcelas_animation = false;
                        this.setState({...state})
                    }
                })
                .catch((error) => {

                    this.handletoastMessage('Ocorreu um erro no servidor!')
                    this.setState({load_parcelas_animation: false})
                })

                this.setState({load_parcelas_animation: false})

            } else {
                this.setState({is_open_vencimento_table: false}) 
            }
            this.setState({load_parcelas_animation: false})
            this.setState({load_parc: false})
        })
        .catch((error) => {
            this.setState({load_parcelas_animation: true})
            this.handletoastMessage('Ocorreu um erro no servidor!')
        })
    }
    getSelectsInfos = async () => {
        var url = ENDPOINT + "juridico/selects";

        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
      
        var myInit = {
          method: 'GET',
          headers: myHeaders,
          mode: 'cors',
          cache: 'default'
        };
        await fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            if(p.success == true){
                var data = p.data;
                var select_reclamados_options = [];
                var select_advogado_aqbank_options = [];
                var select_forma_citacao_options = [];
                var select_departamento_options = [];
                var select_audiencia_preposto_options = [];
                var selec_status_processo_options = [];
                var select_status_pagamento_options = [];

                data.reclamados.forEach((key, index) => {
                    key['value'] = key.id;
                    key['label'] = key.name;
    
                    select_reclamados_options.push(key);
                });            

                this.setState({reclamados_options: select_reclamados_options});   

                data.advogados.forEach((key, index) => {
                    key['value'] = key.id;
                    key['label'] = key.name;
    
                    select_advogado_aqbank_options.push(key);
                });            

                this.setState({advogado_aqbank_options: select_advogado_aqbank_options});
                
                data.citacoes.forEach((key, index) => {
                    key['value'] = key.id;
                    key['label'] = key.name;
    
                    select_forma_citacao_options.push(key);
                });            

                this.setState({forma_citacao_options: select_forma_citacao_options});      

                data.departamentos.forEach((key, index) => {
                    key['value'] = key.id;
                    key['label'] = key.name;
    
                    select_departamento_options.push(key);
                });            

                this.setState({departamento_options: select_departamento_options});

                data.prepostos.forEach((key, index) => {
                    key['value'] = key.id;
                    key['label'] = key.name;
    
                    select_audiencia_preposto_options.push(key);
                });            

                this.setState({audiencia_preposto_options: select_audiencia_preposto_options});

                data.status_processo.forEach((key, index) => {
                    key['value'] = key.id;
                    key['label'] = key.name;
    
                    selec_status_processo_options.push(key);
                });            

                this.setState({status_processo_options: selec_status_processo_options});

                data.status_pagamento.forEach((key, index) => {
                    key['value'] = key.id;
                    key['label'] = key.name;
    
                    select_status_pagamento_options.push(key);
                });            

                this.setState({status_pagamento_options: select_status_pagamento_options});
            }
        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
        })
    }

    handleInputCodigoBarrasChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        let codigoBarras = value.replace(/[^0-9]/g,'');
        if (codigoBarras.length === 47 || codigoBarras.length === 48) {
            var url = ENDPOINT + "juridico/boleto/"+codigoBarras;
            //var url = "http://localhost:8000/juridico/boleto/"+codigoBarras;

            var myHeaders = new Headers();
            myHeaders.append('Authorization', 'Bearer ' + getToken());
            var myInit = {
                method: 'GET',
                headers: myHeaders,
                mode: 'cors',
                cache: 'default'
            };
    
            fetch(url, myInit)
            .then(response => response.json())
            .then((p) => {
                let state = this.state;

                state.custa_vencimento_valor = p.data.vencimento;
                state.custas_processuais = p.data.valor;
                
                this.setState({...state})
            })
            .catch((error) => {
                this.handletoastMessage('Ocorreu um erro no servidor!');
                this.setState({loading_seller_data: false})        
            })
        }        
    }
    handleInputChange = (e) => {
        const { name, value } = e.target;
        
        this.setState({ [name]: value });
    }    
    handleChangeInputParcelamento = (e, parcelamento, i) => {
        const { name, value } = e.target;
        let state = this.state;

        if (state.rows_vencimento[parcelamento.parcela] === undefined) {
            state.rows_vencimento[parcelamento.parcela] = [];
        }
        if (state.rows_vencimento[parcelamento.parcela][name] === undefined) {
            state.rows_vencimento[parcelamento.parcela][name] = '';
        }        
        // if (state.parcelamento[parcelamento.parcela]['data_vencimento'] === undefined) {
        //     state.parcelamento[parcelamento.parcela]['data_vencimento'] = '';
        // }

        // state.rows_vencimento[parcelamento.parcela]['data_vencimento'] = parcelamento.vencimento;
        state.rows_vencimento[parcelamento.parcela][name] = value;

        state.rows_vencimento[i][name] = value;
        
        this.setState({...state})

    }

    handleInputVencimentoChange = (e) => {
        const { name, value } = e.target;
        
        this.setState({ [name]: value });

        let date1 = new Date(value);
        let date2 = new Date();
        let diffTime = Math.abs(date2 - date1);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

        // data de vencimento não alcançada
        if (date1 > date2) {
            this.setState({ atraso: 'Não vencido' });
        } else {
            diffDays = diffDays - 1;
            
            if (diffDays > 0) {
                // let textDays = diffDays > 1 ? diffDays + ' dias' : diffDays + ' dia';
                this.setState({ atraso: diffDays});
            } else {
                this.setState({ atraso: 'Não vencido' });
            }
        }
    }

    handleVencimentoChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }
    handleChangeMotivo = (e) => {
        this.setState({e});
        const name = e.label;
        const value= e.value;

        this.setState({ motivo: value });
        this.setState({ motivo_select: e });

        if (value == 2) {
            this.setState({is_open_acordo_extrajudicial: false})
            this.setState({is_open_reclamado: true})
            this.setState({is_open_reclamante: false})
            this.setState({valid_input: []}) 
        } else if (value == 1) {
            this.setState({is_open_acordo_extrajudicial: false})
            this.setState({is_open_reclamado: false})
            this.setState({is_open_reclamante: true}) 
            this.setState({valid_input: []}) 
        } else if (value == 3) {
            this.setState({is_open_acordo_extrajudicial: true})
            this.setState({is_open_reclamado: false})
            this.setState({is_open_reclamante: false}) 
            this.setState({valid_input: []}) 
        }
    }

    handletoastSuccessMessage = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    handletoastMessage = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    handleValidReclamante = () => {
        this.setState({loading_page: false})
        this.setState({loading_btn_save_form: false})
        let status = 0;
        let state = this.state;
        state.valid_input = [];

        if (this.state.valor_acao === undefined || 
            this.state.valor_acao === null || 
            this.state.valor_acao === false || 
            this.state.valor_acao === '') {
            
            status += 1;
            state.valid_input['valor_acao'] = true;
            this.handletoastMessage('Campo valor da ação é obrigatório')
        }        
        if (this.state.valor_debito === undefined || 
            this.state.valor_debito === null || 
            this.state.valor_debito === false || 
            this.state.valor_debito === '') {
        
            status += 1;
            state.valid_input['valor_debito'] = true;
            this.handletoastMessage('Campo valor do débito é obrigatório')
        }
        if (this.state.vencimento_original === undefined || 
            this.state.vencimento_original === null || 
            this.state.vencimento_original === false || 
            this.state.vencimento_original === '') {

            status += 1;
            state.valid_input['vencimento_original'] = true;
            this.handletoastMessage('Campo vencimento original é obrigatório')
        }        
        if (this.state.valor_atualizado === undefined || 
            this.state.valor_atualizado === null || 
            this.state.valor_atualizado === false || 
            this.state.valor_atualizado === '') {

            status += 1;
            state.valid_input['valor_atualizado'] = true;
            this.handletoastMessage('Campo valor atualizado é obrigatório')
        }        
        if (this.state.honorarios_cobrados === undefined || 
            this.state.honorarios_cobrados === null || 
            this.state.honorarios_cobrados === false || 
            this.state.honorarios_cobrados === '') {
            
            status += 1;
            state.valid_input['honorarios_cobrados'] = true;
            this.handletoastMessage('Campo honorários cobrados é obrigatório')
        }        
        if (this.state.estado === undefined || 
            this.state.estado === null || 
            this.state.estado === false || 
            this.state.estado === '') {

            status += 1;
            state.valid_input['estado'] = true;
            this.handletoastMessage('Campo estado é obrigatório')
        }       
        if (this.state.tipo_acao === undefined || 
            this.state.tipo_acao === null || 
            this.state.tipo_acao === false || 
            this.state.tipo_acao === '') {

            status += 1;
            state.valid_input['tipo_acao'] = true;
            this.handletoastMessage('Campo tipo de ação é obrigatório')
        }

        // if (this.state.motivo == '1') {
        //     if (this.state.reclamado === undefined || 
        //         this.state.reclamado === null || 
        //         this.state.reclamado === false || 
        //         this.state.reclamado === '') {
    
        //         status += 1;
        //         state.valid_input['reclamante'] = true;
        //         this.handletoastMessage('Campo reclamante é obrigatório')
        //     }
        // }  
        // if (this.state.motivo == '2') {
        //     if (this.state.reclamante === undefined || 
        //         this.state.reclamante === null || 
        //         this.state.reclamante === false || 
        //         this.state.reclamante === '') {
    
        //         status += 1;
        //         state.valid_input['reclamante'] = true;
        //         this.handletoastMessage('Campo reclamante é obrigatório')
        //     }
        // }  
        
        if (this.state.advogado_aqbank === undefined || 
            this.state.advogado_aqbank === null || 
            this.state.advogado_aqbank === false || 
            this.state.advogado_aqbank === '') {

            status += 1;
            state.valid_input['advogado_aqbank'] = true;
            this.handletoastMessage('Campo Advogado AQBank é obrigatório')
        }        
        if (this.state.forma_citacao === undefined || 
            this.state.forma_citacao === null || 
            this.state.forma_citacao === false || 
            this.state.forma_citacao === '') {
            
            status += 1;
            state.valid_input['forma_citacao'] = true;
            this.handletoastMessage('Campo forma de citação é obrigatório')
        }        
        if ((this.state.file.inicial_processo === undefined || 
            this.state.file.inicial_processo.length === 0)
            && this.state.arquivo_inicial_processo == null
            ) {
            status += 1;
            state.valid_input['inicial_processo'] = true;
            this.handletoastMessage('Campo anexo da inicial do processo é obrigatório')
        }
        // if (this.state.motivo_processual === undefined || 
        //     this.state.motivo_processual === null || 
        //     this.state.motivo_processual === false || 
        //     this.state.motivo_processual === '') {

        //     status += 1;
        //     state.valid_input['motivo_processual'] = true;
        //     this.handletoastMessage('Campo motivo processual é obrigatório');
        // }         
        if (this.state.departamento === undefined || 
            this.state.departamento === null || 
            this.state.departamento === false || 
            this.state.departamento === '') {

            status += 1;
            state.valid_input['departamento'] = true;
            this.handletoastMessage('Campo departamento da demanda é obrigatório');
        }          
        if (this.state.audiencia_marcada === undefined || 
            this.state.audiencia_marcada === null || 
            this.state.audiencia_marcada === false || 
            this.state.audiencia_marcada === '') {

            status += 1;
            state.valid_input['audiencia_marcada'] = true;
            this.handletoastMessage('Campo audiência marcada é obrigatório');
        }          
        if (this.state.status_processo === undefined || 
            this.state.status_processo === null || 
            this.state.status_processo === false || 
            this.state.status_processo === '') {

            status += 1;
            state.valid_input['status_processo'] = true;
            this.handletoastMessage('Campo audiência marcada é obrigatório');
        }          
        if (this.state.negativado_serasa === undefined || 
            this.state.negativado_serasa === null || 
            this.state.negativado_serasa === false || 
            this.state.negativado_serasa === '') {

            status += 1;
            state.valid_input['negativado_serasa'] = true;
            this.handletoastMessage('Campo negativado no serasa é obrigatório');
        }  

        this.setState({...state})

        if (status > 0) {
            return false;
        } else {
            return true;
        }
    }

    handleSendFormProcesso = (ev) => {
        var url = ENDPOINT + "juridico/enviar";
        
        ev.preventDefault();
        //this.setState({disable_send_button: true})
        this.setState({loading_page: true})
        this.setState({loading_btn_save_form: true})

        if (this.state.motivo == '1' || this.state.motivo == '2') {
            // if(!this.handleValidReclamante()) {
            //     return false;
            // }
        }

   

        let formData = new FormData();

        if (getId() != 'novo') {
            this.setState({id_processo: getId()})
        } else {
            this.setState({id_processo: null})
        }

        Object.entries(this.state).forEach(([field, val]) => {
            if (field !== 'inicial_processo' && 
            field !== 'ata_audiencia' && 
            field !== 'custas_anexo' && 
            field !== 'documentos_processo' && 
            field !== 'deposito_judicial_anexo') {
                formData.append(field, val);
            }
        })

        Object.entries(this.state).forEach(([field, val]) => {
            if (field == 'list_tipo_custas'){
                Object.entries(val).forEach(([f, v]) => {
                    formData.append('custas_processuais_editar['+f+']', v.id_custa);
                    formData.append('custas_processuais_valores['+f+']', v.custas_valor);
                    formData.append('custas_processuais_select['+f+']', v.custas_select.value);
                    formData.append('custas_processuais_vencimento['+f+']', v.custas_vencimento);
                })
            }
        })

        if(this.state.file.inicial_processo.length > 0){
            this.state.file.inicial_processo.forEach((file, index) => {
                formData.append('inicial_processo', file);
            });
        }

        if(this.state.file.ata_audiencia.length > 0){
            this.state.file.ata_audiencia.forEach((file, index) => {
                formData.append('ata_audiencia', file);
            });
        }

        if(this.state.file.custas_anexo.length > 0){
            this.state.file.custas_anexo.forEach((custas, ps) => {
                formData.append('custas_anexo_positions[]', ps);

                custas.forEach((file, index) => {
                    formData.append('custas_anexo_' + ps + '[]', file);
                });
            });
        }

        if(this.state.file.custas_comprovante_pagamento.length > 0){
            this.state.file.custas_comprovante_pagamento.forEach((custas, ps) => {
                formData.append('custas_comprovantes_positions[]', ps);

                custas.forEach((file, index) => {
                    formData.append('custas_comprovante_pagamento_' + ps + '[]', file);
                });
            });
        }

        if(this.state.file.documentos_processo.length > 0){
            this.state.file.documentos_processo.forEach((file, index) => {
                formData.append('documentos_processo[]', file);
            });
        }

        if(this.state.list_contestacao.length > 0){
            this.state.list_contestacao.forEach((contestacao, index) => {
                formData.append('contestacoes['+index+'][id]', contestacao.id);
                formData.append('contestacoes['+index+'][prazo]', contestacao.prazo_contestacao);
                formData.append('contestacoes['+index+'][notas]', contestacao.notas_contestacao);

                contestacao.files_contestacao.forEach((file, ps) => {
                    formData.append('contestacoes['+index+'][files][]', file);
                })
            });
        }

        if(this.state.file.deposito_judicial_anexo.length > 0){
            this.state.file.deposito_judicial_anexo.forEach((file, index) => {
                formData.append('deposito_judicial_anexo', file);
            });
        }
        
   

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }})
        .then((response) => {
            this.setState({loading_btn_save_form: false})
            this.setState({loading_page: false})
            


            if (response.data.success == true) {
                toast.success('Salvo com sucesso!')                
                setTimeout(function(){
                    window.location = '/juridico-detalhes/'+getId();
                }, 1000)
            } else {  
                this.handletoastMessage(response.data.error)
                this.setState({disable_send_button: false})
                this.setState({loading_btn_save_form: false})
                this.setState({loading_page: false})
            }
        })
        .catch(function (error) {
            //this.setState({loading_btn_save_form: false})
            //this.setState({loading_page: false})

            //this.handletoastMessage('Ocorreu um erro no servidor!');

            // if (error.response) {
            //   // The request was made and the server responded with a status code
            //   // that falls out of the range of 2xx
            //   console.log(error.response.data);
            //   //console.log(error.response.status); // status code
            //   //console.log(error.response.headers);
            // } else if (error.request) {
            //   // The request was made but no response was received
            //   // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            //   // http.ClientRequest in node.js
            //   //console.log(error.request);
            // } else {
            //   // Something happened in setting up the request that triggered an Error
            //   console.log('Error', error.message);
            // }
            // // console.log(error.config);
        })
    }
    onData(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    deletarNota = (id) => {
        this.setState({
            loading_nota_id: id
        });
        
        // var url = ENDPOINT+"notas/delete/"+id;
        // axios.delete(url, {
        //     headers: {
        //         Authorization: 'Bearer '+getToken()
        //     }
        // })
        // .then(() => {
        //     this.getNotas(this.state.id);
        //     toast.success('Deletado com sucesso');
        //     this.setState({
        //         loading_nota_id: null
        //     });
        // })
        // .catch((error) => {
        //     var data = error.response.data;
        //     this.handletoastMessage(data.errors);
        //     this.setState({
        //         loading_nota_id: null
        //     });
        // })
    }
    hiddenDelete = (id) => {
        /* var permissao = getPermissoes().flag;
        var id_permissao = getPermissoes().id; */
        
        var permissao = getUser().access_system;
        var id_permissao = getUser().id;

        if(permissao === 'super_user'
         || permissao === 'diretoria'
         || permissao === 'supervisor_setor'){
             return true;
         }
         else if(id_permissao === id){
            return true;
         }

         return false;
    }

    handleAddNota = () => {
        var url = ENDPOINT + "juridico/nota";

        var formData = new FormData();

        formData.append('id_processo', getId());
        formData.append('nota_mensagem', this.state.nota_mensagem);

        this.setState({loading_btn_add_nota: true})

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var notas_priv = this.state.notas_privadas;
            notas_priv.push(response.data.data);

            this.setState({notas_privadas: notas_priv});
            this.setState({is_open_nota: false});
            this.setState({nota_mensagem: null});
            this.setState({loading_btn_add_nota: false})
        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
        })
    }
    handleLoadContestacoesFiles = () => {
        let state = this.state;

        state.list_contestacao.map((contestacao, i)=>{
            contestacao.arquivos_contestacao.map((file, ps)=>{
                if (file.status == 1) {
                    state.url_iframe_contestacao[i] = file.url;
                } else {
                    state.url_iframe_contestacao[i] = '';
                } 
            })
        })

        this.setState({...state})

    } 
    handleLoadIframe = () => {
        let state = this.state;

        state.list_tipo_custas.map((custa, i)=>{   
            state.open_iframe_custa[i] = false;
            // if(custa.files !== undefined) {
            //     custa.files.map((url, ps)=>{
            //         this.setState({url_iframe_custas: url})
            //         //iframes[i][ps] = false;
            //     })
            // }
        })

        this.setState({...state})
    }
    handleLoadIframeDocumentos = () => {

        let iframes = [];
        this.state.iframes_documentos_processo.map((url, i)=>{   
            iframes[i] = false;
        })

        this.setState({iframes_documentos_processo: iframes})
    }

    handleAddCustas = (position) => {

    }

    handleRemoveCustas = (custa, position) => {
        if (window.confirm("Tem certeza que deseja remover?")) {

            if (custa.id_custa !== undefined && custa.id_custa != null) {
                var url = ENDPOINT + "juridico/custas/" + getId() + "/remove/" + custa.id_custa;
                //var url = "http://127.0.0.1:8000/juridico/custas/57/remove/74";
                
                let formData = new FormData();

                // this.state.file.custas_anexo
                axios.delete(url, formData, {
                    headers: {
                        Authorization: 'Bearer '+getToken()
                    }})
                .then((response) => {
                    if (response.data.success !== undefined && response.data.success == true) {
                        toast.success('Custa removida com sucesso!')
                    } else {
                        toast.success('Falha ao removercusta ' + response.data.error)
                    }
                })
                .catch((error) => {
                    this.handletoastMessage('Ocorreu um erro no servidor!');
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        // console.log(error.response.status); // status code
                        // console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        //console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    // console.log(error.config);
                })

            }

            let state = this.state;
            state.list_tipo_custas.splice(position, 1);
    
            this.setState({...state})
        }

    }

    handleNewCustaSend = (newCusta, position) => {
        var url = ENDPOINT + "juridico/custas/add";
        // var url = "http://127.0.0.1:8000/juridico/custas/add";

        this.setState({loading_custas_processual: !this.state.loading_custas_processual})

        let formData = new FormData();

        formData.append('processo_id', getId());
        formData.append('valor', newCusta.custas_valor);
        formData.append('tipo', newCusta.custas_select.value);
        formData.append('vencimento', newCusta.custas_vencimento);
        formData.append('codigo_barras', newCusta.custa_codigo_barras);
        formData.append('data_pagamento', newCusta.custa_data_pagamento);
        
        if (this.state.file.custas_anexo[position] !== undefined && this.state.file.custas_anexo[position].length > 0) {
            formData.append('arquivo', this.state.file.custas_anexo[position][0]);
        }        
        if (this.state.file.custas_comprovante_pagamento[position] !== undefined && this.state.file.custas_comprovante_pagamento[position].length > 0) {
            formData.append('comprovante', this.state.file.custas_comprovante_pagamento[position][0]);
        }

        // this.state.file.custas_anexo
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {

            if(response.data.success) {
                var url = ENDPOINT + "juridico/custas/"+getId();
                //var url = "http://127.0.0.1:8000/juridico/custas/57";

                this.setState({add_custas: !this.state.add_custas})

        
                // this.state.file.custas_anexo
                axios.get(url, {'custas':true}, {
                    headers: {
                        Authorization: 'Bearer '+getToken()
                    }})
                .then((p) => {

                    let state = this.state;
                    if (p.data.data.custas_processuais_valores !== undefined) {
                        let custasValores = [];
    
                        if (p.data.data.custas_processuais_valores !== undefined) {
    
                            state.custas_position = 0;
                            Object.entries(p.data.data.custas_processuais_valores).forEach(([edit, custa]) => {
    
                                if (custa.valor !== undefined) {
                                    custasValores.push({
                                        'id_custa': edit,
                                        'edit': false,
                                        'custas_valor': custa.valor,
                                        'custas_tipo': custa.tipo,
                                        'custas_vencimento': custa.vencimento,
                                        'custa_data_pagamento': custa.data_pagamento,
                                        'custa_codigo_barras': custa.codigo_barras,
                                        'files': custa.files,
                                        'custas_select': {
                                            'label': custa.tipo.replace('_', ' '),
                                            'value': custa.tipo
                                        }
                                    })
                                    
                                    state.custas_position = state.custas_position + 1;
                                }
                            })
                        }
        
                        if (this.state.file.custas_anexo[position] !== undefined && this.state.file.custas_anexo[position].length > 0) {
                            state.file.custas_anexo[position][0] = [];
                        } 
                        if (this.state.file.custas_comprovante_pagamento[position] !== undefined && this.state.file.custas_comprovante_pagamento[position].length > 0) {
                            state.file.custas_comprovante_pagamento[position][0] = [];
                        }

                        state.file.custas_anexo = [];
                        state.add_custas = false;
                        state.custas_processuais = 0.00;
                        state.tipo_custas_select = [];
                        state.custa_vencimento_valor = '';
                        state.custa_codigo_barras = '';
                        state.custa_data_pagamento = null;
                        state.list_tipo_custas = custasValores;

                        this.setState({...state})  
                        this.setState({list_tipo_custas: custasValores})
                        this.handleCalcEditCustasValorAtualizado(state)

                        this.handleLoadIframe()
                        this.handleLoadContestacoesFiles()
                    } else {

                        this.setState({...state})
                        this.handleCalcEditCustasValorAtualizado(state)

                    }

                    this.setState({loading_custas_processual: !this.state.loading_custas_processual})
        
                })
                .catch((er) => {
                    this.handletoastMessage('Ocorreu um erro no servidor!');
                    if (er.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(er.response.data);
                        // console.log(er.response.status); // status code
                        // console.log(er.response.headers);
                      } else if (er.request) {
                        // The request was made but no response was received
                        // `er.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        //console.log(er.request);
                      } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', er.message);
                      }
                      // console.log(er.config);
                })

                this.handletoastSuccessMessage('Adicionado com sucesso!')
            } else {
                this.handletoastMessage('Falha ao inserir');
            }
        })
        .catch((error) => {
            this.setState({loading_custas_processual: !this.state.loading_custas_processual})

            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              // console.log(error.config);
        })
    }

    handeEditCustasChenge = (custa, i) => {
        let newCusta = [];

        newCusta['id_custa'] = (custa.id_custa) ? custa.id_custa : null;
        newCusta['custas_valor'] = (custa.custas_valor) ? custa.custas_valor : null;
        newCusta['custas_select'] = (custa.custas_select) ? custa.custas_select : null;
        newCusta['custas_vencimento'] = (custa.custas_vencimento) ? custa.custas_vencimento : null;
        newCusta['custa_codigo_barras'] = (custa.custa_codigo_barras) ? custa.custa_codigo_barras : null;
        newCusta['custa_data_pagamento'] = (custa.custa_data_pagamento !== undefined) ? custa.custa_data_pagamento : null;

        if (newCusta.id_custa !== null && newCusta.id_custa !== 'null') {
            var url = ENDPOINT + "juridico/custas/change";
            // var url = "http://127.0.0.1:8000/juridico/custas/change";

            let formData = new FormData();

            formData.append('processo_id', getId());
            formData.append('custa_id', newCusta.id_custa);
            formData.append('valor', newCusta.custas_valor);
            formData.append('tipo', newCusta.custas_select.value);
            formData.append('vencimento', newCusta.custas_vencimento);
            formData.append('codigo_barras', newCusta.custa_codigo_barras);
            formData.append('data_pagamento', newCusta.custa_data_pagamento);

            // console.log(this.state.file.custas_anexo[i])
            // if(this.state.file.custas_anexo[i] !== undefined && this.state.file.custas_anexo[i].length > 0){
            //     formData.append('custas_anexo[]', this.state.file.custas_anexo[i]);
            // }

            if(this.state.file.custas_anexo[i] !== undefined && this.state.file.custas_anexo[i].length > 0){
                this.state.file.custas_anexo.forEach((custas, ps) => {
                    if (ps == i) {
                        formData.append('custas_anexo_positions[]', ps);
        
                        custas.forEach((file, index) => {
                            formData.append('custas_anexo_' + ps + '[]', file);
                        });
                    }
                });
            }

            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }})
            .then((response) => {

                if(response.data.success) {
                    this.handletoastSuccessMessage('Atualizado com sucesso!')
                } else {
                    this.handletoastMessage('Falha ao inserir');
                }
            })
            .catch((error) => {
                this.handletoastMessage('Ocorreu um erro no servidor!');
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    // console.log(error.response.status); // status code
                    // console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                // console.log(error.config);
            })
        } else {
            let state = this.state;
            state.list_tipo_custas[i] = newCusta;

            this.setState({...state})

            this.handleCalcEditCustasValorAtualizado(state)
        }
    }

    handleAddNewCusta = () => {
        let state = this.state;
        let newCusta = [];
        let position = state.custas_position;

        newCusta['id_custa'] = null;
        newCusta['custas_valor'] = (this.state.custas_processuais) ? this.state.custas_processuais : null;
        newCusta['custas_select'] = (this.state.tipo_custas_select) ? this.state.tipo_custas_select : null;
        newCusta['custas_vencimento'] = (this.state.custa_vencimento_valor) ? this.state.custa_vencimento_valor : null;
        newCusta['custa_codigo_barras'] = (this.state.custa_codigo_barras) ? this.state.custa_codigo_barras : null;
        newCusta['custa_data_pagamento'] = (this.state.custa_data_pagamento !== undefined) ? this.state.custa_data_pagamento : null;
        
        if (getId() != 'novo') {

            this.handleNewCustaSend(newCusta, position)

        } else {

            state.add_custas = false;
            state.custas_processuais = 0.00;
            state.tipo_custas_select = [];
            state.custa_vencimento_valor = '';
            state.custa_codigo_barras = '';
            state.custa_data_pagamento = null;
            state.list_tipo_custas[position] = newCusta;
            state.custas_position = state.custas_position + 1;
            
            // this.state.file.custas_anexo
            // custas_position
            this.setState({...state})
            this.handleCalcEditCustasValorAtualizado(state)
            this.setState({custas_position: state.custas_position})
        }
    }

    handleAddNewFileContestacao = () => {

        var url = ENDPOINT + "juridico/contestacao/arquivo";
        //var url = "http://127.0.0.1:8000/juridico/contestacao/arquivo";

        let formData = new FormData();

        formData.append('processo_id', getId());
        formData.append('prazo', this.state.prazo_contestacao);
        formData.append('notas', this.state.notas_contestacao);

        this.state.file.peca_defesa.forEach((file, index) => {
            formData.append('files['+index+']', file);
        });

        //  {
        //     'processo_id': getId(),
        //     'files': this.state.file.peca_defesa,
        //     'prazo': this.state.prazo_contestacao,
        //     'notas': this.state.notas_contestacao,
        // }

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            let state = this.state;


            if(response.data.success) {
                state.file.peca_defesa = [];
                state.prazo_contestacao = '';
                state.notas_contestacao = '';
                state.files_contestacao = [];
                state.loading_contestacao = !this.state.loading_contestacao;
                state.infos_contestacao = !this.state.infos_contestacao;
                this.setState({...state})
                // this.handletoastSuccessMessage('Atualizado com sucesso!')

                var url = ENDPOINT + "juridico/contestacao/listar/"+getId();
                // var url = "http://127.0.0.1:8000/juridico/contestacao/listar/"+getId();

                var myHeaders = new Headers();
        
                myHeaders.append('Authorization', 'Bearer ' + getToken());
                var myInit = {
                    method: 'GET',
                    headers: myHeaders,
                    mode: 'cors',
                    cache: 'default'
                };
        
                fetch(url, myInit)
                .then(response => response.json())
                .then((p) => {

                    if (p.data.contestacoes !== undefined) {
                        let state = this.state;

                        p.data.contestacoes.map((contestacao, i) => {
                            state.list_contestacao.push({
                                    'id': contestacao.id,
                                    'prazo_contestacao': contestacao.prazo,
                                    'notas_contestacao': '',
                                    'notas_salvas': contestacao.notas,
                                    'files_contestacao': [],
                                    'arquivos_contestacao': contestacao.arquivos,
                                    'inputFilePecaDefesaMultiProcesso': React.createRef()
                                })

                            state.iframes_contestacoes_processo[i] = false;
                        })

                        this.setState({...state})

                        this.handletoastSuccessMessage('Adicionado com sucesso!');
                    } else {

                    }
                })
                .catch((error) => {
                    this.handletoastMessage('Ocorreu um erro no servidor!');
                    this.setState({loading_seller_data: false})        
                })

            } else {
                state.loading_contestacao = !this.state.loading_contestacao;
                state.infos_contestacao = !this.state.infos_contestacao;

                this.setState({...state})
                this.handletoastMessage('Falha ao atualizar');
            }
        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              // console.log(error.config);
        })

    }
    handleAddNewContestacao = () => {
        var state = this.state;

        state.list_contestacao.push({
            'id': null,
            'prazo_contestacao': state.prazo_contestacao,
            'notas_contestacao': state.notas_contestacao,
            'files_contestacao': state.file.peca_defesa,
            'arquivos_contestacao': [],
            'inputFilePecaDefesaMultiProcesso': React.createRef()
        })
    
        state.file.peca_defesa = [];
        state.prazo_contestacao = '';
        state.notas_contestacao = '';
        state.files_contestacao = [];
        state.loading_contestacao = false;
        state.infos_contestacao = true;
        
        this.setState({...state})
    }
    handleAddContestacao = () => {
        this.setState({
            loading_contestacao: !this.state.loading_contestacao, 
            infos_contestacao: !this.state.infos_contestacao
        })
        
        if (getId() != 'novo') {
            this.handleAddNewFileContestacao()
        } else {
            this.handleAddNewContestacao()
        }
    }

    modalConfirm(visible_confirm, callback = false){
        this.setState({visible_confirm: visible_confirm})
        this.setState({callback_modal: callback})
    }

    handleDebitoCalcValorAtualizado = (e) =>{
        const { name, value } = e.target;
        let state = this.state;
        let valorAtualizado = 0.00;

        valorAtualizado = parseFloat(value) + parseFloat(valorAtualizado);

        if (this.state.honorarios_cobrados == '1') {
            valorAtualizado = parseFloat(this.state.honorarios_valor) + parseFloat(valorAtualizado);
        }

        state.list_tipo_custas.map((custa, index) => {
            valorAtualizado = parseFloat(custa.custas_valor) + parseFloat(valorAtualizado);
        })
  
        valorAtualizado = parseFloat(valorAtualizado).toFixed(2);

        state.valor_debito = value;
        state.valor_atualizado = valorAtualizado;
        this.setState({...state})
    }
    
    handleCalcHonorValorAtualizado = (e) =>{
        const { name, value } = e.target;

        let state = this.state;
        let valorAtualizado = 0.00;

        valorAtualizado = parseFloat(this.state.valor_debito) + parseFloat(valorAtualizado);

        if (this.state.honorarios_cobrados == '1') {
            valorAtualizado = parseFloat(value) + parseFloat(valorAtualizado);
        }
    
        state.list_tipo_custas.map((custa, index) => {
            valorAtualizado = parseFloat(custa.custas_valor) + parseFloat(valorAtualizado);
        })

        valorAtualizado = parseFloat(valorAtualizado).toFixed(2);

        state.honorarios_valor = value;
        state.valor_atualizado = valorAtualizado;
        
        this.setState({...state})
    }

    handleCalcSelectHonorarioValorAtualizado = (honorarios_cobrados_select) =>{
        let state = this.state;
        let valorAtualizado = 0.00;

        valorAtualizado = parseFloat(this.state.valor_debito) + parseFloat(valorAtualizado);

        if (honorarios_cobrados_select.value == '1') {
            valorAtualizado = parseFloat(this.state.honorarios_valor) + parseFloat(valorAtualizado);
        } 

        state.list_tipo_custas.map((custa, index) => {
            valorAtualizado = parseFloat(custa.custas_valor) + parseFloat(valorAtualizado);
        })

        valorAtualizado = parseFloat(valorAtualizado).toFixed(2);

        state.honorarios_cobrados_select = honorarios_cobrados_select;
        state.honorarios_cobrados = honorarios_cobrados_select.value;
        state.valor_atualizado = valorAtualizado;

        this.setState({...state})
    }
    
    handleCalcEditCustasValorAtualizado = (state) =>{
        let valorAtualizado = 0.00;

      
        valorAtualizado = parseFloat(this.state.valor_debito) + parseFloat(valorAtualizado);

        if (this.state.honorarios_cobrados == '1') {
            valorAtualizado = parseFloat(this.state.honorarios_valor) + parseFloat(valorAtualizado);
        }

        state.list_tipo_custas.map((custa, index) => {
            valorAtualizado = parseFloat(custa.custas_valor) + parseFloat(valorAtualizado);
        })

        valorAtualizado = parseFloat(valorAtualizado).toFixed(2);

        state.valor_atualizado = parseFloat(valorAtualizado);

        this.setState({...state})
    }

    handleCalcValorAtualizado = () => {
        let state = this.state;
        let valorAtualizado = 0.00;

        valorAtualizado = parseFloat(this.state.valor_debito) + parseFloat(valorAtualizado);

        if (this.state.honorarios_cobrados == '1') {
            valorAtualizado = parseFloat(this.state.honorarios_valor) + parseFloat(valorAtualizado);
        }

        state.list_tipo_custas.map((custa, index) => {
            valorAtualizado = parseFloat(custa.custas_valor) + parseFloat(valorAtualizado);
        })

        valorAtualizado = parseFloat(valorAtualizado).toFixed(2);

        state.valor_atualizado = valorAtualizado;

        this.setState({...state})
    }

    handleSaveParcela = (parcelamento) => {
        if (getId() != 'novo') {
            this.setState({loading_btn_save_parcelamento: true})

            var formData = new FormData();

            var url = ENDPOINT + "juridico/parcelamento";

            //var url = "http://localhost:8000/juridico/parcelamento";
            //formData.append('id_processo', 57);
           
            
            formData.append('id_processo', getId());
            formData.append('valor', parcelamento.valor);
            formData.append('data_pagamento', parcelamento.data_pagamento);
            formData.append('parcelamento_notas', parcelamento.parcelamento_notas);
            formData.append('data_vencimento', parcelamento.data_vencimento );
            formData.append('parcela', parcelamento.parcela);

            if (parcelamento.files.length !== undefined && parcelamento.files.length > 0) {
                parcelamento.files.map((file, i)=>{
                    formData.append('comprovante', file);
                })
            }
            
            
            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }})
            .then((response) => {

                if (response.data.success) {
                    this.handletoastSuccessMessage(response.data.message);

                    var url = ENDPOINT + "juridico/parcelamento/" + getId();
                    //var url = "http://localhost:8000/juridico/parcelamento/57";

                    var myHeaders = new Headers();
                    myHeaders.append('Authorization', 'Bearer ' + getToken());
                
                    var myInit = {
                        method: 'GET',
                        headers: myHeaders,
                        mode: 'cors',
                        cache: 'default'
                    };
                    fetch(url, myInit)
                    .then(response => response.json())
                    .then((r) => {
                        if (r.success !== undefined && r.success == true) {
                            let state = this.state;
                            if (r.data.length > 0) {
                                r.data.map((dataparcela, i) => {
                                    this.state.rows_vencimento.map((rowparcela, ch) => {
                                        if (rowparcela.parcela == dataparcela.parcela) {
                                            state.rows_vencimento[ch]['id'] = dataparcela.id;
                                            state.rows_vencimento[ch]['valor'] = parseFloat(dataparcela.valor).toFixed(2).replace('.', ',');
                                            state.rows_vencimento[ch]['data_vencimento'] = dataparcela.data_vencimento;
                                            state.rows_vencimento[ch]['data_pagamento'] = dataparcela.data_pagamento;
                                            state.rows_vencimento[ch]['parcela'] = dataparcela.parcela;
                                            state.rows_vencimento[ch]['comprovante'] = dataparcela.comprovante;
                                            state.rows_vencimento[ch]['notas'] = dataparcela.notas;
                                            state.rows_vencimento[ch]['is_open_nota_vencimento'] = false;
                                            state.rows_vencimento[ch]['files'] = [];
                                            state.rows_vencimento[ch]['open_iframe'] = false;
                                            state.rows_vencimento[ch]['upload_comprovante'] = (dataparcela.comprovante != '') ? false : true;
                                        }
                                    })
                                })
                            }
                            
                            state.is_open_nota_vencimento = false;
                            this.setState({...state})
                        }
                    })
                    .catch((error) => {
                       //console.log(error)
                    })

                } else {
                    this.handletoastMessage(response.data.error);  
                }
                this.setState({loading_btn_save_parcelamento: false})
            }) 
            .catch((error) => {
                this.setState({loading_btn_save_parcelamento: false})
                this.handletoastMessage('Ocorreu um erro no servidor!')

                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    // console.log(error.response.status); // status code
                    // console.log(error.response.headers);
                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                  }
                  // console.log(error.config);
            })
        }
    }

    removeParcelamentoComprovantePagamentoFileAttach = (parcelamento, index) => {
        if (window.confirm("Tem certeza que deseja remover?")) {
            var url = ENDPOINT + "juridico/parcelamento/" + getId() + "/comprovante/" + parcelamento.id;
            //var url = "http://localhost:8000/juridico/parcelamento/57/comprovante/" + parcelamento.id;
            
            var formData = new FormData();

            axios.delete(url, formData, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }})
            .then((response) => {
                //console.log(response)


            }) 
            .catch((error) => {
                this.handletoastMessage('Ocorreu um erro no servidor!')

                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    // console.log(error.response.status); // status code
                    // console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                // console.log(error.config);
            })
        }
    }

    render(){
        const custas_tipo_refs = [];
        const custas_valor_refs = [];

        return(
            <Div key="div_main_page">
                <h3>
                    <Button 
                        outline 
                        style={{border: 'none'}}
                        onClick={() => window.location.href = '/juridico'}
                    >
                        <MdArrowBack style={{padding: 0}}/>
                    </Button>&nbsp;&nbsp;Formulário do Processo
                </h3>
                <hr />
                <Row>
                    <Col md={12}>  
                        <ConfirmarRemove 
                            visible={this.state.visible_confirm}
                            onClose={() => this.modalConfirm(false)}
                            callback={this.state.callback_modal}
                        />              
                    </Col>
                </Row>    
                <Row hidden={!this.state.loading_page}>
                    <Col md={12}>
                        <center>
                            <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                        </center>
                        <br/>
                    </Col>
                </Row>
            <Div hidden={!this.state.show_form}>
                <Row>
                    <Col md={12}>
                        <Button 
                            className="btn btn-success btn-save" 
                            disabled={this.state.disable_send_button}
                            onClick={this.handleSendFormProcesso}>
                            {this.state.loading_btn_save_form === true ?
                                <>
                                    <ClipLoader color="#fff" size={12}/>
                                    &nbsp;Salvando...
                                </>
                            : 
                                'Salvar Processo'
                            }
                        </Button>
                    </Col>
                </Row>
                <br/> 
                <Row hidden={!this.state.loading_seller_data}>
                    <Col md={12}>
                        <center>
                            <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                        </center>
                        <br/>
                    </Col>
                </Row>    
                <Row  hidden={!this.state.seller_data_load}>
                    <Col md={12}>
                        <p><strong>Vendedor:</strong> {this.state.seller_name}</p>
                        <p><strong>Empresa:</strong> {this.state.seller_company}</p>
                        <p><strong>Email:</strong> {this.state.seller_email}</p>
                        <p><strong>Telefone:</strong> {this.state.seller_phone}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <label htmlFor="motivo">Motivo da reclamação</label>
                        <Select
                            name="motivo"
                            placeholder="Motivo da reclamação"
                            options={this.state.motivos_options}
                            onChange={this.handleChangeMotivo}
                            value={this.state.motivo_select}
                                />
                    </Col>
                  
                    <Col md={6}>
                        <Collapse isOpen={this.state.is_open_reclamado || this.state.is_open_reclamante || this.state.is_open_acordo_extrajudicial}>
                            <label htmlFor="documento">
                                {this.state.is_open_reclamado || this.state.is_open_reclamante ? 'Documento do cliente' : 'Devedor'}
                            </label>
                            <Input
                                name="documento"
                                placeholder="CPF / CNPJ do estabelecimento"
                                value={this.state.documento}
                                type="text"
                                onChange={this.handleInputChange}
                                onBlur={this.handleChangeDocument} 
                            />
                        </Collapse>
                    </Col>
                   
                </Row>


                {this.state.is_open_acordo_extrajudicial ?
                    <div key='acordo_extrajudicial_div'>
                    <Row>
                        <Col md={6}>
                            <label htmlFor="empresa_credora">Empresa credora</label>
                            <Select
                            name="empresa_credora"
                            placeholder="Selecione..."
                            options={this.state.reclamados_options}
                            onChange={(empresa_credora_select) => {
                                this.setState({empresa_credora_select})
                                this.setState({empresa_credora: empresa_credora_select.value});
                            }}
                            value={this.state.empresa_credora_select}
                                />
                        </Col>
                        <Col md={6}>
                            <label htmlFor="valor_debito_original">Valor débito original</label>
                            <CurrencyInput 
                                inputMode="decimal"
                                className="form-control input-currency" 
                                currency="BRL"
                                name="valor_debito_original"
                                // value={this.state.proposta_acordo}
                                defaultValue={this.state.valor_debito_original}
                                onChange={(event) => {this.handleInputChange(event)}}
                                />
                                <small className="currency-simbol">R$</small>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <label htmlFor="vencimento">Vencimento</label>
                            <Input
                                name="vencimento"
                                placeholder=""
                                value={this.state.vencimento}
                                type="date"
                                onChange={this.handleInputVencimentoChange}
                            />
                        </Col>
                        <Col md={6}>
                            <label htmlFor="atraso">Dias de atraso</label>
                            <Input
                                name="atraso"
                                placeholder=""
                                value={this.state.atraso}
                                type="text"
                                disabled="true"
                            />
                        </Col>
                    </Row>                    
                    <Row>
                        <Col md={12}>
                            <label htmlFor="origem_debito">Origem do débito</label>
                            <Input
                                name="origem_debito"
                                placeholder=""
                                value={this.state.origem_debito}
                                type="textarea"
                                style={{
                                    height: '124px',
                                    resize: 'none'
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <label htmlFor="envio_notificacao">Envio de notificação</label>
                            <Select
                                name="envio_notificacao"
                                placeholder="Selecione..."
                                options={optionsYesNo}
                                onChange={(envio_notificacao_select) => {
                                    this.setState({envio_notificacao_select})
                                    this.setState({envio_notificacao: envio_notificacao_select.value});
                                }}
                                value={this.state.envio_notificacao_select}
                                    />
                        </Col>
                        <Col md={6}>
                            <Collapse isOpen={this.state.envio_notificacao === '1'}>
                                <label htmlFor="data_postagem">Data de postagem</label>
                                <Input
                                    name="data_postagem"
                                    placeholder=""
                                    value={this.state.data_postagem}
                                    type="date"
                                />
                            </Collapse>
                        </Col> 
                    
                    </Row>
                    <Collapse isOpen={this.state.envio_notificacao === '1'}>
                        <Row>
                            <Col md={6}>
                                <label htmlFor="codigo_postagem">Código de postagem</label>
                                <Input
                                    name="codigo_postagem"
                                    placeholder=""
                                    value={this.state.codigo_postagem}
                                    type="text"
                                />
                            </Col>
                            <Col md={6}>
                                <label htmlFor="status_envio">Status de envio</label>
                                <Select
                                    name="status_envio"
                                    placeholder="Selecione..."
                                    options={statusDeEnvio}
                                    onChange={(status_envio_select) => {
                                        this.setState({status_envio_select})
                                        this.setState({status_envio: status_envio_select.value});

                                        var data = new Date(); 
                                        data.setDate(data.getDate() + 8);
                                        this.setState({prazo_notificacao:  data.toISOString().split('T')[0]});
                                    }}
                                    value={this.state.status_envio_select}
                                        />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <label htmlFor="prazo_notificacao">Prazo de notificação</label>
                                <Input
                                    name="prazo_notificacao"
                                    placeholder=""
                                    value={this.state.prazo_notificacao}
                                    type="date"
                                />
                            </Col>
                        </Row>
                    </Collapse>
                    <Row>
                        <Col md={6}>
                            <label htmlFor="havera_acordo">Deseja fazer acordo</label>
                            <Select
                                name="havera_acordo"
                                placeholder="Selecione..."
                                options={[
                                    { value: '1', label: 'Sim'},
                                    { value: '0', label: 'Não'},
                                ]}
                                onChange={(havera_acordo_select) => {
                                    this.setState({havera_acordo_select})
                                    this.setState({havera_acordo: havera_acordo_select.value});
                                }}
                                value={this.state.havera_acordo_select}
                                    />
                        </Col>
                        <Col md={6}>
                            <Collapse isOpen={this.state.havera_acordo === '1'}>
                                <label htmlFor="proposta_acordo">Proposta de acordo</label>
                                <CurrencyInput 
                                    inputMode="decimal"
                                    className="form-control input-currency" 
                                    currency="BRL"
                                    name="proposta_acordo"
                                    // value={this.state.proposta_acordo}
                                    defaultValue={this.state.proposta_acordo}
                                    onChange={(event) => {this.handleInputChange(event)}}
                                    />
                                    <small className="currency-simbol">R$</small>
                            </Collapse>
                        </Col>
                    </Row>
                    <Collapse isOpen={this.state.havera_acordo === '1'}>
                        <Row>
                            <Col md={6}>
                                <label htmlFor="numero_parcelas">Número de parcelas</label>
                                <Select
                                    name="numero_parcelas"
                                    placeholder="Selecione..."
                                    options={numeroParcelas}
                                    onChange={(numero_parcelas_select) => {
                                        this.setState({numero_parcelas_select})
                                        this.setState({numero_parcelas: numero_parcelas_select.value});
                                    }}
                                    
                                    value={this.state.numero_parcelas_select}
                                        />
                            </Col>
                            <Col md={6}>
                                <label htmlFor="valor_debito_atualizado">Valor débito atualizado</label>
                                <CurrencyInput 
                                    inputMode="decimal"
                                    className="form-control input-currency" 
                                    currency="BRL"
                                    name="valor_debito_atualizado"
                                    // value={this.state.valor_debito_atualizado}
                                    defaultValue={this.state.valor_debito_atualizado}
                                    onChange={(event) => {this.handleInputChange(event)}}
                                    />
                                    <small className="currency-simbol">R$</small>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <label htmlFor="valor_debito_parcela">Valor por parcela</label>
                                <CurrencyInput 
                                    inputMode="decimal"
                                    className="form-control input-currency" 
                                    currency="BRL"
                                    name="valor_debito_parcela"
                                    // value={this.state.valor_debito_parcela}
                                    defaultValue={this.state.valor_debito_parcela}
                                    onChange={(event) => {this.handleInputChange(event)}}
                                    />
                                    <small className="currency-simbol">R$</small>
                            </Col>
                            <Col md={6}>
                            {/* FormasDePagamentos */}
                                <label htmlFor="forma_pagamento">Forma de pagamento</label>
                                {/* <Input
                                        name="forma_pagamento"
                                        placeholder=""
                                        value={this.state.forma_pagamento}
                                        type="text"
                                        onChange={this.handleInputChange}
                                    /> */}

                                <Select
                                    name="forma_pagamento_select"
                                    placeholder="Selecione..."
                                    options={FormasDePagamentos}
                                    onChange={(forma_pagamento_select) => {
                                        this.setState({forma_pagamento_select})
                                        this.setState({forma_pagamento: forma_pagamento_select.value});
                                    }}
                                    value={this.state.forma_pagamento_select}
                                        />
                            </Col>
                        </Row>

            {/* forma de pagamento acordo */}
                    <Collapse isOpen={this.state.forma_pagamento == 'deposito_judicial'}>
                        <Row>
                            <Col md={12}>
                                <div style={{padding: '15px', border: '1px solid #dee2e6', borderLeft: '5px solid #28a745', borderRadius: 5}}>
                                    <h5>Comprovante de Pagamento</h5><hr />
                                    <Row>
                                        <Col md={6}>
                                            <label htmlFor="deposito_judicial_vencimento">Vencimento</label>
                                            <Input
                                                name="deposito_judicial_vencimento"
                                                placeholder=""
                                                value={this.state.deposito_judicial_vencimento}
                                                type="date"
                                                onChange={this.handleInputChange} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <label htmlFor="deposito_judicial_anexo">Anexo do pagamento</label>
                                            <Dropzone onDrop={(drop) => this.onDropDepositoJudicialAnexo(drop)}>
                                                {({ getRootProps, getInputProps }) => (
                                                <DropArea {...getRootProps({
                                                    onClick: event => event.stopPropagation()
                                                })}>
                                                    <input {...getInputProps()} />
                                                        {this.state.file.deposito_judicial_anexo.length > 0 ? 
                                                            <>
                                                                <Row> 
                                                                    {this.state.file.deposito_judicial_anexo.map((e, index) => {
                                                                        return <Col key={index} md={4} style={{paddingBottom: 4}}>
                                                                            <Card>
                                                                                <p style={{margin: '3px'}}>
                                                                                    <MdAttachFile style={{width: '32px'}}/>
                                                                                    {e.name}&nbsp; 
                                                                                    <Button 
                                                                                        style={{padding: 0, border: 3, float: 'right'}} 
                                                                                        color="danger" 
                                                                                        outline 
                                                                                        onClick={
                                                                                            () => this.removeDepositoJudicialAnexoAttach(index)
                                                                                        }>
                                                                                            <MdClear style={{padding: 0}}/>
                                                                                    </Button>
                                                                                </p>
                                                                            </Card>
                                                                        </Col>
                                                                    })}
                                                                    <br/>
                                                                </Row>
                                                                <p style={{ margin: 0 }}>
                                                                <MdAttachFile style={{ width: '32px' }} />
                                                                    Arraste o arquivo ou&nbsp;
                                                                    <ButtonUpload 
                                                                        onClick={() => this.inputFileDepositoJudicialAnexo.current.click()} 
                                                                        className="btn btn-dark" 
                                                                        color="primary">
                                                                        Clique Aqui
                                                                    </ButtonUpload> para adicionar mais anexos
                                                                    <input type="file" ref={this.inputFileDepositoJudicialAnexo} hidden onChange={this.handleFileDepositoJudicialAnexoChange} />
                                                            </p>
                                                        </>
                                                        :
                                                        <>
                                                            <p style={{ margin: 0 }}>
                                                                <MdAttachFile style={{ width: '32px' }} />
                                                                    Arraste o arquivo ou&nbsp;
                                                                    <ButtonUpload 
                                                                        onClick={() => this.inputFileDepositoJudicialAnexo.current.click()} 
                                                                        className="btn btn-dark" 
                                                                        color="primary">
                                                                        Clique Aqui
                                                                    </ButtonUpload> para adicionar anexos
                                                                    <input type="file" ref={this.inputFileDepositoJudicialAnexo} hidden onChange={this.handleFileDepositoJudicialAnexoChange} />
                                                            </p>
                                                        </>
                                                    }
                                                </DropArea>
                                            )}
                                            </Dropzone>

                                        </Col>
                                    </Row>    
                                        {this.state.arquivo_deposito_judicial_anexo != null ?
                                            <Row>
                                                <Col md={12}>
                                                    <Button 
                                                        className="btn btn-info btn-arquivo" 
                                                        outline 
                                                        onClick={
                                                            () => this.setState({pdf_deposito_judicial_anexo_open: !this.state.pdf_inicipdf_deposito_judicial_anexo_openal_open})
                                                        }>
                                                            <span>Visualizar</span>
                                                    </Button>
                                                    <Button 
                                                        className="btn btn-info btn-arquivo" 
                                                        outline 
                                                        onClick={
                                                            () => window.open(this.state.arquivo_deposito_judicial_anexo, '_blank').focus()
                                                        }>
                                                            <span>PDF Viewer</span>
                                                    </Button>
                                                    <Collapse isOpen={this.state.pdf_deposito_judicial_anexo_open}>
                                                        <iframe 
                                                            className="file-iframe"
                                                            src={this.state.arquivo_deposito_judicial_anexo} >
                                                            </iframe>
                                                    </Collapse>
                                                </Col>
                                            </Row>
                                        :
                                            ''
                                        }

                                </div>
                            </Col>
                        </Row>
                    </Collapse>    
                    <Collapse isOpen={this.state.forma_pagamento == 'pix'}>
                        <Row>
                            <Col md={12}>
                                <div style={{padding: '15px', border: '1px solid #dee2e6', borderLeft: '5px solid #28a745', borderRadius: 5}}>
                                    <h5>Dados do PIX</h5><hr />
                                    <Row>
                                        <Col md={6}>
                                            <label htmlFor="chave_pix">Chave PIX</label>
                                            <Input
                                                name="chave_pix"
                                                placeholder=""
                                                value={this.state.chave_pix}
                                                type="text"
                                                onChange={this.handleInputChange} />
                                        </Col>
                                        <Col md={6}>
                                            <label htmlFor="nome_pix">Nome</label>
                                            <Input
                                                name="nome_pix"
                                                placeholder=""
                                                value={this.state.nome_pix}
                                                type="text"
                                                onChange={this.handleInputChange} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <label htmlFor="deposito_judicial_anexo">Anexo do pagamento</label>
                                            <Dropzone onDrop={(drop) => this.onDropDepositoJudicialAnexo(drop)}>
                                                {({ getRootProps, getInputProps }) => (
                                                <DropArea {...getRootProps({
                                                    onClick: event => event.stopPropagation()
                                                })}>
                                                    <input {...getInputProps()} />
                                                        {this.state.file.deposito_judicial_anexo.length > 0 ? 
                                                            <>
                                                                <Row> 
                                                                    {this.state.file.deposito_judicial_anexo.map((e, index) => {
                                                                        return <Col key={index} md={4} style={{paddingBottom: 4}}>
                                                                            <Card>
                                                                                <p style={{margin: '3px'}}>
                                                                                    <MdAttachFile style={{width: '32px'}}/>
                                                                                    {e.name}&nbsp; 
                                                                                    <Button 
                                                                                        style={{padding: 0, border: 3, float: 'right'}} 
                                                                                        color="danger" 
                                                                                        outline 
                                                                                        onClick={
                                                                                            () => this.removeDepositoJudicialAnexoAttach(index)
                                                                                        }>
                                                                                            <MdClear style={{padding: 0}}/>
                                                                                    </Button>
                                                                                </p>
                                                                            </Card>
                                                                        </Col>
                                                                    })}
                                                                    <br/>
                                                                </Row>
                                                                <p style={{ margin: 0 }}>
                                                                <MdAttachFile style={{ width: '32px' }} />
                                                                    Arraste o arquivo ou&nbsp;
                                                                    <ButtonUpload 
                                                                        onClick={() => this.inputFileDepositoJudicialAnexo.current.click()} 
                                                                        className="btn btn-dark" 
                                                                        color="primary">
                                                                        Clique Aqui
                                                                    </ButtonUpload> para adicionar mais anexos
                                                                    <input type="file" ref={this.inputFileDepositoJudicialAnexo} hidden onChange={this.handleFileDepositoJudicialAnexoChange} />
                                                            </p>
                                                        </>
                                                        :
                                                        <>
                                                            <p style={{ margin: 0 }}>
                                                                <MdAttachFile style={{ width: '32px' }} />
                                                                    Arraste o arquivo ou&nbsp;
                                                                    <ButtonUpload 
                                                                        onClick={() => this.inputFileDepositoJudicialAnexo.current.click()} 
                                                                        className="btn btn-dark" 
                                                                        color="primary">
                                                                        Clique Aqui
                                                                    </ButtonUpload> para adicionar anexos
                                                                    <input type="file" ref={this.inputFileDepositoJudicialAnexo} hidden onChange={this.handleFileDepositoJudicialAnexoChange} />
                                                            </p>
                                                        </>
                                                    }
                                                </DropArea>
                                            )}
                                            </Dropzone>

                                        </Col>
                                    </Row>    
                                        {this.state.arquivo_deposito_judicial_anexo != null ?
                                            <Row>
                                                <Col md={12}>
                                                    <Button 
                                                        className="btn btn-info btn-arquivo" 
                                                        outline 
                                                        onClick={
                                                            () => this.setState({pdf_deposito_judicial_anexo_open: !this.state.pdf_inicipdf_deposito_judicial_anexo_openal_open})
                                                        }>
                                                            <span>Visualizar</span>
                                                    </Button>
                                                    <Button 
                                                        className="btn btn-info btn-arquivo" 
                                                        outline 
                                                        onClick={
                                                            () => window.open(this.state.arquivo_deposito_judicial_anexo, '_blank').focus()
                                                        }>
                                                            <span>PDF Viewer</span>
                                                    </Button>
                                                    <Collapse isOpen={this.state.pdf_deposito_judicial_anexo_open}>
                                                        <iframe 
                                                            className="file-iframe"
                                                            src={this.state.arquivo_deposito_judicial_anexo} >
                                                            </iframe>
                                                    </Collapse>
                                                </Col>
                                            </Row>
                                        :
                                            ''
                                        }
                                </div>
                            </Col>
                        </Row>
                    </Collapse>
                    <Collapse isOpen={this.state.forma_pagamento == 'ted'}>
                        <Row>
                            <Col md={12}>
                                <div style={{padding: '15px', border: '1px solid #dee2e6', borderLeft: '5px solid #28a745', borderRadius: 5}}>
                                    <h5>Dados da Conta</h5><hr />
                                    <Row>
                                        <Col md={12}>
                                            <label htmlFor="conta_banco">Banco</label>
                                            <Select
                                                name="conta_banco_select"
                                                placeholder="Selecione..."
                                                options={this.state.bancos_options}
                                                onChange={(conta_banco_select) => {
                                                    this.setState({conta_banco_select})
                                                    this.setState({conta_banco: conta_banco_select.value});
                                                }}
                                                value={this.state.conta_banco_select}
                                                    />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <label htmlFor="conta_agencia">Agência</label>
                                            <Input
                                                name="conta_agencia"
                                                placeholder=""
                                                value={this.state.conta_agencia}
                                                type="text"
                                                onChange={this.handleInputChange} />
                                        </Col>
                                        <Col md={6}>
                                            <label htmlFor="conta_conta">Conta</label>
                                            <Input
                                                name="conta_conta"
                                                placeholder=""
                                                value={this.state.conta_conta}
                                                type="text"
                                                onChange={this.handleInputChange} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <label htmlFor="conta_documento">CPF / CNPJ</label>
                                            <Input
                                                name="conta_documento"
                                                placeholder="Documento do recebedor"
                                                value={this.state.conta_documento}
                                                type="text"
                                                onChange={this.handleInputChange} />
                                        </Col>
                                        <Col md={6}>
                                            <label htmlFor="conta_nome">Nome</label>
                                            <Input
                                                name="conta_nome"
                                                placeholder="Nome do recebedor"
                                                value={this.state.conta_nome}
                                                type="text"
                                                onChange={this.handleInputChange} />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12}>
                                            <label htmlFor="deposito_judicial_anexo">Anexo do pagamento</label>
                                            <Dropzone onDrop={(drop) => this.onDropDepositoJudicialAnexo(drop)}>
                                                {({ getRootProps, getInputProps }) => (
                                                <DropArea {...getRootProps({
                                                    onClick: event => event.stopPropagation()
                                                })}>
                                                    <input {...getInputProps()} />
                                                        {this.state.file.deposito_judicial_anexo.length > 0 ? 
                                                            <>
                                                                <Row> 
                                                                    {this.state.file.deposito_judicial_anexo.map((e, index) => {
                                                                        return <Col key={index} md={4} style={{paddingBottom: 4}}>
                                                                            <Card>
                                                                                <p style={{margin: '3px'}}>
                                                                                    <MdAttachFile style={{width: '32px'}}/>
                                                                                    {e.name}&nbsp; 
                                                                                    <Button 
                                                                                        style={{padding: 0, border: 3, float: 'right'}} 
                                                                                        color="danger" 
                                                                                        outline 
                                                                                        onClick={
                                                                                            () => this.removeDepositoJudicialAnexoAttach(index)
                                                                                        }>
                                                                                            <MdClear style={{padding: 0}}/>
                                                                                    </Button>
                                                                                </p>
                                                                            </Card>
                                                                        </Col>
                                                                    })}
                                                                    <br/>
                                                                </Row>
                                                                <p style={{ margin: 0 }}>
                                                                <MdAttachFile style={{ width: '32px' }} />
                                                                    Arraste o arquivo ou&nbsp;
                                                                    <ButtonUpload 
                                                                        onClick={() => this.inputFileDepositoJudicialAnexo.current.click()} 
                                                                        className="btn btn-dark" 
                                                                        color="primary">
                                                                        Clique Aqui
                                                                    </ButtonUpload> para adicionar mais anexos
                                                                    <input type="file" ref={this.inputFileDepositoJudicialAnexo} hidden onChange={this.handleFileDepositoJudicialAnexoChange} />
                                                            </p>
                                                        </>
                                                        :
                                                        <>
                                                            <p style={{ margin: 0 }}>
                                                                <MdAttachFile style={{ width: '32px' }} />
                                                                    Arraste o arquivo ou&nbsp;
                                                                    <ButtonUpload 
                                                                        onClick={() => this.inputFileDepositoJudicialAnexo.current.click()} 
                                                                        className="btn btn-dark" 
                                                                        color="primary">
                                                                        Clique Aqui
                                                                    </ButtonUpload> para adicionar anexos
                                                                    <input type="file" ref={this.inputFileDepositoJudicialAnexo} hidden onChange={this.handleFileDepositoJudicialAnexoChange} />
                                                            </p>
                                                        </>
                                                    }
                                                </DropArea>
                                            )}
                                            </Dropzone>

                                        </Col>
                                    </Row>    
                                        {this.state.arquivo_deposito_judicial_anexo != null ?
                                            <Row>
                                                <Col md={12}>
                                                    <Button 
                                                        className="btn btn-info btn-arquivo" 
                                                        outline 
                                                        onClick={
                                                            () => this.setState({pdf_deposito_judicial_anexo_open: !this.state.pdf_inicipdf_deposito_judicial_anexo_openal_open})
                                                        }>
                                                            <span>Visualizar</span>
                                                    </Button>
                                                    <Button 
                                                        className="btn btn-info btn-arquivo" 
                                                        outline 
                                                        onClick={
                                                            () => window.open(this.state.arquivo_deposito_judicial_anexo, '_blank').focus()
                                                        }>
                                                            <span>PDF Viewer</span>
                                                    </Button>
                                                    <Collapse isOpen={this.state.pdf_deposito_judicial_anexo_open}>
                                                        <iframe 
                                                            className="file-iframe"
                                                            src={this.state.arquivo_deposito_judicial_anexo} >
                                                            </iframe>
                                                    </Collapse>
                                                </Col>
                                            </Row>
                                        :
                                            ''
                                        }
                                </div>
                            </Col>
                        </Row>
                    </Collapse>
            {/* forma de pagamento acordo */}

                        <Row>
                            <Col md={6}>
                                <label htmlFor="primeiro_vencimento">Data primeiro vencimento</label>
                                <Input
                                        name="primeiro_vencimento"
                                        placeholder=""
                                        value={this.state.primeiro_vencimento}
                                        type="date"
                                        onChange={this.handleInputChange}
                                    />
                            </Col>
                        </Row>    
                    </Collapse>
                    <Row>    
                            <Col md={12}>
                                <label htmlFor="confissao_anexo">Anexo acordo confissão de dívida</label>
                                <Dropzone onDrop={(drop) => this.onDropConfissao(drop)}>
                                    {({ getRootProps, getInputProps }) => (
                                    <DropArea {...getRootProps({
                                        onClick: event => event.stopPropagation()
                                    })}>
                                        <input {...getInputProps()} />
                                            {this.state.file.confissao_anexo.length > 0 ? 
                                                <>
                                                    <Row> 
                                                        {this.state.file.confissao_anexo.map((e, index) => {
                                                            return <Col key={index} md={4} style={{paddingBottom: 4}}>
                                                                <Card>
                                                                    <p style={{margin: '3px'}}>
                                                                        <MdAttachFile style={{width: '32px'}}/>
                                                                        {e.name}&nbsp; 
                                                                        <Button 
                                                                            style={{padding: 0, border: 3, float: 'right'}} 
                                                                            color="danger" 
                                                                            outline 
                                                                            onClick={
                                                                                () => this.removeConfissaoAttach(index)
                                                                            }>
                                                                                <MdClear style={{padding: 0}}/>
                                                                        </Button>
                                                                    </p>
                                                                </Card>
                                                            </Col>
                                                        })}
                                                        <br/>
                                                    </Row>
                                                    <p style={{ margin: 0 }}>
                                                    <MdAttachFile style={{ width: '32px' }} />
                                                        Arraste o arquivo ou&nbsp;
                                                        <ButtonUpload 
                                                            onClick={() => this.inputFileConfissao.current.click()} 
                                                            className="btn btn-dark" 
                                                            color="primary">
                                                            Clique Aqui
                                                        </ButtonUpload> para adicionar mais anexos
                                                        <input type="file" ref={this.inputFileConfissao} hidden onChange={this.handleFileConfissaoChange} />
                                                </p>
                                            </>
                                            :
                                            <>
                                                <p style={{ margin: 0 }}>
                                                    <MdAttachFile style={{ width: '32px' }} />
                                                        Arraste o arquivo ou&nbsp;
                                                        <ButtonUpload 
                                                            onClick={() => this.inputFileConfissao.current.click()} 
                                                            className="btn btn-dark" 
                                                            color="primary">
                                                            Clique Aqui
                                                        </ButtonUpload> para adicionar anexos 
                                                        <input type="file" ref={this.inputFileConfissao} hidden onChange={this.handleFileConfissaoChange} />
                                                </p>
                                            </>
                                        }
                                    </DropArea>
                                )}
                                </Dropzone>
                                {this.state.arquivo_confissao_anexo !== null ?
                                    <div style={{marginTop: '10px' }} >
                                    <Button 
                                        className="btn btn-info btn-arquivo" 
                                        outline 
                                        onClick={
                                            () => this.setState({pdf_confissao_open: !this.state.pdf_confissao_open})
                                        }>
                                            <span>Visualizar</span>
                                    </Button>
                                    <Button 
                                        className="btn btn-info btn-arquivo" 
                                        outline 
                                        onClick={
                                            () => window.open(this.state.arquivo_confissao_anexo, '_blank').focus()
                                        }>
                                            <span>PDF Viewer</span>
                                    </Button>
                                    </div>
                                :
                                <></>
                                }
                            </Col>
                        </Row>
                    <Row>
                        <Col md={6}>
                            <label htmlFor="negativado_serasa">Negativado Serasa/SPC</label>
                            <Select
                                name="negativado_serasa"
                                placeholder="Selecione..."
                                options={[
                                    { value: '1', label: 'Sim'},
                                    { value: '0', label: 'Não'},
                                ]}
                                onChange={(negativado_serasa_select) => {
                                    this.setState({negativado_serasa_select})
                                    this.setState({negativado_serasa: negativado_serasa_select.value});
                                }}
                                value={this.state.negativado_serasa_select}
                                    />
                        </Col>
                        <Col md={6}>
                            <Collapse isOpen={this.state.negativado_serasa === '1'}>
                                <label htmlFor="baixa_serasa">Baixa no Serasa/SPC</label>
                                <Input
                                        name="baixa_serasa"
                                        placeholder=""
                                        value={this.state.baixa_serasa}
                                        type="date"
                                        onChange={this.handleInputChange}
                                    />
                            </Collapse>
                        </Col>
                    </Row>
                    </div>
                :
                    <></>
                }

                <Collapse isOpen={this.state.is_open_reclamado || this.state.is_open_reclamante}>

                    <Row>
                        <Col md={4}>
                            <label htmlFor="numero_processo">Número do processo</label>
                            <Input
                                name="numero_processo"
                                placeholder="Número do processo"
                                value={this.state.numero_processo}
                                type="text"
                                onChange={this.handleInputChange}
                            />
                        </Col>
                        <Col md={4}>
                            <label htmlFor="vara">Vara</label>
                            <Input
                                name="vara"
                                placeholder="Vara"
                                value={this.state.vara}
                                className={this.state.valid_input['vara'] !== undefined ? 'invalid' : '' }
                                type="text"
                                onChange={this.handleInputChange}
                            />
                        </Col>
                        <Col md={4}>
                            <label htmlFor="valor_acao">Valor da Ação</label>
                            <CurrencyInput 
                                ref={this.refValorAcao}
                                inputMode="decimal"
                                className={this.state.valid_input['valor_acao'] !== undefined ? 'form-control input-currency invalid' : 'form-control input-currency' }
                                currency="BRL"
                                name="valor_acao"
                                value={this.state.valor_acao}
                                defaultValue={0.00}
                                onChange={this.handleInputChange}
                                onKeyUp={this.handleInputChange}
                                />
                                <small className="currency-simbol">R$</small>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <label htmlFor="valor_debito">Valor do Débito</label>
                            <CurrencyInput 
                                inputMode="decimal"
                                className={this.state.valid_input['valor_debito'] !== undefined ? 'form-control input-currency invalid' : 'form-control input-currency' }
                                currency="BRL"
                                name="valor_debito"
                                value={this.state.valor_debito}
                                defaultValue={0.00}
                                onChange={(event) => {
                                    this.handleInputChange(event)
                                }}
                                onKeyUp={(event) => {
                                    //this.handleDebitoCalcValorAtualizado(event)
                                    this.handleDebitoCalcValorAtualizado(event)
                                }}
                                />
                                <small className="currency-simbol">R$</small>
                        </Col>                       
                        <Col md={6}>
                            <label htmlFor="vencimento_original">Vencimento Original</label>
                            <Input
                                name="vencimento_original"
                                placeholder=""
                                className={this.state.valid_input['vencimento_original'] !== undefined ? 'invalid' : '' }
                                value={this.state.vencimento_original}
                                type="date"
                                onChange={this.handleInputChange}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <label htmlFor="dias_atraso">Dias de Atraso</label>
                            <Input
                                name="dias_atraso"
                                placeholder=""
                                value={this.state.dias_atraso}
                                disabled={true}
                                type="text"
                                onChange={this.handleInputChange}
                            />
                        </Col>
                        <Col md={6}>
                            <label htmlFor="valor_atualizado">Valor Atualizado</label>
                            <CurrencyInput 
                                inputMode="decimal"
                                className={this.state.valid_input['valor_atualizado'] !== undefined ? 'form-control input-currency invalid' : 'form-control input-currency' }
                                currency="BRL"
                                name="valor_atualizado"
                                value={this.state.valor_atualizado}
                                defaultValue={0.00}
                                onChange={(event) => {this.handleInputChange(event)}}
                                onKeyUp={(event) => {this.handleInputChange(event)}}
                                />
                                <small className="currency-simbol">R$</small>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <label htmlFor="honorarios_cobrados">Honorários Cobrados</label>
                            <Select
                                name="honorarios_cobrados"
                                placeholder="Selecione..."
                                className={this.state.valid_input['honorarios_cobrados'] !== undefined ? 'invalid' : '' }
                                options={optionsYesNo}
                                onChange={(honorarios_cobrados_select) => {
                                    this.handleCalcSelectHonorarioValorAtualizado(honorarios_cobrados_select)
                                }}
                                value={this.state.honorarios_cobrados_select}
                                />
                        </Col>
                        {this.state.honorarios_cobrados == '1' ? 
                        <Col md={6}>
                            <label htmlFor="honorarios_valor">Valor de Honorários</label>
                            <CurrencyInput 
                                inputMode="decimal"
                                className="form-control input-currency" 
                                currency="BRL"
                                name="honorarios_valor"
                                value={this.state.honorarios_valor}
                                defaultValue={0.00}
                                onChange={(event) => {this.handleInputChange(event)}}
                                onKeyUp={(event) => {
                                    //this.handleInputChange(event)
                                    this.handleCalcHonorValorAtualizado(event)
                                }}
                                />
                                <small className="currency-simbol">R$</small>
                        </Col>  
                        :
                        <></>    
                        }
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div key="divaddcustas">
                                <hr/>
                                <h5>
                                    <Button
                                        color={this.state.add_custas ? "danger" : "success" }
                                        style={{margin: '1rem 0'}}
                                        onClick={(e) => {this.setState({add_custas: !this.state.add_custas})}}
                                        >
                                            {this.state.add_custas ? 
                                            <div key="bt_add_div"><FaTrash /> Cancelar</div>
                                            :
                                            <div  key="btn_add_div"><FaPlus /> Adicionar nova custa processual</div>
                                            }
                                            
                                    </Button>
                                    &nbsp;
                                </h5>

                                <Collapse isOpen={this.state.add_custas} style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                    <Row>
                                        <Col md={12}>
                                            <label htmlFor="custa_codigo_barras">Código de Barras</label>
                                            <Input
                                                    name="custa_codigo_barras"
                                                    placeholder=""
                                                    value={this.state.custa_codigo_barras}
                                                    type="text"
                                                    onChange={this.handleInputCodigoBarrasChange}
                                                />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <label htmlFor="tipo_custa">Tipo de custa</label>
                                            <Select
                                                name="tipo_custa"
                                                placeholder="Selecione..."
                                                options={tipo_custas_options}
                                                onChange={(tipo_custas_select) => {
                                                    this.setState({tipo_custas_select})
                                                    this.setState({tipo_custas: tipo_custas_select.value});
                                                }}
                                                value={this.state.tipo_custas_select}
                                                />
                                        </Col>
                                        <Col md={6}>
                                            <label htmlFor="custas_processuais">Valor custa processual</label>
                                            <CurrencyInput 
                                                ref={this.refValorCustaProcessual}
                                                inputMode="decimal"
                                                className="form-control input-currency" 
                                                currency="BRL"
                                                name="custas_processuais"
                                                value={this.state.custas_processuais}
                                                defaultValue={0.00}
                                                onChange={(event) => {this.handleInputChange(event)}}
                                                onKeyUp={(event) => {this.handleInputChange(event)}}
                                                />
                                                <small className="currency-simbol">R$</small>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <label htmlFor="custa_vencimento_valor">Vencimento</label>
                                            <Input
                                                    name="custa_vencimento_valor"
                                                    placeholder=""
                                                    value={this.state.custa_vencimento_valor}
                                                    type="date"
                                                    onChange={this.handleInputChange}
                                                />
                                        </Col>
                                        <Col md={6}>
                                            <label htmlFor="custa_data_pagamento">Data de Pagamento</label>
                                            <Input
                                                    name="custa_data_pagamento"
                                                    placeholder=""
                                                    value={this.state.custa_data_pagamento}
                                                    type="date"
                                                    onChange={this.handleInputChange}
                                                />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>

                                                    <label htmlFor="custas_anexo">Documento de custas</label>
                                                    <Dropzone onDrop={(drop) => this.onDropCustas(drop)}>
                                                        {({ getRootProps, getInputProps }) => (
                                                        <DropArea {...getRootProps({
                                                            onClick: event => event.stopPropagation()
                                                        })}>
                                                            <input {...getInputProps()} />
                                                                {this.state.file.custas_anexo[this.state.custas_position] !== undefined && 
                                                                this.state.file.custas_anexo[this.state.custas_position].length > 0 ? 
                                                                    <>
                                                                        <Row> 
                                                                            {this.state.file.custas_anexo[this.state.custas_position].map((e, index) => {
                                                                                return <Col key={index} md={12} style={{paddingBottom: 4}}>
                                                                                    <Card>
                                                                                        <p style={{margin: '3px'}}>
                                                                                            <MdAttachFile style={{width: '32px'}}/>
                                                                                            {e.name}&nbsp; 
                                                                                            <Button 
                                                                                                style={{padding: 0, border: 3, float: 'right'}} 
                                                                                                color="danger" 
                                                                                                outline 
                                                                                                onClick={
                                                                                                    () => this.removeCustasAttach(this.state.custas_position, index)
                                                                                                }>
                                                                                                    <MdClear style={{padding: 0}}/>
                                                                                            </Button>
                                                                                        </p>
                                                                                    </Card>
                                                                                </Col>
                                                                            })}
                                                                            <br/>
                                                                        </Row>
                                                                        <p style={{ margin: 0 }}>
                                                                        <MdAttachFile style={{ width: '32px' }} />
                                                                            Arraste o arquivo ou&nbsp;
                                                                            <ButtonUpload 
                                                                                onClick={() => this.inputFileInserCusta.current.click()} 
                                                                                className="btn btn-dark" 
                                                                                color="primary">
                                                                                Clique Aqui
                                                                            </ButtonUpload>
                                                                            <input type="file" ref={this.inputFileInserCusta} hidden onChange={
                                                                                (event) => {this.handleFileCustasChange(event)}
                                                                            } />
                                                                    </p>
                                                                </>
                                                                :
                                                                <>
                                                                    <p style={{ margin: 0 }}>
                                                                        <MdAttachFile style={{ width: '32px' }} />
                                                                            Arraste o arquivo ou&nbsp;
                                                                            <ButtonUpload 
                                                                                onClick={() => this.inputFileInserCusta.current.click()} 
                                                                                className="btn btn-dark" 
                                                                                color="primary">
                                                                                Clique Aqui
                                                                            </ButtonUpload>
                                                                            <input type="file" ref={this.inputFileInserCusta} hidden onChange={
                                                                                (event) => {this.handleFileCustasChange(event)}
                                                                            } />
                                                                    </p>
                                                                </>
                                                            }
                                                        </DropArea>
                                                    )}
                                                    </Dropzone>


                                            
                                        </Col>
                                    {/* </Row>
                                    <Row> */}
                                        <Col md={6}>
                                            <label htmlFor="custas_comprovante_pagamento">Comprovante de pagamento</label>
                                            <Dropzone onDrop={(drop) => this.onDropCustasComprovante(drop)}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <DropArea {...getRootProps({
                                                        onClick: event => event.stopPropagation()
                                                    })}>
                                                        <input {...getInputProps()} />
                                                        {this.state.file.custas_comprovante_pagamento[this.state.custas_position] !== undefined && 
                                                        this.state.file.custas_comprovante_pagamento[this.state.custas_position].length > 0 ? 
                                                            <>
                                                                <Row> 
                                                                    {this.state.file.custas_comprovante_pagamento[this.state.custas_position].map((e, index) => {
                                                                        return <Col key={index} md={12} style={{paddingBottom: 4}}>
                                                                            <Card>
                                                                                <p style={{margin: '3px'}}>
                                                                                    <MdAttachFile style={{width: '32px'}}/>
                                                                                    {e.name}&nbsp; 
                                                                                    <Button 
                                                                                        style={{padding: 0, border: 3, float: 'right'}} 
                                                                                        color="danger" 
                                                                                        outline 
                                                                                        onClick={
                                                                                            () => this.removeCustasComprovanteAttach(this.state.custas_position, index)
                                                                                        }>
                                                                                            <MdClear style={{padding: 0}}/>
                                                                                    </Button>
                                                                                </p>
                                                                            </Card>
                                                                        </Col>
                                                                    })}
                                                                    <br/>
                                                                </Row>
                                                                <p style={{ margin: 0 }}>
                                                                <MdAttachFile style={{ width: '32px' }} />
                                                                    Arraste o arquivo ou&nbsp;
                                                                    <ButtonUpload 
                                                                        onClick={() => this.inputFileInserCustaPagamento.current.click()} 
                                                                        className="btn btn-dark" 
                                                                        color="primary">
                                                                        Clique Aqui
                                                                    </ButtonUpload>
                                                                    <input type="file" ref={this.inputFileInserCustaPagamento} hidden onChange={
                                                                        (event) => {this.handleFileCustasComprovanteChange(event)}
                                                                    } />
                                                            </p>
                                                        </>
                                                        :
                                                        <>
                                                            <p style={{ margin: 0 }}>
                                                                <MdAttachFile style={{ width: '32px' }} />
                                                                    Arraste o arquivo ou&nbsp;
                                                                    <ButtonUpload 
                                                                        onClick={() => this.inputFileInserCustaPagamento.current.click()} 
                                                                        className="btn btn-dark" 
                                                                        color="primary">
                                                                        Clique Aqui
                                                                    </ButtonUpload>
                                                                    <input type="file" ref={this.inputFileInserCustaPagamento} hidden onChange={
                                                                        (event) => {this.handleFileCustasComprovanteChange(event)}
                                                                    } />
                                                            </p>
                                                        </>
                                                    }
                                                </DropArea>
                                                )}
                                            </Dropzone>
                                            
                                        </Col>
                                    </Row>
                        
                                    <Row>
                                        <Col md={12}>
                                            <Button
                                                color="success"
                                                onClick={(e) => {
                                                    this.handleAddNewCusta()
                                                }}
                                                >
                                                    <FaSave /> Salvar Custas
                                            </Button>
                                        </Col>
                                    </Row>
                                    <hr/>
                                </Collapse>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                <h5>
                                    Custas Processuais

                                </h5><hr/>

                                <Row hidden={!this.state.loading_custas_processual}>
                                    <Col md={12}>
                                        <center>
                                            <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                                        </center>
                                        <br/>
                                    </Col>
                                </Row>
                                
                                {this.state.list_tipo_custas.map((custa, i) => { 
                                    custas_tipo_refs[i] = React.createRef();
                                    custas_valor_refs[i] = React.createRef();

                                    this.inputFileCustas[i] = React.createRef();

                                    // ref={this.inputFileCustas}
                                    if(this.state.list_tipo_custas[i].custas_vencimento) {
                                        let custa_vencimento = new Date(this.state.list_tipo_custas[i].custas_vencimento);
                                        let custa_vencimentomonth = custa_vencimento.getUTCMonth() + 1;
                                        custa_vencimentomonth = custa_vencimentomonth < 10 ? '0' + custa_vencimentomonth : custa_vencimentomonth;
                                        let custa_vencimentoday = custa_vencimento.getUTCDate();
                                        let custa_vencimentoyear = custa_vencimento.getUTCFullYear();
                                        var custa_vencimento_date = custa_vencimentoday + '/' + custa_vencimentomonth + '/' + custa_vencimentoyear;
                                    } else {
                                        var custa_vencimento_date = '';
                                    }

                                    return (
                                        <div key={'custasdiv_'+i}>
                                            <div style={{padding: '1rem', marginBottom: '1rem', borderRadius: '5px', border: '1px solid rgb(222, 226, 230)', borderLeft: '5px solid #28a745'}}>
                                            <Row>
                                                    <Col md={12}>
                                                    {this.state.list_tipo_custas[i].edit ?
                                                            <></>
                                                        :
                                                            <Button
                                                                color="success"
                                                                style={{float: 'left'}}
                                                                onClick={(e) => { 
                                                                    let listTipoCustas = this.state.list_tipo_custas;
                                                                    
                                                                    listTipoCustas.map((f, p)=>{
                                                                        if (p == i) {
                                                                            listTipoCustas[i].edit = true;
                                                                        }
                                                                    })

                                                                    this.setState({list_tipo_custas: listTipoCustas})
                                                                }}
                                                                >
                                                                    <FaPen /> Editar Custa
                                                            </Button>

                                                        }

                                                        <Button 
                                                            style={{
                                                                padding: 0, 
                                                                border: 3, 
                                                                float: 'right',
                                                                position: 'absolute',
                                                                right: '-16px',
                                                                top: '-26px',
                                                            }} 
                                                            color="danger" 
                                                            outline 
                                                            onClick={(e) => { 
                                                                this.handleRemoveCustas(this.state.list_tipo_custas[i], i)
                                                            }}>
                                                                <MdClear style={{padding: 0}}/>
                                                        </Button>
                                                    </Col>
                                                </Row>                                  
                                                <Row hidden={custa.edit}>
                                                    <Col md={12}>
                                                        <p><strong>Tipo: </strong> {this.state.list_tipo_custas[i].custas_select.value}</p>
                                                        <p><strong>Valor: </strong> R$ {this.state.list_tipo_custas[i].custas_valor !== undefined && this.state.list_tipo_custas[i].custas_valor != ''  && this.state.list_tipo_custas[i].custas_valor != null ? this.state.list_tipo_custas[i].custas_valor.replace('.', ',') : '0,00'}</p>
                                                        <p><strong>Vencimento:</strong> {custa_vencimento_date}</p>
                                                    </Col>
                                                </Row>
                                                <Collapse isOpen={custa.edit}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <label htmlFor="custa_codigo_barras">Código de Barras</label>
                                                            <Input
                                                                    name="custa_codigo_barras"
                                                                    placeholder=""
                                                                    value={this.state.list_tipo_custas[i].custa_codigo_barras}
                                                                    type="text"
                                                                    onChange={(event) => {
                                                                        let array_list_tipo_custas = this.state.list_tipo_custas;
                                                                        array_list_tipo_custas[i]['custa_codigo_barras'] = event.target.value;
                                                                        
                                                                        this.setState({list_tipo_custas: array_list_tipo_custas})
                                                                        this.handleInputCodigoBarrasChange(event)
                                                                    }}
                                                                />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <label htmlFor={"custas_tipo_"+i}>Tipo de custa</label>
                                                            <Select
                                                                ref={custas_tipo_refs[i]}
                                                                name={"custas_tipo_"+i}
                                                                placeholder="Selecione..."
                                                                options={tipo_custas_options}
                                                                onChange={(tipo_custas_select) => {
                                                                    let custas_select = this.state.list_tipo_custas;

                                                                    custas_select[i]['custas_tipo'] = custas_tipo_refs[i].current.state.value.value;
                                                                    custas_select[i]['custas_select'] = tipo_custas_select;

                                                                    this.setState({list_tipo_custas: custas_select})
                                                                }}
                                                                value={this.state.list_tipo_custas[i].custas_select}
                                                            />
                                                        </Col>
                                                        <Col md={6}>
                                                            <label htmlFor={"custas_valor_"+i}>Custas processuais</label>
                                                            <CurrencyInput 
                                                                ref={custas_valor_refs[i]}
                                                                inputMode="decimal"
                                                                className="form-control input-currency" 
                                                                currency="BRL"
                                                                name={"custas_valor_"+i}
                                                                defaultValue={this.state.list_tipo_custas[i].custas_valor}
                                                                // onChange={(event) => {
                                                                //     let array_list_tipo_custas = this.state.list_tipo_custas;
                                                                //     array_list_tipo_custas[i]['custas_valor'] = event.target.value;
                                                                    
                                                                //     // this.state.list_tipo_custas[i].custas_valor
                                                                //     this.setState({list_tipo_custas: array_list_tipo_custas})
                                                                // }}
                                                                onKeyUp={(event) => {
                                                                    let array_list_tipo_custas = this.state.list_tipo_custas;
                                                                    array_list_tipo_custas[i]['custas_valor'] = event.target.value;
                                                                    
                                                                    // this.state.list_tipo_custas[i].custas_valor
                                                                    this.setState({list_tipo_custas: array_list_tipo_custas})
                                                                }}
                                                                />
                                                                <small className="currency-simbol">R$</small>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <label htmlFor="custas_vencimento">Vencimento</label>
                                                            <Input
                                                                    name="custas_vencimento"
                                                                    placeholder=""
                                                                    value={this.state.list_tipo_custas[i].custas_vencimento}
                                                                    type="date"
                                                                    onChange={(event) => {
                                                                        let array_list_tipo_custas = this.state.list_tipo_custas;
                                                                        array_list_tipo_custas[i]['custas_vencimento'] = event.target.value;

                                                                        this.setState({list_tipo_custas: array_list_tipo_custas})
                                                                    }}
                                                                />
                                                        </Col>
                                                        <Col m={6}>
                                                        <label htmlFor="custas_vencimento">Data de Pagamento</label>
                                                            <Input
                                                                name="custas_vencimento"
                                                                placeholder=""
                                                                value={this.state.list_tipo_custas[i].custa_data_pagamento}
                                                                type="date"
                                                                onChange={(event) => {
                                                                    let array_list_tipo_custas = this.state.list_tipo_custas;
                                                                    array_list_tipo_custas[i]['custa_data_pagamento'] = event.target.value;
                                                                    
                                                                    this.setState({list_tipo_custas: array_list_tipo_custas})
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Row hidden={this.state.list_tipo_custas[i].files !== undefined && this.state.list_tipo_custas[i].files.length > 0 ? true : false}>
                                                        <Col md={12}>

                                                            <label htmlFor="custas_anexo">Documento de custas</label>
                                                            <Dropzone onDrop={(drop) => this.onDropCustas(drop)}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                <DropArea {...getRootProps({
                                                                    onClick: event => event.stopPropagation()
                                                                })}>
                                                                    <input {...getInputProps()} />
                                                                        {this.state.file.custas_anexo[i] !== undefined && this.state.file.custas_anexo[i].length > 0 ? 
                                                                            <>
                                                                                <Row> 
                                                                                    {this.state.file.custas_anexo[i].map((e, index) => {
                                                                                        return <Col key={index} md={4} style={{paddingBottom: 4}}>
                                                                                            <Card>
                                                                                                <p style={{margin: '3px'}}>
                                                                                                    <MdAttachFile style={{width: '32px'}}/>
                                                                                                    {e.name}&nbsp; 
                                                                                                    <Button 
                                                                                                        style={{padding: 0, border: 3, float: 'right'}} 
                                                                                                        color="danger" 
                                                                                                        outline 
                                                                                                        onClick={
                                                                                                            () => this.removeCustasAttach(i, index)
                                                                                                        }>
                                                                                                            <MdClear style={{padding: 0}}/>
                                                                                                    </Button>
                                                                                                </p>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    })}
                                                                                    <br/>
                                                                                </Row>
                                                                                <p style={{ margin: 0 }}>
                                                                                <MdAttachFile style={{ width: '32px' }} />
                                                                                    Arraste o arquivo ou&nbsp;
                                                                                    <ButtonUpload 
                                                                                        onClick={() => this.inputFileCustas[i].current.click()} 
                                                                                        className="btn btn-dark" 
                                                                                        color="primary">
                                                                                        Clique Aqui
                                                                                    </ButtonUpload> para adicionar mais anexos
                                                                                    <input type="file" ref={this.inputFileCustas[i]} multiple hidden onChange={
                                                                                        (event) => { this.handleFileCustasChange(event, i)}
                                                                                    } />
                                                                            </p>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <p style={{ margin: 0 }}>
                                                                                <MdAttachFile style={{ width: '32px' }} />
                                                                                    Arraste o arquivo ou&nbsp;
                                                                                    <ButtonUpload 
                                                                                        onClick={() => this.inputFileCustas[i].current.click()} 
                                                                                        className="btn btn-dark" 
                                                                                        color="primary">
                                                                                        Clique Aqui
                                                                                    </ButtonUpload> para adicionar anexos
                                                                                    <input type="file" ref={this.inputFileCustas[i]} multiple hidden onChange={
                                                                                        (event) => { this.handleFileCustasChange(event, i)}
                                                                                    } />
                                                                            </p>
                                                                        </>
                                                                    }
                                                                </DropArea>
                                                            )}
                                                            </Dropzone>
                                                    
                                                        </Col>
                                                    </Row>
                                                </Collapse>
                                                
                                                {this.state.file.custas_anexo[i] !== undefined && this.state.file.custas_anexo[i].length > 0 ? 
                                                    <div key={'custa_anexo_div_'+i}>
                                                        <Row hidden={true}>
                                                                {this.state.file.custas_anexo.map((url, ps)=>{ 
                                                                    return(
                                                                        <Col md={12} className="col-cards">
                                                                            <Card style={{marginBottom: '1rem'}}>
                                                                                <p style={{margin: '3px'}}>
                                                                                    <Row>
                                                                                        <Col md={6}>
                                                                                            <MdAttachFile style={{width: '32px'}}/>
                                                                                            Anexo Arquivo Custa&nbsp; 
                                                                                            {/* <Button 
                                                                                                style={{padding: 0, border: 3, float: 'right'}} 
                                                                                                color="danger" 
                                                                                                outline 
                                                                                                onClick={
                                                                                                    () => this.removePecaDefesaMultiDelAttach(url, i, ps)
                                                                                                }
                                                                                                >
                                                                                                    <MdClear style={{padding: 0}}/>
                                                                                            </Button> */}
                                                                                        </Col>
                                                                                        <Col md={6}>
                                                                                        <label htmlFor="custas_vencimento">Data de Pagamento</label>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col md={6}>
                                                                                            
                                                                                        </Col>
                                                                                        <Col md={6}>
                                                                                            <Input
                                                                                                    name="custa_data_pagamento[]"
                                                                                                    placeholder=""
                                                                                                    value={this.state.list_tipo_custas[i].custa_data_pagamento}
                                                                                                    type="date"
                                                                                                    onChange={(event) => {
                                                                                                        let array_list_tipo_custas = this.state.list_tipo_custas;
                                                                                                        array_list_tipo_custas[i]['custa_data_pagamento'] = event.target.value;
                                                                                                        
                                                                                                        this.setState({list_tipo_custas: array_list_tipo_custas})
                                                                                                    }}
                                                                                                />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </p>
                                                                            </Card>
                                                                        </Col>
                                                                    )
                                                                })}
                                                                </Row>


                                                                <Collapse isOpen={this.state.open_iframe_custa[i]} style={{textAlign: 'center'}}>
                                                                    <Button
                                                                        color="danger"
                                                                        outline
                                                                        style={{marginBottom: '1rem'}}
                                                                        onClick={(e) => { 
                                                                            let state = this.state;
                                                                            state.open_iframe_custa[i] = !this.state.open_iframe_custa[i];

                                                                            this.setState({...state})
                                                                        }}
                                                                        >
                                                                            <FaRegWindowClose /> Fechar
                                                                    </Button>
                                                                    {this.state.url_iframe_custas[i] !== undefined ? 
                                                                    <iframe 
                                                                        className="file-iframe"
                                                                        src={this.state.url_iframe_custas[i]} >
                                                                    </iframe>
                                                                    :
                                                                    <></>
                                                                    }

                                                                </Collapse>
                                                                </div>
                                                :
                                                    <></>
                                                }
                                                {this.state.list_tipo_custas[i].files !== undefined && 
                                                    this.state.list_tipo_custas[i].files !== null ? 
                                                        <div key={'custas_files_div_'+i}>
                                                        <Row>
                                                        {this.state.list_tipo_custas[i].files.map((filecusta, ps)=>{ 
                                                            return(
                                                                <Col md={12} className="col-cards">
                                                                    <Card style={{marginBottom: '1rem'}}>
                                                                        <p style={{margin: '3px'}}>
                                                                            {this.state.file.custas_anexo[i] !== undefined && this.state.file.custas_anexo[i].length > 0 ? 
                                                                            <Row> 
                                                                                {this.state.file.custas_anexo[i].map((e, index) => {
                                                                                    return <Col key={index} md={6} style={{paddingBottom: 4}}>
                                                                                        <Card>
                                                                                            <p style={{margin: '3px'}}>
                                                                                                <MdAttachFile style={{width: '32px'}}/>
                                                                                                {e.name}&nbsp; 
                                                                                                <Button 
                                                                                                    style={{padding: 0, border: 3, float: 'right'}} 
                                                                                                    color="danger" 
                                                                                                    outline 
                                                                                                    onClick={
                                                                                                        () => this.removeCustasAttach(i, index)
                                                                                                    }>
                                                                                                        <MdClear style={{padding: 0}}/>
                                                                                                </Button>
                                                                                            </p>
                                                                                        </Card>
                                                                                    </Col>
                                                                                })}
                                                                            </Row>
                                                                            :
                                                                            <></>
                                                                            }
                                                                            <Row>
                                                                                <Col md={8}>
                                                                                    <Button 
                                                                                        style={{padding: 0, border: 3, float: 'left'}} 
                                                                                        color="danger" 
                                                                                        outline 
                                                                                        onClick={
                                                                                            () => this.removeCustasFileAttach(this.state.list_tipo_custas[i], i, ps)
                                                                                        }
                                                                                        >
                                                                                            <MdClear style={{padding: 0}}/>
                                                                                    </Button>
                                                                                    <MdAttachFile style={{width: '32px'}}/>
                                                                                    Anexo Arquivo Custa&nbsp; 
        
                                                                                </Col>
                                                                                <Col md={4}>
                                                                                    <label htmlFor="custas_vencimento">Data de Pagamento</label>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md={8}>
                                                                                    <Button 
                                                                                    className="btn btn-info btn-arquivo" 
                                                                                    outline 
                                                                                    onClick={
                                                                                        () => {
                                                                                            let state = this.state;
                                                                                            state.open_iframe_custa[i] = true;
                                                                                            state.url_iframe_custas[i] = filecusta.url;

                                                                                            this.setState({...state})
                                                                                        }
                                                                                    }>
                                                                                        <span><FaEye /> Visualizar</span>
                                                                                    </Button>

                                                                                    <Button 
                                                                                    className="btn btn-info btn-arquivo" 
                                                                                    outline 
                                                                                    onClick={
                                                                                        () => window.open(filecusta.url, '_blank').focus()
                                                                                    }>
                                                                                        <span><FaFilePdf /> PDF Viewer</span>
                                                                                    </Button>

                                                                                    <Button 
                                                                                    color="success"
                                                                                    onClick={() => {
                                                                                        let listTipoCustas = this.state.list_tipo_custas;
                                                                    
                                                                                        listTipoCustas.map((f, p)=>{
                                                                                            if (p == i) {
                                                                                                listTipoCustas[i].edit = true;
                                                                                            }
                                                                                        })
                    
                                                                                        this.setState({list_tipo_custas: listTipoCustas})

                                                                                        this.inputFileCustas[i].current.click()
                                                                                    }} >
                                                                                        <span><FaPen /> Alterar</span>
                                                                                    </Button>

                                                                                </Col>
                                                                                <Col md={4}>
                                                                                    {/* {moment(this.state.list_tipo_custas[i].custa_data_pagamento).format('DD/MM/YYYY')} */}
                                                                                    <Input
                                                                                        name="custas_vencimento"
                                                                                        placeholder=""
                                                                                        value={this.state.list_tipo_custas[i].custa_data_pagamento}
                                                                                        type="date"
                                                                                        disabled={true}
                                                                                        // onChange={(event) => {
                                                                                        //     let array_list_tipo_custas = this.state.list_tipo_custas;
                                                                                        //     array_list_tipo_custas[i] = {
                                                                                        //         'custas_valor': this.state.list_tipo_custas[i].custas_valor,
                                                                                        //         'custas_tipo': this.state.list_tipo_custas[i].custas_tipo,
                                                                                        //         'custas_vencimento': event.target.value,
                                                                                        //         'custas_select': this.state.list_tipo_custas[i].custas_select
                                                                                        //     };
                                                                                            
                                                                                        //     this.setState({list_tipo_custas: array_list_tipo_custas})
                                                                                        // }}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        </p>
                                                                    </Card>
                                                                </Col>
                                                            )
                                                        })}
                                                        </Row>


                                                        <Collapse isOpen={this.state.open_iframe_custa[i]} style={{textAlign: 'center'}}>
                                                            <Button
                                                                color="danger"
                                                                outline
                                                                style={{marginBottom: '1rem'}}
                                                                onClick={(e) => { 
                                                                    let state = this.state;
                                                                    state.open_iframe_custa[i] = !this.state.open_iframe_custa[i];

                                                                    this.setState({...state})
                                                                }}
                                                                >
                                                                    <FaRegWindowClose /> Fechar
                                                            </Button>
                                                            {this.state.url_iframe_custas[i] !== undefined ? 
                                                            <iframe 
                                                                className="file-iframe"
                                                                src={this.state.url_iframe_custas[i]} >
                                                            </iframe>
                                                            :
                                                            <></>
                                                            }

                                                        </Collapse>
                                                    </div>
                                                :
                                                    <></>
                                                }

                                                {this.state.list_tipo_custas[i].edit ?
                                                    <div key={'butoneditcustas_'+i}>
                                                        <Button
                                                            color="success"
                                                            onClick={(e) => {
                                                                let state = this.state;
                                                                state.list_tipo_custas[i].edit = false;
                                                                this.setState({...state})

                                                                this.handeEditCustasChenge(this.state.list_tipo_custas[i], i)
                                                            }}
                                                            >
                                                                <FaSave /> Salvar Custas
                                                        </Button>

                                                        <Button
                                                            color="danger"
                                                            style={{float: 'right'}}
                                                            onClick={(e) => { 
                                                                let listTipoCustas = this.state.list_tipo_custas;
                                                                
                                                                listTipoCustas.map((f, p)=>{
                                                                    if (p == i) {
                                                                        listTipoCustas[i].edit = false;
                                                                    }
                                                                })

                                                                this.setState({list_tipo_custas: listTipoCustas})
                                                            }}
                                                            >
                                                                <FaRegWindowClose /> Cancelar
                                                        </Button>
                                                    </div>
                                                :
                                                    <></>
                
                                                }
                                            </div>

                                        </div>
                                    )
                                })}


                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <label htmlFor="estado">Estado</label>
                            <Select
                            name="estado"
                            placeholder="Selecione..."
                            className={this.state.valid_input['estado'] !== undefined ? 'invalid' : '' }
                            options={estados_options}
                            onChange={(estado_select) => {
                                this.setState({estado_select})
                                this.setState({estado: estado_select.value});
                            }}
                            value={this.state.estado_select}
                                />
                        </Col>
                        <Col md={6}>
                            <label htmlFor="tipo_acao">Tipo de ação</label>
                            <Input
                                name="tipo_acao"
                                placeholder=""
                                className={this.state.valid_input['tipo_acao'] !== undefined ? 'invalid' : '' }
                                value={this.state.tipo_acao}
                                type="text"
                                onChange={this.handleInputChange}
                            />
                        </Col>
                    </Row>

                    {this.state.is_open_reclamado === true ?
                        <Row>
                            <Col md={6}>
                                <label htmlFor="reclamante">Reclamante</label>
                                <Input
                                    name="reclamante"
                                    placeholder=""
                                    className={this.state.valid_input['reclamante'] !== undefined ? 'invalid' : '' }
                                    value={this.state.reclamante}
                                    type="text"
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                            <Col md={6}>
                                <label htmlFor="reclamado">Reclamado</label>
                                <Select
                                name="reclamado"
                                placeholder="Selecione..."
                                options={this.state.reclamados_options}
                                onChange={(reclamado_select) => {
                                    this.setState({reclamado_select})
                                    this.setState({reclamado: reclamado_select.value});
                                }}
                                value={this.state.reclamado_select}
                                    />
                            </Col>
                        </Row>
                    :
                        <Row>
                            <Col md={6}>
                                <label htmlFor="reclamado">Reclamante</label>
                                <Select
                                name="reclamado"
                                placeholder="Selecione..."
                                options={this.state.reclamados_options}
                                onChange={(reclamado_select) => {
                                    this.setState({reclamado_select})
                                    this.setState({reclamado: reclamado_select.value});
                                }}
                                value={this.state.reclamado_select}
                                    />
                            </Col>
                            <Col md={6}>
                                <label htmlFor="reclamante">Reclamado</label>
                                <Input
                                    name="reclamante"
                                    placeholder=""
                                    disabled="true"
                                    value={this.state.seller_name}
                                    type="text"
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                        </Row>
                    }

                    {this.state.is_open_reclamado === true ?
                        <Row>
                            <Col md={12}>
                                <label htmlFor="advogado_reclamante">Advogado reclamante</label>
                                <Input
                                    name="advogado_reclamante"
                                    placeholder=""
                                    value={this.state.advogado_reclamante}
                                    type="text"
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                        </Row>
                    :
                        <Row>
                            <Col md={6}>
                                <label htmlFor="advogado_aqbank">Advogado AQBank</label>
                                <Select
                                name="advogado_aqbank"
                                placeholder="Selecione..."
                                className={this.state.valid_input['advogado_aqbank'] !== undefined ? 'invalid' : '' }
                                options={this.state.advogado_aqbank_options}
                                onChange={(advogado_aqbank_select) => {
                                    this.setState({advogado_aqbank_select})
                                    this.setState({advogado_aqbank: advogado_aqbank_select.value});
                                }}
                                value={this.state.advogado_aqbank_select}
                                    />
                            </Col>
                        </Row>
                    }

                    {this.state.is_open_reclamado === true ?
                        <></>
                    :
                        <Row>
                            <Col md={6}>
                                <label htmlFor="advogado_reclamante">Advogado reclamado</label>
                                <Input
                                    name="advogado_reclamante"
                                    placeholder=""
                                    value={this.state.advogado_reclamante}
                                    type="text"
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                            <Col md={6}>
                                <label htmlFor="advogado_contato">Contato do Advogado</label>
                                <Input
                                    name="advogado_contato"
                                    placeholder=""
                                    value={this.state.advogado_contato}
                                    type="text"
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                        </Row>
                    }

                    {this.state.is_open_reclamado === true ?
                        <Row>
                            <Col md={6}>
                                <label htmlFor="advogado_contato">Contato do Advogado</label>
                                <Input
                                    name="advogado_contato"
                                    placeholder=""
                                    value={this.state.advogado_contato}
                                    type="text"
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                            <Col md={6}>
                                <label htmlFor="advogado_aqbank">Advogado AQBank</label>
                                <Select
                                name="advogado_aqbank"
                                placeholder="Selecione..."
                                options={this.state.advogado_aqbank_options}
                                onChange={(advogado_aqbank_select) => {
                                    this.setState({advogado_aqbank_select})
                                    this.setState({advogado_aqbank: advogado_aqbank_select.value});
                                }}
                                value={this.state.advogado_aqbank_select}
                                    />
                            </Col>
                        </Row>
                    :
                        <></>
                    }

                    <Row>
                        <Col md={6}>
                            <label htmlFor="forma_citacao">Forma de citação</label>
                            <Select
                            name="forma_citacao"
                            placeholder="Selecione..."
                            className={this.state.valid_input['forma_citacao'] !== undefined ? 'invalid' : '' }
                            options={this.state.forma_citacao_options}
                            onChange={(forma_citacao_select) => {
                                this.setState({forma_citacao_select})
                                this.setState({forma_citacao: forma_citacao_select.value});
                            }}
                            value={this.state.forma_citacao_select}
                                />
                        </Col>
                    
                        
                        <Col md={6}>
                            <Collapse isOpen={this.state.forma_citacao === 2}>
                                <label htmlFor="data_publicacao_edital">Data publicação do edital</label>
                                <Input
                                    name="data_publicacao_edital"
                                    placeholder=""
                                    value={this.state.data_publicacao_edital}
                                    type="date"
                                    onChange={this.handleInputChange}
                                />
                            </Collapse>

                            
                            <Collapse isOpen={this.state.forma_citacao === 1}>
                                <label htmlFor="numero_ar">Número da AR</label>
                                <Input
                                    name="numero_ar"
                                    placeholder=""
                                    value={this.state.numero_ar}
                                    type="text"
                                    onChange={this.handleInputChange}
                                />
                            </Collapse>
                        </Col>
                        
                    </Row>

                    <Collapse isOpen={this.state.forma_citacao === 1 || this.state.forma_citacao === 3}>
                        <Row>
                            <Col md={6}>
                                <label htmlFor="data_citacao">Data de citação recebida</label>
                                <Input
                                    name="data_citacao"
                                    placeholder=""
                                    value={this.state.data_citacao}
                                    type="date"
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                            <Col md={6}>
                                <label htmlFor="hora_citacao">Hora de citação recebida</label>
                                <InputMasked
                                            mask="99:99"
                                            placeholder="00:00"
                                            name="hora_citacao"
                                            value={this.state.hora_citacao}
                                            onChange={this.handleInputChange}
                                        />
                            </Col>
                        </Row>
                    </Collapse>


                    <Row>
                            <Col md={12}>
                                <label htmlFor="inicial_processo">Inicial do processo anexo</label>
                                <Dropzone onDrop={(drop) => this.onDrop(drop)}>
                                    {({ getRootProps, getInputProps }) => (
                                    <DropArea {...getRootProps({
                                        onClick: event => event.stopPropagation()
                                    })}>
                                        <input {...getInputProps()} />
                                            {this.state.file.inicial_processo.length > 0 ? 
                                                <>
                                                    <Row> 
                                                        {this.state.file.inicial_processo.map((e, index) => {
                                                            return <Col key={index} md={4} style={{paddingBottom: 4}}>
                                                                <Card>
                                                                    <p style={{margin: '3px'}}>
                                                                        <MdAttachFile style={{width: '32px'}}/>
                                                                        {e.name}&nbsp; 
                                                                        <Button 
                                                                            style={{padding: 0, border: 3, float: 'right'}} 
                                                                            color="danger" 
                                                                            outline 
                                                                            onClick={
                                                                                () => this.removeAttach(index)
                                                                            }>
                                                                                <MdClear style={{padding: 0}}/>
                                                                        </Button>
                                                                    </p>
                                                                </Card>
                                                            </Col>
                                                        })}
                                                        <br/>
                                                    </Row>
                                                    <p style={{ margin: 0 }}>
                                                    <MdAttachFile style={{ width: '32px' }} />
                                                        Arraste o arquivo ou&nbsp;
                                                        <ButtonUpload 
                                                            onClick={() => this.inputFile.current.click()} 
                                                            className="btn btn-dark" 
                                                            color="primary">
                                                            Clique Aqui
                                                        </ButtonUpload> para adicionar mais anexos
                                                        <input type="file" ref={this.inputFile} hidden onChange={this.handleFileChange} />
                                                </p>
                                            </>
                                            :
                                            <>
                                                <p style={{ margin: 0 }}>
                                                    <MdAttachFile style={{ width: '32px' }} />
                                                        Arraste o arquivo ou&nbsp;
                                                        <ButtonUpload 
                                                            onClick={() => this.inputFile.current.click()} 
                                                            className="btn btn-dark" 
                                                            color="primary">
                                                            Clique Aqui
                                                        </ButtonUpload> para adicionar anexos
                                                        <input type="file" ref={this.inputFile} hidden onChange={this.handleFileChange} />
                                                </p>
                                            </>
                                        }
                                    </DropArea>
                                )}
                                </Dropzone>

                            </Col>
                        </Row>    
                            {this.state.arquivo_inicial_processo != null ?
                                <Row>
                                    <Col md={12}>
                                        <Button 
                                            className="btn btn-info btn-arquivo" 
                                            outline 
                                            onClick={
                                                () => this.setState({pdf_inicial_open: !this.state.pdf_inicial_open})
                                            }>
                                                <span>Visualizar</span>
                                        </Button>
                                        <Button 
                                            className="btn btn-info btn-arquivo" 
                                            outline 
                                            onClick={
                                                () => window.open(this.state.arquivo_inicial_processo, '_blank').focus()
                                            }>
                                                <span>PDF Viewer</span>
                                        </Button>
                                        <Collapse isOpen={this.state.pdf_inicial_open}>
                                            <iframe 
                                                className="file-iframe"
                                                src={this.state.arquivo_inicial_processo} >
                                                </iframe>
                                        </Collapse>
                                    </Col>
                                </Row>
                            :
                                ''
                            }


                    <Row>
                        <Col md={12}>
                            <label htmlFor="motivo_processual">Motivo Processual</label>
                            <Input
                                name="motivo_processual"
                                placeholder=""
                                className={this.state.valid_input['motivo_processual'] !== undefined ? 'invalid' : '' }
                                value={this.state.motivo_processual}
                                type="textarea"
                                style={{
                                    height: '124px',
                                    resize: 'none'
                                }}
                                onChange={this.handleInputChange}
                                onKeyUp={this.handleInputChange}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <label htmlFor="departamento">Departamento da demanda</label>
                            <Select
                            name="departamento"
                            placeholder="Selecione..."
                            className={this.state.valid_input['departamento'] !== undefined ? 'invalid' : '' }
                            options={this.state.departamento_options}
                            onChange={(departamento_select) => {
                                this.setState({departamento_select})
                                this.setState({departamento: departamento_select.value});
                            }}
                            value={this.state.departamento_select}
                                />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <label htmlFor="alegacao_departamento">Alegação do departamento</label>
                            <Input
                                name="alegacao_departamento"
                                placeholder=""
                                value={this.state.alegacao_departamento}
                                type="textarea"
                                style={{
                                    height: '124px',
                                    resize: 'none'
                                }}
                                onChange={this.handleInputChange}
                            />
                        </Col>
                    </Row>
                    {this.state.is_open_reclamado === true  || this.state.is_open_reclamante === true ?
                    <div key="documentos_processo_div">
                    <Row>
                        <Col md={12}>
                            <label htmlFor="documentos_processo">
                            {this.state.is_open_reclamante === true ? 'Documentos referente ao processo' : 'Documentos provas de defesa'}
                            </label>
                            <Dropzone onDrop={(drop) => this.onDropDocumentosProcesso(drop)}>
                                {({ getRootProps, getInputProps }) => (
                                <DropArea {...getRootProps({
                                    onClick: event => event.stopPropagation()
                                })}>
                                    <input {...getInputProps()} />
                                        {this.state.file.documentos_processo.length > 0 ? 
                                            <>
                                                <Row> 
                                                    {this.state.file.documentos_processo.map((e, index) => {
                                                        return <Col key={index} md={4} style={{paddingBottom: 4}}>
                                                            <Card>
                                                                <p style={{margin: '3px'}}>
                                                                    <MdAttachFile style={{width: '32px'}}/>
                                                                    {e.name}&nbsp; 
                                                                    <Button 
                                                                        style={{padding: 0, border: 3, float: 'right'}} 
                                                                        color="danger" 
                                                                        outline 
                                                                        onClick={
                                                                            () => this.removeDocumentosProcessoAttach(index)
                                                                        }>
                                                                            <MdClear style={{padding: 0}}/>
                                                                    </Button>
                                                                </p>
                                                            </Card>
                                                        </Col>
                                                    })}
                                                    <br/>
                                                </Row>
                                                <p style={{ margin: 0 }}>
                                                <MdAttachFile style={{ width: '32px' }} />
                                                    Arraste o arquivo ou&nbsp;
                                                    <ButtonUpload 
                                                        onClick={() => this.inputFileDocumentosProcesso.current.click()} 
                                                        className="btn btn-dark" 
                                                        color="primary">
                                                        Clique Aqui
                                                    </ButtonUpload> para adicionar mais anexos
                                                    <input type="file" ref={this.inputFileDocumentosProcesso} multiple hidden onChange={this.handleFileDocumentosProcessoChange} />
                                            </p>
                                        </>
                                        :
                                        <>
                                            <p style={{ margin: 0 }}>
                                                <MdAttachFile style={{ width: '32px' }} />
                                                    Arraste o arquivo ou&nbsp;
                                                    <ButtonUpload 
                                                        onClick={() => this.inputFileDocumentosProcesso.current.click()} 
                                                        className="btn btn-dark" 
                                                        color="primary">
                                                        Clique Aqui
                                                    </ButtonUpload> para adicionar anexos
                                                    <input type="file" ref={this.inputFileDocumentosProcesso} multiple hidden onChange={this.handleFileDocumentosProcessoChange} />
                                            </p>
                                        </>
                                    }
                                </DropArea>
                            )}
                            </Dropzone>
                            {this.state.arquivo_documentos_processo !== null && Array.isArray(this.state.arquivo_documentos_processo) ? 
                                <Row className="row-files">
                                    {this.handleLoadIframeDocumentos}
                                    {this.state.arquivo_documentos_processo.map((url, i)=>{                                        
                                        return(
                                            <Col md={6} className="col-cards">
                                                <div className="card" style={{marginTop: '10px' }} >
                                                    <h6>{i+1}º ARQUIVO</h6>
                                                    <Row>
                                                        <Col md={6}>
                                                            <Button 
                                                            className="btn btn-info btn-arquivo" 
                                                            outline 
                                                            onClick={
                                                                () => {
                                                                    let opneIframe = this.state.iframes_documentos_processo;
                                                                    opneIframe[i] = !this.state.iframes_documentos_processo[i];
                                                                    
                                                                    opneIframe.map((v, k)=> {
                                                                        if (k !== i) {
                                                                            opneIframe[k] = false;
                                                                        }
                                                                    })

                                                                    this.setState({iframes_documentos_processo: opneIframe})
                                                                    this.setState({pdf_documentos_processo_open: !this.state.pdf_documentos_processo_open})
                                                                }
                                                            }>
                                                                <span>Visualizar</span>
                                                            </Button>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Button 
                                                            className="btn btn-info btn-arquivo" 
                                                            outline 
                                                            onClick={
                                                                () => window.open(url, '_blank').focus()
                                                            }>
                                                                <span>PDF Viewer</span>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            :
                                <></>
                            }
                        </Col>
                    </Row>
                    <Row>
                        {this.state.arquivo_documentos_processo !== null && Array.isArray(this.state.arquivo_documentos_processo) ?
                            <Col md={12}>
                            {this.state.arquivo_documentos_processo.map((url, i)=>{
                                return (
                                    <Collapse isOpen={this.state.iframes_documentos_processo[i]}>
                                        <iframe 
                                            className="file-iframe"
                                            src={url} >
                                        </iframe>
                                    </Collapse>
                                )
                            })}
                            </Col>
                        :
                            <></>
                        }
                    </Row>
                    </div>
                    :
                    <></>
                    }

                    {this.state.is_open_reclamado === true ?
                        <Row>
                            <Col md={6}>
                                <label htmlFor="pedido_procedente">Pedido é procedente</label>
                                <Select
                                name="pedido_procedente"
                                placeholder="Selecione..."
                                options={this.state.pedido_procedente_options}
                                onChange={(pedido_procedente_select) => {
                                    this.setState({pedido_procedente_select})
                                    this.setState({pedido_procedente: pedido_procedente_select.value});
                                }}
                                value={this.state.pedido_procedente_select}
                                    />
                            </Col>
                        </Row>
                    :
                     <></>
                    }

                    <Row>
                        <Col md={12}>
                        <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                            <h5>
                                Contestações
                            </h5><hr/>
                            <Row>
                                <Col md={6}>
                                    <label htmlFor="prazo_contestacao">Prazo de contestação</label>
                                    <Input
                                        name="prazo_contestacao"
                                        placeholder=""
                                        value={this.state.prazo_contestacao}
                                        type="date"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <label htmlFor="peca_defesa">
                                    Peça de Defesa / Contestação Anexos
                                    </label>
                                    <Dropzone onDrop={(drop) => this.onDropPecaoDefesaProcesso(drop)}>
                                        {({ getRootProps, getInputProps }) => (
                                        <DropArea {...getRootProps({
                                            onClick: event => event.stopPropagation()
                                        })}>
                                            <input {...getInputProps()} />
                                                {this.state.file.peca_defesa.length > 0 ? 
                                                    <>
                                                        <Row> 
                                                            {this.state.file.peca_defesa.map((e, index) => {
                                                                return <Col key={index} md={4} style={{paddingBottom: 4}}>
                                                                    <Card>
                                                                        <p style={{margin: '3px'}}>
                                                                            <MdAttachFile style={{width: '32px'}}/>
                                                                            {e.name}&nbsp; 
                                                                            <Button 
                                                                                style={{padding: 0, border: 3, float: 'right'}} 
                                                                                color="danger" 
                                                                                outline 
                                                                                onClick={
                                                                                    () => this.removePecaDefesaAttach(index)
                                                                                }>
                                                                                    <MdClear style={{padding: 0}}/>
                                                                            </Button>
                                                                        </p>
                                                                    </Card>
                                                                </Col>
                                                            })}
                                                            <br/>
                                                        </Row>
                                                        <p style={{ margin: 0 }}>
                                                        <MdAttachFile style={{ width: '32px' }} />
                                                            Arraste o arquivo ou&nbsp;
                                                            <ButtonUpload 
                                                                onClick={() => this.inputFilePecaDefesaProcesso.current.click()} 
                                                                className="btn btn-dark" 
                                                                color="primary">
                                                                Clique Aqui
                                                            </ButtonUpload> para adicionar mais anexos
                                                            <input type="file" ref={this.inputFilePecaDefesaProcesso} multiple hidden onChange={this.handleFilePecaDefesaProcessoChange} />
                                                    </p>
                                                </>
                                                :
                                                <>
                                                    <p style={{ margin: 0 }}>
                                                        <MdAttachFile style={{ width: '32px' }} />
                                                            Arraste o arquivo ou&nbsp;
                                                            <ButtonUpload 
                                                                onClick={() => this.inputFilePecaDefesaProcesso.current.click()} 
                                                                className="btn btn-dark" 
                                                                color="primary">
                                                                Clique Aqui
                                                            </ButtonUpload> para adicionar anexos
                                                            <input type="file" ref={this.inputFilePecaDefesaProcesso} multiple hidden onChange={this.handleFilePecaDefesaProcessoChange} />
                                                    </p>
                                                </>
                                            }
                                        </DropArea>
                                    )}
                                    </Dropzone>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <label htmlFor="notas_contestacao">Notas da contestação</label>
                                    <Input
                                        name="notas_contestacao"
                                        placeholder="Descrição"
                                        value={this.state.notas_contestacao}
                                        type="textarea"
                                        style={{
                                            height: '124px',
                                            resize: 'none'
                                        }}
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '3rem'}}>
                                <Col md={6}>
                                    <Button 
                                        color="success"
                                        onClick={this.handleAddContestacao}>
                                            <span>Salvar Constestação</span>
                                    </Button>
                                </Col>
                            </Row>
                            <Row hidden={!this.state.loading_contestacao}>
                                <Col md={12}>
                                    <center>
                                        <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                                    </center>
                                    <br/>
                                </Col>
                            </Row>
                            <Row hidden={!this.state.infos_contestacao}>
                                <Col md={12}>
                                    {this.state.list_contestacao !== undefined && this.state.list_contestacao.length > 0 ?
                                        this.state.list_contestacao.map((contestacao, i) => {
                                            return(
                                                <div className="card" style={{marginBottom: '1rem', borderRadius: '5px', border: '1px solid rgb(222, 226, 230)'}}>
                                                    <h6 style={{fontSize: '1.3rem', textTransform: 'capitalize', padding: '0.3rem'}}>
                                                        Prazo de contestação: {moment(contestacao.prazo_contestacao).format('DD/MM/YYYY')}
                                                    </h6>
                                                    {contestacao.notas_contestacao ?
                                                        <div key={'notas_contestacao_div_'+i}>
                                                            <small style={{textTransform: 'uppercase', padding: '1rem'}}>{contestacao.notas_contestacao}</small>
                                                            <hr />
                                                        </div>
                                                    :
                                                        <></>
                                                    }
                                                    {contestacao.notas_salvas !== undefined && contestacao.notas_salvas.length > 0 ?
                                                        contestacao.notas_salvas.map((n, c) => {
                                                            return (
                                                                <div style={{paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
                                                                    <div style={{marginBottom: '1rem', padding: '0.5rem'}}>
                                                                        <p style={{textTransform: 'uppercase', padding: '0.5rem', fontSize: 14}}>{n.nota}</p>
                                                                        <small>
                                                                            <b>Operador:</b> {n.operador !== null ? n.operador : 'Sem Informações'} <br/>
                                                                            {moment(n.data).format('DD/MM/YYYY HH:mm')}&nbsp;<br />
                                                                        </small>                                                                
                                                                    </div>
                                                                    <hr />
                                                                </div>
                                                            )
                                                        })
                                                    :
                                                        <></>
                                                    }
                                                    <Row>
                                                    <Col md={12}>
                                                    <Dropzone onDrop={(drop) => this.onDropPecaoDefesaMultiProcesso(drop, i)}>
                                                        {({ getRootProps, getInputProps }) => (
                                                        <DropArea {...getRootProps({
                                                            onClick: event => event.stopPropagation()
                                                        })}>
                                                            <input {...getInputProps()} />
                                                                {contestacao.files_contestacao !== undefined && contestacao.files_contestacao.length > 0 ? 
                                                                    <>
                                                                        <Row> 
                                                                            {contestacao.files_contestacao.map((e, ps) => {
                                                                                return <Col key={ps} md={4} style={{paddingBottom: 4}}>
                                                                                    <Card>
                                                                                        <p style={{margin: '3px'}}>
                                                                                            <MdAttachFile style={{width: '32px'}}/>
                                                                                            {e.name}&nbsp; 
                                                                                            <Button 
                                                                                                style={{padding: 0, border: 3, float: 'right'}} 
                                                                                                color="danger" 
                                                                                                outline 
                                                                                                onClick={
                                                                                                    () => this.removePecaDefesaMultiAttach(i, ps)
                                                                                                }>
                                                                                                    <MdClear style={{padding: 0}}/>
                                                                                            </Button>
                                                                                        </p>
                                                                                    </Card>
                                                                                </Col>
                                                                            })}
                                                                            <br/>
                                                                        </Row>
                                                                        <p style={{ margin: 0 }}>
                                                                        <MdAttachFile style={{ width: '32px' }} />
                                                                            Arraste o arquivo ou&nbsp;
                                                                            <ButtonUpload 
                                                                                onClick={() => contestacao.inputFilePecaDefesaMultiProcesso.current.click()} 
                                                                                className="btn btn-dark" 
                                                                                color="primary">
                                                                                Clique Aqui
                                                                            </ButtonUpload> para adicionar mais anexos
                                                                            <input type="file" ref={contestacao.inputFilePecaDefesaMultiProcesso} multiple hidden onChange={(event) => {this.handleFilePecaDefesaProcessoMultiChange(event, i)}} />
                                                                    </p>
                                                                </>
                                                                :
                                                                <>
                                                                    <p style={{ margin: 0 }}>
                                                                        <MdAttachFile style={{ width: '32px' }} />
                                                                            Arraste o arquivo ou&nbsp;
                                                                            <ButtonUpload 
                                                                                onClick={() => contestacao.inputFilePecaDefesaMultiProcesso.current.click()} 
                                                                                className="btn btn-dark" 
                                                                                color="primary">
                                                                                Clique Aqui
                                                                            </ButtonUpload> para adicionar anexos
                                                                            <input type="file" ref={contestacao.inputFilePecaDefesaMultiProcesso} multiple hidden onChange={(event) => {this.handleFilePecaDefesaProcessoMultiChange(event, i)}} />
                                                                    </p>
                                                                </>
                                                            }
                                                        </DropArea>
                                                    )}
                                                    </Dropzone>
                                                    </Col>
                                                    </Row>
                                                    <Row> 
                                                    {contestacao.arquivos_contestacao.map((file, ps) => {
                                                        if (file.status == 1) {
                                                            return (
                                                                <Col md={6}>
                                                                    <Card style={{marginBottom: '1rem'}}>
                                                                        <p style={{margin: '3px'}}>
                                                                            <MdAttachFile style={{width: '32px'}}/>
                                                                            {ps + 1}º Arquivo&nbsp; 
                                                                            <Button 
                                                                                style={{padding: 0, border: 3, float: 'right'}} 
                                                                                color="danger" 
                                                                                outline 
                                                                                onClick={
                                                                                    () => this.removePecaDefesaMultiDelAttach(contestacao.id, i, ps)
                                                                                }>
                                                                                    <MdClear style={{padding: 0}}/>
                                                                            </Button>
                                                                        </p>
                                                                    </Card>


                                                                    <Button 
                                                                        className="btn btn-info btn-arquivo" 
                                                                        outline 
                                                                        onClick={
                                                                            () => {
                                                                                let state = this.state;

                                                                                state.url_iframe_contestacao[i] = file.url;
                                                                                state.iframes_contestacoes_processo[i] = true;

                                                                                state.iframes_contestacoes_processo.map((v, k)=> {
                                                                                    if (k !== i) {
                                                                                        state.iframes_contestacoes_processo[k] = false;
                                                                                    }
                                                                                })

                                                                                this.setState({...state})
                                                                            }
                                                                        }>
                                                                        <span>Visualizar</span>
                                                                    </Button>

                                                                    <Button 
                                                                    className="btn btn-info btn-arquivo" 
                                                                    outline 
                                                                    onClick={
                                                                        () => window.open(file.url, '_blank').focus()
                                                                    }>
                                                                        <span>PDF Viewer</span>
                                                                    </Button>
                                                                    
                                                                </Col>
                                                            )
                                                        }
                                                    })}
                                                </Row>
                                                
                                                <Collapse style={{textAlign: 'center'}} isOpen={this.state.iframes_contestacoes_processo[i]}>
                                                    <Button 
                                                        color="danger" 
                                                        outline 
                                                        style={{marginBottom: '1rem'}}
                                                        onClick={() => {
                                                            let state = this.state;

                                                            state.iframes_contestacoes_processo[i] = false;
                                                            this.setState({...state})
                                                        }}>
                                                            Fechar
                                                    </Button>

                                                    <iframe 
                                                        className="file-iframe"
                                                        src={this.state.url_iframe_contestacao[i]}
                                                        >
                                                    </iframe>
                                                </Collapse>

                                                </div>
                                            )
                                        })
                                    :
                                    <></>
                                    }

                                </Col>
                            </Row>


                        </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <label htmlFor="audiencia_marcada">Audiência marcada</label>
                            <Select
                                name="audiencia_marcada"
                                placeholder="Selecione..."
                                className={this.state.valid_input['audiencia_marcada'] !== undefined ? 'invalid' : '' }
                                options={optionsYesNo}
                                onChange={(audiencia_marcada_select) => {
                                    this.setState({audiencia_marcada_select})
                                    this.setState({audiencia_marcada: audiencia_marcada_select.value});
                                }}
                                value={this.state.audiencia_marcada_select}
                                    />
                        </Col>
                        {this.state.audiencia_marcada == '1' ?
                        <Col md={6}>
                            <label htmlFor="audiencia_tipo">Tipo de audiência</label>
                            <Select
                                name="audiencia_tipo"
                                placeholder="Selecione..."
                                options={this.state.audiencia_tipo_options}
                                onChange={(audiencia_tipo_select) => {
                                    this.setState({audiencia_tipo_select})
                                    this.setState({audiencia_tipo: audiencia_tipo_select.value});
                                }}
                                value={this.state.audiencia_tipo_select}
                                    />
                        </Col>
                        :
                        <></>
                        }
                    </Row>
                    
                    <Collapse isOpen={this.state.audiencia_marcada == '1'}>
                        <Row>
                            <Col md={6}>
                                <label htmlFor="audiencia_data">Data da audiência</label>
                                <Input
                                    name="audiencia_data"
                                    placeholder=""
                                    value={this.state.audiencia_data}
                                    type="date"
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                            <Col md={6}>
                                <label htmlFor="hora_audiencia">Hora da audiência</label>
                                <InputMasked
                                            mask="99:99"
                                            placeholder="00:00"
                                            name="hora_audiencia"
                                            value={this.state.hora_audiencia}
                                            onChange={this.handleInputChange}
                                        />
                            </Col>
                        </Row>

                        <Collapse isOpen={this.state.audiencia_tipo === 'video'}>
                            <Row>
                                <Col md={12}>
                                    <label htmlFor="audiencia_link">Link da audiência</label>
                                    <Input
                                        name="audiencia_link"
                                        placeholder=""
                                        value={this.state.audiencia_link}
                                        type="text"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </Row>
                        </Collapse>
                    </Collapse>


                    <Row>
                        <Col md={6}>
                            <label htmlFor="audiencia_preposto">Preposto da audiência</label>
                            <Select
                                name="audiencia_preposto"
                                placeholder="Selecione..."
                                options={this.state.audiencia_preposto_options}
                                onChange={(audiencia_preposto_select) => {
                                    this.setState({audiencia_preposto_select})
                                    this.setState({audiencia_preposto: audiencia_preposto_select.value});
                                }}
                                value={this.state.audiencia_preposto_select}
                                    />
                        </Col>
                        <Col md={6}>
                            <label htmlFor="havera_acordo">Haverá acordo?</label>
                            <Select
                                name="havera_acordo"
                                placeholder="Selecione..."
                                options={[
                                    { value: '1', label: 'Sim'},
                                    { value: '0', label: 'Não'},
                                ]}
                                onChange={(havera_acordo_select) => {
                                    this.setState({havera_acordo_select})
                                    this.setState({havera_acordo: havera_acordo_select.value});
                                }}
                                value={this.state.havera_acordo_select}
                                    />
                        </Col>
                    </Row>

                    <Collapse isOpen={this.state.havera_acordo == '1'}>
                        <Row>
                            <Col md={6}>
                                <label htmlFor="proposta_acordo">Proposta de acordo</label>
                                <Input
                                        name="proposta_acordo"
                                        placeholder=""
                                        value={this.state.proposta_acordo}
                                        type="text"
                                        onChange={this.handleInputChange}
                                    />
                            </Col>
                            <Col md={6}>
                                <label htmlFor="pagamento_acordo">Pagamento do acordo</label>
                                <Input
                                        name="pagamento_acordo"
                                        placeholder=""
                                        value={this.state.pagamento_acordo}
                                        type="text"
                                        onChange={this.handleInputChange}
                                    />
                            </Col>
                        </Row>
                    </Collapse>

                    <Row>
                        <Col md={6}>
                            <label htmlFor="audiencia_resultado">Resultado da audiência</label>
                            <Select
                                name="audiencia_resultado"
                                placeholder="Selecione..."
                                options={resultadoAudienciaOptions}
                                onChange={(audiencia_resultado_select) => {
                                    this.setState({audiencia_resultado_select})
                                    this.setState({audiencia_resultado: audiencia_resultado_select.value});
                                }}
                                value={this.state.audiencia_resultado_select}
                                    />
                        </Col>
                        <Col md={6}>
                            <Collapse isOpen={this.state.audiencia_resultado == '0'}>
                                <label htmlFor="audiencia_nova_agendada">Nova Audiência agendada</label>
                                <Input
                                    name="audiencia_nova_agendada"
                                    placeholder=""
                                    value={this.state.audiencia_nova_agendada}
                                    type="date"
                                    onChange={this.handleInputChange}
                                />
                            </Collapse>
                            <Collapse isOpen={this.state.audiencia_resultado == '1'}>
                                <label htmlFor="forma_pagamento">Forma de pagamento</label>
                                    {/* <Input
                                        name="forma_pagamento"
                                        placeholder=""
                                        value={this.state.forma_pagamento}
                                        type="text"
                                        onChange={this.handleInputChange}
                                    /> */}
                                <Select
                                    name="forma_pagamento_select"
                                    placeholder="Selecione..."
                                    options={FormasDePagamentos}
                                    onChange={(forma_pagamento_select) => {
                                        this.setState({forma_pagamento_select})
                                        this.setState({forma_pagamento: forma_pagamento_select.value});
                                    }}
                                    value={this.state.forma_pagamento_select}
                                        />
                            </Collapse>
                        </Col>
                    </Row>

                    <Collapse isOpen={this.state.forma_pagamento == 'deposito_judicial'}>
                        <Row>
                            <Col md={12}>
                                <div style={{padding: '15px', border: '1px solid #dee2e6', borderLeft: '5px solid #28a745', borderRadius: 5}}>
                                    <h5>Comprovante de Pagamento</h5><hr />
                                    <Row>
                                        <Col md={6}>
                                            <label htmlFor="deposito_judicial_vencimento">Vencimento</label>
                                            <Input
                                                name="deposito_judicial_vencimento"
                                                placeholder=""
                                                value={this.state.deposito_judicial_vencimento}
                                                type="date"
                                                onChange={this.handleInputChange} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <label htmlFor="deposito_judicial_anexo">Anexo do pagamento</label>
                                            <Dropzone onDrop={(drop) => this.onDropDepositoJudicialAnexo(drop)}>
                                                {({ getRootProps, getInputProps }) => (
                                                <DropArea {...getRootProps({
                                                    onClick: event => event.stopPropagation()
                                                })}>
                                                    <input {...getInputProps()} />
                                                        {this.state.file.deposito_judicial_anexo.length > 0 ? 
                                                            <>
                                                                <Row> 
                                                                    {this.state.file.deposito_judicial_anexo.map((e, index) => {
                                                                        return <Col key={index} md={4} style={{paddingBottom: 4}}>
                                                                            <Card>
                                                                                <p style={{margin: '3px'}}>
                                                                                    <MdAttachFile style={{width: '32px'}}/>
                                                                                    {e.name}&nbsp; 
                                                                                    <Button 
                                                                                        style={{padding: 0, border: 3, float: 'right'}} 
                                                                                        color="danger" 
                                                                                        outline 
                                                                                        onClick={
                                                                                            () => this.removeDepositoJudicialAnexoAttach(index)
                                                                                        }>
                                                                                            <MdClear style={{padding: 0}}/>
                                                                                    </Button>
                                                                                </p>
                                                                            </Card>
                                                                        </Col>
                                                                    })}
                                                                    <br/>
                                                                </Row>
                                                                <p style={{ margin: 0 }}>
                                                                <MdAttachFile style={{ width: '32px' }} />
                                                                    Arraste o arquivo ou&nbsp;
                                                                    <ButtonUpload 
                                                                        onClick={() => this.inputFileDepositoJudicialAnexo.current.click()} 
                                                                        className="btn btn-dark" 
                                                                        color="primary">
                                                                        Clique Aqui
                                                                    </ButtonUpload> para adicionar mais anexos
                                                                    <input type="file" ref={this.inputFileDepositoJudicialAnexo} hidden onChange={this.handleFileDepositoJudicialAnexoChange} />
                                                            </p>
                                                        </>
                                                        :
                                                        <>
                                                            <p style={{ margin: 0 }}>
                                                                <MdAttachFile style={{ width: '32px' }} />
                                                                    Arraste o arquivo ou&nbsp;
                                                                    <ButtonUpload 
                                                                        onClick={() => this.inputFileDepositoJudicialAnexo.current.click()} 
                                                                        className="btn btn-dark" 
                                                                        color="primary">
                                                                        Clique Aqui
                                                                    </ButtonUpload> para adicionar anexos
                                                                    <input type="file" ref={this.inputFileDepositoJudicialAnexo} hidden onChange={this.handleFileDepositoJudicialAnexoChange} />
                                                            </p>
                                                        </>
                                                    }
                                                </DropArea>
                                            )}
                                            </Dropzone>

                                        </Col>
                                    </Row>    
                                        {this.state.arquivo_deposito_judicial_anexo != null ?
                                            <Row>
                                                <Col md={12}>
                                                    <Button 
                                                        className="btn btn-info btn-arquivo" 
                                                        outline 
                                                        onClick={
                                                            () => this.setState({pdf_deposito_judicial_anexo_open: !this.state.pdf_inicipdf_deposito_judicial_anexo_openal_open})
                                                        }>
                                                            <span>Visualizar</span>
                                                    </Button>
                                                    <Button 
                                                        className="btn btn-info btn-arquivo" 
                                                        outline 
                                                        onClick={
                                                            () => window.open(this.state.arquivo_deposito_judicial_anexo, '_blank').focus()
                                                        }>
                                                            <span>PDF Viewer</span>
                                                    </Button>
                                                    <Collapse isOpen={this.state.pdf_deposito_judicial_anexo_open}>
                                                        <iframe 
                                                            className="file-iframe"
                                                            src={this.state.arquivo_deposito_judicial_anexo} >
                                                            </iframe>
                                                    </Collapse>
                                                </Col>
                                            </Row>
                                        :
                                            ''
                                        }

                                </div>
                            </Col>
                        </Row>
                    </Collapse>    
                    <Collapse isOpen={this.state.forma_pagamento == 'pix'}>
                        <Row>
                            <Col md={12}>
                                <div style={{padding: '15px', border: '1px solid #dee2e6', borderLeft: '5px solid #28a745', borderRadius: 5}}>
                                    <h5>Dados do PIX</h5><hr />
                                    <Row>
                                        <Col md={6}>
                                            <label htmlFor="chave_pix">Chave PIX</label>
                                            <Input
                                                name="chave_pix"
                                                placeholder=""
                                                value={this.state.chave_pix}
                                                type="text"
                                                onChange={this.handleInputChange} />
                                        </Col>
                                        <Col md={6}>
                                            <label htmlFor="nome_pix">Nome</label>
                                            <Input
                                                name="nome_pix"
                                                placeholder=""
                                                value={this.state.nome_pix}
                                                type="text"
                                                onChange={this.handleInputChange} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <label htmlFor="deposito_judicial_anexo">Anexo do pagamento</label>
                                            <Dropzone onDrop={(drop) => this.onDropDepositoJudicialAnexo(drop)}>
                                                {({ getRootProps, getInputProps }) => (
                                                <DropArea {...getRootProps({
                                                    onClick: event => event.stopPropagation()
                                                })}>
                                                    <input {...getInputProps()} />
                                                        {this.state.file.deposito_judicial_anexo.length > 0 ? 
                                                            <>
                                                                <Row> 
                                                                    {this.state.file.deposito_judicial_anexo.map((e, index) => {
                                                                        return <Col key={index} md={4} style={{paddingBottom: 4}}>
                                                                            <Card>
                                                                                <p style={{margin: '3px'}}>
                                                                                    <MdAttachFile style={{width: '32px'}}/>
                                                                                    {e.name}&nbsp; 
                                                                                    <Button 
                                                                                        style={{padding: 0, border: 3, float: 'right'}} 
                                                                                        color="danger" 
                                                                                        outline 
                                                                                        onClick={
                                                                                            () => this.removeDepositoJudicialAnexoAttach(index)
                                                                                        }>
                                                                                            <MdClear style={{padding: 0}}/>
                                                                                    </Button>
                                                                                </p>
                                                                            </Card>
                                                                        </Col>
                                                                    })}
                                                                    <br/>
                                                                </Row>
                                                                <p style={{ margin: 0 }}>
                                                                <MdAttachFile style={{ width: '32px' }} />
                                                                    Arraste o arquivo ou&nbsp;
                                                                    <ButtonUpload 
                                                                        onClick={() => this.inputFileDepositoJudicialAnexo.current.click()} 
                                                                        className="btn btn-dark" 
                                                                        color="primary">
                                                                        Clique Aqui
                                                                    </ButtonUpload> para adicionar mais anexos
                                                                    <input type="file" ref={this.inputFileDepositoJudicialAnexo} hidden onChange={this.handleFileDepositoJudicialAnexoChange} />
                                                            </p>
                                                        </>
                                                        :
                                                        <>
                                                            <p style={{ margin: 0 }}>
                                                                <MdAttachFile style={{ width: '32px' }} />
                                                                    Arraste o arquivo ou&nbsp;
                                                                    <ButtonUpload 
                                                                        onClick={() => this.inputFileDepositoJudicialAnexo.current.click()} 
                                                                        className="btn btn-dark" 
                                                                        color="primary">
                                                                        Clique Aqui
                                                                    </ButtonUpload> para adicionar anexos
                                                                    <input type="file" ref={this.inputFileDepositoJudicialAnexo} hidden onChange={this.handleFileDepositoJudicialAnexoChange} />
                                                            </p>
                                                        </>
                                                    }
                                                </DropArea>
                                            )}
                                            </Dropzone>

                                        </Col>
                                    </Row>    
                                        {this.state.arquivo_deposito_judicial_anexo != null ?
                                            <Row>
                                                <Col md={12}>
                                                    <Button 
                                                        className="btn btn-info btn-arquivo" 
                                                        outline 
                                                        onClick={
                                                            () => this.setState({pdf_deposito_judicial_anexo_open: !this.state.pdf_inicipdf_deposito_judicial_anexo_openal_open})
                                                        }>
                                                            <span>Visualizar</span>
                                                    </Button>
                                                    <Button 
                                                        className="btn btn-info btn-arquivo" 
                                                        outline 
                                                        onClick={
                                                            () => window.open(this.state.arquivo_deposito_judicial_anexo, '_blank').focus()
                                                        }>
                                                            <span>PDF Viewer</span>
                                                    </Button>
                                                    <Collapse isOpen={this.state.pdf_deposito_judicial_anexo_open}>
                                                        <iframe 
                                                            className="file-iframe"
                                                            src={this.state.arquivo_deposito_judicial_anexo} >
                                                            </iframe>
                                                    </Collapse>
                                                </Col>
                                            </Row>
                                        :
                                            ''
                                        }
                                </div>
                            </Col>
                        </Row>
                    </Collapse>
                    <Collapse isOpen={this.state.forma_pagamento == 'ted'}>
                        <Row>
                            <Col md={12}>
                                <div style={{padding: '15px', border: '1px solid #dee2e6', borderLeft: '5px solid #28a745', borderRadius: 5}}>
                                    <h5>Dados da Conta</h5><hr />
                                    <Row>
                                        <Col md={12}>
                                            <label htmlFor="conta_banco">Banco</label>
                                            <Select
                                                name="conta_banco_select"
                                                placeholder="Selecione..."
                                                options={this.state.bancos_options}
                                                onChange={(conta_banco_select) => {
                                                    this.setState({conta_banco_select})
                                                    this.setState({conta_banco: conta_banco_select.value});
                                                }}
                                                value={this.state.conta_banco_select}
                                                    />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <label htmlFor="conta_agencia">Agência</label>
                                            <Input
                                                name="conta_agencia"
                                                placeholder=""
                                                value={this.state.conta_agencia}
                                                type="text"
                                                onChange={this.handleInputChange} />
                                        </Col>
                                        <Col md={6}>
                                            <label htmlFor="conta_conta">Conta</label>
                                            <Input
                                                name="conta_conta"
                                                placeholder=""
                                                value={this.state.conta_conta}
                                                type="text"
                                                onChange={this.handleInputChange} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <label htmlFor="conta_documento">CPF / CNPJ</label>
                                            <Input
                                                name="conta_documento"
                                                placeholder="Documento do recebedor"
                                                value={this.state.conta_documento}
                                                type="text"
                                                onChange={this.handleInputChange} />
                                        </Col>
                                        <Col md={6}>
                                            <label htmlFor="conta_nome">Nome</label>
                                            <Input
                                                name="conta_nome"
                                                placeholder="Nome do recebedor"
                                                value={this.state.conta_nome}
                                                type="text"
                                                onChange={this.handleInputChange} />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12}>
                                            <label htmlFor="deposito_judicial_anexo">Anexo do pagamento</label>
                                            <Dropzone onDrop={(drop) => this.onDropDepositoJudicialAnexo(drop)}>
                                                {({ getRootProps, getInputProps }) => (
                                                <DropArea {...getRootProps({
                                                    onClick: event => event.stopPropagation()
                                                })}>
                                                    <input {...getInputProps()} />
                                                        {this.state.file.deposito_judicial_anexo.length > 0 ? 
                                                            <>
                                                                <Row> 
                                                                    {this.state.file.deposito_judicial_anexo.map((e, index) => {
                                                                        return <Col key={index} md={4} style={{paddingBottom: 4}}>
                                                                            <Card>
                                                                                <p style={{margin: '3px'}}>
                                                                                    <MdAttachFile style={{width: '32px'}}/>
                                                                                    {e.name}&nbsp; 
                                                                                    <Button 
                                                                                        style={{padding: 0, border: 3, float: 'right'}} 
                                                                                        color="danger" 
                                                                                        outline 
                                                                                        onClick={
                                                                                            () => this.removeDepositoJudicialAnexoAttach(index)
                                                                                        }>
                                                                                            <MdClear style={{padding: 0}}/>
                                                                                    </Button>
                                                                                </p>
                                                                            </Card>
                                                                        </Col>
                                                                    })}
                                                                    <br/>
                                                                </Row>
                                                                <p style={{ margin: 0 }}>
                                                                <MdAttachFile style={{ width: '32px' }} />
                                                                    Arraste o arquivo ou&nbsp;
                                                                    <ButtonUpload 
                                                                        onClick={() => this.inputFileDepositoJudicialAnexo.current.click()} 
                                                                        className="btn btn-dark" 
                                                                        color="primary">
                                                                        Clique Aqui
                                                                    </ButtonUpload> para adicionar mais anexos
                                                                    <input type="file" ref={this.inputFileDepositoJudicialAnexo} hidden onChange={this.handleFileDepositoJudicialAnexoChange} />
                                                            </p>
                                                        </>
                                                        :
                                                        <>
                                                            <p style={{ margin: 0 }}>
                                                                <MdAttachFile style={{ width: '32px' }} />
                                                                    Arraste o arquivo ou&nbsp;
                                                                    <ButtonUpload 
                                                                        onClick={() => this.inputFileDepositoJudicialAnexo.current.click()} 
                                                                        className="btn btn-dark" 
                                                                        color="primary">
                                                                        Clique Aqui
                                                                    </ButtonUpload> para adicionar anexos
                                                                    <input type="file" ref={this.inputFileDepositoJudicialAnexo} hidden onChange={this.handleFileDepositoJudicialAnexoChange} />
                                                            </p>
                                                        </>
                                                    }
                                                </DropArea>
                                            )}
                                            </Dropzone>

                                        </Col>
                                    </Row>    
                                        {this.state.arquivo_deposito_judicial_anexo != null ?
                                            <Row>
                                                <Col md={12}>
                                                    <Button 
                                                        className="btn btn-info btn-arquivo" 
                                                        outline 
                                                        onClick={
                                                            () => this.setState({pdf_deposito_judicial_anexo_open: !this.state.pdf_inicipdf_deposito_judicial_anexo_openal_open})
                                                        }>
                                                            <span>Visualizar</span>
                                                    </Button>
                                                    <Button 
                                                        className="btn btn-info btn-arquivo" 
                                                        outline 
                                                        onClick={
                                                            () => window.open(this.state.arquivo_deposito_judicial_anexo, '_blank').focus()
                                                        }>
                                                            <span>PDF Viewer</span>
                                                    </Button>
                                                    <Collapse isOpen={this.state.pdf_deposito_judicial_anexo_open}>
                                                        <iframe 
                                                            className="file-iframe"
                                                            src={this.state.arquivo_deposito_judicial_anexo} >
                                                            </iframe>
                                                    </Collapse>
                                                </Col>
                                            </Row>
                                        :
                                            ''
                                        }
                                </div>
                            </Col>
                        </Row>
                    </Collapse>

                    <Row>
                        <Col md={12}>
                            <label htmlFor="ata_audiencia">Ata da audiência</label>


                            <Dropzone onDrop={(drop) => this.onDropAta(drop)}>
                                {({ getRootProps, getInputProps }) => (
                                <DropArea {...getRootProps({
                                    onClick: event => event.stopPropagation()
                                })}>
                                    <input {...getInputProps()} />
                                        {this.state.file.ata_audiencia.length > 0 ? 
                                            <>
                                                <Row> 
                                                    {this.state.file.ata_audiencia.map((e, index) => {
                                                        return <Col key={index} md={4} style={{paddingBottom: 4}}>
                                                            <Card>
                                                                <p style={{margin: '3px'}}>
                                                                    <MdAttachFile style={{width: '32px'}}/>
                                                                    {e.name}&nbsp; 
                                                                    <Button 
                                                                        style={{padding: 0, border: 3, float: 'right'}} 
                                                                        color="danger" 
                                                                        outline 
                                                                        onClick={
                                                                            () => this.removeAtaAttach(index)
                                                                        }>
                                                                            <MdClear style={{padding: 0}}/>
                                                                    </Button>
                                                                </p>
                                                            </Card>
                                                        </Col>
                                                    })}
                                                    <br/>
                                                </Row>
                                                <p style={{ margin: 0 }}>
                                                <MdAttachFile style={{ width: '32px' }} />
                                                    Arraste o arquivo ou&nbsp;
                                                    <ButtonUpload 
                                                        onClick={() => this.inputFileAta.current.click()} 
                                                        className="btn btn-dark" 
                                                        color="primary">
                                                        Clique Aqui
                                                    </ButtonUpload> para adicionar mais anexos
                                                    <input type="file" ref={this.inputFileAta} hidden onChange={this.handleFileAtaChange} />
                                            </p>
                                        </>
                                        :
                                        <>
                                            <p style={{ margin: 0 }}>
                                                <MdAttachFile style={{ width: '32px' }} />
                                                    Arraste o arquivo ou&nbsp;
                                                    <ButtonUpload 
                                                        onClick={() => this.inputFileAta.current.click()} 
                                                        className="btn btn-dark" 
                                                        color="primary">
                                                        Clique Aqui
                                                    </ButtonUpload> para adicionar anexos
                                                    <input type="file" ref={this.inputFileAta} hidden onChange={this.handleFileAtaChange} />
                                            </p>
                                        </>
                                    }
                                </DropArea>
                            )}
                            </Dropzone>


                        </Col>
                    </Row>


                            {/* arquivo_inicial_processo: null,
                            arquivo_ata_audiencia: null, */}
                            {this.state.arquivo_ata_audiencia != null ?
                                <Row>
                                    <Col md={12}>
                                        <Button 
                                            className="btn btn-info btn-arquivo" 
                                            outline 
                                            onClick={
                                                () => this.setState({pdf_ata_open: !this.state.pdf_ata_open})
                                            }>
                                                <span>Visualizar</span>
                                        </Button>
                                        <Button 
                                            className="btn btn-info btn-arquivo" 
                                            outline 
                                            onClick={
                                                () => window.open(this.state.arquivo_ata_audiencia, '_blank').focus()
                                            }>
                                                <span>PDF Viewer</span>
                                        </Button>
                                        <Collapse isOpen={this.state.pdf_ata_open}>
                                            <iframe 
                                                className="file-iframe"
                                                src={this.state.arquivo_ata_audiencia} >
                                                </iframe>
                                        </Collapse>
                                    </Col>
                                </Row>
                            :
                                ''
                            }



                    <Row>
                        <Col md={6}>
                            <label htmlFor="status_processo">Status do processo</label>
                            <Select
                                name="status_processo"
                                placeholder="Selecione..."
                                className={this.state.valid_input['status_processo'] !== undefined ? 'invalid' : '' }
                                options={this.state.status_processo_options}
                                onChange={(status_processo_select) => {
                                    this.setState({status_processo_select})
                                    this.setState({status_processo: status_processo_select.value});
                                }}
                                value={this.state.status_processo_select}
                                    />
                        </Col>
                        <Col md={6}>
                            <label htmlFor="status_pagamento">Status de pagamento</label>
                            <Select
                                name="status_pagamento"
                                placeholder="Selecione..."
                                className={this.state.valid_input['status_pagamento'] !== undefined ? 'invalid' : '' }
                                options={this.state.status_pagamento_options}
                                onChange={(status_pagamento_select) => {
                                    this.setState({status_pagamento_select})
                                    this.setState({status_pagamento: status_pagamento_select.value});
                                }}
                                value={this.state.status_pagamento_select}
                                    />
                        </Col>
                    </Row>
                    
                    {this.state.is_open_reclamante === true ?
                        <Row>
                            <Col md={6}>
                                <label htmlFor="negativado_serasa">Negativado no serasa ?</label>
                                <Select
                                    name="negativado_serasa"
                                    placeholder="Selecione..."
                                    className={this.state.valid_input['negativado_serasa'] !== undefined ? 'invalid' : '' }
                                    options={[
                                        { value: '1', label: 'Sim'},
                                        { value: '0', label: 'Não'},
                                    ]}
                                    onChange={(negativado_serasa_select) => {
                                        this.setState({negativado_serasa_select})
                                        this.setState({negativado_serasa: negativado_serasa_select.value});
                                    }}
                                    value={this.state.negativado_serasa_select}
                                        />
                            </Col>
                            <Col md={6}>
                                <label htmlFor="baixa_serasa">Baixa no serasa</label>
                                <Input
                                    name="baixa_serasa"
                                    placeholder=""
                                    value={this.state.baixa_serasa}
                                    type="date"
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                        </Row>
                    :
                        <></>
                    }

                </Collapse>
            {/* parcelamento */}
            <Collapse isOpen={this.state.is_open_reclamado || this.state.is_open_reclamante || this.state.is_open_acordo_extrajudicial}>
                <Row>
                        <Col md={6}>
                            <label htmlFor="houve_parcelamento">Houve parcelamento ?</label>
                            <Select
                                name="houve_parcelamento"
                                placeholder="Selecione..."
                                options={[
                                    { value: '1', label: 'Sim'},
                                    { value: '0', label: 'Não'},
                                ]}
                                onChange={(houve_parcelamento_select) => {
                                    this.setState({houve_parcelamento_select})
                                    this.setState({houve_parcelamento: houve_parcelamento_select.value})
                                    this.handleInputParcelasChange()
                                }}
                                value={this.state.houve_parcelamento_select}
                                    />
                        </Col>
                        <Col md={6} hidden={this.state.houve_parcelamento == 1 ? false : true}>
                            <label htmlFor="numero_parcelas">Numero de parcelas</label>
                            <Input
                                name="numero_parcelas"
                                placeholder=""
                                value={this.state.numero_parcelas}
                                type="number"
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputParcelasChange}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6} hidden={this.state.houve_parcelamento == 1 ? false : true}>
                            <label htmlFor="primeiro_vencimento">Data primeiro vencimento</label>
                            <Input
                                name="primeiro_vencimento"
                                placeholder=""
                                value={this.state.primeiro_vencimento}
                                type="date"
                                onChange={this.handleVencimentoChange}
                                onBlur={this.handleInputParcelasChange}
                            />
                        </Col>
                        <Col hidden={this.state.load_parc == true && this.state.houve_parcelamento == 1 ? false : true} md={6}>
                            <label  htmlFor="btn_gerar_parc">&nbsp;</label>
                            <p>
                                <Button color="success">
                                    Carregar parcelamento
                                </Button>
                            </p>
                        </Col>
                    </Row>
                    
                    <Collapse isOpen={this.state.load_parcelas_animation}>
                        <Row>
                            <Col md={12}>
                                <center>
                                    <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                                </center>
                                <br/>
                            </Col>
                        </Row>
                    </Collapse>

                    

                    <Collapse isOpen={this.state.is_open_vencimento_table}>
                        <Row style={{marginTop: '1.5rem'}}>
                            <Col md={12}>
                                <h5>Dados de Parcelamento</h5><hr/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                
                                {this.state.rows_vencimento.map((parcelamento, val) => {

                                    return (
                                        <div 
                                            key={'parcelas_table_div_'+val} 
                                            style={{
                                                padding: '15px', 
                                                border: '1px solid #dee2e6', 
                                                borderRadius: 5,
                                                marginBottom: '1rem',
                                            }}
                                        >
                                            <Row>
                                                <Col md={4}>{parcelamento.parcela}ª Parcela</Col>
                                                <Col md={4}>Valor</Col>
                                                <Col md={4}>Data de Pagamento</Col>
                                                
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <small style={{fontSize: '9px'}}>vencimento</small><br />
                                                    <span style={{fontSize: '12px'}}>{moment(parcelamento.data_vencimento).format('DD/MM/YYYY')}</span>
                                                </Col>
                                                <Col md={4}>                                                
                                                    <NumberFormat 
                                                        name={"valor"}
                                                        className="form-control"
                                                        displayType={'input'}
                                                        prefix={'R$ '}
                                                        decimalSeparator={','}
                                                        decimalScale={2}
                                                        thousandSeparator={'.'}
                                                        value={parcelamento.valor}
                                                        onChange={(event) => {this.handleChangeInputParcelamento(event, parcelamento, val)}}
                                                        />
                                                </Col>
                                                <Col md={4}>
                                                    <Input
                                                        name={"data_pagamento"} 
                                                        placeholder=""
                                                        type="date"
                                                        value={parcelamento.data_pagamento}
                                                        onChange={(event) => {this.handleChangeInputParcelamento(event, parcelamento, val)}}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row hidden={parcelamento.comprovante !== undefined && parcelamento.comprovante != null ? false : true}>
                                                <Col md={12} className="col-cards">
                                                    <Card style={{marginBottom: '1rem'}}>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Button 
                                                                    style={{padding: 0, border: 3, float: 'left'}} 
                                                                    color="danger" 
                                                                    outline 
                                                                    onClick={
                                                                        () => this.removeParcelamentoComprovantePagamentoFileAttach(parcelamento, val)
                                                                    }
                                                                    >
                                                                        <MdClear style={{padding: 0}}/>
                                                                </Button>

                                                                <MdAttachFile style={{width: '32px'}}/>
                                                                Anexo Comprovante de pagamento&nbsp; 
                                                            </Col>
                                                            <Col md={3}>
                                                                <Button 
                                                                    className="btn btn-info btn-arquivo" 
                                                                    outline 
                                                                    onClick={(e) => {
                                                                        let state = this.state;
                                                                        state.rows_vencimento[val].open_iframe = true;

                                                                        this.setState({...state})
                                                                    }}
                                                                    >
                                                                    <span><FaEye /> Visualizar</span>
                                                                </Button>
                                                            </Col>
                                                            <Col md={3}>
                                                                <Button 
                                                                className="btn btn-info btn-arquivo" 
                                                                outline                                        
                                                                onClick={
                                                                    () => window.open(parcelamento.comprovante, '_blank').focus()
                                                                }>
                                                                    <span><FaFilePdf /> PDF Viewer</span>
                                                                </Button>
                                                                
                                                            </Col>
                                                        </Row>

                                                        <Collapse style={{textAlign: 'center'}} isOpen={parcelamento.open_iframe}>
                                                            <Button 
                                                                color="danger" 
                                                                outline 
                                                                style={{marginBottom: '1rem'}}
                                                                onClick={(e) => {
                                                                    let state = this.state;
                                                                    state.rows_vencimento[val].open_iframe = false;

                                                                    this.setState({...state})
                                                                }}>
                                                                    Fechar
                                                            </Button>

                                                            <iframe 
                                                                className="file-iframe"
                                                                src={parcelamento.comprovante}
                                                                >
                                                            </iframe>
                                                        </Collapse>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row hidden={parcelamento.comprovante !== undefined && parcelamento.comprovante != null ? true : false}>
                                                <Col md={12}>Comprovante do pagamento</Col>
                                                <Col md={12}>
                                                    <Dropzone onDrop={(drop) => this.onDropComprovantePagamento(drop, val)}>
                                                        {({ getRootProps, getInputProps }) => (
                                                        <DropArea {...getRootProps({
                                                            onClick: event => event.stopPropagation()
                                                        })}>
                                                            <input {...getInputProps()} />
                                                                {parcelamento.files !== undefined && parcelamento.files.length > 0 ? 
                                                                    <>
                                                                        <Row> 
                                                                            {parcelamento.files.map((e, index) => {
                                                                                return <Col key={index} md={12} style={{paddingBottom: 4}}>
                                                                                    <Card>
                                                                                        <p style={{margin: '3px'}}>
                                                                                            <MdAttachFile style={{width: '32px'}}/>
                                                                                            {e.name}&nbsp; 
                                                                                            <Button 
                                                                                                style={{padding: 0, border: 3, float: 'right'}} 
                                                                                                color="danger" 
                                                                                                outline 
                                                                                                onClick={
                                                                                                    () => this.removeParcelamentoComprovantePagamentoAttach(index)
                                                                                                }>
                                                                                                    <MdClear style={{padding: 0}}/>
                                                                                            </Button>
                                                                                        </p>
                                                                                    </Card>
                                                                                </Col>
                                                                            })}
                                                                            <br/>
                                                                        </Row>
                                                                        <p style={{ margin: 0 }}>
                                                                        <MdAttachFile style={{ width: '32px' }} />
                                                                            Anexo&nbsp;
                                                                            <ButtonUpload 
                                                                                onClick={() => parcelamento.inputFileComprovantePagamento.current.click()} 
                                                                                className="btn btn-dark" 
                                                                                color="primary">
                                                                                Comprovante
                                                                            </ButtonUpload>&nbsp;de pagamento
                                                                            <input type="file" ref={parcelamento.inputFileComprovantePagamento} multiple hidden onChange={(event) => {this.handleFileParcelamentoComprovanteChange(event, val)}} />
                                                                    </p>
                                                                </>
                                                                :
                                                                <>
                                                                    <p style={{ margin: 0 }}>
                                                                        <MdAttachFile style={{ width: '32px' }} />
                                                                            Anexo&nbsp;
                                                                            <ButtonUpload 
                                                                                onClick={() => parcelamento.inputFileComprovantePagamento.current.click()} 
                                                                                className="btn btn-dark" 
                                                                                color="primary">
                                                                                Comprovante
                                                                            </ButtonUpload>&nbsp;de pagamento
                                                                            <input type="file" ref={parcelamento.inputFileComprovantePagamento} multiple hidden onChange={(event) => {this.handleFileParcelamentoComprovanteChange(event, val)}} />
                                                                    </p>
                                                                </>
                                                            }
                                                        </DropArea>
                                                    )}
                                                    </Dropzone>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={12}>
                                                    {parcelamento.notas !== undefined && parcelamento.notas.length > 0 ?

                                                        <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                                            <h5>Notas</h5><hr/>
                                                            <ul style={{listStyleType: 'circle'}}>
                                                                {parcelamento.notas !== undefined && parcelamento.notas.length > 0 ? 
                                                                    parcelamento.notas.map(p => {
                                                                        return <li key={p.id} style={{marginBottom: '7px'}}>
                                                                            <b>Operador:</b> {p.operador !== null ? p.operador : 'Sem Informações'} <br/>
                                                                            <div style={{textTransform: 'uppercase', border: '1px solid #7f7f7f66', borderRadius: 5, padding: '15px'}}>
                                                                                {p.nota.split('\n').map((x, i, {length}) => (
                                                                                    <p style={{margin: (i + 1 === length ? 0 : 3), fontSize: 14}}>{x}</p>
                                                                                ))}
                                                                            </div>
                                                                            <small>
                                                                                {moment(p.data).format('DD/MM/YYYY HH:mm')}&nbsp;
                                                                                {/* <ButtonRed 
                                                                                    hidden={!this.hiddenDelete(p.admin_id)}
                                                                                    disabled={this.state.loading_nota_id === p.id}
                                                                                    onClick={() => this.deletarNota(p.id)}>
                                                                                        Apagar        
                                                                                </ButtonRed> */}
                                                                            </small>
                                                                        </li>
                                                                    })
                                                                :
                                                                <></>
                                                                }
                                                            </ul>
                                                            {parcelamento.notas === undefined || parcelamento.notas.length === 0 ? <p>Nenhuma nota</p> : ''}
                                                            <Button 
                                                                hidden={parcelamento.is_open_nota_vencimento}
                                                                color="success" 
                                                                onClick={() => {
                                                                    let state = this.state;
                                                                    state.rows_vencimento[val].is_open_nota_vencimento = true;

                                                                    this.setState({...state})
                                                                }}>
                                                                    <FaPen /> Adicionar Nota
                                                            </Button>
                                                            <Collapse isOpen={parcelamento.is_open_nota_vencimento}>
                                                                <br/>
                                                                <label htmlFor="for_desc">Nova nota</label>
                                                                <Input
                                                                    name={"parcelamento_notas"}
                                                                    placeholder=""
                                                                    type="textarea"
                                                                    style={{
                                                                        height: '124px',
                                                                        resize: 'none'
                                                                    }}
                                                                    onChange={(event) => {this.handleChangeInputParcelamento(event, parcelamento, val)}}
                                                                />
                                                            </Collapse>

                                                        </div>
                                                    :
                                                        <>
                                                        <label htmlFor="for_desc">Notas</label>
                                                        <Input
                                                            name={"parcelamento_notas"}
                                                            placeholder=""
                                                            type="textarea"
                                                            style={{
                                                                height: '124px',
                                                                resize: 'none'
                                                            }}
                                                            onChange={(event) => {this.handleChangeInputParcelamento(event, parcelamento, val)}}
                                                        />
                                                        </>
                                                    }


                                                </Col>
                                            </Row>   



                                            <Row>
                                                <Col md={12}>
                                                    <Button 
                                                        color="success"
                                                        style={{float: 'right'}}
                                                        onClick={(event) => {this.handleSaveParcela(parcelamento)}}
                                                        >
                                                            {this.state.loading_btn_save_parcelamento === true ?
                                                                <>
                                                                    <ClipLoader color="#fff" size={12}/>
                                                                    &nbsp;Salvando...
                                                                </>
                                                            : 
                                                                <><FaSave /> Salvar</>
                                                            }

                                                        
                                                    </Button>                                          
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                })}
                            </Col>
                        </Row>
                    </Collapse>
                </Collapse>
            {/* parcelamento */}


                <Row>
                <Col md={12}>
                <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                    <h5>Notas</h5><hr/>
                    <ul style={{listStyleType: 'circle'}}>
                        {this.state.notas_privadas.map(p => {
                            return <li key={p.id} style={{marginBottom: '7px'}}>
                                <b>Operador:</b> {p.operador !== null ? p.operador : 'Sem Informações'} <br/>
                                <div style={{textTransform: 'uppercase', border: '1px solid #7f7f7f66', borderRadius: 5, padding: '15px'}}>
                                    {p.nota.split('\n').map((x, i, {length}) => (
                                        <p style={{margin: (i + 1 === length ? 0 : 3), fontSize: 14}}>{x}</p>
                                    ))}
                                </div>
                                <small>
                                    {moment(p.data).format('DD/MM/YYYY HH:mm')}&nbsp;
                                    {/* <ButtonRed 
                                        hidden={!this.hiddenDelete(p.admin_id)}
                                        disabled={this.state.loading_nota_id === p.id}
                                        onClick={() => this.deletarNota(p.id)}>
                                            Apagar        
                                    </ButtonRed> */}
                                </small>
                            </li>
                        })}
                    </ul>
                    {this.state.notas_privadas.length === 0 ? <p>Nenhuma nota</p> : ''}
                    <Button 
                        color="success" 
                        onClick={() => this.setState({is_open_nota: !this.state.is_open_nota})}>
                            <FaPen /> Adicionar Nota
                    </Button>
                    <Collapse isOpen={this.state.is_open_nota}>
                        <br/>
                        <Card>
                            <CardBody>
                                <Input
                                    name="nota_mensagem"
                                    placeholder="Descrição"
                                    value={this.state.nota_mensagem}
                                    type="textarea"
                                    style={{
                                        height: '124px',
                                        resize: 'none'
                                    }}
                                    onChange={this.handleInputChange}
                                />
                                {this.state.notas_privadas.length > 0 ? 
                                    <Button 
                                        className="btn btn-success btn-nota"
                                        onClick={this.handleAddNota}>
                                        {this.state.loading_btn_add_nota === true ?
                                            <>
                                                <ClipLoader color="#fff" size={12}/>
                                                &nbsp;Adicionando...
                                            </>
                                        : 
                                            'Adicionar.'

                                        }
                                    </Button>
                                : 
                                ''
                                }

                            </CardBody>
                        </Card>
                    </Collapse>

                </div>
                    
                </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Button 
                            className="btn btn-success btn-save" 
                            disabled={this.state.disable_send_button}
                            onClick={this.handleSendFormProcesso}>
                            {this.state.loading_btn_save_form === true ?
                                <>
                                    <ClipLoader color="#fff" size={12}/>
                                    &nbsp;Salvando...
                                </>
                            : 
                                'Salvar Processo'
                            }
                        </Button>
                    </Col>
                </Row>
            </Div>
            <br/>
            <br/>
            </Div>

        )
    }
}

export default ResumoProcesso;