import { debounce } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Badge, Button, Input, TabPane } from 'reactstrap';

const get_status_auth = (payment_type) => {
    switch(payment_type){
        case 0 : return 'Aguardando Validação';
        case 1 : return 'Validado';
        case 2 : return 'Perdido';
        default: return ''
    }
}
const get_status = (status) => {
    switch(status){
        case 'ativo' : return <Badge color="success">Ativo</Badge>;
        case 'recusado' : return <Badge color="danger">Recusado</Badge>;
        case 'desativado' : return <Badge color="primary">Desativado</Badge>;
        case 'deletado' : return <Badge color="danger">Deletado</Badge>;
        case 'expirado' : return <Badge color="warning">Expirado</Badge>;
        default: return status
    }
}

function DataClient(props){
    return (
        <TabPane tabId={props.active}>
            <br />
            <div>
                <h5>Dados Pessoais</h5>
                <hr/>
                <p><b>Nome do Responsavel:</b> {props.name}</p>
                {props.atividade_principal ?
                    <p><b>Atividade de vendas:</b> {props.atividade_principal}</p>
                :<></>}
                {props.status ?
                    <p><b>Status Adquirente:</b> {get_status(props.status)}</p>
                :<></>}
                {props.status_crm ?
                    <p>
                        <div style={{ display: 'flex' }}>
                            
                            <b>Status Interno: </b>&nbsp;
                            <Input 
                                type="select" 
                                name="status_crm"
                                style={{borderRadius: '0px', width: '225px', padding: 0, height: '25px', fontSize: '14px', fontWeight: 700, color: '#212529'}} 
                                value={props.status_crm}
                                onChange={props.handleChange} 
                                //onChange={e => this.setState({ valor_tipo: e.target.value })}
                            >
                                <option value="ativo">Ativo</option>
                                <option value="recusado">Recusado</option>
                                <option value="pendente">Pendente</option>
                                <option value="bloqueado">Bloqueado</option>
                                <option value="cancelado">Cancelado</option>
                            </Input>
                        </div>
                    </p>
                    :
                    <></>
                }
                {/* <p><b>E-mail:</b> {props.email}</p>
                <p><b>Telefone:</b> {props.telefone}</p> */}
                
                {props.email_edit_enable !== true ?
                    <>
                    <p><b>E-mail:</b> {props.email} <Button outline onClick={() => props.editEmail(true)} color='success' style={{padding: '3px', fontSize: '12px'}}>Editar</Button></p>
                    </>
                    :
                    <>
                        <p style={{display: 'flex'}}><b>E-mail:</b>&nbsp;
                            <Input 
                                name="email_edit"
                                placeholder="E-mail"
                                value={props.email_edit}
                                onChange={props.handleChange} 
                                style={{width: '80%', fontSize: '12px'}}
                            />
                            <Button color='success' onClick={() => props.storeEmail()} style={{margin: 2, padding: 2, fontSize: '12px'}}>Salvar</Button>
                            <Button color='danger' onClick={() => props.editEmail(false)} style={{margin: 2, padding: 2, fontSize: '12px'}}>Cancelar</Button>
                        </p>
                    </>
                }
                {props.telefone_edit_enable !== true ?
                    <>
                    <p><b>Telefone:</b> {props.telefone} <Button outline onClick={() => props.telefoneEdit(true)} color='success' style={{padding: '3px', fontSize: '12px'}}>Editar</Button></p>
                    </>
                    :
                    <>
                        <p style={{display: 'flex'}}><b>Telefone:</b>&nbsp;
                            <Input 
                                name="telefone_edit"
                                placeholder="Telefone"
                                //value={props.telefone_edit}
                                onChange={debounce(props.handleChange)}
                                style={{width: '80%', fontSize: '12px'}}
                            />
                            <Button color='success' onClick={() => props.storeTelefone()} style={{margin: 2, padding: 2, fontSize: '12px'}}>Salvar</Button>
                            <Button color='danger' onClick={() => props.telefoneEdit(false)} style={{margin: 2, padding: 2, fontSize: '12px'}}>Cancelar</Button>
                        </p>
                    </>
                }
                <p><b>Documento (CPF/CNPJ):</b> {props.cnpj.length === 0 ? props.cpf : props.cnpj}</p>
                <p><b>Criado em:</b> {moment(new Date(props.created_at)).format('DD/MM/YYYY HH:mm')}</p>
                <p><b>Primeiro chat:</b> {props.first_chat_date !== '' ? moment(new Date(props.first_chat_date)).format('DD/MM/YYYY HH:mm') : ''}</p>
                <p><b>Origem do primeiro chat:</b> {props.first_origin}</p>
                <p><b>Ultimo Chat:</b> {props.last_chat_date !== '' ? moment(new Date(props.last_chat_date)).format('DD/MM/YYYY HH:mm') : ''}</p>
                <p><b>Origem do ultimo chat:</b> {props.last_origin}</p>
                <p><b>Plano:</b> {props.plan_seller}</p>
                <p><b>Saldo atual:</b> {props.account_balance}</p>
                <p><b>Saldo na conta:</b> {props.current_balance}</p>
                {props.empresa !== null ? 
                    <>
                        <p><b>E-mail da empresa:</b> {props.empresa.email}</p>
                        <p><b>Nome fantansia:</b> {props.empresa.nome_fantasia}</p>
                        <p><b>Nome da empresa:</b> {props.empresa.razao_social}</p>
                        <p><b>Descrição da empresa:</b> {props.empresa.tipo_negocio}</p>
                        <p><b>Natureza Juridica:</b> {props.empresa.natureza_juridica}</p>
                    </>
                : <></>}
                
                {props.endereco !== null ?
                    <p><b>Endereço: </b>{props.endereco.logradouro} {props.endereco.numero}, {props.endereco.complemento} - {props.endereco.bairro}, {props.endereco.cidade}/{props.endereco.uf} - {props.endereco.cep} <Button outline color='success' style={{padding: '3px', fontSize: '12px'}} onClick={() => props.onOpen()}>Editar</Button></p>
                    :
                    <p><Button color="info" onClick={() => props.onOpen()} style={{padding: 3}}>Cadastrar Endereco</Button></p>
                }
            </div>
        </TabPane>
    )
}

export default DataClient;