import React, { Component } from 'react';
import { Card, CardBody } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { TableEstacionamento } from '../../components/Tables/TableEstacionamento/TableEstacionamento';
import Moment from 'moment';
import { getCity, getRows, getRowsPerType, getStates } from '../../components/Tables/TableEstacionamento/propsEstacionamento';
import { getPermissoes, getUser } from '../../auth/set_services';

const getId = () => {
    if (window.location.pathname === '/estacionamento') {
        return null;
    }
    else {
        return window.location.pathname.replace('/estacionamento/', '')
    }
}
class Estacionamento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading_table: true,
            rowData: [],
            activePage: 1,
            taggy_serial: getId(),
            totalPage: 1,
            filter: 1,
            search: '',
            city: null,
            state: null,
            cities: [],
            states: [],
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.rowData = [];
        state.loading_table = true;
        this.setState({ ...state });

        getRows(state, this.callbackRows.bind(this));
    }
    callbackCities(cities){
        var rows = [];
        console.log(cities)
        cities.forEach(p => {
            var json = {value: p, label: p};
            rows.push(json);
        });
        this.setState({cities: rows});
    }
    callbackStates(states){
        var rows = [];
        console.log(states)
        states.forEach(p => {
            var json = {value: p, label: p};
            rows.push(json);
        });
        this.setState({states: rows});
    }

    componentDidMount() {
        getRows(this.state, this.callbackRows.bind(this));
        getStates(this.callbackStates.bind(this));
    }
    callbackRows = (rowData) => {
        this.setState({ rowData,  loading_table: false  })
    }

    onFilter = (filter) => {
        this.setState({ filter, loading_table: true, rowData: [], search: "" });
        getRowsPerType(filter, this.state, this.callbackRows.bind(this));
    }
    handleSelectChangeState = value => {
        var state = this.state;
        state.state = value;
        state.city = null;
        state.cities = [];
        state.rowData = [];
        state.loading_table = true;
        this.setState({ ...state });

        getRows(state, this.callbackRows.bind(this));

        if(value !== null){
            getCity(value, this.callbackCities.bind(this));
        }
    };
    handleSelectChangeCity = city => {
        var state = this.state;
        state.city = city;
        state.rowData = [];
        state.loading_table = true;
        this.setState({ ...state });
        getRows(state, this.callbackRows.bind(this));
    };
    render() {
        return (
            <div>
                <h3>Estacionamento e Praças de pedágio</h3>
                <hr />
                <Card hidden={!getUser().permissao_gestor_aqpasso}>
                    <TableEstacionamento
                        isSearch={this.handleChange}
                        data={this.state.rowData}
                        onFilter={this.onFilter.bind(this)}
                        filter={this.state.filter}
                        state={this.state.state}
                        states={this.state.states}
                        city={this.state.city}
                        cities={this.state.cities}
                        handleSelectChangeState={this.handleSelectChangeState.bind(this)}
                        handleSelectChangeCity={this.handleSelectChangeCity.bind(this)}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhuma praça de pedagio encontrada
                            </div></CardBody></Card>
                        }
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <div hidden={getUser().permissao_gestor_aqpasso}>
                  <Card>
                      <CardBody>
                          <p>Você não tem permissões para acessar essa função</p>
                      </CardBody>
                  </Card>
                </div>
            <br/>
            <br/>
            </div>
        )
    }
}

export default Estacionamento;