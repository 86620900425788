import React from "react";
import { MdNote } from "react-icons/md";
import { Button, Card, CardBody, Row, TabPane, Input } from "reactstrap";
import Moment from 'moment';
import PropagateLoader from "react-spinners/PropagateLoader";

export const NotasPrivadas = (props) => {
    return (
        <TabPane tabId={props.active}>
            <br />
            <Card style={{filter: 'drop-shadow(#b8b8b8 1px 1px 2px)', borderRadius: '1.25rem'}}>
                <CardBody style={{paddingLeft: '39px'}}>
                    <h5>&nbsp;&nbsp;<MdNote style={{width: '30px', height: '30px'}}/> Ultimas Notas</h5>
                    <Row style={{maxHeight: '60vh', overflow: 'auto'}}>
                    <div style={{ marginTop: '15px', width: '95%' }}>
                        <Card style={{width: '100%', borderRadius: '1.25rem'}}>
                            <CardBody > 
                                <h6>
                                    <MdNote style={{width: '25px', height: '25px'}}/> 
                                    Nova Nota
                                </h6>
                                <hr/>
                                <div style={{ maxHeight: '150px', overflow: 'auto'}}>
                                    <Input 
                                        type="textarea"
                                        name="descricao"
                                        value={props.descricao}
                                        placeholder="Insira a descrição da nota"
                                        onChange={props.handleInputChange} />
                                </div>
                                <hr/>
                                <Button color="success" onClick={()=> props.createNote()}>Criar</Button>
                            </CardBody>
                        </Card>
                        <div hidden={!props.loading_note}>
                            <br/>
                            <center>
                                <small><PropagateLoader color={'#52c565'} loading={50} size={15} /><br />
                                    <b>Enviando nota, aguarde</b></small>
                            </center>
                        </div>
                        {
                            props.chat ?
                                props.chat.notas_privadas.slice(0,5).map(p =>{ return (
                                    <>
                                        <br/>
                                        <Card style={{width: '100%', borderRadius: '1.25rem'}}>
                                            <CardBody > 
                                                <h6>
                                                    <MdNote style={{width: '25px', height: '25px'}}/> 
                                                    Nota de {p.user_id === null ? <>Atendente<br/>Nome: {p.admin !== null ? p.admin.name : 'Sem Informações'}</> : <> Cliente<br/> Nome: {p.usuario.nome+" "+p.usuario.sobrenome} </> } <br/>
                                                    <small>Data de Criação: {Moment(new Date(p.created_at)).format('DD/MM/YYYY HH:mm')}</small>
                                                </h6>
                                                <hr/>
                                                <div style={{ maxHeight: '150px', overflow: 'auto'}}>
                                                    {p.descricao}
                                                </div>
                                            </CardBody>
                                        </Card>
                                        <br/>
                                    </>
                                )})
                            :
                            ''
                        }
                    </div>
                    </Row>
                </CardBody>
            </Card>
        </TabPane>
    )
}

//TabContent