import axios from 'axios';
import React from 'react';
import { Component } from 'react';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { Button, FormGroup, Input, Modal, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import { InputText } from '../../InputText/InputText';
import ModelReturn from '../ReturnsApiModals/ModelReturn';
const selected = [
    {
        code: 1,
        value: "Nonexistent",
        label: "Cobrança não existente"
    },
    {
        code: 2,
        value: "Duplicate",
        label: "Cobrança Duplicada"
    },
    {
        code: 3,
        value: "IncorrectAmount",
        label: "Cobrança de valor Incorreto"
    }
];
const SVGArea = styled.div`
    display: inline-flex;
    svg {
        color: #52c565;
        width: 2rem;
        height: 2rem;
    }
`;

class CreateContestacao extends Component {
    constructor(props){
        super(props);
        this.state = {
            motivo_contestacao: null,
            motivo_contestacao_invalid: false,
            descricao_contestacao: '',
            descricao_contestacao_invalid: false,
            valor_deposito: '',
            valor_deposito_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        }
        this.onRegister = this.onRegister.bind(this);
        this.onClose = this.onClose.bind(this);
    }
    onClose() {
        this.setState({
            motivo_contestacao: '',
            motivo_contestacao_invalid: false,
            descricao_contestacao: '',
            descricao_contestacao_invalid: false,
            valor_deposito: '',
            valor_deposito_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose(false);
    }
    handleSelectChange = motivo_contestacao => {
      this.setState({ motivo_contestacao });
    };

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({ [name]: value });
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }
    onRegister() {
        //if(this.validateFields() === 0){
            this.setState({loading: true});
            this.store();
        //}
    }
    
    store = () => {
        var url = ENDPOINT+"contestacao/store";

        var formData = new FormData();
        formData.append('valor_contestacao', this.state.valor_deposito);
        formData.append('descricao_contestacao', this.state.descricao_contestacao);
        formData.append('motivo_contestacao', this.state.motivo_contestacao.value);
        formData.append('codigo_transacao_greenpass', this.props.current_transacao.transacao_id);
        
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            this.onData(data);
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onData(obj);
            }
            var data = error.response.data;
            this.onData(data);
        });
    }
    onData(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }


    ocutar_mostrar_valor_desejado = () => {
        if(this.state.motivo_contestacao !== null){
            if(this.state.motivo_contestacao.code === 3){
                return true
            }
            else{
                return false
            }
        }
        else{
            return false
        }
    }

    render(){
        return (
            <Modal isOpen={this.props.visible}>
                <ModalBody hidden={this.state.loading}>
                    <SVGArea>
                        <HiOutlineSpeakerphone /><h4>Nova Contestação</h4>
                    </SVGArea>
                    <hr/>
                    <FormGroup>
                        <Select 
                            options={selected} 
                            placeholder="Motivo da Contestação"
                            onChange={this.handleSelectChange}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0
                            })}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input 
                            name="descricao_contestacao"
                            placeholder="Descrição da contestação"
                            type="textarea" 
                            style={{ 
                                height: "90px", 
                                resize: "none" 
                            }} 
                            value={this.state.descricao_contestacao}
                            invalid={this.state.descricao_contestacao_invalid}
                            onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <NumberFormat
                            name="valor_deposito"
                            placeholder="Valor Desejado"
                            customInput={InputText}
                            decimalScale={2}
                            value={this.state.valor_deposito}
                            invalid={this.state.valor_deposito_invalid}
                            fixedDecimalScale={true}
                            //thousandSeparator={true}
                            decimalSeparator={","}
                            hidden={!this.ocutar_mostrar_valor_desejado()}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalBody 
                    style={{
                        minHeight: '114px', 
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                <ModelReturn
                    error_message={this.state.error_message}
                    success_message={this.state.success_message}
                    loading={this.state.loading}
                    onClose={this.onClose}
                    onError={this.onError.bind(this)}
                />
                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() =>  this.onClose(false) }>Cancelar</Button>
                    <Button color="success" onClick={this.onRegister}>Abrir Contestação</Button>
                </ModalFooter>
            </Modal>
        )

    }
}

export default CreateContestacao;