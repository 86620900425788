import axios from "axios";
import React, { Component } from "react";
import Select from "react-select";
import { Button, Form, FormFeedback, FormGroup, Input, Modal, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import iconExclamation from '../../../assets/ico-exclamation.png';

const ModalBody = styled.div`
    .svg-inline--fa {
        width: 10em;
    }
    .svg-inline--fa.fa-w-14{
        width: 10em;
    }
    .prosemirror-toolbar-item{
        color: #52c565;
    }
    .prosemirror-toolbar-item[data-active='true'] {
        background-color: #52c565;
        color: #fff;
    }

    .rdw-option-wrapper{
        border: 0
    }
    .rdw-option-wrapper:active{
        background-color: #aaaaaa;
        box-shadow: none;
    }
    .rdw-option-wrapper:hover {
        background-color: #aaaaaa;
    }

    .rdw-dropdown-wrapper{
        border: 1px solid #ced4da;
    }
    .rdw-dropdown-wrapper:active{
        border: 1px solid #ced4da;
        box-shadow: none;
    }
    .rdw-dropdown-wrapper:hover{
        border: 1px solid #ced4da;
        box-shadow: none;
    }

    .rdw-dropdown-selectedtext{
        color: black
    }
    .rdw-dropdown-selectedtext:hover{
        text-decoration: none;
        color: black
    }
    .div-align {
        width: 40%;
        margin: 0px auto;
    }
    .div-align-text {
        text-align: center;
    }
    .modal-icon {
        width: 10rem;
        height: 8rem;
        padding: 1rem;
        border-radius: 50%;
        border: 5px solid #f1f3f1!important;
        box-shadow: 0px 3px 25px 0px rgb(113 106 202 / 20%);
    }
    h6 {
        display: block;
        position: relative;
        max-width: 100%;
        margin: 0 0 0.4em;
        padding: 0;
        color: #3b3f5c;
        font-size: 1.875em;
        font-weight: 600;
        text-align: center;
        text-transform: none;
        word-wrap: break-word; 
    }
    .span-info {
        justify-content: center;
        margin: 0;
        padding: 0;
        color: #e95f2b;
        font-size: 1.125em;
        font-weight: 300;
        line-height: normal;
    }
`;

class DeletarUsuario extends Component{
    constructor(props){
        super(props);
        this.state = {
            message: '',
            message_invalid: false,
            loading: false,
            error_message: '',
            success_message: '',
            select_fraud: {label: 'Ativo', value: true},
        }
    }

    onClose = () => {
        this.setState({
            message: '',
            message_invalid: false,

            loading: false,
            error_message: '',
            success_message: '',
        });

        this.props.onClose(false);
    }
    

    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    onData = (data) => {
        
        if(data.success === true){
            this.setState({
                success_message: data.message,
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }

    storeUser = () => {
        this.setState({ loading: true })
        var url = ENDPOINT+"querobus/user_delete/"+this.props.user_id;

        axios.delete(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            },
            data: {
                fraud: this.state.select_fraud.value,
                block_note: this.state.message,
            }
          })
        .then((response) => {
            var data = response.data;

            this.props.onClose(true);
        })
        .catch(error => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }

            var data = error.response.data;
            this.setState({
                error_message: data.errors
            })
        })
    }

    render(){
        return(
            <Modal isOpen={this.props.visible}>
                <ModalHeader>Remover Usuário ?</ModalHeader>
                <ModalBody className="modal-body" hidden={this.state.loading}>
                    <div className="div-align">
                        <div className="modal-icon">
                            <img src={iconExclamation} alt="logo" style={{height: '5rem', width: 'auto', position: 'relative', left: '-5px'}}/>
                        </div>
                    </div>
                    <div className="div-align-text">
                        <h6>Tem certeza ?</h6>
                        <span className="span-info">Você não será capaz de reverter isso!</span>
                    </div>
                </ModalBody>

                <ModalBody style={{ minHeight: '114px', display: 'flex', alignItems: 'center', alignSelf: 'center', paddingBottom: '47px' }} hidden={!this.state.loading}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                    />
                </ModalBody>

                <ModalFooter hidden={this.state.loading}>
                    <Button color="success" onClick={() => this.storeUser()}>Sim Remover</Button>
                    <Button color="danger" onClick={() => this.onClose()}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default DeletarUsuario;