import { Component } from "react";
import { Button, Card, CardBody } from "reactstrap";
import { getPermissoes, getToken } from "../../auth/set_services";
import { TableTicket } from "../../components/Tables/TableTicket/TableTicket";
import PropagateLoader from "react-spinners/PropagateLoader";
import { apagarRows, fecharMultiplosTickets, getRows, getRowsPerPage, mesclarRows } from "../../components/Tables/TableTicket/propsTicket";
import moment from "moment";
import { toast } from "react-toastify";
import CreateTicket from "../../components/Modals/CreateTicket/CreateTicket";
import { ImTicket } from "react-icons/im";
import { ENDPOINT } from "../../auth/endpoint";
import axios from "axios";

class Ticket extends Component{
    constructor(props){
        super(props);
        this.state = {
            cod_chat: null,
            rowData: [],
            activePage: 1,
            loading_table: true,
            //email: getId(),
            nome: '',
            email: '',
            status: null,
            assunto: '',

            document: '',
            protocolo_atendimento: '',
            totalPage: 1,
            filter: false,
            departamento: null,
            operador: null,
            mensagens_modal: false,
            mensagens_current: [],
            list_departamentos: [],
            search: '',
            visible_ticket: false,
            selected_rows: {
                allSelected: false,
                selectedCount: 0,
                selectedRows: []
            },
            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.rowData = [];
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentDidMount() {
        getRows(this.state, this.callbackRows.bind(this));
        this.getDepartamentos();
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }
    onFilter = (filter) => {
        this.setState({ filter });
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onMessage = (mensagens_current, cod_chat, mensagens_modal) => {
        this.setState({ mensagens_current, cod_chat, mensagens_modal });
    }
    onToast = (data) => {
        if(data.success === true){
            toast.success(data.message);
        }
        else{
            toast.error(data.errors);
        }
        //this.props.refresh();
    }
    onCloseTicket = (visible_ticket) => {
        if(visible_ticket === false){
            getRows(this.state, this.callbackRows.bind(this));
        }

        this.setState({visible_ticket})
    }

    getDepartamentos = () => {
        var url = ENDPOINT+"departamento/list";
        
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            var list_departamentos = [];
            var none = {
                value: 'none',
                label: 'Sem departamento'
            };
            
            list_departamentos.push(none);
            data.forEach((p, i) => {
                p['value'] = p.flag;
                p['label'] = p.nome;
                p['status'] = p.admins_online_count;
                list_departamentos.push(p);
            })
            this.setState({list_departamentos});
        })
        .catch((error) => {

        });
    }

    handleSelectChange = status => {
        var state = this.state;
        state['status'] = status;
        state.rowData = [];
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    };

    handleOperadorChange = operador => {
        var state = this.state;
        state['operador'] = operador;
        state.rowData = [];
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    };
    
    
    handleDepartamentoChange = departamento => {
        var state = this.state;
        state['departamento'] = departamento;
        state.rowData = [];
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    };
    onSelectedRows = (selected_rows) => {
        this.setState({selected_rows});
    }

    callbackMesclagem = (data) => {
        if(data.success === true){
            toast.success(data.message);
            var selected_rows = this.state.selected_rows;
            selected_rows.selectedCount = 1;
            selected_rows.allSelected = false;
            selected_rows.selectedRows = [
                this.state.selected_rows.selectedRows[0]
            ]

            this.setState({selected_rows});
            getRows(this.state, this.callbackRows.bind(this));
        }
    }

    callbackMesclagemDelete = (data) => {
        if(data.success === true){
            toast.success(data.message);
            var selected_rows = this.state.selected_rows;
            selected_rows.selectedCount = 0;
            selected_rows.allSelected = false;
            selected_rows.selectedRows = []

            this.setState({selected_rows});
            getRows(this.state, this.callbackRows.bind(this));
        }
    }
    
    mesclarTicket = () => {
        mesclarRows(
            this.state.selected_rows.selectedRows, 
            this.callbackMesclagem
        );
    }

    apagarTicket = () => {
        apagarRows(
            this.state.selected_rows.selectedRows, 
            this.callbackMesclagemDelete
        );
    }

    fecharTickets = () => {
        fecharMultiplosTickets(
            this.state.selected_rows.selectedRows, 
            this.callbackMesclagemDelete
        );
    }
    render(){
        return(
            <div>
                <h3>Ticket</h3>
                <hr />
                <div>
                    <Button onClick={() => this.onCloseTicket(true)} style={{marginBottom: 10}} color="success"><ImTicket /> Novo Ticket</Button>
                </div>
                <Card>
                    <TableTicket
                        //clientTicket
                        selectableRows
                        isSearch={this.handleChange}
                        data={this.state.rowData}
                        list_departamentos={this.state.list_departamentos}
                        departamento={this.state.departamento}
                        onFilter={this.onFilter.bind(this)}
                        mesclarTicket={this.mesclarTicket.bind(this)}
                        apagarTicket={this.apagarTicket.bind(this)}
                        fecharTickets={this.fecharTickets.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        selectedData={this.state.selected_rows}
                        onMessage={this.onMessage.bind(this)}
                        onSelectedRows={this.onSelectedRows.bind(this)}
                        callbackMesclagem={this.callbackMesclagem.bind(this)}
                        status={this.state.status}
                        operador={this.state.operador}
                        noDataComponent={this.state.loading_table === true ?
                            <Card style={{ border: 0, padding: '10vh' }}><CardBody>
                                <div><div className="sweet-loading">
                                    <PropagateLoader color={'#52c565'} loading={20} size={30} />
                                </div></div></CardBody></Card> :
                            <Card style={{ border: 0 }}><CardBody><div>
                                Nenhum ticket encontrado
                            </div></CardBody></Card>
                        }
                        handleSelectChange={this.handleSelectChange.bind(this)}
                        handleOperadorChange={this.handleOperadorChange.bind(this)}
                        handleDepartamentoChange={this.handleDepartamentoChange.bind(this)}
                        ticket_current={this.state.selected_rows}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <CreateTicket 
                    onClose={() => this.onCloseTicket(false)}
                    visible={this.state.visible_ticket}
                />
            </div>
        )
    }
}
export default Ticket;