import React from 'react';
import { HiViewGrid } from 'react-icons/hi';
import { Button, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getPermissoes, getToken, getUser } from '../../../auth/set_services';
import ReactTooltip from 'react-tooltip';
import { MdChat, MdDelete } from 'react-icons/md';
import 'react-dropdown/style.css';
import moment from 'moment';
import NumberFormat from 'react-number-format';


const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const columns = (onShowDelete) => [
  {
    name: '',
    selector: 'buttons',
    width: '70px',
    cell: row => <Row>
      {onShowDelete ? 
          <Button data-tip="Deletar Cliente"
            outline
            style={{ padding: 2, marginLeft: 7, border: 0, fontWeight: '700'}}
            color="danger"
            onClick={() => onShowDelete(row.id)}
          >
            <MdDelete style={{ padding: '2px' }} />
          </Button> 
        : 
        <></>}
      <Button data-tip="Resumo do Cliente"
        outline
        style={{ padding: '0px', marginRight: '3px', border: 0}}
        hidden={!getUser().permissao_gestor_aqpago}
        color="primary" onClick={() => 
          {
              window.location.href = '/user-pre-venda/'+row.id
          }
        }>
        <HiViewGrid style={{ padding: '2px', width: '23px' }} />
      </Button>
      <ReactTooltip/>
    </Row>
  },
  {
    name: "Data",
    selector: "created_at",
    sortable: false,
    width: '140px',
    cell: row => moment(new Date(row.created_at)).format('DD/MM/YYYY HH:mm')
  },
  {
    name: "Nome do Responsavel",
    selector: "nome",
    sortable: false,
    cell: row => <div>
      {row.sobrenome !== null ? row.nome+" "+row.sobrenome : row.nome}<br/>
      <small><b>{row.empresa !== null ? row.empresa.razao_social : 'Conta Pessoal'}</b></small>
    </div>
  },
  /* {
    name: "Empresa",
    selector: "user_tipo",
    sortable: false,
    cell: row => row.empresa !== null ? row.empresa.razao_social : 'Conta Pessoal'
  }, */
  {
    name: "Vendedor",
    selector: "email",
    sortable: false,
    cell: row => row.vendedor !== null ? row.vendedor.name : 'Sem Vendedor'
  },
  {
    name: "Email do Responsavel",
    selector: "email",
    sortable: false,
    cell: row => row.email
  },
  {
    name: "Documento",
    selector: "cpf",
    sortable: false,
    width: '162px',
    cell: row => {
      var value = row.cpf !== '' && row.cpf !== null ? row.cpf : row.cnpj;
      if(value !== null){
        return <NumberFormat displayType={'text'} value={value} format={value.replace(/([^\d])+/gim, '').length > 11 ?  '##.###.###/####-##' : "###.###.###-##"} />
      }
      return "";
    }
  },
  /* {
    name: "Status do Cadastro",
    selector: "cad_completo",
    sortable: false,
    width: '10%',
    cell: row => row.cad_completo !== 0 ? 'Completo' : row.etapa_cad
  }, */
  {
    name: "Status",
    selector: "status",
    sortable: false,
    width: '80px',
    cell: row => row.status !== null ? capitalizeFirstLetter(row.status) : "Indisponivel"
  },
  {
    name: "Ultimo chat",
    selector: "chats",
    sortable: false,
    width: '170px',
    cell: row => row.chats.length > 0 ? 
        <div style={{ maxHeight: '200px', overflow: 'auto', width: '100%', margin: '12px' }}>
        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>{row.chats.slice(0, 1).map((p, i) => {
        return (
            <li>
                <p style={{margin: 0}}>
                    <b>
                        <MdChat />
                        Realizado em: </b><br/>{moment(new Date(p.created_at)).format('DD/MM/YYYY HH:mm')}
                </p>
            </li>
        )
    })}</ul></div> : ''
  },
];


export const getRows = (state, callback, pending = false, pdv = false) => {
  var url = ENDPOINT + "user_chat/all?page=" + state.activePage + "&search=" + state.search;
  if(pending === true){
    url+= "&is_pending="+pending;
  }
  if(pdv === true){
    url+= "&pdv="+pdv;
  }
  var document = state.document.replace(/([^\d])+/gim, '');
  if(state.is_cnpj === false){
    if(document.length === 11){
      url += "&cpf="+document;
    }
  }
  else{
      if(document.length === 14){
        url += "&cnpj="+document;
      }
  }
  if(state.feito_por_pedido === 0 || state.feito_por_pedido === 1){
    url += "&feito_por_pedido="+state.feito_por_pedido;
  }

  if(state.operador){
    if(state.operador !== null){
      url += "&vendedor="+state.operador.value
    }
  }

  if(state.status_user.length > 0){
    url += "&status_usuario="+state.status_user
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      callback(p.data, p.last_page)
    })
}

export const getRowsPerPage = (page, state, callback, pending = false, pdv = false) => {
    var url = ENDPOINT + "user_chat/all?page=" + page + "&search=" + state.search;
    if(pending === true){
      url+= "&is_pending="+pending;
    }
    if(pdv === true){
      url+= "&pdv="+pdv;
    }
  var document = state.document.replace(/([^\d])+/gim, '');

  if(state.is_cnpj === false){
    if(document.length === 11){
      url += "&cpf="+document;
    }
  }
  else{
      if(document.length === 14){
        url += "&cnpj="+document;
      }
  }
  if(state.feito_por_pedido === 0 || state.feito_por_pedido === 1){
    url += "&feito_por_pedido="+state.feito_por_pedido;
  }


  if(state.operador){
    if(state.operador !== null){
      url += "&vendedor="+state.operador.value
    }
  }
  
  if (state.filter === true) {
    url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
  }

/*   if(state.type_user.length > 0){
    url += "&tipo_usuario="+state.type_user
  } */


  if(state.status_user.length > 0){
    url += "&status_usuario="+state.status_user
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      callback(p.data, p.last_page)
    })
}