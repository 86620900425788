import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import { MdAlarm, MdSend } from 'react-icons/md';
import { Button, ButtonGroup } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

/* 
const getStatus = (status) => {
    if(status === 1){
        return "Transação inexistente"
    }
    else if(status === 2){
        return "Passagem Duplicada"
    }
    else{
        return "Cobrança de valor incorreto"
    }
}
 */

const formatPayment = (value) => {
    switch(value){
        case 'credit': return 'Cartão de crédito'
        case 'boleto': return 'Boleto Bancario'
        case 'a definir': return 'A definir'
        default: return value;
    }
}

const validar_lembrete = (lembrete) => {
    if(new Date(lembrete) < new Date()){
        return true;
    }
    return false;
}
export const columns = () => [
    {
        name: "Data",
        selector: "created_at",
        sortable: false,
        width: '150px',
        cell: row => moment(row.created_at).format('DD/MM/yyyy HH:mm')
    },
    {
      name: "Documento",
      selector: "status",
      sortable: false,
      width: '150px',
      cell: row => row.conta !== null ? (row.conta.user !== null ? (row.conta.user.cnpj ? row.conta.user.cnpj : row.conta.user.cpf) : '')  : 'Sem Informações'
    },
    {
        name: "Descrição",
        selector: "tipo_movimentacao",
        sortable: false,
        cell: row => row.tipo_movimentacao
    },
    {
        name: "Status",
        selector: "status",
        sortable: false,
        width: '120px',
        cell: row => row.deposito_conta !== null ? (row.deposito_conta.pagamento ? row.deposito_conta.pagamento.status : '') : ''
    },
    {
        name: 'Valor',
        selector: 'valor',
        sortable: false,
        width: '120px',
        cell: row => "R$ "+parseFloat(row.valor).toFixed(2)
    },
    {
        name: 'Forma de Pagamento',
        selector: 'valor',
        sortable: false,
        width: '120px',
        cell: row => formatPayment(row.deposito_conta ?  row.deposito_conta.pagamento.forma_pagamento : '')
    },
    {
        name: "Parcelas",
        selector: "max_parcelas",
        sortable: false,
        width: '100px',
        cell: row => row.deposito_conta ?  <h6 style={{textAlign: 'right'}}>{row.deposito_conta.pagamento.numero_parcelas + "X"}</h6> : 'Sem Informações'
    },
    {
        name: 'Saldo Anterior',
        selector: 'saldo_anterior',
        sortable: false,
        width: '120px',
        cell: row => "R$ "+parseFloat(row.saldo_anterior).toFixed(2)
    },
    {
        name: 'Saldo',
        selector: 'saldo',
        sortable: false,
        width: '120px',
        cell: row => "R$ "+parseFloat(row.saldo).toFixed(2)
    },//,
    /* {
      name: "",
      selector: "functions",
      sortable: false,
      cell: row => row.link ? <ButtonGroup>
          <Button 
              data-tip="Reenviar link de pagamento"
              color 
              disabled={row.link.concluido}
              onClick={() => updateCobranca(row.link.hash_id, row.user.id)}
              style={{ padding: '0px', margin: '3px', border: 0}}>
              <MdSend style={{ padding: '2px', width: '23px' }}/>
            </Button>
          <Button 
              data-tip="Enviar lembrete de pagamento"
              color 
              disabled={row.link.concluido}
              hidden={!validar_lembrete(row.validade)}
              onClick={() => updateCobranca(row.link.hash_id, row.user.id)}
              style={{ padding: '0px', margin: '3px', border: 0}}>
              <MdAlarm style={{ padding: '2px', width: '23px' }}/>
            </Button>
            <ReactTooltip />
        </ButtonGroup> : ''
    } */
];

export const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "cobranca/list?search=" + state.search;

    if (state.user_id !== null) {
        url += '?user_id=' + state.user_id;
    }
    console.log(state.validade);
    if (state.validade === true) {
        url += '&validade=';
    }
    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
        if(document.length === 11){
            url += "&cpf="+document;
        }
    }
    else{
        if(document.length === 14){
        url += "&cnpj="+document;
        }
    }

    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    
    var url = ENDPOINT + "cobranca/list?search=" + search + "&page=" + page;

    if (state.user_id !== null) {
        url += '&user_id=' + state.user_id;
    }
    if (filter === true) {
        url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }

    if (state.validade === true) {
        url += '&validade=true';
    }

    if (state.status_pagamento !== null) {
        url += '&status_pagamento=' + state.status_pagamento.value;
    }

    if (state.forma_pagamento !== null) {
        url += '&forma_pagamento=' + state.forma_pagamento.value;
    }
    
    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&cpf="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&cnpj="+document;
        }
    }

    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}