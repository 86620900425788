import React, { Component } from 'react';
import { Card, CardBody, TabPane } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Moment from 'moment';
import { ENDPOINT } from '../../auth/endpoint';
import axios from 'axios';
import { getToken } from '../../auth/set_services';
import { FaWhatsapp } from 'react-icons/fa';
import { TableWhatsApp } from '../Tables/TableWhatsApp/TableWhatsApp';

const getRows = (communication_relacao_id, state, callbackRows) => {
    if(typeof communication_relacao_id === 'undefined'){
        return null;
    }
    var url = ENDPOINT + "whatsapp/list?communication_relacao_id="+communication_relacao_id;
  
    axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + getToken()
      }
    }).then((response) => {
      callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
  }
  
const getRowsPerPage = (communication_relacao_id, page, search, filter, state, callbackRows) => {
    if(typeof communication_relacao_id === 'undefined'){
        return null;
    }
    var url = ENDPOINT + "whatsapp/list?communication_relacao_id="+communication_relacao_id+"&search=" + search + "&page=" + page;
  
    if (filter === true) {
      url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }
  
    axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + getToken()
      }
    }).then((response) => {
      callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

class WhatsAppClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            activePage: 1,
            loading_table: true,
            //email: getId(),
            client_sms: false,
            totalPage: 1,
            filter: false,
            search: '',
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(this.props.communication_relacao_id, 1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.communication_relacao_id !== this.props.communication_relacao_id) {
            getRows(nextProps.communication_relacao_id, this.state, this.callbackRows.bind(this))
        }
    }
    componentDidMount() {
        getRows(this.props.phone, this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    onFilter = (filter) => {
        this.setState({ filter });
        getRowsPerPage(this.props.communication_relacao_id, 1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(this.props.communication_relacao_id, activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    render() {
        return (
            <TabPane tabId={this.props.active}>
            <br />
            <h5>&nbsp;&nbsp;<FaWhatsapp style={{width: '30px', height: '30px'}}/> WhatsApp's de {this.props.name}</h5>
            <Card>
                    <TableWhatsApp
                        clientSMS
                        isSearch={this.handleChange}
                        data={this.state.rowData}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        noDataComponent={this.state.loading_table === true ?
                            <Card style={{ border: 0, padding: '10vh' }}><CardBody>
                                <div><div className="sweet-loading">
                                    <PropagateLoader color={'#52c565'} loading={20} size={30} />
                                </div></div></CardBody></Card> :
                            <Card style={{ border: 0 }}><CardBody><div>
                                Nenhum e-mail encontrado
                            </div></CardBody></Card>
                        }
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
            </TabPane>
        )
    }
}

export default WhatsAppClient;