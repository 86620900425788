export function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export function formatDocument(type, document) {
  if (type === "PF") {
    return document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
  } else if (type === "PJ") {
    return document.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      "$1.$2.$3/$4-$5"
    );
  }
  return document;
}

export function castToMoney(valor) {
  console.log("valor", valor)
  if(!valor) return "R$ 0,00";
  var partes = valor.toString().split(".");
  var inteiro = partes[0];
  var decimal = partes.length > 1 ? partes[1] : "00";
  
  inteiro = inteiro.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  

  return "R$ " + inteiro + "," + decimal;
}

export const booleanToYesOrNo = (value) => {
  if (value) return "Sim";
  return "Não";
};

export function formatPhone(phone) {
  if(!phone)
    return
  const phoneNumber = phone.replace(/\D/g, '');
  if (phoneNumber.length === 11) {
    return `(${phoneNumber.substring(0, 2)}) ${phoneNumber.substring(2, 7)}-${phoneNumber.substring(7)}`;
  } else {
    return phone;
  }
}

export function castingDate(data) {
  if(!data)
    return
  let partes = data.split(" ");
  let dataPartes = partes[0].split("-");
  let hora = partes[1];
  let novaData = `${dataPartes[0]}/${dataPartes[1]}/${dataPartes[2]} ${hora}`;

  return novaData;
}

export function statusCafTranslate(status) {
  if (status === "APPROVED") return "APROVADO";
  if (status === "REPROVED") return "REPROVADO";
  if (status === "PENDING") return "PENDENTE";
  return status;
}

export const getDocumentType = (document) => {
  if (document.length === 11) {
    return "CPF";
  }
  if (document.length === 14) {
    return "CNPJ";
  }
  return false;
}
