import './set_services';
import { getToken, get_permission, logout, refreshUser, removeKeys, setData, setToken, setTokenShop } from './set_services';
import { ENDPOINT, ENDPOINT_SHOP } from './endpoint';
import axios from 'axios';

export const login = (email, password, token_capcha, onData) => {
    var url = ENDPOINT+"auth/first_login";

    var formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('capcha', token_capcha);
    
    axios.post(url, formData)
    .then((response) => {
        var data = response.data;
        setToken(data.access_token);
        onData(data);
        return data;
    })
    .catch((error) => {
        var response = '';
        var obj;
        if(error.message === 'Network Error'){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        if(data.exception){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        if(typeof data.message !== 'undefined'){
            if(data.message === 'Server Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
        }
        onData(data);
        return data;
    });

}

export const secoundLogin = (code, token, onData) => {
    var url = ENDPOINT+"auth/secound_login";

    var formData = new FormData();
    formData.append('code_phone', code);
    formData.append('token_sms_code', window.atob(token));

    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer '+localStorage.getItem('@api.aqpasso.temp.token')
        }
    })
    .then((response) => {
        var data = response.data;
        setData(JSON.stringify(data.data), JSON.stringify(data.data.permissao), data.data.status_chat);
        onData(data);
        return data;
    })
    .catch((error) => {
        var response = '';
        var obj;
        if(error.message === 'Network Error'){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        if(data.exception){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        if(typeof data.message !== 'undefined'){
            if(data.message === 'Server Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
        }
        onData(data);
        return data;
    });

}

export const rendToken = (token, onData) => {
    var url = ENDPOINT+"auth/rend_token";

    var formData = new FormData();
    formData.append('token_sms_code', window.atob(token));

    axios.post(url, formData)
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        var response = '';
        var obj;
        if(error.message === 'Network Error'){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        if(data.exception){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        if(typeof data.message !== 'undefined'){
            if(data.message === 'Server Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
        }
        onData(data);
        return data;
    });

}




export const getTokenloginShop = async (tax_document) => {
    var url = ENDPOINT+"aqenvio/auth";
    //var url = "http://127.0.0.1:8000/api/aqenvio/auth";

    let responseToken = await axios.get(url, {
        params: {
            tax_document: tax_document
        },
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    })
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            // console.log(error.response.status); // status code
            // console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
    })

    return responseToken?.token;
}

export const loginShop = async (token, tax_document) => {
    var url = ENDPOINT_SHOP+"auth/login";

    var formData = new FormData();
    formData.append('tax_document', tax_document);

    let response = await axios.post(url, formData, {
        headers: {
            'Authorization' : token
        }
    })
    .then((response) => {
        var data = response.data;
        setTokenShop(data.token);
        return data.token;
    })
    .catch((error) => {
        var response = '';
        var obj;
        if(error.message === 'Network Error'){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);;
            return obj;
        }
        var data = error.response.data;
        if(data.exception){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            return obj;
        }
        if(typeof data.message !== 'undefined'){
            if(data.message === 'Server Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                return obj;
            }
        }
        return data;
    });

    return response;
}

/* const checkAccessSystem = (access_system) => {
    switch(access_system){
        case 'analista':
            return false
        case 'gestor_setor':
            return true
        case 'admin':
            return true
        case 'supervisor_setor':
            return false
        case 'vendedor':
            return false
        case 'master':
            return true
        default:
            return true
    }
} */

export const register = (state, onData) => {
    var url = ENDPOINT+"admin/register";

    var formData = new FormData();
    formData.append('email', state.email);
    formData.append('name', state.name);
    formData.append('cpf', state.cpf.replace(/([^\d])+/gim, ''));
    formData.append('telefone', state.telefone.replace(/([^\d])+/gim, ''));
    formData.append('access_system', state.access_system.value);
    formData.append('avatar_foto', state.image_current);
    formData.append('validacao_ip', Number(state.ip_valid));
    if(state.setor !== null){
        formData.append('departamento_chat', state.setor.value);
    }

    formData.append('enable_monitoramento', Number(state.enable_monitoramento));
    formData.append('limite_chat', Number(state.limite_chat));
    formData.append('status_chat', state.status_chat ? state.status_chat.id : 0);

    state.list_enderecos_ip.forEach((e, index) => {
        formData.append('list_enderecos_ip['+index+']', JSON.stringify(e));
    });

    formData.append('assinatura_email_name', state.assinatura_email.value);

    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        //setData(data.access_token, JSON.stringify(data.user));
        onData(data);
        return data;
    })
    .catch((error) => {
        var response = '';
        var obj;
        if(error.message === 'Network Error'){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        if(data.exception){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        if(typeof data.message !== 'undefined'){
            if(data.message === 'Server Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
        }
        onData(data);
        return data;
    });

}
export const resetPassword = (email, onData) => {
    var url = ENDPOINT+"auth/reset_password";

    var formData = new FormData();
    formData.append('email', email);

    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        //setData(data.access_token, JSON.stringify(data.user));
        onData(data);
        return data;
    })
    .catch((error) => {
        var response = '';
        var obj;
        if(error.message === 'Network Error'){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        if(data.exception){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        if(typeof data.message !== 'undefined'){
            if(data.message === 'Server Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
        }
        onData(data);
        return data;
    });

}

export const update = (state, id, onData) => {
    
    var url = ENDPOINT+"admin/update";

    var formData = new FormData();
    formData.append('id', id);
    formData.append('email', state.email);
    formData.append('name', state.name);
    formData.append('cpf', state.cpf.replace(/([^\d])+/gim, ''));
    formData.append('telefone', state.telefone.replace(/([^\d])+/gim, ''));
    formData.append('access_system', state.access_system.value);
    formData.append('avatar_foto', state.image_current);
    formData.append('validacao_ip', Number(state.ip_valid));

    if(state.setor !== null){
        formData.append('departamento_chat', state.setor.value);
    }
    formData.append('enable_monitoramento', Number(state.enable_monitoramento));
    formData.append('limite_chat', Number(state.limite_chat));
    formData.append('status_chat', state.status_chat ? state.status_chat.id : 0);

    state.list_enderecos_ip.forEach((e, index) => {
        formData.append('list_enderecos_ip['+index+']', JSON.stringify(e));
    });

    formData.append('assinatura_email_name', state.assinatura_email.value);
    
    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        var response = '';
        var obj;
        if(error.message === 'Network Error'){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        if(data.exception){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        if(typeof data.message !== 'undefined'){
            if(data.message === 'Server Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
        }
        onData(data);
        return data;
    });

}

export const delete_admin = (id, onData) => {
    var url = ENDPOINT+"admin/delete/"+id;

    axios.delete(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        onData(data);

        return data;
    })
    .catch((error) => {
        var response = '';
        var obj;
        if(error.message === 'Network Error'){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        if(data.exception){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        if(typeof data.message !== 'undefined'){
            if(data.message === 'Server Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
        }
        onData(data);
        return data;
    });

}

export const requestInfo = (token) => {
    var url = ENDPOINT+"auth/info";

    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+token
        }
    })
    .then((response) => {
        var data = response.data;
        //var actualyUser = JSON.parse(user);
        if(data === ""){
            logout();
        }
        if(data.user.code_status === 0){
            logout();
        }
        refreshUser(JSON.stringify(data.user), JSON.stringify(data.user.permissao), data.user.status_chat);
        if(data.user.access_system !== get_permission){
            window.location.reload();
            return false;
        }
    })
    .catch((error) => {
        if(error.message === 'Network Error'){
            return false;
        }
        var data = error.response;
        if(data.status === 401){
            logout();
        }
        return false;
    });
}

export const isLogout = (token) => {
    var url = ENDPOINT+"auth/logout";

    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+token
        }
    })
    .then(() => {
        removeKeys();
        if(window.location.pathname !== '/widget'){
            window.location.replace("/");
        }
        return true;
    })
    .catch((error) => {
        removeKeys();
        if(window.location.pathname !== '/widget'){
            window.location.replace("/");
        }
        return false;
    });

} 

export const alterMyPassword = (state, onData) => {
    var url = ENDPOINT+"admin/update-password";

    var formData = new FormData();
    formData.append('password', state.new_password);
    formData.append('password_confirmation', state.repeat_password);

    axios.post(url, formData, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        var response = '';
        var obj;

        if(error.message === 'Network Error'){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        if(data.exception){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        if(typeof data.message !== 'undefined'){
            if(data.message === 'Server Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
        }
        onData(data);
        return data;
    });

}

export const firstPassword = (state, onData) => {
    var url = ENDPOINT+"auth/first-password";

    var formData = new FormData();
    formData.append('token', atob(state.token));
    formData.append('password', state.new_password);
    formData.append('password_confirmation', state.repeat_password);

    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        //setData(data.access_token, JSON.stringify(data.user));
        onData(data);
        return data;
    })
    .catch((error) => {
        var response = '';
        var obj;
        if(error.message === 'Network Error'){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        if(data.exception){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        if(typeof data.message !== 'undefined'){
            if(data.message === 'Server Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
        }
        onData(data);
        return data;
    });

}

export const alterPassword = (state, onData) => {
    var url = ENDPOINT+"auth/update_password";

    var formData = new FormData();
    formData.append('password', state.new_password);
    formData.append('password_confirmation', state.repeat_password);
    formData.append('token', state.token);

    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        //setData(data.access_token, JSON.stringify(data.user));
        onData(data);
        return data;
    })
    .catch((error) => {
        var response = '';
        var obj;
        if(error.message === 'Network Error'){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        if(data.exception){
             response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
             obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        if(typeof data.message !== 'undefined'){
            if(data.message === 'Server Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
        }
        onData(data);
        return data;
    });

}