import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { Loader } from "../../../components/Loaders/Loader";
import styled from "styled-components";
import { Button } from "reactstrap";
import {
  booleanToYesOrNo,
  formatPhone,
} from "../../../utils";
import { useLocation } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { getUser } from "../../../auth/set_services";
import NumberFormat from "react-number-format";
import { ModalRemovePlan } from "../../../components/Modals/AQMed/ModalRemovePlan";
import { useApiAqmed } from "../../../hooks/useApi";

const Board = styled.div`
  background-color: #f5f5f5;
  margin-top: 30px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h2 {
    margin-bottom: 10px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin-bottom: 5px;
  }
`;

export function ViewClient() {
  const url = useLocation().pathname;
  const user = getUser();
  const urlArray = url.split("/");
  const id = urlArray[urlArray.length - 1];

  const [isFetching, setIsFetching] = useState(true);
  const [userData, setUserData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { apiAqmed } = useApiAqmed()
  const onClose = () => setIsOpen(false);
  useEffect(() => {
    const token = Cookies.get("aqmedtoken");
    console.log("token = ", token);
    apiAqmed
      .get(`/crm/clients/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        const client = res?.data?.client;
        setUserData(client);
      })
      .catch((e) => {
        alert("Erro ao buscar dados");
        console.log(e);
      })
      .finally(() => setIsFetching(false));
  }, []);
  if (isFetching) {
    return <Loader />;
  } else {

    return (
      <div>
        <h3>Dados do Cliente</h3>
        <hr />
        <Board>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4>
              <strong>{userData?.name}</strong>
            </h4>
            <Button
              style={{ justifyContent: "center" }}
              color="danger"
              onClick={() => setIsOpen(true)}
            >
              Remover Plano
              <MdDelete style={{ padding: 0 }} />
            </Button>
          </div>
          <ul>
            <li>
              <strong>Número do contrato: </strong>
              {userData?.contract_number}
            </li>
            <li>
              <strong>Débito Automático: </strong>
              {booleanToYesOrNo(userData?.automatic_billing)}
            </li>
            <li>
              <strong>Data de Criação: </strong>
              {userData?.created_at}
            </li>
            <li>
              <strong>Nome do Plano do usuário: </strong>
              {userData?.userPlan_name}
            </li>
            <hr />
            <li>
              <strong>Email: </strong>
              {userData?.email}
            </li>

            <li>
              <strong>Data de nascimento: </strong>
              {userData?.date_birth}
            </li>
            <li>
              <strong>Documento:</strong>{" "}
              <NumberFormat
                displayType={"text"}
                value={userData?.document}
                format={
                  userData?.document.replace(/([^\d])+/gim, "").length > 11
                    ? "##.###.###/####-##"
                    : "###.###.###-##"
                }
              />
            </li>
            <li>
              <strong>Telefone: </strong>
              {formatPhone(userData?.phone_number)}
            </li>
            <li>
              <strong>Status: </strong>
              {userData?.status}
            </li>
            <li>
              <strong>Sexo: </strong>
              {userData?.gender.toUpperCase()}
            </li>
            <li>
              <strong>Possui Plano Totalpass: </strong>
              {booleanToYesOrNo(userData?.plan_has_totalpass)}
            </li>
            <li>
              <strong>Possui Plano Uniodonto: </strong>
              {booleanToYesOrNo(userData?.plan_has_uniodonto)}
            </li>
            <li>
              <strong>Usuário Totalpass: </strong>
              {booleanToYesOrNo(userData?.user_has_totalpass)}
            </li>
            <li>
              <strong>Usuário Uniodonto: </strong>
              {booleanToYesOrNo(userData?.user_has_totalpass)}
            </li>

            <li>
              <strong>Email Verificado: </strong>
              {booleanToYesOrNo(userData?.email_verified)}
            </li>
            <li>
              <strong>Telefone Verificado: </strong>
              {booleanToYesOrNo(userData?.verify_phone)}
            </li>
          </ul>
        </Board>
        <ModalRemovePlan
          isOpen={isOpen}
          onClose={onClose}
          size={"lg"}
          config={{ userId: userData?.public_id }}
        />
      </div>
    );
  }
}
