import React,{ Component } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import { getRows, getRowsPerPage } from '../../components/Tables/TableNps/propsNps';
import { TableNps } from '../../components/Tables/TableNps/TableNps';
import Moment from "moment";
import { Doughnut } from "react-chartjs-2";
import CreateNps from '../../components/Modals/CreateNps/CreateNps';
import SendNps from "../../components/Modals/CreateNps/SendNps";

class PesquisaNPS extends Component {
    constructor(props){
        super(props);
        this.state = {
            rowData: [],
            activePage: 1,
            loading_table: true,
            //email: getId(),
            totalPage: 1,
            filter: false,
            visible_nps: false,

            cod_pergunta_index: '',
            visible_edit: false,
            search: '',
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentDidMount() {
        getRows(this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    onFilter = (filter) => {
        this.setState({ filter });
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }

    data = (data) => {
        var generateColors = [
            '#c30000', 'red', '#ff6000', '#ffa900', '#ffd800', '#ecff00', '#bcff00', '#6aff00', '#0edb0e', '#2ab400'
        ];
      return {
        labels: data.labels,
        datasets: [
          {
            label: 'Avaliação',
            data: data.datasets,
            fill: false,
            backgroundColor: generateColors,
            borderColor: 'transparent',
          },
        ],
  
      }
    }

    ExpandableRows = ({data}) => {
        //const dataAll = JSON.parse(data.resposta);
        return <div style={{margin: '20px'}}>
            <Row>
                <Col md={8}>
                    <Doughnut width={500} data={this.data(data.relatorio)} />
                </Col>
                <Col>
                    <b>Informações da Pesquisa: </b>
                    <p>Enviados: {data.enviados}<br/>
                       Respondidos: {data.respondidos}<br/>
                       Pendentes de envio: {data.pendentes}<br/>
                       Pendentes de resposta: {data.pendentes_resposta}</p>
                    <Button color="success" onClick={() => this.onOpenEdit(data.cod_pergunta)}>Enviar</Button>
                </Col>
            </Row>
        </div>
    }
    onClose(visible_nps){
        this.setState({visible_nps});
        if(visible_nps === false){
            getRows(this.state, this.callbackRows.bind(this));
        }
    }
    onCloseEdit(visible_edit){
        this.setState({visible_edit});
        if(visible_edit === false){
            this.setState({cod_pergunta_index: ''})
            getRows(this.state, this.callbackRows.bind(this));
        }
    }
    onOpenEdit(cod_pergunta_index){
        this.setState({cod_pergunta_index, visible_edit: true});
    }
    render(){
        return (
            <div>
                <h3>Pesquisa NPS</h3>
                <hr />
                <div>
                    <Button color="success" onClick={() => this.onClose(true)}>Criar NPS</Button>
                    <br/><br/>
                    <Card>
                        <TableNps
                            isSearch={this.handleChange}
                            data={this.state.rowData}
                            phone={this.state.phone}
                            onFilter={this.onFilter.bind(this)}
                            onPageChange={this.onPageChange.bind(this)}
                            filter={this.state.filter}
                            noDataComponent={this.state.loading_table === true ?
                                <Card style={{ border: 0, padding: '10vh' }}><CardBody>
                                    <div><div className="sweet-loading">
                                        <PropagateLoader color={'#52c565'} loading={20} size={30} />
                                    </div></div></CardBody></Card> :
                                <Card style={{ border: 0 }}><CardBody><div>
                                    Nenhuma pesquisa encontrada
                                </div></CardBody></Card>
                            }
                            expandableRowsComponent={<this.ExpandableRows />}
                            activePage={this.state.activePage}
                            totalPage={this.state.totalPage}
                            date_from={this.state.date_from}
                            date_to={this.state.date_to}

                        />
                        <CreateNps
                            visible={this.state.visible_nps}
                            onClose={() => this.onClose(false)}
                        />
                        <SendNps 
                            visible={this.state.visible_edit}
                            onClose={() => this.onCloseEdit(false)}
                            cod_pergunta={this.state.cod_pergunta_index}
                        />
                    </Card>

                </div>
            </div>
        )
    }
}

export default PesquisaNPS;