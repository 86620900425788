import React from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, Row, Col, Collapse, Input, CardFooter, Button } from 'reactstrap';
import { FaFileExcel } from 'react-icons/fa' 
import { columns } from './propsVendas';
import debounce from 'lodash/debounce';
import Pagination from '../Pagination';
import { InputText } from '../../InputText/InputText';
import { InputMasked } from '../../InputText/InputMasked';
import Select from 'react-select';
import ecommerce_logo from '../../../assets/ecommerce-aqpago.svg';

const maquinas_status = [
    {
        value: 'APROVADO',
        label: 'Aprovado'
    },
    {
        value: 'PENDENTE',
        label: 'Pendente'
    },
    {
        value: 'REPROVADO',
        label: 'Reprovado'
    },
    {
        value: 'CANCELADA',
        label: 'Cancelada'
    },
    {
        value: 'FALHA DO PAGAMENTO',
        label: 'Falhada'
    }
];

const formas_pagamento_list = [
    {
        label: 'Boleto Bancario',
        value: 'boleto'
    },
    {
        label: 'Cartão de Crédito',
        value: 'credit'
    }
]

const maquininhas_list = [
    {
        modelo: 'MP5',
        label: 'Maquininha MP5',
        value: 'MP5',
        valor: 199.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/mp5.webp'
    },
    {
        modelo: 'D195',
        label: 'Maquininha D195',
        value: 'D195',
        valor: 399.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/d195.webp'
    },
    {
        modelo: 'S920',
        label: 'Maquininha S920',
        value: 'S920',
        valor: 599.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/s920.webp'
    },
    {
        modelo: 'MP35P',
        label: 'Maquininha MP35P',
        value: 'MP35P',
        valor: 999.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/d195.webp'
    },
    {
        modelo: 'linkpay',
        label: 'Link de Pagamento',
        value: 'linkpay',
        valor: 99.00,
        image: 'https://aqpago.com.br/assets/img/home/melhor-escolha/link-pagamento/link-pagamento.svg'
    },
    {
        modelo: 'shopaqpago',
        label: 'E-commerce',
        value: 'shopaqpago',
        valor: 99.00,
        image: ecommerce_logo
    }
];

const pedido_list = [
    { value: 'alteracao', label: "Aguardando alteração" },
    { value: "entrega", label: "Aguardando entrega" },
    { value: "cliente", label: "Aguardando cliente" },
    { value: "analise", label: "Pedido em análise" },
    { value: "aprovado", label: "Pedido aprovado" },
    { value: "reprovado", label: "Pedido reprovado" },
    { value: "cancelado", label: "Pedido cancelado" },
    { value: "estornado", label: "Pedido estornado" },
    { value: "chargeback", label: "Pedido chargeback" },
    { value: "enviado", label: "Pedido enviado" },
    { value: "entregue", label: "Pedido entregue" },
    { value: "devolvido", label: "Pedido devolvido" },
    { value: "descredenciado", label: "Descredenciado" }
];

const status_envio_list = [
    {  value: 'Aguardando Entrega', label: "Aguardando Entrega" },
    {  value: 'Pedido Enviado', label: "Pedido Enviado" },
    {  value: 'Pedido Entregue', label: "Pedido Entregue" },
    {  value: 'Pedido Devolvido', label: "Pedido Devolvido" },
    {  value: 'Aguardando Reversa', label: "Aguardando Reversa" },
    {  value: "Reversa Em Trânsito", label: "Reversa Em Trânsito" },
    {  value: 'Reversa Recebida', label: "Reversa Recebida" },
    {  value: 'Prioridade Entrega Ouvidoria', label: "Prioridade Entrega Ouvidoria" },
    {  value: 'Prioridade Entrega Reclamação', label: "Prioridade Entrega Reclamação" },
    {  value: 'Pendente Devolver Máquina', label: "Pendente Devolver Máquina" },
    {  value: 'Pendente Troca de Garantia', label: "Pendente Troca de Garantia" },
    {  value: 'Pedido Entrega Extraviada', label: "Pedido Entrega Extraviada" }
];

export const TableVendas = (props) => {
    return (
        <Card>
            <Collapse isOpen={true}>
                <Card style={{ border: 'none' }}>
                    <CardBody>
                        <Button color='success'><FaFileExcel/> Exportar Excel</Button>
                        <hr/>
                        {props.relatorio ?
                            <div>
                                <Row>
                                    {Object.keys(props.relatorio).map((p, i) => {
                                        return <Col md={3} style={{textAlign: 'center', padding: 5}}>
                                            <h4 style={{margin: 0}}>
                                                {i < 4 ?
                                                    parseFloat(props.relatorio[p]).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                                    :
                                                    props.relatorio[p]
                                                }</h4>
                                            <small>{p}</small>
                                        </Col>
                                    })}
                                </Row>
                                <br/>
                            </div>
                        :
                        null}
                        <Row >
                            <Col md={5}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputText
                                        name="search"
                                        placeholder="Buscar por Nº de Transação"
                                        onChange={debounce((event) => props.isSearch(event), 1000)} />
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={6}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputMasked
                                        name="document"
                                        mask={props.is_cnpj === false ? "999.999.999-99" : "99.999.999/9999-99"}
                                        placeholder={props.is_cnpj === false ? "CPF" : "CNPJ"}
                                        onChange={debounce((event) => props.isSearch(event), 500)}
                                        />
                                </div>
                            </Col>
                            <Col md={2}>
                                <p style={{marginTop: '16px'}}><input 
                                    type="checkbox"
                                    checked={props.is_cnpj}
                                    onChange={() => props.onSearchDocument()}/> Pesquisar CNPJ</p>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={4}>
                                <div style={{ textAlign: 'left' }}>
                                    <Select
                                        options={maquininhas_list}
                                        placeholder="Filtrar por Maquininha/Ecommerce"  
                                        onChange={props.selectModeloMaquininha}  
                                    />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div style={{ textAlign: 'left' }}>
                                    <Select
                                        isSearchable={false}
                                        isClearable
                                        options={maquinas_status}
                                        placeholder="Filtrar por Status"  
                                        onChange={props.selectStatus}  
                                    />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div style={{ textAlign: 'left' }}>
                                    <Select
                                        isSearchable={false}
                                        options={formas_pagamento_list}
                                        isClearable
                                        placeholder="Filtrar por Forma de pagamento"  
                                        onChange={props.selectFormaPagamento}  
                                    />
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={6}>
                                <Select
                                    isSearchable={false}
                                    options={pedido_list}
                                    isClearable
                                    placeholder="Filtrar por Status do pedido"  
                                    onChange={props.selectPedido}  
                                />
                            </Col>
                            <Col md={6}>
                                <Select
                                    isSearchable={false}
                                    options={status_envio_list}
                                    isClearable
                                    placeholder="Filtrar por Status da entrega"  
                                    onChange={props.selectStatusEntrega}  
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <input
                                    type="checkbox"
                                    checked={props.pending}
                                    name="pending"
                                    onChange={(value) => props.onPending(value.target.checked)} /> Filtrar por documentos pendentes
                            </Col>
                        </Row>
                        <Row>
                            <Col>De:
                            <Input
                                    name="date_from"
                                    type="date"
                                    //disabled
                                    disabled={!(props.filter === true)}
                                    value={props.date_from}
                                    onChange={(event) => props.isSearch(event)} /></Col>
                            <Col>Até:
                            <Input
                                    name="date_to"
                                    type="date"
                                    disabled={!(props.filter === true)}
                                    //disabled
                                    value={props.date_to}
                                    onChange={(event) => props.isSearch(event)} /></Col>
                        </Row>
                        <Row>
                            <Col md={5}>
                                <input
                                    type="checkbox"
                                    checked={props.filter}
                                    name="filter"
                                    onChange={(value) => props.onFilter(value.target.checked)} /> Filtrar por data de validade

                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Collapse>
            <hr style={{ marginBottom: '4px' }} />
            <DataTable
                noHeader={true}
                button={true}
                noDataComponent={props.noDataComponent}
                columns={columns(props.bloquear_ativar_cupom)}
                data={props.data}
                theme="default"
            />
            <CardFooter style={{ textAlign: 'center' }}>
                <Pagination
                    onPageChange={props.onPageChange}
                    activePage={props.activePage}
                    totalPage={props.totalPage}
                />

            </CardFooter>
        </Card>
    )
}