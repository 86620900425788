import React, { Component } from "react";
import classnames from 'classnames';
import { FaBox, FaScroll, FaSimCard, FaUser } from "react-icons/fa";
import { MdAttachMoney, MdMail, MdMoneyOff } from "react-icons/md";
import { Button, ButtonGroup, Card, CardBody, CardColumns, CardFooter, Col, Collapse, FormGroup, Input, Label, Nav, NavLink, Row, TabContent, TabPane } from "reactstrap";
import styled from "styled-components";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import DataPreClient from "../../components/ResumeClient/DataPreClient";
import DataClientVenda from "../../components/ResumeClient/DataClientVenda";
import sanitizeHtml from 'sanitize-html';
import { InputText } from "../../components/InputText/InputText";
import axios from "axios";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import EnviarEmailVenda from "../../components/Modals/Vendas/EnviarEmailVenda";
import ecommerce_logo from '../../assets/ecommerce-aqpago.svg';
import bobina from '../../assets/bobina.jpg';
import sim_icon from '../../assets/chip.png';
import Select from "react-select";
import { toast } from "react-toastify";
import ClipLoader from 'react-spinners/ClipLoader';
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { InputMasked } from "../../components/InputText/InputMasked";

const status_transacao_not_svg = (payment_type) => {
    switch(payment_type){
        case 'succeeded' : return 'Aprovada';
        case 'canceled' : return 'Cancelada';
        case 'pending' : return 'Pendente';
        case 'failed' : return 'Falhada';
        case 'disputed': return 'Em disputa';
        default: return payment_type
    }
}

const payment = (payment_type) => {
    switch(payment_type){
        case 'credit' : return 'Crédito';
        case 'boleto' : return 'Boleto';
        case 'debit' : return 'Débito';
        default: return payment_type
    }
}

const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
`;


const getPayout = (id, callback) => {
    var url = ENDPOINT + "remessa/info/" + id;

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((data) => {
        callback(data);
    })
}


const updateMaquininha = (id, formData, callback) => {
    var url = ENDPOINT + "vendas/update_maquininha/" + id;

    console.log(formData);

    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((data) => {
        callback(data.data);
    })
}

const get_id = () => {
    return window.location.pathname.replace('/resumo-postagem/', '')
}

const maquininhas_list = [
    {
        modelo: 'MP5',
        label: 'Maquininha MP5',
        value: 'MP5',
        valor: 199.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/mp5.webp'
    },
    {
        modelo: 'D195',
        label: 'Maquininha D195',
        value: 'D195',
        valor: 399.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/d195.webp'
    },
    {
        modelo: 'S920',
        label: 'Maquininha S920',
        value: 'S920',
        valor: 599.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/s920.webp'
    },
    {
        modelo: 'MP35P',
        label: 'Maquininha MP35P',
        value: 'MP35P',
        valor: 999.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/d195.webp'
    },
    {
        modelo: 'BOBINA',
        label: 'Bobina',
        value: 'BOBINA',
        valor: 0.00,
        image: bobina
    },
    {
        modelo: 'TAG',
        label: 'Tag Aqpasso',
        value: 'TAG',
        valor: 0.00,
        image: 'https://taggy.com.br/images/taggy.svg'
    },
    {
        modelo: 'CHIP',
        label: 'Chip Simcard IoT',
        value: 'CHIP',
        valor: 0.00,
        image: sim_icon
    },
    {
        modelo: 'linkpay',
        label: 'Link de Pagamento',
        value: 'linkpay',
        valor: 99.00,
        image: 'https://aqpago.com.br/assets/img/home/melhor-escolha/link-pagamento/link-pagamento.svg'
    },
    {
        modelo: 'shopaqpago',
        label: 'E-commerce',
        value: 'shopaqpago',
        valor: 99.00,
        image: ecommerce_logo
    }
];
  
const produto_prazo_list = [
    {
        label: '1 ano de garantia',
        value: 'um_ano'
    },
    {
        label: '2 anos de garantia',
        value: 'dois_anos'
    },
    {
        label: '3 anos de garantia',
        value: 'tres_anos'
    },
];


const status_envio_list = [
    {  value: 'Aguardando Entrega', label: "Aguardando Entrega" },
    {  value: 'Pedido Enviado', label: "Pedido Enviado" },
    {  value: 'Pedido Entregue', label: "Pedido Entregue" },
    {  value: 'Pedido Devolvido', label: "Pedido Devolvido" },
    {  value: 'Aguardando Reversa', label: "Aguardando Reversa" },
    {  value: "Reversa Em Trânsito", label: "Reversa Em Trânsito" },
    {  value: 'Reversa Recebida', label: "Reversa Recebida" },
    {  value: 'Prioridade Entrega Ouvidoria', label: "Prioridade Entrega Ouvidoria" },
    {  value: 'Prioridade Entrega Reclamação', label: "Prioridade Entrega Reclamação" },
    {  value: 'Pendente Devolver Máquina', label: "Pendente Devolver Máquina" },
    {  value: 'Pendente Troca de Garantia', label: "Pendente Troca de Garantia" },
    {  value: 'Pedido Entrega Extraviada', label: "Pedido Entrega Extraviada" }
  ];


const transporte_list = [
    {   value: 'aqenvio', label: 'AQEnvio'  },
    {   value: 'correios', label: 'Correios'  },
    {   value: 'totalexpress', label: 'Total Express'  },
];

const pedido_list = [
    { value: "aprovado", label: "Pedido aprovado" },
    { value: "reprovado", label: "Pedido reprovado" },
    { value: "cancelado", label: "Pedido cancelado" },
    { value: "estornado", label: "Pedido estornado" },
    { value: "chargeback", label: "Pedido chargeback" },
    { value: "entrega", label: "Aguardando entrega" },
    { value: "enviado", label: "Pedido enviado" },
    { value: "entregue", label: "Pedido entregue" },
    { value: "devolvido", label: "Pedido devolvido" }
];

const getStringMaps = (mail) => {
    var string_mail = "";
    mail.forEach((p) => {
      string_mail += getTranslate(p.recordType) + " " + moment(new Date(p.dateAt).toString()).format('DD/MM/YYYY h:mm:ss a') + "<br /> "
    });
  
    return string_mail;
}

const getTranslate = (name) => {
    switch (name) {
        case 'Delivery': return 'Entregue'
        case 'Open': return 'Aberto'
        case 'Click': return 'Clicado'
        case 'SpamComplaint': return 'Caixa de SPAM'
        case 'Bounce': return 'Mensagem devolvida'
        default : return name;
    }
}

const produto_prazo_get = (value) => {
    var prazo = '';
    produto_prazo_list.forEach(e => {
        if(e.value == value){
            prazo = e.label;
        }
    });

    return prazo;
}

class PostagemResumo extends Component{
    constructor(props){
        super(props);
        this.state = {
            activeTab: "1",
            motivo_estorno: '',
            visible_email: false,
            venda_id: null,
            status_entrega_label: null,
            motivo_cancelamento: '',
            cancelamento_enable: false,
            is_print: false,
            url_pdf_print: null,
            id: get_id(),
            info_remessa: {},
            user: {},
            produtos: [],
            mails: [],
            historico_status: [],
            postagem_total_express: null,
            forma_pagamento: '',
            cod_rastreio: '',
            servico_transporte: '',
            nfe_status: '',
            nfe_chave: '',
            nfe_serie: '',
            nfe_pdf: '',
            nfe_xml: '',
            motivo_mudanca: '',
            url_endpoint: 'https://api.focusnfe.com.br',
            status_pedido_label: null,
            estornado_por: null,
            prevencao_show: false,
            total_express_loading: false,
            nf_status: false,
            nf_aberta: false,
            loading_payout: false
        }
    }

    componentDidMount() {
        getPayout(this.state.id, this.callbackRows.bind(this));
    }

    callbackRows(data) {
        this.setState({ ...data.data });

        if(data.data.status_pedido !== null){
            var status = null;
            pedido_list.forEach(e => {
                if(e.value == data.data.status_pedido){
                    status = e;
                }
            });

            this.setState({ 
                status_pedido_label: status, 
                total_express_loading: false 
            })

            if(data.data.status_entrega !== null){
                var status_entrega_label = {
                    value: data.data.status_entrega, 
                    label: data.data.status_entrega
                };

                this.setState({
                    status_entrega_label
                })
            }
            
        }
    }

    onCloseEmail = (visible_email = false) => {
        this.setState({ visible_email });

        if(visible_email == false){
            getPayout(this.state.id, this.callbackRows.bind(this));
        }
    }

    listData = [
        { name: <><MdAttachMoney/>Informações da Remessa</>, active: '1' },
        { name: <><FaUser />Usuário </>, active: '2' },
        { name: <><MdMail />E-mails </>, active: '3' }
    ];

    alterarProduto = (index, id) => {
        var formData = new FormData();
        if(this.state.produtos[index].serial == null){
            if(typeof this.state.produtos[index].serial_text !== 'undefined'
                && this.state.produtos[index].serial_text !== ""){
                formData.append('serial', this.state.produtos[index].serial_text)
            }
        }
        
        if(this.state.produtos[index].chip == null){
            if(typeof this.state.produtos[index].chip_text !== 'undefined'
                && this.state.produtos[index].chip_text !== ""){
                formData.append('chip', this.state.produtos[index].chip_text)
            }
        }

        if(this.state.produtos[index].date_fornecedor == null){
            if(typeof this.state.produtos[index].date_fornecedor_text !== 'undefined'
                && moment(this.state.produtos[index].date_fornecedor_text, "DD/MM/YYYY", true).isValid() !== false){
                formData.append('date_fornecedor', this.state.produtos[index].date_fornecedor_text)
            }
        }

        
        
        if(this.state.produtos[index].prazo_garantia == null){
            if(typeof this.state.produtos[index].prazo_garantia_text !== 'undefined'
                && this.state.produtos[index].prazo_garantia_text !== ""){
                formData.append('prazo_garantia', this.state.produtos[index].prazo_garantia_text)
            }
        }

        
        
        if(this.state.produtos[index].fornecedor == null){
            if(typeof this.state.produtos[index].fornecedor_text !== 'undefined'
                && this.state.produtos[index].fornecedor_text !== ""){
                formData.append('fornecedor', this.state.produtos[index].fornecedor_text)
            }
        }
        
        if(this.state.produtos[index].nf_fornecedor == null){
            if(typeof this.state.produtos[index].nf_fornecedor_text !== 'undefined'
                && this.state.produtos[index].nf_fornecedor_text !== ""){
                formData.append('nf_fornecedor', this.state.produtos[index].nf_fornecedor_text)
            }
        }
        

        
        if(this.state.produtos[index].prazo_garantia_fornecedor == null){
            if(typeof this.state.produtos[index].prazo_garantia_fornecedor_text !== 'undefined'
                && this.state.produtos[index].prazo_garantia_fornecedor_text !== ""){
                formData.append('prazo_garantia_fornecedor', this.state.produtos[index].prazo_garantia_fornecedor_text)
            }
        }

        if(this.state.produtos[index].nf == null){
            if(typeof this.state.produtos[index].nf_text !== 'undefined'
                && this.state.produtos[index].nf_text !== ""){
                formData.append('nf', this.state.produtos[index].nf_text)
            }
        }


        if(this.state.produtos[index].simcard == null){
            if(typeof this.state.produtos[index].simcard_text !== 'undefined'
                && this.state.produtos[index].simcard_text !== ""){
                formData.append('simcard', this.state.produtos[index].simcard_text)
            }
        }

        if(this.state.produtos[index].label != this.state.produtos[index].vendas_edit_text){
            if(typeof this.state.produtos[index].vendas_edit_text !== 'undefined'
                && this.state.produtos[index].vendas_edit_text !== ""){
                formData.append('modelo', this.state.produtos[index].vendas_edit_text);
                formData.append('motivo_mudanca', this.state.motivo_mudanca);
            }
        }


        

        if(this.state.produtos[index].operador == null){
            if(typeof this.state.produtos[index].operador_text !== 'undefined'
                && this.state.produtos[index].operador_text !== ""){
                formData.append('operador', this.state.produtos[index].operador_text)
            }
        }

        const callbackIs = (data) => {
            var produtos = this.state.produtos;
            produtos[index] = data.data;
            this.setState({ 
                produtos, 
                motivo_mudanca: ''
            })

            getPayout(this.state.id, this.callbackRows.bind(this));
        }

        updateMaquininha(id, formData, callbackIs);
    }


    alterStatus = (status) => {
        this.setState({ status_entrega_label: status });

        var url = ENDPOINT+"remessa/update_status_entrega/"+this.state.id;
    
        var formData = new FormData();
        formData.append('status_pedido', status.value);

        if(status.value2 != null){
            formData.append('status_pedido_venda', status.value2);
        }

        axios.post(url, formData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            getPayout(this.state.id, this.callbackRows.bind(this));
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                toast.error(obj.errors);
            }
  
            var data = error.response.data;
            if(typeof data.errors !== 'undefined'){
              toast.error(data.errors);
            }
        });
    
    }

    handleChangeEmail = (mensagem_email) => {
        this.setState({mensagem_email});
    }

    onToast = (data) => {
        if(data.success === true){
            toast.success(data.message);
        }
        else{
            toast.error(data.errors);
        }
        //this.props.refresh();
    }

    totalExpressRegister = (codigo_pedido) => {
        var url = ENDPOINT + "remessa/total-express-register";
        var formData = new FormData();
        formData.append('codigo_pedido', codigo_pedido);

        axios.post(url, formData, {
          headers: {
              Authorization: 'Bearer '+getToken()
          }}).then((response) => {
            var data = response.data;
            this.onToast(data);
            getPayout(this.state.id, this.callbackRows.bind(this));
            return data;
        })
        .catch((error) => {
            this.setState({total_express_loading: false})
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onToast(obj);
                return obj;
            }
            var data = error.response.data;
            this.onToast(data);
            return data;
        });
    }

    printPostagem(codigo) {
        toast.success("O relatório será baixado em breve");
        if(this.state.servico_transporte == 'correios'){
            var url = ENDPOINT + "remessa/correios-print?codigo_pedido="+codigo;
        }
        else{
            var url = ENDPOINT + "remessa/total-express-print?codigo_pedido="+codigo;
        }

        axios({
            url: url, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        }).then((response) => {
            const url = URL.createObjectURL(response.data);
            this.setState({
                url_pdf_print: url,
                is_print: true
            })
            /* const myWindow = window.open(url)
            myWindow.print() */
        })
        .catch(e => {
            console.log(e)
        })
    }
    
    printPostagemDeclaracao(codigo) {
        toast.success("A declaração será baixada em breve");
        var url = ENDPOINT + "remessa/declaracao-correios-print?codigo_pedido="+codigo;

        axios({
            url: url, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        }).then((response) => {
                const url = URL.createObjectURL(response.data);
                const myWindow = window.open(url)
                myWindow.print();
        })
    }
    
    gerarNotaFiscal = () => {
        this.setState({generate_loading: true})
        var url = ENDPOINT + "remessa/gerar_nota_fiscal";

        var formData = new FormData();
        formData.append('cod_rastreio', this.state.cod_rastreio);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.setState({
                nf_status: true,
                generate_loading: false
            })
            getPayout(this.state.id, this.callbackRows.bind(this));
        })
        .catch(error => {
            this.setState({
                generate_loading: false
            })
            var data = error.response.data;
            toast.error(data.errors);
        })

    }

    enviarNotaFiscal = () => {
        var url = ENDPOINT + "remessa/enviar_nota_fiscal";

        var formData = new FormData();
        formData.append('codigo_pedido', this.state.cod_rastreio);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
        })
        .catch(error => {
            var data = error.response.data;
            toast.error(data.errors);
        })

    }
    solicitarCancelamento = () => {
        this.setState({cancelar_loading: true})
        var url = ENDPOINT + "remessa/solicitar_cancelamento";

        var formData = new FormData();
        formData.append('codigo_pedido', this.state.cod_rastreio);
        formData.append('motivo_cancelamento', this.state.motivo_cancelamento);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.setState({
                cancelar_loading: false,
                nf_status: false,
                nf_aberta: false
            })
            getPayout(this.state.id, this.callbackRows.bind(this));
        })
        .catch(error => {
            var data = error.response.data;
            toast.error(data.errors);
        })

    }
    getNotaFical = () => {
        this.setState({
            generate_loading: true,
        })
        var url = ENDPOINT + "remessa/get_nota_fiscal/"+this.state.cod_rastreio;

        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.setState({
                generate_loading: false,
                nf_aberta: true,
                nfe_numero: data.data.numero,
                nfe_chave: data.data.chave_nfe,
                nfe_serie: data.data.serie,
                nfe_status: data.data.status,
                nfe_xml: data.data.caminho_xml_nota_fiscal,
                nfe_pdf: data.data.caminho_danfe
            })
        })
        .catch(error => {
            var data = error.response.data;
            toast.error(data.errors);
            this.setState({
                generate_loading: false,
                nf_aberta: false,
                nfe_numero: '',
                nfe_chave: '',
                nfe_serie: '',
                nfe_status: '',
                nfe_xml: '',
                nfe_pdf: ''
            })
        })

    }
    render(){
        const Navs = () => {
            return <Nav tabs>
                {this.listData.map((p, index) => {
                    return <NavLink
                        key={index}
                        className={
                            classnames({ active: this.state.activeTab === p.active })}
                        onClick={() => { this.setState({ activeTab: p.active }); }}>
                        <span>{p.name}</span>
                    </NavLink>
                })}
            </Nav>
        }
        return(<Div>
            <h3>Resumo de Venda</h3>
            <Navs />
            <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId={'1'}>
                    <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                        <h5>Dados do Pagamento</h5>
                        <hr/>
                        <p><b>Código de rastreio:</b> {this.state.cod_rastreio}</p>
                        <p><b>Serviço de transporte:</b> {transporte_list.map(e => {
                            if(e.value == this.state.servico_transporte){
                                return e.label;
                            }
                        })}
                        </p>
                        <p><b>Historico:</b>
                            <ul>
                                {this.state.historico_status.map(p => {
                                    return <li>{p.status} - {p.operador !== null ? 'relizado por: '+p.operador.name : ''} às {moment(p.created_at).format('DD/MM/YYYY HH:mm')}</li>
                                })}
                            </ul>
                        </p>
                        <br/>
                        {this.state.nf_status == false ?
                            <Button 
                                color="success"
                                disabled={this.state.generate_loading}
                                onClick={() => this.gerarNotaFiscal()}
                            >Gerar NFe</Button>
                        :
                        <>
                            {this.state.nf_aberta == true ?
                            <>
                                <p>Chave: {this.state.nfe_chave}</p>
                                <p>Serie: {this.state.nfe_serie}</p>
                                <p>Numero: {this.state.nfe_numero}</p>
                                <p>Status: {this.state.nfe_status}</p>
                                {this.state.servico_transporte == 'totalexpress' && this.state.postagem_total_express == null ? 
                                    <Button 
                                        outline 
                                        style={{margin: '5px'}} 
                                        disabled={this.state.total_express_loading}
                                        onClick={() => this.totalExpressRegister(this.state.cod_rastreio)} 
                                        color="danger">
                                        Integrar com a TOTALEXPRESS
                                    </Button>
                                    :
                                    <></>
                                }
                                <ButtonGroup style={{marginBottom: '15px'}}>
                                    <Button 
                                        color="success"
                                        onClick={() => {
                                            window.open(this.state.url_endpoint+''+this.state.nfe_xml, '_blank')
                                        }}
                                    >Abrir XML</Button>
                                    <Button 
                                        color="danger"
                                        onClick={() => {
                                            window.open(this.state.url_endpoint+''+this.state.nfe_pdf, '_blank')
                                        }}
                                    >Abrir PDF</Button>
                                </ButtonGroup>
                                <br/>
                                <Button 
                                    color="danger"
                                    style={{marginBottom: '15px'}}
                                    onClick={() => this.enviarNotaFiscal()}
                                >Enviar Nota Fiscal</Button>
                            </>
                            :
                            <>
                                <Button 
                                    color="success"
                                    disabled={this.state.generate_loading}
                                    onClick={() => this.getNotaFical()}>Visualizar NFe</Button>
                                &nbsp;<Button 
                                    color="danger"
                                    onClick={() => this.setState({ cancelamento_enable: !this.state.cancelamento_enable })}>Cancelar Nota</Button>
                                <br/>
                                <div hidden={!this.state.cancelamento_enable}>
                                    <hr/>
                                    <Input 
                                        name="motivo_cancelamento"
                                        type="textarea"
                                        placeholder="Motivo do Cancelamento"
                                        value={this.state.motivo_cancelamento}
                                        onChange={(e) => {
                                            var name = e.target.name;
                                            var value = e.target.value;
                                            this.setState({ [name]: value });
                                        }}
                                    />
                                    <br/>
                                    <Button 
                                        color="warning" 
                                        disabled={this.state.cancelar_loading}
                                        onClick={() => this.solicitarCancelamento()}>
                                        Confirmar Cancelamento
                                    </Button>
                                </div>
                            </>}
                        </>
                        }
                        <br/>
                        <p style={{margin: 0}}><b>Status da Entrega:</b>
                            <Select
                                value={this.state.status_entrega_label}
                                placeholder="Status da entrega"
                                options={status_envio_list}
                                onChange={this.alterStatus}
                            />
                        </p>
                        {this.state.servico_transporte == 'totalexpress' ? 
                            (
                                this.state.postagem_total_express != null ?
                                    <>
                                        <Button outline style={{margin: '5px'}} onClick={() => this.printPostagem(this.state.cod_rastreio)} color="success">Imprimir Etiqueta</Button>
                                        <br/>
                                    </>
                                :<></>
                            )
                            :
                            this.state.servico_transporte == 'correios' ? 
                                <>
                                    <Button outline style={{margin: '5px'}} onClick={() => this.printPostagem(this.state.cod_rastreio)} color="success">Imprimir Etiqueta Correios</Button>
                                    <Button outline style={{margin: '5px'}} onClick={() => this.printPostagemDeclaracao(this.state.cod_rastreio)} color="success">Imprimir Declaração Correios</Button>
                                </>
                            :
                            <></>
                        }
                        <Collapse isOpen={this.state.is_print}>
                            <iframe 
                                width="100%"
                                height="470px"
                                src={this.state.url_pdf_print}/>
                        </Collapse>
                        <p><b>Produtos Adquiridos:</b></p>
                        <hr/>
                        <CardColumns>
                            {this.state.produtos.map((e, index) => {
                                return <>
                                    {maquininhas_list.map(m => {
                                        if(e.produto.toUpperCase() == m.modelo.toUpperCase()){
                                            if(e.produto == 'TAG'){
                                                return <Card>
                                                    <CardBody>
                                                        <Row>
                                                            <Col>
                                                                <img src={m.image} height="100px"/>
                                                            </Col>
                                                            <Col>
                                                                <p>{m.label}</p>
                                                                <p>R$ {parseFloat(m.valor).toFixed(2).replace('.', ',')}</p>
                                                            </Col>
                                                        </Row>
                                                        <div>
                                                            <hr/>
                                                            {e.nf == null ?
                                                                <FormGroup>
                                                                    <Label>Nota Fiscal (NF):</Label>
                                                                    <Input 
                                                                        placeholder="NF" 
                                                                        name="nf_text"
                                                                        onChange={(e) => {
                                                                            var name = e.target.name;
                                                                            var value = e.target.value;
                                                                            var produtos = this.state.produtos;
                                                                            produtos[index][name] = value;
                                                                            this.setState({ produtos })
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            :
                                                            <p><b>NF:</b> {e.nf}</p>}
                                                            {e.serial == null ?
                                                                <FormGroup>
                                                                    <Label>Serial da Tag:</Label>
                                                                    <Input 
                                                                        placeholder="NF" 
                                                                        name="serial_text"
                                                                        onChange={(e) => {
                                                                            var name = e.target.name;
                                                                            var value = e.target.value;
                                                                            var produtos = this.state.produtos;
                                                                            produtos[index][name] = value;
                                                                            this.setState({ produtos })
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            :
                                                            <p><b>Serial:</b> {e.serial}</p>}
                                                        </div>
                                                    </CardBody>
                                                    {e.nf == null ?
                                                        <CardFooter>
                                                            <Button color="success" onClick={() => this.alterarProduto(index, e.id)}>Salvar</Button>
                                                        </CardFooter>
                                                        :
                                                        null
                                                    }
                                                </Card>
                                            }
                                            else if(e.produto.toUpperCase() != 'LINKPAY' && e.produto.toUpperCase() != 'SHOPAQPAGO'){
                                                return <Card>
                                                    <CardBody>
                                                        <div>
                                                            <center>
                                                                <img src={m.image} height="100px"/>
                                                            </center>
                                                        </div>
                                                        <br/>
                                                        <p style={{textAlign: 'center'}}><b>{m.label} </b> - &nbsp;
                                                            <Button
                                                                style={{
                                                                    padding: 3,
                                                                    fontSize: 12
                                                                }} 
                                                                color="success" 
                                                                onClick={() => { 
                                                                var produtos = this.state.produtos;
                                                                produtos[index]['venda_edit'] = true;
                                                                this.setState({ produtos })
                                                            }}>Editar</Button>
                                                        </p>
                                                        <FormGroup hidden={this.state.produtos[index]['venda_edit'] != true}>
                                                            <FormGroup>
                                                                <Label>Nova Maquininha:</Label>
                                                                <Input 
                                                                    type="select" 
                                                                    placeholder="Prazo"
                                                                    name="vendas_edit_text"
                                                                    onChange={(e) => {
                                                                        var name = e.target.name;
                                                                        var value = e.target.value;
                                                                        var produtos = this.state.produtos;
                                                                        produtos[index][name] = value;
                                                                        this.setState({ produtos })
                                                                    }}
                                                                >
                                                                    <option>Selecione a maquininha</option>
                                                                    <option value="MP5">MP5</option>
                                                                    <option value="D195">D195</option>
                                                                    <option value="S920">S920</option>
                                                                </Input>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label>Motivo da Mudança:</Label>
                                                                <Input 
                                                                    type="textarea" 
                                                                    placeholder="Motivo da mudança"
                                                                    name="motivo_mudanca"
                                                                    value={this.state.motivo_mudanca}
                                                                    onChange={(e) => {
                                                                        var name = e.target.name;
                                                                        var value = e.target.value;
                                                                        this.setState({ [name]: value })
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </FormGroup>
                                                        <div>
                                                            <hr/>
                                                            {e.valor_produto !== null ? <p>R$ {parseFloat(e.valor_produto).toFixed(2).replace('.', ',')}</p> : <></>}
                                                            {e.serial == null ?
                                                                <FormGroup>
                                                                    <Label>Número do Serial:</Label>
                                                                    <Input 
                                                                        placeholder="Nº de Serial" 
                                                                        name="serial_text"
                                                                        onChange={(e) => {
                                                                            var name = e.target.name;
                                                                            var value = e.target.value;
                                                                            var produtos = this.state.produtos;
                                                                            produtos[index][name] = value;
                                                                            this.setState({ produtos })
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            :
                                                            <p><b>Nº de Serial:</b> {e.serial}&nbsp;
                                                            <Button 
                                                                color="success" 
                                                                style={{padding: '3px'}}
                                                                onClick={() => { 
                                                                    var produtos = this.state.produtos;
                                                                    produtos[index]['serial'] = null;
                                                                    this.setState({ produtos })
                                                                }}>Editar</Button></p>}
                                                            {e.nf == null ?
                                                                <FormGroup>
                                                                    <Label>Nota Fiscal (NF):</Label>
                                                                    <Input 
                                                                        placeholder="NF" 
                                                                        name="nf_text"
                                                                        onChange={(e) => {
                                                                            var name = e.target.name;
                                                                            var value = e.target.value;
                                                                            var produtos = this.state.produtos;
                                                                            produtos[index][name] = value;
                                                                            this.setState({ produtos })
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            :
                                                            <p><b>NF emitida:</b> {e.nf}&nbsp;
                                                            <Button 
                                                                color="success" 
                                                                style={{padding: '3px'}}
                                                                onClick={() => { 
                                                                    var produtos = this.state.produtos;
                                                                    produtos[index]['nf'] = null;
                                                                    this.setState({ produtos })
                                                                }}>Editar</Button></p>}
                                                            <p><b>Data da Compra:</b> {moment(e.created_at).format('DD/MM/YYYY HH:mm')}</p>
                                                            {e.prazo_garantia == null ?
                                                                <FormGroup>
                                                                    <Label>Garantia do Terminal:</Label>
                                                                    <Input 
                                                                        type="select" 
                                                                        placeholder="Prazo"
                                                                        name="prazo_garantia_text"
                                                                        onChange={(e) => {
                                                                            var name = e.target.name;
                                                                            var value = e.target.value;
                                                                            var produtos = this.state.produtos;
                                                                            produtos[index][name] = value;
                                                                            this.setState({ produtos })
                                                                        }}
                                                                    >
                                                                        <option>Garantia do Terminal</option>
                                                                        <option value="tres_meses">3 Meses</option>
                                                                        <option value="seis_meses">6 Meses</option>
                                                                        <option value="doze_meses">12 Meses</option>
                                                                        <option value="tres_anos">Sem Garantia</option>
                                                                    </Input>
                                                                </FormGroup>
                                                            :
                                                            <p><b>Garantia do Terminal:</b> {produto_prazo_get(e.prazo_garantia)}</p>}
                                                            {e.chip == null ?
                                                                <FormGroup>
                                                                    <Label>Número do Chip:</Label>
                                                                    <Input 
                                                                        placeholder="Nº do Chip" 
                                                                        name="chip_text"
                                                                        onChange={(e) => {
                                                                            var name = e.target.name;
                                                                            var value = e.target.value;
                                                                            var produtos = this.state.produtos;
                                                                            produtos[index][name] = value;
                                                                            this.setState({ produtos })
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            :
                                                            <p><b>Nº de Chip:</b> {e.chip}&nbsp;
                                                            <Button 
                                                                color="success" 
                                                                style={{padding: '3px'}}
                                                                onClick={() => { 
                                                                    var produtos = this.state.produtos;
                                                                    produtos[index]['chip'] = null;
                                                                    this.setState({ produtos })
                                                                }}>Editar</Button></p>}
                                                            {e.date_fornecedor == null ? 
                                                                <FormGroup>
                                                                    <Label>Data de Compra Fornecedor:</Label>
                                                                    <InputMasked
                                                                        mask="99/99/9999" 
                                                                        placeholder="Data de Compra Fornecedor" 
                                                                        name="date_fornecedor_text"
                                                                        onChange={(e) => {
                                                                            var name = e.target.name;
                                                                            var value = e.target.value;
                                                                            var produtos = this.state.produtos;
                                                                            produtos[index][name] = value;
                                                                            this.setState({ produtos })
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            :
                                                            <p><b>Data de Compra Fornecedor:</b> {e.date_fornecedor}</p>}
                                                            {e.simcard == null ?
                                                                <FormGroup>
                                                                    <Label>Fornecedor SIMCARD:</Label>
                                                                    <Input 
                                                                        type="select" 
                                                                        placeholder="Sim Card"
                                                                        name="simcard_text"
                                                                        onChange={(e) => {
                                                                            var name = e.target.name;
                                                                            var value = e.target.value;
                                                                            var produtos = this.state.produtos;
                                                                            produtos[index][name] = value;
                                                                            this.setState({ produtos })
                                                                        }}
                                                                    >
                                                                        <option>Fornecedor SIMCARD</option>
                                                                        <option value="Allcom">Allcom</option>
                                                                        <option value="Avatek">Avatek</option>
                                                                        <option value="TefTi">TefTi</option>
                                                                        <option value="Algar">Algar</option>
                                                                    </Input>
                                                                </FormGroup>
                                                            :
                                                            <p><b>Fornecedor SIMCARD:</b> {e.simcard}</p>}
                                                            {e.prazo_garantia_fornecedor == null ?
                                                                <FormGroup>
                                                                    <Label>Garantia do Terminal Fornecedor:</Label>
                                                                    <Input 
                                                                        type="select" 
                                                                        placeholder="Garantia do Terminal Fornecedor"
                                                                        name="prazo_garantia_fornecedor_text"
                                                                        onChange={(e) => {
                                                                            var name = e.target.name;
                                                                            var value = e.target.value;
                                                                            var produtos = this.state.produtos;
                                                                            produtos[index][name] = value;
                                                                            this.setState({ produtos })
                                                                        }}
                                                                    >
                                                                        <option>Garantia do Terminal Fornecedor</option>
                                                                        <option value="tres_meses">3 Meses</option>
                                                                        <option value="seis_meses">6 Meses</option>
                                                                        <option value="doze_meses">12 Meses</option>
                                                                        <option value="tres_anos">Sem Garantia</option>
                                                                    </Input>
                                                                </FormGroup>
                                                            :
                                                            <p><b>Garantia do Terminal Fornecedor:</b> {produto_prazo_get(e.prazo_garantia_fornecedor)}</p>}
                                                            {e.operador == null ?
                                                                <FormGroup>
                                                                    <Label>Operador loT SIMCARD:</Label>
                                                                    <Input 
                                                                        type="select" 
                                                                        placeholder="Sim Card"
                                                                        name="operador_text"
                                                                        onChange={(e) => {
                                                                            var name = e.target.name;
                                                                            var value = e.target.value;
                                                                            var produtos = this.state.produtos;
                                                                            produtos[index][name] = value;
                                                                            this.setState({ produtos })
                                                                        }}
                                                                    >
                                                                        <option>Operador SIMCARD</option>
                                                                        <option value="Vivo">Vivo</option>
                                                                        <option value="Claro">Claro</option>
                                                                        <option value="Tim">Tim</option>
                                                                        <option value="Algar">Algar</option>
                                                                        <option value="Eseye">Eseye</option>
                                                                    </Input>
                                                                </FormGroup>
                                                            :
                                                            <p><b>Operador loT SIMCARD:</b> {e.operador}</p>}
                                                            {e.fornecedor == null ?
                                                                <FormGroup>
                                                                <Label>Fornecedor de compra:</Label>
                                                                <Input 
                                                                    type="select" 
                                                                    placeholder="Fornecedor"
                                                                    name="fornecedor_text"
                                                                    onChange={(e) => {
                                                                        var name = e.target.name;
                                                                        var value = e.target.value;
                                                                        var produtos = this.state.produtos;
                                                                        produtos[index][name] = value;
                                                                        this.setState({ produtos })
                                                                    }}
                                                                >
                                                                    <option>Prazo de Fornecedor</option>
                                                                    <option value="TEFTI">TEFTI</option>
                                                                    <option value="Allcom">Allcom</option>
                                                                    <option value="Gertec">Gertec</option>
                                                                    <option value="Scansource">Scansource</option>
                                                                    <option value="PS4L">PS4L</option>
                                                                    <option value="Outros">Outros</option>
                                                                </Input>
                                                            </FormGroup>
                                                            :
                                                            <p><b>Fornecedor de compra:</b> {e.fornecedor}</p>}
                                                            {e.nf_fornecedor == null ?
                                                                <FormGroup>
                                                                    <Label>Nota Fiscal do Fornecedor:</Label>
                                                                    <Input 
                                                                        placeholder="NF" 
                                                                        name="nf_fornecedor_text"
                                                                        onChange={(e) => {
                                                                            var name = e.target.name;
                                                                            var value = e.target.value;
                                                                            var produtos = this.state.produtos;
                                                                            produtos[index][name] = value;
                                                                            this.setState({ produtos })
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            :
                                                            <p><b>NF do Fornecedor:</b> {e.nf_fornecedor}</p>}
                                                        </div>
                                                    </CardBody>
                                                    {e.nf == null || e.fornecedor == null || e.prazo_garantia == null || e.chip == null || e.serial == null || e.operador == null || e.simcard == null || e.venda_edit ?
                                                        <CardFooter>
                                                            <Button color="success" onClick={() => this.alterarProduto(index, e.id)}>Salvar</Button>
                                                        </CardFooter>
                                                        :
                                                        null
                                                    }
                                                </Card>;
                                            }
                                            else{
                                                return <Card>
                                                    <CardBody>
                                                        <Row>
                                                            <Col>
                                                                <img src={m.image} height="100px"/>
                                                            </Col>
                                                            <Col>
                                                                <p>{m.label}</p>
                                                                <p>R$ {parseFloat(m.valor).toFixed(2).replace('.', ',')}</p>
                                                            </Col>
                                                        </Row>
                                                        <div>
                                                            <hr/>
                                                            {e.nf == null ?
                                                                <FormGroup>
                                                                    <Label>Nota Fiscal (NF):</Label>
                                                                    <Input 
                                                                        placeholder="NF" 
                                                                        name="nf_text"
                                                                        onChange={(e) => {
                                                                            var name = e.target.name;
                                                                            var value = e.target.value;
                                                                            var produtos = this.state.produtos;
                                                                            produtos[index][name] = value;
                                                                            this.setState({ produtos })
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            :
                                                            <p><b>NF:</b> {e.nf}</p>}
                                                        </div>
                                                    </CardBody>
                                                    {e.nf == null ?
                                                        <CardFooter>
                                                            <Button color="success" onClick={() => this.alterarProduto(index, e.id)}>Salvar</Button>
                                                        </CardFooter>
                                                        :
                                                        null
                                                    }
                                                </Card>;
                                            }
                                        }
                                    })}
                                </>
                            })}
                        </CardColumns>
                    </div>
                </TabPane>
                <TabPane tabId={'2'}>
                    <DataClientVenda
                        {...this.state.user}
                    />
                </TabPane>
                <TabPane tabId={'3'}>
                    <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                        <EnviarEmailVenda
                            id={this.state.venda_id}
                            visible={this.state.visible_email}
                            onClose={this.onCloseEmail}
                        />
                        <h5>E-mails</h5>
                        <hr/>
                        <Button color="success" onClick={() => this.onCloseEmail(true)}>Enviar E-mail</Button>
                        <br/><br/>
                        <ul style={{listStyleType: 'circle'}}>
                        {this.state.mails.map(p => {
                            return <li style={{marginBottom: '7px'}}>
                                {p.subject}<br/>
                                <small>Conteúdo de E-mail:</small>
                                <div style={{border: '1px solid #7f7f7f66', borderRadius: '15px', padding: '19px'}} dangerouslySetInnerHTML={{ __html: sanitizeHtml(p.message)}}/>
                                <small>{p.responsavel !== null ? <><b>Operador:</b> {p.responsavel.name}</> : ''}</small>
                                <small>{p.mail.length > 0 ?
                                    <div data-tip={getStringMaps(p.mail)} style={{ maxHeight: '200px', 
                                    cursor: 'pointer', overflow: 'auto', width: '100%', margin: '12px' }}>
                                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                                        {p.mail.slice(0, 1).map((p) => {
                                            return <li>
                                            <p><MdMail /> <b>{getTranslate(p.recordType)}</b>
                                                <br />
                                                {moment(new Date(p.dateAt).toString()).format('DD/MM/YYYY h:mm:ss a')}</p>
                                            </li>
                                        })}
                                        </ul>
                                        <ReactTooltip multiline={true} />
                                    </div>
                                    :
                                    <div data-tip={"Postado " + moment(new Date(p.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')} style={{ cursor: 'pointer', maxHeight: '200px', overflow: 'auto', width: '100%', margin: '12px' }}>
                                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                                        <li style={{ listStyleType: 'none' }}><MdMail /> <b>Postado</b><br />{moment(new Date(p.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')}
                                        </li>
                                        </ul>
                                        <ReactTooltip multiline={true} />
                                    </div>
                                }</small>
                            </li>
                        })}
                    </ul>
                    {this.state.mails.length === 0 ? 'Nenhum email encontrado' : ''}
                    </div>
                </TabPane>
            </TabContent>
        </Div>)
    }
}

export default PostagemResumo;