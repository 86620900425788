import axios from 'axios';
import moment from 'moment';
import { HiViewGrid } from 'react-icons/hi';
import ReactTooltip from 'react-tooltip';
import { Button, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';



const getStatus = (status) => {
  if(status == 0){
      return "Pronto para Uso"
  }
  else if(status == 1){
      return "Já Útilizado"
  }
  else if(status == 2){
      return "Bloqueado"
  }
  else{
      return ""
  }
}


export const columns = () => [
    {
        name: '',
        width: '30px',
        cell: row => <>
            <Row>
                <Button data-tip="Resumo do Cliente"
                    outline
                    style={{ padding: '0px', margin: '5px', border: 0}}
                    color="primary" 
                    onClick={() => 
                        {
                            window.location.href = '/gestor-licencas/'+row.id
                        }
                    }
                    >
                    <HiViewGrid style={{ padding: '2px', width: '23px' }} />
                </Button>
                <ReactTooltip/>
            </Row>
        </>
    },
    {
        name: 'Empresa',
        selector: 'empresa',
        sortable: false,
        width: '130px',
    },
    {
        name: 'Abertura',
        selector: 'data_abertura',
        sortable: false,
        width: '170px',
        cell: p => moment(p.data_abertura).format('DD/MM/YYYY HH:mm')
    },
    {
        name: 'Status',
        selector: 'status_solicitacao',
        sortable: false,
        width: '170px',
        cell: p => p.status_solicitacao
    },
    {
      name: "Autorizador",
      selector: "autorizador_name",
      sortable: false,
      width: '170px',
    },
    {
      name: "Solicitante",
      selector: "solicitante_name",
      sortable: false,
      width: '170px',
    },
    {
      name: "Motivo",
      selector: "motivo",
      sortable: false
    },
    {
      name: "Protocolo",
      selector: "protocolo",
      sortable: false
    }
];

export const getRows = (state, callbackRows) => {
    
    var url = ENDPOINT + "autorizacao/list";

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    
    var url = ENDPOINT + "autorizacao/list?search="+search+"&page="+page;
    
    if(filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}