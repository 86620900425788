import { Component } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import axios from "axios";
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import moment from "moment";

class PayoutModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            tipo_pagamento: 'prevencao',
            pagamento_liberado: null,
            pagamento_liberado_invalid: false,
            responsavel_liberacao: null,
            responsavel_liberacao_invalid: false,
            responsavel_conferencia: null,
            responsavel_conferencia_invalid: false,
            razao_bloqueio: '',
            razao_bloqueio_invalid: false,
            data_hora_docs: null,
            data_hora_docs_invalid: false,
            data_pagamento_conta: null,
            data_pagamento_conta_invalid: false,

            estorno_date: null,
            estorno_date_invalid: false,
            estorno_resposta: '',
            estorno_resposta_invalid: false,
            estorno_arquivo: null,
            estorno_arquivo_invalid: false,

            loading: false,
            error_message: '',
            success_message: '',
            payload: {}
        }
    }
    onRegister = () => {
        //if(this.validFields() === true){
            this.store();
        //}
    }
    onError () {
        this.setState({
            error_message: '',
            loading: false
        })
    }
    onClose() {
        this.setState({
            tipo_pagamento: 'prevencao',
            pagamento_liberado: null,
            pagamento_liberado_invalid: false,
            responsavel_liberacao: null,
            responsavel_liberacao_invalid: false,
            responsavel_conferencia: null,
            responsavel_conferencia_invalid: false,
            razao_bloqueio: '',
            razao_bloqueio_invalid: false,
            data_hora_docs: null,
            data_hora_docs_invalid: false,
            data_pagamento_conta: null,
            data_pagamento_conta_invalid: false,

            estorno_date: null,
            estorno_date_invalid: false,
            estorno_resposta: '',
            estorno_resposta_invalid: false,
            estorno_arquivo: null,
            estorno_arquivo_invalid: false,

            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose(false);
    }
    onData(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            });
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    componentWillUpdate(nextProps, nextState){
        if(nextProps.payload !== this.props.payload){
            this.setState({payload: nextProps.payload})
        }
    }

    store = () => {
        this.setState({loading: true})
        var url = ENDPOINT+"payout/store";

        var formData = new FormData();
        formData.append('nome', this.getSellerName());
        formData.append('email', this.getSellerEmail());
        formData.append('documento', this.getSellerDocument());
        //formData.append('valor', this.props.valor);
        formData.append('valor_bloqueado', this.getPayload().amount);
        formData.append('id_transacao', this.getPayload().id);
        formData.append('data_venda', moment(this.getPayload().created_at).format('YYYY-MM-DD HH:mm'));
        formData.append('data_liquidacao', this.getPayload().expected_on !== null && this.getPayload().expected_on !== "None+00:00" ? moment(this.getPayload().expected_on).format('YYYY-MM-DD') : moment(new Date().toISOString()).format('YYYY-MM-DD'));
        formData.append('cliente_notificado_document', this.getSellerDocument());
        formData.append('tipo_bloqueio', this.state.tipo_pagamento);
        formData.append('payload', JSON.stringify(this.state.payload))

        /* formData.append('razao_bloqueio', this.state.razao_bloqueio);
        formData.append('responsavel_conferencia_id', this.state.responsavel_conferencia.value);
        formData.append('responsavel_liberacao_id', this.state.responsavel_liberacao.value);
        formData.append('pagamento_liberado', this.state.pagamento_liberado.value);
        formData.append('data_hora_documentos', this.state.data_hora_docs);
        formData.append('data_hora_pagamento_conta', this.state.data_pagamento_conta);
        formData.append('pedido_solicitacao_estorno', this.stat;e.estorno_arquivo);
        formData.append('resposta_solicitacao_estorno', this.state.estorno_resposta);
        formData.append('data_estorno', this.state.estorno_date) */

        if(this.props.novoBloqueio === true){
            formData.append('novo_bloqueio', true);
        }

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.onData(data);
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            this.onData(data);
            return data;
        });

    }
    loadAtendentesOptions = (inputValue, callback) => {
        var url = ENDPOINT + "admin/list?search=" + inputValue;
        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p.data;

            data.forEach((p, i) => {
                data[i].value = p.id;
                data[i].label = p.name;
            });

            callback(this.filterAtendentes(inputValue, p.data));
        });
    }

    filterAtendentes = (inputValue, data) => {
        return data.filter(i =>
            i.name.toLowerCase().includes(inputValue.toLowerCase())
        );
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({ [name]: value });
    }
    getSellerName = () => {
        if(this.state.payload.seller_payload){
            return this.state.payload.seller_payload.first_name+' '+this.state.payload.seller_payload.last_name;
        }
        else if(this.state.payload.individual){
            return this.state.payload.individual.first_name+' '+this.state.payload.individual.last_name
        }
        else if(this.state.payload.business){
            return this.state.payload.business.business_name;
        }
        else{
            return '';
        }
    }
    getSellerEmail = () => {
        if(this.state.payload.seller_payload){
            return this.state.payload.seller_payload.email;
        }
        else if(this.state.payload.individual){
            return this.state.payload.individual.email;
        }
        else if(this.state.payload.business){
            return this.state.payload.business.business_email;
        }
        else{
            return '';
        }
    }
    getSellerTelefone = () => {
        if(this.state.payload.seller_payload){
            return this.state.payload.seller_payload.phone_number;
        }
        else if(this.state.payload.individual){
            return this.state.payload.individual.phone_number;
        }
        else if(this.state.payload.business){
            return this.state.payload.business.owner.phone_number;
        }
        else{
            return '';
        }
    }
    getSellerDocument = () => {
        if(this.state.payload.seller_payload){
            return this.state.payload.seller_payload.taxpayer_id;
        }
        else if(this.state.payload.individual){
            return this.state.payload.individual.taxpayer_id;
        }
        else if(this.state.payload.business){
            return this.state.payload.business.ein;
        }
        else{
            return '';
        }
    }
    getSellerId = () => {
        if(this.state.payload.transation_payload){
            return this.state.payload.transation_payload.id;
        }
        else if(this.state.payload){
            return this.state.payload.id;
        }
        else{
            return '';
        }
    }
    getPayload = () => {
        if(this.state.payload.transation_payload){
            return this.state.payload.transation_payload;
        }
        else{
            return this.state.payload;
        }

    }
    render(){
        return (
            <Modal isOpen={this.props.visible} size="lg">
                <ModalHeader>Novo Payout Bloqueado</ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <p>Nome do seller: {this.getSellerName()}</p>
                    <p>Email: {this.getSellerEmail()}</p>
                    <p>Telefone: {this.getSellerTelefone()}</p>
                    <br/>
                    <h5>Dados do Pagamento</h5>
                    <hr/>
                    <p>ID da transação: {this.getSellerId()}</p>
                    <p>Data da Venda: {moment(this.getPayload().created_at).format('DD/MM/YYYY HH:mm')}</p>
                    <p>Valor Bloqueado: R$ {parseFloat(this.getPayload().amount).toFixed(2).replace('.', ',')}</p>
                    <p>Data de Liquidação: {this.getPayload().expected_on !== null && this.getPayload().expected_on !== "None+00:00" ? moment(this.getPayload().expected_on).format('DD/MM/YYYY') : moment(new Date().toISOString()).format('DD/MM/YYYY')}</p>
                    <br/>
                    <h5>Tipo de Bloqueio</h5>
                    <hr/>
                    <div>
                        <input 
                            type="radio" 
                            name="block-type"
                            checked={this.state.tipo_pagamento === 'prevencao'}
                            onChange={() => this.setState({tipo_pagamento: 'prevencao'})}
                        /> Prevenção &nbsp;
                        <input 
                            type="radio" 
                            name="block-type"
                            checked={this.state.tipo_pagamento === 'cartao_proprio'}
                            onChange={() => this.setState({tipo_pagamento: 'cartao_proprio'})}
                        /> Cartão Proprio
                    </div>
                    {/* 
                        <br/>
                        <h5>Liberação de Payout</h5>
                        <hr/>
                        <Row>
                        <Col>
                            <FormGroup>
                                <Label>Pagamento Liberado</Label>
                                <Select 
                                    placeholder="Pagamento Liberado"
                                    options={list_pagamentos_liberados}
                                    value={this.state.pagamento_liberado}
                                    onChange={(pagamento_liberado) => this.setState({pagamento_liberado})}
                                />
                                <Input hidden invalid={this.state.pagamento_liberado_invalid}/>
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>Responsável pela conferência</Label>
                                <AsyncSelect
                                    style={{width: '100%'}}           
                                    cacheOptions
                                    loadOptions={this.loadAtendentesOptions}
                                    defaultOptions
                                    placeholder="Responsável pela conferência"
                                    value={this.state.responsavel_conferencia}
                                    onChange={(responsavel_conferencia) => this.setState({responsavel_conferencia})}
                                />
                                <Input hidden invalid={this.state.responsavel_conferencia_invalid}/>
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>Data/Hora Documentos</Label>
                                <Input 
                                    type="datetime-local"
                                    name="data_hora_docs"
                                    invalid={this.state.data_hora_docs_invalid}
                                    value={this.state.data_hora_docs}
                                    onChange={this.handleChange} />
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Razão do Bloqueio</Label>
                                <Input 
                                    placeholder="Razão do Bloqueio" 
                                    name="razao_bloqueio"
                                    invalid={this.state.razao_bloqueio_invalid}
                                    value={this.state.razao_bloqueio}
                                    onChange={this.handleChange}
                                />
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>Responsável pela liberação</Label>
                                <AsyncSelect
                                    style={{width: '100%'}}           
                                    cacheOptions
                                    loadOptions={this.loadAtendentesOptions}
                                    defaultOptions
                                    placeholder="Responsável pela liberação"
                                    value={this.state.responsavel_liberacao}
                                    onChange={(responsavel_liberacao) => this.setState({responsavel_liberacao})}
                                />
                                <Input hidden invalid={this.state.responsavel_liberacao_invalid}/>
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>Data de Pagamento em conta</Label>
                                <Input 
                                    type="datetime-local"
                                    name="data_pagamento_conta"
                                    invalid={this.state.data_pagamento_conta_invalid}
                                    value={this.state.data_pagamento_conta}
                                    onChange={this.handleChange} />
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <br/>
                    <h5>Estorno de Transação</h5>
                    <hr/>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Data de Estorno</Label>
                                <Input 
                                    type="date"
                                    name="estorno_date"
                                    value={this.state.estorno_date}
                                    invalid={this.state.estorno_date_invalid}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Pedido de solicitação do estorno</Label>
                                <Input 
                                    type="file" 
                                    name="estorno_arquivo"
                                    value={this.state.estorno_arquivo}
                                    invalid={this.state.estorno_arquivo}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Resposta do Estorno</Label>
                                <Input 
                                    type="text"
                                    name="estorno_resposta"
                                    placeholder="Resposta do estorno"
                                    value={this.state.estorno_resposta}
                                    onChange={this.handleChange} 
                                />
                            </FormGroup>
                        </Col>
                    </Row> */}
                </ModalBody>
                <ModalBody
                    hidden={!this.state.loading}
                    style={{
                        minHeight: '114px',
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose.bind(this)}
                        onError={this.onError.bind(this)}
                    />
                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={this.onClose.bind(this)}>Fechar</Button>
                    <Button color="success" onClick={this.onRegister}>Salvar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
export default PayoutModal;