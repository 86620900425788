import axios from 'axios';
import moment from 'moment';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { HiViewGrid } from 'react-icons/hi';
import { toast } from 'react-toastify';
import { Button, Row } from 'reactstrap';
import { ENDPOINT } from '../../../../auth/endpoint';
import { getToken } from '../../../../auth/set_services';
import { fetchWithTimeout } from '../../../Tools/fetchWithTimeout';

export const columns = (editCategoria, removeCategoria) => [
    {
      name: "",
      selector: "buttons",
      sortable: false,
      width: '10%',
      cell: row => <Row>
        <Button
          outline
          className='btn-edit'
          style={{ padding: 2, marginLeft: 15, border: 0, fontWeight: '700'}}
          color="dark" onClick={() => { 
            editCategoria(row.id) 
          }}>
          <FaEdit style={{ padding: '2px' }}/>
        </Button>
      </Row>
    },
    {
      name: "Descrição",
      selector: "nome",
      sortable: false,
      width: '40%',
    },
    {
      name: "Tipo",
      selector: "categoria",
      sortable: false,
      width: '20%',
    },
    {
      name: "DRE",
      selector: "grupo_dre",
      sortable: false,
      width: '20%',
      cell: row => <Row>
        {row.grupo_dre === 1 ? 'Não Exibir no DRE' : 'Exibir no DRE'}
      </Row>
    },
    {
      name: "Remover",
      selector: "remove",
      sortable: false,
      width: '10%',
      cell: row => <Row>
        <Button
          outline
          className='btn-remove'
          style={{ padding: 2, marginLeft: 15, border: 0, fontWeight: '700'}}
          color="dark" onClick={() => { 
              removeCategoria(row.id)
          }}>
          <FaTrash style={{ padding: '2px' }}/>
        </Button>
      </Row>
    },
];

var numberOfAjaxCAllPending = 0;

export const getRows = (state, callback) => {
    if(numberOfAjaxCAllPending == 0){
      numberOfAjaxCAllPending = 1;
      var url = ENDPOINT + "financeiro/categorias/lista?page=" + state.activePage + "&search=" + state.search;
      //var url = "http://localhost:8000/api/financeiro/categorias/lista?page=" + state.activePage + "&search=" + state.search;

      if(state.tipo_options !== undefined && state.tipo_options !== null && state.tipo_options.value !== undefined && state.tipo_options.value !== null){
        url += "&tipo_options="+state.tipo_options.value;
      }      

      
      axios.get(url, {
          headers: {
              Authorization: 'Bearer '+getToken()
          },
          mode: 'cors',
          cache: 'default'
      })
      .then((response) => {

          let p = response.data;
          if(p.success == true){
            var meta = 0;
            if(p.meta){
              meta = p.meta.last_page;
            }
            numberOfAjaxCAllPending = 0;
            // callback(p.data, meta, p.valor);
            callback(p.data, meta);
          }
          else{
            toast.error(p.errors);
          }
      })
      .catch((error) => {
          toast.error('Ocorreu um erro no servidor!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status); // status code
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              //console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            // console.log(error.config);

        callback({}, {});
      })
    }
    else{
      //callback(state.rowData, state.totalPage, []);
    }
  }
  
  export const getRowsPerPage = (page, state, callback) => {
    if(numberOfAjaxCAllPending == 0){
      numberOfAjaxCAllPending = 1;

      var url = ENDPOINT + "financeiro/categorias/lista?page=" + state.activePage + "&search=" + state.search;
      //var url = "http://localhost:8000/api/financeiro/categorias/lista?page=" + state.activePage + "&search=" + state.search;

      if(state.tipo_options !== undefined && state.tipo_options !== null && state.tipo_options.value !== undefined && state.tipo_options.value !== null){
        url += "&tipo_options="+state.tipo_options.value;
      }  

      var myHeaders = new Headers();
    
      myHeaders.append('Authorization', 'Bearer ' + getToken());
      
      var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
      };

      fetchWithTimeout(20000, fetch(url, myInit))
      .then(response => response.json())
      .then((p) => {
        if(p.success == true){
          var meta = 0;
          if(p.meta){
            meta = p.meta.last_page;
          }
          numberOfAjaxCAllPending = 0;
          // callback(p.data, meta, p.valor);
          callback(p.data, meta);
        }
        else{
          toast.error(p.errors);
        }
      })
      .catch((error) => {
        toast.error('Ocorreu um erro no servidor!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        
        callback({}, {});
      })
    }
    else{
      //callback(state.rowData, state.totalPage, []);
    }
  }