import { useEffect, useRef, useState } from "react";
import { render } from "@testing-library/react";
import { Button, ButtonGroup, Card, CardBody, CardFooter, Col, Collapse, Form, FormFeedback, FormGroup, Input, Row } from "reactstrap";
import Select from "react-select";
import { FaPlus } from "react-icons/fa";
import PropagateLoader from "react-spinners/PropagateLoader";
import { InputMasked } from "../../../components/InputText/InputMasked";



export const Contatos = (props) => {

    const validInputs = [
        'nome_contato', 
        'email_contato',
    ];

    return(
    <div>
        <Row>
            <Col md={12}>
                <label htmlFor="nome_contato">Nome contato</label>
                <Input
                    name="nome_contato"
                    placeholder=""
                    value={props.state.nome_contato}
                    type="text"
                    onChange={props.handleInputChange}
                />
                <span className={props.state.formErros.nome_contato ? 'error-active' : 'error-none'}>
                    Campo obrigatório
                </span>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <label htmlFor="celular_contato">Celular do contato</label>
                <InputMasked
                    name="celular_contato"
                    mask={props.state.celular_contato.length <= 10 ? '(99) 9999-9999?' : '(99) 99999-9999'}
                    formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                    onChange={(e) => {props.handlerChangeBrazilianPhone(e, 'celular_contato')}}
                    value={props.state.celular_contato}
                />
                <span className={props.state.formErros.celular_contato ? 'error-active' : 'error-none'}>
                    Campo obrigatório
                </span>
            </Col>
            <Col md={6}>
                <label htmlFor="telefone_contato">Telefone do contato</label>
                <InputMasked
                    name="telefone_contato"
                    mask={props.state.telefone_contato.length <= 10 ? '(99) 9999-9999?' : '(99) 99999-9999'}
                    formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                    onChange={(e) => {props.handlerChangeBrazilianPhone(e, 'telefone_contato')}}
                    value={props.state.telefone_contato}
                />
                <span className={props.state.formErros.telefone_contato ? 'error-active' : 'error-none'}>
                    Campo obrigatório
                </span>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <label htmlFor="email_contato">E-mail do contato</label>
                <Input
                    name="email_contato"
                    placeholder=""
                    value={props.state.email_contato}
                    type="text"
                    onChange={props.handleInputChange}
                />
                <span className={props.state.formErros.email_contato ? 'error-active' : 'error-none'}>
                    Campo obrigatório
                </span>
            </Col>
            <Col md={6}>
                <label htmlFor="email_nfe_contato">E-mail para envio NFe</label>
                <Input
                    name="email_nfe_contato"
                    placeholder=""
                    value={props.state.email_nfe_contato}
                    type="text"
                    onChange={props.handleInputChange}
                />
                <span className={props.state.formErros.email_nfe_contato ? 'error-active' : 'error-none'}>
                    Campo obrigatório
                </span>
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                <Button 
                    color="success"
                    outline
                    onClick={(e) => {props.handleAddContato(validInputs, props.state.contato_position)}}
                    >
                        Salvar Contato
                </Button>

                <Button 
                    className="btn-cancel"
                    color="danger"
                    outline
                    onClick={(e) => {props.handleCancelAddContato()}}
                    >
                        Cancelar
                </Button>
            </Col>
        </Row>
    </div>
    )
}