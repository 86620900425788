import React, { Component   } from 'react';
import moment from 'moment';
import styled from "styled-components";
import { Button, Card, CardBody, CardColumns, CardFooter, Col, Collapse, FormGroup, Input, Label, Nav, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { FaArrowLeft, FaGlobeAmericas, FaMoneyBillAlt, FaPercent, FaPlus, FaSave, FaWindowClose } from 'react-icons/fa';
import classnames from 'classnames';
import PropagateLoader from "react-spinners/PropagateLoader";
import ListarTaxasRegras from './Configuracao/ListarTaxasRegras';
import Select from "react-select";
import axios from "axios";
import { getPermissoes, getToken } from "../../auth/set_services";
import { toast } from 'react-toastify';
import ListarPostagens from './Postagens/ListarPostagens';
import { ENDPOINT } from '../../auth/endpoint';

var CurrencyInput = require('react-currency-masked-input');

const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
    .reclamado {
        display: none;
    }
    .row {
        margin: 10px 0px;
    }
    .btn-save {
        float: right;
    }
    .btn-nota {
        margin-top: 10px;
    }
    .file-iframe {
        width: 100%;
        height: 800px; 
    }
    .btn-arquivo {
        margin-right: 10px;
        color: #fff;
        border: 0px;
    }
    .subtitle {
        font-weight: 700;
        margin-top: 20px !important;
    }
    .error-active {
        display: block;
        color: red;
    }
    .error-none {
        display: none;
    }
    .input-error {
        border: 1px solid red;
    }
    .label-error {
        color: red;
    }
    .select-error {
        border: 1px solid red;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
    }
    .card {
        border: 1px solid #ced4da;
        border-left: 5px solid #28a745;
        border-radius: 0.25rem;
        -webkit-border-radius: 0.25rem;
        -moz-border-radius: 0.25rem;
        
    }
    .card h6 {
        font-weight: 600;
        padding: 10px;
        border-radius: 3px 3px 0px 0px;
        margin: 0px;
    }
    .card-box {
        padding: 1rem;
        height: auto;
        min-height: 180px;
    }
    .card-box div {
        font-size: 14px;
    }
    .button-edit {
        position: absolute;
        bottom: 0.5rem;
        right: 1rem;
    }
    .button-remove {
        position: absolute;
        bottom: 0.5rem;
        left: 1rem;
    }
    .btn-salvar {
        float: right;
    }
    .add-address-btn {
        position: absolute;
        top: 0.5rem;
        right: 2rem;
    }
    .col-cards {
        margin-bottom: 1rem;
    }
    .btn-float {
        float: right;
    }
    .nav-link {
        padding: 1rem;
        font-size: 14px; 
        border-bottom: 0px;
    }
    .active.nav-link {
        background: #58d174;
        color: #fff;
    }
    .tab-content {
        border: 1px solid rgb(222, 226, 230);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        position: relative;
        top: 0px;
    }
    .nav.nav-tabs {
        border-bottom: 0px;
    }
    .currency-simbol {
        color: #999b9d;
        position: absolute;
        left: 25px;
        top: 43px;
    }
    .captalize {
        text-transform: capitalize;
    }

    table tr th,
    table tr td {
        font-weight: normal;
        padding: 0.5rem 1.5rem;
        border: 1px solid #dee2e6;
    }

    table tr td { 
        font-weight: bold;
    }

    .input-erro {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ff0000;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    .select-erro {
        border: 1px solid #ff0000;
        border-radius: 0.25rem;
    }
    .currency-simbol {
        color: #999b9d;
        position: absolute;
        left: 25px;
        top: 43px;
    }
    .input-currency {
        padding-left: 30px;
    }
`;

class AQEnvioPostagens extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading_page: false,
            table_categorias: true,

            tax_type_options: [
                {value: 'V', label: 'Valor (R$)'},
                {value: 'P', label: 'Procentagem (%)'},
            ],

            shipping_free: 0,
            shipping_free_select: {value: '0', label: 'Frete Pago'},

            pay_type_select: {value: 'V', label: 'Valor (R$)'},

            seller_conta_id: null,
            seller_name: null,
            seller_email: null,
            seller_document: null,
            select_free_disabled: false,
            create_new_rule: null,

            //activeTabConfig: "free_rules",
            activeTabConfig: "custom_rules",
            activeTab: "1",
            list_tabs_config: [
                { name: <><FaGlobeAmericas style={{ padding: '2px' }}/> Regras & Taxas Global</>, active: 'free_rules'},
                { name: <><FaPercent style={{ padding: '2px' }}/> Regras & Taxas Vendedores</>, active: 'custom_rules'},
            ],

            ruleSellerData: this.handleLoadRuleSellerData.bind(this),
        }
    }

    handletoastSuccessMessage = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    handletoastMessage = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    componentDidMount = () => {
        this.setState({loading_page: true})
        this.loadDataGlobal();
    }

    loadDataGlobal = () => {
        var url = ENDPOINT + "aqenvio/list_global_rules";
        //var url = "http://127.0.0.1:8000/api/aqenvio/list_global_rules";

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            let data = response.data;

            if (data.success) {
                data.data.map((rule, i) => {
                    if (rule.free === 1) {
                        this.setState({
                            free_min_amount: rule.min_amount.replace('.', ','),
                            free_max_amount: rule.max_amount.replace('.', ','),
                            free_weight: rule.weight.replace('.', ','),
                        })
                    } else {
                        this.setState({
                            pay_min_amount: rule.min_amount.replace('.', ','),
                            pay_max_amount: rule.max_amount.replace('.', ','),
                            pay_weight: rule.weight.replace('.', ','),
                            pay_tax_amount: rule.tax_amount.replace('.', ','),
                            pay_tax_percent: rule.tax_percent.replace('.', ','),
                        })
                        

                        this.state.tax_type_options.map((option, i) => {
                            if (option.value == rule.tax_type) {
                                this.setState({
                                    pay_type: option.value,
                                    pay_type_select: option,
                                })
                            }
                        })
                    }
                })
            }
        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              
              //console.log(error.config);
        })
    }

    handleSavePayShipping = () => {
        var url = ENDPOINT + "aqenvio/rules_global_pay";
        //var url = "http://127.0.0.1:8000/api/aqenvio/rules_global_pay";


        axios.post(url, {
            'min_amount': this.state.free_min_amount,
            'max_amount': this.state.free_max_amount,
            'weight': this.state.free_weight,
            'tax_type': this.state.pay_type,
            'tax_amount': this.state.pay_tax_amount,
            'tax_percent': this.state.pay_tax_percent,
        }, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            let data = response.data;

            if (data.success) {
                this.handletoastSuccessMessage('Salvo com sucesso!')
            }

        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              //console.log(error.config);
        })
    }

    handleSaveFreeShipping = () => {
        var url = ENDPOINT + "aqenvio/rules_global_free";
        //var url = "http://127.0.0.1:8000/api/aqenvio/rules_global_free";


        axios.post(url, {
            'min_amount': this.state.free_min_amount,
            'max_amount': this.state.free_max_amount,
            'weight': this.state.free_weight,
        }, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            let data = response.data;

            if (data.success) {
                this.handletoastSuccessMessage('Salvo com sucesso!')
            }

        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              //console.log(error.config);
        })
    }

    handleLoadCloseRuleSellerData = () => {
        this.setState({
            seller_conta_id: null,
            seller_name: null,
            seller_document: null,
            seller_email: null,
            select_free_disabled: false,
        })
    }

    handleLoadRuleSellerData = (data) => {

        let shippingFreeSelect = {};

        if (data.free) {
            shippingFreeSelect = {value: '1', label: 'Frete Grátis'};
        } else {
            shippingFreeSelect = {value: '0', label: 'Frete Pago'};
        }


        this.setState({
            seller_conta_id: data.seller_conta_id,
            seller_name: data.seller_name,
            seller_document: data.seller_document,
            seller_email: data.seller_email,
            shipping_free_select: shippingFreeSelect,
            select_free_disabled: true,
        })
    }
    
    handleNewRuleShipping = () => {
        this.setState({
            create_new_rule: true,
        })
    }

    render() {

        return(
        <Div>
            <h3>Lista de Postagens</h3><hr />
            <Row hidden={this.state.loading_page}>
                <Col md={12}>
                    <center>
                        <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                    </center>
                    <br/>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <ListarPostagens
                        state={this.state}
                        />
                </Col>
            </Row>

            <br />
            <br />
        </Div>
        )
    }
}

export default AQEnvioPostagens;