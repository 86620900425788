import axios from "axios";
import Moment from "moment";
import React from "react";
import { MdAdd, MdCamera, MdFileUpload, MdSearch } from "react-icons/md";
import { toast } from "react-toastify";
import {
    Input,
    Col,
    Form,
    FormGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Row,
    FormFeedback,
    CustomInput,
    Card,
    CardBody,
    CardFooter,
    Label
} from "reactstrap"
import {  getUser } from "../../../auth/set_services";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import { register, update } from "../../../auth/services";
import { ButtonOutline } from "../../Button/ButtonOutline";
import { InputMasked } from "../../InputText/InputMasked";
import { InputText } from "../../InputText/InputText";
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import Select from 'react-select';
import aqpasso_icon from '../../../assets/aqbank-icon.png';
import Webcam from 'react-webcam';
import styled from "styled-components";
//import Amplify from 'aws-amplify';
//import {AmplifyS3Image} from "@aws-amplify/ui-react";
//import { S3Image } from 'aws-amplify-react';

const access_type = [
    { value: 'super_user', label: 'Master' },
    { value: 'analista', label: 'Analista de Atendimento' },
    { value: 'gestor_setor', label: 'Gestor de setor' },
    { value: 'supervisor_setor', label: 'Supervisor de setor' },
    { value: 'vendedor', label: 'Vendedor' },
    { value: 'back_operacional', label: 'BackOffice Operacional' },
    { value: 'back_financeiro', label: 'BackOffice Financeiro' },
    { value: 'diretoria', label: 'Diretoria' },
    { value: 'back_logistica', label: 'BackOffice de Logistica' },
    { value: 'back_prevencao', label: 'BackOffice de Prevenção' },
    { value: 'back_cadastro', label: 'BackOffice de Cadastro' }
];

/* const departamento_chat = [
    { value: 'vendas', label: 'Vendas' },
    { value: 'suporte', label: 'Suporte' }
]; */

const ChatStatus = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 12px;
    background-color: ${props => (props.color)};
`;

const ChatStatusContent = styled.div`
    display: flex;
    align-items: center;
    @media screen and (max-width: 800px) {
    b{
        display: none;
    }
    }
`;
const estado_opcoes = [
    {id: 1, value: 'online', label: <ChatStatusContent><ChatStatus color="green"/> <b>Online</b></ChatStatusContent>},
    {id: 0, value: 'offline', label: <ChatStatusContent><ChatStatus color="red"/> <b>Offline</b></ChatStatusContent>},
    {id: 2, value: 'ocupado', label: <ChatStatusContent><ChatStatus  color="orange"/> <b>Ocupado</b></ChatStatusContent>},
];

const getDepartamentos = (callback) => {
    var url = ENDPOINT + "departamento/list";
    
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        var operadores_list = [];
        data.forEach((p, i) => {
            p['value'] = p.flag;
            p['label'] = p.nome;
            operadores_list.push(p);
        });

        callback(operadores_list)
    });
}

const getUserCpf = (cpf, onData) => {
    var url = ENDPOINT+"user/search_cpf_new?cpf="+cpf.replace(/([^\d])+/gim, '');
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });

}
class AddAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cpf_current: null,
            name: '',
            name_invalid: false,
            email: '',
            email_invalid: false,
            cpf: '',
            cpf_invalid: false,
            access_system: null,
            access_system_invalid: false,
            data_nascimento: '',
            data_nascimento_invalid: false,
            ip_address: '',
            ip_address_invalid: false,
            assinaturas_email: [],
            assinatura_email: null,
            assinatura_email_invalid: false,
            telefone: '',
            telefone_invalid: false,
            enable_monitoramento: false,
            enable_monitoramento_invalid: false,
            list_enderecos_ip: [],
            list_enderecos_ip_invalid: false,
            limite_chat: 0,
            limite_chat_invalid: false,
            status_chat: null,
            status_chat_invalid: false,
            setor: null,
            setor_invalid: null,
            inputValue: '',
            is_form_user: false,
            is_editing: false,
            is_modal_webcam: false,
            loading: false,
            ip_valid: false,
            departamento_chat: [],
            image_current: aqpasso_icon,
            error_message: '',
            success_message: ''
        }
        this.inputFile = React.createRef();
        this.camera = React.createRef();
        this.onRegister = this.onRegister.bind(this);
        this.onClose = this.onClose.bind(this);
        this.loadUser = this.loadUser.bind(this);
    }
    componentDidMount(){
        getDepartamentos(this.onDepartamentoList);
        this.getModelos();
    }
    onDepartamentoList = (departamento_chat) => {
        this.setState({ departamento_chat });
    }
    getModelos = () => {
        var url = ENDPOINT+"modelos/list";
    
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            var assinaturas_email = [];
            data.forEach((p, i) => {
                p['value'] = p.flag;
                p['label'] = p.nome;
                assinaturas_email.push(p);
            })
            this.setState({assinaturas_email});
        })
        .catch((error) => {

        });
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.visible !== false && nextProps.visible !== this.props.visible) {
            this.getModelos();
        }
        if (nextProps.editing !== null && nextProps.editing !== this.props.editing) {
            this.loadUser(nextProps.editing);
        }
    }


    onCPF(data){
        if(data.success === true){
            var convert = data.data.nascimento.match(/(\d\d)(\d\d)(\d\d\d\d)/);
            var dateValue = new Date(convert[3], convert[2] - 1, convert[1]);
            this.setState({
                is_form_user: true,
                cpf_current: data.data.ni,
                name: data.data.nome,
                name_invalid: false,
                email: '',
                email_invalid: false,
                data_nascimento: Moment(new Date(dateValue).toDateString()).format('YYYY-MM-DD'),
                data_nascimento_invalid: false,
                telefone: '',
                telefone_invalid: false,
            })
        }
        else{
            this.setState({
                is_form_user: false,
                cpf_current: null,
                name: '',
                name_invalid: false,
                email: '',
                email_invalid: false,
                data_nascimento: '',
                data_nascimento_invalid: false,
                telefone: '',
                telefone_invalid: false,
            })
        }
    }

    validateFields() {
        var invalid = 0;
        if(this.state.cpf_current !== null){
            this.setState({cpf_invalid: false})
            if (this.state.name.length > 3) {
                this.setState({
                    name_invalid: false
                })
            }
            else {
                invalid++;
                this.setState({
                    name_invalid: true
                })
            }

            if (this.state.data_nascimento.length > 0) {
                this.setState({
                    data_nascimento_invalid: false
                })
            }
            else {
                invalid++
                this.setState({
                    data_nascimento_invalid: true
                })
            }

            if (this.state.email.length > 3) {
                this.setState({
                    email_invalid: false
                })
            }
            else {
                invalid++
                this.setState({
                    email_invalid: true
                })
            }

            if (this.state.telefone.replace(/([^\d])+/gim, '').length > 10) {
                this.setState({
                    telefone_invalid: false
                })
            }
            else {
                invalid++
                this.setState({
                    telefone_invalid: true
                })
            }

            if(this.state.access_system !== null){
                this.setState({
                    access_system_invalid: false
                })
            }
            else{
                invalid++;
                this.setState({
                    access_system_invalid: true
                })
            }

            if(this.state.assinatura_email !== null){
                this.setState({
                    assinatura_email_invalid: false
                })
            }
            else{
                invalid++;
                this.setState({
                    assinatura_email_invalid: true
                })
            }
            if(this.state.ip_valid === true){
                if(this.state.list_enderecos_ip.length > 0){
                    this.setState({list_enderecos_ip_invalid: false})
                }
                else{
                    this.setState({list_enderecos_ip_invalid: true})
                }
            }
            /* if(this.get_permissoes_operadores() === true){
                if(this.checkAccessSystem() === false){
                    if(this.state.setor !== null){
                        this.setState({
                            setor_invalid: false
                        })
                    }
                    else{
                        invalid++;
                        this.setState({
                            setor_invalid: true
                        })
                    }
                }
                else{
                    this.setState({
                        setor_invalid: false
                    })
                }
            }
            else{
                this.setState({
                    setor_invalid: false
                })
            } */

        }
        else{
            invalid++;
            this.setState({cpf_invalid: true})
        }


        return invalid;

    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({ [name]: value });
    }
    onData(data) {
        
        if (data.success === true) {
            this.setState({
                success_message: data.message
            });
            //this.storeTaggy(this.onDataToast.bind(this))

        }
        else {
            this.setState({
                error_message: data.errors
            })
        }
    }
    onDataToast(data) {
        if (data.success === true) {
            toast.success(data.message);
        }
        else {
            toast.warning(data.errors);
        }
    }
    onRegister() {
        if (this.validateFields() === 0) {
            this.setState({ loading: true });
            if (this.props.editing !== null) {
                update(this.state, this.props.editing.id, this.onData.bind(this));
            }
            else {
                register(this.state, this.onData.bind(this));
            }
        }
    }
    onClose() {
        this.setState({
            cpf_current: null,
            name: '',
            name_invalid: false,
            email: '',
            email_invalid: false,
            cpf: '',
            cpf_invalid: false,
            access_system: null,
            access_system_invalid: false,
            data_nascimento: '',
            data_nascimento_invalid: false,
            assinatura_email: null,
            assinatura_email_invalid: false,
            telefone: '',
            telefone_invalid: false,
            ip_address: '',
            ip_address_invalid: false,
            list_enderecos_ip: [],
            enable_monitoramento: false,
            enable_monitoramento_invalid: false,
            limite_chat: 0,
            limite_chat_invalid: false,
            status_chat: null,
            status_chat_invalid: false,
            setor: null,
            setor_invalid: null,
            inputValue: '',
            is_form_user: false,
            is_editing: false,
            is_modal_webcam: false,
            image_current: aqpasso_icon,
            ip_valid: false,
            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose(false);
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    loadUser(user) {
        
        if(user){
            var access_system = {};
            var assinatura_email = null;
            if(user.access_system === 'super_user'){
                access_system = { value: 'super_user', label: 'Super Usuário'}
            }
            else{
                access_type.forEach((p) => {
                    if(p.value === user.access_system){
                        access_system = p;
                    }
                });
            }
    
            if(user.modelos_assinatura){
                assinatura_email = { value: user.modelos_assinatura.flag, label: user.modelos_assinatura.nome }
            }
    
            this.setState({
                name: user.name,
                cpf: user.cpf,
                cpf_current: user.cpf,
                data_nascimento: user.date_nascimento === null ? '' : user.date_nascimento,
                email: user.email === null ? '' : user.email,
                telefone: user.telefone === null ? '' : user.telefone,
                enable_monitoramento: Boolean(user.enable_monitoramento),
                limite_chat: user.limite_chat,
                image_current: user.avatar_foto === null ? this.state.image_current: user.avatar_foto,
                is_form_user: true,
                is_editing: true,
                setor: user.departamento_detalhes ? { value: user.departamento_detalhes.flag, label: user.departamento_detalhes.nome } : null,
                ip_valid: Boolean(user.ip_validation),
                list_enderecos_ip: user.enderecos_ip ? user.enderecos_ip : [],
                status_chat: this.getStatusChat(user),
                access_system,
                assinatura_email
            });
        }
    }
    getStatusChat = (user) => {
        var status_chat = null;
        estado_opcoes.map(p => {
            if(p.id === user.status_chat){ 
                return status_chat = p 
            }
            return "";
        })

        return status_chat;
    }
    
    handleSelect = (name, value) => {
        this.setState({ [name]: value });
    }

    handleSelectChange = access_system => {
      this.setState({ access_system });
    };
    handleIp = ip_valid => {
      this.setState({ ip_valid });
    };
    onSearchCpf(){
        getUserCpf(this.state.cpf, this.onCPF.bind(this));
    }
    baterFoto = () => {
        var screenshot = this.camera.current.getScreenshot();
        this.setState({image_current: screenshot, is_modal_webcam: false});
    }
    
    handleInputChange = (newValue) => {
      const inputValue = newValue.replace(/\W/g, '');
      this.setState({ inputValue });
      return inputValue;
    };

    checkAccessSystem(){
        if(this.state.access_system !== null){
            switch(this.state.access_system.value){
                case 'analista':
                    return false
                case 'gestor_setor':
                    return true
                case 'admin':
                    return true
                case 'supervisor_setor':
                    return false
                case 'vendedor':
                    return false
                case 'master':
                    return true
                default:
                    return true
            }
        }
        else{
            return false;
        }
    }
    onDragOver = (event) => {
        event.stopPropagation();
        event.preventDefault();
    }
    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(
            this.setState({image_current: reader.result})
        );
        reader.onerror = error => reject(error);
    });
    
    onDrop =  (event) => {
        event.preventDefault();
        event.stopPropagation();
        var file = (event.dataTransfer.files[0]);
        if(typeof file !== 'undefined'){
            console.log(btoa(aqpasso_icon));
            this.toBase64(file);
        }
    };

    handleFileChange = (event) => {
        const file = event.target.files[0];
        if(file){
            this.toBase64(file);
        }
    }
    abrirWebcam = () => {
        
        let constraints={video:true};
        navigator.mediaDevices.getUserMedia(constraints)
        .then((stream)=>{
            if(stream.getVideoTracks().length > 0){
                this.setState({is_modal_webcam: true});
            }
        })
        .catch((e) => {
            alert('Nenhuma camera detectada')
        });

    }
    checkIP = () => {
        var invalids = 0;
        this.state.list_enderecos_ip.forEach((p) => {
            if(p.endereco_ip === this.state.ip_address){
                invalids++;
            }
        })
        if(invalids > 0){
            return false
        }
        return true;
    }
    saveIp(){
        const isValidIp = value => (/^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/.test(value) ? true : false);

        if(isValidIp(this.state.ip_address)){
            if(this.checkIP() === true){
                var list = this.state.list_enderecos_ip;
                var endereco_ip = {
                    endereco_ip: this.state.ip_address,
                }
                list.push(endereco_ip);

                this.setState({list_enderecos_ip: list, ip_address: '', ip_address_invalid: false })
            }
            else{
                this.setState({ip_address_invalid: true})
            }
        }
        else{
            this.setState({ip_address_invalid: true})
        }
    }
    ipRemove(index){
        var list_enderecos_ip = this.state.list_enderecos_ip;
        list_enderecos_ip.splice(index, 1);
        this.setState({list_enderecos_ip});
    }
    render() {
        return (
            <Modal isOpen={this.props.visible} size={'lg'}>
                <ModalHeader>
                {this.state.is_editing === true ? 'Editar' : 'Adicionar'} Operador
            </ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <Form>
                        <FormGroup>
                            <Row>
                                <Col md={10}>
                                    <InputMasked
                                        mask="999.999.999-99"
                                        name="cpf"
                                        value={this.state.cpf}
                                        placeholder="CPF"
                                        disabled={this.state.is_editing}
                                        invalid={this.state.cpf_invalid}
                                        onChange={this.handleChange} />
                                    <FormFeedback>CPF Inválido / Não Buscado</FormFeedback>
                                </Col>
                                <Col md={2}>
                                    <ButtonOutline
                                        style={{ margin: 0 }}
                                        hidden={this.state.is_editing}
                                        onClick={this.onSearchCpf.bind(this)}
                                    ><MdSearch style={{ padding: 0 }} /></ButtonOutline>
                                </Col>
                            </Row>
                        </FormGroup>
                        <div hidden={!this.state.is_form_user}>
                            <Row>
                                <Col md={4} sm={6}>
                                    <FormGroup>
                                        <InputText
                                            name="name"
                                            value={this.state.name}
                                            type="text"
                                            disabled
                                            placeholder="Nome"
                                            invalid={this.state.name_invalid}
                                            onChange={this.handleChange}
                                        />
                                        <FormFeedback>Informe o Nome</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputText
                                            name="email"
                                            value={this.state.email}
                                            type="text"
                                            placeholder="E-mail"
                                            invalid={this.state.email_invalid}
                                            onChange={this.handleChange}
                                        />
                                        <FormFeedback>Informe um e-mail valido</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Select
                                            className="react-select"
                                            options={access_type}
                                            isDisabled={this.props.editing !== null ? (this.props.editing.id === getUser().id ? true : false) : false}
                                            placeholder="Nível de acesso"
                                            isSearchable={false}
                                            value={this.state.access_system}
                                            onChange={this.handleSelectChange}
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: 0
                                            })}
                                        />
                                        <Input hidden invalid={this.state.access_system_invalid}/>
                                        <FormFeedback>Selecione o nível de acesso</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        {/* <AsyncSelect
                                            cacheOptions
                                            loadOptions={this.loadOptions}
                                            defaultOptions
                                            value={this.state.setor} 
                                            placeholder="Setor"
                                            isDisabled={this.checkAccessSystem() || !this.get_permissoes_operadores()}
                                            onChange={(setor) => this.setState({setor})}
                                            onInputChange={this.handleInputChange}
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: 0
                                            })}
                                        /> */}

                                        <Select
                                            className="react-select"
                                            options={this.state.departamento_chat}
                                            isDisabled={this.props.editing !== null ? (this.props.editing.id === getUser().id ? true : false) : false}
                                            placeholder="Departamento (Chat)"
                                            isSearchable={false}
                                            value={this.state.setor}
                                            onChange={(setor) => this.setState({setor})}
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: 0
                                            })}
                                        />
                                        <InputText hidden invalid={this.state.setor_invalid}/>
                                        <FormFeedback>Selecione o departamento do operador</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={4} sm={6}>
                                    <FormGroup>
                                        <InputText
                                            name="data_nascimento"
                                            value={this.state.data_nascimento}
                                            type="date"
                                            disabled
                                            placeholder="Data de Nascimento"
                                            invalid={this.state.data_nascimento_invalid}
                                            onChange={this.handleChange}
                                        />
                                        <FormFeedback>Informe a data de nascimento</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputMasked
                                            name="telefone"
                                            value={this.state.telefone}
                                            type="text"
                                            mask='(99) 99999-9999'
                                            placeholder="Celular"
                                            invalid={this.state.telefone_invalid}
                                            onChange={this.handleChange}
                                        />
                                        <FormFeedback>Informe um celular valido</FormFeedback>
                                    </FormGroup>

                                    <FormGroup>
                                        <Select
                                            className="react-select"
                                            options={this.state.assinaturas_email}
                                            placeholder="Assinatura de e-mail"
                                            isSearchable={false}
                                            onChange={(assinatura_email) => this.setState({assinatura_email})}
                                            value={this.state.assinatura_email}
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: 0
                                            })}
                                        />
                                        <Input hidden invalid={this.state.assinatura_email_invalid}/>
                                        <FormFeedback>Selecione a assinatura de e-mail</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={4} sm={12}>
                                    <Card 
                                        onDragEnter={(e) => {}}
                                        onDragOver={this.onDragOver} 
                                        onDrop={this.onDrop}>
                                        <CardBody style={{height: '200px', alignSelf: 'center'}}>
                                            <img src={this.state.image_current+'?access_token='+getToken()} style={{maxWidth: '100%', maxHeight: '168px'}} alt="profile-admin"/>
                                        </CardBody>
                                        <CardFooter>
                                            <Row>
                                                <Col></Col>
                                                <Col>
                                                    <Button color="dark" onClick={() => this.abrirWebcam()}><MdCamera style={{padding: 0}}/></Button>
                                                </Col>
                                                <Col>
                                                    <Button color="success" onClick={() => this.inputFile.current.click()} ><MdFileUpload style={{padding: 0}}/></Button>
                                                    <input type="file" ref={this.inputFile} hidden multiple onChange={this.handleFileChange} />
                                                </Col>
                                                <Col></Col>
                                            </Row>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CustomInput 
                                        type="switch" 
                                        id="ip_switch" 
                                        name="ip_switch"
                                        label="Habilitar restrição por ip em operador" 
                                        disabled={this.props.editing !== null ? (this.props.editing.id === getUser().id ? true : false) : false}
                                        checked={this.state.ip_valid}
                                        onChange={(e) => this.handleIp(e.target.checked)}
                                    />
                                </Col>
                            </Row>
                            <div hidden={this.state.ip_valid === false || (this.props.editing !== null ? (this.props.editing.id !== getUser().id ? false : true) : false)}>
                                <hr/>
                                <h5>IP's configurados</h5>
                                <br/>
                                <FormGroup style={{display: 'flex', width:'100%'}}>
                                    <InputText 
                                        placeholder="Endereço de IP"
                                        type="text" 
                                        name="ip_address"
                                        value={this.state.ip_address} 
                                        invalid={this.state.ip_address_invalid}
                                        min={1}
                                        onChange={this.handleChange}
                                        max={30} />
                                    <Button color="success" 
                                        style={{ marginLeft: '5px'}}
                                        onClick={() => this.saveIp()}
                                        ><MdAdd style={{padding: 0}}/></Button>
                                </FormGroup>
                                <FormGroup>
                                    {this.state.list_enderecos_ip.map((p, index) => (
                                        <Row>
                                            <Col md={3} sm={5} xs={5} style={{alignSelf: 'center', margin: 15}}>
                                                {p.endereco_ip}
                                            </Col>
                                            <Col md={3} sm={5} xs={5} >
                                                <Button color="danger" onClick={() => this.ipRemove(index)}>Excluir</Button>
                                            </Col>
                                        </Row>
                                    ))}
                                    <Input hidden invalid={this.state.list_enderecos_ip_invalid}/>
                                    <FormFeedback>Campo Obrigatório</FormFeedback>
                                </FormGroup>
                                <hr/>
                            </div>
                            <div hidden={this.props.editing !== null ? (this.props.editing.id === getUser().id ? true : false) : false}>
                                <hr/>
                                <h5>Configurações de atendimento</h5>
                                <br/>
                                <Row>
                                    <Col>
                                        <CustomInput 
                                            type="switch" 
                                            id="monitoramento_switch" 
                                            name="monitoramento_switch"
                                            label="Monitorar esse atendente no chat" 
                                            disabled={this.props.editing !== null ? (this.props.editing.id === getUser().id ? true : false) : false}
                                            checked={this.state.enable_monitoramento}
                                            onChange={(e) => this.handleSelect('enable_monitoramento', e.target.checked)}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col>
                                        <Label>Limite de Chat:</Label>
                                        <Input 
                                            placeholder="Limite de chat" 
                                            type="number" 
                                            value={this.state.limite_chat} 
                                            min={0} 
                                            onChange={(e) => this.handleSelect('limite_chat', e.target.value)}
                                            max={100}/>
                                    </Col>
                                    <Col>
                                        <Label>Status de Chat:</Label>
                                        <Select 
                                            styles={{
                                                // Fixes the overlapping problem of the component
                                                menu: provided => ({ ...provided, zIndex: 9999 })
                                            }}
                                            onChange={(value) => this.handleSelect('status_chat', value)}
                                            isSearchable={false}
                                            placeholder="Status de Chat" 
                                            options={estado_opcoes}
                                            value={this.state.status_chat}
                                            menuPlacement="auto"
                                            menuPosition="fixed"/>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Form>
                </ModalBody>

                <Modal isOpen={this.state.is_modal_webcam}>
                    <ModalBody>
                        <Card>
                            <Webcam 
                                ref={this.camera}
                                height={300}
                            />
                        </Card>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={() => this.baterFoto()}>Bater foto</Button>&nbsp;
                        <Button color="danger" onClick={() => this.setState({is_modal_webcam: false})}>Cancelar</Button>
                    </ModalFooter>
                </Modal>

                <ModalBody
                    style={{
                        minHeight: '114px',
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                    />

                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose(false)}>Cancelar</Button>
                    <Button color="success" onClick={this.onRegister}>{this.state.is_editing === true ? 'Editar' : 'Cadastrar'}</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default AddAdmin;