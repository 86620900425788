import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import Moment from 'moment';

export const columns = () => [
    {
      name: "Data de criação",
      selector: "created_at",
      sortable: false,
      width: '150px',
      cell: p => Moment(p.created_at).format('DD/MM/YYYY HH:mm')
    },
    {
      name: "Documento",
      selector: "documento",
      sortable: false,
      width: '150px'
    },
    {
      name: "CEP",
      selector: "cep",
      sortable: false,
      width: '150px'
    },
    {
      name: "Endereco",
      selector: "endereco",
      sortable: false,
      width: '20%',
      cell: p => <>{p.rua} - {p.numero}, {p.complemento} {p.bairro} - {p.estado}/{p.cidade}</>
    },
    {
      name: "Motivo",
      selector: "motivo",
      sortable: false
    },
    {
      name: "Comprovante",
      selector: "url_comprovante",
      sortable: false,
      width: '200px',
      cell: p=> <a href={p.url_comprovante} target="_blank"><b>Abrir Comprovante</b></a>
    }
];

export const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "enderecos/list";

    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&documento="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&documento="+document;
        }
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    
    var url = ENDPOINT + "enderecos/list?search="+search+"&page="+page;

    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&documento="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&documento="+document;
        }
    }


    if(filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}