import React, { Component } from 'react';
import { Button, Card, CardBody } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { TableSMS } from '../../components/Tables/TableSMS/TableSMS';
import Moment from 'moment';
import { getRows, getRowsPerPage } from '../../components/Tables/TableSMS/propsSMS';
import { getPermissoes } from '../../auth/set_services';
import SendSms from '../../components/Modals/SendsModals/SendSms';
import { MdSms } from 'react-icons/md';

/* const getId = () =>{
    if(window.location.pathname === '/contestacoes'){
        return null;
    }
    else{
        return window.location.pathname.replace('/contestacoes/', '')
    }
} */
const getPhone = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const phone_sms = urlParams.get('phone_sms');
    if(phone_sms !== null){
        return phone_sms;
    }
    return "";
}
class SMS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            activePage: 1,
            loading_table: true,
            //email: getId(),
            phone: getPhone(),
            totalPage: 1,
            filter: false,
            visible_sms: false,
            search: '',
            cpf_cnpj: '',
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentDidMount() {
        getRows(this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    onFilter = (filter) => {
        this.setState({ filter });
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onClose(visible_sms){
        this.setState({visible_sms})
    }
    render() {
        return (
            <div>
                <h3>SMS's</h3>
                <hr />
                <div>
                    <Button onClick={() => this.onClose(true)} style={{marginBottom: 10}} color="success"><MdSms /> Novo SMS</Button>
                </div>
                <Card>
                    <TableSMS
                        isSearch={this.handleChange}
                        data={this.state.rowData}
                        phone={this.state.phone}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        noDataComponent={this.state.loading_table === true ?
                            <Card style={{ border: 0, padding: '10vh' }}><CardBody>
                                <div><div className="sweet-loading">
                                    <PropagateLoader color={'#52c565'} loading={20} size={30} />
                                </div></div></CardBody></Card> :
                            <Card style={{ border: 0 }}><CardBody><div>
                                Nenhum sms encontrado
                            </div></CardBody></Card>
                        }
                        cpf_cnpj={this.state.cpf_cnpj}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <SendSms
                    visible={this.state.visible_sms}
                    onClose={() => this.onClose(false)}
                />
            </div>
        )
    }
}

export default SMS;