import axios from "axios";
import Cookies from "js-cookie";

export function useApi(baseUrl, config) {
  const api = axios.create({
    baseURL: baseUrl,
  });
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      return new Promise((resolve, reject) => {
        const originalReq = err.config;
        if (err.response.status == 401 && err.config && !err.config._retry) {
          originalReq._retry = true;
          let res = api
            .post(config?.tokenRoute, config?.tokenRouteParams)
            .then((res) => {
              const token = res?.data?.data?.token_access;
              Cookies.set(config?.tokenCookieName, token);
              originalReq.headers["Authorization"] = `Bearer ${token}`;
              return api(originalReq);
            });
          resolve(res);
        } else {
          reject(err);
        }
      });
    }
  );
  return { api };
}
export function useApiAqmed() {
  const { api } = useApi("https://apiaqmed.aqbank.com.br/api/v1", {
    tokenRoute: "/auth/code",
    tokenRouteParams: {
      document: "00000000000",
      password: "1q2w3e4r",
      remember_token: "HMXxC9",
    },
    tokenCookieName: "aqmedtoken",
  });
  const apiAqmed = api;
  return { apiAqmed };
}

export function useApiOnboarding() {
  const apiOnboarding = axios.create({
    baseURL: "https://apionboarding.aqbank.com.br/api",
  });
  apiOnboarding.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      return new Promise((resolve, reject) => {
        const originalReq = err.config;
        if (err.response.status == 401 && err.config && !err.config._retry) {
          originalReq._retry = true;
          let res = apiOnboarding
            .post("/token", {
              cpf: "api",
              password: "s3nh4usu4r104p1@",
              device_name: "CRM",
            })
            .then((res) => {
              const token = res?.data?.token;
              Cookies.set("onboardingtoken", token);
              originalReq.headers["Authorization"] = `Bearer ${token}`;
              return apiOnboarding(originalReq);
            });
          resolve(res);
        } else {
          reject(err);
        }
      });
    }
  );
  return { apiOnboarding };
}
