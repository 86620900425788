import React, { Component } from 'react';
import Select from 'react-select';
import { 
    Button, 
    Card, 
    CardBody, 
    Col,
    Collapse, 
    FormGroup, 
    Input, 
    InputGroup, 
    InputGroupAddon, 
    Label, 
    Modal, 
    ModalBody,
    ModalFooter,
    Row
} from 'reactstrap';
import { FaCheck } from 'react-icons/fa';
import { GrCatalogOption } from 'react-icons/gr';
import { MdAdd, MdClear, MdList, MdQuestionAnswer, MdRemove } from 'react-icons/md';
import { CgOptions } from 'react-icons/cg';
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import { ENDPOINT } from '../../../auth/endpoint';
import axios from 'axios';
import { getToken } from '../../../auth/set_services';
import { toast } from 'react-toastify';

const tipos = [
    {
        value: 'radio',
        label: 'Por alternativa'
    },
    {
        value: 'descricao',
        label: 'Por descrição'
    }
]
class CreateNps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            titulo: '',
            titulo_invalid: false,
            descricao: '',
            descricao_invalid: false,
            titulo_contato: '',
            titulo_adicional: '',
            titulo_opcao: '',
            opcao_visible: false,
            adicional_visible: false,
            tipo: null,
            opcao: [],
            contato: [],
            perguntas_adicionais: [],
            contato_visible: false,
            loading: false,
            error_message: '',
            success_message: ''
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }
    validFields(){
        var invalid = 0;
        if(this.state.titulo !== ""){
            this.setState({titulo_invalid: false})
        }
        else{
            invalid++;
            this.setState({titulo_invalid: true})
        }

        if(this.state.descricao !== ""){
            this.setState({descricao_invalid: false})
        }
        else{
            invalid++;
            this.setState({descricao_invalid: true})
        }

        return invalid;
    }

    onRegister(){
        if(this.validFields() === 0){
            this.criarNps(this.state, this.onReturn.bind(this))
        }
    }

    onReturn(data) {
        if (data.success === true) {
            this.setState({
                success_message: data.message
            })
        }
        else {
            this.setState({
                error_message: data.errors
            })
        }
    }
    
    criarNps = (state, onData) => {
        this.setState({loading: true})
        var url = ENDPOINT+"nps/create";
    
        var formData = new FormData();
        formData.append('titulo', state.titulo);
        formData.append('descricao', state.descricao);
        
        this.state.perguntas_adicionais.forEach((e, i) => {
            formData.append('perguntas_adicionais['+i+']', JSON.stringify(e));
        });

        this.state.contato.forEach((e, i) => {
            formData.append('contato['+i+']', JSON.stringify(e));
        });
        
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            onData(data);
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
            var data = error.response.data;
            onData(data);
            return data;
        });
    
    }
    
    onClose() {
        this.setState({
            titulo: '',
            titulo_invalid: false,
            descricao: '',
            descricao_invalid: false,
            titulo_contato: '',
            titulo_adicional: '',
            titulo_opcao: '',
            opcao_visible: false,
            adicional_visible: false,
            tipo: null,
            opcao: [],
            contato: [],
            perguntas_adicionais: [],
            contato_visible: false,
            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose(false);
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }
    isVisibleContact(contato_visible){
        this.setState({
            contato_visible,
            titulo_contato: ''
        })
    }
    isVisibleOption(opcao_visible){
        this.setState({
            opcao_visible,
            titulo_opcao: ''
        })
    }
    isVisibleAdicional(adicional_visible){
        this.setState({
            adicional_visible,
            titulo_adicional: '',
            tipo: null,
            opcao: []
        })
    }
    removeContact(index){
        var contacts = this.state.contato;
        contacts.splice(index, 1);
        this.setState({contato: contacts});
    }
    removeAsked(index){
        var perguntas_adicionais = this.state.perguntas_adicionais;
        perguntas_adicionais.splice(index, 1);
        this.setState({perguntas_adicionais});
    }
    checkContacts(){
        var invalids = 0;
        this.state.contato.forEach((p, index) => {
            if(p.titulo === this.state.titulo_contato){
                invalids++
            }
        });

        if(invalids > 0){
            return false;
        }

        return true;
    }
    checkAsked(){
        var invalids = 0;
        this.state.perguntas_adicionais.forEach((p, index) => {
            if(p.titulo === this.state.titulo_adicional){
                invalids++
            }
        });

        if(invalids > 0){
            return false;
        }

        return true;
    }
    checkOpcao(){
        var invalids = 0;
        this.state.opcao.forEach((p, index) => {
            if(p.titulo === this.state.titulo_opcao){
                invalids++
            }
        });

        if(invalids > 0){
            return false;
        }

        return true;
    }
    addContact(){
        var contacts = this.state.contato;
        if(this.state.titulo_contato.length > 0){
            if(this.checkContacts() === true){
                var new_title = {
                    titulo: this.state.titulo_contato
                };
    
                contacts.push(new_title);
                this.setState({contato: contacts, titulo_contato: '', contato_visible: false});
            }
            else{
                toast.error('O contato com esse titulo já foi adicionado');
            }
        }
    }
    addOpcao(){
        var opcao = this.state.opcao;
        if(this.state.titulo_opcao.length > 0){
            if(this.checkOpcao() === true){
                var new_title = {
                    titulo: this.state.titulo_opcao
                };
    
                opcao.push(new_title);
                this.setState({opcao, titulo_opcao: '', opcao_visible: false});
            }
            else{
                toast.error('Já existe essa opçao na lista');
            }
        }
    }

    addAsked(){
        var perguntas_adicionais = this.state.perguntas_adicionais;
        if(this.state.titulo_adicional.length > 0 && this.state.tipo !== null){
            if(this.checkAsked() === true){
                if(this.state.tipo.value === "radio"){
                    if(this.state.opcao.length === 0){
                        toast.error('É necessario adicionar as opções de resposta para perguntas de alternativa');
                        return;
                    }
                }

                var new_title = {
                    tipo: this.state.tipo.value,
                    titulo: this.state.titulo_adicional,
                    opcoes: this.state.opcao
                };

                perguntas_adicionais.push(new_title);

                this.setState({
                    perguntas_adicionais, 
                    opcao: [], 
                    tipo: null, 
                    titulo_adicional: '', 
                    titulo_opcao: '',
                    adicional_visible: false
                });
            }
            else{
                toast.error('A pergunta com esse titulo já foi adicionado');
            }
        }
        else{
            toast.error('É necessario inserir o Tipo e o título da pergunta adicional');

        }
    }
    validate_adicional = () => {
        if(this.state.tipo !== null){
            if(this.state.tipo.value === 'radio'){
                return true;
            }
            return false;
        }
        
        return false;
    }
    minimizarPergunta = (adicional_visible) => {
        this.setState({
            adicional_visible,
            titulo_adicional: '',
            tipo: null,
            opcao: []
        })
    }
    render() {
        return (
            <Modal isOpen={this.props.visible} size="lg">
                <ModalBody className="modal-body" hidden={this.state.loading}>
                    <div style={{ display: 'inline-flex' }}>
                        <h4>CRIAR PESQUISA NPS</h4>
                    </div>
                    <hr/>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Título</Label>
                                <Input 
                                    style={{borderRadius: 0}} 
                                    name="titulo"
                                    placeholder="Titulo de NPS" 
                                    value={this.state.titulo}
                                    invalid={this.state.titulo_invalid}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Descrição</Label>
                                <Input 
                                    style={{borderRadius: 0, resize: 'none', height: 140}} 
                                    type="textarea" 
                                    name="descricao"
                                    placeholder="Descrição de NPS" 
                                    value={this.state.descricao}
                                    invalid={this.state.descricao_invalid}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                        <FormGroup style={{margin: 0}}>
                            <h5 style={{margin: 0}}>&nbsp;&nbsp;Pontos de Contatos 
                            <Button 
                                onClick={() => this.isVisibleContact(!this.state.contato_visible)} 
                                outline 
                                style={{
                                    float: 'right', 
                                    padding: '3px', 
                                    borderRadius: '17px', 
                                    border: 'none'
                                }} color={this.state.contato_visible === false ? "success" : "danger" }>
                                {this.state.contato_visible === false ? 
                                    <MdAdd style={{padding: 0}} /> 
                                    : 
                                    <MdRemove style={{padding: 0}} /> }
                            </Button>
                            </h5>
                            <div style={{lineHeight: '14px', marginBottom: '8px'}}>
                                <small>&nbsp;&nbsp;&nbsp;Insira pontos da empresa a serem avaliados pelo cliente (no qual ele teve contato) </small>
                            </div>
                        </FormGroup>
                        <Collapse isOpen={this.state.contato_visible}>
                            <Card style={{marginBottom: '12px'}}>
                                <CardBody>
                                    <FormGroup>
                                        <InputGroup>
                                            <Input 
                                                placeholder="Título do contato"
                                                style={{borderRadius: 0}} 
                                                type="text" 
                                                name="titulo_contato"
                                                value={this.state.titulo_contato}
                                                onChange={this.handleChange}
                                            />
                                            <InputGroupAddon addonType="append">
                                                <Button 
                                                    onClick={() => this.addContact()}
                                                    style={{height: '37.5px'} }
                                                    color="success">
                                                        <FaCheck style={{padding: '3px'}} />
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </CardBody>
                            </Card>
                        </Collapse>
                        <FormGroup>
                            <Card>
                                <CardBody style={{padding: '15px'}}>
                                    <ul 
                                        style={{
                                            listStyle: 'none', 
                                            padding: 0, 
                                            margin: 0
                                        }}>
                                        {this.state.contato.map((p, index) => {
                                            return <li>
                                                <MdList/> {p.titulo}&nbsp;
                                                    <Button 
                                                        outline 
                                                        onClick={() => this.removeContact(index)}
                                                        style={{
                                                            padding: '0px', 
                                                            borderRadius: '17px', 
                                                            border: 'none', 
                                                            height: '25px'
                                                        }} color="danger">
                                                            <MdClear 
                                                                style={{
                                                                    padding: '3px', 
                                                                    position: 'relative', 
                                                                    top: '-1.5px'
                                                                }}/>
                                                    </Button>
                                            </li>
                                        })}
                                        {this.state.contato.length === 0 ? 'Nenhum contato' : ''}
                                    </ul>
                                </CardBody>
                            </Card>
                        </FormGroup>
                        <FormGroup style={{margin: 0}}>
                            <h5>&nbsp;&nbsp;Perguntas adicionais
                                <Button 
                                    onClick={() => 
                                        this.isVisibleAdicional(!this.state.adicional_visible)} 
                                    outline 
                                    style={{
                                        float: 'right', 
                                        padding: '3px', 
                                        borderRadius: '17px', 
                                        border: 'none'
                                    }} 
                                    color={this.state.adicional_visible === false ? "success" : "danger" }>
                                        {this.state.adicional_visible === false ? 
                                            <MdAdd style={{padding: 0}} /> 
                                            : 
                                            <MdRemove style={{padding: 0}} /> }
                                        
                                </Button>
                            </h5>
                            <div style={{lineHeight: '14px', marginBottom: '8px'}}>
                                <small>&nbsp;&nbsp;&nbsp;Insira perguntas adicionais a serem feitas para o cliente (Quando a pergunta for por alternativas, deve ser inserido as alternativas em questão) </small>
                            </div>
                        </FormGroup>
                        <div >
                            <Collapse isOpen={this.state.adicional_visible}>
                                <Card style={{marginBottom: '12px'}}>
                                    <CardBody>
                                        <FormGroup>
                                            <Select 
                                                placeholder="Tipo"
                                                value={this.state.tipo}
                                                onChange={(tipo) => this.setState({tipo})}
                                                options={tipos} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input 
                                                placeholder="Titulo"
                                                style={{borderRadius: 0}} 
                                                type="text" 
                                                name="titulo_adicional"
                                                value={this.state.titulo_adicional}
                                                onChange={this.handleChange} />
                                        </FormGroup>
                                        <FormGroup hidden={!this.validate_adicional()}>
                                            <Card>
                                                <CardBody>
                                                    <h6>Opção
                                                        <Button 
                                                            onClick={() => this.isVisibleOption(!this.state.opcao_visible)}
                                                            outline 
                                                            style={{
                                                                border: 'none',
                                                                padding: '1px', 
                                                                borderRadius: '22px',
                                                                paddingBottom: '3px'
                                                            }} 
                                                            color={this.state.opcao_visible === false ? "success" : "danger" }>
                                                                {this.state.opcao_visible === false ? 
                                                                    <MdAdd style={{padding: 0}} /> 
                                                                    : 
                                                                    <MdRemove style={{padding: 0}} /> }
                                                        </Button>
                                                    </h6>
                                                    <Collapse isOpen={this.state.opcao_visible}>
                                                        <FormGroup>
                                                            <InputGroup>
                                                                <Input 
                                                                    placeholder="Título da opção"
                                                                    style={{borderRadius: 0}} 
                                                                    type="text" 
                                                                    name="titulo_opcao"
                                                                    value={this.state.titulo_opcao}
                                                                    onChange={this.handleChange}
                                                                />
                                                                <InputGroupAddon addonType="append">
                                                                    <Button 
                                                                        onClick={() => this.addOpcao()}
                                                                        style={{height: '37.5px'} }
                                                                        color="success">
                                                                            <FaCheck style={{padding: '3px'}} />
                                                                    </Button>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Collapse>
                                                    <FormGroup>
                                                        <Card style={{border: 'none'}}>
                                                            <CardBody style={{padding: '0'}}>
                                                                <ul 
                                                                    style={{
                                                                        listStyle: 'none', 
                                                                        margin: 0, 
                                                                        padding: 0
                                                                    }}>
                                                                    {this.state.opcao.map((p) => {
                                                                        return <li>
                                                                            <CgOptions/>{p.titulo}
                                                                        </li>
                                                                    })}
                                                                    {this.state.opcao.length === 0 ? 'Nenhuma opção' : ''}
                                                                </ul>
                                                            </CardBody>
                                                        </Card>
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                            
                                        </FormGroup>
                                        <FormGroup style={{textAlign: 'right'}}>
                                            <Button onClick={() => this.addAsked()} color="success" style={{width: '100%'}}>Adicionar Pergunta</Button>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Collapse>
                            <FormGroup style={{}}>
                                <Card>
                                    <CardBody style={{padding: '15px'}}>
                                        <ul 
                                            style={{
                                                listStyle: 'none', 
                                                margin: 0, 
                                                padding: 0
                                            }}>
                                            {this.state.perguntas_adicionais.map((p, index) => {
                                                return <li>
                                                    <MdQuestionAnswer/>{p.titulo}&nbsp;
                                                    <Button 
                                                        outline 
                                                        onClick={() => this.removeAsked(index)}
                                                        style={{
                                                            padding: '0px', 
                                                            borderRadius: '17px', 
                                                            border: 'none', 
                                                            height: '25px'
                                                        }} color="danger">
                                                            <MdClear 
                                                                style={{
                                                                    padding: '3px', 
                                                                    position: 'relative', 
                                                                    top: '-1.5px'
                                                                }}/>
                                                    </Button>
                                                    <ul style={{listStyle: 'none'}}>
                                                        {p.opcoes.map((l) => {
                                                            return <li>
                                                                <GrCatalogOption /> {l.titulo}
                                                            </li>
                                                        })}
                                                    </ul>
                                                </li>
                                            })}
                                            {this.state.perguntas_adicionais.length === 0 ? 'Nenhuma pergunta adicional' : ''}
                                        </ul>
                                    </CardBody>
                                </Card>
                            </FormGroup>
                        </div>
                    </Col>
                </Row>
                </ModalBody>
                <div 
                    className="modal-body"
                    hidden={!this.state.loading}
                    style={{
                        minHeight: '114px',
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose.bind(this)}
                        onError={this.onError.bind(this)}
                    />

                </div>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="success" onClick={this.onRegister.bind(this)}>Avançar</Button>
                    <Button color="danger" onClick={this.onClose.bind(this)}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default CreateNps;