import axios from "axios";
import React, { Component } from "react";
import classnames from 'classnames';
import { MdArrowBack, MdCheck, MdClear, MdMail, MdWarning, MdAttachFile } from "react-icons/md";
import { BsLock, BsUnlock } from 'react-icons/bs';
import { IoReceiptOutline } from 'react-icons/io5';
import ClipLoader from 'react-spinners/ClipLoader';
import { Button, Card, CardBody, CardColumns, CardFooter, Col, Collapse, FormGroup, Input, Label, Nav, NavLink, Row, TabContent, TabPane } from "reactstrap";
import Dropzone from 'react-dropzone';
import styled from "styled-components";
import { ENDPOINT } from "../../auth/endpoint";
import { getPermissoes, getToken } from "../../auth/set_services";
import Select from "react-select";
import { list_prevencao_status, list_status_pagamentos } from "../../auth/listas";
import { InputMasked } from '../../components/InputText/InputMasked';
import { InputText } from "../../components/InputText/InputText";
import { toast } from 'react-toastify';
import PropagateLoader from "react-spinners/PropagateLoader";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FaClipboardList, FaCreditCard, FaListOl, FaMapMarkerAlt, FaMinus, FaMoneyBill, FaPen, FaPercent, FaPercentage, FaPlus, FaSave, FaSleigh, FaTrash } from "react-icons/fa";
import { transform } from "lodash";
import NumberFormat from "react-number-format";
import Regimes from "./Configuracao/Regimes";
import Receitas from "./Configuracao/Receitas";
import { HiViewGrid } from "react-icons/hi";
import FormasPagamento from "./Configuracao/FormasPagamento";
import Impostos from "./Configuracao/Impostos";

var CurrencyInput = require('react-currency-masked-input');




const Div = styled.div`
.nav-item{
    margin: 0
}
.nav-link{
    color: black;
    text-transform: initial;
    cursor: pointer;
    padding: 0.5rem;
    border: 1px solid #ccc;
    margin: 0.2rem 0.3rem;
    border-radius: 5px;
}
.nav-tabs .nav-item.show .nav-link, 
.nav-tabs .nav-link.active {
    background: #28a745;
    color: #fff;
}
.nav-link:hover{
    background: #70b580;
    color: #fff;
}
.nav-tabs {
    padding-bottom: 1rem;
}
.tab-pane {
    display: none;
}
.tab-pane.active {
    display: block !important;
}
    .reclamado {
        display: none;
    }
    .row {
        margin: 10px 0px;
    }
    .btn-save {
        float: right;
    }
    .btn-nota {
        margin-top: 10px;
    }
    .file-iframe {
        width: 100%;
        height: 800px; 
    }
    .btn-arquivo {
        margin-right: 10px;
        color: #fff;
        border: 0px;
    }
    .subtitle {
        font-weight: 700;
        margin-top: 20px !important;
    }
    .error-active {
        display: block;
        color: red;
    }
    .error-none {
        display: none;
    }
    .input-error {
        border: 1px solid red;
    }
    .label-error {
        color: red;
    }
    .select-error {
        border: 1px solid red;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
    }
    .card {
        border: 1px solid #ced4da;
        border-left: 5px solid #28a745;
        border-radius: 0.25rem;
        -webkit-border-radius: 0.25rem;
        -moz-border-radius: 0.25rem;
        
    }
    .card h6 {
        font-weight: 600;
        padding: 10px;
        border-radius: 3px 3px 0px 0px;
        margin: 0px;
    }
    .card-box {
        padding: 1rem;
        height: auto;
        min-height: 180px;
    }
    .card-box div {
        font-size: 14px;
    }
    .button-edit {
        position: absolute;
        bottom: 0.5rem;
        right: 1rem;
    }
    .button-remove {
        position: absolute;
        bottom: 0.5rem;
        left: 1rem;
    }
    .btn-salvar {
        float: right;
    }
    .add-address-btn {
        position: absolute;
        top: 0.5rem;
        right: 2rem;
    }
    .col-cards {
        margin-bottom: 1rem;
    }
    .btn-float {
        float: right;
    }
    .nav-link {
    padding: 1rem;
    font-size: 14px; 
}
.active.nav-link {
    background: #58d174;
    color: #fff;
}
.tab-content {
    border: 1px solid rgb(222, 226, 230);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    position: relative;
    top: 0px;
}
.nav.nav-tabs {
    border-bottom: 0px;
}
    .currency-simbol {
        color: #999b9d;
        position: absolute;
        left: 25px;
        top: 43px;
    }
    .captalize {
        text-transform: capitalize;
    }

    table tr th,
    table tr td {
        font-weight: normal;
        padding: 0.5rem 1.5rem;
        border: 1px solid #dee2e6;
    }

    table tr td { 
        font-weight: bold;
    }

    .input-erro {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ff0000;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    .select-erro {
        border: 1px solid #ff0000;
        border-radius: 0.25rem;
    }
`;

const tipoPessoaOptions = [
    { value: 'F', label: 'Pessoa Física'},
    { value: 'J', label: 'Pessoa Jurídica'},
    { value: 'E', label: 'Estrangeiro'},
];

const getId = () => {
    return window.location.pathname.replace('/fornecedor-detalhes/', '')
}

const optionsYesNo = [
    { value: '1', label: 'Sim'},
    { value: '0', label: 'Não'},
];
const tipoFornecedorOptions = [
    { value: '1', label: 'Serviços'},
    { value: '2', label: 'Produtos'},
    { value: '3', label: 'Sistemas SAAS'},
    { value: '4', label: 'Cloud'},
];

const DropArea = styled.div`
    border-style: dashed;
    border-color: #38B76E;
    padding: 1%;
    cursor: pointer;
    &:drag {
        opacity: 0.5;
    }
`;

const ButtonUpload = styled.button`
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0;
    padding-left: 7px;
    padding-right: 7px;
    top: -1px;
    position: relative;
`;

class ResumoFornecedor extends Component {
    constructor(props){
        super(props);
        this.state = {
            show_form: true,
            loading_page: false,
            loading_button: false,
            load_send_regime: false,
            load_send_imposto: false,
            add_address: false,
            add_contato: false,
            load_address: false,
            open_contato: false,
            open_add_descricao: false,
            open_add_forma_pagamento: false,
            remove_pagamento: false,
            button_add_forma: true,
            edit_pay: false,
            edit_pay_id: null,
            nome_forma: '',
            id_regime: null,
            id_categoria: null,
            id_imposto: null,
            table_categorias: true,
            id_forma_pagamento: null,

            loaded_regime: false,
            add_new_regime: false,
            add_new_imposto: false,
            list_regimes: true,
            btn_new_regime: true,
            reload_regimes: true,
            load_formas_pagamento: false,
            list_impostos: false,
            reload_impostos: false,
            btn_new_imposto: true,

            descricao: '',
            tipo: '1',
            
            apurado_options: [
                {'label':'Mensal','value':'m'},
                {'label':'Trimestral','value':'t'},
            ],
            tipo_tributacao_options: [
                {'label':'Lucro Real','value':'lr'},
                {'label':'Lucro Presumido','value':'lp'},
            ],

            tipo_taxa_select: [],
            tipo_taxa_options: [
                {value:'aliquota', label: 'Aliquota (%)'},
                {value:'fixa', label: 'Fixa (R$)'},
                {value:'aliquota_fixa', label: 'Aliquota + Fixa'},
            ],

            aplicavel_select: [],
            aplicavel_options: [
                {value:'pagamentos', label: 'Pagamentos'},
                {value:'recebimentos', label: 'Recebimentos'},
                {value:'pagamentos_recebimentos', label: 'Pagamentos e Recebimentos'},
            ],

            tipo_select: [],
            tipo_options: [],
            tipo_pagamento_options: [
                {value:'pix', label: 'PIX'},
                {value:'cartao_credito', label: 'Cartão de Crédito'},
                {value:'cartao_debito', label: 'Cartão de Débito'},
                {value:'ted', label: 'TED'},
                {value:'boleto', label: 'Boleto'},
                {value:'financiamento_carteira_digital', label: 'Financiamento carteira digital'},
            ],
            tipo_pagamento_select: [],
            categoria_despesa: '',
            categoria_despesa_select: [],
            categoria_despesa_options: [],

            grupo_dre: 1,
            grupo_dre_select: [{label: 'Não Exibir no DRE', value: 1}],
            grupo_dre_options: [
                {label: 'Não Exibir no DRE', value: 1},
                {label: 'Exibir no DRE', value: 2},
            ],
            list_receitas: [],
            list_despesas: [],
            list_receitas_e_despesas: [],
            list_fundo_perdido: [],
            list_formas_pagamento: [],


            adicionar_imposto: false,
            abater_imposto: false,
            impostos_sobre_receita: [],
            categorias_abatidas: [],
            abatimento: 100,
            regime_procentagem: '',
            regime_valor_minimo: '',
            regime_valor_maximo: '',

            activeTabConfig: "receitas_despesas",
            activeTab: "1",
            list_tabs_config: [
                { name: <><HiViewGrid style={{ padding: '2px' }}/> Categorias</>, active: 'receitas_despesas'},
                { name: <><FaCreditCard /> Formas de Pagamento</>, active: 'forma_pagamento'},
                { name: <><FaListOl /> Regimes</>, active: 'regimes'},
                { name: <><FaPercentage /> Impostos</>, active: 'imposto_fixo'},  
            ],
            list_tabs: [
                { name: <><FaPlus /> Receitas</>, active: '1', class: 'plus'},
                { name: <><FaMinus /> Despesas</>, active: '2', class: 'minus' },
                { name: <><FaPlus /> <FaMinus /> Receitas e Despesas</>, active: '3', class: 'what' },
                { name: <><FaTrash /> Fundo Perdido</>, active: '4', class: 'minus' },
            ],
        }
    }

    handletoastSuccessMessage = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    handletoastMessage = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    onEditPay = (idEdit) => {
        let state = this.state;
        state.list_formas_pagamento.map((field, i)=>{
            if (idEdit == field.id) {
                state.list_formas_pagamento[i].edit = true;
                state.edit_pay = true;
                state.edit_pay_id = idEdit;
                state.nome_forma = field.tipo_pagamento.replace('_', ' ');
                state.open_add_forma_pagamento = true;
                state.button_add_forma = false;

                state.tipo_pagamento = field.tipo_pagamento;
                state.aplicavel = field.aplicavel;
                state.prazo_recebimento = field.prazo_recebimento;
                state.tipo_taxa = field.tipo_taxa;
                state.taxa_aliquota = field.taxa_aliquota;
                state.taxa_fixa = field.taxa_fixa;
                
                this.state.tipo_pagamento_options.map((o, c) => {
                    if (o.value == state.tipo_pagamento) {
                        state.tipo_pagamento_select = o;
                    }
                })
                
                this.state.aplicavel_options.map((o, c) => {
                    if (o.value == state.aplicavel) {
                        state.aplicavel_select = o;
                    }
                })                
                this.state.tipo_taxa_options.map((o, c) => {
                    if (o.value == state.tipo_taxa) {
                        state.tipo_taxa_select = o;
                    }
                })
            }
        })

        this.setState({...state})
    }
    
    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    handleChangeTipo = (e) => {
        this.setState({e});
        const name = e.label;
        const value= e.value;

        this.setState({ tipo: value });
        this.setState({ tipo_select: e });
    }

    handleChangeGrupoDRE = (e) => {
        this.setState({e});
        const name = e.label;
        const value= e.value;

        this.setState({ grupo_dre: value });
        this.setState({ grupo_dre_select: e });
    }

    componentDidMount = () => {
        this.handleLoadData();
        //this.handleLoadFormasPagamentoData();
    }

    handleLoadFormasPagamentoData = () => {
        
        var url = ENDPOINT + "financeiro/formapagamento";

        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
    
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            
            let listFormasPagamento = [];

            p.data.map((field, i) => {
                field.edit = false;
                listFormasPagamento.push(field)
            })
            
            this.setState({list_formas_pagamento: listFormasPagamento})
        })
        .catch((error) => {
            this.setState({loading_seller_data: false})
            toast.error('Ocorreu um erro no servidor!');
        })

    }

    handleLoadData = () => {
        var url = ENDPOINT + "financeiro/categorias/tipo";
        
        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
    
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {

            // p.data.map((item, key) =>{
            //     console.log(item);
            //     console.log(item);
            // })

            //return false;

            if (p.success) {
                let state = this.state;
                let categorias = [];
                let subcategoriasreceitas = [];
                let subcategoriasdespesas = [];
                let subcategoriasreceitasedespesas = [];
                let subcategoriasfundoperdido = [];
                
                p.data.categorias.map((field, i) => {
                    categorias[i] = {
                        label: field.nome,
                        value: field.id,
                    };
                })


                // p.data.subcategories.map((field, i) => {
                //     if(field.categoria == '1') {
                //         subcategoriasreceitas[i] = {
                //             nome: field.nome,
                //             label: field.nome,
                //             value: field.id,
                //         };
                //     }
                //     if(field.categoria == '2') {
                //         subcategoriasdespesas[i] = {
                //             nome: field.nome,
                //             label: field.nome,
                //             value: field.id,
                //         };
                //     }                    
                //     if(field.categoria == '3') {
                //         subcategoriasreceitasedespesas[i] = {
                //             nome: field.nome,
                //             label: field.nome,
                //             value: field.id,
                //         };
                //     }
                //     if(field.categoria == '4') {
                //         subcategoriasfundoperdido[i] = {
                //             nome: field.nome,
                //             label: field.nome,
                //             value: field.id,
                //         };
                //     }
                // })

                state.tipo_options = categorias;
                state.list_receitas = subcategoriasreceitas;
                state.list_despesas = subcategoriasdespesas;
                state.list_receitas_e_despesas = subcategoriasreceitasedespesas;
                state.list_fundo_perdido = subcategoriasfundoperdido;

                this.setState({...state})
            }
        })
        .catch((error) => {
            this.setState({loading_seller_data: false})
            toast.error('Ocorreu um erro no servidor!');
        })
    }

    handleSendFormSubCategorie = () => {

        if (this.state.descricao === '' || this.state.descricao === null) {
            toast.error('Adicione uma descrição!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })

            return false;
        } else if (this.state.tipo === '' || this.state.tipo === null) {
            toast.error('Selecione um tipo!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })

            return false;
        }

        let rawBody = {
            id_categoria: this.state.id_categoria,
            nome: this.state.descricao,
            financeiro_financeiro_categorias_receitas_despesas_id: this.state.tipo,
            grupo_dre: this.state.grupo_dre,
        };

        this.setState({table_categorias: false})

        var url = ENDPOINT + "financeiro/subcategoria";

        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        myHeaders.append('Content-Type', 'application/json');

        var myInit = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(rawBody),
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {

            if (p.success) {

                let state = this.state;
                let newData = [];

                newData['nome'] = p.data.nome;
                newData['status'] = p.data.status;

                if (this.state.tipo == '1') {
                    state.list_receitas.push(newData)
                }
                if (this.state.tipo == '2') {
                    state.list_despesas.push(newData)
                }

                state.open_add_descricao = false;
                state.descricao = '';
                state.tipo = '';
                state.tipo_select = [];
                state.table_categorias = true;

                this.setState({...state})
                this.handletoastSuccessMessage('Adicionado com sucesso!')
            } else {
                this.handletoastMessage('Falha ao adicionar!')
            }
        })
        .catch((error) => {

            this.setState({table_categorias: true})
            //this.setState({loading_seller_data: false})
            toast.error('Ocorreu um erro no servidor!');
        
        })
    }

    handleChangeReceita = (rawBody) => {
        var url = ENDPOINT + "financeiro/subcategoria";
        
        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        myHeaders.append('Content-Type', 'application/json');

        var myInit = {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify(rawBody),
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            if (p.success) {
                toast.success('Atualizado com sucesso!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                
            }
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!');
        })

    }

    handleSendFormaPagamento = () => {
        var url = ENDPOINT + "financeiro/formapagamento";

        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        myHeaders.append('Content-Type', 'application/json');

        var myInit = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(this.state),
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            if (p.success) {
                this.setState({
                    edit_pay: false,
                    edit_pay_id: false,
                    tipo_pagamento: '',
                    tipo_pagamento_select: [],
                    aplicavel: '',
                    aplicavel_select: [],
                    prazo_recebimento: '',
                    tipo_taxa: '',
                    tipo_taxa_select: [],
                    taxa_aliquota: '',
                    taxa_fixa: '',
                    open_add_forma_pagamento: false,
                    load_formas_pagamento: true,
                    button_add_forma: true,
                })

                toast.success('Salvo com sucesso!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })

                //this.handleLoadFormasPagamentoData()
            }
        })
        .catch(function (error) {
            toast.error('Ocorreu um erro no servidor!');

            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              //console.log(error.response.status); // status code
              //console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              //console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
        })
    }

    handleSendFormImposto = () => {
        var url = ENDPOINT + "financeiro/impostos/enviar";

        this.setState({
            load_send_imposto: true,
            reload_impostos: false
        })

        var myHeaders = new Headers();        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        myHeaders.append('Content-Type', 'application/json');

        var myInit = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(this.state),
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            let data = p.data;

            if (p.success == true) {
                let state = this.state;

                state.load_send_imposto = false;
                state.add_new_imposto = false;
                state.reload_impostos = true;
                state.btn_new_imposto = true;
                state.nome_imposto = '';
                state.apurado_select = [];
                state.tipo_tributacao_select = [];


                this.setState({...state})
            } else {
                this.setState({
                    load_send_imposto: false, 
                })
    
                toast.error('Ocorreu um erro no servidor!');
            }
        })
        .catch(function (error) {
            this.setState({
                load_send_imposto: false, 
            })

            toast.error('Ocorreu um erro no servidor!');

            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              //console.log(error.response.status); // status code
              //console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              //console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
        })
    }

    handleSendFormRegime = () => {
        var url = ENDPOINT + "financeiro/regime/enviar";

        this.setState({
            load_send_regime: true,
            reload_regimes: false
        })

        var myHeaders = new Headers();        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        myHeaders.append('Content-Type', 'application/json');

        var myInit = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(this.state),
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            let data = p.data;

            if (p.success == true) {
                this.setState({
                    nome_regime: '',
                    impostos_sobre_receita: [],
                    categorias_abatidas: [],
                    load_send_regime: false,
                    list_regimes: true,
                    add_new_regime: false,
                    btn_new_regime: true,
                    reload_regimes: true,
                })
            }
        })
        .catch(function (error) {
            this.setState({
                load_send_regime: false, 
                reload_regimes: true
            })

            toast.error('Ocorreu um erro no servidor!');

            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              //console.log(error.response.status); // status code
              //console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              //console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
        })
    }

    handleOpenEditFormaPagamento = (formaPagamento) => {
        // tipo_pagamento_select tipo_pagamento 
        // aplicavel_select aplicavel 
        // tipo_taxa_select tipo_taxa 

        let state = this.state;

        state.id_forma_pagamento = formaPagamento.id;
        state.prazo_recebimento = formaPagamento.prazo_recebimento;
        state.taxa_fixa = formaPagamento.taxa_fixa;
        state.taxa_aliquota = formaPagamento.taxa_aliquota;
        state.open_add_forma_pagamento = true;
        state.load_formas_pagamento = false;

        this.state.tipo_pagamento_options.map((field, i) => {
            if (field.value == formaPagamento.tipo_pagamento) {
                state.tipo_pagamento_select = field;
                state.tipo_pagamento = formaPagamento.tipo_pagamento;
            }
        })
        this.state.aplicavel_options.map((field, i) => {
            if (field.value == formaPagamento.aplicavel) {
                state.aplicavel_select = field;
                state.aplicavel = formaPagamento.aplicavel;
            }
        })
        this.state.tipo_taxa_options.map((field, i) => {
            if (field.value == formaPagamento.tipo_taxa) {
                state.tipo_taxa_select = field;
                state.tipo_taxa = formaPagamento.tipo_taxa;
            }
        })

        this.setState({...state})
    }

    handleEditFormaPagamento = (idPagamento) => {
        var url = ENDPOINT + "financeiro/formapagamento";

        this.setState({edit_pay: true, edit_pay_id: idPagamento})

        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        myHeaders.append('Content-Type', 'application/json');

        var myInit = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(this.state),
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            if (p.success) {
                this.setState({
                    edit_pay: false,
                    edit_pay_id: false,
                    tipo_pagamento: '',
                    tipo_pagamento_select: [],
                    aplicavel: '',
                    aplicavel_select: [],
                    prazo_recebimento: '',
                    tipo_taxa: '',
                    tipo_taxa_select: [],
                    taxa_aliquota: '',
                    taxa_fixa: '',
                    open_add_forma_pagamento: false,
                    button_add_forma: true,
                })

                toast.success('Salvo com sucesso!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })

                this.handleLoadFormasPagamentoData()
            }
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!');
        })

    }

    handleSendRemoveFormaPagamento = (pagamento) => {
        if (window.confirm("Deseja remover ?")) {
            var url = ENDPOINT + "financeiro/formapagamento/remover";

            this.setState({load_formas_pagamento: false})

            var myHeaders = new Headers();
            myHeaders.append('Authorization', 'Bearer ' + getToken());
            myHeaders.append('Content-Type', 'application/json');
    
            var myInit = {
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify(pagamento),
                mode: 'cors',
                cache: 'default'
            };
    
            fetch(url, myInit)
            .then(response => response.json())
            .then((p) => {
                this.setState({load_formas_pagamento: true})

                if (p.success) {
                    toast.success('Removido com sucesso!', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
    
                    this.handleLoadFormasPagamentoData()
                }
            })
            .catch((error) => {
                this.setState({load_formas_pagamento: true})
                toast.error('Ocorreu um erro no servidor!');
            })
        }

    }

    handleAddImposto = () => {
        let state = this.state;
        let erro = false;

        state.erro_regime_procentagem = false;
        state.erro_regime_valor_minimo = false;
        state.erro_regime_valor_maximo = false;

        this.setState({...state})
        
        if (this.state.regime_procentagem == 'null' || this.state.regime_procentagem == '') {
            this.setState({erro_regime_procentagem: true})
            erro = true;
        }
        if (this.state.regime_valor_minimo == 'null' || this.state.regime_valor_minimo == '') {
            this.setState({erro_regime_valor_minimo: true})
            erro = true;
        }       
        if (this.state.regime_valor_maximo == 'null' || this.state.regime_valor_maximo == '') {
            this.setState({erro_regime_valor_maximo: true})
            erro = true;
        }
        
        if (erro) {
            return false;
        }

        state.impostos_sobre_receita.push({
            'aliquota': this.state.regime_procentagem,
            'valor_minimo': (this.state.regime_valor_minimo.replace(/\./g, "")).replace(/\,/g, "."),
            'valor_maximo': (this.state.regime_valor_maximo.replace(/\./g, "")).replace(/\,/g, "."),
        })

        state.regime_procentagem = '';
        state.regime_valor_minimo = '';
        state.regime_valor_maximo = '';
        state.adicionar_imposto = false;

        this.setState({...state})

        if (this.state.id_regime !== undefined && this.state.id_regime !== null) {
            this.handleSendFormRegime()
        }
    }

    handleAddAbatimento = () => {
        let state = this.state;
        let erro = false;


        state.erro_categoria = false;
        state.erro_abatimento = false;

        this.setState({...state})

        if (this.state.categoria_despesa == 'null' || this.state.categoria_despesa == '') {
            this.setState({erro_categoria: true})
            erro = true;
        }
        if (this.state.abatimento == 'null' || this.state.abatimento == '') {
            this.setState({erro_abatimento: true})
            erro = true;
        }

        if(erro) {
            return false;
        }


        state.categorias_abatidas.push({
            'id': null,
            'categoria_id': this.state.categoria_despesa,
            'nome': this.state.categoria_despesa_select.label,
            'abatimento': this.state.abatimento,
        })

        state.categoria_despesa = null;
        state.categoria_despesa_select = [];
        state.abatimento = 100;
        state.abater_imposto = false;

        this.setState({...state})   

        if (this.state.id_regime !== undefined && this.state.id_regime !== null) {
            this.handleSendFormRegime()
        }
    }

    handleRemoveImposto = (idImposto, position) => {
        if (window.confirm("Deseja remover o imposto ?")) {
            let state = this.state;

            if (this.state.id_regime !== undefined && this.state.id_regime !== null && idImposto !== undefined && idImposto !== null) {
                var url = ENDPOINT + 'financeiro/regime/' +this.state.id_regime+ '/imposto/' + idImposto;

                var myHeaders = new Headers();
                myHeaders.append('Authorization', 'Bearer ' + getToken());
                myHeaders.append('Content-Type', 'application/json');
    
                var myInit = {
                    method: 'DELETE',
                    headers: myHeaders,
                    mode: 'cors',
                    cache: 'default'
                };
    
                fetch(url, myInit)
                .then(response => response.json())
                .then((p) => {

                    state.impostos_sobre_receita.splice(position, 1)
                    this.setState({...state})
                })
                .catch((error) => {
                    toast.error('Ocorreu um erro no servidor!');
                })
            } else {
                state.impostos_sobre_receita.splice(position, 1)
    
                this.setState({...state})
            }
        }
    }

    handleRemoveAbatimento = (idAbatimento, position) => {
        if (window.confirm("Deseja remover o abatimento ?")) {
            let state = this.state;

            if (this.state.id_regime !== undefined && this.state.id_regime !== null && idAbatimento !== undefined && idAbatimento !== null) {
                var url = ENDPOINT + 'financeiro/regime/' +this.state.id_regime+ '/abatimento/' + idAbatimento;

                var myHeaders = new Headers();
                myHeaders.append('Authorization', 'Bearer ' + getToken());
                myHeaders.append('Content-Type', 'application/json');

                var myInit = {
                    method: 'DELETE',
                    headers: myHeaders,
                    mode: 'cors',
                    cache: 'default'
                };

                fetch(url, myInit)
                .then(response => response.json())
                .then((p) => {
                    state.categorias_abatidas.splice(position, 1)
                    this.setState({...state})
                })
                .catch((error) => {
                    toast.error('Ocorreu um erro no servidor!');
                })
            } else {
                state.categorias_abatidas.splice(position, 1)

                this.setState({...state})
            }
        }
    }

    handleEditImposto = (idImposto) => {
        var url = ENDPOINT + "financeiro/imposto/" + idImposto;

        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        myHeaders.append('Content-Type', 'application/json');

        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
           
            let data = p.data;
            let state = this.state;

            state.id_imposto = data.id;
            state.nome_imposto = data.nome;

            this.state.apurado_options.map((ap, i) => {
                if (ap.value == data.apurado) {
                    state.apurado_select = ap;
                }
            })

            this.state.tipo_tributacao_options.map((tp, i) => {
                if (tp.value == data.tipo_tributacao) {
                    state.tipo_tributacao_select = tp;
                }
            })
            
            state.btn_new_imposto = false;
            state.reload_impostos = false;
            state.add_new_imposto = true;

            this.setState({...state})
            
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!');
        })
    }

    handleEditRegime = (idRegime) => {
        var url = ENDPOINT + "financeiro/regime/" + idRegime;

        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        myHeaders.append('Content-Type', 'application/json');

        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            let data = p.data;
            let state = this.state;

            state.id_regime = data.id;
            state.nome_regime = data.nome;
            state.impostos_sobre_receita = data.impostos;
            state.categorias_abatidas = data.abatimentos;
            
            state.btn_new_regime = false;
            state.list_regimes = false;
            state.add_new_regime = true;

            this.setState({...state})
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!');
        })
    }

    handleEditCategoria = (idCategoria) => {
        var url = ENDPOINT + "financeiro/subcategoria/" + idCategoria;
        
        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        myHeaders.append('Content-Type', 'application/json');

        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            let data = p.data;
            let state = this.state;

            state.id_categoria = data.id;
            state.descricao = data.nome;
            state.open_add_descricao = true;
            state.table_categorias = false;

            this.state.tipo_options.map((tipo, i) => {
                if (tipo.value == data.tipo) {
                    state.tipo = tipo.value;
                    state.tipo_select = tipo;
                }
            })

            this.state.grupo_dre_options.map((dre, i) => {
                if (dre.value == data.grupo_dre) {
                    state.grupo_dre = dre.value;
                    state.grupo_dre_select = dre;
                }
            })

            this.setState({...state})
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!');
        })

        this.setState({open_add_descricao: false})
    }

    handleRemoveCategoria = (idCategoria) => {
        if (window.confirm("Deseja remover ?")) {
            this.setState({table_categorias: false})
            var url = ENDPOINT + "financeiro/subcategoria/" + idCategoria;

            var myHeaders = new Headers();
            myHeaders.append('Authorization', 'Bearer ' + getToken());
            myHeaders.append('Content-Type', 'application/json');

            var myInit = {
                method: 'DELETE',
                headers: myHeaders,
                mode: 'cors',
                cache: 'default'
            };

            fetch(url, myInit)
            .then(response => response.json())
            .then((p) => {
                let data = p.data;

                if (p.success == true) {
                    this.handletoastSuccessMessage('Removido com sucesso!')
                } else {
                    this.handletoastMessage('Ocorreu um erro ao remover!');
                }

                this.setState({table_categorias: true})
            })
            .catch((error) => {
                toast.error('Ocorreu um erro no servidor!');
            })
        }
    }

    handleLoadRegimes = () => {
        this.setState({loaded_regime: true});
        var url = ENDPOINT + "financeiro/categorias/despesas";
        
        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        myHeaders.append('Content-Type', 'application/json');

        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            let data = p.data;
            let state = this.state;

            state.categoria_despesa_options = data;
            this.setState({...state})
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!');
        })
    }

    handleCancelImposto = () => {
        this.setState({
            add_new_imposto: false,
            reload_impostos: true,
            btn_new_imposto: true,
            id_imposto: null,
            nome_imposto: '',
            apurado_select: [],
            tipo_tributacao_select: [],
        })
    }

    handleSelectTab = () => {
        if (this.state.activeTabConfig == 'forma_pagamento' && !this.state.load_formas_pagamento) {
            this.setState({load_formas_pagamento: true})
        }
        if (this.state.activeTabConfig == 'regimes' && !this.state.loaded_regime) {
            this.setState({loaded_regime: true})
            this.handleLoadRegimes()
        }
    }

    render(){

        const NavsConfig = () => {
            return <Nav tabs>
                {this.state.list_tabs_config.map((p, index) => {
                    let ClassNames = classnames({ active: this.state.activeTabConfig === p.active });
                    return <NavLink
                        key={index}
                        className={ClassNames}
                        onClick={() => { this.setState({ activeTabConfig: p.active }); }}>
                        <span>{p.name}</span>
                    </NavLink>
                })}
            </Nav>
        }

        const Navs = () => {
            return <Nav tabs>
                    {this.state.list_tabs.map((p, index) => {
                        let ClassNames = classnames({ active: this.state.activeTab === p.active });
                        return <NavLink
                            key={'sub-navs-' + index}
                            className={ClassNames + ' ' + p.class}
                            onClick={() => { this.setState({ activeTab: p.active }); }}>
                            <span>{p.name}</span>
                        </NavLink>
                    })}
                </Nav>
        }

        return(
            <Div>
                <h3>Configuração financeiro</h3><hr />
                <Row>
                    <Col md={12}>
                        <NavsConfig />
                        <TabContent activeTab={this.state.activeTabConfig} onSelect={this.handleSelectTab()}>
                            <TabPane tabId={'receitas_despesas'}>
                                {/* <Row hidden={!this.state.loading_page}>
                                    <Col md={12}>
                                        <center>
                                            <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                                        </center>
                                        <br/>
                                    </Col>
                                </Row> */}
                                <Div hidden={!this.state.show_form}>
                                    <Row hidden={!this.state.loading_seller_data}>
                                        <Col md={12}>
                                            <center>
                                                <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                                            </center>
                                            <br/>
                                        </Col>
                                    </Row> 
                                    <Row>
                                        <Col md={12}>
                                            <h5>
                                                <Button 
                                                    className="btn btn-success btn-nota"
                                                    onClick={(ev) =>{
                                                        this.setState({
                                                            table_categorias: !this.state.table_categorias,
                                                            open_add_descricao: !this.state.open_add_descricao
                                                        })}}>
                                                    <FaPlus /> Adicionar Categoria
                                                </Button>
                                            </h5><hr/>
                                            <Collapse isOpen={this.state.open_add_descricao}>
                                                <Row>
                                                    <Col md={6}>
                                                        <label htmlFor="descricao">Descrição</label>
                                                        <Input
                                                            name="descricao"
                                                            placeholder="Nome para identificar"
                                                            value={this.state.descricao}
                                                            type="text"
                                                            onChange={this.handleInputChange}
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <label htmlFor="tipo">Tipo</label>
                                                        <Select
                                                            name="tipo"
                                                            placeholder="Selecione..."
                                                            options={this.state.tipo_options}
                                                            onChange={this.handleChangeTipo}
                                                            value={this.state.tipo_select}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <label htmlFor="grupo_dre">Grupo de DRE</label>
                                                        <Select
                                                            name="grupo_dre"
                                                            placeholder="Selecione..."
                                                            options={this.state.grupo_dre_options}
                                                            onChange={this.handleChangeGrupoDRE}
                                                            value={this.state.grupo_dre_select}
                                                        />
                                                    </Col> 
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
            
            
                                                        <Button 
                                                            color="danger"
                                                            outline
                                                            onClick={(ev) =>{
                                                                this.setState({
                                                                    table_categorias: true,
                                                                    open_add_descricao: false
                                                                })
                                                            }}
                                                            >
                                                                Cancelar
                                                        </Button>
            
                                                        <Button
                                                            className="btn-salvar"
                                                            color="success"
                                                            outline
                                                            onClick={this.handleSendFormSubCategorie}
                                                            >
                                                                Salvar
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Collapse>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            {this.state.table_categorias === true ?
                                                <Receitas 
                                                state={this.state}
                                                editCategoria={this.handleEditCategoria}
                                                removeCategoria={this.handleRemoveCategoria}
                                                />
                                            :
                                                <></>
                                            }
            
                                        </Col>
                                    </Row>
                                </Div>
                            </TabPane>                            
                            <TabPane tabId={'forma_pagamento'}>
                                <Row>
                                    <Col md={12}>
                                            <h5 style={{textTransform: 'capitalize'}}>
                                                {this.state.button_add_forma ?
                                                    <Button 
                                                        className="btn btn-success btn-nota"
                                                        onClick={(ev) =>{this.setState({open_add_forma_pagamento: !this.state.open_add_forma_pagamento, load_formas_pagamento: false})}}>
                                                        <FaPlus /> Adicionar Forma de pagamento
                                                    </Button>
                                                :
                                                    'Editar ' + this.state.nome_forma
                                                }
            
                                            </h5>
                                            <Collapse isOpen={this.state.open_add_forma_pagamento}>
                                                <Row>
                                                    <Col md={6}>
                                                        <label htmlFor="tipo_pagamento">Tipo de pagamento</label>
                                                        <Select
                                                            name="tipo_pagamento"
                                                            placeholder="Selecione..."
                                                            options={this.state.tipo_pagamento_options}
                                                            onChange={(tipo_pagamento_select) => {
                                                                this.setState({tipo_pagamento_select})
                                                                this.setState({tipo_pagamento: tipo_pagamento_select.value})
                                                            }}
                                                            value={this.state.tipo_pagamento_select}
                                                        />
                                                    </Col>  
                                                    <Col md={6}>
                                                        <label htmlFor="aplica">Aplicável à</label><br/>
                                                        <Select
                                                            name="aplicavel"
                                                            placeholder="Selecione..."
                                                            options={this.state.aplicavel_options}
                                                            onChange={(aplicavel_select) => {
                                                                this.setState({aplicavel_select})
                                                                this.setState({aplicavel: aplicavel_select.value})
                                                            }}
                                                            value={this.state.aplicavel_select}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <label htmlFor="prazo_recebimento">Prazo de Recebimento</label>
                                                        <Input
                                                            name="prazo_recebimento"
                                                            placeholder=""
                                                            style={{height: '40px'}}
                                                            value={this.state.prazo_recebimento}
                                                            type="number"
                                                            onChange={this.handleInputChange} />
                                                    </Col> 
                                                    <Col md={6}>
                                                        <label htmlFor="tipo_taxa">Tipo da taxa</label><br/>
                                                        <Select
                                                            name="tipo_taxa"
                                                            placeholder="Selecione..."
                                                            options={this.state.tipo_taxa_options}
                                                            onChange={(tipo_taxa_select) => {
                                                                this.setState({tipo_taxa_select})
                                                                this.setState({tipo_taxa: tipo_taxa_select.value})
                                                            }}
                                                            value={this.state.tipo_taxa_select}
                                                        />
                                                    </Col>
                                                </Row>    
            
            
                                                {this.state.tipo_taxa == 'aliquota_fixa' ?
                                                    <Row>
                                                        <Col md={6}>
                                                            <label htmlFor="taxa_aliquota">Valor Aliquota</label>
                                                            <Input
                                                                name="taxa_aliquota"
                                                                placeholder="%"
                                                                value={this.state.taxa_aliquota}
                                                                type="text"
                                                                onChange={this.handleInputChange}
                                                                onKeyUp={this.handleInputChange}
                                                                onPress={this.handleInputChange}
                                                                />
                                                        </Col>
                                                        <Col md={6}>
                                                            <label htmlFor="taxa_fixa">Taxa Fixa</label>
                                                            <Input
                                                                name="taxa_fixa"
                                                                placeholder="R$"
                                                                value={this.state.taxa_fixa}
                                                                type="text"
                                                                onChange={this.handleInputChange}
                                                                onKeyUp={this.handleInputChange}
                                                                onPress={this.handleInputChange}
                                                                />
            
                                                        </Col> 
                                                    </Row>
                                                :
                                                <></>
                                                }
            
                                                {this.state.tipo_taxa == 'aliquota'?
                                                    <Row>
                                                        <Col md={6}>
                                                            <label htmlFor="taxa_aliquota">Valor Aliquota</label>
                                                            <Input
                                                                name="taxa_aliquota"
                                                                placeholder="%"
                                                                value={this.state.taxa_aliquota}
                                                                type="text"
                                                                onChange={this.handleInputChange}
                                                                onKeyUp={this.handleInputChange}
                                                                onPress={this.handleInputChange}
                                                                />
                                                        </Col>
                                                    </Row>
                                                :
                                                <></>
                                                }
            
                                                    
                                                {this.state.tipo_taxa == 'fixa' ?
                                                    <Row>
                                                        <Col md={6}>
                                                            <label htmlFor="taxa_fixa">Taxa Fixa</label>
                                                            <Input
                                                                name="taxa_fixa"
                                                                placeholder="R$"
                                                                value={this.state.taxa_fixa}
                                                                type="text"
                                                                onChange={this.handleInputChange}
                                                                onKeyUp={this.handleInputChange}
                                                                onPress={this.handleInputChange}
                                                                />
            
                                                        </Col>
                                                    </Row>
                                                :
                                                <></>
                                                }
            
                                                <Row>
                                                    <Col md={12}>
                                                        <Button 
                                                            color="success"
                                                            outline
                                                            style={{float: 'right'}}
                                                            onClick={(ev) =>{
                                                                if (this.state.id_forma_pagamento) {
                                                                    this.handleEditFormaPagamento(this.state.id_forma_pagamento)
                                                                } else {
                                                                    this.handleSendFormaPagamento()
                                                                }
                                                               
                                                            }}
                                                            >
                                                            Salvar
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Collapse>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                            {this.state.load_formas_pagamento === true ?
                                                <FormasPagamento 
                                                    options={this.state}
                                                    editFormaPagamento={this.handleOpenEditFormaPagamento}
                                                    removeFormaPagamento={this.handleSendRemoveFormaPagamento}
                                                    // tipoPagamentoOptions={this.state.tipo_pagamento_options}
                                                    // aplicavelOptions={this.state.aplicavel_options}
                                                    />
                                            :
                                                <></>
                                            }
                                            
            
                                    </Col>
                                </Row>
                            </TabPane>

                            <TabPane tabId={'regimes'}>
                                {this.state.loaded_regime === true ?
                                    <>
                                        <Row>
                                            <Col md={12}>
                                                <Button 
                                                    className="btn btn-success btn-nota"
                                                    hidden={!this.state.btn_new_regime}
                                                    onClick={(ev) => {
                                                        this.setState({
                                                            btn_new_regime: false,
                                                            add_new_regime: true,
                                                            list_regimes: false
                                                        })
                                                    }}>
                                                    <FaPlus /> Adicionar Regime
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Collapse isOpen={this.state.add_new_regime}> 
                                        <Row>
                                            <Col md={12}>
                                                <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
            
                                                    <Row>
                                                        <Col md={12}>
                                                            <h5>
                                                                {this.state.id_regime !== null ?
                                                                'Editar: ' + this.state.nome_regime
                                                                :
                                                                'Cadastro de Regime'
                                                                }
                                                            </h5><hr />
                                                        </Col>
                                                    </Row>
            
                                                    <Row>
                                                        <Col md={12}>
                                                            <label htmlFor="nome_regime">Nome do Regime</label>
                                                            <Input
                                                                name="nome_regime"
                                                                placeholder=""
                                                                value={this.state.nome_regime}
                                                                type="text"
                                                                onChange={this.handleInputChange}
                                                                />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                                                <h5>
                                                                    Impostos sobre Receita Bruta
                                                                    <Button 
                                                                        className="btn btn-success btn-nota"
                                                                        hidden={this.state.adicionar_imposto}
                                                                        style={{float: 'right',position: 'relative',top: '-15px'}}
                                                                        onClick={(ev) => {
                                                                            this.setState({adicionar_imposto: !this.state.adicionar_imposto})
                                                                        }}>
                                                                        <FaPlus /> Adicionar
                                                                    </Button>
            
                                                                </h5>
            
                                                                <Collapse isOpen={this.state.adicionar_imposto}>                                            
                                                                    <Row>
                                                                        <Col md={5}>
                                                                            <label htmlFor="regime_valor_minimo">Valor Mínimo</label>
                                                                            <Input
                                                                                name="regime_valor_minimo"
                                                                                placeholder="0"
                                                                                className={this.state.erro_regime_valor_minimo ? 'input-erro' : ''}
                                                                                value={this.state.regime_valor_minimo}
                                                                                type="text"
                                                                                onChange={this.handleInputChange}
                                                                                />
                                                                        </Col>
                                                                        <Col md={5}>
                                                                            <label htmlFor="regime_valor_maximo">Valor Máximo</label>
                                                                            <Input
                                                                                name="regime_valor_maximo"
                                                                                placeholder="180.000,00"
                                                                                className={this.state.erro_regime_valor_maximo ? 'input-erro' : ''}
                                                                                value={this.state.regime_valor_maximo}
                                                                                type="text"
                                                                                onChange={this.handleInputChange}
                                                                                />
                                                                        </Col>
                                                                        <Col md={2}>
                                                                            <label htmlFor="regime_procentagem">Alíquota</label>
                                                                            <Input
                                                                                name="regime_procentagem"
                                                                                placeholder="11,2"
                                                                                className={this.state.erro_regime_procentagem ? 'input-erro' : ''}
                                                                                value={this.state.regime_procentagem}
                                                                                type="text"
                                                                                onChange={this.handleInputChange}
                                                                                />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col md={12}>
                                                                            <Button 
                                                                                className="btn btn-success btn-nota"
                                                                                style={{float: 'right'}}
                                                                                onClick={this.handleAddImposto}>
                                                                                <FaSave /> Salvar
                                                                            </Button>  
                                                                        </Col>
                                                                    </Row>
                                                                </Collapse>
                                                                
                                                                <hr />
            
            
                                                                {this.state.impostos_sobre_receita !== undefined && this.state.impostos_sobre_receita.length > 0 ?
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Receita Bruta Total</th>
                                                                                <th>Alíquota</th>
                                                                                <th>Remover</th>
                                                                            </tr>
                                                                        </thead>                                                                
                                                                        
                                                                        <tbody>
                                                                            {this.state.impostos_sobre_receita.map((imposto, i)=>{
                                                                                let valorMaximo = parseFloat(imposto.valor_maximo).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
                                                                                let valorMinimo = parseFloat(imposto.valor_minimo).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
            
            
                                                                                return(
                                                                                    <tr>
                                                                                        <td>
                                                                                            De&nbsp;
                                                                                            {valorMinimo}
                                                                                            &nbsp;
                                                                                            Até&nbsp;
                                                                                            {valorMaximo}
                                                                                        </td>
                                                                                        <td>
                                                                                            {imposto.aliquota}%
                                                                                        </td>
                                                                                        <td>
                                                                                            <Button
                                                                                                outline
                                                                                                className='btn-edit'
                                                                                                style={{ padding: '0.5rem', marginLeft: 15, border: 0, fontWeight: '700'}}
                                                                                                onClick={()=>{
                                                                                                    this.handleRemoveImposto(imposto.id, i)
                                                                                                }}
                                                                                                >
                                                                                                <FaTrash style={{padding: '0'}}/>
                                                                                            </Button>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }) }
                                                                        </tbody>
                                                                    </table>
                                                                :
                                                                    <></>
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                                                <h5>
                                                                    Categorias Abatidas do Imposto
                                                                    <Button 
                                                                        className="btn btn-success btn-nota"
                                                                        hidden={this.state.abater_imposto}
                                                                        style={{float: 'right',position: 'relative',top: '-15px'}}
                                                                        onClick={(ev) =>{
                                                                            this.setState({abater_imposto: !this.state.abater_imposto})
                                                                        }}>
                                                                        <FaPlus /> Adicionar
                                                                    </Button>
                                                                </h5>
            
                                                                <Collapse isOpen={this.state.abater_imposto}>
                                                                    <Row>
                                                                        <Col md={8}>
                                                                            <label>Categorias de despesa</label>
                                                                            <Select
                                                                                name="categoria_despesa"
                                                                                placeholder="Selecione"
                                                                                className={this.state.erro_categoria ? 'select-erro' : ''}
                                                                                options={this.state.categoria_despesa_options}
                                                                                value={this.state.categoria_despesa_select}
                                                                                onChange={(select)=> {
            
                                                                                    let state = this.state;
                                                                                    
                                                                                    
                                                                                    // state.categoria_despesa_options.map((option, i)=>{
                                                                                    //     if (select.value == option.value) {
                                                                                    //         state.categoria_despesa_options.splice(i, 1)
                                                                                    //     }
                                                                                    // })
            
                                                                                    state.categoria_despesa = select.value;
                                                                                    state.categoria_despesa_select = select;
            
                                                                                    this.setState({...state})
                                                                                }}
                                                                                />
                                                                        </Col>
                                                                        <Col md={4}>
                                                                            <label htmlFor="abatimento">
                                                                                Abatimento (%)
                                                                            </label>
                                                                            <Input
                                                                                name="abatimento"
                                                                                placeholder="10,0"
                                                                                class={this.state.erro_abatimento ? 'input-erro' : ''}
                                                                                value={this.state.abatimento}
                                                                                type="text"
                                                                                onChange={this.handleInputChange}
                                                                                />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col md={12}>
                                                                            <Button 
                                                                                className="btn btn-success btn-nota"
                                                                                style={{float: 'right'}}
                                                                                onClick={this.handleAddAbatimento}>
                                                                                <FaSave /> Salvar
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </Collapse>
                                                                <hr />
                                                                {this.state.categorias_abatidas !== undefined && this.state.categorias_abatidas.length > 0 ?
                                                                    <>
                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Descrição</th>
                                                                                    <th>Abater</th>
                                                                                    <th>Remover</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.state.categorias_abatidas.map((abatimento, i)=>{
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>{abatimento.nome}</td>
                                                                                            <td>{abatimento.abatimento}%</td>
                                                                                            <td>
                                                                                                <Button
                                                                                                    outline
                                                                                                    className='btn-edit'
                                                                                                    style={{ padding: '0.5rem', marginLeft: 15, border: 0, fontWeight: '700'}}
                                                                                                    onClick={()=>{this.handleRemoveAbatimento(abatimento.id, i)}}
                                                                                                    >
                                                                                                    <FaTrash style={{padding: '0'}}/>
                                                                                                </Button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </>
                                                                :
                                                                    <></>
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Button 
                                                                className="btn btn-danger btn-nota"
                                                                style={{float: 'left'}}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        btn_new_regime: true,
                                                                        add_new_regime: false,
                                                                        list_regimes: true,
                                                                        id_regime: null,
                                                                        nome_regime: '',
                                                                        impostos_sobre_receita: [],
                                                                        categorias_abatidas: [],
                                                                    })
                                                                }}>
                                                                Cancelar
                                                            </Button>  
            
                                                            <Button 
                                                                className="btn btn-success btn-nota"
                                                                style={{float: 'right'}}
                                                                onClick={this.handleSendFormRegime}>
                                                                {this.state.load_send_regime === true ? 
                                                                    <>
                                                                        <ClipLoader color="#fff" size={12}/>
                                                                        &nbsp;Salvando...
                                                                    </>
                                                                : 
                                                                    <>
                                                                        <FaSave /> Salvar Regime
                                                                    </>
                                                                }
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                        </Collapse>
                                        
                                        <br/>
            
                                        <Collapse isOpen={this.state.list_regimes}>
                                            {this.state.reload_regimes === true ?
                                                <Regimes 
                                                    editRegime={this.handleEditRegime.bind(this)}
                                                    />
                                            :
                                                <></>
                                            }
                                        </Collapse>
                                    </>
                                :
                                    <></>
                                }
                            </TabPane>

                            <TabPane tabId={'imposto_fixo'}>
                                <Row>
                                    <Col md={12}>
                                        <Button 
                                            className="btn btn-success btn-nota"
                                            hidden={!this.state.btn_new_imposto}
                                            onClick={(ev) => {
                                                this.setState({
                                                    btn_new_imposto: false,
                                                    add_new_imposto: true,
                                                    reload_impostos: false,
                                                })
                                            }}>
                                            <FaPlus /> Adicionar Imposto
                                        </Button>
                                    </Col>
                                </Row>
                                <Collapse isOpen={this.state.add_new_imposto}>
                                    <Row>
                                        <Col md={6}>
                                            <label htmlFor="nome_imposto">Nome do Imposto</label>
                                            <Input
                                                name="nome_imposto"
                                                placeholder="Nome para identificar"
                                                value={this.state.nome_imposto}
                                                type="text"
                                                onChange={this.handleInputChange}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <label htmlFor="apurado">Apurado (Frequência de pagamento)</label>
                                            <Select
                                                name="apurado"
                                                placeholder="Selecione..."
                                                options={this.state.apurado_options}
                                                onChange={(select) => {
                                                    this.setState({
                                                        apurado: select.value,
                                                        apurado_select: select,
                                                    })
                                                }}
                                                value={this.state.apurado_select}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <label htmlFor="tipo_tributacao">Tipo de Tributação</label>
                                            <Select
                                                name="tipo_tributacao"
                                                placeholder="Selecione..."
                                                options={this.state.tipo_tributacao_options}
                                                onChange={(select) => {
                                                    this.setState({
                                                        tipo_tributacao: select.value,
                                                        tipo_tributacao_select: select,
                                                    })
                                                }}
                                                value={this.state.tipo_tributacao_select}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <div className="alert alert-warning">
                                                <strong>Atenção!</strong>
                                                <p>Aliquota do imposto será adicionada em um próximo passo, assim que o imposto for atribuido a uma empresa!</p>
                                            </div>
                                            
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Button 
                                                color="danger"
                                                outline
                                                onClick={(ev) =>{
                                                    this.handleCancelImposto()
                                                }}
                                                >
                                                    Cancelar
                                            </Button>
                                            
            
                                            <Button 
                                                className="btn btn-success btn-nota"
                                                style={{float: 'right'}}
                                                onClick={this.handleSendFormImposto}>
                                                {this.state.load_send_imposto === true ? 
                                                    <>
                                                        <ClipLoader color="#fff" size={12}/>
                                                        &nbsp;Salvando...
                                                    </>
                                                : 
                                                    <>
                                                        <FaSave /> Salvar Imposto
                                                    </>
                                                }
                                            </Button> 
                                        </Col>
                                    </Row>
                                </Collapse>
            
                                <Collapse isOpen={this.state.list_impostos}>
                                    {this.state.reload_impostos === true ? 
                                        <Impostos 
                                            editImposto={this.handleEditImposto.bind(this)}
                                            apurado={this.state.apurado_options}
                                            tipo_tributacao={this.state.tipo_tributacao_options}
                                            />
                                    :
                                        <></>
                                    }
                                </Collapse>
            
                            </TabPane>

                        </TabContent>
                    </Col>
                </Row>
                <br/>
                <br/>
            </Div>
        )
    }
}

export default ResumoFornecedor;