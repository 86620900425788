import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';

export const columns = () => [
  {
    name: "Título",
    selector: "titulo",
    sortable: false,
    width: '40%'
  },
  {
    name: "Descrição",
    selector: "descricao",
    sortable: false,
    width: '40%',
    cell: row => <>{row.descricao}</>
  },
  {
    name: "Média de Avaliações",
    selector: "media",
    sortable: false,
    cell: row => <>{row.media}</>
  }
];

export const getRows = (state, callbackRows) => {
  var url = ENDPOINT + "nps/list?search=" + state.search+ "&page=" + state.activePage;
  
  if (state.filter === true) {
    url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
  }

  axios.get(url, {
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  }).then((response) => {
    callbackRows(response.data.data, response.data.current_page, response.data.last_page)
  });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
  
  var url = ENDPOINT + "nps/list?search=" + search + "&page=" + page;

  if (filter === true) {
    url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
  }


  axios.get(url, {
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  }).then((response) => {
    callbackRows(response.data.data, response.data.current_page, response.data.last_page)
  });
}