import React, { Component } from 'react';
import { Card, CardBody } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { TableRetorno } from '../../components/Tables/TableRetorno/TableRetorno';
import Moment from 'moment';
import { getPermissoes, getToken } from '../../auth/set_services';
import { ENDPOINT } from '../../auth/endpoint';
import axios from 'axios';
import { toast } from 'react-toastify';
import ModalDialog from '../../components/Modals/ReturnsApiModals/ModalDialog';

const getDepartamentos = (callback) => {
    var url = ENDPOINT + "departamento/list";
    
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        var departamentos_list = [];
        data.forEach((p, i) => {
            p['value'] = p.flag;
            p['label'] = p.nome;
            departamentos_list.push(p);
        });

        callback(departamentos_list)
    });
}

const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "pendencias/list";

    if (state.protocolo_atendimento.length > 0) {
        url += '&protocolo_atendimento=' + state.protocolo_atendimento
    }
    
    if (state.departamento !== null) {
        url += '&departamento=' + state.departamento.value
    }

    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}
  
const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    var url = ENDPOINT + "pendencias/list?search=" + search + "&page=" + page;
  
    if (state.protocolo_atendimento.length > 0) {
        url += '&protocolo_atendimento=' + state.protocolo_atendimento
    }

    if (state.departamento !== null) {
        url += '&departamento=' + state.departamento.value;
    }

    if (state.document.length > 0) {
        url += '&document=' + state.document;
    }

    if (filter === true) {
      url += '&date_from=' + state.date_from + '&date_to=' + state.date_to;
    }
  
    axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + getToken()
      }
    }).then((response) => {
      callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

class Pendencia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cod_chat: null,
            rowData: [],
            activePage: 1,
            loading_table: true,
            //email: getId(),
            document: '',
            protocolo_atendimento: '',
            departamentos_list: [],
            departamento: null,
            totalPage: 1,
            filter: false,
            mensagens_modal: false,
            mensagens_current: [],
            search: '',
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),

            delete_id: -1,
            error_message: '',
            success_message: '',
            loading: false,
            visible_delete: false,
        }
        this.onMessage = this.onMessage.bind(this);
    }

    onShowDelete(delete_id){
        console.log(delete_id)
        this.setState({
            delete_id,
            visible_delete: true
        })
    }
    onClose(){
        this.setState({
            delete_id: -1,
            error_message: '',
            success_message: '',
            loading: false,
            visible_delete: false,
        })
    }
    onError(){
        this.setState({
            loading: false,
            error_message: ''
        })
    }

    onDelete = () => {
        this.setState({loading: true})
        var url = ENDPOINT+"pendencias/delete/"+this.state.delete_id;
        axios.delete(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.onClose();
            getRows(this.state, this.callbackRows.bind(this));
            return data;
        })
        .catch((error) => {
            var response = '';
            var obj;
            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            if(data.exception){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            this.onData(data);
            return data;
        });
    }

    onData(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            });
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }

    
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentDidMount() {
        getDepartamentos(this.callbackDepartamentos.bind(this));
        getRows(this.state, this.callbackRows.bind(this));
    }
    componentWillUpdate(nextProps, nextState) {
       /*  if (nextProps.communication_relacao_id !== this.props.communication_relacao_id) {
            getRows(this.state, this.callbackRows.bind(this))
        } */
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }
    callbackDepartamentos = (departamentos_list) => {
        this.setState({departamentos_list});
    }

    onFilter = (filter) => {
        this.setState({ filter });
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onMessage = (mensagens_current, cod_chat, mensagens_modal) => {
        this.setState({ mensagens_current, cod_chat, mensagens_modal });
    }
    onToast = (data) => {
        if(data.success === true){
            toast.success(data.message);
        }
        else{
            toast.error(data.errors);
        }
        //this.props.refresh();
    }

    handleSelectChange = departamento => {
        var state = this.state;
        state['departamento'] = departamento;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    };

    render() {
        return (
            
            <div>
                <h3>Clientes com pendência</h3>
                <hr />
                <Card>
                    <TableRetorno
                        clientChat
                        isSearch={this.handleChange}
                        departamentos_list={this.state.departamentos_list}
                        data={this.state.rowData}
                        operador={this.state.operador}
                        handleSelectChange={this.handleSelectChange.bind(this)}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        onMessage={this.onMessage.bind(this)}
                        noDataComponent={this.state.loading_table === true ?
                            <Card style={{ border: 0, padding: '10vh' }}><CardBody>
                                <div><div className="sweet-loading">
                                    <PropagateLoader color={'#52c565'} loading={20} size={30} />
                                </div></div></CardBody></Card> :
                            <Card style={{ border: 0 }}><CardBody><div>
                                Nenhum chat encontrado
                            </div></CardBody></Card>
                        }
                        activePage={this.state.activePage}
                        onDelete={this.onShowDelete.bind(this)}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <ModalDialog
                    visible={this.state.visible_delete}
                    onError={this.onError.bind(this)}
                    message="Deseja remover esse operador do grupo ?"
                    onClose={() => this.onClose()}
                    error_message={this.state.error_message}
                    loading={this.state.loading}
                    onSave={this.onDelete.bind(this)}
                    success_message={this.state.success_message}
                />
            </div>
        )
    }
}

export default Pendencia;