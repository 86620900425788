import React from 'react';
import { ButtonGroup } from 'reactstrap';
import styled from 'styled-components';
import { AButton } from '../AButton/AButton';

const Paginate = styled.div`
    display: inline-flex;
    align-self: center;
    margin: 15px;

    button {
        color: black;
        float: left;
        padding: 5px 11px;
        text-decoration: none;
    }
    button.active {
        background-color: #52c565;
        color: white;
        border-radius: 5px;
    }
    button:hover:not(.active):not(.disabled) {
        background-color: #ddd;
        border-radius: 5px;
    }
    button.disabled{
        opacity: 0.5;
        cursor: default
    }
    p{
        margin-top: 4px;
        margin-bottom: 0;
        position: relative;
        bottom: 29%;
    }
`;

const Pagination = (props) => {
	var totalPage = [];
    if(props.totalPage > 6){
        if(props.activePage < 4){
            totalPage.push(
                <>
                    <AButton onClick={() => props.onPageChange(1)} className={props.activePage === 1 ? 'active' : ''}>{1}</AButton>
                    <AButton onClick={() => props.onPageChange(2)} className={props.activePage === 2 ? 'active' : ''}>{2}</AButton>
                    <AButton onClick={() => props.onPageChange(3)} className={props.activePage === 3 ? 'active' : ''}>{3}</AButton>
                    <AButton onClick={() => props.onPageChange(4)} className={props.activePage === 4 ? 'active' : ''}>{4}</AButton>
                    <p style={{cursor: 'default'}}>...</p>
                    <AButton onClick={() => props.onPageChange(props.totalPage)} >{props.totalPage}</AButton>
                </>
            )
        }
        else if(props.activePage === props.totalPage){
            totalPage.push(
                <>
                    <AButton onClick={() => props.onPageChange(1)} className={props.activePage === 1 ? 'active' : ''}>{1}</AButton>
                    <p disabled style={{cursor: 'default'}}>...</p>
                    <AButton onClick={() => props.onPageChange(props.activePage-2)}>{props.activePage-2}</AButton>
                    <AButton onClick={() => props.onPageChange(props.activePage-1)}>{props.activePage-1}</AButton>
                    <AButton onClick={() => props.onPageChange(props.activePage)} className={'active'}>{props.activePage}</AButton>
                </>
            )
        }
        else if(props.activePage > props.totalPage-2){
            totalPage.push(
                <>
                    <AButton onClick={() => props.onPageChange(1)} className={props.activePage === 1 ? 'active' : ''}>{1}</AButton>
                    <p disabled style={{cursor: 'default'}}>...</p>
                    <AButton onClick={() => props.onPageChange(props.activePage-1)}>{props.activePage-1}</AButton>
                    <AButton onClick={() => props.onPageChange(props.activePage)} className={'active'}>{props.activePage}</AButton>
                    <AButton onClick={() => props.onPageChange(props.activePage+1)}>{props.activePage+1}</AButton>
                </>
            )
        }
        else{
            totalPage.push(
                <>
                    <AButton onClick={() => props.onPageChange(1)} >{1}</AButton>
                    <p disabled style={{cursor: 'default'}}>...</p>
                    <AButton onClick={() => props.onPageChange(props.activePage-1)}>{props.activePage-1}</AButton>
                    <AButton onClick={() => props.onPageChange(props.activePage)} className={'active'}>{props.activePage}</AButton>
                    <AButton onClick={() => props.onPageChange(props.activePage+1)}>{props.activePage+1}</AButton>
                    {props.activePage < props.totalPage-2 ? <p style={{cursor: 'default'}}>...</p> : <></>}
                    <AButton onClick={() => props.onPageChange(props.totalPage)} >{props.totalPage}</AButton>
                </>
            )

        }

    }
    else{
        for(let i = 0; i < props.totalPage; i++){

            totalPage.push(
                <AButton onClick={() => props.onPageChange(i+1)} className={props.activePage === i+1 ? 'active' : ''}>{i+1}</AButton>
            )
        }
    }

    return(
        <ButtonGroup>
            <Paginate>
                <AButton onClick={() => { 
                    if(props.activePage > 1){
                        props.onPageChange(props.activePage - 1)
                    }
                }} className={props.activePage === 1 ? "disabled" : ""}>&laquo;</AButton>

                    {totalPage}

                <AButton onClick={() => { 
                    if(props.activePage !== props.totalPage){
                        props.onPageChange(props.activePage + 1)
                    }
                }}
                className={props.activePage === props.totalPage ? "disabled" : ""}>&raquo;</AButton>
            </Paginate>
        </ButtonGroup>
    );
}
export default Pagination;