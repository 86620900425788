import axios from 'axios';
import { HiViewGrid } from 'react-icons/hi';
import ReactTooltip from 'react-tooltip';
import { Button, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';

const formatPayment = (value) => {
    switch(value){
        case 'credit': return 'Cartão de crédito'
        case 'boleto': return 'Boleto Bancario'
        case 'debit' : return 'Débito'
        case 'a definir': return 'A definir'
        default: return value;
    }
}

export const columns = () => [
    {
      name: '',
      selector: 'buttons',
      width: '50px',
      cell: row => <Row>
        <Button data-tip="Informações"
          outline
          style={{ marginRight: '3px', border: 0}}
          color="primary" 
          onClick={() => 
            {
                window.location.href = '/resumo-solicitacao/'+row.id;
            }
          }>
          <HiViewGrid style={{ padding: '2px', width: '23px' }} />
        </Button>
        <ReactTooltip/>
      </Row>
    },
    {
      name: "Nome do cliente",
      selector: "hash_produto",
      sortable: false,
      cell: row => row.user !== null ? row.user.nome + ' ' + row.user.sobrenome : ''
    },
    {
      name: "ID da transação",
      selector: "id_transacao",
      sortable: false,
    },
    {
      name: "Status de Pagamento",
      selector: "status",
      sortable: false,
    },
    {
      name: "Status de Solicitação",
      selector: "status_solicitacao",
      sortable: false,
    },
    {
      name: "Forma de Pagamento",
      selector: "forma_pagamento",
      sortable: false,
      cell: row => formatPayment(row.forma_pagamento)
    },
    {
      name: "Analista",
      selector: "analista",
      sortable: false,
      cell: row => row.analista !== null ? row.analista.name : ''
    },
];

export const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "solicitacao/list";

    if(state.document.replace(/([^\d])+/gim, '').length > 0){
      if(state.document.replace(/([^\d])+/gim, '').length === 11){
          url +='&cpf_cnpj='+state.document.replace(/([^\d])+/gim, '');
      }
      else if(state.document.replace(/([^\d])+/gim, '').length === 14){
          url +='&cpf_cnpj='+state.document.replace(/([^\d])+/gim, '');
      }
    }

    if(state.status_pagamento !== null){
        url += '&status_pagamento='+state.status_pagamento.value;
    }

    if(state.status_migracao !== null){
        url += '&status_migracao='+state.status_migracao.value;
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    
    var url = ENDPOINT + "solicitacao/list?search="+search+"&page="+page;

    if(filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    if(state.document.replace(/([^\d])+/gim, '').length > 0){
      if(state.document.replace(/([^\d])+/gim, '').length === 11){
          url +='&cpf_cnpj='+state.document.replace(/([^\d])+/gim, '');
      }
      else if(state.document.replace(/([^\d])+/gim, '').length === 14){
          url +='&cpf_cnpj='+state.document.replace(/([^\d])+/gim, '');
      }
    }

    if(state.status_pagamento !== null){
        url += '&status_pagamento='+state.status_pagamento.value;
    }

    if(state.status_migracao !== null){
        url += '&status_migracao='+state.status_migracao.value;
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}