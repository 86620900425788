import { Component } from "react";
import { Button, Card, CardBody, Col, Collapse, Input, Row, Modal, ModalBody, ListGroup, ListGroupItem  } from "reactstrap";
import { getUser } from "../../auth/set_services";
import { getRowsPerPage, getRows } from "../../components/Tables/TableQueroBus/propsCupom";
import TableCupom from "../../components/Tables/TableQueroBus/TableCupom";
import PropagateLoader from "react-spinners/PropagateLoader";
import moment from "moment";
import { FaPlus, FaSave } from "react-icons/fa";
import Select from "react-select";
import { ENDPOINT } from "../../auth/endpoint";
import axios from "axios";
import { getPermissoes, getToken } from "../../auth/set_services";
import { toast, useToastContainer } from 'react-toastify';
import NumberFormat from 'react-number-format';
// import CupomModalMeubus from "../../components/Modals/Meubus/CupomModalMeubus";

const statusOptions = [
    { value: '0', label: 'Desativado'},
    { value: '1', label: 'Ativado'},
];

const typeOptions = [
    { value: 'p', label: 'Porcentagem'},
    { value: 'f', label: 'Fixo'},
];


const limiteOptions = [
    { value: '1', label: 'Sim'},
    { value: '0', label: 'Não'},
];


const ModalShowUsers = (props) => {
    
    return <Modal isOpen={props.visible} size="lg">
        <ModalBody>
            <div style={{float: 'right'}}>
                <Button
                    outline
                    onClick={props.onClose}
                    style={{paddingTop: '3px', paddingBottom: '3px', fontSize: '19px', paddingLeft: '5px', paddingRight: '5px', fontWeight: '600', border: 'none'}}
                    color='danger'>
                        X
                    </Button>
            </div>
            <div>
                <h5 style={{paddingTop: '3px', paddingBottom: '3px', paddingLeft: '5px', paddingRight: '5px'}}>Cupom "{props.hash}"" usado por:</h5>
            </div>
            <hr/>
            <ListGroup>
                {props.list.map(p => (
                    <ListGroupItem>
                        <b>
                        {p.cnpj !== null ? (
                            <NumberFormat 
                                  displayType={'text'} 
                                  value={p?.cnpj} 
                                  format={'##.###.###/####-##'}
                                  />
                        ) : (
                            <NumberFormat 
                                  displayType={'text'} 
                                  value={p?.cpf} 
                                  format={'###.###.###-##'}
                                  />
                        )}</b> {p.cnpj !== null ? p.social_name : p.name } às {moment(p.created_at).format('DD/MM/YYYY HH:mm')}
                    </ListGroupItem>
                ))}
            </ListGroup>
            {props.list.length == 0 ?
                <ListGroup>
                    <ListGroupItem>
                        Nenhum uso encontrado
                    </ListGroupItem>
                </ListGroup>
            :
            null}
        </ModalBody>
    </Modal>
}

class CupomQueroBus extends Component
{
    constructor(props){
        super(props);
        this.state = {
            show_activity: false,
            show_filter: false,
            user_change: null,
            search: '',
            seller_id: '',
            valor_de: '0.00',
            valor_ate: '0.00',
            loading_table: true,
            modal_show_user: false,
            edit_id: -1,
            edit_user: null,
            new_cupom: false,
            rowData: [],
            cupom_id: null,
            cupom_code: null,
            cupom_limit_total: null,
            cupom_status: { value: '1', label: 'Ativado'},
            cupom_type: { value: 'p', label: 'Porcentagem'},
            cupom_limit: { value: '0', label: 'Não'},
            cupom_value: null,
            cpf_current: null,
            cnpj_current: null,
            phone_current: null,
            status_user: '',
            type_user: '',
            type: { value: 'p', label: 'Porcentagem'},
            limit: { value: '0', label: 'Não'},
            status: null,
            totalPage: 0,
            activePage: 1,
            is_cnpj: false,
            document: '',
            filter: false,
            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            visible_taggy_add: false,
            visible_user: false,
            hash_user: '',
            users_lists_user: [],
        }
    }
    
    componentDidMount(){
        getRows(this.state, this.callbackRows.bind(this));
    }

    onFilter = (filter) => {
        this.setState({filter});
        getRows(this.state, this.callbackRows.bind(this));
    }

    callbackRows = (rowData, totalPage) => {
        this.setState({rowData, totalPage, loading_table: false})
    }

    onPageChange = (activePage) => {
        var state = this.state;

        state.activePage = activePage;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});

        getRowsPerPage(activePage, state, this.callbackRows.bind(this));
    }

    handleChange = (event) => {
        var state = this.state;
        let name = event.target.name;
        let value = event.target.value;

        state[name] = value;
        state.activePage = 1;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});
        
        getRowsPerPage(1, state, this.callbackRows);
            
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    onStatusChange(status_user){
        var state = this.state;
        state.status_user = status_user;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});
        
        getRows(state, this.callbackRows);
    }
    filterShow(){
        this.setState({
            show_filter: !this.state.show_filter
        })
    }
    handleTypeStatusChange = status => {
        var state = this.state;
        state['status'] = status;
        state.loading_table = true;
        this.setState({ ...state });
        getRows(state, this.callbackRows);
    };
    handletoastSuccessMessage = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    handletoastMessage = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    handleSaveCupom = () => {
        var url = ENDPOINT + "querobus/cupom";

        let formData = new FormData();

        if (this.state.cupom_id) formData.append('cupom_id', this.state.cupom_id);

        formData.append('code', this.state.cupom_code);
        formData.append('status', this.state.cupom_status.value);
        formData.append('type', this.state.cupom_type.value);
        formData.append('value', this.state.cupom_value);
        formData.append('limit', this.state.cupom_limit.value);
        formData.append('limit_total', this.state.cupom_limit_total);


        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }})
        .then((response) => {
            console.log(response)
            
            if (this.state.cupom_id) {
                this.handletoastSuccessMessage('Cupom atualizado com sucesso!')
            } else {
                this.handletoastSuccessMessage('Cupom cadastrado com sucesso!')
            }
        })
        .catch(function (error) {
            this.handletoastMessage('Ocorreu um erro no servidor!');

            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              //console.log(error.response.data);
              //console.log(error.response.status); // status code
              //console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              //console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              //console.log('Error', error.message);
            }
            // console.log(error.config);
        })
    }
    handleInputChange = (e) => {
        const { name, value } = e.target;
        //console.log(name, value)
        this.setState({ [name]: value });
    }    

    handleOpenUsers = (cupom_id) => {
        this.state.rowData.map((cupom, i) => {
            if(cupom.id == cupom_id) {
                //modal_show_user users_lists_user hash_user
                this.setState({
                    modal_show_user: true,
                    users_lists_user: cupom.users,
                    hash_user: cupom.code,
                })
            }
        })
    }

    render(){
        return (
            <div>
                <h3>Cupom MeuBus</h3>
                <hr/>
                
                <Collapse isOpen={!this.state.new_cupom}>
                    <Row>
                        <Col md="12">
                            <Button 
                                className="btn btn-success btn-save" 
                                style={{"margin-bottom": "20px"}}
                                onClick={() => this.setState({new_cupom: true})}
                                >
                                    <FaPlus /> Cadastrar Cupom
                            </Button>
                        </Col> 
                    </Row>
                </Collapse>
                <Collapse isOpen={this.state.new_cupom}>
                    <Row style={{marginBottom: '1rem'}}>
                        <Col md={6}>
                            <h2>Dados do Cupom</h2>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '1rem'}}>
                        <Col md={6}>
                            <label htmlFor="cupom_code">Código</label>
                            <Input
                                name="cupom_code"
                                placeholder="Código do CUPOM"
                                value={this.state.cupom_code}
                                type="text"
                                onChange={this.handleInputChange} />
                        </Col>
                        <Col md={6}>
                            <label htmlFor="cupom_status">Status</label>
                            <Select
                                    name="cupom_status"
                                    placeholder="Selecione..."
                                    options={statusOptions}
                                    onChange={(select) => {
                                        this.setState({cupom_status: select});
                                    }}
                                    value={this.state.cupom_status}
                                        />
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '1rem'}}>
                        <Col md={6}>
                            <label htmlFor="cupom_type">Tipo</label>
                            <Select
                                name="cupom_type"
                                placeholder="Selecione..."
                                options={typeOptions}
                                onChange={(select) => {
                                    this.setState({cupom_type: select});
                                }}
                                value={this.state.cupom_type}
                                />
                        </Col>
                        <Col md={6}>
                            <label htmlFor="cupom_value">Procentagem / Valor de desconto</label>
                            <Input
                                name="cupom_value"
                                placeholder=""
                                value={this.state.cupom_value}
                                type="text"
                                onChange={this.handleInputChange} />
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '1rem'}}>
                        <Col md={6}>
                            <label htmlFor="cupom_limit">Limite de uso</label>
                            <Select
                                name="cupom_limit"
                                placeholder="Selecione..."
                                options={limiteOptions}
                                onChange={(select) => {
                                    this.setState({cupom_limit: select})
                                }}
                                value={this.state.cupom_limit}
                                />
                        </Col>
                        <Col md={6}>
                            <label htmlFor="cupom_limit_total">Quantidade Total</label>
                            <Input
                                name="cupom_limit_total"
                                placeholder="Quantidade"
                                value={this.state.cupom_limit_total}
                                type="text"
                                onChange={this.handleInputChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Button 
                                className="btn btn-danger btn-save" 
                                style={{"margin-bottom": "20px"}}
                                onClick={() => this.setState({new_cupom: false})}
                                >
                                    Cancelar
                            </Button>
                        </Col> 
                        <Col md="6" style={{textAlign: 'right'}}>
                            <Button 
                                className="btn btn-success btn-save" 
                                style={{"margin-bottom": "20px"}}
                                onClick={() => this.handleSaveCupom()}
                                >
                                    <FaSave /> Salvar Cupom
                            </Button>
                        </Col> 
                    </Row>
                </Collapse>
                <Collapse isOpen={!this.state.new_cupom}>
                    <Row>
                        <Col md="12">
                            <Card hidden={!getUser().permissao_gestor_aqpago}>
                                <TableCupom
                                    isSearch={this.handleChange}
                                    onFilter={this.onFilter}
                                    filter={this.state.filter}
                                    onSearchDocument={this.onSearchDocument.bind(this)}
                                    data={this.state.rowData}
                                    is_cnpj={this.state.is_cnpj}
                                    handleTypeStatusChange={this.handleTypeStatusChange}
                                    status_user={this.state.status_user}
                                    onStatusChange={this.onStatusChange.bind(this)}
                                    type_user={this.state.type_user}
                                    show_filter={this.state.show_filter}
                                    filterShow={this.filterShow.bind(this)}
                                    handleOpenUsers={this.handleOpenUsers.bind(this)}
                                    onPageChange={this.onPageChange}
                                    activePage={this.state.activePage}
                                    state={this.state}
                                    totalPage={this.state.totalPage}
                                    noDataComponent={ this.state.loading_table === true ? 
                                        <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                            <PropagateLoader color={'#52c565'} loading={20} size={30} />
                                        </div></div></CardBody></Card> :
                                        <Card style={{border: 0}}><CardBody><div>
                                            Nenhum cupom encontrado
                                        </div></CardBody></Card>
                                    }
                                />
                            </Card>
                        </Col>
                    </Row>
                </Collapse>
                <ModalShowUsers 
                    visible={this.state.modal_show_user}
                    list={this.state.users_lists_user} 
                    hash={this.state.hash_user}
                    onClose={() => this.setState({ modal_show_user: false, users_lists_user: [], hash_user: '' })}
                />
            </div>
        )
    }

}

export default CupomQueroBus;