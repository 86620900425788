import axios from "axios";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import Moment from "moment";
import { Button } from "reactstrap";
import NumberFormat from "react-number-format";
import { getStatusAqmed } from "../utils";
export const columns = () => [
  {
    name: "Data",
    selector: "created_at",
    sortable: false,
    width: "150px",
    cell: (p) => Moment(p?.date).format("DD/MM/YYYY"),
  },
  {
    name: "Cliente",
    selector: "cliente",
    sortable: false,
    width: "270px",
    cell: (p) => (
      <div style={{ justifyContent: "center", margin: 5, width: "100%" }}>
        <center>
          {p?.name}
          <br />
          <b>
            <NumberFormat
              displayType={"text"}
              value={p?.document}
              format={
                p?.document.replace(/([^\d])+/gim, "").length > 11
                  ? "##.###.###/####-##"
                  : "###.###.###-##"
              }
            />
          </b>
        </center>
      </div>
    ),
  },
  {
    name: "Valor",
    selector: "valor",
    sortable: false,
    cell: (p) =>
      "R$ " + parseFloat(p?.plan_total_value).toFixed(2).replace(".", ","),
  },
  {
    name: "Forma Pagamento",
    selector: "id",
    sortable: false,
    cell: (p) => p?.payment_method,
  },
  {
    name: "Status",
    selector: "status",
    sortable: false,
    cell: (p) => <b style={{ color: "#000000" }}>{getStatusAqmed(p?.payment_status)}</b>,
  },
  {
    name: "Próximo Vencimento",
    selector: "created_at",
    sortable: false,
    width: "150px",
    cell: (p) =>
      Moment(p?.invoice_due_date).add(1, "M").format("DD/MM/YYYY") ===
      "Invalid date"
        ? null
        : Moment(p?.invoice_due_date).add(1, "M").format("DD/MM/YYYY"),
  },
];

export const getRows = (state, callbackRows) => {
  var url =
    ENDPOINT +
    "faturas/list?search=" +
    state.search +
    "&page=" +
    state.activePage;

  var document = state.document.replace(/([^\d])+/gim, "");
  if (state.is_cnpj === false) {
    if (document.length === 11) {
      url += "&cpf=" + document;
    }
  } else {
    if (document.length === 14) {
      url += "&cnpj=" + document;
    }
  }

  if (state.cod_cliente != "") {
    url += "&cod_cliente=" + state.cod_cliente;
  }

  if (state.cod_fatura != "") {
    url += "&cod_fatura=" + state.cod_fatura;
  }

  if (state.filter === true) {
    url += "&date_from=" + state.date_from + "&date_to=" + state.date_to;
  }

  axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then((response) => {
      callbackRows(
        response.data.data,
        response.data.current_page,
        response.data.last_page
      );
    });
};

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
  var url = ENDPOINT + "faturas/list?search=" + search + "&page=" + page;

  var document = state.document.replace(/([^\d])+/gim, "");
  if (state.is_cnpj === false) {
    if (document.length === 11) {
      url += "&cpf=" + document;
    }
  } else {
    if (document.length === 14) {
      url += "&cnpj=" + document;
    }
  }

  if (state.status_envio != null) {
    url += "&status_envio=" + state.status_envio.value;
  }

  if (state.status_fatura != null) {
    url += "&status_fatura=" + state.status_fatura.value;
  }

  if (state.cod_cliente != "") {
    url += "&cod_cliente=" + state.cod_cliente;
  }

  if (state.cod_fatura != "") {
    url += "&cod_fatura=" + state.cod_fatura;
  }

  if (filter === true) {
    url += "&date_from=" + state.date_from + "&date_to=" + state.date_to;
  }

  axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then((response) => {
      callbackRows(
        response.data.data,
        response.data.current_page,
        response.data.last_page
      );
    });
};
