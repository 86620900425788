import axios from "axios";
import React, { Component } from "react";
import Select from "react-select";
import { Button, Form, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import ModelReturn from "../ReturnsApiModals/ModelReturn";

class BloquearUsuario extends Component{
    constructor(props){
        super(props);
        this.state = {
            message: '',
            message_invalid: false,
            loading: false,
            error_message: '',
            success_message: '',
            select_fraud: {label: 'Ativo', value: true},
        }
    }

    onClose = () => {
        this.setState({
            message: '',
            message_invalid: false,

            loading: false,
            error_message: '',
            success_message: '',
        });
        this.props.onClose();
    }
    
    onData = (data) => {
        
        if(data.success === true){
            this.setState({
                success_message: data.message,
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }

    onDocument = (documento) => {
        this.setState({documento})
    }

    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    onFile = (file) => {
        if(file.target.files[0]){
            this.setState({file_document: file.target.files[0]});
        }
        else{
            this.setState({file_document: null});
        }
    }

    onSave = () => {
        if(this.validFields() === true){
            this.storeUser();
        }
    }

    validFields = () => {
        var count_invalid = 0;
        if(this.state.message.length > 0){
            this.setState({
                documento_invalid: false
            })
        }
        else{
            this.setState({
                documento_invalid: true
            });
            count_invalid++;
        }

        if(count_invalid > 0){
            return false;
        }

        return true;
    }

    storeUser = () => {
        this.setState({ loading: true })
        var url = ENDPOINT+"querobus/user/"+this.props.user_id;

        axios.delete(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            },
            data: {
                fraud: this.state.select_fraud.value,
                block_note: this.state.message,
            }
          })
        .then((response) => {
            console.log(response)
            var data = response.data;
            this.setState({
                success_message: 'usuário bloqueado com sucesso!',
            })
        })
        .catch(error => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }

            var data = error.response.data;
            this.setState({
                error_message: data.errors
            })
        })
    }

    render(){
        return(
            <Modal isOpen={this.props.visible}>
                <ModalHeader>{this.props.title_nota ? this.props.title_nota : 'Nova Nota'}</ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <Form>
                        <FormGroup>
                            <label htmlFor="conta_banco">Bloqueio por Fraude ?</label>
                            <Select
                                name="fraud"
                                placeholder="Selecione..."
                                options={[
                                    {label: 'Ativo', value: true},
                                    {label: 'Inativo', value: false},
                                ]}
                                onChange={(opt) => {
                                    this.setState({select_fraud: opt})
                                    this.setState({fraud: opt.value})
                                }}
                                value={this.state.select_fraud}
                                    />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                type="textarea"
                                placeholder={'Descrição'}
                                invalid={this.state.message_invalid}
                                value={this.state.message}
                                onChange={(e) => this.setState({message: e.target.value})}
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
            
                <ModalBody style={{ minHeight: '114px', display: 'flex', alignItems: 'center', alignSelf: 'center', paddingBottom: '47px' }} hidden={!this.state.loading}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                    />
                </ModalBody>

                <ModalFooter hidden={this.state.loading}>
                    <Button color="success" onClick={() => this.onSave()}>Confirmar</Button>
                    <Button color="danger" onClick={() => this.onClose()}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default BloquearUsuario;