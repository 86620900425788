import { Component } from "react";
import { MdSearch } from "react-icons/md";
import { Button, Card, CardBody, Nav, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import styled from 'styled-components';
import NumberFormat from "react-number-format";
import ClipLoader from 'react-spinners/ClipLoader';
import { InputMasked } from "../../components/InputText/InputMasked";
import { toast } from "react-toastify";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import axios from "axios";
import DataTable from "react-data-table-component";
const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
`
const columns = () => [
    {
        name: "Tipo de cartão",
        selector: "tipo_cartao",
        sortable: false,
        width: '12%',
        cell: p => p.tipo_cartao.toUpperCase()
    },
    {
        name: "Nome no cartão",
        selector: "nome_proprietario",
        sortable: false,
        width: '15%'
    },
    {
        name: "Número do cartão",
        selector: "numero",
        sortable: false,
        width: '150px'
    },
    {
        name: "Validade",
        selector: "tipo_cartao",
        sortable: false,
        width: '12%',
        cell: p => p.validade_mes + " / "+p.validade_ano
    },
    {
        name: "Apelido do cartão",
        selector: "apelido_cartao",
        sortable: false,
        width: '12%'
    },
    {
        name: "Bandeira",
        selector: "bandeira",
        sortable: false,
        width: '12%',
        cell: p => p.bandeira !== null ? p.bandeira.toUpperCase() : 'Sem informações'
    },
    {
        name: "Dono do Cartao",
        sortable: false,
        width: '250px',
        cell: p => <ul style={{listStyleType: 'circle', marginTop: '15px'}}>
            <li style={{marginBottom: '7px'}}>{p.user.nome} {p.user.sobrenome}</li>
            <li style={{marginBottom: '7px'}}>{p.user.email}</li>
            <li style={{marginBottom: '7px'}}><NumberFormat displayType={'text'} value={p.user.cpf} format={p.user.cpf.length > 11 ? '##.###.###/####-##' : "###.###.###-##"}/></li>
        </ul>
    },
];
class ConsultarReceiraFederal extends Component{
    constructor(props){
        super(props);
        this.state = {
            cpf_cnpj: '',
            nome_completo_current: '',
            cpf_current: '',
            invalid_cpf_current: false,
            email_current: '',
            telefone_current: '',
            endereco_current: {},
            status_current: '',

            nome_receita_current: '',
            nascimento_receita_current: '',
            cpf_receita_current: '',
            situacao_receita_current: '',


            nome_empresarial_current: '',
            nome_fantasia_current: '',
            cnpj_current: '',
            natureza_juridica_current: '',
            cnae_current: '',
            email_current: '',
            telefone_current: '',

            tipo_logradouro: '',
            logradouro: '',
            complemento: '',
            numero: '',
            bairro: '',
            cidade: '',
            cep: '',
            uf: '',
            pais: '',

            loading_button: false,
            loading_buyer: true,
            loading_cartao: true,
            is_cnpj: false,
            cartoes: [],

            socios: [],

            activeTab: '1'
        }
    }

    listData = [
        { name: <>Dados Pessoais</>, active: '1' },
        { name: <>Receita Federal </>, active: '2' },
        { name: <>Cartões de crédito </>, active: '3' }
    ];
    valid_fields = () => {
        var invalids = 0;
        if(this.state.cpf_cnpj.replace(/([^\d])+/gim, '').length > 10){
            this.setState({invalid_cpf_current: false})
        }
        else{
            invalids++;
            this.setState({invalid_cpf_current: true})
        }

        

        return invalids;
    }
    Navs = () => {
        return <Nav tabs>
            {this.listData.map((p, index) => {
                return <NavLink
                    key={index}
                    className={
                        classnames({ active: this.state.activeTab === p.active })}
                    onClick={() => { this.setState({ activeTab: p.active }); }}>
                    <span>{p.name}</span>
                </NavLink>
            })}
        </Nav>
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({ [name]: value });
    }
    getUserbyReceita = () => {
        if(this.valid_fields() === 0){
            this.setState({loading_button: true, loading_buyer: true});

            if(this.state.is_cnpj == true){
                var url = ENDPOINT+"user/search_cnpj?cnpj="+this.state.cpf_cnpj.replace(/([^\d])+/gim, '');
            }
            else{
                var url = ENDPOINT+"user/search_cpf_new?cpf="+this.state.cpf_cnpj.replace(/([^\d])+/gim, '');
            }
            axios.get(url, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }
            })
            .then((response) => {
                var data = response.data.data;
                if(response.data.message == 'Cpf Encontrado'){
                    this.setState({
                        loading_button: false,
                        loading_buyer: false,
                        nome_receita_current: data.nome,
                        nascimento_receita_current: data.nascimento,
                        cpf_receita_current: data.ni,
                        situacao_receita_current: data.situacao.descricao,
                        nome_empresarial_current: '',
                        nome_fantasia_current: '',
                        cnpj_current: '',
                        natureza_juridica_current: '',
                        cnae_current: '',
                        email_current: '',
                        telefone_current: '',
            
                        logradouro: '',
                        complemento: '',
                        numero: '',
                        bairro: '',
                        cidade: '',
                        cep: '',
                        uf: '',
                        pais: '',
                    })
                }
                else if(response.data.message == 'CNPJ Encontrado'){
                    this.setState({
                        loading_button: false,
                        loading_buyer: false,
                        nome_receita_current: '',
                        nascimento_receita_current: '',
                        cpf_receita_current: '',
                        nome_empresarial_current: data.nomeEmpresarial,
                        nome_fantasia_current: data.nomeFantasia,
                        cnpj_current: data.ni,
                        cnae_current: data.cnaePrincipal ? data.cnaePrincipal.descricao : '',
                        natureza_juridica_current: data.naturezaJuridica ? data.naturezaJuridica.descricao : '',
                        email_current: data.correioEletronico,
                        telefone_current: data.telefones ? data.telefones[0].ddd + '' + data.telefones[0].numero : '',
                        socios: data.socios ? data.socios : []
                    })
                    if(data.endereco){
                        this.setState({
                            loading_button: false,
                            tipo_logradouro: data.endereco.tipoLogradouro,
                            logradouro: data.endereco.logradouro,
                            complemento: data.endereco.complemento,
                            numero: data.endereco.numero,
                            bairro: data.endereco.bairro,
                            cidade: data.endereco.municipio ? data.endereco.municipio.descricao : '',
                            cep: data.endereco.cep,
                            uf: data.endereco.uf,
                            pais: data.endereco.uf
                        })
                    }
                }

                return data;
            })
            .catch((error) => {
                var data = error.response.data;
                this.setState({
                    loading_button: false,
                    nome_receita_current: '',
                    nascimento_receita_current: '',
                    cpf_receita_current: '',
                    situacao_receita_current: data.errors,
                })
            })
        }
    }
    render(){
        return(
            <Div>
                <h3>Consultar na Receita Federal</h3>
                <hr />
                <Card>
                    <CardBody>
                        <div>
                            <InputMasked
                                name="cpf_cnpj"
                                mask={this.state.is_cnpj === false ? "999.999.999-99" : "99.999.999/9999-99"}
                                placeholder={this.state.is_cnpj === false ? "CPF" : "CNPJ"}
                                value={this.state.cpf_cnpj}
                                invalid={this.state.invalid_cpf_current}
                                type="text"
                                onChange={this.handleChange}
                            />
                            <input 
                                type="checkbox" 
                                checked={this.state.is_cnpj}
                                onChange={(e) => this.setState({is_cnpj: e.target.checked})}
                            /> Mudar para CNPJ
                            <br/>
                            <Button disabled={this.state.loading_button} color="success" onClick={() => this.getUserbyReceita()}>
                                {this.state.loading_button === true ?
                                    <ClipLoader color="#fff" size={20}/>:
                                    <MdSearch style={{ padding: 0 }}/>} Buscar</Button>
                        </div>
                        <br/>
                        <div hidden={this.state.loading_buyer} >
                            <Card hidden={this.state.nome_receita_current == ''}>
                                <CardBody>
                                    <h5>Receita Federal</h5>
                                    <hr/>
                                    <p><b>Nome:</b> {this.state.nome_receita_current}</p>
                                    <p><b>Data de nascimento:</b> <NumberFormat displayType={"text"} format="##/##/####" value={this.state.nascimento_receita_current} /></p>
                                    <p><b>CPF:</b> <NumberFormat displayType={"text"} format="###.###.###-##" value={this.state.cpf_receita_current} /></p>
                                    <p><b>Situação:</b> {this.state.situacao_receita_current}</p>
                                </CardBody>
                            </Card>
                            <Card hidden={this.state.nome_empresarial_current == ''}>
                                <CardBody>
                                    <h5>Receita Federal</h5>
                                    <hr/>
                                    <p><b>Dados empresariais</b></p>
                                    <p><b>Nome Empresarial:</b> {this.state.nome_empresarial_current}</p>
                                    <p><b>Nome Fantasia:</b> {this.state.nome_fantasia_current}</p>
                                    <p><b>CNPJ:</b> {this.state.cnpj_current}</p>
                                    <p><b>Natureza Juridica:</b> {this.state.natureza_juridica_current}</p>
                                    <p><b>CNAE:</b> {this.state.cnae_current}</p>
                                    <p><b>Telefone Empresarial:</b> {this.state.telefone_current}</p>
                                    <hr/>
                                    <p><b>Endereço empresarial</b></p>
                                    <p><b>Tipo de Logradouro:</b> {this.state.tipo_logradouro}</p>
                                    <p><b>Logradouro:</b> {this.state.logradouro}</p>
                                    <p><b>Complemento:</b> {this.state.complemento}</p>
                                    <p><b>Numero:</b> {this.state.numero}</p>
                                    <p><b>Bairro:</b> {this.state.bairro}</p>
                                    <p><b>Cidade:</b> {this.state.cidade}</p>
                                    <p><b>CEP:</b> {this.state.cep}</p>
                                    <p><b>UF:</b> {this.state.uf}</p>
                                    <p><b>Pais:</b> {this.state.pais}</p>
                                    {this.state.socios.length > 0 ?
                                    <>
                                        <hr/>
                                        <p><b>Sócios</b></p>
                                        {this.state.socios.map(e => {
                                            return <>
                                                <p>{e.nome}</p>
                                            </>
                                        })}
                                    </>
                                    :<></>}
                                </CardBody>
                            </Card>
                        </div>
                    </CardBody>
                </Card>
            </Div>
        )
    }
}

export default ConsultarReceiraFederal;