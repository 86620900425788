import React, { Component } from 'react';
import { Card, CardBody, Form, FormGroup, Input, Button, TabPane } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { toast } from "react-toastify";
import axios from "axios";
import { TableTransacao } from '../../components/Tables/TableTransacao/TableTransacao';
import Moment from 'moment';
import { getPermissoes, getToken } from '../../auth/set_services';
import { GiBarrier } from 'react-icons/gi';
import { HiOutlineSpeakerphone } from 'react-icons/hi';

import { ButtonOutline } from '../../components/Button/ButtonOutline';
import CreateContestacao from '../../components/Modals/CreateContestacao/CreateContestacao';
import { ENDPOINT } from '../../auth/endpoint';
import { MdNote } from 'react-icons/md';

var CurrencyInput = require('react-currency-masked-input');
const getSerialNumber = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const serial_number = urlParams.get('serial_number');
    if (serial_number !== null) {
        return serial_number;
    }
    else {
        return "";
    }
}

export const getRows = (document, state, callbackRows) => {
    if (typeof document === 'undefined') {
        return null;
    }
    else if (document === -1) {
        return null;
    }

    var url = ENDPOINT + "transacao/list";

    if (state.taggy_serial !== null) {
        url += '?taggy_serial=' + state.taggy_serial;
    }



    if (document.replace(/([^\d])+/gim, '').length > 0) {
        if (document.replace(/([^\d])+/gim, '').length === 11) {
            url += '&cpf=' + document.replace(/([^\d])+/gim, '');
        }
        else if (document.replace(/([^\d])+/gim, '').length === 14) {
            url += '&cnpj=' + document.replace(/([^\d])+/gim, '');
        }
    }

    if (state.filter === true) {
        url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }


    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (document, page, search, filter, state, callbackRows) => {

    var url = ENDPOINT + "transacao/list?search=" + search + "&page=" + page;

    if (state.taggy_serial !== null) {
        url += '&taggy_serial=' + state.taggy_serial;
    }
    if (filter === true) {
        url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }



    if (document.replace(/([^\d])+/gim, '').length > 0) {
        if (document.replace(/([^\d])+/gim, '').length === 11) {
            url += '&cpf=' + document.replace(/([^\d])+/gim, '');
        }
        else if (document.replace(/([^\d])+/gim, '').length === 14) {
            url += '&cnpj=' + document.replace(/([^\d])+/gim, '');
        }
    }

    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

class TransacaoPedagio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading_table: true,
            rowData: [],
            activePage: 1,
            taggy_serial: getSerialNumber(),
            cpf_current: null,
            cnpj_current: '',
            document: '',
            totalPage: 1,
            filter: false,
            is_cnpj: false,
            search: '',
            current_transacao: null,
            visible_contestacao: false,
            valor: 0,
            motivo: '',
            razao: '',
            contestacao_show: false,
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(this.props.document, 1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentDidMount() {
        getRows(this.props.document, this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    onFilter = (filter) => {
        this.setState({ filter, loading_table: true });
        getRowsPerPage(this.props.document, 1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(this.props.document, activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.documento !== this.props.documento) {
            getRows(nextProps.documento, this.state, this.callbackRows.bind(this))
        }
    }

    onData = (data) => {
        if (data.success === true) {
            toast.success(data.msg);
        }
        else {
            toast.error(data.errors);
        }
    }

    onAprovved = (id) => {
        var url = ENDPOINT + "contestacao/abrir_contestacao";

        var formData = new FormData();
        formData.append('motivo', this.state.motivo);
        formData.append('razao', this.state.razao);
        formData.append('transactionId', id);
        formData.append('valor', this.state.valor);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                this.onData(data);
                getRows(this.state, this.callbackRows.bind(this));
                this.setState({
                    visible_contestacao: false,
                    valor: 0,
                    motivo: '',
                    razao: '',
                })
                return data;
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    this.onData(obj);
                }
                var data = error.response.data;
                this.onData(data);
            });
    }
    getStatusTransaction = (status) => {
        if (status === 'authorized') {
            return 'Autorizado';
        }
        else if (status === 'refunded') {
            return 'Devolvido';
        }
        else {
            return 'Capturado';
        }
    }
    getCategoria = (type, axes) => {
        //var categoriaCodigo = 0;
        var categoria = '';
        //var axes = axes;

        if (type === 4) {
            if (axes === 2) {
                //categoriaCodigo = 410
                categoria = 'Ônibus com rodagem dupla - 2 eixos'
            }
            if (axes === 3) {
                //categoriaCodigo = 411
                categoria = 'Ônibus com rodagem dupla - 3 eixos'
            }
            if (axes === 4) {
                //categoriaCodigo = 412
                categoria = 'Ônibus com rodagem dupla - 4 eixos'
            }
        }
        if (type === 3) {
            if (axes === 2) {
                //categoriaCodigo = 305
                categoria = 'Caminhão Leve e Caminhão Trator - 2 eixos'
            }
            if (axes === 3) {
                //categoriaCodigo = 306
                categoria = 'Caminhão, Caminhão Trator e Caminhão Trator com Semireboque - 3 eixos'
            }
            if (axes === 4) {
                //categoriaCodigo = 307
                categoria = 'Caminhão com Reboque e Caminhão com Semireboque - 4 eixos'
            }
            if (axes === 5) {
                //categoriaCodigo = 308
                categoria = 'Caminhão com Reboque e Caminhão com Semireboque - 5 eixos'
            }
            if (axes === 6) {
                //categoriaCodigo = 309
                categoria = 'Caminhão com Reboque e Caminhão com Semireboque - 6 eixos'
            }
            if (axes === 7) {
                //categoriaCodigo = 313
                categoria = 'Caminhão com Reboque - 7 eixos'
            }
            if (axes === 8) {
                //categoriaCodigo = 314
                categoria = 'Caminhão com Reboque - 8 eixos'
            }
            if (axes === 9) {
                //categoriaCodigo = 315
                categoria = 'Caminhão com Reboque - 9 eixos'
            }
        }
        if (type === 1) {

            if (axes === 2) {
                //categoriaCodigo = 101
                categoria = 'Automóveis e Utilitários de rodagem simples - 2 eixos'
            }
            if (axes === 3) {
                //categoriaCodigo = 102
                categoria = 'Automóveis e Utilitários de rodagem simples - 3 eixos'
            }
            if (axes === 4) {
                //categoriaCodigo = 103
                categoria = 'Automóvel com reboque e Utilitários com reboque - 4 eixos'
            }
        }

        if (type === 5) {
            axes = 2
            //categoriaCodigo = 504
            categoria = 'Furgão de rodagem dupla - 2 eixos'
        }

        if (type === 2) {
            axes = 2
            //categoriaCodigo = 205
            categoria = 'Furgão de rodagem dupla - 2 eixos'
        }

        return categoria;
    }

    onValor = (event) => {
        var valor_current = event.target.value;
        if (valor_current.length > 1) {
            valor_current = valor_current.replaceAll('.', '');
            var digits = valor_current.substr(valor_current.length - 2);
            valor_current = valor_current.slice(0, -2);
            valor_current = valor_current + '.' + digits;
        }

        var valido = /^[\d,.?!]+$/.test(valor_current);
        if (valido === true || valor_current === '') {
            this.setState({ valor: valor_current });
        }
    };
    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }
    ExpandableRows = ({ data }) => {
        const getEstabelecimentoTipo = (status) => {
            if (status === 'TOLL_OPERATOR') {
                return 'Pedágio';
            }
            else if (status === 'PARKING') {
                return 'Estacionamento';
            }
            else {
                return status;
            }
        }
        const dataAll = JSON.parse(data.resposta);
        return <div style={{ margin: '20px' }}>
            <h4><GiBarrier style={{ width: '40px', height: '40px' }} /> Dados da Transação</h4>
            <hr />
            <p><b>Data da Transação:</b> {data.transacao_data}</p>
            <p><b>Usuário: </b>{data.tag_conta.user ? (data.tag_conta.user.nome + ' ' + data.tag_conta.user.sobrenome) : 'Sem informações'}</p>
            <p><b>CPF/CNPJ: </b>{data.tag_conta.user ? (data.tag_conta.user.cnpj ? data.tag_conta.user.cnpj : data.tag_conta.user.cpf) : 'Sem informações'}</p>
            <p><b>Local:</b> {data.sumario}</p>
            <p><b>Status da Transação:</b> {this.getStatusTransaction(data.status)}</p>
            <p><b>Nome do Estabelecimento:</b> {data.nome_estabelecimento}</p>
            <p><b>Tipo de Estabelecimento:</b> {getEstabelecimentoTipo(data.tipo_estabelecimento)}</p>
            <p><b>Placa:</b> {data.veiculo_placa}</p>
            <p><b>Endereço:</b> {data.endereco}</p>
            <p><b>Valor da Passagem:</b> R$ {parseFloat(data.valor_sinal).toFixed(2)}</p>
            <p><b>Serial da Taggy:</b> {data.serial == '0000000000000000' ? 'Não atribuido' : data.serial}</p>
            <p><b>Categoria Cadastrada:</b> {dataAll ? this.getCategoria(dataAll.entryData.metadata?.RegisteredCategoryVehicleType, dataAll.entryData.metadata?.RegisteredCategoryAxles) : 'Sem Informações'}</p>
            <p><b>Categoria Detectada:</b> {dataAll ? this.getCategoria(dataAll.entryData.metadata?.DetectedCategoryVehicleType, dataAll.entryData.metadata?.DetectedCategoryAxles) : 'Sem Informações'}</p>
            <p><b>Categoria Cobrada:</b> {dataAll ? this.getCategoria(dataAll.entryData.metadata?.ChargedCategoryVehicleType, dataAll.entryData.metadata?.ChargedCategoryAxles) : 'Sem Informações'}</p>
            <br />
            <Button color="success" onClick={() => this.setState({ contestacao_show: !this.state.contestacao_show })}>Contestar Transação</Button>
            {this.state.contestacao_show == true ?
                <Form>
                    <FormGroup>
                        <Input
                            type='text'
                            placeholder='Razão da contestação'
                            value={this.state.razao}
                            name="razao"
                            onChange={this.handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type='textarea'
                            placeholder='Descrição do Motivo da Contestação'
                            value={this.state.motivo}
                            name="motivo"
                            onChange={this.handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <p>Valor a Extornar: </p>
                        <CurrencyInput
                            inputMode="tel"
                            className="form-control"
                            currency="BRL"
                            name="valor_de"
                            //value={this.state.valor}
                            defaultValue={'0.00'}
                            onChange={this.onValor} />
                    </FormGroup>
                    <FormGroup>
                        <Button color='success' onClick={() => this.onAprovved(data.transacao_id)}>Salvar</Button>
                    </FormGroup>
                </Form>
                :
                <></>
            }
            {/* <ButtonOutline onClick={() => this.onOpenClosedTransacao(true, data)} style={{margin: 0}}><HiOutlineSpeakerphone />Abrir Contestação</ButtonOutline> */}
        </div>
    }
    onSearchDocument() {
        this.setState({ is_cnpj: !this.state.is_cnpj })
    }
    onOpenClosedTransacao(visible_contestacao, current_transacao) {
        this.setState({ visible_contestacao, current_transacao });
    }

    render() {
        return (
            <TabPane tabId={this.props.active}>
                <br />
                <h5>&nbsp;&nbsp;<MdNote style={{ width: '30px', height: '30px' }} /> Transações de {this.props.name}</h5>
                <br />
                <Card>
                    <TableTransacao
                        isSearch={this.handleChange}
                        data={this.state.rowData}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        taggy_serial={this.state.taggy_serial}
                        is_cnpj={this.state.is_cnpj}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        noDataComponent={this.state.loading_table === true ?
                            <Card style={{ border: 0, padding: '10vh' }}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{ border: 0 }}><CardBody><div>
                                Nenhuma transação encontrada
                            </div></CardBody></Card>
                        }
                        expandableRowsComponent={<this.ExpandableRows />}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <CreateContestacao
                    visible={this.state.visible_contestacao}
                    current_transacao={this.state.current_transacao}
                    onClose={() => this.onOpenClosedTransacao(false, null)}
                />
            </TabPane>
        )
    }
}

export default TransacaoPedagio;