import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import Moment from 'moment';
import { Button } from 'reactstrap';

export const getStatusTaggy = (value) => {
  switch(value){
       case 'aguardando_envio': return 'Aguardando envio';
       case 'aguardando_pagamento': return 'Aguardando pagamento';
       case 'inativo': return 'Aguardando ativação';
       case 'cancelado': return 'Cancelado';
       case 'ativado': return 'Ativado';
       case 'bloqueado': return 'Bloqueado';
       default: return value;
  }
} 

export const getStatus = (value) => {
  if (value.status == 'fechada') {
    if (new Date(value.vencimento_fatura) < new Date()) {
      return { status: 'Fatura vencida', cor: 'red' };
    }
  }
  switch (value.status) {
    case 'a vencer': return { status: 'Fatura a vencer', cor: 'blue' };
    case 'fechada': return { status: 'Fatura fechada', cor: '#b7a700' };
    case 'paga': return { status: 'Fatura paga', cor: '#10a100' };
    default: return { status: 'Fatura ' + value.status, cor: 'black' };
  }
} 


const forma_pagamento = [
    { value: "pix", label: "PIX" },
    { value: "boleto", label: "Boleto" },
    { value: "credit", label: "Crédito" },
    { value: "none", label: "Pagamento não necessario" },
];

export const columns = () => [
    {
      name: "Data",
      selector: "created_at",
      sortable: false,
      width: '100px',
      cell: p => Moment(p.created_at).format('DD/MM/YYYY')
    },
    {
      name: "Parcela",
      selector: "referente_a",
      sortable: false,
      width: '100px',
      cell: p => p.referente_a
    },
    {
      name: "Cliente",
      selector: "client_name",
      sortable: false,
      width: '270px'
    },
    {
      name: "Valor",
      selector: "valor",
      sortable: false,
      cell: p => 'R$ ' + parseFloat(p.valor).toFixed(2).replace('.', ',')
    },
    {
      name: "ID",
      selector: "id",
      sortable: false,
      cell: p => '#'+p.id
    },
    {
      name: "Status",
      selector: "status",
      sortable: false
    },
    {
      name: "Vencimento",
      selector: "created_at",
      sortable: false,
      width: '150px',
      cell: p => Moment(p.vencimento).format('DD/MM/YYYY')
    },
    {
      name: "Próximo Vencimento",
      selector: "created_at",
      sortable: false,
      width: '150px',
      cell: p => Moment(p.proxima_fatura).format('DD/MM/YYYY')
    },
];

export const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "aqcontabil-admin/fatura_list?search="+state.search+"&page="+state.activePage;

    var document = state.document.replace(/([^\d])+/gim, '');
    if(document.length > 10){
        url += "&cpf_cnpj="+document;
    }

    if(state.status_pagamento !== null){
        url += "&status_pagamento="+state.status_pagamento.value;
    }

    if (state.filter === true) {
        url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.meta.current_page, response.data.meta.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    var url = ENDPOINT + "aqcontabil-admin/fatura_list?search="+search+"&page="+page;

    var document = state.document.replace(/([^\d])+/gim, '');
    if(document.length > 10){
        url += "&cpf_cnpj="+document;
    }

    if(state.status_pagamento !== null){
        url += "&status_pagamento="+state.status_pagamento.value;
    }
    
    if (filter === true) {
        url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.meta.current_page, response.data.meta.last_page)
    });
}