import React, { useEffect, useState } from "react";
import { MdAttachMoney } from "react-icons/md";
import { Button, Card, CardBody, Row, TabPane, Col, Collapse } from "reactstrap";
import Moment from 'moment';
import FadeLoader from "react-spinners/FadeLoader";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { toast } from "react-toastify";


const getUser = (id, callback) => {
    var url = ENDPOINT + "user/get_aqbank/"+id;

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        callback(p);
    })
    .catch(e => {
        var data = {
            success: false
        }

        callback(data);
    })
}

const status_transacao = (payment_type) => {
    switch(payment_type){
        case 'succeeded' : return 'Aprovada';
        case 'canceled' : return 'Cancelada';
        case 'pending' : return 'Pendente';
        case 'failed' : return 'Falhada';
        default: return payment_type
    }
}
const status_prevencao = (payment_type) => {
    switch(payment_type){
        case 'dispute' : return 'Em Disputa';
        case 'charged_back' : return 'Chargeback';
        default: return payment_type
    }
}
const payment = (payment_type) => {
    switch(payment_type){
        case 'credit' : return 'Crédito';
        case 'boleto' : return 'Boleto';
        case 'debit' : return 'Débito';
        default: return payment_type
    }
}
export const InformacoesAqbank = (props) => {
    const [document, setDocument] = useState('');
    const [transacoes, setTransacoes] = useState([]);
    const [transacoes_show, setTransacoesShow] = useState(false);
    const [transferencias, setTransferencia] = useState([]);
    const [transferencias_show, setTransferenciaShow] = useState(false);
    const [contas_bancarias, setContasBancarias] = useState([]);
    const [contas_bancarias_show, setContasBancariasShow] = useState(false);
    const [prevencao, setPrevencao] = useState([]);
    const [prevencao_show, setPrevencaoShow] = useState(false);
    //const [lancamentos, setLancamentos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isClient, setIsClient] = useState(true);

    const clearDefault = () => {
        setLoading(true);
        //setLancamentos([]);
        setPrevencao([]);
        setContasBancarias([]);
        setTransferencia([]);
        setTransacoes([]);
        setDocument('')
    }


    const loadEffect = () => {
        if(props.active === props.activeCurrent){
            if(props.chat){
                if(props.chat.cpf_cnpj !== document){
                    clearDefault();
                    setDocument(props.chat.cpf_cnpj);
                    getUser(props.chat.cpf_cnpj,load_aqbank)
                }
            }
        }
        else{
            clearDefault();
        }
    }

    useEffect(() => {
        loadEffect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.activeCurrent]);

    useEffect(() => {
        loadEffect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chat]);

    const load_aqbank = (data) => {
        if(data.success === false){
            setIsClient(false);
            setLoading(false);
            toast.error(data.errors);
        }
        else{
            setIsClient(true);
            setTransacoes(data.data.transactions ? data.data.transactions : []);
            setContasBancarias(data.data.bank_accounts ? data.data.bank_accounts : []);
            setPrevencao(data.data.prevention ? data.data.prevention : []);
            setTransferencia(data.data.transfers? data.data.transfers : []);
            setLoading(false);
        }
    }

    return (
        <TabPane tabId={props.active}>
            <br />
            <Card style={{filter: 'drop-shadow(#b8b8b8 1px 1px 2px)', borderRadius: '1.25rem'}}>
                <CardBody hidden={!isClient}>
                    <h5>&nbsp;&nbsp;<MdAttachMoney style={{width: '30px', height: '30px'}}/> Inf. de Pagamento</h5>
                    <div hidden={loading} style={{maxHeight: '60vh', overflow: 'auto'}}>
                        <div style={{height: '100%', overflow: 'auto', padding: '15px'}}>
                            <b>Transações: </b>
                            <Button style={{padding: 0}} onClick={() => setTransacoesShow(!transacoes_show)}>
                                <AiFillCaretDown hidden={transacoes_show} style={{padding: 0}}/>
                                <AiFillCaretUp hidden={!transacoes_show} style={{padding: 0}}/>
                            </Button>
                            <Collapse isOpen={transacoes_show}>
                                {transacoes.map(p => {
                                    return (
                                        <>
                                        <Row style={{fontSize: '14px', paddingLeft: 16}}>
                                            <Col md={12}>
                                                <p style={{ marginBottom: 0 }}>{Moment(new Date(p.created_at).toDateString()).format('DD/MM/YYYY')}</p>
                                            </Col>
                                            <Col md={12}>
                                                <p style={{ marginBottom: 0 }}>{status_transacao(p.status)}</p>
                                            </Col>
                                            <Col md={12}>
                                                <p style={{ marginBottom: 0 }}>{p.transaction_number !== null ? p.transaction_number : 'Sem Informação'}</p>
                                            </Col>
                                            <Col md={12}>
                                                <p style={{ marginBottom: 0 }}>{payment(p.payment_type)}</p>
                                            </Col>
                                            <Col md={12}>
                                                <p style={{ marginBottom: 0 }}><b>Valor:</b> {p.amount}<br/>
                                                <b>Valor Líquido:</b> {p.original_amount}</p>
                                            </Col>
                                        </Row>
                                        <hr/>
                                    </>
                                )})}
                                {transacoes.length === 0 ? 'Nenhum histórico de Transações' : ''}
                            </Collapse>
                        </div>
                        <div style={{height: '100%', overflow: 'auto', padding: '15px'}}>
                            <b>Transferências: </b>
                            <Button style={{padding: 0}} onClick={() => setTransferenciaShow(!transferencias_show)}>
                                <AiFillCaretDown hidden={transferencias_show} style={{padding: 0}}/>
                                <AiFillCaretUp hidden={!transferencias_show} style={{padding: 0}}/>
                            </Button>
                            <Collapse isOpen={transferencias_show}>
                                {transferencias.map(p => {
                                    return (
                                        <>
                                        <Row style={{fontSize: '14px', paddingLeft: 16}}>
                                            <Col md={12} style={{alignSelf: 'center'}}>
                                                <p style={{ marginBottom: 0 }}>{Moment(new Date(p.created_at).toDateString()).format('DD/MM/YYYY')}</p>
                                            </Col>
                                            <Col md={12}>
                                                <p style={{ marginBottom: 0 }}>{status_transacao(p.status)}</p>
                                            </Col>
                                            <Col md={12}>
                                                <p style={{ marginBottom: 0 }}>{p.transfer_number !== null ? p.transfer_number : 'Sem Informação'}</p>
                                            </Col>
                                            <Col md={12}>
                                                <p style={{ marginBottom: 0 }}>{p.amount}</p>
                                            </Col>
                                        </Row>
                                        <hr/>
                                    </>
                                )})}
                            {transferencias.length === 0 ? 'Nenhum histórico de Transferências' : ''}
                            </Collapse>
                        </div>
                        <div style={{height: '100%', overflow: 'auto', padding: '15px'}}>
                            <b>Contas Bancarias: </b>
                            <Button style={{padding: 0}} onClick={() => setContasBancariasShow(!contas_bancarias_show)}>
                                <AiFillCaretDown hidden={contas_bancarias_show} style={{padding: 0}}/>
                                <AiFillCaretUp hidden={!contas_bancarias_show} style={{padding: 0}}/>
                            </Button>
                            <Collapse isOpen={contas_bancarias_show}>
                                {contas_bancarias.map(p => {
                                    return (
                                        <>
                                        <Row style={{fontSize: '14px', paddingLeft: 16}}>
                                            <Col md={12} style={{alignSelf: 'center'}}>
                                                <p style={{ marginBottom: 0 }}>{p.holder_name}</p>
                                            </Col>
                                            <Col md={12}>
                                                <p style={{ marginBottom: 0 }}>{p.is_active === true ? 'Ativo' : 'Inativo'}</p>
                                            </Col>
                                            <Col md={12}>
                                                <p style={{ marginBottom: 0 }}>{p.taxpayer_id}</p>
                                            </Col>
                                            <Col md={12}>
                                                <p style={{ marginBottom: 0 }}>{p.bank_name}</p>
                                            </Col>
                                            <Col md={12}>
                                                <p style={{ marginBottom: 0 }}><b>Conta:</b> {p.account_number}<br/>
                                                <b>Agência:</b> {p.routing_number}</p>
                                            </Col>
                                            <Col md={12}>
                                                <p style={{ marginBottom: 0 }}>{Moment(new Date(p.created_at).toDateString()).format('DD/MM/YYYY')}</p>
                                            </Col>
                                        </Row>
                                        <hr/>
                                    </>
                                )})}
                                {contas_bancarias.length === 0 ? 'Nenhum histórico de Contas Bancárias' : ''}
                            </Collapse>
                        </div>
                        <div style={{height: '100%', overflow: 'auto', padding: '15px'}}>
                            <b>Prevenção e Risco: </b>
                            <Button style={{padding: 0}} onClick={() => setPrevencaoShow(!prevencao_show)}>
                                <AiFillCaretDown hidden={prevencao_show} style={{padding: 0}}/>
                                <AiFillCaretUp hidden={!prevencao_show} style={{padding: 0}}/>
                            </Button>
                            <Collapse isOpen={prevencao_show}>
                                {prevencao.map(p => {
                                    return (
                                        <>
                                        <Row style={{fontSize: '14px', paddingLeft: 16}}>
                                            <Col md={12}>
                                                <p style={{ marginBottom: 0 }}>{Moment(new Date(p.created_at).toDateString()).format('DD/MM/YYYY')}</p>
                                            </Col>
                                            <Col md={12} >
                                                <p style={{ marginBottom: 0 }}>{status_prevencao(p.status)}</p>
                                            </Col>
                                            <Col md={12} >
                                                <p style={{ marginBottom: 0 }}>{p.transaction_number !== null ? p.transaction_number : 'Sem Informação'}</p>
                                            </Col>
                                            <Col md={12} >
                                                <p style={{ marginBottom: 0 }}>{payment(p.payment_type)}</p>
                                            </Col>
                                            <Col md={12} >
                                                <p style={{ marginBottom: 0 }}><b>Valor:</b> {p.amount}<br/>
                                                <b>Taxa:</b> {p.fees}<br/>
                                                <b>Valor Liquido:</b> {p.original_amount}</p>
                                            </Col>
                                        </Row>
                                        <hr/>
                                    </>
                                )})}
                                {prevencao.length === 0 ? 'Nenhum histórico de Prevenção & Riscos' : ''}
                            </Collapse>
                        </div>
                    </div>
                    <div hidden={!loading} style={{height: '60vh', textAlign: 'center', marginRight: '30px'}}>
                        <FadeLoader color={'#52c565'} margin={0} loading={50} size={60} /><br />
                    </div>
                </CardBody>
                <CardBody hidden={isClient}>
                    <div hidden={!loading} style={{height: '60vh', textAlign: 'center', marginRight: '30px'}}>
                        <FadeLoader color={'#52c565'} margin={0} loading={50} size={60} /><br />
                    </div>
                    <div hidden={loading} style={{height: '60vh', textAlign: 'center', marginRight: '30px'}}>
                        <h4>Esse cliente não está cadastrado em nenhum plano de taxas</h4>
                    </div>
                </CardBody>
            </Card>
        </TabPane>
    )
}

//TabContent