import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';

export const columns = () => [
  {
    name: "Título",
    selector: "titulo",
    sortable: false,
    width: '20%',
    cell: row => <>{row.pesquisa.titulo}</>
  },
  {
    name: "Descrição",
    selector: "descricao",
    sortable: false,
    width: '25%',
    cell: row => <>{row.pesquisa.descricao}</>
  },
  {
    name: "Avaliação",
    selector: "avaliacao",
    sortable: false,
    width: '100px',
    cell: row => <>{row.avaliacao}</>
  },
  {
    name: "Respostas de contato",
    selector: "media",
    sortable: false,
    width: '20%',
    cell: row => <>
        <ul>
            {row.contatos.map((p, i) => {
                return <li>{p.titulo}: {p.resposta !== null ? p.resposta.valor : 'Sem resposta'}</li>
            })}
            {row.contatos.length === 0 ? 'Sem resposta' : ''}
        </ul>
    </>
  },
  {
    name: "Respostas",
    selector: "media",
    sortable: false,
    width: '25%',
    cell: row => <>
        <ul>
            {row.perguntas_adicionais.map((p, i) => {
                return <li>{p.titulo}: {p.resposta !== null ? p.resposta.valor : 'Sem resposta'}</li>
            })}
            {row.perguntas_adicionais.length === 0 ? 'Sem resposta' : ''}
        </ul>
    </>
  }
];

export const getRows = (state, callbackRows) => {
  var url = ENDPOINT + "nps/list_respostas?search=" + state.search+ "&page=" + state.activePage;
  
  if (state.filter === true) {
    url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
  }

  axios.get(url, {
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  }).then((response) => {
    callbackRows(response.data.data, response.data.current_page, response.data.last_page)
  });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
  
  var url = ENDPOINT + "nps/list_respostas?search=" + search + "&page=" + page;

  if (filter === true) {
    url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
  }


  axios.get(url, {
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  }).then((response) => {
    callbackRows(response.data.data, response.data.current_page, response.data.last_page)
  });
}