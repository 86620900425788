import { Component } from "react";
import { Modal, ModalBody, Row, Col, Form, FormGroup, Input, ModalFooter, Button, Card, CardBody, } from 'reactstrap';
import Dropzone, { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { InputText } from "../../components/InputText/InputText";
import { useState } from "react";
import { toast } from "react-toastify";
import { getRows, getRowsPerPage } from "../../components/Tables/TableCampanha/propsCampanha";
import { TableCampanha } from "../../components/Tables/TableCampanha/TableCampanha";
import axios from "axios";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import moment from 'moment';
import PropagateLoader from "react-spinners/PropagateLoader";
import ModalDialog from "../../components/Modals/ReturnsApiModals/ModalDialog";

const Div = styled.div`
`;
const DropArea = styled.div`
    border-style: dashed;
    border-color: #8300b6;
    padding: 1%;
    cursor: pointer;
    &:drag {
        opacity: 0.5;
    }

    .dropzone{
        height: 170px;
    }
`;
const ModalCampanha = (props) => {
    const [mes, setMes] = useState('');
    const [ano, setAno] = useState('');
    const [descricao, setDescricao] = useState('');
    const [loading, setLoading] = useState(false);


    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({});
    const files = acceptedFiles.map(file => <li key={file.path}>{file.path}</li>);

    const onDataToast = (data) => {
        if (data.success === true) {
            toast.success(data.message);
        }
        else {
            toast.warning(data.errors);
        }
    }
    const onClose = () => {
        setMes('');
        setAno('');
        setDescricao('');
        setLoading(false);
        props.onClose(false);
    }
    const store = () => {
        if(mes == ''){
            toast.error('Selecione o Mês');
            return;
        }

        if(ano == ''){
            toast.error('Selecione o Ano');
            return;
        }

        if(acceptedFiles.length == 0){
            toast.error('Selecione o Banner');
            return;
        }

        var url = ENDPOINT+'faturas/nova_campanha'
        setLoading(true);

        var formData = new FormData();
        formData.append('mes', parseInt(mes, 10));
        formData.append('ano', parseInt(ano, 10));
        formData.append('descricao', descricao);
        formData.append('anexo_file', acceptedFiles[0]);
    
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            onDataToast(data);
            onClose()
            return data;
        })
        .catch((error) => {
            setLoading(false);
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                onDataToast(obj);
                return obj;
            }
            var data = error.response.data;
            onDataToast(data);
            return data;
        });
    }
    
    const yearsList = () => {
        var rows = [];
        var ano_atual = new Date().getFullYear();
        var ano_add = ano_atual + 10;

        for (var i = ano_atual; i < ano_add; i++) {
            rows.push(<option key={i}>{i}</option>);
        }

        return rows;
    }
    return (
        <Modal isOpen={props.visible} size="lg">
            <ModalBody>
                <Row>
                    <Col>
                        <DropArea className="container">
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                {files.length > 0 ?
                                    <div style={{ paddingTop: '61px', textAlign: 'center' }}>
                                        <h6>Arquivo:</h6>
                                        <ul style={{ padding: 0, listStyle: 'none' }}>{files}</ul>
                                    </div>
                                    :
                                    <p style={{ paddingTop: '74px', textAlign: 'center' }}>Arraste o banner a ser adicionado</p>
                                }
                            </div>
                        </DropArea>
                    </Col>
                    <Col>
                        <Form>
                            <FormGroup>
                                <Input
                                    type="select"
                                    value={mes}
                                    onChange={(event) => setMes(event.target.value)}
                                    placeholder="Mês">
                                    <option>Selecione o mês</option>
                                    <option value="01">Janeiro</option>
                                    <option value="02">Fevereiro</option>
                                    <option value="03">Março</option>
                                    <option value="04">Abril</option>
                                    <option value="05">Maio</option>
                                    <option value="06">Junho</option>
                                    <option value="07">Julho</option>
                                    <option value="08">Agosto</option>
                                    <option value="09">Setembro</option>
                                    <option value="10">Outubro</option>
                                    <option value="11">Novembro</option>
                                    <option value="12">Dezembro</option>
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="select"
                                    value={ano}
                                    onChange={(event) => setAno(event.target.value)}
                                    placeholder="Ano">
                                    <option>Selecione o ano</option>
                                    {yearsList()}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="textarea"
                                    value={descricao}
                                    onChange={(event) => setDescricao(event.target.value)}
                                    placeholder="Descrição" />
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={() => onClose()}>Cancelar</Button>
                <Button color="success" disabled={loading} onClick={() => store()}>Salvar</Button>
            </ModalFooter>
        </Modal>
    )
}
class Campanha extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            activePage: 1,
            loading_table: true,
            //email: getId(),
            totalPage: 1,
            filter: false,
            visible_nps: false,

            cod_pergunta_index: '',
            visible_edit: false,
            search: '',
            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            delete_id: -1,
            error_message: '',
            success_message: '',
            loading: false,
            visible_delete: false,
        }
    }
    

    onShowDelete = (delete_id) => {
        this.setState({
            delete_id,
            visible_delete: true
        })
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentDidMount() {
        getRows(this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    onFilter = (filter) => {
        this.setState({ filter });
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onClose(visible_nps) {
        this.setState({ visible_nps });
        if (visible_nps === false) {
            getRows(this.state, this.callbackRows.bind(this));
        }
    }
    
    onDelete = () => {
        this.setState({loading: true})
        var url = ENDPOINT+"faturas/delete_campanha/"+this.state.delete_id;
        axios.delete(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.onShowDelete(-1);
            this.setState({visible_delete: false});
            getRows(this.state, this.callbackRows.bind(this));
            return data;
        })
        .catch((error) => {
            var response = '';
            var obj;
            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            if(data.exception){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            this.onData(data);
            return data;
        });
    }

    onData = (data) => {
        if(data.success === true){
            this.setState({
                success_message: data.message
            });
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onCloseEdit(visible_edit) {
        this.setState({ visible_edit });
        if (visible_edit === false) {
            this.setState({ cod_pergunta_index: '' })
            getRows(this.state, this.callbackRows.bind(this));
        }
    }
    onOpenEdit(cod_pergunta_index) {
        this.setState({ cod_pergunta_index, visible_edit: true });
    }

    onError(){
        this.setState({
            loading: false,
            error_message: ''
        })
    }
    render() {
        return (
            <div>
                <h3>Gerenciamento de Campanha</h3>
                <hr />
                <div>
                    <Button color="success" onClick={() => this.onClose(true)}>Criar Campanha</Button>
                    <br /><br />
                    <Card>
                        <TableCampanha
                            isSearch={this.handleChange}
                            onShowDelete={this.onShowDelete}
                            data={this.state.rowData}
                            phone={this.state.phone}
                            onFilter={this.onFilter.bind(this)}
                            onPageChange={this.onPageChange.bind(this)}
                            filter={this.state.filter}
                            noDataComponent={this.state.loading_table === true ?
                                <Card style={{ border: 0, padding: '10vh' }}><CardBody>
                                    <div><div className="sweet-loading">
                                        <PropagateLoader color={'#52c565'} loading={20} size={30} />
                                    </div></div></CardBody></Card> :
                                <Card style={{ border: 0 }}><CardBody><div>
                                    Nenhuma pesquisa encontrada
                                </div></CardBody></Card>
                            }
                            activePage={this.state.activePage}
                            totalPage={this.state.totalPage}
                            date_from={this.state.date_from}
                            date_to={this.state.date_to}
                        />
                    </Card>
                    <ModalCampanha
                        visible={this.state.visible_nps}
                        onClose={() => this.onClose(false)}
                    />
                    <ModalDialog
                        visible={this.state.visible_delete}
                        onError={this.onError.bind(this)}
                        message="Deseja excluir essa Campanha? Essa operação não poderá ser desfeita"
                        onClose={() => {
                            this.onShowDelete(-1);
                            this.setState({visible_delete: false});
                            getRows(this.state, this.callbackRows.bind(this));
                        }}
                        error_message={this.state.error_message}
                        loading={this.state.loading}
                        onSave={() => this.onDelete()}
                        success_message={this.state.success_message}
                    />
                </div>
            </div>
        )
    }
}

export default Campanha;