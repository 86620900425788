import React, { Component } from 'react';
import { Button, Card, CardBody, TabPane } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { TableSMS } from '../../components/Tables/TableSMS/TableSMS';
import Moment from 'moment';
import { ENDPOINT } from '../../auth/endpoint';
import axios from 'axios';
import { getPermissoes, getToken, getUser } from '../../auth/set_services';
import { MdSms } from 'react-icons/md';
import SendSms from '../Modals/SendsModals/SendSms';

const getRows = (communication_relacao_id, state, callbackRows, phone = false) => {
    if(typeof communication_relacao_id === 'undefined' && !phone){
        return null;
    }
    
    //var url = ENDPOINT + "sms/list?communication_relacao_id="+communication_relacao_id;
    var url = ENDPOINT;
    
    if (phone) {
        url += `sms/list?phone=${phone}`;
    } else {
        url += `sms/list?communication_relacao_id=${communication_relacao_id}`;
    }

    axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + getToken()
      }
    }).then((response) => {
      callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
  }
  
const getRowsPerPage = (communication_relacao_id, page, search, filter, state, callbackRows, phone = false) => {
    if(typeof communication_relacao_id === 'undefined' && !phone){
        return null;
    }

    //var url = ENDPOINT + "sms/list?communication_relacao_id="+communication_relacao_id+"&search=" + search + "&page=" + page;
    var url = ENDPOINT;
    
    if (phone) {
        url += `sms/list?phone=${phone}&search=${search}&page=${page}`;
    } else {
        url += `sms/list?communication_relacao_id=${communication_relacao_id}&search=${search}&page=${page}`;
    }

    if (filter === true) {
      url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }
    
    axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + getToken()
      }
    }).then((response) => {
      callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

class SmsClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            activePage: 1,
            loading_table: true,
            //email: getId(),
            client_sms: false,
            totalPage: 1,
            filter: false,
            update: false,
            search: '',
            visible_sms: false,
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(this.props.communication_relacao_id, 1, state.search, this.state.filter, state, this.callbackRows.bind(this), this.props?.telefone);
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.communication_relacao_id !== this.props.communication_relacao_id) {
            getRows(nextProps.communication_relacao_id, this.state, this.callbackRows.bind(this), this.props?.telefone)
        }

        if (!this.state.update && this.props?.telefone) {
            getRows(nextProps.communication_relacao_id, this.state, this.callbackRows.bind(this), this.props?.telefone)
            this.setState({update: true})
        }
    }
    componentDidMount() {
        getRows(this.props.phone, this.state, this.callbackRows.bind(this), this.props?.telefone);
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    onFilter = (filter) => {
        this.setState({ filter, rowData: [], activePage: 1, loading_table: true });
        getRowsPerPage(this.props.communication_relacao_id, 1, this.state.search, filter, this.state, this.callbackRows.bind(this), this.props?.telefone);
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(this.props.communication_relacao_id, activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this), this.props?.telefone);
    }
    onClose = (visible_sms) => {
        this.setState({visible_sms})
    }
    render() {
        return (
            <TabPane tabId={this.props.active}>
            <br />
            <h5>&nbsp;&nbsp;<MdSms style={{width: '30px', height: '30px'}}/> SMS's de {this.props.name}</h5>
            
            <div hidden={!getUser().permissao_omnichannel}>
                <Button onClick={() => this.onClose(true)} style={{marginBottom: 10}} color="success"><MdSms /> Novo SMS</Button>
            </div>
            <Card>
                    <TableSMS
                        clientSMS
                        isSearch={this.handleChange}
                        data={this.state.rowData}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        noDataComponent={this.state.loading_table === true ?
                            <Card style={{ border: 0, padding: '10vh' }}><CardBody>
                                <div><div className="sweet-loading">
                                    <PropagateLoader color={'#52c565'} loading={20} size={30} />
                                </div></div></CardBody></Card> :
                            <Card style={{ border: 0 }}><CardBody><div>
                                Nenhum e-mail encontrado
                            </div></CardBody></Card>
                        }
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <SendSms
                    is_phone={this.props.telefone}
                    communication_relacao_id={this.props.communication_relacao_id}
                    visible={this.state.visible_sms}
                    onClose={() => this.onClose(false)}
                />
            </TabPane>
        )
    }
}

export default SmsClient;