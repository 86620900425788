import React from 'react';
import { FaMoneyBillWave } from 'react-icons/fa';
import { TabPane } from 'reactstrap';
import Lancamentos from '../UsersList/Lancamentos';

const ProximosLancamentos = (props) => {
    return (
        <TabPane tabId={props.active}>
            <br />
            <h5>&nbsp;&nbsp;
                <FaMoneyBillWave style={{width: '30px', height: '30px'}}/> 
                Lançamentos futuros de {props.name} </h5>
            <br/>
            <Lancamentos data={props.data} />
        </TabPane>
    )
}

export default ProximosLancamentos;