import React from "react";
import { Button, CardBody, Col, Collapse, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import styled from "styled-components";
import logoAqbank from '../../assets/logo.png';
import { InputText } from "../InputText/InputText";
import NumberFormat from "react-number-format";
import Carousel from 'react-elastic-carousel';
import { GiTakeMyMoney } from "react-icons/gi";
import { MdAdd, MdCreditCard } from "react-icons/md";
import { ImBarcode } from "react-icons/im";
import { ButtonOutline } from '../Button/ButtonOutline';
import Cards from 'react-credit-cards';
import "react-credit-cards/es/styles-compiled.css"
import { InputMasked } from "../InputText/InputMasked";
import StyleCreditCard from "../CreditCard/StyleCreditCard";
import { ButtonDefault } from "../Button/ButtonDefault";

const ImageLogo = styled.img`
    padding-bottom: 15px;
    width: 100px;
`;
const CardTaggy = styled.div`
    background-color: rgb(28, 28, 28);
    border-radius: 10px;
    width: 400px;
    color: #fff;
`
const GroupFunction = styled.div`
    display: inline-flex;
    place-items: center;
    font-size: 29px;
    color: #fff;
`;
const ButtonD = styled.button`
    margin-left: 1em;
    margin-right: 1em;
    padding: 4px 15px;
    border-radius: 100%;
    border: 1px solid #52c565;
    color: #fff;
    font-weight: 400;
    font-size: 23px;
    background-color: #52c565;
    
    &:disabled {
        border: 1px solid #52c565;
        background-color: #52c565;
    }
`;

const Plan = styled.div`
    background-color: #641084;
    width: 165px;
    height: 153px;
    font-size: 15px;
    color: white;
    border-radius: 100%;
    filter: drop-shadow(0px 3px 5px #3f0854);
    margin: 15px;
`;
const PlanBackground = styled.div`    
    background-color: #79259a;
    height: 70%;
    width: 148px;
    border-radius: 74px 74px 2vw 2vw;
    filter: drop-shadow(0px 3px 5px #3f0854);
`;
const PlanValue = styled.p`
    position: absolute;
    top: 50px;
    left: 5.6%;
    font-size: 29px;
    font-weight: 546;
    
    small {
        font-size: 17px;
        font-weight: 700;
    }
`
const StringRecarga = styled.div`
    p {
        padding: 0;
        margin-top: -6px;
    }
`;
const Title = styled.div`
    svg {
        width: 2.5rem;
        height: 2.5rem;
        padding-right: .5rem;
        color: #641083;
    }
`
const ButtonRefresh = (props) => {
    if(props.type === props.current){
        return <ButtonDefault {...props}> {props.children}</ButtonDefault>;
    }
    else{
        return <ButtonOutline {...props}> {props.children}</ButtonOutline>;
    }
}
/* const tratarIcone = (bandeira) => {
    if(bandeira === 'hipercard'){
        return <PaymentIcon transparent id={bandeira} color="white" style={{width: '54px'}}/>
    }
    if(bandeira === 'american_express'){
        return <PaymentIcon id='amex' color="white" style={{width: '54px'}}/>
    }
    return <PaymentIcon id={bandeira} color="white" style={{width: '54px'}}/>
} */
const FormTaggys = (props) => {
    return (
        <center>
            <Row>
                <Col md={12} >
                    <Row>
                        <Col>
                            <Title><h5><GiTakeMyMoney /> Qual o valor da recarga?</h5></Title>
                            <Carousel onChange={props.onRecarga}>
                                {props.list_plan.map(p => {
                                    return <div>
                                        <Plan>
                                            <PlanBackground>
                                                <PlanValue>
                                                    <small>R$</small> <b>{p.valor_label}</b>
                                                </PlanValue>
                                            </PlanBackground>
                                            <StringRecarga>
                                                <small>EFETUAR </small>
                                                <p><b>RECARGA</b></p>
                                            </StringRecarga>
                                        </Plan>
                                    </div>
                                })}
                            </Carousel>
                        </Col>
                    </Row>
                    {/* <NumberFormat
                        name="valor_deposito"
                        placeholder="Valor do plano"
                        customInput={InputText}
                        decimalScale={2}
                        value={props.state.valor_deposito}
                        invalid={props.state.valor_deposito_invalid}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        onChange={props.onChange}
                    /> */}
                </Col>
            </Row>
            <hr />
            <CardTaggy className="card">
                <CardBody>
                    <Row>
                        <Col>
                            <ImageLogo src={logoAqbank} />
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                            <img
                                src="https://www.taggy.com.br/images/taggy-branco.svg"
                                width={65}
                                alt="Logo" /><br /><br />

                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h5>Selecione o número de Taggys</h5>
                            <GroupFunction>
                                <ButtonD onClick={() => props.onChangeTaggy(props.taggys - 1)} disabled={props.taggys === 1}><h3 style={{ marginLeft: '3px', marginRight: '3px' }}>-</h3></ButtonD>
                                <p style={{ margin: 0 }}>{props.taggys}</p>
                                <ButtonD onClick={() => props.onChangeTaggy(props.taggys + 1)}><h3>+</h3></ButtonD>
                            </GroupFunction>
                        </Col>
                    </Row>
                </CardBody>
            </CardTaggy>
            <br />
            <div>
                <hr />
                <h5>Escolha a forma de pagamento</h5>
                <center>
                    <ButtonRefresh onClick={() => props.onPaymentType('credit')} type={props.state.forma_de_pagamento} current="credit"><MdCreditCard /> Cartão de Crédito</ButtonRefresh>
                    <ButtonRefresh onClick={() => props.onPaymentType('boleto')} type={props.state.forma_de_pagamento} current="boleto"><ImBarcode /> Boleto Bancario</ButtonRefresh>
                </center>
                <center hidden={props.state.forma_de_pagamento === 'credit' ? false : true}>
                    <ButtonDefault hidden={props.state.list_cards.length === 0 ? true : false} onClick={() => props.onModalPayment('credit_list')}><MdCreditCard /> Cartão já cadastrado</ButtonDefault>
                    <ButtonDefault onClick={() => props.onModalPayment('credit_new')}><MdAdd /> Novo cartão</ButtonDefault>
                </center>
            </div>
            <Collapse isOpen={props.state.card !== null ?  true: false} style={{textAlign: 'left', width: '72%'}}>
                {props.state.card !== null ? 
                    <div style={{textAlign: 'left', width: '72%'}}>
                        <MdCreditCard/><b>Número:</b> **** **** **** {props.state.card.numero.substr(-4)}<br/>
                        <MdCreditCard/><b>Validade:</b> {props.state.card.validade}<br/>
                    </div>
                : <div></div>}
            </Collapse>
            <br />
            {ListModalCard(props)}
            {ModalCard(props)}
        </center>
    )
}
const ListModalCard = (props) => {
    return (
        <Modal isOpen={props.state.modal_type === 'credit_list'} size={'lg'}>
            <ModalHeader>Escolher cartão</ModalHeader>
            <ModalBody>
                <Carousel onChange={props.onChangeCard}>
                    {props.state.list_cards.map((p, i) => {
                        return (
                            <div>
                                <StyleCreditCard 
                                    status={p.status} 
                                    validade={p.validade_mes+"/"+p.validade_ano} 
                                    numero_cartao={p.numero} 
                                    bandeira={p.bandeira.replaceAll(' ', '_').toLowerCase()}
                                />    
                            </div>
                        )
                    })}
                </Carousel>
                <center><ButtonOutline onClick={() => props.selectedCard(props.state.currentCard, 193)}><MdCreditCard style={{height: '24px', width: '32px'}} /> Selecionar Cartão</ButtonOutline></center>
            </ModalBody>
            <ModalFooter>
                    <Button color="danger" onClick={() => props.onModalPayment('')}>Fechar</Button>
            </ModalFooter>
        </Modal>
    )
}

const ModalCard = (props) => {
    return (
        <Modal isOpen={props.state.modal_type === 'credit_new'}>
            <ModalHeader>Adicionar cartão</ModalHeader>
            <ModalBody>
                <Form>
                    <Row>
                        <Col>
                            <Cards
                                cvc={props.state.cvc}
                                expiry={props.state.expiry}
                                focused={props.state.focus}
                                name={props.state.name}
                                number={props.state.number}
                            />
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <NumberFormat
                                                customInput={InputText}
                                                type="tel"
                                                name="number"
                                                placeholder="Número de Cartão de Crédito"
                                                onChange={props.handleInputChange}
                                                onFocus={props.handleInputFocus}
                                                format="#### #### #### ####"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <InputText
                                                type="text"
                                                name="name"
                                                placeholder="Nome (Como está no cartão)"
                                                onChange={props.handleInputChange}
                                                onFocus={props.handleInputFocus}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InputMasked
                                            type="text"
                                            name="expiry"
                                            mask={'99/99'}
                                            placeholder="Validade"
                                            onChange={props.handleInputChange}
                                            onFocus={props.handleInputFocus}
                                        />
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <NumberFormat
                                                customInput={InputText}
                                                type="tel"
                                                name="cvc"
                                                placeholder="CVC"
                                                onChange={props.handleInputChange}
                                                onFocus={props.handleInputFocus}
                                                format="###"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={() => props.selectedNewCard()}>Cadastrar</Button>
                <Button color="danger" onClick={() => props.onModalPayment('')}>Fechar</Button>
            </ModalFooter>
        </Modal>
    )
}
export default FormTaggys;