import { Component } from "react";
import { MdSearch } from "react-icons/md";
import Select from "react-select";
import { Button, Col, Modal, Input, ModalBody, ModalFooter, ModalHeader, Row, FormGroup, FormFeedback } from "reactstrap";
import { InputMasked } from "../../InputText/InputMasked";
import { InputText } from "../../InputText/InputText";
import axios from 'axios';
import { getToken } from '../../../auth/set_services';
import { ENDPOINT } from "../../../auth/endpoint";
import NumberFormat from "react-number-format";
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import ClipLoader from 'react-spinners/ClipLoader';
import { cpf } from "cpf-cnpj-validator"

var CurrencyInput = require('react-currency-masked-input');

const conta_tipo = [
    {value: 'checking', label: 'Conta Poupança'},
    {value: 'savings', label: 'Conta Corrente'}
]
const getBusiness = (cnpj, onData) => {
    var url = ENDPOINT+"user/search_cnpj?cnpj="+cnpj.replace(/([^\d])+/gim, '');
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        console.log(error)
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });
}

const getUser = (cpf, onData) => {
    var url = ENDPOINT+"user/search_cpf?cpf="+cpf.replace(/([^\d])+/gim, '');
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });

}
const getMCC = (onData) => {
    var url = ENDPOINT+"user/mcc";
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });

}
const getPlanos = (onData) => {
    var url = ENDPOINT+"user/planos";
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });

}



class AQContabilModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            isCnpj: false,
            step: 0,
            socios: [],
            mcc_list: [],
            planos_list: [],
            request: {},
            
            socio_checked_add: false,
            socio_checked_add_invalid: false,
            socios_adds: [],

            cpf_add: '',
            cpf_add_invalid: false,
            cep_add: '',
            cep_add_invalid: false,
            logradouro_add: '',
            logradouro_add_invalid: false,
            numero_add: '',
            numero_add_invalid: false,
            complemento_add: '',
            complemento_add_invalid: false,
            bairro_add: '',
            bairro_add_invalid: false,
            cidade_add: '',
            cidade_add_invalid: false,
            uf_add: '',
            uf_add_invalid: false,

            nome_fantasia_pretendido: '',
            nome_fantasia_pretendido_invalid: false,
            capital_social: 0,
            capital_social_invalid: false,
            porte_empresa: '',
            porte_empresa_invalid: false,
            mcc: null,
            mcc_invalid: false,
            plano: null,
            plano_invalid: false,
            cep: '',
            logradouro: '',
            logradouro_invalid: false,
            numero: '',
            numero_invalid: false,
            complemento: '',
            complemento_invalid: false,
            bairro: '',
            bairro_invalid: false,
            cidade: '',
            cidade_invalid: false,
            uf: '',
            uf_invalid: false,
            holder_name: '',
            holder_name_invalid: false,
            bank_code: '',
            bank_code_invalid: false,
            routing_number: '',
            routing_number_invalid: false,
            account_number: '',
            account_number_invalid: false,
            type: '',
            type_invalid: false,
            email: '',
            email_invalid: false,
            telefone: '',
            telefone_invalid: false,
            document: '',
            document_invalid: false,
            document_loading: false,
            socio: null,
            socio_invalid: false,
            loading: false,
            error_message: '',
            success_message: '',
        }
    }
    componentDidMount(){
        getMCC(this.onMcc.bind(this));
        getPlanos(this.onPlano.bind(this))
    }
    handleSelect = (type) => {
        this.setState({type})
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        
        this.setState({[name]: value});

        if(name === 'cep'){
            if(value.replace(/([^\d])+/gim, '').length > 7){
                this.getCep(value)
            }
        }
    }
    
    removeSocio = (index) => {
        var socios = this.state.socios_adds;
        socios.splice(index, 1);
        this.setState({ socios_adds: socios });
    }
    onSearch(){
        this.setState({document_loading: true})
        if(this.state.isCnpj === true){
            getBusiness(this.state.document, this.onCNPJ.bind(this));
        }
        else{
            getUser(this.state.document, this.onCPF.bind(this));
        }
    }
    onMcc(data){
        if(data.success === true){
            var mcc_list = []
            data.data.forEach(e => {
                mcc_list.push({
                    value: e.id,
                    label: e.category + ' - ' + e.description
                })
            });

            this.setState({mcc_list});
        }
    }
    onPlano(data){
        if(data.success === true){
            var planos_list = []
            data.data.forEach(e => {
                planos_list.push({
                    value: e.id,
                    label: e.name
                })
            });

            this.setState({planos_list});
        }
    }
    onCNPJ(data){
        if(data.success === true){
            var socios = [];
            var socio = null;
            if(data.data.socios){
                data.data.socios.forEach(e => {
                    socios.push({
                        value: e.nome,
                        label: e.nome
                    })
                });
    
                socio = {
                    value: data.data.socios[0].nome,
                    label: data.data.socios[0].nome
                }
            }
            else{
                socios.push({
                    value: data.data.nomeEmpresarial,
                    label: data.data.nomeEmpresarial
                })
    
                socio = {
                    value: data.data.nomeEmpresarial,
                    label: data.data.nomeEmpresarial
                }
            }
            this.setState({
                document_invalid: false,
                document_loading: false,
                step: 2,
                request: data.data,
                email: '',
                socios,
                socio
            });
        }
        else{
            this.setState({
                document_invalid: true,
                document_loading: false,
                step: 0,
                request: {},
                email: '',
                socios: [],
                socio: null
            });
        }
    }
    onCPF(data){
        if(data.success === true){
            this.setState({
                document_invalid: false,
                document_loading: false,
                step: 1,
                request: data.data,
                email: '',
                socios: [],
                socio: null
            });
        }
        else{
            this.setState({
                document_invalid: true,
                document_loading: false,
                step: 0,
                request: {},
                email: '',
                socios: [],
                socio: null
            });
        }
    }
    getCep = (value) => {
        var url = ENDPOINT+'user/search_cep?cep='+value;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            if(data.success === true){
                this.setState({
                    logradouro: data.data.end,
                    complemento: data.data.complemento2,
                    uf: data.data.uf,
                    bairro: data.data.bairro,
                    cidade: data.data.cidade
                })
            }
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            this.onData(data);
            return data;
        });

    }
    storeUser = () => {
        var url = ENDPOINT+"user/store_user";

        var formData = new FormData();
        formData.append('document', this.state.request.ni.replace(/([^\d])+/gim, ''));
        if(this.state.step === 1){
            formData.append('telefone', this.state.telefone.replace(/([^\d])+/gim, ''));
            formData.append('cep', this.state.cep);
            formData.append('rua', this.state.logradouro);
            formData.append('numero', this.state.numero);
            formData.append('complemento', this.state.complemento);
            formData.append('bairro', this.state.bairro);
            formData.append('cidade', this.state.cidade);
            formData.append('uf', this.state.uf);
            formData.append('pais', 'Brasil');
        }
        else{
            formData.append('socio_name', this.state.socio.value);
            if(this.state.request.telefones){
                formData.append('telefone', this.state.request.telefones[0].ddd + ''+this.state.request.telefones[0].numero);
            }
            else{
                formData.append('telefone', this.state.telefone.replace(/([^\d])+/gim, ''));
            }
        }
        formData.append('email', this.state.email);
        //formData.append('plano', this.state.plano.label);
        formData.append('mcc', this.state.mcc.value);
        
        formData.append('holder_name', this.state.holder_name);
        formData.append('bank_code', this.state.bank_code);
        formData.append('routing_number', this.state.routing_number);
        formData.append('account_number', this.state.account_number);
        if(this.state.type !== null){
            formData.append('type', this.state.type.value);
        }

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            this.onData(data);
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            this.onData(data);
            return data;
        });
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    validateFields = () => {
        var invalids = 0;
        if(this.state.step === 1){
            if(this.state.email.length > 0){
                this.setState({email_invalid: false})
            }
            else{
                invalids++;
                this.setState({email_invalid: true})
            }


            if(this.state.cep.replace(/([^\d])+/gim, '').length > 7){
                this.setState({cep_invalid: false})
            }
            else{
                invalids++;
                this.setState({cep_invalid: true})
            }


            if(this.state.telefone.replace(/([^\d])+/gim, '').length > 9){
                this.setState({telefone_invalid: false})
            }
            else{
                invalids++;
                this.setState({telefone_invalid: true})
            }
    
    
            if(this.state.logradouro.length > 0){
                this.setState({logradouro_invalid: false})
            }
            else{
                invalids++;
                this.setState({logradouro_invalid: true})
            }
    
            if(this.state.numero.length > 0){
                this.setState({numero_invalid: false})
            }
            else{
                invalids++;
                this.setState({numero_invalid: true})
            }
    
            if(this.state.complemento.length > 0){
                this.setState({complemento_invalid: false})
            }
            else{
                invalids++;
                this.setState({complemento_invalid: true})
            }
    
            if(this.state.bairro.length > 0){
                this.setState({bairro_invalid: false})
            }
            else{
                invalids++;
                this.setState({bairro_invalid: true})
            }
    
            if(this.state.cidade.length > 0){
                this.setState({cidade_invalid: false})
            }
            else{
                invalids++;
                this.setState({cidade_invalid: true})
            }
    
            if(this.state.uf.length > 0){
                this.setState({uf_invalid: false})
            }
            else{
                invalids++;
                this.setState({uf_invalid: true})
            }
            
            /* if(this.state.plano !== null){
                this.setState({plano_invalid: false})
            }
            else{
                invalids++;
                this.setState({plano_invalid: true})
            } */
        }
        else if(this.state.step === 2){
            if(!this.state.request.telefones){
                if(this.state.telefone.replace(/([^\d])+/gim, '').length > 9){
                    this.setState({telefone_invalid: false})
                }
                else{
                    invalids++;
                    this.setState({telefone_invalid: true})
                }
            }
            /* if(this.state.plano !== null){
                this.setState({plano_invalid: false})
            }
            else{
                invalids++;
                this.setState({plano_invalid: true})
            } */
            
            if(this.state.mcc !== null){
                this.setState({mcc_invalid: false})
            }
            else{
                invalids++;
                this.setState({mcc_invalid: true})
            }

            if(this.state.email.length > 0){
                this.setState({email_invalid: false})
            }
            else{
                invalids++;
                this.setState({email_invalid: true})
            }
            
        }
        else{
            this.onSearch();
            invalids++
        }

        return invalids
    }
    onRegister = () => {
        if(this.validateFields() === 0){
            this.setState({loading: true});
            this.storeUser();
        }
    }
    onData = (data) => {
        
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onClose = () => {
        this.setState({
            isCnpj: false,
            step: 0,
            socios: [],
            mcc_list: [],
            planos_list: [],
            request: {},
            
            socio_checked_add: false,
            socio_checked_add_invalid: false,
            socios_adds: [],

            cpf_add: '',
            cpf_add_invalid: false,
            cep_add: '',
            cep_add_invalid: false,
            logradouro_add: '',
            logradouro_add_invalid: false,
            numero_add: '',
            numero_add_invalid: false,
            complemento_add: '',
            complemento_add_invalid: false,
            bairro_add: '',
            bairro_add_invalid: false,
            cidade_add: '',
            cidade_add_invalid: false,
            uf_add: '',
            uf_add_invalid: false,

            nome_fantasia_pretendido: '',
            nome_fantasia_pretendido_invalid: false,
            capital_social: 0,
            capital_social_invalid: false,
            porte_empresa: '',
            porte_empresa_invalid: false,
            mcc: null,
            mcc_invalid: false,
            plano: null,
            plano_invalid: false,
            cep: '',
            logradouro: '',
            logradouro_invalid: false,
            numero: '',
            numero_invalid: false,
            complemento: '',
            complemento_invalid: false,
            bairro: '',
            bairro_invalid: false,
            cidade: '',
            cidade_invalid: false,
            uf: '',
            uf_invalid: false,
            holder_name: '',
            holder_name_invalid: false,
            bank_code: '',
            bank_code_invalid: false,
            routing_number: '',
            routing_number_invalid: false,
            account_number: '',
            account_number_invalid: false,
            type: '',
            type_invalid: false,
            email: '',
            email_invalid: false,
            telefone: '',
            telefone_invalid: false,
            document: '',
            document_invalid: false,
            document_loading: false,
            socio: null,
            socio_invalid: false,
            loading: false,
            error_message: '',
            success_message: '',
        });
        this.props.onClose(false);
    }

    validSocios = () => {
        var invalids = 0;

        if(cpf.isValid(this.state.cpf_add.replace(/([^\d])+/gim, '')) == true){
            this.setState({ cpf_add_invalid: false });
        }
        else{
            invalids++;
            this.setState({ cpf_add_invalid: true });
        }

        if(this.state.cep_add.replace(/([^\d])+/gim, '').length > 7){
            this.setState({ cep_add_invalid: false });
        }
        else{
            invalids++;
            this.setState({ cep_add_invalid: true });
        }


        if(this.state.logradouro_add.length > 0){
            this.setState({ logradouro_add_invalid: false });
        }
        else{
            invalids++;
            this.setState({ logradouro_add_invalid: true });
        }

        if(this.state.numero_add.length > 0){
            this.setState({ numero_add_invalid: false });
        }
        else{
            invalids++;
            this.setState({ numero_add_invalid: true });
        }

        if(this.state.bairro_add.length > 0){
            this.setState({ bairro_add_invalid: false });
        }
        else{
            invalids++;
            this.setState({ bairro_add_invalid: true });
        }

        if(this.state.cidade_add.length > 0){
            this.setState({ cidade_add_invalid: false });
        }
        else{
            invalids++;
            this.setState({ cidade_add_invalid: true });
        }

        if(this.state.uf_add.length > 0){
            this.setState({ uf_add_invalid: false });
        }
        else{
            invalids++;
            this.setState({ uf_add_invalid: true });
        }

        this.state.socios_adds.forEach(e => {
            if(e.cpf == this.state.cpf_add){
                invalids++;
                this.setState({ cpf_add_invalid: true });
            }
        })

        return invalids;
    }
    addSocio = () => {
        if(this.validSocios() == 0){
            var value = {
                cpf: this.state.cpf_add,
                logradouro: this.state.logradouro_add,
                cep: this.state.cep_add,
                bairro: this.state.bairro_add,
                numero: this.state.numero_add,
                complemento: this.state.complemento_add,
                uf: this.state.uf_add,
                cidade: this.state.cidade_add
            }
    
            var socios = this.state.socios_adds;
            socios.push(value);
    
            this.setState({
                socios_adds: socios,
                cpf_add: '',
                logradouro_add: '',
                cep_add: '',
                bairro_add: '',
                numero_add: '',
                complemento_add: '',
                uf_add: '',
                cidade_add: ''
            });
        }
    }

    render(){
        return <Modal isOpen={this.props.visible} size={"lg"}>
            <ModalHeader>Novo Cliente</ModalHeader>
            <ModalBody hidden={this.state.loading}>
                <FormGroup>
                    <Row>
                        <Col md={10} sm={10} xs={10}>
                            <InputMasked
                                name="document"
                                value={this.state.document}
                                mask={this.state.isCnpj === true ? "99.999.999/9999-99" : "999.999.999-99"}
                                placeholder={this.state.isCnpj === true ? "CNPJ" : "CPF"}
                                onChange={this.handleChange}
                            />
                        </Col>
                        <Col md={1} sm={1} xs={1}>
                            <Button color="info" onClick={() => this.onSearch()}>
                                {this.state.document_loading === true ?
                                    <ClipLoader color="#fff" size={20}/>:
                                    <MdSearch style={{ padding: 0 }}/>}
                            </Button>
                        </Col>
                    </Row>
                    <input 
                        type="checkbox" 
                        checked={this.state.isCnpj}
                        onChange={(e) => this.setState({isCnpj: e.target.checked})}
                    /> Mudar para CNPJ
                </FormGroup>
                {this.state.step === 1 ?
                    <div>
                        <h6><b>Nome: </b> {this.state.request.nome}</h6>
                        <h6><b>CPF: </b> 
                            <NumberFormat 
                                displayType={'text'}  
                                format="###.###.###-##" 
                                value={this.state.request.ni} /></h6>
                        <h6><b>Situação: </b> {this.state.request.situacao.descricao}</h6>
                        <h6><b>Data de Nascimento: </b> 
                            <NumberFormat 
                                displayType={'text'}  
                                format="##/##/####" 
                                value={this.state.request.nascimento} /></h6>
                        <FormGroup>
                            <h6><b>E-mail: </b> </h6>
                            <InputText 
                                name="email"
                                value={this.state.email}
                                placeholder="Digite o e-mail"
                                invalid={this.state.email_invalid}
                                onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <h6><b>Telefone:</b> </h6>
                            {/* <InputText placeholder="Telefone"/> */}
                            <InputMasked
                                mask="(99) 99999-9999"
                                name="telefone"
                                value={this.state.telefone}
                                placeholder="Digite o telefone"
                                invalid={this.state.telefone_invalid}
                                onChange={this.handleChange}/>
                        </FormGroup>
                        <br/>
                        <h5>Dados de endereço</h5>
                        <hr/>
                        <FormGroup>
                            <h6><b>CEP: </b> </h6>
                            <InputMasked 
                                name="cep"
                                mask="99999-999"
                                value={this.state.cep}
                                placeholder="Digite o cep"
                                invalid={this.state.cep_invalid}
                                onChange={this.handleChange}/>
                        </FormGroup>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <h6><b>Logradouro: </b> </h6>
                                    <InputText 
                                        name="logradouro"
                                        value={this.state.logradouro}
                                        placeholder="Digite o nome da Rua"
                                        invalid={this.state.logradouro_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                                <FormGroup>
                                    <h6><b>Número: </b> </h6>
                                    <InputText 
                                        name="numero"
                                        value={this.state.número}
                                        placeholder="Digite o numero"
                                        invalid={this.state.numero_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                                <FormGroup>
                                    <h6><b>Complemento: </b> </h6>
                                    <InputText 
                                        name="complemento"
                                        value={this.state.complemento}
                                        placeholder="Digite o complemento"
                                        invalid={this.state.complemento_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <h6><b>Bairro: </b> </h6>
                                    <InputText 
                                        name="bairro"
                                        value={this.state.bairro}
                                        placeholder="Digite o bairro"
                                        invalid={this.state.bairro_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                                <FormGroup>
                                    <h6><b>Estado (UF): </b> </h6>
                                    <InputText 
                                        name="uf"
                                        maxLength={2}
                                        value={this.state.uf}
                                        placeholder="Digite o uf"
                                        invalid={this.state.uf_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                                <FormGroup>
                                    <h6><b>Cidade: </b> </h6>
                                    <InputText 
                                        name="cidade"
                                        value={this.state.cidade}
                                        placeholder="Digite a cidade"
                                        invalid={this.state.cidade_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr/>
                        <FormGroup>
                            <h6><b>Agora informe qual o objetivo social de seu CNPJ, quais as atividades que você vai realizar:</b> </h6>
                            <Select
                                placeholder="Atividade de vendas"
                                options={this.state.mcc_list}
                                value={this.state.mcc}
                                onChange={(mcc) => this.setState({mcc})}
                            />
                            <Input hidden invalid={this.state.mcc_invalid} />
                            <FormFeedback>Campo obrigatório</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <h6><b>Informe qual o nome fantasia que você irá usar na abertura de seu cnpj:</b> </h6>
                            <InputText 
                                name="nome_fantasia_pretendido"
                                value={this.state.nome_fantasia_pretendido}
                                placeholder="Nome Fantasia"
                                invalid={this.state.nome_fantasia_pretendido_invalid}
                                onChange={this.handleChange}/>
                            <FormFeedback>Campo obrigatório</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <h6><b>Qual será o capital social que vai abrir/registrar seu CNPJ:</b> </h6>
                            <CurrencyInput
                                inputMode="tel"
                                className="form-control" 
                                currency="BRL"
                                name="capital_social"
                                //value={valor}
                                defaultValue={'0.00'}
                                onChange={(event) => this.handleChange(event)}/>
                            <Input hidden invalid={this.state.capital_social_invalid}/>
                            <FormFeedback>Campo obrigatório</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <h6><b>Qual tipo/porte de empresa deseja abrir? </b></h6>
                            <Input 
                                type="select" 
                                placeholder="Porte da Empresa"
                                name="porte_empresa"
                                invalid={this.state.porte_empresa_invalid}
                                onChange={(event) => this.handleChange(event)}>
                                <option value="">Porte da Empresa</option>
                                <option value="MEI">MEI</option>
                                <option value="EIRELI">EIRELI</option>
                                <option value="Empresario Individual">Empresario Individual</option>
                                <option value="Sociedade Empresária Limitada">Sociedade Empresária Limitada</option>
                                <option value="Sociedade Simples">Sociedade Simples</option>
                            </Input>
                        </FormGroup>
                    </div>
                    :
                    this.state.step === 2 ?
                        <div>
                            <hr/>
                            <h6><b>Nome da Empresa: </b> {this.state.request.nomeEmpresarial}</h6>
                            <h6><b>Data de Constituição: </b> {this.state.request.dataAbertura}</h6>
                            <h6><b>Natureza Juridica: </b>{this.state.request.naturezaJuridica.descricao}</h6>
                            {this.state.request.telefones ?
                                <h6><b>Telefone: </b> {'('+this.state.request.telefones[0].ddd + ') '+this.state.request.telefones[0].numero}</h6>
                                :
                                <FormGroup>
                                    <h6><b>Telefone:</b> </h6>
                                    {/* <InputText placeholder="Telefone"/> */}
                                    <InputMasked
                                        mask="(99) 99999-9999"
                                        name="telefone"
                                        value={this.state.telefone}
                                        placeholder="Digite o telefone"
                                        invalid={this.state.telefone_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            }
                            <FormGroup>
                                <h6><b>Sócios: </b> </h6>
                                <Select
                                    placeholder="Sócios"
                                    options={this.state.socios}
                                    value={this.state.socio}
                                    onChange={(socio) => this.setState({socio})}
                                />
                                <Input hidden invalid={this.state.socio_invalid} />
                                <FormFeedback>Campo Obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>E-mail Pessoal: </b> </h6>
                                <InputText 
                                    name="email"
                                    value={this.state.email}
                                    placeholder="Digite o e-mail"
                                    invalid={this.state.email_invalid}
                                    onChange={this.handleChange}
                                />
                                <Input hidden invalid={this.state.email_invalid} />
                                <FormFeedback>Campo Obrigatório</FormFeedback>
                            </FormGroup>
                            
                            <Row>
                                <Col>
                                    <p>A empresa terá mais algum sócio além de você? &nbsp;&nbsp;
                                    <input type="radio" name="socio_checked_add" value={true} onChange={this.handleChange}/> Sim &nbsp;
                                    <input type="radio" name="socio_checked_add" value={false} onChange={this.handleChange}/> Não</p>
                                </Col>
                            </Row>
                        <hr/>
                        <div hidden={this.state.socio_checked_add != true}>
                            <hr/>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <h6><b>CPF: </b> </h6>
                                        <InputMasked 
                                            name="cpf_add"
                                            mask="999.999.999-99"
                                            value={this.state.cpf_add}
                                            placeholder="Digite o cpf do socio"
                                            invalid={this.state.cpf_add_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <h6><b>CEP: </b> </h6>
                                        <InputMasked 
                                            name="cep_add"
                                            mask="99999-999"
                                            value={this.state.cep_add}
                                            placeholder="Digite o cep"
                                            invalid={this.state.cep_add_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <h6><b>Número: </b> </h6>
                                        <InputText 
                                            name="numero_add"
                                            value={this.state.numero_add}
                                            placeholder="Digite o numero"
                                            invalid={this.state.numero_add_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <h6><b>Cidade: </b> </h6>
                                        <InputText 
                                            name="cidade_add"
                                            value={this.state.cidade_add}
                                            placeholder="Digite o cidade"
                                            invalid={this.state.cidade_add_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <h6><b>Logradouro: </b> </h6>
                                        <InputText 
                                            name="logradouro_add"
                                            value={this.state.logradouro_add}
                                            placeholder="Digite o nome da Rua"
                                            invalid={this.state.logradouro_add_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <h6><b>Complemento: </b> </h6>
                                        <InputText 
                                            name="complemento_add"
                                            value={this.state.complemento_add}
                                            placeholder="Digite o complemento"
                                            invalid={this.state.complemento_add_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <h6><b>Bairro: </b> </h6>
                                        <InputText 
                                            name="bairro_add"
                                            value={this.state.bairro_add}
                                            placeholder="Digite o bairro"
                                            invalid={this.state.bairro_add_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <h6><b>Estado (UF): </b> </h6>
                                        <InputText 
                                            name="uf_add"
                                            maxLength={2}
                                            value={this.state.uf_add}
                                            placeholder="Digite o uf"
                                            invalid={this.state.uf_add_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button 
                                        color="primary"
                                        onClick={() => this.addSocio()}
                                    >Adicionar Sócio</Button>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                    <ul>
                                        {this.state.socios_adds.map((e, index) => {
                                            return <li>{e.cpf} - <Button color="danger" style={{padding: 2, fontSize: '12px'}} onClick={() => this.removeSocio(index)}>Remover</Button></li>
                                        })}
                                    </ul>
                                </Col>
                            </Row>
                            <Input hidden invalid={this.state.socio_checked_add_invalid} />
                            <FormFeedback>Nenhum sócio adicionado</FormFeedback>
                        </div>
                        </div>
                    :
                    <></>
                }
            </ModalBody>
                <ModalBody 
                    style={{
                        minHeight: '114px', 
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn 
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                        />
                </ModalBody>
            <ModalFooter hidden={this.state.loading}>
                <Button color="danger" onClick={() => this.onClose()}>Cancelar</Button>
                <Button color="success" onClick={() => this.onRegister()}>{this.state.step === 0 ? 'Avançar' : 'Cadastrar'}</Button>
            </ModalFooter>
        </Modal>
    }
}

export default AQContabilModal;