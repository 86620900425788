import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';


import Login from './pages/Auth/Login';
import RecoverPassword from './pages/Auth/RecoverPassword';
import NewPassword from './pages/Auth/NewPassword';
import Pages from './layout/Pages';
import WidgetLayout from './layout/WidgetLayout';
import Authentication from './layout/Authentication';
import dashboardRoutes from './routes';
import { getUser, isAuthenticated } from './auth/set_services';
import Dashboard from './pages/Dashboard/Dashboard';
import Token from './pages/Auth/Token';
import FirstPassword from './pages/Auth/FirstPassword';
import WidgetApp from './layout/Widget';
import AudioRecorder from 'audio-recorder-polyfill'
window.MediaRecorder = AudioRecorder;

const LayoutRoute = ({ layout, component, ...rest }) => {
  return (
    <Route {...rest} render={(props) =>
      React.createElement(layout, props, React.createElement(component, props))
    } />
  );
}

const AuthenticationRoutes = () => (
  <BrowserRouter>
    <Switch>
      <LayoutRoute
        path='/login'
        layout={Authentication}
        component={Login}
      />
      <LayoutRoute
        path='/recover'
        layout={Authentication}
        component={RecoverPassword}
      />
      <LayoutRoute
        path='/change'
        layout={Authentication}
        component={NewPassword}
      />
      <LayoutRoute
        path='/token'
        layout={Authentication}
        component={Token}
      />
      <LayoutRoute
        path='/first-password'
        layout={Authentication}
        component={FirstPassword}
      />
      <Redirect to="/login" />
    </Switch>
  </BrowserRouter>
);


const PrivateRoute = () => (
  dashboardRoutes().map((p, i) => {
    if (p.type == "link") {
      if(getUser()[p.permission]){
        return (
          <div key={i}>
            <LayoutRoute
              path={p.path}
              layout={Pages}
              component={p.component} />
            {p.scroll ?
              <LayoutRoute
                path={p.scroll.path}
                layout={Pages}
                component={p.scroll.component} />
              :
              <></>}
          </div>
        )
      }
    }
    else {
      return (<></>);
    }
  })
);

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <LayoutRoute
        path="/widget"
        exact
        layout={WidgetLayout}
        component={WidgetApp} />
      {isAuthenticated() ?
        <>
          <LayoutRoute
            path="/"
            exact
            layout={Pages}
            component={Dashboard} />
          <PrivateRoute />
        </>
        : <AuthenticationRoutes />
      }
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
