import axios from "axios";
import React, { Component } from "react";
import { MdArrowBack, MdCheck, MdClear, MdMail, MdWarning, MdAttachFile } from "react-icons/md";
import { BsLock, BsUnlock } from 'react-icons/bs';
import { IoReceiptOutline } from 'react-icons/io5';
import { Button, Card, CardBody, CardColumns, CardFooter, Col, Collapse, FormGroup, Input, Label, Nav, NavLink, Row, TabContent, TabPane, Alert } from "reactstrap";
import Dropzone from 'react-dropzone';
import styled from "styled-components";
import { ENDPOINT, ENDPOINT_SHOP } from "../../../auth/endpoint";
import { getPermissoes, getToken, getTokenShop, createTokenShop, generateUUID } from "../../../auth/set_services";
import Select from "react-select";
import { InputMasked } from '../../../components/InputText/InputMasked';
import { toast } from 'react-toastify';
import PropagateLoader from "react-spinners/PropagateLoader";
import ClipLoader from 'react-spinners/ClipLoader'
import moment from "moment";
import { FaAngleDown, FaArrowsAltV, FaBox, FaBoxes, FaClipboardList, FaCreditCard, FaEye, FaFileImport, FaFileInvoiceDollar, FaFunnelDollar, FaListOl, FaMapMarkerAlt, FaMoneyBillAlt, FaPen, FaPercent, FaPercentage, FaPlus, FaRegCreditCard, FaRegListAlt, FaRegMoneyBillAlt, FaSave, FaSearch, FaSleigh, FaTicketAlt, FaTrash, FaTruck, FaWallet, FaWindowClose } from "react-icons/fa";
import classnames from 'classnames';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import { ButtonRed } from "../../../components/ButtonStylish/ButtonRed";
import { stringToArray } from "ag-grid-community";
import ListarExtrato from "./ListarExtrato";
import ListarEtiquetas from "./ListarEtiquetas"; 
import PDFViewer from "pdf-viewer-reactjs";
import NumberFormat from "react-number-format";
import ReactScoreIndicator from "react-score-indicator";
import ConsultaMixDataView from "../../../components/Modals/Prevencao/ConsultaMixDataView";
import ConsultarSellerLocaliza from "../../../components/Modals/Prevencao/ConsultarSellerLocaliza";
import ConsultarSeller from "../../../components/Modals/Prevencao/ConsultarSeller";
import ListarFreeRules from './ListarFreeRules';
import ConfirmarRemoveFree from "../../../components/Modals/AQEnvio/ConfirmarRemoveFree";

var CurrencyInput = require('react-currency-masked-input');


const Div = styled.div`
.nav-item{
    margin: 0
}
.nav-link{
    color: black;
    text-transform: initial;
    cursor: pointer;
    padding: 0.5rem;
    border: 1px solid #ccc;
    margin: 0.2rem 0.3rem;
    border-radius: 5px;
}
.nav-tabs .nav-item.show .nav-link, 
.nav-tabs .nav-link.active {
    background: #28a745;
    color: #fff;
}
.nav-link:hover{
    background: #70b580;
    color: #fff;
}
.nav-tabs {
    padding-bottom: 1rem;
}
.reclamado {
    display: none;
}
.row {
    margin: 10px 0px;
}
.btn-save {
    float: right;
}
.btn-nota {
    margin-top: 10px;
}
.file-iframe {
    width: 100%;
    height: 800px; 
}
.btn-arquivo {
    margin-right: 10px;
    color: #fff;
    border: 0px;
}
.subtitle {
    font-weight: 700;
    margin-top: 20px !important;
}
.error-active {
    display: block;
    color: red;
}
.error-none {
    display: none;
}
.input-error {
    border: 1px solid red;
}
.label-error {
    color: red;
}
.select-error {
    border: 1px solid red;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}
.card {
    border: 1px solid #ced4da;
    border-left: 5px solid #28a745;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    -moz-border-radius: 0.25rem;
    
}
.card h6 {
    font-weight: 600;
    padding: 10px;
    border-radius: 3px 3px 0px 0px;
    margin: 0px;
}
.card-box {
    padding: 1rem;
    height: auto;
    min-height: 180px;
}
.card-box div {
    font-size: 14px;
}
.button-edit {
    position: absolute;
    bottom: 0.5rem;
    right: 1rem;
}
.button-remove {
    position: absolute;
    bottom: 0.5rem;
    left: 1rem;
}
.btn-salvar {
    float: right;
}
.add-address-btn {
    position: absolute;
    top: 0.5rem;
    right: 2rem;
}
.col-cards {
    margin-bottom: 1rem;
}
.btn-float {
    float: right;
}
.nav-link {
    padding: 1rem;
    font-size: 14px; 
}
.active.nav-link {
    background: #58d174;
    color: #fff;
}
.tab-content {
    border: 1px solid rgb(222, 226, 230);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    position: relative;
    top: 0px;
}
.nav.nav-tabs {
    border-bottom: 0px;
}
.currency-simbol {
    color: #999b9d;
    position: absolute;
    left: 25px;
    top: 43px;
}
.captalize {
    text-transform: capitalize;
}

table tr th,
table tr td {
    font-weight: normal;
    padding: 0.5rem 1.5rem;
    border: 1px solid #dee2e6;
}

table tr td { 
    font-weight: bold;
}

.input-erro {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ff0000;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.select-erro {
    border: 1px solid #ff0000;
    border-radius: 0.25rem;
}
.currency-simbol {
    color: #999b9d;
    position: absolute;
    left: 25px;
    top: 43px;
}
.input-currency {
    padding-left: 30px;
}
.select-period {
    color: #000 !important;
}
.SortableListPayments {
    
}
`;

const DropArea = styled.div`
    border-style: dashed;
    border-color: #38B76E;
    padding: 1%;
    cursor: pointer;
    &:drag {
        opacity: 0.5;
    }
`;

const ButtonUpload = styled.button`
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0;
    padding-left: 7px;
    padding-right: 7px;
    top: -1px;
    position: relative;
`;

const optionStatus = [
    {label: 'Liberado', value: 1},
    {label: 'Bloqueado', value: 0}
];

const getId = () => {
    return window.location.pathname.replace('/aqenvio-vendedor-detalhes/', '')
}

const motivos_limite = [
    {label: "Ré-análise de Risco SCR", value: 'scr'},
    {label: "Pontuação de Risco Score de Crédito Bureau", value: 'score_risco'},
    {label: "Inadiplência Holding AQBank", value: 'inadiplente_aqbank'},
    {label: "Inadiplência de Fatura", value: 'inadiplente_fatura'},
    {label: "Cumprimento de Bloqueio Judicial", value: 'cumprimento_bloqueio_judicial'},
    {label: "Cancelamento de Serviço Pós-Pago", value: 'cancelamento'},
];

const SortableItem = SortableElement(({value, state, item, handleRemove}) =>
    <li key={ String((Math.floor(Math.random() * 10))) } style={{
        justifyContent: 'center', 
        alignItems: 'center', 
        listStyle: 'none',
        backgroundColor: '#ffffff', 
        // opacity: item.value !== "after_paid" && state.payment_pos_active === true ? 0.4 : 1,
        padding: '0.5rem', 
        cursor: 'pointer', 
        height: '3.5rem', 
        marginTop: '0', 
        border: '1px solid #dee2e6'
    }}>
        <b>{value}</b> 
        
        <Button 
            outline 
            color="danger" 
            style={{color: '#FF0000',float: 'right'}}
            onClick={() => handleRemove(item)}
            >
            Remover
        </Button>
        {item.value !== "after_paid" && state.payment_pos_active === true ? 
            <><br/><small style={{color: '#28a745'}}>Alternativo</small></> 
        : 
            <></>
        }
    </li>
);

const SortableList = SortableContainer(({items, state, remove}) => {
    return (
        <ul style={{padding: 0, backgroundColor: '#eeeeee'}}>
            {items.map((item, index) => (
                <SortableItem 
                    key={`item-${index}`} 
                    index={index} 
                    value={item.label} 
                    state={state} 
                    item={item} 
                    handleRemove={remove} 
                    />
            ))}
        </ul>
    );
});

class ResumoVendedor extends Component {
    constructor(props){
        super(props);
        this.state = {
            show_form: true,
            loading_page: true,
            loading_button: false,
            loading_moviments: true,
            files: [],
            notas: [],
            seller_id: getId(),
            nota_mensagem: '',
            payment_method: null,
            options_method_payment: [],
            seller_data: [],
            payments: [],
            payment_pos_active: false,
            conta_id: getId(),

            extract_data: [],
            free_id: null,
            start_date: null,
            end_date: null,
            zip_code_origin: null,
            destination: null,
            show_free_table: true,
            visible_confirm: false,

            installments_min: {label: '8x', value:'8'},
            expire_days: null,
            base64_label: null,
            loading_download: false,
            loading_view: false,
            loading_label: false,
            simulate_shippings: [],

            form_receiver: false,
            form_simulate: false,
            loading_consultar_vendedor: false,
            consulta_view: '',

            period_extract: {label: 'Todo', value: 'todo'},

            items: ['Pagar por Etiqueta', 'Comprar Crédito', 'Pós Pago', 'Débito'],

            pay_type_select: {label: null, value: null},
            status_aqenvio: {label: 'Liberado', value: 1},
            status_free_aqenvio: {label: 'Liberado', value: 1},

            tax_type_options: [
                {value: 'V', label: 'Valor (R$)'},
                {value: 'P', label: 'Procentagem (%)'},
            ],

            limit_credit: null,
            nota_limite: null,
            motivos_limite: motivos_limite[0]['value'],
            motivos_limite_option: motivos_limite[0],

            list_limit: [],
            moviments: null,

            shipping_height: 15,
            shipping_width: 11,
            shipping_length: 20,

            id_empresa: null,
            razao_social: null,
            cnpj: null,
            regime_id: null,
            data_criacao: null,
            visible_consulta_view: false,
            activeTabConfig: "tab_invoice",
            activeTab: "1",
            list_tabs_config: [
                { name: <><FaRegMoneyBillAlt style={{ padding: '2px' }}/> Faturar</>, active: 'tab_invoice'},
                { name: <><FaPercent style={{ padding: '2px' }}/> Regra Frete Grátis</>, active: 'tab_free'},
                { name: <><FaTruck style={{ padding: '2px' }}/> Regra Frete Pago</>, active: 'tab_pay'},
                { name: <><FaCreditCard style={{ padding: '2px' }}/> Serasa</>, active: 'tab_serasa'},
                { name: <><FaMoneyBillAlt style={{ padding: '2px' }}/> Limite de Pós pago</>, active: 'tab_limit_credit'},
                { name: <><FaClipboardList style={{ padding: '2px' }}/> Extrato</>, active: 'tab_extrato'},
                { name: <><FaBox style={{ padding: '2px' }}/> Postagens</>, active: 'tab_labels'},
            ],
        }
        
        this.inputFile = React.createRef();
        this.handleOpenLabelPDF = this.handleOpenLabelPDF.bind();
        this.handleDownloadLabelPDF = this.handleDownloadLabelPDF.bind();
        this.handleRemovePaymentMethod = this.handleRemovePaymentMethod.bind();

        this.callback_consultar_seller = this.handleNewConsultaSeller.bind(this);
        this.callback_consultar_seller_localiza = this.handleNewConsultaSellerLocaliza.bind(this);

        this.handleEditRuleShipping = this.handleEditRuleShipping.bind(this);
        this.handleCancelSaveFreeShipping = this.handleCancelSaveFreeShipping.bind(this);
        this.handleRemoveFreeRule = this.handleRemoveFreeRule.bind(this);
    }
    
    handleRemoveFreeRule = () => {
        this.setState({show_free_table: false})
        var url = ENDPOINT + "aqenvio/rules_cancel_global_free/" + this.state.free_id;
        //var url = "http://127.0.0.1:8000/api/aqenvio/rules_cancel_global_free/" + this.state.free_id;
        
        axios.delete(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            let data = response.data;
            
            this.setState({show_free_table: true})

            if (data.success) {
                this.loadDataGlobal()
                this.handletoastSuccessMessage('Removido com sucesso!')
            } else {
                this.handletoastMessage('Falha ao remover!');
            }

        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              //console.log(error.config);
        })
    }

    handleCancelSaveFreeShipping = (idRule) => {
        this.setState({free_id: idRule})
        this.modalConfirm(true, this.handleRemoveFreeRule)
    }
    
    modalConfirm(visible_confirm, callback = false){
        this.setState({visible_confirm: visible_confirm, callback_modal: callback})
    }

    handleSaveFreeShippingCancel = () => {
        this.setState({
            free_id: null,
            create_new_rule: false,
            show_free_form: false,
            show_free_table: true,
        })
    }

    handleEditRuleShipping = (rule) => {

        this.setState({
            free_id: rule.id,
            free_min_amount: rule.min_amount,
            free_max_amount: rule.max_amount,
            free_weight: rule.weight,
            expire_days: rule.expire,
            installments_min: {label: rule.installments_min + 'x', value: rule.installments_min},
            create_new_rule: true,
            show_free_form: true,
            show_free_table: false,
        })
    }

    handleNewRuleShipping = () => {
        this.setState({
            create_new_rule: true,
            show_free_form: true,
            show_free_table: false,
            free_id: null,
        })
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState({
          items: arrayMove(this.state.items, oldIndex, newIndex),
        });
      };

    onDrop = (file) => {
        var state = this.state;
        file.forEach(file_current => {
            state.files.push(file_current);
        });
        this.setState({
            ...state
        })
    }
    removeAttach = (index) => {
        var state = this.state;
        state.files.splice(index, 1);
        this.setState({
            ...state
        })
    }

    handleFileChange = (event) => {
        const files = event.target.files;

        var state = this.state;
        Array.from(files).forEach(file_current => {
            state.files.push(file_current);
        });
        this.setState({
            ...state
        })
    }

    handletoastSuccessMessage = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    handletoastMessage = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    componentDidMount(){
       this.handleLoadDataPayment()
       this.handleLoadDataSeller()
       this.handleLoadDataRulesSeller()
       this.handleLoadDataLimit()
       this.handleLoadDataExtract()
    }

    handleOpenShopToken = async (document) => {
        let authShop = await createTokenShop(document);
        return authShop;
    }

    handleCreateNewLabel = async () => {
        let authShop = await this.handleOpenShopToken(this.state.seller_document);
        var url = ENDPOINT_SHOP + "aqenvio/new_post";
        let orderID = generateUUID();

        this.setState({loading_label: true})

        axios.post(url, {
            'reference': this.state.simulate_id,
            'order': {
                'type': 'D',
                'order-number': orderID,
                'order-amount': this.state.commodity_value,
                'order-weight': this.state.merchandise_weight,
                // 'nfe': {
                //     'number': null,
                //     'serie': null,
                //     'key': null,
                // },
            },
            'receiver': {
                'name': this.state.receiver_name,
                'taxvat': this.state.receiver_document,
                'address': {
                    'street': this.state.destination.logradouro,
                    'number': this.state.destination.receiver_number,
                    'complement': this.state.destination.receiver_complement,
                    'district': this.state.destination.bairro,
                    'zip-code': this.state.zip_code_origin,
                    'city': this.state.destination.localidade,
                    'state': this.state.destination.uf,
                },
                'contact': null,
                'email': this.state.receiver_email,
                'phone': null,
                'celular': this.state.receiver_celular,
            },
            'volumes': [
                {
                    'name': this.state.conteudo,
                    'weight': this.state.merchandise_weight,
                    'height': this.state.shipping_height,
                    'width': this.state.shipping_width,
                    'length': this.state.shipping_length,
                    'type': 'C',
                    'amount': this.state.commodity_value,
                    'quantity': 1,
                }
            ],
            'products': [
                {
                    'name': this.state.conteudo,
                    'weight': null,
                    'height': this.state.shipping_height,
                    'width': this.state.shipping_width,
                    'length': this.state.shipping_length,
                    'amount': this.state.commodity_value,
                    'quantity': 1,
                }
            ],
        }, {
            headers: {
                Authorization: 'Bearer ' + authShop
            },
        })
        .then((response) => {
            let data = response.data;
            let state = this.state;

            if (data?.success) {
                this.handletoastSuccessMessage('Gerado com sucesso!');
                window.location.href = '/aqenvio-etiqueta-detalhes/'+data?.id;
            } else {
                this.handletoastMessage('Falha ao gerar etiqueta!');
            }
        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
           // console.log(error.config);
        })
    }

    handleSimulateShipping = async () => {
        this.setState({loading_label: true})

        let authShop = await this.handleOpenShopToken(this.state.seller_document);
        var url = ENDPOINT_SHOP + "aqenvio/simulate";

        axios.post(url, {
            'payment_id': this.state.payment_id,
            'seller': this.state.seller_document,
            'zip-code-origin': this.state?.seller_address?.cep,
            'zip-code-destination': this.state.zip_code_origin,
            'commodity-value': this.state.commodity_value,
            'merchandise-weight': this.state.merchandise_weight,
            'volumes': [
                {
                    'weight': this.state.merchandise_weight,
                    'height': this.state.shipping_height,
                    'width': this.state.shipping_width,
                    'length': this.state.shipping_length,
                    'value': this.state.commodity_value,
                }
            ],
        }, {
            headers: {
                Authorization: 'Bearer ' + authShop
            },
        })
        .then((response) => {
            let data = response.data;
            let state = this.state;

            state.simulate_shippings = data.data;
            state.loading_label = false;

            this.setState({...state})
        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
           // console.log(error.config);
        })
    }

    handleDownloadLabelPDF = async (code) => {
        let authShop = await this.handleOpenShopToken(this.state.seller_document);
        var url = ENDPOINT_SHOP + "aqenvio/print_label/" + code;
        
        this.setState({loading_label: true})

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + authShop
            },
        })
        .then((response) => {
            let data = response.data;
            let state = this.state;

            state.base64_label = data.pdf;
            state.loading_label = false;

            this.setState({...state})

            /**
             * Download do PDF
             */
            const linkSource = `data:application/pdf;base64,${this.state.base64_label}`;
            const downloadLink = document.createElement("a");
            const fileName = code + "_etiqueta.pdf";
        
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            //console.log(error.config);
        })
    }

    handleOpenLabelPDF = async (code) => {
        let authShop = await this.handleOpenShopToken(this.state.seller_document);
        var url = ENDPOINT_SHOP + "aqenvio/print_label/" + code;

        this.setState({loading_label: true})

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + authShop
            },
        })
        .then((response) => {
            let data = response.data;
            let state = this.state;

            state.base64_label = data.pdf;
            state.loading_label = false;
            
            this.setState({...state})

            /**
             * Abrir PDF em nova aba
             */
            var byteCharacters = atob(this.state.base64_label);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var file = new Blob([byteArray], { type: 'application/pdf;base64' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);

        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            //console.log(error.config);
        })
    }

    handleNewSimulate = () => {
        var url = ENDPOINT_SHOP + "aqenvio/simulate";
        //var url = "http://localhost:8000/api/aqenvio/simulate";

        axios.post(url, {
            'payment_id': null,
            'seller': null,
            'zip-code-origin': null,
            'zip-code-destination': null,
            'commodity-value': null,
            'merchandise-weight': null,
            'volumes': [
                {
                    'weight': null,
                    'height': null,
                    'width': null,
                    'length': null,
                    'type': null,
                    'value': null,
                    'quantity': null,
                }
            ],
            'products': [
                {
                    'weight': null,
                    'height': null,
                    'width': null,
                    'length': null,
                    'value': null,
                    'quantity': null,
                }
            ],
        }, {
            headers: {
                Authorization: getTokenShop()
            }
        })
        .then((response) => {
            let data = response.data;
            //let state = this.state;

  

            // state.loading_moviments = false;

            // if (data?.sucess && data.sucess) {
            //     state.moviments = data;
            // }
            // this.setState({...state})
        })
        .catch((error) => {
            this.setState({loading_moviments: false})
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              //console.log(error.config);
        })
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleInputChangeCep = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        if (value.length == 8) {
            var url = `https://viacep.com.br/ws/${value}/json/`;

            axios.get(url)
            .then((response) => {
                let data = response.data;
                let state = this.state;

                state.destination = data;

                this.setState({...state})
            })
            .catch((error) => {
                this.setState({loading_moviments: false})
                this.handletoastMessage('Ocorreu um erro no servidor!');
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    // console.log(error.response.status); // status code
                    // console.log(error.response.headers);
                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                  }
                  //console.log(error.config);
            }) 
        }
    }

    handleLoadDataExtract = () => {
        var url = ENDPOINT + "aqenvio/moviment/" + getId();
        //var url = "http://localhost:8000/api/aqenvio/moviment/" + getId();

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            let data = response.data;
            let state = this.state;

            state.loading_moviments = false;

            if (data?.sucess && data.sucess) {
                state.moviments = data;
            }

            this.setState({...state})
        })
        .catch((error) => {
            this.setState({loading_moviments: false})
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              //console.log(error.config);
        })
    }

    handleLoadDataLimit = () => {
        var url = ENDPOINT + "aqenvio/credit_limit/" + getId();
        //var url = "http://127.0.0.1:8000/api/aqenvio/credit_limit/" + getId();

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            let data = response.data;

            if (data.success) {
                let state = this.state;
                state.list_limit = data.data;

                this.setState({...state})
            }
        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              //console.log(error.config);
        })
    }

    handleSaveNewLimit = () => {
        var url = ENDPOINT + "aqenvio/credit_limit";
        //var url = "http://127.0.0.1:8000/api/aqenvio/credit_limit";

        axios.post(url, {
            'seller': getId(),
            'amount': this.state.limit_credit,
            'reason': this.state.motivos_limite_option.value,
            'notes': this.state.nota_limite,
        }, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            let data = response.data;

            
            if (data.success) {
                let state = this.state;

                state.limit_credit = '';
                state.motivos_limite_option = motivos_limite[0];
                state.nota_limite = '';
                state.is_open_nota = false;

                this.setState({...state})
                this.handleLoadDataLimit()
                this.handletoastSuccessMessage('Salvo com sucesso!')
            }
        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              //console.log(error.config);
        })
    }

    handleSaveInvoice = () => {
        var url = ENDPOINT + "aqenvio/invoice_seller";
        //var url = "http://127.0.0.1:8000/api/aqenvio/invoice_seller";

        axios.post(url, {
            'payments': this.state.payments,
            'status': this.state.status_aqenvio.value,
            'conta_id': getId(),
            'free': this.state.status_free_aqenvio.value,
        }, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {

            let data = response.data;

            if (data.success) {
                this.handletoastSuccessMessage('Salvo com sucesso!')
            }

        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              //console.log(error.config);
        })
    }
    
    handleSavePayShipping = () => {
        var url = ENDPOINT + "aqenvio/rules_pay";
        //var url = "http://localhost:8000/api/aqenvio/rules_pay";

        var formData = new FormData();

        formData.append('seller_conta_id', getId());
        formData.append('min_amount', this.state.pay_min_amount);
        formData.append('max_amount', this.state.pay_max_amount);
        formData.append('tax_type', this.state.pay_type);

        if (this.state.pay_type == 'P') {
            formData.append('tax_percent', this.state.pay_tax_percent);
        } else {
            formData.append('tax_amount', this.state.pay_tax_amount); 
        }
        

        formData.append('weight', this.state.pay_weight);
        formData.append('global', 0);
        formData.append('status', 1);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            let data = response.data;


            
            if (data.success) {
                this.handletoastSuccessMessage('Salvo com sucesso!')
            }
        })
        .catch((error) => {

            toast.error('Ocorreu um erro no servidor!');
        })
    }

    handleSaveFree = () => {
        var url = ENDPOINT + "aqenvio/rules_free";
        //var url = "http://localhost:8000/api/aqenvio/rules_free";

        var formData = new FormData();

        formData.append('seller_conta_id', getId());
        formData.append('id', this.state.free_id);
        formData.append('min_amount', this.state.free_min_amount);
        formData.append('max_amount', this.state.free_max_amount);
        formData.append('weight', this.state.free_weight);
        formData.append('expire', this.state.expire_days);
        formData.append('installments_min', this.state.installments_min.value);
        formData.append('global', 0);
        formData.append('status', 1);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            console.log(response)

            let data = response.data;
            
            this.setState({
                create_new_rule: false,
                show_free_form: false,
                show_free_table: true,
            })

            if (data.success) {
                this.handletoastSuccessMessage('Salvo com sucesso!')
            }
        })
        .catch((error) => {

            toast.error('Ocorreu um erro no servidor!');

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
            console.log(error.config);
        })
    }

    handleLoadDataRulesSeller = () => {
        var url = ENDPOINT + "aqenvio/seller_rules/" + getId();
        //var url = "http://localhost:8000/api/aqenvio/seller_rules/" + getId();

        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
    
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            if (p.success === true) {
                let data = p.data;
                let state = this.state;

                this.setState({
                    free_min_amount: data.rule_free.min_amount,
                    free_max_amount: data.rule_free.max_amount,
                    free_weight: data.rule_free.weight,
                    expire_days: (data.rule_free?.expire) ? data.rule_free?.expire : null,
                    
                    pay_min_amount: data.rule_pay.min_amount,
                    pay_max_amount: data.rule_pay.max_amount,
                    pay_tax_percent: data.rule_pay.tax_percent,
                    pay_tax_amount: data.rule_pay.tax_amount,
                    pay_weight: data.rule_pay.weight,
                })

                this.state.tax_type_options.map((option, i) => {
                    if (option.value == data.rule_pay.tax_type) {
                        this.setState({
                            pay_type: option.value,
                            pay_type_select: option,
                        })
                    }
                })
            }  
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              //console.log(error.config);
        })
    }

    handleOpenModalDetalhado = (consulta) => {
        this.setState({consulta_view: consulta}) 
        this.modalConsultaView(true)
    }
    modalConsultaView = (status) => {
        this.setState({visible_consulta_view: status})
    }
    modalConsultaSellerLocalizador = (status) => {
        this.setState({callback_modal: this.callback_consultar_seller_localiza})
        this.setState({visible_consulta_localiza_vendedor: status})
    }
    modalConsultaSeller = (status) => {
        this.setState({callback_modal: this.callback_consultar_seller})
        this.setState({visible_consulta_vendedor: status})
    }   
    handleLoadDataSeller = () => {
        var url = ENDPOINT + "aqenvio/seller_status/" + getId();
        //var url = "http://localhost:8000/api/aqenvio/seller_status/" + getId();

        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
    
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            if (p.success === true) {
                let data = p.data;
                let state = this.state;

                // this.setState({
                //     payment_method: {label: data.invoice.name, value: data.invoice.value},
                //     //status_aqenvio: ,
                // })


                optionStatus.map((field, i) => {
                    if (field.value == data.status) {
                        state.status_aqenvio = field;
                    }

                    if (field.value == data.free) {
                        state.status_free_aqenvio = field;
                    }
                })

                state.seller_name = data.seller_name;
                state.seller_email = data.seller_email;
                state.seller_document = data.seller_document;
                state.seller_contact = data.seller_contact;
                state.seller_address = data.address;
                state.payments = data.payments;
                state.loading_page = false;

                this.setState({...state})
                this.getHitoricoConsultasVendedor()
                this.getHitoricoConsultasVendedorLocaliza()
            }  
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!');
        })
    }

    handleLoadDataPayment = () => {
        var url = ENDPOINT + "aqenvio/payment_methods";
        //var url = "http://localhost:8000/api/aqenvio/payment_methods";

        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
    
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            if (p.success === true) {
                let data = p.data;
                let state = this.state;

                data.map((field, i) => {
                    state.options_method_payment.push({
                        label: field.name,
                        value: field.code,
                    })
                })

                this.setState({...state})
            }  
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!');
        })
    }

    onChangeSelect = (name, value) => {
        var state = this.state;
        state[name] = value;
        this.setState({...state});
    }

    deletarNota = (id) => {
        this.setState({
            loading_nota_id: id
        });
        
        // var url = ENDPOINT+"notas/delete/"+id;
        // axios.delete(url, {
        //     headers: {
        //         Authorization: 'Bearer '+getToken()
        //     }
        // })
        // .then(() => {
        //     this.getNotas(this.state.id);
        //     toast.success('Deletado com sucesso');
        //     this.setState({
        //         loading_nota_id: null
        //     });
        // })
        // .catch((error) => {
        //     var data = error.response.data;
        //     toast.error(data.errors);
        //     this.setState({
        //         loading_nota_id: null
        //     });
        // })
    }
    getHitoricoConsultasVendedorLocaliza = () => {
        var url = ENDPOINT+"prevencao/consultar_historico_localiza/"+this.state?.seller_document;
        //var url = "http://127.0.0.1:8000/juridico/teste";

        var myHeaders = new Headers();
        
        // console.log(url)
        // return false;

        myHeaders.append('Authorization', 'Bearer ' + getToken());
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            let response = p.data;
            if (response.data !== undefined && response.data.length > 0) {
                this.setState({hitorico_consultas_localiza: response.data})
            }
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!') 
        })
    }
    handleNewConsultaSeller = () => {
        var url = ENDPOINT+"prevencao/consultar_credito";
        //var url = "http://127.0.0.1:8000/prevencao/consultar_credito";

        this.setState({loading_consultar_vendedor: true})
        
        var formData = new FormData();
        formData.append('documento', this.state?.seller_document);
        formData.append('id_pagamento', null);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;

            this.setState({loading_consultar_vendedor: false})
            if (data.success) {
                toast.success('Consulta de crédito realizada com sucesso!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                this.getHitoricoConsultasVendedor()
            } else {
                toast.error('Falha ao consultar crédito', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                this.getHitoricoConsultasVendedor()
            }
        })
        .catch((error) => {
            this.setState({loading_consultar_comprador: false})
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              // console.log(error.config);
        })
    }

    handleNewConsultaSellerLocaliza = () => {
        var url = ENDPOINT+"prevencao/consultar_localiza";
        //var url = "http://127.0.0.1:8000/prevencao/consultar_localiza";

        this.setState({loading_consultar_vendedor_localiza: true})
        
        var formData = new FormData();
        formData.append('documento', this.state?.seller_document);
        formData.append('id_pagamento', null);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;

            this.setState({loading_consultar_vendedor_localiza: false})
            if (data.success) {
                toast.success('Consulta de crédito realizada com sucesso!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                this.getHitoricoConsultasVendedorLocaliza()
            } else {
                toast.error('Falha ao consultar crédito', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                this.getHitoricoConsultasVendedorLocaliza()
            }
        })
        .catch((error) => {
            this.setState({loading_consultar_comprador: false})
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              // console.log(error.config);
        })
    }
    getHitoricoConsultasVendedor = () => {
        var url = ENDPOINT+"prevencao/consultar_historico_mix/"+this.state?.seller_document;
        // var url = "http://127.0.0.1:8000/juridico/teste";

        var myHeaders = new Headers();

        myHeaders.append('Authorization', 'Bearer ' + getToken());
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        console.log('endpoint')
        console.log(url)
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            let response = p.data;

            if (response.data !== undefined && response.data.length > 0) {
                this.setState({hitorico_consultas_vendedor: response.data})
            }
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!') 
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                //console.log(error.response.status); // status code
                //console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);
        })
    }

    handleAddNota = () => {
        var url = ENDPOINT + "financeiro/nota";

        var formData = new FormData();

        formData.append('id_processo', getId());
        formData.append('nota_mensagem', this.state.nota_mensagem);

        this.setState({loading_btn_add_nota: true})

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var notas_priv = this.state.notas_privadas;
            notas_priv.push(response.data.data);

            this.setState({notas_privadas: notas_priv});
            this.setState({is_open_nota: false});
            this.setState({nota_mensagem: null});
            this.setState({loading_btn_add_nota: false})
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!');
        })
    }
    
    handleRemovePaymentMethod = (payment) => {
        let state = this.state;
        let confirmPos = false;

        state.payments.map((item, i) => {
            if (item.value == payment.value){
                state.payments.splice(i, 1)
            }
        })


        state.payments.map((item, i) => {
            if (item.value == "after_paid") {
                confirmPos = true;
            }
        })

        state.payment_pos_active = confirmPos;

        this.setState({...state})
    }

    handleChangeMethodPayment = (select) => {
       
        let state = this.state;
        let confirm = false;
        let confirmPos = false;

        state.payments.map((item, i) => {
            if (item.value == select.value){
                confirm = true;
            }
        })

        if (!confirm) {
            state.payments.push({
                label: select.label,
                value: select.value,
            })
        }

        state.payments.map((item, i) => {
            if (item.value == "after_paid") {
                confirmPos = true;
            }
        })

        state.payment_pos_active = confirmPos;


        this.setState({...state})
    }

    handleChangeExtractPeriod = (select) => {

        this.setState({period_extract: select, loading_moviments: true})

        var url = ENDPOINT + "aqenvio/moviment/" + getId() + '?option=' + select.value;
        //var url = "http://localhost:8000/api/aqenvio/moviment/" + getId() + '?option=' + select.value;

        if (select.value == 'custom') {
            url += '&start=' + this.state.start_date;
            url += '&end=' + this.state.end_date;
        }

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            let data = response.data;
            let state = this.state;

            state.loading_moviments = false;

            if (data?.sucess && data.sucess) {
                state.moviments = data;
            }

            this.setState({...state})
        })
        .catch((error) => {
            this.setState({loading_moviments: false})
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
            //console.log(error.config);
        })
    }

    handleSetShipping = (simulate_id) => {
        let state = this.state;

        state.simulate_id = simulate_id;
        state.form_receiver = true;

        this.setState({...state})
    }
    
    render(){
        const NavsConfig = () => {
            return <Nav tabs>
                {this.state.list_tabs_config.map((p, index) => {
                    let ClassNames = classnames({ active: this.state.activeTabConfig === p.active });
                    return <NavLink
                        key={index}
                        className={ClassNames}
                        onClick={() => { this.setState({ activeTabConfig: p.active }); }}>
                        <span>{p.name}</span>
                    </NavLink>
                })}
            </Nav>
        }

        return(
        <Div key={String('div-resumo-vendedor')}>
            <h3>                            
                <Button 
                    outline 
                    style={{border: 'none'}}
                    onClick={() => window.location.href = '/aqenvio-vendedores'}
                >
                    <MdArrowBack style={{padding: 0}}/>
                </Button>&nbsp;&nbsp;Configuração para AQEnvio
            </h3><hr />        
            <Row hidden={!this.state.loading_page}>
                <Col md={12}>
                    <center>
                        <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                    </center>
                    <br/>
                </Col>
            </Row>
            <Div key={String('div-resumo-load')} hidden={this.state.loading_page} style={{marginBottom: '2rem'}}>
                <Row hidden={!this.state.loading_seller_data}>
                    <Col md={12}>
                        <center>
                            <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                        </center>
                        <br/>
                    </Col>
                </Row> 
                <NavsConfig />
                <TabContent activeTab={this.state.activeTabConfig}>
                    <TabPane tabId={'tab_invoice'}>
                        <Row>
                            <Col md={6}>
                                <label htmlFor="razao_social">Nome / Razão Social</label>
                                <Input
                                    name="razao_social"
                                    disabled={true}
                                    placeholder=""
                                    type="text"
                                    value={this.state.seller_name}
                                />
                            </Col>
                            <Col md={6}>
                                <label htmlFor="cnpj">CPF / CNPJ</label>
                                <InputMasked
                                    name="cnpj"
                                    disabled={true}
                                    mask={this.state?.seller_document && this.state.seller_document.length <= 11 ? '999.999.999-99' : '99.999.999/9999-99'}
                                    placeholder={'00.000.000/0000-00'}
                                    value={this.state.seller_document}
                                    onChange={(event) => {
                                        this.setState({
                                            seller_document: event.target.value
                                        })
                                    }}
                                    />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <label htmlFor="email">Email</label>
                                <Input
                                    name="email"
                                    disabled={true}
                                    placeholder=""
                                    value={this.state.seller_email}
                                    type="text"
                                />
                            </Col>
                            <Col md={6}>
                                <label htmlFor="contato">Contato</label>
                                <Input
                                    name="contato"
                                    type="text"
                                    disabled={true}
                                    value={this.state.seller_contact}
                                    /> 
                            </Col>
                        </Row>
                        <Row>

                            <Col md={6}>
                                <label htmlFor="status_aqenvio">AQEnvio Status</label>
                                <Select
                                name="status_aqenvio"
                                placeholder="Selecione..."
                                options={optionStatus}
                                onChange={(select) => {
                                    this.setState({status_aqenvio: select})
                                }}
                                value={this.state.status_aqenvio}
                                    />
                            </Col>
                            <Col md={6} hidden={this.state.status_aqenvio.value == '0'}>
                                <label htmlFor="status_free_aqenvio">Frete Grátis</label>
                                <Select
                                name="status_free_aqenvio"
                                placeholder="Selecione..."
                                options={[
                                    {label: 'Bloqueado', value: 0},
                                    {label: 'Liberado', value: 1},
                                ]}
                                onChange={(select) => {
                                    this.setState({status_free_aqenvio: select})
                                }}
                                value={this.state.status_free_aqenvio}
                                    />
                            </Col>
                        </Row>  
                        <Row> 
                            <Col md={6}>

                                <div key="div-page-1" style={{ marginTop: '2rem', padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                    <label htmlFor="pagamento">Adicionar tipo de recebimento</label>
                                    <Select
                                        name="pagamento"
                                        placeholder="Selecione..."
                                        options={this.state.options_method_payment}
                                        onChange={(select) => {
                                            this.handleChangeMethodPayment(select)
                                        }}
                                        value={this.state.payment_method}
                                            />
                                        <h5 style={{marginTop: '1rem'}}>Formas de Faturar</h5>


                                        <SortableList helperClass="SortableListPayments" items={this.state.payments} state={this.state} remove={this.handleRemovePaymentMethod} onSortEnd={this.onSortEnd} />
                                
                                </div>
                                
                            </Col>
                        </Row> 
                        <Row> 
                            <Col md={6}> 

                            </Col>
                        </Row>   
                        <Row style={{marginTop: '2rem'}}>
                            <Col md={12}>
                                <Button 
                                    className="btn btn-success btn-save" 
                                    onClick={(event) => this.handleSaveInvoice()}
                                >
                                    <FaSave style={{height: '1rem', padding: 0}}/> Salvar
                                </Button>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId={'tab_free'}>
                        <Row>
                            <Col md={12}>
                                <h5>Frete Grátis</h5><hr/>
                            </Col>
                        </Row>
                        {this.state.status_free_aqenvio.value == 0 ?
                            <Row>
                                <Col>
                                    <Alert color="warning">
                                        Frete grátis bloqueado para o vendedor!
                                    </Alert>
                                </Col>
                            </Row>
                        :
                            <>
                                <Collapse isOpen={this.state.show_free_form}>
                                    <Row>
                                        <Col md={6}>
                                            <label htmlFor="min_amount">Valor mínimo</label>
                                            <Input 
                                                className="form-control input-currency" 
                                                name="min_amount"
                                                value={this.state.free_min_amount}
                                                onChange={(event) => {
                                                    this.setState({
                                                        free_min_amount: event.target.value
                                                    })
                                                }}
                                                />
                                                <small className="currency-simbol">R$</small>
                                        </Col>
                                        <Col md={6}>
                                            <label htmlFor="max_amount">Valor máximo</label>
                                            <Input 
                                                className="form-control input-currency" 
                                                name="max_amount"
                                                value={this.state.free_max_amount}
                                                onChange={(event) => {
                                                    this.setState({
                                                        free_max_amount: event.target.value
                                                    })
                                                }}
                                                />
                                                <small className="currency-simbol">R$</small>
                                        </Col>
                                    </Row>
                                    <Row> 
                                        <Col md={6}>
                                            <label htmlFor="weight">Peso máximo</label>
                                            <Input 
                                                className="form-control input-currency" 
                                                name="weight"
                                                value={this.state.free_weight}
                                                onChange={(event) => {
                                                    this.setState({
                                                        free_weight: event.target.value
                                                    })
                                                }}
                                                />
                                                <small className="currency-simbol">Kg</small>
                                        </Col>
                                        <Col md={6}>
                                            <label htmlFor="weight">Dias para utilizar</label>
                                            <Input 
                                                className="form-control input-currency" 
                                                name="weight"
                                                value={this.state.expire_days}
                                                onChange={(event) => {
                                                    this.setState({
                                                        expire_days: event.target.value
                                                    })
                                                }}
                                                />
                                        </Col>
                                    </Row> 
                                    <Row>
                                        <Col md={6}>
                                            <label htmlFor="installments_min">Parcela mínima</label>
                                            <Select
                                                placeholder="Regra para"
                                                options={[
                                                    {label: '1x', value:'1'},
                                                    {label: '2x', value:'2'},
                                                    {label: '3x', value:'3'},
                                                    {label: '4x', value:'4'},
                                                    {label: '5x', value:'5'},
                                                    {label: '6x', value:'6'},
                                                    {label: '7x', value:'7'},
                                                    {label: '8x', value:'8'},
                                                    {label: '9x', value:'9'},
                                                    {label: '10x', value:'10'},
                                                    {label: '11x', value:'11'},
                                                    {label: '12x', value:'12'},
                                                ]}
                                                value={this.state.installments_min}
                                                onChange={(value) => this.onChangeSelect('installments_min', value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Button 
                                                style={{float: 'left'}}
                                                className="btn btn-danger" 
                                                onClick={(event) => this.handleSaveFreeShippingCancel()}
                                            >
                                                Cancelar
                                            </Button>

                                            <Button 
                                                className="btn btn-success btn-save" 
                                                onClick={(event) => this.handleSaveFree()}
                                            >
                                                <FaSave style={{height: '1rem', padding: 0}}/> Salvar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Collapse>

                                {this.state.show_free_table ?
                                    <>
                                        <Row style={{marginTop: '2rem'}}>
                                            <Col md={12}>
                                                <Button 
                                                    className="btn btn-success btn-save" 
                                                    onClick={(event) => this.handleNewRuleShipping()}
                                                >
                                                    <FaPlus style={{height: '1rem',padding: 0}}/> Cadastrar Regra
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop: '2rem'}}>
                                            <Col md={12}>
                                                <ListarFreeRules 
                                                    state={this.state}
                                                    ruleSellerData={this.state.ruleSellerData}
                                                    editRuleShipping={this.handleEditRuleShipping}
                                                    cancelSaveFreeShipping={this.handleCancelSaveFreeShipping}
                                                    conta_id={getId()}
                                                    />
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    <></>
                                    }
                            </>
                        }

                    </TabPane>
                    <TabPane tabId={'tab_pay'}>
                        <Row>
                            <Col md={12}>
                                <h5>Frete Pagos</h5><hr/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <label htmlFor="min_amount">Valor mínimo</label>
                                <Input 
                                    className="form-control input-currency" 
                                    name="min_amount"
                                    value={this.state.pay_min_amount}
                                    onChange={(event) => {
                                        this.setState({
                                            pay_min_amount: event.target.value
                                        })
                                    }}
                                    />
                                    <small className="currency-simbol">R$</small>
                            </Col>
                            <Col md={6}>
                                <label htmlFor="max_amount">Valor máximo</label>
                                <Input 
                                    className="form-control input-currency" 
                                    name="max_amount"
                                    value={this.state.pay_max_amount}
                                    onChange={(event) => {
                                        this.setState({
                                            pay_max_amount: event.target.value
                                        })
                                    }}
                                    />
                                    <small className="currency-simbol">R$</small>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <label htmlFor="tax_type">Tipo de taxa</label>
                                <Select
                                    name="tax_type"
                                    placeholder="Tipo de taxa usada"
                                    options={this.state.tax_type_options}
                                    onChange={(select_option) => {
                                        this.setState({
                                            pay_type: select_option.value,
                                            pay_type_select: select_option,
                                        })
                                    }}
                                    value={this.state.pay_type_select}
                                />
                            </Col>
                            {this.state.pay_type_select.value === 'V' ?
                                <Col md={6}>
                                    <label htmlFor="tax_amount">Valor da taxa</label>
                                    <CurrencyInput 
                                        inputMode="tax_amount"
                                        className="form-control input-currency" 
                                        currency="BRL"
                                        name="min_amount"
                                        value={this.state.pay_tax_amount}
                                        onChange={(event) => {
                                            this.setState({
                                                pay_tax_amount: event.target.value
                                            })
                                        }}
                                        />
                                        <small className="currency-simbol">R$</small>
                                </Col>
                                :
                                <Col md={6}>
                                    <label htmlFor="tax_percent">Porcentagem da taxa</label>
                                    <Input 
                                        className="form-control input-currency" 
                                        name="min_amount"
                                        value={this.state.pay_tax_percent}
                                        onChange={(event) => {
                                            this.setState({
                                                pay_tax_percent: event.target.value
                                            })
                                        }}
                                        />
                                        <small className="currency-simbol">%</small>
                                </Col>
                            }
                        </Row> 
                        <Row> 
                            <Col md={6}>
                                <label htmlFor="weight">Peso máximo</label>
                                <Input 
                                    className="form-control input-currency" 
                                    name="weight"
                                    value={this.state.pay_weight}
                                    onChange={(event) => {
                                        this.setState({
                                            pay_weight: event.target.value
                                        })
                                    }}
                                    />
                                    <small className="currency-simbol">Kg</small>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Button 
                                    className="btn btn-success btn-save" 
                                    onClick={(event) => this.handleSavePayShipping()}
                                >
                                    <FaSave style={{height: '1rem', padding: 0}}/> Salvar
                                </Button>
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane tabId={'tab_serasa'}>
                        <Row><Col md={6}>
                        <div style={{
                            overflow: 'auto',
                            height: this.state.hitorico_consultas_vendedor !== undefined && this.state.hitorico_consultas_vendedor.length > 0 ? '855px' : 'auto', 
                            padding: '15px', 
                            border: '1px solid #dee2e6', 
                            borderRadius: 5}}>
                            <h5>Consultas Bureau</h5><hr/>
                            {this.state.hitorico_consultas_vendedor !== undefined && this.state.hitorico_consultas_vendedor.length > 0 ?
                                
                                this.state.hitorico_consultas_vendedor.map((consulta, i) => {

                                    if (i == 0) {
                                        return (
                                        <div key={'div_historico_vendedor_'+i}>
                                                <Button 
                                                    disabled={this.state.loading_analises_ip} 
                                                    onClick={(event) => {this.handleOpenModalDetalhado(consulta)}} 
                                                    style={{margunTop: '7px',float: 'right',position: 'relative',top: '-5px'}}
                                                    color="info">
                                                    {this.state.loading_analises_ip === true ?
                                                        <ClipLoader color="#fff" size={15}/>
                                                        :
                                                        <FaEye />
                                                    } Ver Detalhado
                                                </Button>
                                            <p>
                                                <b>Data da consulta:</b> {moment(consulta.created_at).format('DD/MM/YYYY HH:mm')}
                                            </p>
                                            <Card style={{marginBottom: '1rem', padding: '1rem'}}>
                                                <div key={"score_bar"+consulta.id} style={{position: 'relative'}}>
                                                    <ReactScoreIndicator 
                                                        stepsColors={[
                                                            '#d12000',
                                                            '#ed8d00',
                                                            '#f1bc00',
                                                            '#84c42b',
                                                            '#84c42b',
                                                            '#84c42b',
                                                            '#3da940',
                                                            '#3da940',
                                                            ]}
                                                        lineWidth={30}
                                                        value={consulta.payload.resposta.ocorrencias.score.score.pontos}
                                                        maxValue={1000}
                                                        />
                                                    <span style={{
                                                        position: 'absolute',
                                                        top: '2rem', 
                                                        width: '100%',
                                                        fontSize: '55px',
                                                        textAlign: 'center',
                                                        fontWeight: 'bold'
                                                    }}>
                                                        {consulta.payload.resposta.ocorrencias.score.score.classe}
                                                    </span>
                                                    <h5 style={{textAlign: 'center'}}>{consulta.payload.resposta.ocorrencias.score.score.faixa.titulo}</h5>
                                                    <p>{consulta.payload.resposta.ocorrencias.score.score.faixa.descricao}</p>
                                                </div>
                                                <p>
                                                    <b>Renda Presumida:</b>&nbsp;                                                         
                                                    <NumberFormat 
                                                        prefix="R$ " 
                                                        displayType="text" 
                                                        value={parseFloat(consulta.payload.resposta.resumos.rendaPresumida)} 
                                                        decimalSeparator=","/>
                                                </p>
                                                <p>
                                                    <b>{consulta.payload.resposta.resumos.debitos.sumQuantidade} Débito{consulta.payload.resposta.resumos.debitos.sumQuantidade > 1 ? 's' : ''}:</b>&nbsp;                                                         
                                                    <NumberFormat 
                                                        prefix="R$ " 
                                                        displayType="text" 
                                                        value={parseFloat(consulta.payload.resposta.resumos.debitos.sumValorTotal)} 
                                                        decimalSeparator=","/>
                                                </p>
                                                <p>
                                                    <b>{consulta.payload.resposta.resumos.protestos.sumQuantidade} Protesto{consulta.payload.resposta.resumos.protestos.sumQuantidade > 1 ? 's' : ''} :</b>&nbsp;                                                         
                                                    <NumberFormat 
                                                        prefix="R$ " 
                                                        displayType="text" 
                                                        value={parseFloat(consulta.payload.resposta.resumos.protestos.sumValorTotal)} 
                                                        decimalSeparator=","/>
                                                </p>
                                                {consulta.payload.resposta.resumos.acoes ?
                                                    <p>
                                                        <b>{consulta.payload.resposta.resumos.acoes.sumQuantidade} Aç{consulta.payload.resposta.resumos.acoes.sumQuantidade > 1 ? 'ões' : 'ão'}:</b>&nbsp;                                                         
                                                        <NumberFormat 
                                                            prefix="R$ " 
                                                            displayType="text" 
                                                            value={parseFloat(consulta.payload.resposta.resumos.acoes.sumValorTotal)} 
                                                            decimalSeparator=","/>
                                                    </p>
                                                    :
                                                    <></>
                                                }
                                                <p>
                                                    <b>{consulta.payload.resposta.resumos.cheques.sumQuantidade} Cheque{consulta.payload.resposta.resumos.cheques.sumQuantidade > 1 ? 's' : ''}</b>&nbsp;                                                         
                                                </p>
                                            </Card>

                                            <hr />
                                            
                                        </div>
                                        )
                                    } else {
                                        return (
                                            <div key={'div_historico_vendedor_'+i}>
                                                    <Button 
                                                        disabled={this.state.loading_analises_ip} 
                                                        onClick={(event) => {this.handleOpenModalDetalhado(consulta)}} 
                                                        style={{margunTop: '7px',float: 'right',position: 'relative',top: '-5px'}}
                                                        color="info">
                                                        {this.state.loading_analises_ip === true ?
                                                            <ClipLoader color="#fff" size={15}/>
                                                            :
                                                            <FaEye />
                                                        } Ver Detalhado
                                                    </Button>
                                                <p>
                                                    <b>Data da consulta:</b> {moment(consulta.created_at).format('DD/MM/YYYY HH:mm')}
                                                </p>

                                                <hr />
                                            </div>
                                        )
                                    }
                                })
                            :
                                <>
                                    <Button 
                                        color="success" 
                                        style={{marginLeft: '1rem'}}
                                        onClick={() => {this.modalConsultaSeller(true)}} 
                                        disabled={this.state.loading_consultar_vendedor}>
                                        {this.state.loading_consultar_vendedor === true ? 
                                        <ClipLoader color="#fff" size={15}/>
                                        :
                                        <FaSearch />
                                        }
                                        Consultar Crédito
                                    </Button>
                                    <p>Nenhuma consulta encontrada</p>
                                </>
                            }
                        </div>
                        </Col>
                        <Col md={6}>
                        <div style={{
                                overflow: 'auto',
                                height: this.state.hitorico_consultas_localiza !== undefined && this.state.hitorico_consultas_localiza.length > 0 ? '855px' : 'auto',                                
                                padding: '15px', 
                                border: '1px solid #dee2e6', 
                                borderRadius: 5
                                }}>
                                <Button 
                                    disabled={this.state.loading_consultar_localiza_vendedor} 
                                    onClick={() => {this.modalConsultaSellerLocalizador(true)}} 
                                    style={{margunTop: '7px',float: 'right',position: 'relative',top: '-5px'}}
                                    color="success">
                                    {this.state.loading_consultar_localiza_vendedor === true ?
                                        <ClipLoader color="#fff" size={15}/>
                                        :
                                        <FaSearch />
                                    } Consultar
                                </Button>

                                {/* <Button 
                                            color="success" 
                                            style={{marginLeft: '1rem'}}
                                            onClick={() => {this.modalConsultaSeller(true)}} 
                                            disabled={this.state.loading_consultar_vendedor}>
                                            {this.state.loading_consultar_vendedor === true ? 
                                            <ClipLoader color="#fff" size={15}/>
                                            :
                                            <FaSearch />
                                            }
                                            Consultar Crédito
                                        </Button> */}

                                <h5>Localizador AQBank</h5><hr/>
                                {this.state.hitorico_consultas_localiza !== undefined && this.state.hitorico_consultas_localiza.length > 0 ?
                                    
                                    this.state.hitorico_consultas_localiza.map((consulta, i) => {
                                        if (i == 0) {
                                            return (
                                            <div key={'div_historico_comprador_'+i}>
                                                    <Button 
                                                        disabled={this.state.loading_analises_ip} 
                                                        onClick={(event) => {this.handleOpenModalLocalizaDetalhado(consulta)}}  
                                                        style={{margunTop: '7px',float: 'right',position: 'relative',top: '-5px'}}
                                                        color="info">
                                                        {this.state.loading_analises_ip === true ?
                                                            <ClipLoader color="#fff" size={15}/>
                                                            :
                                                            <FaEye />
                                                        } Ver Detalhado
                                                    </Button>
                                                <p>
                                                    <b>Data da consulta:</b> {moment(consulta.created_at).format('DD/MM/YYYY HH:mm')}
                                                </p>
                                                <Card style={{marginBottom: '1rem', padding: '1rem'}}>
                                                    <div key={"localiza_bar_"+consulta.id} style={{position: 'relative'}}>
                                                        <h5>Endereços</h5><hr/>
                                                        {consulta.payload.resposta.enderecos !== undefined && consulta.payload.resposta.enderecos.length > 0 ?
                                                            consulta.payload.resposta.enderecos.map((endereco, ps) => {
                                                                return (
                                                                    <div>
                                                                        <p>{endereco.cep}</p>
                                                                        <p>{endereco.tipoLogradouro}. {endereco.logradouro}, {endereco.numero} {endereco.complemento !== undefined && endereco.complemento !== '' ? ' - ' + endereco.complemento : ''}</p>
                                                                        <p>{endereco.bairro}</p>
                                                                        <p>{endereco.cidade} / {endereco.uf}</p><hr/>
                                                                    </div>
                                                                )
                                                            })
                                                        :
                                                        <></>
                                                        }
                                                        
                                                    </div>
                                                </Card>
                                                
                                                <Card style={{marginBottom: '1rem', padding: '1rem'}}>
                                                    {consulta.payload.resposta.telefones.fixos !== undefined &&
                                                    consulta.payload.resposta.telefones.fixos.length > 0 ?
                                                        
                                                    consulta.payload.resposta.telefones.fixos.map((telefone, ps) => {
                                                            return(
                                                                <div>
                                                                    <h5>Fixo: {telefone.numero}</h5><hr/>
                                                                    {telefone.numero !== undefined ? 
                                                                        <div>
                                                                            {/* <p><b>Número:</b> </p> */}
                                                                            <p><b>Relação:</b> {telefone.relacao}</p>
                                                                            <p><b>Não pertube:</b> {telefone.naoPerturbe == false ? 'Não' : 'Sim'}</p>
                                                                            <p><b>WhatsApp Business:</b> {telefone.aplicativos.whatsAppBusiness == false ? 'Não' : 'Sim'}</p>
                                                                        </div>
                                                                    :
                                                                        <></>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    :
                                                        <></>
                                                    }

                                                    {consulta.payload.resposta.telefones.moveis !== undefined &&
                                                    consulta.payload.resposta.telefones.moveis.length > 0 ?
                                                        
                                                    consulta.payload.resposta.telefones.moveis.map((telefone, i) => {
                                                            return(
                                                                <div>
                                                                    <h5>Celular: {telefone.numero}</h5><hr/>
                                                                    {telefone.numero !== undefined ? 
                                                                        <div>
                                                                            {/* <p><b>Número:</b> </p> */}
                                                                            <p><b>Relação:</b> {telefone.relacao}</p>
                                                                            <p><b>Não pertube:</b> {telefone.naoPerturbe == false ? 'Não' : 'Sim'}</p>
                                                                            <p><b>WhatsApp:</b> {telefone.aplicativos.whatsApp == false ? 'Não' : 'Sim'}</p>
                                                                        </div>
                                                                    :
                                                                        <></>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    :
                                                        <></>
                                                    }
                                                </Card>                                                
                                            </div>
                                            )
                                        } else {
                                            return (
                                                <div style={{marginTop: '1rem'}} key={'div_historico_vendedor_localiza_'+consulta.id}>
                                                    <hr/>
                                                        <Button 
                                                            disabled={this.state.loading_analises_ip} 
                                                            onClick={(event) => {this.handleOpenModalLocalizaDetalhado(consulta)}} 
                                                            style={{margunTop: '7px',float: 'right',position: 'relative',top: '-5px'}}
                                                            color="info">
                                                            {this.state.loading_analises_ip === true ?
                                                                <ClipLoader color="#fff" size={15}/>
                                                                :
                                                                <FaEye />
                                                            } Ver Detalhado
                                                        </Button>
                                                    <p>
                                                        <b>Data da consulta:</b> {moment(consulta.created_at).format('DD/MM/YYYY HH:mm')}
                                                    </p>

                                                    <hr />
                                                </div>
                                            )
                                        }
                                    })
                                :
                                    <p>Nenhuma consulta encontrada</p>
                                }
                            </div>  
                        </Col>
                        </Row>

                        <ConsultaMixDataView
                        visible={this.state.visible_consulta_view}
                        onClose={() => this.modalConsultaView(false)}
                        callback={this.callback_consultar_seller}
                        consulta_view={this.state.consulta_view}
                        />

                    
                        <ConsultarSellerLocaliza
                        visible={this.state.visible_consulta_localiza_vendedor}
                        onClose={() => this.modalConsultaSellerLocalizador(false)}
                        callback={this.callback_consultar_seller_localiza}
                        nome_comprador={this.state.seller_name}
                        cpf_comprador={this.state?.seller_document}
                        /> 

                        <ConsultarSeller 
                        visible={this.state.visible_consulta_vendedor}
                        onClose={() => this.modalConsultaSeller(false)}
                        callback={this.callback_consultar_seller}
                        nome_comprador={this.state.seller_name}
                        cpf_comprador={this.state?.seller_document}
                        /> 

                    </TabPane>
                    <TabPane tabId={'tab_limit_credit'}> 
                        {this.state.list_limit !== undefined && this.state.list_limit.length > 0 ?
                            (this.state.list_limit.map((field, i) => {
                                if(field.status == 1) {
                                    return (
                                    <Row>
                                        <Col>
                                            <h5 style={{padding: '1rem', backgroundColor: '#28a745',color: '#ffffff',borderRadius: '3px 3px 0px 0px'}}>Limite Atual: {parseFloat(field.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h5>
                                        </Col>
                                    </Row>
                                    )
                                }
                            }))
                            :
                            <></>
                        }
                        <Row>
                            <Col md={12}>
                                <div key="div-page-2" style={{padding: '1rem',marginTop: '2rem', border: '1px solid #dee2e6', borderRadius: 5}}>
                                    <h5>Atribuir novo limite de crédito</h5><hr/>
                                    <small>Limite referente ao plano pós pago.</small>
                                    <div key="div-page-3" style={{padding: '15px'}}>
                                        <Row>
                                            <Col md={6}>
                                                <label htmlFor="limit_credit">Valor do limite</label>
                                                <Input 
                                                    className="form-control input-currency" 
                                                    name="limit_credit"
                                                    placeholder="0,00"
                                                    value={this.state.limit_credit}
                                                    onChange={this.handleInputChange}
                                                    />
                                                    <small className="currency-simbol">R$</small>
                                            </Col>
                                            <Col md={6}>
                                                <label htmlFor="motivos_limite">Motivo de Alteração</label>
                                                <Select
                                                    name="motivos_limite"
                                                    placeholder="Selecione..."
                                                    options={motivos_limite}
                                                    onChange={(select_option) => {
                                                        this.setState({
                                                            motivos_limite: select_option.value,
                                                            motivos_limite_option: select_option,
                                                        })
                                                    }}
                                                    value={this.state.motivos_limite_option}
                                                />
                                            </Col> 
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Collapse isOpen={this.state.is_open_nota}>
                                                    <Input
                                                        name="nota_limite"
                                                        placeholder="Descreva sua nota"
                                                        value={this.state.nota_limite}
                                                        type="textarea"
                                                        style={{
                                                            height: '124px',
                                                            resize: 'none'
                                                        }}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </Collapse>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <hr/>
                                                <Button 
                                                    color="success" 
                                                    onClick={() => this.setState({is_open_nota: !this.state.is_open_nota})}>
                                                        <FaPen /> Adicionar Nota
                                                </Button>

                                                <Button 
                                                    color="success" 
                                                    style={{float: 'right'}}
                                                    onClick={() => {this.handleSaveNewLimit()}}>
                                                        <FaSave /> Salvar limite
                                                </Button>
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                            <div key="div-page-4" style={{marginTop: '2rem', padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                                <h5>Notas</h5><hr/>
                                                <ul style={{listStyleType: 'circle'}}>
                                                    {this.state.notas.map(p => {
                                                        return <li key={p.id} style={{marginBottom: '7px'}}>
                                                            <b>Operador:</b> {p.operador !== null ? p.operador : 'Sem Informações'} <br/>
                                                            <div key="div-page-5" style={{textTransform: 'uppercase', border: '1px solid #7f7f7f66', borderRadius: 5, padding: '15px'}}>
                                                                {p.nota.split('\n').map((x, i, {length}) => (
                                                                    <p style={{margin: (i + 1 === length ? 0 : 3), fontSize: 14}}>{x}</p>
                                                                ))}
                                                            </div>
                                                            <small>
                                                                {moment(p.data).format('DD/MM/YYYY HH:mm')}&nbsp;
                                                            <ButtonRed 
                                                                    hidden={!this.hiddenDelete(p.admin_id)}
                                                                    disabled={this.state.loading_nota_id === p.id}
                                                                    onClick={() => this.deletarNota(p.id)}>
                                                                        Apagar        
                                                                </ButtonRed>
                                                            </small>
                                                        </li>
                                                    })}
                                                </ul>
                                                {this.state.notas.length === 0 ? <p>Nenhuma nota</p> : ''}


                                            </div>
                                        </Row> */}
                                    </div>
                                </div>
                            </Col>
                        </Row> 
                        <Row> 
                            <Col md={12}>
                                <div key="div-page-6" style={{overflow: 'auto', maxHeight: '600px', marginTop: '2rem', padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                    <h5>Histórico de limite</h5><hr/>

                                    {this.state.list_limit !== undefined && this.state.list_limit.length > 0 ?
                                        (this.state.list_limit.map((field, i) => {

                                            let hexColor = field.status === 1 ? '1px solid #28a745': '1px solid #B22222'
                                            return ( // B22222 vm #28a745
                                                <Row style={{border: hexColor, borderRadius: 5, padding: '15px'}}> 
                                                    <Col md={12}>
                                                        <strong style={{marginBottom: '1rem'}}>Limite Pós Pago:</strong> {parseFloat(field.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}<br/>
                                                        <strong style={{marginBottom: '1rem'}}>Status:</strong> {field.status === 1 ? ('Ativo') : ('Desativado')}<br/>
                                                        <strong style={{marginBottom: '1rem'}}>Criado:</strong> {moment(field.created_at).format('DD/MM/YYYY HH:mm')}<br/>
                                                        <strong style={{marginBottom: '1rem'}}>Atualizado:</strong> {moment(field.updated_at).format('DD/MM/YYYY HH:mm')}<br/>
                                                        <strong style={{marginBottom: '1rem'}}>Motivo da Alteração:</strong> 
                                                            {motivos_limite.map((motivo, p)=>{ 
                                                                if(motivo.value == field.reason) {
                                                                    return (
                                                                        <> {motivo.label}</>
                                                                    ) 
                                                                }
                                                            })}
                                                        <br/>
                                                        {/* <>
                                                            {field.notes.map((nota, p)=>{ 
                                                                return (
                                                                    <>
                                                                        <small><b>Operador: </b>{nota.admin}</small> <small><b>Data: </b>{moment(nota.date).format('DD/MM/YYYY HH:mm')}</small><br/>
                                                                        <b>Nota: </b>{nota.notes}<br/><br/><br/>
                                                                    </>
                                                                )
                                                            })}
                                                        </> */}
                                                        {field.notes !== undefined && field.notes != null ? 
                                                            <ul style={{ marginTop: '1rem', listStyleType: 'none'}}>
                                                                {field.notes.map(p => {

                                                                    return <li key={Math.floor(Math.random() * 10)} style={{marginBottom: '7px'}}>
                                                                        <b>Operador:</b> {p.admin !== null ? p.admin : 'Sem Informações'} <br/>
                                                                        <div key={String(Math.floor(Math.random() * 100))} style={{textTransform: 'uppercase', border: '1px solid #7f7f7f66', borderRadius: 5, padding: '15px'}}>
                                                                            {p.note.split('\n').map((x, i, {length}) => (
                                                                                <p style={{margin: (i + 1 === length ? 0 : 3), fontSize: 14}}>{x}</p>
                                                                            ))}
                                                                        </div>
                                                                        <small>
                                                                            {moment(p.date).format('DD/MM/YYYY HH:mm')}&nbsp;
                                                                        </small>
                                                                    </li>
                                                                })}
                                                            </ul>
                                                        :
                                                        <></>
                                                        }
                                                        <hr />
                                                    </Col>
                                                </Row> 
                                            )
                                        }))
                                    :
                                        <>Sem registros</>
                                    }
                                </div>
                            </Col>
                        </Row> 

                    </TabPane>
                    <TabPane tabId={'tab_extrato'}>
                        <Row>
                            <Col md={12}>
                                <div key="div-movimentacaao" 
                                    style={{padding: '1rem',
                                    marginTop: '2rem', 
                                    marginBottom: '2rem', 
                                    border: '1px solid #dee2e6', 
                                    background: 'rgba(0, 0, 0, 0.1)',
                                    borderRadius: 5}}>
                                    <h5
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                    >
                                        <div key='sub-title-mov-1'style={{width: '40%', paddingTop: '0.5rem'}}>
                                            <FaFunnelDollar /> Movimentações do Período: 
                                        </div>
                                        <div key='sub-title-mov-2' style={{width: '35%'}}>
                                            <Select
                                                name="period_extract"
                                                placeholder="Selecione..."
                                                className="select-period"
                                                options={[
                                                    {label: 'Todo', value: 'todo'},
                                                    {label: 'Último Dia', value: 'last_day'},
                                                    {label: 'Última Semana', value: 'last_week'},
                                                    {label: 'Último Mês', value: 'last_month'},
                                                    {label: 'Últimos 3 Meses', value: 'last_three_month'},
                                                    {label: 'Últimos 6 Meses', value: 'last_six_month'},
                                                    {label: 'Último Ano', value: 'last_year'},
                                                    {label: 'Personalizado', value: 'custom'},
                                                ]}
                                                value={this.state.period_extract}
                                                onChange={(select) => {
                                                    this.handleChangeExtractPeriod(select)
                                                }}
                                                    />
                                        </div>
                                    </h5>
                                    
                                    
                                    <Row hidden={this.state.period_extract.value != 'custom' ? true : false} style={{marginBottom: '1.5rem'}}>
                                        <Col md={6}>
                                            <div style={{ textAlign: 'left' }}>
                                                <label htmlFor="start_date">Período Inicial</label>
                                                <Input
                                                    name="start_date"
                                                    type="date"
                                                    value={this.state.start_date}
                                                    onChange={(event) => {
                                                        this.setState({start_date: event.target.value})
                                                        this.handleChangeExtractPeriod(this.state.period_extract)
                                                    }} 
                                                    />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div style={{ textAlign: 'left' }}>
                                                <label htmlFor="end_date">Período Final</label>
                                                <Input
                                                    name="end_date"
                                                    type="date"
                                                    value={this.state.end_date}
                                                    onChange={(event) => {
                                                        this.setState({end_date: event.target.value})
                                                        this.handleChangeExtractPeriod(this.state.period_extract)
                                                    }} 
                                                    />
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row hidden={!this.state.loading_moviments}>
                                        <Col md={12}>
                                            <center>
                                                <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                                            </center>
                                            <br/>
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '1.5rem'}}>
                                        <Col md={6} style={{padding: '0.5rem'}}>
                                            <h5>Total Faturado</h5>
                                            <strong style={{fontSize: '18px'}}>{parseFloat(this.state.moviments?.bill ? this.state.moviments.bill : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
                                        </Col>
                                        <Col md={6} style={{padding: '0.5rem'}}>
                                            <h5>Lançamentos Futuros</h5>
                                            <strong style={{fontSize: '18px'}}>{parseFloat(this.state.moviments?.futures ? this.state.moviments.futures : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '1.5rem'}}>
                                        <Col md={12} style={{padding: '0.5rem'}}>
                                            <Button
                                                color="warning"
                                                style={{float: 'right'}}
                                                >
                                                <FaAngleDown /> Detalhado
                                            </Button>
                                        </Col>
                                    </Row>
                                    <div 
                                    style={{
                                        border: '1px solid #dee2e6', 
                                        background: 'rgba(0, 0, 0, 0.1)',
                                        borderRadius: 5
                                    }}>
                                    <Row style={{marginBottom: '1.5rem'}}>
                                        <Col md={6} style={{padding: '0.5rem'}}>
                                            <strong><FaCreditCard /> Pagar por Etiqueta</strong>
                                            <p style={{margin: '0.5rem 0'}}>{this.state.moviments?.pay_label.total} Pagamentos:  <strong>{parseFloat(this.state.moviments?.pay_label ? this.state.moviments.pay_label.amount : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></p>
                                        </Col>
                                        <Col md={6}>
                                            
                                            <strong><FaWallet /> Pré Pago - Saldo: {parseFloat(this.state.moviments?.pay_pre ? this.state.moviments.pay_pre.balance : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
                                            <p style={{margin: '0.5rem 0'}}>{this.state.moviments?.pay_pre.total} Recebidos:  <strong>{parseFloat(this.state.moviments?.pay_pre ? this.state.moviments.pay_pre.amount : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></p>
                                            <p style={{margin: '0.5rem 0'}}>{this.state.moviments?.pay_pre.extra_total} Bônificação:  <strong>{parseFloat(this.state.moviments?.pay_pre ? this.state.moviments.pay_pre.extra_amount : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></p>
                                            <p style={{margin: '0.5rem 0'}}>{this.state.moviments?.pay_pre.used_total} Faturados: <strong>{parseFloat(this.state.moviments?.pay_pre ? this.state.moviments.pay_pre.used_amount : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></p>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row style={{marginBottom: '1.5rem'}}>
                                        <Col md={6}>
                                            <strong><FaFileInvoiceDollar /> Pós Pago - Limite: {parseFloat(this.state.moviments?.pay_pos ? this.state.moviments.pay_pos.limit : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
                                            <p style={{margin: '0.5rem 0'}}>{this.state.moviments?.pay_pos.cob_total} Lançamentos:  <strong>{parseFloat(this.state.moviments?.pay_pos ? this.state.moviments.pay_pos.cob_amount : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></p>
                                            <p style={{margin: '0.5rem 0'}}>Saldo Pós: <strong>{parseFloat(this.state.moviments?.pay_pos ? this.state.moviments.pay_pos.balance : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></p>
                                            <hr />
                                            <p style={{margin: '0.5rem 0'}}>{this.state.moviments?.pay_pos.total} Faturados: <strong>{parseFloat(this.state.moviments?.pay_pos ? this.state.moviments.pay_pos.amount : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></p>
                                        </Col>
                                        <Col md={6}>
                                            <strong><FaFileImport /> Débitos - Saldo: {parseFloat(this.state.moviments?.balance ? this.state.moviments.debit.balance : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
                                            <p style={{margin: '0.5rem 0'}}>{this.state.moviments?.debit.total } Pagamentos: <strong>{parseFloat(this.state.moviments?.debit ? this.state.moviments.debit.amount : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></p>
                                            <p style={{margin: '0.5rem 0'}}>{this.state.moviments?.debit.amount_schedule_total } Agendados: <strong>{parseFloat(this.state.moviments?.debit ? this.state.moviments.debit.amount_schedule : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></p>
                                        </Col>
                                    </Row>
                                    </div>
                                    <hr />
                                    <h5> <FaTruck /> Movimentações de Etiquetas</h5>
                                    <hr/>
                                    <Row>
                                        <Col md={6} style={{padding: '0.5rem'}}>
                                            <strong><FaCreditCard /> Etiquetas Pagas</strong>
                                            <p style={{margin: '0.5rem 0'}}>{this.state.moviments?.label.total} Criadas: <strong> {parseFloat(this.state.moviments?.label ? this.state.moviments.label.amount : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></p>
                                            <p style={{margin: '0.5rem 0'}}>{this.state.moviments?.label.used_total} Utilizadas: <strong> {parseFloat(this.state.moviments?.label ? this.state.moviments.label.used_amount : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></p>
                                            <p style={{margin: '0.5rem 0'}}>{this.state.moviments?.simulate_label.total} Simulações: <strong> {parseFloat(this.state.moviments?.simulate_label ? this.state.moviments.simulate_label.amount : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></p>
                                        </Col>
                                        <Col md={6}>
                                            <strong><FaPercentage /> Etiquetas Grátis</strong>
                                            <p style={{margin: '0.5rem 0'}}>{this.state.moviments?.free_label.total} Utilizadas: <strong> {parseFloat(this.state.moviments?.free_label ? this.state.moviments.free_label.amount : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></p>
                                            <p style={{margin: '0.5rem 0'}}>{this.state.moviments?.free_label.available_total} Disponibilizadas:  <strong> {parseFloat(this.state.moviments?.free_label ? this.state.moviments.free_label.available_amount : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></p>
                                       </Col>
                                    </Row>
                                </div>
                                    
                                 <ListarExtrato
                                        state={this.state}
                                        ruleSellerData={this.state.ruleSellerData}
                                        />
                            </Col>
                        </Row>
                    </TabPane> 

                    <TabPane tabId={'tab_labels'}>
                        <div key="div-label-create" style={{ 
                            margin: '1rem', 
                            padding: '15px', 
                            border: '1px solid #dee2e6', 
                            borderRadius: 5,
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                            <Button 
                                outline 
                                color="success"
                                onClick={() => {
                                    this.setState({form_simulate: true})
                                }}
                            >
                                Simular Frete
                            </Button>

                            <Button 
                                outline 
                                color="success"
                                onClick={() => {}}
                            >
                                Rastreio de Objeto
                            </Button>
                        </div>

                        <Row>
                            <Col md={6}>

                            </Col>
                            <Col md={6}></Col>
                        </Row>

                        <div key="div-simulate-key" hidden={!this.state.form_simulate}>
                            <Row>
                                {this.state?.seller_address ? (
                                    <Col md={6}>
                                        <h5>Origem</h5>
                                        {this.state?.seller_address?.street}, {this.state?.seller_address?.number} {this.state?.seller_address?.complement ?  ' - ' + this.state?.seller_address?.complement + ' - ' : (<></>)} - {this.state?.seller_address?.district} - {this.state?.seller_address?.city} / {this.state?.seller_address?.uf} - {this.state?.seller_address?.cep}<br />
                                    </Col>
                                ) : (
                                    <Col md={6}></Col>
                                )}

                                <Col md={6} hidden={this.state.form_receiver}>
                                    <label htmlFor="payment_id">
                                        ID Pagamento (opcional) <small>para regra de frete grátis</small>
                                    </label>
                                    <Input
                                        name="payment_id"
                                        placeholder="ID Pagamento"
                                        value={this.state.payment_id}
                                        type="text"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </Row>

                            <Row hidden={this.state.form_receiver}>
                                <Col md={3}>
                                    <label htmlFor="zip_code_origin">
                                        Cep de destino
                                    </label>
                                    <Input
                                        name="zip_code_origin"
                                        placeholder="08000000"
                                        value={this.state.zip_code_origin}
                                        type="text"
                                        onChange={this.handleInputChangeCep}
                                    />
                                </Col>
                            </Row>
                            {this.state?.destination ? (
                                <Row hidden={this.state.form_receiver}>
                                    <Col md={12}>
                                        {this.state?.destination?.logradouro} {this.state?.destination?.complemento ?  ' - ' + this.state?.destination?.complemento + ' - ' : (<></>)} - {this.state?.destination?.bairro} - {this.state?.destination?.localidade} / {this.state?.destination?.uf}<br />
                                    </Col>
                                </Row>
                            ) : (
                                <></>
                            )}

                            <Row hidden={this.state.form_receiver}>
                                <Col md={12} style={{margin: '1rem 0'}}>
                                    <h5>Volume</h5>
                                </Col>
                            </Row>

                            <Row hidden={this.state.form_receiver}>
                                <Col md={2}>
                                    <label htmlFor="shipping_height">Altura</label>
                                    <Input
                                        name="shipping_height"
                                        value={this.state.shipping_height}
                                        type="text"
                                        className="form-control input-currency" 
                                        onChange={this.handleInputChangeCep}
                                    />
                                    <small className="currency-simbol">cm</small>
                                </Col>
                                <Col md={2}>
                                    <label htmlFor="shipping_width">Largura</label>
                                    <Input
                                        name="shipping_width"
                                        value={this.state.shipping_width}
                                        type="text"
                                        className="form-control input-currency" 
                                        onChange={this.handleInputChangeCep}
                                    />
                                    <small className="currency-simbol">cm</small>
                                </Col>

                                <Col md={2}>
                                    <label htmlFor="shipping_length">Comprimento</label>
                                    <Input
                                        name="shipping_length"
                                        value={this.state.shipping_length}
                                        type="text"
                                        className="form-control input-currency" 
                                        onChange={this.handleInputChangeCep}
                                    />
                                    <small className="currency-simbol">cm</small>
                                </Col>

                                <Col md={3}>
                                    <label htmlFor="merchandise_weight">Peso</label>
                                    <Input
                                        name="merchandise_weight"
                                        value={this.state.merchandise_weight}
                                        type="text"
                                        className="form-control input-currency" 
                                        onChange={this.handleInputChangeCep}
                                    />
                                    <small className="currency-simbol">Kg</small>
                                </Col>

                                <Col md={3}>
                                    <label htmlFor="commodity_value">Valor declarado</label>
                                    <CurrencyInput 
                                        inputMode="decimal"
                                        className="form-control input-currency" 
                                        currency="BRL"
                                        name="commodity_value"
                                        defaultValue={this.state.commodity_value}
                                        onChange={(event) => {this.handleInputChange(event)}}
                                        />
                                    <small className="currency-simbol">R$</small>
                                </Col>
                            </Row>

                            <Row hidden={this.state.form_receiver}>
                                <Col md={12}>
                                    <Button 
                                        color="warning"
                                        style={{float:'right', padding: '0.7rem'}} 
                                        onClick={() => {
                                            this.handleSimulateShipping()
                                        }}
                                    >
                                        Calcular Frete <FaSearch style={{fontSize:'0.5rem', padding: 0}}/>
                                    </Button>
                                </Col>
                            </Row>

                            {this.state.simulate_shippings.length > 0 ? (
                            <>
                            <h5 style={{margin: '1rem'}}>Resultados:</h5>
                            {this.state.simulate_shippings.map((simulate, i) => {
                                return (
                                    <Row style={{
                                        margin: '1rem',
                                        padding: '1rem', 
                                        border: '1px solid #dee2e6', 
                                        borderLeft: '5px solid #28a745', 
                                        borderRadius: 5
                                    }}>
                                        <Col md={3}>
                                            <strong>{simulate.name}</strong>
                                        </Col>
                                        <Col md={3}>
                                            {simulate.deadline} dias úteis
                                        </Col>
                                        <Col md={3}>
                                            {parseFloat(simulate.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                                        </Col>
                                        <Col md={3}>
                                            <Button
                                            outline
                                            style={{float: 'right'}}
                                            color="success"
                                            onClick={ () => {
                                                this.handleSetShipping(simulate.id)
                                            }}
                                            >
                                                Selecionar
                                            </Button>
                                        </Col>
                                    </Row>
                                )

                                })}
                                </>
                            )
                            : (<></> )}

                            <Row 
                            hidden={!this.state.form_receiver}
                            style={{
                                margin: '1rem',
                                padding: '1rem', 
                                border: '1px solid #dee2e6', 
                                borderLeft: '5px solid #28a745', 
                                borderRadius: 5
                            }}>
                                <Col md={6} style={{margin: '1rem 0'}}>
                                    <label htmlFor="conteudo">Conteúdo do pacote</label>
                                    <Input
                                        name="conteudo"
                                        placeholder="Descreva o conteúdo: Ex. Sapato, Bolsa, Calça"
                                        value={this.state.conteudo}
                                        type="text"
                                        className="form-control" 
                                        onChange={this.handleInputChange}
                                        />
                                </Col>
                                <Col md={6} style={{margin: '1rem 0'}}>
                                    <label htmlFor="rementente">Remetente</label>
                                    <Input
                                        name="rementente"
                                        disabled={true}
                                        placeholder=""
                                        type="text"
                                        value={this.state.seller_name}
                                    />
                                </Col>
                                <Col md={12} style={{margin: '1rem 0'}}>
                                    <h5>Destinatário:</h5><hr/>
                                </Col>
                                
                                <Col md={6}>
                                    <label htmlFor="receiver_document">CPF / CNPJ</label>
                                    <InputMasked
                                        name="receiver_document"
                                        disabled={false}
                                        mask={this.state.receiver_document !== undefined && this.state.receiver_document.length == 11 ? '999.999.999-99' : '99.999.999/9999-99'}
                                        value={this.state.receiver_document}
                                        /> 
                                </Col>
                                <Col md={6}>
                                    <label htmlFor="receiver_name">Nome</label>
                                    <Input
                                        name="receiver_name"
                                        placeholder=""
                                        type="text"
                                        value={this.state.receiver_name}
                                    />
                                </Col>
                                <Col md={6} style={{margin: '1rem 0'}}>
                                    <label htmlFor="receiver_celular">Celular</label>
                                    <Input
                                        name="receiver_celular"
                                        placeholder=""
                                        type="text"
                                        value={this.state.receiver_celular}
                                    />
                                </Col>
                                <Col md={6} style={{margin: '1rem 0'}}>
                                    <label htmlFor="receiver_email">E-mail</label>
                                    <Input
                                        name="receiver_email"
                                        placeholder=""
                                        type="text"
                                        value={this.state.receiver_email}
                                    />
                                </Col>
                                <Col md={6} style={{margin: '1rem 0'}}>
                                    <label htmlFor="receiver_zip_code">Cep</label>
                                    <Input
                                        name="receiver_zip_code"
                                        placeholder=""
                                        disabled={true}
                                        type="text"
                                        value={this.state.zip_code_origin}
                                    />
                                </Col>
                                <Col md={6} style={{margin: '1rem 0'}}>
                                    <label htmlFor="receiver_street">Logradouro</label>
                                    <Input
                                        name="receiver_street"
                                        placeholder=""
                                        disabled={true}
                                        type="text"
                                        value={this.state?.destination?.logradouro}
                                    />
                                </Col>
                                <Col md={6} style={{margin: '1rem 0'}}>
                                    <label htmlFor="receiver_number">Número</label>
                                    <Input
                                        name="receiver_number"
                                        placeholder=""
                                        type="text"
                                        value={this.state?.destination?.receiver_number}
                                    />
                                </Col>
                                <Col md={6} style={{margin: '1rem 0'}}>
                                    <label htmlFor="receiver_complement">Complemento</label>
                                    <Input
                                        name="receiver_complement"
                                        placeholder=""
                                        disabled={false}
                                        type="text"
                                        value={this.state?.destination?.receiver_complement}
                                    />
                                </Col>
                                <Col md={6} style={{margin: '1rem 0'}}>
                                    <label htmlFor="receiver_district">Bairro</label>
                                    <Input
                                        name="receiver_district"
                                        disabled={true}
                                        type="text"
                                        value={this.state?.destination?.bairro}
                                    />
                                </Col>
                                <Col md={6} style={{margin: '1rem 0'}}>
                                    <label htmlFor="receiver_city">Cidade</label>
                                    <Input
                                        name="receiver_city"
                                        disabled={true}
                                        type="text"
                                        value={this.state?.destination?.localidade}
                                    />
                                </Col>
                                <Col md={12} style={{margin: '1rem 0'}}>
                                    
                                    <Button
                                        outline
                                        style={{float: 'left'}}
                                        color="danger"
                                        onClick={ () => {
                                            this.setState({form_simulate: false, form_receiver: false})
                                        }
                                    }>
                                        Cancelar
                                    </Button>

                                    <Button
                                        outline
                                        style={{float: 'right'}}
                                        color="success"
                                        onClick={ () => {
                                            this.handleCreateNewLabel()
                                        }
                                    }>
                                        Gerar Pedido
                                    </Button>

                                </Col>
                            </Row>
                        </div>

                        <Row hidden={!this.state.loading_label}>
                            <Col md={12}>
                                <center>
                                    <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                                </center>
                                <br/>
                            </Col>
                        </Row>
                        
                        {!this.state.form_simulate ? (
                            <ListarEtiquetas
                                state={this.state}
                                ruleSellerData={this.state.ruleSellerData}
                                handleOpenLabelPDF={this.handleOpenLabelPDF.bind(this)}
                                handleDownloadLabelPDF={this.handleDownloadLabelPDF.bind(this)}
                            />
                        ) 
                        : 
                        (<></>)}

                    </TabPane>
                </TabContent>

            </Div>
            <br/>
            <br/>
            <ConfirmarRemoveFree 
                visible={this.state.visible_confirm}
                onClose={() => this.modalConfirm(false)}
                callback={this.state.callback_modal}
                /> 
        </Div>
        )
    }
}

export default ResumoVendedor;