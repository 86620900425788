import axios from 'axios';
import moment from 'moment';
import { FaEdit } from 'react-icons/fa';
import { HiViewGrid } from 'react-icons/hi';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import { ENDPOINT } from '../../../../auth/endpoint';
import { getToken } from '../../../../auth/set_services';
import { fetchWithTimeout } from '../../../Tools/fetchWithTimeout';

export const columns = (props, ruleSellerData) => [
    {
      name: "",
      selector: "buttons",
      sortable: false,
      width: '10%',
      cell: row => <Row>
        <Button
          outline
          style={{ padding: 2, marginLeft: 15, border: 0, fontWeight: '700'}}
          color="dark" onClick={() => 
            {
              ruleSellerData(row)
              // window.location.href = '/aqenvio-configuracao-detalhes/'+row.id
            }
          }>
          <FaEdit style={{ padding: '2px' }}/>
        </Button>
      </Row>
    },
    {
      name: "Cliente",
      selector: "nome",
      sortable: false,
      width: '40%',
      cell: row => <Row>
          <Col md={12}>
            <h4 style={{fontSize: '1.2rem'}}>{row.seller_name}</h4>
          </Col>
          <Col md={12} style={{marginBottom: '0.2rem'}}>
            <b>Documento:</b> {row.seller_document}
          </Col>
          <Col md={12} style={{marginBottom: '0.2rem'}}>
            <b>Email:</b> {row.seller_email}
          </Col>          
          <Col md={12} style={{marginBottom: '0.2rem'}}>
            {row.free == '1' ? 
              <h5 style={{color:'rgb(22 131 47)'}}>Frete Grátis</h5>
            :
              <h5 style={{color:'#ff0000'}}>Frete Pago</h5>
            }
          </Col>
      </Row>
    },
    {
      name: "Valores",
      selector: "valores",
      sortable: false,
      width: '20%',
      cell: row => <Row>
          <Col md={12}>
            <b>Mínimo</b>
          </Col>
          <Col md={12} style={{marginBottom: '0.5rem'}}>
            <NumberFormat 
                displayType={'text'}
                prefix={'R$ '}
                decimalSeparator={'.'}
                decimalScale={2}
                value={row.min_amount}
              />
          </Col>
          <Col md={12}>
            <b>Máximo</b>
          </Col>
          <Col md={12}>
            <NumberFormat 
                displayType={'text'}
                prefix={'R$ '}
                decimalSeparator={'.'}
                decimalScale={2}
                value={row.max_amount}
              />
          </Col>
      </Row>
    },
    {
      name: "Taxas",
      selector: "taxas",
      sortable: false,
      width: '15%',
      cell: row => <Row>
          <Col md={12}>
            <b>Tipo</b>
          </Col>
          {row.tax_type == 'P' ?
            <>
              <Col md={12} style={{marginBottom: '0.5rem'}}>
                Porcentagem
              </Col>
              <Col md={12}>
                <b>taxa</b>
              </Col>
              <Col md={12}>
                <NumberFormat 
                  displayType={'text'}
                  prefix={'% '}
                  decimalSeparator={'.'}
                  decimalScale={2}
                  value={row.tax_percent}
                  />
              </Col>
            </>
          :
            <>
              <Col md={12} style={{marginBottom: '0.5rem'}}>
                Valor
              </Col>
              <Col md={12}>
                <b>taxa</b>
              </Col>
              <Col md={12}>
                <NumberFormat 
                    displayType={'text'}
                    prefix={'R$ '}
                    decimalSeparator={'.'}
                    decimalScale={2}
                    value={row.tax_amount}
                  />
              </Col>
            </>
          }
      </Row>
    },
    {
      name: "Peso",
      selector: "taxas",
      sortable: false,
      width: '15%',
      cell: row => <Row>
          <Col md={12}>
            <NumberFormat 
              displayType={'text'}
              prefix={'Kg: '}
              decimalSeparator={'.'}
              decimalScale={3}
              value={row.weight}
              />
          </Col>
      </Row>
    },
];

var numberOfAjaxCAllPending = 0;

export const getRows = (state, callback) => {
  if(numberOfAjaxCAllPending == 0){
    numberOfAjaxCAllPending = 1;
    var url = ENDPOINT + "aqenvio/list_rules?page=" + state.activePage + "&search=" + state.search;
    //var url = "http://localhost:8000/api/aqenvio/list_rules?page=" + state.activePage + "&search=" + state.search;

    if(state.rule_for !== null){
      url += "&rule_for="+state.rule_for.value;
    }      
    
    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        },
        mode: 'cors',
        cache: 'default'
    })
    .then((response) => {
        let p = response.data;
        if(p.success == true){
          var meta = 0;
          if(p.meta){
            meta = p.meta.last_page;
          }
          numberOfAjaxCAllPending = 0;
          // callback(p.data, meta, p.valor);
          callback(p.data, meta);
        }
        else{
          toast.error(p.errors);
        }
    })
    .catch((error) => {
        toast.error('Ocorreu um erro no servidor!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          //console.log(error.config);

      callback({}, {});
    })
  }
  else{
    //callback(state.rowData, state.totalPage, []);
  }
}

export const getRowsPerPage = (page, state, callback) => {
  if(numberOfAjaxCAllPending == 0){
    numberOfAjaxCAllPending = 1;

    var url = ENDPOINT + "aqenvio/list_rules?page=" + state.activePage + "&search=" + state.search;
    //var url = "http://127.0.0.1:8000/api/aqenvio/list_rules?page=" + state.activePage + "&search=" + state.search;

    if(state.rule_for !== null){
      url += "&rule_for="+state.rule_for.value;
    }    

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());
    
    var myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };

    fetchWithTimeout(20000, fetch(url, myInit))
    .then(response => response.json())
    .then((p) => {
      if(p.success == true){
        var meta = 0;
        if(p.meta){
          meta = p.meta.last_page;
        }
        numberOfAjaxCAllPending = 0;
        // callback(p.data, meta, p.valor);
        callback(p.data, meta);
      }
      else{
        toast.error(p.errors);
      }
    })
    .catch((error) => {
      toast.error('Ocorreu um erro no servidor!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      
      callback({}, {});
    })
  }
  else{
    //callback(state.rowData, state.totalPage, []);
  }
}