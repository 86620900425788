import moment from 'moment';
import { HiViewGrid } from 'react-icons/hi';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';

const payment = (payment_type) => {
  switch(payment_type){
      case 'credit' : return 'Crédito';
      case 'boleto' : return 'Boleto';
      case 'debit' : return 'Débito';
      default: return payment_type
  }
}

const origens = [
  { value: "cobrancas", label: "Link de Pagamento" },
  /* { value: "magento2", label: "Loja Magento" }, */
  { value: "magento2", label: "SHOPAQPago" },
  { value: "woocommerce", label: "WooCommerce" },
  { value: "opencard", label: "OpenCart" },
  { value: "prestashop", label: "PrestaShop" },
  { value: "api", label: "Integração API" },
  { value: "meubus", label: "MeuBus" },
];

const get_origem = (produto_servico) => {
  var produto = '';

  origens.forEach(e => {
    if(produto_servico == e.value){
      produto = e.label;
    }
  });

  if (!produto) produto = 'Integração';

  return produto;
}

const status_validacao_card = (payment_type) => {
  switch(payment_type){
      case null : return 'Cartão pedente de validação';
      case 'pending' : return 'Cartão pedente de validação';
      case 'success' : return 'Validação de cartão aprovada';
      case 'payment_refused' : return 'Pagamento de validação recusada';
      default: return payment_type
  }
}

const list_status_pagamentos = [
  { value: 0, label: "Todos" },
  { value: "CANCELADA", 
    label: <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Pagamento cancelado
    </div>
  },
  { value: "PENDENTE", 
    label: "Pagamento pendente" 
  },
  { value: "APROVADO", 
  label: "Pagamento aprovado" 
  },
  { value: "FALHA NO PAGAMENTO", 
    label: <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Falha no pagamento
    </div>
  },
  { value: "ANALISANDO", 
    label: 
    <div style={{fontSize: '16px',fontWeight: 'bold'}}>
      Analise Pendente
    </div>  
  },
  { value: "EM ANALISE", 
    label: 
    <div style={{fontSize: '16px',fontWeight: 'bold'}}>
      Analise Iniciada
    </div> 
  },
  { value: "APROVADO AUTOMATICA", 
    label:
    <div style={{color: 'rgb(61, 129, 73)',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude aprovado automaticamente
    </div> 
  },
  { 
    value: "APROVADO MANUALMENTE", 
    label:
    <div style={{color: 'rgb(61, 129, 73)',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude aprovado manualmente
    </div> 
  },
  { value: "REPROVADO AUTOMATICAMENTE", 
    label: 
    <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude - Reprovado automaticamente
    </div>
  },
  { 
    value: "FRAUDE COMFIRMADA", 
    label:
    <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude reprovado por confirmada
    </div> 
  },
  { 
    value: "FRAUDE SUSPEITA", 
    label:
    <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude reprovado por suspeita
    </div> 
  },
  { 
    value: 'REPROVADO MANUALMENTE', 
    label:
    <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude Reprovado Manualmente
    </div> 
  },
  { 
    value: "FRAUDE SEM SUSPEITA", 
    label:
    <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude reprovado sem contato
    </div> 
  }    
];

const getStatus = (value, status_current) => {
  if(value === ""){
      var status_is_not_antifraude = '';
      list_status_pagamentos.map((p) => {
          if(p.value === status_current){
              status_is_not_antifraude = p.label
          }
          return null;
      })
      return status_is_not_antifraude;
  }
  else{
      var status_is = '';
      const status = [
          { 
            value: 'INC', 
            label: 
              <div style={{color: 'rgb(195 144 0)',fontSize: '16px',fontWeight: 'bold'}}>
                Pagamento Incompleto
              </div>
          },
          { 
            value: 'NVO', 
            label:
            <div style={{color: 'rgb(55 58 159)',fontSize: '16px',fontWeight: 'bold'}}>
              Análise Pendente
            </div>
          },
          { 
            value: 'AMA', 
            label:
            <div style={{color: 'rgb(55 58 159)',fontSize: '16px',fontWeight: 'bold'}}>
              Análise Iniciada
            </div>
          },
          //{ value: 'APA', label: 'Aprovado Automaticamente'},  
          { 
            value: 'APA', 
            label: 
            <div style={{color: '#3d8149',fontSize: '16px',fontWeight: 'bold'}}>
              Aprovado Automaticamente
            </div>
          },  
          { 
            value: 'APM', 
            label:
            <div style={{color: '#3d8149',fontSize: '16px',fontWeight: 'bold'}}>
              Aprovado Manualmente
            </div>
          },
          { 
            value: 'SUS', 
            label:
            <div style={{color: '#ff5707',fontSize: '16px',fontWeight: 'bold'}}>
              Suspeita de Fraude
            </div>
          },
          { 
            value: 'FRD', 
            label:
            <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
              Fraude Confirmada
            </div>
          },
          { 
            value: 'RMA', 
            label:
            <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
              Reprovado Manualmente
            </div>
          },
          { 
            value: 'RPM', 
            label:
            <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
              Reprovado sem contato
            </div>
          },
          { 
            value: 'RPA', 
            label:
            <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
              Reprovado Automático
            </div>
          },
      ];

      status.forEach(p => {
          if(p.value === value){
              status_is = p.label;
          }
      });

      return status_is;

  }
}

// export const columns = () => [
//   {
//     name: "",
//     selector: "buttons",
//     sortable: false,
//     width: '50px',
//     cell: row => <Row>
//       <Button
//         outline
//         style={{ padding: 2, marginLeft: 15, border: 0, fontWeight: '700'}}
//         color="dark" onClick={() => 
//           {
//               window.location.href = '/prevencao-detalhes/'+row.id
//           }
//         }>
//         <HiViewGrid style={{ padding: '2px' }}/>
//       </Button>
//     </Row>
//   },
//   {
//     name: "Estabelecimento",
//     selector: "estabelecimento",
//     sortable: false,
//     width: '250px'
//   },
//   {
//     name: "Status da Validação de cartão",
//     selector: "validacao_card",
//     sortable: false,
//     width: '170px',
//     cell: p => status_validacao_card(p.validacao_card)
//   },
//   {
//     name: "Origem da Venda",
//     selector: "produto_servico",
//     sortable: false,
//     width: '180px',
//     cell: p => get_origem(p.produto_servico)
//   },
//   {
//     name: "Status",
//     selector: "status",
//     sortable: false,
//     width: '17%',
//     cell: p => <p style={{textAlign: 'center', marginTop: 10}}>{getStatus(p.antifraude_code, p.status)}
//     {p.notificacoes_pendentes === true ? 
//       <>
//         <br/>
//         <b style={{color: 'red'}}>Existem emails de validação não lidos recebidos pelo cliente</b>
//       </> 
//     : null}</p>,
//   },
//   {
//     name: "Status KOIN",
//     selector: "status_service_antrifraud",
//     sortable: false,
//     width: '180px',
//     cell: p => <>
//       {p.status_service_antrifraud !== undefined && p.status_service_antrifraud !== null ? p.status_service_antrifraud.replace('_', ' ') : '-'}
//       </>
//   },
//   {
//     name: "Envio KOIN",
//     selector: "envio_service_antifraud",
//     sortable: false,
//     width: '180px',
//     cell: p =><>
//       {p.envio_service_antifraud !== undefined &&  p.envio_service_antifraud == true ? 'Sim' : 'Não'}
//     </>
//   },
//   {
//     name: "CPF Buyer",
//     selector: "cpf_cnpj",
//     sortable: false,
//     width: '15%'
//   },
//   {
//     name: 'Valor',
//     selector: 'valor',
//     sortable: false,
//     width: '100px',
//     cell: p => "R$ "+parseFloat(p.valor).toFixed(2).replace('.', ',')
//   },
//   {
//     name: "Forma de Pagamento",
//     selector: "forma_pagamento",
//     sortable: false,
//     width: '100px',
//     cell: p => p.pagamento_multiplo === false ? payment(p.forma_pagamento) : 'Múltiplo'
//   },
//   {
//     name: "Data do Pedido",
//     selector: "data_pedido",
//     sortable: false,
//     width: '150px',
//     cell: p => moment(p.data_pedido).format('DD/MM/YYYY HH:mm')
//   },
//   {
//     name: "Analista",
//     selector: "analista",
//     sortable: false,
//     width: '250px',
//     cell: p => <>
//       <p style={{width: '100%'}}><b>{p.analista_inicio_name.length > 0 ? 'Iniciado por: ' : ''}</b> {p.analista_inicio_name.length > 0 ? p.analista_inicio_name : ''}</p>
//       <p style={{width: '100%'}}><b>{p.analista_fim_name.length > 0 ? 'Finalizado por: ' : ''}</b> {p.analista_inicio_name.length > 0 ? p.analista_fim_name : ''}</p>
//     </>
//   },
//   /* {
//     name: "Número do Pedido",
//     selector: "transacao_id",
//     sortable: false,
//     width: '200px',
//   }, */
// ];

export const columns = () => [
    {
      name: "",
      selector: "buttons",
      sortable: false,
      width: '5%',
      cell: row => <Row>
        <Button
          outline
          style={{ padding: 2, marginLeft: 15, border: 0, fontWeight: '700'}}
          color="dark" onClick={() => 
            {
                window.location.href = '/prevencao-detalhes/'+row.id
            }
          }>
          <HiViewGrid style={{ padding: '2px' }}/>
        </Button>
      </Row>
    },
    {
      name: "Estabelecimento (Seller)",
      selector: "estabelecimento",
      sortable: false,
      width: '20%',
      cell: p => 
        <Row>
            <Col md={12} style={{marginBottom: '0.5rem'}}>
                <p style={{marginBottom: '0.2rem'}}>
                  <b>Seller:</b>
                </p>
                <p style={{marginBottom: '1rem'}}>{p.estabelecimento.toUpperCase()}</p> 
            </Col>
            {p.seller_documento !== undefined ? 
              <Col md={12} style={{margin: '0.5rem 0px'}}>
                <p style={{marginBottom: '0.2rem'}}>
                  <b>Documento:</b>
                </p>
                <p style={{marginBottom: '1rem'}}>
                  <NumberFormat displayType={'text'} value={p.seller_documento} format={p.seller_documento.length > 11 ? '##.###.###/####-##' : "###.###.###-##"} />
                </p>  
              </Col>
            : 
              ''
            }
        </Row>
    },
    {
      name: "Dados da Venda",
      selector: "dados_venda",
      sortable: false,
      width: '20%',
      cell: p =>          
          <Row>
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Valor:</b>
              </p>
              <p style={{color: '#3d8149',fontSize: '16px',fontWeight: 'bold', marginBottom: '1rem'}}>
                {parseFloat(p.valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              </p>
            </Col>
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Forma de pagamento:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                {p.pagamento_multiplo === false ? 
                  <div>
                    {payment(p.forma_pagamento)}&nbsp;
                    
                    {p.forma_pagamento == 'credit' ?
                      p.numero_parcelas !== undefined ? 
                        p.numero_parcelas > 1 ?
                          p.numero_parcelas + 'x' 
                        :
                        'À vista'
                      : 
                        ''
                    :
                      ''
                    }
                  </div>
                :
                  'Múltiplo'
                }
              </p>
            </Col>           
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Data do pedido:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                { moment(p.data_pedido).format('DD/MM/YYYY HH:mm')}
              </p>
            </Col>
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>ID da transação:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                {p.transacao_id}
              </p>
            </Col>
            
          </Row>
    },
    {
      name: "Informações",
      selector: "informacoes",
      sortable: false,
      width: '20%',
      cell: p =>          
          <Row>
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Origem da venda:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                {get_origem(p.produto_servico)}
              </p>
            </Col>
            <Col md={12}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Status:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                {getStatus(p.antifraude_code, p.status)}
              </p>
            </Col>
            <Col md={12}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Status validação cartão:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>{status_validacao_card(p.validacao_card)}</p>
            </Col>       
            <Col md={12}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Envio KOIN:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                {p.envio_service_antifraud !== undefined &&  p.envio_service_antifraud == true ? 'Sim' : 'Não'}
              </p>
            </Col>     
            <Col md={12}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Status KOIN:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                {p.status_service_antrifraud !== undefined && p.status_service_antrifraud !== null ? p.status_service_antrifraud.replace('_', ' ') : '-'}
              </p>
            </Col>            


          </Row>
    },
    {
      name: "Comprador (Buyer)",
      selector: "comprador",
      sortable: false,
      width: '20%',
      cell: p =>          
          <Row>
            <Col md={12} style={{marginBottom: '0.5rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Buyer:</b>
              </p>
              <p>{p.cliente_nome.toUpperCase()}</p>
            </Col>
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Documento:</b>
              </p>
              <p>
                <NumberFormat displayType={'text'} value={p.cpf_cnpj} format={p.cpf_cnpj.length > 11 ? '##.###.###/####-##' : "###.###.###-##"} /> 
              </p>
            </Col>
          </Row>
    },
    {
      name: "Analista",
      selector: "analista",
      sortable: false,
      width: '15%',
      cell: p => 
        <Row>
          <Col md={12} style={{marginTop: '0.3rem'}}>
            <p style={{marginBottom: '0.2rem'}}>
              <b>{p.analista_inicio_name.length > 0 ? 'Iniciado por:' : ''}</b>
            </p>
            <p>
              {p.analista_inicio_name.length > 0 ? p.analista_inicio_name : ''}
            </p>
          </Col>
          <Col md={12} style={{marginTop: '0.3rem'}}>
            <p style={{marginBottom: '0.2rem'}}>
              <b>{p.analista_fim_name.length > 0 ? 'Finalizado por: ' : ''}</b>
            </p>
            <p>
              {p.analista_inicio_name.length > 0 ? p.analista_fim_name : ''}
            </p>
          </Col>
        </Row>
    },
];

var numberOfAjaxCAllPending = 0;

export const getRows = (state, callback) => {
    if(numberOfAjaxCAllPending == 0){
      numberOfAjaxCAllPending = 1;
      var url = ENDPOINT + "prevencao/list?page=" + state.activePage + "&search=" + state.search;

      var cpf_cnpj = state.cpf_cnpj.replace(/([^\d])+/gim, '');
      
      if(cpf_cnpj.length > 0){
        if(state.is_seller === true){
          if(state.is_cnpj === false){
            if(cpf_cnpj.length <= 11){
              url += "&cpf_seller="+cpf_cnpj+"&status=";
            }
          }
          else{
              if(cpf_cnpj.length > 11){
                url += "&cnpj_seller="+cpf_cnpj+"&status=";
              }
          }
        }
        else{
          if(state.is_cnpj === false){
            if(cpf_cnpj.length <= 11){
              url += "&cpf_buyer="+cpf_cnpj+"&status=";
            }
          }
          else{
            if(cpf_cnpj.length > 11){
                url += "&cnpj_buyer="+cpf_cnpj+"&status=";
              }
          }
        }
      }

      if(state.numero_pedido.length > 0){
        url += "&numero_pedido="+state.numero_pedido+"&status=";
      }
      
      if(state.filter === true){
          url += "&date_from="+state.date_from+'&date_to='+state.date_to;
      }
      
      if(state.origem_pagamento !== null){
          url += "&origem_pagamento="+state.origem_pagamento.value;
      }
      
      if(state.forma_pagamento !== null){
          url += "&forma_pagamento="+state.forma_pagamento.value;
      }

      if(parseFloat(state.valor_ate) > 0){
          url += "&valor_de="+state.valor_de;
      }
      
      if(parseFloat(state.valor_ate) > 0){
          url += "&valor_ate="+state.valor_ate;
      }

      if(state.admin_inicio !== null){
        url += "&analyst_init_id="+state.admin_inicio.id;
      }

      if(state.admin_fim !== null){
        url += "&analyst_completion_id="+state.admin_fim.id;
      }

      if(cpf_cnpj.length === 0 && state.numero_pedido.length === 0){
        if(state.status !== null){
          if(state.status.value !== 0){
            url += "&status="+state.status.value;
          }
        }
      }
    /*   if(state.status_user.length > 0){
        url += "&status_usuario="+state.status_user
      } */
      
      var myHeaders = new Headers();

      
      
      myHeaders.append('Authorization', 'Bearer ' + getToken());
    
      var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
      };
      fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
          //console.log(p)
          if(p.success == true){
            var meta = 0;
            if(p.meta){
              meta = p.meta.last_page;
            }
            numberOfAjaxCAllPending = 0;
            callback(p.data, meta, p.valor);
          }
          else{
            toast.error(p.errors);
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
    else{
      //callback(state.rowData, state.totalPage, []);
    }
  }
  
  export const getRowsPerPage = (page, state, callback) => {
    if(numberOfAjaxCAllPending == 0){
      numberOfAjaxCAllPending = 1;
      var url = ENDPOINT + "prevencao/list?page=" + page + "&search=" + state.search;
    
      var cpf_cnpj = state.cpf_cnpj.replace(/([^\d])+/gim, '');
      
      if(cpf_cnpj.length > 0){
        if(state.is_seller === true){
          if(state.is_cnpj === false){
            if(cpf_cnpj.length <= 11){
              url += "&cpf_seller="+cpf_cnpj+"&status=";
            }
          }
          else{
              if(cpf_cnpj.length > 11){
                url += "&cnpj_seller="+cpf_cnpj+"&status=";
              }
          }
        }
        else{
          if(state.is_cnpj === false){
            if(cpf_cnpj.length <= 11){
              url += "&cpf_buyer="+cpf_cnpj+"&status=";
            }
          }
          else{
            if(cpf_cnpj.length > 11){
                url += "&cnpj_buyer="+cpf_cnpj+"&status=";
              }
          }
        }
      }

      if(state.numero_pedido.length > 0){
        url += "&numero_pedido="+state.numero_pedido+"&status=";
      }
      
      if(state.filter === true){
          url += "&date_from="+state.date_from+'&date_to='+state.date_to;
      }
      
      if(state.origem_pagamento !== null){
          url += "&origem_pagamento="+state.origem_pagamento.value;
      }
      
      if(state.forma_pagamento !== null){
          url += "&forma_pagamento="+state.forma_pagamento.value;
      }

      if(parseFloat(state.valor_ate) > 0){
          url += "&valor_de="+state.valor_de;
      }
      
      if(parseFloat(state.valor_ate) > 0){
          url += "&valor_ate="+state.valor_ate;
      }

      if(state.admin_inicio !== null){
        url += "&analyst_init_id="+state.admin_inicio.id;
      }

      if(state.admin_fim !== null){
        url += "&analyst_completion_id="+state.admin_fim.id;
      }

      if(cpf_cnpj.length === 0 && state.numero_pedido.length === 0){
        if(state.status !== null){
          if(state.status.value !== 0){
            url += "&status="+state.status.value;
          }
        }
      }

      var myHeaders = new Headers();
    
      myHeaders.append('Authorization', 'Bearer ' + getToken());
    
      var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
      };
      fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
          if(p.success == true){
            var meta = 0;
            if(p.meta){
              meta = p.meta.last_page;
            }
            numberOfAjaxCAllPending = 0;
            callback(p.data, meta, p.valor);
          }
          else{
            toast.error(p.errors);
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
    else{
      //callback(state.rowData, state.totalPage, []);
    }
  }