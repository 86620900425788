import axios from "axios";
import React, { Component } from "react";
import { MdCreditCard } from "react-icons/md";
import { toast } from "react-toastify";
import { Button, Col, Row, TabPane } from "reactstrap";
import { ENDPOINT } from "../../auth/endpoint";
import { getPermissoes, getToken, getUser } from "../../auth/set_services";
import StyleCreditCard from "../CreditCard/StyleCreditCard";
import AddCreditCard from "../Modals/CreditCard/AddCreditCard";
import { ButtonDefault } from '../Button/ButtonDefault';

class CreditCardClient extends Component{
    constructor(props){
        super(props);
        this.state = {
            visible_card: false
        }
    }
    showNewCredit(visible_card) {
        this.setState({ visible_card });
        if(visible_card === false){
            this.props.refresh();
        }
    }

    onToasty(data){
        if(data.success === true){
            toast.success(data.message);
        }
        else{
            toast.error(data.errors);
        }
        this.props.refresh();
    }
    deleteCreditCard(id){
        this.deleteCreditCardRequest(id, this.onToasty.bind(this));
    }

    deleteCreditCardRequest(id, onData) {
        var url = ENDPOINT + "cartao/destroy/"+id;
        axios.delete(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            onData(data);
            return data;
        })
        .catch((error) => {
            if (error.message === 'Network Error') {
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
            var data = error.response.data;
            onData(data);
            return data;
        });
    }
    render(){
        return(
            <TabPane tabId={this.props.active}>
                <br />
                <h5>&nbsp;&nbsp;<MdCreditCard style={{width: '30px', height: '30px'}}/> Cartões de Crédito</h5>
                <Row>
                    {this.props.cartao_vinculado.map((p, i) => {
                        return <div>
                                <StyleCreditCard status={p.status} validade={p.validade_mes+"/"+p.validade_ano} numero_cartao={p.numero} bandeira={p.bandeira.replaceAll(' ', '_').toLowerCase()}/>    
                                <center hidden={!p.status}>
                                    <Button color="danger" onClick={() => this.deleteCreditCard(p.id)}>
                                        Inativar Cartão
                                    </Button>
                                </center>
                            </div>
                    })}
                    {this.props.cartao_vinculado.length === 0 ? <Col style={{marginLeft: '45px'}}> Nenhum cartão encontrado</Col>: ''}
                </Row>
                <Row>
                    <Col style={{marginLeft: '10px'}}>
                        <br/>
                        <ButtonDefault color="primary" hidden={!getUser().permissao_gestor_aqpasso} onClick={() => this.showNewCredit(true)}>Adicionar novo cartão</ButtonDefault>
                    </Col>
                </Row>
                <AddCreditCard
                    user_id={this.props.user_id}
                    visible={this.state.visible_card}
                    onClose={() => this.showNewCredit(false)}
                />
            </TabPane>
        )
    }
}
export default CreditCardClient;