import React, { Component } from "react";
import classnames from 'classnames';
import { FaBox, FaUser } from "react-icons/fa";
import { MdAttachMoney, MdLock, MdMail, MdMoneyOff, MdNote, MdReceipt } from "react-icons/md";
import { Button, Card, CardBody, CardColumns, CardFooter, CardHeader, Col, Collapse, FormGroup, Input, Label, Nav, NavLink, Row, TabContent, TabPane } from "reactstrap";
import styled from "styled-components";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import DataPreClient from "../../components/ResumeClient/DataPreClient";
import DataClientVenda from "../../components/ResumeClient/DataClientVenda";
import sanitizeHtml from 'sanitize-html';
import { InputText } from "../../components/InputText/InputText";
import axios from "axios";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import EnviarEmailVenda from "../../components/Modals/Vendas/EnviarEmailVenda";
import ecommerce_logo from '../../assets/ecommerce-aqpago.svg';
import Select from "react-select";
import { toast } from "react-toastify";
import ClipLoader from 'react-spinners/ClipLoader';
import { AiFillCaretDown, AiFillCaretUp, AiFillFilePdf, AiFillFileUnknown } from "react-icons/ai";
import PropagateLoader from "react-spinners/PropagateLoader";
import ModalShow from "../../components/Modals/ModalShow/ModalShow";
import get_extension from 'get-url-extension';
import error_image from '../../assets/error-image.png'
import { IoMdDocument } from "react-icons/io";
import UploadDocumentos from "../../components/Modals/SendsModals/UploadDocumentos";
import ModalValidacaoToken from "../../components/Modals/ModalValidacaoToken/ModalValidacaoToken";
import ModalDesconto from "../../components/Modals/Desconto/ModalDesconto";

const thumb = {
    //display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 305,
    height: 'auto',
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 200,
    overflow: 'hidden',
    placeContent: 'center'
};

const img = {
    display: 'block',
    width: '100%',
    maxHeight: '330px'
};

const pdf = {
    display: 'block',
    width: 'auto',
    height: '120px',
    color: '#af0000'
};
const file_style = {
    display: 'block',
    width: 'auto',
    height: '120px',
    color: '#484848'
};
const status_transacao_not_svg = (payment_type) => {
    switch (payment_type) {
        case 'succeeded': return 'Aprovada';
        case 'canceled': return 'Cancelada';
        case 'pending': return 'Pendente';
        case 'failed': return 'Falhada';
        case 'disputed': return 'Em disputa';
        default: return payment_type
    }
}

const payment = (payment_type) => {
    switch (payment_type) {
        case 'credit': return 'Crédito';
        case 'boleto': return 'Boleto';
        case 'debit': return 'Débito';
        case 'cupom100': return 'Cupom de 100% de Desconto';
        default: return payment_type
    }
}

const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
`;


const maquininhas_list = [
    {
        value: 'CONTADOR-BASICO',
        label: 'Contador Básico R$79.00',
        texto: 'Contador Básico'
    },
    {
        value: 'CONTADOR-PADRAO',
        label: 'Contador Padrão R$129.00',
        texto: 'Contador Padrão'
    },
    {
        value: 'CONTADOR-EMPREENDEDOR',
        label: 'Contador Empreendedor Plus+ R$293.00',
        texto: 'Contador Empreendedor Plus+'
    },
    {
        value: 'CONTADOR-ESSENCIAL',
        label: 'Contador Essencial R$399.00',
        texto: 'Contador Essencial'
    },
    {
        value: 'CONTADOR-PRO',
        label: 'Contador PRO R$599.00',
        texto: 'Contador PRO'
    }
];
const getPayout = (id, callback) => {
    var url = ENDPOINT + "aqcontabil-admin/info/" + id;

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
        .then(response => response.json())
        .then((data) => {
            callback(data);
        })
}

const getOperadores = (callback) => {
    var url = ENDPOINT + "chat-atendimento/consultar-operadores";

    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        var operadores_list = [];
        data.forEach((p, i) => {
            p['value'] = p.id;
            p['label'] = p.name;
            operadores_list.push(p);
        });

        callback(operadores_list)
    });
}

const updateCancelamento = (id, formData, callback) => {
    var url = ENDPOINT + "aqcontabil-admin/cancelar_plano/" + id;

    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    })
        .then((data) => {
            callback(data.data);
        })
        .catch((error) => {
            if (error.message === 'Network Error') {
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                callback(obj);
            }

            var data = error.response.data;
            if (typeof data.errors !== 'undefined') {
                callback(data);
            }
            else{
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                callback(obj);
            }
        })
}

const updateEnderecoFiscal = (id, formData, callback) => {
    var url = ENDPOINT + "aqcontabil-admin/adicionar_endereco_fical/" + id;

    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    })
        .then((data) => {
            callback(data.data);
        })
        .catch((error) => {
            if (error.message === 'Network Error') {
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                callback(obj);
            }

            var data = error.response.data;
            if (typeof data.errors !== 'undefined') {
                callback(data);
            }
            else{
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                callback(obj);
            }
        })
}
const updateAtivacao = (id, formData, callback) => {
    var url = ENDPOINT + "aqcontabil-admin/ativar_plano/" + id;

    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    })
        .then((data) => {
            callback(data.data);
        })
        .catch((error) => {
            if (error.message === 'Network Error') {
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                callback(obj);
            }

            var data = error.response.data;
            if (typeof data.errors !== 'undefined') {
                callback(data);
            }
            else{
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                callback(obj);
            }
        })
}

const updatePlano = (id, formData, callback) => {
    var url = ENDPOINT + "aqcontabil-admin/update_plan/" + id;

    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        }
    })
        .then((data) => {
            callback(data.data);
        })
        .catch((error) => {
            if (error.message === 'Network Error') {
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                callback(obj);
            }

            var data = error.response.data;
            if (typeof data.errors !== 'undefined') {
                callback(data);
            }
            else{
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                callback(obj);
            }
        })
}

const get_id = () => {
    return window.location.pathname.replace('/aqcontabil/', '')
}

const produto_prazo_list = [
    {
        label: '1 ano de garantia',
        value: 'um_ano'
    },
    {
        label: '2 anos de garantia',
        value: 'dois_anos'
    },
    {
        label: '3 anos de garantia',
        value: 'tres_anos'
    },
];

const getStringMaps = (mail) => {
    var string_mail = "";
    mail.forEach((p) => {
        string_mail += getTranslate(p.recordType) + " " + moment(new Date(p.dateAt).toString()).format('DD/MM/YYYY h:mm:ss a') + "<br /> "
    });

    return string_mail;
}

const getTranslate = (name) => {
    switch (name) {
        case 'Delivery': return 'Entregue'
        case 'Open': return 'Aberto'
        case 'Click': return 'Clicado'
        case 'SpamComplaint': return 'Caixa de SPAM'
        case 'Bounce': return 'Mensagem devolvida'
        default: return name;
    }
}

const produto_prazo_get = (value) => {
    var prazo = '';
    produto_prazo_list.forEach(e => {
        if (e.value == value) {
            prazo = e.label;
        }
    });

    return prazo;
}
const get_status_auth = (payment_type) => {
    switch (payment_type) {
        case 0: return 'Aguardando Validação';
        case 1: return 'Validado';
        case 2: return 'Perdido';
        default: return ''
    }
}

class ResumoAqContabil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "1",
            motivo_estorno: '',
            id_transacao: '',
            num_funcionarios: '',
            plano: '',
            opcao_contratacao: '',
            total_descontos: 0,
            pagamentos: [],
            descontos: [],
            visible_email: false,
            venda_id: get_id(),
            proxima_fatura_valor: 0,
            info_transacao: {},
            user: null,
            produtos: [],
            mails: [],
            socios: [],
            operadores_list: [],
            documentos: [],
            notas_privadas: [],
            documents: [],
            lojas: null,
            forma_pagamento: '',
            ultimo_pagamento: '',
            proximo_pagamento: '',
            endereco_fiscal: '',
            certificado_a1: '',
            descricao: '',
            userCode: '',
            operador: null,
            status_pedido_label: null,
            status: '',
            estornado_por: null,
            maquininha: null,
            open_plano: false,
            prevencao_show: false,
            loading_payout: false,
            loading_note: false,
            loading_desconto: false,
            autorizacoes: [],
            changeVisible: false,
            document_file: '',
            isVisibleDesconto: false,
            modal_show_document: false,
            modal_upload: false
        }
    }

    componentDidMount() {
        getPayout(this.state.venda_id, this.callbackRows.bind(this));
        getOperadores(this.callbackOperadores.bind(this));
    }

    callbackOperadores = (operadores_list) => {
        this.setState({ operadores_list });
    }


    changeVisibleDesconto = (isVisibleDesconto) => {
        this.setState({ isVisibleDesconto });
        getPayout(this.state.venda_id, this.callbackRows.bind(this));
    }

    changeVisible = (isVisible) => {
        this.setState({ isVisible });
        getPayout(this.state.venda_id, this.callbackRows.bind(this));
    }


    callbackRows(data) {
        var data_is = data.data;
        data_is.endereco_fiscal = data_is.endereco_fiscal == null ? 'Não adquirido' : data_is.endereco_fiscal;
        data_is.certificado_a1 = data_is.certificado_a1 == null ? 'Não adquirido' : data_is.certificado_a1;
        this.setState({ ...data_is });

        /* if(data.data.analista !== null){
            var operador = {
                value: data.data.analista.id,
                label: data.data.analista.name
            };

            this.setState({ operador })

        }
        if(data.data.status_pedido !== null){
            var status = null;
            pedido_list.forEach(e => {
                if(e.value == data.data.status_pedido){
                    status = e;
                }
            });

            this.setState({ status_pedido_label: status })
            
        } */
    }

    onCloseEmail = (visible_email = false) => {
        this.setState({ visible_email });

        if (visible_email == false) {
            getPayout(this.state.venda_id, this.callbackRows.bind(this));
        }
    }
    meses = [
        "",
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
    ];

    listData = [
        { name: <><MdAttachMoney />Informações de Pagamento</>, active: '1' },
        { name: <><FaUser />Usuário </>, active: '2' },
        { name: <><MdMail />E-mails </>, active: '3' },
        { name: <><MdNote />Notas </>, active: '4' },
        { name: <><IoMdDocument />Documentos </>, active: '5' },
        { name: <><MdLock/>Validações de Cliente </>, active: '6' },
        { name: <><MdReceipt />Pagamentos </>, active: '7' },
        { name: <><MdReceipt />Descontos </>, active: '8' }
    ];

    reenviar_link_eccommerce = () => {
        var url = ENDPOINT + "loja/reenviar_link_loja/" + this.state.venda_id;

        var formData = new FormData();
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                toast.success(data.message);
                return data;
            })
            .catch((error) => {
                var data = error.response.data;
                toast.error(data.errors);
            });

    }

    reenviar_boleto = () => {
        toast.info('Enviando, por favor aguarde');

        var url = ENDPOINT + "aqcontabil-admin/reenviar_boleto/" + this.state.venda_id;

        var formData = new FormData();
        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                toast.success(data.message);
                return data;
            })
            .catch((error) => {
                var data = error.response.data;
                toast.error(data.errors);
            });

    }
    createNote = () => {
        this.setState({ loading_note: true })
        var url = ENDPOINT + "notas/store";

        var formData = new FormData();
        formData.append('id', this.state.venda_id);
        formData.append('desc_id', 'id_contabilidade');
        formData.append('descricao', this.state.descricao);
        /* formData.append('title_document', this.state.documento.value);
        formData.append('categoria_document', this.state.documento.categoria);
        formData.append('file_document', this.state.file_document); */

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                this.setState({ loading_note: false })
                var data = response.data;
                toast.success(data.message);
                getPayout(this.state.venda_id, this.callbackRows.bind(this));
                return data;
            })
            .catch((error) => {
                this.setState({ loading_note: false })
                var data = error.response.data;
                toast.error(data.errors);
                return data;
            });

    }
    bloquearPagamento = () => {
        this.setState({ loading_payout: true })
        var url = ENDPOINT + "vendas/estornar_pagamento/" + this.state.venda_id;

        var formData = new FormData();
        formData.append('motivo_estorno', this.state.motivo_estorno);
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                this.setState({
                    loading_payout: false,
                    prevencao_show: false
                });
                getPayout(this.state.venda_id, this.callbackRows.bind(this));
                return data;
            })
            .catch((error) => {
                var data = error.response.data;
                this.setState({
                    loading_payout: false
                });
                toast.error(data.errors);
            });

    }
    removerDesconto = (id) => {
        this.setState({
            loading_desconto: true
        })
        var url = ENDPOINT + "aqcontabil-admin/remover_desconto/" + id;

        var formData = new FormData();
        formData.append('id', id);
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                this.setState({
                    loading_desconto: false
                })
                toast.success(data.message);
                
                getPayout(this.state.venda_id, this.callbackRows.bind(this));
                return data;
            })
            .catch((error) => {
                var data = error.response.data;
                this.setState({
                    loading_desconto: false
                })
                toast.error(data.errors);
            });
    }
    reenviarBoleto = (id_transacao) => {
        toast.dark('O boleto está sendo reenviado, por favor aguarde')
        var url = ENDPOINT + "aqcontabil-admin/resend/" + id_transacao;

        var formData = new FormData();
        formData.append('motivo_estorno', this.state.motivo_estorno);
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                //getPayout(this.state.venda_id, this.callbackRows.bind(this));
                toast.success(data.message);
                return data;
            })
            .catch((error) => {
                var data = error.response.data;
                /* this.setState({
                    loading_payout: false
                }); */
                toast.error(data.errors);
            });
    }
    gerarNovoBoleto = (id_transacao) => {
        toast.dark('O boleto está sendo reenviado, por favor aguarde')
        var url = ENDPOINT + "aqcontabil-admin/generate/" + id_transacao;

        var formData = new FormData();
        formData.append('motivo_estorno', this.state.motivo_estorno);
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                getPayout(this.state.venda_id, this.callbackRows.bind(this));
                toast.success(data.message);
                return data;
            })
            .catch((error) => {
                var data = error.response.data;
                /* this.setState({
                    loading_payout: false
                }); */
                toast.error(data.errors);
            });
    }

    alterarPlano = () => {
        toast.info('Por favor aguarde');
        var formData = new FormData();
        formData.append('plano', this.state.maquininha.value)

        const callbackIs = (data) => {
            if(data.success == true){
                toast.success(data.message);
                this.setState({
                    maquininha: null,
                    open_plano: false
                })
                getPayout(this.state.venda_id, this.callbackRows.bind(this));
            }
            else{
                toast.error(data.errors)
            }
        }

        updatePlano(this.state.venda_id, formData, callbackIs);
    }

    adicionarEnderecoFiscal = () => {
        toast.info('Por favor aguarde');
        var formData = new FormData();

        const callbackIs = (data) => {
            if(data.success == true){
                toast.success(data.message);
                this.setState({
                    maquininha: null,
                    open_plano: false
                })
                getPayout(this.state.venda_id, this.callbackRows.bind(this));
            }
            else{
                toast.error(data.errors)
            }
        }

        updateEnderecoFiscal(this.state.venda_id, formData, callbackIs);
    }

    cancelarPlano = () => {
        toast.info('Por favor aguarde');
        var formData = new FormData();

        const callbackIs = (data) => {
            if(data.success == true){
                toast.success(data.message);
                this.setState({
                    maquininha: null,
                    open_plano: false
                })
                getPayout(this.state.venda_id, this.callbackRows.bind(this));
            }
            else{
                toast.error(data.errors)
            }
        }

        updateCancelamento(this.state.venda_id, formData, callbackIs);
    }

    ativarPlano = () => {
        toast.info('Por favor aguarde');
        var formData = new FormData();

        const callbackIs = (data) => {
            if(data.success == true){
                toast.success(data.message);
                this.setState({
                    maquininha: null,
                    open_plano: false
                })
                getPayout(this.state.venda_id, this.callbackRows.bind(this));
            }
            else{
                toast.error(data.errors)
            }
        }

        updateAtivacao(this.state.venda_id, formData, callbackIs);
    }
    

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    alterStatus = (status) => {
        this.setState({ status_pedido_label: status });

        var url = ENDPOINT + "vendas/update_status/" + this.state.venda_id;

        var formData = new FormData();
        formData.append('status_pedido', status.value);
        axios.post(url, formData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                toast.success(data.message);
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    toast.error(obj.errors);
                }

                var data = error.response.data;
                if (typeof data.errors !== 'undefined') {
                    toast.error(data.errors);
                }
            });

    }

    alterOperador = (operador) => {
        var url = ENDPOINT + "vendas/update_operador/" + operador.value;

        var formData = new FormData();
        formData.append('venda_id', this.state.venda_id);
        axios.post(url, formData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                toast.success(data.message);
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    toast.error(obj.errors);
                }

                var data = error.response.data;
                if (typeof data.errors !== 'undefined') {
                    toast.error(data.errors);
                }
            });

    }

    handleChangeEmail = (mensagem_email) => {
        this.setState({ mensagem_email });
    }

    handleSelectChange = (name, value) => {
        var state = this.state;
        state[name] = value;
        this.setState({ ...state });

        this.alterOperador(value);
    }

    getFileExtension = (url) => {
        var extension = get_extension(url);

        if (extension === '.png' ||
            extension === '.PNG' ||
            extension === '.jpg' ||
            extension === '.jpeg' ||
            extension === '.svg' ||
            extension === '.webp') {
            return 'image'
        }
        else if (extension === '.pdf' ||
            extension === '.PDF') {
            return 'pdf';
        }

        return 'file';
    }

    onCloseShowDocument = (modal_show_document, document_file) => {
        this.setState({ modal_show_document, document_file })
    }
    thumbs = () => {
        return this.state.documents.map((file, i) => (
            <>
                <div style={thumb} key={i}>
                    <div style={thumbInner}>
                        {this.getFileExtension(file.url) === 'image' ?
                            <>
                                <a href="#" onClick={() => this.onCloseShowDocument(true, file.url)}>
                                    <img
                                        alt="logotipo-documento"
                                        src={file.url + '?access_token=' + getToken()}
                                        style={img}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = error_image;
                                        }}
                                    />
                                </a>
                            </>
                            :
                            this.getFileExtension(file.url) === 'pdf' ?
                                <a href="#" onClick={() => this.onCloseShowDocument(true, file.url)}>
                                    <AiFillFilePdf style={pdf} />
                                </a>
                                :
                                <a href={file.url + '?access_token=' + getToken()}>
                                    <AiFillFileUnknown style={file_style} />
                                </a>
                        }
                        <br />
                    </div>
                    <center>
                        <small><b>{file.descricao}</b></small>
                        {/* {file.status_analise_docs === 'pending' ?
                    <>
                        <Row>
                            <Col md={12} style={{textAlign: 'center'}}>
                                <Button color="success" style={{padding: 5, margin: 8}} onClick={() => this.onDesbloqueio(file.id)}>
                                    Aprovar
                                </Button>
                                <Button color="danger" style={{padding: 5, margin: 8}} onClick={() => this.onBloqueio(file.id)}>
                                    Reprovar
                                </Button>
                                <Button color="danger" style={{padding: 5, margin: 8}} onClick={() => this.onDelete(file.id)}>
                                    Apagar
                                </Button>
                            </Col>
                            <Col md={12} style={{textAlign: 'center'}}>
                                <Button color="info" style={{padding: 5, margin: 8}} onClick={() => this.onCloseDocument(file.id, true)}>
                                    Adicionar nota
                                </Button>
                            </Col>
                        </Row>
                    </>
                    :
                    file.status_analise_docs === 'aprovado' ?
                    <>
                        <p style={{color: 'green', margin: 0}}><b>Documento Aprovado</b></p>
                        <small>Analisado por: {file.responsavel_analise !== null ? file.responsavel_analise.name : 'Sem informações'}</small><br/><br/>
                        <Button color="danger" style={{padding: 3, margin: 3}} onClick={() => this.onDelete(file.id)}>
                            Apagar
                        </Button>
                        <Button color="info" style={{padding: 3, margin: 3}} onClick={() => this.onCloseDocument(file.id, true)}>
                            Adicionar nota
                        </Button>
                    </>
                    :
                    <>
                        <p style={{color: 'red', margin: 0}}><b>Documento Reprovado</b></p>
                        <small>Analisado por: {file.responsavel_analise !== null ? file.responsavel_analise.name : 'Sem informações'}</small><br/><br/>
                        <Button color="danger" style={{padding: 3, margin: 3}} onClick={() => this.onDelete(file.id)}>
                            Apagar
                        </Button>
                        <Button color="info" style={{padding: 3, margin: 3}} onClick={() => this.onCloseDocument(file.id, true)}>
                            Adicionar nota
                        </Button>
                    </>
                    }
                    <br/>
                    <br/>
                    <p style={{margin: 0}}>Notas:</p>
                    <small>
                        {file.notas.map(e => {
                            return <><b>{e.admin !== null ? e.admin.name : 'Sem Informações'}:</b> {e.descricao}<br/></>
                        })}
                        {file.notas.length === 0 ? <><b>Nenhuma nota</b></> : ''}
                    </small> */}
                    </center>
                    <br />
                </div>
            </>
        ))
    };

    onCloseUpload = (modal_upload = false) => {
        this.setState({
            modal_upload
        });

        if (modal_upload === false) {
            getPayout(this.state.venda_id, this.callbackRows.bind(this));
        }
    }
    render() {
        const Navs = () => {
            return <Nav tabs>
                {this.listData.map((p, index) => {
                    return <NavLink
                        key={index}
                        className={
                            classnames({ active: this.state.activeTab === p.active })}
                        onClick={() => { this.setState({ activeTab: p.active }); }}>
                        <span>{p.name}</span>
                    </NavLink>
                })}
            </Nav>
        }
        return (<Div>
            <h3>Resumo AQContabil</h3>
            <Navs />
            <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId={'1'}>
                    <div style={{ padding: '15px', border: '1px solid #dee2e6', borderRadius: 5 }}>
                        <h5>Dados do Pagamento</h5>
                        <hr />
                        <p><b>Opção contratação:</b> {this.state.opcao_contratacao}</p>
                        <p><b>Status do plano:</b> {this.state.status_assinatura}</p>
                        <p><b>Proxima Fatura:</b> R$ {parseFloat(this.state.proxima_fatura_valor).toFixed(2).replace('.', ',')}</p>
                        <p><b>Total de descontos para a proxima fatura:</b> R$ {parseFloat(this.state.total_descontos).toFixed(2).replace('.', ',')}</p>
                        <p><b>Status do ultimo pagamento:</b> {status_transacao_not_svg(this.state.info_transacao.status)}</p>
                        <p><b>ID da Transação:</b> {this.state.id_transacao}</p>
                        <p><b>Valor da venda:</b> R$ {parseFloat(this.state.info_transacao.amount).toFixed(2).replace('.', ',')}</p>
                        <p><b>Valor Liquido:</b> R$ {(parseFloat(this.state.info_transacao.original_amount) - parseFloat(this.state.info_transacao.fees)).toFixed(2).replace('.', ',')}</p>
                        <p><b>Forma de Pagamento escolhida:</b> {payment(this.state.info_transacao.payment_type)}</p>
                        <p><b>Plano contratado:</b> {this.state.plano}</p>
                        <p><b>Funcionarios Inclusos:</b> {this.state.num_funcionarios}</p>
                        <p><b>Último Pagamento:</b> {moment(this.state.ultimo_pagamento).format('DD/MM/YYYY HH:mm')}</p>
                        <p><b>Fechamento da proxima fatura:</b> {moment(this.state.proximo_pagamento).format('DD/MM/YYYY')}</p>
                        <p><b>Certificado A1:</b> {this.state.certificado_a1}<br/>
                        {this.state.certificado_a1 == 'Certificado A1 adquirido' ?
                            <div style={{marginLeft: 7}}>
                                <small>
                                    <b>Pago em: </b> {this.state.ultima_a1_paga}<br/>
                                    <b>Validade do certificado: </b> {moment(this.state.validade_a1).format('DD/MM/YYYY')}<br/>
                                    <b>ID da transação (ZOOP): </b> {this.state.id_transacao_a1}
                                </small>
                            </div>
                        :<></>}</p>
                        <p><b>Endereço Fiscal:</b> {this.state.endereco_fiscal}</p>
                            
                        <p><b>Pagamento {this.state.status}</b></p>

                        <Button 
                            color={this.state.open_plano == false ? "success" : 'danger'}
                            disabled={this.state.status_assinatura == 'CANCELADO'}
                            onClick={() => this.setState({ open_plano: !this.state.open_plano })}
                        >
                            
                            {this.state.open_plano == false ? "Alterar Plano do cliente" : 'Cancelar'}
                            
                        </Button>
                        <br/>
                        {this.state.endereco_fiscal == 'Não adquirido' ?
                            <>
                                <Button 
                                    color="info"
                                    style={{ marginTop: 3}}
                                    onClick={() => this.adicionarEnderecoFiscal()}
                                >
                                    Adicionar Endereço Fiscal
                                </Button>
                                <br/>
                            </>
                        : <></>}
                        
                        {this.state.status_assinatura == 'ATIVO' ?
                            <Button 
                                color="danger"
                                style={{ marginTop: 3}}
                                onClick={() => this.cancelarPlano()}
                            >
                                Cancelar Assinatura
                            </Button>
                        : <></>}
                        
                        {this.state.status_assinatura == 'CANCELADO' ?
                            <Button 
                                color="success"
                                style={{ marginTop: 3}}
                                onClick={() => this.ativarPlano()}
                            >
                                Ativar Assinatura
                            </Button>
                        : <></>}



                        <Collapse isOpen={this.state.open_plano}>
                            <br/>
                            <Select
                                options={maquininhas_list}
                                placeholder="Plano"
                                onChange={(maquininha) => this.setState({ maquininha })}
                                value={this.state.maquininha}
                            />
                            <hr/>
                            <Button 
                                color="success"
                                onClick={() => this.alterarPlano()}
                                disabled={this.state.maquininha == null || this.state.maquininha.texto == this.state.plano}
                            >
                                Alterar Plano
                            </Button>
                            <br/>
                            <br/>
                            <br/>
                        </Collapse>
                    </div>
                </TabPane>
                <TabPane tabId={'2'}>
                    {this.state.user !== null ?
                        <div style={{ padding: '15px', border: '1px solid #dee2e6', borderRadius: 5 }}>
                            <h5>Dados Pessoais</h5>
                            <hr />
                            <p><b>Nome do Responsavel:</b> {this.state.user.nome + ' ' + this.state.user.sobrenome}</p>
                            {this.state.user.status ?
                                <p><b>Status:</b> {this.state.user.status}</p>
                                : <></>}

                            <p><b>E-mail:</b> {this.state.user.email} </p>
                            <p><b>Telefone:</b> {this.state.user.telefone} </p>
                            {this.state.user.endereco !== null && typeof this.state.user.endereco !== 'undefined' ?
                                <p><b>Endereço: </b>{this.state.user.endereco.logradouro} {this.state.user.endereco.numero}, {this.state.user.endereco.complemento} - {this.state.user.endereco.bairro}, {this.state.user.endereco.cidade}/{this.state.user.endereco.uf}</p>
                                :
                                null
                            }
                            <p><b>Documento (CPF/CNPJ):</b> {this.state.user.cnpj.length === 0 ? this.state.user.cpf : this.state.user.cnpj}</p>
                            <p><b>Criado em:</b> {moment(new Date(this.state.user.created_at)).format('DD/MM/YYYY HH:mm')}</p>
                            {this.state.user.empresa !== null ?
                                <>
                                    <p><b>E-mail da empresa:</b> {this.state.user.empresa.email}</p>
                                    <p><b>Nome fantansia:</b> {this.state.user.empresa.nome_fantasia}</p>
                                    <p><b>Nome da empresa:</b> {this.state.user.empresa.razao_social}</p>
                                    <p><b>Descrição da empresa:</b> {this.state.user.empresa.tipo_negocio}</p>
                                    <p><b>Natureza Juridica:</b> {this.state.user.empresa.natureza_juridica}</p>
                                    <p><b>Patrimonio:</b> {this.state.user.empresa.valor_patrimonio}</p>
                                </>
                                : <></>}
                            <p><b>Capital Social Pretendido(se não houver CNPJ):</b> {this.state.user.capital_social ? parseFloat(this.state.user.capital_social).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : ''}</p>
                            <p><b>Nome Fantasia Pretendido(se não houver CNPJ):</b> {this.state.user.nome_fantasia_pretendido}</p>
                            <p><b>Porte empresa Pretendido(se não houver CNPJ):</b> {this.state.user.porte_empresa}</p>
                            <p><b>Inscrição IPTU:</b> {this.state.user.inscricao_iptu}</p>
                            {this.state.socios.map((e, index) => {
                                return <>
                                    <p><b>Sócio {index + 1}: {e.cpf}</b><br />
                                        <b>Endereço: </b>{e.logradouro} {e.numero}, {e.complemento} - {e.bairro}, {e.cidade}/{e.uf}</p>
                                </>
                            })}
                            {/*  */}
                        </div>
                        :
                        null
                    }
                </TabPane>
                <TabPane tabId={'3'}>
                    <div style={{ padding: '15px', border: '1px solid #dee2e6', borderRadius: 5 }}>
                        {/* <EnviarEmailVenda
                            id={this.state.venda_id}
                            visible={this.state.visible_email}
                            onClose={this.onCloseEmail}
                        /> */}
                        <h5>E-mails</h5>
                        <hr />
                        {/* <Button color="success" onClick={() => this.onCloseEmail(true)}>Enviar E-mail</Button>
                        <br/><br/> */}
                        <ul style={{ listStyleType: 'circle' }}>
                            {this.state.mails.map(p => {
                                return <li style={{ marginBottom: '7px' }}>
                                    {p.subject}<br />
                                    <small>Conteúdo de E-mail:</small>
                                    <div style={{ border: '1px solid #7f7f7f66', borderRadius: '15px', padding: '19px' }} dangerouslySetInnerHTML={{ __html: sanitizeHtml(p.message) }} />
                                    <small>{p.responsavel !== null ? <><b>Operador:</b> {p.responsavel.name}</> : ''}</small>
                                    <small>{p.mail.length > 0 ?
                                        <div data-tip={getStringMaps(p.mail)} style={{
                                            maxHeight: '200px',
                                            cursor: 'pointer', overflow: 'auto', width: '100%', margin: '12px'
                                        }}>
                                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                                {p.mail.slice(0, 1).map((p) => {
                                                    return <li>
                                                        <p><MdMail /> <b>{getTranslate(p.recordType)}</b>
                                                            <br />
                                                            {moment(new Date(p.dateAt).toString()).format('DD/MM/YYYY h:mm:ss a')}</p>
                                                    </li>
                                                })}
                                            </ul>
                                            <ReactTooltip multiline={true} />
                                        </div>
                                        :
                                        <div data-tip={"Postado " + moment(new Date(p.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')} style={{ cursor: 'pointer', maxHeight: '200px', overflow: 'auto', width: '100%', margin: '12px' }}>
                                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                                <li style={{ listStyleType: 'none' }}><MdMail /> <b>Postado</b><br />{moment(new Date(p.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')}
                                                </li>
                                            </ul>
                                            <ReactTooltip multiline={true} />
                                        </div>
                                    }</small>
                                </li>
                            })}
                        </ul>
                        {this.state.mails.length === 0 ? 'Nenhum email encontrado' : ''}
                    </div>
                </TabPane>
                <TabPane tabId={'4'}>
                    <div style={{ padding: '15px', border: '1px solid #dee2e6', borderRadius: 5 }}>
                        <h5>Notas</h5>
                        <hr />
                        <Row style={{ maxHeight: '60vh', overflow: 'auto' }}>
                            <div style={{ width: '95%' }}>
                                <Card style={{ width: '100%', border: 'none' }}>
                                    <CardBody >
                                        <div style={{ maxHeight: '150px', overflow: 'auto' }}>
                                            <Input
                                                type="textarea"
                                                name="descricao"
                                                value={this.state.descricao}
                                                placeholder="Insira a descrição da nota"
                                                onChange={this.handleInputChange} />
                                        </div>
                                        <hr />
                                        <Button color="success" onClick={() => this.createNote()}>Criar</Button>
                                    </CardBody>
                                </Card>
                                <div hidden={!this.state.loading_note}>
                                    <br />
                                    <center>
                                        <small><PropagateLoader color={'#52c565'} loading={50} size={15} /><br />
                                            <b>Enviando nota, aguarde</b></small>
                                    </center>
                                </div>
                                {
                                    this.state.notas_privadas.map(p => {
                                        return (
                                            <>
                                                <br />
                                                <Card style={{ width: '100%', border: 'none' }}>
                                                    <CardBody >
                                                        <h6>
                                                            <MdNote style={{ width: '25px', height: '25px' }} />
                                                            Nota de {p.user_id === null ? <>Atendente<br />Nome: {p.admin !== null ? p.admin.name : 'Sem Informações'}</> : <> Cliente<br /> Nome: {p.usuario.nome + " " + p.usuario.sobrenome} </>} <br />
                                                            <small>Data de Criação: {moment(new Date(p.created_at)).format('DD/MM/YYYY HH:mm')}</small>
                                                        </h6>
                                                        <hr />
                                                        <div style={{ maxHeight: '150px', overflow: 'auto' }}>
                                                            {p.descricao}
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                                <br />
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </Row>
                    </div>
                </TabPane>
                <TabPane tabId={'5'}>
                    <div style={{ padding: '15px', border: '1px solid #dee2e6', borderRadius: 5 }}>
                        <h5>Documentos</h5>
                        <hr />
                        <Button
                            onClick={() => this.onCloseUpload(true)}
                            color="info"
                            style={{ padding: 5, marginBottom: 15 }}>Upload de Documentos</Button>
                        <Row>
                            {this.thumbs()}
                        </Row>
                        <ModalShow
                            visible={this.state.modal_show_document}
                            url={this.state.document_file}
                            onClose={this.onCloseShowDocument}
                        />
                        <UploadDocumentos
                            visible={this.state.modal_upload}
                            userId={null}
                            aqcontabil_id={this.state.venda_id}
                            onClose={this.onCloseUpload}
                        />
                    </div>
                </TabPane>
                <TabPane
                    tabId={'6'}>
                    <div style={{ padding: '15px', border: '1px solid #dee2e6', borderRadius: 5 }}>
                        <h5>Validações de Cliente</h5>
                        <hr />
                        <Button color='success' onClick={() => this.changeVisible(true)}>Validar Cliente</Button>
                        <hr />
                        {this.state.autorizacoes ?
                            <p>{this.state.autorizacoes.map(e => (
                                <p>Token gerado em {moment(e.created_at).format('DD/MM/YYYY HH:mm')} com o status de {get_status_auth(e.status)}
                                    <br /><small>Gerado por: <b>{e.operador_name}</b></small></p>
                            ))}</p>
                            :
                            null
                        }
                    </div>
                </TabPane>
                <TabPane
                    tabId={'7'}>
                    <div style={{ padding: '15px', border: '1px solid #dee2e6', borderRadius: 5 }}>
                        <h5>Pagamentos</h5>
                        <hr />
                        {this.state.pagamentos.map((e, i) => {
                            return (
                                <div>
                                    <Card>
                                        <CardBody>
                                            <b>ID: #{e.id}</b><br />
                                            <b>Valor Pago: </b>R$ {parseFloat(e.valor).toFixed(2)}<br />
                                            <b>Plano Pago:</b> {e.plano_name} <br />
                                            <b>ID da Transação:</b> {e.id_transacao} <br />
                                            <b>Forma de Pagamento:</b> {payment(e.forma_pagamento)} <br />
                                            <b>Status de Pagamento:</b> {e.status_pagamento} <br />
                                            <b>Boleto criado em:</b> {moment(e.created_at).format('DD/MM/YYYY HH:mm')}<br />
                                            <b>Referente ao mês de:</b> {e.mes < 10 ? '0' : ''}{e.mes}/{e.ano} <br />
                                            <hr hidden={e.status_pagamento !== 'PENDENTE'} /><br />
                                            <Button
                                                color="dark"
                                                style={{
                                                    margin: 3
                                                }}
                                                onClick={() => {
                                                    this.reenviarBoleto(e.id_transacao)
                                                }}
                                                hidden={e.status_pagamento !== 'PENDENTE'}
                                            >Re-enviar Boleto</Button>
                                            <Button
                                                color="dark"
                                                style={{
                                                    margin: 3
                                                }}
                                                onClick={() => {
                                                    this.gerarNovoBoleto(e.id_transacao)
                                                }}
                                                hidden={e.status_pagamento !== 'PENDENTE'}
                                            >Gerar novo Boleto</Button>
                                        </CardBody>
                                    </Card>
                                    <br />
                                </div>
                            )
                        })}
                    </div>
                </TabPane>
                <TabPane
                    tabId={'8'}>
                    <div style={{ padding: '15px', border: '1px solid #dee2e6', borderRadius: 5 }}>
                        <h5>Descontos ou Taxas</h5>
                        <hr />
                        <Button color='success' onClick={() => this.changeVisibleDesconto(true)}>Aplicar desconto/taxa</Button>
                        <hr />
                        {this.state.descontos.length == 0 ?
                            <p>Nenhum desconto ou taxa aplicado</p>
                        : null}
                        {this.state.descontos.map((e, i) => {
                            return (
                                <div>
                                    <Card>
                                        <CardHeader>
                                            <b>{e.valor_desconto < 0 ? 'Taxa' : 'Desconto'} aplicad{e.valor_desconto < 0? 'a' : 'o'} para {this.meses[e.mes]} de {e.ano}</b>
                                        </CardHeader>
                                        <CardBody>
                                            <b>Valor: </b>R$ {parseFloat(e.valor_desconto).toFixed(2)}<br />
                                            <b>Status:</b> {e.status}<br />
                                            <b>{e.valor_desconto < 0 ? 'Taxa' : 'Desconto'} criado em:</b> {moment(e.created_at).format('DD/MM/YYYY HH:mm')}
                                        </CardBody>
                                        <CardFooter>
                                            <Button 
                                                color="danger" 
                                                disabled={this.state.loading_desconto} 
                                                onClick={() => this.removerDesconto(e.id)}>
                                                    {this.state.loading_desconto == true ?
                                                        <ClipLoader color={'#000'} loading={50} size={15} />
                                                    :<></>} Remover {e.valor_desconto < 0 ? 'Taxa' : 'Desconto'}
                                            </Button>
                                        </CardFooter>
                                    </Card>
                                    <br />
                                </div>
                            )
                        })}
                    </div>
                </TabPane>
            </TabContent>
            <ModalValidacaoToken
                user={{
                    id: (this.state.user ? this.state.user.id : 0)
                }}
                tipo_cliente="aqcontabil"
                visible={this.state.isVisible}
                onClose={() => this.changeVisible(false)}
            />
            <ModalDesconto
                contabilidade_id={this.state.venda_id}
                visible={this.state.isVisibleDesconto}
                onClose={() => this.changeVisibleDesconto(false)}
            />
        </Div>)
    }
}

export default ResumoAqContabil;