import React, { Component } from "react";
import { Button, Card, CardBody } from "reactstrap";
import ModalTitularidade from "../../components/Modals/Titularidade/ModalTitularidade";
import { getRows, getRowsPerPage } from '../../components/Tables/TableMudanca/propsMudanca';
import moment from "moment";
import PropagateLoader from "react-spinners/PropagateLoader";
import { TableMudanca } from "../../components/Tables/TableMudanca/TableMudanca";

class MudancaEndereco extends Component{
    constructor(props){
        super(props);
        this.state = {
            visible_modal: false,
            activePage: 1,
            loading_table: true,
            cpf_current: null,
            cnpj_current: '',
            totalPage: 1,
            document: '',
            is_cnpj: false,
            filter: false,
            search: '',
            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
    }
    onClose = (visible_modal) => {
        this.setState({ visible_modal })
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.rowData = [];
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentDidMount() {
        getRows(this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    onFilter = (filter) => {
        this.setState({ filter });
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    render(){
        return(
            <div>
                <h3>Solicitações de mudanças de endereço</h3>
                <hr/>
                {/* <Button color="success" onClick={() => this.onClose(true)}>Transferir Titularidade</Button>
                <ModalTitularidade 
                    visible={this.state.visible_modal}
                    onClosed={this.onClose}
                />
                <hr/> */}
                <TableMudanca
                    isSearch={this.handleChange}
                    onSearchDocument={this.onSearchDocument.bind(this)}
                    data={this.state.rowData}
                    is_cnpj={this.state.is_cnpj}
                    onFilter={this.onFilter.bind(this)}
                    onPageChange={this.onPageChange.bind(this)}
                    filter={this.state.filter}
                    noDataComponent={this.state.loading_table === true ?
                        <Card style={{ border: 0, padding: '10vh' }}><CardBody>
                            <div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                        <Card style={{ border: 0 }}><CardBody><div>
                            Nenhuma tarefa encontrado
                        </div></CardBody></Card>
                    }
                    activePage={this.state.activePage}
                    totalPage={this.state.totalPage}
                    date_from={this.state.date_from}
                    date_to={this.state.date_to}
                />
            </div>
        )
    }
}

export default MudancaEndereco;