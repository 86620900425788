import axios from "axios";
import moment from "moment";
import { Component } from "react"
import { FaTasks } from "react-icons/fa"
import Select from "react-select";
import { toast } from "react-toastify";
import { Form, FormGroup, Label, Modal, ModalBody, Input, ModalFooter, Button, FormFeedback } from "reactstrap"
import { ENDPOINT } from "../../../auth/endpoint";
import { list_tarefas, list_tarefas_icons, list_tempos } from "../../../auth/listas";
import { getToken } from "../../../auth/set_services"
import ModelReturn from "../../../components/Modals/ReturnsApiModals/ModelReturn";

export class Ligacoes extends Component {
    constructor(props){
        super(props);
        this.state = {
            visible_create: false,

            //ModalCreate
            assunto: '',
            assunto_invalid: false,
            direcao: '',
            direcao_invalid: false,
            status: '',
            status_invalid: false,
            descricao: '',
            descricao_invalid: false,
            tarefa: null,
            tarefa_invalid: false,
            data: '',
            data_invalid: false,
            tempo: null,
            tempo_invalid: false,
            lembrar_sms: false,
            lembrar_telefone: false,
            error_message_modal: '',
            success_message_modal: '',
            loading_modal: false,
        }
    }
    handleInputChange = (e) => {
      const { name, value } = e.target;
      
      this.setState({ [name]: value });
    }

    handleStatus = (name, value) => {
        this.setState({ [name]: value });
    }
    
    onClose() {
        this.setState({
            visible_create: false,

            //ModalCreate
            assunto: '',
            assunto_invalid: false,
            direcao: '',
            direcao_invalid: false,
            status: '',
            status_invalid: false,
            descricao: '',
            descricao_invalid: false,
            tarefa: null,
            tarefa_invalid: false,
            data: '',
            data_invalid: false,
            tempo: null,
            tempo_invalid: false,
            lembrar_sms: false,
            lembrar_telefone: false,
            error_message_modal: '',
            success_message_modal: '',
            loading_modal: false,
        });
    }
    handleCheckedChange = (e) => {
        const { name, checked } = e.target;

        this.setState({ [name]: checked });
    }

    onData(data){
        if(data.success === true){
            this.setState({
                success_message_modal: data.message
            });
            this.props.refreshUser();
        }
        else{
            this.setState({
                error_message_modal: data.errors
            })
        }
    }

    onError(){
        this.setState({
            loading_modal: false,
            error_message_modal: ''
        })
    }

    onLoading = (loading_modal) => {
        this.setState({loading_modal})
    }

    validFields = () => {
        var invalids = 0;

        if(this.state.assunto.length > 0){
            this.setState({ assunto_invalid: false })
        }
        else{
            invalids++;
            this.setState({ assunto_invalid: true })
        }
        
        if(this.state.direcao.length > 0){
            this.setState({ direcao_invalid: false })
        }
        else{
            invalids++;
            this.setState({ direcao_invalid: true })
        }
        
        if(this.state.status.length > 0){
            this.setState({ status_invalid: false })
        }
        else{
            invalids++;
            this.setState({ status_invalid: true })
        }
        
        if(this.state.tarefa !== null){
            this.setState({ tarefa_invalid: false })
        }
        else{
            invalids++;
            this.setState({ tarefa_invalid: true })
        }
        
        if(this.state.tempo !== null){
            this.setState({ tempo_invalid: false })
        }
        else{
            invalids++;
            this.setState({ tempo_invalid: true })
        }
        
        if(this.state.descricao.length > 0){
            this.setState({ descricao_invalid: false })
        }
        else{
            invalids++;
            this.setState({ descricao_invalid: true })
        }
        
        if(this.state.status !== 'chamada_atual' && this.state.status !== '' ){
            if(this.state.data !== ''){
                this.setState({ data_invalid: false })
            }
            else{
                invalids++;
                this.setState({ data_invalid: true })
            }
        }

        if(invalids > 0){
            return false;
        }
        return true;
    }

    flagLigacoes = (value) => {
        switch(value){
            case 'concluido': return 'Concluido'
            case 'chamada_atual': return 'Em andamento'
            case 'agendado': return 'Agendado'
            case 'aguardando_chamada' : return 'Aguardando'
            case 'perdido' : return 'Perdido'
            default: return value
        }
    }

    getColors = (value) => {
        switch(value){
            case 'concluido': return '#25851e'
            case 'chamada_atual': return '#898f26'
            case 'agendado': return '#333333'
            case 'aguardando_chamada' : return '#c56400'
            case 'perdido' : return '#8f2626'
            default: return '#333333'
        }
    }

    atualizar_chamada = (id) => {
        var url = ENDPOINT+"ticket-admin/realizar_chamada";

        var formData = new FormData();
        formData.append('id', id);
        
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.props.refreshUser();
            return true;
        })
        .catch((error) => {
            var data = error.response.data;
            var response_erro = '';
            var obj;
            if(typeof data.exception !== 'undefined'){
                response_erro = {
                    "success": false,
                    "errors": data.message.length > 0 ? data.message : "Ocorreu um erro no servidor"
                }
                toast.error(response_erro.errors);
            }
            else if(error.message === 'Network Error'){
                response_erro = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response_erro);
                toast.error(obj.errors);
            }
            else if(data){
                toast.success(data.errors);
            }
            else{
                response_erro = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response_erro);
                toast.success(obj.errors);
            }

            return false;
        });

    }

    render(){
        return (
            <div style={{padding: '15px'}}>
                <h5>Tarefas</h5>
                <hr />
                    {this.props.chamadas.map(p => { 
                        return <div style={{padding: 5, display: 'block'}}>
                            <div style={{display: 'flex'}}>
                                <div 
                                    style={{ 
                                        border: '1px solid', 
                                        borderRadius: '100%', 
                                        padding: 7,
                                        borderColor: this.getColors(p.status),
                                        height: '40px',
                                    }}>
                                    {list_tarefas_icons.map(icon => { 
                                        if(icon.value === p.categoria){ 
                                            return <icon.label style={{padding: 0}} /> 
                                        }
                                        return null;
                                    })}
                                </div>
                                <b style={{marginTop: 5, marginLeft: 5}}>{p.assunto}</b>
                            </div>
                            <p style={{marginLeft: 45, marginBottom: 0}}>{p.descricao}</p>
                            
                            {p.status === 'aguardando_chamada' ? <Button onClick={() => this.atualizar_chamada(p.id)} color="success"><FaTasks/>Realizar tarefa</Button> : null}
                            <small style={{marginLeft: 45}}>
                                {this.flagLigacoes(p.status)} - {new Date(p.inicio_chamada).toLocaleString()}
                            </small>
                        </div>
                        }
                    )}
                    {this.props.chamadas.length === 0 ? <>&nbsp;&nbsp;&nbsp;Nenhuma Tarefa</> : ''}
                
                <hr />
                <Button color="dark" onClick={() => this.setState({visible_create: true})}><FaTasks />Adicionar Tarefa</Button>
                <ModalCreate 
                    cod_ticket={this.props.cod_ticket}
                    validFields={this.validFields}
                    handleInputChange={this.handleInputChange}
                    handleStatus={this.handleStatus}
                    handleCheckedChange={this.handleCheckedChange}
                    onData={this.onData.bind(this)}
                    onError={this.onError.bind(this)}
                    onClose={this.onClose.bind(this)}
                    onLoading={this.onLoading.bind(this)}
                    {...this.state}
                />
            </div>
        )
    }
}

const ModalCreate = (props) => {
    const store = () => {
        if(props.validFields() === true){
            props.onLoading(true);
            var url = ENDPOINT+"ticket-admin/adicionar_chamada";

            var formData = new FormData();
            formData.append('cod_ticket', props.cod_ticket);
            formData.append('assunto', props.assunto);
            formData.append('direcao', props.direcao);
            formData.append('status', props.status);
            formData.append('categoria', props.tarefa.value);
            if(props.status === 'chamada_atual'){
                formData.append('inicio_chamada', moment(new Date()).format('YYYY-MM-DD HH:m'));
            }
            else{
                formData.append('inicio_chamada', moment(new Date(props.data)).format('YYYY-MM-DD HH:m'));
            }
            formData.append('final_chamada', props.tempo.value);
            formData.append('descricao', props.descricao);
            formData.append('lembrar', props.status === 'agendado' ? true : false);
            formData.append('lembrar_sms', props.lembrar_sms);
            
            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }
            })
            .then((response) => {
                var data = response.data;
                props.onData(data);
                return data;
            })
            .catch((error) => {
                var data = error.response.data;
                var response_erro = '';
                var obj;
                if(typeof data.exception !== 'undefined'){
                    response_erro = {
                        "success": false,
                        "errors": data.message.length > 0 ? data.message : "Ocorreu um erro no servidor"
                    }
                    props.onData(response_erro);
                }
                else if(error.message === 'Network Error'){
                    response_erro = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    obj = JSON.parse(response_erro);
                    props.onData(obj);
                }
                else if(data){
                    props.onData(data);
                }
                else{
                    response_erro = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    obj = JSON.parse(response_erro);
                    props.onData(obj);
                }
            });
        }
    }

    return (
        <Modal isOpen={props.visible_create} size="lg">
            <ModalBody hidden={props.loading_modal}>
                <h4><FaTasks style={{padding: '0'}}/> &nbsp;Adicionar Tarefa</h4>
                <hr/>
                <Form>
                    <FormGroup>
                        <Label><b>Assunto:</b></Label><br/>
                        <Input
                            name="assunto"
                            value={props.assunto}
                            invalid={props.assunto_invalid}
                            onChange={props.handleInputChange}
                            placeholder="Assunto" />
                        <FormFeedback>Campo Obrigatório</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label><b>Categoria:</b></Label><br/>
                        {/* <input type="radio" checked={props.direcao === 'entrada' ? true : false} onChange={() => props.handleStatus('direcao', 'entrada')} name="direcao"/> Entrada &nbsp;&nbsp;
                        <input type="radio" checked={props.direcao === 'saida' ? true : false} onChange={() => props.handleStatus('direcao', 'saida')} name="direcao"/> Saída */}
                        <Select 
                            options={list_tarefas}
                            onChange={(tarefa) => props.handleStatus('tarefa', tarefa)}
                            value={props.tarefa}
                            placeholder="Categoria"/>
                        <Input invalid={props.tarefa_invalid} hidden />
                        <FormFeedback>Campo Obrigatório</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label><b>Direção:</b></Label><br/>
                        <input type="radio" checked={props.direcao === 'entrada' ? true : false} onChange={() => props.handleStatus('direcao', 'entrada')} name="direcao"/> Entrada &nbsp;&nbsp;
                        <input type="radio" checked={props.direcao === 'saida' ? true : false} onChange={() => props.handleStatus('direcao', 'saida')} name="direcao"/> Saída
                        <Input invalid={props.direcao_invalid} hidden />
                        <FormFeedback>Campo Obrigatório</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label><b>Status:</b></Label><br/>
                        <input type="radio" checked={props.status === 'chamada_atual' ? true : false} onChange={() => props.handleStatus('status', 'chamada_atual')} name="status"/> Em Andamento &nbsp;&nbsp;
                        <input type="radio" checked={props.status === 'agendado' ? true : false} onChange={() => props.handleStatus('status', 'agendado')} name="status"/> Agendado &nbsp;&nbsp;
                        <input type="radio" checked={props.status === 'concluido' ? true : false} onChange={() => props.handleStatus('status', 'concluido')} name="status"/> Concluido
                        <Input invalid={props.status_invalid} hidden />
                        <FormFeedback>Campo Obrigatório</FormFeedback>
                    </FormGroup>
                    <FormGroup hidden={!(props.status === 'agendado' || props.status === 'concluido')}>
                        <Label><b>
                            {
                                props.status === 'agendado' ?
                                    'Insira a data do agendamento'
                                :
                                props.status === 'concluido' ?
                                    'Data de conclusão'
                                :
                                ''
                            }:
                        </b></Label><br/>
                        <Input
                            type="datetime-local"
                            name="data"
                            value={props.data}
                            invalid={props.data_invalid}
                            onChange={props.handleInputChange}
                            placeholder="Insira a Data"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label><b>Duração da Tarefa:</b></Label><br/>
                        <Select 
                            options={list_tempos}
                            onChange={(tempo) => props.handleStatus('tempo', tempo)}
                            value={props.tempo}
                            placeholder="Duração"/>
                        <Input invalid={props.tempo_invalid} hidden />
                        <FormFeedback>Campo Obrigatório</FormFeedback>
                    </FormGroup>
                    {props.status === 'agendado' ?
                        <FormGroup>
                            <Label><b>Lembrar por meio de:</b></Label><br/>
                            <FormGroup style={{marginLeft: '15px'}}>
                                <Input 
                                    type="checkbox"
                                    name="lembrar_sms"
                                    checked={props.lembrar_sms}
                                    onChange={props.handleCheckedChange}
                                    style={{margin: 0,marginTop: 5, marginBottom: 5}}/><p style={{marginLeft: 15}}>SMS</p>
                                <Input 
                                    type="checkbox"
                                    name="lembrar_telefone"
                                    checked={props.lembrar_telefone}
                                    onChange={props.handleCheckedChange}
                                    style={{margin: 0,marginTop: 5, marginBottom: 5}}/><p style={{marginLeft: 15}}>Telefone</p>
                            </FormGroup>
                        </FormGroup>
                    :<></>}
                    <FormGroup>
                        <Label><b>Descrição:</b></Label><br/>
                        <Input
                            type="textarea"
                            style={{ height: 100, resize: 'none' }}
                            name="descricao"
                            value={props.descricao}
                            invalid={props.descricao_invalid}
                            onChange={props.handleInputChange}
                            placeholder="Descrição" />
                        <FormFeedback>Campo Obrigatório</FormFeedback>
                    </FormGroup>
                </Form>
            </ModalBody>
            
            <ModalBody style={{ minHeight: '114px', display: 'flex', 
                alignItems: 'center', alignSelf: 'center', paddingBottom: '47px' }} 
                hidden={!props.loading_modal}>
                    <ModelReturn
                        error_message={props.error_message_modal}
                        success_message={props.success_message_modal}
                        loading={props.loading_modal}
                        onClose={props.onClose}
                        onError={props.onError}
                    />
                </ModalBody>
            <ModalFooter hidden={props.loading_modal}>
                <Button color="danger" onClick={() => props.onClose()}>Fechar</Button>
                <Button color="success" onClick={() => store()}>Salvar</Button>
            </ModalFooter>
        </Modal>
    )
}