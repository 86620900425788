import React, { Component } from 'react';
import { Card, CardBody, Nav, NavLink, Row, TabContent } from 'reactstrap';
import { 
    getUserRows, 
    getTaggyRows, 
    getUserRowsInicial 
} from '../../components/Tables/TableUsers/propsBusiness';
import ActiveTaggy from '../../components/Modals/TaggysModals/ActivateTaggy';
import classnames from 'classnames';
import styled from 'styled-components';
import TaggyClient from '../../components/ResumeClient/TaggyClient';
import TransacoesClient from '../../components/ResumeClient/TransacoesClient';
import ChatsClient from '../../components/ResumeClient/ChatsClient';
import ContestacoesClient from '../../components/ResumeClient/ContestacoesClient';
import DataBusinessClient from '../../components/ResumeClient/DataBusinessClient';
import Funcionarios from '../../components/ResumeClient/FuncionariosClient';
import { getPermissoes } from '../../auth/set_services';
import CreditCardClient from '../../components/ResumeClient/CreditCardClient';
import CreditosClient from '../../components/ResumeClient/CreditosClient';
import EmailClient from '../../components/ResumeClient/EmailClient';
import CobrancasClient from '../../components/ResumeClient/CobrancasClient';
import SmsClient from '../../components/ResumeClient/SmsClient';
import WhatsAppClient from '../../components/ResumeClient/WhatsAppClient';
import NotasAtendimento from '../../components/ChatClient/NotasAtendimento';
import RespostaNPS from '../../components/ResumeClient/RespostaNPS';
import OcorrenciasClient from '../../components/ResumeClient/OcorrenciasClient';

const getId = () => {
    return window.location.pathname.replace('/empresa/', '')
}

const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
`;

const getActive = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('active');
    if(id !== null){
        return id;
    }
    return "1";
}
export default class DataUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            email_responsavel: '',
            phone_responsavel: '',
            telefone: '',
            userCode: '',
            activeTab: getActive(),
            cnpj: '',
            rowData: [],
            cobrancasData: [],
            transactionsData: [],
            contestacoesData: [],
            creditosData: [],
            isBusiness: false,
            totalPage: 0,
            activePage: 1,
            serialTaggy: 0,
            userId: getId(),
            usuarioId: 0,
            taggy_id: 0,
            razao_social: '',
            tipo_negocio: '',
            communication_relacao_id: -1,
            data_constituicao: '',
            nome_fantasia: '',
            email_business: '',
            is_active_taggy: false,
            visible_taggy: false,
            cartao_vinculado: [],
            columns: [
                {
                  name: "Número de Série",
                  selector: "tag_serial",
                  sortable: false,
                  width: '21%'
                },
                {
                  name: "Status",
                  selector: "status",
                  sortable: false,
                  width: '14%'
                },
                {
                  name: "Placa",
                  selector: "veiculo_placa",
                  sortable: false,
                  width: '14%'
                },
                {
                  name: "Fabricante",
                  selector: "veiculo_fabricante",
                  sortable: false,
                  width: '15%'
                },
                {
                  name: "Modelo",
                  selector: "veiculo_modelo",
                  sortable: false,
                  width: '15%'
                },
                {
                  name: "Categoria",
                  selector: "categoria_nome",
                  sortable: false,
                  width: '14%'
                }
            ]
        }
        this.showUsers = this.showUsers.bind(this);
        this.onTaggyChange = this.onTaggyChange.bind(this);
        this.callbackRows = this.callbackRows.bind(this);
    }
    componentDidMount(){
        getUserRowsInicial(
            this.state.userId, 
            this.callbackRows, 
            this.callbackTaggy.bind(this), 
            this.callbackDataUser.bind(this)
            );
    }
    showUsers(visible_taggy){
        this.setState({serialTaggy: this.state.serialTaggy, visible_taggy})
    }
    callbackRows(rowData, creditosData, cobrancasData, totalPage){
        console.log(creditosData)
        this.setState({rowData, creditosData, cobrancasData, totalPage});
    }
    callbackDataUser(data){
        this.setState({
            name: data.nome,
            email: data.email,
            telefone: data.telefone,
            userCode: data.userCode,
            communication_relacao_id: data.conta.id
        });

        data.operadores.forEach(user => {
            if(user.user_tipo === 'responsavel'){
                this.setState({
                    usuarioId: user.id
                })
            }
        })

        if(data.conta.empresa !== null){
            this.callbackDataBusiness(data.conta.empresa);
        }
        if(data.conta.users !== null){
            this.setState({
                email_responsavel: data.conta.users.email,
                phone_responsavel: data.conta.users.telefone
            })
        }

        if (data.conta.cartao_vinculado !== null) {
            this.setState({
                cartao_vinculado: data.conta.cartao_vinculado
            });
        }
    }

    callbackDataBusiness(data){
        this.setState({
            isBusiness: true,
            razao_social: data.razao_social,
            tipo_negocio: data.tipo_negocio,
            data_constituicao: data.data_constituicao,
            nome_fantasia: data.nome_fantasia,
            email_business: data.email,
            cnpj: data.cnpj
        })
    }

    callbackTaggy(transactionsData, serialTaggy, contestacoesData, taggy_id, status){
         if(status === 'inativo'){
            this.setState({is_active_taggy: true})
        }
        else{
            this.setState({is_active_taggy: false})
        } 
        this.setState({transactionsData, serialTaggy, contestacoesData, taggy_id});
        getUserRows(this.state.userId, this.callbackRows);
    }

    onTaggyChange = row => {
        this.setState({rowData: this.state.rowData, serialTaggy: row.tag_serial});
        getTaggyRows(row.id, row.tag_serial, this.callbackTaggy.bind(this));
    }
    conditionalRowStyles = [{
        when: row => row.id === this.state.taggy_id,
            style: {
                backgroundColor: 'rgba(79, 7, 107, 0.7)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        }
    ];
    listData = [
        { name: 'Dados da Empresa', active: '1' },
        { name: 'Taggys', active: '2' },
        { name: 'Transações', active: '3' },
        { name: 'Contestações', active: '4' },
        { name: 'Cobranças', active: '5' },
        { name: 'Operadores', active: '6' },
        { name: 'Cartões de Crédito', active: '7' },
        { name: 'Créditos Pré-Pago', active: '8' },
        { name: 'E-mails', active: '9' },
        { name: 'SMS', active: '10' },
        { name: 'Acessos', active: '11' },
        { name: 'WhatsApp', active: '12' },
        { name: 'Telefones', active: '13' },
        { name: 'Chats', active: '14' },
        { name: 'Notas', active: '15' },
        { name: "NPS", active: '16' },
        { name: "Ocorrências", active: '17' }
    ]

    Navs = () => {
        return <Nav tabs>
            {this.listData.map((p, index) => {
                return <NavLink
                    key={index}
                    className={
                        classnames({ active: this.state.activeTab === p.active })}
                    onClick={() => { this.setState({ activeTab: p.active }); }}>
                    <span>{p.name}</span>
                </NavLink>
            })}
        </Nav>
    }

    render() {
        return (
            <Div>
                <h3>Resumo da Empresa</h3>
                <hr />
                <Card>
                    <CardBody>
                        <this.Navs />
                        <TabContent activeTab={this.state.activeTab}>
                            <DataBusinessClient
                                active={'1'}
                                name={this.state.name}
                                email={this.state.email}
                                telefone={this.state.telefone}
                                userCode={this.state.userCode}
                                razao_social={this.state.razao_social}
                                nome_fantasia={this.state.nome_fantasia}
                                data_constituicao={this.state.data_constituicao}
                                cep={this.state.cep}
                                cidade={this.state.cidade}
                                estado={this.state.estado}
                                endereco={this.state.endereco}
                                bairro={this.state.bairro}
                            />
                            <TaggyClient
                                active={'2'}
                                onTaggyChange={this.onTaggyChange}
                                showUsers={this.showUsers}
                                rowData={this.state.rowData}
                                conditionalRowStyles={this.conditionalRowStyles}
                                columns={this.state.columns}
                                is_active_taggy={this.state.is_active_taggy}
                            />
                            {/* <CobrancasClient
                                name={this.state.name}
                                cobrancasData={this.state.cobrancasData}
                                userId={this.state.userId} 
                            /> */}
                            <TransacoesClient
                                active={'3'}
                                name={this.state.name}
                                transactionsData={this.state.transactionsData}
                                serialTaggy={this.state.serialTaggy} 
                            />
                            <ContestacoesClient
                                active={'4'}
                                name={this.state.name}
                                contestacoesData={this.state.contestacoesData}
                                serialTaggy={this.state.serialTaggy}
                            />
                            <CobrancasClient
                                active={'5'}
                                name={this.state.razao_social}
                                cobrancasData={this.state.cobrancasData}
                                userId={this.state.userId} 
                            />
                            <Funcionarios
                                active={'6'}
                                razao_social={this.state.razao_social}
                                cnpj={this.state.cnpj}
                                />
                            <CreditCardClient
                                active={'7'}
                                cartao_vinculado={this.state.cartao_vinculado}
                                name={this.state.name}
                                user_id={this.state.usuarioId}
                                refresh={() =>  
                                    getUserRowsInicial(
                                        this.state.userId,
                                        this.callbackRows,
                                        this.callbackTaggy.bind(this),
                                        this.callbackDataUser.bind(this)
                                    )}
                                telefone={this.state.telefone}
                            />
                            <CreditosClient
                                active={'8'}
                                name={this.state.razao_social}
                                serialTaggy={this.state.serialTaggy}
                                creditosData={this.state.creditosData}
                            />
                            <EmailClient
                                active={'9'}
                                name={this.state.razao_social}
                                email={this.state.email}
                                communication_relacao_id={this.state.communication_relacao_id}
                            />
                            <SmsClient
                                active={'10'}
                                telefone={this.state.telefone}
                                name={this.state.razao_social}
                                communication_relacao_id={this.state.communication_relacao_id}
                            />
                            <WhatsAppClient
                                active={'12'}
                                name={this.state.razao_social}
                                communication_relacao_id={this.state.communication_relacao_id}
                            />
                            <ChatsClient 
                                active={'14'}
                                name={this.state.razao_social}
                                communication_relacao_id={this.state.communication_relacao_id}
                            />
                            <NotasAtendimento
                                active={'15'}
                                name={this.state.razao_social}
                                user_id={this.state.userId}
                                communication_relacao_id={this.state.communication_relacao_id}
                            />
                            <RespostaNPS
                                active={'16'}
                                name={this.state.name}
                                user_id={this.state.userId}
                            />
                            <OcorrenciasClient
                                active={'17'}
                                name={this.state.name}
                                user_id={this.state.userId}
                            />
                        </TabContent>
                    </CardBody>
                </Card>
                <Row>
                </Row>
                <ActiveTaggy
                    visible={this.state.visible_taggy}
                    edit_id={this.state.userId}
                    type_user={'user_id'}
                    taggy={this.state.serialTaggy}
                    onClose={() => this.showUsers(false)}
                />
                <br />
            </Div>
        )
    }
}