import React from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, Col, Row, Collapse, Button, Label, Input } from 'reactstrap';
import Pagination from '../Pagination';
import { columns } from './propsPassagens';
import Select from 'react-select';

var CurrencyInput = require('react-currency-masked-input');

const formas_pagamentos = [
    { value: 'sao_paulo_tiete_sp', label: 'São Paulo (Tiete)' },
    { value: 'curitiba_pr', label: 'Curitiba (Pr)' },
];

const TableCidades = (props) => {
    return (
        <>
            <Card>
                <CardBody>
                    <hr style={{ marginBottom: '4px' }} />
                    <DataTable
                        noHeader={true}
                        columns={columns(props.newDeal, props.newOriginDestiny, props.state.type_insert)}
                        button={true}
                        responsive
                        noDataComponent={props.noDataComponent}
                        data={props.data}
                        theme="default"
                    />
                </CardBody>
                <CardFooter style={{ textAlign: 'center' }}>
                    <Pagination
                        onPageChange={props.onPageChange}
                        activePage={props.activePage}
                        totalPage={props.totalPage} />
                </CardFooter>
            </Card>
        </>
    )
}

export default TableCidades;