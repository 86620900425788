import React, {Component} from 'react';
import { Card, CardBody } from "reactstrap";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { TableLoja } from '../../components/Tables/TableLoja/TableLoja';
import Moment from 'moment';
import { getRows, getRowsPerPage } from '../../components/Tables/TableLoja/propsLoja';
import PropagateLoader from "react-spinners/PropagateLoader";
import axios from 'axios';
import { ENDPOINT } from '../../auth/endpoint';
import { getPermissoes, getToken, getUser } from '../../auth/set_services';
import { toast } from 'react-toastify';
import ModalDialog from '../../components/Modals/ReturnsApiModals/ModalDialog';


const store = (hash, id, onData) => {
    var url = ENDPOINT+'cobranca/reenviar/'+hash+'/'+id;

    axios.post(url, [], {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });
}
class Loja extends Component{
    constructor(props){
        super(props);
        this.state = {
            rowData: [],
            validade: false,
            activePage: 1,
            totalPage: 1,
            document: '',
            forma_pagamento: null,
            status_loja: null,
            is_cnpj: false,
            filter: false,
            search: '',
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            loading_table: true,
            delete_id: -1,
            error_message: '',
            success_message: '',
            loading: false,
            visible_delete: false,
        }
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentDidMount(){
        getRows(this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({rowData, activePage, totalPage, loading_table: false})
    }

    onFilter = (filter) => {
        this.setState({filter});
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }

    onPageChange = (activePage) => {
        this.setState({activePage, loading_table: true})
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }

    onDataToast(data) {
        if (data.success === true) {
            toast.success(data.message);
        }
        else {
            toast.error(data.errors);
        }
    }
    handleStatusSelectChange = status_pagamento => {
        var state = this.state;
        state['status_pagamento'] = status_pagamento;
        state.loading_table = true;
        state.rowData = [];
        this.setState({ ...state });
        getRowsPerPage(1, this.state.search, this.state.filter, state, this.callbackRows.bind(this));
    };
    
    handleFormasPagamentoSelectChange = forma_pagamento => {
        var state = this.state;
        state['forma_pagamento'] = forma_pagamento;
        state.loading_table = true;
        state.rowData = [];
        this.setState({ ...state });
        getRowsPerPage(1, this.state.search, this.state.filter, state, this.callbackRows.bind(this));
    };

    onClose(){
        this.setState({
            visible_delete: false,
            loading: false,
            error_message: '',
            success_message: '',
        })
    }

    onData = (data) => {
        if(data.success === true){
            this.setState({
                success_message: data.message
            });
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onDelete = () => {
        this.setState({loading: true})
        var url = ENDPOINT+"loja/delete/"+this.state.delete_id;
        axios.delete(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.onClose();
            getRows(this.state, this.callbackRows.bind(this));
            return data;
        })
        .catch((error) => {
            var response = '';
            var obj;
            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            if(data.exception){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            this.onData(data);
            return data;
        });
    }

    onError(){
        this.setState({
            loading: false,
            error_message: ''
        })
    }

    onShowDelete = (delete_id) => {
        this.setState({
            delete_id,
            visible_delete: true
        })
    }

    render(){
        return(
            <div>
                <h3>Lojas</h3>
                <hr/>
                <Card hidden={!getUser().permissao_gestor_aqpago}>
                    <TableLoja
                        handleStatusSelectChange={this.handleStatusSelectChange.bind(this)}
                        handleFormasPagamentoSelectChange={this.handleFormasPagamentoSelectChange.bind(this)}
                        isSearch={this.handleChange}
                        is_cnpj={this.state.is_cnpj}
                        data={this.state.rowData}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        onShowDelete={this.onShowDelete}
                        filter={this.state.filter}
                        state={this.state}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhuma cobrança encontrada
                            </div></CardBody></Card>
                        }
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <ModalDialog
                    visible={this.state.visible_delete}
                    onError={this.onError.bind(this)}
                    message="Deseja excluir essa Loja? Essa operação não poderá ser desfeita"
                    onClose={() => this.onClose()}
                    error_message={this.state.error_message}
                    loading={this.state.loading}
                    onSave={() => this.onDelete()}
                    success_message={this.state.success_message}
                />
            </div>
        )
    }
}

export default Loja;