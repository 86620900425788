import React from 'react';
import { HiViewGrid } from 'react-icons/hi';
import { Button, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getPermissoes, getToken } from '../../../auth/set_services';
import ReactTooltip from 'react-tooltip';
import 'react-dropdown/style.css';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import moment from 'moment';

export const columns = (newDeal, newOriginDestiny, typeInsert) => [
  {
    name: '',
    selector: 'buttons',
    width: '5%',
    cell: row => <Row>
      <Button data-tip="Resumo da passagem"
        outline
        style={{ padding: '0px', marginRight: '3px', border: 0 }}
        color="primary" 
        onClick={() => {
          return newDeal(row)
        }}>
        <HiViewGrid style={{ padding: '2px', width: '23px' }} />
      </Button>
      <ReactTooltip />
    </Row>
  },
  {
    name: "Empresa",
    selector: "business",
    sortable: false,
    cell: row => {
        if (row.url_banner) {
            return <img src={row.url_banner} style={{width: '200px', margin: '1rem'}} />
        } else {
            return row.business;
        }
    }
  },
//   {
//     name: "Origem",
//     selector: "origin",
//     sortable: false,
//   },
//   {
//     name: "Destino",
//     selector: "destiny",
//     sortable: false,
//   },
  {
    name: "Saída",
    selector: "exit_hour",
    sortable: false,
    //cell: row => moment(row.updated_at).format('DD/MM/YYYY HH:mm')
  },
  {
    name: "Chegada",
    selector: "arrival_hour",
    sortable: false,
    cell: row => {
        if (row.arrival_day > 1) {
            return `${row.arrival_day} dias às ${row.arrival_hour}`
        } 
        else if (row.arrival_day > 0) {
            return `${row.arrival_day} dia às ${row.arrival_hour}`
        }
        else {
            return `${row.arrival_hour}`
        }
    }
  },
  {
    name: "Tempo",
    selector: "time_travel",
    sortable: false,
  },
  {
    name: "Assentos Livres",
    selector: "free_seats",
    sortable: false,
  },
  {
    name: "Valor",
    selector: "amount",
    sortable: false,
    cell: row => parseFloat(row.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
  },
  {
    name: "Seguro",
    selector: "optional_insurance",
    sortable: false,
    cell: row => parseFloat(row.optional_insurance).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
  },
];

export const getRows = (state, callback) => {
  console.log(getToken())
    if (state.origin && state.destiny && state.date_accommodation) {
        let url = `https://apimeubus.aqbank.com.br/api/accomodation/search-accommodation?origin_id=${state.origin?.value}&destiny_id=${state.destiny?.value}&date_accommodation=${state.date_accommodation}`;

        let myInit = {
            method: 'GET',
            mode: 'cors',
            cache: 'default'
        };
    
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
          console.log(p)
          callback(p.data, p.last_page);
        })
        .catch(() => {
          toast.error('Ocorreu um erro no servidor!');
        })
    } else {
        callback([], 1);
    }
}

export const getRowsPerPage = (page, state, callback) => {
    if (state.origin && state.destiny && state.date_accommodation) {
        let url = `https://apimeubus.aqbank.com.br/api/accomodation/search-accommodation?origin_id=${state.origin?.value}&destiny_id=${state.destiny?.value}&date_accommodation=${state.date_accommodation}`;
        
        let myInit = {
            method: 'GET',
            mode: 'cors',
            cache: 'default'
        };
    
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
          console.log(p)
          callback(p.going_booking, p.last_page);
        })
        .catch(() => {
          toast.error('Ocorreu um erro no servidor!');
        })
    } else {
        callback([], 1);
    }
}
