import { Component } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { TablePayoutBlock } from "../../components/Tables/TablePayout/TablePayoutBlock";
import { getRelatorio, getRows, getRowsPerPage } from "../../components/Tables/TablePayout/propsPayout";
import { Button, Card, CardBody, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PayoutModal from "../../components/Modals/PayoutModal/PayoutModal";
import Moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import ModalDialog from "../../components/Modals/ReturnsApiModals/ModalDialog";
import { sendSMS, sendMails, setPayoutLiberado, setPayoutLiquidado } from "../../components/Tables/TablePayout/propsPayoutBlock";
import ClipLoader from 'react-spinners/ClipLoader';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const DetalhesTransacao = (props) => (
    <Modal isOpen={props.visible}>
        <ModalHeader>Detalhes da Transação</ModalHeader>
        <ModalBody>
            <p>
                <b>ID da Transação:</b> {props.detalhe.id_transacao}<br/>
                <b>Descrição:</b> {props.detalhe.detalhes}<br/>
                <b>Seller:</b> {props.detalhe.nome}<br/>
                <b>Nº da Transação:</b> {props.detalhe.transaction_number}<br/>
                <b>Valor:</b> {props.detalhe.valor}<br/>
                <b>Taxa:</b> {props.detalhe.taxa}<br/>
                <b>Data da Venda:</b> {props.detalhe.created_at}<br/>
            </p>
        </ModalBody>
        <ModalFooter>
            <Button color="danger" onClick={() => props.onClose()}>Fechar</Button>
        </ModalFooter>
    </Modal>
)

class PayoutBloqueados extends Component{
    constructor(props){
        super(props);
        this.state = {
            valor_de: '',
            valor_ate: '',
            search: '',
            loading_table: true,
            edit_id: -1,
            edit_user: null,
            rowData: [],
            cpf_current: null,
            cnpj_current: null,
            phone_current: null,
            modalidade: null,
            motivo: null,
            razao_bloqueio: null,
            relatorio: {},
            pagamento_liberado: null,
            mail_received: false,
            status_user: '',
            type_user: '',
            totalPage: 0,
            activePage: 1,
            is_cnpj: false,
            mensagem_sms: '',
            mensagem_sms_invalid: false,
            mensagem_email: '',
            mensagem_email_invalid: false,
            document: '',
            filter: false,
            loading_sms: false,
            loading_emails: false,
            loading_manual_emails: false,
            loading_manual_sms: false,
            visible_manual_email: false,
            visible_manual_sms: false,
            visible_email: false,
            payload_current: {},
            selected_rows: {
                allSelected: false,
                selectedCount: 0,
                selectedRows: []
            },
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),

            details: {},
            visible_details: false,

            delete_id: -1,
            error_message: '',
            success_message: '',
            loading: false,
            visible_delete: false,
        }
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    onDetails = (details) => {
        this.setState({
            visible_details: true,
            details
        })
    }
    handleChange = (event) => {
        var state = this.state;
        let name = event.target.name;
        let value = event.target.value;
        state[name] = value;
        
        this.setState({...state});
        
        /* if(name === 'document'){
            if(value.replace(/([^\d])+/gim, '').length > 10){
                state[name] = value.replace(/([^\d])+/gim, '');
                state.activePage = 1;
                state.rowData = [];
                state.loading_table = true;
                this.setState({...state});
                
                getRowsPerPage(1, state, this.callbackRows);
                getRelatorio(this.onRelatorio);
            }
            else if(value.replace(/([^\d])+/gim, '').length === 0){
                if(state[name].replace(/([^\d])+/gim, '').length > 0){
                    state[name] = value.replace(/([^\d])+/gim, '');
                    state.activePage = 1;
                    state.rowData = [];
                    state.loading_table = true;
                    this.setState({...state});
                    
                    getRowsPerPage(1, state, this.callbackRows);
                    getRelatorio(this.onRelatorio);
                }
            }
        }
        else if(name !== "mensagem_sms"){
            state[name] = value;
            state.activePage = 1;
            state.rowData = [];
            state.loading_table = true;
            this.setState({...state});
            
            getRowsPerPage(1, state, this.callbackRows);
            getRelatorio(this.onRelatorio);
        }
        else{
            state[name] = value;
            
            this.setState({...state});
        } */
    }

    onShowDelete(delete_id){
        this.setState({
            delete_id,
            visible_delete: true
        })
    }

    onCloseDelete(){
        this.setState({
            delete_id: -1,
            error_message: '',
            success_message: '',
            loading: false,
            visible_delete: false,
        })
    }
    onError(){
        this.setState({
            loading: false,
            error_message: ''
        })
    }

    componentDidMount(){
        getRows(this.state, this.callbackRows.bind(this));
        getRelatorio(this.onRelatorio);
    }
    filterShow(){
        this.setState({
            show_filter: !this.state.show_filter
        })
    }
    callbackRows = (rowData, totalPage) => {
        this.setState({rowData, totalPage, loading_table: false})
    }

    onPageChange = (activePage) => {
        var state = this.state;

        state.activePage = activePage;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});

        getRowsPerPage(activePage, state, this.callbackRows.bind(this));
        getRelatorio(this.onRelatorio);
    }

    onFilter = (filter) => {
        var state = this.state;
        state.filter = filter;
        this.setState({ ...state });
        //getRowsPerPage(state.activePage, state, this.callbackRows.bind(this));
        //getRelatorio(this.onRelatorio);
    }

    onMailReceived = (mail_received) => {
        var state = this.state;
        state.mail_received = mail_received;
        this.setState({ ...state });
        //getRowsPerPage(state.activePage, state, this.callbackRows.bind(this));
        //getRelatorio(this.onRelatorio);
    }

    onPayload = (payload_current) => {
        this.setState({
            payload_current,
            visible_payload: true
        })
    }
    onClose = () => {
        getRowsPerPage(this.state.activePage, this.state, this.callbackRows.bind(this));
        getRelatorio(this.onRelatorio);
        this.setState({visible_payload: false, payload_current: {}});
    }
    onSelectedRows = (selected_rows) => {
        console.log(selected_rows)
        this.setState({selected_rows});
    }
    onCallbackEmails = (data) => {
        if(data.success === true){
            this.setState({loading_emails: false, mensagem_email: '', visible_email: false});

            toast.update('enviar_emails', {
                type: toast.TYPE.SUCCESS,
                render: data.message,
                autoClose: 3000
            });
        }
        else{
            this.setState({loading_emails: false});
            toast.update('enviar_emails', {
                type: toast.TYPE.ERROR,
                render: data.errors,
                autoClose: 3000
            });
        }

        return data;
    }

    onCallback = (data) => {
        if(data.success === true){
            this.setState({loading_sms: false, mensagem_sms: ''});

            toast.update('enviar_sms', {
                type: toast.TYPE.SUCCESS,
                render: data.message,
                autoClose: 3000
            });
        }
        else{
            this.setState({loading_sms: false, mensagem_sms: ''});
            
            toast.update('enviar_sms', {
                type: toast.TYPE.ERROR,
                render: data.errors,
                autoClose: 3000
            });
        }

        return data;
    }

    enviarSMSManualmente = () => {
        this.onCloseSMS(false);

        toast.dark(<><ClipLoader color="#ffffff" size={20}/> SMS's sendo enviados, por favor aguarde</>, {
            toastId: 'enviar_sms'
        });

        this.setState({loading_sms: true});

        sendSMS(
            this.state.selected_rows.selectedRows, 
            this.state.mensagem_sms,
            this.onCallback
        );

    }

    enviarEmailManualmente = () => {
        toast.dark(<><ClipLoader color="#ffffff" size={20}/> E-mails sendo enviados, por favor aguarde</>, {
            toastId: 'enviar_emails'
        });

        this.setState({loading_emails: true});
        
        sendMails(
            this.state.selected_rows.selectedRows, 
            draftToHtml(convertToRaw(this.state.mensagem_email.getCurrentContent())),
            this.onCallbackEmails
        );
    }

    enviarSMS = () => {
        toast.dark(<><ClipLoader color="#ffffff" size={20}/> SMS's sendo enviados, por favor aguarde</>, {
            toastId: 'enviar_sms'
        });

        this.setState({loading_sms: true});

        sendSMS(
            this.state.selected_rows.selectedRows, 
            null,
            this.onCallback
        );
    }
    
    enviarEmail = () => {
        toast.dark(<><ClipLoader color="#ffffff" size={20}/> E-mails sendo enviados, por favor aguarde</>, {
            toastId: 'enviar_emails'
        });

        this.setState({loading_emails: true});
        
        sendMails(
            this.state.selected_rows.selectedRows, 
            null,
            this.onCallbackEmails
        );
    }
    
    liberarPagamentos = () => {
        toast.dark(<><ClipLoader color="#ffffff" size={20}/> E-mails sendo enviados, por favor aguarde</>, {
            toastId: 'enviar_emails'
        });

        this.setState({loading_emails: true});
        
        setPayoutLiberado(
            this.state.selected_rows.selectedRows, 
            this.onCallbackEmails
        );
    }

    liquidarPagamentos = () => {
        toast.dark(<><ClipLoader color="#ffffff" size={20}/> Por favor aguarde</>, {
            toastId: 'enviar_emails'
        });

        this.setState({loading_emails: true});
        
        setPayoutLiquidado(
            this.state.selected_rows.selectedRows, 
            this.onCallbackEmails
        );
    }
    onData(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            });
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }

    onDelete = () => {
        this.setState({loading: true})
        var url = ENDPOINT+"payout/deletar_pagamento/"+this.state.delete_id;
        axios.delete(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.onCloseDelete();
            getRowsPerPage(this.state.activePage, this.state, this.callbackRows.bind(this));
            getRelatorio(this.onRelatorio);
            return data;
        })
        .catch((error) => {
            var response = '';
            var obj;
            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            if(data.exception){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            this.onData(data);
            return data;
        });
    }

    onPagamentoLiberado = (pagamento_liberado) => {
        var state = this.state;
        state.pagamento_liberado = pagamento_liberado;
        //state.rowData = [];
        //state.loading_table = true;
        this.setState({ ...state });
        /* getRowsPerPage(state.activePage, state, this.callbackRows.bind(this));
        getRelatorio(this.onRelatorio); */
    }

    buscarPayout = () => {
        var state = this.state;
        state.rowData = [];
        state.loading_table = true;
        state.activePage = 1;
        this.setState({ ...state });
        
        getRowsPerPage(state.activePage, state, this.callbackRows.bind(this));
        //getRelatorio(this.onRelatorio);
    }

    onRelatorio = (relatorio) => {
        console.log(relatorio)
        this.setState({relatorio})
    }
    onCloseSMS = (visible_manual_sms) => {
        this.setState({visible_manual_sms})
    }
    onCloseEmail = (visible_email) => {
        this.setState({visible_email})
    }
    handleChangeEmail = (mensagem_email) => {
        this.setState({mensagem_email});
    }
    
    onChangeSelect = (name, value) => {
        var state = this.state;
        state[name] = value;
        this.setState({...state});
    }

    render(){
        return (
            <div>
                <h3>Pagamentos Bloqueados</h3>
                <hr />
                <PayoutModal 
                    novoBloqueio={false}
                    payload={this.state.payload_current}
                    visible={this.state.visible_payload}
                    onClose={() => this.onClose()}
                />
                <TablePayoutBlock
                    selectableRows
                    onPagamentoLiberado={this.onPagamentoLiberado}
                    isSearch={this.handleChange}
                    onChangeSelect={this.onChangeSelect}
                    onSearchDocument={this.onSearchDocument.bind(this)}
                    onSelectedRows={this.onSelectedRows.bind(this)}
                    enviarSMS={this.enviarSMS}
                    enviarEmail={this.enviarEmail}
                    liberarPagamentos={this.liberarPagamentos}
                    liquidarPagamentos={this.liquidarPagamentos}
                    modalSendSms={this.onCloseSMS}
                    modalSendEmail={this.onCloseEmail}
                    buscarPayout={this.buscarPayout}
                    filterShow={this.filterShow.bind(this)}
                    onPageChange={this.onPageChange}
                    onFilter={this.onFilter.bind(this)}
                    onMailReceived={this.onMailReceived.bind(this)}
                    onDetails={this.onDetails}
                    onPayload={this.onPayload}
                    onDelete={this.onShowDelete.bind(this)}
                    mail_received={this.state.mail_received}
                    data={this.state.rowData}
                    payouts_current={this.state.selected_rows}
                    is_cnpj={this.state.is_cnpj}
                    modalidade={this.state.modalidade}
                    motivo={this.state.motivo}
                    razao_bloqueio={this.state.razao_bloqueio}
                    relatorio={this.state.relatorio}
                    loading_sms={this.state.loading_sms}
                    loading_emails={this.state.loading_emails}
                    loading_manual_emails={this.state.loading_manual_emails}
                    loading_manual_sms={this.state.loading_manual_sms}
                    show_filter={this.state.show_filter}
                    pagamento_liberado={this.state.pagamento_liberado}
                    date_from={this.state.date_from}
                    date_to={this.state.date_to}
                    filter={this.state.filter}
                    activePage={this.state.activePage}
                    totalPage={this.state.totalPage}
                    noDataComponent={ this.state.loading_table === true ? 
                        <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                            <PropagateLoader color={'#52c565'} loading={20} size={30} />
                        </div></div></CardBody></Card> :
                        <Card style={{border: 0}}><CardBody><div>
                            Nenhum pagamento encontrado
                        </div></CardBody></Card>
                    }
                />
                <ModalDialog
                    visible={this.state.visible_delete}
                    onError={this.onError.bind(this)}
                    message="Deseja remover essa solicitação de bloqueio ?"
                    onClose={() => this.onCloseDelete()}
                    error_message={this.state.error_message}
                    loading={this.state.loading}
                    onSave={this.onDelete.bind(this)}
                    success_message={this.state.success_message}
                />
                <DetalhesTransacao
                    visible={this.state.visible_details} 
                    detalhe={this.state.details}
                    onClose={() => this.setState({visible_details: false, details: {} })}
                />
                <EnviarEmail
                    visible={this.state.visible_email} 
                    mensagem_email={this.state.mensagem_email}
                    loading_emails={this.state.loading_emails}
                    mensagem_email_invalid={this.state.mensagem_email_invalid}
                    handleChangeEmail={this.handleChangeEmail}
                    onClose={this.onCloseEmail}
                    enviarEmailManualmente={this.enviarEmailManualmente}
                />
                <EnviarSMS
                    visible={this.state.visible_manual_sms}
                    mensagem_sms={this.state.mensagem_sms}
                    mensagem_sms_invalid={this.state.mensagem_sms_invalid}
                    handleChange={this.handleChange}
                    onClose={this.onCloseSMS}
                    enviarSMSManualmente={this.enviarSMSManualmente}
                />
            </div>
        )
    }
}


const EnviarEmail = (props) => {
    return <Modal isOpen={props.visible}>
        <ModalHeader>Enviar E-mail</ModalHeader>
        <ModalBody>
            <FormGroup>
                    <Card style={{minHeight: 250}}>
                        <Editor
                            initialEditorState={props.mensagem_email}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={props.handleChangeEmail}
                            editorStyle={{
                                fontSize: 12,
                                fontFamily: "Arial"
                            }}
                        />
                    </Card>
            </FormGroup>
        </ModalBody>
        <ModalFooter>
            <Button color="danger" onClick={() => props.onClose(false)}>Cancelar</Button>
            <Button color="success" disabled={props.loading_emails} onClick={() => props.enviarEmailManualmente()}>Enviar</Button>
        </ModalFooter>
    </Modal>
}

const EnviarSMS = (props) => {
    return <Modal isOpen={props.visible}>
        <ModalHeader>Enviar SMS</ModalHeader>
        <ModalBody>
            <FormGroup>
                <Input
                    type="textarea"
                    placeholder="Mensagem"
                    name="mensagem_sms"
                    onChange={props.handleChange}
                    maxLength={160}
                    value={props.mensagem_sms} 
                    invalid={props.mensagem_sms_invalid}
                    style={{ height: "110px", resize: 'none' }}
                />
                <small style={{float: 'right'}}>{props.mensagem_sms.length}/160</small>
            </FormGroup>
        </ModalBody>
        <ModalFooter>
            <Button color="danger" onClick={() => props.onClose(false)}>Cancelar</Button>
            <Button color="success" onClick={() => props.enviarSMSManualmente()}>Enviar</Button>
        </ModalFooter>
    </Modal>
}


export default PayoutBloqueados;