import { useEffect, useState } from "react";
import { TableCustomized } from "../../components/Tables/TableCustomized/TableCustomized";
import { Card, CardBody, Button } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import NumberFormat from "react-number-format";
import Cookies from "js-cookie";
import { useApiOnboarding } from "../../hooks/useApi";
import { useHistory } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { HiViewGrid } from "react-icons/hi";

export function Onboarding() {
  const [isFetching, setIsFetiching] = useState(true);
  const [links, setLinks] = useState(null);
  const [filter, setFilter] = useState({
    document: null,
    page: 1,
  });
  const [status, setStatus] = useState(null);

  const [search, setSearch] = useState(null);
  const [data, setData] = useState([]);

  const { apiOnboarding } = useApiOnboarding();
  const history = useHistory();

  const validateInputSearch = (document) => {
    if (document.replace(/[^0-9]/g, "").length > 14) {
      return {
        message: "O documento deve ter no maximo 14 caracteres",
        valid: false,
        invalid: true,
      };
    }
    return {
      message: "",
      valid: true,
      invalid: false,
    };
  };

  const onSearchDocument = () => {
    if (!search) return;
    setSearch({
      document: search.document,
      ...validateInputSearch(search.document),
    });

    if (search?.valid) {
      setIsFetiching(true);
      setData([]);
      setFilter({ page: null, document: search.document });
    }
  };
  const searchOnChange = (event) => setSearch({ document: event.target.value });

  const handleDeleteUser = async (id) => {
    const confirmDelete = window.confirm(
      "Tem certeza que deseja resetar este usuário?"
    );
    if (!confirmDelete) {
      return;
    }

    const token = Cookies.get("onboardingtoken");
    await apiOnboarding
      .post(
        `/crm/onboarding/delete`,
        { identification: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setIsFetiching(true);
        setData([]);
        setFilter({ document: null, page: null });
      })
      .catch((e) => {
        alert("Erro ao resetar usuário");
      });
  };

  const onChangePage = (event) => {
    setIsFetiching(true);
    setData([]);
    setFilter({ page: Number(event), document: null });
  };

  const formatStatus = (status) => {
    switch (status) {
      case "approved":
        return "Aprovado";
      case "in_analysis":
        return "Em analise";
      case "processing":
        return "Processando";
      case "refused":
        return "Rejeitado";
      default:
        return "Pendente";
    }
  };

  useEffect(() => {
    const token = Cookies.get("onboardingtoken");
    let url = `/crm/onboarding?page=${filter.page}`;
    if (status) url += `&filters=${status.value}`;
    if (filter.document)
      url += `&document=${filter.document.replace(/\D/g, "")}`;
    apiOnboarding
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const clients = res?.data?.data;
        setData(clients);
        setLinks(res?.data?.links);
      })
      .catch((e) => {
        alert("Erro ao buscar dados");
        console.log(e);
      })
      .finally(() => {
        setIsFetiching(false);
        setSearch(null);
      });
  }, [filter, status]);

  const columns = () => {
    return [
      {
        name: "",
        selector: "view-user",
        sortable: false,
        width: "4%",
        cell: (row) => (
          <>
            {row?.processing === 0 && (
              <Button
                data-tip="Resetar Cliente"
                outline
                style={{ padding: "0px", marginRight: "3px", border: 0 }}
                color="danger"
                onClick={() => handleDeleteUser(row?.identification)}
              >
                <MdDelete style={{ padding: "2px", width: "23px" }} />
              </Button>
            )}
            {row?.processing === 1 && (
              <Button
                data-tip="Resumo do Cliente"
                outline
                style={{ padding: "0px", marginRight: "3px", border: 0 }}
                color="primary"
                onClick={() => {
                  history.push("/usuario/onboarding/" + row?.identification);
                }}
              >
                <HiViewGrid style={{ padding: "2px", width: "23px" }} />
              </Button>
            )}
          </>
        ),
      },
      {
        name: "Nome / Empresa",
        selector: "nome",
        sortable: false,
        width: "20%",
        cell: (row) => (row?.name ? row?.name : "NÃO INFORMADO"),
      },
      {
        name: "Tipo",
        selector: "type",
        sortable: false,
        width: "5%",
        cell: (row) => row?.type_person.toUpperCase(),
      },
      {
        name: "Documento",
        selector: "document",
        sortable: false,
        width: "13%",
        cell: (row) => {
          if (row?.document !== null) {
            return (
              <NumberFormat
                displayType={"text"}
                value={row?.document}
                format={
                  row?.document.replace(/([^\d])+/gim, "").length > 11
                    ? "##.###.###/####-##"
                    : "###.###.###-##"
                }
              />
            );
          }
          return "S/N";
        },
      },
      {
        name: "Sexo",
        selector: "gender",
        sortable: false,
        width: "5%",
        cell: (row) => (row?.gender ? row?.gender : "N/I"),
      },
      {
        name: "Status Unico",
        selector: "status",
        sortable: false,
        width: "12%",
        cell: (row) => <div>{row?.status}</div>,
      },
      {
        name: "Status",
        selector: "status_onboarding",
        sortable: false,
        width: "10%",
        cell: (row) => <div>{formatStatus(row?.status_onboarding)}</div>,
      },
      {
        name: "Score",
        selector: "score",
        sortable: false,
        width: "5%",
        cell: (row) => <div>{row?.score}</div>,
      },
      {
        name: "Início",
        selector: "start",
        sortable: false,
        width: "13%",
        cell: (row) => <div>{row?.date_in}</div>,
      },
      {
        name: "Fim",
        selector: "end",
        sortable: false,
        width: "13%",
        cell: (row) => <div>{row?.date_end ? row?.date_end : "N/I"}</div>,
      },
    ];
  };
  return (
    <>
      <h3>Onboarding</h3>
      <hr />
      <TableCustomized
        search={search}
        onSearchDocument={onSearchDocument}
        searchOnChange={searchOnChange}
        data={data}
        onPageChange={onChangePage}
        activePage={links?.currentPage}
        totalPage={links?.lastPage}
        columns={columns}
        status={status}
        setStatus={setStatus}
        noDataComponent={
          isFetching ? (
            <Card style={{ border: 0, padding: "10vh" }}>
              <CardBody>
                <div>
                  <div className="sweet-loading">
                    <PropagateLoader color={"#52c565"} loading={20} size={30} />
                  </div>
                </div>
              </CardBody>
            </Card>
          ) : (
            <Card style={{ border: 0 }}>
              <CardBody>
                <div>Nenhum usuário encontrado</div>
              </CardBody>
            </Card>
          )
        }
      />
    </>
  );
}
