import React from 'react';
import { columns } from './propsTaggy';
import { Button, Card, Col, Row, Collapse, CardBody, CardFooter } from "reactstrap";
import DataTable from "react-data-table-component";
import Pagination from '../Pagination';
import debounce from 'lodash/debounce';
import { FaFileExcel } from 'react-icons/fa';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { InputText } from '../../InputText/InputText';
import styled from 'styled-components';
import { InputMasked } from '../../InputText/InputMasked';

const ColSearch = styled.div`
    text-align: left
`;

const ColFunctions = styled.div`
    text-align: right;

    @media (max-width: 650px) {
        text-align: left;
        margin: 6px;
    }

`;

export const TableTaggy = (props) => {
    return (
        <>
            <Card>
                <CardBody>
                    <Row >
                        <ColSearch className="col-md-5">
                            <InputText
                                name="search"
                                placeholder="Buscar"
                                onChange={debounce((event) => props.isSearch(event), 500)} />
                        </ColSearch>
                        <ColFunctions className="col-md-7">
                            {/* <Button
                                color="primary"
                                //hidden={getUser().permission['taggys'] !== 2}
                                hidden={!permissao[get_permission].edit}
                                onClick={() => props.showExport(true)}
                                style={{ marginRight: '5px', marginBottom: '4px' }}>
                                <FaFileUpload style={{ padding: 3 }} size={15} /> Importar
                            </Button> */}
                            <Button
                                color="success"
                                //hidden={getUser().permission['taggys'] !== 2}
                                //hidden={!permissao[get_permission].edit}
                                onClick={props.export}
                                style={{ marginRight: '5px', marginBottom: '4px' }}>
                                <FaFileExcel style={{ padding: 3 }} size={15} /> Exportar
                            </Button>
                            <Button
                                color="warning"
                                onClick={props.filterShow}
                                style={{ marginRight: '5px', marginBottom: '4px' }}>
                                Filtrar
                                    <AiFillCaretDown hidden={props.show_filter} />
                                <AiFillCaretUp hidden={!props.show_filter} />
                            </Button>
                            {/* <ButtonDefault
                                //hidden={getUser().permission['taggys'] !== 2}
                                onClick={() => props.showTaggy(true)}
                                hidden={!permissao[get_permission].edit}
                                style={{ width: '125px', margin: '0px' }}>
                                <MdAdd style={{ padding: 0 }} size={15} />Adicionar
                            </ButtonDefault> */}
                        </ColFunctions>
                    </Row>
                    <Collapse isOpen={props.show_filter}>
                        <Card style={{ border: 'none' }}>
                            <CardBody>
                                <Row>
                                    <ColSearch className="col-md-9">
                                        <InputMasked
                                            name="document"
                                            mask={props.is_cnpj === false ? "999.999.999-99" : "99.999.999/9999-99"}
                                            placeholder={props.is_cnpj === false ? "CPF" : "CNPJ"}
                                            onChange={debounce((event) => props.isSearch(event), 500)} />
                                            &nbsp;&nbsp;<input 
                                                type="checkbox"
                                                checked={props.is_cnpj}
                                                onChange={() => props.onSearchDocument()}/> Pesquisar CNPJ
                                    </ColSearch>
                                </Row>
                                <br/>
                                <input
                                    type="radio"
                                    name="filter"
                                    checked={props.filter === 'all'}
                                    onChange={() => props.onTaggyFilter('all')} /> Todos <br />
                                <input
                                    type="radio"
                                    name="filter"
                                    checked={props.filter === 'active'}
                                    onChange={() => props.onTaggyFilter('active')} /> Taggys Ativadas<br />
                                <input
                                    type="radio"
                                    name="filter"
                                    checked={props.filter === 'block'}
                                    onChange={() => props.onTaggyFilter('block')} /> Taggys Bloqueadas<br />
                                <input
                                    type="radio"
                                    name="filter"
                                    checked={props.filter === 'cancel'}
                                    onChange={() => props.onTaggyFilter('cancel')} /> Taggys Canceladas<br />
                                <input
                                    type="radio"
                                    name="filter"
                                    checked={props.filter === 'no_activate'}
                                    onChange={() => props.onTaggyFilter('no_activate')} /> Taggys Aguardando Ativação<br />
                                <input
                                    type="radio"
                                    name="filter"
                                    checked={props.filter === 'only_buy'}
                                    onChange={() => props.onTaggyFilter('only_buy')} /> Somente taggys disponiveis para venda<br />
                                <br/>
                                <Row>
                                    <Col>De:
                                <InputText
                                            name="date_from"
                                            type="date"
                                            //disabled
                                            disabled={!(props.is_date)}
                                            value={props.date_from}
                                            onChange={(event) => props.isSearch(event)} /></Col>
                                    <Col>Até:
                                <InputText
                                            name="date_to"
                                            type="date"
                                            disabled={!(props.is_date)}
                                            //disabled
                                            value={props.date_to}
                                            onChange={(event) => props.isSearch(event)} /></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <input
                                            type="checkbox"
                                            checked={props.is_date}
                                            name="is_date"
                                            onChange={() => props.isDateShow()} /> Filtrar por data de alteração:<br /><br />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Collapse>
                </CardBody>
                <hr style={{ marginBottom: '4px' }} />
                <DataTable
                    noHeader={true}
                    columns={columns(props.updateTaggy, props.replaceTaggy, props.add_credit_taggy)}
                    button={true}
                    noDataComponent={props.noDataComponent}
                    data={props.data}
                    theme="default"
                />
                <CardFooter style={{ textAlign: 'center' }}>
                    <Pagination
                        onPageChange={props.onPageChange}
                        activePage={props.activePage}
                        totalPage={props.totalPage}
                    />
                </CardFooter>
            </Card>
        </>
    )
}