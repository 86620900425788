import axios from "axios";
import React, { Component, useMemo, useState } from "react";
import { MdArrowBack, MdCheck, MdClear, MdMail, MdWarning, MdAttachFile } from "react-icons/md";
import { BsLock, BsUnlock } from 'react-icons/bs';
import { IoReceiptOutline } from 'react-icons/io5';
import { Button, ButtonGroup, Card, CardBody, CardFooter, Col, Collapse, Form, FormFeedback, FormGroup, Input, Row } from "reactstrap";
import Dropzone from 'react-dropzone';
import styled from "styled-components";
import { ENDPOINT } from "../../auth/endpoint";
import { getPermissoes, getToken } from "../../auth/set_services";
import Select from "react-select";
import { list_prevencao_status, list_status_pagamentos } from "../../auth/listas";
import { InputMasked } from '../../components/InputText/InputMasked';
import { InputText } from "../../components/InputText/InputText";
import { toast, useToastContainer } from 'react-toastify';
import PropagateLoader from "react-spinners/PropagateLoader";
import ClipLoader from 'react-spinners/ClipLoader'
import moment from "moment";
import NumberFormat from "react-number-format";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import sanitizeHtml from 'sanitize-html';
import ReactTooltip from "react-tooltip";
import GoogleMapReact from 'google-map-react';
import DataTable from 'react-data-table-component';
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { FaAngleUp, FaClosedCaptioning, FaEye, FaFilePdf, FaMapMarkerAlt, FaPen, FaPlus, FaRegWindowClose, FaSave, FaTrash, FaUps } from "react-icons/fa";
import Pusher from 'pusher-js';
import { ButtonRed } from "../../components/ButtonStylish/ButtonRed";
import { DropdownIcon } from "../../components/DropdownIcon/DropdownIcon";
import SendEmailByCard from "../../components/Modals/CreditCard/SendEmailByCard";
import { MessageNoTable } from "../MessageTable/MessageNoTable";
import PDFViewer from 'pdf-viewer-reactjs';
import ConfirmarRemove from "../../components/Modals/Juridico/ConfirmarRemove";

var CurrencyInput = require('react-currency-masked-input');

const Div = styled.div`

    @media print {
        .title-page {
            display: none;
        }
        .box-div-page {
            border: 0px !important;
        }
    }
`;

const getId = () => {
    return window.location.pathname.replace('/detalhes-aceite-contrato-aqpago/', '')
}

class ResumoAceiteContratoAqpago extends Component {
    constructor(props){
        super(props);
        this.state = {
            show_form: false,
            loading_page: true,
            loading_button: false,

            id_aceite: null,
            assinatura_dispositivo: null,
            estabeleciomento: null,
            documento_seller: null,
            meio_acesso: null,
            tipo_dispositivo: null,
            modelo_dispositivo: null,
            sistema_operacional: null,
            ip_internet: null,
            latitude: null,
            longitude: null,
            data_aceite: null,
            termos: [],
        }
    }

    componentDidMount(){
        this.handleLoadDataAceite()
    }

    handleLoadDataAceite = () => {
        var url = ENDPOINT + "termo_aceite/detalhes/" + getId();

        var myHeaders = new Headers();

        myHeaders.append('Authorization', 'Bearer ' + getToken());
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            if (p.success !== undefined && p.success == true) {
                let response = p.data.data;
                let estabelecimento = '';

                if (response.razao_social !== undefined) {
                    estabelecimento = response.razao_social;
                } else {
                    estabelecimento = response.nome + ' ' + response.sobrenome;
                }

                this.setState({termos: response.termos})

                this.setState({
                    // id_aceite: response.termos.id,
                    // assinatura_dispositivo: response.termos.assinatura_dispositivo,
                    estabeleciomento: estabelecimento,
                    documento_seller: response.documento,
                    // meio_acesso: response.termos.meio_acesso,
                    // tipo_dispositivo: response.termos.tipo_dipositivo,
                    // modelo_dispositivo: response.termos.modelo_dispositivo,
                    // sistema_operacional: response.termos.sistema_operacional,
                    // ip_internet: response.termos.ip,
                    // latitude: response.termos.latitude,
                    // longitude: response.termos.longitude,
                    // data_aceite: response.termos.data_aceite,
                })
            }
        })
        .catch((error) => {
            console.log(error)
            toast.error('Ocorreu um erro no servidor!');
        })

    }

    render(){
        return (
            <Div key="div_box_aceite">
                <h3 className="title-page">
                    <Button 
                        outline 
                        style={{border: 'none'}}
                        onClick={() => window.location.href = '/aceite-contrato-aqpago'}
                    >
                        <MdArrowBack style={{padding: 0}}/>
                    </Button>&nbsp;&nbsp;Termo de Aceite de contrato
                    <hr />
                </h3>
                {this.state.termos !== undefined && this.state.termos.length > 0 ?
                    this.state.termos.map((termo, i) => {
                        return (
                            <Row>
                                <Col md={12}>
                                    <div className="box-div-page" style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                        <h3 style={{marginBottom: '2rem', fontSize: '24px', textAlign: 'center'}}>Termo de Aceite do Contrato de Credenciamento e Adesão de Estabelecimento ao Sistema de Pagamentos AQPago</h3>
            
                                        <p><b>ID Aceite:</b> {termo.id}</p>
                                        <p><b>Código Hash Digital de Assinatura do Aceite ID:</b> {termo.assinatura_dispositivo}</p>
                                        <p><b>Nome do Cliente/Estabelecimento Credenciamento:</b> {this.state.estabeleciomento}</p>
                                        <p><b>CPF/CNPJ:</b>&nbsp;
                                            {this.state.documento_seller !== null && this.state.documento_seller !== undefined ?
                                                <NumberFormat displayType={'text'} value={this.state.documento_seller} format={this.state.documento_seller.length > 11 ? '##.###.###/####-##' : "###.###.###-##"}/>
                                                :
                                                ''
                                            }
                                        </p>
                                        <p><b>Meio de Acesso:</b>&nbsp; 
                                            {termo.meio_acesso !== null ?
                                                termo.meio_acesso.replace('_', ' ')
                                            :
                                                ''
                                            }
                                        </p>
                                        <p><b>Tipo de Dispositivo Usado:</b> {termo.tipo_dispositivo}</p>
                                        <p><b>Modelo do Dispositivo:</b> {termo.modelo_dispositivo}</p>
                                        <p><b>Sistema Operacional do Dispositivo:</b>&nbsp;
                                            {termo.sistema_operacional !== null ?
                                                termo.sistema_operacional.toUpperCase()
                                            :
                                                ''
                                            }
                                        </p>
                                        <p><b>Endereço IP de Internet:</b> {termo.ip_internet}</p>
                                        <p><b>Latitude de Geolocalização:</b> {termo.latitude}</p>
                                        <p><b>Longitude Geolocalização:</b> {termo.longitude}</p>
                                        <p><b>Data do Aceite:</b>&nbsp;
                                            {termo.data_aceite !== null ?
                                                moment(termo.data_aceite).format('DD/MM/YYYY')
                                            :
                                                ''
                                            }
                                        </p>
                                        <p><b>Hora do Aceite:</b>&nbsp;
                                            {termo.data_aceite !== null ?
                                                moment(termo.data_aceite).format('HH:mm')
                                            :
                                                ''
                                            }
                                        </p>
                                        <p><b>Carimbo de Data/Hora:</b>&nbsp;
                                            {termo.data_aceite !== null ?
                                                moment(termo.data_aceite).format('DD/MM/YYYY HH:mm:ss')
                                            :
                                                ''
                                            }
                                        </p>
            
                                    </div>
                                </Col>
                            </Row>
                        )
                    })
                :
                    <></>
                }

            </Div>
        )
    }
}

export default ResumoAceiteContratoAqpago;