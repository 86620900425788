import React, { Component } from 'react';
import { Card, CardBody, TabPane } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { TableOperadores } from '../../components/Tables/TableOperadores/TableOperadores';
import Moment from 'moment';
import { getRows, getRowsPerPage } from '../../components/Tables/TableOperadores/propsOperadores';
import AddOperador from '../../components/Modals/ClientsModals/AddOperador';
import AddTaggyFromUser from '../../components/Modals/TaggysModals/AddTaggyFromUser';
import { MdBusiness } from 'react-icons/md';
import { ENDPOINT } from '../../auth/endpoint';
import axios from 'axios';
import { getToken } from '../../auth/set_services';
import { toast } from 'react-toastify';

/* const getId = () =>{
    if(window.location.pathname === '/operadores'){
        return null;
    }
    else{
        return window.location.pathname.replace('/operadores/', '')
    }
}  */
class Funcionarios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            activePage: 1,
            loading_table: true,
            cpf_current: null,
            empresa_cnpj: '',
            cnpj_current: '',
            totalPage: 1,
            document: '',
            is_cnpj: false,
            filter: false,
            visibled_add: false,
            visibled_taggy: false,
            search: '',
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
        this.updateStatus = this.updateStatus.bind(this);
        this.updateStatusRequest = this.updateStatusRequest.bind(this);
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.rowData = [];
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentWillUpdate(nextProps, nextState){
        if(nextProps.cnpj !== this.props.cnpj){
            var state = this.state;
            state.empresa_cnpj = nextProps.cnpj;
            this.setState({
                ...state
            });
            getRows(state,  this.callbackRows.bind(this));
        }
    }
    componentDidMount() {
        getRows(this.state, this.callbackRows.bind(this));
    }

    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    onFilter = (filter) => {
        this.setState({ filter });
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onClose = (visibled_add) => {
        this.setState({visibled_add});
        if(visibled_add === false){
            getRowsPerPage(this.state.activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
        }
    }
    onCloseTaggy = (cpf_current, cnpj_current, visibled_taggy) => {
        this.setState({cpf_current, cnpj_current, visibled_taggy})
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    onToasty(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
            toast.success(data.message);
        }
        else{
            this.setState({
                error_message: data.errors
            })
            toast.error(data.errors);
        }
        getRows(this.state, this.callbackRows);
    }
    updateStatus(id, status){
        this.updateStatusRequest(id, status, this.onToasty.bind(this));
    }
    updateStatusRequest(id, status, onData) {
        var url = ENDPOINT + "user/store_status";

        var formData = new FormData();
        formData.append('id', id);
        formData.append('status', status);
        //formData.append('chave_decimal', state.chave_decimal);
    
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            onData(data);
            return data;
        })
        .catch((error) => {
            if (error.message === 'Network Error') {
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
            var data = error.response.data;
            onData(data);
            return data;
        });
    }
    render() {
        return (
            <TabPane tabId={this.props.active}>
            <br />
            <h5>&nbsp;&nbsp;
                <MdBusiness style={{width: '30px', height: '30px'}}/> 
                    Operadores da empresa {this.props.razao_social}
                </h5>
                <hr />
                <Card>
                    <TableOperadores
                        isSearch={this.handleChange}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        data={this.state.rowData}
                        updateStatus={this.updateStatus}
                        onTaggy={this.onCloseTaggy.bind(this)}
                        is_cnpj={this.state.is_cnpj}
                        empresa_cnpj={this.state.empresa_cnpj}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        noDataComponent={this.state.loading_table === true ?
                            <Card style={{ border: 0, padding: '10vh' }}><CardBody>
                                <div><div className="sweet-loading">
                                    <PropagateLoader color={'#52c565'} loading={20} size={30} />
                                </div></div></CardBody></Card> :
                            <Card style={{ border: 0 }}><CardBody><div>
                                Nenhum e-mail encontrado
                            </div></CardBody></Card>
                        }
                        onAdd={this.onClose.bind(this)}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                    <AddOperador 
                        visible={this.state.visibled_add}
                        onClose={() => this.onClose(false)}
                        cnpj={this.state.empresa_cnpj}
                    />
                    <AddTaggyFromUser
                        visible={this.state.visibled_taggy}
                        cpf={this.state.cpf_current}
                        api="operadores/store_taggy"
                        onClose={() => this.onCloseTaggy(null, null, false)}
                        cnpj={this.state.empresa_cnpj}
                        />
                </Card>
            </TabPane>
        )
    }
}

export default Funcionarios;