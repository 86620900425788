import axios from 'axios';
import React, { Component } from 'react';
import Select from 'react-select';
import { Card, CardBody, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { ENDPOINT } from '../../auth/endpoint';
import { getPermissoes, getToken } from '../../auth/set_services';
import { ButtonDefault } from '../../components/Button/ButtonDefault';
import PropagateLoader from "react-spinners/PropagateLoader";
import permissoes from './permissoes_json.json';
import { toast } from "react-toastify";
import ReactRoundedImage from "react-rounded-image";

const getPerm = (callbackRows) => {
    var url = ENDPOINT + "permissao/list";
    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data)
    });
}

const getOperadores = (callback) => {
    var url = ENDPOINT + "chat-atendimento/consultar-operadores";
    
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        var operadores_list = [];
        data.forEach((p, i) => {
            p['value'] = p.id;
            p['label'] = <div style={{display: 'flex'}}>
                <ReactRoundedImage
                    image={p['avatar_foto'] !== null ? p['avatar_foto'] : ''}
                    roundedColor="#8dd199"
                    roundedSize="4"
                    imageWidth="25"
                    imageHeight="25"/> 
                    <p style={{display: 'flex', margin: 0}}>
                        &nbsp;
                        {p.name}
                    </p>
            </div>;
            operadores_list.push(p);
        });

        callback(operadores_list)
    });
}
const storePermission = (permission, checkeds, onData) => {
    var url = ENDPOINT+'permissao/save';
    var formData = new FormData();

    formData.append('admin_id', permission.id);

    checkeds.forEach((p) => {
        formData.append(p.flag, Number(p.value));
    })

    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });
}
class Permissoes extends Component {
    constructor(props){
        super(props);
        this.state = {
            permission: null,
            listPermission: [],
            checkeds: [],
            operador: null,
            loading: false,
        }
        this.callbackList = this.callbackList.bind(this);
    }
    componentDidMount(){
        getPerm(this.callbackList);
        getOperadores(this.callbackOperadores.bind(this));
        this.loadJson();
    }
    

    callbackList(list){
        var json  = [];
        list.forEach((p) => {
            var json_current = {
                label: p.name,
                value: p.flag,
                ...p
            }
            json.push(json_current);
        });

        this.setState({listPermission: json })
    }

    loadJson(operador){
        permissoes.forEach((p) => {
            var checkeds = this.state.checkeds;
            var json = {
                value: false,
                flag: p.flag,
                label: p.title
            }
            checkeds.push(json);
            this.setState({checkeds})
        });
    }

    loadJsonOperador(operador){
        var checkeds = [];
        permissoes.forEach((p) => {
            var json = {
                value: Boolean(operador[p.flag]),
                flag: p.flag,
                label: p.title
            }
            checkeds.push(json);
            this.setState({checkeds})
        });
    }

    onChecked(id, value){
        var checkeds = this.state.checkeds;
        checkeds[id].value = value;
        this.setState({checkeds})
    }

    onSelect = (permission) => {
        var checkeds = this.state.checkeds;
        checkeds.forEach((check, index) => {
            checkeds[index].value = permission[check.flag];
        })
        this.setState({permission, checkeds})
    }
    loadCheckeds(permission){
        var checkeds = this.state.checkeds;
        checkeds.forEach((check, index) => {
            checkeds[index].value = Number(permission[check.flag]);
        });

        this.setState({checkeds})
    }

    onSubmit(){
        this.setState({loading: true})
        storePermission(this.state.operador, this.state.checkeds, this.onDataToast.bind(this))
    }

    onDataToast(data) {
        if (data.success === true) {
            this.setState({loading: false})
            toast.success(data.message);
            getOperadores(this.callbackOperadores.bind(this));
        }
        else {
            this.setState({loading: false})
            toast.error(data.errors);
        }
    }
    callbackOperadores = (operadores_list) => {
        this.setState({operadores_list});
    }
    render(){
        return(
            <div>
                <h3>Permissões</h3>
                <hr />
                <Card>
                    <CardBody>
                        <Form>
                            <FormGroup>
                                <Label>Selecione a Permissão:</Label>
                                <Select
                                    placeholder="Operador"
                                    isSearchable={false}
                                    options={this.state.operadores_list}
                                    onChange={(operador) => {
                                        this.loadJsonOperador(operador)
                                        this.setState({operador})
                                    }}
                                />
                            </FormGroup>
                        </Form>
                        <Row hidden={this.state.operador === null}>
                            {this.state.checkeds.map((p, index) => {
                                return <Col md={4} key={index}>
                                            <input 
                                                type="checkbox" 
                                                disabled={this.state.loading}
                                                checked={p.value} 
                                                onChange={() => this.onChecked(index, !p.value)}/> 
                                                &nbsp;{p.label} 
                                            <br/>
                                        </Col>
                            })}
                        </Row>
                        <div style={{textAlign: 'center'}} hidden={!this.state.loading}>
                            <br/>
                            <PropagateLoader color={'#52c565'}/>
                        </div>
                        <div style={{textAlign: 'right'}} hidden={this.state.operador === null}>
                            <ButtonDefault disabled={this.state.loading} onClick={this.onSubmit.bind(this)}>Salvar</ButtonDefault>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }
}
export default Permissoes;