import { Component } from "react";
import { Button, Card, CardBody, Col, Collapse, Input, Row } from "reactstrap";
import { getUser } from "../../auth/set_services";
import { getRowsPerPage, getRows } from "../../components/Tables/TableQueroBus/propsCupom";
import TableCupom from "../../components/Tables/TableQueroBus/TableCupom";
import PropagateLoader from "react-spinners/PropagateLoader";
import moment from "moment";
import { FaPlus, FaSave } from "react-icons/fa";
import Select from "react-select";
import { ENDPOINT } from "../../auth/endpoint";
import axios from "axios";
import { getPermissoes, getToken } from "../../auth/set_services";
import { toast, useToastContainer } from 'react-toastify';
import { MdArrowBack } from "react-icons/md";

const statusOptions = [
    { value: '0', label: 'Desativado'},
    { value: '1', label: 'Ativado'},
];

const typeOptions = [
    { value: 'p', label: 'Porcentagem'},
    { value: 'f', label: 'Fixo'},
];


const limiteOptions = [
    { value: '1', label: 'Sim'},
    { value: '0', label: 'Não'},
];

const getId = () => {
    return window.location.pathname.replace('/querobus-cupom/', '')
}

class CupomQueroBus extends Component
{
    constructor(props){
        super(props);
        this.state = {
            show_activity: false,
            show_filter: false,
            user_change: null,
            search: '',
            seller_id: '',
            valor_de: '0.00',
            valor_ate: '0.00',
            loading_table: true,
            edit_id: -1,
            edit_user: null,
            new_cupom: false,
            rowData: [],
            cupom_id: null,
            cupom_code: null,
            cupom_limit_total: null,
            cupom_status: { value: '1', label: 'Ativado'},
            cupom_type: { value: 'p', label: 'Porcentagem'},
            cupom_limit: { value: '0', label: 'Não'},
            cupom_value: null,
            cpf_current: null,
            cnpj_current: null,
            phone_current: null,
            status_user: '',
            type_user: '',
            type: { value: 'p', label: 'Porcentagem'},
            limit: { value: '0', label: 'Não'},
            status: null,
            totalPage: 0,
            activePage: 1,
            is_cnpj: false,
            document: '',
            filter: false,
            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            visible_taggy_add: false,
            visible_user: false
        }
    }
    
    componentDidMount(){
        this.handleLoadDataCupom()
    }

    handleLoadDataCupom() {
        var url = ENDPOINT + "querobus/cupom/get/" + getId();

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            console.log(response)

            let cupom = response?.data?.cupom;
            
            this.setState({
                cupom_id: cupom?.id,
                cupom_code: cupom?.code,
                cupom_limit_total: cupom?.limit_total,
                cupom_value: cupom?.value,
            })

            statusOptions.map((field, i)=>{
                if (field.value == cupom?.status) {
                    this.setState({cupom_status: field})
                }
            })

            typeOptions.map((field, i)=>{
                if (field.value == cupom?.type) {
                    this.setState({cupom_type: field})
                }
            })

            limiteOptions.map((field, i)=>{
                if (field.value == cupom?.limit) {
                    this.setState({cupom_limit: field})
                }
            })
        })
        .catch(function (error) {
            //this.handletoastMessage('Ocorreu um erro no servidor!');

            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              //console.log(error.response.data);
              //console.log(error.response.status); // status code
              //console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              //console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              //console.log('Error', error.message);
            }
            // console.log(error.config);
        })
    }

    handletoastSuccessMessage = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    handletoastMessage = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    handleSaveCupom = () => {
        var url = ENDPOINT + "querobus/cupom";

        let formData = new FormData();

        if (this.state.cupom_id) formData.append('cupom_id', this.state.cupom_id);

        formData.append('code', this.state.cupom_code);
        formData.append('status', this.state.cupom_status.value);
        formData.append('type', this.state.cupom_type.value);
        formData.append('value', this.state.cupom_value);
        formData.append('limit', this.state.cupom_limit.value);
        formData.append('limit_total', this.state.cupom_limit_total);


        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }})
        .then((response) => {
            console.log(response)
            
            if (this.state.cupom_id) {
                this.handletoastSuccessMessage('Cupom atualizado com sucesso!')

            } else {
                this.handletoastSuccessMessage('Cupom cadastrado com sucesso!')
            }
        })
        .catch(function (error) {
            this.handletoastMessage('Ocorreu um erro no servidor!');

            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              //console.log(error.response.data);
              //console.log(error.response.status); // status code
              //console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              //console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              //console.log('Error', error.message);
            }
            // console.log(error.config);
        })
    }
    handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value)
        this.setState({ [name]: value });
    }    

    render(){
        return (
            <div>
                <h3><Button 
                        outline 
                        style={{border: 'none'}}
                        onClick={() => window.location.href = '/cupom-querobus'}
                    >
                        <MdArrowBack style={{padding: 0}}/>
                    </Button>&nbsp;&nbsp;Cupom MeuBus</h3>
                    <hr/>
                    
                    <Row style={{marginBottom: '1rem'}}>
                        <Col md={6}>
                            <h2>Dados do Cupom</h2>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '1rem'}}>
                        <Col md={6}>
                            <label htmlFor="cupom_code">Código</label>
                            <Input
                                name="cupom_code"
                                placeholder="Código do CUPOM"
                                value={this.state.cupom_code}
                                type="text"
                                onChange={this.handleInputChange} />
                        </Col>
                        <Col md={6}>
                            <label htmlFor="cupom_status">Status</label>
                            <Select
                                    name="cupom_status"
                                    placeholder="Selecione..."
                                    options={statusOptions}
                                    onChange={(select) => {
                                        this.setState({cupom_status: select});
                                    }}
                                    value={this.state.cupom_status}
                                        />
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '1rem'}}>
                        <Col md={6}>
                            <label htmlFor="cupom_type">Tipo</label>
                            <Select
                                name="cupom_type"
                                placeholder="Selecione..."
                                options={typeOptions}
                                onChange={(select) => {
                                    this.setState({cupom_type: select});
                                }}
                                value={this.state.cupom_type}
                                />
                        </Col>
                        <Col md={6}>
                            <label htmlFor="cupom_value">Procentagem / Valor de desconto</label>
                            <Input
                                name="cupom_value"
                                placeholder=""
                                value={this.state.cupom_value}
                                type="text"
                                onChange={this.handleInputChange} />
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '1rem'}}>
                        <Col md={6}>
                            <label htmlFor="cupom_limit">Limite de uso</label>
                            <Select
                                name="cupom_limit"
                                placeholder="Selecione..."
                                options={limiteOptions}
                                onChange={(select) => {
                                    this.setState({cupom_limit: select})
                                }}
                                value={this.state.cupom_limit}
                                />
                        </Col>
                        <Col md={6}>
                            <label htmlFor="cupom_limit_total">Quantidade Total</label>
                            <Input
                                name="cupom_limit_total"
                                placeholder="Quantidade"
                                value={this.state.cupom_limit_total}
                                type="text"
                                onChange={this.handleInputChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                        </Col> 
                        <Col md="6" style={{textAlign: 'right'}}>
                            <Button 
                                className="btn btn-success btn-save" 
                                style={{"margin-bottom": "20px"}}
                                onClick={() => this.handleSaveCupom()}
                                >
                                    <FaSave /> Salvar Cupom
                            </Button>
                        </Col> 
                    </Row>

            </div>
        )
    }

}

export default CupomQueroBus;