import { useState } from "react";
import Cookies from "js-cookie";
import {
  Button,
  Col,
  Modal,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormGroup,
} from "reactstrap";

import { InputText } from "../../InputText/InputText";
import { getUser } from "../../../auth/set_services";
import { useApiAqmed } from "../../../hooks/useApi";

export function ModalCustomized({ isOpen, size, onClose, config = null }) {
  const [contract, setContract] = useState("");
  const [operator, setOperator] = useState("");
  const [identification, setIdentification] = useState("");
  const [description, setDescription] = useState("");
  const [isValid, setIsValid] = useState({
    contract: true,
    id: true,
    operator: true,
    status: true,
  });

  const { apiAqmed } = useApiAqmed();

  const serviceId = config?.userId;
  const userCrmId = getUser()?.id;

  const handleSubmit = async () => {
    const valid = {
      contract: true,
      id: true,
      operator: true,
      status: true,
    };
    if (!contract) valid.contract = false;
    if (!identification) valid.id = false;
    if (!operator) valid.operator = false;
    valid.status = valid.contract && valid.id && valid.operator;

    setIsValid(valid);

    if (!valid.status) return;
    const formData = {
      user_public_id: serviceId,
      contract_number: contract,
      operation_name: operator,
      description: description,
      created_by_user_crm_id: userCrmId,
    };
    if (config.service.toLowerCase() === "totalpass") {
      formData.totalpass_identification = identification;
    }
    if (config.service.toLowerCase() === "uniodonto") {
      formData.uniodonto_identification = identification;
    }

    const token = Cookies.get("aqmedtoken");

    apiAqmed
      .post(
        `https://apiaqmed.aqbank.com.br/api/v1/crm/${config?.service.toLowerCase()}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      )
      .then((send) => {
        if (send.status === 200) {
          alert("Contrato criado com sucesso");
          onClose();
          window.location.reload();
        } else {
          alert("Erro ao cadastrar contrato");
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Erro ao cadastrar contrato");
      });
  };

  return (
    <Modal isOpen={isOpen} size={size}>
      <ModalHeader>Contrato {config?.service}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Row>
            <Col md={10} sm={10} xs={10}>
              <h6 style={{ marginTop: "10px" }}>Número do Contrato</h6>
              <InputText
                name="contract"
                invalid={!isValid?.contract}
                value={contract}
                onChange={(event) => setContract(event.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={10} sm={10} xs={10}>
              <h6 style={{ marginTop: "10px" }}>Nome do Operador</h6>
              <InputText
                name="operator"
                invalid={!isValid?.operator}
                value={operator}
                onChange={(event) => setOperator(event.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={10} sm={10} xs={10}>
              <h6 style={{ marginTop: "10px" }}>{config?.service} ID</h6>
              <InputText
                name="identification"
                invalid={!isValid?.id}
                value={identification}
                onChange={(event) => setIdentification(event.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={10} sm={10} xs={10}>
              <h6 style={{ marginTop: "10px" }}>Descrição</h6>
              <InputText
                name="description"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </Col>
          </Row>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onClose}>
          Cancelar
        </Button>
        <Button color="success" onClick={handleSubmit}>
          Cadastrar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
