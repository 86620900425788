import React, {Component} from 'react';
import { Button, Card, CardBody } from "reactstrap";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Moment from 'moment';
import { getRows, getRowsPerPage } from '../../components/Tables/TableGiftCard/propsGiftCard';
import PropagateLoader from "react-spinners/PropagateLoader";
import { getPermissoes, getUser } from '../../auth/set_services';
import { TableGiftCard } from '../../components/Tables/TableGiftCard/TableGiftCard';
import CreateGiftCard from '../../components/Modals/GiftCard/CreateGiftCard';


const getSerialNumber = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const serial_number = urlParams.get('serial_number');
    if(serial_number !== null){
        return serial_number;
    }
    else{
        return null;
    }
}

const getStatus = (status) => {
    if(status === 1){
        return "Transação inexistente"
    }
    else if(status === 2){
        return "Passagem Duplicada"
    }
    else{
        return "Cobrança de valor incorreto"
    }
}

class GiftCard extends Component{
    constructor(props){
        super(props);
        this.state = {
            rowData: [],
            activePage: 1,
            taggy_serial: getSerialNumber(),
            totalPage: 1,
            filter: false,
            search: '',
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            loading_table: true,
            visible_tag: false,
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentDidMount(){
        getRows(this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({rowData, activePage, totalPage, loading_table: false})
    }

    onFilter = (filter) => {
        this.setState({filter});
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }

    onClose = (visible_tag = false) => {
        this.setState({visible_tag})
    }
    render(){
        return(
            <div>
                <h3>Gift Card</h3>
                <hr/>
                <Button 
                    hidden={!getUser().permissao_gestor_aqpasso} 
                    onClick={() => this.onClose(true)}
                    style={{
                        marginBottom: '12px'
                    }}
                    color="success">Novo GIFTCARD</Button>
                <Card hidden={!getUser().permissao_gestor_aqpasso}>
                    <TableGiftCard
                        isSearch={this.handleChange}
                        data={this.state.rowData}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhum GiftCard encontrado
                            </div></CardBody></Card>
                        }
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <Card hidden={getUser().permissao_gestor_aqpasso}>
                    <CardBody>
                        <p>Você não tem permissões para acessar essa função</p>
                    </CardBody>
                </Card>
                <CreateGiftCard
                    visible={this.state.visible_tag}
                    onRefresh={() => this.onPageChange(this.state.activePage)}
                    onClose={() => this.onClose(false)} />
            </div>
        )
    }
}

export default GiftCard;