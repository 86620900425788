import { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { getUser } from "../../auth/set_services";
import PropagateLoader from "react-spinners/PropagateLoader";
import TableMultibeneficios from "../../components/Tables/TableMultibeneficios/TableMultibeneficios";
import { getRowsPerPage, getRows } from "../../components/Tables/TableMultibeneficios/propsMultibeneficios";

class Multibeneficio extends Component
{
    constructor(props){
        super(props);
        this.state = {
            show_activity: false,
            show_filter: false,
            user_change: null,
            search: '',
            seller_id: '',
            loading_table: true,
            edit_id: -1,
            edit_user: null,
            rowData: [],
            cpf_current: null,
            cnpj_current: null,
            phone_current: null,
            status_user: '',
            type_user: '',
            totalPage: 0,
            total: 0,
            activePage: 1,
            status: null,
            is_cnpj: false,
            document: '',
            visible_taggy_add: false,
            visible_user: false
        }
    }
    
    componentDidMount(){
        getRows(this.state, this.callbackRows.bind(this));
    }

    callbackRows = (rowData, totalPage, total) => {
        this.setState({rowData, totalPage, total: total, loading_table: false})
    }

    onPageChange = (activePage) => {
        var state = this.state;

        state.activePage = activePage;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});

        getRowsPerPage(activePage, state, this.callbackRows.bind(this));
    }

    handleChange = (event) => {
        var state = this.state;
        let name = event.target.name;
        let value = event.target.value;

        /* if(name === 'document'){
            if(value.replace(/([^\d])+/gim, '').length > 10){
                state[name] = value;
                state.activePage = 1;
                state.rowData = [];
                state.loading_table = true;
                this.setState({...state});
                
                getRowsPerPage(1, state, this.callbackRows);
            }
            else if(value.replace(/([^\d])+/gim, '').length === 0){
                if(state[name].replace(/([^\d])+/gim, '').length > 0){
                    state[name] = value;
                    state.activePage = 1;
                    state.rowData = [];
                    state.loading_table = true;
                    this.setState({...state});
                    
                    getRowsPerPage(1, state, this.callbackRows);
                }
            }
        }
        else{ */
            state[name] = value;
            state.activePage = 1;
            state.rowData = [];
            state.loading_table = true;
            this.setState({...state});
            
            getRowsPerPage(1, state, this.callbackRows);
        //}
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    onStatusChange(status_user){
        var state = this.state;
        state.status_user = status_user
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});
        
        getRows(state, this.callbackRows);
    }
    filterShow(){
        this.setState({
            show_filter: !this.state.show_filter
        })
    }
    handleTypePaymentSelectChange = forma_pagamento => {
        var state = this.state;
        state['forma_pagamento'] = forma_pagamento;
        state.loading_table = true;
        this.setState({ ...state });
        getRows(state, this.callbackRows);
    };

    render(){
        return (
            <div>
                <h3>Lista de Espera Multibeneficio</h3>
                <h5>Total da lista {this.state?.total}</h5>
                <hr/>
                <Card hidden={!getUser().permissao_gestor_aqpago}>
                    <TableMultibeneficios
                        isSearch={this.handleChange}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        data={this.state.rowData}
                        handleTypePaymentSelectChange={this.handleTypePaymentSelectChange}
                        is_cnpj={this.state.is_cnpj}
                        status_user={this.state.status_user}
                        onStatusChange={this.onStatusChange.bind(this)}
                        type_user={this.state.type_user}
                        show_filter={this.state.show_filter}
                        filterShow={this.filterShow.bind(this)}
                        onPageChange={this.onPageChange}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        state={this.state}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhum usuário encontrado
                            </div></CardBody></Card>
                        }
                    />
                </Card>
            </div>
        )
    }

}

export default Multibeneficio;