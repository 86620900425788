import axios from 'axios';
import moment from 'moment';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { HiViewGrid } from 'react-icons/hi';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import { ENDPOINT } from '../../../../auth/endpoint';
import { getToken } from '../../../../auth/set_services';
import { fetchWithTimeout } from '../../../Tools/fetchWithTimeout';



// name="tipo_pagamento"
// placeholder="Selecione..."
// options={this.state.tipo_pagamento_options}
// onChange={(tipo_pagamento_select) => {
//     this.setState({tipo_pagamento_select})
//     this.setState({tipo_pagamento: tipo_pagamento_select.value})
// }}
// value={this.state.tipo_pagamento_select}

export const columns = (props, editFormaPagamento, removeFormaPagamento) => [
    {
      name: "",
      selector: "buttons",
      sortable: false,
      width: '10%',
      cell: row => <Row>
        <Button
          outline
          style={{ padding: 2, marginLeft: 15, border: 0, fontWeight: '700'}}
          color="dark" onClick={() => { 
            editFormaPagamento(row)
          }}>
          <FaEdit style={{ padding: '2px' }}/>
        </Button>
      </Row>
    },
    {
      name: "Tipo",
      selector: "tipo_pagamento",
      sortable: false,
      width: '30%',
      cell: row => <Row>
        {props.tipo_pagamento_options.map((tipo, i) => {
            if (tipo.value == row.tipo_pagamento) {
                return (
                    <div>{tipo.label}</div>
                )
            }
        })}
      </Row>
    },
    {
      name: "Aplicável",
      selector: "aplicavel",
      sortable: false,
      width: '20%',
      cell: row => <Row>
        {props.aplicavel_options.map((aplicavel, i) => {
            if (aplicavel.value == row.aplicavel) {
                return (
                    <div>{aplicavel.label}</div>
                )
            }
        })}
      </Row>
    },
    {
      name: "Prazo",
      selector: "prazo_recebimento",
      sortable: false,
      width: '15%',
    },
    {
        name: "Taxa",
        selector: "tipo_taxa",
        sortable: false,
        width: '15%',
        cell: row => <Row>
            {row.tipo_taxa == 'aliquota' ? 
                <Col md={12}>
                    <p><strong>Al:</strong> {row.taxa_aliquota}%</p>
                </Col>
            :
                <></>
            }
            {row.tipo_taxa == 'fixa' ? 
                <Col md={12}>
                    <p><strong>Fx:</strong> {parseFloat(row.taxa_fixa).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</p>
                </Col>
            :
                <></>
            }
            {row.tipo_taxa == 'aliquota_fixa' ? 
                <Col md={12}>
                    <p><strong>Al:</strong> {row.taxa_aliquota}%</p>
                    <p><strong>Fx:</strong> {parseFloat(row.taxa_fixa).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</p>
                </Col>
            :
                <></>
            }
      </Row>
      },
      {
        name: "Remover",
        selector: "remove",
        sortable: false,
        width: '10%',
        cell: row => <Row>
          <Button
            outline
            className='btn-remove'
            style={{ padding: 2, marginLeft: 15, border: 0, fontWeight: '700'}}
            color="dark" onClick={() => { 
              removeFormaPagamento(row)
            }}>
            <FaTrash style={{ padding: '2px' }}/>
          </Button>
        </Row>
      },
];

var numberOfAjaxCAllPending = 0;

export const getRows = (state = null, callback) => {
    if(numberOfAjaxCAllPending == 0){
      numberOfAjaxCAllPending = 1;
      var url = ENDPOINT + "financeiro/formapagamento?page=" + state.activePage + "&search=" + state.search;
      //var url = "http://localhost:8000/api/financeiro/formapagamento?page=" + state.activePage + "&search=" + state.search;


      if(state.tipo_pagamento !== undefined && state.tipo_pagamento !== null && state.tipo_pagamento.value !== undefined && state.tipo_pagamento.value !== null){
        url += "&tipo_pagamento="+state.tipo_pagamento.value;
      }

      if(state.aplicavel !== undefined && state.aplicavel !== null && state.aplicavel.value !== undefined && state.aplicavel.value !== null){
        url += "&aplicavel="+state.aplicavel.value;
      } 
      if(state.tipo_taxa !== undefined && state.tipo_taxa !== null && state.tipo_taxa.value !== undefined && state.tipo_taxa.value !== null){
        url += "&tipo_taxa="+state.tipo_taxa.value;
      } 
      
      axios.get(url, {
          headers: {
              Authorization: 'Bearer '+getToken()
          },
          mode: 'cors',
          cache: 'default'
      })
      .then((response) => {

          let p = response.data;
          if(p.success == true){
            var meta = 0;
            if(p.meta){
              meta = p.meta.last_page;
            }
            numberOfAjaxCAllPending = 0;
            // callback(p.data, meta, p.valor);
            callback(p.data, meta);
          }
          else{
            toast.error(p.errors);
          }
      })
      .catch((error) => {
          toast.error('Ocorreu um erro no servidor!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          console.log(error)
          if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status); // status code
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              //console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            // console.log(error.config);

        callback({}, {});
      })
    }
    else{
      //callback(state.rowData, state.totalPage, []);
    }
  }
  
  export const getRowsPerPage = (page, state, callback) => {
    if(numberOfAjaxCAllPending == 0){
      numberOfAjaxCAllPending = 1;

      var url = ENDPOINT + "financeiro/formapagamento?page=" + state.activePage + "&search=" + state.search;
      //var url = "http://127.0.0.1:8000/api/financeiro/formapagamento?page=" + state.activePage + "&search=" + state.search;

      if(state.tipo_pagamento !== undefined && state.tipo_pagamento !== null && state.tipo_pagamento.value !== undefined && state.tipo_pagamento.value !== null){
        url += "&tipo_pagamento="+state.tipo_pagamento.value;
      }

      if(state.aplicavel !== undefined && state.aplicavel !== null && state.aplicavel.value !== undefined && state.aplicavel.value !== null){
        url += "&aplicavel="+state.aplicavel.value;
      } 
      if(state.tipo_taxa !== undefined && state.tipo_taxa !== null && state.tipo_taxa.value !== undefined && state.tipo_taxa.value !== null){
        url += "&tipo_taxa="+state.tipo_taxa.value;
      } 

      var myHeaders = new Headers();
    
      myHeaders.append('Authorization', 'Bearer ' + getToken());
      
      var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
      };

      fetchWithTimeout(20000, fetch(url, myInit))
      .then(response => response.json())
      .then((p) => {
        if(p.success == true){
          var meta = 0;
          if(p.meta){
            meta = p.meta.last_page;
          }
          numberOfAjaxCAllPending = 0;
          // callback(p.data, meta, p.valor);
          callback(p.data, meta);
        }
        else{
          toast.error(p.errors);
        }
      })
      .catch((error) => {
        console.log(error)

        toast.error('Ocorreu um erro no servidor!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        
        callback({}, {});
      })
    }
    else{
      //callback(state.rowData, state.totalPage, []);
    }
  }