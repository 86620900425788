import Moment from 'moment';
import { Col, Row } from 'reactstrap';


export const columns = (onMessage) => [
    {
        name: "Descrição",
        selector: "descricao",
        sortable: false,
        cell: row => {
            return <Row style={{padding: '1rem'}}>
                <Col md={12}>
                    <div dangerouslySetInnerHTML={{ __html: row.descricao }} />
                </Col>
            </Row>
        }
    },
    {
        name: "Protocolo de Atendimento (Chat)",
        selector: "protocolo",
        sortable: false,
        width: '15%',
        cell: row => row.chat !== null ? row.chat.protocolo_atendimento : (row.protocolo_atendimento !== null ? row.protocolo_atendimento : 'Sem informações')
    },
    {
        name: 'Data de criação da nota',
        selector: 'created_at',
        sortable: false,
        width: '15%',
        cell: row =>  Moment(new Date(row.created_at)).format('DD/MM/YYYY HH:mm')
    },
  ];
