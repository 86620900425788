import { useContext, useEffect, useState } from "react";
import { MdAdd, MdOutlineAddCircle, MdWarning } from "react-icons/md";
import { FaCar, FaCarSide } from "react-icons/fa";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { toast } from 'react-toastify';
import { ENDPOINT, ENDPOINT_AQPASSO } from "../../../auth/endpoint";
import { InputMasked } from "../../InputText/InputMasked";
import axios from "axios";
import { getToken } from "../../../auth/set_services";



const getPlaca = (placa, callback, onError) => {
    var url = ENDPOINT_AQPASSO+'consultar_placa/'+placa;

    axios.get(url)
    .then((response) => {
        var data = response.data;
        callback(data.body);
    })
    .catch(error => {
        var data = error.response;
        toast.error(data.data.error);
        onError();
        return false;
    })
}

const getMarcas = (tipo_veiculo, callback) => {
    var url = ENDPOINT_AQPASSO+'fipe/marcas/'+tipo_veiculo;

    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        callback(data);
    })
    .catch(error => {
        var data = error.response;
        toast.error(data.data.error);
        return false;
    })
}


const getModelos = (tipo_veiculo, codigo_marca, callback) => {
    var url = ENDPOINT_AQPASSO+'fipe/modelos/'+tipo_veiculo+'?codigo_marca='+codigo_marca;

    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        callback(data);
    })
    .catch(error => {
        var data = error.response;
        toast.error(data.data.error);
        return false;
    })
}
const alterarVeiculo = (formData, callback, callbackError) => {
    var url = ENDPOINT+'taggy/alterar_veiculo';

    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data.body;
        callback(data);
        callbackError();
    })
    .catch(error => {
        var data = error.response;
        toast.error(data.data.error);
        callbackError();
        return false;
    })
}

const getCategoriaVeiculos = (callback) => {
    var url = ENDPOINT_AQPASSO+'consultas_publicas/categoria-veiculos';

    axios.get(url)
    .then((response) => {
        var data = response.data.body;
        callback(data);
    })
    .catch(error => {
        var data = error.response;
        toast.error(data.error);
        return false;
    })
}


function verificaRenavam(renavam) {

    if (Number.isInteger(renavam)) {
        renavam = `${renavam}`;
    }
    var length = 11;
    var mod11 = 0;
    var ultimoDigitoCalculado = 0;
    if (renavam.match("^([0-9]{9})$")) {
        renavam = "00" + renavam;
    }

    if (renavam.match("^([0-9]{10})$")) {
        renavam = "0" + renavam;
    }

    if (!renavam.match("[0-9]{11}")) {
        return false;
    }

    var renavamSemDigito = renavam.substring(0, 10);

    var renavamReversoSemDigito = renavamSemDigito.split("").reverse().join("");

    var soma = 0;

    for (var i = 0; i < 8; i++) {
        var algarismo = parseInt(renavamReversoSemDigito.substring(i, i + 1));
        var multiplicador = i + 2;
        soma += algarismo * multiplicador;
    }
    soma += renavamReversoSemDigito[8] * 2;
    soma += renavamReversoSemDigito[9] * 3;
    mod11 = (soma % length);
    ultimoDigitoCalculado = (length - mod11);
    ultimoDigitoCalculado = (ultimoDigitoCalculado >= 10 ? 0 : ultimoDigitoCalculado);
    var digitoRealInformado = parseInt(renavam.substring(renavam.length - 1, renavam.length));
    if (ultimoDigitoCalculado == digitoRealInformado) {
        return true;
    }
    return false;
}

const TrocaVeiculoModal = ({ onClose, onRegister, isOpen, user_id, serial, tag_id, onRequest }) => {
    const [veiculo_input, setVeiculoInput] = useState({
        taggy_ativar: '',
        placa: '',
        placa_invalid: false,
        renavam: '',
        renavam_invalid: false,
        tipo_modelo: '',
        tipo_modelo_invalid: false,
        eixos: '',
        eixos_invalid: false,
        producao_ano: '',
        producao_ano_invalid: false,
        modelo: '',
        modelo_id: '',
        modelo_invalid: false,
        modelo_ano: '',
        modelo_ano_invalid: false,
        categoria_nome: '',
        categoria_nome_invalid: false,
        blindado: '',
        blindado_invalid: false,
        categoria_codigo: '',
        categoria_codigo_invalid: '',
        veiculo_fabricante: '',
        veiculo_fabricante_id: '',
        veiculo_fabricante_invalid: false,
        marcas_list: [],
        modelos_list: []
    });
    const [veiculo_consultado, setVeiculoConsultado] = useState(null);
    const [categoria_veiculo, setCategoriaVeiculo] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getCategoriaVeiculos(setCategoriaVeiculo);
    }, []);


    const validarCampos = () => {
        var invalids = 0;
        var vehicle = veiculo_input;

        if(vehicle.renavam != ''){
            if (verificaRenavam(vehicle.renavam) == true) {
                vehicle.renavam_invalid = false;
            }
            else {
                invalids++;
                vehicle.renavam_invalid = true;
            }
        }

        if (veiculo_consultado == null) {
            if (vehicle.placa.length > 6) {
                vehicle.placa_invalid = false;
            }
            else {
                invalids++;
                vehicle.placa_invalid = true;
            }

            if (vehicle.producao_ano.replace(/([^\d])+/gim, '').length > 3) {
                vehicle.producao_ano_invalid = false;
            }
            else {
                invalids++;
                vehicle.producao_ano_invalid = true;
            }

            if (vehicle.modelo_ano.replace(/([^\d])+/gim, '').length > 3) {
                vehicle.modelo_ano_invalid = false;
            }
            else {
                invalids++;
                vehicle.modelo_ano_invalid = true;
            }

            if (vehicle.veiculo_fabricante.length > 0) {
                vehicle.veiculo_fabricante_invalid = false;
            }
            else {
                invalids++;
                vehicle.veiculo_fabricante_invalid = true;
            }

            if (vehicle.modelo.length > 0) {
                vehicle.modelo_invalid = false;
            }
            else {
                invalids++;
                vehicle.modelo_invalid = true;
            }
        }

        if (vehicle.categoria_codigo.length > 0) {
            vehicle.categoria_codigo_invalid = false;
        }
        else {
            invalids++;
            vehicle.categoria_codigo_invalid = true;
        }

        setVeiculoInput({ ...veiculo_input, vehicle });

        if (invalids > 0) {
            return false;
        }
        return true;
    }

    const callbackMarcas = (data) => {
        const veiculo_ = veiculo_input;
        veiculo_.marcas_list = data;
        setVeiculoInput({ ...veiculo_input, veiculo_ });
    }
    const callbackModelos = (data) => {
        const veiculo_ = veiculo_input;
        veiculo_.modelos_list = data.Modelos;
        setVeiculoInput({ ...veiculo_input, veiculo_ });
    }

    const handleVehicle = (e) => {
        const { name, value } = e.target;
        if (name == 'veiculo_fabricante_id') {
            const { value } = e.target;
            const veiculo_ = veiculo_input;
            veiculo_.veiculo_fabricante_id = value;
            veiculo_.veiculo_fabricante = e.target.selectedOptions[0].attributes.label.value;
            veiculo_.modelo = '';
            veiculo_.modelo_id = '';
            getModelos(veiculo_.tipo_modelo, value, callbackModelos);
            setVeiculoInput({ ...veiculo_input, veiculo_ });
        }
        else if (name == 'modelo_id') {
            const { value } = e.target;
            const veiculo_ = veiculo_input;
            veiculo_.modelo_id = value;
            veiculo_.modelo = e.target.selectedOptions[0].attributes.label.value;
            setVeiculoInput({ ...veiculo_input, veiculo_ });
        }
        else if (name == 'tipo_modelo') {
            const { value } = e.target;
            const veiculo_ = veiculo_input;
            veiculo_.tipo_modelo = value;
            veiculo_.modelo = '';
            veiculo_.modelo_id = '';
            veiculo_.veiculo_fabricante = '';
            veiculo_.veiculo_fabricante_id = '';
            getMarcas(value, callbackMarcas);
            setVeiculoInput({ ...veiculo_input, veiculo_ });

        }
        else if (name == 'placa') {
            const { value } = e.target;

            function escapeRegExp(string) {
                return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
            }

            function replaceAll(str, find, replace) {
                return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
            }

            if (replaceAll(value, '_', '').length > 6) {
                setLoading(true);
                toast.info('Estamos consultando a placa de seu veículo, por favor aguarde');
                getPlaca(replaceAll(value, '_', ''), setarVeiculoConsultado, () => setLoading(false));
            }
            else {
                setVeiculoConsultado(null);
            }

            const veiculo_ = veiculo_input;
            veiculo_[name] = value.toUpperCase();
            setVeiculoInput({ ...veiculo_input, veiculo_ });
        }
        else {
            const veiculo_ = veiculo_input;
            veiculo_[name] = value;
            setVeiculoInput({ ...veiculo_input, veiculo_ });
        }
    }
    const setarVeiculoConsultado = (data) => {
        setLoading(false);
        var marcaModelo = data.marcaModelo;
        var marca = '';
        var modelo = '';
        if (marcaModelo.includes("/")) {
            var is_marca = marcaModelo.split('/');
            marca = is_marca[0];
            modelo = is_marca[1];
        }
        else {
            var is_marca = marcaModelo.split(' ');
            marca = is_marca[0];
            is_marca.forEach((e, i) => {
                if (i > 0) {
                    modelo = modelo + '' + e + ' ';
                }
            })
        }

        data.marca = marca;
        data.modelo = modelo;

        setVeiculoConsultado(data);
    }

    const handleVehicleCategory = (e) => {
        const { value } = e.target;
        const veiculo_ = veiculo_input;
        veiculo_.categoria_codigo = value;
        veiculo_.eixos = e.target.selectedOptions[0].attributes.eixos.value;
        veiculo_.categoria_nome = e.target.selectedOptions[0].attributes.categoria_nome.value;
        setVeiculoInput({ ...veiculo_input, veiculo_ });
    }

    const onCloseReq = (is_true = null) => {
        if (is_true != null) {
            onRequest();
        }
        setVeiculoInput({
            taggy_ativar: '',
            placa: '',
            placa_invalid: false,
            renavam: '',
            renavam_invalid: false,
            tipo_modelo: '',
            tipo_modelo_invalid: false,
            eixos: '',
            eixos_invalid: false,
            producao_ano: '',
            producao_ano_invalid: false,
            modelo: '',
            modelo_id: '',
            modelo_invalid: false,
            modelo_ano: '',
            modelo_ano_invalid: false,
            categoria_nome: '',
            categoria_nome_invalid: false,
            blindado: '',
            blindado_invalid: false,
            categoria_codigo: '',
            categoria_codigo_invalid: '',
            veiculo_fabricante: '',
            veiculo_fabricante_id: '',
            veiculo_fabricante_invalid: false,
            marcas_list: [],
            modelos_list: []
        });
        setLoading(false);
        onClose();
    }
    const storeTaggy = () => {
        if (validarCampos() == true) {
            setLoading(true);

            var formData = {
                veiculo: {},
                tag_id: tag_id,
                userId: user_id
            };

            if (veiculo_consultado !== null) {
                formData.veiculo = {
                    'renavam': veiculo_input.renavam,
                    'placa': veiculo_input.placa,
                    'modelo': veiculo_consultado.modelo,
                    'veiculo_fabricante': veiculo_consultado.marca,
                    'modelo_ano': veiculo_consultado.anoModelo,
                    'producao_ano': veiculo_consultado.anoFabricacao,
                    'eixos': veiculo_input.eixos,
                    'categoria_nome': veiculo_input.categoria_nome,
                    'categoria_codigo': veiculo_input.categoria_codigo,
                };
            }
            else {
                //formData.veiculo = veiculo_input;
                formData.veiculo = {
                    'renavam': veiculo_input.renavam,
                    'placa': veiculo_input.placa,
                    'modelo': veiculo_input.modelo_ano,
                    'veiculo_fabricante': veiculo_input.veiculo_fabricante,
                    'modelo_ano': veiculo_input.modelo_ano,
                    'producao_ano': veiculo_input.producao_ano,
                    'eixos': veiculo_input.eixos,
                    'categoria_nome': veiculo_input.categoria_nome,
                    'categoria_codigo': veiculo_input.categoria_codigo,
                };
            }

            alterarVeiculo(formData, onCloseReq, () => setLoading(false));
        }
    }

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader>Trocar Veículo</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label>Placa:</Label>
                        <InputMasked
                            placeholder='Placa'
                            type="text"
                            name="placa"
                            mask="***9*99"
                            value={veiculo_input.placa}
                            invalid={veiculo_input.placa_invalid}
                            disabled={loading}
                            onChange={handleVehicle}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Renavam:</Label>
                        <Input
                            placeholder='Renavam'
                            type="text"
                            name="renavam"
                            autocomplete={"new_renavam_" + Math.floor(Math.random() * 100)}
                            value={veiculo_input.renavam}
                            invalid={veiculo_input.renavam_invalid}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            onChange={handleVehicle}
                        />
                    </FormGroup>
                    {veiculo_consultado != null ?
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Fabricante:</Label>
                                    <Label>{veiculo_consultado.marca}</Label>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Ano de Fabricação:</Label>
                                    <Label>{veiculo_consultado.anoFabricacao}</Label>
                                </FormGroup>

                                <FormGroup>
                                    <Label>Categoria:</Label>
                                    <Input
                                        type="select"
                                        placeholder="Categoria"
                                        name="categoria_codigo"
                                        value={veiculo_input.categoria_codigo}
                                        invalid={veiculo_input.categoria_codigo_invalid}
                                        onChange={handleVehicleCategory}
                                    >
                                        <option
                                            value={''}
                                            eixos={''}
                                            categoria_nome={''}
                                        >Selecione a categoria do veículo</option>
                                        {categoria_veiculo.map(e => (
                                            <option
                                                value={e.code}
                                                eixos={e.axles}
                                                categoria_nome={e.description}
                                            >{e.description}</option>
                                        )).sort((a, b) => {
                                            return (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0);
                                        })}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Modelo:</Label>
                                    <Label>{veiculo_consultado.modelo}</Label>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Ano do Modelo:</Label>
                                    <Label>{veiculo_consultado.anoModelo}</Label>
                                </FormGroup>
                            </Col>
                        </Row>

                        :
                        <></>}
                    <div hidden={veiculo_consultado != null}>
                        <FormGroup>
                            <Label>Categoria:</Label>
                            <Input
                                type="select"
                                placeholder="Categoria"
                                name="tipo_modelo"
                                value={veiculo_input.tipo_modelo}
                                invalid={veiculo_input.tipo_modelo_invalid}
                                onChange={handleVehicle}
                            >
                                <option value={''}>Selecione o tipo de veículo</option>
                                <option value={1}>Carro</option>
                                <option value={2}>Moto</option>
                                <option value={3}>Caminhão/Ônibus</option>
                            </Input>
                        </FormGroup>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Fabricante:</Label>
                                    <Input
                                        type="select"
                                        placeholder="Fabricante"
                                        name="veiculo_fabricante_id"
                                        value={veiculo_input.veiculo_fabricante_id}
                                        invalid={veiculo_input.veiculo_fabricante_invalid}
                                        onChange={handleVehicle}
                                    >
                                        <option value={''}>Selecione o modelo de veículo</option>
                                        {veiculo_input.marcas_list.map(e => (
                                            <option value={e.Value} label={e.Label}>{e.Label}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Ano de Fabricação:</Label>
                                    <InputMasked
                                        mask="9999"
                                        placeholder='Ano de Fabricação'
                                        type="tel"
                                        name="producao_ano"
                                        value={veiculo_input.producao_ano}
                                        invalid={veiculo_input.producao_ano_invalid}
                                        onChange={handleVehicle}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Categoria:</Label>
                                    <Input
                                        type="select"
                                        placeholder="Categoria"
                                        name="categoria_codigo"
                                        value={veiculo_input.categoria_codigo}
                                        invalid={veiculo_input.categoria_codigo_invalid}
                                        onChange={handleVehicleCategory}
                                    >
                                        <option
                                            value={''}
                                            eixos={''}
                                            categoria_nome={''}
                                        >Selecione a categoria do veículo</option>
                                        {categoria_veiculo.map(e => (
                                            <option
                                                value={e.code}
                                                eixos={e.axles}
                                                categoria_nome={e.description}
                                            >{e.description}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Modelo:</Label>
                                    <Input
                                        type="select"
                                        placeholder="Modelo"
                                        name="modelo_id"
                                        value={veiculo_input.modelo_id}
                                        invalid={veiculo_input.modelo_invalid}
                                        onChange={handleVehicle}
                                    >
                                        <option value={''}>Selecione o modelo de veículo</option>
                                        {veiculo_input.modelos_list.map(e => (
                                            <option value={e.Value} label={e.Label}>{e.Label}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Ano do Modelo:</Label>
                                    <InputMasked
                                        mask="9999"
                                        placeholder='Ano do Modelo'
                                        type="tel"
                                        name="modelo_ano"
                                        value={veiculo_input.modelo_ano}
                                        invalid={veiculo_input.modelo_ano_invalid}
                                        onChange={handleVehicle}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={onCloseReq}>Cancelar</Button>
                <Button
                    color="success"
                    onClick={storeTaggy}
                    disabled={loading}
                >Cadastrar</Button>
            </ModalFooter>
        </Modal>
    )
}

export default TrocaVeiculoModal;