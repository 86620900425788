import React, { Component } from "react";
import { Button, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import ModelReturn from "../ReturnsApiModals/ModelReturn";
import NumberFormat from "react-number-format";
import { register, updateTaggy } from "../../../services/request_taggys";
import { InputText } from '../../InputText/InputText';

class TaggyAdd extends Component {
    constructor(props){
        super(props);
        this.state = {
            user_id: null,
            oldTaggy: '',
            serial: '',
            serial_invalid: false,
            chave_decimal: '',
            chave_decimal_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        }
        this.onRegister = this.onRegister.bind(this);
        this.onClose = this.onClose.bind(this);
        this.loadTaggy = this.loadTaggy.bind(this);
    }
    componentWillUpdate(nextProps, nextState){
        if(nextProps.editing !== null && nextProps.editing !== this.props.editing){
            this.loadTaggy(nextProps.editing);
        }
    }

    loadTaggy(taggy){
        this.setState({
            user_id: taggy.conta === null ? -1 : taggy.conta.users.id,
            oldTaggy: taggy.serial,
            serial: taggy.serial,
            chave_decimal: taggy.chave_decimal,
        })
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }
    validateFields(){
        var invalid = 0;

        if(this.state.serial.length > 15){
            this.setState({
                serial_invalid: false
            })
        }
        else{
            invalid++;
            this.setState({
                serial_invalid: true
            })
        }

        return invalid;

    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    onRegister() {
        if(this.validateFields() === 0){
            console.log(this.state.user_id);
            this.setState({loading: true});
             if(this.props.editing !== null){
                updateTaggy(this.state, this.onData.bind(this));
            }
            else{
                register(this.state, this.onData.bind(this));
            }
        }
    }
    onData(data){
        
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onClose(){
        this.setState({
            user_id: null,
            oldTaggy: '',
            serial: '',
            serial_invalid: false,
            chave_decimal: '',
            chave_decimal_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose(false);
    }
    functionName = () => {
        if(this.props.editing !== null){
            if(this.state.user_id > 0){
                return "Substituir Taggy"
            }
            else{
                return "Alterar Taggy"
            }
        }
        else{
            return "Adicionar Taggy"
        }
    }
    render(){
        return(
            <Modal isOpen={this.props.visible}>
                <ModalHeader>
                    {this.functionName()}
                </ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <Form>
                        <FormGroup>
                            <NumberFormat
                                customInput={InputText}
                                name="serial"
                                value={this.state.serial}
                                type="text"
                                placeholder="Numero de Serial"
                                invalid={this.state.serial_invalid}
                                onChange={this.handleChange}
                                format="#### #### #### ####" 
                            />
                        </FormGroup>
                        {/* <FormGroup>
                            <NumberFormat 
                                customInput={InputText}
                                format="##########" 
                                name="chave_decimal"
                                value={this.state.chave_decimal}
                                type="text"
                                placeholder="Numero de OBUID"
                                invalid={this.state.chave_decimal_invalid}
                                onChange={this.handleChange}
                            />
                        </FormGroup> */}
                    </Form>
                </ModalBody>
                <ModalBody 
                    style={{
                        minHeight: '114px', 
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn 
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                        />
                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose(false)}>Cancelar</Button>
                    <Button color="success" onClick={this.onRegister}>Cadastrar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
export default TaggyAdd;