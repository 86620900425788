import axios from "axios";
import React, { Component } from "react";
import { MdAdd } from "react-icons/md";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardFooter, Col, Collapse, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { ENDPOINT } from "../../auth/endpoint";
import { getPermissoes, getToken, getUser } from "../../auth/set_services";
import ModalDialog from "../../components/Modals/ReturnsApiModals/ModalDialog";

const getDepartamentos = (callback) => {
    var url = ENDPOINT + "departamento/list";
    
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        var operadores_list = [];
        data.forEach((p, i) => {
            p['value'] = p.flag;
            p['label'] = p.nome;
            operadores_list.push(p);
        });

        callback(operadores_list)
    });
}

const getServicos = (callback) => {
    var url = ENDPOINT + "servicos/list";
    
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        var operadores_list = [];
        data.forEach((p, i) => {
            p['value'] = p.flag;
            p['label'] = p.nome;
            operadores_list.push(p);
        });

        callback(operadores_list)
    });
}

class Departamento extends Component{
    constructor(props){
        super(props);
        this.state = {
            departamentos_list: [],
            servicos_list: [],
            servico_name: '',
            departamentos: [],
            current_departamento: null,
            new_servico: false,
            name: '',
            name_invalid: false,
            descricao: '',
            descricao_invalid: false,
            tipo_departamento: null,
            tipo_departamento_invalid: false,
            error_message: '',
            success_message: '',
            loading: false,
            disable_button: false,
            visible_delete: false,
        }
    }
    componentDidMount(){
        getDepartamentos(this.callbackDepartamentos.bind(this));
        getServicos(this.callbackServicos.bind(this));
    }
    callbackServicos = (servicos_list) => {
        this.setState({servicos_list});
    }
    callbackDepartamentos = (departamentos_list) => {
        this.setState({departamentos: departamentos_list})
        var list = [];

        var item_new = [];
        item_new['value'] = 'new';
        item_new['label'] = <><MdAdd/>Novo departamento</>;

        list.push(item_new);
        departamentos_list.forEach((p) => {
            list.push(p);
        })

        this.setState({departamentos_list: list});
    }
    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    retira_acentos(str) {
        var com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
        var sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
        var novastr="";
        for(var i=0; i<str.length; i++) {
            var troca=false;
            for (var a = 0 ; a < com_acento.length; a++) {
                /* eslint eqeqeq: 0 */
                if (str.substr(i,1)==com_acento.substr(a,1)) {
                    novastr+=sem_acento.substr(a,1);
                    troca=true;
                    break;
                }
            }
            /* eslint eqeqeq: 0 */
            if (troca==false) {
                novastr+=str.substr(i,1);
            }
        }

        return novastr;
    }

    formatflag = (titulo) => {
        return this.retira_acentos(titulo).replaceAll(' ', '_').replace(/[^a-z0-9]/gi,'').toLowerCase()
    }

    salvar_departamento() {
        if(this.validFields() === true){
            var url = ENDPOINT + "departamento/update";
    
            if(this.state.current_departamento.value === 'new'){
                url = ENDPOINT + "departamento/store";
            }
    
            var formData = new FormData();
            formData.append('nome', this.state.name);
    
            if(this.state.current_departamento.value !== 'new'){
                formData.append('flag', this.state.current_departamento.flag);
            }
            else{
                formData.append('flag', this.formatflag(this.state.name));
            }
    
            formData.append('descricao', this.state.descricao);
            formData.append('tipo_departamento', this.state.tipo_departamento);
    
            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer ' + getToken()
                }
            })
            .then((response) => {
                getDepartamentos(this.callbackDepartamentos.bind(this));
                this.clearFields();
                toast.success('Departamento criado/atualizado com sucesso, para definir um horario, vá na aba "Horario de Atendimento"');
            })
            .catch(() => {});
        }
    }
    clearFields() {
        this.setState({
            name: '',
            descricao: '',
            tipo_departamento: null,
            name_invalid: false,
            descricao_invalid: false,
            tipo_departamento_invalid: false,
            current_departamento: null
        });
    }
    
    salvarServicos = () => {
        var url = ENDPOINT + "servicos/save";
        
        var formData = new FormData();
        formData.append('servicos', JSON.stringify(this.state.servicos_list));

        axios.post(url, formData, {
            headers: {
            Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            var servicos_list = [];
            data.data.forEach((p, i) => {
                p['value'] = p.flag;
                p['label'] = p.nome;
                servicos_list.push(p);
            });
            toast.success(data.message);

            this.callbackServicos(servicos_list)
        })
        .catch(() => {
            toast.error('Ocorreu um erro no servidor');
        });
    }

    excluirServicos = (id) => {
        var url = ENDPOINT + "servicos/delete/"+id;

        axios.delete(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            getServicos(this.callbackServicos.bind(this));
        })
        .catch(() => {
            toast.error('Ocorreu um erro no servidor');
        });
    }

    criarServico = () => {
        if(this.state.servico_name.length === 0){
            toast.error('Digite o nome do serviço')
        }

        this.setState({disable_button: true});
        var url = ENDPOINT + "servicos/criar";
        
        var formData = new FormData();
        formData.append('servico_nome', this.state.servico_name);
        formData.append('servico_flag', this.formatflag(this.state.servico_name));

        axios.post(url, formData, {
            headers: {
            Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            toast.success('Serviço cadastrado com sucesso');
            this.setState({
                disable_button: false,
                servico_name: '',
                new_servico: false 
            });
            getServicos(this.callbackServicos.bind(this));
        })
        .catch(() => {
            toast.error('Ocorreu um erro no servidor');
            this.setState({
                disable_button: false
            })
        });
    }

    validFields(){
        var invalids = 0;

        if(this.state.name.length > 0){
            this.setState({name_invalid: false});
        }
        else{
            invalids++;
            this.setState({name_invalid: true});
        }

        if(this.state.descricao.length > 0){
            this.setState({descricao_invalid: false});
        }
        else{
            invalids++;
            this.setState({descricao_invalid: true});
        }

        if(this.state.tipo_departamento !== null){
            this.setState({tipo_departamento_invalid: false});
        }
        else{
            invalids++;
            this.setState({tipo_departamento_invalid: true});
        }

        if(invalids > 0){
            toast.error("Há campos que faltam ser selecionados");
            return false;
        }

        return true;
    }
    
    onDelete(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            });
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    deletarDepartamento = () => {
        this.setState({loading: true})
        var url = ENDPOINT+"departamento/delete";
    
        var formData = new FormData();
        formData.append('flag', this.state.current_departamento.value);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.onDelete(data);
            this.setState({
                current_departamento: null
            });

            getDepartamentos(this.callbackDepartamentos.bind(this));
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "data": null, "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                toast.error(obj.errors);
                this.onDelete(obj);
                return obj;
            }
            var data = error.response.data;
            toast.error(obj.errors);
            this.onDelete(data);
            return data;
        });
    }
    onChangeDepartamento = (departamento) => {
        if(departamento.value !== 'new'){
            this.setState({
                name: (departamento.nome),
                descricao: (departamento.descricao === null ? '' : departamento.descricao),
                tipo_departamento: (departamento.tipo_departamento),
                name_invalid: false,
                descricao_invalid: false,
                tipo_departamento_invalid: false,
                current_departamento: departamento
            })
        }
        else{
            this.setState({
                name: '',
                descricao: '',
                tipo_departamento: null,
                name_invalid: false,
                descricao_invalid: false,
                tipo_departamento_invalid: false,
                current_departamento: departamento
            });
        }
    }
    onError(){
        this.setState({
            loading: false,
            error_message: ''
        })
    }
    onClose(){
        this.setState({
            visible_delete: false,
            loading: false,
            error_message: '',
            success_message: '',
        })
    }
     handleSelect = (name, value, key) => {
        var list = [];
        name.forEach((p) => {
            var new_json = {};
            new_json.flag_departamento = p.flag;
            new_json.flag_servico = value.flag;

            list.push(new_json);
         });

        var state = this.state;
        state.servicos_list[key].informacao_servico = list;
        this.setState({...state});
    }
    
    getDepartamentoLabel(value){
        var departamento = [];
        value.forEach(s => {
            this.state.departamentos.forEach((p, i) => {
                if(p.value === s.flag_departamento){
                    departamento.push(p);
                }
            });

        })

        return departamento;
    }
    render(){
        return(
            <div>
                <h3>Controle de Departamento</h3>
                {/* <small>A funcionalidade do controle de departamento não está disponivel</small> */}
                <hr />
                <div hidden={!getUser().permissao_edicao_operadores} >
                    <Select 
                        placeholder="Departamento" 
                        onChange={this.onChangeDepartamento}
                        options={this.state.departamentos_list}
                        value={this.state.current_departamento}
                    />
                    <br/>
                    <div hidden={this.state.current_departamento === null}>
                        <Card>
                            <CardBody>
                                <Form>
                                    <FormGroup>
                                        <Label>Nome:</Label>
                                        <Input 
                                            placeholder="Nome"
                                            type="text"
                                            name="name"
                                            invalid={this.state.name_invalid}
                                            value={this.state.name}
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Descrição:</Label>
                                        <Input 
                                            placeholder="Descrição"
                                            type="text"
                                            name="descricao"
                                            invalid={this.state.descricao_invalid}
                                            value={this.state.descricao}
                                            onChange={this.handleInputChange}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Tipo de Departamento:</Label>
                                        <br/>
                                        <input 
                                            type="radio" 
                                            name="tipo_departamento" 
                                            checked={this.state.tipo_departamento === 'publico'} 
                                            onChange={() => this.setState({tipo_departamento: 'publico'})} /> 
                                                Público <small>(Vísivel para visitantes na janela de chat)</small><br/>
                                        <input 
                                            type="radio" 
                                            name="tipo_departamento" 
                                            checked={this.state.tipo_departamento === 'privado'}  
                                            onChange={() => this.setState({tipo_departamento: 'privado'})}/> 
                                            Privado <small>(Apenas para uso interno não vísivel aos visitantes)</small>
                                        <Input invalid={this.state.tipo_departamento_invalid} hidden/>
                                        <FormFeedback>Selecione o Tipo de Departamento</FormFeedback>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <Button 
                                    color="success" 
                                    onClick={this.salvar_departamento.bind(this)}>Salvar</Button>

                                <Button 
                                    onClick={() => this.setState({visible_delete: true})} 
                                    style={{marginLeft: '10px'}} 
                                    hidden={this.state.current_departamento !== null ? 
                                        (this.state.current_departamento.value === 'nova_assinatura' ? 
                                            true : false) : true} 
                                    color="danger" >Excluir</Button>
                            </CardFooter>
                        </Card>
                        <br/>
                    </div>
                    <ModalDialog
                        visible={this.state.visible_delete}
                        onError={this.onError.bind(this)}
                        message="Deseja excluir esse modelo de assinatura? Essa operação não poderá ser desfeita"
                        onClose={() => this.onClose()}
                        error_message={this.state.error_message}
                        loading={this.state.loading}
                        onSave={() => this.deletarDepartamento()}
                        success_message={this.state.success_message}
                    />
                    <div>
                        <Card>
                            <CardBody>
                                <h4>Serviços:</h4>
                                <hr/>
                                {this.state.servicos_list.map((p, i) => {
                                    return <Row style={{marginBottom: 5}}>
                                        <Col md={3}>
                                            {p.nome_servico}
                                        </Col>
                                        <Col md={5} style={{display: 'flex'}}>
                                            <Button color="danger" onClick={() => this.excluirServicos(p.id)}>Excluir</Button>
                                            <div style={{width: '100%', marginLeft: '9px'}}>
                                                <Select 
                                                    placeholder="Departamento"
                                                    options={this.state.departamentos}
                                                    isSearchable={false}
                                                    isMulti
                                                    onChange={(value) => this.handleSelect(value, p, i)}
                                                    value={this.getDepartamentoLabel(p.informacao_servico)}
                                                    menuPlacement="auto"
                                                    menuPosition="fixed"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                })}
                                {this.state.servicos_list.length === 0 ? 'Nenhum servico encontrado' : ''}
                                <br/>
                                <br/>
                                <Button color="success" onClick={() => this.setState({new_servico: !this.state.new_servico})}>Adicionar Novo Serviço</Button>
                                <Collapse isOpen={this.state.new_servico}>
                                    <hr/>
                                    <Form>
                                        <FormGroup>
                                            <Input
                                                placeholder="Nome do Serviço"
                                                name="servico_name"
                                                value={this.state.servico_name}
                                                onChange={this.handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Button disabled={this.state.disable_button} onClick={() => this.setState({new_servico: false})} color="danger"> Cancelar</Button> &nbsp;
                                            <Button disabled={this.state.disable_button} onClick={() => this.criarServico()} color="info"> Salvar Serviço</Button>
                                        </FormGroup>
                                    </Form>
                                </Collapse>
                            </CardBody>
                            {this.state.servicos_list.length > 0 ?
                            <CardFooter>
                                <Button color="success" onClick={() => this.salvarServicos()}>Salvar</Button>
                            </CardFooter>
                            : <></>}
                        </Card>
                    </div>
                </div>
                <div hidden={getUser().permissao_edicao_operadores}>
                  <Card>
                      <CardBody>
                          <p>Você não tem permissões para acessar essa função</p>
                      </CardBody>
                  </Card>
                </div>
            </div>
        )
    }
}
export default Departamento;