import axios from "axios";
import React, { Component } from "react";
import { MdArrowBack, MdCheck, MdClear, MdMail, MdWarning, MdAttachFile } from "react-icons/md";
import { BsLock, BsUnlock } from 'react-icons/bs';
import { IoReceiptOutline } from 'react-icons/io5';
import { Button, Card, CardBody, CardColumns, CardFooter, Col, Collapse, FormGroup, Input, Label, Nav, NavLink, Row, TabContent, TabPane, Alert } from "reactstrap";
import Dropzone from 'react-dropzone';
import styled from "styled-components";
import { ENDPOINT, ENDPOINT_SHOP } from "../../../auth/endpoint";
import { getPermissoes, getToken, getTokenShop, createTokenShop, generateUUID } from "../../../auth/set_services";
import Select from "react-select";
import { InputMasked } from '../../../components/InputText/InputMasked';
import { toast } from 'react-toastify';
import PropagateLoader from "react-spinners/PropagateLoader";
import ClipLoader from 'react-spinners/ClipLoader'
import moment from "moment";
import { FaAngleDown, FaArrowsAltV, FaBox, FaBoxes, FaClipboardList, FaCreditCard, FaFileImport, FaFileInvoiceDollar, FaFunnelDollar, FaListOl, FaMapMarkerAlt, FaMoneyBillAlt, FaPen, FaPercent, FaPercentage, FaPlus, FaRegCreditCard, FaRegFilePdf, FaRegListAlt, FaRegMoneyBillAlt, FaSave, FaSearch, FaSleigh, FaTicketAlt, FaTrash, FaTruck, FaWallet, FaWindowClose } from "react-icons/fa";
import classnames from 'classnames';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import { ButtonRed } from "../../../components/ButtonStylish/ButtonRed";
import { stringToArray } from "ag-grid-community";
import PDFViewer from "pdf-viewer-reactjs";
import copy from "copy-to-clipboard"; 
import ConfirmarCancelar from "../../../components/Modals/AQEnvio/ConfirmarCancelar";

var CurrencyInput = require('react-currency-masked-input');


const Div = styled.div`
.nav-item{
    margin: 0
}
.nav-link{
    color: black;
    text-transform: initial;
    cursor: pointer;
    padding: 0.5rem;
    border: 1px solid #ccc;
    margin: 0.2rem 0.3rem;
    border-radius: 5px;
}
.nav-tabs .nav-item.show .nav-link, 
.nav-tabs .nav-link.active {
    background: #28a745;
    color: #fff;
}
.nav-link:hover{
    background: #70b580;
    color: #fff;
}
.nav-tabs {
    padding-bottom: 1rem;
}
.reclamado {
    display: none;
}
.row {
    margin: 10px 0px;
}
.btn-save {
    float: right;
}
.btn-nota {
    margin-top: 10px;
}
.file-iframe {
    width: 100%;
    height: 800px; 
}
.btn-arquivo {
    margin-right: 10px;
    color: #fff;
    border: 0px;
}
.subtitle {
    font-weight: 700;
    margin-top: 20px !important;
}
.error-active {
    display: block;
    color: red;
}
.error-none {
    display: none;
}
.input-error {
    border: 1px solid red;
}
.label-error {
    color: red;
}
.select-error {
    border: 1px solid red;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}
.card {
    border: 1px solid #ced4da;
    border-left: 5px solid #28a745;
    border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    -moz-border-radius: 0.25rem;
    
}
.card h6 {
    font-weight: 600;
    padding: 10px;
    border-radius: 3px 3px 0px 0px;
    margin: 0px;
}
.card-box {
    padding: 1rem;
    height: auto;
    min-height: 180px;
}
.card-box div {
    font-size: 14px;
}
.button-edit {
    position: absolute;
    bottom: 0.5rem;
    right: 1rem;
}
.button-remove {
    position: absolute;
    bottom: 0.5rem;
    left: 1rem;
}
.btn-salvar {
    float: right;
}
.add-address-btn {
    position: absolute;
    top: 0.5rem;
    right: 2rem;
}
.col-cards {
    margin-bottom: 1rem;
}
.btn-float {
    float: right;
}
.nav-link {
    padding: 1rem;
    font-size: 14px; 
}
.active.nav-link {
    background: #58d174;
    color: #fff;
}
.tab-content {
    border: 1px solid rgb(222, 226, 230);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    position: relative;
    top: 0px;
}
.nav.nav-tabs {
    border-bottom: 0px;
}
.currency-simbol {
    color: #999b9d;
    position: absolute;
    left: 25px;
    top: 43px;
}
.captalize {
    text-transform: capitalize;
}

table tr th,
table tr td {
    font-weight: normal;
    padding: 0.5rem 1.5rem;
    border: 1px solid #dee2e6;
}

table tr td { 
    font-weight: bold;
}

.input-erro {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ff0000;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.select-erro {
    border: 1px solid #ff0000;
    border-radius: 0.25rem;
}
.currency-simbol {
    color: #999b9d;
    position: absolute;
    left: 25px;
    top: 43px;
}
.input-currency {
    padding-left: 30px;
}
.select-period {
    color: #000 !important;
}
.SortableListPayments {
    
}
`;

const DropArea = styled.div`
    border-style: dashed;
    border-color: #38B76E;
    padding: 1%;
    cursor: pointer;
    &:drag {
        opacity: 0.5;
    }
`;

const ButtonUpload = styled.button`
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0;
    padding-left: 7px;
    padding-right: 7px;
    top: -1px;
    position: relative;
`;

const optionStatus = [
    {label: 'Liberado', value: 1},
    {label: 'Bloqueado', value: 0}
];

const getId = () => {
    return window.location.pathname.replace('/aqenvio-etiqueta-detalhes/', '')
}

const motivos_limite = [
    {label: "Ré-análise de Risco SCR", value: 'scr'},
    {label: "Pontuação de Risco Score de Crédito Bureau", value: 'score_risco'},
    {label: "Inadiplência Holding AQBank", value: 'inadiplente_aqbank'},
    {label: "Inadiplência de Fatura", value: 'inadiplente_fatura'},
    {label: "Cumprimento de Bloqueio Judicial", value: 'cumprimento_bloqueio_judicial'},
    {label: "Cancelamento de Serviço Pós-Pago", value: 'cancelamento'},
];

const SortableItem = SortableElement(({value, state, item, handleRemove}) =>
    <li key={ String((Math.floor(Math.random() * 10))) } style={{
        justifyContent: 'center', 
        alignItems: 'center', 
        listStyle: 'none',
        backgroundColor: '#ffffff', 
        // opacity: item.value !== "after_paid" && state.payment_pos_active === true ? 0.4 : 1,
        padding: '0.5rem', 
        cursor: 'pointer', 
        height: '3.5rem', 
        marginTop: '0', 
        border: '1px solid #dee2e6'
    }}>
        <b>{value}</b> 
        
        <Button 
            outline 
            color="danger" 
            style={{color: '#FF0000',float: 'right'}}
            onClick={() => handleRemove(item)}
            >
            Remover
        </Button>
        {item.value !== "after_paid" && state.payment_pos_active === true ? 
            <><br/><small style={{color: '#28a745'}}>Alternativo</small></> 
        : 
            <></>
        }
    </li>
);

const SortableList = SortableContainer(({items, state, remove}) => {
    return (
        <ul style={{padding: 0, backgroundColor: '#eeeeee'}}>
            {items.map((item, index) => (
                <SortableItem 
                    key={`item-${index}`} 
                    index={index} 
                    value={item.label} 
                    state={state} 
                    item={item} 
                    handleRemove={remove} 
                    />
            ))}
        </ul>
    );
});

class ResumoPostagem extends Component {
    constructor(props){
        super(props);
        this.state = {
            show_form: true,
            loading_page: true,
            loading_button: false,
            loading_moviments: true,
            files: [],
            notas: [],
            seller_id: getId(),
            nota_mensagem: '',
            payment_method: null,
            options_method_payment: [],
            seller_data: [],
            payments: [],
            payment_pos_active: false,
            loading_pay_etiqueta: false,

            extract_data: [],
            start_date: null,
            end_date: null,
            zip_code_origin: null,
            destination: null,

            descricao_cancelamento: null,
            base64_label: null,
            loading_download: false,
            loading_view: false,
            loading_label: false,
            simulate_shippings: [],

            form_receiver: false,
            form_simulate: false,
            loading_etiqueta: false,
            visible_confirm: false,

            copyText: null,

            period_extract: {label: 'Todo', value: 'todo'},

            items: ['Pagar por Etiqueta', 'Comprar Crédito', 'Pós Pago', 'Débito'],

            pay_type_select: {label: null, value: null},
            status_aqenvio: {label: 'Liberado', value: 1},
            status_free_aqenvio: {label: 'Liberado', value: 1},

            tax_type_options: [
                {value: 'V', label: 'Valor (R$)'},
                {value: 'P', label: 'Procentagem (%)'},
            ],

            limit_credit: null,
            nota_limite: null,
            motivos_limite: motivos_limite[0]['value'],
            motivos_limite_option: motivos_limite[0],

            list_limit: [],
            moviments: null,

            shipping_height: 15,
            shipping_width: 11,
            shipping_length: 20,

            id_empresa: null,
            razao_social: null,
            cnpj: null,
            regime_id: null,
            data_criacao: null,
        }
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState({
          items: arrayMove(this.state.items, oldIndex, newIndex),
        });
      };

    onDrop = (file) => {
        var state = this.state;
        file.forEach(file_current => {
            state.files.push(file_current);
        });
        this.setState({
            ...state
        })
    }
    removeAttach = (index) => {
        var state = this.state;
        state.files.splice(index, 1);
        this.setState({
            ...state
        })
    }

    handleFileChange = (event) => {
        const files = event.target.files;

        var state = this.state;
        Array.from(files).forEach(file_current => {
            state.files.push(file_current);
        });
        this.setState({
            ...state
        })
    }

    handletoastSuccessMessage = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    handletoastMessage = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    handleSendFormEmpresa = (ev) => {
        // var url = ENDPOINT + "/financeiro/empresa/enviar";
        // //var url = "http://localhost:8000/api/financeiro/empresa/enviar";

        // ev.preventDefault();
        // //this.setState({disable_send_button: true})
        // this.setState({loading_page: true})
        // this.setState({loading_btn_save_form: true})

        // let formData = new FormData();

        // Object.entries(this.state).forEach(([field, val]) => {
        //     if (field !== 'anexos') {
        //         formData.append(field, val);
        //     }
        // })

        // if(this.state.files.length > 0){
        //     this.state.files.forEach((file, index) => {
        //         formData.append('anexos[]', file);
        //     });
        // }

        // axios.post(url, formData, {
        //     headers: {
        //         Authorization: 'Bearer ' + getToken()
        //     }})
        // .then((response) => {
            
        //     console.log(response)
        // })
        // .catch(function (error) {

        //     if (error.response) {
        //       // The request was made and the server responded with a status code
        //       // that falls out of the range of 2xx
        //       console.log(error.response.data);
        //       //console.log(error.response.status); // status code
        //       //console.log(error.response.headers);
        //     } else if (error.request) {
        //       // The request was made but no response was received
        //       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        //       // http.ClientRequest in node.js
        //       //console.log(error.request);
        //     } else {
        //       // Something happened in setting up the request that triggered an Error
        //       console.log('Error', error.message);
        //     }
        //     console.log(error);
        // })
    }

    componentDidMount(){
        this.handleLoadLabel()
    }
    
    handleLoadSellerStatus = async () => {

        let authShop = await this.handleOpenShopToken(this.state.seller_document);
        var url = ENDPOINT_SHOP + "aqenvio/seller_status";
        //var url = "http://localhost:8001/api/aqenvio/seller_status";

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + authShop
            },
        })
        .then((response) => {
            let resp = response.data;
            let state = this.state;

            state.seller_data = resp.data;
            this.setState({...state})

            console.log(resp.data)

            if (state.seller_data?.payments) {
                state.seller_data.payments.map((payMethod, i)=>{
                    switch(payMethod){
                         case "pay_label":
                             return;
                         break;
                         case "credits":
                             this.handleSaldoPre()
                         break;
                         case "after_paid":
                             this.handleSaldoPos()
                         break;
                         case "debit":
                             this.handleSaldoDebit()
                         break;
                         default:
                             return;          
                         break;
                    }
                 })
            }

        })
        .catch((error) => {
            this.setState({loading_moviments: false})
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              //console.log(error.config);
        })
    }
    
    handleLoadLabel = () => {
        var url = ENDPOINT + "aqenvio/show_label/" + getId();
        //var url = "http://localhost:8000/api/aqenvio/show_label/" + getId();
        
        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            let data = response.data;
            let state = this.state;
            console.log('Label data')
            console.log(data)
            state.loading_moviments = false;
            state.label_id = data.data.id;
            state.code = data.data.code;
            state.free = data.data.free;
            state.type = data.data.type;
            state.weight = data.data.weight;
            state.status = data.data.status;
            state.status_id = data.data.status_id;
            state.amount = data.data.amount;
            state.seller_document = data.data.document;
            state.seller_id = data.data.seller_id;
            state.pay_amount = data.data.pay_amount;
            state.recipient = data.data.recipient;

            this.setState({...state})
            
            if (state.status_id != 1) {
                this.handleTrackingCode(state.code)
            } else {
                this.handleSearchCob(state.label_id)
            }
            this.handleLoadSellerStatus()
        })
        .catch((error) => {
            this.setState({loading_moviments: false})
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              //console.log(error.config);
        })
    }
    
    handleSearchCob = async (idLabel) => {
        let authShop = await this.handleOpenShopToken(this.state.seller_document);
        var url = ENDPOINT_SHOP + "aqenvio/cob/" + idLabel;
        //var url = "http://localhost:8001/api/aqenvio/cob/" + idLabel;

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + authShop
            },
        })
        .then((response) => {
            let data = response.data;
            let state = this.state;

            if (data?.success) {
                state.url_pay = data?.url_pay;

                this.setState({...state})
            }

        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            //console.log(error.config);
        })
    }

    handleSaldoDebit = async () => {
        let authShop = await this.handleOpenShopToken(this.state.seller_document);

        var url = ENDPOINT_SHOP + "aqenvio/debit_balance";
        //var url = "http://localhost:8001/api/aqenvio/debit_balance";

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + authShop
            },
        })
        .then((response) => {
            let data = response.data;
            let state = this.state;

            state.debit_saldo = data?.balance;

            this.setState({...state})
        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            //console.log(error.config);
        })
    }

    handleSaldoPos = async () => {
        let authShop = await this.handleOpenShopToken(this.state.seller_document);

        var url = ENDPOINT_SHOP + "aqenvio/pos";
        //var url = "http://localhost:8001/api/aqenvio/pos";

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + authShop
            },
        })
        .then((response) => {
            let data = response.data;
            let state = this.state;

            state.pos_saldo = data?.balance;

            this.setState({...state})
        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            //console.log(error.config);
        })
    }

    handleSaldoPre = async () => {
        let authShop = await this.handleOpenShopToken(this.state.seller_document);

        var url = ENDPOINT_SHOP + "aqenvio/credits";
        //var url = "http://localhost:8001/api/aqenvio/credits";

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + authShop
            },
        })
        .then((response) => {
            let data = response.data;
            let state = this.state;

            state.pre_saldo = data?.balance;

            this.setState({...state})
        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            //console.log(error.config);
        })
    }

    handleNewSimulate = () => {
        var url = ENDPOINT_SHOP + "aqenvio/simulate";
        //var url = "http://localhost:8000/api/aqenvio/simulate";

        axios.post(url, {
            'payment_id': null,
            'seller': null,
            'zip-code-origin': null,
            'zip-code-destination': null,
            'commodity-value': null,
            'merchandise-weight': null,
            'volumes': [
                {
                    'weight': null,
                    'height': null,
                    'width': null,
                    'length': null,
                    'type': null,
                    'value': null,
                    'quantity': null,
                }
            ],
            'products': [
                {
                    'weight': null,
                    'height': null,
                    'width': null,
                    'length': null,
                    'value': null,
                    'quantity': null,
                }
            ],
        }, {
            headers: {
                Authorization: getTokenShop()
            }
        })
        .then((response) => {
            let data = response.data;
            //let state = this.state;


            // state.loading_moviments = false;

            // if (data?.sucess && data.sucess) {
            //     state.moviments = data;
            // }
            // this.setState({...state})
        })
        .catch((error) => {
            this.setState({loading_moviments: false})
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              //console.log(error.config);
        })
    }

    handleOpenShopToken = async (document) => {
        let authShop = await createTokenShop(document);
        return authShop;
    }

    handleNewPreCredit = async () => {
        this.setState({loading_pay_etiqueta: true})
        let authShop = await this.handleOpenShopToken(this.state.seller_document);
        var url = ENDPOINT_SHOP + "aqenvio/credit/" + this.state.label_id;

        axios.post(url, {success: true}, {
            headers: {
                Authorization: 'Bearer ' + authShop
            }
        })
        .then((response) => {
            this.setState({loading_pay_etiqueta: false})
            let data = response.data;
            let state = this.state;

            if (data?.success) {
                toast.success('Faturado com sucesso!');
                this.handleLoadLabel()
            } else {
                toast.error('Falha ao realizar pagamento!');
            }
        })
        .catch((error) => {
            this.setState({loading_pay_etiqueta: false})
            toast.error('Ocorreu um erro no servidor!');
        })
    }

    handleNewCharge = async () => {
        this.setState({loading_pay_etiqueta: true})
        let authShop = await this.handleOpenShopToken(this.state.seller_document);
        var url = ENDPOINT_SHOP + "aqenvio/cob_new/" + this.state.label_id;
        
        axios.post(url, {success: true}, {
            headers: {
                Authorization: 'Bearer ' + authShop
            }
        })
        .then((response) => {
            this.setState({loading_pay_etiqueta: false})
            
            let data = response.data;
            let state = this.state;

            

            if (data?.success) {
                state.url_pay = data?.url_pay;

                this.setState({...state})
            }
        })
        .catch((error) => {
            this.setState({loading_pay_etiqueta: false})
            toast.error('Ocorreu um erro no servidor!');
            if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            // console.log(error.response.status); // status code
            // console.log(error.response.headers);
            } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            //console.log(error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            }
            //console.log(error.config);
        })
    }

    handleNewDebit = async () => {
        let authShop = await this.handleOpenShopToken(this.state.seller_document);
        var url = ENDPOINT_SHOP + "aqenvio/cob_new/" + this.state.label_id;
        
        axios.post(url, {success: true}, {
            headers: {
                Authorization: 'Bearer ' + authShop
            }
        })
        .then((response) => {
            let data = response.data;
            let state = this.state;

            if (data?.success) {
                state.url_pay = data?.url_pay;

                this.setState({...state})
            }
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!');
        })
    }

    handleTrackingCode = async (code) => {
        let authShop = await this.handleOpenShopToken(this.state.seller_document);
        var url = ENDPOINT_SHOP + "aqenvio/track/" + code;
        
        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + authShop
            },
        })
        .then((response) => {
            let data = response.data?.data;
            let state = this.state;

            if (data?.situacao) {
                state.tracking = {};
                state.tracking.situacao = {};
                state.tracking.historico = [];
                state.tracking.situacao = data.situacao;
                state.tracking.historico = data.historico;

                this.setState({...state})
            }
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if(!error.response.data?.status_code) {
                    this.handletoastMessage('Ocorreu um erro no servidor!');
                }
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            //console.log(error.config);
        })
    }

    handleDownloadLabelPDF = async (code) => {
        let authShop = await this.handleOpenShopToken(this.state.seller_document);
        var url = ENDPOINT_SHOP + "aqenvio/print_label/" + code;
        
        this.setState({loading_etiqueta: true})

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + authShop
            },
        })
        .then((response) => {
            let data = response.data;
            let state = this.state;

            state.base64_label = data.pdf;
            state.loading_etiqueta = false;

            this.setState({...state})

            /**
             * Download do PDF
             */
            const linkSource = `data:application/pdf;base64,${this.state.base64_label}`;
            const downloadLink = document.createElement("a");
            const fileName = code + "_etiqueta.pdf";
        
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            //console.log(error.config);
        })
    }

    handleOpenLabelPDF = async (code) => {

        let authShop = await this.handleOpenShopToken(this.state.seller_document);
        var url = ENDPOINT_SHOP + "aqenvio/print_label/" + code;

        this.setState({loading_etiqueta: true})

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + authShop
            },
        })
        .then((response) => {
            let data = response.data;
            let state = this.state;

            if(data?.pdf) {
                state.base64_label = data.pdf;
                state.loading_etiqueta = false;
                
                this.setState({...state})
    
                /**
                 * Abrir PDF em nova aba
                 */
                var byteCharacters = atob(this.state.base64_label);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: 'application/pdf;base64' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            } else {
                state.loading_etiqueta = false;
                
                this.setState({...state})

                this.handletoastMessage('Falha ao abrir PDF');
            }
        })
        .catch((error) => {

            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            //console.log(error.config);
        })
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleLoadDataLimit = () => {
        var url = ENDPOINT + "aqenvio/credit_limit/" + getId();
        //var url = "http://127.0.0.1:8000/api/aqenvio/credit_limit/" + getId();

        axios.get(url, null, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            let data = response.data;

            if (data.success) {
                let state = this.state;
                state.list_limit = data.data;

                this.setState({...state})
            }
        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              //console.log(error.config);
        })
    }

    handleLoadDataSeller = (sellerId) => {
        var url = ENDPOINT + "aqenvio/seller_status/" + sellerId;
        //var url = "http://localhost:8000/aqenvio/seller_status/" + sellerId;
        

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            let data = response.data;
            let state = this.state;


            this.setState({...state})

        })
        .catch((error) => {
            this.setState({loading_moviments: false})
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              //console.log(error.config);
        })
    }

    handleLoadDataPayment = () => {
        var url = ENDPOINT + "aqenvio/payment_methods";
        //var url = "http://localhost:8000/aqenvio/payment_methods";

        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
    
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            if (p.success === true) {
                let data = p.data;
                let state = this.state;

                data.map((field, i) => {
                    state.options_method_payment.push({
                        label: field.name,
                        value: field.code,
                    })
                })

                this.setState({...state})
            }  
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!');
            //console.log(error)
        })
    }

    deletarNota = (id) => {
        this.setState({
            loading_nota_id: id
        });
        
        // var url = ENDPOINT+"notas/delete/"+id;
        // axios.delete(url, {
        //     headers: {
        //         Authorization: 'Bearer '+getToken()
        //     }
        // })
        // .then(() => {
        //     this.getNotas(this.state.id);
        //     toast.success('Deletado com sucesso');
        //     this.setState({
        //         loading_nota_id: null
        //     });
        // })
        // .catch((error) => {
        //     var data = error.response.data;
        //     toast.error(data.errors);
        //     this.setState({
        //         loading_nota_id: null
        //     });
        // })
    }

    handleAddNota = () => {
        var url = ENDPOINT + "financeiro/nota";

        var formData = new FormData();

        formData.append('id_processo', getId());
        formData.append('nota_mensagem', this.state.nota_mensagem);

        this.setState({loading_btn_add_nota: true})

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var notas_priv = this.state.notas_privadas;
            notas_priv.push(response.data.data);

            this.setState({notas_privadas: notas_priv});
            this.setState({is_open_nota: false});
            this.setState({nota_mensagem: null});
            this.setState({loading_btn_add_nota: false})
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!');
        })
    }

    handleSetShipping = (simulate_id) => {
        let state = this.state;

        state.simulate_id = simulate_id;
        state.form_receiver = true;

        this.setState({...state})
    }

    handleCopy = () => {
        copy(this.state?.url_pay);
        alert('Link copiado!');
    }
    onClose(visible_confirm){
        this.setState({visible_confirm: false})
    }
    
    openCancel = (code) => {
        this.setState({cancel_code: code, visible_confirm: true})
    }

    cancelLabel = async () => {
        console.log(this.state.cancel_code)

        //var url = ENDPOINT + "aqenvio/cancel/" + this.state.cancel_code;
        var url = "http://localhost:8000/api/aqenvio/cancel/" + this.state.cancel_code;
        
        axios.delete(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            },
            data: {
                descricao: this.state.descricao_cancelamento
            }
        })
        .then((response) => {
            console.log(response)

            this.handletoastSuccessMessage('Cancelado com sucesso!');
        })
        .catch((error) => {
            this.setState({loading_moviments: false})
            this.handletoastMessage('Ocorreu um erro no servidor!');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              //console.log(error.config);
        })
    }

    render(){
        const NavsConfig = () => {
            return <Nav tabs>
                {this.state.list_tabs_config.map((p, index) => {
                    let ClassNames = classnames({ active: this.state.activeTabConfig === p.active });
                    return <NavLink
                        key={index}
                        className={ClassNames}
                        onClick={() => { this.setState({ activeTabConfig: p.active }); }}>
                        <span>{p.name}</span>
                    </NavLink>
                })}
            </Nav>
        }

        return(
        <Div key={String('div-resumo-vendedor')}>
            <ConfirmarCancelar
                visible={this.state.visible_confirm}
                onClose={() => this.onClose(false)}
                callback={this.cancelLabel}
                /> 
            <h3>                            
                <Button 
                    outline 
                    style={{border: 'none'}}
                    onClick={() => window.location.href = '/aqenvio-vendedores'}
                >
                    <MdArrowBack style={{padding: 0}}/>
                </Button>&nbsp;&nbsp;Detalhes da Postagem
            </h3><hr />   
            <Row>
                <Col md={6}>
                    <div key="div-page" style={{ marginTop: '2rem', padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                        
                        <h5>
                            {this.state?.code}

                            {this.state?.status == 'Aguardando Pagamento' || this.state?.status == 'Aguardando Postagem' ? (
                                <Button
                                    style={{float: 'right',position: 'relative',top: '-0.3rem'}}
                                    color="danger"
                                    onClick={() => {
                                        this.openCancel(this.state?.code)
                                    }}>
                                    Cancelar
                                </Button>)
                            : 
                                <></>
                            }
                        </h5><hr/>
                        <Collapse
                            isOpen={!this.state.show_cancel}
                            style={{marginTop: '1rem'}}
                            >
                            <Row style={{marginBottom: '2rem'}}>
                                <Col md={12}>
                                    <label htmlFor="origem_debito">Motivo do cancelamento</label>
                                    <Input
                                        name="origem_debito"
                                        placeholder=""
                                        value={this.state.descricao_cancelamento}
                                        onChange={(event) => {this.handleInputChange(event)}}
                                        type="textarea"
                                        style={{
                                            height: '100px',
                                            resize: 'none'
                                        }}
                                        />
                                </Col>
                                <Col md={12}>
                                    <Button
                                        style={{float: 'left',position: 'relative',top: '1rem'}}
                                        color="danger"
                                        onClick={() => {
                                            
                                        }}>
                                        fechar
                                    </Button>

                                    <Button
                                        style={{float: 'right',position: 'relative',top: '1rem'}}
                                        color="success"
                                        onClick={() => {
                                            this.openCancel(this.state?.code)
                                        }}>
                                        Confirmar cancelamento
                                    </Button>
                                </Col>
                            </Row>
                        </Collapse>
                        <Row>
                            <Col md={12}>
                                <p><strong>Tipo: </strong> {this.state?.type}</p>
                                <p><strong>Status: </strong> {this.state?.status}</p>
                                <p><strong>Valor Kangu: </strong> {parseFloat(this.state?.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                <p><strong>Valor Pagamento: </strong> 
                                    {this.state?.free == 0 ?
                                        <>
                                            {this.state?.pay_amount == 0 ?
                                                <>Frete Grátis</>
                                            :
                                            <>{parseFloat(this.state?.pay_amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</>
                                            }
                                        </>
                                    :
                                        <>Frete Grátis</>
                                    }
                                    
                                </p>
                                <p><strong>Peso: </strong> {this.state?.weight}kg</p>
                            </Col>
                        </Row>
                    </div>   
                </Col>
                
                {this.state?.status_id == 1 ? (
                    <Col md={6}>
                        <div key="div-page" style={{ marginTop: '2rem', padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                            <h5>Pagamento</h5><hr/>
                            <Row>
                                <Col md={6} style={{marginBottom:'0.5rem'}}>
                                    <p><b>Saldo Pré: </b>{parseFloat(this.state?.pre_saldo ? this.state?.pre_saldo : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                </Col>
                                <Col md={6} style={{marginBottom:'0.5rem'}}>
                                    <p><b>Saldo Pós: </b>{parseFloat(this.state?.pos_saldo ? this.state?.pos_saldo : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                </Col>
                                <Col md={6} style={{marginBottom:'0.5rem'}}>
                                    <Button
                                    color="info"
                                    onClick={() => {
                                        this.handleNewPreCredit()
                                    }}
                                    >
                                        Utilizar crédito
                                    </Button>
                                </Col>
                                <Col md={6} style={{marginBottom:'0.5rem'}}>
                                    <Button
                                    color="info"
                                    onClick={() => {  
                                    }}
                                    >
                                        Utilizar pós pago
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} style={{marginBottom:'0.5rem'}}></Col>
                                <Col md={6} style={{marginBottom:'0.5rem'}}>
                                    <p><b>Saldo Débito: </b>{parseFloat(this.state?.debit_saldo ? this.state?.debit_saldo : 0.00).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} style={{marginBottom:'0.5rem'}}>
                                    <Button
                                    color="info"
                                    onClick={() => {
                                        this.handleNewCharge()
                                    }}>
                                        Gerar Link
                                    </Button>  
                                </Col>
                                <Col md={6} style={{marginBottom:'0.5rem'}}>
                                    <Button
                                    color="info"
                                    onClick={() => {
                                        this.handleNewDebit()
                                    }}
                                    >
                                        Utilizar Debito
                                    </Button>
                                </Col>
                                <Col md={12} style={{marginBottom:'0.5rem'}}>
                                    {this.state?.url_pay ? (
                                        <>
                                            <hr/>
                                            <Button
                                            outline
                                            color="success"
                                            onClick={() => {
                                                window.open(this.state?.url_pay, "_blank");
                                            }}
                                            >
                                                Abrir Link
                                            </Button>

                                            <Button
                                            outline
                                            color="success"
                                            style={{float: 'right'}}
                                            onClick={() => {
                                                this.handleCopy()
                                            }}
                                            >
                                                Copiar Link
                                            </Button>
                                            <p style={{marginTop: '1rem'}}>{this.state?.url_pay}</p>
                                        </>
                                    ) 
                                    : (<></>)}
                                </Col>
                            </Row>
                            
                            <Row hidden={!this.state.loading_pay_etiqueta}>
                                <Col md={12}>
                                    <center>
                                        <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                                    </center>
                                    <br/>
                                </Col>
                            </Row>
                        </div>
                    </Col>  
                ) 
                : (<></>)}

                {this.state?.status_id && this.state?.status_id != 1 ? (
                    <Col md={6}>
                        <div key="div-page" style={{ marginTop: '2rem', padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                            <h5>
                                Etiqueta

                            </h5><hr/>
                            <Row>
                                <Col md={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                    {this.state?.status == 'Aguardando Postagem' ? (
                                        <>
                                            <Button
                                                outline
                                                color="info"
                                                onClick={() => {
                                                    this.handleDownloadLabelPDF(this.state?.code)
                                                }}>
                                                <FaRegFilePdf />&nbsp;Download
                                            </Button>

                                            <Button
                                                outline
                                                color="info"
                                                onClick={() => {
                                                    this.handleOpenLabelPDF(this.state?.code)
                                                }}>
                                                    <FaRegFilePdf />&nbsp;Visualizar
                                            </Button>
                                        </>
                                    ) : (<>
                                        {this.state?.status}
                                    </>)}
                                </Col>
                            </Row>
                            <Row hidden={!this.state.loading_etiqueta}>
                                <Col md={12}>
                                    <center>
                                        <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                                    </center>
                                    <br/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                ) 
                : (<></>) }
            </Row>  
            <Row>
                <Col md={6}>
                    <div key="div-destinatario" style={{ marginTop: '2rem', padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                        <h5>Destinatário</h5><hr/> 
                        <p style={{margin: '0.5rem'}}><b>Nome: </b>{this.state?.recipient?.nome}</p>
                        <p style={{margin: '0.5rem'}}><b>Documento: </b>{this.state?.recipient?.cnpjCpf}</p>
                        <p style={{margin: '0.5rem'}}><b>Email: </b>{this.state?.recipient?.email}</p>
                        <p style={{margin: '0.5rem'}}><b>Telefone: </b>{this.state?.recipient?.telefone}</p>
                        <p style={{margin: '0.5rem'}}><b>Celular: </b>{this.state?.recipient?.celular}</p>
                        <p style={{margin: '0.5rem'}}><b>Cep: </b>{this.state?.recipient?.endereco?.cep}</p>
                        <p style={{margin: '0.5rem'}}><b>Endereço: </b>{this.state?.recipient?.endereco?.logradouro + ', ' + this.state?.recipient?.endereco?.numero}</p>
                        <p style={{margin: '0.5rem'}}><b>Complemento: </b>{this.state?.recipient?.endereco?.complemento}</p>
                        <p style={{margin: '0.5rem'}}><b>Bairro: </b>{this.state?.recipient?.endereco?.bairro}</p>
                        <p style={{margin: '0.5rem'}}><b>Cidade: </b>{this.state?.recipient?.endereco?.cidade}</p>
                        <p style={{margin: '0.5rem'}}><b>UF: </b>{this.state?.recipient?.endereco?.uf}</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div key="div-page" style={{ marginTop: '2rem', padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                        <h5>Histórico de Rastreio</h5><hr/>
                        <Row>
                            <Col md={12}>
                                {!this.state.view_tracking ?
                                    <>
                                        {this.state?.tracking?.situacao ? (
                                            <>
                                                <h5>{this.state?.tracking.situacao.ocorrencia}</h5>
                                                <p style={{margin: '0.5rem'}}><b>Data: </b>{moment(this.state?.tracking.situacao.dataHora).format('DD/MM/YYYY HH:mm')}</p>
                                                <p style={{margin: '0.5rem'}}><b>Observação: </b>{this.state?.tracking.situacao.observacao}</p>
                                                <p style={{margin: '0.5rem'}}><b>Recebedor: </b>{this.state?.tracking.situacao.recebedor}</p>
                                                <p style={{margin: '0.5rem'}}><b>Ação: </b>{this.state?.tracking.situacao.acao}</p>

                                                <Button
                                                color="warning"
                                                onClick={ () => {
                                                    this.setState({view_tracking: true})
                                                }}
                                                >
                                                    Ver tudo
                                                </Button>                                              
                                            </>
                                        ) : (
                                            <p>Não Localizado.</p>
                                        )}
                              
                                    </>
                                :
                                <></>
                                }
                                <Collapse
                                isOpen={this.state.view_tracking}
                                style={{marginTop: '1rem'}}
                                >

                                    {this.state.tracking?.historico.map((tracking, i) => {
                                        return(
                                        <>
                                            <hr/>
                                            <h5>{tracking?.ocorrencia}</h5>
                                            <p style={{margin: '0.5rem'}}><b>Data: </b>{moment(tracking?.dataHora).format('DD/MM/YYYY HH:mm')}</p>
                                            <p style={{margin: '0.5rem'}}><b>Observação: </b>{tracking?.observacao}</p>
                                            <p style={{margin: '0.5rem'}}><b>Recebedor: </b>{tracking?.recebedor}</p>
                                            <p style={{margin: '0.5rem'}}><b>Ação: </b>{tracking?.acao}</p>
                                        </>
                                        )
                                    })}

                                </Collapse>
                            </Col>
                        </Row>
                    </div>
                </Col>
                {/* <Col md={6}>
                    <div key="div-page" style={{ marginTop: '2rem', padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                        <h5>Pagamento</h5><hr/>
                        <Row>
                            <Col md={12}>

                            </Col>
                        </Row>
                    </div>
                </Col> */}
            </Row>
            <br/>
            <br/>
        </Div>
        )
    }
}

export default ResumoPostagem;