import axios from "axios";
import { useState } from "react"
import { MdAdd } from "react-icons/md";
import Select from 'react-select';
import { toast } from "react-toastify";
import { Button, Col, Collapse, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";

var CurrencyInput = require('react-currency-masked-input');


export const list_distracts = [
    {value: 'distrato1', label: 'Distrato 1' },
    {value: 'distrato2', label: 'Distrato 2' }
];

export const ModalComplience = (props) => {
    const [limit_pos, setLimitPos] = useState('');
    const [limit_online, setLimitOnline] = useState('');
    const [block_transaction, setBlockTransaction] = useState('');
    const [distracts, setDistracts] = useState([]);
    const [status_distract, setStatusDistract] = useState(null);
    const [file_distract, setFileDistract] = useState(null);
    const [file_distract64, setFileDistract64] = useState('');
    const [distract_show, setDistractShow] = useState(false);
    const [distract_input, setDistractInput] = useState('');
    const [loading, setLoading] = useState(false);

    const onValor = (event, stepCurrent) => {
        var valor_current = event.target.value;
        if (valor_current.length > 1) {
            valor_current = valor_current.replaceAll('.', '');
            var digits = valor_current.substr(valor_current.length - 2);
            valor_current = valor_current.slice(0, -2);
            valor_current = valor_current + '.' + digits;
        }

        var valido = /^[\d,.?!]+$/.test(valor_current);
        if (valido === true || valor_current === '') {
            stepCurrent(valor_current);
        }
    };

    const addDistract = () => {
        var dist = distracts;

        if(status_distract == null){
            toast.error('O Distrato é obrigatório');
            return false;
        }
        
        dist.push({
            "status": status_distract.label,
            "status_label": status_distract.label,
            "description": distract_input,
            "file": file_distract,
            'file64': file_distract64
        });

        setDistracts(dist);
        setDistractInput('');
        setFileDistract(null);
        setStatusDistract(null);
        setDistractShow(false);
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(
            setFileDistract64(reader.result)
        );
        reader.onerror = error => reject(error);
    });

    const handleFileChange = (e) => {
        var name = e.target.name;
        if(e.target.files.length > 0){
            setFileDistract(e.target.files[0]);
            toBase64(e.target.files[0]);
        }
    }

    const onClose = () => {
        setLimitPos('');
        setLimitOnline('');
        setBlockTransaction('');
        setDistracts([]);
        setStatusDistract(null);
        setFileDistract(null);
        setDistractShow(false);
        setDistractInput('');
        setLoading(false);
        props.onClose();
    }

    const onSave = () => {
        if(limit_online == ''){
            toast.error('Informe o limite online');
            return '';
        }

        if(limit_pos == ''){
            toast.error('Informe o limite pos');
            return '';
        }

        /* if(block_transaction == ''){
            toast.error('Selecione a opção de "Deseja selecionar transação?"');
            return '';
        } */


        setLoading(true);
        var url = ENDPOINT+"payout/store_complience";

        var formData = new FormData();
        formData.append('transacao_id', props.transacao_id);
        formData.append('limit_pos', limit_pos);
        formData.append('limit_online', limit_online);
        formData.append('document', props.document);
        //formData.append('bloquear_transacao', block_transaction);

        distracts.forEach((el, i) => {
            formData.append('distracts['+i+']', JSON.stringify(el));
        });
        
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            toast.success(data.message);

            if(props.onRefresh){
                props.onRefresh();
            }

            onClose();
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                toast.error(obj.errors);
                return obj;
            }

            var data = error.response.data;
            toast.error(data.errors);
            return data;
        });
    }
    return (
        <Modal isOpen={props.visible} size="lg">
            <ModalHeader>
                Time complience
            </ModalHeader>
            <ModalBody >
                <Form>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Limite liberado terminal POS:</Label>
                                <CurrencyInput
                                    inputMode="tel"
                                    className="form-control"
                                    currency="BRL"
                                    name="limit_pos"
                                    defaultValue={'0.00'}
                                    onChange={e => onValor(e, setLimitPos)} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Limite liberado On-line:</Label>
                                <CurrencyInput
                                    inputMode="tel"
                                    className="form-control"
                                    currency="BRL"
                                    name="limit_online"
                                    defaultValue={'0.00'}
                                    onChange={e => onValor(e, setLimitOnline)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        {/* <Col>
                            <FormGroup>
                                <Label>Bloquear transação:</Label><br/>
                                <input 
                                    type="radio" 
                                    name="block_transaction"
                                    checked={block_transaction == 'nao'}
                                    onChange={() => setBlockTransaction('nao')}
                                /> Não<br/>
                                <input 
                                    type="radio" 
                                    name="block_transaction"
                                    checked={block_transaction == 'sim'}
                                    onChange={() => setBlockTransaction('sim')}
                                /> Sim<br/>
                                <input 
                                    type="radio" 
                                    name="block_transaction"
                                    checked={block_transaction == 'autorizacao'}
                                    onChange={() => setBlockTransaction('autorizacao')}
                                /> Pedir autorização<br/>
                            </FormGroup>
                        </Col> */}
                        <Col>
                            <FormGroup>
                                <Label>Distratos:</Label><br/>
                                <Button 
                                    color="success"
                                    hidden={distract_show}
                                    onClick={() => setDistractShow(true)}
                                ><MdAdd />Adicionar</Button>
                                <Collapse isOpen={distract_show}>
                                    <Form>
                                        <FormGroup>
                                            <Select 
                                                placeholder="Status" 
                                                options={list_distracts}
                                                onChange={(status) => setStatusDistract(status)}
                                                value={status_distract}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input 
                                                type="textarea"
                                                name="descricao"
                                                value={distract_input}
                                                placeholder="Insira a descrição do distrato"
                                                onChange={(e) => setDistractInput(e.target.value)} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input 
                                                type="file"
                                                name="file_distract"
                                                onChange={handleFileChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Button
                                                onClick={() => {
                                                    setStatusDistract(null);
                                                    setDistractInput('');
                                                    setFileDistract(null);
                                                    setFileDistract64('');
                                                }}
                                            >Cancelar</Button>
                                            <Button
                                                onClick={() => addDistract()}
                                            >Salvar</Button>
                                        </FormGroup>
                                    </Form>
                                </Collapse>
                                <div>
                                    {distracts.map((p, i) => (
                                        <p>#{i+1} {p.status} - {p.description}</p>
                                    ))}
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={() => onClose()}>Cancelar</Button>
                <Button color="success" onClick={() => onSave()}>Salvar</Button>
            </ModalFooter>
        </Modal>
    )
}