import React, { Component } from 'react';
import { Button, Card, CardBody, TabPane } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Moment from 'moment';
import { ENDPOINT } from '../../auth/endpoint';
import axios from 'axios';
import { getToken } from '../../auth/set_services';
import { TableTicket } from '../Tables/TableTicket/TableTicket';
import { ImTicket } from 'react-icons/im';
import CreateTicket from '../Modals/CreateTicket/CreateTicket';

const getRows = (email, state, callbackRows) => {
    if(typeof email === 'undefined'){
        return null;
    }

    if(email.length === 0){
        return null;
    }

    var url = ENDPOINT + "ticket-admin/list?email="+email+"&search=" + state.search + "&page=" + state.activePage;
    
    if(state.protocolo_atendimento.length > 0){
        url += '&protocolo_atendimento=' + state.protocolo_atendimento;
    }

    if (state.filter === true) {
        url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }

    axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + getToken()
      }
    }).then((response) => {
      callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
  }
  
const getRowsPerPage = (email, page, search, filter, state, callbackRows) => {
    if(typeof email === 'undefined'){
        return null;
    }

    if(email.length === 0){
        return null;
    }

    var url = ENDPOINT + "ticket-admin/list?email="+email+"&search=" + search + "&page=" + page;
  
    if(state.protocolo_atendimento.length > 0){
        url += '&protocolo_atendimento=' + state.protocolo_atendimento;
    }
    
    if (filter === true) {
      url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }
  
    axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + getToken()
      }
    }).then((response) => {
      callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

class TicketClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            activePage: 1,
            loading_table: true,
            //email: getId(),
            totalPage: 1,
            filter: false,
            visible_ticket: false,
            search: '',
            protocolo_atendimento: '',
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.rowData = [];
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(this.props.email, 1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.email !== this.props.email) {
            getRows(nextProps.email, this.state, this.callbackRows.bind(this))
        }
    }
    componentDidMount() {
        getRows(this.props.email, this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    onFilter = (filter) => {
        this.setState({ filter, rowData: [], activePage: 1, loading_table: true });
        getRowsPerPage(this.props.email, 1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(this.props.email, activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onCloseTicket = (visible_ticket) => {
        if(visible_ticket === false){ 
            getRows(this.props.email, this.state, this.callbackRows.bind(this));
        }

        this.setState({visible_ticket})
    }
    render() {
        return (
            <TabPane tabId={this.props.active}>
            <br />
            <h5>&nbsp;&nbsp;<ImTicket style={{width: '30px', height: '30px'}}/> Ticket's de {this.props.name}</h5>
            <br/>
            <div style={{textAlign: 'right'}} hidden={!this.props.isCreate}>
                <Button onClick={() => this.onCloseTicket(true)} style={{marginBottom: 10}} color="success"><ImTicket /> Novo Ticket</Button>
            </div>
            <Card>
                <TableTicket
                    clientTicket
                    isSearch={this.handleChange}
                    data={this.state.rowData}
                    onFilter={this.onFilter.bind(this)}
                    onPageChange={this.onPageChange.bind(this)}
                    filter={this.state.filter}
                    noDataComponent={this.state.loading_table === true ?
                        <Card style={{ border: 0, padding: '10vh' }}>
                            <CardBody>
                                <div>
                                    <div className="sweet-loading">
                                        <PropagateLoader color={'#52c565'} loading={20} size={30} />
                                    </div>
                                </div>
                            </CardBody>
                        </Card> 
                        :
                        <Card style={{ border: 0 }}>
                            <CardBody>
                                <div>
                                    Nenhum ticket encontrado
                                </div>
                            </CardBody>
                        </Card>
                    }
                    activePage={this.state.activePage}
                    totalPage={this.state.totalPage}
                    date_from={this.state.date_from}
                    date_to={this.state.date_to}
                />
                </Card>
                <CreateTicket 
                    onClose={() => this.onCloseTicket(false)}
                    visible={this.state.visible_ticket}
                />
            </TabPane>
        )
    }
}

export default TicketClient;