import React from 'react';
import { HiViewGrid } from 'react-icons/hi';
import { Button, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getPermissoes, getToken } from '../../../auth/set_services';
import ReactTooltip from 'react-tooltip';
import 'react-dropdown/style.css';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { FaUsers } from 'react-icons/fa';

export const columns = (handleOpenUsers) => [
  {
    name: '',
    selector: 'buttons',
    width: '5%',
    cell: row => <Row>
      <Button data-tip="Resumo do Cliente"
        outline
        style={{ padding: '0px', marginRight: '3px', border: 0 }}
        color="primary" onClick={() => {
            window.location.href = '/querobus-cupom/' + row.id
          }
        }>
        <HiViewGrid style={{ padding: '2px', width: '23px' }} />
      </Button>
      <Button data-tip="Clientes que utilizaram"
        outline
        style={{ padding: '0px', marginRight: '3px', border: 0 }}
        color="success" 
        onClick={() => handleOpenUsers(row.id)}
        >
        <FaUsers style={{ padding: '2px', width: '23px' }} />
      </Button>
      
      <ReactTooltip />
    </Row>
  },
  {
    name: "Código",
    selector: "code",
    sortable: false,
  },
  {
    name: "Qtd Utilizado",
    selector: "qtd",
    sortable: false,
    cell:  row => {
        return row.coupons?.length ? row.coupons.length : 0;
    }
  },
  {
    name: "Data de criação",
    selector: "booking_date",
    sortable: false,
    cell: row => moment(row.created_at).format('DD/MM/YYYY HH:mm')
  },
  {
    name: "Atualizado",
    selector: "updated_at",
    sortable: false,
    cell: row => moment(row.updated_at).format('DD/MM/YYYY HH:mm')
  },
  {
    name: "Status",
    selector: "status_booking",
    sortable: false,
    cell:  row => {
        if (row.status) {
            return 'Ativado';
        } else {
            return 'Destivado';
        }
    }
  },
  {
    name: "Tipo",
    selector: "type",
    sortable: false,
    cell: row => {
        if (row.type == 'p') {
            return 'Porcentagem';
        } else {
            return 'Fixo';
        }
    }
  },
  {
    name: "Valor",
    selector: "toll_price",
    sortable: false,
    cell: row => {
        if (row.type == 'p') {
            return `${parseFloat(row.value)}%` 
        } else {
            return parseFloat(row.value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
        }
        
    }
  },
  {
    name: "Limite",
    selector: "limit",
    sortable: false,
    cell: row => {
        if (row.limit) {
            return row.limit_total;
        } else {
            return 'Não';
        }
    }
  },
];

export const getRows = (state, callback) => {
  var url = ENDPOINT + "querobus/cupom/list?page=" + state.activePage + "&search=" + state.search;

  var document = state.document.replace(/([^\d])+/gim, '');
  if (state.is_cnpj === false) {
    url += "&cpf=" + document;
  }
  else {
    url += "&cnpj=" + document;
  }

  if(parseFloat(state.valor_ate) > 0){
    url += "&valor_de="+state.valor_de;
  }

  if(parseFloat(state.valor_ate) > 0){
      url += "&valor_ate="+state.valor_ate;
  }

  if (state.status !== null) {
    url += "&status=" + state.status.value
  }

  if (state.filter === true) {
    if(state.date_from ){
      url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }
  }
  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      console.log(p)
      callback(p.data, p.last_page);
    })
    .catch(() => {
      toast.error('Ocorreu um erro no servidor!');
    })
}

export const getRowsPerPage = (page, state, callback) => {
  var url = ENDPOINT + "querobus/cupom/list?page=" + page + "&search=" + state.search;
  console.log(state.document)
  var document = state.document.replace(/([^\d])+/gim, '');
  if (state.is_cnpj === false) {
    url += "&cpf=" + document;
  }
  else {
    url += "&cnpj=" + document;
  }

  if(parseFloat(state.valor_ate) > 0){
      url += "&valor_de="+state.valor_de;
  }
  
  if(parseFloat(state.valor_ate) > 0){
      url += "&valor_ate="+state.valor_ate;
  }

  if (state.status !== null) {
    url += "&status=" + state.status.value
  }
  if (state.filter === true) {
    if(state.date_from ){
      url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
    }
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      console.log(p)
      callback(p.data, p.last_page)
    })
    .catch(() => {
      toast.error('Ocorreu um erro no servidor!');
    })
}
