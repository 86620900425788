import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import { MdMail } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';
import Moment from 'moment';


/* const getStatus = (status) => {
    console.log(status)
    if(status === 1){
        return "Transação inexistente"
    }
    else if(status === 2){
        return "Passagem Duplicada"
    }
    else{
        return "Cobrança de valor incorreto"
    }
} */

export const columns = () => [
  {
    name: "Assunto",
    selector: "subject",
    sortable: false,
    width: '25%',
    cell: row => <p>{row.subject}</p>
  },
  {
    name: "E-mail",
    selector: "to_email_address",
    sortable: false,
    width: '25%'
  },
  {
    name: 'Mensagem',
    selector: 'message',
    sortable: false,
    width: '25%',
    cell: row => <div style={{maxHeight: '150px', overflow: 'auto'}} dangerouslySetInnerHTML={{__html: row.message}} />
  },
  {
    name: "Status",
    selector: "opened",
    sortable: false,
    width: '30%',
    cell: row => row.mail.length > 0 ?
      <div data-tip={getStringMaps(row.mail)} style={{ maxHeight: '200px', 
      cursor: 'pointer', overflow: 'auto', width: '100%', margin: '12px' }}>
        <ul style={{ listStyleType: 'none' }}>
          {row.mail.slice(0, 1).map((p) => {
            return <li>
              <p><MdMail /> <b>{getTranslate(p.recordType)}</b>
                <br />
                {Moment(new Date(p.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')}</p>
            </li>
          })}
        </ul>
        <ReactTooltip multiline={true} />
      </div>
      :
      <div data-tip={"Postado " + Moment(new Date(row.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')} style={{ cursor: 'pointer', maxHeight: '200px', overflow: 'auto', width: '100%', margin: '12px' }}>
        <ul>
          <li style={{ listStyleType: 'none' }}><MdMail /> <b>Postado</b><br />{Moment(new Date(row.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')}
          </li>
        </ul>
        <ReactTooltip multiline={true} />
      </div>
  }
];
const getStringMaps = (mail) => {
  var string_mail = "";
  mail.forEach((p) => {
    string_mail += getTranslate(p.recordType) + " " + Moment(new Date(p.created_at).toString()).format('DD/MM/YYYY h:mm:ss a') + "<br /> "
  });

  return string_mail;
}

const getTranslate = (name) => {
  switch (name) {
    case 'Delivery': return 'Entregue'
    case 'Open': return 'Aberto'
    case 'Click': return 'Clicado'
    case 'SpamComplaint': return 'Caixa de SPAM'
    case 'Bounce': return 'Mensagem devolvida'
    default : return name;
  }
}

export const getRows = (state, callbackRows) => {
  
  var url = ENDPOINT + "emails/list";

  if (state.email.length > 0) {
    url += '?email=' + state.email
  }
  
  if (state.cpf_cnpj.length > 0) {
    url += '&cpf_cnpj=' + state.cpf_cnpj
  }

  axios.get(url, {
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  }).then((response) => {
    callbackRows(response.data.data, response.data.current_page, response.data.last_page)
  });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
  
  var url = ENDPOINT + "emails/list?search=" + search + "&page=" + page;

  if (filter === true) {
    url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
  }

  if (state.email.length > 0) {
    url += '&email=' + state.email
  }

  if (state.cpf_cnpj.length > 0) {
    url += '&cpf_cnpj=' + state.cpf_cnpj
  }

  axios.get(url, {
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  }).then((response) => {
    callbackRows(response.data.data, response.data.current_page, response.data.last_page)
  });
}