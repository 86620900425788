import moment from 'moment';
import React from 'react';
import { Button, Form, FormGroup, Input, TabPane } from 'reactstrap';
import { InputText } from '../InputText/InputText';

const get_status_auth = (payment_type) => {
    switch(payment_type){
        case 0 : return 'Aguardando Validação';
        case 1 : return 'Validado';
        case 2 : return 'Perdido';
        default: return ''
    }
}
const get_status = (payment_type) => {
    switch(payment_type){
        case 'enabled' : return 'Ativo';
        case 'inactive' : return 'Inativo';
        case 'pending' : return 'Pendente';
        case 'failed' : return 'Falhada';
        default: return payment_type
    }
}
const DataAQPago = (props) => {
    return (
        <TabPane tabId={props.active}>
            <br />
            <div>
                <p style={{margin: '0'}}><b>Dados do Titular</b></p>
                <hr style={{marginTop: '3px'}}/>
                <p><b>Tipo de Conta:</b> {props.tipo_conta}</p>
                <p><b>Nome do Titular:</b> {props.nome_titular}</p>
                <p><b>Status:</b> {get_status(props.status)}</p>
                {props.email_edit_enable !== true ?
                    <>
                    <p><b>E-mail:</b> {props.email} <Button outline onClick={() => props.editEmail(true)} color='success' style={{padding: '3px', fontSize: '12px'}}>Editar</Button></p>
                    </>
                    :
                    <>
                        <p style={{display: 'flex'}}><b>E-mail:</b>&nbsp;
                            <Input 
                                name="email_edit"
                                placeholder="E-mail"
                                value={props.email_edit}
                                onChange={props.handleChange} 
                                style={{width: '80%', fontSize: '12px'}}
                            />
                            <Button disabled={props.loading_edit} color='success' onClick={() => props.storeEmail()} style={{margin: 2, padding: 2, fontSize: '12px'}}>Salvar</Button>
                            <Button color='danger' onClick={() => props.editEmail(false)} style={{margin: 2, padding: 2, fontSize: '12px'}}>Cancelar</Button>
                        </p>
                    </>
                }
                {/* <p><b>Telefone:</b> {props.telefone} <Button outline color='success' style={{padding: '3px', fontSize: '12px'}}>Editar</Button></p> */}
                {props.telefone_edit_enable !== true ?
                    <>
                    <p><b>Telefone:</b> {props.telefone} <Button outline onClick={() => props.telefoneEdit(true)} color='success' style={{padding: '3px', fontSize: '12px'}}>Editar</Button></p>
                    </>
                    :
                    <>
                        <p style={{display: 'flex'}}><b>Telefone:</b>&nbsp;
                            <Input 
                                name="telefone_edit"
                                placeholder="Telefone"
                                value={props.telefone_edit}
                                onChange={props.handleChange}
                                style={{width: '80%', fontSize: '12px'}}
                            />
                            <Button disabled={props.loading_edit} color='success' onClick={() => props.storeTelefone()} style={{margin: 2, padding: 2, fontSize: '12px'}}>Salvar</Button>
                            <Button color='danger' onClick={() => props.telefoneEdit(false)} style={{margin: 2, padding: 2, fontSize: '12px'}}>Cancelar</Button>
                        </p>
                    </>
                }
                <p><b>Documento (CPF/CNPJ):</b> {props.documento}</p>
                <p><b>Plano:</b> {props.plano}</p>
                <p><b>Criado em:</b> {moment(new Date(props.created_at)).format('DD/MM/YYYY HH:mm')}</p>
                <p><b>Endereco:</b> {props.endereco}</p>
                <br/>
                {props.empresa !== null ? 
                    <>
                        <p style={{margin: '0'}}><b>Dados da Empresa</b></p>
                        <hr style={{marginTop: '3px'}}/>
                        <p><b>E-mail da empresa:</b> {props.empresa.email_empresa}</p>
                        <p><b>Nome da empresa:</b> {props.empresa.nome_fantasia}</p>
                        <p><b>Descrição da empresa:</b> {props.empresa.descricao_empresa}</p>
                        <p><b>Telefone da empresa:</b> {props.empresa.telefone_empresa}</p>
                    </>
                : <></>}
            </div>
        </TabPane>
    )
}

export default DataAQPago;