import React, { Component } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { TablePayout } from "../../components/Tables/TablePayout/TablePayout";
import { getAllRows, getAllRowsPerPage } from "../../components/Tables/TablePayout/propsPayout";
import { Card, CardBody } from 'reactstrap';
import PayoutModal from "../../components/Modals/PayoutModal/PayoutModal";
import Moment from "moment";
import { ModalComplience } from "../../components/Modals/ModalComplience/ModalComplience";

class TodosPayouts extends Component{
    constructor(props){
        super(props);
        this.state = {
            search: '',
            loading_table: true,
            edit_id: -1,
            edit_user: null,
            rowData: [],
            cpf_current: null,
            cnpj_current: null,
            phone_current: null,
            status_pagamento: null,
            forma_pagamento: null,
            transacao_id: null,
            modalidade: null,
            status_user: '',
            type_user: '',
            transaction_id: -1,
            totalPage: 0,
            activePage: 1,
            valor_de: '0.00',
            valor_ate: '0.00',
            visible_details: false,
            visible_complience: false,
            is_cnpj: false,
            document: '',
            filter: false,
            show_filter: false,
            payload_current: {},
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
        this.refDocument = React.createRef();
    }

    componentDidMount(){
        getAllRows(this.state, this.callbackRows.bind(this));    
    }
    
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    handleChange = (event) => {
        
        var state = this.state;
        let name = event.target.name;
        let value = event.target.value;

        console.log(name)
        console.log(value)

        state[name] = value;
        
        this.setState({...state});
/* 
        console.log(name);
        if(name === 'document'){
            if(value.replace(/([^\d])+/gim, '').length > 10){
                state[name] = value;
                state.activePage = 1;
                state.rowData = [];
                state.loading_table = true;
                this.setState({...state});
                
                getAllRowsPerPage(1, state, this.callbackRows);
            }
            else if(value.replace(/([^\d])+/gim, '').length === 0){
                if(state[name].length > 0){
                    state[name] = value;
                    state.activePage = 1;
                    state.rowData = [];
                    state.loading_table = true;
                    this.setState({...state});
                    
                    getAllRowsPerPage(1, state, this.callbackRows);
                }
            }
        }
        else{
            state[name] = value;
            state.activePage = 1;
            state.rowData = [];
            state.loading_table = true;
            this.setState({...state});
            
            getAllRowsPerPage(1, state, this.callbackRows);
        } */
    }
    filterShow(){
        this.setState({
            show_filter: !this.state.show_filter
        })
    }

    onChangeSelect = (name, value) => {
        var state = this.state;
        //state.rowData = [];
        //state.loading_table = true;
        state[name] = value;
        this.setState({...state});

        //getAllRows(state, this.callbackRows.bind(this));
    }
    
    
    callbackRows = (rowData, totalPage) => {
        console.log(rowData)

        this.setState({rowData, totalPage, loading_table: false})
    }

    onPageChange = (activePage) => {
        var state = this.state;

        state.activePage = activePage;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});

        getAllRowsPerPage(activePage, state, this.callbackRows.bind(this));
    }
    onPayload = (payload_current) => {
        this.setState({
            payload_current,
            visible_payload: true
        })
    }
    onFilter = (filter) => {
        var state = this.state;
        state.filter = filter;
        //state.activePage = 1;
        this.setState({ ...state });
        //getAllRowsPerPage(state.activePage, state, this.callbackRows.bind(this));
    }
    onClose = () => {
        var state = this.state;
        state.visible_payload = false;
        state.payload_current = {};
        this.setState({ ...state });
        
        getAllRowsPerPage(state.activePage, state, this.callbackRows.bind(this));
    }
    onSellerDocument = (row) => {
        var document = row.individual !== null ? row.individual.taxpayer_id : row.business.ein;

        var state = this.state;
        state.rowData = [];
        state.loading_table = true;
        state.document = document;
        state.is_cnpj = document.length > 13 ? true : false;
        state.activePage = 1;
        this.setState({ ...state });
        //console.log(this.refDocument)
        this.refDocument.current.value = document;

        getAllRowsPerPage(1, state, this.callbackRows.bind(this));        
    }

    buscarVendas = () => {
        var state = this.state;
        state.rowData = [];
        state.loading_table = true;
        state.activePage = 1;
        this.setState({ ...state });
        
        getAllRowsPerPage(1, state, this.callbackRows.bind(this));
    }

    onOpenClosedComplience = (visible_complience = false, transaction_id = -1) => {
        this.setState({ visible_complience, transaction_id })
    }

    render(){
        return (
            <div>
                <h3>Transações de Vendas</h3>
                <hr />
                <PayoutModal 
                    novoBloqueio={true}
                    payload={this.state.payload_current}
                    visible={this.state.visible_payload}
                    onClose={() => this.onClose()}
                />
                {/* <ModalComplience
                    visible={this.state.visible_complience}
                    onClose={() => this.onOpenClosedComplience()}
                    onRefresh={() => getAllRows(this.state, this.callbackRows.bind(this))}
                    transacao_id={this.state.transaction_id}
                /> */}
                <TablePayout
                    isSearch={this.handleChange}
                    onChangeSelect={this.onChangeSelect}
                    onSearchDocument={this.onSearchDocument.bind(this)}
                    onSellerDocument={this.onSellerDocument}
                    data={this.state.rowData}
                    state={this.state}
                    is_cnpj={this.state.is_cnpj}
                    show_filter={this.state.show_filter}
                    buscarVendas={this.buscarVendas}
                    onFilter={this.onFilter.bind(this)}
                    date_from={this.state.date_from}
                    date_to={this.state.date_to}
                    filter={this.state.filter}
                    refDocument={this.refDocument}
                    filterShow={this.filterShow.bind(this)}
                    onOpenClosedComplience={this.onOpenClosedComplience}
                    onPageChange={this.onPageChange}
                    activePage={this.state.activePage}
                    totalPage={this.state.totalPage}
                    onPayload={this.onPayload}
                    noDataComponent={ this.state.loading_table === true ? 
                        <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                            <PropagateLoader color={'#52c565'} loading={20} size={30} />
                        </div></div></CardBody></Card> :
                        <Card style={{border: 0}}><CardBody><div>
                            Nenhum usuário encontrado
                        </div></CardBody></Card>
                    }
                />
            </div>
        )
    }
}

export default TodosPayouts;