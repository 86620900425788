import axios from 'axios';
import moment from 'moment';
import { FaEdit, FaEye, FaRegFilePdf, FaTrash } from 'react-icons/fa';
import { HiViewGrid } from 'react-icons/hi';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import { ENDPOINT } from '../../../../auth/endpoint';
import { getToken } from '../../../../auth/set_services';
import { fetchWithTimeout } from '../../../Tools/fetchWithTimeout';
import ClipLoader from 'react-spinners/ClipLoader';

const actionSwitch = (param) => {
  switch(param) {
    case 'C':
      return 'Coletado';
    case 'T':
      return 'Transferido';
    case 'E':
      return 'Entregue';
    case 'R':
      return 'Retirado';
    default:
      return param;
  }
}

export const columns = (props, handleOpenLabelPDF, handleDownloadLabelPDF) => [
    {
        name: "",
        selector: "buttons",
        sortable: false,
        width: '10%',
        cell: row => 
        <Row>
            <Button
            outline
            style={{ padding: 2, marginLeft: 15, border: 0, fontWeight: '700'}}
            onClick={() => {
                window.location.href = '/aqenvio-etiqueta-detalhes/'+row.id
            }}>
                <HiViewGrid style={{ padding: '2px' }}/>
            </Button>
        </Row>
    },
    {
      name: "Cliente",
      selector: "seller",
      sortable: false,
      width: '20%',
    },
    {
      name: "Código",
      selector: "code",
      sortable: false,
      width: '15%',
    },
    {
      name: "Data",
      selector: "data",
      sortable: false,
      width: '15%',
      cell: row => <Row style={{width: '100%'}}>
        <Col md={12}>{moment(row.created_at).format('DD/MM/YYYY HH:mm')}</Col>
      </Row>
    },
    {
      name: "Status",
      selector: "data",
      sortable: false,
      width: '15%',
      cell: row => <Row style={{width: '100%'}}>
        <Col md={12}>{row.status}</Col>
      </Row>
    },
    {
      name: "Ação",
      selector: "data",
      sortable: false,
      width: '25%',
      cell: row => <>
        <Row style={{width: '100%'}}>
            {row.tracking_history?.situacao?.ocorrencia ? (
                <Col md={12} style={{margin: '1rem 0'}}>
                  <p><strong>Ocorrência:</strong> {row.tracking_history?.situacao?.ocorrencia}</p>
                  <p><strong>Data:</strong> {moment(row.tracking_history?.situacao?.dataHora).format('DD/MM/YYYY HH:mm')}</p>
                  <p><strong>Observacao:</strong> {row.tracking_history?.situacao?.observacao}</p>
                </Col>
            ) : (
              <>
              <Col md={12} style={{marginBottom: '10px'}}>
                {row.label ? (
                      <Button
                          outline
                          color="info"
                          onClick={() => {
                              return handleDownloadLabelPDF(row.code)
                          }}>
                          {props.state.loading_download ? (
                            <>
                              <ClipLoader color="#fff" size={12}/>
                              &nbsp;Abrindo...
                            </>
                          ) : (
                            <>
                              <FaRegFilePdf />&nbsp;Download
                            </>
                          )}
                      </Button>
                  ) : (
                      <></>
                  )}
              </Col>
              <Col md={12}>
                  {row.label ? (
                      <Button
                        outline
                        color="info"
                        onClick={() => {
                            return handleOpenLabelPDF(row.code)
                        }}>
                          {props.state.loading_view ? (
                            <>
                              <ClipLoader color="#fff" size={12}/>
                              &nbsp;Abrindo...
                            </>
                          ) : (
                            <>
                              <FaRegFilePdf />&nbsp;Visualizar
                            </>
                          )}
                      </Button>
                  ) : (
                      <></>
                  )}
              </Col>
              </>
            )}
      </Row>
      </>
    },
];

var numberOfAjaxCAllPending = 0;

export const getRows = (state, callback) => {
  if(numberOfAjaxCAllPending == 0){
    numberOfAjaxCAllPending = 1;

    var url = ENDPOINT + "aqenvio/show_labels?page=" + state.activePage + "&search=" + state.search;
    //var url = "http://localhost:8000/api/aqenvio/show_labels?page=" + state.activePage + "&search=" + state.search;

    if (state.filter_type_payment_select?.value && state.filter_type_payment_select.value !== null) {
      url += "&type_payment="+state.filter_type_payment_select.value;
    }      
    if (state.filter_status?.value && state.filter_status.value !== null) {
      url += "&status="+state.filter_status.value;
    }      
    if (state?.date_from && state.date_from !== null) {
      url += "&date_from="+state.date_from;
    }  
    if (state?.date_to && state.date_to !== null) {
      url += "&date_to="+state.date_to;
    }

    axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + getToken()
        },
        mode: 'cors',
        cache: 'default'
    })
    .then((response) => {
        let p = response.data;
        if(p.success == true){
          var meta = 0;
          if(p.meta){
            meta = p.meta.last_page;
          }
          numberOfAjaxCAllPending = 0;
          // callback(p.data, meta, p.valor);
          callback(p.data, meta);
        }
        else{
          toast.error(p.errors);
        }
    })
    .catch((error) => {
        toast.error('Ocorreu um erro no servidor!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          //console.log(error.config);

      callback({}, {});
    })
  }
  else{
    //callback(state.rowData, state.totalPage, []);
  }
}

export const getRowsPerPage = (page, state, callback) => {
  //if(numberOfAjaxCAllPending == 0){
    numberOfAjaxCAllPending = 1;

    var url = ENDPOINT + "aqenvio/show_labels?page=" + state.activePage + "&search=" + state.search;
    //var url = "http://localhost:8000/api/aqenvio/show_labels?page=" + state.activePage + "&search=" + state.search;

    if (state.filter_type_payment_select?.value && state.filter_type_payment_select.value !== null) {
      url += "&type_payment="+state.filter_type_payment_select.value;
    }      
    if (state.filter_status?.value && state.filter_status.value !== null) {
      url += "&status="+state.filter_status.value;
    }         
    if (state?.date_from && state.date_from !== null) {
      url += "&date_from="+state.date_from;
    }  
    if (state?.date_to && state.date_to !== null) {
      url += "&date_to="+state.date_to;
    }

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };

    fetchWithTimeout(20000, fetch(url, myInit))
    .then(response => response.json())
    .then((p) => {
      if(p.success == true){
        var meta = 0;
        if(p.meta){
          meta = p.meta.last_page;
        }
        numberOfAjaxCAllPending = 0;
        // callback(p.data, meta, p.valor);
        callback(p.data, meta);
      }
      else{
        toast.error(p.errors);
      }
    })
    .catch((error) => {
      toast.error('Ocorreu um erro no servidor!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      
      callback({}, {});
    })
  // }
  // else{
  //   //callback(state.rowData, state.totalPage, []);
  // }
}