import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import { MessageBox } from 'react-chat-elements';
import { MdAttachFile, MdSend, MdViewList, MdSupervisorAccount, MdImage, MdOpenInBrowser, MdBlock, MdFileDownload, MdLock, MdReply, MdInfo, MdClear, MdWarning } from "react-icons/md";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Button, ButtonGroup, Card, CardFooter, Row, Col, Input, CardBody, Collapse, ModalBody, Modal, ModalFooter } from "reactstrap";
import styled from "styled-components";
import { ENDPOINT } from "../../auth/endpoint";
import Dropzone from "react-dropzone";
import { getToken, getUser } from "../../auth/set_services";
import get_extension from 'get-url-extension';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import ReactRoundedImage from "react-rounded-image";
import Pusher from 'pusher-js';
import PropagateLoader from "react-spinners/PropagateLoader";
import ReactTooltip from 'react-tooltip';
import FadeLoader from "react-spinners/FadeLoader";
import { toast } from "react-toastify";
import ModalDialog from "../Modals/ReturnsApiModals/ModalDialog";
import TextareaAutosize from 'react-textarea-autosize';
import AddAtendentesGroupModal from "../../pages/Chat/components_chat/AddAtendentesGroupModal";

const DropArea = styled.div`
    border-style: dashed;
    border-color: #8300b6;
    padding: 1%;
    cursor: pointer;
    &:drag {
        opacity: 0.5;
    }
`;
const DropdownGrupo = styled.div`
    button{
        padding: 0;
        background-color: transparent;
        border-color: transparent;
    }
    button:hover{
        padding: 0;
        background-color: transparent;
        border-color: transparent;
    }
    .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
        color: #000;
        background-color: transparent;
        border-color: transparent;
    }
    .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
        box-shadow: none;
    }

    .btn-primary.focus, .btn-primary:focus {
        color: #000;
        background-color: transparent;
        border-color: transparent;
        box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 50%);
    }
    .dropdown-toggle::after{
        display: none
    }
    .dropdown svg{
        color: black
    }
`;

const ButtonUpload = styled.button`
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0;
    padding-left: 7px;
    padding-right: 7px;
    top: -1px;
    position: relative;
`;

const Div = styled.div`
    .msg{
        margin-top: 7px;
        font-size: 14px;
        color: black !important;
        margin-right: 5px;
        resize: none;
        height: auto !important;
        max-height: 93px !important;
        border-right: none;
        border-left: none;
        border-top: none;
        border-bottom: none;
        overflow: auto;
        box-shadow: none !important;
        border: none !important;
        line-height: 14px;
        outline: 0px solid transparent !important;
    }
    .msg:focus{
        box-shadow: none !important;
        border: none !important;
    }
    audio{
        height: 35px;
    }
    .nav-item{
        margin: 0
    }
    .widget_btn{
        padding: 0;
        padding-left: 2.5px;
        padding-right: 2.5px;
        border: none;
        box-shadow: none !important;
    }
    .talk_btn:hover{
        color: black;
        background: transparent !important;
        box-shadow: none !important;
    }
    
    .talk_btn:active{
        background: transparent !important;
        box-shadow: none !important;
    }
    
    .talk_btn:visited{
        background: transparent !important;
        box-shadow: none !important;
    }
    
    .talk_btn:active{
        background: transparent !important;
        box-shadow: none !important;
    }
    .nav-link{
        padding: 4px;
        padding-right: 6px;
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
    .rce-citem-avatar{
        display: none;
    }
    .rce-mbox-left-notch {
        position: absolute;
        left: -14px;
        top: 0px;
        width: 24px;
        height: 15px;
        fill: white;
    }
    .rce-mbox-right .rce-mbox-time{
        color: rgba(0, 0, 0, 0.45);
    }
    .rce-mbox-photo--img__block {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        border-radius: 5px;
        display: flex;
        max-width: 300px;
    }
    .rce-mbox-photo--img {
        min-height: 180px;
    }
    .rce-mbox-photo--img img {
        min-height: 180px;
        -webkit-user-select: none;
        user-select: none;
        object-fit: cover;
    }
    .rce-mbox-photo--download {
        color: #efe;
        display: flex;
        justify-content: center;
        background: none;
        border: none;
        font-size: 3.2em;
        outline: none;
        border: 1px solid #eee;
        border-radius: 100%;
        height: 92px;
        width: 92px;
    }
    
    .rce-mbox-photo--download svg{
        vertical-align: middle;
        place-self: center;
        padding: 0;
    }
    .dropdown-image .btn{
        background: transparent;
        border-radius: 50%;
        border: 1px solid;
        height: 50%;
        width: 50%;
    }
    .rce-mbox-text img{
        height: 143px
    }
    .rce-mbox-text video{
        height: 143px
    }
    .audio-react-recorder{
        display: none
    }
`;

const MessageBoxArea = styled.div`
  .dropdown-toggle:after { 
    content: none 
  }
  .dropdown-toggle:before { 
    content: none 
  }
  .dropdown-image{
      text-align: center;
  }
`;
const DropdownCurrent = styled.div`
    .btn{
        display: inline-grid;
        width: 24px;
        border: 0;
        transform-style: flat;
        background-color: transparent;
        padding: 0;
        position: relative;
        float: ${props => (props.type_message === true ? 'right' : 'left' )};
    }
    svg {
        width: 1.3rem;
        height: 1.3rem;
    }
  
    .chat-reposta { 
        display: grid;
        position: relative;
        top: ${props => (props.type_message === true ? '5px' : '5px' )};
        float: ${props => (props.type_message === true ? 'right' : 'left' )};
    }
    .chat-reposta .btn-danger svg{
        color: #e11a1a;
        width: 1.3rem;
        height: 1.3rem;
        margin-left: 5px;
    }

    .chat-reposta .btn-info svg{
        color: #0066ff;
        width: 1.5rem;
        height: 1.5rem;
        padding: 3px;
    }
  
`;

const load_messages = (cod_grupo, total_pagina, callback) => {
    if(cod_grupo !== null){
        var url = ENDPOINT + 'chat-grupo/exibir-mensagens/' + cod_grupo + '?total_pagina='+total_pagina;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            callback(data);
        })
        .catch((error) => {
            var response = '';
            var obj;

            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                callback(obj);
                return obj;
            }

            var errors = error.response.data;
            if(errors.exception){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                callback(obj);
                return obj;
            }
            else{
                callback(errors);
                return errors;
            }
        });
    }
}

class ChatGrupo extends Component{
    constructor(props){
        super(props);
        this.state = {
            isOpen: false,
            chat: [],
            chat_info: null,
            current_message: null,
            sending_message: false,
            message: '', 
            masked_message: null,
            file: [],
            total_pagina: 10,
            mensagens_total: 0,
            admin_id: -1,
            loading_chat: true,
            loading_messages: false,
            error_message: '',
            success_message: '',
            loading: false,
            visible_delete: false,
            visible_delete_group: false,
            visible_group: false
            //isOpen: false
        }
        this.myRef = React.createRef();
        this.onKeyUp = this.onKeyUp.bind(this);
        this.inputFile = React.createRef();
        this.onDrop = this.onDrop.bind(this);
    }
    componentDidMount(){
        this.setState({
            current_message: this.props.cod_grupo
        })
        this.PusherInstancia(this.props.cod_grupo, this.callbackChat.bind(this));
        load_messages(this.props.cod_grupo, this.state.total_pagina, this.callbackChat.bind(this));
    }

    PusherInstancia(cod_grupo, callback) {
        const pusher = new Pusher("2892f3b88d766b1b14d3", {
            cluster: 'us3',
        });

        this.setState({pusher_instance: pusher});

        var response = pusher.subscribe('pusher_atualizar_grupo_'+cod_grupo);
        response.bind('atualizar', () => {
            if(cod_grupo === this.state.current_message){
                load_messages(cod_grupo, this.state.total_pagina, callback);
            }
        });
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.cod_grupo !== null && nextProps.cod_grupo !== this.props.cod_grupo) {
            this.setState({
                chat: [],
                chat_info: null,
                masked_message: null,
                isOpen: false,
                current_message: nextProps.cod_grupo,
                total_pagina: 10,
                loading_chat: true
            })
            this.PusherInstancia(nextProps.cod_grupo, this.callbackChat.bind(this));
            load_messages(nextProps.cod_grupo, 10, this.callbackChat.bind(this));
        }
    }

    callbackChat(data){
        if(typeof data.chat_info !== 'undefined'){
            if(data.chat_info.cod_grupo !== null ? data.chat_info.cod_grupo === this.state.current_message : ''){
                if(data.mensagens.length !== this.state.chat.length){
                    if (typeof this.myRef !== 'undefined') {
                        if(typeof this.myRef.current  !== 'undefined'){
                            if (this.myRef.current !== null) {
                                setTimeout(() => 
                                {
                                    try{
                                        if(this.myRef.current.scrollTop){
                                            this.myRef.current.scrollTop = this.myRef.current.scrollHeight;
                                        }

                                    }
                                    catch(e){
                                        
                                    }
                                },
                                500);
                            }
                        }
                    }
                }

                try{
                    if (typeof this.myRef !== 'undefined') {
                        if(typeof this.myRef.current  !== 'undefined'){
                            if (this.myRef.current !== null) {
                                if( (data.mensagens.length !== this.state.chat.length) || 
                                    (this.state.chat_info !== null ? data.chat_info.cod_grupo !== this.state.chat_info.cod_grupo: '')
                                )
                                {
                                    setTimeout(() => {
                                        if(this.myRef.current.scrollHeight <= this.myRef.current.clientHeight){
                                            this.readMessageAll(this.state.current_message)
                                        }
                                    },
                                    1000);

                                }
                            }
                        }
                    }
                }
                catch(e){

                }
            }
            this.setState({
                chat_info: data.chat_info,
                chat: data.mensagens,
                mensagens_total: data.mensagens_total,
                //loading_note: false,
                loading_chat: false,
                loading_messages: false,
            });
        }
        else if(typeof data.errors !== 'undefined'){
            /* eslint eqeqeq: 0 */
            if(data.errors == 'Você não está nesse grupo' || data.errors == 'Esse chat não existe'){
                this.setState({
                    isOpen: true,
                    chat: [],
                    chat_info: null,
                    current_message: null,
                    sending_message: false,
                    message: '', 
                    masked_message: null,
                    file: [],
                    total_pagina: 10,
                    mensagens_total: 0,
                    admin_id: -1,
                    loading_chat: true,
                    loading_messages: false,
                    error_message: '',
                    success_message: '',
                    loading: false,
                    visible_delete: false,
                });
                this.props.fecharChat();
            }
        }
    }
    onImageModal = (modal_image, urlImage) =>{
        this.setState({modal_image, urlImage});
    }

    callbackChatNone(data){
        if(typeof data.chat_info !== 'undefined'){
            this.setState({
                chat_info: data.chat_info,
                chat: data.mensagens,
                mensagens_total: data.mensagens_total,
                //loading_note: false,
                loading_chat: false,
                loading_messages: false,
            });
        }
        else if(typeof data.errors !== 'undefined'){
            
            if(data.errors == 'Você não está nesse grupo' || data.errors == 'Esse chat não existe'){
                this.setState({
                    isOpen: true,
                    chat: [],
                    chat_info: null,
                    current_message: null,
                    sending_message: false,
                    message: '', 
                    masked_message: null,
                    file: [],
                    total_pagina: 10,
                    mensagens_total: 0,
                    admin_id: -1,
                    loading_chat: true,
                    loading_messages: false,
                    error_message: '',
                    success_message: '',
                    loading: false,
                    visible_delete: false,
                });
                this.props.fecharChat();
            }
        }
    }
    handleInputChange = (e) => {
        const { name, value } = e.target;
        
        this.setState({ [name]: value });
    }
    onKeyUp(event) {
        if (event.key === "Enter" && event.shiftKey === false) {
            this.sendValid(this.state.chat_info.cod_grupo);
            event.preventDefault();
        }
    }

    sendValid = (cod_grupo) => {
        var invalid = 0;
        if(this.state.file.length === 0){
            if(typeof this.state.message === 'undefined'){
                invalid++;
            }
            else if(this.state.message == null){
                invalid++;
            }
            else if(this.state.message.length === 0){
                invalid++;
            }
        }

        if(invalid === 0){
            this.sendMessage(cod_grupo);
        }
    }
    getHtmlExtension = (anexo_url, url, message) =>{
        if(anexo_url !== null){
            var extension = get_extension(url);

            if(extension === '.png' ||
                extension === '.PNG' ||
                extension === '.jpg' ||
                extension === '.jpeg' ||
                extension === '.svg' ||
                extension === '.webp'){
                //return <img src={url} />
                return <div class="rce-mbox-photo">
                        <div class="rce-mbox-photo--img">
                            <img src={url+'?access_token='+getToken()} alt="archive-rce"/>
                            <div class="rce-mbox-photo--img__block">
                                <DropdownButton 
                                    outline
                                    className="dropdown-image rce-mbox-photo--img__block-item"
                                    title={<MdImage style={{ padding: '3px', width: '23px' }}/>}
                                    noCaret={true}
                                >
                                    <Dropdown.Item onClick={() => this.onImageModal(true, url)}><MdOpenInBrowser />Abrir</Dropdown.Item>
                                    <Dropdown.Item onClick={() => window.open(url+'?access_token='+getToken(), '_blank')}><MdFileDownload />Download</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                    </div>
            }
            else if(extension === '.webm' ||
                extension === '.3gp' ||
                extension === '.ogg' ||
                extension === '.mp4'){
                return (<center>
                            <video controls>
                                <source src={url+'?access_token='+getToken()} />
                            </video>
                        </center>)
            }
            else if(extension === '.mp3' ||
                extension === '.wav' ||
                extension === '.ogg'){

                return (<center>
                    <audio controls>
                        <source src={url+'?access_token='+getToken()} />
                    </audio>
                </center>)
            }

            return message;
        }

        if(message === 'ESSA MENSAGEM FOI APAGADA'){
            return <div style={{fontStyle: 'italic', margin: 0, opacity: 0.8}}><MdBlock style={{ height: '1rem', marginTop: '-2px', padding: 0 }} /> ESSA MENSAGEM FOI APAGADA</div>
        }

        return message.split('\n').map((x, i, {length}) => (
            <p style={{margin: (i + 1 === length ? 0 : 3)}}>{x}</p>
        ));
    }
    getFileExtension = (url) =>{
        var extension = get_extension(url);

        if(extension === '.png' ||
            extension === '.PNG' ||
            extension === '.jpg' ||
            extension === '.jpeg' ||
            extension === '.svg' ||
            extension === '.webp'){
            return 'text'
        }
        else if(extension === '.webm' ||
            extension === '.3gp' ||
            extension === '.ogg' ||
            extension === '.mp4'){
                return 'text';
        }
        else if(extension === '.mp3' ||
            extension === '.wav' ||
            extension === '.ogg'){
            return 'text';
        }

        return 'file';
    }

    sendMessage(cod_grupo) {
        this.setState({ sending_message: true, modal_anexo: false });
        var url = ENDPOINT + 'chat-grupo/enviar-mensagem/' + cod_grupo;

        var formData = new FormData();
        formData.append('message', this.state.message);
        formData.append('cod_grupo', cod_grupo);
        formData.append('type_message', 0);
        if (this.state.masked_message !== null) {
            formData.append('codigo_message_masked', this.state.masked_message.cod_message);
        }

        if(this.state.file.length > 0){
            this.state.file.forEach((file, index) => {
                formData.append('anexo['+index+']', file);
            });
        }

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                //var data = response.data;
                //this.callbackChat(data);
                this.setState({ 
                    sending_message: false, 
                    message: '', 
                    masked_message: null, 
                    file: [],
                    is_type_message: false,
                });
            })
            .catch((error) => {
                this.setState({
                    sending_message: false, 
                    message: '', 
                    masked_message: null,
                    file: []
                });

                //toast.error('Ocorreu um erro no servidor');
            });
    }
    isOpenCurrent() {
        this.setState({ isOpen: !this.state.isOpen });
        //setTimeout(() => this.myRef.current.scrollTop = this.myRef.current.scrollHeight, 100)
    }
    onScroll(scroll) {
        try{ var scroll_current = scroll.target;
            if (scroll_current.scrollHeight - scroll_current.scrollTop === scroll_current.clientHeight) {
                this.readMessageAll(this.state.chat_info.cod_grupo);
        } }catch(ex){}
    }
    
    checar_se_existe_mensagens_nao_lidas = () => {
        var messages_pending = 0;

        this.state.chat.forEach((p, i) => {
            if(p.status_da_mensagem !== 2){
                messages_pending++;
            }
        });

        if(messages_pending === 0){
            return false;
        }

        return true;
    }

    readMessageAll(cod_grupo) {
        if(this.checar_se_existe_mensagens_nao_lidas() === true){
            var url = ENDPOINT + 'chat-grupo/ler-todas-as-mensagens';

            var formData = new FormData();
            formData.append('cod_grupo', cod_grupo);

            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer ' + getToken()
                }
            })
            .then((response) => {})
            .catch((error) => {});
        }
    }

    textOperadoresRead = (data) => {
        var text = '';

        var operadores_lidos = '';
        var valid_lidos = 0;
        data.forEach((p) => {
            if(p.status === 2){
                if(p.operadores.id !== getUser().id){
                    operadores_lidos += p.operadores.name+'<br/>';
                    valid_lidos++;
                }
            }
        });

        if(valid_lidos > 0){
            text += 'Lido por <br/>'+ operadores_lidos;
        }


        var operadores_entregue = '';
        var valid_entregue = 0;
        data.forEach((p) => {
            if(p.status === 1){
                operadores_entregue += p.operadores.name+'<br/>';
                valid_entregue++;
            }
        });

        if(valid_entregue > 0){
            text += 'Entregue a <br/>'+ operadores_entregue;
        }

        
        var operadores_enviados = '';
        var valid_enviados = 0;
        data.forEach((p) => {
            if(p.status === 0){
                operadores_enviados += p.operadores.name+'<br/>';
                valid_enviados++;
            }
        });

        if(valid_enviados > 0){
            text += 'Enviado a: <br/>'+ operadores_enviados;
        }

        return text;

    }
    onMaskedMessage(data, nome) {
        var json = {
            cod_message: data.cod_message,
            nome: nome,
            message: data.message
        }

        this.setState({ masked_message: json });
    }
    isLoadingButton(){
        if(this.state.loading_chat === true){
            return true;
        }
        else if(this.state.mensagens_total > this.state.chat.length ){
            //return true;
            return false;
        }
        else{
            return true;
        }
    }
    isMoreLoading(){
        this.setState({
            loading_messages: true,
            total_pagina: this.state.total_pagina + 10
        });

        load_messages(this.props.cod_grupo, this.state.total_pagina + 10, this.callbackChatNone.bind(this));
    }
    atribuicaoOperador(operador_id, atribuicao){
        var url = ENDPOINT + 'chat-grupo/atribuicao-operador-grupo';

        var formData = new FormData();
        formData.append('cod_grupo', this.state.chat_info.cod_grupo);
        formData.append('operador_id', operador_id);
        formData.append('alterar_para', atribuicao);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            return data;
        })
        .catch((error) => {
            var response = '';
            var obj;
            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                return obj;
            }
            var data = error.response.data;
            if(data.exception){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                return obj;
            }
            toast.success(data.message);
        });

    }
    onError(){
        this.setState({
            loading: false,
            error_message: ''
        })
    }
    onClose(){
        this.setState({
            admin_id: -1,
            visible_delete: false,
            visible_group: false,
            visible_delete_group: false,
            loading: false,
            error_message: '',
            success_message: '',
        })
    }
    onData(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            });
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onDelete = () => {
        this.setState({loading: true})
        var url = ENDPOINT+"chat-grupo/apagar-operador";
    
        var formData = new FormData();
        formData.append('cod_grupo', this.state.chat_info.cod_grupo);
        formData.append('operador_id', this.state.admin_id);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.onData(data);
            return data;
        })
        .catch((error) => {
            var response = '';
            var obj;
            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            if(data.exception){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                this.onData(obj);
                return obj;
            }
            toast.success(data.message);
        });
    }

    onDeleteGrupo = () => {
        this.setState({loading: true})
        var url = ENDPOINT+"chat-grupo/excluir_grupo";
    
        var formData = new FormData();
        formData.append('cod_grupo', this.state.chat_info.cod_grupo);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);

            this.setState({
                isOpen: true,
                chat: [],
                chat_info: null,
                current_message: null,
                sending_message: false,
                message: '', 
                masked_message: null,
                file: [],
                total_pagina: 10,
                mensagens_total: 0,
                admin_id: -1,
                loading_chat: true,
                loading_messages: false,
                error_message: '',
                success_message: '',
                loading: false,
                visible_delete: false,
            });
            this.props.fecharChat();

            return data;
        })
        .catch((error) => {
            var response = '';
            var obj;
            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            if(data.exception){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                this.onData(obj);
                return obj;
            }
            toast.success(data.message);
        });
    }

    modalAttach(value){
        this.setState({modal_anexo: value, file: []})
    }

    removeAttach = (index) => {
        var state = this.state;
        state.file.splice(index, 1);
        this.setState({
            ...state
        })
    }
    handleFileChange = (event) => {
        const files = event.target.files;
        var state = this.state;
        Array.from(files).forEach(file_current => {
            state.file.push(file_current);
        });
        this.setState({
            ...state
        })
    }
    onDrop = (file) => {
        var state = this.state;
        file.forEach(file_current => {
            state.file.push(file_current);
        });
        this.setState({
            ...state
        })
    }
    readMessageSpecific(message) {
        if (message.status !== 2 && getUser().id !== message.operador.admin_id) {
            var url = ENDPOINT + 'chat-grupo/ler-mensagem-especifica/' + message.cod_message;

            var formData = new FormData();

            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer ' + getToken()
                }
            })
            .then((response) => {

            })
            .catch((error) => {

            });

        }
    }
    render(){
        return(
            <Div>
                <Row>
                    <Col md={this.state.isOpen === true ? 8 : 12} >
                        <Card style={{ height: '76vh' }}>
                            <div
                                className="card-body"
                                ref={this.myRef}
                                onScroll={this.onScroll.bind(this)}
                                style={{
                                    border: '1px solid #dfdfdf',
                                    maxWidth: '100%',
                                    backgroundColor: '#343a4024',
                                    height: '100%',
                                    //minHeight: '80%', 
                                    overflow: 'auto'
                                }}>
                                {this.state.loading_chat === true ? 
                                    <div style={{height: '100%'}} hidden={!this.state.current_message === null}>
                                        <center>
                                            <small style={{
                                                bottom: '60%',
                                                position: 'absolute'
                                            }}>
                                                <FadeLoader color={'#52c565'} margin={0} loading={50} size={60} /><br />
                                            </small>
                                        </center>
                                    </div>
                                    :
                                    <></>}
                                <center>
                                    <Button onClick={() => this.isMoreLoading()} hidden={this.isLoadingButton()} color="dark" style={{borderRadius: '150px'}}>{this.state.loading_messages === false ? 'Carregar mais mensagens' : 'Carregando ...' }</Button>
                                </center>
                                {this.state.chat.map((p, index) => {
                                    return <div key={index} 
                                    onClick={() => this.readMessageSpecific(p)} 
                                        style={{ backgroundColor: p.status_da_mensagem !== 2 ? '#52c56517' : 'transparent' }}>
                                            <DropdownCurrent type_message={p.admin_id === getUser().id}>
                                                <div class="chat-reposta" type_message={p.admin_id === getUser().id}>
                                                    { p.admin_id === getUser().id ? <>
                                                    {/* <Button 
                                                                disabled={new Date(p.created_at) > new Date().setMinutes(new Date().getMinutes() - 1)
                                                                        ? 
                                                                    (p.message === 'ESSA MENSAGEM FOI APAGADA' || p.message === 'APAGANDO MENSAGEM...') : true} color="danger" onClick={() => this.apagar_mensagem(p)}>
                                                        <FaTrash/>
                                                    </Button> */}
                                                    <Button data-tip={this.textOperadoresRead(p.quem_leu_mensagem)} color="info">
                                                        <MdInfo/>
                                                    </Button>
                                                    </> : <></>}
                                                    
                                                    <Button disabled={p.message === 'ESSA MENSAGEM FOI APAGADA' || p.message === 'APAGANDO MENSAGEM...'} color="info" onClick={() => this.onMaskedMessage(p, p.operador.name)}>
                                                        <MdReply/>
                                                    </Button>
                                                    
                                                    <ReactTooltip multiline={true}/>

                                                </div>
                                            </DropdownCurrent>
                                            <MessageBoxArea>
                                                <MessageBox
                                                copiableDate={false}
                                                dateString={moment(new Date(p.created_at)).format('HH:m')}
                                                //status={p.type_message === 0 ? this.getStatusMessage(p.status_message, p.message) : null}
                                                retracted={true}
                                                titleColor={p.operador.id === getUser().id ? "#343a40" : '#52c565'}
                                                data={p.anexo_url !== null ? this.getFileExtension(p.anexo_url) !== 'text' ? ({
                                                    uri: ENDPOINT+'grupo_download_url/'+p.cod_message+'/'+p.message+'?access_token='+getToken(),
                                                } ) : null  : null}
                                                avatar={p.operador.avatar_foto}
                                                position={p.operador.id === getUser().id ? 'right' : 'left'}
                                                reply={p.mask_messages !== null ? {
                                                    title: p.mask_messages.operador.id === getUser().id ? (p.operador !== null ? p.operador.name : 'Atendimento') : this.state.chat_info.nome,
                                                    titleColor: '#38B76E',
                                                    message: p.mask_messages.message,
                                                } : null}
                                                type={p.anexo_url !== null ? this.getFileExtension(p.anexo_url) : 'text'}
                                                onClick={() =>  {
                                                    if(p.anexo_url !== null){
                                                        if(this.getFileExtension(p.anexo_url) === 'file'){
                                                            window.open(ENDPOINT+'grupo_download_url/'+p.cod_message+'/'+p.message+'?access_token='+getToken(), '_blank')
                                                        }
                                                    }
                                                }}
                                                title={p.operador !== null ? p.operador.name : 'Sem nome'}
                                                text={this.getHtmlExtension(p.anexo_url, ENDPOINT+'grupo_download_url/'+p.cod_message+'/'+p.message+'?access_token='+getToken(), p.message)}
                                            />
                                        </MessageBoxArea>
                                    </div>
                                })}
                            </div>
                            <CardFooter>
                                <Row hidden={!this.state.sending_message}>
                                    <Col md={12}>
                                        <center>
                                            <small><PropagateLoader color={'#52c565'} loading={50} size={15} /><br />
                                                <b>Enviando mensagem</b><p></p></small>
                                        </center>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col md={12}>
                                        <Collapse isOpen={this.state.masked_message !== null} 
                                            style={{ 
                                                borderLeft: '4px solid #28a745', 
                                                paddingLeft: '6px', 
                                                marginBottom: 10, 
                                                width: '100%', 
                                                backgroundColor: '#cccccc' 
                                            }}>
                                            <small hidden={this.state.sending_message}>
                                                <b>{this.state.masked_message !== null ? 
                                                    this.state.masked_message.nome : ''} 
                                                    <Button color="danger" 
                                                        onClick={() => this.setState({ masked_message: null })} 
                                                        outline
                                                        style={{ padding: 0, float: 'right', margin: '11px', borderRadius: '50%', height: '27px', width: '27px' }}>
                                                            <MdClear style={{ padding: 3 }} />
                                                    </Button>
                                                </b>
                                            <br />{this.state.masked_message !== null ? 
                                            this.state.masked_message.message : ''}</small>
                                        </Collapse>
                                    </Col>
                                </Row>
                                
                                <Col md={12} style={{ display: 'inherit' }} hidden={this.state.sending_message}>
                                    <div style={{display: 'flex', border: '1px solid #dfdfdf', width: '100%', minHeight: '50px', padding: '7px', borderRadius: '15px'}}>
                                        <TextareaAutosize
                                            type="textarea"
                                            placeholder="Escreva sua mensagem"
                                            className="msg"
                                            style={{ resize: 'none', background: 'transparent', width: '100%' }} 
                                            name="message"
                                            hidden={this.state.is_type_message}
                                            onKeyPress={this.onKeyUp}
                                            value={this.state.message}
                                            onChange={this.handleInputChange} />
                                            <ButtonGroup style={{ float: 'right', borderRadius: 0 }}>
                                                <Button  
                                                    style={{ borderRadius: 0}} 
                                                    outline
                                                    className="widget_btn talk_btn"
                                                    onClick={() => this.modalAttach(true)}
                                                    >
                                                        <MdAttachFile style={{ width: 20, padding: 0 }} />
                                                    </Button>
                                                <Button 
                                                    //disabled
                                                    style={{borderRadius: 0}} 
                                                    color="info" 
                                                    outline
                                                    className="widget_btn talk_btn"
                                                    onClick={() => { 
                                                        this.isOpenCurrent() 
                                                    }}><MdViewList style={{ width: 20, padding: 0 }} /></Button>
                                                <Button 
                                                //disabled
                                                    style={{borderRadius: 0}} 
                                                    color="success" 
                                                    outline
                                                    className="widget_btn talk_btn"
                                                    onClick={() => this.sendValid(this.state.chat_info.cod_grupo)}>
                                                        <MdSend style={{ width: 20, padding: 0 }} /></Button>
                                            </ButtonGroup>

                                    </div>
                                </Col>  
                                {/* <Col md={12} style={{ display: 'flex', padding: 0 }} hidden={this.state.sending_message}>                                    
                                    <Input
                                        type="textarea"
                                        placeholder="Escreva sua mensagem"
                                        style={{ height: 42, resize: 'none', marginRight: '15px', 
                                                margin: 0, borderRight: 0, borderRadius: 0, 
                                                paddingTop: 8 }}
                                        name="message"
                                        hidden={this.state.is_type_message}
                                        onKeyPress={this.onKeyUp}
                                        value={this.state.message}
                                        onChange={this.handleInputChange} />
                                    <ButtonGroup style={{ float: 'right', borderRadius: 0 }}>
                                        <Button  
                                         style={{borderRadius: 0}} onClick={() => this.modalAttach(true)}>
                                            <MdAttachFile style={{ width: 20, padding: 0 }} />
                                        </Button>
                                        <Button 
                                            //disabled
                                            style={{borderRadius: 0}} 
                                            color="info" 
                                            onClick={() => { 
                                                this.isOpenCurrent() 
                                            }}><MdViewList style={{ width: 20, padding: 0 }} /></Button>
                                        <Button 
                                        //disabled
                                            style={{borderRadius: 0}} 
                                            color="success" 
                                            onClick={() => this.sendValid(this.state.chat_info.cod_grupo)}>
                                                <MdSend style={{ width: 20, padding: 0 }} /></Button>
                                    </ButtonGroup>
                                </Col> */}
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={this.state.isOpen === true ? 4 : 0} hidden={!this.state.isOpen} >
                        <Card style={{borderRadius: '1.25rem'}}>
                            <CardBody style={{paddingLeft: '39px'}}>
                                <h5>&nbsp;&nbsp;<MdSupervisorAccount style={{width: '30px', height: '30px'}}/> Dados do Grupo</h5>
                                <hr/>
                                <div style={{ marginTop: '15px' }}>
                                    <center>
                                        <h4>{this.state.chat_info !== null ? this.state.chat_info.nome_grupo : ''}</h4>
                                        <p>{this.state.chat_info !== null ? this.state.chat_info.tipo_grupo : ''}</p>
                                    </center> 
                                    <hr/>
                                    <h5>Participantes:</h5>
                                    <ul style={{listStyle: 'none', padding: 0}}>
                                    {this.state.chat_info !== null ? this.state.chat_info.operadores.map((p, i) => {
                                        return <li style={{display: 'flex', margin: '5px'}}>
                                            <ReactRoundedImage 
                                                image={p.operador.avatar_foto}
                                                roundedColor="#8dd199"
                                                roundedSize="4"
                                                imageWidth="25"
                                                imageHeight="25"/>  &nbsp;{p.operador.name} ({p.acesso})
                                                <div hidden={!(this.state.chat_info.meu_acesso === 'administrador')}>
                                                    <DropdownGrupo>
                                                        <DropdownButton 
                                                            hidden={p.operador.id === getUser().id}
                                                            title={<BsThreeDotsVertical style={{ padding: '3px', width: '23px' }}/>}
                                                            noCaret={true}
                                                        >
                                                            <Dropdown.Item 
                                                                onClick={() => this.atribuicaoOperador(p.operador.id, p.acesso === 'administrador' ? 'usuario' : 'administrador')}
                                                                ><MdLock style={{color: '#17a2b8'}} />Tornar {p.acesso === 'administrador' ? 'Usuário Comum' : 'Admin'}</Dropdown.Item>
                                                            <Dropdown.Item 
                                                                onClick={() => this.setState({ visible_delete: true, admin_id: p.operador.id })}
                                                                ><MdClear style={{color: 'red'}} />Excluir</Dropdown.Item>
                                                        </DropdownButton>

                                                    </DropdownGrupo>

                                                </div>
                                            </li>
                                    }) : ''}
                                    </ul>
                                    {this.state.chat_info !== null ? 
                                        <div hidden={!(this.state.chat_info.meu_acesso === 'administrador')}>
                                            <center>
                                                <Button color="success" onClick={() => this.setState({ visible_group: true })} style={{borderRadius: '45px', marginBottom: '5px'}}>Adicionar Participante</Button>
                                                <Button color="danger" onClick={() => this.setState({ visible_delete_group: true })} style={{borderRadius: '45px'}}><MdWarning />Excluir Grupo</Button>
                                            </center>
                                        </div>
                                        :
                                        <></>
                                    }
                                    <AddAtendentesGroupModal
                                        operadores_adicionados={this.state.chat_info}
                                        visible={this.state.visible_group}
                                        onClose={() => this.onClose()}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Modal isOpen={this.state.modal_anexo}>
                    <ModalBody className="modal-body">
                        <div style={{ display: 'inline-flex' }}>
                            <MdAttachFile /><h4>ADICIONAR ARQUIVOS</h4>
                        </div>
                        <hr />
                        <Dropzone onDrop={(drop) => this.onDrop(drop)}>
                            {({ getRootProps, getInputProps }) => (
                                <DropArea {...getRootProps({
                                    onClick: event => event.stopPropagation()
                                })}>
                                    <input {...getInputProps()} />
                                    {this.state.file.length > 0 ?
                                        <>
                                            <Row>
                                                {this.state.file.map((e, index) => {
                                                    return <Col key={index} md={4} style={{ paddingBottom: 4 }}>
                                                        <Card>
                                                            <p style={{ margin: '3px' }}>
                                                                <MdAttachFile style={{ width: '32px' }} />
                                                                {e.name}
                                                <Button
                                                                    style={{ padding: 0, border: 3, float: 'right' }}
                                                                    color="danger"
                                                                    outline
                                                                    onClick={
                                                                        () => this.removeAttach(index)
                                                                    }>
                                                                    <MdClear style={{ padding: 0 }} />
                                                                </Button>
                                                            </p>
                                                        </Card>
                                                    </Col>
                                                })}
                                                <br />
                                            </Row>
                                            <p style={{ margin: 0 }}>
                                            <MdAttachFile style={{ width: '32px' }} />
                                                Arraste arquivos ou&nbsp;
                                                <ButtonUpload 
                                                    onClick={() => this.inputFile.current.click()} 
                                                    className="btn btn-dark" 
                                                    color="primary">
                                                    Clique Aqui
                                                </ButtonUpload> para adicionar mais anexos
                                                <input type="file" ref={this.inputFile} multiple hidden onChange={this.handleFileChange} />
                                        </p>
                                    </>
                                    :
                                    <>
                                        <p style={{ margin: 0 }}>
                                            <MdAttachFile style={{ width: '32px' }} />
                                                Arraste arquivos ou&nbsp;
                                                <ButtonUpload 
                                                    onClick={() => this.inputFile.current.click()} 
                                                    className="btn btn-dark" 
                                                    color="primary">
                                                    Clique Aqui
                                                </ButtonUpload> para adicionar anexos
                                                <input type="file" ref={this.inputFile} multiple hidden onChange={this.handleFileChange} />
                                        </p>
                                    </>
                                    }
                                </DropArea>
                            )}
                        </Dropzone>
                        <br />
                        <Input
                            type="textarea"
                            placeholder="Escreva sua mensagem"
                            style={{ height: 40, resize: 'none' }}
                            name="message"
                            value={this.state.message}
                            onChange={this.handleInputChange} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => this.modalAttach(false)}>Cancelar</Button>
                        <Button color="success" onClick={() => this.sendMessage(this.state.chat_info.cod_grupo)}>Enviar</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.modal_image} centered size="lg">
                    <ModalBody>
                        <center>
                            <img 
                                style={{
                                    maxWidth: '100%'
                                }}
                                alt="ver-imagem"
                                src={this.state.urlImage+'?access_token='+getToken()}
                            />
                        </center>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => this.onImageModal(false, '')}>Fechar</Button>
                    </ModalFooter>
                </Modal>
                <ModalDialog
                    visible={this.state.visible_delete}
                    onError={this.onError.bind(this)}
                    message="Deseja remover esse operador do grupo ?"
                    onClose={() => this.onClose()}
                    error_message={this.state.error_message}
                    loading={this.state.loading}
                    onSave={() => this.onDelete()}
                    success_message={this.state.success_message}
                />
                <ModalDialog
                    visible={this.state.visible_delete_group}
                    onError={this.onError.bind(this)}
                    message="Deseja mesmo deletar esse grupo ? Esse procedimento não poderá ser desfeito "
                    onClose={() => this.onClose()}
                    error_message={this.state.error_message}
                    loading={this.state.loading}
                    onSave={() => this.onDeleteGrupo()}
                    success_message={this.state.success_message}
                />
            </Div>
        )
    }
}

export default ChatGrupo;