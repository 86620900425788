import axios from 'axios';
import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import { MdAdd, MdClear, MdEdit } from 'react-icons/md';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { ModalBody, ModalFooter, Modal, Collapse, Form, FormGroup, Input, Label, Row, Col, Button, ButtonGroup, Card, CardBody, FormFeedback } from 'reactstrap';
import { ENDPOINT } from '../../auth/endpoint';
import { getPermissoes, getToken, getUser } from '../../auth/set_services';
import { ButtonDefault } from '../../components/Button/ButtonDefault';
import { GiPartyPopper } from 'react-icons/gi';
import { InputMasked } from "../../components/InputText/InputMasked";
import { InputText } from "../../components/InputText/InputText";
import ModelReturn from "../../components/Modals/ReturnsApiModals/ModelReturn";
import ModalDialog from "../../components/Modals/ReturnsApiModals/ModalDialog";

const getRows = (callbackRows) => {
    var url = ENDPOINT + "feriados/list";
  
    axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + getToken()
      }
    }).then((response) => {
      callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

const mes = (mes) => {
    switch(mes){
        case 1 : return 'Janeiro';
        case 2 : return 'Fevereiro';
        case 3 : return 'Março';
        case 4 : return 'Abril';
        case 5 : return 'Maio';
        case 6 : return 'Junho';
        case 7 : return 'Julho';
        case 8 : return 'Agosto';
        case 9 : return 'Setembro';
        case 10 : return 'Outubro';
        case 11 : return 'Novembro';
        case 12 : return 'Dezembro';
        default: return mes;
    }
}

const ano = (ano) => {
    switch(ano){
        case 'all' : return 'Todo ano';
        default: return ano;
    }

}

const columns = (edit, isdelete) => [
    {
        name: "Nome",
        selector: "nome_feriado",
        sortable: true
    },
    {
        name: "Data",
        selector: "data",
        sortable: true,
        cell: row => row.dia+' de '+mes(row.mes)
    }, 
    {
        name: "Tempo",
        selector: "tempo",
        sortable: true,
        cell: row => row.horario_inicio+' - '+row.horario_final
    },
    {
        name: "Ano",
        selector: "todo_ano",
        sortable: true,
        cell: row => ano(row.ano_especifico)
    },
    {
        name: '',
        selector: 'func',
        sortable: false,
        width: '15%',
        cell: row => <ButtonGroup>
            <Button color="info" onClick={() => edit(row)}> <MdEdit style={{padding: 0}}/> </Button>
            <Button color="danger" onClick={() => isdelete(row)}> <MdClear style={{padding: 0}}/> </Button>
        </ButtonGroup>
    }
];
class HorarioAtendimento extends Component{
    constructor(props){
        super(props);
        this.state = {
            current_departamento: null, 
            list_departamentos: [],
            list_feriados: [],

            is_modal: false,
            visible_delete: false,
            is_year: false,
            feriado_id: null,
            data: '',
            data_invalid: false,
            feriado: '',
            feriado_invalid: false,
            ano: '',
            ano_invalid: false,
            inicio: '',
            inicio_invalid: false,
            fim: '',
            fim_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        }
        this.onClose = this.onClose.bind(this);
    }
    componentDidMount(){
        this.getDepartamentos();
        getRows(this.callbackRows.bind(this));
    }
    callbackRows = (list_feriados) => {
        this.setState({list_feriados});
    } 
    
    getDepartamentos = () => {
        var url = ENDPOINT+"departamento/list_public";
    
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            var list_departamentos = [];
            data.forEach((p, i) => {
                p['value'] = p.flag;
                p['label'] = p.nome;
                list_departamentos.push(p);
            })
            this.setState({list_departamentos});
        })
        .catch((error) => {

        });
    }
    
    onClose() {
        this.setState({
            feriado_id: null,
            visible_delete: false,
            is_modal: false,
            is_year: false,
            data: '',
            data_invalid: false,
            feriado: '',
            feriado_invalid: false,
            ano: '',
            ano_invalid: false,
            inicio: '',
            inicio_invalid: false,
            fim: '',
            fim_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        });
        getRows(this.callbackRows.bind(this));
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    setDepartamentos = () => {
        var url = ENDPOINT+"departamento/store_horario";
    
        var formData = new FormData();
        formData.append('flag', this.state.current_departamento.flag);
        formData.append('disponivel_feriado', Number(this.state.current_departamento.disponivel_feriado));
        formData.append('disponivel_ponto_facultativo', Number(this.state.current_departamento.disponivel_ponto_facultativo));
        formData.append('disponivel_segunda', Number(this.state.current_departamento.disponivel_segunda));
        formData.append('disponivel_terca', Number(this.state.current_departamento.disponivel_terca));
        formData.append('disponivel_quarta', Number(this.state.current_departamento.disponivel_quarta));
        formData.append('disponivel_quinta', Number(this.state.current_departamento.disponivel_quinta));
        formData.append('disponivel_sexta', Number(this.state.current_departamento.disponivel_sexta));
        formData.append('disponivel_sabado', Number(this.state.current_departamento.disponivel_sabado));
        formData.append('disponivel_domingo', Number(this.state.current_departamento.disponivel_domingo));

        if(Boolean(this.state.current_departamento.disponivel_segunda) === true){
            formData.append('horario_inicio_segunda', this.state.current_departamento.horario_inicio_segunda);
            formData.append('horario_final_segunda', this.state.current_departamento.horario_final_segunda);
        }
        else{
            formData.append('horario_inicio_segunda', "00:00");
            formData.append('horario_final_segunda', "23:59");
        }

        
        if(Boolean(this.state.current_departamento.disponivel_terca) === true){
            formData.append('horario_inicio_terca', this.state.current_departamento.horario_inicio_terca);
            formData.append('horario_final_terca', this.state.current_departamento.horario_final_terca);
        }
        else{
            formData.append('horario_inicio_terca', "00:00:00");
            formData.append('horario_final_terca', "23:59");
        }

        
        if(Boolean(this.state.current_departamento.disponivel_quarta) === true){
            formData.append('horario_inicio_quarta', this.state.current_departamento.horario_inicio_quarta);
            formData.append('horario_final_quarta', this.state.current_departamento.horario_final_quarta);
        }
        else{
            formData.append('horario_inicio_quarta', "00:00:00");
            formData.append('horario_final_quarta', "23:59");
        }

        
        if(Boolean(this.state.current_departamento.disponivel_quinta) === true){
            formData.append('horario_inicio_quinta', this.state.current_departamento.horario_inicio_quinta);
            formData.append('horario_final_quinta', this.state.current_departamento.horario_final_quinta);
        }
        else{
            formData.append('horario_inicio_quinta', "00:00:00");
            formData.append('horario_final_quinta', "23:59");
        }

        
        if(Boolean(this.state.current_departamento.disponivel_sexta) === true){
            formData.append('horario_inicio_sexta', this.state.current_departamento.horario_inicio_sexta);
            formData.append('horario_final_sexta', this.state.current_departamento.horario_final_sexta);
        }
        else{
            formData.append('horario_inicio_sexta', "00:00:00");
            formData.append('horario_final_sexta', "23:59");
        }

        
        if(Boolean(this.state.current_departamento.disponivel_sabado) === true){
            formData.append('horario_inicio_sabado', this.state.current_departamento.horario_inicio_sabado);
            formData.append('horario_final_sabado', this.state.current_departamento.horario_final_sabado);
        }
        else{
            formData.append('horario_inicio_sabado', "00:00:00");
            formData.append('horario_final_sabado', "23:59");
        }

        
        if(Boolean(this.state.current_departamento.disponivel_domingo) === true){
            formData.append('horario_inicio_domingo', this.state.current_departamento.horario_inicio_domingo);
            formData.append('horario_final_domingo', this.state.current_departamento.horario_final_domingo);
        }
        else{
            formData.append('horario_inicio_domingo', "00:00:00");
            formData.append('horario_final_domingo', "23:59");
        }
        
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.onToast(data);
            return data;
        })
        .catch((error) => {
            var response = '';
            var obj = '';

            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                this.onToast(obj);
                return obj;
            }
            
            var data = error.response.data;
            if(data.exception){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                this.onToast(obj);
                return obj;
            }

            this.onToast(data);
            return data;
        });
    }

    onToast(data) {
        if (data.success === true) {
            toast.success(data.message);
            this.getDepartamentos();
        }
        else {
            toast.error(data.errors);
        }
    }

    handleSelectChange = current_departamento => {
        this.setState({ current_departamento });
    };
    handleDiaSemana = (name, value) => {
        var current_departamento = this.state.current_departamento;
        current_departamento[name] = value;
        this.setState({ current_departamento });
    }
    vinteQuatroHoras = (name) => {
        var current_departamento = this.state.current_departamento;
        current_departamento['horario_inicio_'+name] = "00:00:00";
        current_departamento['horario_final_'+name] = "23:59:59";
        this.setState({ current_departamento });
    }
    handleInputChange = (e) => {
      const { name, value } = e.target;
      
      this.setState({ [name]: value });
    }

    onData(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onDelete(row){
        this.setState({
            feriado_id: row.id,
            visible_delete: true
        })
    }
    onEdit(row){
        if(row.ano_especifico === 'all'){
            this.setState({is_year: true})
        }
        else{
            this.setState({ano: row.ano_especifico})
        }
        this.setState({
            feriado_id: row.id,
            feriado: row.nome_feriado,
            inicio: row.horario_inicio,
            fim: row.horario_final,
            data: (parseInt(row.dia, 10) <= 9 ? '0'+row.dia : row.dia) +"/"+(parseInt(row.mes, 10) <= 9 ? '0'+row.mes : row.mes),
            is_modal: true
        })
    }
    deletarFeriado = () => {
        this.setState({loading: true});

        var url = ENDPOINT+"feriados/delete/"+this.state.feriado_id;

        axios.delete(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.onData(data);
            return data;
        })
        .catch((error) => {
            var response = '';
            var obj = '';

            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            
            var data = error.response.data;
            if(data.exception){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }

            this.onData(data);
            return data;
        });

    }
    cadastrarFeriado = () => {
        if(this.validarCampos() === true){
            this.setState({loading: true});
            var url = ENDPOINT+"feriados/store";
    
            var formData = new FormData();
            if(this.state.feriado_id !== null){
                formData.append('id', this.state.feriado_id);
            }

            formData.append('nome_feriado', this.state.feriado);
            formData.append('ano_especifico', this.state.is_year === true ? 'all' : this.state.ano);
            formData.append('horario_inicio', this.state.inicio);
            formData.append('horario_final', this.state.fim);
            
            var dia = this.state.data.substr(0, 2);
            var mes = this.state.data.substr(-2, 2);

            formData.append('dia', dia);
            formData.append('mes', mes);

            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }
            })
            .then((response) => {
                var data = response.data;
                this.onData(data);
                return data;
            })
            .catch((error) => {
                var response = '';
                var obj = '';
    
                if(error.message === 'Network Error'){
                    response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    obj = JSON.parse(response);
                    this.onData(obj);
                    return obj;
                }
                
                var data = error.response.data;
                if(data.exception){
                    response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    obj = JSON.parse(response);
                    this.onData(obj);
                    return obj;
                }
    
                this.onData(data);
                return data;
            });
        }
    }
    validarCampos = () => {
        var invalid_fields = 0;

        if(this.state.feriado.length > 0){
            this.setState({
                feriado_invalid: false
            });
        }
        else{
            invalid_fields++;
            this.setState({
                feriado_invalid: true
            });
        }

        if(this.state.data.replace('_', '').replace('/', '').length > 3){
            var dia = this.state.data.substr(0, 2);
            var mes = this.state.data.substr(-2, 2);
            if(parseInt(dia, 10) > 31){
                invalid_fields++;
                this.setState({
                    data_invalid: true
                });
            }
            else if(parseInt(mes, 10) > 12){
                invalid_fields++;
                this.setState({
                    data_invalid: true
                });
            }
            else{
                this.setState({
                    data_invalid: false
                }); 
            }
        }
        else{
            invalid_fields++;
            this.setState({
                data_invalid: true
            });
        }

        if(this.state.is_year === false){
            console.log(this.state.ano.length);
            if(this.state.ano.length > 3){
                this.setState({
                    ano_invalid: false
                }); 
            }
            else{
                invalid_fields++;
                this.setState({
                    ano_invalid: true
                });
            }
        }
        else{
            this.setState({
                ano_invalid: false
            });
        }

        if(this.state.inicio === '' || this.state.inicio === null){
            invalid_fields++;
            this.setState({
                inicio_invalid: true
            });
        }
        else{
            this.setState({
                inicio_invalid: false
            });
        }

        if(this.state.fim === '' || this.state.fim === null){
            invalid_fields++;
            this.setState({
                fim_invalid: true
            });
        }
        else{
            this.setState({
                fim_invalid: false
            });
        }

        if(invalid_fields === 0){
            return true;
        }

        return false;

    }
    render(){
        return(
            <div>
                <h3>Horario de Atendimento</h3>
                <hr />
                <div hidden={!getUser().permissao_configuracoes}>
                    <Select 
                        placeholder="Departamento" 
                        options={this.state.list_departamentos} 
                        value={this.state.current_departamento}
                        onChange={this.handleSelectChange}
                        isSearchable={false}
                        theme={theme => ({
                            ...theme,
                            borderRadius: 0
                        })}
                    />
                    <Collapse isOpen={this.state.current_departamento !== null}>
                        <br/>
                        <div style={{margin: '1px', paddingLeft: '25px'}}>
                            {this.state.current_departamento !== null ?  <>
                                <Form>
                                    <h5>Horarios de Atendimento</h5>
                                    <hr/>
                                    <FormGroup>
                                        <Row>
                                            <Col md={2} style={{display: 'flex', alignItems: 'center'}}>
                                                <Input 
                                                    style={{margin: '0', position: 'relative'}} 
                                                    type="checkbox"
                                                    onChange={(e) => this.handleDiaSemana('disponivel_feriado', e.target.checked)}
                                                    checked={this.state.current_departamento.disponivel_feriado}
                                                />&nbsp;<p style={{margin: '0'}}>Incluir Feriados</p>&nbsp;&nbsp;
                                            </Col>
                                            <Col md={4} style={{display: 'flex', alignItems: 'center'}}>
                                                <Input 
                                                    style={{margin: '0', position: 'relative'}} 
                                                    type="checkbox"
                                                    onChange={(e) => this.handleDiaSemana('disponivel_ponto_facultativo', e.target.checked)}
                                                    checked={this.state.current_departamento.disponivel_ponto_facultativo}/>&nbsp;<p style={{margin: '0'}}>Incluir Pontos Facultativos</p>&nbsp;&nbsp;
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row>
                                            <Col md={2} style={{display: 'flex', alignItems: 'center'}}>
                                                <Input 
                                                    style={{margin: '0', position: 'relative'}} 
                                                    type="checkbox"
                                                    onChange={(e) => this.handleDiaSemana('disponivel_segunda', e.target.checked)}
                                                    checked={this.state.current_departamento.disponivel_segunda}/>&nbsp;<p style={{margin: '0'}}>Segunda:</p>&nbsp;&nbsp;
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                                <Label style={{marginRight: '9px'}}>Inicio: </Label>
                                                <Input type="time"
                                                //list="times"
                                                    step={120}
                                                    disabled={!this.state.current_departamento.disponivel_segunda}
                                                    value={this.state.current_departamento.horario_inicio_segunda}
                                                    onChange={(e) => this.handleDiaSemana('horario_inicio_segunda', e.target.value)}/>
                                                    
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                                <Label style={{marginRight: '9px'}}>Fim: </Label>
                                                <Input type="time"
                                                    disabled={!this.state.current_departamento.disponivel_segunda}
                                                    value={this.state.current_departamento.horario_final_segunda}
                                                    onChange={(e) => this.handleDiaSemana('horario_final_segunda', e.target.value)}/>
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                            <Button 
                                                disabled={!this.state.current_departamento.disponivel_segunda}
                                                onClick={() => this.vinteQuatroHoras('segunda')}>24 Horas</Button>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col md={2} style={{display: 'flex', alignItems: 'center'}}>
                                                <Input 
                                                    style={{margin: '0', position: 'relative'}} 
                                                    type="checkbox"
                                                    onChange={(e) => this.handleDiaSemana('disponivel_terca', e.target.checked)}
                                                    checked={this.state.current_departamento.disponivel_terca} />&nbsp;<p style={{margin: '0'}}>Terça:</p>&nbsp;&nbsp;
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                                <Label style={{marginRight: '9px'}}>Inicio: </Label>
                                                <Input 
                                                    type="time"
                                                    disabled={!this.state.current_departamento.disponivel_terca}
                                                    value={this.state.current_departamento.horario_inicio_terca}
                                                    onChange={(e) => this.handleDiaSemana('horario_inicio_terca', e.target.value)}/>
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                                <Label style={{marginRight: '9px'}}>Fim: </Label>
                                                <Input 
                                                    type="time"
                                                    disabled={!this.state.current_departamento.disponivel_terca}
                                                    value={this.state.current_departamento.horario_final_terca}
                                                    onChange={(e) => this.handleDiaSemana('horario_final_terca', e.target.value)}/>
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                            <Button
                                                disabled={!this.state.current_departamento.disponivel_terca}
                                                    onClick={() => this.vinteQuatroHoras('terca')}>24 Horas</Button>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col md={2} style={{display: 'flex', alignItems: 'center'}}>
                                                <Input 
                                                    style={{margin: '0', position: 'relative'}}
                                                    type="checkbox"
                                                    onChange={(e) => this.handleDiaSemana('disponivel_quarta', e.target.checked)}
                                                    checked={this.state.current_departamento.disponivel_quarta}/>&nbsp;<p style={{margin: '0'}}>Quarta:</p>&nbsp;&nbsp;
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                                <Label style={{marginRight: '9px'}}>Inicio: </Label>
                                                <Input 
                                                    type="time"
                                                    disabled={!this.state.current_departamento.disponivel_quarta}
                                                    value={this.state.current_departamento.horario_inicio_quarta}
                                                    onChange={(e) => this.handleDiaSemana('horario_inicio_quarta', e.target.value)}/>
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                                <Label style={{marginRight: '9px'}}>Fim: </Label>
                                                <Input 
                                                    type="time"
                                                    disabled={!this.state.current_departamento.disponivel_quarta}
                                                    value={this.state.current_departamento.horario_final_quarta}
                                                    onChange={(e) => this.handleDiaSemana('horario_final_quarta', e.target.value)}/>
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                            <Button 
                                                disabled={!this.state.current_departamento.disponivel_quarta}
                                                onClick={() => this.vinteQuatroHoras('quarta')}>24 Horas</Button>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col md={2} style={{display: 'flex', alignItems: 'center'}}>
                                                <Input 
                                                    style={{margin: '0', position: 'relative'}} 
                                                    type="checkbox"
                                                    onChange={(e) => this.handleDiaSemana('disponivel_quinta', e.target.checked)}
                                                    checked={this.state.current_departamento.disponivel_quinta}/>&nbsp;<p style={{margin: '0'}}>Quinta:</p>&nbsp;&nbsp;
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                                <Label style={{marginRight: '9px'}}>Inicio: </Label>
                                                <Input 
                                                    type="time"
                                                    disabled={!this.state.current_departamento.disponivel_quinta}
                                                    value={this.state.current_departamento.horario_inicio_quinta}
                                                    onChange={(e) => this.handleDiaSemana('horario_inicio_quinta', e.target.value)}/>
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                                <Label style={{marginRight: '9px'}}>Fim: </Label>
                                                <Input 
                                                    type="time"
                                                    disabled={!this.state.current_departamento.disponivel_quinta}
                                                    value={this.state.current_departamento.horario_final_quinta}
                                                    onChange={(e) => this.handleDiaSemana('horario_final_quinta', e.target.value)}/>
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                            <Button 
                                                disabled={!this.state.current_departamento.disponivel_quinta}
                                                onClick={() => this.vinteQuatroHoras('quinta')}>24 Horas</Button>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col md={2} style={{display: 'flex', alignItems: 'center'}}>
                                                <Input 
                                                    style={{margin: '0', position: 'relative'}} 
                                                    type="checkbox"
                                                    onChange={(e) => this.handleDiaSemana('disponivel_sexta', e.target.checked)}
                                                    checked={this.state.current_departamento.disponivel_sexta}/>&nbsp;<p style={{margin: '0'}}>Sexta:</p>&nbsp;&nbsp;
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                                <Label style={{marginRight: '9px'}}>Inicio: </Label>
                                                <Input 
                                                    type="time"
                                                    disabled={!this.state.current_departamento.disponivel_sexta}
                                                    value={this.state.current_departamento.horario_inicio_sexta}
                                                    onChange={(e) => this.handleDiaSemana('horario_inicio_sexta', e.target.value)}/>
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                                <Label style={{marginRight: '9px'}}>Fim: </Label>
                                                <Input 
                                                    type="time"
                                                    disabled={!this.state.current_departamento.disponivel_sexta}
                                                    value={this.state.current_departamento.horario_final_sexta}
                                                    onChange={(e) => this.handleDiaSemana('horario_final_sexta', e.target.value)}/>
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                            <Button 
                                                disabled={!this.state.current_departamento.disponivel_sexta}
                                                onClick={() => this.vinteQuatroHoras('sexta')}>24 Horas</Button>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col md={2} style={{display: 'flex', alignItems: 'center'}}>
                                                <Input 
                                                    style={{margin: '0', position: 'relative'}} 
                                                    type="checkbox"
                                                    checked={this.state.current_departamento.disponivel_sabado}
                                                    onChange={(e) => this.handleDiaSemana('disponivel_sabado', e.target.checked)}/>&nbsp;<p style={{margin: '0'}}>Sábado:</p>&nbsp;&nbsp;
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                                <Label style={{marginRight: '9px'}}>Inicio: </Label>
                                                <Input 
                                                    type="time"
                                                    disabled={!this.state.current_departamento.disponivel_sabado}
                                                    value={this.state.current_departamento.horario_inicio_sabado}
                                                    onChange={(e) => this.handleDiaSemana('horario_inicio_sabado', e.target.value)}/>
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                                <Label style={{marginRight: '9px'}}>Fim: </Label>
                                                <Input 
                                                    type="time"
                                                    disabled={!this.state.current_departamento.disponivel_sabado}
                                                    value={this.state.current_departamento.horario_final_sabado}
                                                    onChange={(e) => this.handleDiaSemana('horario_final_sabado', e.target.value)}/>
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                            <Button 
                                                disabled={!this.state.current_departamento.disponivel_sabado}
                                                onClick={() => this.vinteQuatroHoras('sabado')}
                                                >24 Horas</Button>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col md={2} style={{display: 'flex', alignItems: 'center'}}>
                                                <Input 
                                                    style={{margin: '0', position: 'relative'}} 
                                                    type="checkbox"
                                                    onChange={(e) => this.handleDiaSemana('disponivel_domingo', e.target.checked)}
                                                    checked={this.state.current_departamento.disponivel_domingo}/>&nbsp;<p style={{margin: '0'}}>Domingo:</p>&nbsp;&nbsp;
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                                <Label style={{marginRight: '9px'}}>Inicio: </Label>
                                                <Input 
                                                    type="time" 
                                                    disabled={!this.state.current_departamento.disponivel_domingo}
                                                    value={this.state.current_departamento.horario_inicio_domingo}
                                                    onChange={(e) => this.handleDiaSemana('horario_inicio_domingo', e.target.value)}/>
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                                <Label style={{marginRight: '9px'}}>Fim: </Label>
                                                <Input 
                                                    type="time" 
                                                    disabled={!this.state.current_departamento.disponivel_domingo}
                                                    value={this.state.current_departamento.horario_final_domingo}
                                                    onChange={(e) => this.handleDiaSemana('horario_final_domingo', e.target.value)}/>
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                                <Button 
                                                    onClick={() => this.vinteQuatroHoras('domingo')}
                                                    disabled={!this.state.current_departamento.disponivel_domingo}
                                                >24 Horas</Button>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Form>
                                <hr/>
                                <Form>
                                    <h5>Configurar Feriados</h5>
                                    <small>O sistema mudará de para o modo férias nesses dias e horários</small>
                                    <br/><br/>
                                    <div>
                                        <Button color="success" onClick={() => this.setState({is_modal: true})}><MdAdd />Adicionar</Button>&nbsp;
                                    </div>
                                    <hr/>
                                    <DataTable
                                        noHeader={true}
                                        //onRowClicked={props.onTaggyChange}
                                        noDataComponent={
                                            <Card style={{ border: 0 }}>
                                                <CardBody><div>
                                                        Nenhum feriado configurado, 
                                                        clique em "Adicionar" para 
                                                        adicionar um feriado ao 
                                                        sistema
                                                </div></CardBody>
                                            </Card>
                                        }
                                        data={this.state.list_feriados}
                                        columns={columns(this.onEdit.bind(this), this.onDelete.bind(this))}

                                    />
                                </Form>
                                <hr/>
                                <div style={{textAlign: 'right'}}>
                                    <ButtonDefault onClick={() => this.setDepartamentos()}>Salvar</ButtonDefault>
                                </div>
                                <Modal isOpen={this.state.is_modal}>
                                    <ModalBody hidden={this.state.loading}>
                                        <div style={{display: 'flex'}}>
                                            <GiPartyPopper style={{padding: '0'}}/>&nbsp;<h4>Adicionar novo feriado</h4>
                                        </div>
                                        <hr/>
                                        <FormGroup>
                                            <InputText 
                                                placeholder="Nome do feriado"
                                                type="text"
                                                name="feriado"
                                                value={this.state.feriado}
                                                invalid={this.state.feriado_invalid}
                                                onChange={this.handleInputChange}
                                            />
                                            <FormFeedback>Digite o nome do feriado</FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <InputMasked 
                                                mask="99/99"
                                                placeholder="Dia do Feriado (dia e mes)"
                                                name="data"
                                                value={this.state.data}
                                                invalid={this.state.data_invalid}
                                                onChange={this.handleInputChange}/>
                                            <FormFeedback>Selecione o ano do feriado</FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <InputMasked 
                                                mask="9999"
                                                placeholder="Ano"
                                                type="text"
                                                name="ano"
                                                hidden={this.state.is_year}
                                                value={this.state.ano}
                                                invalid={this.state.ano_invalid}
                                                onChange={this.handleInputChange}/>
                                            <InputText
                                                disabled
                                                hidden={!this.state.is_year}
                                                value="Todos os anos selecionados"/>
                                            <Input 
                                                style={{float: 'right', marginLeft: 5, marginBottom: '3px'}} 
                                                type="checkbox"
                                                onChange={(e) => this.setState({ is_year: e.target.checked })}
                                                invalid={this.state.ano_invalid}
                                                checked={this.state.is_year}/><p style={{marginLeft: 20,  marginBottom: '3px'}}>Todos os anos</p>
                                            <FormFeedback>Selecione o ano do feriado</FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Inicio:</Label>
                                            <Input 
                                                type="time"
                                                name="inicio"
                                                value={this.state.inicio}
                                                invalid={this.state.inicio_invalid}
                                                onChange={this.handleInputChange}/>
                                            <FormFeedback>Selecione a hora que inicia o feriado</FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Final:</Label>
                                            <Input 
                                                type="time"
                                                name="fim"
                                                value={this.state.fim}
                                                invalid={this.state.fim_invalid}
                                                onChange={this.handleInputChange}/>
                                            <FormFeedback>Selecione a hora que finaliza o feriado</FormFeedback>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalBody
                                        style={{
                                            minHeight: '114px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            alignSelf: 'center',
                                            paddingBottom: '47px'
                                        }} hidden={!this.state.loading}>
                                        <ModelReturn
                                            error_message={this.state.error_message}
                                            success_message={this.state.success_message}
                                            loading={this.state.loading}
                                            onClose={this.onClose}
                                            onError={this.onError.bind(this)}
                                        />

                                    </ModalBody>
                                    <ModalFooter hidden={this.state.loading}>
                                        <Button color="danger" onClick={() => this.onClose()}>Cancelar</Button>
                                        <Button color="success" onClick={() => this.cadastrarFeriado()}>Salvar</Button>
                                    </ModalFooter>
                                </Modal>
                                <ModalDialog
                                    visible={this.state.visible_delete}
                                    onError={this.onError.bind(this)}
                                    message="Deseja excluir esse modelo de assinatura? Essa operação não poderá ser desfeita"
                                    onClose={() => this.onClose()}
                                    error_message={this.state.error_message}
                                    loading={this.state.loading}
                                    onSave={() => this.deletarFeriado()}
                                    success_message={this.state.success_message}
                                />
                            </> : <></>}
                        </div>
                    </Collapse>
                </div>
                <div hidden={getUser().permissao_configuracoes} >
                  <Card>
                      <CardBody>
                          <p>Você não tem permissões para acessar essa função</p>
                      </CardBody>
                  </Card>
                </div>
            </div>
        )
    }
}
export default HorarioAtendimento;