import Moment from 'moment';
import { MdChat } from 'react-icons/md';
import { ImTicket } from 'react-icons/im';

const atendimento_realizado = (atendimento) => {
    switch(atendimento){
      case 'chat': return 'Atendimento por Chat';
      case 'ticket': return 'Atendimento por Ticket';
      default: return atendimento;
    }
}
const details = (details) => {
  if(details.chat !== null){
    return <div style={{margin: '10px'}}>
      <MdChat/><b>Atendimento por chat</b><br/>
      Atendido por: {details.chat.atendente !== null ? details.chat.atendente.name : 'Sem atendente'}
    </div>
  }
  if(details.ticket !== null){
    return <div style={{margin: '10px'}}>
      <ImTicket/><b>Abertura de Ticket</b><br/>
      Cliente: {details.ticket.nome}
    </div>

  }
  return <></>
}
export const columns = (onMessage) => [
    {
        name: "Número de Protocolo",
        selector: "numero_protocolo",
        sortable: false,
        //width: '160px'
    },
    {
        name: "CPF/CNPJ Cliente",
        selector: "document",
        sortable: false,
        //width: '200px'
    },
    {
        name: "Nome do Cliente",
        selector: "nome_cliente",
        sortable: false,
        //width: '250px',
    },
    {
        name: 'Atendimento Realizado',
        selector: 'atendimento_realizado',
        sortable: false,
        cell: row => atendimento_realizado(row.atendimento_realizado)
        //width: '150px',
    },
    {
        name: 'Data do atendimento',
        selector: 'created_at',
        sortable: false,
        //width: '150px',
        cell: row =>  Moment(new Date(row.created_at)).format('DD/MM/YYYY HH:mm')
    },
    {
        name: 'Detalhe',
        selector: 'details',
        sortable: false,
        width: '250px',
        cell: row =>  details(row)
    }
  ];
/* const getStatusChat = (status) => {
    switch(status){
        case 0: return <div style={{fontSize: '14px'}}><BsChatFill/>Aguardando Atendente</div>;
        case 1: return <div style={{color: '#2196f3', fontSize: '14px'}}><BsChatFill/>Em andamento</div>;
        case 2: return <div style={{color: '#2a770a', fontSize: '14px'}}><BsChatFill/>Finalizado</div>;
        case 3: return <div style={{color: '#df1818', fontSize: '14px'}}><BsChatFill/>Perdido</div>;
        default : return status;
    }
} */