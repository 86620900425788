import React from 'react';
import { HiDocumentDuplicate, HiViewGrid } from 'react-icons/hi';
import { Button, Col, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import 'react-dropdown/style.css';
import moment from 'moment';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { MdBackup, MdMoneyOff } from 'react-icons/md';
import axios from 'axios';


/* const capitalizeFirstLetter = (string) => {
  if(string !== null){
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "Indisponivel"
} */
/* const Div = styled.div`
  .dropdown-toggle:after { 
    content: none 
  }
  .dropdown-toggle:before { 
    content: none 
  }
  .btn-primary {
    color: #007bff;
    background-color: #ffffff;
    border-color: #ffffff;
    padding: 0;
  }
  .btn-primary:hover {
    background-color: #007bff;
    color: #ffffff;
    border-color: #007bff;
    padding: 0;
  }
`;

const getTimeline = (type, empresa, userId) =>{
  if(empresa !== null){
    switch(type){
      case 'email': return '/empresa/'+empresa.id+'?active=9';
      case 'sms': return '/empresa/'+empresa.id+'?active=10'; 
    }
  }
  else{
    switch(type){
      case 'email': return '/user/'+userId+'?active=6'; 
      case 'sms': return '/user/'+userId+'?active=7'; 
    }

  }
  return '';
}
 */


const payment = (payment_type) => {
  switch(payment_type){
      case 'credit' : return 'Crédito';
      case 'boleto' : return 'Boleto';
      case 'debit' : return 'Débito';
      default: return payment_type
  }
}

const getMeioPagamento = (payment_type, status_pay) => {
  switch(payment_type){
    case "barcode": return "BOLETO";
    case "manually_keyed": return "LINK DE PAGAMENTO";
    case "chip": return "CHIP " + status_pay;
    case "contactless_chip": return "APROXIMAÇÃO";
    default: return payment_type;
  }
}


const list_status_pagamentos = [
  { value: 0, label: "Todos" },
  { value: "CANCELADA", 
    label: <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Pagamento cancelado
    </div>
  },
  { value: "PENDENTE", 
    label: "Pagamento pendente" 
  },
  { value: "APROVADO", 
  label: "Pagamento aprovado" 
  },
  { value: "FALHA NO PAGAMENTO", 
    label: <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Falha no pagamento
    </div>
  },
  { value: "ANALISANDO", 
    label: 
    <div style={{fontSize: '16px',fontWeight: 'bold'}}>
      Analise Pendente
    </div>  
  },
  { value: "EM ANALISE", 
    label: 
    <div style={{fontSize: '16px',fontWeight: 'bold'}}>
      Analise Iniciada
    </div> 
  },
  { value: "APROVADO AUTOMATICA", 
    label:
    <div style={{color: 'rgb(61, 129, 73)',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude aprovado automaticamente
    </div> 
  },
  { 
    value: "APROVADO MANUALMENTE", 
    label:
    <div style={{color: 'rgb(61, 129, 73)',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude aprovado manualmente
    </div> 
  },
  { value: "REPROVADO AUTOMATICAMENTE", 
    label: 
    <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude - Reprovado automaticamente
    </div>
  },
  { 
    value: "FRAUDE COMFIRMADA", 
    label:
    <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude reprovado por confirmada
    </div> 
  },
  { 
    value: "FRAUDE SUSPEITA", 
    label:
    <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude reprovado por suspeita
    </div> 
  },
  { 
    value: 'REPROVADO MANUALMENTE', 
    label:
    <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude Reprovado Manualmente
    </div> 
  },
  { 
    value: "FRAUDE SEM SUSPEITA", 
    label:
    <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
      Antifraude reprovado sem contato
    </div> 
  }    
];


const getStatus = (value, status_current) => {
  if(value === ""){
      var status_is_not_antifraude = '';
      list_status_pagamentos.map((p) => {
          if(p.value === status_current){
              status_is_not_antifraude = p.label
          }
          return null;
      })
      return status_is_not_antifraude ? status_is_not_antifraude : value;
  }
  else{
      var status_is = '';
      const status = [
          { 
            value: 'INC', 
            label: 
              <div style={{color: 'rgb(195 144 0)',fontSize: '16px',fontWeight: 'bold'}}>
                Pagamento Incompleto
              </div>
          },
          { 
            value: 'NVO', 
            label:
            <div style={{color: 'rgb(55 58 159)',fontSize: '16px',fontWeight: 'bold'}}>
              Análise Pendente
            </div>
          },
          { 
            value: 'AMA', 
            label:
            <div style={{color: 'rgb(55 58 159)',fontSize: '16px',fontWeight: 'bold'}}>
              Análise Iniciada
            </div>
          },
          //{ value: 'APA', label: 'Aprovado Automaticamente'},  
          { 
            value: 'paid', 
            label: 
            <div style={{color: '#3d8149',fontSize: '16px',fontWeight: 'bold'}}>
              Aprovado
            </div>
          },  
          { 
            value: 'succeeded', 
            label:
            <div style={{color: '#3d8149',fontSize: '16px',fontWeight: 'bold'}}>
              Aprovado
            </div>
          },
          { 
            value: 'pending', 
            label:
            <div style={{color: '#ff5707',fontSize: '16px',fontWeight: 'bold'}}>
              Pendente
            </div>
          },
          { 
            value: 'FRD', 
            label:
            <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
              Fraude Confirmada
            </div>
          },
          { 
            value: 'RMA', 
            label:
            <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
              Reprovado Manualmente
            </div>
          },
          { 
            value: 'RPM', 
            label:
            <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
              Reprovado sem contato
            </div>
          },
          { 
            value: 'failed', 
            label:
            <div style={{color: '#d11a1a',fontSize: '16px',fontWeight: 'bold'}}>
              Falha
            </div>
          },
      ];

      status.forEach(p => {
          if(p.value === value){
              status_is = p.label;
          }
      });

      return status_is ? status_is : value;

  }
}


// export const columns = (onPayload, onSellerDocumen t) => [
//   {
//     name: '',
//     selector: 'buttons',
//     width: '30px',
//     cell: row => <Row style={{margin: 2}}>
//       <Button data-tip="Bloquear Transação"
//         outline
//         disabled={row.individual === null && row.business === null}
//         style={{ padding: '0px', marginRight: '3px', border: 0}}
//         onClick={() => onPayload(row)}
//       >
//         <MdMoneyOff style={{ padding: '2px', width: '23px' }} />
//       </Button>
//       <ReactTooltip/>
//     </Row>
//   },
//   {
//     name: '',
//     selector: 'buttons',
//     width: '30px',
//     cell: row => <Row>
//         <Button data-tip="Detalhes da Transação"
//         outline
//         disabled={row.individual === null && row.business === null}
//         style={{ padding: '0px', marginRight: '3px', marginLeft: 15, border: 0, fontWeight: '700'}}
//         onClick={() => 
//             {
//                 window.location.href = '/payout-aqpago-detalhes/'+row.id
//             }
//         }>
//         <HiViewGrid style={{ padding: '2px' }}/>
//         </Button>
//     </Row>
//   },
//   {
//     name: '',
//     selector: 'buttons',
//     width: '50px',
//     cell: row => <Row>
//         <Button data-tip="Selecionar transações desse seller"
//         outline
//         disabled={row.individual === null && row.business === null}
//         style={{ padding: '0px', marginRight: '3px', marginLeft: 15, border: 0, fontWeight: '700'}}
//         onClick={() => onSellerDocument(row)}
//         >
//         <HiDocumentDuplicate style={{ padding: '2px' }}/>
//         </Button>
//     </Row>
//   },
//   {
//     name: "Data",
//     selector: "created_at",
//     sortable: false,
//     width: '150px',
//     cell: row => row.created_at !== null ? moment(row.created_at).format('DD/MM/YYYY HH:mm') : ''
//   },
//   {
//     name: "ID da Transação",
//     selector: "id",
//     sortable: false,
//     width: '20%'
//   },
//   {
//     name: "Modalidade",
//     selector: "point_of_sale",
//     sortable: false,
//     cell: row => row.point_of_sale !== null ? (row.point_of_sale.entry_mode == 'chip' ? 'CP - Cartão Presente' : 'CNP - Cartão não presente') : ''
//   },
//   {
//     name: "Seller",
//     selector: "individual",
//     sortable: false,
//     width: '25%',
//     cell: row => row.individual !== null || row.business !== null ? (row.statement_descriptor + ' - ' + (typeof row.individual !== 'undefined' &&row.individual !== null ? row.individual.first_name+' '+row.individual.last_name : row.business.business_name)) : 'S/N',
//   },
//   {
//     name: "Documento",
//     selector: "cpf",
//     sortable: false,
//     width: '15%',
//     cell: row => row.individual !== null || row.business !== null ? (typeof row.individual !== 'undefined' && row.individual !== null ? row.individual.taxpayer_id : (row.business !== null ? row.business.ein : '')) :  'S/N'
//   },
//   {
//     name: "Tipo de Venda",
//     selector: "payment_type",
//     sortable: false,
//     cell: row => payment(row.payment_type)
//   },
//   {
//     name: "Valor",
//     selector: "amount",
//     sortable: false,
//     cell: row => <>R$ {parseFloat(row.amount).toFixed(2).replace('.', ',')}</>
//   },
// ];


export const columns = (onPayload, onSellerDocument, onOpenClosedComplience) => [
  {
    name: '',
    selector: 'buttons',
    width: '2%',
    cell: row => <Row style={{margin: 2}}>
      <Button data-tip="Bloquear Transação"
        outline
        disabled={row.individual === null && row.business === null}
        style={{ padding: '0px', marginRight: '3px', border: 0}}
        onClick={() => onPayload(row)}
      >
        <MdMoneyOff style={{ padding: '2px', width: '23px' }} />
      </Button>
      <ReactTooltip/>
    </Row>
  },
  /* {
    name: '',
    selector: 'buttons',
    width: '2%',
    cell: row => <Row style={{margin: 2}}>
      <Button data-tip="Registrar/Alterar Complience"
        outline
        disabled={row.compliance !== null}
        style={{ padding: '0px', marginRight: '3px', border: 0}}
        onClick={() => onOpenClosedComplience(true, row.id)}
      >
        <MdBackup style={{ padding: '2px', width: '23px' }} />
      </Button>
      <ReactTooltip/>
    </Row>
  }, */
  {
    name: '',
    selector: 'buttons',
    width: '2%',
    cell: row => <Row>
        <Button data-tip="Detalhes da Transação"
        outline
        disabled={row.individual === null && row.business === null}
        style={{ padding: '0px', marginRight: '3px', marginLeft: 15, border: 0, fontWeight: '700'}}
        onClick={() => 
            {
                window.location.href = '/contas-a-receber-detalhes/'+row.id
            }
        }>
        <HiViewGrid style={{ padding: '2px' }}/>
        </Button>
    </Row>
  },
  {
    name: '',
    selector: 'buttons',
    width: '2%',
    cell: row => <Row>
        <Button data-tip="Selecionar transações desse seller"
        outline
        disabled={row.individual === null && row.business === null}
        style={{ padding: '0px', marginRight: '3px', marginLeft: 15, border: 0, fontWeight: '700'}}
        onClick={() => onSellerDocument(row)}
        >
        <HiDocumentDuplicate style={{ padding: '2px' }}/>
        </Button>
    </Row>
  },
  {
    name: "Estabelecimento Seller",
    selector: "individual",
    sortable: false,
    width: '30%',
    //cell: row => row.individual !== null || row.business !== null ? (row.statement_descriptor + ' - ' + (typeof row.individual !== 'undefined' &&row.individual !== null ? row.individual.first_name+' '+row.individual.last_name : row.business.business_name)) : 'S/N',
    cell: row => 
      <Row>
          <Col md={12} style={{marginBottom: '0.5rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Seller:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
              {row.individual !== null || row.business !== null ? (row.statement_descriptor + ' - ' + (typeof row.individual !== 'undefined' &&row.individual !== null ? row.individual.first_name+' '+row.individual.last_name : row.business.business_name)) : 'S/N'}
              </p> 
          </Col>          
          <Col md={12} style={{marginBottom: '0.5rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Documento:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
              {row.individual !== null || row.business !== null ? (typeof row.individual !== 'undefined' && row.individual !== null ? row.individual.taxpayer_id : (row.business !== null ? row.business.ein : '')) :  'S/N'}
              </p> 
          </Col>
          {row.payment_type == 'boleto' ? (
            <Col md={12} style={{marginBottom: '0.5rem'}}>
              <hr/>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Buyer:</b>
              </p>
              <p>{row?.buyer[0]?.first_name + ' ' + row?.buyer[0]?.last_name}</p>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Documento:</b>
              </p>
              <p>{row?.buyer[0]?.taxpayer_id}</p>
            </Col>
          ) : (<></>)}
      </Row>
  },
  {
    name: "Dados da Venda",
    selector: "dados_venda",
    sortable: false,
    width: '20%',
    // cell: row => row.created_at !== null ? moment(row.created_at).format('DD/MM/YYYY HH:mm') : ''
    cell: row => <Row>
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Valor:</b>
              </p>
              <p style={{color: '#3d8149',fontSize: '16px',fontWeight: 'bold', marginBottom: '1rem'}}>
                {parseFloat(row.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              </p>
            </Col>
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Forma de pagamento:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                {payment(row.payment_type)} {row.installment_plan !== null && row.installment_plan.number_installments !== undefined ? row.installment_plan.number_installments : ' À Vista'}
              </p>
            </Col>
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Data do pedido:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                {row.created_at !== null ? moment(row.created_at).format('DD/MM/YYYY HH:mm') : ''}
              </p>
            </Col>
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>ID da transação:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                {row.id}
              </p>
            </Col>   
          </Row>
  },
  {
    name: "Informações",
    selector: "informations",
    sortable: false,
    width: '20%',
    cell: row => <Row>
            {row.individual !== null ?
            <>  
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Saldo atual:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                 {parseFloat(row.individual.account_balance).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              </p>
            </Col>  
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Saldo na conta:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                {parseFloat(row.individual.account_balance).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              </p>
            </Col>
            </>:null}
            {row.business !== null ?
            <>  
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Saldo atual:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                 {parseFloat(row.business.account_balance).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              </p>
            </Col>  
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Saldo na conta:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                {parseFloat(row.business.account_balance).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              </p>
            </Col>
            </>:null}
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Tipo:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                {getMeioPagamento(row.point_of_sale.entry_mode, payment(row.payment_type))}
              </p>
            </Col>   
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Status:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                {getStatus(row.status)}
              </p>
            </Col>  
            {row.payment_type != 'boleto' ? (
              <Col md={12} style={{marginTop: '0.3rem'}}>
                <p style={{marginBottom: '0.2rem'}}>
                  <b>Modalidade:</b>
                </p>
                <p style={{marginBottom: '1rem'}}>
                  {row.point_of_sale !== null ? (row.point_of_sale.entry_mode == 'chip' ? 'CP - Cartão Presente' : 'CNP - Cartão não presente') : ''}
                </p>
              </Col>
            ) : (<></>)}
  
          </Row>
  },
  {
    name: "Compliance",
    selector: "compliance",
    sortable: false,
    width: '20%',
    cell: row => <Row>
            {row.compliance !== null ?
            <>
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Limite liberado On-line:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                {parseFloat(row.compliance.limit_online).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              </p>
            </Col>   
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Limite liberado terminal POS:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                {parseFloat(row.compliance.limit_pos).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              </p>
            </Col>  
            <Col md={12}>
            {row.compliance.documents.map(p => {
                  return <small>
                            <b>Descrição: </b>{p.descricao}<br/>
                            <b>Distrato:</b> {p.distract}<br/>
                            <b>Enviado pelo Operador:</b> {p.auth_name}<br/>
                            <b><a href={p.url+'?access_token='+getToken()} target="_blank">
                                Abrir Documento
                            </a></b>
                            <br/>
                        </small>
              })}
            </Col>
            </>
            :
            <>
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Limite liberado On-line:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                {parseFloat(0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              </p>
            </Col>   
            <Col md={12} style={{marginTop: '0.3rem'}}>
              <p style={{marginBottom: '0.2rem'}}>
                <b>Limite liberado terminal POS:</b>
              </p>
              <p style={{marginBottom: '1rem'}}>
                {parseFloat(0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              </p>
            </Col>  
            </>
            }
          </Row>
  },
];

const getDetailsPayout = (value) => {
  var details = {
    id_transacao: value.transation_payload.id,
    data_venda: value.transation_payload.created_at,
    detalhes: value.transation_payload.details,
    taxa: value.transation_payload.fees,
    transaction_number: value.transation_payload.transaction_number,
    documento: value.seller_payload.type === 'individual' ? value.seller_payload.individual.taxpayer_id : value.seller_payload.business.ein,
    telefone: value.seller_payload.type === 'individual' ? value.seller_payload.phone_number : value.seller_payload.owner.phone_number,
    email: value.seller_payload.type === 'individual' ? value.seller_payload.email : value.seller_payload.business_email,
    valor: value.transation_payload.amount,
    nome: (value.seller_payload.type === 'individual' ? value.seller_payload.first_name+' '+value.seller_payload.last_name : value.seller_payload.business_name),
    created_at: moment(value.transation_payload.created_at).format('DD/MM/YYYY HH:mm')
  }
  return JSON.stringify(details);
}

export const sendSMS = (telefones, callbackRows) => {
  var url = ENDPOINT + "payout/enviar_sms";
  var formData = new FormData();
  telefones.forEach((p, i) => {
    formData.append('telefones['+i+']', getDetailsPayout(p));
  });

  axios.post(url, formData, {
      headers: {
        Authorization: 'Bearer '+getToken()
      }
  })
  .then((response) => {
      var data = response.data;
      callbackRows(data);
      return true;
  })
  .catch((error) => {
    
    toast.error('Ocorreu um erro no servidor');
    return false;
  });
}
export const sendMails = (emails, callbackRows) => {
  var url = ENDPOINT + "payout/enviar_emails";
  var formData = new FormData();
  emails.forEach((p, i) => {
    formData.append('emails['+i+']', getDetailsPayout(p));
  });

  axios.post(url, formData, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }
  })
  .then((response) => {
      var data = response.data;
      callbackRows(data);
      return true;
  })
  .catch((error) => {
    
    toast.error('Ocorreu um erro no servidor');
    return false;
  });
}

export const getRows = (state, callback) => {
  var url = ENDPOINT + "payout/listar_pagamentos_bloqueados?page=" + state.activePage + "&search=" + state.search;

  var document = state.document.replace(/([^\d])+/gim, '');
  if(state.is_cnpj === false){
    if(document.length === 11){
      url += "&cpf_cnpj="+document;
    }
  }
  else{
      if(document.length === 14){
        url += "&cpf_cnpj="+document;
      }
  }
  
  if(state.transacao_id !== null){
    url += "&transacao_id="+state.transacao_id;
  }  

  if(state.pagamento_liberado !== null){
    url += "&pagamento_liberado="+state.pagamento_liberado.value;
  }

  if(state.filter === true){
      url += "&date_from="+state.date_from+'&date_to='+state.date_to;
  }
  
  if(parseFloat(state.valor_ate) > 0){
    url += "&valor_de="+state.valor_de;
  }

  if(parseFloat(state.valor_ate) > 0){
      url += "&valor_ate="+state.valor_ate;
  }

  
  if(state.modalidade !== null){
    url += "&modalidade="+state.modalidade.value;
  }

  if(state.razao_bloqueio !== null){
    url += "&razao_bloqueio="+state.razao_bloqueio.value;
  }

  if(state.motivo !== null){
    url += "&motivo="+state.motivo.value;
  }

  if(state.mail_received === true){
      url += "&email_recebido="+state.mail_received;
  }

  
  //console.log(state)
/*   if(state.status_user.length > 0){
    url += "&status_usuario="+state.status_user
  } */

  // var myHeaders = new Headers();

  // myHeaders.append('Authorization', 'Bearer ' + getToken());

  // var myInit = {
  //   method: 'GET',
  //   headers: myHeaders,
  //   mode: 'cors',
  //   cache: 'default'
  // };
  // fetch(url, myInit)
  //   .then(response => response.json())
  //   .then((p) => {
  //     //console.log(p)
  //       var meta = 0;
  //       if(p.meta){
  //         meta = p.meta.last_page;
  //       }
  //       callback(p.data, meta);
  //   })
  //   .catch((error) => {
  //       toast.error(error.toString());
  //   })

    callback([]);
}

export const getRowsPerPage = (page, state, callback) => {
  var url = ENDPOINT + "payout/listar_pagamentos_bloqueados?page=" + state.activePage + "&search=" + state.search;

  var document = state.document.replace(/([^\d])+/gim, '');
  if(state.is_cnpj === false){
    if(document.length === 11){
      url += "&cpf_cnpj="+document;
    }
  }
  else{
      if(document.length === 14){
        url += "&cpf_cnpj="+document;
      }
  }
  
  if(state.transacao_id !== null){
    url += "&transacao_id="+state.transacao_id;
  }  

  if(state.pagamento_liberado !== null){
    url += "&pagamento_liberado="+state.pagamento_liberado.value;
  }
  if(state.filter === true){
      url += "&date_from="+state.date_from+'&date_to='+state.date_to;

  }

  if(parseFloat(state.valor_ate) > 0){
    url += "&valor_de="+state.valor_de;
  }

  if(parseFloat(state.valor_ate) > 0){
      url += "&valor_ate="+state.valor_ate;
  }
  
  if(state.modalidade !== null){
    url += "&modalidade="+state.modalidade.value;
  }

  if(state.razao_bloqueio !== null){
    url += "&razao_bloqueio="+state.razao_bloqueio.value;
  }

  if(state.motivo !== null){
    url += "&motivo="+state.motivo.value;
  }

  if(state.mail_received === true){
      url += "&email_recebido="+state.mail_received;
  }

  //console.log(state)
  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      console.log(p)
      var meta = 0;
      if(p.meta){
        meta = p.meta.last_page;
      }
      callback(p.data, meta);
    })
    .catch((error) => {
      toast.error(error.toString());
    })
}

export const getRelatorio = (callback) => {
  var url = ENDPOINT + "payout/relatorio";
  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      console.log(p)
      callback(p);
    });
}

export const getAllRows = (state, callback) => {
  var url = ENDPOINT + "payout/listar_pagamentos?page=" + state.activePage + "&search=" + state.search;

  var document = state.document.replace(/([^\d])+/gim, '');
  if(state.is_cnpj === false){
    if(document.length === 11){
      url += "&cpf_cnpj="+document;
    }
  }
  else{
      if(document.length === 14){
        url += "&cpf_cnpj="+document;
      }
  }
  
  if(state.filter === true){
      url += "&date_from="+state.date_from+'&date_to='+state.date_to;

  }

  if(state.status_pagamento !== null){
      url += "&status_pagamento="+state.status_pagamento.value;
  }
  
  if(state.forma_pagamento !== null){
      url += "&forma_pagamento="+state.forma_pagamento.value;
  }
  
  if(state.modalidade !== null){
      url += "&modalidade="+state.modalidade.value;
  }

  if(state.mail_received === true){
      url += "&email_recebido="+state.mail_received;
  }

  if(parseFloat(state.valor_ate) > 0){
      url += "&valor_de="+state.valor_de;
  }
  
  if(parseFloat(state.valor_ate) > 0){
      url += "&valor_ate="+state.valor_ate;
  }
/*   if(state.status_user.length > 0){
    url += "&status_usuario="+state.status_user
  } */

  // var myHeaders = new Headers();

  // myHeaders.append('Authorization', 'Bearer ' + getToken());

  // var myInit = {
  //   method: 'GET',
  //   headers: myHeaders,
  //   mode: 'cors',
  //   cache: 'default'
  // };
  // fetch(url, myInit)
  //   .then(response => response.json())
  //   .then((p) => {
  //     //console.log(p)
  //     var meta = 0;
  //     if(p.meta){
  //       meta = p.meta.last_page;
  //     }
  //     callback(p.data, meta);
  //   })
  //   .catch((error) => {
  //       toast.error(error.toString());
  //   })

    callback([]);
}

export const getAllRowsPerPage = (page, state, callback) => {
  var url = ENDPOINT + "payout/listar_pagamentos?page=" + page + "&search=" + state.search;

  var document = state.document.replace(/([^\d])+/gim, '');
  if(state.is_cnpj === false){
    if(document.length === 11){
      url += "&cpf_cnpj="+document;
    }
  }
  else{
      if(document.length === 14){
        url += "&cpf_cnpj="+document;
      }
  }
  
  
  if(state.transacao_id !== null){
    url += "&transacao_id="+state.transacao_id;
  }  

  
  if(state.filter === true){
      url += "&date_from="+state.date_from+'&date_to='+state.date_to;
  }
  
  if(state.status_pagamento !== null){
      url += "&status_pagamento="+state.status_pagamento.value;
  }
  
  if(state.forma_pagamento !== null){
      url += "&forma_pagamento="+state.forma_pagamento.value;
  }
  
  if(state.modalidade !== null){
      url += "&modalidade="+state.modalidade.value;
  }

  if(state.mail_received === true){
      url += "&email_recebido="+state.mail_received;
  }
  
  if(parseFloat(state.valor_ate) > 0){
      url += "&valor_de="+state.valor_de;
  }
  
  if(parseFloat(state.valor_ate) > 0){
      url += "&valor_ate="+state.valor_ate;
  }

  //console.log(url)

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      console.log(p)
      var meta = 0;
      if(p.meta){
        meta = p.meta.last_page;
      }
      callback(p.data, meta);
    })
    .catch((error) => {
      toast.error(error.toString());
    })
}