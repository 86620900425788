import React, { Component } from 'react';
import { Button, Card, CardBody, Input, Collapse, Row, FormGroup, CardFooter, Col, Modal, ModalBody, ModalFooter, ModalHeader, CardHeader, Nav, NavLink, TabPane, TabContent } from 'reactstrap';
import styled from 'styled-components';
import { GiTicket } from 'react-icons/gi';
import { getPermissoes, getToken } from '../../auth/set_services';
import { ENDPOINT } from '../../auth/endpoint';
import { MdAttachFile, MdClear, MdReply, MdMail, MdSms, MdAdd, MdSettings } from 'react-icons/md';
import { BsReplyAll } from 'react-icons/bs';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import { flag_origem, list_status } from '../../auth/listas';
import Dropzone from 'react-dropzone';
import ReactTooltip from 'react-tooltip';
import TextareaAutosize from 'react-textarea-autosize';
import ModelReturn from '../../components/Modals/ReturnsApiModals/ModelReturn';
import sanitizeHtml from 'sanitize-html';
import AsyncSelect from 'react-select/async';
import classnames from 'classnames';
import { Resumo } from './Tabs/Resumo';
import { Configuracoes } from './Tabs/Configuracoes';
import { Ligacoes } from './Tabs/Ligacoes';
import { FaTasks } from 'react-icons/fa';
import FadeLoader from "react-spinners/FadeLoader";

const DropArea = styled.div`
    border-style: dashed;
    border-color: #38B76E;
    padding: 1%;
    cursor: pointer;
    &:drag {
        opacity: 0.5;
    }
`;
const ButtonUpload = styled.button`
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0;
    padding-left: 7px;
    padding-right: 7px;
    top: -1px;
    position: relative;
`;

const get_protocolo = () => {
    return window.location.pathname.replace('/ticket-detalhe/', '')
}

/* const getActive = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('active');
    if(id !== null){
        return id;
    }
    return "1";

} */


const getOrigem = (origem) => {
    return <div>
        {flag_origem.map(p => {
            if(p.value === origem){
                if(p.src){
                    return <p.label src={p.src} style={{width: 24, padding: 0, margin: '8px'}}/>;
                }
                return <p.label style={{padding: 0, margin: '8px'}}/>;
            }
            return null;
        })}
    </div>
}

const Div = styled.div`
    .nav-item{
        margin: 0
    }

    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
`;

const StyleText = styled.div`
    .msg{
        margin-top: 7px;
        color: black !important;
        margin-right: 5px;
        resize: none;
        height: auto !important;
        min-height: 93px !important;
        max-height: 55vh !important;
        border-right: none;
        border-left: none;
        border-top: none;
        border-bottom: none;
        overflow: auto;
        box-shadow: none !important;
        border: none !important;
        line-height: 14px;
        outline: 0px solid transparent !important;
    }
    .msg:focus{
        box-shadow: none !important;
        border: none !important;
    }
`

const getUser = (id, callback) => {
    var url = ENDPOINT + "ticket-admin/detalhes/" + id;

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            console.log(p.data)
            callback(p.data);
        })
}

const getStringMaps = (mail) => {
    var string_mail = "";
    mail.forEach((p) => {
      string_mail += getTranslate(p.recordType) + " " + moment(new Date(p.dateAt).toString()).format('DD/MM/YYYY h:mm:ss a') + "<br /> "
    });
  
    return string_mail;
}
const getStringMapsPhone = (respostas) => {
  var string_respostas = ""
  respostas.forEach((p) => {
    string_respostas += getStatusPhone(p.tipo_resposta) + " - " + (p.resposta ? getResposta(p.resposta) : '') + " " + moment(new Date(p.dataResposta).toString()).format('DD/MM/YYYY h:mm:ss a') + "<br /> "
  })
  return string_respostas;
}

const getStatusPhone = (status) => {
    if(status === 'pendente'){
        return "Pendente";
    }
    else if(status === 'DELIVERED'){
        return "Entregue";
    }
    else{
        return status;
    }
}


const getTranslate = (name) => {
    switch (name) {
        case 'Delivery': return 'Entregue'
        case 'Open': return 'Aberto'
        case 'Click': return 'Clicado'
        case 'SpamComplaint': return 'Caixa de SPAM'
        case 'Bounce': return 'Mensagem devolvida'
        default : return name;
    }
}
const getResposta = (resposta) => {
    if(resposta === 'Message delivered to handset'){
        return "entregue ao destinatário";
    }
    else{
        return resposta;
    }
}

export default class ResumoTicket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            protocolo: '',
            protocolo_ticket: get_protocolo(),
            cod_ticket:  get_protocolo(),
            assunto: '',
            nome_cliente: '',
            email: '',
            telefone: '',
            status: '',
            protocolo_ocorrencia: '',
            status_flag: 'fechado',
            documento: '',
            departamento: '',
            departamento_value: '',
            criado_em: '',
            observacao: '',
            origem: '',
            observacao_invalid: false,
            replys: [],
            funcionario: null,
            
            cod_resposta: '',
            visible_nota: false,
            loading: false,
            error_message: '',
            success_message: '',

            respostas_prontas: [],
            resposta: null,
            loading_button: false,
            descricao: '',
            validade: '',
            files: [],
            modalTicket: false,
            loading_user: false,
            activeTab: "1",
            list_departamentos: [],
            chamadas: [],
            avaliacao: null
        }
        this.inputFile = React.createRef();
        this.myRef = React.createRef();
        this.callbackRows = this.callbackRows.bind(this);
        this.onRegister = this.onRegister.bind(this);
        this.onClose = this.onClose.bind(this);
    }
    componentDidMount() {
        getUser(
            this.state.protocolo_ticket,
            this.callbackRows
        );
        this.getDepartamentos();
    }
    getDepartamentos = () => {
        var url = ENDPOINT+"departamento/list";
    
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            var list_departamentos = [];
            data.forEach((p, i) => {
                p['value'] = p.flag;
                p['label'] = p.nome;
                list_departamentos.push(p);
            })
            this.setState({list_departamentos});
        })
        .catch((error) => {

        });
    }
    callbackRows(data) {

        if (data.respostas !== undefined && data.respostas.length) {
            var urlApi = ENDPOINT.replace('api/','')
            data.respostas.map((resposta, i) => {
                if(data.respostas[i].anexos !== undefined && data.respostas[i].anexos.length) {
                    resposta.anexos.map((anexo, ps) => {
                        if(data.respostas[i].anexos[ps].url_anexo !== undefined) {
                            data.respostas[i].anexos[ps].url_anexo = anexo.url_anexo.replace('https://apicrm.aqbank.io/', urlApi)
                        }
                    })
                }
            })
        }
        
        this.setState({
            nome_cliente: data.nome,
            email: data.email,
            telefone: (data.telefone !== null ? data.telefone: ''),
            protocolo_ocorrencia: (data.protocolo_ocorrencia !== null ? data.protocolo_ocorrencia: ''),
            assunto: data.assunto,
            documento: data.document,
            protocolo: data.protocolo_ticket,
            replys: data.respostas,
            status_flag: data.status,
            funcionario: data.funcionario,
            origem: data.origem,
            validade: data.validade_ticket,
            avaliacao: data.avaliacao,
            chamadas: data.chamadas,
            loading_user: true
        });

        if(data.cod_ticket){
            this.setState({
                cod_ticket: data.cod_ticket
            })
        }

        if(data.departamento_ !== null){
            this.setState({
                departamento: data.departamento_.nome,
                departamento_value: data.departamento_.flag
            })
        }
        var status = '';
        list_status.forEach((p) => {
            if (p.value === data.status) {
                status = p.label
            }
        });
        
        this.setState({
            status
        });
    }

    /* listData = [
        { name: 'Informações da ocorrência', active: '1' },
        { name: 'Imagens', active: '2' },
        { name: 'Linha do Tempo', active: '3' },
        { name: 'Notas (Comentarios Internos)', active: '4' },
    ] */

    /* Navs = () => {
        return <Nav tabs>
            {this.listData.map((p, index) => {
                return <NavLink
                    key={index}
                    className={
                        classnames({ active: this.state.activeTab === p.active })}
                    onClick={() => { this.setState({ activeTab: p.active }); }}>
                    <span>{p.name}</span>
                </NavLink>
            })}
        </Nav>
    } */

    handleFile = (e) => {
        if(e.target.files.length > 0){
            this.setState({
                file: e.target.files[0]
            })
        }
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        
        this.setState({ [name]: value });
    }
    
    responderTicket = () => {
        this.setState({
            loading_button: true
        });

        var url = ENDPOINT + "ticket-admin/responder";
        var formData = new FormData();
        if(this.state.resposta !== null){
            formData.append('descricao', this.tratar_string(this.state.resposta.conteudo));
        }
        else{
            formData.append('descricao', this.state.descricao);
        }

        formData.append('cod_ticket', this.state.cod_ticket);

        
        if(this.state.files.length > 0){
            this.state.files.forEach((file, index) => {
                formData.append('anexo['+index+']', file);
            });
        }

        axios.post(url, formData, {
          headers: {
              Authorization: 'Bearer '+getToken()
          }})
        .then((response) => {
            var data = response.data;
            toast.success(data.message);

            getUser(this.state.cod_ticket, this.callbackRows);
            
            this.setState({
                modalTicket: false,
                descricao: '',
                loading_button: false,
                resposta: null,
                anexos: []
            });
        })
        .catch(() => {
            this.setState({
                loading_button: false
            });
            toast.error('Ocorreu um erro');
        });
    }
    fecharTicket = () => {
        var url = ENDPOINT + "ticket-admin/fechar_ticket";
        var formData = new FormData();
        formData.append('cod_ticket', this.state.cod_ticket);

        axios.post(url, formData, {
          headers: {
              Authorization: 'Bearer '+getToken()
          }})
        .then((response) => {
            var data = response.data;
            toast.success(data.message);

            getUser(this.state.cod_ticket, this.callbackRows);

            this.setState({
                modalTicket: false,
                descricao: '',
                anexos: []
            });
        })
        .catch(() => {
            toast.error('Ocorreu um erro');
        });
    }
    
    onRegister() {
        if(this.state.observacao.length > 0){
            this.setState({loading: true});
            this.store();
        }
        else{
            toast.error('Descreva a nota')
        }
    }
    
    onData(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            });

            getUser(this.state.cod_ticket, this.callbackRows);
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }

    store = () => {
        var url = ENDPOINT+"ticket-admin/criar-nota";

        var formData = new FormData();
        formData.append('descricao', this.state.observacao);
        formData.append('cod_resposta', this.state.cod_resposta);
    
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            this.onData(data);
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onData(obj);
            }
            var data = error.response.data;
            this.onData(data);
        });
    }
    /*

    adicionarImagem = () => {
        var url = ENDPOINT + "ocorrencia/nova-imagem";
        var formData = new FormData();
        formData.append('descricao', this.state.descricao_image);
        formData.append('protocolo_ocorrencia', this.state.protocolo_ocorrencia);
        formData.append('image_foto', this.state.file, this.state.file.name);

        axios.post(url, formData, {
          headers: {
              Authorization: 'Bearer '+getToken()
          }})
        .then((response) => {
            var data = response.data;
            toast.success(data.message);

            getUser(this.state.protocolo, this.callbackRows);

            this.setState({
                modalImage: false,
                descricao_image: '',
                file: null
            });
        })
        .catch(() => {
            toast.error('Ocorreu um erro');
        });
    } */
    onDrop = (file) => {
        var state = this.state;
        file.forEach(file_current => {
            state.files.push(file_current);
        });
        this.setState({
            ...state
        })
    }
    handleFileChange = (event) => {
        const files = event.target.files;
        var state = this.state;
        Array.from(files).forEach(file_current => {
            state.files.push(file_current);
        });
        this.setState({
            ...state
        })
    }
    removeAttach = (index) => {
        var state = this.state;
        state.files.splice(index, 1);
        this.setState({
            ...state
        })
    }
    openNotas(visible_nota, cod_resposta) {
        this.setState({ visible_nota, cod_resposta })
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    onClose() {
        this.setState({
            visible_nota: false,
            cod_resposta: '',
            observacao: '',
            observacao_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        });
    }
    
    handleInputChangeSelect = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
      };
    
      loadOptions = (inputValue, callback) => {
        var url = ENDPOINT + "respostas/list_for_chat?page=1&search=" + inputValue;
        var myHeaders = new Headers();
      
        myHeaders.append('Authorization', 'Bearer ' + getToken());
      
        var myInit = {
          method: 'GET',
          headers: myHeaders,
          mode: 'cors',
          cache: 'default'
        };
        fetch(url, myInit)
          .then(response => response.json())
          .then((p) => {
              var data = p;
              data.forEach((p, i) => {
                  data[i].conteudo = this.tratar_string(p.conteudo);
                  data[i].label = p.nome;
                  data[i].value = p.flag;
              });
  
              this.state.respostas_prontas.forEach((p, i) => {
                  data.forEach((p2, i2) => {
                      /* eslint eqeqeq: 0 */
                      if(p2.value == p.value){
                          data.splice(i2, 1);
                      }
                  });
              });
  
              callback(this.filter(inputValue, p));
          });
      };
    tratar_string = (string) => {
        string = string.replace('{{ $Nome }}', this.state.nome_cliente);
        string = string.replace('{{ $Telefone }}', (this.state.telefone !== null ? this.state.telefone : ''));
        string = string.replace('{{ $E-mail }}', this.state.email);

        return string;
    }
    filter = (inputValue, data) => {
          return data.filter(i =>
            i.flag.toLowerCase().includes(inputValue.toLowerCase())
          );
    };
    isRespostas = (resposta) => {
        this.setState({resposta})
    }

    listData = [
        { name: <><GiTicket/>Informações de Ticket</>, active: '1' },
        { name: <><FaTasks />Tarefas </>, active: '2' },
        { name: <><MdSettings />Configurações</>, active: '3' }
    ];
    Navs = () => {
        return <Nav tabs>
            {this.listData.map((p, index) => {
                return <NavLink
                    key={index}
                    className={
                        classnames({ active: this.state.activeTab === p.active })}
                    onClick={() => { this.setState({ activeTab: p.active }); }}>
                    <span>{p.name}</span>
                </NavLink>
            })}
        </Nav>
    }
    
  
    render() {
        return (
            <Div>
                <div style={{display: 'flex'}}>{getOrigem(this.state.origem)} <h3>Ticket: {this.state.assunto}</h3></div>
                <hr />
                <Card hidden={this.state.loading_user == true}>
                    <CardBody style={{height: '120px'}}>
                        <center>
                            <FadeLoader color={'#52c565'} margin={0} loading={50} size={60} />
                        </center>
                    </CardBody>
                </Card>
                <div>
                    <Card hidden={this.state.loading_user == false}>
                        <CardBody>
                            <this.Navs/>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId={'1'}>
                                        <Resumo {...this.state} />
                                    </TabPane>
                                    <TabPane tabId={'2'}>
                                        <Ligacoes 
                                            refreshUser={() => {
                                                getUser(this.state.cod_ticket, this.callbackRows);
                                            }}
                                            chamadas={this.state.chamadas}
                                            cod_ticket={this.state.cod_ticket} />
                                    </TabPane>
                                    <TabPane tabId={'3'}>
                                        <Configuracoes 
                                            refreshUser={() => {
                                                getUser(this.state.cod_ticket, this.callbackRows);
                                            }}
                                            {...this.state} />
                                    </TabPane>
                                </TabContent>
                        </CardBody>
                    </Card>
                    <Card style={{marginTop: 15}} hidden={this.state.status_flag === 'fechado' || this.state.loading_user == false}>
                        <CardBody hidden={this.state.departamento_value === ''}>
                            <Button color="success" onClick={() => this.setState({modalTicket: !this.state.modalTicket, descricao:  '', anexos: []})}><MdReply /> Responder Ticket</Button>
                            <Button color="danger" onClick={() => this.fecharTicket()} style={{marginLeft: '5px'}}>Fechar Ticket</Button>
                            <Collapse isOpen={this.state.modalTicket}><hr />
                                <FormGroup hidden={this.state.resposta !== null}>
                                    <Input 
                                        type="textarea"
                                        placeholder="Descrição"
                                        style={{ height: 120, resize: 'none' }}
                                        name="descricao"
                                        value={this.state.descricao}
                                        onChange={this.handleInputChange} />
                                </FormGroup>
                                <FormGroup>
                                <AsyncSelect
                                    style={{width: '100%'}}           
                                    cacheOptions
                                    loadOptions={this.loadOptions}
                                    defaultOptions
                                    isClearable
                                    value={this.state.resposta} 
                                    placeholder="Respostas Prontas"
                                    onChange={this.isRespostas}
                                    onInputChange={this.handleInputChangeSelect}
                                />
                                </FormGroup>
                                <hr />
                                <Dropzone onDrop={(drop) => this.onDrop(drop)}>
                                    {({ getRootProps, getInputProps }) => (
                                        <DropArea {...getRootProps({
                                            onClick: event => event.stopPropagation()
                                        })}>
                                            <input {...getInputProps()} />
                                            {this.state.files.length > 0 ?
                                                <>
                                                    <Row>
                                                        {this.state.files.map((e, index) => {
                                                            return <Col key={index} md={4} style={{ paddingBottom: 4 }}>
                                                                <Card>
                                                                    <p style={{ margin: '3px' }}>
                                                                        <MdAttachFile style={{ width: '32px' }} />
                                                                        {e.name}
                                                        <Button
                                                                            style={{ padding: 0, border: 3, float: 'right' }}
                                                                            color="danger"
                                                                            outline
                                                                            onClick={
                                                                                () => this.removeAttach(index)
                                                                            }>
                                                                            <MdClear style={{ padding: 0 }} />
                                                                        </Button>
                                                                    </p>
                                                                </Card>
                                                            </Col>
                                                        })}
                                                        <br />
                                                    </Row>
                                                    <p style={{ margin: 0 }}>
                                            <MdAttachFile style={{ width: '32px' }} />
                                                Arraste arquivos ou&nbsp;
                                                <ButtonUpload 
                                                    onClick={() => this.inputFile.current.click()} 
                                                    className="btn btn-dark" 
                                                    color="primary">
                                                    Clique Aqui
                                                </ButtonUpload> para adicionar mais anexos
                                                <input type="file" ref={this.inputFile} multiple hidden onChange={this.handleFileChange} />
                                        </p>
                                    </>
                                    :
                                    <>
                                        <p style={{ margin: 0 }}>
                                            <MdAttachFile style={{ width: '32px' }} />
                                                Arraste arquivos ou&nbsp;
                                                <ButtonUpload 
                                                    onClick={() => this.inputFile.current.click()} 
                                                    className="btn btn-dark" 
                                                    color="primary">
                                                    Clique Aqui
                                                </ButtonUpload> para adicionar anexos
                                                <input type="file" ref={this.inputFile} multiple hidden onChange={this.handleFileChange} />
                                        </p>
                                    </>
                                            }
                                        </DropArea>
                                    )}
                                </Dropzone>
                                <hr />
                                <FormGroup>
                                    <Button color="success" onClick={() => this.responderTicket()} disabled={(this.state.descricao.length === 0 && this.state.resposta === null) || this.state.loading_button === true}>Responder</Button>&nbsp;
                                    <Button color="danger" onClick={() => this.setState({modalTicket: false, descricao:  ''})}>Fechar</Button>
                                </FormGroup>
                            </Collapse>
                        </CardBody>
                        <CardBody hidden={this.state.departamento_value !== ''}>
                            <p>É necessario definir o departamento para responder o ticket</p>
                        </CardBody>
                    </Card>
                    <br/>
                    {this.state.replys.map((p) => {
                        return <><Card className={p.operador !== null ? "bg-dark text-white" : 'border-success'}>
                            <CardHeader>
                                <b><BsReplyAll/>{moment(new Date(p.created_at)).format('DD/MM/YYYY HH:mm')}</b>
                            </CardHeader>
                            <CardBody>
                                {p.operador !== null ? 
                                    <><b>Operador: {p.operador.name}</b><br/></>
                                    : <></>}
                                <b>Descrição: </b>
                                <div 
                                   // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{ __html: sanitizeHtml(p.descricao)}} />
                                <br/>
                                <div style={{display: 'flex'}}>
                                    <div>
                                        <small hidden={p.email_detalhes === null}>
                                            <p style={{marginLeft: '7px'}}><b>Status do e-mail:</b></p>
                                            {p.email_detalhes !== null ?
                                                p.email_detalhes.mail.length > 0 ? (
                                                    <div data-tip={getStringMaps(p.email_detalhes.mail)} 
                                                        style={{ maxHeight: '200px', cursor: 'pointer', overflow: 'auto', width: '100%', margin: '7px' }}>
                                                        <ul style={{ listStyleType: 'none', paddingLeft: 6 }}>
                                                        {p.email_detalhes.mail.slice(0, 1).map((d) => {
                                                            return <li>
                                                            <p><MdMail /> <b>{getTranslate(d.recordType)}</b>
                                                                <br />
                                                                {moment(new Date(d.dateAt).toString()).format('DD/MM/YYYY h:mm:ss a')}</p>
                                                            </li>
                                                        })}
                                                        </ul>
                                                    </div> )
                                                    :
                                                    <div data-tip={"Postado " + moment(new Date(p.email_detalhes.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')} 
                                                        style={{ maxHeight: '200px', cursor: 'pointer', overflow: 'auto', width: '100%', margin: '7px' }}>
                                                        <ul style={{ listStyleType: 'none', paddingLeft: 6 }}>
                                                            <li style={{ listStyleType: 'none' }}><MdMail /> <b>Postado</b><br />{moment(new Date(p.email_detalhes.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                :
                                                <></>
                                            }
                                        </small>
                                    </div>
                                    <div style={{marginLeft: '16px'}}>
                                        <small hidden={p.sms_detalhes === null}>
                                            <p style={{marginLeft: '7px'}}><b>Status de sms:</b></p>
                                            {p.sms_detalhes !== null ?
                                                p.sms_detalhes.respostas.length > 0 ? (
                                                    <div data-tip={getStringMapsPhone(p.sms_detalhes.respostas)} style={{ maxHeight: '200px', cursor: 'pointer', overflow: 'auto', width: '100%', margin: '7px' }}>
                                                        <ul style={{ listStyleType: 'none', paddingLeft: 6 }}>
                                                        {p.sms_detalhes.respostas.slice(0, 1).map((p) => {
                                                            return <li>
                                                            <p><MdSms /> <b>{getStatusPhone(p.tipo_resposta)}</b>
                                                                <br />
                                                                {p.resposta ? <><b>Mensagem:</b> {getResposta(p.resposta)}<br /></> : ''}
                                                                {moment(new Date(p.dataResposta).toString()).format('DD/MM/YYYY h:mm:ss a')}</p>
                                                            </li>
                                                        })}
                                                        </ul>
                                                        <ReactTooltip multiline={true} />
                                                    </div> )
                                                    :
                                                    <div data-tip={"Postado " + moment(new Date(p.sms_detalhes.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')} 
                                                            style={{ 
                                                            maxHeight: '200px', 
                                                            cursor: 'pointer', 
                                                            overflow: 'auto', 
                                                            width: '100%', 
                                                            margin: '12px' 
                                                            }}>
                                                        <ul  style={{ listStyleType: 'none', paddingLeft: 6 }}>
                                                            <li style={{ listStyleType: 'none' }}><MdSms /> <b>Postado</b><br />{moment(new Date(p.sms_detalhes.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')}
                                                            </li>
                                                        </ul>
                                                        <ReactTooltip multiline={true} />
                                                    </div>
                                                :
                                                <></>
                                            }
                                        </small>
                                    </div>
                                </div>
                                <ReactTooltip multiline={true} />
                                <div>
                                    <b>Notas Privadas: </b>
                                    <Button outline onClick={() => this.openNotas(true, p.cod_resposta)} color="success" style={{padding: 0, border: 'none'}}><MdAdd style={{padding: 0}}/></Button>
                                    {p.notas_privadas.length > 0 ? 
                                    <>
                                        <br/>
                                        <ul style={{ listStyleType: 'none', paddingLeft: 6, marginTop: 13 }}>
                                            {p.notas_privadas.map(i => {
                                                return <li style={{
                                                        display: 'inline-block', 
                                                        marginRight: '28px', 
                                                        border: '1px solid', 
                                                        padding: '16px', 
                                                        borderBottomLeftRadius: '15px', 
                                                        borderTopRightRadius: '15px',
                                                        marginBottom: '15px',
                                                        width: '300px',
                                                    }}>
                                                    <p>{i.admin !== null ? <b>{i.admin.name}</b> : <b>Operador não encontrado</b>}
                                                        <br/>
                                                        <div style={{
                                                            height: '100px',
                                                            overflow: 'auto'
                                                        }}>{i.descricao.split('\n').map((x, i, {length}) => (
                                                            <p style={{margin: (i + 1 === length ? 0 : 3)}}>{x}</p>
                                                        ))}</div>
                                                    </p>
                                                </li>

                                            })}

                                        </ul>
                                    </>
                                    : 
                                    <></>
                                    }
                                </div>
                            </CardBody>
                            <CardFooter hidden={p.anexos.length === 0}>
                                Anexos:&nbsp;
                                {p.anexos.map((p, i) => {
                                    return <Button outline style={{marginTop: 3, marginRight: 5}} onClick={() => window.open(p.url_anexo+'?access_token='+getToken(), '_blank')}>Anexo {("000" + (i+1)).slice(-3)}</Button>
                                })}
                            </CardFooter>
                        </Card>
                        <br/>
                        </>
                    })}
                </div>
                <Row>
                </Row>
                <br />
                <Modal isOpen={this.state.visible_nota}>
                    <ModalHeader>Nova Nota</ModalHeader>
                    <ModalBody hidden={this.state.loading}>
                        <StyleText>
                            <Card>
                                <CardBody style={{paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
                                    <TextareaAutosize 
                                        className="msg"
                                        style={{ resize: 'none', lineHeight: '20px', background: 'transparent', width: '100%' }} 
                                        type="textarea" 
                                        placeholder="Descrição da nota" 
                                        name="observacao"
                                        value={this.state.observacao} 
                                        invalid={this.state.observacao_invalid} 
                                        onChange={this.handleInputChange}
                                    />
                                </CardBody>
                            </Card>
                        </StyleText>
                    </ModalBody>
                    <ModalBody
                        style={{
                            minHeight: '114px',
                            display: 'flex',
                            alignItems: 'center',
                            alignSelf: 'center',
                            paddingBottom: '47px'
                        }} hidden={!this.state.loading}>
                        <ModelReturn
                            error_message={this.state.error_message}
                            success_message={this.state.success_message}
                            loading={this.state.loading}
                            onClose={this.onClose}
                            onError={this.onError.bind(this)}
                        />
                    </ModalBody>
                    <ModalFooter hidden={this.state.loading}>
                        <Button color="danger" onClick={() => this.onClose()}>Cancelar</Button>
                        <Button color="success" onClick={() => this.onRegister()}>Salvar</Button>
                    </ModalFooter>
                </Modal>
            </Div>
        )
    }
}