import React, { Component } from "react";
import classnames from 'classnames';
import { FaBox, FaUser } from "react-icons/fa";
import { MdMail, MdMoneyOff } from "react-icons/md";
import { Button, Card, CardBody, CardColumns, CardFooter, Col, Collapse, FormGroup, Input, Label, Nav, NavLink, Row, TabContent, TabPane } from "reactstrap";
import styled from "styled-components";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import DataPreClient from "../../components/ResumeClient/DataPreClient";
import DataClientVenda from "../../components/ResumeClient/DataClientVenda";
import sanitizeHtml from 'sanitize-html';
import { InputText } from "../../components/InputText/InputText";
import axios from "axios";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import EnviarEmailVenda from "../../components/Modals/Vendas/EnviarEmailVenda";
import ecommerce_logo from '../../assets/ecommerce-aqpago.svg';
import Select from "react-select";
import { toast } from "react-toastify";
import ClipLoader from 'react-spinners/ClipLoader';
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

const status_transacao_not_svg = (payment_type) => {
    switch(payment_type){
        case 'succeeded' : return 'Aprovada';
        case 'canceled' : return 'Cancelada';
        case 'pending' : return 'Pendente';
        case 'failed' : return 'Falhada';
        case 'disputed': return 'Em disputa';
        default: return payment_type
    }
}

const payment = (payment_type) => {
    switch(payment_type){
        case 'credit' : return 'Crédito';
        case 'boleto' : return 'Boleto';
        case 'debit' : return 'Débito';
        default: return payment_type
    }
}

const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
`;


const getPayout = (id, callback) => {
    var url = ENDPOINT + "vendas/consultar_venda/" + id;

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((data) => {
        callback(data);
    })
}

const getOperadores = (callback) => {
    var url = ENDPOINT + "chat-atendimento/consultar-operadores";
    
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        var operadores_list = [];
        data.forEach((p, i) => {
            p['value'] = p.id;
            p['label'] = p.name;
            operadores_list.push(p);
        });
  
        callback(operadores_list)
    });
  }

const updateMaquininha = (id, formData, callback) => {
    var url = ENDPOINT + "vendas/update_maquininha/" + id;

    console.log(formData);

    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((data) => {
        callback(data.data);
    })
}

const get_id = () => {
    return window.location.pathname.replace('/venda/', '')
}

const maquininhas_list = [
    {
        modelo: 'MP5',
        label: 'Maquininha MP5',
        value: 'MP5',
        valor: 99.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/mp5.webp'
    },
    {
        modelo: 'D195',
        label: 'Maquininha D195',
        value: 'D195',
        valor: 349.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/d195.webp'
    },
    {
        modelo: 'S920',
        label: 'Maquininha S920',
        value: 'S920',
        valor: 499.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/s920.webp'
    },
    {
        modelo: 'MP35P',
        label: 'Maquininha MP35P',
        value: 'MP35P',
        valor: 999.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/d195.webp'
    },
    {
        modelo: 'linkpay',
        label: 'Link de Pagamento',
        value: 'linkpay',
        valor: 99.00,
        image: 'https://aqpago.com.br/assets/img/home/melhor-escolha/link-pagamento/link-pagamento.svg'
    },
    {
        modelo: 'shopaqpago',
        label: 'E-commerce',
        value: 'shopaqpago',
        valor: 99.00,
        image: ecommerce_logo
    }
];
  
const produto_prazo_list = [
    {
        label: '1 ano de garantia',
        value: 'um_ano'
    },
    {
        label: '2 anos de garantia',
        value: 'dois_anos'
    },
    {
        label: '3 anos de garantia',
        value: 'tres_anos'
    },
];

const pedido_list = [
    { value: "entrega", label: "Aguardando entrega" },
    { value: "cliente", label: "Aguardando cliente" },
    { value: "analise", label: "Pedido em análise" },
    { value: "aprovado", label: "Pedido aprovado" },
    { value: "reprovado", label: "Pedido reprovado" },
    { value: "cancelado", label: "Pedido cancelado" },
    { value: "estornado", label: "Pedido estornado" },
    { value: "chargeback", label: "Pedido chargeback" },
    { value: "enviado", label: "Pedido enviado" },
    { value: "entregue", label: "Pedido entregue" },
    { value: "devolvido", label: "Pedido devolvido" },
    { value: "descredenciado", label: "Descredenciado" }
];

const getStringMaps = (mail) => {
    var string_mail = "";
    mail.forEach((p) => {
      string_mail += getTranslate(p.recordType) + " " + moment(new Date(p.dateAt).toString()).format('DD/MM/YYYY h:mm:ss a') + "<br /> "
    });
  
    return string_mail;
}

const getTranslate = (name) => {
    switch (name) {
        case 'Delivery': return 'Entregue'
        case 'Open': return 'Aberto'
        case 'Click': return 'Clicado'
        case 'SpamComplaint': return 'Caixa de SPAM'
        case 'Bounce': return 'Mensagem devolvida'
        default : return name;
    }
}

const produto_prazo_get = (value) => {
    var prazo = '';
    produto_prazo_list.forEach(e => {
        if(e.value == value){
            prazo = e.label;
        }
    });

    return prazo;
}

class ResumoVenda extends Component{
    constructor(props){
        super(props);
        this.state = {
            activeTab: "1",
            motivo_estorno: '',
            visible_email: false,
            venda_id: get_id(),
            info_transacao: {},
            user: {},
            produtos: [],
            mails: [],
            operadores_list: [],
            lojas: null,
            forma_pagamento: '',
            motivo_mudanca: '',
            operador: null,
            status_pedido_label: null,
            estornado_por: null,
            prevencao_show: false,
            loading_payout: false
        }
    }

    componentDidMount() {
        getPayout(this.state.venda_id, this.callbackRows.bind(this));
        getOperadores(this.callbackOperadores.bind(this));
      }
  
      callbackOperadores = (operadores_list) => {
          this.setState({operadores_list});
      }
  

    callbackRows(data) {
        this.setState({ ...data.data });

        if(data.data.analista !== null){
            var operador = {
                value: data.data.analista.id,
                label: data.data.analista.name
            };

            this.setState({ operador })

        }
        if(data.data.status_pedido !== null){
            var status = null;
            pedido_list.forEach(e => {
                if(e.value == data.data.status_pedido){
                    status = e;
                }
            });

            this.setState({ status_pedido_label: status })
            
        }
    }

    onCloseEmail = (visible_email = false) => {
        this.setState({ visible_email });

        if(visible_email == false){
            getPayout(this.state.venda_id, this.callbackRows.bind(this));
        }
    }

    listData = [
        { name: <><MdMoneyOff/>Informações de Pagamento</>, active: '1' },
        { name: <><FaUser />Usuário </>, active: '2' },
        { name: <><FaBox />Produtos </>, active: '3' },
        { name: <><MdMail />E-mails </>, active: '4' }
    ];

    reenviar_link_eccommerce = () => {
        var url = ENDPOINT+"loja/reenviar_link_loja/"+this.state.venda_id;
        
        var formData = new FormData();
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            return data;
        })
        .catch((error) => {
            var data = error.response.data;
            toast.error(data.errors);
        });

    }
    bloquearPagamento = () => {
        this.setState({loading_payout: true})
        var url = ENDPOINT+"vendas/estornar_pagamento/"+this.state.venda_id;
        
        var formData = new FormData();
        formData.append('motivo_estorno', this.state.motivo_estorno);
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({
                loading_payout: false,
                prevencao_show: false
            });
            getPayout(this.state.venda_id, this.callbackRows.bind(this));
            return data;
        })
        .catch((error) => {
            var data = error.response.data;
            this.setState({
                loading_payout: false
            });
            toast.error(data.errors);
        });

    }
    alterarProduto = (index, id) => {
        console.log(this.state.produtos[index])

        var formData = new FormData();
        if(this.state.produtos[index].serial == null){
            if(typeof this.state.produtos[index].serial_text !== 'undefined'
                && this.state.produtos[index].serial_text !== ""){
                formData.append('serial', this.state.produtos[index].serial_text)
            }
        }
        
        if(this.state.produtos[index].chip == null){
            if(typeof this.state.produtos[index].chip_text !== 'undefined'
                && this.state.produtos[index].chip_text !== ""){
                formData.append('chip', this.state.produtos[index].chip_text)
            }
        }
        
        if(this.state.produtos[index].prazo_garantia == null){
            if(typeof this.state.produtos[index].prazo_garantia_text !== 'undefined'
                && this.state.produtos[index].prazo_garantia_text !== ""){
                formData.append('prazo_garantia', this.state.produtos[index].prazo_garantia_text)
            }
        }

        if(this.state.produtos[index].simcard == null){
            if(typeof this.state.produtos[index].simcard_text !== 'undefined'
                && this.state.produtos[index].simcard_text !== ""){
                formData.append('simcard', this.state.produtos[index].simcard_text)
            }
        }

        if(this.state.produtos[index].operador == null){
            if(typeof this.state.produtos[index].operador_text !== 'undefined'
                && this.state.produtos[index].operador_text !== ""){
                formData.append('operador', this.state.produtos[index].operador_text)
            }
        }
        
        if(this.state.produtos[index].fornecedor == null){
            if(typeof this.state.produtos[index].fornecedor_select !== 'undefined'
                && this.state.produtos[index].fornecedor_select !== ""){
                    if(this.state.produtos[index].fornecedor_select == 'Outros'){
                        formData.append('fornecedor', this.state.produtos[index].fornecedor_text)
                    }
                    else{
                        formData.append('fornecedor', this.state.produtos[index].fornecedor_select)
                    }
            }
        }

        if(this.state.produtos[index].label != this.state.produtos[index].vendas_edit_text){
            if(typeof this.state.produtos[index].vendas_edit_text !== 'undefined'
                && this.state.produtos[index].vendas_edit_text !== ""){
                formData.append('modelo', this.state.produtos[index].vendas_edit_text);
                formData.append('motivo_mudanca', this.state.motivo_mudanca);
            }
        }

        
        if(this.state.produtos[index].nf == null){
            if(typeof this.state.produtos[index].nf_text !== 'undefined'
                && this.state.produtos[index].nf_text !== ""){
                formData.append('nf', this.state.produtos[index].nf_text)
            }
        }


        const callbackIs = (data) => {
            var produtos = this.state.produtos;
            produtos[index] = data.data;
            this.setState({ 
                produtos,
                motivo_mudanca: '' 
            });
        }

        updateMaquininha(id, formData, callbackIs);
    }


    alterStatus = (status) => {
        this.setState({ status_pedido_label: status });

        var url = ENDPOINT+"vendas/update_status/"+this.state.venda_id;
    
        var formData = new FormData();
        formData.append('status_pedido', status.value);
        axios.post(url, formData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                toast.error(obj.errors);
            }
  
            var data = error.response.data;
            if(typeof data.errors !== 'undefined'){
              toast.error(data.errors);
            }
        });
    
    }

    alterOperador = (operador) => {
        var url = ENDPOINT+"vendas/update_operador/"+operador.value;
    
        var formData = new FormData();
        formData.append('venda_id', this.state.venda_id);
        axios.post(url, formData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                toast.error(obj.errors);
            }
  
            var data = error.response.data;
            if(typeof data.errors !== 'undefined'){
              toast.error(data.errors);
            }
        });
    
    }

    handleChangeEmail = (mensagem_email) => {
        this.setState({mensagem_email});
    }

    handleSelectChange = (name, value) => {
        var state = this.state;
        state[name] = value;
        this.setState({ ...state });

        this.alterOperador(value);
    }

    render(){
        const Navs = () => {
            return <Nav tabs>
                {this.listData.map((p, index) => {
                    return <NavLink
                        key={index}
                        className={
                            classnames({ active: this.state.activeTab === p.active })}
                        onClick={() => { this.setState({ activeTab: p.active }); }}>
                        <span>{p.name}</span>
                    </NavLink>
                })}
            </Nav>
        }
        return(<Div>
            <h3>Resumo de Venda</h3>
            <Navs />
            <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId={'1'}>
                    <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                        <h5>Dados do Pagamento</h5>
                        <hr/>
                        <p><b>Status:</b> {status_transacao_not_svg(this.state.info_transacao.status)}</p>
                        <p><b>ID da Transação:</b> {this.state.info_transacao.transaction_number}</p>
                        <p><b>Estabelecimento:</b> {this.state.info_transacao.statement_descriptor}</p>
                        <p><b>Valor da venda:</b> R$ {parseFloat(this.state.info_transacao.amount).toFixed(2).replace('.', ',')}</p>
                        <p><b>Taxa:</b> R$ {parseFloat(this.state.info_transacao.fees).toFixed(2).replace('.', ',')}</p>
                        <p><b>Valor Liquido:</b> R$ {(parseFloat(this.state.info_transacao.original_amount) - parseFloat(this.state.info_transacao.fees)).toFixed(2).replace('.', ',')}</p>
                        <p><b>Tipo de Pagamento:</b> {payment(this.state.info_transacao.payment_type)}</p>
                        <p>
                            <div style={{display: "table-cell"}}>
                                <b>Status do Pedido: </b>
                            </div>
                            <div style={{display: "table-cell", paddingLeft: '10px', width: '250px'}}>
                                <Select
                                    placeholder="Status do Pedido"
                                    options={pedido_list}
                                    value={this.state.status_pedido_label}
                                    onChange={this.alterStatus}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            boxShadow: "none",
                                            marginTop: '-6px',
                                            paddingLeft: '0px',
                                            background: "transparent"
                                        })
                                    }}
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 0
                                    })}
                                />
                            </div>
                        </p>
                        <p>
                            <div style={{display: "table-cell"}}>
                                <b>Analista da Venda: </b>
                            </div>
                            <div style={{display: "table-cell", paddingLeft: '10px', width: '250px'}}>
                                <Select
                                    placeholder="Analista"
                                    options={this.state.operadores_list}
                                    value={this.state.operador}
                                    onChange={(value) => this.handleSelectChange('operador', value)}
                                />
                            </div>
                        </p>
                        
                        {this.state.info_transacao.status !== 'succeeded' || this.state.forma_pagamento == 'boleto'?
                                <p>
                                    {this.state.motivo_estorno !== null && this.state.motivo_estorno !== ""?
                                        <><b>Motivo do estorno: </b>{this.state.motivo_estorno}<br/>
                                        {this.state.estornado_por !== null ? 
                                        <>
                                            <b>Estornado por: </b>{this.state.estornado_por.name}
                                        </>:null}
                                        </>
                                    :null}
                                </p>
                            :
                            <>
                                <p>
                                    <Button
                                        color="warning"
                                        disabled={this.state.loading_payout}
                                        onClick={() => this.setState({prevencao_show: !this.state.prevencao_show})}>
                                        Estornar Pagamento&nbsp;
                                        {this.state.loading_payout === true ?
                                            <ClipLoader color="#fff" size={15}/>
                                            :
                                            <>
                                                <AiFillCaretDown hidden={this.state.prevencao_show}/>
                                                <AiFillCaretUp hidden={!this.state.prevencao_show}/>
                                            </>
                                        }
                                    </Button>
                                    <Collapse isOpen={this.state.prevencao_show}>
                                        <hr/>
                                        <p>Deseja estornar o pagamento? Essa opção não poderá ser desfeita</p>
                                        <Input
                                            type="textarea"
                                            placeholder="Insira o motivo do estorno"
                                            onChange={(e) => this.setState({ motivo_estorno: e.target.value})}
                                        />
                                        <br/>
                                        <Button 
                                            onClick={() => this.bloquearPagamento()} 
                                            disabled={this.state.loading_payout}
                                            color="danger">
                                            {this.state.loading_payout === true ?
                                                <ClipLoader color="#fff" size={15}/>
                                                :
                                                ''} Confirmar Estorno
                                        </Button>
                                    </Collapse>
                                </p>
                            </>}
                    </div>
                </TabPane>
                <TabPane tabId={'2'}>
                    <DataClientVenda
                        {...this.state.user}
                    />
                </TabPane>
                <TabPane tabId={'3'}>
                    <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                    <h5>Produtos Adquiridos</h5>
                    <hr/>
                            <CardColumns>
                                {this.state.produtos.map((e, index) => {
                                    return <>
                                        {maquininhas_list.map(m => {
                                            if(e.produto.toUpperCase() == m.modelo.toUpperCase()){
                                                if(e.produto.toUpperCase() != 'LINKPAY' && e.produto.toUpperCase() != 'SHOPAQPAGO'){
                                                    return <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col>
                                                                    <img src={m.image} height="100px"/>
                                                                </Col>
                                                                <Col>
                                                                    <p>{m.label} &nbsp;
                                                                        <Button color="success" onClick={() => { 
                                                                            var produtos = this.state.produtos;
                                                                            produtos[index]['venda_edit'] = true;
                                                                            this.setState({ produtos })
                                                                        }}>Editar</Button></p>
                                                                    <FormGroup hidden={this.state.produtos[index]['venda_edit'] != true}>
                                                                        <Label>Nova Maquininha:</Label>
                                                                        <Input 
                                                                            type="select" 
                                                                            placeholder="Prazo"
                                                                            name="vendas_edit_text"
                                                                            onChange={(e) => {
                                                                                var name = e.target.name;
                                                                                var value = e.target.value;
                                                                                var produtos = this.state.produtos;
                                                                                produtos[index][name] = value;
                                                                                this.setState({ produtos })
                                                                            }}
                                                                        >
                                                                            <option>Selecione a maquininha</option>
                                                                            <option value="MP5">MP5</option>
                                                                            <option value="D195">D195</option>
                                                                            <option value="S920">S920</option>
                                                                        </Input>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label>Motivo da Mudança:</Label>
                                                                        <Input 
                                                                            type="textarea" 
                                                                            placeholder="Motivo da mudança"
                                                                            name="motivo_mudanca"
                                                                            value={this.state.motivo_mudanca}
                                                                            onChange={(e) => {
                                                                                var name = e.target.name;
                                                                                var value = e.target.value;
                                                                                this.setState({ [name]: value })
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                    {/* <p>R$ {parseFloat(m.valor).toFixed(2).replace('.', ',')}</p> */}
                                                                </Col>
                                                            </Row>
                                                            <div>
                                                                <hr/>
                                                                {e.serial == null ?
                                                                    <FormGroup>
                                                                        <Label>Número do Serial:</Label>
                                                                        <Input 
                                                                            placeholder="Nº de Serial" 
                                                                            name="serial_text"
                                                                            onChange={(e) => {
                                                                                var name = e.target.name;
                                                                                var value = e.target.value;
                                                                                var produtos = this.state.produtos;
                                                                                produtos[index][name] = value;
                                                                                this.setState({ produtos })
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                :
                                                                <p><b>Nº de Serial:</b> {e.serial}</p>}
                                                                {e.nf == null ?
                                                                    <FormGroup>
                                                                        <Label>Nota Fiscal (NF):</Label>
                                                                        <Input 
                                                                            placeholder="NF" 
                                                                            name="nf_text"
                                                                            onChange={(e) => {
                                                                                var name = e.target.name;
                                                                                var value = e.target.value;
                                                                                var produtos = this.state.produtos;
                                                                                produtos[index][name] = value;
                                                                                this.setState({ produtos })
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                :
                                                                <p><b>NF:</b> {e.nf}</p>}
                                                                {e.chip == null ?
                                                                    <FormGroup>
                                                                        <Label>Número do Chip:</Label>
                                                                        <Input 
                                                                            placeholder="Nº do Chip" 
                                                                            name="chip_text"
                                                                            onChange={(e) => {
                                                                                var name = e.target.name;
                                                                                var value = e.target.value;
                                                                                var produtos = this.state.produtos;
                                                                                produtos[index][name] = value;
                                                                                this.setState({ produtos })
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                :
                                                                <p><b>Nº de Chip:</b> {e.chip} 
                                                                <Button color="success" onClick={() => { 
                                                                    var produtos = this.state.produtos;
                                                                    produtos[index]['chip'] = null;
                                                                    this.setState({ produtos })
                                                                }}>Editar</Button></p>}
                                                                {e.prazo_garantia == null ?
                                                                    <FormGroup>
                                                                        <Label>Prazo de garantia:</Label>
                                                                        <Input 
                                                                            type="select" 
                                                                            placeholder="Prazo"
                                                                            name="prazo_garantia_text"
                                                                            onChange={(e) => {
                                                                                var name = e.target.name;
                                                                                var value = e.target.value;
                                                                                var produtos = this.state.produtos;
                                                                                produtos[index][name] = value;
                                                                                this.setState({ produtos })
                                                                            }}
                                                                        >
                                                                            <option>Prazo de garantia</option>
                                                                            <option value="tres_meses">3 Meses</option>
                                                                            <option value="seis_meses">6 Meses</option>
                                                                            <option value="doze_meses">12 Meses</option>
                                                                            <option value="tres_anos">Sem Garantia</option>
                                                                        </Input>
                                                                    </FormGroup>
                                                                :
                                                                <p><b>Prazo de garantia:</b> {produto_prazo_get(e.prazo_garantia)}</p>}
                                                                {e.simcard == null ?
                                                                    <FormGroup>
                                                                        <Label>Fornecedor SIMCARD:</Label>
                                                                        <Input 
                                                                            type="select" 
                                                                            placeholder="Sim Card"
                                                                            name="simcard_text"
                                                                            onChange={(e) => {
                                                                                var name = e.target.name;
                                                                                var value = e.target.value;
                                                                                var produtos = this.state.produtos;
                                                                                produtos[index][name] = value;
                                                                                this.setState({ produtos })
                                                                            }}
                                                                        >
                                                                            <option>Fornecedor SIMCARD</option>
                                                                            <option value="Allcom">Allcom</option>
                                                                            <option value="Avatek">Avatek</option>
                                                                            <option value="TefTi">TefTi</option>
                                                                            <option value="Algar">Algar</option>
                                                                        </Input>
                                                                    </FormGroup>
                                                                :
                                                                <p><b>Fornecedor SIMCARD:</b> {e.simcard}</p>}
                                                                {e.operador == null ?
                                                                    <FormGroup>
                                                                        <Label>Operador SIMCARD:</Label>
                                                                        <Input 
                                                                            type="select" 
                                                                            placeholder="Sim Card"
                                                                            name="operador_text"
                                                                            onChange={(e) => {
                                                                                var name = e.target.name;
                                                                                var value = e.target.value;
                                                                                var produtos = this.state.produtos;
                                                                                produtos[index][name] = value;
                                                                                this.setState({ produtos })
                                                                            }}
                                                                        >
                                                                            <option>Operador SIMCARD</option>
                                                                            <option value="Vivo">Vivo</option>
                                                                            <option value="Claro">Claro</option>
                                                                            <option value="Tim">Tim</option>
                                                                            <option value="Algar">Algar</option>
                                                                            <option value="Eseye">Eseye</option>
                                                                        </Input>
                                                                    </FormGroup>
                                                                :
                                                                <p><b>Operador SIMCARD:</b> {e.operador}</p>}
                                                                {e.fornecedor == null ?
                                                                    <>
                                                                        <FormGroup>
                                                                            <Label>Fornecedor:</Label>
                                                                            <Input 
                                                                                type="select" 
                                                                                placeholder="Fornecedor"
                                                                                name="fornecedor_select"
                                                                                onChange={(e) => {
                                                                                    var name = e.target.name;
                                                                                    var value = e.target.value;
                                                                                    var produtos = this.state.produtos;
                                                                                    produtos[index][name] = value;
                                                                                    this.setState({ produtos })
                                                                                }}
                                                                            >
                                                                                <option>Prazo de Fornecedor</option>
                                                                                <option value="TEFTI">TEFTI</option>
                                                                                <option value="Allcom">Allcom</option>
                                                                                <option value="Gertec">Gertec</option>
                                                                                <option value="Scansource">Scansource</option>
                                                                                <option value="PS4L">PS4L</option>
                                                                                <option value="Outros">Outros</option>
                                                                            </Input>
                                                                        </FormGroup>
                                                                        <FormGroup hidden={this.state.produtos[index].fornecedor_select != 'Outros'}>
                                                                            <Input 
                                                                                placeholder="Fornecedor" 
                                                                                name="fornecedor_text"
                                                                                onChange={(e) => {
                                                                                    var name = e.target.name;
                                                                                    var value = e.target.value;
                                                                                    var produtos = this.state.produtos;
                                                                                    produtos[index][name] = value;
                                                                                    this.setState({ produtos })
                                                                                }}
                                                                            />
                                                                        </FormGroup>
                                                                    </>
                                                                :
                                                                <p><b>Fornecedor:</b> {e.fornecedor}</p>}
                                                                {e.nf_fornecedor == null ?
                                                                    <FormGroup>
                                                                        <Label>Nota Fiscal Fornecedor:</Label>
                                                                        <Input 
                                                                            placeholder="NF" 
                                                                            name="nf_fornecedor_text"
                                                                            onChange={(e) => {
                                                                                var name = e.target.name;
                                                                                var value = e.target.value;
                                                                                var produtos = this.state.produtos;
                                                                                produtos[index][name] = value;
                                                                                this.setState({ produtos })
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                :
                                                                <p><b>NF Fornecedor:</b> {e.nf_fornecedor}</p>}
                                                                <p><b>Data da Compra:</b> {moment(e.created_at).format('DD/MM/YYYY HH:mm')}</p>
                                                            </div>
                                                        </CardBody>
                                                        {e.nf == null || e.fornecedor == null || e.prazo_garantia == null || e.chip == null || e.serial == null || e.operador == null || e.simcard == null ?
                                                            <CardFooter>
                                                                <Button color="success" onClick={() => this.alterarProduto(index, e.id)}>Salvar</Button>
                                                            </CardFooter>
                                                            :
                                                            null
                                                        }
                                                    </Card>;
                                                }
                                                else{
                                                    return <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col>
                                                                    <img src={m.image} height="100px"/>
                                                                </Col>
                                                                <Col>
                                                                    <p>{m.label}</p>
                                                                    <p>R$ {parseFloat(m.valor).toFixed(2).replace('.', ',')}</p>
                                                                </Col>
                                                            </Row>
                                                            <div>
                                                                <hr/>
                                                                {e.nf == null ?
                                                                    <FormGroup>
                                                                        <Label>Nota Fiscal (NF):</Label>
                                                                        <Input 
                                                                            placeholder="NF" 
                                                                            name="nf_text"
                                                                            onChange={(e) => {
                                                                                var name = e.target.name;
                                                                                var value = e.target.value;
                                                                                var produtos = this.state.produtos;
                                                                                produtos[index][name] = value;
                                                                                this.setState({ produtos })
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                :
                                                                <p><b>NF:</b> {e.nf}</p>}
                                                                <p><b>Data da Compra:</b> {moment(e.created_at).format('DD/MM/YYYY HH:mm')}</p>
                                                                
                                                                {e.produto.toUpperCase() == 'SHOPAQPAGO' ?
                                                                    <>
                                                                        <Button color="info" onClick={() => this.reenviar_link_eccommerce()} hidden={this.state.lojas != null}>Re-enviar link de registro de loja</Button>
                                                                    </>
                                                                :
                                                                    <></>
                                                                }
                                                            </div>
                                                        </CardBody>
                                                        {e.nf == null ?
                                                            <CardFooter>
                                                                <Button color="success" onClick={() => this.alterarProduto(index, e.id)}>Salvar</Button>
                                                            </CardFooter>
                                                            :
                                                            null
                                                        }
                                                    </Card>;
                                                }
                                            }
                                        })}
                                    </>
                                })}
                            </CardColumns>
                    </div>
                </TabPane>
                <TabPane tabId={'4'}>
                    <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                        <EnviarEmailVenda
                            id={this.state.venda_id}
                            visible={this.state.visible_email}
                            onClose={this.onCloseEmail}
                        />
                        <h5>E-mails</h5>
                        <hr/>
                        <Button color="success" onClick={() => this.onCloseEmail(true)}>Enviar E-mail</Button>
                        <br/><br/>
                        <ul style={{listStyleType: 'circle'}}>
                        {this.state.mails.map(p => {
                            return <li style={{marginBottom: '7px'}}>
                                {p.subject}<br/>
                                <small>Conteúdo de E-mail:</small>
                                <div style={{border: '1px solid #7f7f7f66', borderRadius: '15px', padding: '19px'}} dangerouslySetInnerHTML={{ __html: sanitizeHtml(p.message)}}/>
                                <small>{p.responsavel !== null ? <><b>Operador:</b> {p.responsavel.name}</> : ''}</small>
                                <small>{p.mail.length > 0 ?
                                    <div data-tip={getStringMaps(p.mail)} style={{ maxHeight: '200px', 
                                    cursor: 'pointer', overflow: 'auto', width: '100%', margin: '12px' }}>
                                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                                        {p.mail.slice(0, 1).map((p) => {
                                            return <li>
                                            <p><MdMail /> <b>{getTranslate(p.recordType)}</b>
                                                <br />
                                                {moment(new Date(p.dateAt).toString()).format('DD/MM/YYYY h:mm:ss a')}</p>
                                            </li>
                                        })}
                                        </ul>
                                        <ReactTooltip multiline={true} />
                                    </div>
                                    :
                                    <div data-tip={"Postado " + moment(new Date(p.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')} style={{ cursor: 'pointer', maxHeight: '200px', overflow: 'auto', width: '100%', margin: '12px' }}>
                                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                                        <li style={{ listStyleType: 'none' }}><MdMail /> <b>Postado</b><br />{moment(new Date(p.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')}
                                        </li>
                                        </ul>
                                        <ReactTooltip multiline={true} />
                                    </div>
                                }</small>
                            </li>
                        })}
                    </ul>
                    {this.state.mails.length === 0 ? 'Nenhum email encontrado' : ''}
                    </div>
                </TabPane>
            </TabContent>
        </Div>)
    }
}

export default ResumoVenda;