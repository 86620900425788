import { Component } from "react";
import { Button, Card, CardBody, Col, Collapse, Input, Row } from "reactstrap";
import { getUser } from "../../auth/set_services";
import { getRowsPerPage, getRows } from "../../components/Tables/TableQueroBus/propsCupom";
import TableCupom from "../../components/Tables/TableQueroBus/TableCupom";
import PropagateLoader from "react-spinners/PropagateLoader";
import moment from "moment";
import { FaPlus, FaSave } from "react-icons/fa";
import Select from "react-select";
import { ENDPOINT } from "../../auth/endpoint";
import axios from "axios";
import { getPermissoes, getToken } from "../../auth/set_services";
import { toast, useToastContainer } from 'react-toastify';
import { MdArrowBack } from "react-icons/md";
import { useParams } from "react-router-dom";
import ClipLoader from 'react-spinners/ClipLoader'

const statusOptions = [
    { value: '0', label: 'Desativado'},
    { value: '1', label: 'Ativado'},
];

const typeOptions = [
    { value: 'p', label: 'Porcentagem (%)'},
    { value: 'f', label: 'Fixo (R$)'},
];


const limiteOptions = [
    { value: '1', label: 'Sim'},
    { value: '0', label: 'Não'},
];

const getId = () => {
    return window.location.pathname.replace('/querobus-cupom/', '')
}

const GetDataParam = () => {
    // let init, uri, service_id, group, date_accommodation, origin, destiny, rest;
    // let path = window.location.pathname.split('/');

    // [init, uri, service_id, group, date_accommodation, origin, destiny, ...rest] = path;

    
    // return {
    //     service_id: service_id,
    //     group: group,
    //     date_accommodation: date_accommodation,
    //     origin: origin,
    //     destiny: destiny,
    // }
}


class NewDealQueroBus extends Component
{
    constructor(props){
        super(props);
        this.state = {
            show_activity: false,
            show_filter: false,
            user_change: null,
            search: '',
            seller_id: '',
            valor_de: '0.00',
            valor_ate: '0.00',
            loading_table: true,
            edit_id: -1,
            edit_user: null,
            new_cupom: false,
            rowData: [],
            cupom_id: null,
            cupom_code: null,
            cupom_limit_total: null,
            cupom_status: { value: '1', label: 'Ativado'},
            cupom_type: { value: 'p', label: 'Porcentagem'},
            
            cupom_limit: { value: '0', label: 'Não'},
            cupom_value: null,
            cpf_current: null,
            cnpj_current: null,
            phone_current: null,
            status_user: '',
            type_user: '',
            type: { value: 'p', label: 'Porcentagem'},
            limit: { value: '0', label: 'Não'},
            status: null,
            totalPage: 0,
            activePage: 1,
            is_cnpj: false,
            document: '',
            filter: false,
            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            visible_taggy_add: false,
            visible_user: false,
            type_discont: { value: 'p', label: 'Porcentagem'},
            amount_discont: this.props?.location?.deal_value ? this.props?.location?.deal_value : null,
            deal_id: this.props?.location?.deal_id ? this.props?.location?.deal_id : null,
            loading_btn_save: false,
        }

        console.log(this.props)
    }

    componentDidMount() {
        if(this.props?.location?.deal_type) {
            typeOptions.map((field, i) => {
                if (this.props?.location?.deal_type == field.value) {
                    this.setState({type_discont: field})
                }
            })
        }

    }

    handletoastSuccessMessage = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    handletoastMessage = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    handleSaveDeal = () => {
        var url = ENDPOINT + "querobus/deal";

        let formData = new FormData();

        if (this.props?.location?.deal_id) formData.append('deal_id', this.props?.location?.deal_id);

        formData.append('origin_id', this.props?.location?.origin);
        formData.append('destiny_id', this.props?.location?.destiny);
        formData.append('date_accommodation', this.props?.location?.date_accommodation);
        formData.append('service_id', this.props?.location?.booking?.service_id);
        formData.append('group', this.props?.location?.booking?.group);
        formData.append('business', this.props?.location?.booking?.business);
        formData.append('search_accommodation_id', this.props?.location?.booking?.id);
        formData.append('type', this.state?.type_discont?.value);
        formData.append('value', this.state?.amount_discont);

        // console.log({
        //     'deal_id': this.props?.location?.deal_id,
        //     'origin_id': this.props?.location?.origin,
        //     'destiny_id': this.props?.location?.destiny,
        //     'date_accommodation': this.props?.location?.date_accommodation,
        //     'service_id': this.props?.location?.booking?.service_id,
        //     'group': this.props?.location?.booking?.group,
        //     'business': this.props?.location?.booking?.business,
        //     'search_accommodation_id': this.props?.location?.booking?.id,
        //     'type': this.state?.type_discont?.value,
        //     'value': this.state?.amount_discont,
        // });
        // return;

        this.setState({loading_btn_save: true})

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }})
        .then((response) => {
            this.setState({loading_btn_save: false})
            
            axios.get('https://meubus.com.br/api/revalidate?secret=holdingaqBank')
            .then((response) => {
            })
            .catch((error) => {
                // this.handletoastMessage('Falha ao atulizar no frontend!');
            })

            if (this.state.deal_id) {
                this.handletoastSuccessMessage('Oferta atualizada com sucesso!')
            } else {
                this.handletoastSuccessMessage('Oferta cadastrado com sucesso!')
            }

            setTimeout(() => {
                this.props.history.push({pathname: '/ofertas-querobus'});
            }, 2000)
        })
        .catch((error) => {
            this.handletoastMessage('Ocorreu um erro no servidor!');
            this.setState({loading_btn_save: false})

            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              //console.log(error.response.status); // status code
              //console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              //console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              //console.log('Error', error.message);
            }
            console.log(error);
        })
    }
    
    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }    


    render(){
        return (
            <div>
                <h3>
                    <Button 
                        outline 
                        style={{border: 'none'}}
                        onClick={() => window.location.href = '/ofertas-querobus'}
                        >
                            <MdArrowBack style={{padding: 0}}/>
                    </Button>&nbsp;&nbsp;Nova Oferta MeuBus
                </h3>
                <hr/>
                    
                <Row style={{marginBottom: '1rem'}}>
                    <Col md={12}>
                        <h2>Passagem</h2><hr/>
                    </Col>
                </Row>

                <Row style={{marginBottom: '1rem'}}>
                    <Col md={6}>
                        <p><b>Origem:</b> {this.props?.location?.booking?.origin}</p>
                        <p><b>Destino:</b> {this.props?.location?.booking?.destiny}</p>
                        <p><b>Data:</b> {moment(this.props?.location?.date_accommodation).format('DD/MM/YYYY')}</p>
                        <p><b>Valor:</b> {parseFloat(this.props?.location?.booking?.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                        <p><b>Seguro:</b> {parseFloat(this.props?.location?.booking?.optional_insurance).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                    </Col>
                    <Col md={6}>
                        <img src={this.props?.location?.booking?.url_banner} style={{width: '200px', margin: '1rem'}} />
                        <p><b>Grupo:</b> {this.props?.location?.booking?.group}</p>
                        <p><b>Duração da viagem:</b> {this.props?.location?.booking?.time_travel}h</p>
                    </Col>
                </Row>

                <Row style={{marginBottom: '1rem'}}>
                    <Col md={6}>
                        <label htmlFor="type_discont">Tipo de desconto</label>
                        <Select
                                name="type_discont"
                                placeholder="Selecione..."
                                options={typeOptions}
                                onChange={(select) => {
                                    this.setState({type_discont: select});
                                }}
                                value={this.state.type_discont} 
                                    />
                    </Col>
                    <Col md={6}>
                        <label htmlFor="amount_discont">Valor do desconto</label>
                        <Input
                            name="amount_discont"
                            placeholder=""
                            value={this.state.amount_discont}
                            type="text"
                            onChange={this.handleInputChange} />
                    </Col>
                </Row>

                <Row>
                    <Col md="6">
                    </Col> 
                    <Col md="6" style={{textAlign: 'right'}}>
                        <Button 
                            className="btn btn-success btn-save" 
                            style={{"margin-bottom": "20px"}}
                            disabled={this.state.loading_btn_save}
                            onClick={() => this.handleSaveDeal()}
                            >
                            {this.state.loading_btn_save === true ?
                                <>
                                    <ClipLoader color="#fff" size={12}/>
                                    &nbsp;Salvando...
                                </>
                            : 
                                <><FaSave /> Salvar Oferta</>
                            }
                        </Button>
                    </Col> 
                </Row>
                
            </div>
        )
    }

}

export default NewDealQueroBus;