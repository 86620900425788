import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Loader } from "../../../components/Loaders/Loader";
import styled from "styled-components";
import {
  booleanToYesOrNo,
  formatPhone,
} from "../../../utils";
import { useLocation } from "react-router-dom";
import { useApiAqmed } from "../../../hooks/useApi"

const Board = styled.div`
  background-color: #f5f5f5;
  margin-top: 30px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h2 {
    margin-bottom: 10px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin-bottom: 5px;
  }
`;

export function TotalPassUserData() {
  const url = useLocation().pathname;
  const urlArray = url.split("/");
  const id = urlArray[urlArray.length - 1];
  const { apiAqmed } = useApiAqmed();
  const [isFetching, setIsFetching] = useState(true);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const token = Cookies.get("aqmedtoken");
    apiAqmed
      .get(`/crm/totalpass/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const client = res?.data?.totalpass_contract;
        setUserData(client);
      })
      .catch((e) => {
        alert("Usuario não encontrado");
        console.log(e);
      })
      .finally(() => setIsFetching(false));
  }, []);

  if (isFetching) {
    return <Loader />;
  } else {
    return (
      <div>
        <h3>Dados do Cliente TotalPass</h3>
        <hr />

        <Board>
          <div>
            <h4>
              <strong>{userData?.owner?.name}</strong>
            </h4>
          </div>
          <ul>
            <li>
              <strong>Número do contrato: </strong>
              {userData?.contract_number}
            </li>
            <li>
              <strong>Data de Criação: </strong>
              {userData?.created_at}
            </li>
            <li>
              <strong>Descrição: </strong>
              {userData?.description}
            </li>
            <li>
              <strong>Nome da Operação: </strong>
              {userData?.operation_name}
            </li>
            <hr />
            <li>
              <strong>Email: </strong>
              {userData?.owner?.email}
            </li>
            <li>
              <strong>Débito Automático: </strong>
              {booleanToYesOrNo(userData?.owner?.automatic_billing)}
            </li>
            <li>
              <strong>Data de nascimento: </strong>
              {userData?.owner?.date_birth}
            </li>
            <li>
              <strong>Documento:</strong>{" "}
              {userData?.owner?.document}
            </li>
            <li>
              <strong>Telefone: </strong>
              {formatPhone(userData?.owner?.phone_number)}
            </li>
            <li>
              <strong>Status: </strong>
              {userData?.owner?.status}
            </li>
            <li>
              <strong>Sexo: </strong>
              {userData?.owner?.gender.toUpperCase()}
            </li>
            <li>
              <strong>Plano Totalpass: </strong>
              {booleanToYesOrNo(userData?.owner?.plan_has_totalpass)}
            </li>
            <li>
              <strong>Usuário Totalpass: </strong>
              {booleanToYesOrNo(userData?.owner?.user_has_totalpass)}
            </li>
            <li>
              <strong>Nome do Plano do usuário: </strong>
              {userData?.owner?.userPlan_name}
            </li>
            <li>
              <strong>Email Verificado: </strong>
              {booleanToYesOrNo(userData?.owner?.email_verified)}
            </li>
            <li>
              <strong>Telefone Verificado: </strong>
              {booleanToYesOrNo(userData?.owner?.verify_phone)}
            </li>
          </ul>
        </Board>
      </div>
    );
  }
}
