import axios from 'axios';
import moment from 'moment';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';



const getStatus = (status) => {
  if(status == 0){
      return "Pronto para Uso"
  }
  else if(status == 1){
      return "Já Útilizado"
  }
  else if(status == 2){
      return "Bloqueado"
  }
  else{
      return ""
  }
}


export const columns = () => [
    {
        name: 'Hash',
        selector: 'hash',
        sortable: false,
    },
    {
        name: 'Lote',
        selector: 'lote',
        sortable: false,
    },
    {
      name: "Valor",
      selector: "valor",
      sortable: false,
      cell: row => "R$ "+parseFloat(row.valor).toFixed(2).replace('.', ',')
    },
    {
      name: "Valor do abono",
      selector: "valor_abono",
      sortable: false,
      cell: row => "R$ "+parseFloat(row.valor_abono).toFixed(2).replace('.', ',')
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
      cell: row => getStatus(row.status)
    },
    {
      name: "Usado por",
      selector: "conta",
      sortable: false,
      cell: row => row.conta !== null ? row.conta.nome_fatura : 'Sem Uso'
    }
];

export const getRows = (state, callbackRows) => {
    
    var url = ENDPOINT + "giftcard/list";

    if(state.taggy_serial !== null){
        url += '?taggy_serial='+state.taggy_serial;
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    
    var url = ENDPOINT + "giftcard/list?search="+search+"&page="+page;

    if(state.taggy_serial !== null){
        url += '&taggy_serial='+state.taggy_serial;
    }
    if(filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}