import React from 'react';
import { HiViewGrid } from 'react-icons/hi';
import { Button, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getPermissoes, getToken, getUser } from '../../../auth/set_services';
import ReactTooltip from 'react-tooltip';
import { MdDelete } from 'react-icons/md';
import 'react-dropdown/style.css';
import moment from 'moment';


const capitalizeFirstLetter = (string) => {
  if(string !== null){
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string
}

export const columns = (onShowDelete) => [
  {
    name: '',
    selector: 'buttons',
    width: '100px',
    cell: row => <Row>
      <Button data-tip="Resumo do Cliente"
        outline
        //disabled={!row.cliente_existe}
        style={{ padding: '0px', marginRight: '3px', border: 0}}
        hidden={!getUser().permissao_gestor_aqpago}
        color="primary" onClick={() => 
          {
              window.location.href = '/user-aqpasso/'+row.id
          }
        }>
        <HiViewGrid style={{ padding: '2px', width: '23px' }} />
      </Button>
      {onShowDelete ? 
          <Button
            outline
            style={{ padding: 2, marginLeft: 7, border: 0, fontWeight: '700'}}
            color="danger"
            onClick={() => onShowDelete(row.id)}
          >
            <MdDelete style={{ padding: '2px' }} />
          </Button> 
        : 
        <></>}
      <ReactTooltip/>
    </Row>
  },
  {
    name: "Data",
    selector: "created_at",
    sortable: false,
    width: '140px',
    cell: row => moment(new Date(row.created_at)).format('DD/MM/YYYY HH:mm')
  },
  {
    name: "Nome do Responsavel",
    selector: "nome",
    sortable: false,
    width: '18%',
    cell: row => row.sobrenome !== null && row.nome !== null ? row.nome+" "+row.sobrenome : (row.nome_fatura !== null ? row.nome_fatura : '')
  },
  {
    name: "Telefone",
    selector: "telefone",
    sortable: false,
    width: '160px',
    cell: row => row.telefone ? row.telefone : 'Sem informações'
  },
  {
    name: "Email do Responsavel",
    selector: "email",
    sortable: false,
    cell: row => row.email ? row.email : 'Sem informações'
  },
  {
    name: "Documento",
    selector: "cpf",
    sortable: false,
    width: '14%',
    cell: row => row.cnpj ? row.cnpj : row.cpf
  },
  /* {
    name: "Status do Cadastro",
    selector: "cad_completo",
    sortable: false,
    width: '10%',
    cell: row => row.cad_completo !== 0 ? 'Completo' : row.etapa_cad
  }, */
  /* {
    name: "Status",
    selector: "status",
    sortable: false,
    width: '100px',
    cell: row => row.status !== null ? capitalizeFirstLetter(row.status) : "Indisponivel"
  }, */
  /* {
    name: "Ultimo chat",
    selector: "chats",
    sortable: false,
    width: '200px',
    cell: row => row.chats.length > 0 ? 
        <div style={{ maxHeight: '200px', overflow: 'auto', width: '100%', margin: '12px' }}>
        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>{row.chats.slice(0, 1).map((p, i) => {
        return (
            <li>
                <p style={{margin: 0}}>
                    <b>
                        <MdChat />
                        Realizado em: </b><br/>{moment(new Date(p.created_at)).format('DD/MM/YYYY HH:mm')}
                </p>
            </li>
        )
    })}</ul></div> : 'Nenhum chat realizado'
  }*/
];


export const getRows = (state, callback) => {//
  var url = ENDPOINT + "user/all?empresa_relacao=aqpasso&page=" + state.activePage + "&search=" + state.search;

  var document = state.document.replace(/([^\d])+/gim, '');
  if(state.is_cnpj === false){
    if(document.length === 11){
      url += "&cpf="+document;
    }
  }
  else{
      if(document.length === 14){
        url += "&cnpj="+document;
      }
  }

  
  if(state.hours == true){
      url += "&assistencia=1";
      
  }

  /* if(state.type_user.length > 0){
    url += "&tipo_usuario="+state.type_user
  } */

  if(state.status_user.length > 0){
    url += "&status_usuario="+state.status_user
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      callback(p.data, p.last_page)
    })
}

export const getRowsPerPage = (page, state, callback) => {
    var url = ENDPOINT + "user/all?empresa_relacao=aqpasso&page=" + page + "&search=" + state.search;
    console.log(state.document)
    var document = state.document.replace(/([^\d])+/gim, '');

    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&cpf="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&cnpj="+document;
        }
    }
  
    if(state.hours == true){
        url += "&assistencia=1";
        
    }

/*   if(state.type_user.length > 0){
    url += "&tipo_usuario="+state.type_user
  } */


  if(state.status_user.length > 0){
    url += "&status_usuario="+state.status_user
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      callback(p.data, p.last_page)
    })
}