import React from 'react';
import { HiViewGrid, HiTrash } from 'react-icons/hi';
import { Button, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getPermissoes, getToken } from '../../../auth/set_services';
import ReactTooltip from 'react-tooltip';
import 'react-dropdown/style.css';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import moment from 'moment';

const getStatusMeuBus = (status) => {
  switch(status){
    case 'completed':
      return 'Completo';
    break;
    case 'blocked':
      return 'Bloqueado';
    break;
    case 'pending':
      return 'Pendente';
    break;
    case 'stage_contact':
      return 'Etapa de contato';
    break;
    case 'stage_pending_token_email':
      return 'Validação de e-mail';
    break;
    case 'stage_pending_token':
      return 'Validação de sms';
    break;
    default:
      return status;
  }
}

const getEstagioMeuBus = (status) => {
  switch(status){
    case 'completed':
      return 'Completo';
    break;
    case 'blocked':
      return 'Bloqueado';
    break;
    case 'pending':
      return 'Iniciado';
    break;
    case 'stage_contact':
      return 'Etapa de contato';
    break;
    case 'stage_pending_token_email':
      return 'Validação de e-mail';
    break;
    case 'stage_pending_token':
      return 'Validação de sms';
    break;
    default:
      return status;
  }
}

export const conditionalRowStyles = [
  {
    when: row => row.status == 'blocked' && row.fraud,
    style: {
      backgroundColor: '#fff',
      color: '#d10303',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
];

export const columns = (deleteUserMeuBus) => [
  {
    name: '',
    selector: 'buttons',
    width: '5%',
    cell: row => <Row>
      <Button data-tip="Resumo do Cliente"
        outline
        style={
          row.status == 'blocked' && row.fraud ?
          { padding: '0px', marginRight: '3px', border: 0 } :
          { color: '#fff', padding: '0px', marginRight: '3px', border: 0 } 
        }
        disabled={row.status == 'pending'}
        color="primary" onClick={() => {
            window.location.href = '/querobus-user/' + row.id
          }
        }>
          
        <HiViewGrid style={row.status == 'blocked' && row.fraud ? 
          {  color: '#d10303', padding: '2px', width: '23px' } : 
          {color: 'blue', padding: '2px', width: '23px' } 
        } />
      </Button>
      <Button data-tip="Deletar Cliente"
        outline
        style={
          row.status == 'blocked' && row.fraud ?
          { padding: '0px', marginRight: '3px', border: 0 } :
          { color: '#fff', padding: '0px', marginRight: '3px', border: 0 } 
        }
        onClick={() => {
            deleteUserMeuBus(row)
          }
        }>
        <HiTrash style={{  color: '#d10303', padding: '2px', width: '23px' }} />
      </Button>


      <ReactTooltip />
    </Row>
  },
  {
    name: "Nome",
    selector: "name",
    sortable: false,
    width: '20%',
    cell: row => row.social_name != null ? row.social_name + ' | ' + row.name : row.name
  },
  {
    name: "Email",
    selector: "email",
    sortable: false,
    width: '20%',
    cell: row => row.email
  },
  /* {
    name: "Aceite de termo",
    selector: "termos_aceito",
    sortable: false,
    width: '15%',
    cell: row => <div>
      {row.termos_aceito == true ? 'Sim' : 'Pendente'}
    </div>
  }, */
  {
    name: "Telefone",
    selector: "phone",
    sortable: false,
    width: '20%',
    cell: row => <NumberFormat displayType={'text'} value={row.phone} format="(##) #####-####" />
  },
  {
    name: "Documento",
    selector: "cpf",
    sortable: false,
    width: '20%',
    cell: row => {
      var value = row.cnpj !== null ? row.cnpj : row.cpf
      if (value !== null) {
        return <NumberFormat displayType={'text'} value={value} format={value.replace(/([^\d])+/gim, '').length > 11 ? '##.###.###/####-##' : "###.###.###-##"} />
      }
      return "S/N";
    }
  },
  {
    name: "Status",
    selector: "status",
    sortable: false,
    cell: row => getStatusMeuBus(row.status)
  },
  // {
  //   name: "Estágio",
  //   selector: "cadastro",
  //   sortable: false,
  //   cell: row => getEstagioMeuBus(row.stage)
  // },
  {
    name: "Data de Nascimento",
    selector: "birthdate",
    sortable: false,
    width: '100px',
    cell: row => moment(new Date(row.birthdate).toDateString()).format('DD/MM/YYYY')
  },
];

export const getRows = (state, callback) => {
  var url = ENDPOINT + "querobus/users/list?page=" + state.activePage + "&search=" + state.search;

  var document = state.document.replace(/([^\d])+/gim, '');
  if (state.is_cnpj === false) {
    url += "&cpf=" + document;
  }
  else {
    url += "&cnpj=" + document;
  }

  if (state.seller_id) {
    url += "&seller_id=" + state.seller_id
  }

  if (state.status_user.length > 0) {
    url += "&status_usuario=" + state.status_user
  }

  if (state.status?.value != '') {
    url += "&status=" + state.status?.value
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      callback(p.data, p.last_page);
    })
    .catch(() => {
      toast.error('Ocorreu um erro no servidor!');
    })
}

export const getRowsPerPage = (page, state, callback) => {
  var url = ENDPOINT + "querobus/users/list?page=" + page + "&search=" + state.search;
  var document = state.document.replace(/([^\d])+/gim, '');
  if (state.is_cnpj === false) {
    url += "&cpf=" + document;
  }
  else {
    url += "&cnpj=" + document;
  }

  if (state.seller_id) {
    url += "&seller_id=" + state.seller_id
  }

  if (state.status_user.length > 0) {
    url += "&status_usuario=" + state.status_user
  }

  if (state.status?.value != '') {
    url += "&status=" + state.status?.value
  }
  
  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      callback(p.data, p.last_page)
    })
    .catch(() => {
      toast.error('Ocorreu um erro no servidor!');
    })
}