import Moment from 'moment';
import StarRating from 'react-star-ratings';
import { BsChatFill } from 'react-icons/bs';
import { Button } from 'reactstrap';


export const columns = (onMessage) => [
    {
        name: "Nome do Responsavel",
        selector: "nome",
        sortable: false,
        width: '160px'
    },
    {
        name: "Empresa",
        selector: "empresa",
        sortable: false,
        width: '200px',
        cell: row => row.usuario !== null ? (row.usuario.empresa !== null ? row.usuario.empresa.razao_social : '') : 'Sem informação'
    },
    {
        name: "E-mail",
        selector: "email",
        sortable: false,
        width: '250px',
    },
    {
        name: 'Telefone',
        selector: 'telefone',
        sortable: false,
        width: '150px',
    },
    {
        name: 'Data de Chat',
        selector: 'created_at',
        sortable: false,
        width: '150px',
        cell: row =>  Moment(new Date(row.created_at)).format('DD/MM/YYYY HH:mm')
    },
    {
        name: 'Operador',
        selector: 'created_at',
        sortable: false,
        width: '150px',
        cell: row =>  row.atendente !== null ? row.atendente.name : 'Sem Informações'
    },
    {
        name: 'Protocolo',
        selector: 'protocolo_atendimento',
        sortable: false,
        width: '150px',
    },
    {
        name: 'Mensagens',
        selector: 'messages_count',
        sortable: false,
        width: '100px',
    },
    {
        name: 'Status do Chat',
        selector: 'status_chat',
        width: '150px',
        cell: row =>  getStatusChat(row.status_chat)
    },
    {
        name: 'Avaliação',
        selector: 'avaliacao',
        width: '250px',
        cell: row => row.avaliacao !== null ? <><StarRating 
                            totalStars={5} 
                            starDimension='30px'
                            starRatedColor="#ccb00f"
                            rating={row.avaliacao}
                        />
                        </> : 'Não definido'
    },
    {
        name: '',
        cell: row => <Button outline onClick={() => onMessage(row.messages, row.cod_chat, true)} color="info"><BsChatFill style={{padding: '1px'}}/></Button>
    }
    //,
    /* {
      name: "Status",
      selector: "opened",
      sortable: false,
      width: '30%',
      cell: row => row.mail.length > 0 ?
        <div data-tip={getStringMaps(row.mail)} style={{ maxHeight: '200px', overflow: 'auto', width: '100%', margin: '12px' }}>
          <ul style={{ listStyleType: 'none' }}>
            {row.mail.slice(0, 1).map((p) => {
              return <li>
                <p><MdMail /> <b>{getTranslate(p.recordType)}</b>
                  <br />
                  {Moment(new Date(p.dateAt).toString()).format('DD/MM/YYYY h:mm:ss a')}</p>
              </li>
            })}
          </ul>
          <ReactTooltip multiline={true} />
        </div>
        :
        <div data-tip={"Postado " + Moment(new Date(row.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')} style={{ maxHeight: '200px', overflow: 'auto', width: '100%', margin: '12px' }}>
          <ul>
            <li style={{ listStyleType: 'none' }}><MdMail /> <b>Postado</b><br />{Moment(new Date(row.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')}
            </li>
          </ul>
          <ReactTooltip multiline={true} />
        </div>
    } */
  ];
const getStatusChat = (status) => {
    switch(status){
        case 0: return <div style={{fontSize: '14px'}}><BsChatFill/>Aguardando Atendente</div>;
        case 1: return <div style={{color: '#2196f3', fontSize: '14px'}}><BsChatFill/>Em andamento</div>;
        case 2: return <div style={{color: '#2a770a', fontSize: '14px'}}><BsChatFill/>Finalizado</div>;
        case 3: return <div style={{color: '#df1818', fontSize: '14px'}}><BsChatFill/>Perdido</div>;
        default : return status;
    }
}