import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import FormTaggys from "../../FormAddUser/FormTaggys";
import { ENDPOINT } from "../../../auth/endpoint";
import axios from "axios";
import { getToken } from "../../../auth/set_services";

class AddTaggyFromUser extends Component {
    constructor(props){
        super(props);
        this.state = {
            cvc: '',
            expiry: '',
            focus: '',
            name: '',
            number: '',
            forma_de_pagamento: '',
            modal_type: '',
            number_taggys: 1,
            list_cards: [],
            list_plan: [
                { valor_label: '50,00', valor: 50.00},
                { valor_label: '100,00', valor: 100.00},
                { valor_label: '300,00', valor: 300.00},
            ],
            card: null,
            currentCard: null,
            valor_deposito: "50.00",
            loading: false,
            error_message: '',
            success_message: ''
        }
        this.onRegister = this.onRegister.bind(this);
        this.onClose = this.onClose.bind(this);
        this.loadTaggy = this.loadTaggy.bind(this);
    }
    onChange(currentItem, pageIndex) {
        this.setState({
            valor_deposito: this.state.list_plan[pageIndex].valor
        })
    }

    list_plans = (number_taggys) => [
        { valor_label: String(parseFloat(50.00 * number_taggys).toFixed(2)), valor: 50.00 * number_taggys},
        { valor_label: String(parseFloat(100.00 * number_taggys).toFixed(2)), valor: 100.00 * number_taggys},
        { valor_label: String(parseFloat(300.00 * number_taggys).toFixed(2)), valor: 300.00 * number_taggys},
    ];

    componentWillUpdate(nextProps, nextState){
        if(nextProps.editing !== null && nextProps.editing !== this.props.editing){
            this.loadTaggy(nextProps.editing);
        }
        if(nextProps.visible !== false && nextProps.visible !== this.props.visible){
            this.getCards(nextProps.cpf === null ? nextProps.cnpj : nextProps.cpf);
        }
    }

    loadTaggy(taggy){
        this.setState({
            user_id: taggy.id === null ? -1 : taggy.id,
            oldTaggy: taggy.serial,
            serial: taggy.serial,
            chave_decimal: taggy.chave_decimal,
        })
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }
    onPaymentType(forma_de_pagamento){
        this.setState({forma_de_pagamento, card: null})
    }
    onModalPayment(modal_type){
        if(modal_type === 'credit_list'){
            var list_cards = this.state.list_cards;
            var currentCard = list_cards[0];
            this.setState({currentCard})
        }
        this.setState({modal_type})
    }
    validateFields(){
        var invalid = 0;

        if(this.state.serial.length > 15){
            this.setState({
                serial_invalid: false
            })
        }
        else{
            invalid++;
            this.setState({
                serial_invalid: true
            })
        }

        /* if(this.state.chave_decimal.length > 9){
            this.setState({
                chave_decimal_invalid: false
            })
        }
        else{
            invalid++
            this.setState({
                chave_decimal_invalid: true
            })
        } */
        return invalid;

    }
    selectedCard(card_select, cvc){
        var card = {
            new_card: false,
            nome_proprietario: card_select.nome_proprietario,
            numero: card_select.numero,
            validade: card_select.validade_mes+'/'+card_select.validade_ano,
            cvc: cvc,
            api_cartao_token_id: card_select.api_cartao_token_id,
            api_cartao_id: card_select.api_cartao_id
        }

        this.setState({card, modal_type: ''})
    }
    selectedNewCard(){
        var card = {
            new_card: true,
            nome_proprietario: this.state.name,
            numero: this.state.number,
            validade: this.state.expiry,
            cvc: this.state.cvc,
        }

        this.setState({card, modal_type: ''})
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    getCards = (cpf) => {
        var url = ENDPOINT+'cartao/list/'+cpf;
        console.log(url);
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            console.log(response.data.data);
            this.setState({list_cards: response.data.data})
        })
        .catch((error) => {
            this.setState({list_cards: []})
        });
    }

    storeTaggy = (onData) => {
        this.setState({loading: true});
        var url = ENDPOINT+this.props.api;

        var formData = new FormData();

        formData.append('num_taggys', this.state.number_taggys);

        if(this.props.cpf !== null){
            formData.append('cpf', this.props.cpf);
        }
        if(this.props.cnpj !== null){
             formData.append('cnpj', this.props.cnpj);
        }
        var valor = parseFloat(this.state.valor_deposito) * parseFloat(this.state.number_taggys);
        formData.append('valor_plano', String(parseFloat(valor).toFixed(2)));

        formData.append('tipo_pagamento', this.state.forma_de_pagamento);

        if(this.state.forma_de_pagamento === 'credit'){
            formData.append('cartao', JSON.stringify(this.state.card));
            formData.append('tipo_cartao', 'card_list');
            formData.append('tipo_pagamento', 'credit');
        }
    
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            onData(data);
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
            var data = error.response.data;
            onData(data);
            return data;
        });
    }

    onRegister() {
        this.storeTaggy(this.onData.bind(this));
    }

    onData(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onClose(){
        this.setState({
            cvc: '',
            expiry: '',
            focus: '',
            name: '',
            number: '',
            forma_de_pagamento: '',
            modal_type: '',
            number_taggys: 1,
            list_cards: [],
            card: null,
            currentCard: null,
            valor_deposito: "50.00",
            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose(false);
    }

    functionName = () => {
        return "Fazer pedido de Taggy para o cliente"
    }
    onChangeTaggy(number_taggys){
        this.setState({number_taggys})
    }
    handleInputFocus = (e) => {
      this.setState({ focus: e.target.name });
    }
    handleInputChange = (e) => {
      const { name, value } = e.target;
      
      this.setState({ [name]: value });
    }
    onChangeCard = (currentItem, pageIndex) => {
        var list_cards = this.state.list_cards;
        var currentCard = list_cards[pageIndex];
        console.log(currentCard);
        this.setState({currentCard});
    }
    
    render(){
        return(
            <Modal isOpen={this.props.visible}>
                <ModalHeader>
                    {this.functionName()}
                </ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <FormTaggys 
                        handleInputFocus={this.handleInputFocus}
                        handleInputChange={this.handleInputChange}
                        list_plan={this.list_plans(this.state.number_taggys)} 
                        selectedCard={this.selectedCard.bind(this)}
                        selectedNewCard={this.selectedNewCard.bind(this)}
                        onPaymentType={this.onPaymentType.bind(this)}
                        onModalPayment={this.onModalPayment.bind(this)}
                        onRecarga={this.onChange.bind(this)} 
                        taggys={this.state.number_taggys} 
                        state={this.state} 
                        onChangeCard={this.onChangeCard.bind(this)}
                        onChangeTaggy={this.onChangeTaggy.bind(this)} />
                </ModalBody>
                <ModalBody 
                    style={{
                        minHeight: '114px', 
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn 
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                        />
                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose(false)}>Cancelar</Button>
                    <Button color="success" onClick={this.onRegister}>Cadastrar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
export default AddTaggyFromUser;