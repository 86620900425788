import React from 'react';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import 'react-dropdown/style.css';
import moment from 'moment';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ButtonGreen } from '../../ButtonStylish/ButtonGreen';
import styled from 'styled-components';


/* const capitalizeFirstLetter = (string) => {
  if(string !== null){
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "Indisponivel"
} */
/* const Div = styled.div`
  .dropdown-toggle:after { 
    content: none 
  }
  .dropdown-toggle:before { 
    content: none 
  }
  .btn-primary {
    color: #007bff;
    background-color: #ffffff;
    border-color: #ffffff;
    padding: 0;
  }
  .btn-primary:hover {
    background-color: #007bff;
    color: #ffffff;
    border-color: #007bff;
    padding: 0;
  }
`;

const getTimeline = (type, empresa, userId) =>{
  if(empresa !== null){
    switch(type){
      case 'email': return '/empresa/'+empresa.id+'?active=9';
      case 'sms': return '/empresa/'+empresa.id+'?active=10'; 
    }
  }
  else{
    switch(type){
      case 'email': return '/user/'+userId+'?active=6'; 
      case 'sms': return '/user/'+userId+'?active=7'; 
    }

  }
  return '';
}

const payment = (payment_type) => {
  switch(payment_type){
      case 'credit' : return 'Crédito';
      case 'boleto' : return 'Boleto';
      case 'debit' : return 'Débito';
      default: return payment_type
  }
}
 */

const AreaIdTransacao = styled.div`
  text-overflow: ellipsis;
  width: 162px;
  overflow: hidden;
  white-space: nowrap;
`

export const columns = () => [
  /* {
    name: '',
    selector: 'buttons',
    width: '50px',
    cell: row => <Row>
            <Button data-tip="Detalhes da Transação"
            outline
            style={{ padding: '0px', marginRight: '3px', marginLeft: 15, border: 0, fontWeight: '700'}}
            onClick={() => 
                {
                    window.location.href = '/payout-aqpago-detalhes/'+row.codigo_pagamento
                }
            }>
            <HiViewGrid style={{ padding: '2px' }}/>
            </Button>
        </Row>
  }, */
  {
    name: "Data",
    selector: "created_at",
    sortable: false,
    width: '150px',
    cell: row => row.created_at !== null ? moment(row.created_at).format('DD/MM/YYYY HH:mm') : ''
  },
  {
    name: "ID da Transação",
    selector: "api_transacao",
    sortable: false,
    width: '10%',
    cell: row => <AreaIdTransacao>
      <span data-tip={row.api_transacao}>{row.api_transacao}</span>
      <div style={{display: 'flex'}}>
        <CopyToClipboard onCopy={() => {toast.success('Copiado com sucesso!')}} text={row.api_transacao}>
            <ButtonGreen>
              Copiar
            </ButtonGreen>
          </CopyToClipboard>
        </div>
        <ReactTooltip />
      </AreaIdTransacao>
  },
  {
    name: "Seller",
    selector: "individual",
    sortable: false,
    width: '25%',
    cell: row => row.vendedor.user_tipo === 'empresarial' ? row.vendedor.empresa.razao_social + ' - ' + row.vendedor.empresa.cnpj : row.vendedor.user.nome + ' ' +  row.vendedor.user.sobrenome
  },
  {
    name: "Buyer",
    selector: "cpf",
    sortable: false,
    width: '25%',
    cell: row => 
        row.comprador.user ? 
            (row.comprador.user.nome + ' ' + row.comprador.user.sobrenome) + ' - ' + row.comprador.user.cpf 
            : 
            row.comprador.user.razao_social + row.comprador.empresa.cnpj
  },
  {
    name: "Status",
    selector: "status",
    width: '180px',
    sortable: false,
    cell: row => row.status
  },
  {
    name: "Valor",
    selector: "amount",
    sortable: false,
    cell: row => <>R$ {parseFloat(row.valor).toFixed(2).replace('.', ',')}</>
  },
];

export const getRows = (state, callback) => {
  var url = ENDPOINT + "payout/listar_pagamentos_por_cartao?page=" + state.activePage;

  if(state.numeros_iniciais.replace(/([^\d])+/gim, '').length > 3 && state.numeros_finais.replace(/([^\d])+/gim, '').length > 3){
      url += "&numeros_iniciais="+state.numeros_iniciais+'&numeros_finais='+state.numeros_finais;
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
        var meta = 0;
        if(p.meta){
          meta = p.meta.last_page;
        }
        callback(p.data, meta);
    })
    .catch((error) => {
        toast.error(error.toString());
    })
}

export const getRowsPerPage = (page, state, callback) => {
  var url = ENDPOINT + "payout/listar_pagamentos_por_cartao?page=" + page;

  if(state.numeros_iniciais.replace(/([^\d])+/gim, '').length > 3 && state.numeros_finais.replace(/([^\d])+/gim, '').length > 3){
      url += "&numeros_iniciais="+state.numeros_iniciais+'&numeros_finais='+state.numeros_finais;
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      var meta = 0;
      if(p.meta){
        meta = p.meta.last_page;
      }
      callback(p.data, meta);
    })
    .catch((error) => {
      toast.error(error.toString());
    })
}


export const getAllRows = (state, callback) => {
  var url = ENDPOINT + "payout/listar_pagamentos_por_cartao?page=" + state.activePage;

  if(state.numeros_iniciais.replace(/([^\d])+/gim, '').length > 3 && state.numeros_finais.replace(/([^\d])+/gim, '').length > 3){
      url += "&numeros_iniciais="+state.numeros_iniciais+'&numeros_finais='+state.numeros_finais;
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      var meta = 0;
      if(p.meta){
        meta = p.meta.last_page;
      }
      callback(p.data, meta);
    })
    .catch((error) => {
        toast.error(error.toString());
    })
}

export const getAllRowsPerPage = (page, state, callback) => {
  var url = ENDPOINT + "payout/listar_pagamentos_por_cartao?page=" + page;

  if(state.numeros_iniciais.replace(/([^\d])+/gim, '').length > 3 && state.numeros_finais.replace(/([^\d])+/gim, '').length > 3){
      url += "&numeros_iniciais="+state.numeros_iniciais+'&numeros_finais='+state.numeros_finais;
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      var meta = 0;
      if(p.meta){
        meta = p.meta.last_page;
      }
      callback(p.data, meta);
    })
    .catch((error) => {
      toast.error(error.toString());
    })
}