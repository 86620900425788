import axios from "axios";
import React, { Component } from "react";
import { Button, Card, Col, FormGroup, Label, Modal, ModalFooter, ModalHeader, Input, Row, FormFeedback, CustomInput } from 'reactstrap';
import styled from "styled-components";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import { InputText } from '../../InputText/InputText';
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import Dropzone from 'react-dropzone';
import { MdAttachFile, MdClear, MdEmail, MdPhoneAndroid } from "react-icons/md";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import { list_planos, list_companys } from "../../../auth/listas";
import aqpago from '../../../assets/aqpago.svg';
import aqpasso from '../../../assets/aqpasso-logo.svg';
import aqbank from '../../../assets/aqbank.svg';
import aqcontabil from '../../../assets/aqcontabil.svg';
import meubus from '../../../assets/meubus.svg';
import aqmed from '../../../assets/AQMed.svg';
import { BsFillPeopleFill } from "react-icons/bs";

const ModalBody = styled.div`
    .svg-inline--fa {
        width: 2em;
    }
    .svg-inline--fa.fa-w-14{
        width: 2em;
    }
    .prosemirror-toolbar-item{
        color: #52c565;
    }
    .prosemirror-toolbar-item[data-active='true'] {
        background-color: #52c565;
        color: #fff;
    }

    .rdw-option-wrapper{
        border: 0
    }
    .rdw-option-wrapper:active{
        background-color: #aaaaaa;
        box-shadow: none;
    }
    .rdw-option-wrapper:hover {
        background-color: #aaaaaa;
    }

    .rdw-dropdown-wrapper{
        border: 1px solid #ced4da;
    }
    .rdw-dropdown-wrapper:active{
        border: 1px solid #ced4da;
        box-shadow: none;
    }
    .rdw-dropdown-wrapper:hover{
        border: 1px solid #ced4da;
        box-shadow: none;
    }

    .rdw-dropdown-selectedtext{
        color: black
    }
    .rdw-dropdown-selectedtext:hover{
        text-decoration: none;
        color: black
    }
`;
const DropArea = styled.div`
    border-style: dashed;
    border-color: #38B76E;
    padding: 1%;
    cursor: pointer;
    &:drag {
        opacity: 0.5;
    }
`;
const ButtonUpload = styled.button`
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0;
    padding-left: 7px;
    padding-right: 7px;
    top: -1px;
    position: relative;
`;

const ButtonOption = styled.button`
    padding: 20px;
    background: ${props => get_color(props.type)};
    border-radius: 100%;
    border: none;
    outline: none;
    box-shadow: none;

    &:hover{
        opacity: 0.8;
    }
`;
const OptionArea = styled.div`
    margin: 15px;
    p{
        padding: 15px;
        font-weight: 600;
    }
`;
const OptionsArea = styled.div`
    display: flex;
    text-align: center;
    place-content: center;
`;
const OptionsSMS = styled.div`
    display: flex;
    margin-bottom: 8px;

    button{
        margin-right: 4px;
    }

    .select-option{
        width: 100%;
    }
`;
const OptionLogo = styled.div`
    padding: 8px;
    height: 42px;
    background: ${props => get_color(props.type)};
    border-radius: 100%;
    margin-left: 10px;
    border: none;
    outline: none;
    box-shadow: none;
`;


const get_color = (type) => {
    switch(type){
        case 'aqpago': return '#12061E';
        case 'aqbank': return '#1A1E1D';
        case 'aqpasso': return '#2A1639';
        case 'aqcontabil': return '#EEEFF1';
        case 'meubus': return '#0085FF';
        case 'aqmed': return '#0C4C62';
        case 'todos': return '#25292d';
        default: return '#757678'
    }
}


class SendEmail extends Component{
    constructor(props){
        super(props);
        this.state = {
            step: 1,
            option_select: 'aqpago',
            assinaturas_email: [],
            assinatura_email: null,
            assinatura_email_invalid: false,
            email_text: this.props?.email_current ? this.props?.email_current  : '',
            email_text_invalid: false,
            email: [],
            email_invalid: false,
            plano: null,
            plano_invalid: false,
            file: [],
            file_invalid: false,
            assunto: '',
            assunto_invalid: false,
            mensagem: EditorState.createEmpty(),
            mensagem_invalid: false,
            inputValue: '',
            all_users: false,
            email_manual:false,
            loading: false,
            error_message: '',
            success_message: '',
            company_select: {value: 'aqpago', label: 'AQPago'},
            update: false,
        }
        this.onRegister = this.onRegister.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.inputFile = React.createRef();
    }
    componentDidMount(){
        this.getModelos();
    }
    componentWillUpdate(nextProps, nextState){
        if(nextProps.user !== null && nextProps.user !== this.props.user){
            this.setState({ 
                email: [{ 
                    isFixed: true,
                    label: nextProps.user.nome+" "+nextProps.user.sobrenome+" - "+nextProps.user.email,
                    value: nextProps.user.email
                 }]
            });
        }

        if (nextProps.visible !== false && nextProps.visible !== this.props.visible) {
            this.getModelos();
        }

        if (!this.state.update && this.props?.email_current) {
            this.onSelectPlan('manual', 3)
            this.setState({update: true})
        }
    }

    filter = (inputValue, data) => {
      return data.filter(i =>
        i.nome.toLowerCase().includes(inputValue.toLowerCase())
      );
    };
    
    getModelos = () => {
        var url = ENDPOINT+"modelos/list";
    
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            var assinaturas_email = [];
            data.forEach((p, i) => {
                p['value'] = p.flag;
                p['label'] = p.nome;
                assinaturas_email.push(p);
            })
            this.setState({assinaturas_email});
        })
        .catch((error) => {

        });
    }
    
    loadOptions = (inputValue, callback) => {
        //var url = ENDPOINT + "user/all?search=" + inputValue;
        var url = ENDPOINT + "user/list?empresa_relacao=" + this.state.option_select + "&search=" + inputValue;
        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p.data;

            data.forEach((p, i) => {
                data[i].value = p.id;
                data[i].email = p.email;
                data[i].label = 
                p?.name ?
                (p.name !== null ? p.name : p.social_name) +" - "+ p.email
                :
                (p.nome !== null ? p.nome+" "+p.sobrenome : p.nome_fatura) +" - "+ p.email;
            });

            this.state.email.forEach((p, i) => {
                data.forEach((p2, i2) => {
                    /* eslint eqeqeq: 0 */
                    if(p2.email == p.value){
                        data.splice(i2, 1);
                    }
                });
            });

            callback(this.filter(inputValue, p.data));
        });


    }

    filter = (inputValue, data) => {
        return data.filter(i =>
            i?.nome ?
            (i.nome !== null ? i.nome : '').toLowerCase().includes(inputValue.toLowerCase())
            :
            (i.name !== null ? i.name : '').toLowerCase().includes(inputValue.toLowerCase())
        );
    }
    onBack(){
        this.setState({step: 1})
    }
    onClose(){
        this.setState({
            assinaturas_email: [],
            assinatura_email: null,
            assinatura_email_invalid: false,
            email_text: '',
            email_text_invalid: false,
            email: [],
            email_invalid: false,
            plano: null,
            plano_invalid: false,
            file: [],
            file_invalid: false,
            assunto: '',
            assunto_invalid: false,
            mensagem: EditorState.createEmpty(),
            mensagem_invalid: false,
            inputValue: '',
            all_users: false,
            email_manual:false,
            loading: false,
            error_message: '',
            success_message: '',
            hidden_select: false,
        });
        this.props.onClose(false);
    }
    onCloseNew() {
        this.props.onClose(false);
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }
    handleChangeEmail = (mensagem) => {
        this.setState({mensagem});
    }
    handleFileChange = (event) => {
        const files = event.target.files;

        var state = this.state;
        Array.from(files).forEach(file_current => {
            state.file.push(file_current);
        });
        this.setState({
            ...state
        })
    }
    validateFields(){
        var invalid = 0;

        if(this.state.assinatura_email !== null){
            this.setState({
                assinatura_email_invalid: false
            })
        }
        else{
            invalid++;
            this.setState({
                assinatura_email_invalid: true
            })
        }
        if(this.props.email){
            if(this.state.email_manual === false){
                if(this.state.email.length > 0){
                    this.setState({
                        email_invalid: false
                    })
                }
                else{
                    invalid++;
                    this.setState({
                        email_invalid: true
                    })
                }
            }
            else{
                if(this.state.email_text.length > 0){
                    this.setState({
                        email_text_invalid: false
                    })
                }
                else{
                    invalid++;
                    this.setState({
                        email_text_invalid: true
                    })
                }
            }
        }

        if(this.state.assunto.length > 0){
            this.setState({
                assunto_invalid: false
            })
        }
        else{
            invalid++;
            this.setState({
                assunto_invalid: true
            })
        }

        return invalid;
    }

    onRegister() {
        if(this.validateFields() === 0){
            this.setState({loading: true});
            this.store();
        }
    }
    
    store = () => {
        var url = ENDPOINT+"emails/store";

        var formData = new FormData();
        formData.append('todos', Number(this.state.all_users));
        if(this.props.email_current){
            formData.append('one_email', this.props.email_current);
            formData.append('communication_relacao_id', this.props.communication_relacao_id);
        }
        else if(this.state.email_manual === false){
            if(this.state.all_users === false){
                if(this.state.email.length > 0){
                    this.state.email.forEach((p, index) => {
                        formData.append('email['+index+']', p.email);
                    });
                }
            }
        }
        else{
            formData.append('one_email', this.state.email_text);
        }

        formData.append('mensagem', draftToHtml(convertToRaw(this.state.mensagem.getCurrentContent())));
        formData.append('assunto', this.state.assunto);
        if(this.state.file.length > 0){
            this.state.file.forEach((file, index) => {
                formData.append('anexo['+index+']', file);
            });
        }
        if(this.state.plano !== null){
            formData.append('plano', this.state.plano.label)
        }

        formData.append('modelo_assinatura', this.state.assinatura_email.conteudo)

        if(this.state.company_select?.value) {
            formData.append('company[0]', this.state.company_select?.value)
        } else {
            this.state.company_select.map((company, i)=>{
                formData.append(`company[${i}]`, company.value)
            })
        }
        
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            this.onData(data);
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            if(data.exception){
                 response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                 obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            if(typeof data.message !== 'undefined'){
                if(data.message === 'Server Error'){
                    response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    obj = JSON.parse(response);
                    this.onData(obj);
                    return obj;
                }
            }
            this.onData(data);
        });
    }

    onData(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }

    onDrop = (file) => {
        var state = this.state;
        file.forEach(file_current => {
            state.file.push(file_current);
        });
        this.setState({
            ...state
        })
    }

    removeAttach = (index) => {
        var state = this.state;
        state.file.splice(index, 1);
        this.setState({
            ...state
        })
    }

    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    handleInputChange = (newValue) => {
      const inputValue = newValue.replace(/\W/g, '');
      this.setState({ inputValue });
      return inputValue;
    };

    handleManual = (email_manual) => {
        this.setState({
            email: [],
            email_invalid: false,
            email_text: '',
            email_text_invalid: false,
            all_users: false,
            email_manual
        })
    }
    onAll = (all_users) => {
        if(all_users === true){
            this.setState({ 
                email: [{ 
                    isFixed: true,
                    label: "Todos",
                    value: 'all'
                 }],
                 plano: null
            });
        }
        else{
            this.setState({email: [], plano: null})
        }
        this.setState({all_users})
    }

    onSelectPlan = (option_select, step) => {

        // if(all_users === true){
        //     this.setState({ 
        //         email: [{ 
        //             isFixed: true,
        //             label: "Todos",
        //             value: 'all'
        //          }],
        //          plano: null
        //     });
        // }
        // else{
        //     this.setState({email: [], plano: null})
        // }
        // this.setState({all_users})
        // ('manual', 3)
        switch(option_select) {
            case 'aqpago':

                this.setState({
                    email_manual: false,
                    company_select: { value: 'aqpago', label: 'AQPago'},
                    email: [{ 
                        isFixed: true,
                        label: "Todos",
                        value: 'all'
                    }],
                    plano: null,
                    all_users: true,
                    hidden_select: true,
                })
                
                break;
                case 'aqmed':

                this.setState({
                    email_manual: false,
                    company_select: { value: 'aqmed', label: 'AQMed'},
                    email: [{ 
                        isFixed: true,
                        label: "Todos",
                        value: 'all'
                    }],
                    plano: null,
                    all_users: true,
                    hidden_select: true,
                })
                
                break;
            case 'aqbank':

                this.setState({
                    email_manual: false,
                    company_select: { value: 'aqbank', label: 'AQBank'},
                    email: [{ 
                        isFixed: true,
                        label: "Todos",
                        value: 'all'
                    }],
                    plano: null,
                    all_users: true,
                    hidden_select: true,
                })

                break;
            case 'aqpasso':

                this.setState({
                    email_manual: false,
                    company_select: { value: 'aqpasso', label: 'AQPasso'},
                    email: [{ 
                        isFixed: true,
                        label: "Todos",
                        value: 'all'
                    }],
                    plano: null,
                    all_users: true,
                    hidden_select: true,
                })

                break;
            case 'aqcontabil':

                this.setState({
                    email_manual: false,
                    company_select: { value: 'aqcontabil', label: 'AQContabil'},
                    email: [{ 
                        isFixed: true,
                        label: "Todos",
                        value: 'all'
                    }],
                    plano: null,
                    all_users: true,
                    hidden_select: true,
                })

                break;
            case 'meubus':

                this.setState({
                    email_manual: false,
                    company_select: { value: 'meubus', label: 'MeuBus'},
                    email: [{ 
                        isFixed: true,
                        label: "Todos",
                        value: 'all'
                    }],
                    plano: null,
                    all_users: true,
                    hidden_select: true,
                })

                break;
            case 'manual':

                this.setState({
                    email: [],
                    email_invalid: false,
                    email_text: this.props?.email_current ? this.props?.email_current : '',
                    email_text_invalid: false,
                    all_users: false,
                    email_manual: true,
                    company_select: { value: 'manual', label: 'Manual'},
                    email: [], 
                    plano: null,
                    all_users: false,
                    hidden_select: false,
                })

                break;
            case 'todos':

                this.setState({
                    email_manual: false,
                    company_select: { value: 'all', label: 'Todas'},
                    email: [{ 
                        isFixed: true,
                        label: "Todos",
                        value: 'all'
                    }],
                    plano: null,
                    all_users: true,
                    hidden_select: true,
                })

                break;
            default:
                break;
        }

        this.setState({
            option_select: option_select, 
            step: step
        })
    }

    StepOne = () => {
        return(
            <>
                <OptionsArea>
                    <OptionArea>
                        <ButtonOption type="aqpago" onClick={() => this.onSelectPlan('aqpago', 2)}>
                            <img 
                                alt="aqpago-logo"
                                width="50" 
                                src={aqpago}
                            />
                        </ButtonOption>
                        <p>Selecionar clientes <br/>AQPago</p>
                    </OptionArea>
                    <OptionArea>
                        <ButtonOption type="aqbank" onClick={() => this.onSelectPlan('aqbank', 2)}>
                            <img 
                                alt="aqbank-logo"
                                width="50" 
                                src={aqbank}
                            />
                        </ButtonOption>
                        <p>Selecionar clientes <br/>AQBank</p>
                    </OptionArea>
                    <OptionArea>
                        <ButtonOption type="aqpasso" onClick={() => this.onSelectPlan('aqpasso', 2)}>
                            <img 
                                alt="aqpasso-logo"
                                width="50" 
                                src={aqpasso}
                            />
                        </ButtonOption>
                        <p>Selecionar clientes <br/>AQPasso</p>
                    </OptionArea>
                </OptionsArea>
                <OptionsArea>
                    <OptionArea>
                        <ButtonOption type="aqcontabil" onClick={() => this.onSelectPlan('aqcontabil', 2)}>
                            <img 
                                alt="aqcontabil-logo"
                                width="50" 
                                height="46" 
                                src={aqcontabil}
                            />
                        </ButtonOption>
                        <p>Selecionar clientes <br/>AQContabil</p>
                    </OptionArea>
                    <OptionArea>
                        <ButtonOption type="meubus" onClick={() => this.onSelectPlan('meubus', 2)}>
                            <img 
                                alt="mebus-logo"
                                width="50" 
                                height="46" 
                                src={meubus}
                            />
                        </ButtonOption>
                        <p>Selecionar clientes <br/>MeuBus</p>
                    </OptionArea>
                    <OptionArea>
                        <ButtonOption type="aqmed" onClick={() => console.log("aqmedmail")}>
                            <img 
                                alt="aqmed-logo"
                                width="50" 
                                height="46" 
                                src={aqmed}
                            />
                        </ButtonOption>
                        <p>Selecionar clientes <br/>AQMed</p>
                    </OptionArea>
                </OptionsArea>
                <OptionsArea>
                    <OptionArea>
                        <ButtonOption type="manual" onClick={() => this.onSelectPlan('manual', 3)}>
                            <MdEmail style={{color: 'white', padding: 0, width: '50px', height: '46px'}}/>
                        </ButtonOption>
                        <p>Selecionar e-mails<br/>Manualmente</p>
                    </OptionArea>
                    <OptionArea>
                        <ButtonOption type="todos" onClick={() => this.onSelectPlan('todos', 4)}>
                            <BsFillPeopleFill style={{color: 'white', padding: 0, width: '50px', height: '46px'}}/>
                        </ButtonOption>
                        <p>Selecionar <br/>Todos</p>
                    </OptionArea>
                </OptionsArea>
            </>
        )
    }

    render(){
        return(
            <Modal isOpen={this.props.visible} size={'lg'}>
                <ModalHeader>
                    {this.state.step === 1 ? 
                        'Enviar E-mail' 
                        : 
                            (this.state.company_select.value == 'manual' ? 
                                'Enviar E-mail'
                                :
                                `Enviar E-mail para ${this.state.company_select.value == 'all' ? this.state.company_select.label + ' Empresas': this.state.company_select.label}`
                            )
                            
                    }
                </ModalHeader>
                    {this.state.step === 1 ? (
                        <>
                            <ModalBody className="modal-body" hidden={this.state.loading}>
                                <this.StepOne/>
                            </ModalBody>
                            <ModalFooter hidden={this.state.loading}>
                                <Button color="danger" onClick={() => this.onClose(false)}>Cancelar</Button>
                            </ModalFooter>
                        </>
                    ) : (
                        <>
                            <ModalBody className="modal-body" hidden={this.state.loading}>
                                {!this.props.email_current ?
                                    <>
                                        <FormGroup hidden={true}>
                                            <CustomInput 
                                                type="switch" 
                                                id="exampleCustomSwitch" 
                                                name="customSwitch"
                                                label="Escolher e-mail manualmente" 
                                                checked={this.state.email_manual}
                                                onChange={(e) => this.handleManual(e.target.checked)}
                                            />
                                        </FormGroup>
                                        <FormGroup hidden={this.state.hidden_select}>
                                            <Label>Para:</Label>
                                            <div hidden={!this.state.email_manual}>
                                                <InputText 
                                                    name="email_text"
                                                    type="text" 
                                                    placeholder="E-mail" 
                                                    onChange={this.handleChange}
                                                    value={this.state.email_text} 
                                                    invalid={this.state.email_text_invalid}
                                                />
                                                <Input hidden invalid={this.state.email_text_invalid} />
                                                <FormFeedback>Campo obrigatório</FormFeedback>
                                            </div>
                                            <div hidden={this.state.email_manual}>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={this.loadOptions}
                                                    defaultOptions
                                                    isMulti
                                                    isDisabled={this.state.all_users}
                                                    value={this.state.email} 
                                                    placeholder="E-mails"
                                                    onChange={(email) => this.setState({email})}
                                                    onInputChange={this.handleInputChange}
                                                />
                                                <Input hidden invalid={this.state.email_invalid} />
                                                <FormFeedback>Campo obrigatório</FormFeedback>
                                                <p style={{marginLeft: '22px'}} ><Input type="checkbox" onChange={(e) => this.onAll(e.target.checked)} checked={this.state.all_users}/>Todos os clientes</p>
                                            </div>
                                        </FormGroup>
                                        <FormGroup hidden={!this.state.all_users || this.state.company_select.value != 'aqpago' }>
                                            <Select
                                                className="react-select"
                                                options={list_planos}
                                                placeholder="Plano (opcional)"
                                                isSearchable={false}
                                                isDisabled={!this.state.all_users}
                                                onChange={(plano) => this.setState({plano})}
                                                value={this.state.plano}
                                                theme={theme => ({
                                                    ...theme
                                                })}
                                            />
                                            <Input hidden invalid={this.state.plano_invalid}/>
                                            <FormFeedback>Selecione o plano</FormFeedback>
                                        </FormGroup>
                                    </>
                                    :
                                    <>
                                        <FormGroup hidden={this.state.hidden_select}>
                                            <Label>Para:</Label>
                                            <div hidden={!this.state.email_manual}>
                                                <InputText 
                                                    name="email_text"
                                                    type="text" 
                                                    placeholder="E-mail" 
                                                    onChange={this.handleChange}
                                                    value={this.props.email_current} 
                                                    invalid={this.state.email_text_invalid}
                                                />
                                                <Input hidden invalid={this.state.email_text_invalid} />
                                                <FormFeedback>Campo obrigatório</FormFeedback>
                                            </div>
                                            <div hidden={this.state.email_manual}>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={this.loadOptions}
                                                    defaultOptions
                                                    isMulti
                                                    isDisabled={this.state.all_users}
                                                    value={this.state.email} 
                                                    placeholder="E-mails"
                                                    onChange={(email) => this.setState({email})}
                                                    onInputChange={this.handleInputChange}
                                                />
                                                <Input hidden invalid={this.state.email_invalid} />
                                                <FormFeedback>Campo obrigatório</FormFeedback>
                                                <p style={{marginLeft: '22px'}} ><Input type="checkbox" onChange={(e) => this.onAll(e.target.checked)} checked={this.state.all_users}/>Todos os clientes</p>
                                            </div>
                                        </FormGroup>
                                    </>
                                }
                                <FormGroup hidden={true}>
                                    <Label>empresas:</Label>
                                    <Select
                                        className="react-select"
                                        options={list_companys}
                                        placeholder="Empresas"
                                        isSearchable={false}
                                        //isDisabled={this.state.all_users}
                                        isMulti={true}
                                        onChange={(select_option) => this.setState({company_select: select_option})}
                                        value={this.state.company_select}
                                        theme={theme => ({
                                            ...theme
                                        })}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Select
                                        className="react-select"
                                        options={this.state.assinaturas_email}
                                        placeholder="Assinatura de e-mail"
                                        isSearchable={false}
                                        onChange={(assinatura_email) => this.setState({assinatura_email})}
                                        value={this.state.assinatura_email}
                                        theme={theme => ({
                                            ...theme
                                        })}
                                    />
                                    <Input hidden invalid={this.state.assinatura_email_invalid}/>
                                    <FormFeedback>Selecione a assinatura de e-mail</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <InputText 
                                        name="assunto"
                                        type="text" 
                                        placeholder="Assunto" 
                                        onChange={this.handleChange}
                                        value={this.state.assunto} 
                                        invalid={this.state.assunto_invalid}
                                    />
                                    <Input hidden invalid={this.state.assunto_invalid} />
                                    <FormFeedback>Campo obrigatório</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Card style={{minHeight: 250}}>
                                        <Editor
                                            initialEditorState={this.state.mensagem}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={this.handleChangeEmail}
                                            editorStyle={{
                                                fontSize: 12,
                                                fontFamily: "Arial"
                                            }}
                                        />
                                    </Card>
                                </FormGroup>
                                <FormGroup>
                                    <Dropzone onDrop={(drop) => this.onDrop(drop)}>
                                        {({ getRootProps, getInputProps }) => (
                                        <DropArea {...getRootProps({
                                            onClick: event => event.stopPropagation()
                                        })}>
                                            <input {...getInputProps()} />
                                                {this.state.file.length > 0 ? 
                                                    <>
                                                        <Row> 
                                                            {this.state.file.map((e, index) => {
                                                                return <Col key={index} md={4} style={{paddingBottom: 4}}>
                                                                    <Card>
                                                                        <p style={{margin: '3px'}}>
                                                                            <MdAttachFile style={{width: '32px'}}/>
                                                                            {e.name}&nbsp; 
                                                                            <Button 
                                                                                style={{padding: 0, border: 3, float: 'right'}} 
                                                                                color="danger" 
                                                                                outline 
                                                                                onClick={
                                                                                    () => this.removeAttach(index)
                                                                                }>
                                                                                    <MdClear style={{padding: 0}}/>
                                                                            </Button>
                                                                        </p>
                                                                    </Card>
                                                                </Col>
                                                            })}
                                                            <br/>
                                                        </Row>
                                                        <p style={{ margin: 0 }}>
                                                        <MdAttachFile style={{ width: '32px' }} />
                                                            Arraste arquivos ou&nbsp;
                                                            <ButtonUpload 
                                                                onClick={() => this.inputFile.current.click()} 
                                                                className="btn btn-dark" 
                                                                color="primary">
                                                                Clique Aqui
                                                            </ButtonUpload> para adicionar mais anexos
                                                            <input type="file" ref={this.inputFile} multiple hidden onChange={this.handleFileChange} />
                                                    </p>
                                                </>
                                                :
                                                <>
                                                    <p style={{ margin: 0 }}>
                                                        <MdAttachFile style={{ width: '32px' }} />
                                                            Arraste arquivos ou&nbsp;
                                                            <ButtonUpload 
                                                                onClick={() => this.inputFile.current.click()} 
                                                                className="btn btn-dark" 
                                                                color="primary">
                                                                Clique Aqui
                                                            </ButtonUpload> para adicionar anexos
                                                            <input type="file" ref={this.inputFile} multiple hidden onChange={this.handleFileChange} />
                                                    </p>
                                                </>
                                            }
                                        </DropArea>
                                    )}
                                    </Dropzone>
                                </FormGroup>
                            </ModalBody>
                            <div 
                                className="modal-body"
                                style={{
                                    minHeight: '114px', 
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignSelf: 'center',
                                    paddingBottom: '47px'
                                }} hidden={!this.state.loading}>
                                <ModelReturn 
                                    error_message={this.state.error_message}
                                    success_message={this.state.success_message}
                                    loading={this.state.loading}
                                    onClose={this.onClose}
                                    onError={this.onError.bind(this)}
                                    />
                            </div>
                            <ModalFooter hidden={this.state.loading}>
                                {this.props?.email_current ? (<>
                                    <Button color="danger" onClick={() => this.onCloseNew(false)}>Cancelar</Button>
                                </>) : (<>
                                    <Button color="danger" onClick={() => this.onBack()}>Voltar</Button>
                                </>)}
                                
                                <Button color="success" onClick={this.onRegister}>Salvar</Button>
                            </ModalFooter>
                        </>
                    )
                }
            </Modal>
        )
    }
}

export default SendEmail;