import axios from "axios";
import moment from "moment";
import { Component } from "react";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Nav, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import classnames from 'classnames';
import styled from "styled-components";
import { getUser } from "../../auth/set_services";
import { getRowsPerPage, getRows } from "../../components/Tables/TableQueroBus/propsOfertas";
import TableOfertas from "../../components/Tables/TableQueroBus/TableOfertas";
import PropagateLoader from "react-spinners/PropagateLoader";

const getColor = (status) => {
    switch(status){
        case 'DEVOLVIDO' : return 'danger';
        case 'CANCELADA' : return 'dark';
        case 'APROVADA' : return 'success';
        default : return 'info'
    }
}
const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
        padding: 0.5rem;
        border: 1px solid #ccc;
        margin: 0.2rem 0.3rem;
        border-radius: 5px;
    }
    .nav-tabs .nav-item.show .nav-link, 
    .nav-tabs .nav-link.active {
        background: #28a745;
        color: #fff;
    }
    .nav-link:hover{
        background: #70b580;
        color: #fff;
    }
    .nav-tabs {
        padding-bottom: 1rem;
    }
    p {
        margin-bottom: 0.5rem;
    }
`

const get_id = () => {
    return window.location.pathname.replace('/aqbank-lead/', '')
}

class ListaDeOfertas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            json_response: null,
            activeTab: "1",
            reservas: [],
            cities_origin: [],
            rowData: [],
            show_activity: false,
            show_filter: false,
            user_change: null,
            search: '',
            seller_id: '',
            valor_de: '0.00',
            valor_ate: '0.00',
            loading_table: true,
            edit_id: -1,
            edit_user: null,
            cpf_current: null,
            cnpj_current: null,
            phone_current: null,
            status_user: '',
            type_user: '',
            type: { value: 'p', label: 'Porcentagem'},
            limit: { value: '0', label: 'Não'},
            status: null,
            totalPage: 0,
            activePage: 1,
            is_cnpj: false,
            document: '',
            filter: false,
            date_accommodation: null,
            origin: [],
            origin_id: null,
            search_origin: null,
            destiny: [],
            destiny_id: null,
            visible_taggy_add: false,
            visible_user: false
        }
    }
    componentDidMount(){
        this.loadCitiesorigem();
        getRows(this.state, this.callbackRows.bind(this));
    }

    loadCitiesorigem = () => {
        let url = `https://apimeubus.aqbank.com.br/api/accomodation/search-city-origin-all`;

        if (this.state.search_origin) {
            url += `?search=${this.state.search_origin}`;
        } 
        
        let myInit = {
            method: 'GET',
            mode: 'cors',
            cache: 'default'
        };
    
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            p.data.map((city, i) => {
                this.state.cities_origin.push({
                    value: city.flag,
                    label: city.city,
                })
            })
        })
        .catch(() => {
          toast.error('Ocorreu um erro no servidor!');
        })
    }

    onFilter = (filter) => {
        this.setState({filter});
        getRows(this.state, this.callbackRows.bind(this));
    }

    callbackRows = (rowData, totalPage) => {
        this.setState({rowData, totalPage, loading_table: false})
    }

    onPageChange = (activePage) => {
        var state = this.state;

        state.activePage = activePage;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});

        getRowsPerPage(activePage, state, this.callbackRows.bind(this));
    }

    handleChange = (event) => {
        var state = this.state;
        let name = event.target.name;
        let value = event.target.value;

        state[name] = value;
        state.activePage = 1;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});
        
        getRowsPerPage(1, state, this.callbackRows);
            
    }
    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    onStatusChange(status_user){
        var state = this.state;
        state.status_user = status_user;
        state.rowData = [];
        state.loading_table = true;
        this.setState({...state});
        
        getRows(state, this.callbackRows);
    }
    filterShow(){
        this.setState({
            show_filter: !this.state.show_filter
        })
    }
    onChangeSelect = (name, value) => {
        var state = this.state;
        state.rowData = [];
        state.activePage = 1;
        state.loading_table = true;
        state[name] = value;
        this.setState({ ...state });

        getRows(state, this.callbackRows.bind(this));
    }

    handleTypeStatusChange = status => {
        var state = this.state;
        state['status'] = status;
        state.loading_table = true;
        this.setState({ ...state });
        getRows(state, this.callbackRows);
    };

    handletoastSuccessMessage = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    handletoastMessage = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    handleNewDealRedirect = (row) => {
        // console.log(row)
        // console.log({ 
        //     pathname: '/querobus-new-deal', 
        //     booking: row.search,
        //     date_accommodation: row.date_accommodation,
        //     origin: row.origin.id,
        //     destiny: row.destiny.id,
        //     deal_id: row.id,
        //     deal_type: row.type,
        //     deal_value: row.value,
        // }

        // );
        // return;

        this.props.history.push({ 
            pathname: '/querobus-new-deal', 
            booking: row.search,
            date_accommodation: row.date_accommodation,
            origin: row.origin.id,
            destiny: row.destiny.id,
            deal_id: row.id,
            deal_type: row.type,
            deal_value: row.value,
        });
    }

    render(){
        return(
            <Div>
                <Card hidden={!getUser().permissao_gestor_aqpago}>
                    <TableOfertas
                        isSearch={this.handleChange}
                        onFilter={this.onFilter}
                        onChangeSelect={this.onChangeSelect}
                        newDealRoute={this.handleNewDealRedirect.bind(this)}
                        filter={this.state.filter}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        data={this.state.rowData}
                        cities_origin={this.state.cities_origin}
                        date_accommodation={this.state.date_accommodation}
                        origin={this.state.origin}
                        origin_id={this.state.origin_id}
                        destiny={this.state.destiny}
                        destiny_id={this.state.destiny_id}
                        handleTypeStatusChange={this.handleTypeStatusChange}
                        status_user={this.state.status_user}
                        onStatusChange={this.onStatusChange.bind(this)}
                        type_user={this.state.type_user}
                        show_filter={this.state.show_filter}
                        filterShow={this.filterShow.bind(this)}
                        onPageChange={this.onPageChange}
                        activePage={this.state.activePage}
                        state={this.state}
                        totalPage={this.state.totalPage}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                {this.state.origin_id && this.state.destiny_id && this.state.date_accommodation ?
                                (<>Nenhuma passagem encontrada</>) : (<>Selecione Origem e Destino com a Data</>)}
                                
                            </div></CardBody></Card>
                        }
                    />
                </Card>
            </Div>
        )
    }
}

export default ListaDeOfertas;