import axios from "axios";
import React, { Component } from "react";
import { Button, Card, Col, FormGroup, Label, Modal, ModalFooter, ModalHeader, Input, Row, FormFeedback, CustomInput } from 'reactstrap';
import styled from "styled-components";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import { InputText } from '../../InputText/InputText';
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import Dropzone from 'react-dropzone';
import { MdAttachFile, MdClear } from "react-icons/md";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import { list_planos } from "../../../auth/listas";
import { FaCheck, FaExclamationTriangle, FaRegWindowClose } from "react-icons/fa";
import iconExclamation from '../../../assets/ico-exclamation.png';
import NumberFormat from "react-number-format";



const ModalBody = styled.div`
    .svg-inline--fa {
        width: 10em;
    }
    .svg-inline--fa.fa-w-14{
        width: 10em;
    }
    .prosemirror-toolbar-item{
        color: #52c565;
    }
    .prosemirror-toolbar-item[data-active='true'] {
        background-color: #52c565;
        color: #fff;
    }

    .rdw-option-wrapper{
        border: 0
    }
    .rdw-option-wrapper:active{
        background-color: #aaaaaa;
        box-shadow: none;
    }
    .rdw-option-wrapper:hover {
        background-color: #aaaaaa;
    }

    .rdw-dropdown-wrapper{
        border: 1px solid #ced4da;
    }
    .rdw-dropdown-wrapper:active{
        border: 1px solid #ced4da;
        box-shadow: none;
    }
    .rdw-dropdown-wrapper:hover{
        border: 1px solid #ced4da;
        box-shadow: none;
    }

    .rdw-dropdown-selectedtext{
        color: black
    }
    .rdw-dropdown-selectedtext:hover{
        text-decoration: none;
        color: black
    }
    .div-align {
        width: 40%;
        margin: 0px auto;
    }
    .div-align-text {
        text-align: center;
    }
    .modal-icon {
        width: 10rem;
        height: 8rem;
        padding: 1rem;
        border-radius: 50%;
        border: 5px solid #f1f3f1!important;
        box-shadow: 0px 3px 25px 0px rgb(113 106 202 / 20%);
    }
    h6 {
        display: block;
        position: relative;
        max-width: 100%;
        margin: 0 0 0.4em;
        padding: 0;
        color: #3b3f5c;
        font-size: 1.875em;
        font-weight: 600;
        text-align: center;
        text-transform: none;
        word-wrap: break-word; 
    }
    .span-info {
        justify-content: center;
        margin: 0;
        padding: 0;
        color: #e95f2b;
        font-size: 1.125em;
        font-weight: 300;
        line-height: normal;
    }
`;

class ConsultarSeller extends Component{
    constructor(props){
        super(props);
        this.state = {
            assinaturas_email: [],
        }
        this.onClose = this.onClose.bind(this); 
        //this.props = props; 
    }

    onClose(){
        this.props.onClose(false);
    }

    callback(){
        this.props.onClose(false);
        this.props.callback();
    }

    render(){
        return(
            <Modal isOpen={this.props.visible}  size={'md'}>
                <ModalBody className="modal-body" hidden={this.state.loading}>
                    <div className="div-align">
                        <div className="modal-icon">
                            <img src={iconExclamation} alt="logo" style={{height: '5rem', width: 'auto', position: 'relative', left: '-5px'}}/>
                        </div>
                    </div>
                    <div className="div-align-text">
                        <h6 style={{fontSize: '1.4rem'}}>{this.props.nome_comprador}</h6>
                        {this.props.cpf_comprador !== undefined ?
                        <p><NumberFormat displayType={'text'} value={this.props.cpf_comprador} format={this.props.cpf_comprador.length > 11 ? "CNPJ: ##.###.###/####-##" : "CPF: ###.###.###-##"}/></p>
                        :
                        <></>
                        }
                        
                        <span className="span-info">Consultar crédito do vendedor ?</span>
                    </div>
                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose(false)}><FaRegWindowClose /> Não Cancelar</Button>
                    <Button color="success" onClick={() => this.callback()}><FaCheck /> Sim Consultar</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ConsultarSeller;