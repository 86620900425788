import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Input, FormFeedback } from "reactstrap";
import Dropzone from 'react-dropzone';
import styled from "styled-components";
import { MdFileUpload } from "react-icons/md";
import { importData } from "../../../services/request_taggys";
import ModelReturn from "../ReturnsApiModals/ModelReturn";

const DropArea = styled.div`
    border-style: dashed;
    border-color: #8300b6;
    padding: 9%;
    text-align: center;
    cursor: pointer;
    &:drag {
        opacity: 0.5;
    }
`
class ImportTaggy extends Component {
    constructor(props){
        super(props);
        this.state = {
            file: null,
            file_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        }
        this.onRegister = this.onRegister.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }
    onDrop = (file) => {
        this.setState({
            file: file[0]
        })
    }
    validateFields(){
        var invalid = 0;

        if(this.state.file !== null){
            this.setState({
                file_invalid: false
            })
        }
        else{
            invalid++;
            this.setState({
                file_invalid: true
            })
        }

        return invalid;

    }
    onRegister() {
        if(this.validateFields() === 0){
            this.setState({loading: true});
            /* if(this.props.editing !== null){
                update(this.state, this.props.editing.id, this.onData.bind(this));
            }
            else{ */
                importData(this.state, this.onData.bind(this));
            //}
        }
    }
    onClose(){
        this.setState({
            file: null,
            file_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose(false);
    }
    onData(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }
    render(){
        return(
            <Modal isOpen={this.props.visible}>
                <ModalHeader>
                    Importar Taggy
                </ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <Dropzone onDrop={(drop) => this.onDrop(drop)}>
                        {({ getRootProps, getInputProps }) => (
                        <DropArea {...getRootProps()}>
                            <input {...getInputProps()} />
                            <MdFileUpload style={{width: '32px'}}/> 
                            {this.state.file !== null ? this.state.file.name : 'Arraste o arquivo'}
                        </DropArea>
                    )}
                    </Dropzone>
                    <Input
                        invalid={this.state.file_invalid}
                        hidden/>
                    <FormFeedback>Nenhum arquivo selecionado</FormFeedback>
                </ModalBody>
                <ModalBody 
                    style={{
                        minHeight: '114px', 
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn 
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                        />
                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose(false)}>Cancelar</Button>
                    <Button color="success" onClick={this.onRegister}>Cadastrar</Button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default ImportTaggy;