import React from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { columns } from './propsEstacionamento';
import debounce from 'lodash/debounce';
import { InputText } from '../../InputText/InputText';
import Select from 'react-select';

/* const access_type = [
    { value: "AC", label: "AC" },
    { value: "AL", label: "AL" },
    { value: "AP", label: "AP" },
    { value: "AM", label: "AM" },
    { value: "BA", label: "BA" },
    { value: "CE", label: "CE" },
    { value: "DF", label: "DF" },
    { value: "ES", label: "ES" },
    { value: "GO", label: "GO" },
    { value: "MA", label: "MA" },
    { value: "MT", label: "MT" },
    { value: "MS", label: "MS" },
    { value: "MG", label: "MG" },
    { value: "PA", label: "PA" },
    { value: "PB", label: "PB" },
    { value: "PR", label: "PR" },
    { value: "PE", label: "PE" },
    { value: "PI", label: "PI" },
    { value: "RJ", label: "RJ" },
    { value: "RN", label: "RN" },
    { value: "RS", label: "RS" },
    { value: "RO", label: "RO" },
    { value: "RR", label: "RR" },
    { value: "SC", label: "SC" },
    { value: "SP", label: "SP" },
    { value: "SE", label: "SE" },
    { value: "TO", label: "TO" }
]; */

export const TableEstacionamento = (props) => {
    return (
        <Card>
            <CardBody>
                <Row>
                    <Col>
                        <div style={{ textAlign: 'left' }}>
                            <InputText
                                name="search"
                                placeholder="Buscar"
                                onChange={debounce((event) => props.isSearch(event), 1000)} />
                        </div>
                    </Col>
                    <Col>
                        <input
                            type="radio"
                            name="filter"
                            checked={props.filter === 1}
                            onChange={() => props.onFilter(1)} />  Estacionamento &nbsp;&nbsp;
                        <input
                            type="radio"
                            name="filter"
                            checked={props.filter === 2}
                            onChange={() => props.onFilter(2)} /> Praça de Pedagio<br />

                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col md={4}>
                        <Select
                            className="react-select"
                            options={props.states}
                            placeholder="Estados"
                            isSearchable={false}
                            isClearable
                            value={props.state}
                            onChange={props.handleSelectChangeState}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0
                            })}
                        />
                    </Col>
                    <Col md={4}>
                        <Select
                            className="react-select"
                            options={props.cities}
                            placeholder="Cidade"
                            isSearchable={true}
                            isDisabled={props.state === null}
                            value={props.city}
                            onChange={props.handleSelectChangeCity}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0
                            })}
                        />
                    </Col>
                </Row>
            </CardBody>
            <DataTable
                wrap={true}
                noHeader={true}
                button={true}
                noDataComponent={props.noDataComponent}
                columns={columns()}
                data={props.data}
                pagination={true}
                theme="default"
            />
        </Card>
    )
}