import { Component } from "react";
import { Button, Card, CardBody } from "reactstrap";
import RemessaModal from "../../components/Modals/RemessaModal/RemessaModal";
import { TablePostagem } from "../../components/Tables/TablePostagem/TablePostagem";
import { getRows, getRowsPerPage } from '../../components/Tables/TableRemessa/propsRemessa';
import PropagateLoader from "react-spinners/PropagateLoader";
import moment from "moment";
import { TableRemessa } from "../../components/Tables/TableRemessa/TableRemessa";

class Logistica extends Component{
    constructor(props){
        super(props);
        this.state = {
            remessa_modal: false,
            loading_table: true,
            rowData: [],
            activePage: 1,
            status_envio: '',
            cpf_current: null,
            servico_entrega: null,
            status_entrega: null,
            remessa: null,
            cnpj_current: '',
            document: '',
            totalPage: 1,
            filter: false,
            is_cnpj: false,
            search: '',
            visible_contestacao: false,
            current_transacao: null,

            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
    }

    onClose = (remessa_modal = false) => {
        this.setState({ remessa_modal, remessa: null })
        if(remessa_modal == false){
            getRows(this.state, this.callbackRows.bind(this));
        }
    }
    openRemessa = (remessa_modal = false, remessa) => {
        console.log(remessa)
        this.setState({ remessa_modal, remessa })
    }
    componentDidMount() {
        getRows(this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }
    onFilter = (filter) => {
        this.setState({ filter, loading_table: true });
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }

    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    selectStatusEntrega = (status_entrega) => {
        var state = this.state;
        state.status_entrega = status_entrega;
        state.loading_table = true;
        state.rowData = [];
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    selectServicoEntrega = (servico_entrega) => {
        var state = this.state;
        state.rowData = [];
        state.servico_entrega = servico_entrega;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }
    render(){
        return(
            <div>
                <h3>Logística</h3>
                <hr />
                <Button color="success" onClick={() => this.onClose(true)}>Nova Remessa</Button>
                <br/><br/>
                <Card>
                    <TableRemessa
                        isSearch={this.handleChange}
                        data={this.state.rowData}
                        selectStatusEntrega={this.selectStatusEntrega}
                        selectServicoEntrega={this.selectServicoEntrega}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        is_cnpj={this.state.is_cnpj}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhuma remessa encontrada
                            </div></CardBody></Card>
                        }
                        openRemessa={this.openRemessa}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <RemessaModal
                    visible={this.state.remessa_modal}
                    onClose={this.onClose}
                    remessa={this.state.remessa}
                />
                {/* <h4>Em construção</h4> */}
            </div>
        )
    }
}

export default Logistica;