
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { Card, CardBody, Row, Col, Input, CardFooter, Collapse, Button, Label } from 'reactstrap';
import { columns } from './propsFormasPagamento';
import debounce from 'lodash/debounce';
import Pagination from '../../Pagination';
import { InputText } from '../../../InputText/InputText';
import InputMask from 'react-input-mask';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import Select from 'react-select';
import { ENDPOINT } from '../../../../auth/endpoint';
import { getToken } from '../../../../auth/set_services';

export const TableFormasPagamento = (props) => {
    return (
        <Card>
            <CardBody>
                <Row>
                    <Col md={4}>
                        <Select
                            placeholder="Tipo de pagamento"
                            options={props.options.tipo_pagamento_options}
                            isClearable
                            value={props.state.tipo_pagamento}
                            onChange={(value) => props.onChangeSelect('tipo_pagamento', value)}
                        />
                    </Col>
                    <Col md={4}>
                        <Select
                            placeholder="Aplicável"
                            options={props.options.aplicavel_options}
                            isClearable
                            value={props.state.aplicavel}
                            onChange={(value) => props.onChangeSelect('aplicavel', value)}
                        />
                    </Col>
                    <Col md={4}>
                        <Select
                            placeholder="Taxa"
                            options={props.options.tipo_taxa_options}
                            isClearable
                            value={props.state.tipo_taxa}
                            onChange={(value) => props.onChangeSelect('tipo_taxa', value)}
                        />
                    </Col>
                </Row>
                <br/>
            </CardBody>
            <hr style={{ marginBottom: '4px' }} />
            <DataTable
                noHeader={true}
                button={true}
                noDataComponent={props.noDataComponent}
                columns={columns(props.options, props.editFormaPagamento, props.removeFormaPagamento)}
                data={props.data}
                theme="default"
            />
            <CardFooter style={{ textAlign: 'center' }}>
                <Pagination
                    onPageChange={props.onPageChange}
                    activePage={props.activePage}
                    totalPage={props.totalPage}
                />
            </CardFooter>
        </Card>
    )
}