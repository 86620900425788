import styled from "styled-components";

const ButtonD = styled.button`
    font-size: 13px;
    border: 2px solid #0eb100;
    margin: 2px;
    background-color: #ffffff;
    color: #38af54;
    border-radius: 20px;
    margin-top: 5px;
    &:hover{
    background-color: #0eb100;
    color: #ffffff;
    }
    &:focus{
    box-shadow: 0 0 0.3em #0eb100;
    }
`

export const ButtonGreen = (props) => {
    return(
        <ButtonD
            type="button"
            {...props}
        />
    )

}