import React from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, Row, Col, Input, CardFooter } from 'reactstrap';
import { columns } from './propsRemessa';
import debounce from 'lodash/debounce';
import Pagination from '../Pagination';
import { InputText } from '../../InputText/InputText';
import { InputMasked } from '../../InputText/InputMasked';
import Select from "react-select";

const status_envio_list = [
    {  value: 'Aguardando Entrega', label: "Aguardando Entrega", value2: 'entrega' },
    {  value: 'Pedido Enviado', label: "Pedido Enviado", value2: 'enviado' },
    {  value: 'Pedido Entregue', label: "Pedido Entregue", value2: 'entregue' },
    {  value: 'Pedido Devolvido', label: "Pedido Devolvido", value2: 'devolvido' },
    {  value: 'Aguardando Reversa', label: "Aguardando Reversa", value2: 'entrega' },
    {  value: "Reversa Em Trânsito", label: "Reversa Em Trânsito", value2: 'enviado' },
    {  value: 'Reversa Recebida', label: "Reversa Recebida", value2: 'entregue' },
    {  value: 'Prioridade Entrega Ouvidoria', label: "Prioridade Entrega Ouvidoria", value2: null },
    {  value: 'Prioridade Entrega Reclamação', label: "Prioridade Entrega Reclamação", value2: null },
    {  value: 'Pendente Devolver Máquina', label: "Pendente Devolver Máquina", value2: null },
    {  value: 'Pendente Troca de Garantia', label: "Pendente Troca de Garantia", value2: null },
    {  value: 'Pedido Entrega Extraviada', label: "Pedido Entrega Extraviada", value2: null }
  ];

const transporte_list = [
    {   value: 'aqenvio', label: 'AQEnvio'  },
    {   value: 'correios', label: 'Correios'  },
    {   value: 'totalexpress', label: 'Total Express'  },
];


export const TableRemessa = (props) => {
    return (
        <Card>
            <CardBody>
                <Row >
                    <Col md={5}>
                        <div style={{ textAlign: 'left' }}>
                            <InputText
                                name="search"
                                placeholder="Buscar"
                                onChange={debounce((event) => props.isSearch(event), 1000)} />
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={4}>
                        <div style={{ textAlign: 'left' }}>
                            <InputMasked
                                name="document"
                                mask={props.is_cnpj === false ? "999.999.999-99" : "99.999.999/9999-99"}
                                placeholder={props.is_cnpj === false ? "CPF" : "CNPJ"}
                                onChange={debounce((event) => props.isSearch(event), 500)}
                                />
                        </div>
                        </Col>
                        <Col md={6}>
                        <div style={{marginTop: '17px'}}>
                            &nbsp;&nbsp;<input 
                                type="checkbox"
                                checked={props.is_cnpj}
                                onChange={() => props.onSearchDocument()}/> Pesquisar CNPJ</div>
                    </Col>
                </Row>
                <br />
                {/* <Row>
                    <Col md={6}>
                        <div style={{ textAlign: 'left' }}>
                            <InputText
                                name="status_envio"
                                placeholder="Status de Envio"
                                defaultValue={props.status_envio}
                                onChange={debounce((event) => props.isSearch(event), 500)}
                        />
                        </div>
                        </Col>
                </Row>
                <br /> */}
                <Row>
                    <Col md={6}>
                        <div style={{ textAlign: 'left' }}>
                            <Select
                                isSearchable={false}
                                isClearable
                                options={status_envio_list}
                                placeholder="Filtrar por Status de entrega"  
                                onChange={props.selectStatusEntrega}  
                            />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div style={{ textAlign: 'left' }}>
                            <Select
                                isSearchable={false}
                                options={transporte_list}
                                isClearable
                                placeholder="Filtrar por Serviço de entrega"  
                                onChange={props.selectServicoEntrega}  
                            />
                        </div>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col md={5}>
                            <input
                                type="checkbox"
                                checked={props.filter}
                                name="filter"
                                onChange={(value) => props.onFilter(value.target.checked)} /> Filtrar por data de alteração:
                    </Col>
                </Row>
                <Row>
                    <Col>De:
                    <Input
                            name="date_from"
                            type="date"
                            //disabled
                            disabled={!(props.filter === true)}
                            value={props.date_from}
                            onChange={(event) => props.isSearch(event)} /></Col>
                    <Col>Até:
                    <Input
                            name="date_to"
                            type="date"
                            disabled={!(props.filter === true)}
                            //disabled
                            value={props.date_to}
                            onChange={(event) => props.isSearch(event)} /></Col>
                </Row>
            </CardBody>
            <hr style={{ marginBottom: '4px' }} />
            <DataTable
                noHeader={true}
                button={true}
                noDataComponent={props.noDataComponent}
                columns={columns(props.openRemessa)}
                data={props.data}
                theme="default"
            />
            <CardFooter style={{ textAlign: 'center' }}>
                <Pagination
                    onPageChange={props.onPageChange}
                    activePage={props.activePage}
                    totalPage={props.totalPage}
                />
            </CardFooter>
        </Card>
    )
}