import React, { Component } from 'react';
import { Button, ModalFooter, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalHeader, Row, Collapse } from 'reactstrap';
import { FaCarSide, FaMotorcycle, FaShuttleVan, FaTruck } from 'react-icons/fa';
import Switch from 'react-switch';
import NumberFormat from "react-number-format";
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import { ENDPOINT } from '../../../auth/endpoint';
import axios from 'axios';
import { getToken } from '../../../auth/set_services';
import { InputText } from '../../InputText/InputText';
import Select from 'react-select';
import { InputMasked } from '../../InputText/InputMasked';

const get_marcas = (category, callback) => {
    var marca_id = 1;
    if (category === 'onibus') {
        marca_id = 1;
    }
    else if (category === 'caminhao') {
        marca_id = 2;
    }
    else if (category === 'rodagem_simples') {
        marca_id = 2;
    }
    else if (category === 'motorcycle') {
        marca_id = 3;
    }

    var url = ENDPOINT+"fipe/marcas/"+marca_id;
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        callback(data);
        return data;
    })
    .catch((error) => {
        callback([]);
    });
}

const get_modelos = (state, callback, callback_years) => {
    var marca_id = 1;
    if (state.category === 'onibus') {
        marca_id = 1;
    }
    else if (state.category === 'caminhao') {
        marca_id = 2;
    }
    else if (state.category === 'rodagem_simples') {
        marca_id = 2;
    }
    else if (state.category === 'motorcycle') {
        marca_id = 3;
    }

    var url = ENDPOINT+"fipe/modelos/"+marca_id+'?codigo_marca='+state.manufacturer.value;
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        callback(data);
        callback_years(data);
        return data;
    })
    .catch((error) => {
        callback({Modelos: []});
        callback_years({Anos: []});
    });
}

export default class AddTaggy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serial: '',
            serial_invalid: false,
            plate: '',
            plate_invalid: false,
            is_pap: false,
            armored: false,
            is_motorcycle: false,
            loading_models: false,
            loading_marcas: false,
            list_marcas: [],
            list_modelos: [],
            list_years: [],
            model: null,
            model_invalid: false,
            manufacturer: null,
            manufacturer_invalid: false,
            year: null,
            year_invalid: false,
            axes: 0,
            axes_invalid: false,
            category: null,
            category_invalid: false,
            type_vehicle: '',
            type_vehicle_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        }
        this.onCategory = this.onCategory.bind(this);
        this.onData = this.onData.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onType = this.onType.bind(this);
        this.register = this.register.bind(this);
        this.onRegister = this.onRegister.bind(this);
    }
    componentWillUpdate(nextProps, nextState) {
        if (nextProps.taggy !== null && nextProps.taggy !== this.props.taggy) {
            this.setState({
                serial: nextProps.taggy
            })
        }
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    onRegister() {
        if (this.validateFields() === 0) {
            this.setState({ loading: true });
            if (this.props.edit_id !== null) {
                this.register();
            }
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({ [name]: value });
    }
    onCategory(category) {
        this.setState({
            type_vehicle: '',
            category,
            list_marcas: [],
            list_modelos: [],
            list_years: [],
            manufacturer: null,
            model: null,
            year: null,
            loading_marcas: true
        });
        get_marcas(category, this.callbackListMarcas.bind(this))
    }
    callbackListMarcas(marcas){
        var rows = [];
        marcas.forEach(p => {
            var json = {value: p.Value, label: p.Label};
            rows.push(json);
        });

        this.setState({list_marcas: rows, loading_marcas: false});
    }
    onType(type_vehicle) {
        this.setState({
            type_vehicle
        })
    }
    onClose() {
        if (this.props.taggy === null) {
            this.setState({
                serial: '',
            })
        }
        this.setState({
            serial_invalid: false,
            plate: '',
            plate_invalid: false,
            is_pap: false,
            armored: false,
            is_motorcycle: false,
            loading_models: false,
            loading_marcas: false,
            list_marcas: [],
            list_modelos: [],
            list_years: [],
            model: null,
            model_invalid: false,
            manufacturer: null,
            manufacturer_invalid: false,
            year: null,
            year_invalid: false,
            axes: 0,
            axes_invalid: false,
            category: null,
            category_invalid: false,
            type_vehicle: '',
            type_vehicle_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose(false);
    }
    handleMarcaChange = value => {
        var state = this.state;
        state.manufacturer = value;
        state.loading_models = true;
        this.setState({ ...state });

        get_modelos(state, this.callbackListModelos.bind(this), this.callbackListAnos.bind(this));
    };
    handleModelChange = value => {
        var state = this.state;
        state.model = value;
        this.setState({ ...state });
    }
    handleYearChange = value => {
        var state = this.state;
        state.year = value;
        this.setState({ ...state });
    }
    callbackListModelos(modelos){
        var rows = [];
        modelos.Modelos.forEach(p => {
            var json = {value: p.Value, label: p.Label};
            rows.push(json);
        });
        this.setState({list_modelos: rows, loading_models: false});
    }

    callbackListAnos(anos){
        var rows = [];
        anos.Anos.forEach(p => {
            var json = {value: p.Value, label: p.Label};
            rows.push(json);
        });
        this.setState({list_years: rows});
    }

    register = () => {
        this.setState({ loading: true })
        var url = ENDPOINT + "taggys/store";
        var formData = new FormData();
        formData.append('serial', this.state.serial.replace(/\s/g, ''));
        formData.append('placa', this.state.plate);
        //formData.append('grupo_faturamento_codigo', 'GF_a26efccf');
        formData.append('grupo_faturamento_codigo', 'GF_4d67bbc1');
        formData.append('pointToPoint', Number(this.state.is_pap));
        formData.append('blindado', Number(this.state.armored));
        formData.append('bloqueado', 1);
        formData.append('modelo', this.state.model.label);
        formData.append('veiculo_fabricante', this.state.manufacturer.label);
        formData.append('modelo_ano',  Number(this.state.year.value.substr(0, 4)));
        formData.append('producao_ano',Number(this.state.year.value.substr(0, 4)));
        formData.append('status_uso', 'recarga');
        formData.append(this.props.type_user, this.props.edit_id);


        var categoriaCodigo = 0;
        var categoria = '';
        var axes = 0;

        if (this.state.type_vehicle === 'onibus') {
            if (Number(this.state.axes) === 2) {
                categoriaCodigo = 410
                categoria = 'Ônibus com rodagem dupla - 2 eixos'
            }
            if (Number(this.state.axes) === 3) {
                categoriaCodigo = 411
                categoria = 'Ônibus com rodagem dupla - 3 eixos'
            }
            if (Number(this.state.axes) === 4) {
                categoriaCodigo = 412
                categoria = 'Ônibus com rodagem dupla - 4 eixos'
            }
        }
        if (this.state.category === 'caminhao') {
            if (Number(this.state.axes) === 2) {
                categoriaCodigo = 305
                categoria = 'Caminhão Leve e Caminhão Trator - 2 eixos'
            }
            if (Number(this.state.axes) === 3) {
                categoriaCodigo = 306
                categoria = 'Caminhão, Caminhão Trator e Caminhão Trator com Semireboque - 3 eixos'
            }
            if (Number(this.state.axes) === 4) {
                categoriaCodigo = 307
                categoria = 'Caminhão com Reboque e Caminhão com Semireboque - 4 eixos'
            }
            if (Number(this.state.axes) === 5) {
                categoriaCodigo = 308
                categoria = 'Caminhão com Reboque e Caminhão com Semireboque - 5 eixos'
            }
            if (Number(this.state.axes) === 6) {
                categoriaCodigo = 309
                categoria = 'Caminhão com Reboque e Caminhão com Semireboque - 6 eixos'
            }
            if (Number(this.state.axes) === 7) {
                categoriaCodigo = 313
                categoria = 'Caminhão com Reboque - 7 eixos'
            }
            if (Number(this.state.axes) === 8) {
                categoriaCodigo = 314
                categoria = 'Caminhão com Reboque - 8 eixos'
            }
            if (Number(this.state.axes) === 9) {
                categoriaCodigo = 315
                categoria = 'Caminhão com Reboque - 9 eixos'
            }
        }
        if (this.state.type_vehicle === 'rodagem_simples') {

            if (Number(this.state.axes) === 2) {
                categoriaCodigo = 101
                categoria = 'Automóveis e Utilitários de rodagem simples - 2 eixos'
            }
            if (Number(this.state.axes) === 3) {
                categoriaCodigo = 102
                categoria = 'Automóveis e Utilitários de rodagem simples - 3 eixos'
            }
            if (Number(this.state.axes) === 4) {
                categoriaCodigo = 103
                categoria = 'Automóvel com reboque e Utilitários com reboque - 4 eixos'
            }
        }
        if (this.state.category === 'motorcycle') {
            axes = 2
            categoriaCodigo = 504
            categoria = 'Furgão de rodagem dupla - 2 eixos'
        }
        if (this.state.type_vehicle === 'rodagem_dupla') {
            axes = 2
            categoriaCodigo = 205
            categoria = 'Furgão de rodagem dupla - 2 eixos'
        }

        if (this.state.category === 'passeio') {
            axes = 2
            categoriaCodigo = 205
            categoria = 'Furgão de rodagem dupla - 2 eixos'
        }


        formData.append('eixos', axes === 0 ? this.state.axes : axes);
        formData.append('categoria_codigo', categoriaCodigo);
        formData.append('categoria_nome', categoria);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                //setData(data.access_token, JSON.stringify(data.user));
                this.onData(data);
                return data;
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    this.onData(obj);
                    return obj;
                }
                var data = error.response.data;
                this.onData(data);
                return data;
            });

    }
    onData(data) {
        if (data.success === true) {
            this.setState({
                success_message: data.message
            })
        }
        else {
            this.setState({
                error_message: data.errors
            })
        }
    }

    validateFields() {
        var invalid = 0;

        if (this.state.serial.length > 15) {
            this.setState({
                serial_invalid: false
            })
        }
        else {
            invalid++;
            this.setState({
                serial_invalid: true
            })
        }

        if (this.state.plate.length > 6) {
            this.setState({
                plate_invalid: false
            })
        }
        else {
            invalid++
            this.setState({
                plate_invalid: true
            })
        }
        if (this.state.model !== null) {
            this.setState({
                model_invalid: false
            })
        }
        else {
            invalid++
            this.setState({
                model_invalid: true
            })
        }

        if (this.state.manufacturer !== null) {
            this.setState({
                manufacturer_invalid: false
            })
        }
        else {
            invalid++
            this.setState({
                manufacturer_invalid: true
            })
        }

        if (this.state.year !== null) {
            this.setState({
                year_invalid: false
            })
        }
        else {
            invalid++
            this.setState({
                year_invalid: true
            })
        }

        if (this.state.category !== null) {
            this.setState({
                category_invalid: false
            });


            if (this.state.category !== 'passeio') {
                if (this.state.type_vehicle.length > 0) {
                    this.setState({
                        type_vehicle_invalid: false
                    });
                    if (this.state.type_vehicle !== 'rodagem_dupla') {
                        if (Number(this.state.axes) > 0) {
                            this.setState({
                                axes_invalid: false
                            })
                        }
                        else {
                            invalid++
                            this.setState({
                                axes_invalid: true
                            })
                        }
                    }
                }
                else {
                    invalid++
                    this.setState({
                        type_vehicle_invalid: true
                    })
                }
            }
        }
        else {
            invalid++
            this.setState({
                category_invalid: true
            })
        }
        return invalid;

    }
    render() {
        return (
            <Modal isOpen={this.props.visible} size={'lg'}>
                <ModalHeader>Ativar Taggy</ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <Form>
                        <FormGroup>
                            <h5>&nbsp;&nbsp;DADOS BÁSICOS</h5>
                        </FormGroup>
                        <FormGroup>
                            <NumberFormat
                                customInput={InputText}
                                name="serial"
                                value={this.state.serial}
                                type="text"
                                placeholder="Numero de Serial"
                                invalid={this.state.serial_invalid}
                                onChange={this.handleChange}
                                format="#### #### #### ####"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col style={{ maxWidth: '34%' }} md={5}>
                                    &nbsp;Ponto a Ponto (PaP):
                                </Col>
                                <Col style={{ padding: 0, paddingTop: '3px' }}>
                                    <Switch
                                        height={15}
                                        width={37}
                                        className="react-switch"
                                        id="material-switch"
                                        checked={this.state.is_pap}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        onColor="#ff86c5"
                                        onHandleColor="#e62689"
                                        onChange={() => this.setState({ is_pap: !this.state.is_pap })}
                                        handleDiameter={20}
                                        uncheckedIcon={false}
                                        checkedIcon={false} />
                                </Col>
                            </Row>
                        </FormGroup>
                        <hr />
                        <FormGroup>
                            <h5>&nbsp;&nbsp;VEÍCULO</h5>
                        </FormGroup>
                        <Row>
                            <Col>
                                <Row>
                                    <Col style={{ textAlign: 'center' }}>
                                        <FaCarSide style={{ width: 75, height: 75 }} />
                                        <br /> Passeio<br />
                                        <input
                                            type="radio"
                                            checked={this.state.category === 'passeio'}
                                            onChange={() => this.onCategory('passeio')} />
                                    </Col>
                                    <Col style={{ textAlign: 'center' }}>
                                        <FaShuttleVan style={{ width: 75, height: 75 }} />
                                        <br /> Van <br />
                                        <input
                                            type="radio"
                                            checked={this.state.category === 'van'}
                                            onChange={() => this.onCategory('van')} />
                                    </Col>
                                    <Col style={{ textAlign: 'center' }}>
                                        <FaTruck style={{ width: 75, height: 75 }} />
                                        <br /> Outros<br />
                                        <input
                                            type="radio"
                                            checked={this.state.category === 'outros'}
                                            onChange={() => this.onCategory('outros')} />
                                    </Col>
                                    <Col style={{ textAlign: 'center' }}>
                                        <FaMotorcycle style={{ width: 75, height: 75 }} />
                                        <br /> Moto<br />
                                        <input
                                            type="radio"
                                            checked={this.state.category === 'motorcycle'}
                                            onChange={() => this.onCategory('motorcycle')} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <FormGroup>
                            <InputMasked
                                placeholder="Placa do Veículo"
                                name="plate"
                                mask={'*******'}
                                value={this.state.plate}
                                type="text"
                                invalid={this.state.plate_invalid}
                                onChange={this.handleChange} />
                        </FormGroup>

                        <Collapse isOpen={this.state.category === 'van'}>
                            <Card>
                                <CardBody>
                                    Tipo de Veículo
                                            <hr />
                                    <Row>
                                        <Col>
                                            <input
                                                type="radio"
                                                name="van_type"
                                                checked={this.state.type_vehicle === 'rodagem_simples'}
                                                onChange={() => this.onType('rodagem_simples')} />&nbsp;Rodagem Simples

                                                </Col>
                                        <Col>
                                            <input
                                                type="radio"
                                                name="van_type"
                                                checked={this.state.type_vehicle === 'rodagem_dupla'}
                                                onChange={() => this.onType('rodagem_dupla')} />&nbsp;Rodagem Dupla
                                                </Col>
                                        <Col md={3}>
                                            <NumberFormat
                                                customInput={InputText}
                                                hidden={this.state.type_vehicle !== 'rodagem_simples'}
                                                name="axes"
                                                value={this.state.axes}
                                                type="text"
                                                placeholder="Eixos"
                                                invalid={this.state.axes_invalid}
                                                onChange={this.handleChange}
                                                format="#"
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Collapse>
                        <Collapse isOpen={this.state.category === 'outros'}>
                            <Card hidden={this.state.category !== 'outros'}>
                                <CardBody>
                                    Tipo de Veículo
                                            <hr />
                                    <Row>
                                        <Col>
                                            <input
                                                type="radio"
                                                name="other_type"
                                                checked={this.state.type_vehicle === 'onibus'}
                                                onChange={() => this.onType('onibus')} />&nbsp;Ônibus

                                                </Col>
                                        <Col>
                                            <input
                                                type="radio"
                                                name="other_type"
                                                checked={this.state.type_vehicle === 'caminhao'}
                                                onChange={() => this.onType('caminhao')} />&nbsp;Caminhão

                                                </Col>
                                        <Col md={3}>
                                            <NumberFormat
                                                customInput={InputText}
                                                hidden={this.state.type_vehicle === ''}
                                                name="axes"
                                                value={this.state.axes}
                                                type="text"
                                                placeholder="Eixos"
                                                invalid={this.state.axes_invalid}
                                                onChange={this.handleChange}
                                                format="#"
                                            />
                                            <FormFeedback hidden={this.state.type_vehicle === ''}>O Eixo deve ser definido</FormFeedback>
                                        </Col>
                                    </Row>
                                    <Input hidden invalid={this.state.type_vehicle_invalid} />
                                    <FormFeedback>O Tipo de Veículo deve ser selecionado</FormFeedback>
                                </CardBody>
                            </Card>
                        </Collapse>
                        <hr />
                        <FormGroup>
                            <h5>&nbsp;&nbsp;CATEGORIA DO VEÍCULO</h5>
                        </FormGroup>
                        <FormGroup>
                            <Select
                                className="react-select"
                                options={this.state.list_marcas}
                                isDisabled={this.state.category !== null ? false : true}
                                isLoading={this.state.loading_marcas}
                                placeholder="Marca do Veículo"
                                value={this.state.manufacturer}
                                isSearchable={true}
                                onChange={this.handleMarcaChange}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0
                                })}
                            />
                            <Input hidden invalid={this.state.manufacturer_invalid}/>
                            <FormFeedback>Selecione a marca do Veículo</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Select
                                className="react-select"
                                isDisabled={this.state.manufacturer !== null ? false : true}
                                options={this.state.list_modelos}
                                placeholder="Modelo do Veículo"
                                isLoading={this.state.loading_models}
                                isSearchable={true}
                                value={this.state.model}
                                onChange={this.handleModelChange}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0
                                })}
                            />
                            <Input hidden invalid={this.state.model_invalid}/>
                            <FormFeedback>Selecione o modelo do Veículo</FormFeedback>
                        </FormGroup>
                        <Row>
                            {/* <Col>
                                <FormGroup>
                                    <InputText
                                        placeholder="Marca do Veículo"
                                        name="manufacturer"
                                        value={this.state.manufacturer}
                                        type="text"
                                        invalid={this.state.manufacturer_invalid}
                                        onChange={this.handleChange} />
                                </FormGroup>

                            </Col> */}
                            <Col>
                                <FormGroup>
                                    <Select
                                        className="react-select"
                                        isDisabled={this.state.manufacturer !== null ? false : true}
                                        options={this.state.list_years}
                                        placeholder="Ano"
                                        isLoading={this.state.loading_models}
                                        isSearchable={true}
                                        value={this.state.year}
                                        onChange={this.handleYearChange}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 0
                                        })}
                                    />
                                    <Input hidden invalid={this.state.year_invalid}/>
                                    <FormFeedback>Selecione o ano do Veículo</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Row>
                                <Col style={{ maxWidth: '37%' }} md={5}>
                                    &nbsp;O Veículo é blindado?:
                                                </Col>
                                <Col style={{ padding: 0, paddingTop: '3px' }}>
                                    <Switch
                                        height={15}
                                        width={37}
                                        className="react-switch"
                                        id="material-switch"
                                        checked={this.state.armored}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        onColor="#ff86c5"
                                        onChange={() => this.setState({ armored: !this.state.armored })}
                                        onHandleColor="#e62689"
                                        handleDiameter={20}
                                        uncheckedIcon={false}
                                        checkedIcon={false} />
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalBody
                    style={{
                        minHeight: '114px',
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                    />
                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose(false)}>Cancelar</Button>
                    <Button color="success" onClick={this.onRegister}>Cadastrar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}