import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';

export const columns = () => [
    {
      name: "Operador",
      selector: "admin",
      sortable: false,
      width: '150px',
      cell: row => row.users !== null ? row.users.name : 'Sem Informações'
    },
    {
      name: "Titulo",
      selector: "title",
      sortable: false,
      width: '20%',
      cell: row => row.title !== null ? row.title : 'Sem Informações'
    },
    {
      name: "Detalhes",
      selector: "detalhe",
      sortable: false,
      width: '25%',
      cell: row => row.detalhe !== null ? row.detalhe : 'Sem Informações'
    },
    {
      name: "Motivo",
      selector: "motivo",
      sortable: false,
      width: '15%',
      cell: row => row.motivo !== null ? row.motivo : 'Sem Informações'
    },
    {
      name: "CPF",
      selector: "cpf",
      sortable: false,
      width: '150px',
      cell: row => row.cpf !== null ? row.cpf : 'Sem Informações'
    },
    {
      name: 'CNPJ',
      selector: 'cnpj',
      sortable: false,
      width: '150px',
      cell: row => row.cnpj !== null ? row.cnpj : 'Sem Informações'
    },
    {
      name: "Valor",
      selector: "valor",
      sortable: false,
      width: '150px',
      cell: row => row.valor !== null ? "R$ "+parseFloat(row.valor).toFixed(2) : 'Sem Informações'
    }
];

export const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "tarefas/list";

    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&cpf="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&cnpj="+document;
        }
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    
    var url = ENDPOINT + "tarefas/list?search="+search+"&page="+page;

    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&cpf="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&cnpj="+document;
        }
    }


    if(filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}