import { MdTimer } from "react-icons/md";
import StarRating from 'react-star-ratings';

const getTime = (date) => {
    if(new Date(date) > new Date()){
      return <div style={{color: 'green', fontWeight: '700'}}>&nbsp;&nbsp;<MdTimer /> {new Date(date).toLocaleString()}</div>
    }
  
    return <div style={{color: 'red', fontWeight: '700'}}>&nbsp;&nbsp;<MdTimer style={{padding: 0, width: '18px'}}/> {new Date(date).toLocaleString()}</div>
}
export const Resumo = (props) => {
    return (
        <div style={{lineHeight: '40px', padding: '15px'}}>
            <h5>Informações do ticket</h5>
            <hr />
            {props.protocolo_ocorrencia.toString().length > 0 ? 
                <p style={{margin: 0}}><b>Protocolo da ocorrencia: </b>{props.protocolo_ocorrencia}</p>
            : <></>}
            <p style={{margin: 0}}><b>Protocolo: </b>{props.protocolo}</p>
            <p style={{margin: 0}}><b>Assunto: </b>{props.assunto}</p>
            <p style={{margin: 0}}><b>Nome do Cliente: </b>{props.nome_cliente}</p>
            <p style={{margin: 0}}><b>E-mail: </b>{props.email}</p>
            <p style={{margin: 0}}><b>Telefone: </b>{props.telefone}</p>
            <p style={{margin: 0}}><b>Documento: </b>{props.documento}</p>
            <p style={{margin: 0}}><b>Departamento: </b>{props.departamento}</p>
            <p style={{margin: 0}}><b>Status: </b>{props.status}</p>
            {props.funcionario !== null ? 
                <p style={{margin: 0}}><b>Funcionario Atribuído: </b>{props.funcionario.name}</p>
            : <></>}
            <p style={{margin: 0, display: 'flex'}}><b>Validade do Ticket: </b>{getTime(props.validade)}</p>
            {props.avaliacao !== null ? 
                <p style={{margin: 0}}><b>Avaliação: </b>
                <div style={{position: 'relative', top: '-3px', display: 'inline'}}><StarRating 
                    totalStars={5} 
                    starSpacing="2px"
                    starDimension='35px'
                    starRatedColor="#ccb00f"
                    rating={props.avaliacao}
                /></div></p>
            : <></>}
        </div>
    )
}