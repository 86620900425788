import { Component } from "react";
import { Button, Card, CardBody } from "reactstrap";
import RemessaModal from "../../components/Modals/RemessaModal/RemessaModal";
import { getRows, getRowsPerPage } from "../../components/Tables/TableSolicitacaoPlano/propsSolicitacaoPlano";
import { TableSolicitacaoPlano } from "../../components/Tables/TableSolicitacaoPlano/TableSolicitacaoPlano";
import PropagateLoader from "react-spinners/PropagateLoader";
import moment from "moment";

class SolicitacaoPlano extends Component{
    constructor(props){
        super(props);
        this.state = {
            remessa_modal: false,
            loading_table: true,
            rowData: [],
            activePage: 1,
            status_envio: '',
            cpf_current: null,
            status_migracao: null,
            status_pagamento: null,
            cnpj_current: '',
            document: '',
            totalPage: 1,
            filter: false,
            is_cnpj: false,
            search: '',
            visible_contestacao: false,
            current_transacao: null,

            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
    }

    onClose = (remessa_modal = false) => {
        this.setState({ remessa_modal })
    }
    componentDidMount() {
        getRows(this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }
    onFilter = (filter) => {
        this.setState({ filter, loading_table: true });
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }

    onSearchDocument(){
        this.setState({is_cnpj: !this.state.is_cnpj})
    }
    selectStatusPagamento = (status_pagamento) => {
        var state = this.state;
        state.status_pagamento = status_pagamento;
        state.loading_table = true;
        state.rowData = [];
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    selectStatusMigracao = (status_migracao) => {
        var state = this.state;
        state.rowData = [];
        state.status_migracao = status_migracao;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }
    render(){
        return(
            <div>
                <h3>Solicitação de planos</h3>
                <hr />
                <Card>
                    <TableSolicitacaoPlano
                        isSearch={this.handleChange}
                        data={this.state.rowData}
                        selectStatusPagamento={this.selectStatusPagamento}
                        selectStatusMigracao={this.selectStatusMigracao}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        is_cnpj={this.state.is_cnpj}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhuma solicitação encontrada
                            </div></CardBody></Card>
                        }
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                        state={this.state}
                    />
                </Card>
                <br/>
            </div>
        )
    }
}

export default SolicitacaoPlano;