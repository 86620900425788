import React, { useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, Row, Col, Input, CardFooter, Collapse, Button, Label } from 'reactstrap';
import { columns } from './propsPrevencao';
import debounce from 'lodash/debounce';
import Pagination from '../Pagination';
import { InputText } from '../../InputText/InputText';
import InputMask from 'react-input-mask';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import Select from 'react-select';
import { list_status_pagamentos } from '../../../auth/listas';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import axios from 'axios';
import { FaChartPie } from 'react-icons/fa';
import { Doughnut, Pie, getDatasetAtEvent } from 'react-chartjs-2';
import { BiPieChart } from 'react-icons/bi';
import { Chart } from 'chart.js';

var CurrencyInput = require('react-currency-masked-input');

const forma_pagamento = [
    { value: "pix", label: "PIX" },
    { value: "boleto", label: "Boleto" },
    { value: "credit", label: "Cartão de Crédito" },
];

const origens = [
    // { value: "cobrancas", label: "Link de Pagamento" },
    // { value: "magento2", label: "SHOPAQPago" },
    // { value: "woocommerce", label: "WooCommerce" },
    // { value: "opencard", label: "OpenCart" },
    // { value: "prestashop", label: "PrestaShop" },
    // { value: "api", label: "Integração API" },
    { value: "meubus", label: "MeuBus" },
];


const getOperadores = (callback) => {
    var url = ENDPOINT + "chat-atendimento/consultar-operadores";

    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        var operadores_list = [];
        data.forEach((p, i) => {
            p['value'] = p.id;
            p['label'] = p.name;
            operadores_list.push(p);
        });

        callback(operadores_list)
    });
}
export const TablePrevencaoQueroBus = (props) => {
    const [operadores_list, setOperadoresList] = useState([]);
    const chartRef = useRef();

    useEffect(() => {
        getOperadores(setOperadoresList);
    }, []);


    const check_disabled = () => props.state.cpf_cnpj.replace(/([^\d])+/gim, '').length > 0 || props.state.numero_pedido.length > 0;
    
    const check_porcentagem = (event) => {
        console.log(event)
        console.log(getDatasetAtEvent(chartRef.current, event));
    }

    return (
        <Card>
            <CardBody>
                <Row >
                    <Col md={10}>
                        <div style={{ textAlign: 'left' }}>
                            <InputText
                                name="search"
                                placeholder="Buscar"
                                onChange={debounce((event) => props.isSearch(event), 1000)} />
                        </div>
                    </Col>
                    <Col md={2} style={{padding: 0, textAlign: 'right'}}>
                        <Button
                            color="warning"
                            onClick={props.filterShow}
                            style={{ marginRight: '25px', marginBottom: '4px' }}>
                            Filtrar&nbsp;
                            <AiFillCaretDown hidden={props.show_filter} />
                            <AiFillCaretUp hidden={!props.show_filter} />
                        </Button>
                    </Col>
                </Row>
                <Collapse isOpen={props.show_filter}>
                    <br/>
                    <Row>
                        <Col md={4}>
                            <InputText
                                    name="numero_pedido"
                                    placeholder="Número do Pedido"
                                    onChange={debounce((event) => props.isSearch(event), 1000)} />
                        </Col>
                        <Col md={4}>
                            <Select
                                style={{display: 'none'}}
                                placeholder="Origem do Pagamento"
                                options={origens}
                                isClearable={props.state.cpf_cnpj.replace(/([^\d])+/gim, '').length < 10}
                                value={props.state.origem_pagamento}
                                onChange={(value) => props.onChangeSelect('origem_pagamento', value)}
                            />
                        </Col>
                        <Col md={4}>
                            <Select
                                placeholder="Forma de pagamento"
                                options={forma_pagamento}
                                isClearable
                                value={props.state.forma_pagamento}
                                onChange={(value) => props.onChangeSelect('forma_pagamento', value)}
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={4}>
                            <div style={{ textAlign: 'left' }}>
                                <InputMask
                                    name="cpf_cnpj"
                                    className="form-group"
                                    mask={props.is_cnpj === false ? "999.999.999-99" : "99.999.999/9999-99"}
                                    onChange={debounce((event) => props.isSearch(event), 1000)}
                                >
                                    {() => <InputText placeholder={props.is_cnpj === false ? "CPF" : "CNPJ"} name="cpf_cnpj" {...props} />}
                                </InputMask>
                            </div>
                            </Col>
                            {props.is_seller === true ?
                                <Col md={4}>
                                    <div style={{marginTop: '17px'}}>
                                        &nbsp;&nbsp;<input 
                                                        type="checkbox"
                                                        checked={props.is_cnpj}
                                                        onChange={() => props.onSearchDocument()}/> Pesquisar CNPJ</div>
                                </Col>
                                :
                                <></>
                            }
                            <Col md={4}>
                                <Select
                                    placeholder="Status do Pagamento"
                                    options={list_status_pagamentos}
                                    isClearable
                                    isDisabled={check_disabled()}
                                    value={!check_disabled() ? props.state.status : { value: 0, label: 'Todos' }}
                                    onChange={(value) => props.onChangeSelect('status', value)}
                                />
                            </Col>
                    </Row>
                        <small>
                            <input type="radio" name="is_seller_is" onClick={() => props.onSellerIs(false)} checked={!props.is_seller}/> Buyer&nbsp;&nbsp;
                            <input type="radio" name="is_seller_is" onClick={() => props.onSellerIs(true)} checked={props.is_seller} /> Seller
                        </small>
                    <br/>
                    <br/>
                    <Row>
                        <Col md={6}>
                            <Select
                                placeholder="Operador que iniciou prevenção"
                                options={operadores_list}
                                isClearable
                                value={props.state.admin_inicio}
                                onChange={(value) => props.onChangeSelect('admin_inicio', value)}
                            />
                        </Col>
                        <Col md={6}>
                            <Select
                                placeholder="Operador que finalizou prevenção"
                                options={operadores_list}
                                isClearable
                                value={props.state.admin_fim}
                                onChange={(value) => props.onChangeSelect('admin_fim', value)}
                            />
                        </Col>
                    </Row>
                    <hr/>
                    <br/>
                    <small>Filtro por Data</small>
                    <Row>
                        <Col>De:
                        <Input
                                name="date_from"
                                type="date"
                                disabled={!(props.filter === true)}
                                onChange={debounce((event) => props.isSearch(event), 1000)}
                                //value={props.date_from}
                                //onChange={(event) => props.isSearch(event)}
                                 /></Col>
                        <Col>Até:
                        <Input
                                name="date_to"
                                type="date"
                                disabled={!(props.filter === true)}
                                onChange={debounce((event) => props.isSearch(event), 1000)}
                                //disabled
                                //value={props.date_to}
                                //onChange={(event) => props.isSearch(event)} 
                                /></Col>
                    </Row>
                    <Row>
                        <Col md={5}>
                            <input
                                type="checkbox"
                                checked={props.filter}
                                name="filter"
                                onChange={(value) => props.onFilter(value.target.checked)} /> Habilitar filtro por data de alteração:
                        </Col>
                    </Row>
                    <hr/>
                    <small>Filtro por Valor</small>
                    <Row>
                        <Col md={6}>
                            <Label>De:</Label>
                            <CurrencyInput 
                                inputMode="tel"
                                className="form-control" 
                                currency="BRL"
                                name="valor_de"
                                //value={valor}
                                defaultValue={'0.00'}
                                onChange={debounce((event) => props.isSearch(event), 500)} />
                        </Col>
                        <Col md={6}>
                            <Label>Até:</Label>
                            <CurrencyInput 
                                inputMode="tel"
                                className="form-control" 
                                currency="BRL"
                                name="valor_ate"
                                //value={valor}
                                defaultValue={'0.00'}
                                onChange={debounce((event) => props.isSearch(event), 500)} />
                        </Col>
                    </Row>
                </Collapse>
            </CardBody>
            <hr style={{ marginBottom: '4px' }} />
            <DataTable
                noHeader={true}
                button={true}
                noDataComponent={props.noDataComponent}
                columns={columns(props.onPayload, props.onSellerDocument)}
                data={props.data}
                theme="default"
            />
            <CardFooter style={{ textAlign: 'center' }}>
                <Pagination
                    onPageChange={props.onPageChange}
                    activePage={props.activePage}
                    totalPage={props.totalPage}
                />
            </CardFooter>
        </Card>
    )
}