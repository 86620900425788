import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import Moment from 'moment';
import { Button, Row } from 'reactstrap';
import { MdDelete } from 'react-icons/md';

export const columns = (onShowDelete) => [
    {
      name: '',
      selector: 'buttons',
      width: '100px',
      cell: row => <Row>
        {onShowDelete ? 
            <Button
              outline
              style={{ padding: 2, marginLeft: 7, border: 0, fontWeight: '700'}}
              color="danger"
              onClick={() => onShowDelete(row.id)}
            >
              Cancelar nota fiscal
            </Button> 
          : 
          <></>}
      </Row>
    },
      {
        name: "Data de criação",
        selector: "nf_date",
        sortable: false,
        width: '150px',
        cell: p => Moment(p.created_at).format('DD/MM/YYYY')
      },
      {
        name: "Chave",
        selector: "nfe_chave",
        sortable: false,
        width: '35%',
        cell: p => p.nfe_chave
      },
      {
        name: "Referência",
        selector: "ref",
        sortable: false,
        width: '100px',
      },
      {
        name: "Número da Nota",
        selector: "nfe_num",
        sortable: false,
        width: '100px',
      },
      {
        name: "Documento (CPF/CNPJ)",
        sortable: false,
        width: '150px',
        cell: p => p.cnpj !== '' ? p.cnpj : p.cpf
      },
      {
        name: "Status",
        selector: "nfe_status",
        sortable: false,
        cell: p => p.nfe_status
      },
  ];
  
export const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "nota_fiscal/list?search="+state.search+"&page="+state.activePage;

    if(state.filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    
    var url = ENDPOINT + "nota_fiscal/list?search="+search+"&page="+page;

    if(filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}