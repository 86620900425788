import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import get_extension from 'get-url-extension';
import PDFDocument from "../../PDF/PDFDocument";
import { getToken } from "../../../auth/set_services";



const ModalShow = (props) => {
    const onClose = () => {
        props.onClose();
    }

    const get_file_extension = (url) =>{
        var extension = get_extension(url);
    
        if(extension === '.png' ||
            extension === '.PNG' ||
            extension === '.jpg' ||
            extension === '.jpeg' ||
            extension === '.svg' ||
            extension === '.webp'){
            return 'image'
        }
        else if(extension === '.pdf' ||
            extension === '.PDF'){
            return 'pdf';
        }
    
        return 'file';
    }

    return(
        <Modal isOpen={props.visible} size="lg">
            <ModalHeader>Documento</ModalHeader>
            <ModalBody style={{textAlign: 'center'}}>
                {get_file_extension(props.url) === 'image' ?
                    <img 
                        style={{maxWidth: '100%'}}
                        src={props.url+'?access_token='+getToken()} 
                    />
                :
                get_file_extension(props.url) === 'pdf' ?
                <iframe 
                    src={props.url+'?access_token='+getToken()} 
                    width="100%"
                    height="470px"
                />
                :
                <></>}
            </ModalBody>

            <ModalFooter>
                <Button color="danger" onClick={() => onClose()}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalShow;