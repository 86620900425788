import axios from 'axios';
import { HiViewGrid } from 'react-icons/hi';
import { MdDelete, MdTimer } from 'react-icons/md';
import { Button, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';

const getTime = (date) => {
  if(new Date(date) > new Date()){
    return <div style={{color: 'green', fontWeight: '700'}}><MdTimer /> {new Date(date).toLocaleString()}</div>
  }

  return <div style={{color: 'red', fontWeight: '700'}}><MdTimer /> {new Date(date).toLocaleString()}</div>
}

export const columns = (onShowDelete) => [
    {
      name: '',
      selector: 'buttons',
      width: '90px',
      cell: row => <Row>
        <Button
          outline
          style={{ padding: 2, marginLeft: 15, border: 0, fontWeight: '700'}}
          color="dark" onClick={() => 
            {
                window.location.href = '/ocorrencia-detalhe/'+row.protocolo_ocorrencia
            }
          }>
          <HiViewGrid style={{ padding: '2px' }}/>
        </Button>
        {onShowDelete ? 
            <Button
              outline
              style={{ padding: 2, marginLeft: 7, border: 0, fontWeight: '700'}}
              color="danger"
              onClick={() => onShowDelete(row.id)}
            >
              <MdDelete style={{ padding: '2px' }} />
            </Button> 
          : 
          <></>}
      </Row>
    },
    {
      name: "Razão da ocorrência",
      selector: "razao_ocorrencia",
      sortable: false,
      width: '200px'
    },
    {
      name: "Validade da ocorrência",
      selector: "validade_ocorrencia",
      sortable: false,
      width: '200px',
      cell: p => <>{getTime(p.validade_ocorrencia)}</>,
    },
    {
      name: "Produto",
      selector: "produto",
      sortable: false,
      width: '120px'
    },
    {
      name: "Protocolo da ocorrência",
      selector: "protocolo_ocorrencia",
      width: '190px',
      sortable: false,
    },
    {
      name: "Protocolo do cliente",
      selector: "protocolo_cliente",
      width: '170px',
      sortable: false,
    },
    {
      name: "Cliente",
      selector: "cliente",
      sortable: false,
      width: '15%',
      cell: row => row.cliente !== null ? row.cliente.nome+' '+row.cliente.sobrenome : 'Sem Informações'
    },
    {
      name: "Funcionário Atribuido",
      selector: "funcionario",
      sortable: false,
      width: '15%',
      cell: row => row.funcionario_atribuido !== null ? row.funcionario_atribuido.name : 'Sem Informações'
    }
];

export const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "ocorrencia/list";

    if(state.produto !== null){
      url +='&produto='+state.produto.value;
    }

    if(state.produto !== null){
      url +='&tipo_ocorrencia='+state.tipo_ocorrencia.value;
    }

    if(state.razao.length > 0){
      url +='&razao='+state.razao;
    }

    if(state.protocolo_ocorrencia.length > 0){
      url +='&protocolo_ocorrencia='+state.protocolo_ocorrencia;
    }

    if(state.protocolo_cliente.length > 0){
      url +='&protocolo_cliente='+state.protocolo_cliente;
    }

    if (state.operador !== null) {
      url += '&operador=' + state.operador.value;
    }

    if(state.document.replace(/([^\d])+/gim, '').length > 0){
      if(state.document.replace(/([^\d])+/gim, '').length === 11){
          url +='&cpf='+state.document.replace(/([^\d])+/gim, '');
      }
      else if(state.document.replace(/([^\d])+/gim, '').length === 14){
          url +='&cnpj='+state.document.replace(/([^\d])+/gim, '');
      }
    }
    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    var url = ENDPOINT + "ocorrencia/list?search="+search+"&page="+page;

    
    if(state.produto !== null){
      url +='&produto='+state.produto.value;
    }

    if(state.tipo_ocorrencia !== null){
      url +='&tipo_ocorrencia='+state.tipo_ocorrencia.value;
    }

    if(state.razao.length > 0){
      url +='&razao='+state.razao;
    }

    if(state.protocolo_ocorrencia.length > 0){
      url +='&protocolo_ocorrencia='+state.protocolo_ocorrencia;
    }

    if(state.protocolo_cliente.length > 0){
      url +='&protocolo_cliente='+state.protocolo_cliente;
    }

    if (state.operador !== null) {
      url += '&operador=' + state.operador.value;
    }

    if(filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    
    if(state.document.replace(/([^\d])+/gim, '').length > 0){
      if(state.document.replace(/([^\d])+/gim, '').length === 11){
          url +='&cpf='+state.document.replace(/([^\d])+/gim, '');
      }
      else if(state.document.replace(/([^\d])+/gim, '').length === 14){
          url +='&cnpj='+state.document.replace(/([^\d])+/gim, '');
      }
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}