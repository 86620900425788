import React, { Component } from "react";
import classnames from 'classnames';
import { FaBox, FaUser } from "react-icons/fa";
import { MdMail, MdMoneyOff } from "react-icons/md";
import { Button, Card, CardBody, CardColumns, CardFooter, Col, Collapse, FormGroup, Input, Label, Nav, NavLink, Row, TabContent, TabPane } from "reactstrap";
import styled from "styled-components";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import DataPreClient from "../../components/ResumeClient/DataPreClient";
import DataClientVenda from "../../components/ResumeClient/DataClientVenda";
import sanitizeHtml from 'sanitize-html';
import { InputText } from "../../components/InputText/InputText";
import axios from "axios";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import EnviarEmailVenda from "../../components/Modals/Vendas/EnviarEmailVenda";
import ecommerce_logo from '../../assets/ecommerce-aqpago.svg';
import Select from "react-select";
import { toast } from "react-toastify";
import ClipLoader from 'react-spinners/ClipLoader';
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

const status_transacao_not_svg = (payment_type) => {
    switch(payment_type){
        case 'succeeded' : return 'Aprovada';
        case 'canceled' : return 'Cancelada';
        case 'pending' : return 'Pendente';
        case 'failed' : return 'Falhada';
        case 'disputed': return 'Em disputa';
        default: return payment_type
    }
}

const payment = (payment_type) => {
    switch(payment_type){
        case 'credit' : return 'Crédito';
        case 'boleto' : return 'Boleto';
        case 'debit' : return 'Débito';
        default: return payment_type
    }
}

const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
`;


const getSolicitacao = (id, callback) => {
    var url = ENDPOINT + "solicitacao/info/" + id;

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((data) => {
        callback(data);
    })
}


const iniciarMigracao = (id, formData = [], callback, callbackError) => {
    var url = ENDPOINT + "solicitacao/iniciar_migracao/" + id;

    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((data) => {
        callback(data.data);
    })
    .catch((error) => {
        var data = error.response.data;
        callbackError(data);
    })
}

const get_id = () => {
    return window.location.pathname.replace('/resumo-solicitacao/', '')
}

const status_solicitacao_list = [
    {  value: 'Em Progresso', label: "Em Progresso" },
    {  value: 'Cancelado', label: "Cancelado" },
    {  value: 'Reprovado', label: "Reprovado" },
    {  value: 'Em Analise', label: "Em Analise" },
    {  value: 'Concluido', label: "Concluido" }
];

const getStringMaps = (mail) => {
    var string_mail = "";
    mail.forEach((p) => {
      string_mail += getTranslate(p.recordType) + " " + moment(new Date(p.dateAt).toString()).format('DD/MM/YYYY h:mm:ss a') + "<br /> "
    });
  
    return string_mail;
}

const getTranslate = (name) => {
    switch (name) {
        case 'Delivery': return 'Entregue'
        case 'Open': return 'Aberto'
        case 'Click': return 'Clicado'
        case 'SpamComplaint': return 'Caixa de SPAM'
        case 'Bounce': return 'Mensagem devolvida'
        default : return name;
    }
}

class SolicitacaoPlanoResumo extends Component{
    constructor(props){
        super(props);
        this.state = {
            activeTab: "1",
            motivo_estorno: '',
            visible_email: false,
            venda_id: null,
            status_solicitacao_label: null,
            id: get_id(),
            info_remessa: {},
            user: {},
            produtos: [],
            mails: [],
            historico_status: [],
            postagem_total_express: null,
            analista: null,
            forma_pagamento: '',
            status: '',
            status_solicitacao: '',
            status_pedido_label: null,
            estornado_por: null,
            prevencao_show: false,
            total_express_loading: false,
            loading_migracao: false
        }
    }

    componentDidMount() {
        getSolicitacao(this.state.id, this.callbackRows.bind(this));
    }

    callbackRows(data) {
        this.setState({ ...data.data });

        if(data.data.status_entrega !== null){
            var status_solicitacao_label = {
                value: data.data.status_solicitacao, 
                label: data.data.status_solicitacao
            };

            this.setState({
                status_solicitacao_label
            })
        }
    }

    onCloseEmail = (visible_email = false) => {
        this.setState({ visible_email });

        if(visible_email == false){
            getSolicitacao(this.state.id, this.callbackRows.bind(this));
        }
    }

    listData = [
        { name: 'Informações da Solicitação', active: '1' },
        { name: 'E-mails', active: '2' }
    ];

    alterStatus = (status) => {
        this.setState({ status_solicitacao_label: status });

        var url = ENDPOINT+"solicitacao/update_status/"+this.state.id;
    
        var formData = new FormData();
        formData.append('status_pedido', status.value);
        axios.post(url, formData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            getSolicitacao(this.state.id, this.callbackRows.bind(this));
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                toast.error(obj.errors);
            }
  
            var data = error.response.data;
            if(typeof data.errors !== 'undefined'){
              toast.error(data.errors);
            }
        });
    
    }

    handleChangeEmail = (mensagem_email) => {
        this.setState({mensagem_email});
    }

    onToast = (data) => {
        if(data.success === true){
            toast.success(data.message);
        }
        else{
            toast.error(data.errors);
        }
        //this.props.refresh();
    }

    iniciarMigracaoBtn = () => {
        this.setState({loading_migracao: true});

        const callbackAnalista = (analista) => {
            this.setState({analista, loading_migracao: false})
        }
        const callbackError = (value) => {
            this.setState({loading_migracao: false});
            toast.error(value.errors);
        }

        iniciarMigracao(this.state.id, [], callbackAnalista, callbackError);
    }

    render(){
        const Navs = () => {
            return <Nav tabs>
                {this.listData.map((p, index) => {
                    return <NavLink
                        key={index}
                        className={
                            classnames({ active: this.state.activeTab === p.active })}
                        onClick={() => { this.setState({ activeTab: p.active }); }}>
                        <span>{p.name}</span>
                    </NavLink>
                })}
            </Nav>
        }
        return(<Div>
            <h3>Resumo de Solicitação</h3>
            <Navs />
            <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId={'1'}>
                    <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                        <h5>Dados do Pagamento</h5>
                        <hr/>
                        <p><b>Status de Pagamento:</b> {this.state.status}</p>
                        <p><b>Forma de Pagamento:</b> {payment(this.state.forma_pagamento)}</p>
                        <p style={{margin: 0}}><b>Status da Entrega:</b>
                            <Select
                                value={this.state.status_solicitacao_label}
                                placeholder="Status da solicitação"
                                options={status_solicitacao_list}
                                onChange={this.alterStatus}
                            />
                        </p>
                        {this.state.status_solicitacao !== 'Concluido' ?
                            this.state.analista !== null  ?
                                <>
                                <br/>
                                    <Button 
                                        color="info"
                                        onClick={() => {
                                            var document = this.state.user.id;
                                            window.open('/user-pre-venda/'+document, '_blank');
                                        }}
                                        style={{margin: 5}}>Credenciar</Button>
                                <br/>
                                    <Button 
                                        color="info"
                                        onClick={() => {
                                            var document = this.state.user.cpf ? this.state.user.cpf : this.state.user.cnpj;
                                            window.open('/users-plano?cpf_cnpj='+document, '_blank');
                                        }}
                                        style={{margin: 5}}>Trocar Plano</Button>
                                </>
                            :
                            <>
                                <br/>
                                <Button 
                                    color="info" 
                                    onClick={() => this.iniciarMigracaoBtn()}
                                    disabled={this.state.loading_migracao}>
                                        {this.state.loading_migracao === true ?
                                        <ClipLoader color="#fff" size={20}/>:''} Iniciar migração de plano
                                </Button>
                            </>
                            :
                            ''
                        }
                    </div>
                </TabPane>
                <TabPane tabId={'2'}>
                    <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                        {/* <EnviarEmailVenda
                            id={this.state.venda_id}
                            visible={this.state.visible_email}
                            onClose={this.onCloseEmail}
                        /> */}
                        <h5>E-mails</h5>
                        <hr/>
                       {/*  <Button color="success" onClick={() => this.onCloseEmail(true)}>Enviar E-mail</Button> */}
                        {/* <br/><br/> */}
                        <ul style={{listStyleType: 'circle'}}>
                        {this.state.mails.map(p => {
                            return <li style={{marginBottom: '7px'}}>
                                {p.subject}<br/>
                                <small>Conteúdo de E-mail:</small>
                                <div style={{border: '1px solid #7f7f7f66', borderRadius: '15px', padding: '19px'}} dangerouslySetInnerHTML={{ __html: sanitizeHtml(p.message)}}/>
                                <small>{p.responsavel !== null ? <><b>Operador:</b> {p.responsavel.name}</> : ''}</small>
                                <small>{p.mail.length > 0 ?
                                    <div data-tip={getStringMaps(p.mail)} style={{ maxHeight: '200px', 
                                    cursor: 'pointer', overflow: 'auto', width: '100%', margin: '12px' }}>
                                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                                        {p.mail.slice(0, 1).map((p) => {
                                            return <li>
                                            <p><MdMail /> <b>{getTranslate(p.recordType)}</b>
                                                <br />
                                                {moment(new Date(p.dateAt).toString()).format('DD/MM/YYYY h:mm:ss a')}</p>
                                            </li>
                                        })}
                                        </ul>
                                        <ReactTooltip multiline={true} />
                                    </div>
                                    :
                                    <div data-tip={"Postado " + moment(new Date(p.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')} style={{ cursor: 'pointer', maxHeight: '200px', overflow: 'auto', width: '100%', margin: '12px' }}>
                                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                                        <li style={{ listStyleType: 'none' }}><MdMail /> <b>Postado</b><br />{moment(new Date(p.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')}
                                        </li>
                                        </ul>
                                        <ReactTooltip multiline={true} />
                                    </div>
                                }</small>
                            </li>
                        })}
                    </ul>
                    {this.state.mails.length === 0 ? 'Nenhum email encontrado' : ''}
                    </div>
                </TabPane>
            </TabContent>
        </Div>)
    }
}

export default SolicitacaoPlanoResumo;