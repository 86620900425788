import React, { Component } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { Card, CardBody } from 'reactstrap';
import { getRows, getRowsPerPage } from "../../components/Tables/TableAnalise/propsPrevencao";
import Moment from "moment";
import { TablePrevencaoQueroBus } from "../../components/Tables/TableAnalise/TablePrevencaoQueroBus";
import Pusher from 'pusher-js';

class PagamentosQueroBus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            loading_table: true,
            edit_id: -1,
            edit_user: null,
            rowData: [],
            cpf_cnpj: '',
            is_seller: false,
            forma_pagamento: null,
            data_pedido: null,
            origem_pagamento: { value: "meubus", label: "MeuBus" },
            admin_inicio: null,
            admin_fim: null,
            numero_pedido: '',
            totalPage: 0,
            activePage: 1,
            total_NVO: '0.00',
            total_AMA: '0.00',
            total_RPM: '0.00',
            total_APM: '0.00',
            total_APA: '0.00',
            is_cnpj: false,
            document: '',
            //status: { value: "ANALISANDO", label: "Antifraude - Analise Pendente" },
            status: { value: 0, label: 'Todos' },
            filter: false,
            searching: false,
            show_filter: false,
            show_graf: false,
            valor_de: '0.00',
            valor_ate: '0.00',
            payload_current: {},
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
        }
        this.refDocument = React.createRef();
    }
    onSearchDocument() {
        this.setState({ is_cnpj: !this.state.is_cnpj })
    }
    handleChange = (event) => {
        var state = this.state;
        let name = event.target.name;
        let value = event.target.value;

        if (name === 'cpf_cnpj') {
            if (value.replace(/([^\d])+/gim, '').length > 10) {
                state[name] = value;
                if (state.origem_pagamento == null) {
                    state.origem_pagamento = { value: "cobrancas", label: "Link de Pagamento" };
                }
                state.activePage = 1;
                state.rowData = [];
                state.loading_table = true;
                this.setState({ ...state });

                getRowsPerPage(1, state, this.callbackRows);
            }
            else if (value.replace(/([^\d])+/gim, '').length === 0) {
                state[name] = value;
                state.activePage = 1;
                state.rowData = [];
                state.loading_table = true;
                this.setState({ ...state });

                getRowsPerPage(1, state, this.callbackRows);
            }
        }
        else {
            state[name] = value;
            state.activePage = 1;
            state.rowData = [];
            state.loading_table = true;
            this.setState({ ...state });

            getRowsPerPage(1, state, this.callbackRows);
        }
    }

    componentDidMount() {
        getRows(this.state, this.callbackRows.bind(this));
        this.PusherInstancia();
    }
    filterShow() {
        this.setState({
            show_filter: !this.state.show_filter
        })
    }
    grafShow() {
        this.setState({
            show_graf: !this.state.show_graf
        })
    }

    onChangeSelect = (name, value) => {
        var state = this.state;
        state.rowData = [];
        state.activePage = 1;
        state.loading_table = true;
        state[name] = value;
        this.setState({ ...state });

        getRows(state, this.callbackRows.bind(this));
    }


    callbackRows = (rowData, totalPage, totalValor) => {
        
        if (typeof (totalValor.parcial_mensal) !== 'undefined') {
            totalValor.parcial_mensal.forEach(e => {
                Object.keys(e).map(p => {
                    this.setState({ [p]: e[p] });
                    return "";
                })
            });
        }
        else {
            totalValor.forEach(e => {
                Object.keyvalors(e).map(p => {
                    this.setState({ [p]: e[p] });
                    return "";
                })
            });
        }
        console.log(rowData)
        this.setState({ rowData, totalPage, loading_table: false, searching: false })
    }

    onPageChange = (activePage) => {
        var state = this.state;

        state.activePage = activePage;
        state.rowData = [];
        state.loading_table = true;
        this.setState({ ...state });

        getRowsPerPage(activePage, state, this.callbackRows.bind(this));
    }
    onFilter = (filter) => {
        var state = this.state;
        state.filter = filter;
        state.activePage = 1;
        this.setState({ ...state });
        getRowsPerPage(state.activePage, state, this.callbackRows.bind(this));
    }
    onSellerDocument = (row) => {
        var document = row.individual !== null ? row.individual.taxpayer_id : row.business.ein;

        var state = this.state;
        state.rowData = [];
        state.loading_table = true;
        state.document = document;
        state.is_cnpj = document.length > 13 ? true : false;
        state.activePage = 1;
        this.setState({ ...state });
        //console.log(this.refDocument)
        this.refDocument.current.value = document;

        getRowsPerPage(1, state, this.callbackRows.bind(this));
    }
    onSellerIs = (is_seller) => {
        var state = this.state;
        state.rowData = [];
        state.loading_table = true;
        state.is_seller = is_seller;
        state.activePage = 1;
        this.setState({ ...state });

        getRowsPerPage(state.activePage, state, this.callbackRows.bind(this));
    }

    onGetRows = () => {
        if (this.state.searching == false) {
            console.log('Okay')
            this.setState({ searching: true });
            getRows(this.state, this.callbackRows.bind(this));
        }

    }
    PusherInstancia = () => {
        const pusher_instance = new Pusher("2892f3b88d766b1b14d3", {
            cluster: 'us3',
        });

        var response = pusher_instance.subscribe('atualizar_analise_prevencao_risco');
        response.bind('atualizar_prevencao', () => {
            setTimeout(() => this.onGetRows(), 5000);
        });
    }

    onClickChart = (chartRef) => {

    }

    prevencao_pie_data = () => {
        let lab = [];
        let valorTotal = 0;

        console.log(this.state)

        valorTotal = (parseFloat(this.state.total_APM) + parseFloat(this.state.total_APA) + parseFloat(this.state.total_NVO) + parseFloat(this.state.total_AMA) + parseFloat(this.state.total_RPM));

        let pc_total_APM = (parseFloat(this.state.total_APM) > 0) ? (parseFloat(this.state.total_APM) / parseFloat(valorTotal)) * 100 : 0.00;
        let pc_total_APA = (parseFloat(this.state.total_APA) > 0) ? (parseFloat(this.state.total_APA) / parseFloat(valorTotal)) * 100 : 0.00;
        let pc_total_NVO = (parseFloat(this.state.total_NVO) > 0) ? (parseFloat(this.state.total_NVO) / parseFloat(valorTotal)) * 100 : 0.00;
        let pc_total_AMA = (parseFloat(this.state.total_AMA) > 0) ? (parseFloat(this.state.total_AMA) / parseFloat(valorTotal)) * 100 : 0.00;
        let pc_total_RPM = (parseFloat(this.state.total_RPM) > 0) ? (parseFloat(this.state.total_RPM) / parseFloat(valorTotal)) * 100 : 0.00;


        pc_total_APM = parseFloat(pc_total_APM).toFixed(2);
        pc_total_APA = parseFloat(pc_total_APA).toFixed(2);
        pc_total_NVO = parseFloat(pc_total_NVO).toFixed(2);
        pc_total_AMA = parseFloat(pc_total_AMA).toFixed(2);
        pc_total_RPM = parseFloat(pc_total_RPM).toFixed(2);


        lab[0] = pc_total_APM + '% Aprovada Manual';
        lab[1] = pc_total_APA + '% Aprovada Automático';
        lab[2] = pc_total_NVO + '% Análise Pendente';
        lab[3] = pc_total_AMA + '% Análise Iniciada';
        lab[4] = pc_total_RPM + '% Análise Reprovado';

        let dat = [];
        dat[0] = this.state.total_APM;
        dat[1] = this.state.total_APA;
        dat[2] = this.state.total_NVO;
        dat[3] = this.state.total_AMA;
        dat[4] = this.state.total_RPM;

        return {
            labels: lab,
            datasets: [
                {
                    label: 'Analises de Prenveção',
                    data: dat,
                    //fill: false,
                    backgroundColor: [
                        '#008000',
                        '#00FF7F',
                        '#FFFF00',
                        '#00008B',
                        '#B22222',
                    ]
                },
            ],

        }
    }

    render() {
        return (
            <div>
                <h3>Pagamentos MeuBus</h3>
                <hr />
                <TablePrevencaoQueroBus
                    isSearch={this.handleChange}
                    onChangeSelect={this.onChangeSelect}
                    onSearchDocument={this.onSearchDocument.bind(this)}
                    onSellerIs={this.onSellerIs.bind(this)}
                    onSellerDocument={this.onSellerDocument}
                    data={this.state.rowData}
                    state={this.state}
                    is_cnpj={this.state.is_cnpj}
                    show_filter={this.state.show_filter}
                    show_graf={this.state.show_graf}
                    onFilter={this.onFilter.bind(this)}
                    date_from={this.state.date_from}
                    date_to={this.state.date_to}
                    is_seller={this.state.is_seller}
                    filter={this.state.filter}
                    refDocument={this.refDocument}
                    filterShow={this.filterShow.bind(this)}
                    grafShow={this.grafShow.bind(this)}
                    onPageChange={this.onPageChange}
                    activePage={this.state.activePage}
                    totalPage={this.state.totalPage}
                    pieData={this.prevencao_pie_data.bind(this)}
                    noDataComponent={this.state.loading_table === true ?
                        <Card style={{ border: 0, padding: '10vh' }}><CardBody><div><div className="sweet-loading">
                            <PropagateLoader color={'#52c565'} loading={20} size={30} />
                        </div></div></CardBody></Card> :
                        <Card style={{ border: 0 }}><CardBody><div>
                            Nenhum pagamento encontrado
                        </div></CardBody></Card>
                    }
                />
            </div>
        )
    }
}

export default PagamentosQueroBus;