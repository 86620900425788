import React, {Component} from 'react';
import { Card, CardBody, Button, Input } from "reactstrap";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { TableContestacao } from '../../components/Tables/TableContestacao/TableContestacao';
import Moment from 'moment';
import { getRows, getRowsPerPage } from '../../components/Tables/TableContestacao/propsContestacao';
import PropagateLoader from "react-spinners/PropagateLoader";
import { getPermissoes, getToken, getUser } from '../../auth/set_services';
import { toast } from "react-toastify";
import { ENDPOINT } from '../../auth/endpoint';
import axios from 'axios';

var CurrencyInput = require('react-currency-masked-input');

const getSerialNumber = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const serial_number = urlParams.get('serial_number');
    if(serial_number !== null){
        return serial_number;
    }
    else{
        return null;
    }
}
class Contestacoes extends Component{
    constructor(props){
        super(props);
        this.state = {
            rowData: [],
            activePage: 1,
            taggy_serial: getSerialNumber(),
            totalPage: 1,
            filter: false,
            search: '',
            radio_valor: 'total',
            motivo: '',
            valor: 0,
            date_from: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: Moment(new Date().toDateString()).format('YYYY-MM-DD'),
            loading_table: true,
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        var state = this.state;
        state[name] = value;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }

    componentDidMount(){
        getRows(this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({rowData, activePage, totalPage, loading_table: false})
    }

    onFilter = (filter) => {
        this.setState({filter});
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }
    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    
    onValor = (event) => {
        var valor_current = event.target.value;
        if (valor_current.length > 1) {
        valor_current = valor_current.replaceAll('.', '');
        var digits = valor_current.substr(valor_current.length - 2);
        valor_current = valor_current.slice(0, -2);
        valor_current = valor_current + '.' + digits;
        }

        var valido = /^[\d,.?!]+$/.test(valor_current);
        if (valido === true || valor_current === '') {
            this.setState({ valor: valor_current });
        }
    };
    onData = (data) => {
        if(data.success === true){
            toast.success(data.msg);
        }
        else{
            toast.error(data.errors);
        }
    }
    onAprovved = (id) => {
        var url = ENDPOINT+"contestacao/aprovar_contestacao/"+id;

        var formData = new FormData();
        formData.append('motivo_contestacao', this.state.motivo);

        if(this.state.radio_valor == 'manual'){
            formData.append('valor', this.state.valor);
        }
        
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            this.onData(data);
            getRows(this.state, this.callbackRows.bind(this));
            this.setState({
                motivo: '',
                radio_valor: 'manual'
            })
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onData(obj);
            }
            var data = error.response.data;
            this.onData(data);
        });
    }
    ExpandableRows = ({data}) => {
        const dataAll = data.json;
        return <div style={{margin: '20px'}}>
                <h4>Contestação #{dataAll.disputeId}</h4>
                <hr/>
                <p>{Moment(dataAll.OpenDate).format('DD/MM/YYYY')}</p>
                <p><b>Valor da Transacao:</b> {parseFloat(dataAll.transactionAmount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                <p><b>Razão da Contestação: </b>{dataAll.requestReason}</p>
                <p><b>Descrição Contestação:</b> {dataAll.requestDescription}</p>
                <p><b>Status:</b> {dataAll.processStatus}</p>
                <p><b>Resultado:</b> {dataAll.resultStatus}</p>
                {data.notas ?
                <>
                    {data.notas.map(e => <p>
                        <b>{Moment(e.created_at).format('DD/MM/YYYY')}</b> - {e.descricao}
                    </p>)}
                </>
                :
                <></>}
                <br/>
                <div hidden={data.extornada == true}>
                    <Button color="success" onClick={() => this.onAprovved(data.id)}>Aprovar contestação manualmente</Button><br/>
                    <input type='radio' checked={this.state.radio_valor == 'manual'} onChange={() => this.setState({ radio_valor: 'manual'})} /> Extornar Valor Manual<br/>
                    <input type='radio' checked={this.state.radio_valor == 'total'} onChange={() => this.setState({ radio_valor: 'total'})} /> Extornar Valor Total
                    <div hidden={this.state.radio_valor != 'manual'}>
                        <br/>
                        <CurrencyInput 
                            inputMode="tel"
                            className="form-control" 
                            currency="BRL"
                            name="valor_de"
                            //value={this.state.valor}
                            defaultValue={'0.00'}
                            onChange={this.onValor}/>
                        <br/>
                    </div>
                    <div>
                        <Input 
                            type="textarea"
                            name='motivo'
                            value={this.state.motivo}
                            placeholder="Motivo da contestação"
                            onChange={e => this.setState({ motivo: e.target.value })}
                        />
                    </div>
                </div>
        </div>
    }
    render(){
        return(
            <div>
                <h3>Contestações</h3>
                <hr/>
                <Card hidden={!getUser().permissao_gestor_aqpasso}>
                    <TableContestacao
                        isSearch={this.handleChange}
                        data={this.state.rowData}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        noDataComponent={ this.state.loading_table === true ? 
                            <Card style={{border: 0, padding: '10vh'}}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{border: 0}}><CardBody><div>
                                Nenhuma contestação encontrada
                            </div></CardBody></Card>
                        }
                        expandableRowsComponent={<this.ExpandableRows />}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
                <Card hidden={getUser().permissao_gestor_aqpasso}>
                    <CardBody>
                        <p>Você não tem permissões para acessar essa função</p>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default Contestacoes;