import axios from "axios";
import React, { Component } from "react";
import { MdArrowBack, MdCheck, MdClear, MdMail, MdWarning, MdAttachFile } from "react-icons/md";
import { BsLock, BsUnlock } from 'react-icons/bs';
import { IoReceiptOutline } from 'react-icons/io5';
import { Button, ButtonGroup, Card, CardBody, CardFooter, Col, Collapse, Form, FormFeedback, FormGroup, Input, Row } from "reactstrap";
import Dropzone from 'react-dropzone';
import styled from "styled-components";
import { ENDPOINT } from "../../auth/endpoint";
import { getPermissoes, getToken, getUser } from "../../auth/set_services";
import Select from "react-select";
import { list_prevencao_status, list_status_pagamentos } from "../../auth/listas";
import { InputMasked } from '../../components/InputText/InputMasked';
import { InputText } from "../../components/InputText/InputText";
import { toast } from 'react-toastify';
import PropagateLoader from "react-spinners/PropagateLoader";
import ClipLoader from 'react-spinners/ClipLoader'
import moment from "moment";
import NumberFormat from "react-number-format";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import sanitizeHtml from 'sanitize-html';
import ReactTooltip from "react-tooltip";
import GoogleMapReact from 'google-map-react';
import DataTable from 'react-data-table-component';
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { FaMapMarkerAlt, FaPen, FaPlus, FaSleigh, FaTrash } from "react-icons/fa";
import Pusher from 'pusher-js';
import { ButtonRed } from "../../components/ButtonStylish/ButtonRed";
import { DropdownIcon } from "../../components/DropdownIcon/DropdownIcon";
import SendEmailByCard from "../../components/Modals/CreditCard/SendEmailByCard";
import { MessageNoTable } from "../MessageTable/MessageNoTable";

import { Enderecos } from "./Fornecedores/Enderecos";
import { Contatos } from "./Fornecedores/Contatos";

var CurrencyInput = require('react-currency-masked-input');


const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
    .reclamado {
        display: none;
    }
    .row {
        margin: 10px 0px;
    }
    .btn-save {
        float: right;
    }
    .btn-nota {
        margin-top: 10px;
    }
    .file-iframe {
        width: 100%;
        height: 800px; 
    }
    .btn-arquivo {
        margin-right: 10px;
        color: #fff;
        border: 0px;
    }
    .subtitle {
        font-weight: 700;
        margin-top: 20px !important;
    }
    .error-active {
        display: block;
        color: red;
    }
    .error-none {
        display: none;
    }
    .input-error {
        border: 1px solid red;
    }
    .label-error {
        color: red;
    }
    .select-error {
        border: 1px solid red;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
    }
    .card {
        border: 1px solid #ced4da;
        border-left: 5px solid #28a745;
        border-radius: 0.25rem;
        -webkit-border-radius: 0.25rem;
        -moz-border-radius: 0.25rem;
        
    }
    .card h6 {
        font-weight: 600;
        padding: 10px;
        border-radius: 3px 3px 0px 0px;
        margin: 0px;
    }
    .card-box {
        padding: 1rem;
        height: auto;
        min-height: 180px;
    }
    .card-box div {
        font-size: 14px;
    }
    .button-edit {
        position: absolute;
        bottom: 0.5rem;
        right: 1rem;
    }
    .button-remove {
        position: absolute;
        bottom: 0.5rem;
        left: 1rem;
    }
    .btn-cancel {
        float: right;
    }
    .add-address-btn {
        position: absolute;
        top: 0.5rem;
        right: 2rem;
    }
    input, textarea {
        text-transform: uppercase;
    }
    .website {
        text-transform: lowercase;
    }
    .col-cards {
        margin-bottom: 1rem;
    }
`;

const tipoPessoaOptions = [
    { value: 'F', label: 'Pessoa Física'},
    { value: 'J', label: 'Pessoa Jurídica'},
    { value: 'E', label: 'Estrangeiro'},
];

const getId = () => {
    return window.location.pathname.replace('/fornecedor-detalhes/', '')
}

const optionsYesNo = [
    { value: '1', label: 'Sim'},
    { value: '0', label: 'Não'},
];
const tipoFornecedorOptions = [
    { value: '1', label: 'Serviços'},
    { value: '2', label: 'Produtos'},
    { value: '3', label: 'Sistemas SAAS'},
    { value: '4', label: 'Cloud'},
];

const DropArea = styled.div`
    border-style: dashed;
    border-color: #38B76E;
    padding: 1%;
    cursor: pointer;
    &:drag {
        opacity: 0.5;
    }
`;

const ButtonUpload = styled.button`
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0;
    padding-left: 7px;
    padding-right: 7px;
    top: -1px;
    position: relative;
`;

class ResumoFornecedor extends Component {
    constructor(props){
        super(props);
        this.state = {
            show_form: true,
            loading_page: false,
            loading_button: false,
            add_address: false,
            add_contato: false,
            load_address: false,
            open_contato: false,

            id_fornecedor: '',
            documento: '',
            nome: '',
            nome_fantasia: '',
            fornecedor_desde: '',

            id_endereco: false,
            cep: '',
            endereco: '',
            numero: '',
            bairro: '',
            complemento: '',
            cidade: '',
            estado: '',
            estado_select: '',
            endreco_visible: true,
            endereco_position: '',
            open_address: false,

            id_contato: false,
            nome_contato: '',
            telefone_contato: '',
            celular_contato: '',
            email_contato: '',
            email_nfe_contato: '',
            contato_visible: true,
            contato_position: '',
            
            tipo_pessoa: '',
            tipo_pessoa_select: [],            
            tipo_fornecedor: '',
            tipo_fornecedor_select: [],
            tipo_fornecedor_options: [],
            address: [],
            list_enderecos: [],
            list_contatos: [],
            notas_privadas: [],
            enderecos: [],
            contatos: [],

            formErros: {
                cep: false,
                endereco: false,
                numero: false,
                bairro: false,
            }
        }
    }

    handleFileChange = (event) => {
        const files = event.target.files;

        var state = this.state;
        Array.from(files).forEach(file_current => {
            state.file.inicial_processo.push(file_current);
        });
        this.setState({
            ...state
        })
    }
    onDrop = (file) => {
        var state = this.state;
        file.forEach(file_current => {
            state.file.inicial_processo.push(file_current);
        });
        this.setState({
            ...state
        })
    }

    removeAttach = (index) => {
        var state = this.state;
        state.file.inicial_processo.splice(index, 1);
        this.setState({
            ...state
        })
    }
    componentDidMount(){
        this.handleLoadData();
        if (getId() !== 'novo') {
            this.handleLoadDataFornecedor();
        } else {
            this.setState({
                
                // tipo_pessoa: 'F',
                // tipo_pessoa_select: {
                //     'label': 'Pessoa fisica',
                //     'value': 'F'
                // },
                // documento: '000.000.001-91',
                // nome: 'Fornecedor de teste',
                // codigo: '12345',
                // fornecedor_desde: '2022-06-01',
                // tipo_fornecedor: '1',
                // tipo_fornecedor_select: {
                //     'label': 'Serviços',
                //     'value': '1'
                // },
                // website: 'https://www.google.com',

                // cep: '08596-696',
                // numero: '64',

                // nome_contato: 'teste teste',
                // telefone_contato: '1112341234',
                // celular_contato: '11912341234',
                // email_contato: 'teste@teste.com',
                // email_nfe_contato: 'teste@teste.com',
            })
        }
    }

    handleLoadDataFornecedor = () => {
        var url = ENDPOINT + "financeiro/fonercedor/" + getId();
        //var url = ENDPOINT + "financeiro/fonercedores/teste";
        // var url = "http://127.0.0.1:8000/financeiro/fonercedor/" + getId();

        //console.log( url )

        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
    
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            if (p.success === true) {

                this.setState({id_fornecedor: getId()})

                Object.entries(p.data).forEach(([field, val]) => {
                    if (field !== 'enderecos') {
                        this.setState({[field]: val})
                    }
                })
                
                Object.entries(tipoPessoaOptions).forEach(([field, val]) => {
                    if (val.value == p.data.tipo_pessoa) {
                        this.setState({tipo_pessoa_select: val})
                    }
                })
                
                Object.entries(this.state.tipo_fornecedor_options).forEach(([field, val]) => {
                    if (val.value == p.data.tipo_fornecedor) {
                        this.setState({tipo_fornecedor_select: val})
                    }
                })
                
                
                let state = this.state;

                if (p.data.enderecos !== undefined && p.data.enderecos.length > 0) {
                    Object.entries(p.data.enderecos).forEach(([field, val]) => {
                        val['remove'] = false;
                        val['uf'] = val['estado'];
                        state.list_enderecos.push(val)
                    })

                    this.setState({...state})
                }


                if (p.data.contatos !== undefined && p.data.contatos.length > 0) {
                    Object.entries(p.data.contatos).forEach(([field, val]) => {
                        val['remove'] = false;
                        state.list_contatos.push(val)
                    })

                    this.setState({...state})
                }
                
            }
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!');
            console.log(error)
        })
    }

    handleLoadData = async () => {
        var url = ENDPOINT + "financeiro/fonercedor_tipo";
        // var url = "http://127.0.0.1:8000/financeiro/fonercedor_tipo";

        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
    
        var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
        };

        await fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            if (p.success !== undefined && p.success) {
                this.setState({tipo_fornecedor_options: p.data})                
            }
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!');
        })
    }

    handleChangeDocument = (e) => {
        // var documento = e.target.value.replace(/[^0-9]/g,'');

        // if (documento.length === 11 || documento.length === 14) {
        //     this.setState({loading_seller_data: true})
    
        //     var url = ENDPOINT + "user/all_aqbank?page=1&search=";
            
        //     if (documento.length === 11) {
        //         url += "&cpf="+documento;
        //     } else {
        //         url += "&cnpj="+documento;
        //     }

        //     var myHeaders = new Headers();
        //     myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        //     var myInit = {
        //     method: 'GET',
        //     headers: myHeaders,
        //     mode: 'cors',
        //     cache: 'default'
        //     };
        //     fetch(url, myInit)
        //     .then(response => response.json())
        //     .then((p) => {
        //         this.setState({loading_seller_data: false})
        //         if (p.meta.total > 0) {
        //             this.setState({seller_name: p.data[0].nome})
        //             if (p.data[0].empresa !== null) {
        //                 this.setState({seller_company: p.data[0].empresa.razao_social})
        //             }
        //             this.setState({seller_email: p.data[0].email})
        //             this.setState({seller_phone: p.data[0].telefone})
        //             this.setState({seller_data_load: true})
        //         } else {
        //             // this.setState({documento: ''})
        //             toast.error('Documento não localizado em nossa base!');
        //         }
        //     })
        //     .catch((error) => {
        //         this.setState({loading_seller_data: false})
        //         toast.error('Ocorreu um erro no servidor!');
        //     })
        // }
    }

    handleEditContato  = (contato, i) => {
        this.setState({
            id_contato: (contato.id !== undefined) ? contato.id : false,
            nome_contato: contato.nome,
            telefone_contato: contato.telefone,
            celular_contato: contato.celular,
            email_contato: contato.email,
            email_nfe_contato: contato.email_nfe,
            add_contato: true,
            contato_visible: false,
            contato_position: i,
        })
    }

    handleEditEndereco = (address, i) => {
        this.setState({
            id_endereco: (address.id !== undefined) ? address.id : false,
            cep: address.cep,
            endereco: address.endereco,
            numero: address.numero,
            complemento: address.complemento,
            bairro: address.bairro,
            cidade: address.cidade,
            estado: address.estado,
            add_address: true,
            endreco_visible: false,
            endereco_position: i,
            open_address: true,
        })
    }
    
    handleRemoveContato = (contato, i) => {
        let state = this.state;
        state.list_contatos[i]['remove'] = true;

        this.setState({
            ...state
        })

        if (this.state.id_fornecedor) {
            
            var url = ENDPOINT + "financeiro/fornecedor/" + this.state.id_fornecedor + "/contato/" + contato.id;
            // var url = "http://localhost:8000/financeiro/fornecedor/" + this.state.id_fornecedor + "/contato/" + contato.id;


            var myHeaders = new Headers();
            myHeaders.append('Authorization', 'Bearer ' + getToken());
            myHeaders.append('Content-Type', 'application/json');
    
      
            var myInit = {
                method: 'DELETE',
                body: JSON.stringify(this.state),
                headers: myHeaders,    
                mode: 'cors',
                cache: 'default'
            };
    
            fetch(url, myInit)
            .then(response => response.json())
            .then((p) => {    
                if (p.success == true) {
                    toast.success('Contato removido com sucesso!', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                } else {  
                    toast.error(p.error)
                }
    
            })
            .catch((error) => {
                toast.error('Ocorreu um erro no servidor!');
            })            
        }
    }

    handleRemoveEndereco  = (address, i) => {
        let state = this.state;

        state.list_enderecos[i]['remove'] = true;

        if (this.state.id_fornecedor && address.id) {
            var url = ENDPOINT + "financeiro/fornecedor/" + this.state.id_fornecedor + "/endereco/" + address.id;
            // var url = "http://localhost:8000/financeiro/fornecedor/" + this.state.id_fornecedor + "/endereco/" + address.id;

            var myHeaders = new Headers();
            myHeaders.append('Authorization', 'Bearer ' + getToken());
            myHeaders.append('Content-Type', 'application/json');
    
            var myInit = {
                method: 'DELETE',
                headers: myHeaders,    
                mode: 'cors',
                cache: 'default'
            };
    
            fetch(url, myInit)
            .then(response => response.json())
            .then((p) => {    
                if (p.success == true) {
                    toast.success('Contato removido com sucesso!', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {  
                    toast.error(p.error)
                }
    
            })
            .catch((error) => {
                toast.error('Ocorreu um erro no servidor!');
            })   
        }

        this.setState({
            ...state
        })
    }


    handleCancelAddEndereco = () => {
        var state = this.state;

        state.add_address = false;
        state.open_address = false;
        state.endreco_visible = false;

        state.id_endereco = false;
        state.cep = '';
        state.endereco = '';
        state.numero = '';
        state.bairro = '';
        state.complemento = '';
        state.cidade = '';
        state.estado = '';
        state.estado_select = '';
        state.estado_select = '';
        state.endreco_visible = false;
        state.endereco_position = '';
        state.open_address = false;

        this.setState({
            ...state
        })
    }

    handleCancelAddContato = () => {
        var state = this.state;

        state.add_contato = false;
        state.contato_visible = true;
        state.contato_position = '';

        state.id_contato = false;
        state.nome_contato = '';
        state.telefone_contato = '';
        state.celular_contato = '';
        state.email_contato = '';
        state.email_nfe_contato = '';
        state.contato_visible = true;
        state.contato_position = '';

        this.setState({
            ...state
        })
    }
    handleAddContato = (validInputs, position) => {
        let error = false;

        validInputs.map((input, index) => {
            let state = this.state;

            if (!this.state[input]) {
                error = true;
                state.formErros[input] = true;
                this.setState({...state});
            } else {
                state.formErros[input] = false;
                this.setState({...state});
            }
        });

        if (error) {
            return false;
        }

        // Stunato

        let contatos = {};

        contatos.id = (this.state.id_contato) ? this.state.id_contato : false;
        contatos.nome = this.state.nome_contato;
        contatos.telefone = this.state.telefone_contato;
        contatos.celular = this.state.celular_contato;
        contatos.email = this.state.email_contato;
        contatos.email_nfe = this.state.email_nfe_contato;
        contatos.remove = false;

        // update address
        if (this.state.id_contato) {
            var url = ENDPOINT + "financeiro/fornecedor/" + getId() + "/contato/" + this.state.id_contato;
            // var url = "http://127.0.0.1:8000/financeiro/fornecedor/" + getId() + "/contato/" + this.state.id_contato;
            
            
            var myHeaders = new Headers();
            myHeaders.append('Authorization', 'Bearer ' + getToken());
            myHeaders.append('Content-Type', 'application/json');
            
            var myInit = {
                method: 'PUT',
                body: JSON.stringify(contatos),
                headers: myHeaders,    
                mode: 'cors',
                cache: 'default'
            };
            
            fetch(url, myInit)
            .then(response => response.json())
            .then((p) => {
                if (p.success === true) {
                    toast.success('Contato atualizado com sucesso!', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {  
                    toast.error(p.error)
                }
    
            })
            .catch((error) => {
                toast.error('Ocorreu um erro no servidor!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
        } else if (!this.state.id_contato && getId() !== 'novo') {
            var url = ENDPOINT + "financeiro/fornecedor/" + getId() + "/contato";
            // var url = "http://127.0.0.1:8000/financeiro/fornecedor/" + getId() + "/contato";

            var myHeaders = new Headers();
            myHeaders.append('Authorization', 'Bearer ' + getToken());
            myHeaders.append('Content-Type', 'application/json');
            
            var myInit = {
                method: 'POST',
                body: JSON.stringify(contatos),
                headers: myHeaders,    
                mode: 'cors',
                cache: 'default'
            };
            
            fetch(url, myInit)
            .then(response => response.json())
            .then((p) => {
                console.log(p)

                if (p.success == true) {
                    contatos.id = p.data.id;

                    toast.success('Contato adicionado com sucesso!', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {  
                    toast.error(p.error)
                }
    
            })
            .catch((error) => {
                toast.error('Ocorreu um erro no servidor!');
            })
        }

        var state = this.state;

        if(position !== '') {
            state.list_contatos[position] = contatos;
        } else {
            state.list_contatos.push(contatos);
        }

        state.id_contato = false;
        state.nome_contato = '';
        state.telefone_contato = '';
        state.celular_contato = '';
        state.email_contato = '';
        state.email_nfe_contato = '';
        state.add_contato = false;
        state.contato_visible = true;
        state.contato_position = '';

        this.setState({
            ...state
        })
    }
    
    handleAddEndereco = (validInputs, position) => {
        let error = false;

        validInputs.map((input, index) => {
            let state = this.state;

            if (!this.state[input]) {
                error = true;
                state.formErros[input] = true;
                this.setState({...state});
            } else {
                state.formErros[input] = false;
                this.setState({...state});
            }
        });

        if (error) {
            return false;
        }

        let enderecos = {};

        enderecos.id = (this.state.id_endereco) ? this.state.id_endereco : false;
        enderecos.cep = this.state.cep;
        enderecos.endereco = this.state.endereco;
        enderecos.numero = this.state.numero;
        enderecos.complemento = this.state.complemento;
        enderecos.bairro = this.state.bairro;
        enderecos.cidade = this.state.cidade;
        enderecos.estado = this.state.estado;
        enderecos.remove = false;

        // update address
        if (this.state.id_endereco) {
            var url = ENDPOINT + "financeiro/fornecedor/" + getId() + "/endereco/" + this.state.id_endereco;
            // var url = "http://127.0.0.1:8000/financeiro/fornecedor/" + getId() + "/endereco/" + this.state.id_endereco;

            var myHeaders = new Headers();
            myHeaders.append('Authorization', 'Bearer ' + getToken());
            myHeaders.append('Content-Type', 'application/json');
            
            var myInit = {
                method: 'PUT',
                body: JSON.stringify(enderecos),
                headers: myHeaders,    
                mode: 'cors',
                cache: 'default'
            };
            
            fetch(url, myInit)
            .then(response => response.json())
            .then((p) => {
                if (p.success == true) {
                    toast.success('Endereço atualizado com sucesso!', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {  
                    toast.error(p.error)
                }
    
            })
            .catch((error) => {
                toast.error('Ocorreu um erro no servidor!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
        }
        else if (!this.state.id_endereco && getId() !== 'novo') {
            var url = ENDPOINT + "financeiro/fornecedor/"  + getId() + "/endereco";
            // var url = "http://127.0.0.1:8000/financeiro/fornecedor/" + getId() + "/endereco";

            var myHeaders = new Headers();
            myHeaders.append('Authorization', 'Bearer ' + getToken());
            myHeaders.append('Content-Type', 'application/json');
            
            var myInit = {
                method: 'POST',
                body: JSON.stringify(enderecos),
                headers: myHeaders,    
                mode: 'cors',
                cache: 'default'
            };
            
            fetch(url, myInit)
            .then(response => response.json())
            .then((p) => {
                if (p.success == true) {
                    enderecos.id = p.data.id;
                    toast.success('Endereço salvo com sucesso!', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {  
                    toast.error(p.error)
                }
            })
            .catch((error) => {
                toast.error('Ocorreu um erro no servidor!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
        }

        var state = this.state;

        if(position !== '') {
            state.list_enderecos[position] = enderecos;
        } else {
            state.list_enderecos.push(enderecos);
        }

        state.id_endereco = false;
        state.cep = '';
        state.endereco = '';
        state.numero = '';
        state.complemento = '';
        state.bairro = '';
        state.cidade = '';
        state.estado = '';
        state.add_address = false;
        state.endreco_visible = true;
        state.endereco_position = '';

        this.setState({
            ...state
        })
    }

    handlerChangeBrazilianPhone = (e, input) => {
        const brazilianPhone = e.target.value.replace(/[^0-9]+/g, '')
        this.setState({ [input]:  brazilianPhone})
    }
    
    handleCepChange = (data) => {
        var state = this.state;
        state['endereco'] = data.logradouro;
        state['complemento'] = data.complemento;
        state['bairro'] = data.bairro;
        state['cidade'] = data.localidade;
        state['uf'] = data.uf;
        state['load_address'] = false;
        state['open_address'] = true;
        
        this.setState({...state});
        this.handleInputChangeEnderecoSelect(data.estado_select)
    }

    handleInputChangeEnderecoSelect = (estado_select) => {
        this.setState({estado_select})
        this.setState({estado: estado_select.value});
    }

    handleInputChangeEndereco = (e) => {
        const { name, value } = e.target; 
        var state = this.state;
        state[name] = value;
        this.setState({...state});
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;    
        this.setState({ [name]: value });
    }

    handleChangeMotivo = (e) => {
        this.setState({e});
        const name = e.label;
        const value= e.value;

        this.setState({ motivo: value });
        this.setState({ motivo_select: e });

        if (value == 2) {
            this.setState({is_open_reclamado: true})
            this.setState({is_open_reclamante: false})
        } else {
            this.setState({is_open_reclamado: false})
            this.setState({is_open_reclamante: true}) 
        }
    }

    handleSendFormFornecedor = () => {
        var url = ENDPOINT + "financeiro/enviar";
        // var url = "http://127.0.0.1:8000/financeiro/enviar";


        this.setState({loading_page: true, loading_btn_save_form: true, show_form: false})

        // var formData = new FormData();

        if (getId() != 'novo') {
            this.setState({id_fornecedor: getId()})
        } else {
            this.setState({id_fornecedor: null})
        }


        this.setState({enderecos: []})
        Object.entries(this.state.list_enderecos).forEach(([field, val]) => {
            this.state.enderecos.push( JSON.stringify(Object.assign({}, val)) )
        })

        this.setState({contatos: []})

        Object.entries(this.state.list_contatos).forEach(([field, val]) => {
            this.state.contatos.push( JSON.stringify(Object.assign({}, val)) )
        })

        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        myHeaders.append('Content-Type', 'application/json');

        var myInit = {
            method: 'POST',
            body: JSON.stringify(this.state),
            headers: myHeaders,    
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            console.log(p)
            this.setState({loading_btn_save_form: false})
            this.setState({loading_page: false})

            if (p.success == true) {
                toast.success('Salvo com sucesso!')                
                setTimeout(function(){
                    window.location = '/fornecedor';
                }, 1000)
            } else {  
                toast.error(p.error)
                this.setState({loading_page: false, loading_btn_save_form: false, show_form: true})
            }

        })
        .catch((error) => {
            this.setState({loading_page: false, loading_btn_save_form: false, show_form: true})
            toast.error('Ocorreu um erro no servidor!');

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              //console.log(error.config);
        })
        // .catch((error) => {
        //     this.setState({loading_page: false, loading_btn_save_form: false, show_form: true})
        //     toast.error('Ocorreu um erro no servidor!');
        // })

        // axios.post(url, formData, {
        //     headers: {
        //         Authorization: 'Bearer '+getToken()
        //     }})
        // .then((response) => {
        //     console.log(response)
        // })
        // .catch((error) => {
        //     this.setState({loading_btn_save_form: false})
        //     this.setState({loading_page: false})
        //     toast.error('Ocorreu um erro no servidor!');
        // });
    }

    onData(data){
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }

    deletarNota = (id) => {
        this.setState({
            loading_nota_id: id
        });
        
        // var url = ENDPOINT+"notas/delete/"+id;
        // axios.delete(url, {
        //     headers: {
        //         Authorization: 'Bearer '+getToken()
        //     }
        // })
        // .then(() => {
        //     this.getNotas(this.state.id);
        //     toast.success('Deletado com sucesso');
        //     this.setState({
        //         loading_nota_id: null
        //     });
        // })
        // .catch((error) => {
        //     var data = error.response.data;
        //     toast.error(data.errors);
        //     this.setState({
        //         loading_nota_id: null
        //     });
        // })
    }

    hiddenDelete = (id) => {
        /* var permissao = getPermissoes().flag;
        var id_permissao = getPermissoes().id; */

        
        var permissao = getUser().access_system;
        var id_permissao = getUser().id;

        if(permissao === 'super_user'
         || permissao === 'diretoria'
         || permissao === 'supervisor_setor'){
             return true;
         }
         else if(id_permissao === id){
            return true;
         }

         return false;
    }

    handleAddNota = () => {
        var url = ENDPOINT + "financeiro/nota";

        var formData = new FormData();

        formData.append('id_processo', getId());
        formData.append('nota_mensagem', this.state.nota_mensagem);

        this.setState({loading_btn_add_nota: true})

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var notas_priv = this.state.notas_privadas;
            notas_priv.push(response.data.data);

            this.setState({notas_privadas: notas_priv});
            this.setState({is_open_nota: false});
            this.setState({nota_mensagem: null});
            this.setState({loading_btn_add_nota: false})
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!');
        })
    }

    render(){
        return(
            <Div>
                <h3>
                    <Button 
                        outline 
                        style={{border: 'none'}}
                        onClick={() => window.location.href = '/fornecedor'}
                    >
                        <MdArrowBack style={{padding: 0}}/>
                    </Button>&nbsp;&nbsp;Cadastro de Fornecedor
                </h3>
                <hr />
                <Row hidden={!this.state.loading_page}>
                    <Col md={12}>
                        <center>
                            <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                        </center>
                        <br/>
                    </Col>
                </Row>
            <Div hidden={!this.state.show_form}>
                <Row hidden={!this.state.loading_seller_data}>
                    <Col md={12}>
                        <center>
                            <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                        </center>
                        <br/>
                    </Col>
                </Row> 
                <Row>
                    <Col md={12}>
                        <Button 
                            className="btn btn-success btn-save" 
                            onClick={this.handleSendFormFornecedor}>
                            {this.state.loading_btn_save_form === true ?
                                <>
                                    <ClipLoader color="#fff" size={12}/>
                                    &nbsp;Salvando...
                                </>
                            : 
                                'Salvar Fornecedor'
                            }
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                            <h5>Dados Cadastrais</h5><hr/>

                            <Row>
                                <Col md={6}>
                                    <label htmlFor="tipo_pessoa">Tipo de pessoa</label>
                                    <Select
                                    name="tipo_pessoa"
                                    placeholder="Selecione..."
                                    options={tipoPessoaOptions}
                                    onChange={(tipo_pessoa_select) => {
                                        this.setState({tipo_pessoa_select})
                                        this.setState({tipo_pessoa: tipo_pessoa_select.value});
                                    }}

                                    value={this.state.tipo_pessoa_select}
                                        />
                                </Col>
                                <Col md={6}>
                                    <Collapse isOpen={this.state.tipo_pessoa !== 'E'}>
                                        <label htmlFor="documento">Documento</label>
                                        <InputMasked
                                            name="documento"
                                            mask={this.state.tipo_pessoa === 'F' ? '999.999.999-99' : '99.999.999/9999-99'}
                                            placeholder={this.state.tipo_pessoa !== 'J' ? '000.000.000-00' : '00.000.000/0000-00'}
                                            value={this.state.documento}
                                            onChange={this.handleInputChange}
                                            onBlur={this.handleChangeDocument}
                                        />
                                    </Collapse>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <label htmlFor="nome">
                                        {this.state.tipo_pessoa === 'J' ? 'Razão social' : 'Nome'}
                                    </label>
                                    <Input
                                        name="nome"
                                        placeholder="Nome do fornecedor"
                                        value={this.state.nome}
                                        type="text"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                                <Col md={6}>
                                    <label htmlFor="codigo">Código</label>
                                    <Input
                                        name="codigo"
                                        placeholder="Identificador do fornecedor"
                                        value={this.state.codigo}
                                        type="text"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </Row>
                            <Collapse isOpen={this.state.tipo_pessoa === 'J'}>
                                <Row>
                                    <Col md={6}>
                                            <label htmlFor="nome_fantasia">Nome fantasia</label>
                                            <Input
                                                name="nome_fantasia"
                                                placeholder="Nome fantasia do fornecedor"
                                                value={this.state.nome_fantasia}
                                                type="text"
                                                onChange={this.handleInputChange}
                                            />
                                    </Col>
                                    <Col md={6}>
                                        <label htmlFor="inscricao_estadual">Inscrição estadual</label>
                                        <Input
                                            name="inscricao_estadual"
                                            placeholder=""
                                            value={this.state.inscricao_estadual}
                                            type="text"
                                            onChange={this.handleInputChange}
                                        />
                                    </Col>
                                </Row>
                            </Collapse>
                            <Row>
                                <Col md={6}>
                                    <label htmlFor="fornecedor_desde">Fornecedor desde</label>
                                    <Input
                                        name="fornecedor_desde"
                                        placeholder=""
                                        value={this.state.fornecedor_desde}
                                        type="date"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                                <Col md={6}>
                                    <label htmlFor="tipo_fornecedor">Tipo de fornecedor</label>
                                    <Select
                                    name="tipo_fornecedor"
                                    placeholder="Selecione..."
                                    options={tipoFornecedorOptions}
                                    onChange={(tipo_fornecedor_select) => {
                                        this.setState({tipo_fornecedor_select})
                                        this.setState({tipo_fornecedor: tipo_fornecedor_select.value});
                                    }}

                                    value={this.state.tipo_fornecedor_select}
                                        />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <label htmlFor="website">Website</label>
                                    <Input
                                        name="website"
                                        className="website"
                                        placeholder=""
                                        value={this.state.website}
                                        type="text"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </Row>
                
                </div>
                </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                            <h5>
                                Endereços

                                <Collapse isOpen={!this.state.add_address}>
                                    <Button 
                                        className="add-address-btn"
                                        color="success"
                                        onClick={() => this.setState({add_address: !this.state.add_address, endreco_visible: false})}>
                                            <FaPlus /> Adicionar
                                    </Button>
                                </Collapse>                              
                            </h5><hr/>
                            <Row>
                                {this.state.list_enderecos.length > 0 ? this.state.list_enderecos.map((address, i) => {
                                    return (
                                        <Col md={6} className="col-cards" hidden={address.remove}>
                                        {this.state.endreco_visible === true ?
                                            <div  className="card">
                                                <h6>{address.cidade} - {address.estado}</h6>
                                                <div  className="card-box">
                                                    <div>{address.cep}</div>
                                                    <div>{address.endereco}, {address.numero}</div>
                                                    <div>{address.complemento}</div>
                                                    <div>{address.bairro} - {address.cidade}</div>
                                                    <div>{address.estado}</div>
                                                    <div>
                                                        <Button
                                                            className="button-remove"
                                                            color="danger"
                                                            outline
                                                            onClick={(event) => {this.handleRemoveEndereco(address, i)}}>
                                                            
                                                            <FaTrash /> Remover
                                                        </Button>

                                                        <Button
                                                            className="button-edit"
                                                            color="info"
                                                            outline
                                                            onClick={(event) => {this.handleEditEndereco(address, i)}}>
                                                            
                                                            <FaPen /> Editar
                                                        </Button>


                                                    </div>
                                                </div>
                                            </div>
                                        :
                                        <></>
                                        }
                                        </Col>
                                    )
                                })
                                :
                                <></>
                                }
                                </Row>
                                <Collapse isOpen={this.state.add_address}>
                                    <Enderecos 
                                        handleInputChangeEndereco={this.handleInputChangeEndereco}
                                        handleInputChangeEnderecoSelect={this.handleInputChangeEnderecoSelect}
                                        handleAddEndereco={this.handleAddEndereco}
                                        handleCepChange={this.handleCepChange}
                                        handleCancelAddEndereco={this.handleCancelAddEndereco}
                                        state={this.state}
                                    />
                                </Collapse>  
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                            <h5>
                                Contatos
                                <Button 
                                    hidden={this.state.add_contato}
                                    className="add-address-btn"
                                    color="success"
                                    onClick={(e) => {this.setState({open_contato: !this.state.open_contato, add_contato: !this.state.add_contato, contato_visible: false})}}
                                    >
                                    <FaPlus /> Adicionar
                                </Button>                               
                            </h5><hr/>
                            <Row>
                                {this.state.list_contatos.length > 0 ? this.state.list_contatos.map((contato, i) => {
                                    return (
                                        <Col md={6} className="col-cards" hidden={contato.remove}>
                                        {this.state.contato_visible === true ?
                                            <div  className="card">
                                                <h6>{contato.nome}</h6>
                                                <div  className="card-box">
                                                    <div><strong>E-mail: </strong>{contato.email}</div>
                                                    <div><strong>NFe: </strong>{contato.email_nfe}</div>
                                                    <div><strong>Telefone: </strong>{contato.telefone}</div>
                                                    <div><strong>Celular: </strong>{contato.celular}</div>
                                                    <div>
                                                        <Button
                                                            className="button-remove"
                                                            color="danger"
                                                            outline
                                                            onClick={(event) => {this.handleRemoveContato(contato, i)}}>
                                                            
                                                            <FaTrash /> Remover
                                                        </Button>

                                                        <Button
                                                            className="button-edit"
                                                            color="info"
                                                            outline
                                                            onClick={(event) => {this.handleEditContato(contato, i)}}>
                                                            
                                                            <FaPen /> Editar
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                        <></>
                                        }
                                        </Col>
                                    )
                                })
                                :
                                <></>
                                }
                            </Row>
                            <Collapse isOpen={this.state.add_contato}>
                                <Contatos 
                                    handleInputChange={this.handleInputChange}    
                                    handleAddContato={this.handleAddContato}   
                                    handlerChangeBrazilianPhone={this.handlerChangeBrazilianPhone}   
                                    handleCancelAddContato={this.handleCancelAddContato}   
                                    state={this.state}       
                                />
                            </Collapse>
                        </div>
                    </Col>
                </Row>
                <Row>
                <Col md={12}>
                <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                    <h5>Notas</h5><hr/>
                    <ul style={{listStyleType: 'circle'}}>
                        {this.state.notas_privadas.map(p => {
                            return <li key={p.id} style={{marginBottom: '7px'}}>
                                <b>Operador:</b> {p.operador !== null ? p.operador : 'Sem Informações'} <br/>
                                <div style={{border: '1px solid #7f7f7f66', borderRadius: 5, padding: '15px'}}>
                                    {p.nota.split('\n').map((x, i, {length}) => (
                                        <p style={{margin: (i + 1 === length ? 0 : 3), fontSize: 14}}>{x}</p>
                                    ))}
                                </div>
                                <small>
                                    {moment(p.data).format('DD/MM/YYYY HH:mm')}&nbsp;
                                    <ButtonRed 
                                        hidden={!this.hiddenDelete(p.admin_id)}
                                        disabled={this.state.loading_nota_id === p.id}
                                        onClick={() => this.deletarNota(p.id)}>
                                            Apagar        
                                    </ButtonRed>
                                </small>
                            </li>
                        })}
                    </ul>
                    {this.state.notas_privadas.length === 0 ? <p>Nenhuma nota</p> : ''}
                    <Button 
                        color="success" 
                        onClick={() => this.setState({is_open_nota: !this.state.is_open_nota})}>
                            <FaPen /> Adicionar Nota
                    </Button>
                    <Collapse isOpen={this.state.is_open_nota}>
                        <br/>
                        <Card>
                            <CardBody>
                                <Input
                                    name="nota_mensagem"
                                    placeholder="Descrição"
                                    value={this.state.nota_mensagem}
                                    type="textarea"
                                    style={{
                                        height: '124px',
                                        resize: 'none'
                                    }}
                                    onChange={this.handleInputChange}
                                />
                                {this.state.notas_privadas.length > 0 ? 
                                    <Button 
                                        className="btn btn-success btn-nota"
                                        onClick={this.handleAddNota}>
                                        {this.state.loading_btn_add_nota === true ?
                                            <>
                                                <ClipLoader color="#fff" size={12}/>
                                                &nbsp;Adicionando...
                                            </>
                                        : 
                                            'Adicionar.'

                                        }
                                    </Button>
                                : 
                                ''
                                }

                            </CardBody>
                        </Card>
                    </Collapse>

                </div>
                    
                </Col>
                </Row>


                <br/>
            <br/>
                <Row>
                    <Col md={12}>
                        <Button 
                            className="btn btn-success btn-save" 
                            onClick={this.handleSendFormFornecedor}>
                            {this.state.loading_btn_save_form === true ?
                                <>
                                    <ClipLoader color="#fff" size={12}/>
                                    &nbsp;Salvando...
                                </>
                            : 
                                'Salvar Fornecedor'
                            }
                        </Button>
                    </Col>
                </Row>
            </Div>
            <br/>
            <br/>
            </Div>

        )
    }
}

export default ResumoFornecedor;