import Moment from 'moment';
import { Col, Row } from 'reactstrap';
import GoogleMapReact from 'google-map-react';
import { FaEye, FaMapMarkerAlt, FaSearch } from "react-icons/fa";

const AnyReactComponent = ({ text }) => <div style={{width: '126px', textAlign: 'center', fontWeight: '800'}}><FaMapMarkerAlt style={{color: 'red'}}/> <br/>{text}</div>;

const getTypeSession = (type) => {
    switch(type) {
        case 'register':
            return 'Cadastro'
            break;
        case 'login':
            return 'Login';
            break;
        default:
            return type;
            break;
    }
}

export const columns = (openMaps) => [
    {
        name: "Resultados",
        selector: "buttons",
        sortable: false,
        width: '100%',
        cell: row => <div style={{paddingTop: '0.5rem',paddingBottom: '0.5rem', width: '100%'}}>
            <Row>
                <Col md={3}>
                    <h3>Dados</h3>
                    <p><strong>IP:</strong> {row.ip}</p>
                    <p><strong>Tipo:</strong> {getTypeSession(row.type)}</p>
                    <p><strong>Celular:</strong> {row.mobile ? 'Sim' : 'Não'}</p>
                    <p><strong>Criado:</strong> {Moment(new Date(row.created_at)).format('DD/MM/YYYY HH:mm')}</p>
                    <p><strong>Atualizado:</strong> {Moment(new Date(row.updated_at)).format('DD/MM/YYYY HH:mm')}</p>
                </Col>
                <Col md={3}>
                    <h3>Internet</h3>
                    <p><strong>ISP:</strong> {row.isp}</p>
                    <p><strong>Organização:</strong> {row.org}</p>
                    <p><strong>Apelido:</strong> {row.as}</p>
                </Col>
                <Col md={2}>
                    <h3>Localização</h3>
                    <p><strong>Cidade:</strong> {row.city}</p>
                    <p><strong>Bairro:</strong> {row.district}</p>
                    <p><strong>Estado:</strong> {row.region}</p>
                    <p><strong>Cep:</strong> {row.zip}</p>
                    <p><strong>País:</strong> {row.country}</p>
                    <p><strong>Latitude:</strong> {row.lat}</p>
                    <p><strong>Longitude:</strong> {row.lon}</p>
                </Col>
                <Col md={4}>
                    <h3>Mapa</h3>
                    <div style={{ height: '340px', width: '100%' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyCNBu6Af086YRI-3EdGrmsqgdBvjfL7Mdg" }}
                            defaultCenter={{
                                lat: Number.parseFloat(row.lat),
                                lng: Number.parseFloat(row.lon)
                            }}
                            defaultZoom={11}
                            >
                            <AnyReactComponent
                                lat={Number.parseFloat(row.lat)}
                                lng={Number.parseFloat(row.lon)}
                                text={row.city + ' - ' + row.region}
                            />
                        </GoogleMapReact>
                    </div>
                </Col>

            </Row>
        </div>
      },
  ];

