import { Component } from "react";
import { Button, Col, Form, FormFeedback, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Cards from 'react-credit-cards';
import "react-credit-cards/es/styles-compiled.css"
import { InputText } from "../../InputText/InputText";
import { InputMasked } from "../../InputText/InputMasked";
import NumberFormat from "react-number-format";
import { ENDPOINT } from "../../../auth/endpoint";
import axios from "axios";
import { getToken } from "../../../auth/set_services";
import ModelReturn from "../ReturnsApiModals/ModelReturn";

const creditCard = (state, id, onData) => {
    var url = ENDPOINT+"cartao/store";

    var formData = new FormData();
    formData.append('prop_id', id);
    formData.append('prop_cartao', state.name);
    formData.append('validade_mes', state.expiry.substr(0, 2));
    formData.append('validade_ano', state.expiry.substr(-2, 2));
    formData.append('num_cartao', state.number.replace(/\s/g, ''));
    formData.append('prop_cpf', state.cpf.replace(/\s/g, ''));
    formData.append('address[cep]', state.address_cep);
    formData.append('address[logradouro]', state.address_logradouro);
    formData.append('address[numero]', state.address_numero);
    formData.append('address[complemento]', state.address_complemento);
    formData.append('address[bairro]', state.address_bairro);
    formData.append('address[uf]', state.address_estado);
    formData.append('address[cidade]', state.address_cidade);
    
    axios.post(url, formData, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }
    })
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });

}
const getCep = (cep, onData) => {
    var url = ENDPOINT + "user/search_cep?cep=" + cep;

    axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getToken()
        }
    })
        .then((response) => {
            var data = response.data;
            onData(data);
            return data;
        })
        .catch((error) => {
            if (error.message === 'Network Error') {
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
            var data = error.response.data;
            onData(data);
            return data;
        });

}
class AddCreditCard extends Component{
    constructor(props){
        super(props);
        this.state = {
            cvc: '',
            expiry: '',
            focus: '',
            name: '',
            number: '',
            cpf: '',
            cpf_invalid: false,
            disabled_address: false,
            address_cep: '',
            address_cep_invalid: false,
            address_logradouro: '',
            address_logradouro_invalid: false,
            address_numero: '',
            address_numero_invalid: false,
            address_complemento: '',
            address_complemento_invalid: false,
            address_bairro: '',
            address_bairro_invalid: false,
            address_estado: '',
            address_estado_invalid: false,
            address_cidade: '',
            address_cidade_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        }
    }
    handleInputFocus = (e) => {
      this.setState({ focus: e.target.name });
    }
    handleInputChange = (e) => {
      const { name, value } = e.target;
      
      this.setState({ [name]: value });
      
      if(name === 'address_cep'){
            if(value.replace('_', '').length > 8){
                getCep(value, this.onCep.bind(this));
            }
      }
    }
    onCep(data){
        if(data.success === true){
            this.setState({
                disabled_address: false,
                address_logradouro: data.data.end,
                address_bairro: data.data.bairro,
                address_cidade: data.data.cidade,
                address_estado: data.data.uf,
            })
        }
    }

    onRegister(){
        //if(this.validFields() === 0){
            this.setState({loading: true})
            creditCard(this.state, this.props.user_id, this.onReturn.bind(this))
        //}
    }
    onReturn(data) {
        if (data.success === true) {
            this.setState({
                success_message: data.message
            })
        }
        else {
            this.setState({
                error_message: data.errors
            })
        }
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }
    onClose() {
        this.setState({
            cvc: '',
            expiry: '',
            focus: '',
            name: '',
            number: '',
            cpf: '',
            cpf_invalid: false,
            disabled_address: false,
            address_cep: '',
            address_cep_invalid: false,
            address_logradouro: '',
            address_logradouro_invalid: false,
            address_numero: '',
            address_numero_invalid: false,
            address_complemento: '',
            address_complemento_invalid: false,
            address_bairro: '',
            address_bairro_invalid: false,
            address_estado: '',
            address_estado_invalid: false,
            address_cidade: '',
            address_cidade_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose(false);
    }
    render(){
        return(
            <Modal size={'lg'} isOpen={this.props.visible}>
                <ModalHeader>
                    Adicionar novo cartão
                </ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <div id="PaymentForm">
                        <br/>
                        <Form>
                            <Row>
                                <Col>
                                    <Cards
                                        cvc={this.state.cvc}
                                        expiry={this.state.expiry}
                                        focused={this.state.focus}
                                        name={this.state.name}
                                        number={this.state.number}
                                    />
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <NumberFormat
                                                        customInput={InputText}
                                                        type="tel"
                                                        name="number"
                                                        placeholder="Número de Cartão de Crédito"
                                                        onChange={this.handleInputChange}
                                                        onFocus={this.handleInputFocus}
                                                        format="#### #### #### ####"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <InputText
                                                        type="text"
                                                        name="name"
                                                        placeholder="Nome (Como está no cartão)"
                                                        onChange={this.handleInputChange}
                                                        onFocus={this.handleInputFocus}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <InputMasked
                                                    type="text"
                                                    name="expiry"
                                                    mask={'99/99'}
                                                    placeholder="Validade"
                                                    onChange={this.handleInputChange}
                                                    onFocus={this.handleInputFocus}
                                                />
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <NumberFormat
                                                        customInput={InputText}
                                                        type="tel"
                                                        name="cvc"
                                                        placeholder="CVC"
                                                        onChange={this.handleInputChange}
                                                        onFocus={this.handleInputFocus}
                                                        format="###"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <InputMasked
                                            name="cpf"
                                            value={this.state.cpf}
                                            type="text"
                                            placeholder="CPF"
                                            invalid={this.state.cpf_invalid}
                                            onChange={this.handleInputChange}
                                            onFocus={this.handleInputFocus}
                                        />
                                        <FormFeedback>Informe o CPF do Titular</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <InputMasked
                                            name="address_cep"
                                            value={this.state.address_cep}
                                            type="text"
                                            mask="99999-999"
                                            placeholder="CEP"
                                            invalid={this.state.address_cep_invalid}
                                            onChange={this.handleInputChange}
                                            onFocus={this.handleInputFocus}
                                        />
                                        <FormFeedback>Informe o CEP para continuar</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputText
                                            name="address_logradouro"
                                            value={this.state.address_logradouro}
                                            type="text"
                                            placeholder="Logradouro"
                                            disabled={this.state.disabled_address}
                                            invalid={this.state.address_logradouro_invalid}
                                            onChange={this.handleInputChange}
                                            onFocus={this.handleInputFocus}
                                        />
                                        <FormFeedback>Informe o Logradouro</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputText
                                            name="address_complemento"
                                            value={this.state.address_complemento}
                                            type="text"
                                            placeholder="Complemento"
                                            disabled={this.state.disabled_address}
                                            invalid={this.state.address_complemento_invalid}
                                            onChange={this.handleInputChange}
                                            onFocus={this.handleInputFocus}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <InputText
                                            name="address_numero"
                                            value={this.state.address_numero}
                                            type="text"
                                            placeholder="Número"
                                            disabled={this.state.disabled_address}
                                            invalid={this.state.address_numero_invalid}
                                            onChange={this.handleInputChange}
                                            onFocus={this.handleInputFocus}
                                        />
                                        <FormFeedback>Informe o Número residencial</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <InputText
                                            name="address_bairro"
                                            value={this.state.address_bairro}
                                            type="text"
                                            placeholder="Bairro"
                                            disabled={this.state.disabled_address}
                                            invalid={this.state.address_bairro_invalid}
                                            onChange={this.handleInputChange}
                                            onFocus={this.handleInputFocus}
                                        />
                                        <FormFeedback>Informe o Bairro</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputText
                                            name="address_estado"
                                            value={this.state.address_estado}
                                            type="text"
                                            placeholder="UF"
                                            maxlength={2}
                                            disabled={this.state.disabled_address}
                                            invalid={this.state.address_estado_invalid}
                                            onChange={this.handleInputChange}
                                            onFocus={this.handleInputFocus}
                                        />
                                        <FormFeedback>Informe o Estado</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputText
                                            name="address_cidade"
                                            value={this.state.address_cidade}
                                            type="text"
                                            placeholder="Cidade"
                                            disabled={this.state.disabled_address}
                                            invalid={this.state.address_cidade_invalid}
                                            onChange={this.handleInputChange}
                                            onFocus={this.handleInputFocus}
                                        />
                                        <FormFeedback>Informe a cidade</FormFeedback>          
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </ModalBody>
                <ModalBody
                    hidden={!this.state.loading}
                    style={{
                        minHeight: '114px',
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose.bind(this)}
                        onError={this.onError.bind(this)}
                    />

                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={this.onClose.bind(this)}>Cancelar</Button>
                    <Button color="success" onClick={this.onRegister.bind(this)}>Cadastrar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default AddCreditCard;