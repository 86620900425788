import React from 'react';
import { CardBody, Col, Row } from 'reactstrap';
import logoAqbank from '../../assets/aqbank-white.webp';
import styled from 'styled-components';
import PaymentIcon from 'react-payment-icons';

const ImageLogo = styled.img`
    width: 82px;
`;
const CardTaggy = styled.div`
    background: black;
    border-radius: 22px;
    border: 0; 
    width: 367px;
    
    ${({ status }) => status && `
        background: linear-gradient(to left, #3b0051 , #7f00b0);
    `}
`

const tratarIcone = (bandeira) => {
    if(bandeira === 'hipercard'){
        return <PaymentIcon transparent id={bandeira} color="white" style={{width: '79px', margin: '-11px'}}/>
    }
    if(bandeira === 'american_express'){
        return <PaymentIcon id='amex' color="white" style={{width: '79px', margin: '-11px'}}/>
    }
    return <PaymentIcon id={bandeira} color="white" style={{width: '79px', margin: '-11px'}}/>
}
const StyleCreditCard = (props) => {
    return (
        <div style={{padding: '15px'}}>
            <CardTaggy status={props.status} className="card">
                <CardBody>
                    <Row>
                        <Col style={{color: 'white', fontSize: '17px'}}>
                            <ImageLogo src={logoAqbank} />
                        </Col>
                    </Row>
                    <Row>
                        <Col 
                            md={9}
                            style={{color: 'white', alignSelf: 'center', textAlign: 'right',fontWeight: '400'}}>
                                <h5>**** **** **** {props.numero_cartao}</h5>
                                <p><small style={{color: '#dc3545'}}>{props.status === 0 ? 'Cartão Inativado' : ''}</small> Val: {props.validade}</p>
                            </Col>
                        <Col md={3}>
                            <br/>
                            {tratarIcone(props.bandeira)}
                        </Col>
                    </Row>
                </CardBody>
            </CardTaggy>

        </div>
    )
}

export default StyleCreditCard;