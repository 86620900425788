import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import Moment from 'moment';
import { Button } from 'reactstrap';
import ecommerce_logo from '../../../assets/ecommerce-aqpago.svg'
import { toast } from 'react-toastify';
import { HiViewGrid } from 'react-icons/hi';

const maquininhas_list = [
  {
      modelo: 'MP5',
      label: 'Maquininha MP5',
      value: 'MP5',
      valor: 199.00,
      image: 'https://aqpago.com.br/assets/img/maquinas/mp5.webp'
  },
  {
      modelo: 'D195',
      label: 'Maquininha D195',
      value: 'D195',
      valor: 399.00,
      image: 'https://aqpago.com.br/assets/img/maquinas/d195.webp'
  },
  {
      modelo: 'MP35P',
      label: 'Maquininha MP35P',
      value: 'MP35P',
      valor: 999.00,
      image: 'https://aqpago.com.br/assets/img/maquinas/d195.webp'
  },
  {
      modelo: 'S920',
      label: 'Maquininha S920',
      value: 'S920',
      valor: 599.00,
      image: 'https://aqpago.com.br/assets/img/maquinas/s920.webp'
  },
  {
      modelo: 'linkpay',
      label: 'Link de Pagamento',
      value: 'linkpay',
      valor: 99.00,
      image: 'https://aqpago.com.br/assets/img/home/melhor-escolha/link-pagamento/link-pagamento.svg'
  },
  {
      modelo: 'shopaqpago',
      label: 'E-commerce',
      value: 'shopaqpago',
      valor: 99.00,
      image: ecommerce_logo
  }
];

const forma_pagamento = [
    { value: "pix", label: "PIX" },
    { value: "boleto", label: "Boleto" },
    { value: "credit", label: "Crédito" },
];

const check_eccomerce = (produtos) => {
  var existe_loja = false;

  produtos.forEach(p => {
    if (p.produto.toLowerCase() == 'shopaqpago') {
      existe_loja = true;
    }
  });

  return existe_loja;

}
const get_remessa_info = (p) => {
  var remessa_id = [];
  p.produtos.map(e => {
    if (e.remessa != null) {
      remessa_id.push(e.remessa.status_entrega)
    }

  });

  var remessas = remessa_id.filter(function (este, i) {
    return remessa_id.indexOf(este) === i;
  });

  return remessas;
}

const get_remessa = (p, openRemessa) => {
  var pendentes = 0;
  var remessa_id = [];
  p.produtos.map(e => {
    if (e.remessa != null) {
      remessa_id.push(e.remessa.id)
    }
    else {
      pendentes++;
    }

  });

  var remessas = remessa_id.filter(function (este, i) {
    return remessa_id.indexOf(este) === i;
  });

  return <>{pendentes > 0 ? <>
    <Button
      style={{ padding: 5, margin: 5, fontSize: '13px' }}
      outline
      color="primary" onClick={() => openRemessa(true, p)}>
      Nova Remessa
    </Button>
  </> : <></>}
    {remessas.map((e, index) => {
      return <>
        <Button
          style={{ padding: 5, margin: 5, fontSize: '13px' }}
          outline
          color="primary" onClick={() => {
            window.location.href = '/resumo-postagem/' + e
          }
          }>
          Remessa {index + 1}
        </Button>
      </>

    })}
  </>
}
export const columns = (openRemessa) => [
    {
      name: "Data da Venda",
      selector: "created_at",
      sortable: false,
      width: '150px',
      cell: p => Moment(p.created_at).format('DD/MM/YYYY HH:mm')
    },
    {
      name: "ID da Transação",
      selector: "id_transacao",
      sortable: false,
      width: '270px',
      cell: p => <div style={{margin: 5, width: '100%'}}>
                    {p.id_transacao}<br/>
                    <center>
                      {p.user !== null ?
                          p.user.nome + ' '+p.user.sobrenome
                      :null}
                      {p.user !== null && p.user.empresa !== null ?
                        <>
                          <br/>
                          <b>Empresa: </b>{p.user.empresa == null ? p.user.empresa.razao_social : ''}
                        </>
                      :null}
                    </center>
                    <hr style={{margin: 5, width: '100%'}}/>
                    <center>
                      {p.user !== null ?
                        p.user.endereco_id == null ?
                        <Button 
                            style={{padding: 5, margin: 5, fontSize: '13px'}} 
                            color="success"
                            outline
                            onClick={() => 
                                {
                                    window.location.href = '/user-pre-venda/'+p.user.id
                                }
                            }
                            >Cadastrar endereço</Button>
                          :
                          null
                      :null}
                      <Button 
                          style={{padding: 5, margin: 5, fontSize: '13px'}} 
                          color="info"
                          outline
                          hidden={!p.id_transacao}
                          onClick={() => 
                              {
                                  window.open('/venda/'+p.id, '_blank')
                              }
                          }
                          >Ver transação</Button>
                    {p.produto !== null ?
                        get_remessa(p, openRemessa)
                    : null}
                    </center>
                </div>
    },
    {
      name: "CPF/CNPJ",
      selector: "cpf_cnpj",
      sortable: false,
      width: '140px',
      cell: p => p.user !== null ? (p.user.cpf.length > 0 ? p.user.cpf : p.user.cnpj) : ''
    },
    {
      name: "Status da Remessa",
      selector: "status",
      sortable: false,
      cell: p => <div style={{margin: 5}}>
                      {/* <b>Pagamento:</b> {p.status} */}
                      {get_remessa_info(p).map(n => {
                        return <p>{n}</p>
                      })}
                  </div>
    },
    {
      name: "Status do Pedido",
      selector: "status",
      sortable: false,
      cell: p => <div style={{margin: 5}}>
                      {p.status_pedido !== null ? 
                          <>{getStatus(p.status_pedido)}</>
                      :
                      <>Aguardando Alteração</>}
                  </div>
    },
    {
      name: "Pagamento",
      selector: "forma_pagamento",
      sortable: false,
      cell: p => forma_pagamento.map(e => {
          if(e.value == p.forma_pagamento){
              return e.label;
          }
      })
    },
    {
      name: "Produtos",
      selector: "produtos",
      sortable: false,
      width: '230px',
      cell: p => <ul>{p.produtos.map(e => {
        return <li>Maquininha: {e.produto}</li>
      })}</ul>
    }
];

const pedido_list = [
  { value: "aprovado", label: "Pedido aprovado" },
  { value: "reprovado", label: "Pedido reprovado" },
  { value: "cancelado", label: "Pedido cancelado" },
  { value: "estornado", label: "Pedido estornado" },
  { value: "chargeback", label: "Pedido chargeback" },
  { value: "entrega", label: "Aguardando entrega" },
  { value: "enviado", label: "Pedido enviado" },
  { value: "entregue", label: "Pedido entregue" },
  { value: "devolvido", label: "Pedido devolvido" }
];

const getStatus = (status_pedido) => {
    var status = null;
    pedido_list.forEach(e => {
        if(e.value == status_pedido){
            status = e.label;
        }
    });

    return status;
}

const getProdutos = (produtos_is) => {
  
  var produtos = [];
  const exist_array = (value, array) => {
      var existe = false;
      array.forEach(e => {
        if(value == e.produto){
          existe = true;
        }
      });
      return existe;
  }

  const index_array = (value, array) => {
      var index = 0;
      array.forEach((e, i) => {
        if(value == e.produto){
          index = i;
        }
      });
      return index;
  }

  produtos_is.forEach((e, i) => {
      var produto = '';
        maquininhas_list.forEach(m => {
          if(m.modelo.toUpperCase() == e.produto.toUpperCase()){
            produto = m.label;
          }
        });

        if(exist_array(produto, produtos) == false){
            produtos.push({
              quantidade: 1,
              produto: produto
            })
        }
        else{
          produtos[index_array(produto, produtos)].quantidade = produtos[index_array(produto, produtos)].quantidade + 1
        }
    });

    return produtos.map(e => {
      return <li>{e.quantidade}x - {e.produto}</li>;
    })
}

export const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "maquininhas/list?remessa_cad=true&search="+state.search+"&page="+state.activePage;

    if(state.document.replace(/([^\d])+/gim, '').length > 0){
      if(state.document.replace(/([^\d])+/gim, '').length === 11){
          url +='&documento='+state.document.replace(/([^\d])+/gim, '');
      }
      else if(state.document.replace(/([^\d])+/gim, '').length === 14){
          url +='&documento='+state.document.replace(/([^\d])+/gim, '');
      }
    }

    if(state.servico_entrega !== null){
      url +='&servico_entrega='+state.servico_entrega.value;
    }

    if(state.status_entrega !== null){
      if(state.status_entrega.value2 !== null){
        url +='&status_pedido='+state.status_entrega.value2;
      }
      else{
        url +='&status_entrega='+state.status_entrega.value;
      }
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}


export const getRelatorio = (callback) => {
  var url = ENDPOINT + "vendas/relatorio";
  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      console.log(p)
      callback(p);
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    
    var url = ENDPOINT + "maquininhas/list?remessa_cad=true&search="+search+"&page="+page;
   
    if(state.document.replace(/([^\d])+/gim, '').length > 0){
        if(state.document.replace(/([^\d])+/gim, '').length === 11){
            url +='&documento='+state.document.replace(/([^\d])+/gim, '');
        }
        else if(state.document.replace(/([^\d])+/gim, '').length === 14){
            url +='&documento='+state.document.replace(/([^\d])+/gim, '');
        }
      }

    if(state.servico_entrega !== null){
        url +='&servico_entrega='+state.servico_entrega.value;
      }
    if(state.status_entrega !== null){
      if(state.status_entrega.value2 !== null){
        url +='&status_pedido='+state.status_entrega.value2;
      }
      else{
        url +='&status_entrega='+state.status_entrega.value;
      }
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}