import React, { useEffect, useState } from 'react';

import 'react-chat-elements/dist/main.css';
import 'react-chat-widget/lib/styles.css';
//import '../styles/bootstrap_isolado.css';
import 'react-toastify/dist/ReactToastify.css';
/* import 'bootstrap/dist/css/bootstrap.min.css'; */

import { MessageBox } from 'react-chat-elements';
import logo from '../assets/aqbank-min-logo.png';
import logoAqbank from '../assets/aqbanksvg.svg';
import aqbank_white from '../assets/logo_b_horizontal.png';
import { Button, Input, Form, FormGroup, Row, Col, Collapse, Card, ButtonGroup, Modal, ModalBody, ModalFooter, FormFeedback, InputGroup, InputGroupAddon } from 'reactstrap';
import { InputText } from '../components/InputText/InputText';
import { InputMasked } from '../components/InputText/InputMasked';
import { ButtonOutline } from '../components/Button/ButtonOutline';
import { ButtonDefault } from '../components/Button/ButtonDefault';
import { MdAttachFile, MdBlock, MdCameraAlt, MdClear, MdDelete, MdExitToApp, MdImage, MdMail, MdMic, MdOpenInBrowser, MdReply, MdSend } from 'react-icons/md';
import { ENDPOINT } from '../auth/endpoint';
import axios from 'axios';
import Pusher from 'pusher-js';
import { cpf, cnpj } from 'cpf-cnpj-validator';
//import './widget.css';
import Dropzone from "react-dropzone";
import PropagateLoader from "react-spinners/PropagateLoader";
import FadeLoader from "react-spinners/FadeLoader";
import styled, {keyframes} from 'styled-components';
import StarRating from 'react-star-ratings';
import Select from 'react-select';
import ReactRoundedImage from "react-rounded-image";
import { toast, ToastContainer } from 'react-toastify';
import { FaArrowCircleRight, FaPaperPlane, FaWindowMinimize } from 'react-icons/fa';
import { GiPaperClip } from 'react-icons/gi';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useTimer } from 'use-timer';
import get_extension from 'get-url-extension';
import ReactTooltip from 'react-tooltip';
import TextareaAutosize from 'react-textarea-autosize';
import moment from 'moment';

import background from '../assets/background-widget.svg';
import IsidoraSans from '../assets/fonts/IsidoraSans-Regular.woff';
import IsidoraSansBold from '../assets/fonts/IsidoraSans-Bold.woff';
import IsidoraSansLight from '../assets/fonts/IsidoraSans-Light.woff';
import NumberFormat from 'react-number-format';
import fone from "../assets/fone.svg";
import z from "../assets/z.svg";

const HeaderBeforeTalk = styled.div`
    font-size: 20px;
    padding: 15px;
    margin-top: 14px;
    font: normal normal bold 22px/30px Isidora Sans Bold;
    color: #444648;
`;

const HeaderAfterTalk = styled.div`
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    padding: 15px;
    background: #38B76E 0% 0% no-repeat padding-box;
    color: white;

    .minimizar{
        top: -15px;
        left: -10px;
        position: absolute;
        transform-style: flat;
        padding: 0;
        border: none;
        color: white;
        border-radius: 25px;
        height: 31px;
        width: 31px;
        background: #38B76E 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 0px 6px #00000057;
    }
    .foto-atendente{
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        top: 9vh;
    }
`;
const ButtonsMessage = styled.div`
    text-align: center;
    z-index: 100000;
    margin-bottom: -48px;
    margin-top: 11px;
    position: relative;
    opacity: 0;

    .btn{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 6px #38B76E8F;
        border: 1px solid #38B76E;
        border-radius: 25px;
        opacity: 1;
        width: 42px;
        height: 42px;
        padding: 8px;
    }
    .btn:hover{
        background: #38B76E 0% 0% no-repeat padding-box;
    }

    
  &:hover{
        opacity: 1;
  }
`;
const ButtonOpen = styled.button`
    @font-face {
        font-family: 'Isidora Sans';
        src: url(${IsidoraSans});
    }
    @font-face {
        font-family: 'Isidora Sans Light';
        src: url(${IsidoraSansLight});
    }

    @font-face {
        font-family: 'Isidora Sans Bold';
        src: url(${IsidoraSansBold});
    }

    position: absolute;
    bottom: 0;
    right: 0;
    background-color: transparent;
    background-image: url(${background});
    background-size: cover;
    width: 334px;
    height: 55px;
    text-decoration: none;
    border: none;
    opacity: 1;

    .logo-button{
        margin-top: 15px;
        width: 70px;
        margin-right: 25px;
    }
    p{
        width: 126px;
        height: 17px;
        text-align: left;
        font: normal normal normal 20px/28px Isidora Sans;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        padding: 0;
        padding-top: 15px;
        margin-right: 8px;
    }
`
const CssWidget = styled.div`
z-index: 1000;

input[type="text"] {
    font-size: inherit;
  }
svg{
  width: 22px;
  height: 22px;
}
html {
  min-height: 100%;
}

body {
  margin: 0;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.rdt_TableCol_Sortable{
  font-size: 14px;
}
.rdt_Table{
  min-height: 200px;
}
.rdt_TableBody{
  border-bottom: 1px solid rgba(0,0,0,.125);
}
.dropdown-menu.show {
  display: block;
  font-size: 16px;
  border-radius: 0;
  background-color: #1a1a1a;
}
.dropdown-item {
  color: #ffffff !important;
}
.dropdown-item:hover {
  color: #999999 !important;
  background-color: #1a1a1a !important;
}
.modal{
  border-radius: 0 !important;
}
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
audio{
    height: 35px;
}

#chat-running{
    margin-right: -20px;
    margin-left: -10px;
}

audio::-webkit-media-controls-time-remaining-display{
    display: none;
}

audio::-webkit-media-controls-current-time-display{
    display: none;
}

audio::-webkit-media-controls-mute-button {
    display: none !important;
}

audio::-webkit-media-controls-volume-slider {
    display: none !important;
}

audio::-webkit-media-controls-volume-control-container.closed {
    display: none !important;
}
audio::-webkit-media-controls-volume-control-container{
    display: none !important;
}
audio::-webkit-media-controls-panel {
    background: rgba(209, 208, 208, 0.18);
}
.widget{
    z-index: 1000;
    font: normal normal bold 15px/30px Isidora Sans;
    bottom: 0;
    display: flex;
    flex-direction: column;
    max-width: 85%;
    position: fixed;
    right: 0;
    width: 365px;
    margin-right: 10px;
}
.widget-header{
    background-color: #fff;
    color: #38B76E;
    font-weight: 500;
    font-size: 115%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.logotipo-padrao{
    position: absolute;
    width: 130px;
    float: right;
    right: 16px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 9px;
    padding: 7px;
    top: -31px;
}
.widget-content{
    margin: 15px;
    margin-bottom: 0;
}
.widget-button{
}
.widget-button:hover{
    
}
.widget-button:active{
    
}
.widget-button:visited{

}
.widget-button:link{

}

.back{
    width: 126px;
}
.widget-container{
    background-color: #fff;
    margin-bottom: 70px;
    padding: 0;
    border-radius: 18px;
    filter: drop-shadow(0px 3px 6px #00000029);
}
.widget-container.arrow-bottom:after {
    content: " ";
    position: absolute;
    right: 55px;
    bottom: -21px;
    border-top: 24px solid white;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: none;
  }
.button-back{
    margin-right: 7px !important;
}
.button-chat{
    width: 192px;
    border-radius: 6px !important;
    padding: 8px !important;
    margin: 0 !important;
    font-weight: 200 !important;
    float: right !important;
    margin-bottom: 14px !important;
    margin-top: 0 !important;
    box-shadow: 0px 3px 6px #00000029;
}
.select-chat{
    border-radius: 6px;
    padding: 8px !important;
    margin: 5px !important;
    font-weight: 200 !important;
    margin-bottom: 14px !important;
    margin-top: 0 !important;
    box-shadow: 0px 3px 6px #00000029;
}
.select-chat:disabled{
    opacity:0.6;
}
.rce-mbox-right-notch {
    position: absolute;
    right: -19px;
    top: -4px;
    width: 19px;
    height: 19px;
    fill: #D5F6E3 0% 0% no-repeat padding-box;
    box-shadow: none;
    filter: none;
}
.rce-mbox-right .rce-mbox-time{
    color: black;
}
.rce-mbox.rce-mbox-right {
    float: right;
    margin-left: 5px;
    margin-right: 20px;
    border-top-right-radius: 0px;
    border-top-left-radius: 5px;
    background: #D5F6E3 0% 0% no-repeat padding-box;
    box-shadow: none;
    color: #707070;
}
.rce-mbox {
    background: #F6F6F6 0% 0% no-repeat padding-box;
    box-shadow: none;
    width: 90%;
    margin-left: 10px;
}

textarea::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 8px;
    background-clip: padding-box;  
    border: 1px solid transparent;
}
textarea::-webkit-scrollbar {
    width: 8px;
}
textarea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.15);
}

.msg{
    margin-top: 7px;
    font-size: 16px;
    color: black !important;
    margin-right: 5px;
    resize: none;
    height: auto !important;
    max-height: 93px !important;
    border-right: none;
    border-left: none;
    border-top: none;
    border-bottom: none;
    overflow: auto;
    box-shadow: none !important;
    border: none !important;
    line-height: 14px;
    outline: 0px solid transparent !important;
}
.msg:focus{
    box-shadow: none !important;
    border: none !important;
}
.rce-mbox-left-notch {
    display: none
}
.buttons-chat{
    position: relative;
    text-align: center;
    right: 8%;
}
.widget_btn{
    padding: 0;
    padding-left: 2.5px;
    padding-right: 2.5px;
    border: none;
    box-shadow: none !important;
}
.talk_btn:hover{
    color: black;
    background: transparent !important;
    box-shadow: none !important;
}

.talk_btn:active{
    background: transparent !important;
    box-shadow: none !important;
}

.talk_btn:visited{
    background: transparent !important;
    box-shadow: none !important;
}

.talk_btn:active{
    background: transparent !important;
    box-shadow: none !important;
}
.card-file{
    border-radius: 0;
    border-color: #38B76E;
}
.rce-avatar-container.default {
    width: 25px;
    height: 25px;
    border-radius: 57%;
}
.rce-mbox-photo--img__block {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    border-radius: 5px;
    display: flex;
    max-width: 300px;
}
.rce-mbox-photo--img img {
    height: 100%;
    min-height: 180px;
    -webkit-user-select: none;
    user-select: none;
    max-width: 300px;
    object-fit: cover;
}
.rce-mbox-photo--download {
    color: #efe;
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    font-size: 3.2em;
    outline: none;
    border: 1px solid #eee;
    border-radius: 100%;
    height: 92px;
    width: 92px;
}

.rce-mbox-photo--download svg{
    vertical-align: middle;
    place-self: center;
    padding: 0;
}
.audio-react-recorder{
    display: none
}
.rce-mbox-text audio{
    width: 100%;
}
.rce-mbox-text video{
    width: 100%
}
.__react_component_tooltip{
    z-index: 9999999;
    max-width: 80%;
}
.dropdown-image .btn{
    background: transparent;
    border-radius: 50%;
    border: 1px solid;
    height: 50%;
    width: 50%;
}
`;
/* const ReplyButton = styled.button`
    width: 24px;
    border: 0;
    transform-style: flat;
    background-color: transparent;
    padding: 0;
    float: ${props => (props.type_message === 1 ? 'right' : 'left' )};
    svg {
         width: 1.3rem;
    }
` */

const MessageBoxArea = styled.div`
  .dropdown-toggle:after { 
    content: none 
  }
  .dropdown-toggle:before { 
    content: none 
  }
  .dropdown-image{
      text-align: center;
  }
  .rce-mbox{
        border: ${props => props.borderColor};
        border-radius: ${props => (props.type_message === 1 ? '15px 15px 0px 15px !important' : '15px 15px 15px 0px !important' )};
  }
  .rce-avatar-container.default{
    position: absolute;
    float: right;
    right: -17px;
    background: white;
    top: -9px;
    height: 40px;
    width: 40px;
    border: 1px solid;
  }
  span{
        color: #38B76E;
        font: normal normal bold 13px/19px Isidora Sans Bold;
  }

  .rce-mbox-status svg{
        display:none;
    }
    .rce-mbox-status:before{
        background-color: ${props => props.status_message === 0 ? 'white' : props.status_message === 2 ? '#38B76E' : '#8E8E8E'};
        color: ${props => props.status_message === 0 ? 'white' : props.status_message === 2 ? '#38B76E' : '#8E8E8E'};
        height: 15px;
        font-size: 15px;
        content: "O";
        border-radius: 60px;
        padding: 0;
    }
  &:hover{
    .rce-mbox{
        opacity: 0.5;
    }
  }
`;

const DropdownCurrent = styled.div`
    .btn{
        display: inline-grid;
        width: 24px;
        border: 0;
        transform-style: flat;
        background-color: transparent;
        padding: 0;
        position: relative;
        float: ${props => (props.type_message === 1 ? 'right' : 'left' )};
    }
    svg {
        width: 1.3rem;
        height: 1.3rem;
    }
  
    .chat-reposta { 
        display: flex;
        position: relative;
        top: ${props => (props.type_message === 1 ? '0px' : '5px' )};
        float: ${props => (props.type_message === 1 ? 'right' : 'left' )};
    }
    .chat-reposta .btn-danger svg{
        color: #e11a1a;
        width: 1.3rem;
        height: 1.3rem;
    }

    .chat-reposta .btn-info svg{
        color: #0066ff;
        width: 1.5rem;
        height: 1.5rem;
    }
  
`;

const breatheAnimation = keyframes`
    0% { opacity: 1; }
    100% { opacity: 0; }
`;

const Recorder = styled.div`
 height: 30px;
 width: 30px;
 margin-left: 10px;
 animation-name: ${breatheAnimation};
 animation-duration: 2s;
 animation-iteration-count: infinite;
 svg {
     width: 2rem;
     color: red;
}
`
const StarContainer = styled.div`
    text-align: center;
`
const removeCookie = (name, value) => {
    var expires = "";
    var date = new Date();
    date.setDate(date.getDate() -1);
    expires = "; expires=" + date.toUTCString();

    document.cookie = name + "=" + expires + "; path=/";
}
const setCookie = (name, value) => {
    var expires = "";
    var date = new Date();
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    expires = "; expires=" + date.toUTCString();

    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        
        /* eslint eqeqeq: 0 */
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        /* eslint eqeqeq: 0 */
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
const DropArea = styled.div`
    border-style: dotted;
    border-color: #38B76E;
    padding-bottom: 16px;
    padding: 1%;
    text-align-last: center;
    cursor: pointer;
    margin-bottom: 15px;
    opacity: 1;

    &:drop {
        opacity: 0.5;
    }
`;
const ButtonUpload = styled.button`
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0;
    padding-left: 7px;
    padding-right: 7px;
    top: -1px;
    position: relative;
`

const App = () => {
    const [inputs, setInputs] = useState({
        message: '',
        message_invalid: false
    });
    const [option_cnpj, setOptionCnpj] = useState(false);
    const [step, setStep] = useState(0);
    const [file, setFile] = useState([]);
    const [modal_attach, setAttach] = useState(false);
    const [list_messages, setListMessage] = useState([]);
    const [visible, setVisible] = useState(false);
    const [finaly, setFinaly]= useState(false);
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude]= useState(0);
    const [mask, setMask] = useState('999.999.999-99');
    const [maskPhone, setMaskPhone] = useState('(99) 9999-99999');
    const [loading, setLoading]= useState(true);
    const [retorno, setRetorno]= useState(false);
    const [masked, setMasked] = useState(null);
    const [list_departamentos, setListDepartamento] = useState([]);
    const [permissoes, setPermissoes] = useState(null);
    const [rating, setRating] = useState(0);
    const [departamento, setDepartamento] = useState(0);
    const [avatar_foto, setAvatarFoto] = useState(null);
    const [nome_atendente, setAtendente] = useState('Atendimento');
    const [protocolo, setProtocolo] = useState('');
    const [loading_create, setLoadingCreate] = useState(false);
    const [audio_visible, setAudioVisible] = useState(false);
    const [audio, setAudio] = useState(null);
    const [fila, setFila] = useState(null);
    const [modal_image, setModalImage] = useState(null);
    const [urlImage, setUrl] = useState(null);
    const [recording_audio, setRecorderAudio] = useState(null);
    const [servicos_list, setServicos]= useState([]);
    const [instancia_pusher, setInstanciaPusher] = useState(null);
    const [servico, setServico] = useState(null);
    const { 
        time, 
        start, 
        //pause, 
        reset, 
        //status 
    } = useTimer();

    const myRef = React.useRef();
    const inputFile = React.useRef();
    const filePhoto = React.useRef();

    useEffect(() => {
        checkStatusChat();
        checkServicosChat();
        getLocation();
        if (getCookie('@chat.codigo') !== null) {
            loadChatByMessage();
            PusherInstance();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(instancia_pusher && instancia_pusher.bind){
            console.log("Unbinding Event");
            instancia_pusher.unsubscribe('pusher_atualizar_mensagem_'+getCookie('@chat.codigo'));
            instancia_pusher.unsubscribe('pusher_atualizar_todos_status_'+getCookie('@chat.codigo'));
            console.log("Rebinding Event");
            AtualizarMensagem(instancia_pusher);
            AtualizarTodosStatus(instancia_pusher);
        }
    }, [list_messages]);

    const PusherInstance = () => {
        const pusher = new Pusher("2892f3b88d766b1b14d3", {
            cluster: 'us3',
        });
        var response = pusher.subscribe('pusher_atualizar_chat_'+getCookie('@chat.codigo'));
        response.bind('atualizar', () => loadChatRequest());

        AtenderChatInstance(pusher);
        AtualizarFila(pusher);
        AtualizarTodosStatus(pusher);
        AtualizarMensagem(pusher);
        FinalizeInstance(pusher);
        setInstanciaPusher(pusher);
    }

    const getLocation = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(setLocation);
        } 
        else { 
            console.log('localização não encontrada')
        }
    }
      
    function setLocation(position) {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
    }

    const AtualizarTodosStatus = (pusher) => {
        var atualizar_todos_status_ = pusher.subscribe('pusher_atualizar_todos_status_'+getCookie('@chat.codigo'));
        atualizar_todos_status_.bind('atendentes', (e) => {
            atualizar_todos_status(e, 0)
        });

        atualizar_todos_status_.bind('clientes', (e) => {
            atualizar_todos_status(e, 1)
        });
    }

    const AtualizarFila = (pusher) => {
        var response = pusher.subscribe('pusher_atualizar_fila');
        response.bind('atualizar', () => {
            if(fila !== 0){
                loadInfos();
            }
        });
    }

    
    const AtualizarMensagem = (pusher) => {
        var response = pusher.subscribe('pusher_atualizar_mensagem_'+getCookie('@chat.codigo'));
        response.bind('atualizar', (e) => {
            loadMessage(e, atualizar_chat);
        });
    }

    const FinalizeInstance = (pusher) => {
        var response = pusher.subscribe('chat_finalizado_'+getCookie('@chat.codigo'));
        response.bind('atualizar', () => loadInfos());
    }

    const AtenderChatInstance = (pusher) => {
        var response = pusher.subscribe('pusher_chat_atendido_'+getCookie('@chat.codigo'));
        response.bind('atualizar', () => loadInfos());
    }

    const handleInputChange = (event) => {
        //console.log(event.style)
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    }
    const deleteMasked = () => {
        setMasked(null);
    }
    const apagar_mensagem = (message) => {
        setLoadingInMessage(message.cod_chat_message);
        var url = ENDPOINT + 'chat-widget/apagar-mensagem/' + message.cod_chat_message;

        var formData = new FormData();

        var username = getCookie('@chat.codigo');
        var senha = getCookie('@chat.senha_chat');

        axios.post(url, formData, {
            headers: {
                Authorization: 'Basic ' + btoa(username+':'+senha)
            }
        })
        .then((response) => {})
        .catch(() => { toast.success('Mensagem apagada com sucesso'); });
    }
    const stateDefault = () => {
        setStep(0);

        removeCookie('@chat.codigo');
        removeCookie("@chat.name_cliente");
        removeCookie("@chat.senha_chat");

        setInputs({
            message: '',
            message_invalid: false
        });
        setFile([]);
        setAttach(false);
        setListMessage([]);
        setVisible(false);
        setFinaly(false);
        setMask('999.999.999-99');
        setMaskPhone('(99) 9999-99999');
        setLoading(true);
        setMasked(null);
        setRating(0);
        setDepartamento(0);
        setAvatarFoto(null);
        setAtendente('Atendimento');
        setProtocolo('');
        setLoadingCreate(false);
        setAudioVisible(false);
        setAudio(null);
        setFila(null);
        setRecorderAudio(null);
        setRetorno(false);
        setServicos([]);

        window.location.reload();
    }
    const loadScroll = () => {
        if (typeof myRef !== 'undefined') {
            if(typeof myRef.current  !== 'undefined'){
                if (myRef.current !== null) {
                    setTimeout(() => 
                    {
                        try{
                            myRef.current.scrollTop = myRef.current.scrollHeight;
                        }
                        catch(e){
                            
                        }
                    },
                    500);
                }
            }
        }
    }
    const setLoadingInMessage = (cod_chat_message) => {
        var list = list_messages;

        list.forEach((p) => {
            if(p.cod_chat_message === cod_chat_message){
                p.message = 'Apagando Mensagem...'
            }
        });

        setListMessage(oldListMessages => [...oldListMessages, list]);
    }

    const setDeleteMessage = (cod_chat_message) => {
        var list = list_messages;

        list.forEach((p) => {
            if(p.cod_chat_message === cod_chat_message){
                p.message = 'ESSA MENSAGEM FOI APAGADA';
                p.code_chat_message_mask = null;
                p.status_message = 2;
            }
        });

        setListMessage(oldListMessages => [...oldListMessages, list]);
    }

    const checar_se_existe_mensagens_nao_lidas = () => {
        var messages_pending = 0;

        list_messages.forEach((p, i) => {
            if(p.type_message === 0){
                if(p.status_message !== 2){
                    messages_pending++;
                }
            }
        });

        if(messages_pending === 0){
            return false;
        }

        return true;
    }
    const mensagens_nao_lidas = () => {
        var messages_pending = 0;

        list_messages.forEach((p, i) => {
            if(p.type_message === 0){
                if(p.status_message !== 2){
                    messages_pending++;
                }
            }
        });

        return messages_pending;
    }

    const loadChatRequest = () => {
        if(getCookie('@chat.codigo') !== null){
            var url = ENDPOINT + 'chat-widget/exibir-mensagens/' + getCookie('@chat.codigo');

            var username = getCookie('@chat.codigo');
            var senha = getCookie('@chat.senha_chat');
            axios.get(url, {
                headers: {
                    Authorization: 'Basic ' + btoa(username+':'+senha)
                }
            })
            .then((response) => {
                var data = response.data;
                if(data.mensagens.length > list_messages.length){
                    loadScroll();
                }

                setListMessage(data.mensagens);
                if(data.chat_info === null){
                    stateDefault();
                }
                else if(data.chat_info.status_chat === 2){
                    setStep(1);
                }
                else if(data.chat_info.status_chat === 3){
                    stateDefault();
                    toast.error('Esse chat não está disponivel');
                }

                if(data.chat_info !== null){
                    setFila(data.chat_info.fila)
                    setProtocolo(String(data.chat_info.protocolo_atendimento));
                    if(data.chat_info.atendente !== null){
                        setAtendente(data.chat_info.atendente.name);
                        setAvatarFoto(data.chat_info.atendente.avatar_foto);
                    }
                    else{
                        setAtendente('Atendimento');
                        setAvatarFoto(null);
                    }
                }
                //setLoading(false);
            })
            .catch((error) => {
                console.log(error.response);
                var response = '';
                var obj = '';
                if(error.message === 'Network Error'){
                    response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    obj = JSON.parse(response);
                    toast.error(obj.errors);
                    return obj;
                }
                var data = error.response.data;
                if(data.exception){
                    response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    obj = JSON.parse(response);
                    toast.error(obj.errors);
                    return obj;
                }
                if(data.code === 401){
                    stateDefault();
                    toast.error(data.errors);
                }

            });

        }
    }
    
    const atualizar_chat = (mensagem) => {
        var message_exists = null;

        list_messages.forEach(e => {
            if(e.id == mensagem.id){
                message_exists = e;
            }
        });

        if(message_exists !== null){
            setDeleteMessage(message_exists.cod_chat_message);
            /* console.log('Caiu aqui')
            var list = list_messages;

            list.forEach((p) => {
                if(p.cod_chat_message === message_exists.cod_chat_message){
                    p.message = message_exists.message;
                }
            });

            setListMessage(oldListMessages => [...oldListMessages, list]); */
        }
        else{
            setListMessage(oldListMessages => [...oldListMessages, mensagem]);
            loadScroll();
        }
    }

    const loadInfos = (id) => {
        var url = ENDPOINT + 'chat-widget/informacoes/' + getCookie('@chat.codigo');

        var username = getCookie('@chat.codigo');
        var senha = getCookie('@chat.senha_chat');
        axios.get(url, {
            headers: {
                Authorization: 'Basic ' + btoa(username+':'+senha)
            }
        })
        .then((response) => {
            var data = response.data.data;

            if(data === null){
                stateDefault();
            }
            else if(data.status_chat === 2){
                setStep(1);
            }
            else if(data.status_chat === 3){
                stateDefault();

                toast.error('Esse chat não está disponivel');
            }

            if(data !== null){
                if(data.status == 0){
                    setFila(data.fila)
                }
                else{
                    setFila(0)
                }
                setProtocolo(String(data.protocolo_atendimento));
                if(data.atendente !== null){
                    setAtendente(data.atendente.name);
                    setAvatarFoto(data.atendente.avatar_foto);
                }
                else{
                    setAtendente('Atendimento');
                    setAvatarFoto(null);
                }
            }
            setLoading(false);
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const loadMessage = (id, callback) => {
        var url = ENDPOINT + 'chat-widget/exibir-mensagem/' + getCookie('@chat.codigo') + '/' + id;

        var username = getCookie('@chat.codigo');
        var senha = getCookie('@chat.senha_chat');
        axios.get(url, {
            headers: {
                Authorization: 'Basic ' + btoa(username+':'+senha)
            }
        })
        .then((response) => {
            var data = response.data;
            callback(data.data)
        })
        .catch((error) => {
            console.log(error.response);
            var response = '';
            var obj = '';
            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                return obj;
            }
            var data = error.response.data;
            if(data.exception){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                return obj;
            }
            if(data.code === 401){
                stateDefault();
                toast.error(data.errors);
            }

        });
    }

    const loadChatByMessage = () => {
        var url = ENDPOINT + 'chat-widget/exibir-mensagens/' + getCookie('@chat.codigo');

        var username = getCookie('@chat.codigo');
        var senha = getCookie('@chat.senha_chat');
        axios.get(url, {
            headers: {
                Authorization: 'Basic ' + btoa(username+':'+senha)
            }
        })
        .then((response) => {
            var data = response.data;
            
            if(data.mensagens.length !== list_messages.length){
                loadScroll();
            }

            setListMessage(data.mensagens);
            if(data.chat_info === null){
                stateDefault();
            }
            else if(data.chat_info.status_chat === 2){
                setStep(1);
            }
            else if(data.chat_info.status_chat === 3){
                stateDefault();

                toast.error('Esse chat não está disponivel');
            }

            if(data.chat_info !== null){
                setFila(data.chat_info.fila)
                setProtocolo(String(data.chat_info.protocolo_atendimento));
                if(data.chat_info.atendente !== null){
                    setAtendente(data.chat_info.atendente.name);
                    setAvatarFoto(data.chat_info.atendente.avatar_foto);
                }
                else{
                    setAtendente('Atendimento');
                    setAvatarFoto(null);
                }
            }
            setLoading(false);
        })
        .catch((error) => {
            console.log(error.response);
            var response = '';
            var obj = '';
            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                return obj;
            }
            var data = error.response.data;
            if(data.exception){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
                return obj;
            }
            if(data.code === 401){
                stateDefault();
                toast.error(data.errors);
            }

        });

    }
    const startAudio = () => {
        let constraints={audio:true};
        navigator.mediaDevices.getUserMedia(constraints)
        .then((stream)=>{
            if(stream.getAudioTracks().length > 0){
                var record_instance;
                if(audio_visible === true){
                    record_instance = recording_audio;

                    if(record_instance !== null){
                        record_instance.pause();
                        record_instance.stream.getTracks().forEach( track => track.stop() );
                        setRecorderAudio(null);
                    }

                    reset();
                    setAudioVisible(true);
                    setRecorderAudio(null);
                }
                else{
                    record_instance = recording_audio;

                    if(record_instance === null){
                        let recorder = new MediaRecorder(stream);
                        recorder.start();

                        recorder.addEventListener("dataavailable", e => {
                            onStop(e);
                        });

                        record_instance = recorder;
                    }

                    start();
                    setAudioVisible(true);
                    setRecorderAudio(record_instance);
                }

            }
        })
        .catch((e) => {
            alert('Nenhum microfone detectado')
        });
    }
    const stopAudio = () => {
        recording_audio.requestData();
        recording_audio.stream.getTracks().forEach( track => track.stop() );

        reset();
        
        setRecorderAudio(null);
    }
    const resetAudio = () => {
        reset();
        setRecorderAudio(null);
        setAudioVisible(false);
        setAudio(null);
    }
    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
    }
    const readMessageSpecific = (message) => {
        if (message.status_message !== 2 && message.type_message === 0) {
            var url = ENDPOINT + 'chat-widget/ler-mensagem-especifica/' + message.cod_chat_message;

            var formData = new FormData();
            var username = getCookie('@chat.codigo');
            var senha = getCookie('@chat.senha_chat');
    
            axios.post(url, formData, {
                headers: {
                    Authorization: 'Basic ' + btoa(username+':'+senha)
                }
            })
                .then((response) => {
                    loadChatRequest();
                })
                .catch((error) => {
                    console.log(error.response);
                    var response = '';
                    var obj;
                    if(error.message === 'Network Error'){
                        response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                        obj = JSON.parse(response);
                        toast.error(obj.errors);
                    }
        
                    var is_response = error.response.data;
                    if(is_response){
                        toast.error(is_response.errors);
                        if(is_response.code === 401){
                            stateDefault();
                        }
                    }

                });

        }
    }
    /* const getIcon = (qtd) => {
        if(qtd > 0){
            return ( <HiStatusOnline /> )
        }
        else{
            return ( <HiStatusOffline /> )
        }
    } */
    const onDepartamento = (data) => {
        var permissoes_list = [];
        data.forEach((p) => {
            permissoes_list.push({
                value: p.flag,
                label: p.nome_departamento,
                ...p
            })
        })

        setPermissoes(permissoes_list);
        
        var departamentos_list = [];
        data.forEach((p) => {
            if(p.flag !== 'vendas'){
                if(p.status > 0){
                    departamentos_list.push({
                        value: p.flag,
                        label: p.nome_departamento,
                        isDisabled: data.status === 0 ? true : false
                    })
                }
            }
        })

        setListDepartamento(departamentos_list);
    }
    const onServicos = (data) => {
        var servicos_list = [];
        data.forEach((p) => {
            servicos_list.push({
                value: p.flag,
                label: p.nome_servico,
                ...p
            })
        })

        setServicos(servicos_list);
    }
    const checkStatusChat = () => {
        var url = ENDPOINT + 'checar-se-existe-operadores-online';
        
        var username = getCookie('@chat.codigo');
        var senha = getCookie('@chat.senha_chat');
        axios.get(url, {
            headers: {
                Authorization: 'Basic ' + btoa(username+':'+senha)
            }
        })
        .then((response) => {
            var data = response.data;
            onDepartamento(data)
        })
        .catch((error) => {
            console.log(error.response);
            var response = '';
            var obj;
            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
            }

            var is_response = error.response.data;
            if(is_response){
                toast.error(is_response.errors);
                if(is_response.code === 401){
                    stateDefault();
                }
            }

        });

    }
    const checkServicosChat = () => {
        var url = ENDPOINT + 'checar-se-existe-servicos-online';
        
        var username = getCookie('@chat.codigo');
        var senha = getCookie('@chat.senha_chat');
        axios.get(url, {
            headers: {
                Authorization: 'Basic ' + btoa(username+':'+senha)
            }
        })
        .then((response) => {
            var data = response.data;
            onServicos(data)
        })
        .catch((error) => {
            console.log(error);
            console.log(error.response);
            var response = '';
            var obj;
            if(error.message === 'Network Error'){
                response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                obj = JSON.parse(response);
                toast.error(obj.errors);
            }

            var is_response = error.response.data;
            if(is_response){
                toast.error(is_response.errors);
                if(is_response.code === 401){
                    stateDefault();
                }
            }

        });

    }
    const checkVendas = () => {
        if(servicos_list.length === 0){
            return false;
        }
        else{
            return true;
        }
    }

    const checkAllUsers = () => {
        if(permissoes === null){
            return false;
        }
        else{
            var onlines = 0;

            permissoes.forEach((p) => {
                if(p.nome_departamento !== 'vendas'){
                    if(p.status > 0){
                        onlines++;
                    }
                }
            });
            if(onlines > 0){
                return true;
            }
            return false;
        }
    }
    /* const FormSelect = () => {
        return (
            <>
                <center>
                    <h5>Para iniciar, selecione se você já é um cliente de servicos da AQBANK</h5>
                    <br/>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div data-type="error" data-tip={checkAllUsers() === false ? 'Infelizmente, não há atendentes disponiveis nesse momento, tente contatar em nosso horario de atendimento de Segunda à Sexta - 08:30 às 17:30' : null}>
                            <ButtonOutline 
                                disabled={!checkAllUsers()}  
                                className="select-chat" 
                                onClick={() => setStep(2)}>
                                Já sou cliente
                            </ButtonOutline>
                        </div>
                        <div data-type="error"  data-tip={checkVendas() === false ? 'Infelizmente, não há atendentes disponiveis nesse momento, tente contatar em nosso horario de atendimento de Segunda à Sexta - 08:30 às 17:30' : null}>
                            <ButtonDefault className="select-chat" 
                                disabled={!checkVendas()} 
                                onClick={() => setStep(1)}>
                                Não sou cliente
                            </ButtonDefault>
                        </div>
                    </div>
                </center>
            </>
        )
    } */
    const validData = () =>{
        var invalid = 0;
        if(inputs.cpf){
            var cpf_cnpj = inputs.cpf.replaceAll('_', '').replaceAll('-', '').replaceAll('/', '').replaceAll('.', '');
            if(cpf_cnpj.length > 11){
                if(cnpj.isValid(cpf_cnpj) === true){
                    setInputs(inputs => ({...inputs, cpf_invalid: false}));
                }
                else{
                    invalid++;
                    setInputs(inputs => ({...inputs, cpf_invalid: true}));
                }
            }
            else{
                if(cpf.isValid(cpf_cnpj) === true){
                    setInputs(inputs => ({...inputs, cpf_invalid: false}));
                }
                else{
                    invalid++;
                    setInputs(inputs => ({...inputs, cpf_invalid: true}));
                }
            }
        }
        else{
            invalid++;
            setInputs(inputs => ({...inputs, cpf_invalid: true}));
        }

        if(step === 1){
            if(inputs.email){
                if(inputs.email.length > 0){
                    setInputs(inputs => ({...inputs, email_invalid: false}));
                }
                else{
                    invalid++;
                    setInputs(inputs => ({...inputs, email_invalid: true}));
                }
            }
            else{
                invalid++;
                setInputs(inputs => ({...inputs, email_invalid: true}));
            }

            
            if(inputs.telefone){
                if(inputs.telefone.length > 0){
                    setInputs(inputs => ({...inputs, telefone_invalid: false}));
                }
                else{
                    invalid++;
                    setInputs(inputs => ({...inputs, telefone_invalid: true}));
                }
            }
            else{
                invalid++;
                setInputs(inputs => ({...inputs, telefone_invalid: true}));
            }

            if(servico !== null){
                setInputs(inputs => ({...inputs, servico_invalid: false}));

            }
            else{
                invalid++;
                setInputs(inputs => ({...inputs, servico_invalid: true}));
            }
            
        }
        else{
            if(inputs.message){
                if(inputs.message.length > 0){
                    setInputs(inputs => ({...inputs, message_invalid: false}));
                }
                else{
                    invalid++;
                    setInputs(inputs => ({...inputs, message_invalid: true}));
                }
            }
            else{
                invalid++;
                setInputs(inputs => ({...inputs, message_invalid: true}));
            }
        }

        if(invalid > 0){
            return false;
        }

        return true;
    }

    /* const blobToFile = (theBlob) => {
        return new File([theBlob], 'audio.wav', { type: theBlob.type });
    } */

    const sendMessageOnFile = (files) => {
        setLoading(true);
        var url = ENDPOINT + 'chat-widget/enviar-mensagem/' + getCookie('@chat.codigo');

        var formData = new FormData();
        formData.append('message', typeof inputs.message !== 'undefined' ? inputs.message : '');
        formData.append('cod_chat', getCookie('@chat.codigo'));
        formData.append('type_message', 1);
        formData.append('latitude', latitude);
        formData.append('longitude', longitude);

        if (masked !== null) {
            formData.append('codigo_message_masked', masked.cod_chat_message);
        }

        if(files.length > 0){
            files.forEach((file, index) => {
                formData.append('anexo['+index+']', file);
            });
        }
        var username = getCookie('@chat.codigo');
        var senha = getCookie('@chat.senha_chat');

        axios.post(url, formData, {
            headers: {
                Authorization: 'Basic ' + btoa(username+':'+senha)
            }
        })
            .then(() => {
                var inputsT = inputs;
                inputsT.message = '';
                inputsT.file = [];
                setLoading(true);
                setInputs(inputsT);
                setFile([]);
                setMasked(null);
                //loadChatByMessage();
            })
            .catch((error) => {
                setLoading(false);
                var response = '';
                var obj;
                if(error.message === 'Network Error'){
                    response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    obj = JSON.parse(response);
                    toast.error(obj.errors);
                }
    
                var is_response = error.response.data;
                if(is_response){
                    toast.error(is_response.errors);
                    if(is_response.code === 401){
                        stateDefault();
                    }
                }

            });
    }

    const signUpChat = () => {
        if(validData() === true){
            setLoadingCreate(true);
            var cpf_cnpj = inputs.cpf.replaceAll('_', '').replaceAll('-', '').replaceAll('/', '').replaceAll('.', '');
            
            var url = ENDPOINT + 'create_chat';
            var formData = new FormData();
            formData.append('email', inputs.email);
            formData.append('telefone', inputs.telefone ? inputs.telefone.replace(/([^\d])+/gim, '') : '');
            formData.append('cpf_cnpj', cpf_cnpj);
            formData.append('new_user', step === 1 ? 0 : 1);
            if(step === 1){
                formData.append('message', 'Olá, sou um novo usuário, gostaria de informações sobre: '+servico.nome_servico);
                //formData.append('departamento', 'vendas');
                //formData.append('departamento', servico.departamento);
                formData.append('servico', servico.flag);
            }
            else{
                formData.append('message', inputs.message);
                formData.append('departamento', departamento.value);
            }

            formData.append('type_message', 1);

            /* var username = getCookie('@chat.codigo');
            var senha = getCookie('@chat.senha_chat'); */
    
            axios.post(url, formData)
            .then((response) => {
                try{
                    var data = response.data;
                    
                    var inputsT = inputs;
                    inputsT.message = '';

                    setStep(0);
                    setCookie('@chat.codigo', data.chat_info.cod_chat);
                    setCookie('@chat.name_cliente', data.chat_info.nome);
                    setCookie('@chat.senha_chat', data.senha_chat);
                    PusherInstance();
                    setLoading(false);
                    setLoadingCreate(false);
                    setInputs(inputsT);
                    loadChatRequest();
                }
                catch(ex){console.log(ex)}
            })
            .catch((error) => {
                try{
                    console.log(error);
                    setLoadingCreate(false);
                    setLoading(false);
                    var response = '';
                    var obj;
                    if(error.message === 'Network Error'){
                        response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                        obj = JSON.parse(response);
                        toast.error(obj.errors);
                    }

                    var is_response = error.response;
                    if(typeof is_response.data !== 'undefined'){
                        if(is_response.data !== null){
                            var is_return = is_response.data;

                            if(is_return.errors === 'ja.existe'){
                                toast.warning(<AtendimentoNotificacao />, {
                                    position: "top-right",
                                    autoClose: 150000,
                                    hideProgressBar: true,
                                    closeOnClick: false,
                                    pauseOnHover: true,
                                    draggable: true,
                                    closeButton: false,
                                    //progress: undefined,
                                });
                            }
                            else{
                                toast.warning(is_return.errors);
                            }
                        }
                        else{
                            toast.warning('Ocorreu um erro no servidor');
                        }
                    }
                    else{
                        toast.warning('Ocorreu um erro no servidor');
                    }

                }
                catch(ex){console.log(ex)}
            });
        }
    }

    const AtendimentoNotificacao = (props) => {
        return(
            <div style={{ textAlign: 'center' }}>
                <p style={{ color: 'white' }}>Esse usuario já existe em nosso sistema, deseja realizar um atendimento ?</p>
                <ButtonGroup>
                    <Button color="success" onClick={() => { setStep(2); props.closeToast()}}>Sim</Button>
                    <Button color="danger" onClick={() => { setStep(0); props.closeToast() }}>Não</Button>
                </ButtonGroup>
            </div>
        )
    };
    
    const atualizar_todos_status = (status, type_message) => {
        var chat_current = list_messages;

        chat_current.forEach((e, index) => {
            if(e.type_message == type_message){
                e.status_message = status;
            }
        });

        setListMessage(oldListMessages => [...oldListMessages, chat_current])
        //this.setState({chat: chat_current});
    }

    const readMessageAll = () => {
        if(checar_se_existe_mensagens_nao_lidas() === true){
            var url = ENDPOINT + 'chat-widget/ler-todas-as-mensagens';

            var formData = new FormData();
            formData.append('message', inputs.message);
            formData.append('cod_chat', getCookie('@chat.codigo'));
            formData.append('type_message', 0);

            var username = getCookie('@chat.codigo');
            var senha = getCookie('@chat.senha_chat');

            axios.post(url, formData, {
                headers: {
                    Authorization: 'Basic ' + btoa(username+':'+senha)
                }
            })
            .then(() => {
                //loadChatRequest();
            })
            .catch((error) => {
                var response = '';
                var obj;
                console.log(error.response);
                if(error.message === 'Network Error'){
                    response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    obj = JSON.parse(response);
                    toast.error(obj.errors);
                }
    
                var is_response = error.response.data;
                if(is_response){
                    toast.error(is_response.errors);
                    if(is_response.code === 401){
                        stateDefault();
                    }
                }
            });
        }
    }
    
    /* const load_message_unique = (cod_chat, id, callback) => {
            var url = ENDPOINT + 'chat-widget/exibir-mensagem/' + cod_chat + '/' + id;

            var username = getCookie('@chat.codigo');
            var senha = getCookie('@chat.senha_chat');

            axios.get(url, {
                headers: {
                    Authorization: 'Basic ' + btoa(username+':'+senha)
                }
            })
            .then((response) => {
                var data = response.data;
                //this.setState({ consulta_mensagens_enabled: true });
                callback(data.data);
            })
            .catch((error) => {
                console.log(error)
            })
        //}
    } */
    const scroll_novas_mensagens = () =>{
        var verificar_referencia = myRef.current;
        if(typeof verificar_referencia !== 'undefined'){
            var scroll_current = myRef.current;
            if(scroll_current){
                if (scroll_current.scrollHeight - scroll_current.scrollTop === scroll_current.clientHeight) {
                    return false;
                }
            }
        }

        return checar_se_existe_mensagens_nao_lidas();

    }
    const onScroll = (scroll) => {
        var scroll_current = scroll.target;
        if (scroll_current.offsetHeight + scroll_current.scrollTop >= scroll_current.scrollHeight){
            readMessageAll();
        }
    }

    const removeAttach = (index) => {
        let files = [...file]
        files.splice(index, 1);
        setFile(files);
    }
    const sendMessage = () => {
        setLoading(true);
        var url = ENDPOINT + 'chat-widget/enviar-mensagem/' + getCookie('@chat.codigo');

        var formData = new FormData();
        formData.append('message', typeof inputs.message !== 'undefined' ? inputs.message : '');
        formData.append('cod_chat', getCookie('@chat.codigo'));
        formData.append('type_message', 1);

        if (masked !== null) {
            formData.append('codigo_message_masked', masked.cod_chat_message);
        }

        if(file.length > 0){
            file.forEach((file, index) => {
                formData.append('anexo['+index+']', file);
            });
        }

        var username = getCookie('@chat.codigo');
        var senha = getCookie('@chat.senha_chat');

        axios.post(url, formData, {
            headers: {
                Authorization: 'Basic ' + btoa(username+':'+senha)
            }
        })
            .then(() => {
                var inputsT = inputs;
                inputsT.message = '';
                inputsT.file = [];
                setInputs(inputsT);
                setFile([]);
                setMasked(null);
                setLoading(false);
                //loadChatByMessage();
            })
            .catch((error) => {
                setLoading(false);
                var response = '';
                var obj;
                if(error.message === 'Network Error'){
                    response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    obj = JSON.parse(response);
                    toast.error(obj.errors);
                }
    
                var is_response = error.response.data;
                if(is_response){
                    toast.error(is_response.errors);
                    if(is_response.code === 401){
                        stateDefault();
                    }
                }
            });
    }

    const validar_avaliacao = () => {
        if(retorno === false){
            return true
        }
        else{
            var invalids = 0;

            /* if(departamento_retorno !== null){
                setInputs(inputs => ({...inputs, departamento_retorno_invalid: false}));
            }
            else{
                invalids++;
                setInputs(inputs => ({...inputs, departamento_retorno_invalid: true}));
            } */

            if(typeof inputs.observacao === 'undefined'){
                invalids++;
                setInputs(inputs => ({...inputs, observacao_invalid: true}));
            }
            else if(inputs.observacao == null){
                invalids++;
                setInputs(inputs => ({...inputs, observacao_invalid: true}));
            }
            else if(inputs.observacao.length === 0){
                invalids++;
                setInputs(inputs => ({...inputs, observacao_invalid: true}));
            }
            else{
                setInputs(inputs => ({...inputs, observacao_invalid: false}));
            }

            if(invalids > 0){
                return false;
            }

            return true;
        }
    }
    const avaliarChat = () => {
        if(validar_avaliacao() === true){
            var url = ENDPOINT + 'chat-widget/avaliar-atendimento/' + getCookie('@chat.codigo');

            var formData = new FormData();
            formData.append('avaliacao', rating);
            formData.append('descricao', typeof inputs.observacao === 'undefined' ? '' : inputs.observacao);
            if(retorno == true){
                formData.append('retorno', Number(retorno));
                /* if(departamento_retorno !== null){
                    formData.append('departamento_retorno', departamento_retorno.value);
                } */
                
            }
            var username = getCookie('@chat.codigo');
            var senha = getCookie('@chat.senha_chat');

            axios.post(url, formData, {
                headers: {
                    Authorization: 'Basic ' + btoa(username+':'+senha)
                }
            })
                .then((response) => {
                    var is_response = response.data;
                    toast.success(is_response.message);
                    stateDefault();
                })
                .catch((error) => {
                    console.log(error.response);
                    
                    var response = '';
                    var obj;
                    if(error.message === 'Network Error'){
                        response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                        obj = JSON.parse(response);
                        toast.error(obj.errors);
                    }

                    var is_response = error.response.data;
                    if(is_response){
                        toast.error(is_response.errors);
                        if(is_response.code === 401){
                            stateDefault();
                        }
                    }
                });
        }
    }
    const finalizarChat = () => {
        setLoadingCreate(true);
        var url = ENDPOINT + 'chat-widget/finalizar-chat/' + getCookie('@chat.codigo');

        var formData = new FormData();

        var username = getCookie('@chat.codigo');
        var senha = getCookie('@chat.senha_chat');

        axios.post(url, formData, {
            headers: {
                Authorization: 'Basic ' + btoa(username+':'+senha)
            }
        })
        .then(() => {
            
                loadChatRequest();
        })
        .catch((error) => {
            var response = '';
            var obj;
            console.log(error.response);
            if(error.message === 'Network Error'){
                 response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                 obj = JSON.parse(response);
                toast.error(obj.errors);
            }

            var is_response = error.response.data;
            if(is_response){
                toast.error(is_response.errors);
                if(is_response.code === 401){
                    stateDefault();
                }
            }

        });

    }
    const onDrop = (file_new) => {
        var files = [];

        file_new.forEach(file_current => {
            files.push(file_current);
        });
        setAttach(false);
        sendMessageOnFile(files)
    }

    const checkDateTime = () => {
        if(checkAllUsers() === false && checkVendas() === false){
            return false;
        }
        return true;

    }
    const TitlesValid = () => {
        if(checkDateTime() === true){
            if(getCookie('@chat.codigo') === null){
                switch(step){
                    case 0: return <HeaderBeforeTalk>
                        Para continuar:
                    </HeaderBeforeTalk>;
                    case 1: return <HeaderBeforeTalk>
                    {/* <img style={{ width: '51px', marginRight: '15px' }} alt="logotipo-iniciar-atendimento" src={logo} /> */}
                        Iniciar conversa
                    </HeaderBeforeTalk>;
                    case 2: return <HeaderBeforeTalk>
                    {/* <img style={{ width: '51px', marginRight: '15px' }} src={logo} alt="logotipo-iniciar-atendimento" /> */}
                        Iniciar conversa
                    </HeaderBeforeTalk>;
                    case 3: return <HeaderAfterTalk>
                                <button className="minimizar" onClick={() => setVisible(!visible)}>
                                    <FaWindowMinimize style={{margin: 0, padding: '0px 0px 8px'}} />
                                </button>
                                <p style={{marginBottom: 0, font: 'normal normal medium 16px/22px Isidora Sans;'}}>
                                    Protocolo: <br/><b style={{font: 'normal normal bold 18px/26px Isidora Sans Bold'}}>{protocolo}</b>
                                    <p style={{float: 'right'}}>{moment(new Date()).format('DD/MM/YYYY')}</p>
                                </p>
                                    </HeaderAfterTalk>;
                    case 4: return <HeaderAfterTalk style={{height: '15vh'}}>
                                <button className="minimizar" onClick={() => setVisible(!visible)}>
                                    <FaWindowMinimize style={{margin: 0, padding: '0px 0px 8px'}} />
                                </button>
                                <div style={{textAlign: 'center', filter: 'drop-shadow(0px 3px 6px #00000029)'}} className="foto-atendente">
                                    <center>
                                        <ReactRoundedImage
                                            image={avatar_foto !== null ? avatar_foto : logo}
                                            roundedColor="#fff"
                                            imageWidth="100"
                                            imageHeight="96"
                                            roundedSize="3"
                                        />

                                    </center>
                                </div>
                            </HeaderAfterTalk>;
                    default: return <>
                        {/* <img style={{ width: '51px', marginRight: '15px' }} src={logo} alt="logotipo-avaliacao" /> */}
                            Iniciar conversa
                    </>;
                }
            }
            else{
                switch(step){
                    case 0: return <HeaderAfterTalk>
                                <button className="minimizar" onClick={() => setVisible(!visible)}>
                                    <FaWindowMinimize style={{margin: 0, padding: '0px 0px 8px'}} />
                                </button>
                                <p style={{marginBottom: 0, font: 'normal normal medium 16px/22px Isidora Sans;'}}>
                                    Protocolo: <br/><b style={{font: 'normal normal bold 18px/26px Isidora Sans Bold'}}>{protocolo}</b>
                                    <p style={{float: 'right'}}>{moment(new Date()).format('DD/MM/YYYY')}</p>
                                </p>
                                    </HeaderAfterTalk>;
                    case 1: return <HeaderAfterTalk style={{height: '15vh'}}>
                        <button className="minimizar" onClick={() => setVisible(!visible)}>
                            <FaWindowMinimize style={{margin: 0, padding: '0px 0px 8px'}} />
                        </button>
                        <div style={{textAlign: 'center', filter: 'drop-shadow(0px 3px 6px #00000029)'}} className="foto-atendente">
                            <center>
                                <ReactRoundedImage
                                    image={avatar_foto !== null ? avatar_foto : logo}
                                    roundedColor="#fff"
                                    imageWidth="100"
                                    imageHeight="96"
                                    roundedSize="3"
                                />

                            </center>
                        </div>
                    </HeaderAfterTalk>;
                    default: return <div>
                                {/* <ReactRoundedImage
                                    image={avatar_foto !== null ? avatar_foto : logo}
                                    roundedColor="#fff"
                                    imageWidth="53"
                                    imageHeight="53"
                                    roundedSize="3"
                                /> */}
                                <p style={{marginLeft: '17px', marginTop: '8px', marginBottom: 0}}>
                                    {nome_atendente !== 'Atendimento' ? nome_atendente : 'Em breve, nossos analistas o atenderão'}
                                    <small><br/>Protocolo: {protocolo}</small>
                                </p>
                                
                            </div>;
                }
            }
        }
        else{
            return <><HeaderBeforeTalk>
            {/* <img style={{ width: '51px', marginRight: '15px' }} src={logo} alt="logotipo-iniciar-atendimento" /> */}
                Chat Offline
            </HeaderBeforeTalk></>;
        }
    }
    const onMaskedMessage = (data, nome) => {
        var json = {
            cod_chat_message: data.cod_chat_message,
            nome: nome,
            message: data.message
        }

        setMasked(json);
    }

    const maskPhonePress = (e) => {
        console.log(e);

        var check = e.target.value.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll('_', '').replaceAll(' ', '').length;
        var tecla = e.key;
        if((!isNaN(tecla)) || tecla == "Backspace"){
            if(check >= 10){
                e.target.mask = '(99) 99999-9999';
                setMaskPhone('(99) 99999-9999');
            }
            else{
                e.target.mask = '(99) 9999-99999';
                setMaskPhone('(99) 9999-99999');
            }

            if(tecla == "Backspace"){
                if(check >= 13){
                    e.target.mask = '(99) 99999-9999';
                    setMaskPhone('(99) 99999-9999');
                }
                else{
                    e.target.mask = '(99) 9999-99999';
                    setMaskPhone('(99) 9999-99999');
                }
            }

        }
    }
    const ButtonRetorno = (validate, change, text) => {
        if(validate === change){
            return <ButtonDefault style={{margin: 0, width: '100%', padding: '3px', borderRadius: '10px'}} onClick={() => setRetorno(change)} >{text}</ButtonDefault>
        }
        else{
            return <ButtonOutline style={{margin: 0, width: '100%', padding: '3px', borderRadius: '10px'}} onClick={() => setRetorno(change)} >{text}</ButtonOutline>
        }

    }
    const AvaliacaoChat = () => {
        return (<div style={{textAlign: 'center', paddingTop: '40px'}}>
            {/* <p>O chat foi concluido, por favor avalie nosso atendimento</p> */}
            <p style={{    margin: 0}}>Atendimento ao cliente</p>
            <p style={{font: 'normal normal 600 27px/37px Isidora Sans', color: '#38B76E', marginBottom: '2px'}}>{nome_atendente}</p>
            <div style={{textAlign: 'left', marginBottom: '0'}}>
                <p style={{margin: 0, fontSize: 12}}>Avaliação do cliente</p>
            </div>
            <StarContainer rating={rating}>
                <StarRating
                    name="handler" 
                    caption="Use onClick Handlers!" 
                    totalStars={5} 
                    starDimension="45px"
                    starSpacing="2px"
                    starRatedColor="#38B76E"
                    rating={rating}
                    changeRating={(n) => setRating(n)}
                /> 
            </StarContainer>
            {rating > 0 ? 
                <> 
                {/* <p>Obrigado por sua avaliação, agora descreva em que podemos melhorar</p> */}
                <Form>
                    <div style={{textAlign: 'left'}}>
                        <p style={{margin: 0, fontSize: 11, marginBottom: 5}}>Ficou algo pendente, Vai necessitar de retorno?</p>
                        {/* <div style={{margin: '7px'}}>
                            <input type="radio" name="radio-retorno" checked={retorno} onChange={() => setRetorno(true)}/>&nbsp;Sim&nbsp;&nbsp;
                            <input type="radio" name="radio-retorno" checked={!retorno} onChange={() => setRetorno(false)}/>&nbsp;Não
                        </div> */}
                        <div style={{justifyContent: 'center', marginLeft: 20, marginRight: 20}}>
                            <Row>
                                <Col xs={6}>
                                    {ButtonRetorno(retorno, true, "Sim")}
                                </Col>
                                <Col xs={6}>
                                    {ButtonRetorno(retorno, false, "Não")}
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div hidden={!retorno} style={{margin: 0, marginTop: 8}}>
                        <FormGroup style={{margin: 0}}>
                            <InputGroup>
                                <Input 
                                    type="textarea" 
                                    placeholder="Descreva o motivo" 
                                    //className="msg"
                                    name="observacao"
                                    style={{ resize: 'none', height: '52px', fontSize: '16px' }} 
                                    value={inputs.observacao} 
                                    invalid={inputs.observacao_invalid} 
                                    onKeyPress={onKeyUp}
                                    onChange={handleInputChange} />
                                <InputGroupAddon addonType="prepend"><Button color="success" onClick={() => avaliarChat()}><MdSend style={{padding: 0}}/></Button></InputGroupAddon>
                                </InputGroup>
                            <FormFeedback>Descreva o motivo do retorno</FormFeedback>
                        </FormGroup>
                    </div>
                    <br/>
                    <div style={{textAlign: 'left', padding: 5, font: 'normal normal bold 18px/26px Isidora Sans Bold', border: '1px solid #38B76E', borderRadius: '10px'}}>
                        <small style={{color: '#38B76E'}}><b>Protocolo</b></small>
                        <center>
                            <h4>
                                <b>
                                    <NumberFormat
                                        displayType={'text'}
                                        value={protocolo}
                                        format="####.#######.###"
                                    />
                                </b>
                            </h4>
                        </center>
                    </div>
                    {/* <div style={{textAlign: 'left', border: '1px solid #38B76E', borderRadius: '7px'}}>
                        <b>Protocolo</b>
                    </div> */}
                        <ButtonDefault hidden={retorno}
                            style={{borderRadius: '10px', padding: '3px', width: '100px'}}
                            onClick={() => avaliarChat()}>Enviar</ButtonDefault>
                </Form>
            </> : ''}
            <br/>
        </div>)
    }
    /* const ChatOffline = () => {
        return (
        <center>
            Estamos Offline no momento, ficamos online das 8:30 às 17:30
        </center>
        )
    } */
    const sendValid = () => {
        var invalid = 0;
        if(file.length === 0){
            if(typeof inputs.message === 'undefined'){
                invalid++;
            }
            else if(inputs.message == null){
                invalid++;
            }
            else if(inputs.message.length === 0){
                invalid++;
            }
        }

        if(invalid === 0){
            sendMessage();
        }
    }
    const onKeyUp = (event) => {
        if (event.key === "Enter" && event.shiftKey === false) {
            sendMessage();
            event.preventDefault();
        }
    }
    const handleFileChange = (event) => {
        const file_new = event.target.files;
        var files = [];

        Array.from(file_new).forEach(file_current => {
            files.push(file_current);
        });

        setAttach(false);
        sendMessageOnFile(files)
    }

    const getFileExtension = (url) =>{
        var extension = get_extension(url);

        if(extension === '.png' ||
            extension === '.PNG' ||
            extension === '.jpg' ||
            extension === '.jpeg' ||
            extension === '.svg' ||
            extension === '.webp'){
            return 'text'
        }
        else if(extension === '.webm' ||
            extension === '.3gp' ||
            extension === '.ogg' ||
            extension === '.mp4'){
                return 'text';
        }
        else if(extension === '.mp3' ||
            extension === '.wav' ||
            extension === '.ogg'){
            return 'text';
        }

        return 'file';
    }
    const blobToBase64 = (blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                setAudio(reader.result);
            };
        });
    };
    const onStop = (audioData) => {
        if (audioData.data.size > 0) {
            blobToBase64(audioData.data)
        }
    }
    
    const base64toFile = (dataurl) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], 'new_file.wav', {type:mime});
    }

    const onSendAudio = () => {
        var files = [];
        var audio_file = base64toFile(audio);

        files.push(audio_file);

        setAudioVisible(false);
        sendMessageOnFile(files);
        setRecorderAudio(null);
        setAudio(null);
    }
    const onImageModal = (modal_image, urlImage) =>{
        //this.setState({modal_image, urlImage});
        setUrl(urlImage);
        setModalImage(modal_image);
    }
    const getHtmlExtension = (anexo, url, message) =>{
        if(anexo !== null){
            var extension = get_extension(url);

            if(extension === '.png' ||
                extension === '.PNG' ||
                extension === '.jpg' ||
                extension === '.jpeg' ||
                extension === '.svg' ||
                extension === '.webp'){
                //return <img src={url} />
                return <div className="rce-mbox-photo">
                        <div className="rce-mbox-photo--img">
                            <img src={url} alt="logotipo"/>
                            <div className="rce-mbox-photo--img__block">
                                <DropdownButton 
                                    outline
                                    className="dropdown-image rce-mbox-photo--img__block-item"
                                    title={<MdImage style={{ padding: '3px', width: '23px' }}/>}
                                    noCaret={true}
                                >
                                    <Dropdown.Item onClick={() => onImageModal(true, url)}><MdOpenInBrowser />Abrir</Dropdown.Item>
                                    <Dropdown.Item onClick={() => window.open(url, '_blank')}><MdImage />Download</Dropdown.Item>
                                </DropdownButton>
                                {/* <button 
                                    class="rce-mbox-photo--img__block-item rce-mbox-photo--download"
                                    onClick={() => window.open(url, '_blank')}>
                                    <MdFileDownload />
                                </button> */}
                            </div>
                        </div>
                    </div>
            }
            else if(extension === '.svg'){
                return <div className="rce-mbox-photo">
                <div className="rce-mbox-photo--img">
                    <img src={url} alt="logotipo"/>
                    <div className="rce-mbox-photo--img__block">
                        <DropdownButton 
                            outline
                            className="dropdown-image rce-mbox-photo--img__block-item"
                            title={<MdImage style={{ padding: '3px', width: '23px' }}/>}
                            noCaret={true}
                        >
                            <Dropdown.Item onClick={() => onImageModal(true, url)}><MdOpenInBrowser />Abrir</Dropdown.Item>
                            <Dropdown.Item onClick={() => window.open(url, '_blank')}><MdImage />Download</Dropdown.Item>
                        </DropdownButton>
                        {/* <button 
                            class="rce-mbox-photo--img__block-item rce-mbox-photo--download"
                            onClick={() => window.open(url, '_blank')}>
                            <MdFileDownload />
                        </button> */}
                    </div>
                </div>
            </div>
            }
            else if(extension === '.webm' ||
                extension === '.3gp' ||
                extension === '.ogg' ||
                extension === '.mp4'){
                return (<center><video controls>
                    <source src={url} />
                </video></center>)
            }
            else if(extension === '.mp3' ||
                extension === '.wav' ||
                extension === '.ogg'){

                return (<center><audio controls>
                    <source src={url} />
                </audio></center>)
            }

            return message;
        }

        if(message === 'ESSA MENSAGEM FOI APAGADA'){
            return <p style={{fontStyle: 'italic', margin: 0, opacity: 0.7}}><MdBlock style={{ height: '1rem', marginTop: '-2px', padding: 0 }} /> ESSA MENSAGEM FOI APAGADA</p>
        }

        return message.split('\n').map((x, i, {length}) => (
            <p style={{margin: (i + 1 === length ? 0 : 3)}}>{x}</p>
        ));
    }
    /* const auto_grow = (element) =>  {
        console.log(element.style)
        element.style.height = "5px";
        element.style.height = (element.scrollHeight)+"px !important";
    } */
    const getStatusMessage = (status, message) => {
        if(message === 'ESSA MENSAGEM FOI APAGADA'){
            return null;
        }
        else if(status === 0){
            return 'sent';
        }
        else if(status === 1){
            return 'received';
        }
        else if(status === 2){
            return 'read';
        }

        return 'sent';
    }
    const WidgetOmnichannel = () => {
        if(checkDateTime() === true){
            if(getCookie('@chat.codigo') === null){
                return <div
                  style={{
                    position: 'relative',
                    right: '0',
                    height: '41px'
                  }}
                ><div style={{display: 'flex', width: '169px', height: '42px'}}>
                    <img alt="imagem-fone" src={fone} style={{width: 46, height: 46, marginTop: '8px'}} />
                    <div style={{marginTop: '4px'}}>
                        <p style={{fontSize: '18px', lineHeight: '12px'}}>Dúvidas ?<br/>
                        <small style={{fontSize: '9px'}}>fale com nosso chat</small></p>
                    </div>
                </div>

                <div style={{
                        position: 'relative',
                        bottom: '18.5px',
                        right: '-16px',
                        textAlign: 'center',
                        zIndex: 9999
                    }}>
                    <div 
                        //hidden={!checar_se_existe_mensagens_nao_lidas()} 
                        style={{
                            boxShadow: 'inset 0px 0px 6px #00000057, 0px 0px 6px #00000029',
                            opacity: '0.9', 
                            borderRadius: '25px', 
                            padding: 4, 
                            background: '#00FF4E 0% 0% no-repeat padding-box',
                            width: '14px',
                            height: '14px',
                            color: '#fff'}}>
                        </div>
                    </div>
                </div>
            }
            else{
                return <>
                    <div style={{display: 'flex', width: '169px', height: '42px'}}>
                        <img alt="imagem-fone" src={fone} style={{width: 46, height: 46, marginTop: '7px'}} />
                        <div style={{marginTop: '4px'}}>
                            <p style={{fontSize: '18px', lineHeight: '12px'}}>Atendimento<br/>
                            <small style={{fontSize: '9px'}}>em andamento</small></p>
                        </div>
                        
                  <div style={{
                      position: 'relative', 
                      right: '0',
                      height: '17px',
                      width: '40px'
                    }}>
                    <div style={{
                        position: 'relative',
                        bottom: '-20.5px',
                        right: '165px',
                        textAlign: 'center', 
                        zIndex: 9999}}>
                                <span hidden={!checar_se_existe_mensagens_nao_lidas()} 
                                  style={{
                                    margin: 0, 
                                    backgroundColor: '#4bc0b0', 
                                    color: 'white',
                                    position: 'relative', 
                                    padding: '3px 6px',
                                    borderRadius: '100%',
                                    top: '-3.5px', 
                                    font: 'normal normal 600 14px/30px Isidora Sans'
                                  }}>{mensagens_nao_lidas()}</span>
                    </div>
                  </div>
                    </div>
                </>
            }
        }
        else{
            return <>
            <div style={{position: 'absolute', top: '-14px', left:'123px'}}>
                <img alt="imagem-z" src={z} style={{
                    top: '-6px',
                    position: 'relative',
                    filter: 'drop-shadow(0px 2px 2px #00000029)',
                    width: '12px',
                    left: '4px'
                }} />
                <img alt="imagem-z" src={z} style={{
                    position: 'relative',
                    filter: 'drop-shadow(0px 2px 2px #00000029)',
                    width: '15px'
                }} />
                <img alt="imagem-z" src={z} style={{
                    position: 'relative',
                    filter: 'drop-shadow(0px 2px 2px #00000029)',
                    width: '15px',
                    top: '5px',
                    left: '-21px'
                }} />
            </div>
            <div style={{display: 'flex', width: '175px', height: '42px'}}>
                <img alt="imagem-fone" src={fone} style={{width: 46, height: 46, marginTop: '8px'}} />
                <div style={{marginTop: '-7px'}}>
                    <p style={{fontSize: '16px', lineHeight: '12px'}}>
                        <small style={{fontSize: '9px'}}>Estamos tirando</small><br/>
                            Uma Soneca <br/>
                        <small style={{fontSize: '9px', top: '-3px', position: 'relative'}}>Enquanto isso abra um "Ticket"</small>
                    </p>
                </div>
            </div>

            <div style={{
                position: 'relative', 
                bottom: '-23px', 
                right: '159px', 
                textAlign: 'center', 
                zIndex: 9999}}>
                <div 
                    //hidden={!checar_se_existe_mensagens_nao_lidas()} 
                    style={{
                        boxShadow: 'inset 0px 0px 6px #00000057, 0px 0px 6px #00000029',
                        opacity: '0.9', 
                        borderRadius: '25px', 
                        padding: 4, 
                        background: '#FF0000 0% 0% no-repeat padding-box',
                        width: '14px',
                        height: '14px',
                        color: '#fff'}}>
                    </div>
                </div>
            </>
        }
    }
    const CurrentChat = () => {
        return (
            <div>
                
                <div style={{minHeight: '100px'}} hidden={!loading_create}>
                    <center>
                        <small style={{
                            left: '-15px',
                            position: 'relative'
                        }}>
                            <FadeLoader color={'#38B76E'} margin={0} loading={50} size={60} /><br />
                        </small>
                    </center>
                </div>
                <div 
                    hidden={loading_create}
                    ref={myRef} 
                    onScroll={onScroll}
                    style={{ height: '46vh', overflow: 'auto', marginBottom: '15px', lineHeight: '22px' }}>
                    
                    <MessageBoxArea type_message={0}>
                        <MessageBox
                            dateString={' '}
                            titleColor={'#38B76E'}
                            position={'left'}
                            title={nome_atendente}
                            type={'text'}
                            text={<>Ola, {getCookie('@chat.name_cliente')}! <br/>Bem vindo ao Atendimento AQBank, <br/>você está sendo atendido por um humano.</>}
                            avatar={avatar_foto !== null ? avatar_foto : logo}
                            //date={new Date()}
                        />
                    </MessageBoxArea>
                    {list_messages.map((p, index) => {
                        if(p.type_message === 0 || p.type_message === 1){
                            return (
                              <div >
                                <div key={index}
                                    onClick={() => readMessageSpecific(p)}
                                    >
                                    {/* <ReplyButton onClick={() => onMaskedMessage(p, p.type_message === 0 ? p.admin.name : 'Eu')} type_message={p.type_message}><FaReply /></ReplyButton>
                                     */}
                                     <DropdownCurrent type_message={p.type_message}>
    
                                        {/* <div className="chat-reposta" type_message={p.type_message}>
                                            { p.type_message === 1 ? <div><Button disabled={p.message === 'ESSA MENSAGEM FOI APAGADA'} color="danger" onClick={() => apagar_mensagem(p)}>
                                                <FaTrash/>
                                            </Button></div> : <></>}
                                            
                                            <Button disabled={p.message === 'ESSA MENSAGEM FOI APAGADA'} color="info" onClick={() => onMaskedMessage(p, p.type_message === 0 ? (p.admin !== null ? p.admin.name : 'Atendimento') : 'Eu')}>
                                                <MdReply/>
                                            </Button>
                                        </div> */}
                                     </DropdownCurrent>
                                     <MessageBoxArea 
                                        borderColor={'6px solid '+(p.type_message === 0 ? (p.status_message !== 2 ? '#198754' : 'transparent') : 'transparent') }
                                        type_message={p.type_message} 
                                        status_message={p.status_message}>
                                      {p.message !== 'ESSA MENSAGEM FOI APAGADA' ? 
                                         <ButtonsMessage type_message={p.type_message}>

                                            <Button outline disabled={p.message === 'ESSA MENSAGEM FOI APAGADA'} color="success" ><MdReply style={{padding: 0}} onClick={() => onMaskedMessage(p, p.type_message === 0 ? (p.admin !== null ? p.admin.name : 'Atendimento') : 'Eu')}/></Button>&nbsp;&nbsp;&nbsp;
                                            { p.type_message === 1 ? <><Button outline disabled={p.message === 'ESSA MENSAGEM FOI APAGADA'} color="success" onClick={() => apagar_mensagem(p)} ><MdDelete style={{padding: 0}} /></Button></> : <></>}
                                         </ButtonsMessage>
                                         : <></>}
                                        <MessageBox
                                            titleColor={p.type_message === 0 ? "#161b22" : '#38B76E'}
                                            data={p.anexo !== null ? getFileExtension(p.anexo) !== 'text' ? (
                                                {
                                                    uri: ENDPOINT+'archive_chat/'+p.cod_chat_message+'/'+p.message,
                                                    status: {
                                                        click: false,
                                                        loading: 0,
                                                    }
                                                } ) : null  : null}
                                            dateString={moment(new Date(p.created_at)).format('HH:m')}
                                            status={p.type_message === 1 ? getStatusMessage(p.status_message, p.message) : null}
                                            avatar={p.type_message === 0 ? p.admin.avatar_foto : ''}
                                            position={p.type_message === 1 ? 'right' : 'left'}
                                            reply={p.mask_messages !== null ? {
                                                title: p.mask_messages.type_message === 0 ? p.mask_messages.admin.name : 'Eu',
                                                titleColor: '#8717ae', message: p.mask_messages.message,
                                            } : null}
                                            onTitleClick={() => onMaskedMessage(p, p.type_message === 0 ? p.admin.name : '')}
                                            type={p.anexo !== null ? getFileExtension(p.anexo) : 'text'}
                                            onDownload={() => window.open(p.anexo, '_blank')}
                                            title={p.type_message === 0 ? p.admin.name : null}
                                            text={getHtmlExtension(p.anexo, ENDPOINT+'archive_chat/'+p.cod_chat_message+'/'+p.message, p.message)}
                                            //date={new Date(p.updated_at)}
                                        />
                                    </MessageBoxArea>
                                </div>
                                </div>
                            );
                        }
                        else if(p.type_message === 2){
                            return(
                                <center>
                                    <small style={{color: 'dimgray', padding: 0}}>{p.message}</small>
                                    <hr style={{color: 'dimgray', marginTop: '3px'}}/>
                                </center>
                            )
                        }
                        else{
                            return(<></>);
                        }
                    })
                    }
                    <center hidden={fila === null || fila === 0} style={{color: '#8d8d8d'}}>
                        <small><FaArrowCircleRight />Aguarde para ser atendido, posição na fila: {fila}</small>
                    </center>

                    <div style={{position: 'absolute', top:'16%', right: '27%', textAlign: 'center', zIndex: 9999}}>
                        <div 
                            hidden={!scroll_novas_mensagens()} 
                            style={{
                                border: '1px solid rgb(113 207 129)', 
                                opacity: '0.9',
                                borderRadius: '25px', 
                                padding: 4, 
                                backgroundColor: 'rgb(113 207 129)', 
                                color: '#fff'
                            }}>
                                <small><MdMail/>Você tem novas mensagens</small>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        marginRight: '8px',
                        marginLeft: '8px'
                    }}
                    hidden={loading_create}>
                    <hr/>
                    <Collapse isOpen={modal_attach}>
                    <Dropzone onDrop={(drop) => onDrop(drop)}>
                        {({ getRootProps, getInputProps }) => (
                            <DropArea {...getRootProps({
                                onClick: event => event.stopPropagation()
                            })}>
                                <input {...getInputProps()} />
                                {file.length > 0 ?
                                    <Row>
                                        {file.map((e, index) => {
                                            return <Col key={index} md={6} style={{ paddingBottom: 4 }}>
                                                <Card className="card-file">
                                                    <p style={{ margin: '3px' }}>
                                                        <MdAttachFile style={{ width: '32px' }} />
                                                        {e.name}&nbsp;
                                                        <Button
                                                            style={{ padding: 0, border: 3, float: 'right' }}
                                                            color="danger"
                                                            outline
                                                            onClick={
                                                                () => removeAttach(index)
                                                            }>
                                                            <MdClear style={{ padding: 0 }} />
                                                        </Button>
                                                    </p>
                                                </Card>
                                            </Col>
                                        })}
                                        <br />
                                    </Row>
                                    :
                                    <>
                                        <p style={{ margin: 0, fontSize: '13px', lineHeight: '12px' }}>
                                            <MdAttachFile style={{ width: '32px' }} />
                            Arraste arquivos ou <ButtonUpload style={{ fontSize: '13px' }} onClick={() => inputFile.current.click()} className="btn btn-dark" color="primary">
                                                Clique Aqui
                            </ButtonUpload> para adicionar anexos
                                    <input type="file" ref={inputFile} multiple hidden onChange={(e) => handleFileChange(e)} />
                                        </p>
                                    </>
                                }
                            </DropArea>
                        )}
                    </Dropzone>
                    </Collapse>
                    <Collapse isOpen={audio_visible}>
                        <Row hidden={audio !== null}>
                            <Col style={{display: 'inherit'}}>
                                <div style={{ 
                                    border: '1px solid rgb(112, 112, 112, 0.18)', 
                                    alignItems: 'center', 
                                    borderRadius: '21px', 
                                    background: '0% 0% no-repeat padding-box padding-box rgb(209 208 208 / 18%)', 
                                    width: '85%', 
                                    marginRight: '10px'}}>
                                    <Row>
                                        <Col style={{display: 'flex', paddingTop: '3px', fontSize: '23px'}}>
                                            <Recorder><MdMic /></Recorder>
                                            <p style={{padding: 0, margin: 0}}>
                                                {(Math.floor(time / 60)) < 10 ? '0' : '' }
                                                {Math.floor(time / 60)}:
                                                {(time - Math.floor(time / 60) * 60) < 10 ? '0' : ''}
                                                {time - Math.floor(time / 60) * 60}</p>
                                        </Col>
                                        <Col style={{ paddingTop: '3px', paddingRight: '25px', font: 'normal normal 600 22px/30px Isidora Sans Bold'}}>
                                            <div style={{float: 'right'}}>
                                                <Button color="danger" className="widget_btn talk_btn" style={{borderRadius: 0}} outline onClick={() => resetAudio()}>
                                                    <MdClear style={{ padding: 0, margin: '2px' }} /> cancelar
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                    <Button className="widget_btn" style={{borderRadius: '20px', padding: '6px'}} color="success" onClick={() => stopAudio()}><FaPaperPlane style={{ padding: 2, margin: '2px' }} /> </Button>
                                {/* <Button color="success"onClick={() => {stopAudio()}}>Enviar</Button> */}
                            </Col>
                        </Row>
                        <Row hidden={audio === null}>
                            <Col style={{ display: 'inherit' }}>
                                <div style={{
                                    alignItems: 'center', 
                                    borderRadius: '21px', 
                                    width: '85%', 
                                    backgroundColor: '#ecedee',
                                    paddingRight: '10px',
                                    marginRight: '10px'}}>
                                    <Row>
                                        <Col style={{display: 'flex', paddingTop: '3px', fontSize: '23px'}}>
                                            <audio style={{width: '100%'}} controls src={audio} />
                                            <Button className="widget_btn talk_btn" style={{borderRadius: 0}} outline onClick={() => resetAudio()}><MdDelete style={{ padding: 0, margin: '2px' }} /></Button>
                                        </Col>
                                    </Row>
                                </div>
                                <Button className="widget_btn" style={{borderRadius: '20px', padding: '6px'}} color="success" onClick={() => onSendAudio()}><FaPaperPlane style={{ padding: 2, margin: '2px' }} /> </Button>
                            </Col>
                        </Row>
                    </Collapse>
                <Row hidden={!loading}>
                    <Col md={12}>
                        <center>
                            <small><PropagateLoader color={'#38B76E'} loading={50} size={15} /><br />
                                </small>
                        </center>
                    </Col>
                </Row>
                <Row hidden={!masked}
                >
                    <Col md={12}>
                        <Collapse 
                            isOpen={masked !== null} 
                            style={{ 
                                borderLeft: '4px solid #28a745', 
                                paddingLeft: '6px', 
                                marginBottom: 10, 
                                width: '100%', 
                                backgroundColor: '#cccccc' 
                            }}>
                            <small>
                                <b>{masked !== null ? masked.nome : ''} 
                                <Button 
                                    color="danger" 
                                    onClick={() => deleteMasked()} 
                                    style={{ padding: 0, float: 'right', margin: '11px', borderRadius: '50%', height: '27px', width: '27px' }}>
                                        <MdClear style={{ padding: 0 }} />
                                </Button></b><br /><div style={{lineHeight: '17px'}}>{masked !== null ? (masked.message.length > 100 ? masked.message.substr(0, 100)+'...' : masked.message)  : ''}</div></small>
                        </Collapse>
                    </Col>
                </Row>
                <div style={{ display: 'inherit' }} hidden={loading || audio_visible}>
                    <Row>
                        <Col style={{ display: 'inherit', alignItems: 'center' }}>
                            <div style={{ 
                                border: '1px solid rgb(112, 112, 112, 0.18)', 
                                borderRadius: '21px', 
                                background: '0% 0% no-repeat padding-box padding-box rgb(209 208 208 / 18%)', 
                                width: '85%', 
                                minHeight: '39px',
                                display: 'flex', 
                                marginRight: '10px'}}>
                                    <Button className="widget_btn talk_btn" style={{borderRadius: 0}} outline onClick={() => filePhoto.current.click()}><MdCameraAlt style={{ padding: 0, margin: '2px' }} /></Button>
                                    <input type="file" ref={filePhoto} accept="image/*"  multiple hidden onChange={(e) => handleFileChange(e)} />
                                    <TextareaAutosize
                                        className="msg"
                                        name="message"
                                        invalid={inputs.message_invalid} 
                                        style={{ resize: 'none', background: 'transparent', width: '100%' }} 
                                        value={typeof inputs.message !== 'undefined' ? inputs.message : ''} 
                                        onKeyPress={onKeyUp}
                                        onChange={handleInputChange} 
                                    />
                                    <Button className="widget_btn talk_btn" style={{borderRadius: 0}} color="danger" outline onClick={() => setFinaly(!finaly)}><MdExitToApp style={{ padding: 0, margin: '2px' }} /></Button>
                                    <Button className="widget_btn talk_btn" style={{borderRadius: 0}} outline onClick={() => setAttach(!modal_attach)}><GiPaperClip style={{ padding: 0, margin: '2px' }} /></Button>
                            </div>
                            <div >
                                {/*  */}
                               {typeof inputs.message === 'undefined' || inputs.message === '' ? 
                                    <Button className="widget_btn" style={{borderRadius: '20px', padding: '6px'}} color="success" onClick={() => startAudio()}><MdMic style={{ padding: 0, margin: '5px' }} /></Button> :
                                    <Button className="widget_btn" style={{borderRadius: '20px', padding: '6px'}} color="success" onClick={() => sendValid()}><FaPaperPlane style={{ padding: 0, margin: '2px' }} /> </Button>} 
                            </div>
                            
                        </Col>
                    </Row>
                </div>
                    <Collapse isOpen={finaly}>
                    <Row>
                        <Col style={{textAlign: 'center', marginTop: '15px', fontSize: '11px', margin: 0}}>
                            <b>DESEJA FINALIZAR O CHAT?</b>
                            <div style={{marginTop: '4px'}}>
                                <Button outline color="success" style={{borderRadius: '26px', fontSize: '11px'}} onClick={() => finalizarChat()}>Sim</Button> &nbsp;
                                <Button outline color="danger" style={{borderRadius: '26px', fontSize: '11px'}} onClick={() => setFinaly(false)}>Não</Button>
                            </div> </Col>
                    </Row>
                </Collapse>
                <center>
                    <small style={{fontSize: '8.5px', color: '#2aa156', fontWeight: '600'}}>Nos ajude a melhorar o seu atendimento, avaliando o seu atendimento</small>
                </center>
                </div>
                <div className="modal-image" >
                    <Modal isOpen={modal_image} centered size="lg" style={modalStyles}>
                        <ModalBody>
                            <center>
                                <img 
                                    alt="imagem-aberta"
                                    style={{
                                        maxWidth: '100%'
                                    }}
                                    src={urlImage}
                                />
                            </center>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={() => onImageModal(false, '')}>Fechar</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        )
    }
    
    var modalStyles = {overlay: {}};
    modalStyles.overlay["z-index"] = 25000;
    
    return (
        <CssWidget >
            <div className="conversa-widget bootstrap-isolated">
              <div className="widget" >
                  <div className="widget-container arrow-bottom" hidden={!visible}>
                      <div className="widget-header">
                          <img style={{ width: '130px', marginRight: '15pxpxpx' }} class="logotipo-padrao" alt="logotipo-espera-atendente" src={logoAqbank} />
                          {TitlesValid()}
                      </div>
                      <div className="widget-content" hidden={checkDateTime() === false}>

                          {/*
                              Escolha de Tipo de Atendimento: se já é um cliente ou um cliente novo
                          */}
                          <div id="chat-enter" hidden={!(step === 0 && getCookie('@chat.codigo') === null)}>
                              <center>
                                  {/* <h5>Para iniciar, selecione se você já é um cliente de servicos da AQBANK</h5>
                                  <br/> */}
                                      <Row style={{justifyContent: 'center'}}>
                                          <Col style={{paddingLeft: 15}}>
                                              <div data-type="error" data-tip={checkAllUsers() === false ? 'Infelizmente, não há atendentes disponiveis nesse momento, tente contatar em nosso horario de atendimento de Segunda à Sexta - 08:30 às 17:30' : null}>
                                                  <ButtonOutline 
                                                      disabled={!checkAllUsers()}  
                                                      style={{
                                                          width: '100%'
                                                      }}
                                                      className="select-chat" 
                                                      onClick={() => setStep(2)}>
                                                      Já sou cliente
                                                  </ButtonOutline>
                                              </div>
                                          </Col>
                                          <Col style={{paddingLeft: 0, paddingRight: '21px'}}>
                                              <div data-type="error"  data-tip={checkVendas() === false ? 'Infelizmente, não há atendentes disponiveis nesse momento, tente contatar em nosso horario de atendimento de Segunda à Sexta - 08:30 às 17:30' : null}>
                                                  <ButtonDefault 
                                                      className="select-chat" 
                                                      style={{
                                                          width: '100%'
                                                      }}
                                                      disabled={!checkVendas()} 
                                                      onClick={() => setStep(1)}>
                                                      Não sou cliente
                                                  </ButtonDefault>
                                              </div>
                                          </Col>
                                      </Row>
                                      <div style={{marginBottom: 10}}>
                                          <small style={{fontSize: '14px', color: '#2DB24A'}}>Converse agora com um de nossos analistas</small>
                                      </div>
                              </center>
                          </div>

                          {/*
                              Se não é um cliente, criar uma oferta
                          */}
                          <div id="chat-new-client" hidden={!(step === 1 && getCookie('@chat.codigo') === null)}>
                              <div style={{minHeight: '100px'}} hidden={!loading_create}>
                                  <center>
                                      <small style={{
                                          left: '-15px',
                                          position: 'relative'
                                      }}>
                                          <FadeLoader color={'#38B76E'} margin={0} loading={50} size={60} /><br />
                                      </small>
                                  </center>
                              </div>
                              <Form onSubmit={handleSubmit} hidden={loading_create}>
                                  <FormGroup style={{marginBottom: 10}}>
                                      <InputMasked 
                                          mask={mask} 
                                          placeholder="Informe CPF ou CNPJ para criar uma oferta" 
                                          type="text"
                                          name="cpf"
                                          mask={option_cnpj === false ? '999.999.999-99' : '99.999.999/9999-99'}
                                          value={inputs.cpf}
                                          invalid={inputs.cpf_invalid} 
                                          onChange={handleInputChange} />
                                    <div style={{textAlign: 'right'}}>
                                      <input type="checkbox" onChange={(event) => setOptionCnpj(event.target.checked)}/> Mudar para CNPJ
                                    </div>
                                  </FormGroup>
                                  <FormGroup style={{marginBottom: 10}}>
                                      <InputText 
                                          placeholder="E-mail" 
                                          name="email"
                                          value={inputs.email} 
                                          invalid={inputs.email_invalid} 
                                          onChange={handleInputChange} />
                                  </FormGroup>
                                  <FormGroup style={{marginBottom: 10}}>
                                      <InputMasked 
                                          mask={maskPhone} 
                                          //mask={getPhoneLenght() === true ? '(99) 99999-9999' : '(99) 9999-99999'}
                                          placeholder="Telefone" 
                                          name="telefone"
                                          onKeyDown={(e) => maskPhonePress(e)}
                                          value={inputs.telefone} 
                                          invalid={inputs.telefone_invalid} 
                                          onChange={handleInputChange} />
                                  </FormGroup>
                                  <FormGroup className="servico-style" style={{marginBottom: 10}}>
                                      <Select
                                          menuPlacement="top"
                                          placeholder="Selecione o serviço"
                                          options={servicos_list}
                                          isSearchable={false}
                                          onChange={setServico}
                                          theme={theme => ({
                                              ...theme,
                                              borderRadius: 6
                                            })}
                                      />
                                      <Input hidden invalid={inputs.servico_invalid} />
                                      <FormFeedback>Escolha o serviço que deseja</FormFeedback>
                                  </FormGroup>
                                  <FormGroup>
                                          <Row>
                                              <Col xs={4} style={{paddingLeft: 15}}>
                                                  <ButtonOutline 
                                                      style={{
                                                          width: '100%'
                                                      }}
                                                      className="select-chat back" onClick={() => setStep(0)}>Voltar</ButtonOutline>
                                              </Col>
                                              <Col xs={8} style={{paddingLeft: 0}}>
                                                  <ButtonDefault 
                                                      className="button-chat" 
                                                      style={{
                                                          width: '100%'
                                                      }}
                                                      onClick={() => signUpChat()}>Entrar na conversa</ButtonDefault>
                                              </Col>
                                          </Row>
                                  </FormGroup>
                              </Form>
                          </div>

                          {/*
                              Se já é um cliente, escolher um tipo de atendimento
                          */}
                          <div id="chat-exists-client" hidden={!(step === 2 && getCookie('@chat.codigo') === null)}>
                              <div style={{minHeight: '100px'}} hidden={!loading_create}>
                                  <center>
                                      <small style={{
                                          left: '-15px',
                                          position: 'relative'
                                      }}>
                                          <FadeLoader color={'#38B76E'} margin={0} loading={50} size={60} /><br />
                                      </small>
                                  </center>
                              </div>
                              <Form onSubmit={handleSubmit} hidden={loading_create}>
                                  <FormGroup  style={{marginBottom: 10}}>
                                      <InputMasked 
                                          mask={mask} 
                                          placeholder="CPF ou CNPJ cadastrado" 
                                          type="text"
                                          name="cpf"
                                          mask={option_cnpj === false ? '999.999.999-99' : '99.999.999/9999-99'}
                                          value={inputs.cpf} 
                                          invalid={inputs.cpf_invalid} 
                                          onChange={handleInputChange} />
                                  </FormGroup>
                                  <div style={{textAlign: 'right'}}>
                                    <input type="checkbox" onChange={(event) => setOptionCnpj(event.target.checked)}/> Mudar para CNPJ
                                  </div>
                                  <FormGroup style={{marginBottom: 10}}>
                                      <Select
                                          menuPlacement="top"
                                          placeholder="Selecione o assunto"
                                          options={list_departamentos}
                                          isSearchable={false}
                                          theme={theme => ({
                                              ...theme,
                                              borderRadius: 6
                                            })}
                                          onChange={setDepartamento}
                                      />
                                  </FormGroup>
                                  <FormGroup style={{marginBottom: 10}}>
                                      <InputText 
                                          type="textarea" 
                                          name="message"
                                          placeholder="Digite sua mensagem" 
                                          invalid={inputs.message_invalid} 
                                          style={{ resize: 'none', borderRadius: 6 }} 
                                          value={inputs.message}
                                          onChange={handleInputChange}
                                        />
                                  </FormGroup>
                                  <FormGroup>
                                      <Row>
                                          <Col xs={4} style={{paddingLeft: 15}}>
                                              <ButtonOutline 
                                                  style={{
                                                      width: '100%'
                                                  }}
                                                  className="select-chat back" onClick={() => setStep(0)}>Voltar</ButtonOutline>
                                          </Col>
                                          <Col xs={8} style={{paddingLeft: 0}}>
                                              <ButtonDefault 
                                                  className="button-chat" 
                                                  style={{
                                                      width: '100%'
                                                  }}
                                                  onClick={() => signUpChat()}>Entrar na conversa</ButtonDefault>
                                          </Col>
                                      </Row>
                                      
                                  </FormGroup>
                              </Form>
                          </div>

                          <div id="chat-running" hidden={!(step === 0 && getCookie('@chat.codigo') !== null)}>
                              {CurrentChat()  }
                          </div>


                          <div id="chat-avaliacao" hidden={!(step === 1 && getCookie('@chat.codigo') !== null)}>
                              {AvaliacaoChat()}
                          </div>
                      </div>
                      <div style={{margin: 20}} hidden={checkDateTime() === true}>
                          <p align="justify">Olá, agradecemos seu contato.<br/>
                              Não há atendentes disponiveis nesse momento, nosso horário de atendimento
                              é de Segunda à Sexta de 08:15 até 18:00, favor entre em contato em
                              horário comercial, ou abra um ticket para qualquer solicitação ou dúvida.
                          </p>
                          <ButtonDefault style={{margin: 0}} onClick={() => { window.open('https://erp.aqpago.com.br/submitticket.php?step=2&deptid=19', '_blank')}}>Abrir Ticket</ButtonDefault>
                      </div>
                      <div className="widget-footer">
                      </div>
                  </div>
                  <ButtonOpen 
                      visible={visible}
                      back={background}
                      onClick={() => setVisible(!visible)}
                  >
                      <div style={{display: 'flex', float: 'right'}}>
                          <WidgetOmnichannel />
                          <img src={aqbank_white} alt="logo" className="logo-button" />
                      </div>
                  </ButtonOpen>
              </div>
              <ReactTooltip />
              <ToastContainer hideProgressBar/>
            </div>
        </CssWidget>
    );
}

export default App;