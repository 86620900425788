import React from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, Row, Col, Collapse, Input, CardFooter } from 'reactstrap';
import { columns } from './propsChat';
import debounce from 'lodash/debounce';
import Pagination from '../Pagination';
import { InputText } from '../../InputText/InputText';
import Select from 'react-select';

export const TableChat = (props) => {
    return (
        <Card>
            <Collapse isOpen={true}>
                <Card style={{ border: 'none' }}>
                    <CardBody>
                        <Row >
                            <Col md={6}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputText
                                        name="search"
                                        placeholder="Buscar"
                                        onChange={debounce((event) => props.isSearch(event), 1000)} />
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row >
                            <Col md={6}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputText
                                        name="protocolo_atendimento"
                                        placeholder="Protocolo de Atendimento"
                                        onChange={debounce((event) => props.isSearch(event), 1000)} />
                                </div>
                            </Col>
                            <Col md={6}>
                                <Select 
                                    options={props.operadores_list}
                                    value={props.operador}
                                    onChange={props.handleSelectChange}
                                    placeholder="Busca por Operador"/>
                            </Col>
                            <Col hidden={props.clientChat}>
                                <div style={{ textAlign: 'left' }}>
                                    <InputText
                                        name="email"
                                        placeholder="E-mail"
                                        defaultValue={props.email}
                                        onChange={debounce((event) => props.isSearch(event), 1000)} />
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>De:
                            <Input
                                    name="date_from"
                                    type="date"
                                    //disabled
                                    disabled={!(props.filter === true)}
                                    value={props.date_from}
                                    onChange={(event) => props.isSearch(event)} /></Col>
                            <Col>Até:
                            <Input
                                    name="date_to"
                                    type="date"
                                    disabled={!(props.filter === true)}
                                    //disabled
                                    value={props.date_to}
                                    onChange={(event) => props.isSearch(event)} /></Col>
                        </Row>
                        <Row>
                            <Col md={5}>
                                <input
                                    type="checkbox"
                                    checked={props.filter}
                                    name="filter"
                                    onChange={(value) => props.onFilter(value.target.checked)} /> Filtrar por data de envio

                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Collapse>
            <hr style={{ marginBottom: '4px' }} />
            <DataTable
                noHeader={true}
                button={true}
                columns={columns(props.onMessage)}
                noDataComponent={props.noDataComponent}
                data={props.data}
                theme="default"
            />
            <CardFooter style={{ textAlign: 'center' }}>
                <Pagination
                    onPageChange={props.onPageChange}
                    activePage={props.activePage}
                    totalPage={props.totalPage}
                />
            </CardFooter>
        </Card>
    )
}