import { useEffect, useState } from "react";
import { TableCustomized } from "../../../components/Tables/TableCustomized/TableCustomized";
import { Button, Card, CardBody } from "reactstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import { HiViewGrid } from "react-icons/hi";

import NumberFormat from "react-number-format";
import { validateInputAqmed, searchWithMask } from "../utils";
import Cookies from "js-cookie";
import { useApiAqmed } from "../../../hooks/useApi";
import { useHistory } from "react-router-dom";

const getDataFormated = (apiResponse) => {
  const data = apiResponse.map((item) => {
    return {
      id: item?.public_id,
      name: item?.owner?.name,
      document: item?.owner?.document,
      contract: item?.contract_number,
      operation: item?.operation_name,
      identification: item?.totalpass_identification,
    };
  });
  return data;
};

export function TotalPass() {
  const [isFecthing, setIsFecthing] = useState(true);
  const [links, setLinks] = useState(null);
  const [filter, setFilter] = useState({
    document: null,
    page: null,
  });
  const [search, setSearch] = useState(null);
  const [data, setData] = useState([]);

  const { apiAqmed } = useApiAqmed();
  const history = useHistory();

  const onSearchDocument = () => {
    const verify = validateInputAqmed(search.document);
    const documentWithoutMask = search.document.replace(/[.\-/]/g, "");

    if (verify?.valid) {
      setSearch({
        document: search.document,
        ...verify,
      });
      setIsFecthing(true);
      setData([]);
      setFilter({ page: null, document: documentWithoutMask });
    } else {
      setSearch({
        document: search.document,
        ...verify,
      });
    }
  };
  const searchOnChange = (event) => {
    searchWithMask(event, setSearch);
  };

  const onChangePage = (event) => {
    setIsFecthing(true);
    setData([]);
    setFilter({ page: Number(event), document: null });
  };

  useEffect(() => {
    const token = Cookies.get("aqmedtoken");
    let url = "/crm/totalpass";
    if (filter.page) url += `?page=${filter.page}`;
    if (filter.document) url += `?document=${filter.document}`;
    apiAqmed
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const clients = getDataFormated(res?.data?.totalpass_contracts?.data);
        setData(clients);
        setLinks(res?.data?.page);
      })
      .catch((e) => {
        alert("Erro ao buscar dados");
        console.log(e);
      })
      .finally(() => {
        setIsFecthing(false);
        setSearch(null);
      });
  }, [filter]);

  const columns = () => {
    return [
      {
        name: "",
        selector: "view",
        sortable: false,
        width: "7%",
        cell: (row) => (
          <Button
            data-tip="Resumo do Cliente"
            outline
            style={{ padding: "0px", marginRight: "3px", border: 0 }}
            color="primary"
            onClick={() => history.push(`/aqmed/usuario/totalpass/${row?.id}`)}
          >
            <HiViewGrid style={{ padding: "2px", width: "23px" }} />
          </Button>
        ),
      },
      {
        name: "Documento",
        selector: "cpf",
        sortable: false,
        width: "20%",
        cell: (row) => {
          if (row?.document) {
            return (
              <NumberFormat
                displayType={"text"}
                value={row?.document}
                format={
                  row?.document.replace(/([^\d])+/gim, "").length > 11
                    ? "##.###.###/####-##"
                    : "###.###.###-##"
                }
              />
            );
          }
          return "S/N";
        },
      },
      {
        name: "Nome / Empresa",
        selector: "nome",
        sortable: false,
        width: "20%",
        cell: (row) => row?.name,
      },
      {
        name: "Contrato",
        selector: "contract",
        sortable: false,
        width: "20%",
        cell: (row) => row?.contract,
      },
      {
        name: "Operador",
        selector: "Operation Name",
        sortable: false,
        width: "20%",
        cell: (row) => {
          if (row?.operation) return row?.operation;
          return "Não Informado";
        },
      },
      {
        name: "Id TotalPass",
        selector: "created_at",
        sortable: false,
        width: "15%",
        cell: (row) => <div>{row?.identification}</div>,
      },
    ];
  };
  return (
    <>
      <h3>Clientes TotalPass</h3>
      <hr />
      <TableCustomized
        search={search}
        onSearchDocument={onSearchDocument}
        searchOnChange={searchOnChange}
        data={data}
        onPageChange={onChangePage}
        activePage={links?.currentPage}
        totalPage={links?.lastPage}
        columns={columns}
        noDataComponent={
          isFecthing ? (
            <Card style={{ border: 0, padding: "10vh" }}>
              <CardBody>
                <div>
                  <div className="sweet-loading">
                    <PropagateLoader color={"#52c565"} loading={20} size={30} />
                  </div>
                </div>
              </CardBody>
            </Card>
          ) : (
            <Card style={{ border: 0 }}>
              <CardBody>
                <div>Nenhum usuário encontrado</div>
              </CardBody>
            </Card>
          )
        }
      />
    </>
  );
}
