import { requestInfo, isLogout, loginShop, getTokenloginShop } from "./services";
export const TOKEN_KEY = "@api.aqpasso.token";
export const TOKEN_TEMP_KEY = "@api.aqpasso.temp.token";
export const USER_LOGGED = "@api.aqpasso.user";
export const USER_PERMISSION = "@api.aqpasso.permission";
export const USER_LOGGED_STATUS = "@api.aqpasso.status";

export const TOKEN_KEY_SHOP = "@api.shopaqpago.token";

export const isAuthenticated = () => {
  if(localStorage.getItem(TOKEN_KEY) !== null){
      requestInfo(localStorage.getItem(TOKEN_KEY), localStorage.getItem(USER_LOGGED))
  }
  return localStorage.getItem(TOKEN_KEY) !== null;
};


export const getToken = () => { 
    return localStorage.getItem(TOKEN_KEY);
};

export const getTokenShop = () => { 
  return localStorage.getItem(TOKEN_KEY_SHOP);
}

export const createTokenShop = async (tax_document) => { 
  let tokenApi = await getTokenloginShop(tax_document);

  // console.log('tax_document')
  // console.log(tax_document)

  // console.log('tokenApi')
  // console.log(tokenApi)
  
  let response = await loginShop(tokenApi, tax_document);

  // console.log('response')
  // console.log(response)
  
  return response;
};

const getId = () => {
  if (window.location.pathname === '/movimentacao') {
      return null;
  }
  else {
      return window.location.pathname.replace('/movimentacao/', '')
  }
}

export const getStatus = () => { 
    return parseInt(localStorage.getItem(USER_LOGGED_STATUS), 10);
};

export const getUser = () => {
    return JSON.parse(localStorage.getItem(USER_LOGGED));
};

export const getPermissoes = () => {
  return JSON.parse(localStorage.getItem(USER_PERMISSION));
};


export const get_permission = getUser() ? getUser().access_system : null;

export const refreshUser = (user, permissao, status_chat) => {
  localStorage.setItem(USER_LOGGED, user);
  localStorage.setItem(USER_PERMISSION, permissao);
  localStorage.setItem(USER_LOGGED_STATUS, status_chat);
};

export const setData = (user, permissao, status_chat) => {
  var token = localStorage.getItem(TOKEN_TEMP_KEY);
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(USER_LOGGED, user);
  localStorage.setItem(USER_LOGGED_STATUS, status_chat);
  localStorage.setItem(USER_PERMISSION, permissao);
  localStorage.removeItem(TOKEN_TEMP_KEY);
};

export const setStatusChat = (status) => {
  localStorage.setItem(USER_LOGGED_STATUS, status);

  if(getId() === '/chat'){
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }
};

export const setToken = (token) => {
  localStorage.setItem(TOKEN_TEMP_KEY, token);
};

export const setTokenShop = (token) => {
  localStorage.setItem(TOKEN_KEY_SHOP, token);
};

export const logout = () => {
  isLogout(localStorage.getItem(TOKEN_KEY));
};

export const removeKeys = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_LOGGED);
  localStorage.removeItem(USER_PERMISSION);
  localStorage.removeItem(USER_LOGGED_STATUS);
  localStorage.removeItem(TOKEN_TEMP_KEY);
  localStorage.removeItem('@api_token_sms');

}

export const permissao = {
  super_user: {
    write: true,
    edit: true,
    delete: true,
    authorization: true
  },
  admin: {
    write: true,
    edit: true,
    delete: true,
    authorization: true
  },
  analista: {
    write: true,
    edit: true,
    delete: true,
    authorization: true
  },
  gestor_setor: {
    write: true,
    edit: true,
    delete: true,
    authorization: true
  },
  supervisor_setor: {
    write: true,
    edit: true,
    delete: true,
    authorization: true
  },
  vendedor: {
    write: true,
    edit: true,
    delete: true,
    authorization: true
  }
};

export const descricao_permissao = {
  super_user: 'Master',
  analista: 'Analista',
  gestor_setor: 'Gestor de setor',
  supervisor_setor: 'Supervisor de setor',
  vendedor: 'Analista de Negócios',
  back_operacional: 'BackOffice Operacional',
  back_financeiro: 'BackOffice Financeiro',
  diretoria: 'Diretoria',
  back_logistica: 'BackOffice de Logistica',
  back_prevencao: 'BackOffice de Prevenção',
  back_cadastro: 'BackOffice de Cadastro',
};

export const chat_gestao = {
  vendas: 'Vendas',
  suporte: 'Suporte',
};

export const generateUUID = () => { 
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if(d > 0){//Use timestamp until depleted
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
      } else {//Use microseconds since page-load if supported
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}