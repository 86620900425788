import { MdChat, MdEmail, MdEventAvailable, MdHelp, MdPhone, MdVideocam } from "react-icons/md";
import { IoIosApps } from "react-icons/io";
import { CgWebsite } from "react-icons/cg";
import { Badge } from "reactstrap";
import { FaScroll, FaSimCard, FaTasks } from "react-icons/fa";
import aqpago from '../assets/logo_aqpago-02.png';
import aqpasso from '../assets/aqpasso.svg';
import ecommerce_logo from '../assets/ecommerce-aqpago.svg';
import mp35p from '../assets/mp35p.png'
import bobina from '../assets/bobina.jpg';
import sim_icon from '../assets/chip.png';

export const list_companys = [
    { value: 'aqpago', label: 'AQPago'},
    { value: 'aqbank', label: 'AQBank'},
    { value: 'aqpasso', label: 'AQPasso'},
    { value: 'aqcontabil', label: 'AQContabil'},
    { value: 'meubus', label: 'MeuBus'},
    { value: 'all', label: 'Todas'},
];

export const list_planos = [
    { value: 1, label: 'Plano Pro AQPAGO SUPER'},
    { value: 2, label: 'Plano Standard AQPAGO SUPER LINK'},
    { value: 3, label: 'Plano Pro AQPAGO PAY PRIDE'},
    { value: 4, label: 'Plano Standard AQPAGO PAY PRIDE'},
    { value: 5, label: 'Plano Pro LINKPAY SHOPAQPAGO'},
    { value: 6, label: 'Plano Standard LINKPAY SHOPAQPAGO'},
    { value: 7, label: 'Plano Pro AQPAY CASH'},
    { value: 8, label: 'Plano Standard AQPAY CASH'},
    { value: 9, label: 'Plano Pro AQPAY PREMIUM'},
    { value: 10, label: 'Plano Standard AQPAY PREMIUM'},
    { value: 11, label: 'Plano Pro AQBANK PAY ON'},
    { value: 12, label: 'Plano Standard AQBANK PAY ON'},
];

export const list_origem_ticket = [
    { value: 'email', label: 'E-mail'},
    { value: 'aplicativo', label: 'Aplicativo'},
    { value: 'telefone', label: 'Telefone'},
    { value: 'chat', label: 'Chat'},
    { value: 'site', label: 'Website'},
    { value: 'ajuda', label: 'Central de Ajuda'}
];

export const flag_origem = [
    { value: 'email', label: MdEmail},
    { value: 'aplicativo', label: IoIosApps},
    { value: 'Aplicativo', label: IoIosApps},
    { value: 'telefone', label: MdPhone},
    { value: 'chat', label: MdChat },
    { value: 'site', label: CgWebsite },
    { value: 'Site AQPago', label: 'img', src: aqpago},
    { value: 'Site AQPasso', label: 'img', src: aqpasso},
    { value: 'ajuda', label: MdHelp}
];

export const maquininhas_list = [
    {
        modelo: 'MP5',
        label: 'Maquininha MP5',
        value: 'MP5',
        valor: 199.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/mp5.webp'
    },
    {
        modelo: 'D195',
        label: 'Maquininha D195',
        value: 'D195',
        valor: 399.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/d195.webp'
    },
    {
        modelo: 'MP35P',
        label: 'Maquininha MP35P',
        value: 'MP35P',
        valor: 999.00,
        image: mp35p
    },
    {
        modelo: 'S920',
        label: 'Maquininha S920',
        value: 'S920',
        valor: 599.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/s920.webp'
    },
    {
        modelo: 'BOBINA',
        label: 'Bobina',
        value: 'BOBINA',
        valor: 0.00,
        image: bobina
    },
    {
        modelo: 'CHIP',
        label: 'Chip Simcard IoT',
        value: 'CHIP',
        valor: 0.00,
        image: sim_icon
    },
    {
        modelo: 'linkpay',
        label: 'Link de Pagamento',
        value: 'linkpay',
        valor: 99.00,
        image: 'https://aqpago.com.br/assets/img/home/melhor-escolha/link-pagamento/link-pagamento.svg'
    },
    {
        modelo: 'shopaqpago',
        label: 'E-commerce',
        value: 'shopaqpago',
        valor: 99.00,
        image: ecommerce_logo
    }
];
  

export const list_status_pagamentos = [
    { value: 0, label: "Todos" },
    { value: "ANALISANDO", label: "Antifraude - Analise Pendente" },
    { value: "EM ANALISE", label: "Antifraude - Analise Iniciada" },
    { value: "APROVADO AUTOMATICA", label: "Antifraude - Aprovado automaticamente" },
    { value: "APROVADO MANUALMENTE", label: "Antifraude - Aprovado manualmente" },
    { value: "REPROVADO AUTOMATICAMENTE", label: "Antifraude - Reprovado automaticamente" },
    { value: "FRAUDE COMFIRMADA", label: "Antifraude - Fraude Confirmada" },
    { value: "FRAUDE SUSPEITA", label: "Antifraude - Fraude Suspeita"},
    { value: "FRAUDE SEM SUSPEITA", label: "Antifraude - Reprovado sem contato"},
    { value: "CANCELADA", label: "Pagamento cancelado" },
    { value: "PENDENTE", label: "Pagamento pendente" },
    { value: "APROVADO", label: "Pagamento aprovado" },
    { value: "FALHA NO PAGAMENTO", label:"Falha no pagamento"}
];

export const list_prevencao_status = [
    { value: 'NVM', sigla: 'NVO', label: 'Análise Manual'},  
    { value: 'NVP', sigla: 'NVO', label: 'Análise Pendente'}, 
    { value: 'AMA', sigla: 'AMA', label: 'Análise Iniciada'}, 
    { value: 'APM', sigla: 'APM', label: 'Aprovação Manual'},  
    { value: 'APG', sigla: 'APM', label: 'Aprovação Gerencial'},  
    /* { value: 'APE', sigla: 'APM', label: 'Aprovação por Estabelecimento'},  */
    { value: 'SUS', sigla: 'SUS', label: 'Suspeita de Fraude'},  
    { value: 'FRD', sigla: 'FRD', label: 'Fraude Confirmada'},  
    { value: 'RMA', sigla: 'RMA', label: 'Reprovado Manualmente'},
    { value: 'RPM', sigla: 'RPM', label: 'Reprovado sem contato'},
];

export const list_tipos = [
    {value: 'conta_digital', label: 'Conta Digital'},
    {value: 'cartao_credito', label: 'Cartão de Crédito'},
    {value: 'emprestimo_pessoa_fisica', label: 'Empréstimo Pessoa Fisica'},
    {value: 'emprestimo_pessoa_juridica', label: 'Enpréstimo Pessoa Jurídica'},
    {value: 'seguros', label: 'Seguros'},
    {value: 'consorcio', label: 'Consórcio'},
    {value: 'remessa_valores', label: 'Remessa de Valores'},
    {value: 'revenda_recarga', label: 'Revenda de Recargas'},
    {value: 'tag', label: 'Tag de Pedágio & Estacionamento'},
    {value: 'ecommerce', label: 'Loja E-commerce'},
    {value: 'link_pagamento', label: 'Link de Pagamento'},
    {value: 'cobranca_corrente', label: 'Cobrança Recorrente'},
    {value: 'boleto_bancario', label: 'Boleto Bancário'},
    {value: 'pix', label: 'Cobrança PIX'},
    {value: '24_horas', label: 'Saque Banco 24 Horas'},
    {value: 'modulo_pagamento', label: 'Módulo de Pagamento'},
    {value: 'api_integracao', label: 'API de Integração'},
    {value: 'afiliados', label: 'Programa de Afiliados'},
    {value: 'terminal_franquia', label: 'Programa de Franquia'},
    {value: 'terminal_pagamento', label: 'Terminal de Pagamento'},
    {value: 'outros', label: 'Outros'},
];


export const list_origem = [
    {value: 'chat_site', label: 'Chat no Site'},
    {value: 'chat_app', label: 'Chat no App'},
    {value: 'whatsapp', label: 'WhatsApp'},
    {value: 'facebook', label: 'Facebook'},
    {value: 'instagram', label: 'Instagram'},
    {value: 'comment_tiktok', label: 'TikTok Comentário'},
    {value: 'comment_youtube', label: 'Youtube Comentário'},
    {value: 'reclame_aqui', label: 'Reclameaqui'},
    {value: 'consumidor_gov', label: 'Consumidor.Gov'},
    {value: 'procon', label: 'Procon'},
    {value: 'sac_telefone', label: 'Sac Telefone'},
    {value: 'ticket', label: 'Ticket'},
    {value: 'email', label: 'E-mail'},
    {value: 'sms', label: 'SMS'},
    {value: 'review_google', label: 'Review Google'},
    {value: 'review_trustpilot', label: 'Review Trustpilot'},
    {value: 'review_google_play', label: 'Review Google Play'},
    {value: 'review_app_store', label: 'Review App Store'},
    {value: 'ouvidoria', label: 'Ouvidoria'}
];

export const list_status = [
    {value: 'novo', label: <Badge color="info">Novo</Badge>},
    {value: 'andamento', label: <Badge color="dark">Em Andamento</Badge>},
    {value: 'aguardando_cliente', label: <Badge color="warning">Aguardando Cliente</Badge>},
    {value: 'aguardando_terceiros', label: <Badge color="secondary">Aguardando Terceiros</Badge>},
    {value: 'fechado', label: <Badge color="danger">Fechado</Badge>},
];


export const list_produtos = [
    {value: 1, label: 'AQBank' },
    {value: 2, label: 'AQPago' },
    {value: 3, label: 'AQPasso' },
    {value: 4, label: 'ShopAQPago' },
    {value: 5, label: 'AQFast' },
    {value: 6, label: 'AQEnvio' },
    {value: 7, label: 'AQContábil' },
    {value: 8, label: 'Goovie' },
    {value: 9, label: 'AQBus' },
];

export const list_tarefas = [
    {value: 'evento', label: 'Evento' },
    {value: 'conferencia', label: 'Conferência' },
    {value: 'chamada_telefonica', label: 'Chamada Telefonica' },
    {value: 'tarefa', label: 'Tarefa Comum' }
];

export const list_tarefas_icons = [
    {value: 'evento', label: MdEventAvailable },
    {value: 'conferencia', label: MdVideocam },
    {value: 'chamada_telefonica', label: MdPhone },
    {value: 'tarefa', label: FaTasks }
];

export const list_tempos = [
    {value: '+0 minutes', label: 'Sem informações'},
    {value: '+15 minutes', label: '15 Minutos'},
    {value: '+30 minutes', label: '30 Minutos'},
    {value: '+45 minutes', label: '45 Minutos'},
    {value: '+1 hour', label: '1 Hora'},
    {value: '+2 hour', label: '2 Horas'},
    {value: '+3 hour', label: '3 Horas'},
]

export const list_razao = [
    {value: 'pagamento_bloqueado', label: 'Pagamento Bloqueado' },
    {value: 'contestacao_passagem', label: 'Contestação de Passagem' },
    {value: 'estorno_transacao', label: 'Estorno de Transação' },
    {value: 'credito_recargas', label: 'Crédito de Recarga' },
    {value: 'envio_chip', label: 'Envio de Chip de Dados' },
    {value: 'pague_volta', label: 'Oferta Pague & Volta' },
    {value: 'terminal_pos', label: 'Garantia de Terminal POS' },
    {value: 'ativacao_loja_virtual', label: 'Ativação de Loja Virtual' },
    {value: 'alteracao_cadastro', label: 'Alteração de Cadastro' },
    {value: 'outros_assuntos', label: 'Outros Assuntos' }
];

export const list_pagamentos_liberados = [
    { value: 'liberado', label: "Pagamento Sob Risco"},
    { value: 'estorno_realizado', label: "Estorno Realizado"},
    { value: 'autorizado', label: "Pagamento Autorizado"},
    { value: 'bloqueio_ajustado', label: "Bloqueio Ajustado"},
    { value: 'bloqueio_seis_meses', label: "Bloqueio 6 Meses"},
    { value: 'processo_judicial', label: "Processo Judicial"},
    { value: 'estorno', label: "Solicitou Estorno"},
    { value: 'chargeback', label: 'Chargeback' },
    { value: 'aguardando_documento', label: 'Aguardando Documentos' },
    { value: 'retido_zoop', label: "Retido ZOOP"},
    { value: 'liberacao_sete_dias', label: "Liberação 7 Dias"},
    { value: 'realizado', label: 'Pagamento Realizado' },
    { value: 'liberacao_quatorze_dias', label: "Liberação 14 Dias"},
    { value: 'transacao_analise', label: "Transação em Análise"},
    { value: 'chargeback_devedor', label: "Chargeback Devedor"},
    { value: 'chargeback_quitado', label: "Chargeback Quitado"},
]

export const list_razao_bloqueio = [
    { value: 'cartao_proprio', label: 'Cartão Próprio'}, 
    { value: 'cartao_familiar', label: 'Cartão Familiar'}, 
    { value: 'venda_fora_atividade', label: 'Venda Fora da Atividade'}, 
    { value: 'venda_acima_media', label: 'Venda Acima da Média'}, 
    { value: 'venda_cartao_media', label: 'Venda Cartão Repetido'}
];


export const list_bancos_retidos = [
    { value: 'inter', label: 'Inter'}, 
    { value: 'bs2', label: 'BS2'}, 
    { value: 'starkbank', label: 'StarkBank'}, 
    { value: 'btg', label: 'BTG'}, 
    { value: 'cora', label: 'Cora'},
    { value: 'itau', label: 'Itaú'},
    { value: 'banco_do_brasil', label: 'Banco do Brasil'}
]