import React, { useEffect, useState } from 'react';
import { MdSupervisorAccount, MdWarning } from "react-icons/md";
import { ImWhatsapp } from "react-icons/im";
import { Button, ButtonGroup, Card, CardBody, Row, TabPane } from "reactstrap";
import Moment from 'moment';
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import axios from "axios";
import NumberFormat from 'react-number-format';

import logoAqbank from '../../assets/empresas/AQBank_brand.svg';
import logo365 from '../../assets/empresas/365_brand.svg';
import logoaqcontabil from '../../assets/empresas/aqcontabil_brand.svg';
import logoaqpago from '../../assets/empresas/aqpago_brand.svg';
import logoaqmed from '../../assets/empresas/aqmed_brand.svg';
import logoaqpasso from '../../assets/empresas/aqpasso_brand.svg';
import logomeubus from '../../assets/empresas/meubus_brand.svg';
import logomultibeneficios from '../../assets/empresas/multibenefícios_brand.svg';
import logopagfacil from '../../assets/empresas/pagfacil_brand.svg';
import logopronto from '../../assets/empresas/pronto_brand.svg';

const logoImageOrigin = (empresa) => {
    if(empresa == null){
        empresa = '';
    }

    empresa = empresa.toUpperCase();
    if(empresa == 'AQBANK'){
        return logoAqbank;
    }
    else if(empresa == '365PAY'){
        return logo365;
    }
    else if(empresa == 'AQCONTABIL'){
        return logoaqcontabil;
    }
    else if(empresa == 'AQMED'){
        return logoaqmed;
    }
    else if(empresa == 'AQPAGO'){
        return logoaqpago;
    }
    else if(empresa == 'AQPASSO'){
        return logoaqpasso;
    }
    else if(empresa == 'MEUBUS'){
        return logomeubus;
    }
    else if(empresa == 'MULTIBENEFICIOS'){
        return logomultibeneficios;
    }
    else if(empresa == 'PRONTO'){
        return logopronto;
    }
    else if(empresa == 'PAGFACIL'){
        return logopagfacil;
    }
    else{
        return logoAqbank;
    }
}

  
export const ResumoClient = (props) => {
    const [departamentos, setDepartamentos] = useState([]);

    useEffect(() => {
        getDepartamentos();
    },[]);
    
    const getDepartamentos = () => {
        var url = ENDPOINT+"departamento/list";

        axios.get(url, {
            headers: {
                'Access-Control-Allow-Origin' : '*',
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            setDepartamentos(data);
        })
        .catch((error) => {

        });
    }

    const capitalizeFirstLetter = (string) => {
        if(string !== null){
            var dep = '';
            

            departamentos.forEach(p => {
                if(p.flag === string){
                    dep = p.nome;
                }
            });
            return dep;
        }
        else{
            return 'Sem informações'
        }
    }
    const get_color = () => {
        if(props.chat !== null){
            if(props.chat.tipo_chat == 'whatsapp'){
                return 'drop-shadow(#52c565 1px 1px 2px)';
            }
        }

        return 'drop-shadow(#b8b8b8 1px 1px 2px)';
    }
    return (
        <TabPane tabId={props.active}>
            <br />
            <Card style={{filter: get_color(), borderRadius: '1.25rem'}}>
                <CardBody style={{paddingLeft: '39px'}}>
                    <h5>&nbsp;&nbsp;
                        {props.chat !== null ? 
                            (props.chat.tipo_chat == 'whatsapp' ?
                                <ImWhatsapp style={{width: '30px', height: '30px'}}/>
                            :
                                <MdSupervisorAccount style={{width: '30px', height: '30px'}}/> 
                            )
                            :
                            null
                        }
                        
                        Dados do Cliente</h5>
                    <hr/>
                    <Row style={{maxHeight: '60vh', overflow: 'auto'}}>
                    <div style={{ marginTop: '15px' }}>
                        {props.chat !== null ? 
                            <>
                                <small>
                                    <img src={logoImageOrigin(props.chat.dominio)} width={100} />
                                    <br/>
                                    <br/>
                                    <b>ORIGEM: </b>{props.chat.dominio}
                                    <br /><b>NOME: </b>{props.chat.nome}
                                    <br /><b>TELEFONE: </b>{props.chat.telefone}
                                    <br /><b>CPF/CNPJ: </b><NumberFormat displayType={'text'} value={props.chat.cpf_cnpj} format={props.chat.cpf_cnpj.length > 11 ? '##.###.###/####-##' : "###.###.###-##"}/>
                                    <br /><b>E-MAIL: </b>{props.chat.email}
                                    <br /><b>DEPARTAMENTO: </b>{capitalizeFirstLetter(props.chat.departamento)}
                                    <br/><b>TIPO DE CHAT:</b> {props.chat.tipo_chat == 'whatsapp' ? 'WhatsApp' : 'Chat'}
                                    <br/><b>ULTIMO(S) CHAT(S): </b><br/>
                                    {
                                        props.chat.usuario ?
                                            props.chat.usuario.chats_recentes.slice(0,3).map(p =>{ return (
                                                <>
                                                    <b>Realizado em: </b>{Moment(new Date(p.created_at)).format('DD/MM/YYYY HH:mm')} {p.cod_chat === props.chat.cod_chat ? '[Chat Selecionado]': ''}<br/>
                                                </>
                                            )})
                                        :
                                        ''
                                    }
                                    <br/><b>ULTIMO(S) E-MAIL(S): </b><br/><br/>
                                    {
                                        props.chat.usuario ?
                                            props.chat.usuario.emails_recentes.slice(0, 3).map(p =>{ return (
                                                <>
                                                    <Card style={{borderRadius: '1rem'}}>
                                                        <CardBody style={{padding: '1rem'}}>
                                                            <b>Assunto: </b>{p.subject}
                                                            <br/><b>Mensagem: </b><hr style={{ marginTop: '2px' }}/>
                                                            <div style={{maxHeight: '200px', padding: '15px', border:'1px solid #dfdfdf', boxShadow: '#b8b8b8 1px 1px 3px', overflow: 'auto'}} dangerouslySetInnerHTML={{__html: p.message}} />
                                                        </CardBody>
                                                    </Card>
                                                    <br/>
                                                </>
                                            )})
                                        :
                                        ''
                                    }
                                </small>
                                </> : ''
                                }
                                {
                                    props.chat !== null?
                                        props.chat.status_chat !== 2 && props.chat.status_chat !== 3 ?
                                            <>
                                                <hr/>
                                                <center>
                                                <ButtonGroup>
                                                    <Button 
                                                        color="danger"
                                                        onClick={() => props.finalizarChat()}>Finalizar Chat</Button>
                                                    <Button 
                                                        color="info"
                                                        onClick={() => props.transferClose()}
                                                    >Transferir Chat</Button>
                                                    {props.type_chat === 'monitoramento' && props.chat.status_chat === 0 ? 
                                                        <Button 
                                                            color="dark"
                                                            onClick={() => props.chamarAtendente()}>Chamar Atendente</Button>
                                                    : 
                                                    <></>}
                                                </ButtonGroup>
                                                    <Button onClick={() => props.chat_perdido()} style={{backgroundColor: '#bb0012', borderColor: '#bb0012', marginTop:'5px'}} color="danger"><MdWarning/>Chat Perdido</Button>
                                                </center>
                                                </>
                                        : <></>
                                    : <> </>
                        }
                    </div>
                    </Row>
                </CardBody>
            </Card>
        </TabPane>
    )
}

//TabContent