import axios from "axios";
import React, { Component } from "react";
import { Button, Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import { InputMasked } from "../../InputText/InputMasked";
import { InputText } from "../../InputText/InputText";
import ModelReturn from "../ReturnsApiModals/ModelReturn";

var CurrencyInput = require('react-currency-masked-input');

class CreateGiftCard extends Component{
    constructor(props){
        super(props);
        this.state = {
            hash: '',
            hash_invalid: false,
            valor: 0,
            valor_invalid: false,
            valor_abono: 0,
            valor_abono_invalid: false,
            num_gifts: 1,
            loading: false,
            error_message: '',
            success_message: '',
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        
        this.setState({[name]: value});
    }

    storeUser = () => {
        var url = ENDPOINT+"giftcard/store";
        //formData.append('telefone', this.state.telefone);
        var formData = new FormData();
        formData.append('valor', this.state.valor);
        formData.append('valor_abono', this.state.valor_abono);
        formData.append('numero', this.state.num_gifts);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            this.onData(data);
            this.props.onRefresh();
            return data;
        })
        .catch((error) => {
            console.log(error)
            var data = error.response.data;
            this.onData(data);
            return data;
        });
    }

    onData = (data) => {
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onRegister = () => {
        if(this.validateFields() === 0){
            this.setState({loading: true});
            this.storeUser();
        }
    }
    validateFields = () => {
        var invalids = 0;

        if(parseFloat(this.state.valor) > 0){
            this.setState({valor_invalid: false})
        }
        else{
            invalids++;
            this.setState({valor_invalid: true})
        }

        /* if(parseFloat(this.state.valor_abono) > 0){
            this.setState({valor_abono_invalid: false})
        }
        else{
            invalids++;
            this.setState({valor_abono_invalid: true})
        } */

        return invalids
    }
    onClose = () => {
        this.setState({
            hash: '',
            hash_invalid: false,
            valor: 0,
            valor_invalid: false,
            valor_abono: '',
            valor_abono_invalid: false,
            num_gifts: 1,
            loading: false,
            error_message: '',
            success_message: '',
        });
        this.props.onClose(false);
    }

    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }
    onValor = (event) => {
        var valor_current = event.target.value;
        if (valor_current.length > 1) {
        valor_current = valor_current.replaceAll('.', '');
        var digits = valor_current.substr(valor_current.length - 2);
        valor_current = valor_current.slice(0, -2);
        valor_current = valor_current + '.' + digits;
        }

        var valido = /^[\d,.?!]+$/.test(valor_current);
        if (valido === true || valor_current === '') {
            this.setState({ valor: valor_current });
        }
    };
    onValorAbono = (event) => {
        var valor_current = event.target.value;
        if (valor_current.length > 1) {
        valor_current = valor_current.replaceAll('.', '');
        var digits = valor_current.substr(valor_current.length - 2);
        valor_current = valor_current.slice(0, -2);
        valor_current = valor_current + '.' + digits;
        }

        var valido = /^[\d,.?!]+$/.test(valor_current);
        if (valido === true || valor_current === '') {
            this.setState({ valor_abono: valor_current });
        }
    };
    render(){
        return (
            <Modal isOpen={this.props.visible}>
                <ModalHeader>Criar GiftCard</ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <FormGroup>
                        <h6><b>Valor do GiftCard: </b> </h6>
                        <CurrencyInput 
                            inputMode="tel"
                            className="form-control" 
                            currency="BRL"
                            name="valor"
                            defaultValue={'0.00'}
                            onChange={this.onValor}/>
                    </FormGroup>
                    <FormGroup>
                        <h6><b>Valor do abono GiftCard: </b> </h6>
                        <CurrencyInput 
                            inputMode="tel"
                            className="form-control" 
                            currency="BRL"
                            name="valor"
                            defaultValue={'0.00'}
                            onChange={this.onValorAbono}/>
                    </FormGroup>
                    <FormGroup>
                        <h6><b>Digite quantos GiftCard's você deseja gerar: </b> </h6>
                        <InputText
                            type="number"
                            name="num_gifts"
                            value={this.state.num_gifts}
                            placeholder="Numero de GiftCard's"
                            min={1}
                            onChange={this.handleChange}/>
                    </FormGroup>
                </ModalBody>
                <ModalBody 
                    style={{
                        minHeight: '114px', 
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn 
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                        />
                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose()}>Cancelar</Button>
                    <Button color="success" onClick={() => this.onRegister()}>Gerar Giftcard's</Button>
                </ModalFooter>
            </Modal>
        )
    }

}

export default CreateGiftCard;