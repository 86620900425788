import axios from 'axios';
import moment from 'moment';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';


const getStatus = (status) => {
    console.log(status)
    if(status === 1){
        return "Transação inexistente"
    }
    else if(status === 2){
        return "Passagem Duplicada"
    }
    else{
        return "Cobrança de valor incorreto"
    }
}

export const columns = () => [
    {
        name: 'Cliente',
        selector: 'clinte',
        sortable: false,
        width: '20%',
        cell: row => row.user !== null ? row.user.nome + ' ' + row.user.sobrenome + (row.user.cnpj ? row.user.cnpj : row.user.cpf) : ''
    },
    {
      name: "Descrição da Contestação",
      selector: "RequestDescription",
      sortable: false,
      width: '25%',
      cell: row => row.json.requestDescription
    },
    {
      name: "Motivo da Contestação",
      selector: "RequestReasonCode",
      sortable: false,
      width: '200px',
      cell: row => getStatus(row.json.requestReasonCode)
    },
    {
      name: "Contestação aberta em:",
      selector: "OpenDate",
      sortable: false,
      width: '100px',
      cell: row => moment(row.json.openDate).format('DD/MM/YYYY HH:mm')
    },
    {
      name: "Histórico",
      selector: "json",
      sortable: false,
      width: '25%',
      cell: row => <ul>{row.json.history.map(e => (
        <li>{moment(e.date).format('DD/MM/YYYY HH:mm')} - {e.description}</li>
      ))}</ul>
    },
];

export const getRows = (state, callbackRows) => {
    
    var url = ENDPOINT + "contestacao/list";

    if(state.taggy_serial !== null){
        url += '?taggy_serial='+state.taggy_serial;
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.meta.current_page, response.data.meta.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    
    var url = ENDPOINT + "contestacao/list?search="+search+"&page="+page;

    if(state.taggy_serial !== null){
        url += '&taggy_serial='+state.taggy_serial;
    }
    if(filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.meta.current_page, response.data.meta.last_page)
    });
}