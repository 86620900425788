import { useEffect, useRef, useState } from "react";
import { Button, Col, Collapse, Input, Row } from "reactstrap";
import Select from "react-select";
import PropagateLoader from "react-spinners/PropagateLoader";
import { InputMasked } from "../../../components/InputText/InputMasked";


const estados_options = [
    {"label": "Acre", "value": "AC"},
    {"label": "Alagoas", "value": "AL"},
    {"label": "Amapá", "value": "AP"},
    {"label": "Amazonas", "value": "AM"},
    {"label": "Bahia", "value": "BA"},
    {"label": "Ceará", "value": "CE"},
    {"label": "Distrito Federal", "value": "DF"},
    {"label": "Espírito Santo", "value": "ES"},
    {"label": "Goiás", "value": "GO"},
    {"label": "Maranhão", "value": "MA"},
    {"label": "Mato Grosso", "value": "MT"},
    {"label": "Mato Grosso do Sul", "value": "MS"},
    {"label": "Minas Gerais", "value": "MG"},
    {"label": "Pará", "value": "PA"},
    {"label": "Paraíba", "value": "PB"},
    {"label": "Paraná", "value": "PR"},
    {"label": "Pernambuco", "value": "PE"},
    {"label": "Piauí", "value": "PI"},
    {"label": "Rio de Janeiro", "value": "RJ"},
    {"label": "Rio Grande do Norte", "value": "RN"},
    {"label": "Rio Grande do Sul", "value": "RS"},
    {"label": "Rondônia", "value": "RO"},
    {"label": "Roraima", "value": "RR"},
    {"label": "Santa Catarina", "value": "SC"},
    {"label": "São Paulo", "value": "SP"},
    {"label": "Sergipe", "value": "SE"},
    {"label": "Tocantins", "value": "TO"}
];

export const Enderecos = (props) => {
    const [openAddress, setOpenAddress] = useState(false);
    const validInputs = [
        'cep', 
        'endereco',
        'numero',
        'bairro',
        'cidade',
        'estado',
    ];

    const numberInput = useRef(null);

    const handleChangeCep = (e) => {
        props.state.load_address = true;
        const { name, value } = e.target; 

        let cep = value.replace(/[^0-9]/g,'');
        console.log(cep.length)
        if (cep.length == 8) {
            var url = 'https://viacep.com.br/ws/'+cep+'/json/';

            var myInit = {
                method: 'GET',
                mode: 'cors',
                cache: 'default'
            };
    
            fetch(url, myInit)
            .then(response => response.json())
            .then((p) => {
                if (p.logradouro !== undefined) {

                    estados_options.map((estado, i) => {

                        if (estado.value === p.uf) {
                            p.estado_select = estado;
                        }
                    })

                    console.log(p)
                    props.handleCepChange(p);
                }
            })
            .catch((error) => {
                console.log(error)
            })
        }
    }

    return (
    <div key={props.state.address.length}>
        <Row>
            <Col md={4} >
                <label htmlFor="cep">Cep</label>
                <InputMasked
                    name="cep"
                    mask="99999-999"
                    placeholder=""
                    type="text"
                    value={props.state.cep}
                    onChange={(e) => props.handleInputChangeEndereco(e)}
                    onBlur={(e) => handleChangeCep(e)}
                />
                <span className={props.state.formErros.cep ? 'error-active' : 'error-none'}>
                    Campo obrigatório
                </span>
            </Col>
            <Col md={3}>
                <label htmlFor="numero">Número</label>
                <Input
                    ref={numberInput}
                    className={props.state.formErros.numero ? 'input-error' : ''}
                    name="numero"
                    placeholder=""
                    type="text"
                    value={props.state.numero}
                    onChange={(e) => props.handleInputChangeEndereco(e)}
                />
                <span className={props.state.formErros.numero ? 'error-active' : 'error-none'}>
                    Campo obrigatório
                </span>
            </Col>
        </Row>


        <Row hidden={!props.state.load_address}>
            <Col md={12}>
                <center>
                    <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                </center>
                <br/>
            </Col>
        </Row> 
        <Collapse isOpen={props.state.open_address}>
        <Row>
            <Col md={12}>
                <label htmlFor="endereco">Endereço</label>
                <Input
                    className={props.state.formErros.endereco ? 'input-error' : ''}
                    name="endereco"
                    placeholder=""
                    type="text"
                    value={props.state.endereco}
                    onChange={(e) => props.handleInputChangeEndereco(e)}
                />
                <span className={props.state.formErros.endereco ? 'error-active' : 'error-none'}>
                    Campo obrigatório
                </span>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <label htmlFor="complemento">Complemento</label>
                <Input
                    name="complemento"
                    placeholder=""
                    type="text"
                    value={props.state.complemento}
                    onChange={(e) => props.handleInputChangeEndereco(e)}
                />
            </Col>
            <Col md={6}>
                <label htmlFor="bairro">Bairro</label>
                <Input
                    className={props.state.formErros.bairro ? 'input-error' : ''}
                    name="bairro"
                    placeholder=""
                    type="text"
                    value={props.state.bairro}
                    onChange={(e) => props.handleInputChangeEndereco(e)}
                />
                <span className={props.state.formErros.bairro ? 'error-active' : 'error-none'}>
                    Campo obrigatório
                </span>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <label htmlFor="cidade">Cidade</label>
                <Input
                    className={props.state.formErros.cidade ? 'input-error' : ''}
                    name="cidade"
                    placeholder=""
                    type="text"
                    value={props.state.cidade}
                    onChange={(e) => props.handleInputChangeEndereco(e)}
                />
                <span className={props.state.formErros.cidade ? 'error-active' : 'error-none'}>
                    Campo obrigatório
                </span>
            </Col>
            <Col md={6}>
                <label htmlFor="estado">Estado</label>
                <Select
                className={props.state.formErros.estado ? 'select-error' : ''}
                name="estado"
                placeholder="Selecione..."
                options={estados_options}
                onChange={(value) => props.handleInputChangeEnderecoSelect('estado', value)}
                value={props.state.estado_select}
                    />
                <span className={props.state.formErros.estado ? 'error-active' : 'error-none'}>
                    Campo obrigatório
                </span>
            </Col>
        </Row>
        </Collapse>
        <Row>
            <Col md={12}>
                <Collapse isOpen={props.state.open_address}>
                <Button
                    color="success"
                    outline
                    onClick={(e) => {props.handleAddEndereco(validInputs, props.state.endereco_position)}}
                    >
                         Salvar Endereço
                </Button>
                </Collapse>

                <Button 
                    className="btn-cancel"
                    color="danger"
                    outline
                    onClick={(e) => {props.handleCancelAddEndereco()}}
                    >
                        Cancelar
                </Button>
            </Col>
        </Row>
    </div>
    )
}