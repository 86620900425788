import React from 'react';
import { CardBody, Col, Row } from 'reactstrap';
import QRCode from 'qrcode.react';
import NumberFormat from 'react-number-format';
import logoAqbank from '../../assets/logo.png';
import styled from 'styled-components';

const ImageLogo = styled.img`
    width: 114px;
`;
const CardTaggy = styled.div`
    background-color: rgb(28, 28, 28);
    border-radius: 10px;
    border: 0; 
    min-width: 334px;
`
const StyleTag = (props) => {
    return (
        <div style={{padding: '15px'}}>
            <CardTaggy className="card">
                <CardBody>
                    <Row>
                        <Col 
                            md={6}
                            sm={12}
                            xs={12} 
                            style={{color: 'white', fontWeight: '600', fontSize: '17px'}}>
                            <ImageLogo src={logoAqbank} />
                        </Col>
                        <Col>
                            <img alt="logo" src="https://www.taggy.com.br/images/taggy-branco.svg" style={{width: '69px', float: 'right'}}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col 
                            md={10}
                            style={{color: 'white', textAlign: 'right', fontSize: '16px', fontWeight: '400'}}>
                                <NumberFormat 
                                    value={props.tag_serial} 
                                    displayType={'text'} 
                                    style={{
                                        fontWeight: '300'
                                    }}
                                    format="#### #### #### ####" />
                                
                            </Col>
                        <Col md={2}>
                            <QRCode value={props.tag_serial}  size={30}/>
                        </Col>
                    </Row>
                </CardBody>
            </CardTaggy>

        </div>
    )
}

export default StyleTag;