import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { Card, CardBody, Row, Col, Input, CardFooter, Collapse, Button, Label } from 'reactstrap';
import { columns } from './propsFornecedor';
import debounce from 'lodash/debounce';
import Pagination from '../Pagination';
import { InputText } from '../../InputText/InputText';
import InputMask from 'react-input-mask';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import Select from 'react-select';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';

// const getOptionSelects = (setStatusProcessoOptions, setStatusPagamentoOptions, setReclamadoOptions) => {
//     var url = ENDPOINT + "juridico/selects";

    // var myHeaders = new Headers();
    
    // myHeaders.append('Authorization', 'Bearer ' + getToken());
  
    // var myInit = {
    //   method: 'GET',
    //   headers: myHeaders,
    //   mode: 'cors',
    //   cache: 'default'
    // };
    // fetch(url, myInit)
    // .then(response => response.json())
    // .then((p) => {
    //     if(p.success == true){
    //         var data = p.data;
    //         var status_processo_options = [];
    //         var status_pagamento_options = [];
    //         var reclamado_options = [];

    //         data.status_processo.forEach((key, index) => {
    //             key['value'] = key.id;
    //             key['label'] = key.name;

    //             status_processo_options.push(key);
    //         });            

    //         data.status_pagamento.forEach((key, index) => {
    //             key['value'] = key.id;
    //             key['label'] = key.name;

    //             status_pagamento_options.push(key);
    //         });

    //         data.reclamados.forEach((key, index) => {
    //             key['value'] = key.id;
    //             key['label'] = key.name;

    //             reclamado_options.push(key);
    //         });

    //         setStatusProcessoOptions(status_processo_options);
    //         setStatusPagamentoOptions(status_pagamento_options);
    //         setReclamadoOptions(reclamado_options);
    //     }
    // })
    // .catch((error) => {
    //     toast.error('Ocorreu um erro no servidor!');
    // })

// }

export const TableFornecedor = (props) => {
    const [status_processo_options, setStatusProcessoOptions] = useState([]);
    const [status_pagamento_options, setStatusPagamentoOptions] = useState([]);
    const [reclamado_options, setReclamadoOptions] = useState([]);

    // useEffect(() => {
    //     getOptionSelects(setStatusProcessoOptions, setStatusPagamentoOptions, setReclamadoOptions);
    // }, []);

    return (
        <Card>
            <CardBody>
                <Row >
                    <Col md={10}>
                        <Button 
                            className="btn btn-success btn-save" 
                            style={{"margin-bottom": "20px"}}
                            onClick={() => { window.location = '/fornecedor-detalhes/novo'; }}
                            >
                                Cadastrar Fornecedor
                        </Button>
                    </Col>
                </Row>
                <Row >
                    <Col md={8}>
                        <div style={{ textAlign: 'left' }}>
                            <InputText
                                name="search"
                                placeholder="CPF / CNPJ / Nome"
                                onChange={debounce((event) => props.isSearch(event), 1000)} />
                        </div>
                    </Col>
                </Row>
            </CardBody>
            <hr style={{ marginBottom: '4px' }} />
            <DataTable
                noHeader={true}
                button={true}
                noDataComponent={props.noDataComponent}
                columns={columns(props.onPayload, props.onSellerDocument)}
                data={props.data}
                theme="default"
            />
            <CardFooter style={{ textAlign: 'center' }}>
                <Pagination
                    onPageChange={props.onPageChange}
                    activePage={props.activePage}
                    totalPage={props.totalPage}
                />
            </CardFooter>
        </Card>
    )
}