import axios from "axios";
import { Component } from "react";
import { HorizontalBar, Bar } from "react-chartjs-2";
import { Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Row } from "reactstrap";
import { ENDPOINT } from "../../auth/endpoint";
import { getPermissoes, getToken } from "../../auth/set_services";
import { GiTicket } from 'react-icons/gi';
import { InputText } from "../../components/InputText/InputText";
import { InputMasked } from "../../components/InputText/InputMasked";
import { ButtonDefault } from '../../components/Button/ButtonDefault';
import { MdSearch } from 'react-icons/md';
import { getCity, getStates } from "../../components/Tables/TableEstacionamento/propsEstacionamento";
import Select from "react-select";

/* const decimal_remove = {
    scales: {
        yAxes: [{
            ticks: {
                stepSize: 1,
                beginAtZero: true,
                userCallback: function(label, index, labels) {
                    // when the floored value is the same as the value we have a whole number
                    if (Math.floor(label) === label) {
                        return label;
                    }

                },
            }
        }],
    },
} */
class Relatorios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            state: null,
            states: [],
            city: null,
            cities: [],
            date_from: null,
            date_to: null,
            cnpj: '',
            cpf: '',
            venda_tags_estado: {labels: [], datasets: [] },
            venda_tags_cidade: {labels: [], datasets: [] },
            venda_tags_cnpj: {labels: [], datasets: [] },
            venda_tags_pessoa_fisica: {labels: [], datasets: [] },
            venda_tags_date: {labels: [], datasets: [] }
        }
    }
    componentDidMount() {
        this.getStatistics();
        getStates(this.callbackStates.bind(this));
    }
    callbackStates(states){
        var rows = [];
        console.log(states)
        states.forEach(p => {
            var json = {value: p, label: p};
            rows.push(json);
        });
        this.setState({states: rows});
    }
    callbackCities(cities){
        var rows = [];
        console.log(cities)
        cities.forEach(p => {
            var json = {value: p, label: p};
            rows.push(json);
        });
        this.setState({cities: rows});
    }


    getStatistics() {
        var url = ENDPOINT + "taggys/taggy_report?search=";

        if(this.state.state !== null){
            url += "&state="+this.state.state.value
        }
        if(this.state.city !== null){
            url += "&city="+this.state.city.value
        }
        if(this.state.date_from !== null){
            url += "&date_from="+this.state.date_from
        }

        if(this.state.date_to !== null){
            url += "&date_to="+this.state.date_to
        }
        
        if(this.state.cnpj.replace(/([^\d])+/gim, '').length > 0){
            url += "&cnpj="+this.state.cnpj
        }
        
        if(this.state.cpf.replace(/([^\d])+/gim, '').length > 0){
            url += "&cpf="+this.state.cpf
        }

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        }).then((response) => {
            console.log(response)
            this.setState({
                venda_tags_estado: response.data.data.venda_tags_estado,
                venda_tags_cidade: response.data.data.venda_tags_cidade,
                venda_tags_cnpj: response.data.data.venda_tags_empresa,
                venda_tags_pessoa_fisica: response.data.data.venda_tags_pessoa_fisica,
                venda_tags_date: response.data.data.venda_tags_date
                /* venda_tags_estado: {
                    data: response.data.venda_tags_estado.data,
                    labels: response.data.venda_tags_estado.labels
                } */
            });
        })
    }

    data_venda_tags_estado = () => {
        return {
            labels: this.state.venda_tags_estado.labels,
            datasets: [
                {
                    label: 'Taggys vendidas',
                    data: this.state.venda_tags_estado.datasets,
                    fill: false,
                    backgroundColor: 'rgb(111, 66, 193)',
                    borderColor:  'rgba(111, 66, 193, 0.4)',
                },
            ],

        }
    }

    data_venda_tags_cidade = () => {
        return {
            labels: this.state.venda_tags_cidade.labels,
            datasets: [
                {
                    label: 'Taggys vendidas',
                    data: this.state.venda_tags_cidade.datasets,
                    fill: false,
                    backgroundColor:'rgb(15, 154, 73)',
                    borderColor: 'rgb(15, 154, 73, 0.4)',
                },
            ],

        }
    }

    data_venda_tags_cnpj = () => {
        return {
            labels: this.state.venda_tags_cnpj.labels,
            datasets: [
                {
                    label: 'Taggys vendidas',
                    data: this.state.venda_tags_cnpj.datasets,
                    fill: false,
                    backgroundColor:'rgb(26, 26, 26)',
                    borderColor: 'rgb(26, 26, 26, 0.4)',
                },
            ],

        }
    }

    data_venda_tags_pessoa_fisica = () => {
        return {
            labels: this.state.venda_tags_pessoa_fisica.labels,
            datasets: [
                {
                    label: 'Taggys vendidas',
                    data: this.state.venda_tags_pessoa_fisica.datasets,
                    fill: false,
                    backgroundColor:'rgb(26, 26, 26)',
                    borderColor: 'rgb(26, 26, 26, 0.4)',
                },
            ],

        }
    }

    data_venda_tags_date = () => {
        return {
            labels: this.state.venda_tags_date.labels,
            datasets: [
                {
                    label: 'Taggys vendidas',
                    data: this.state.venda_tags_date.datasets,
                    fill: false,
                    backgroundColor:'rgb(99, 16, 132)',
                    borderColor: 'rgb(99, 16, 132, 0.4)',
                },
            ],

        }
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }

    handleSelectChangeState = value => {
        var state = this.state;
        state.state = value;
        state.city = null;
        state.cities = [];
        state.rowData = [];
        state.loading_table = true;
        this.setState({ ...state });

        if(value !== null){
            getCity(value, this.callbackCities.bind(this));
        }
    };

    
    handleCityChangeState = value => {
        var state = this.state;
        state.city = value;
        state.loading_table = true;
        this.setState({ ...state });
    };

    render() {
        return (
            <div>
                <h3>Relatórios</h3>
                <hr />
                <br/>
                <div>
                    <h5 style={{marginBottom: '22px'}}><GiTicket style={{width: '45px'}}/> RELATÓRIO DE TAGGYS</h5>
                    <Card>
                        <CardBody>
                            <Form>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Estado (UF):</Label>
                                            {/* <InputMasked
                                                mask="**" 
                                                name="state"
                                                value={this.state.state}
                                                placeholder="Digite o Estado (UF)"
                                                type="text"
                                                onChange={this.handleChange}
                                            /> */}
                                            <Select
                                                className="react-select"
                                                options={this.state.states}
                                                placeholder="Estados"
                                                isSearchable={false}
                                                isClearable
                                                value={this.state.state}
                                                onChange={this.handleSelectChangeState}
                                                theme={theme => ({
                                                    ...theme,
                                                    borderRadius: 0
                                                })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label>Cidade:</Label>
                                            <Select
                                                className="react-select"
                                                options={this.state.cities}
                                                placeholder="Cidade"
                                                isSearchable={false}
                                                isClearable
                                                value={this.state.city}
                                                isDisabled={this.state.state === null}
                                                onChange={this.handleCityChangeState}
                                                theme={theme => ({
                                                    ...theme,
                                                    borderRadius: 0
                                                })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label>Data Inicial:</Label>
                                            <InputText 
                                                name="date_from"
                                                value={this.state.date_from}
                                                type="date"
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label>Data Final:</Label>
                                            <InputText 
                                                name="date_to"
                                                value={this.state.date_to}
                                                type="date"
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <FormGroup>
                                        <Label>CNPJ:</Label>
                                        <InputMasked 
                                            mask="99.999.999/9999-99" 
                                            placeholder="Digite o CNPJ"
                                            name="cnpj"
                                            value={this.state.cnpj}
                                            type="text"
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                    </Col>
                                    <Col>
                                    <FormGroup>
                                        <Label>CPF:</Label>
                                        <InputMasked 
                                            mask="999.999.999-99" 
                                            placeholder="Digite o CPF"
                                            name="cpf"
                                            value={this.state.cpf}
                                            type="text"
                                            onChange={this.handleChange}
                                    />
                                    </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                            <ButtonDefault onClick={() => this.getStatistics()} style={{float: 'right'}}><MdSearch />Buscar</ButtonDefault>
                        </CardBody>
                    </Card>
                    <br/>
                    <Row>
                        <Col md={4}>
                            <Card>
                                <CardHeader>Relatório de Tags Vendidas por Estado</CardHeader>
                                <CardBody>
                                    <HorizontalBar
                                        data={this.data_venda_tags_estado}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <CardHeader>Relatório de Tags Vendidas por Cidade</CardHeader>
                                <CardBody>
                                    <Bar
                                        data={this.data_venda_tags_cidade}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <CardHeader>Relatório de Tags Vendidas por Empresa (CNPJ)</CardHeader>
                                <CardBody>
                                    <HorizontalBar
                                        data={this.data_venda_tags_cnpj}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={4}>
                            <Card>
                                <CardHeader>Relatório de Tags por Pessoa Física (CPF)</CardHeader>
                                <CardBody>
                                    <HorizontalBar
                                        //options={decimal_remove}
                                        data={this.data_venda_tags_pessoa_fisica}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <CardHeader>Relatório de Tags por Data</CardHeader>
                                <CardBody>
                                    <Bar
                                        data={this.data_venda_tags_date}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>        
                </div>
            </div>
        )
    }
}
export default Relatorios;