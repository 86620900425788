import moment from 'moment';
import { HiViewGrid } from 'react-icons/hi';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import { fetchWithTimeout } from '../../Tools/fetchWithTimeout';

export const columns = () => [
    {
      name: "",
      selector: "buttons",
      sortable: false,
      width: '5%',
      cell: row => <Row>
        <Button
          outline
          style={{ padding: 2, marginLeft: 15, border: 0, fontWeight: '700'}}
          color="dark" onClick={() => 
            {
                window.location.href = '/contas-a-receber-detalhes/'+row.id
            }
          }>
          <HiViewGrid style={{ padding: '2px' }}/>
        </Button>
      </Row>
    },
    {
      name: "Descrição",
      selector: "descricao",
      sortable: false,
      width: '40%',
      cell: row => <Row>
        <Col md={12} style={{paddingBottom: '0.3rem'}}>{row.descricao}</Col>
        <Col md={12} style={{paddingBottom: '0.3rem'}}><b>Fornecedor:</b> {row.fornecedor}</Col>
        <Col md={12} style={{paddingBottom: '0.3rem'}}></Col>
      </Row>
    },
    {
      name: "Vencimento",
      selector: "vencimento",
      sortable: false,
      width: '20%',
      cell: row => moment(row.vencimento).format('DD/MM/YYYY')
    },
    {
      name: 'Valor',
      selector: 'valor',
      sortable: false,
      width: '15%',
      cell: row => <Row>
        <NumberFormat
          displayType={'text'}
          prefix={'R$ '}
          decimalSeparator={','}
          decimalScale={2}
          thousandSeparator={'.'}
          value={row.valor}
          />
      </Row>
    },
    {
      name: "Status Pagamento",
      selector: "status_pagamento",
      sortable: false,
      width: '20%',
    },
];

var numberOfAjaxCAllPending = 0;

export const getRows = (state, callback) => {
    if(numberOfAjaxCAllPending == 0){
      numberOfAjaxCAllPending = 1;
      //var url = ENDPOINT + "financeiro/contas_pagar?page=" + state.activePage + "&search=" + state.search;
      var url = "http://localhost:8000/api/financeiro/contas_pagar/listar?page=" + state.activePage + "&search=" + state.search;

      if(state.status_processo !== null){
        url += "&status_processo="+state.status_processo.id;
      }      
      
      if(state.status_pagamento !== null){
        url += "&status_pagamento="+state.status_pagamento.id;
      }

      if(state.reclamado !== null){
        url += "&reclamado="+state.reclamado.id;
      }
      console.log('getRows')
      console.log(url)

      var myHeaders = new Headers();
    
      myHeaders.append('Authorization', 'Bearer ' + getToken());

      var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
      };

      fetchWithTimeout(20000, fetch(url, myInit))
      .then(response => response.json())
      .then((p) => {
        if(p.success == true){
          var meta = 0;
          if(p.meta){
            meta = p.meta.last_page;
          }
          numberOfAjaxCAllPending = 0;
          // callback(p.data, meta, p.valor);
          callback(p.data, meta);
        }
        else{
          toast.error(p.errors);
        }
      })
      .catch((error) => {
        toast.error('Ocorreu um erro no servidor!');
        callback({}, {});
      })
    }
    else{
      //callback(state.rowData, state.totalPage, []);
    }
  }
  
  export const getRowsPerPage = (page, state, callback) => {
    if(numberOfAjaxCAllPending == 0){
      numberOfAjaxCAllPending = 1;
      //var url = ENDPOINT + "financeiro/contas_pagar/listar?page=" + page + "&search=" + state.search;
      var url = "http://localhost:8000/api/financeiro/contas_pagar/listar?page=" + page + "&search=" + state.search;

      if(state.status_processo !== null){
        url += "&status_processo="+state.status_processo.id;
      }      
      if(state.status_pagamento !== null){
        url += "&status_pagamento="+state.status_pagamento.id;
      }      
      if(state.reclamado !== null){
        url += "&reclamado="+state.reclamado.id;
      }

      var myHeaders = new Headers();
    
      myHeaders.append('Authorization', 'Bearer ' + getToken());
      
      var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
      };

      fetchWithTimeout(20000, fetch(url, myInit))
      .then(response => response.json())
      .then((p) => {
        if(p.success == true){
          var meta = 0;
          if(p.meta){
            meta = p.meta.last_page;
          }
          numberOfAjaxCAllPending = 0;
          // callback(p.data, meta, p.valor);
          callback(p.data, meta);
        }
        else{
          toast.error(p.errors);
        }
      })
      .catch((error) => {
        toast.error('Ocorreu um erro no servidor!');
        callback({}, {});
      })
    }
    else{
      //callback(state.rowData, state.totalPage, []);
    }
  }