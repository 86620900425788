import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import Moment from 'moment';
import { Button } from 'reactstrap';

const forma_pagamento = [
    { value: "pix", label: "PIX" },
    { value: "boleto", label: "Boleto" },
    { value: "credit", label: "Crédito" },
    { value: "none", label: "Pagamento não necessario" },
    { value: "cupom100", label: "Cupom de 100% de desconto" },
];

export const columns = (onShowDelete) => [
    {
      name: "Data da Compra",
      selector: "created_at",
      sortable: false,
      width: '135px',
      cell: p => Moment(p.created_at).format('DD/MM/YYYY HH:mm')
    },
    {
      name: "Transação",
      selector: "id_transacao",
      sortable: false,
      width: '250px',
      cell: p => <div style={{margin: 5, width: '100%'}}>
                    <center>
                      {p.user !== null ?
                          p.user.nome + ' '+p.user.sobrenome
                      :null}
                    </center>
                    <hr style={{margin: 5}}/>
                    <center>
                        <Button 
                            style={{padding: 5, margin: 5, fontSize: '13px'}} 
                            color="info"
                            outline
                            onClick={() => 
                                {
                                    window.open('/aqcontabil/'+p.id, '_blank')
                                }
                            }
                            >Detalhes</Button>
                    {onShowDelete ? 
                        <Button
                          outline
                          style={{padding: 5, margin: 5, fontSize: '13px'}} 
                          color="danger"
                          onClick={() => onShowDelete(p.id)}
                        >
                          Deletar Pedido
                        </Button> 
                      : 
                      <></>}
                    </center>
                </div>
    },
    {
      name: "Plano",
      selector: "status",
      sortable: false,
      width: '200px',
      cell: p => p.plano.plano
    },
    {
      name: "Valor",
      selector: "condicao",
      sortable: false,
      width: '130px',
      cell: p => 'R$ ' + parseFloat(p.valor).toFixed(2).replace('.', ',')
    },
    {
      name: "Status Pagamento",
      selector: "status",
      sortable: false,
      cell: p => p.status != 'APROVADO' ? (new Date(p.vencimento_pagamento) < new Date() ? 'VENCIDO' : p.status) : p.status
    },
    {
      name: "Pagamento",
      selector: "forma_pagamento",
      sortable: false,
      cell: p => forma_pagamento.map(e => {
          if(e.value == p.tipo_pagamento){
              return e.label;
          }
      })
    },
    {
      name: "Ultimo Pagamento",
      selector: "ultimo_pagamento",
      sortable: false,
      width: '150px',
      cell: p => Moment(p.ultimo_pagamento).format('DD/MM/YYYY HH:mm')
    },
    {
      name: "Vencimento",
      selector: "proximo_pagamento",
      sortable: false,
      width: '150px',
      cell: p => <div>
                      <p><small><b>Fechamento:</b></small> {Moment(p.proxima_pagamento).format('DD/MM/YYYY')}<br/>
                         <small><b>Vencimento:</b></small> {Moment(p.vencimento_pagamento).format('DD/MM/YYYY')}
                      </p>
                </div>
    }
];

export const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "aqcontabil-admin/list?search="+state.search+"&page="+state.activePage;

    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&documento="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&documento="+document;
        }
    }

    if(state.plano !== null){
      url += "&plano="+state.plano.value
    }

    if(state.forma_pagamento !== null){
      url += "&forma_pagamento="+state.forma_pagamento.value
    }
    if(state.filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    var url = ENDPOINT + "aqcontabil-admin/list?search="+search+"&page="+page;

    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&documento="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&documento="+document;
        }
    }

    if(state.plano !== null){
      url += "&plano="+state.plano.value
    }

    if(state.status !== null){
      url += "&status="+state.status.value
    }

    if(state.forma_pagamento !== null){
      url += "&forma_pagamento="+state.forma_pagamento.value
    }
    if(state.filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}