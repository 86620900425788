import React, { Component } from "react";
import { MdImage } from "react-icons/md";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import ReactRoundedImage from "react-rounded-image";
import { Button, Col, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import { InputMasked } from "../../InputText/InputMasked";
import { InputText } from "../../InputText/InputText";
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import axios from "axios";
import { list_origem, list_produtos, list_razao, list_status, list_tipos } from "../../../auth/listas";

const getDepartamentos = (callback) => {
    var url = ENDPOINT + "departamento/list";
    
    axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + getToken()
        }
    }).then((response) => {
        var data = response.data;
        var list_departamentos = [];
        data.forEach((p, i) => {
            p['value'] = p.flag;
            p['label'] = p.nome;
            list_departamentos.push(p);
        });

        callback(list_departamentos)
    });
}
class OcorrenciaModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            product: null, 
            product_invalid: false,
            origem: null,
            origem_invalid: false,
            relacao_cliente: null,
            relacao_cliente_invalid: false,
            tipo_cliente: 'aqpago',
            tipo_pesquisa: 'nome',
            tipo: null,
            tipo_invalid: false,
            telefone: '',
            telefone_invalid: false,
            whatsapp: '',
            whatsapp_invalid: false,
            razao: null,
            razao_invalid: false,
            status: null,
            status_invalid: false,
            funcionario_atribuido: null,
            funcionario_atribuido_invalid: false,
            email: '',
            email_invalid: false,
            departamento: null,
            departamento_invalid: false,
            protocolo_cliente: '',
            protocolo_cliente_invalid: false,
            descricao: '',
            descricao_invalid: false,
            fechamento_conclusao: '',
            fechamento_conclusao_invalid: false,
            prazo_retorno: null,
            prazo_retorno_invalid: false,
            image: null,
            inputValue: '',
            list_departamentos: [],
            loading: false,
            error_message: '',
            success_message: ''
        }
        this.myRef = React.createRef();
    }
    componentWillUpdate(nextProps, nextState) {
        if (nextProps.visible !== false && nextProps.visible !== this.props.visible) {
            getDepartamentos(this.callbackDepartamentos.bind(this));
        }
    }
    callbackDepartamentos(list_departamentos){
        this.setState({list_departamentos});
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }
    

    loadAtendentesOptions = (inputValue, callback) => {
        var url = ENDPOINT + "admin/list?search=" + inputValue;
        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p.data;

            data.forEach((p, i) => {
                data[i].value = p.id;
                data[i].label = <div style={{display: 'flex'}}>
                    <ReactRoundedImage
                        image={p['avatar_foto'] !== null ? p['avatar_foto'] : ''}
                        roundedColor="#8dd199"
                        roundedSize="4"
                        imageWidth="25"
                        imageHeight="25"/> 
                        <p style={{display: 'flex', margin: 0}}>
                            &nbsp;
                            {p.name}
                        </p>
                </div>;
            });

            callback(this.filterAtendentes(inputValue, p.data));
        });
    }

    loadOptions = (inputValue, callback) => {
        var url = ENDPOINT + "user/all?"+this.state.tipo_pesquisa+"=" + inputValue;

        if(this.state.tipo_cliente === 'aqpasso'){
            url = ENDPOINT + "user/all?"+this.state.tipo_pesquisa+"=" + inputValue;
        }
        else{
            url = ENDPOINT + "user/all_aqbank?page=1&"+this.state.tipo_pesquisa+"=" + inputValue;
        }

        var myHeaders = new Headers();
        
        myHeaders.append('Authorization', 'Bearer ' + getToken());
        
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            var data = p.data;

            if(this.state.tipo_cliente === 'aqpasso'){
                data.forEach((p, i) => {
                    data[i].value = p.id;
                    data[i].document = (p.cnpj ? p.cnpj : p.cpf);
                    data[i].label = p.nome+' '+p.sobrenome+(p.empresa !== null ? ' - '+p.empresa.razao_social: '');
                });
            }
            else{
                data.forEach((p, i) => {
                    data[i].value = p.id;
                    data[i].document = (p.cnpj ? p.cnpj : p.cpf);
                    data[i].label = (p.nome ? p.nome : p.responsavel.nome + ' '+p.responsavel.sobrenome) + ' - ' + (p.cpf !== null ? p.cpf : p.cnpj !== null ? p.cnpj : '');
                });
            }

            callback(this.filter(inputValue, data));
        })
        .catch(e => {
            callback(this.filter(inputValue, []));
        });
    }

    filter = (inputValue, data) => {
        return data.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }

    filterAtendentes = (inputValue, data) => {
        return data.filter(i =>
            i.name.toLowerCase().includes(inputValue.toLowerCase())
        );
    }
    validarEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    validarCampos = () => {
        var invalids = 0;
        if(this.state.product !== null){
            this.setState({ product_invalid: false });
        }
        else{
            invalids++;
            this.setState({ product_invalid: true });
        }
        
        if(this.state.origem !== null){
            this.setState({ origem_invalid: false });
        }
        else{
            invalids++;
            this.setState({ origem_invalid: true });
        }
        
        if(this.state.tipo !== null){
            this.setState({ tipo_invalid: false });
        }
        else{
            invalids++;
            this.setState({ tipo_invalid: true });
        }

        if(this.state.relacao_cliente !== null){
            this.setState({ relacao_cliente_invalid: false });
        }
        else{
            invalids++;
            this.setState({ relacao_cliente_invalid: true });
        }

        /* if(this.state.telefone.replaceAll('_', '').length > 12){
            this.setState({ telefone_invalid: false });
        }
        else{
            invalids++;
            this.setState({ telefone_invalid: true });
        }
        
        if(this.state.whatsapp.replaceAll('_', '').length > 12){
            this.setState({ whatsapp_invalid: false });
        }
        else{
            invalids++;
            this.setState({ whatsapp_invalid: true });
        } */

        if(this.state.status !== null){
            this.setState({ status_invalid: false });
        }
        else{
            invalids++;
            this.setState({ status_invalid: true });
        }

        if(this.state.funcionario_atribuido !== null){
            this.setState({ funcionario_atribuido_invalid: false });
        }
        else{
            invalids++;
            this.setState({ funcionario_atribuido_invalid: true });
        }
        
        /* if(this.state.protocolo_cliente.replace('_', '').length > 13){
            this.setState({ protocolo_cliente_invalid: false });
        }
        else{
            invalids++;
            this.setState({ protocolo_cliente_invalid: true });
        } */
        if(this.state.email.length > 0){
            if(this.validarEmail(this.state.email) === true){
                this.setState({ email_invalid: false });
            }
            else{
                invalids++;
                this.setState({ email_invalid: true });
            }
        }

        if(this.state.razao !== null){
            this.setState({ razao_invalid: false });
        }
        else{
            invalids++;
            this.setState({ razao_invalid: true });
        }
        
        if(this.state.departamento !== null){
            this.setState({ departamento_invalid: false });
        }
        else{
            invalids++;
            this.setState({ departamento_invalid: true });
        }

        if(this.state.descricao.length > 0){
            this.setState({ descricao_invalid: false });
        }
        else{
            invalids++;
            this.setState({ descricao_invalid: true });
        }

        /* if(this.state.fechamento_conclusao.length > 0){
            this.setState({ fechamento_conclusao_invalid: false });
        }
        else{
            invalids++;
            this.setState({ fechamento_conclusao_invalid: true });
        } */

        if(this.state.prazo_retorno !== null){
            this.setState({ prazo_retorno_invalid: false });
        }
        else{
            invalids++;
            this.setState({ prazo_retorno_invalid: true });
        }

        if(invalids > 0){
            return false;
        }

        return true;
    }

    onData(data) {
        
        if (data.success === true) {
            this.setState({
                success_message: data.message
            });
        }
        else {
            this.setState({
                error_message: data.errors
            })
        }
    }

    onSave(){
        if(this.validarCampos() === true){
            this.setState({loading: true})
            var url = ENDPOINT+"ocorrencia/add";

            var formData = new FormData();
            formData.append('produto', this.state.product.label);
            formData.append('produto_id', this.state.product.value);
            formData.append('origem_ocorrencia', this.state.origem.label);
            if(this.state.tipo_cliente === 'aqpago'){
                if(this.state.relacao_cliente.cliente_existe === true){
                    formData.append('cliente_id', this.state.relacao_cliente.cliente_existe_id);
                }
                else{
                    formData.append('cliente_new_id', this.state.relacao_cliente.cnpj !== null ? this.state.relacao_cliente.cnpj : this.state.relacao_cliente.cpf);
                }
            }
            else{
                formData.append('cliente_id', this.state.relacao_cliente.value);
            }

            formData.append('tipo_ocorrencia', this.state.tipo.value);
            formData.append('telefone', this.state.telefone);
            formData.append('whatsapp_numero', this.state.whatsapp);
            formData.append('razao_ocorrencia', this.state.razao.label);
            formData.append('email', this.state.email);
            formData.append('protocolo_cliente', this.state.protocolo_cliente);
            formData.append('descricao', this.state.descricao);
            formData.append('status', this.state.status.value);
            
            if(this.state.fechamento_conclusao.length > 0){
                formData.append('fechamento_conclusao', this.state.fechamento_conclusao);
            }
            formData.append('departamento_atribuido', this.state.departamento.value);
            formData.append('prazo_conclusao', this.state.prazo_retorno);
            
            formData.append('funcionario_atribuido_id', this.state.funcionario_atribuido.id);

            if(this.state.image !== null){
                formData.append('ocorrencia_imagem', this.state.image, this.state.image.name);
            }
            
            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }})
            .then((response) => {
                var data = response.data;
                this.onData(data);
                return data;
            })
            .catch((error) => {
                if(error.message === 'Network Error'){
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    this.onData(obj);
                }
                var data = error.response.data;
                this.onData(data);
            });

        }
    }
    onClose() {
        this.setState({
            product: null, 
            product_invalid: false,
            origem: null,
            origem_invalid: false,
            relacao_cliente: null,
            relacao_cliente_invalid: false,
            tipo: null,
            tipo_invalid: false,
            telefone: '',
            telefone_invalid: false,
            whatsapp: '',
            whatsapp_invalid: false,
            razao: null,
            razao_invalid: false,
            status: null,
            status_invalid: false,
            funcionario_atribuido: null,
            funcionario_atribuido_invalid: false,
            email: '',
            email_invalid: false,
            departamento: null,
            departamento_invalid: false,
            protocolo_cliente: '',
            protocolo_cliente_invalid: false,
            descricao: '',
            descricao_invalid: false,
            fechamento_conclusao: '',
            fechamento_conclusao_invalid: false,
            prazo_retorno: null,
            prazo_retorno_invalid: false,
            image: null,
            inputValue: '',
            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose(false);
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    handleFile = (e) => {
        if(e.target.files.length > 0){
            this.setState({
                image: e.target.files[0]
            })
        }
    }

    render(){
        return(
            <Modal isOpen={this.props.visible} size='lg'>
                <ModalHeader>
                    Criar Ocorrência
                </ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <p style={{textDecoration: 'underline'}}><b>Informações de Ocorrência</b></p>
                    <Form>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Serviço da Empresa:</Label>
                                <Select 
                                    placeholder="Servico" 
                                    options={list_produtos}
                                    isSearchable={false}
                                    value={this.state.product}
                                    onChange={(product) => this.setState({product})}
                                    noOptionsMessage={() => {return 'Sem opções'}}/>
                                <Input hidden invalid={this.state.product_invalid}/>
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>Origem da Ocorrência:</Label>
                                <Select 
                                    placeholder="Origem da Ocorrência" 
                                    options={list_origem}
                                    isSearchable={false}
                                    value={this.state.origem}
                                    onChange={(origem) => this.setState({origem})}
                                    noOptionsMessage={() => {return 'Sem opções'}}/>
                                <Input hidden invalid={this.state.origem_invalid}/>
                                <FormFeedback>Campo obrigatório</FormFeedback>
                                {/* <Label>Origem da Ocorrência:</Label>
                                <InputText 
                                    type="text"
                                    name="origem"
                                    value={this.state.origem}
                                    onChange={this.handleChange}
                                    invalid={this.state.origem_invalid}
                                    placeholder="Origem da Ocorrência" />
                                <FormFeedback>Campo obrigatório</FormFeedback> */}
                            </FormGroup>
                            <FormGroup>
                                <Label>Razão da ocorrência:</Label>
                                <Select 
                                    placeholder="Razão da ocorrência" 
                                    options={list_razao}
                                    isSearchable={false}
                                    value={this.state.razao}
                                    onChange={(razao) => this.setState({razao})}
                                    noOptionsMessage={() => {return 'Sem opções'}}/>
                                <Input hidden invalid={this.state.razao_invalid}/>
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>Tipo:</Label>
                                <Select 
                                    placeholder="Selecione o Tipo"
                                    isSearchable={false}
                                    value={this.state.tipo}
                                    onChange={(tipo) => this.setState({tipo})}
                                    options={list_tipos}
                                    noOptionsMessage={() => {return 'Sem opções'}} />
                                <Input hidden invalid={this.state.tipo_invalid}/>
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>Relacionado a:</Label>
                                <br/>
                                <input type="radio" name="cliente_radio" onChange={() => this.setState({tipo_cliente: 'aqpago'})} checked={this.state.tipo_cliente === 'aqpago'} />&nbsp;Clientes AQPago&nbsp;&nbsp;
                                <input type="radio" name="cliente_radio" onChange={() => this.setState({tipo_cliente: 'aqpasso'})} checked={this.state.tipo_cliente === 'aqpasso'}/>&nbsp;Clientes AQPasso
                                <AsyncSelect
                                    key={this.state.tipo_cliente+' - '+this.state.tipo_pesquisa}
                                    style={{width: '100%'}}           
                                    cacheOptions
                                    loadOptions={this.loadOptions}
                                    defaultOptions
                                    isOptionDisabled={(option) => option.document === null}
                                    placeholder="Cliente"
                                    onChange={(relacao_cliente) => this.setState({relacao_cliente})}
                                />
                                <small>Pesquisa por: &nbsp;
                                <input type="radio" name="pesquisa_radio" onChange={() => this.setState({tipo_pesquisa: 'nome'})} checked={this.state.tipo_pesquisa === 'nome'} />&nbsp;Nome&nbsp;&nbsp;
                                <input type="radio" name="pesquisa_radio" onChange={() => this.setState({tipo_pesquisa: 'nome_empresarial'})} checked={this.state.tipo_pesquisa === 'nome_empresarial'}/>&nbsp;Nome Empresa&nbsp;&nbsp;
                                <input type="radio" name="pesquisa_radio" onChange={() => this.setState({tipo_pesquisa: 'cpf'})} checked={this.state.tipo_pesquisa === 'cpf'}/>&nbsp;CPF&nbsp;&nbsp;
                                <input type="radio" name="pesquisa_radio" onChange={() => this.setState({tipo_pesquisa: 'cnpj'})} checked={this.state.tipo_pesquisa === 'cnpj'}/>&nbsp;CNPJ&nbsp;&nbsp; </small>
                                <Input hidden invalid={this.state.relacao_cliente_invalid}/>
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>Telefone:</Label>
                                <InputMasked 
                                    name="telefone"
                                    value={this.state.telefone}
                                    onChange={this.handleChange}
                                    invalid={this.state.telefone_invalid}
                                    placeholder="Telefone" 
                                    mask="(99) 99999-9999"/>
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>WhatsApp:</Label>
                                <InputMasked 
                                    name="whatsapp"
                                    value={this.state.whatsapp}
                                    onChange={this.handleChange}
                                    invalid={this.state.whatsapp_invalid}
                                    placeholder="WhatsApp"  
                                    mask="(99) 99999-9999"/>
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Status:</Label>
                                <Select 
                                    placeholder="Status" 
                                    isSearchable={false}
                                    options={list_status}
                                    value={this.state.status}
                                    onChange={(status) => this.setState({status})}
                                    noOptionsMessage={() => {return 'Sem opções'}}
                                />
                                <Input hidden invalid={this.state.status_invalid}/>
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>Funcionário Atribuido:</Label>
                                <AsyncSelect
                                    style={{width: '100%'}}           
                                    cacheOptions
                                    loadOptions={this.loadAtendentesOptions}
                                    defaultOptions
                                    placeholder="Funcionário"
                                    onChange={(funcionario_atribuido) => this.setState({funcionario_atribuido})}
                                />
                                <Input hidden invalid={this.state.funcionario_atribuido_invalid}/>
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>E-mail:</Label>
                                <InputText 
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    invalid={this.state.email_invalid}
                                    placeholder="E-mail" />
                            </FormGroup>
                            <FormGroup>
                                <Label>Protocolo do cliente:</Label>
                                <InputMasked 
                                    name="protocolo_cliente"
                                    value={this.state.protocolo_cliente}
                                    onChange={this.handleChange}
                                    invalid={this.state.protocolo_cliente_invalid}
                                    mask="99999999999999"
                                    placeholder="Protocolo do cliente" />
                            </FormGroup>
                            <FormGroup>
                                <Label>Departamento Atribuido:</Label>
                                <Select 
                                    placeholder="Departamento"
                                    options={this.state.list_departamentos}
                                    isSearchable={false}
                                    onChange={(departamento) => this.setState({departamento})}
                                    noOptionsMessage={() => {return 'Sem opções'}}
                                />
                                <Input hidden invalid={this.state.departamento_invalid} />
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>Prazo de retorno:</Label>
                                <Input
                                    type="date"
                                    min={new Date().toISOString().split("T")[0]}
                                    name="prazo_retorno"
                                    value={this.state.prazo_retorno}
                                    onChange={this.handleChange}
                                    invalid={this.state.prazo_retorno_invalid}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr/>
                    <p style={{textDecoration: 'underline'}}><b>Descrição de Ocorrência</b></p>
                    <FormGroup>
                        <Label>Descrição:</Label>
                        <Input 
                            type="textarea" 
                            name="descricao"
                            value={this.state.descricao}
                            onChange={this.handleChange}
                            invalid={this.state.descricao_invalid}
                            placeholder="Descrição" />
                    </FormGroup>
                    <FormGroup>
                        <input 
                            type="file" 
                            hidden 
                            ref={this.myRef}
                            onChange={this.handleFile}/>
                        <InputGroup>
                            <Input 
                                disabled 
                                placeholder={this.state.image !== null ? this.state.image.name : "Selecionar Imagem" }
                                style={{height: '40px'}}/>
                            <InputGroupAddon addonType="prepend">
                                <Button 
                                    onClick={() => this.myRef.current.click()} 
                                    color="success">
                                    <MdImage style={{padding: 0}}/>
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <Label>Fechamento de conclusão:</Label>
                        <Input 
                            type="textarea" 
                            name="fechamento_conclusao"
                            value={this.state.fechamento_conclusao}
                            onChange={this.handleChange}
                            invalid={this.state.fechamento_conclusao_invalid}
                            placeholder="Fechamento de conclusão" />
                    </FormGroup>
                    </Form>
                </ModalBody>
                <ModalBody
                    hidden={!this.state.loading}
                    style={{
                        minHeight: '114px',
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose.bind(this)}
                        onError={this.onError.bind(this)}
                    />

                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose()}>Fechar</Button>
                    <Button color="success" onClick={() => this.onSave()}>Salvar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default OcorrenciaModal;