import React, { useState, useEffect } from "react";
import DetalhesRecarga from "./DetalhesRecarga";
import axios from "axios";
import { Button, Row, Card, CardBody } from "reactstrap";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import DataTable from 'react-data-table-component';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import styles from "./Recargas.module.css";
import Select from 'react-select';
import ReactTooltip from "react-tooltip"
import { HiViewGrid } from 'react-icons/hi';
import { FaFileExcel } from 'react-icons/fa';

//componente functional
const Recargas = () => {

    const [recargas, setRecargas] = useState([]);
    const [totalRecargasCelular, setTotalRecargasCelular] = useState([]);
    const [totalRecargasTv, setTotalRecargasTv] = useState([]);
    const [totalRecargasPin, setTotalRecargasPin] = useState([]);
    const [saldoTotal, setSaldoTotal] = useState(''); // Passo 1
    const [showFilter, setShowFilter] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Passo 1
    const [timeoutId, setTimeoutId] = useState(null);


    //estados de filtros
    const [filtroId, setFiltroId] = useState('');
    const [filtroCPF, setFiltroCPF] = useState(''); // Estado para o filtro por CPF
    const [filtroNome, setFiltroNome] = useState(''); // Estado para o filtro por nome
    const [filtroTipo, setFiltroTipo] = useState(''); // Estado para o filtro por tipo
    const [filtroStatus, setFiltroStatus] = useState(''); // Estado para o filtro por tipo
    const [mostrarMensagemNenhumResultado, setMostrarMensagemNenhumResultado] = useState(false);


    const currentDate = new Date(); // Obtém a data atual
    const currentYear = currentDate.getFullYear(); // Extrai o ano atual
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // Extrai o mês atual e garante que tenha dois dígitos

    const [inputMonthValue, setInputMonthValue] = useState(`${currentYear}-${currentMonth}`);

    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };



    const columns = [
        {
            name: '',
            width: '50px',
            cell: row => <>
                <Row>
                    <Button
                        data-tip="Detalhe da Recarga"
                        outline
                        style={{ padding: '0px', marginLeft: '3px', border: 0 }}
                        color="primary">
                        <HiViewGrid style={{ padding: '2px', width: '23px' }} onClick={() => {
                            window.location.href = '/recarga/' + row.id
                        }
                        } />
                    </Button>
                    <ReactTooltip />
                </Row>
            </>
        },
        {
            name: '',
            width: '50px',
            cell: row => <>
                <Row>
                    <Button
                        data-tip="Exportar Recarga para Excel"
                        outline
                        style={{ padding: '0px', marginLeft: '3px', border: 0 }}
                        color="success">
                        <FaFileExcel style={{ padding: '2px', width: '23px' }} onClick={() => {
                            handleExportExcel(row.id)
                        }
                        } />
                    </Button>
                    <ReactTooltip />
                </Row>
            </>
        },

        {
            name: 'ID',
            selector: 'id',
            sortable: true,
            cell: row => row.id
        },
        {
            name: 'Nome',
            sortable: false,
            cell: row => row.user.nome + ' ' + row.user.sobrenome
        },
        {
            name: 'Tipo',
            selector: 'servico_recarga',
            sortable: true,
            cell: row => row.servico_recarga ? row.servico_recarga.charAt(0).toUpperCase() + row.servico_recarga.slice(1) : 'N/A'
        },
        {
            name: 'Pagamento',
            sortable: true,
            selector: row => row.pagamento && row.pagamento.forma_pagamento ? row.pagamento.forma_pagamento.toLowerCase() : '',
            cell: row => {
                // Função para capitalizar a primeira letra e tratar strings específicas
                const formatPayment = (payment) => {
                    if (!payment) return 'N/A'; // Retorna 'N/A' se não houver pagamento
                    let formattedPayment = payment.toLowerCase() === 'credit' ? 'Crédito' : payment;
                    // Capitaliza a primeira letra
                    return formattedPayment.charAt(0).toUpperCase() + formattedPayment.slice(1);
                };

                return formatPayment(row.pagamento ? row.pagamento.forma_pagamento : '');
            }
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            cell: row => row.status
        },
        {
            name: 'Data do Pedido',
            selector: 'data_pedido',
            sortable: true,
            cell: row => row.data_pedido
        },

        {
            name: 'Valor Cobrado',
            selector: 'valor_cobrado',
            sortable: true,
            cell: row => `R$ ${parseFloat(row.valor_cobrado).toFixed(2)}`
        }

    ];

    const getRecargas = () => {
        //axios get recargas
        axios.get(`${ENDPOINT}recarga/list/`, {
            headers: {
                Authorization: 'Bearer ' + getToken() // Certifique-se de que getToken() está definido e acessível
            }
        })
            .then(response => {
                setRecargas(response.data);
                setIsLoading(false); // Passo 2
                console.log('Success Recarga:');
            })
            .catch(error => {
                setIsLoading(false); // Passo 2
                console.error('Error fetching recargas:', error);
            });
    }

    const planilhaHandler = () => {
        axios.get(`${ENDPOINT}recarga/relatorio`, {
            headers: {
                Authorization: 'Bearer ' + getToken() // Certifique-se de que getToken() está definido e acessível
            },
            responseType: 'blob' // Importante para lidar com o download de arquivos
        })
            .then(response => {
                // Cria um URL para o Blob
                const url = window.URL.createObjectURL(new Blob([response.data]));
                // Cria um elemento de link para download
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'planilha_recargas.xlsx'); // Define o nome do arquivo para download
                // Adiciona o link ao corpo do documento
                document.body.appendChild(link);
                // Inicia o download
                link.click();
                // Limpa o link do corpo do documento e revoga o URL do Blob
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
                console.log('Success:', 'Planilha gerada para download');
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const handleExportExcel = (id) => {
        axios.get(`${ENDPOINT}recarga/relatorio/` + id, {
            headers: {
                Authorization: 'Bearer ' + getToken() // Certifique-se de que getToken() está definido e acessível
            },
            responseType: 'blob' // Importante para lidar com o download de arquivos
        })
            .then(response => {
                // Cria um URL para o Blob
                const url = window.URL.createObjectURL(new Blob([response.data]));
                // Cria um elemento de link para download
                const link = document.createElement('a');
                // Define o nome do arquivo para download
                link.href = url;
                link.setAttribute('download', 'recarga_' + id + '.xlsx');
                // Adiciona o link ao corpo do documento
                document.body.appendChild(link);
                // Inicia o download
                link.click();
                // Limpa o link do corpo do documento e revoga o URL do Blob
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
                console.log('Success:', 'Planilha gerada para download');
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }


    const tipos_recarga = [
        { value: 'pin', label: 'Pin' },
        { value: 'celular', label: 'Celular' },
        { value: 'tv', label: 'TV' },
        { value: 'transporte', label: 'Transporte' },
        { value: 'sorteio', label: 'Sorteio' }
    ];

    const tipos_pagamento = [
        { value: 'aprovado', label: 'Aprovado' },
        { value: 'pendente', label: 'Pendente' },
        { value: 'cancelado', label: 'Cancelado' },
    ];


    // Função genérica para manipular mudanças de input
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'filtroId') setFiltroId(value);
        else if (name === 'filtroCPF') setFiltroCPF(value);
        else if (name === 'filtroNome') setFiltroNome(value);
    };

    const handleMonthChange = (event) => {
        setInputMonthValue(event.target.value);
    };


    const handleSelectTipoChange = selectedOption => {
        setFiltroTipo(selectedOption);
    };

    const handleSelectStatusChange = selectedOption => {
        setFiltroStatus(selectedOption);
    };

    // const getTotalValorRecargas = () => {
    //     axios.get(`${ENDPOINT}recarga/saldo_credito`, {
    //         headers: {
    //             Authorization: 'Bearer ' + getToken() // Certifique-se de que getToken() está definido e acessível
    //         },
    //     })
    //         .then(response => {
    //             setSaldoTotal(response?.data?.total);
    //             console.log(response?.data?.total);
    //         })
    //         .catch(error => {
    //             console.error('Error saldo total credito:', error);
    //         });
    // }



    // Inicializa recargasFiltradas com todas as recargas
    let recargasFiltradas = recargas;

    // Aplica filtro por ID
    if (filtroId) {
        recargasFiltradas = recargasFiltradas.filter(recarga => String(recarga.id) === filtroId);
    }

    // Aplica filtro por CPF sobre o resultado do filtro anterior
    if (filtroCPF) {
        recargasFiltradas = recargasFiltradas.filter(recarga => String(recarga.user.cpf).includes(filtroCPF));
    }

    if (filtroNome) {
        recargasFiltradas = recargasFiltradas.filter(recarga =>
            String(recarga.user.nome).toLowerCase().includes(filtroNome.toLowerCase()) ||
            String(recarga.user.sobrenome).toLowerCase().includes(filtroNome.toLowerCase())
        );
    }

    // Aplica filtro por Tipo sobre o resultado dos filtros anteriores
    if (filtroTipo) {
        recargasFiltradas = recargasFiltradas.filter(recarga => String(recarga.servico_recarga).toLowerCase() === filtroTipo.value.toLowerCase());
    }

    // Aplica filtro por Tipo sobre o resultado dos filtros anteriores
    if (filtroStatus) {
        recargasFiltradas = recargasFiltradas.filter(recarga => String(recarga.status).toLowerCase() === filtroStatus.value.toLowerCase());
    }

    useEffect(() => {
        getRecargas();
    }, []);
    useEffect(() => {
        if (recargas.length > 0) {

            setSaldoTotal(recargas
                .filter(recarga => recarga.status === 'APROVADO') // Filtra apenas as recargas aprovadas
                .reduce((acc, recarga) => {
                    return acc + parseFloat(Number(recarga.valor_cobrado)); // Soma os valores das recargas aprovadas
                }, 0));
        }
    }, [recargas]);


    useEffect(() => {
        if (inputMonthValue) {
            const [anoSelecionado, mesSelecionado] = inputMonthValue.split('-').map(Number); // Extrai ano e mês do inputMonthValue

            const inicioDoMes = new Date(anoSelecionado, mesSelecionado - 1, 1, 0, 0, 0, 0); // Mês em JavaScript começa do 0
            const fimDoMes = new Date(anoSelecionado, mesSelecionado, 0, 23, 59, 59, 999); // Dia 0 do próximo mês é o último dia do mês atual

            if (recargas.length > 0) {
                const recargasNoMesSelecionado = recargas.filter(recarga => {
                    const dataRecarga = new Date(recarga.updated_at);
                    return dataRecarga >= inicioDoMes && dataRecarga <= fimDoMes;
                });

                setTotalRecargasCelular(recargasNoMesSelecionado.filter(recarga => recarga.servico_recarga === 'celular' && recarga.status === 'APROVADO'));
                setTotalRecargasTv(recargasNoMesSelecionado.filter(recarga => recarga.servico_recarga === 'tv' && recarga.status === 'APROVADO'));
                setTotalRecargasPin(recargasNoMesSelecionado.filter(recarga => recarga.servico_recarga === 'pin' && recarga.status === 'APROVADO'));
            }
        }
    }, [recargas, inputMonthValue]);

    useEffect(() => {
        if (recargasFiltradas.length === 0) {
            setMostrarMensagemNenhumResultado(true);
        } else {
            setMostrarMensagemNenhumResultado(false);
        }
    }, [recargasFiltradas]);

    //og em total recargas celular
    useEffect(() => {
        console.log(totalRecargasCelular);
    }, [totalRecargasCelular]);


    if (isLoading) { // Passo 3
        return <div>Carregando...</div>; // Substitua isso pelo seu componente de carregamento preferido
    }

    return (
        <div>
            <div className={styles.cardInfoRecargasFather}>
                <div className={styles.cardInfoRecargas}>
                    <div className={styles.cardInfoHeaderRecargas}>
                        <h5>Saldo total das Recargas</h5>
                    </div>
                    <div className="card-body">
                        <span className={styles.icon}>Valor Total:</span>
                        <br />
                        <span className={styles.count}>{recargas.length}</span>
                        <span className={styles.valueTotal}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(saldoTotal / 10)}</span>                    </div>
                </div>
                <div className={styles.cardInfoRecargas2}>
                    <div className={styles.cardInfoHeaderRecargas}>
                        <h5>Valor e quantidade de Recargas no mês</h5>
                        <input
                            value={inputMonthValue}
                            onChange={handleMonthChange} // Adiciona o manipulador de eventos onChange
                            type='month'
                            className={styles.inputMonth}
                            min="2020-01" // Exemplo de mínimo
                            max="2025-12" // Exemplo de máximo
                        />
                    </div>
                    <div className="card-body">
                        <div className={styles.cardInfoRecargas2Children}>
                            <span className={styles.celularLabel}>Celular:</span>
                            <span className={styles.count}>{totalRecargasCelular.length}</span>
                            {totalRecargasCelular.length > 0 ?
                                <span className={styles.valueTotal}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalRecargasCelular
                                    .reduce((acc, recarga) => acc + parseFloat(Number(recarga.valor_cobrado)), 0))}</span> : <span className={styles.valueTotal}>R$ 0,00</span>
                            }
                        </div>
                        <div className={styles.cardInfoRecargas2Children}>
                            <span className={styles.tvLabel}>TV:</span>
                            <span className={styles.count}>{totalRecargasTv.length}</span>
                            {totalRecargasTv.length > 0 ?
                                <span className={styles.valueTotal}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalRecargasTv
                                    .reduce((acc, recarga) => acc + parseFloat(Number(recarga.valor_cobrado)), 0))}</span> : <span className={styles.valueTotal}>R$ 0,00</span>
                            }
                        </div>
                        <div className={styles.cardInfoRecargas2Children}>
                            <span className={styles.pinLabel}>Pin:</span>
                            <span className={styles.count}>{totalRecargasPin.length}</span>
                            {totalRecargasPin.length > 0 ?
                                <span className={styles.valueTotal}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalRecargasPin
                                    .reduce((acc, recarga) => acc + parseFloat(Number(recarga.valor_cobrado)), 0))}</span> : <span className={styles.valueTotal}>R$ 0,00</span>
                            }
                        </div>

                    </div>
                </div>
            </div>

            <hr />
            <div style={{ marginBottom: '23px', marginTop: '18px' }}>
                <Button color="success" onClick={() => planilhaHandler()}>Baixar planilha de recargas</Button>
                <br />
            </div>
            <div className={styles.card}>
                <div className="card-body">
                    <Button
                        className={styles.filterButton}
                        color="warning"
                        onClick={toggleFilter}>
                        Filtrar&nbsp;
                        {showFilter ? <AiFillCaretUp /> : <AiFillCaretDown />}
                    </Button>
                    <div className={styles.filtersDiv}>
                        <div className={styles.inputs}>
                            <input
                                className={styles.filtroId}
                                name="filtroId"
                                value={filtroId}
                                onChange={handleInputChange}
                                onKeyDown={handleInputChange}
                                onKeyUp={handleInputChange}
                                placeholder="Filtro por ID"
                            />
                        </div>
                    </div>
                    <div className={`${styles.filtersDiv} ${showFilter ? styles.fadeIn : styles.fadeOut}`}>

                        <div className={styles.inputs}>
                            <input
                                className={styles.filtroCpf}
                                name="filtroCPF"
                                value={filtroCPF}
                                onChange={handleInputChange}
                                onKeyDown={handleInputChange}
                                onKeyUp={handleInputChange}
                                placeholder="Filtro por CPF - (sem pontuação)"
                            />
                            <input
                                className={styles.filtroNome}
                                name="filtroNome"
                                value={filtroNome}
                                onChange={handleInputChange}
                                onKeyDown={handleInputChange}
                                onKeyUp={handleInputChange}
                                placeholder="Filtro por Nome e Sobrenome"
                            />
                        </div>
                        <div className={styles.selects}>
                            <Select
                                className="react-select"
                                options={tipos_recarga}
                                placeholder="Filtro por Tipo de Recarga"
                                isSearchable={false}
                                isClearable
                                value={filtroTipo}
                                onChange={handleSelectTipoChange}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0
                                })}
                            />
                            <Select
                                className="react-select"
                                options={tipos_pagamento} // Substitua por suas opções de status de pagamento
                                placeholder="Filtro por Status da Recarga"
                                isSearchable={false}
                                isClearable
                                value={filtroStatus} // Substitua pelo estado correspondente ao filtro de status de pagamento
                                onChange={handleSelectStatusChange} // Substitua pela função que lida com a mudança de seleção
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0
                                })}
                            />
                        </div>
                    </div>

                    {
                        mostrarMensagemNenhumResultado ? (
                            <div className={styles.noResults}>Nenhum resultado encontrado</div>
                        ) : (
                            <DataTable
                                noHeader={true}
                                columns={columns}
                                button={true}
                                responsive
                                noDataComponent="sem registros"
                                data={recargasFiltradas.length > 0 ? recargasFiltradas : recargas}
                                pagination
                                paginationPerPage={30} // Set default rows per page
                                paginationRowsPerPageOptions={[30]} // Set rows per page options
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default Recargas;
