import axios from 'axios';
import moment from 'moment';
import { Row, ButtonGroup, Button } from 'reactstrap';
import { MdDelete } from 'react-icons/md';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';


const getStatus = (status) => {
    console.log(status)
    if(status === 1){
        return "Transação inexistente"
    }
    else if(status === 2){
        return "Passagem Duplicada"
    }
    else{
        return "Cobrança de valor incorreto"
    }
}

const getMonthString = (mes) => {
    var month = new Array();
        month[1] = "Janeiro";
        month[2] = "Fevereiro";
        month[3] = "Março";
        month[4] = "Abril";
        month[5] = "Maio";
        month[6] = "Junho";
        month[7] = "Julho";
        month[8] = "Agosto";
        month[9] = "Setembro";
        month[10] = "Outubro";
        month[11] = "Novembro";
        month[12] = "Dezembro";

    return month[mes];
}
export const columns = (onShowDelete) => [
    {
        name: '',
        selector: 'buttons',
        width: '70px',
        cell: row => <Row>
              <ButtonGroup>
                  <Button
                    outline
                    style={{ padding: 2, border: 0, fontWeight: '700'}}
                    color="danger"
                    onClick={() => onShowDelete(row.id)}
                  >
                    <MdDelete style={{ padding: '2px' }} />
                  </Button>  
              </ButtonGroup>
        </Row>
      },
    {
        name: 'Mês',
        selector: 'mes',
        sortable: false,
        width: '100px',
        cell: p => getMonthString(p.mes)
    },
    {
        name: 'Ano',
        selector: 'ano',
        sortable: false,
        width: '100px',
    },
    {
      name: "Descrição",
      selector: "descricao",
      sortable: false,
      width: '200px',
      cell: row => row.descricao
    },
    {
      name: "Banner",
      selector: "url",
      sortable: false,
      cell: row => <img src={row.url_banner} width="500" height="auto" />
    }
];

export const getRows = (state, callbackRows) => {
    
    var url = ENDPOINT + "faturas/campanha_list";

    if(state.taggy_serial !== null){
        url += '?taggy_serial='+state.taggy_serial;
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    
    var url = ENDPOINT + "faturas/campanha_list?search="+search+"&page="+page;

    if(state.taggy_serial !== null){
        url += '&taggy_serial='+state.taggy_serial;
    }
    if(filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}