import { useState } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Button,
  Col,
  Modal,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormGroup,
  FormFeedback,
} from "reactstrap";

import { InputText } from "../../InputText/InputText";
import { getUser } from "../../../auth/set_services";
import { Loader } from "../../Loaders/Loader";
import { useApiAqmed } from "../../../hooks/useApi";

export function ModalRemovePlan({ isOpen, size, onClose, config = null }) {
  const [fee, setFee] = useState("");
  const [reason, setReason] = useState("");
  const [expiresAt, setExpiresAt] = useState("");
  const [isFetching, setIsFecthing] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const { apiAqmed } = useApiAqmed();
  const history = useHistory();

  const userCrmId = getUser()?.id;

  const validadeFee = (fee) => {
    if (!fee) return;
    if (fee.includes(",")) {
      setFee(fee.replace(",", "."));
    }
    if (!fee.includes(".")) {
      setIsValid(false);
      return;
    }
    setIsValid(true);
    return;
  };
  const handleSubmit = async () => {
    const confirm = window.confirm("Tem certeza que deseja remover o plano?");
    if (!confirm) {
      return;
    }
    validadeFee(fee);
    if (!isValid) return;

    const formData = {
      user_public_id: config?.userId,
      created_by_user_crm_id: userCrmId,
      amount_fee_uniodonto: fee,
      expired_at: expiresAt,
      reason: reason,
    };
    console.log("formData = ", formData);
    const token = Cookies.get("aqmedtoken");
    apiAqmed
      .post(`/crm/plan/destroy`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((send) => {
        if (send.status === 200) {
          alert("Contrato Removido com sucesso");
          onClose();
          history.push("/aqmed/clientes");
        } else {
          alert("Erro ao Remover contrato");
          onClose();
        }
      })
      .catch((error) => {
        alert("Erro ao Remover contrato");
        onClose();
      })
      .finally(() => setIsFecthing(false));
  };

  return (
    <Modal isOpen={isOpen} size={size}>
      <ModalHeader>Remover Plano</ModalHeader>

      {!isFetching ? (
        <>
          <ModalBody>
            <FormGroup>
              <Row>
                <Col md={10} sm={10} xs={10}>
                  <h6 style={{ marginTop: "10px" }}>
                    Valor da Multa (Uniodonto)
                  </h6>
                  <InputText
                    type="number"
                    invalid={!isValid}
                    name="fee"
                    value={fee}
                    onChange={(event) => setFee(event.target.value)}
                  />
                  <FormFeedback>{"Formato precisa ser ###,##"}</FormFeedback>
                </Col>
              </Row>
              <Row>
                <Col md={10} sm={10} xs={10}>
                  <h6 style={{ marginTop: "10px" }}>Motivo</h6>
                  <InputText
                    name="reason"
                    value={reason}
                    onChange={(event) => setReason(event.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={10} sm={10} xs={10}>
                  <h6 style={{ marginTop: "10px" }}>
                    Data de Vencimento do Boleto
                  </h6>
                  <InputText
                    type="date"
                    name="reason"
                    value={expiresAt}
                    onChange={(event) => setExpiresAt(event.target.value)}
                  />
                </Col>
              </Row>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={onClose}>
              Cancelar
            </Button>
            <Button color="success" onClick={handleSubmit}>
              Cadastrar
            </Button>
          </ModalFooter>
        </>
      ) : (
        <Loader />
      )}
    </Modal>
  );
}
