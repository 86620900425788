import React, { Component } from 'react';
import { ButtonGroup, Card, CardBody, CardColumns, Col, Collapse, Input, Nav, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import styled from 'styled-components';
import DataAQPago from '../../components/ResumeClient/DataAQPago';
import TransacoesClient from '../../components/ResumeClient/TransacoesClient';
import TransferenciaClient from '../../components/ResumeClient/TransferenciaClient';
import { getPermissoes, getToken, getUser } from '../../auth/set_services';
import { ENDPOINT } from '../../auth/endpoint';
import PrevencaoRiscoClient from '../../components/ResumeClient/PrevencaoRiscoClient';
import ProximosLancamentos from '../../components/ResumeClient/ProximosLancamentos';
import ContasBancariasClient from '../../components/ResumeClient/ContasBancariasClient';
import aqpago from '../../assets/aqpago.webp';
import { toast } from 'react-toastify';
import axios from 'axios';
import { maquininhas_list } from '../../auth/listas';
import FadeLoader from "react-spinners/FadeLoader";
import ReactStoreIndicator from 'react-score-indicator';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { Button } from 'react-bootstrap';
import ModalValidacaoToken from '../../components/Modals/ModalValidacaoToken/ModalValidacaoToken';
import { ModalComplience } from '../../components/Modals/ModalComplience/ModalComplience';


const get_status_auth = (payment_type) => {
    switch(payment_type){
        case 0 : return 'Aguardando Validação';
        case 1 : return 'Validado';
        case 2 : return 'Perdido';
        default: return ''
    }
}
const get_status = (payment_type) => {
    switch(payment_type){
        case 'enabled' : return 'Ativo';
        case 'inactive' : return 'Inativo';
        case 'pending' : return 'Pendente';
        case 'failed' : return 'Falhada';
        default: return payment_type
    }
}
const getId = () => {
    return window.location.pathname.replace('/aqpago/', '')
}
/* const getActive = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('active');
    if(id !== null){
        return id;
    }
    return "1";

} */

const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
`

const getUserAQPago = (id, callback, callbackError) => {
    var url = ENDPOINT + "user/get_aqbank/"+id;
  
    var myHeaders = new Headers();
  
    myHeaders.append('Authorization', 'Bearer ' + getToken());
  
    var myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };
    fetch(url, myInit)
      .then(response => response.json())
      .then((p) => {
          callback(p);
      })
      .catch((error) => {
        callbackError();
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            toast.error(obj.errors);
            return obj;
        }
        var data = error.response.data;
        toast.error(data.errors);
      })
  }
  
const getSerasaNew = (documento, callback, callbackError) => {
    var url = ENDPOINT + "nova_consulta_serasa/"+documento;
  
    var myHeaders = new Headers();
  
    myHeaders.append('Authorization', 'Bearer ' + getToken());
  
    var myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };
    fetch(url, myInit)
      .then(response => response.json())
      .then(() => {
        getUserAQPago(getId(), callback);
      })
      .catch((error) => {
        callbackError();
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            toast.error(obj.errors);
            return obj;
        }
        var data = error.response.data;
        toast.error(data.errors);
      })
}
const getSerasa = (documento, callback) => {
    var url = ENDPOINT + "consultar_serasa/"+documento;
  
    var myHeaders = new Headers();
  
    myHeaders.append('Authorization', 'Bearer ' + getToken());
  
    var myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };
    fetch(url, myInit)
      .then(response => response.json())
      .then(() => {
        getUserAQPago(getId(), callback);
      })
}
export default class ResumoAQPago extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id_zoop: '',
            nome_titular: '',
            tipo_conta: '',
            email: '',
            telefone: '',
            documento: getId(),
            plano: '',
            created_at: '2021-01-01',
            endereco: '',
            endereco_empresa: '',
            empresa: null,
            userId: getId(),
            isVisible: false,
            transactions: [],
            transfers: [],
            prevention: [],
            bank_accounts: [],
            activeTab: "1",
            future_releases: [],
            maquininhas: [],
            communication_relacao_id: -1,
            serasa: [],
            autorizacoes: [],
            compliance: null,
            is_open_nota: false,
            distract_nota: '',
            loading_compliance: false,

            email_edit_enable: false,
            email_edit: '',
            account_balance: '',
            current_balance: '',
            
            telefone_edit_enable: false,
            telefone_edit: '',
            loading_user: false,
        }
        //this.showUsers = this.showUsers.bind(this);
        //this.onTaggyChange = this.onTaggyChange.bind(this);
        this.callbackRows = this.callbackRows.bind(this);
    }
    componentDidMount() {
        getUserAQPago(
            this.state.userId,
            this.callbackRows
        );
    }
    callbackRows(data){
        if(data.success === true){
            var dados = data.data;

            if(dados.type === 'individual'){
                var endereco_json = dados.address;
                var endereco = endereco_json.line1 + (endereco_json.line2 !== null ? ', ' + endereco_json.line2 : '') +
                            (endereco_json.line3 !== null ? ', '+endereco_json.line3 : '') + ' - ' +
                            (endereco_json.neighborhood !== null ? ''+endereco_json.neighborhood : '') +
                            (endereco_json.city !== null ? ' - '+endereco_json.city : '') +
                            (endereco_json.state !== null ? '/'+endereco_json.state : '');

                
                this.setState({
                    tipo_conta: 'Conta Pessoal',
                    nome_titular: dados.first_name + ' '+dados.last_name, 
                    email: dados.email, 
                    telefone: dados.phone_number, 
                    plano: dados.plan_seller, 
                    status: dados.status,
                    created_at: dados.created_at, 
                    serasa: dados.serasa,
                    endereco, 
                });
            }
            else if(dados.type === 'business'){
                var owner = dados.owner;
                var endereco_owner = dados.owner.address;

                var endereco_empresa = endereco_owner.line1 + (endereco_owner.line2 !== null ? ', '+endereco_owner.line2 : '') +
                                    (endereco_owner.line3 !== null ? ', '+endereco_owner.line3 : '') + ' - ' +
                                    (endereco_owner.neighborhood !== null ? ''+endereco_owner.neighborhood : '') +
                                    (endereco_owner.city !== null ? ' - '+endereco_owner.city : '') +
                                    (endereco_owner.state !== null ? '/'+endereco_owner.state : '');

                var empresa = {
                    tipo_conta: 'Conta Empresarial',
                    nome_fantasia: dados.business_name,
                    email_empresa: dados.business_email,
                    descricao_empresa: dados.business_description,
                    telefone_empresa: dados.business_phone,
                };

                this.setState({
                    tipo_conta: 'Conta Empresarial',
                    nome_titular: owner.first_name + ' '+owner.last_name, 
                    email: owner.email, 
                    telefone: owner.phone_number, 
                    plano: dados.plan_seller, 
                    created_at: dados.created_at, 
                    endereco: endereco_empresa, 
                    status: dados.status,
                    serasa: dados.serasa,
                    empresa
                });
            }
            console.log(dados);

            var future_releases = [];
            if(dados.future_releases !== null){
                Object.keys(dados.future_releases).forEach((p) => {
                    future_releases.push({
                        date: p,
                        ...dados.future_releases[p]
                    })
                });
            }


            this.setState({
                id_zoop: dados.id,
                transactions: dados.transactions,
                transfers: dados.transfers,
                maquininhas: dados.maquininhas,
                prevention: dados.prevention,
                plan_seller: dados.plan_seller,
                account_balance: dados.account_balance,
                current_balance: dados.current_balance,
                bank_accounts: dados.bank_accounts,
                autorizacoes: dados.autorizacoes,
                compliance: dados.compliance,
                future_releases,
                loading_user: true
            });
        }
    }
    listData = [
        { name: 'Dados do Cliente', active: '1' },
        { name: 'Transações', active: '2' },
        { name: 'Transferências', active: '3' },
        { name: 'Prevenção e Risco', active: '4' },
        { name: 'Proximos Lançamentos', active: '5' },
        { name: 'Contas Bancárias', active: '6' },
        { name: 'Maquininhas', active: '7' },
        { name: 'Bureau de Crédito', active: '8' },
        { name: 'Validações de Cliente', active: '9' },
        { name: "Time de Compliance", active: '10' },
    ]

    changeVisible = (isVisible) => {
        this.setState({ isVisible });
        getUserAQPago(
            this.state.userId,
            this.callbackRows
        );
    }
    Navs = () => {
        return <Nav tabs>
            {this.listData.map((p, index) => {
                return <NavLink
                    key={index}
                    className={
                        classnames({ active: this.state.activeTab === p.active })}
                    onClick={() => { this.setState({ activeTab: p.active }); }}>
                    <span>{p.name}</span>
                </NavLink>
            })}
        </Nav>
    }

    editEmail = (email_edit_enable) => {
        this.setState({email_edit_enable, email_edit: ''})
    }

    telefoneEdit = (telefone_edit_enable) => {
        this.setState({telefone_edit_enable, telefone_edit: ''})
    }

    storeEmail = () => {
        this.setState({loading_edit: true})
        var url = ENDPOINT+`user/update_user_zoop/${this.state.documento}`;

        var formData = new FormData();
        formData.append('email', this.state.email_edit);
        formData.append('user_tipo', this.state.documento.length <= 11 ? 'titular' : 'responsavel');

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data.data;
            this.setState({
                email_edit_enable: false,
                email_edit: '',
                email: data.email,
                loading_edit: false
            })
        })
        .catch((error) => {
            var data = error.response.data;
            toast.error(data.errors);
            this.setState({
                loading_edit: false
            });
            return data;
        });
    }

    storeTelefone = () => {
        //var url = ENDPOINT+`user/update_user_zoop/${this.state.documento}`;
        var url = ENDPOINT+"user/update_user_zoop/"+this.state.documento;
        console.log(url);

        var formData = new FormData();
        formData.append('telefone', this.state.telefone_edit);
        formData.append('user_tipo', this.state.documento.length <= 11 ? 'titular' : 'responsavel');

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data.data;
            this.setState({
                telefone_edit_enable: false,
                telefone_edit: '',
                telefone: data.telefone,
                loading_edit: false
            })

            
            getUserAQPago(
                this.state.userId,
                this.callbackRows
            );
        })
        .catch((error) => {
            var data = error.response.data;
            toast.error(data.errors);
            this.setState({
                loading_edit: false
            });
            return data;
        });
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }
    onOpenClosedComplience = (visible_complience = false) => {
        this.setState({ visible_complience })
    }

    store_note = (compliance) => {
      this.setState({
          loading_compliance: true
      });
        var url = ENDPOINT+"complience/store_note";

        var formData = new FormData();
        formData.append('descricao', this.state.distract_nota);
        formData.append('id', compliance);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            toast.success(data.message);
            this.setState({
                distract_nota: '',
                is_open_nota: false,
                loading_compliance: false
            });
            
              getUserAQPago(
                  this.state.userId,
                  this.callbackRows
              );
            return data;
        })
        .catch((error) => {
          this.setState({
              loading_compliance: false
          });
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                toast.success(obj.errors);
                return false;
            }
            var data = error.response.data;
            toast.success(data.errors);
            return false;
        });
    }
    render() {
        return (
            <Div>
                <h3>Resumo de Cliente AQPago
                    <img width="40" src={aqpago} alt="logo-aqpago" style={{position: 'relative', marginLeft: '8px', filter: 'invert(1)', top: '-5px'}}/>
                </h3>
                <hr />
                <Card hidden={this.state.loading_user == true}>
                    <CardBody style={{height: '120px'}}>
                        <center>
                            <FadeLoader color={'#52c565'} margin={0} loading={50} size={60} />
                        </center>
                    </CardBody>
                </Card>
                <Card hidden={this.state.loading_user == false}>
                    <CardBody hidden={!getUser().permissao_gestor_aqpago}>
                        <this.Navs />
                        <TabContent activeTab={this.state.activeTab}>
                            <DataAQPago
                                active={'1'}
                                tipo_conta={this.state.tipo_conta}
                                nome_titular={this.state.nome_titular}
                                status={this.state.status}

                                email_edit={this.state.email_edit}
                                email_edit_enable={this.state.email_edit_enable}
                                editEmail={this.editEmail}
                                autorizacoes={this.state.autorizacoes}
                                
                                telefone_edit={this.state.telefone_edit}
                                telefone_edit_enable={this.state.telefone_edit_enable}
                                telefoneEdit={this.telefoneEdit}

                                email={this.state.email}
                                telefone={this.state.telefone}
                                documento={this.state.documento}
                                plano={this.state.plano}
                                created_at={this.state.created_at}
                                endereco={this.state.endereco}
                                empresa={this.state.empresa}

                                loading_edit={this.state.loading_edit}

                                handleChange={this.handleChange}
                                storeTelefone={this.storeTelefone}
                                storeEmail={this.storeEmail}
                            />
                            <TransacoesClient
                                active={'2'}
                                name={this.state.empresa !== null ? this.state.empresa.nome_fantasia : this.state.nome_titular}
                                data={this.state.transactions}
                            />
                            <TransferenciaClient
                                active={'3'}
                                name={this.state.empresa !== null ? this.state.empresa.nome_fantasia : this.state.nome_titular}
                                data={this.state.transfers}
                            />
                            <PrevencaoRiscoClient
                                active={'4'}
                                name={this.state.empresa !== null ? this.state.empresa.nome_fantasia : this.state.nome_titular}
                                data={this.state.prevention}
                            />
                            <ProximosLancamentos
                                active={'5'}
                                name={this.state.empresa !== null ? this.state.empresa.nome_fantasia : this.state.nome_titular}
                                data={this.state.future_releases}
                            />
                            <ContasBancariasClient
                                active={'6'}
                                name={this.state.empresa !== null ? this.state.empresa.nome_fantasia : this.state.nome_titular}
                                data={this.state.bank_accounts}
                            />
                            <TabPane tabId={"7"}>
                                <Card style={{border: 'none'}}>
                                    <CardBody>
                                        <h5>Maquininhas do cliente</h5>
                                        <hr/>
                                        <div>
                                            <CardColumns>
                                                {this.state.maquininhas.map(p => <Card>
                                                    <CardBody>
                                                        
                                                        {maquininhas_list.map(m => {
                                                            if(m.modelo.toUpperCase() == p.produto.toUpperCase()){
                                                                if(p.produto.toUpperCase() != 'LINKPAY' && p.produto.toUpperCase() != 'SHOPAQPAGO'){
                                                                    return <Row>
                                                                        <Col md={12} style={{textAlign: 'center'}}>
                                                                            <img src={m.image} height="100px"/>
                                                                        </Col>
                                                                        <Col md={12} style={{marginTop: 5}}>
                                                                            <p><b>Produto: </b>{m.label}</p>
                                                                            <p><b>Serie: </b>{p.serial}</p>
                                                                            <p><b>Chip: </b>{p.chip}</p>
                                                                            <p><b>NF: </b>{p.nf}</p>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                                else{
                                                                    return <Row>
                                                                        <Col md={12} style={{textAlign: 'center'}}>
                                                                            <img src={m.image} height="100px"/>
                                                                        </Col>
                                                                        <Col md={12} style={{marginTop: 5}}>
                                                                            <p><b>Produto: </b>{m.label}</p>
                                                                            <p><b>NF: </b>{p.nf}</p>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                            }
                                                        })}
                                                    </CardBody>
                                                </Card>)}
                                            </CardColumns>
                                            <hr/>
                                        </div>
                                    </CardBody>
                                </Card>
                            </TabPane>
                            <TabPane tabId={"8"}>
                                <Card style={{border: 'none'}}>
                                    <CardBody>
                                    <h5>Bureau de Crédito</h5>
                                    <hr/>
                                        {this.state.serasa.length == 0 ? <div>
                                            <Button 
                                                color="success"
                                                disabled={this.state.loading_serasa}
                                                onClick={() => {
                                                    this.setState({
                                                        loading_serasa: true
                                                    });

                                                    getSerasa(
                                                        getId(), 
                                                        this.callbackRows,
                                                        () => this.setState({loading_serasa: false })
                                                    )
                                                }}
                                            >
                                                Consultar Bureau
                                            </Button>
                                        </div> 
                                        : 
                                            <div>
                                                <Button 
                                                    color="success"
                                                    disabled={this.state.loading_serasa}
                                                    onClick={() => {
                                                        this.setState({
                                                            loading_serasa: true
                                                        });

                                                        getSerasaNew(
                                                            getId(), 
                                                            this.callbackRows,
                                                            () => this.setState({loading_serasa: false })
                                                        )
                                                    }}
                                                >
                                                    Nova Consulta Bureau
                                                </Button>
                                            </div>
                                        }
                                        <br/>
                                    <Row>
                                        {this.state.serasa.map(e => {
                                            var score = JSON.parse(e.consulta_serasa);
                                            var resumo = JSON.parse(e.resumo_serasa);
                                            return(
                                                <Col md={6}>
                                                    <Card>
                                                        <CardBody>
                                                            <ReactStoreIndicator
                                                                value={score.score.pontos}
                                                                maxValue={1000}
                                                                lineWidth={25}
                                                            />
                                                            <span style={{
                                                                position: 'absolute',
                                                                top: '4rem', 
                                                                width: '100%',
                                                                left: 0,
                                                                fontSize: '55px',
                                                                textAlign: 'center',
                                                                fontWeight: 'bold'
                                                            }}>
                                                                {score.score.classe}
                                                            </span>
                                                            <center>
                                                                <h5>{score.score.faixa.titulo}</h5>
                                                            </center>
                                                            <hr/>
                                                            <div>
                                                                <p>{score.score.faixa.descricao}</p>
                                                                <p><b>Data da Consulta:</b> {moment(new Date(e.created_at).toString()).format('DD/MM/YYYY HH:m')}</p>
                                                                <p>
                                                                    <b>Renda Presumida:</b>&nbsp;                                                         
                                                                    <NumberFormat 
                                                                        prefix="R$ " 
                                                                        displayType="text" 
                                                                        value={parseFloat(resumo.rendaPresumida)} 
                                                                        decimalSeparator=","/>
                                                                </p>
                                                                <p>
                                                                    <b>{resumo.debitos.sumQuantidade} Débito{resumo.debitos.sumQuantidade > 1 ? 's' : ''}:</b>&nbsp;                                                         
                                                                    <NumberFormat 
                                                                        prefix="R$ " 
                                                                        displayType="text" 
                                                                        value={parseFloat(resumo.debitos.sumValorTotal)} 
                                                                        decimalSeparator=","/>
                                                                </p>
                                                                <p>
                                                                    <b>{resumo.protestos.sumQuantidade} Protesto{resumo.protestos.sumQuantidade > 1 ? 's' : ''} :</b>&nbsp;                                                         
                                                                    <NumberFormat 
                                                                        prefix="R$ " 
                                                                        displayType="text" 
                                                                        value={parseFloat(resumo.protestos.sumValorTotal)} 
                                                                        decimalSeparator=","/>
                                                                </p>
                                                                {resumo.acoes ? 
                                                                    <p>
                                                                        <b>{resumo.acoes.sumQuantidade} Aç{resumo.acoes.sumQuantidade > 1 ? 'ões' : 'ão'}:</b>&nbsp;                                                         
                                                                        <NumberFormat 
                                                                            prefix="R$ " 
                                                                            displayType="text" 
                                                                            value={parseFloat(resumo.acoes.sumValorTotal)} 
                                                                            decimalSeparator=","/>
                                                                    </p>
                                                                    :
                                                                    <></>
                                                                }
                                                                <p>
                                                                    <b>{resumo.cheques.sumQuantidade} Cheque{resumo.cheques.sumQuantidade > 1 ? 's' : ''}</b>&nbsp;                                                         
                                                                </p>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                    </CardBody>
                                </Card>
                            </TabPane>
                            <TabPane  
                                tabId={'9'}>
                                    <div>
                                        <br/>
                                        <h5>Validações de Cliente</h5>
                                        <hr/>
                                        <Button color='success' onClick={() => this.changeVisible(true)}>Validar Cliente</Button>
                                        {this.state.autorizacoes ?
                                            <p>{this.state.autorizacoes.map(e => (
                                                <p>Token gerado em {moment(e.created_at).format('DD/MM/YYYY HH:mm') } com o status de {get_status_auth(e.status)}
                                                <br/><small>Gerado por: <b>{e.operador_name}</b></small></p>
                                            ))}</p>
                                            :
                                            null
                                        }
                                    </div>
                            </TabPane>
                            <TabPane  
                            tabId={'10'}>
                                <div>
                                    <br/>
                                    <h5>Time de Compliance</h5>
                                    <hr/>
                                    {this.state.compliance !== null ?
                                        <div>
                                            <p><b>Limite liberado On-line: </b>{parseFloat(this.state.compliance.limit_online).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                            <p><b>Limite liberado terminal POS: </b>{parseFloat(this.state.compliance.limit_pos).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                            <b>Distrato:</b>
                                            {this.state.compliance.documents.map(p => {
                                                return <Card style={{margin: 5}}>
                                                            <CardBody>
                                                                    <p>
                                                                        <b>Descrição: </b>{p.descricao}<br/>
                                                                        <b>Distrato:</b> {p.distract}<br/>
                                                                        <b>Enviado pelo Operador:</b> {p.auth_name}<br/>
                                                                        <hr/>
                                                                        <Button 
                                                                            color='success'
                                                                            onClick={() => window.open(p.url+'?access_token='+getToken(), '_blank')}
                                                                        >
                                                                            Abrir Documento
                                                                        </Button>
                                                                    </p>
                                                            </CardBody>
                                                        </Card>
                                            })}
                                            <br/>
                                            <Button color='info' hidden={this.state.is_open_nota} onClick={() => this.setState({ is_open_nota: true })}>Nova Nota</Button>
                                            <Collapse isOpen={this.state.is_open_nota}>
                                                <label htmlFor="for_desc">Nova nota</label>
                                                <Input
                                                    name={"distract_nota"}
                                                    placeholder="Descrição da Nota"
                                                    type="textarea"
                                                    value={this.state.distract_nota}
                                                    style={{
                                                        height: '124px',
                                                        resize: 'none'
                                                    }}
                                                    onChange={(event) => {this.handleChange(event)}}
                                                />
                                                <ButtonGroup>
                                                    <Button color='info' onClick={() => this.setState({ is_open_nota: false, distract_nota: '' })}>Cancelar</Button>
                                                    <Button color='success' disabled={this.state.loading_compliance} onClick={() => this.store_note(this.state.compliance.id)}>Salvar</Button>
                                                </ButtonGroup>
                                            </Collapse>
                                            <br/>
                                            <br/>
                                            {this.state.compliance.notas_privadas.map(e => (
                                                <p><b>{moment(e.created_at).format('DD/MM/YYYY HH:mm')}</b> {e.descricao}</p>
                                            ))}
                                            <Button color='info' onClick={() => this.onOpenClosedComplience(true)}>Alterar</Button>
                                            <ModalComplience
                                                visible={this.state.visible_complience}
                                                onClose={() => this.onOpenClosedComplience()}
                                                onRefresh={() => getUserAQPago(this.state.userId,this.callbackRows)}
                                                document={this.state.documento}
                                            />
                                        </div>
                                    : <div>
                                        <p><b>Limite liberado On-line: </b>{parseFloat(0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                        <p><b>Limite liberado terminal POS: </b>{parseFloat(0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                            <Button color='info' onClick={() => this.onOpenClosedComplience(true)}>Alterar</Button>
                                            <ModalComplience
                                                visible={this.state.visible_complience}
                                                onClose={() => this.onOpenClosedComplience()}
                                                onRefresh={() => getUserAQPago(this.state.userId,this.callbackRows)}
                                                document={this.state.documento}
                                            />
                                    </div> }
                                </div>
                            </TabPane>
                        </TabContent>
                        {/* <AddCreditCard user_id={this.state.userId}/> */}
                    </CardBody>
                </Card>
                <ModalValidacaoToken
                    user={{
                        id: this.state.userId   
                    }}
                    tipo_cliente="crm_aqpago"
                    visible={this.state.isVisible}
                    onClose={() => this.setState({ isVisible: false })}
                />
                <Row>
                </Row>
                <br />
            </Div>
        )
    }
}