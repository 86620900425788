import React, { Component } from "react";
import { 
    Button, 
    Form, 
    FormGroup, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader, 
    FormFeedback 
} from "reactstrap";

import ModelReturn from "../ReturnsApiModals/ModelReturn";
import { InputText } from '../../InputText/InputText';
import { alterMyPassword } from "../../../auth/services";

class ModalNewPassword extends Component {
    constructor(props){
        super(props);
        this.state = {
            new_password: '',
            new_password_invalid: false,
            repeat_password: '',
            repeat_password_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        }
        this.onRegister = this.onRegister.bind(this);
        this.onClose = this.onClose.bind(this);
    }
    componentWillUpdate(nextProps, nextState){

    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({[name]: value});
    }
    validateFields(){
        var invalid = 0;

        //Validar senha
        if(this.state.new_password.length > 6){
            this.setState({new_password_invalid: false})
        }
        else{
            invalid++;
            this.setState({new_password_invalid: true})
        }

        if(this.state.repeat_password.length > 6){
            this.setState({repeat_password_invalid: false})
        }
        else{
            invalid++;
            this.setState({repeat_password_invalid: true})
        }

        if(this.state.new_password !== this.state.repeat_password){
            invalid++;
            this.setState({repeat_password_invalid: true})
        }
        else{
            this.setState({repeat_password_invalid: false})
        }

        return invalid;

    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    onRegister() {
        if(this.validateFields() === 0){
            this.setState({loading: true});
            alterMyPassword(this.state, this.onData.bind(this));
        }
    }
    onData(data){
        
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onClose(){
        this.setState({
            new_password: '',
            new_password_invalid: false,
            repeat_password: '',
            repeat_password_invalid: false,
            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose(false);
    }
    onEnter = (evt) => {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        if(key === 13){
            this.onRegister();
        }
     }
    render(){
        return(
            <Modal isOpen={this.props.visible}>
                <ModalHeader>
                    Alterar Senha
                </ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <Form>
                        <FormGroup>
                            <InputText
                                    name="new_password"
                                    type="password"
                                    placeholder="Nova Senha"
                                    invalid={this.state.new_password_invalid}
                                    value={this.state.new_password}
                                    onChange={this.handleChange}
                                    onKeyPress={this.onEnter}/>
                            <FormFeedback>A Senha deve ter no minimo 6 caracteres</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <InputText 
                                placeholder="Repetir Senha"
                                name="repeat_password"
                                type="password"
                                invalid={this.state.repeat_password_invalid}
                                value={this.state.repeat_password}
                                onChange={this.handleChange}
                                onKeyPress={this.onEnter}/>
                            <FormFeedback>Senhas divergentes</FormFeedback>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalBody 
                    style={{
                        minHeight: '114px', 
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn 
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                        />
                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose(false)}>Cancelar</Button>
                    <Button color="success" onClick={this.onRegister}>Alterar Senha</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
export default ModalNewPassword;