import React from 'react';
import { ImFileEmpty } from 'react-icons/im';
import { Col, Row } from "reactstrap";
import Moment from 'moment';
import { BiTransferAlt } from 'react-icons/bi';

/* function creditsAproved(data) {
    var array = [];
    data.forEach(e => {
        if(e.pagamento!== null){
            if(e.pagamento.status === "APROVADO"){
                array.push(e);
            }
        }
    });
    return array;
}

const payment = (payment_type) => {
    switch(payment_type){
        case 'credit' : return 'Crédito';
        case 'boleto' : return 'Boleto';
        default: return payment_type
    }
} */

const status_transacao = (payment_type) => {
    switch(payment_type){
        case 'succeeded' : return 'Aprovada';
        case 'canceled' : return 'Cancelada';
        case 'pending' : return 'Pendente';
        case 'failed' : return 'Falhada';
        default: return payment_type
    }
}
const Transferencia = (props) => {
    var isData = props.data;
    return (
        <>
            <div hidden={props.data.length === 0}>
                <Row style={{fontSize: '14px', paddingLeft: 16}}>
                    <Col md={1} className="d-none d-md-block">
                        
                    </Col>
                    <Col md={2} className="d-none d-md-block" style={{alignSelf: 'center'}}>
                        <b style={{fontWeight: 500}}>Data</b>
                    </Col>
                    <Col md={2} className="d-none d-md-block">
                        <b style={{fontWeight: 500}}>Status</b>
                    </Col>
                    <Col md={4} className="d-none d-md-block">
                        <b style={{fontWeight: 500}}>Numero de transferência</b>
                    </Col>
                    <Col md={2} className="d-none d-md-block">
                        <b style={{fontWeight: 500}}>Valor</b>
                    </Col>
                </Row>
                <hr className="d-none d-md-block"/>
            </div>
            {isData.map(p => {
                return (
                    <>
                    <Row style={{fontSize: '14px', paddingLeft: 16}}>
                        <Col md={1}>
                            <BiTransferAlt style={{ width: '41px', height: '41px' }}/>
                        </Col>
                        <Col md={2} style={{alignSelf: 'center'}}>
                            <p style={{ marginBottom: 0 }}>{Moment(new Date(p.created_at).toDateString()).format('DD/MM/YYYY')}</p>
                        </Col>
                        <Col md={2}>
                            <p style={{ marginBottom: 0 }}>{status_transacao(p.status)}</p>
                        </Col>
                        <Col md={4}>
                            <p style={{ marginBottom: 0 }}>{p.transfer_number !== null ? p.transfer_number : 'Sem Informação'}</p>
                        </Col>
                        <Col md={2}>
                            <p style={{ marginBottom: 0 }}>{p.amount}</p>
                        </Col>
                    </Row>
                    <hr/>
                    </>
                )
            })}
            {props.data.length === 0 ?
            <div>
                <center>
                    <ImFileEmpty style={{width: '50px', height: '50px'}} /><br/>
                    Não há histórico de transferências
                </center>
            </div>:<></>}
        </>
    )
}

export default Transferencia;