import moment from 'moment';
import React, { Component } from 'react';
import { Card, CardBody, Button, Input, FormGroup, Collapse, Form } from "reactstrap";
import CupomModal from '../../components/Modals/CupomModals/CupomModal';
import { getRows, getRowsPerPage } from '../../components/Tables/TableFaturas/propsFaturaAQContabil';
import PropagateLoader from "react-spinners/PropagateLoader";
import { toast } from 'react-toastify';
import axios from 'axios';
import { getToken } from '../../auth/set_services';
import { ENDPOINT } from '../../auth/endpoint';
import { TableContabilidade } from '../../components/Tables/TableContabilidade/TableContabilidade';
import { TableFaturas } from '../../components/Tables/TableFaturas/TableFaturas';
import { TableFaturaAQContabil } from '../../components/Tables/TableFaturas/TableFaturaAQContabil';

class PosPagoAQContabil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_cupom: false,
            search: '',
            document: '',
            is_cnpj: false,
            rowData: [],
            relatorio: [],
            status: null,
            plano: null,
            forma_pagamento: null,
            status_fatura: null,
            totalPage: 0,
            activePage: 1,
            cod_cliente: '',
            motivo_cancelamento: '',
            abater_fatura_show: false,
            motivo_abatimento: '',
            status_pagamento: null,
            cod_fatura: '',
            pending: false,
            filter: false,
            loading_table: true,
            cancela_fatura_show: false,
            date_from: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            date_to: moment(new Date().toDateString()).format('YYYY-MM-DD'),
            status_envio: null,
            loading: false,
            status_envio: null,
            error_message: '',
            success_message: '',
        }
    }

    gerarFatura = (id) => {
        var url = ENDPOINT + 'faturas_aqcontabil/gerar-lembrete';

        var formData = new FormData();
        formData.append('id', id);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                toast.success(data.message);
                getRows(this.state, this.callbackRows.bind(this));
                return data;
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    toast.error(obj.errors);
                    return obj;
                }
                var data = error.response.data;
                toast.error(data.errors);
                return data;
            });
    }

    onChangeSelect = (name, value) => {
        var state = this.state;
        state[name] = value;
        this.setState({ ...state });

        getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
    }


    handleChange = (event) => {

        var state = this.state;
        let name = event.target.name;
        let value = event.target.value;

        if (name === 'document') {
            if (value.replace(/([^\d])+/gim, '').length > 10) {
                state[name] = value;
                state.activePage = 1;
                state.rowData = [];
                state.loading_table = true;
                this.setState({ ...state });

                getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
            }
            else if (value.replace(/([^\d])+/gim, '').length === 0) {
                if (state[name].replace(/([^\d])+/gim, '').length > 0) {
                    state[name] = value;
                    state.activePage = 1;
                    state.rowData = [];
                    state.loading_table = true;
                    this.setState({ ...state });

                    getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
                }
            }
        }
        else {
            state[name] = value;
            state.activePage = 1;
            state.rowData = [];
            state.loading_table = true;
            this.setState({ ...state });

            getRowsPerPage(1, state.search, this.state.filter, state, this.callbackRows.bind(this));
        }
    }

    componentDidMount() {
        getRows(this.state, this.callbackRows.bind(this));
    }
    callbackRows = (rowData, activePage, totalPage) => {
        this.setState({ rowData, activePage, totalPage, loading_table: false })
    }

    onFilter = (filter) => {
        this.setState({ filter });
        getRowsPerPage(1, this.state.search, filter, this.state, this.callbackRows.bind(this));
    }

    onPageChange = (activePage) => {
        this.setState({ activePage, loading_table: true, rowData: [] });
        getRowsPerPage(activePage, this.state.search, this.state.filter, this.state, this.callbackRows.bind(this));
    }
    onSearchDocument() {
        this.setState({ is_cnpj: !this.state.is_cnpj })
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    onData = (data) => {
        if (data.success === true) {
            toast.success(data.message);
        }
        else {
            toast.error(data.errors);
        }
    }
    onRefazer = (id) => {
        var url = ENDPOINT + "aqcontabil-admin/refazer_fatura/" + id;

        var formData = new FormData();
        formData.append('motivo_cancelamento', this.state.motivo_refazer);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                this.onData(data);
                getRows(this.state, this.callbackRows.bind(this));
                this.setState({
                    motivo_cancelamento: '',
                    cancela_fatura_show: false
                })
                return data;
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    this.onData(obj);
                }
                var data = error.response.data;
                this.onData(data);
            });
    }
    onAprovved = (id) => {
        var url = ENDPOINT + "aqcontabil-admin/cancelar_fatura/" + id;

        var formData = new FormData();
        formData.append('motivo_cancelamento', this.state.motivo_cancelamento);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                this.onData(data);
                getRows(this.state, this.callbackRows.bind(this));
                this.setState({
                    motivo_cancelamento: '',
                    cancela_fatura_show: false
                })
                return data;
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    this.onData(obj);
                }
                var data = error.response.data;
                this.onData(data);
            });
    }
    onAbater = (id) => {
        var url = ENDPOINT + "aqcontabil-admin/abater_fatura/" + id;

        var formData = new FormData();
        formData.append('motivo_abatimento', this.state.motivo_abatimento);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                this.onData(data);
                getRows(this.state, this.callbackRows.bind(this));
                this.setState({
                    motivo_abatimento: '',
                    abater_fatura_show: false
                })
                return data;
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    this.onData(obj);
                }
                var data = error.response.data;
                this.onData(data);
            });
    }
    ExpandableRows = ({ data }) => {
        return <div style={{ margin: '20px' }}>
            <h4>Fatura {data.status} - #{data.id}</h4>
            <hr />
            <p>{moment(data.created_at).format('DD/MM/YYYY')}</p>
            <p><b>Valor:</b> {parseFloat(data.valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
            <p><b>Nome: </b>{data.client_name}</p>
            <p><b>Documento (CPF/CNPJ):</b> {data.cpf_cpnj}</p>
            <p><b>Código da fatura:</b> {data.id}</p>
            <p><b>Parcela da fatura:</b> {data.numero_parcela}x</p>
            <p><b>Vencimento da Fatura:</b> {moment(data.vencimento).format('DD/MM/YYYY')}</p>
            <p><b>Proxima Fatura:</b> {moment(data.proxima_fatura).format('DD/MM/YYYY')}</p>
            {/* {data.pagamento ? */}
            <p><b>URL da Fatura:</b> <a href={data.url} target="_blank">Abrir Fatura</a> </p>
            {data.notas ?
                <>
                    {data.notas.map(e => <p>
                        <b>{moment(e.created_at).format('DD/MM/YYYY')}</b> - {e.descricao}
                    </p>)}
                </>
                :
                <></>}
            <div hidden={data.status != 'PENDENTE'}>
                <div style={{ display: 'inline' }}>
                    <Button style={{ margin: '2px' }} color='success' onClick={() => this.enviarLembrete(data.id)}>Re-enviar fatura</Button>
                    <Button style={{ margin: '2px' }} color='dark' onClick={() => this.setState({ refazer_fatura_show: true })}>Refazer fatura</Button>
                    <Button style={{ margin: '2px' }} color='danger' onClick={() => this.setState({ cancela_fatura_show: true })}>Cancelar fatura</Button>
                </div>
                <Collapse isOpen={this.state.refazer_fatura_show}>
                    <br />
                    <Form>
                        <FormGroup>
                            <Input
                                type='textarea'
                                placeholder="Motivo para refazer da fatura"
                                name="motivo_refazer"
                                value={this.state.motivo_refazer}
                                onChange={this.handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button style={{ margin: '2px' }} color='success' onClick={() => this.onRefazer(data.id)}>Salvar</Button>
                            <Button style={{ margin: '2px' }} color='danger' onClick={() => this.setState({ refazer_fatura_show: false })}>Cancelar</Button>
                        </FormGroup>
                    </Form>
                </Collapse>
                <Collapse isOpen={this.state.cancela_fatura_show}>
                    <br />
                    <Form>
                        <FormGroup>
                            <Input
                                type='textarea'
                                placeholder="Motivo do cancelamento da fatura"
                                name="motivo_cancelamento"
                                value={this.state.motivo_cancelamento}
                                onChange={this.handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button style={{ margin: '2px' }} color='success' onClick={() => this.onAprovved(data.id)}>Salvar</Button>
                            <Button style={{ margin: '2px' }} color='danger' onClick={() => this.setState({ cancela_fatura_show: false })}>Cancelar</Button>
                        </FormGroup>
                    </Form>
                </Collapse>
            </div>
            <div hidden={data.status != 'PENDENTE'}>
                <div style={{ display: 'inline' }}>
                    <Button style={{ margin: '2px' }} color='info' onClick={() => this.setState({ abater_fatura_show: true })}>Abater fatura</Button>
                </div>
                <Collapse isOpen={this.state.abater_fatura_show}>
                    <br />
                    <Form>
                        <FormGroup>
                            <Input
                                type='textarea'
                                placeholder="Motivo do abatimento da fatura"
                                name="motivo_abatimento"
                                value={this.state.motivo_abatimento}
                                onChange={this.handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button style={{ margin: '2px' }} color='success' onClick={() => this.onAbater(data.id)}>Salvar</Button>
                            <Button style={{ margin: '2px' }} color='danger' onClick={() => this.setState({ abater_fatura_show: false })}>Cancelar</Button>
                        </FormGroup>
                    </Form>
                </Collapse>
            </div>
            {/* :
                <>
                    <Button 
                        onClick={() => this.gerarFatura(data.id)} 
                        color='primary'
                        hidden={data.status != 'fechada'}
                    >
                        Gerar Fatura
                    </Button>
                </>}
                {data.status_envio == 'correios' ?
                    <>
                        <p>Envio via Correios</p>
                        <Input 
                            type='select'
                            value={data.status_fatura_envio}
                            onChange={(e) => this.updateStatusFatura(data.id, e.target.value)}
                        >
                            <option value="aguardando_envio">Fatura aguardando envio</option>
                            <option value="enviado">Fatura enviada</option>
                            <option value="entregue">Fatura entregue</option>
                        </Input>
                    </>
                    :
                    <p>Envio por E-mail</p>
                }
                <br/><br/>
                <Button 
                    color='success'
                    disabled={data.status != 'fechada'}
                    onClick={() => this.enviarLembrete(data.id)} 
                    style={{margin: 0}}>
                        Enviar Lembrete
                </Button>
                <br/><br/>
                <Button 
                    color='warning'
                    onClick={() => this.bloquearTaggys(data.id)} 
                    style={{margin: 0}}>
                        Bloquear Taggys
                </Button> */}
        </div>
    }


    enviarLembrete = (id) => {
        var url = ENDPOINT + 'aqcontabil-admin/reenviar_fatura/' + id;

        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                toast.success(data.message);
                return data;
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    toast.error(obj.errors);
                    return obj;
                }
                var data = error.response.data;
                toast.error(data.errors);
                return data;
            });
    }
    handleStatusSelectChange = status_pagamento => {
        var state = this.state;
        state['status_pagamento'] = status_pagamento;
        state['activePage'] = 1;
        state.loading_table = true;
        this.setState({ ...state });
        getRowsPerPage(1, this.state.search, this.state.filter, state, this.callbackRows.bind(this));
    };

    render() {
        return (
            <div>
                <h3>Faturas AQContabil</h3>
                <hr />
                <Card >
                    <TableFaturaAQContabil
                        relatorio={this.state.relatorio}
                        isSearch={this.handleChange}
                        is_cnpj={this.state.is_cnpj}
                        onChangeSelect={this.onChangeSelect}
                        state={this.state}
                        onSearchDocument={this.onSearchDocument.bind(this)}
                        handleStatusSelectChange={this.handleStatusSelectChange}
                        data={this.state.rowData}
                        onFilter={this.onFilter.bind(this)}
                        onPageChange={this.onPageChange.bind(this)}
                        filter={this.state.filter}
                        noDataComponent={this.state.loading_table === true ?
                            <Card style={{ border: 0, padding: '10vh' }}><CardBody><div><div className="sweet-loading">
                                <PropagateLoader color={'#52c565'} loading={20} size={30} />
                            </div></div></CardBody></Card> :
                            <Card style={{ border: 0 }}><CardBody><div>
                                Nenhuma fatura encontrada
                            </div></CardBody></Card>
                        }
                        pending={this.state.pending}
                        expandableRowsComponent={<this.ExpandableRows />}
                        activePage={this.state.activePage}
                        totalPage={this.state.totalPage}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                    />
                </Card>
            </div>
        )
    }
}

export default PosPagoAQContabil;