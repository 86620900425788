import axios from "axios";
import React, { Component } from "react";
import { Button, Col, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import Select from "react-select";
import { InputText } from "../../InputText/InputText";
import ModelReturn from "../ReturnsApiModals/ModelReturn";

var CurrencyInput = require('react-currency-masked-input');

class ModalDesconto extends Component{
    constructor(props){
        super(props);
        this.state = {
            valor: '',
            valor_invalid: false,
            mes_ano: '',
            mes_ano_invalid: false,
            motivo: '',
            tipo_desconto: 'desconto',
            loading: false,
            error_message: '',
            success_message: '',
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        
        this.setState({[name]: value});
    }

    storeUser = () => {
        var url = ENDPOINT+"aqcontabil-admin/aplicar_desconto";
        //formData.append('telefone', this.state.telefone);
        var formData = new FormData();
        formData.append('contabilidade_id', this.props.contabilidade_id);
        formData.append('valor', this.state.valor);
        formData.append('motivo', this.state.motivo);
        formData.append('tipo', this.state.tipo_desconto);
        formData.append('mes', this.separarMes());
        formData.append('ano', this.separarAno());

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            this.onData(data);
            //this.props.onRefresh();
            return data;
        })
        .catch((error) => {
            console.log(error)
            var data = error.response.data;
            this.onData(data);
            return data;
        });
    }

    onData = (data) => {
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onRegister = () => {
        if(this.validateFields() === 0){
            this.setState({loading: true});
            this.storeUser();
        }
    }
    validateFields = () => {
        var invalids = 0;
        if(this.state.valor > 0){
            this.setState({valor_invalid: false})
        }
        else{
            invalids++;
            this.setState({valor_invalid: true})
        }

        if(this.state.mes_ano !== null){
            this.setState({mes_ano_invalid: false})
        }
        else{
            invalids++;
            this.setState({mes_ano_invalid: true})
        }

        if(this.state.type !== null){
            this.setState({type_invalid: false})
        }
        else{
            invalids++;
            this.setState({type_invalid: true})
        }

        return invalids
    }
    onClose = () => {
        this.setState({
            valor: '',
            valor_invalid: false,
            mes_ano: '',
            mes_ano_invalid: false,
            motivo: '',
            tipo_desconto: 'desconto',
            loading: false,
            error_message: '',
            success_message: '',
        });
        this.props.onClose(false);
    }

    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    handleSelect = (name, type) => {
        this.setState({[name]: type})
    }

    separarAno() {
        let [ano, mes] = this.state.mes_ano.split('-');
        return ano;
    }
    separarMes() {
        let [ano, mes] = this.state.mes_ano.split('-');
        return mes;
    }

    onValor = (event) => {
        var valor_current = event.target.value;
        if (valor_current.length > 1) {
        valor_current = valor_current.replaceAll('.', '');
        var digits = valor_current.substr(valor_current.length - 2);
        valor_current = valor_current.slice(0, -2);
        valor_current = valor_current + '.' + digits;
        }

        var valido = /^[\d,.?!]+$/.test(valor_current);
        if (valido === true || valor_current === '') {
            this.setState({ valor: valor_current });
        }
    };
    render(){
        return (
            <Modal isOpen={this.props.visible}>
                <ModalHeader>Aplicar desconto ou inserir taxa</ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <div style={{display: 'inline'}}>
                                    <hr/>
                                    <input type="radio" checked={this.state.tipo_desconto === 'desconto'} name="desconto_tipo" onChange={() => this.setState({tipo_desconto: 'desconto'})}/>&nbsp;Desconto&nbsp;&nbsp;
                                    <input type="radio" checked={this.state.tipo_desconto === 'taxa'} name="desconto_tipo" onChange={() => this.setState({tipo_desconto: 'taxa'})}/>&nbsp;Taxa&nbsp;
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Valor: </b> </h6>
                                <CurrencyInput 
                                    inputMode="tel"
                                    className="form-control" 
                                    currency="BRL"
                                    name="valor"
                                    defaultValue={'0.00'}
                                    onChange={this.onValor}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <h6><b>Para a fatura do mes/ano: </b> </h6>
                                <InputText 
                                    name="mes_ano"
                                    type="month"
                                    value={this.state.mes_ano}
                                    placeholder="Insira o mês/ano da fatura a ser descontada ou taxada"
                                    invalid={this.state.mes_ano_invalid}
                                    onChange={this.handleChange}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <FormGroup>
                            <Input
                                type='textarea'
                                placeholder='Descrição do Motivo'
                                value={this.state.motivo}
                                name="motivo"
                                onChange={this.handleChange}
                            />
                        </FormGroup>
                    </Col>
                    </Row>
                </ModalBody>
                <ModalBody 
                    style={{
                        minHeight: '114px', 
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn 
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                        />
                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose()}>Cancelar</Button>
                    <Button color="success" onClick={() => this.onRegister()}>Registrar</Button>
                </ModalFooter>
            </Modal>
        )
    }

}

export default ModalDesconto;