import axios from "axios";
import React, { Component } from "react";
import { MdArrowBack, MdCheck, MdClear, MdMail, MdWarning, MdAttachFile } from "react-icons/md";
import { BsLock, BsUnlock } from 'react-icons/bs';
import { IoReceiptOutline } from 'react-icons/io5';
import { Button, ButtonGroup, Card, CardBody, CardFooter, Col, Collapse, Form, FormFeedback, FormGroup, Input, Row } from "reactstrap";
import Dropzone from 'react-dropzone';
import styled from "styled-components";
import { ENDPOINT } from "../../auth/endpoint";
import { getPermissoes, getToken } from "../../auth/set_services";
import Select from "react-select";
import { list_prevencao_status, list_status_pagamentos } from "../../auth/listas";
import { InputMasked } from '../../components/InputText/InputMasked';
import { InputText } from "../../components/InputText/InputText";
import { toast } from 'react-toastify';
import PropagateLoader from "react-spinners/PropagateLoader";
import ClipLoader from 'react-spinners/ClipLoader'
import moment from "moment";
import NumberFormat from "react-number-format";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import sanitizeHtml from 'sanitize-html';
import ReactTooltip from "react-tooltip";
import GoogleMapReact from 'google-map-react';
import DataTable from 'react-data-table-component';
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { FaMapMarkerAlt, FaPen } from "react-icons/fa";
import Pusher from 'pusher-js';
import { ButtonRed } from "../../components/ButtonStylish/ButtonRed";
import { DropdownIcon } from "../../components/DropdownIcon/DropdownIcon";
import SendEmailByCard from "../../components/Modals/CreditCard/SendEmailByCard";
import { MessageNoTable } from "../MessageTable/MessageNoTable";

var CurrencyInput = require('react-currency-masked-input');


const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
    .reclamado {
        display: none;
    }
    .row {
        margin: 10px 0px;
    }
    .btn-save {
        float: right;
    }
    .btn-nota {
        margin-top: 10px;
    }
    .file-iframe {
        width: 100%;
        height: 800px; 
    }
    .btn-arquivo {
        margin-right: 10px;
        color: #fff;
        border: 0px;
    }
    .h3 {
        margin-top: 10px !important;
        font-weight: 700;
    }
    .required-input {
        color: red;
        font-size: 12px;
        padding: 0.2rem 0.5rem;
        display: none;
    }
`;

const estados_options = [
    {"label": "Acre", "value": "AC"},
    {"label": "Alagoas", "value": "AL"},
    {"label": "Amapá", "value": "AP"},
    {"label": "Amazonas", "value": "AM"},
    {"label": "Bahia", "value": "BA"},
    {"label": "Ceará", "value": "CE"},
    {"label": "Distrito Federal", "value": "DF"},
    {"label": "Espírito Santo", "value": "ES"},
    {"label": "Goiás", "value": "GO"},
    {"label": "Maranhão", "value": "MA"},
    {"label": "Mato Grosso", "value": "MT"},
    {"label": "Mato Grosso do Sul", "value": "MS"},
    {"label": "Minas Gerais", "value": "MG"},
    {"label": "Pará", "value": "PA"},
    {"label": "Paraíba", "value": "PB"},
    {"label": "Paraná", "value": "PR"},
    {"label": "Pernambuco", "value": "PE"},
    {"label": "Piauí", "value": "PI"},
    {"label": "Rio de Janeiro", "value": "RJ"},
    {"label": "Rio Grande do Norte", "value": "RN"},
    {"label": "Rio Grande do Sul", "value": "RS"},
    {"label": "Rondônia", "value": "RO"},
    {"label": "Roraima", "value": "RR"},
    {"label": "Santa Catarina", "value": "SC"},
    {"label": "São Paulo", "value": "SP"},
    {"label": "Sergipe", "value": "SE"},
    {"label": "Tocantins", "value": "TO"}
];

const tipoPessoaOptions = [
    { value: 'F', label: 'Pessoa Física'},
    { value: 'J', label: 'Pessoa Jurídica'},
    { value: 'E', label: 'Estrangeiro'},
];

const getId = () => {
    return window.location.pathname.replace('/juridico-detalhes/', '')
}

const optionsYesNo = [
    { value: '1', label: 'Sim'},
    { value: '0', label: 'Não'},
];
const tipoFornecedorOptions = [
    { value: '1', label: 'Serviços'},
    { value: '2', label: 'Produtos'},
    { value: '3', label: 'Sistemas SAAS'},
    { value: '4', label: 'Cloud'},
];

const DropArea = styled.div`
    border-style: dashed;
    border-color: #38B76E;
    padding: 1%;
    cursor: pointer;
    &:drag {
        opacity: 0.5;
    }
`;

const ButtonUpload = styled.button`
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0;
    padding-left: 7px;
    padding-right: 7px;
    top: -1px;
    position: relative;
`;

class ResumoContasPagar extends Component {
    constructor(props){
        super(props);
        this.state = {
            show_form: true,
            loading_page: false,
            loading_button: false,
            fornecedor: false,

            documento: null,
            nome: null,
            nome_fantasia: null,
            descricao: null,
            vencimento: null,
            valor: null,
            data_emissao: null,
            select_forma: null,
            historico: null,
            total_ocorrencias: 1,

            tipo_pessoa: null,
            tipo_pessoa_select: [],            
            tipo_fornecedor: null,
            tipo_fornecedor_select: [],
            status_pagamento_select: {label: 'Pendente', value: 'pendente'},
            status_pagamento: 'pendente',

            notas: [],
            notas_privadas: [],
            optionsFornecedores: [],
            optionsCategorias: [],
            fornecedor_select: [],
            tipo_custo_select: [],
            ocorrencia: [],
            ocorrencia_select: [],
            categoria: [],
            categoria_select: [],
            options_forma_pagamento: [],

            options_ocorrencias: [
                {'label': 'Mensal', 'value': 'M'},
                {'label': 'Trimestral', 'value': 'T'},
                {'label': 'Semestral', 'value': 'S'},
                {'label': 'Anual', 'value': 'A'},
            ],
            file: {
                anexo_conta: [],
            },
        }

        this.inputFileAnexo = React.createRef();
        this.inputFornecedor = React.createRef();
        this.inputDescricao = React.createRef();
        this.inputVencimento = React.createRef();
        this.inputValor = React.createRef();
        this.inputDataEmissao = React.createRef();
        this.inputFormaPagamento = React.createRef();
        this.inputCategoria = React.createRef();
        this.inputStatusPagamento = React.createRef();
        this.inputTipoCusto = React.createRef();
        this.inputOcorrencia = React.createRef();
        this.inputTotalOcorrencia = React.createRef();
    }

    componentDidMount(){

        this.handleLoadFonercedores()
        this.handleCategorias()
        this.handleLoadFormasPagamentoData()
        /**
         * Construct functions
         */
        if (getId() !== 'novo') {
            // this.handleLoadDataProcesso();
        } else {
            /** Load Data */
        }
    }

    handleCategorias = () => {
        var url = ENDPOINT + "financeiro/categorias/despesas";

        console.log(url)
        console.log(getToken())

        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
    
        var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {

            console.log(p)

            let state = this.state;
            let opCategorias = [];

            if (p.success) {
                p.data.map((categoria, i) => {
                    opCategorias.push({
                        'label': categoria.label.toUpperCase(),
                        'value': categoria.value,
                    })
                })
            }

            state.optionsCategorias = opCategorias;
            this.setState({...state})
        })
        .catch(function (error) {
            toast.error('Ocorreu um erro no servidor!');

            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              //console.log(error.response.status); // status code
              //console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              //console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
        })
    }
    handleLoadFonercedores = () => {
        var url = ENDPOINT + "financeiro/fonercedores/lista";

        //console.log(getToken())

        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
    
        var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            let state = this.state;
            let opFornecedores = [];

            if (p.success) {
                p.data.map((fornecedor, i) => {
                    
                    opFornecedores.push({
                        'label': fornecedor.nome.toUpperCase(),
                        'value': fornecedor.id,
                    })


                })

            }

            state.optionsFornecedores = opFornecedores;

            this.setState({...state})
        })
        .catch((error) => {
            // this.setState({loading_seller_data: false})
            toast.error('Ocorreu um erro no servidor!');
        })
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    handleLoadFormasPagamentoData = () => {
        var url = ENDPOINT + "financeiro/formapagamento";

        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + getToken());
    
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            console.log(p)
            let listFormasPagamento = [];

            p.data.map((field, i) => {
                listFormasPagamento.push({
                    label: field.tipo_pagamento.toUpperCase(),
                    value: field.id,
                })
            })
            
            this.setState({options_forma_pagamento: listFormasPagamento})
        })
        .catch((error) => {
            this.setState({loading_seller_data: false})
            toast.error('Ocorreu um erro no servidor!');
        })

    }
    
    onDropAnexo = (file) => {
        var state = this.state;
        file.forEach(file_current => {
            state.file.anexo_conta.push(file_current);
        });
        this.setState({
            ...state
        })
    }

    handleFileAnexoChange = (event) => {
        const files = event.target.files;

        var state = this.state;
        Array.from(files).forEach(file_current => {
            state.file.anexo_conta.push(file_current);
        });
        this.setState({
            ...state
        })
    }

    removeAnexoAttach = (index) => {
        var state = this.state;
        state.file.deposito_judicial_anexo.splice(index, 1);
        this.setState({
            ...state
        })
    }

    handleValidForm = () => {
        let formValid = true;

        if (!this.state?.fornecedor) {
            this.inputFornecedor.current.style.display = 'block'
            formValid = false;
        } else {
            this.inputFornecedor.current.style.display = 'none'
        }

        if (!this.state?.descricao) {
            this.inputDescricao.current.style.display = 'block'
            formValid = false;
        } else {
            this.inputDescricao.current.style.display = 'none'
        }

        if (!this.state?.vencimento) {
            this.inputVencimento.current.style.display = 'block'
            formValid = false;
        } else {
            this.inputVencimento.current.style.display = 'none'
        }

        if (!this.state?.valor) {
            this.inputValor.current.style.display = 'block'
            formValid = false;
        } else {
            this.inputValor.current.style.display = 'none'
        }

        if (!this.state?.status_pagamento) {
            this.inputStatusPagamento.current.style.display = 'block'
            formValid = false;
        } else {
            this.inputStatusPagamento.current.style.display = 'none'
        }

        if (!this.state?.tipo_custo_select?.value) {
            this.inputTipoCusto.current.style.display = 'block'
            formValid = false;
        } else {
            this.inputTipoCusto.current.style.display = 'none'
        }

        if (this.state?.tipo_custo_select?.value == 'F') {
            if (!this.state?.ocorrencia?.value) {
                this.inputOcorrencia.current.style.display = 'block'
                formValid = false;
            } else {
                this.inputOcorrencia.current.style.display = 'none'
            }
        } 
        else if (this.state?.tipo_custo_select?.value == 'V') {
            if (!this.state?.ocorrencia?.value) {
                this.inputOcorrencia.current.style.display = 'block'
                formValid = false;
            } else {
                this.inputOcorrencia.current.style.display = 'none'
            }

            if (!this.state?.total_ocorrencias) {
                this.inputTotalOcorrencia.current.style.display = 'block'
                formValid = false;
            } else {
                this.inputTotalOcorrencia.current.style.display = 'none'
            }
        } 
        else {
            this.inputOcorrencia.current.style.display = 'none'
            this.inputTotalOcorrencia.current.style.display = 'none'
        }

        return formValid;
    }

    handleSendSaveConta = (ev) => {
        // var url = ENDPOINT + "/financeiro/contas_pagar/salvar";
        var url = "http://localhost:8000/api/financeiro/contas_pagar/salvar";

        ev.preventDefault();
        this.setState({loading_btn_save_form: true})

        if (!this.handleValidForm()) {
            this.setState({loading_btn_save_form: false})
            this.handletoastMessage('Campos obrigatórios não preenchidos!')
            return;
        }

        let formData = new FormData();

        formData.append('financeiro_fornecedores_id', this.state.fornecedor);
        formData.append('descricao', this.state.descricao);
        formData.append('vencimento', this.state.vencimento);
        formData.append('valor', this.state.valor);
        formData.append('data_emissao', this.state.data_emissao);
        formData.append('financeiro_formas_pagamento_id', this.state?.select_forma?.value);
        formData.append('financeiro_sub_categorias_receitas_despesas_id', this.state?.categoria);
        formData.append('tipo_custo', this.state?.tipo_custo_select?.value);
        formData.append('ocorrencia', this.state?.ocorrencia?.value);
        formData.append('total_ocorrencia', this.state?.total_ocorrencias);
        formData.append('status_pagamento', this.state?.status_pagamento);

        if(this.state.file.anexo_conta.length > 0){
            this.state.file.anexo_conta.forEach((anexo, ps) => {
                formData.append('anexo_positions['+ps+']', ps);
                formData.append('anexo_conta[]', anexo);
            });
        }


        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }})
        .then((response) => {
            if (response.data?.success) {
                this.setState({loading_btn_save_form: false})
                this.handletoastSuccessMessage('Conta salva com sucesso!')
                setTimeout(function(){
                    window.location = '/contas-a-pagar';
                }, 1000)
            } else {
                this.setState({loading_btn_save_form: false})
                this.handletoastMessage('Erro ao salvar!')
            }

        })
        .catch((error) => {
            this.setState({loading_btn_save_form: false})
            this.handletoastMessage('Houve uma falha a salvar!')

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                this.handletoastMessage(JSON.stringify(error.response.data.error))
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            //console.log(error);
        })
    }

    handletoastSuccessMessage = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    handletoastMessage = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    render(){
        return(
            <Div>
                <h3>
                    <Button 
                        outline 
                        style={{border: 'none'}}
                        onClick={() => window.location.href = '/contas-a-pagar'}
                    >
                        <MdArrowBack style={{padding: 0}}/>
                    </Button>&nbsp;&nbsp;Conta a pagar
                </h3>
                <hr />
                <Row hidden={!this.state.loading_page}>
                    <Col md={12}>
                        <center>
                            <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                        </center>
                        <br/>
                    </Col>
                </Row>

            <Div hidden={!this.state.show_form}>
                <Row>
                    <Col md={12}>
                        <Button 
                            className="btn btn-success btn-save" 
                            // onClick={}
                            >
                            {this.state.loading_btn_save_form === true ?
                                <>
                                    <ClipLoader color="#fff" size={12}/>
                                    &nbsp;Salvando...
                                </>
                            : 
                                'Salvar conta'
                            }
                        </Button>
                    </Col>
                </Row>
                <br/> 
                <Row hidden={!this.state.loading_seller_data}>
                    <Col md={12}>
                        <center>
                            <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                        </center>
                        <br/>
                    </Col>
                </Row> 

                <Row>
                    <Col md={12}>
                        <label htmlFor="motivo">Fornecedor</label>
                        <Select 
                            name="fornecedor"
                            placeholder="Selecione o Fornecedor"
                            options={this.state.optionsFornecedores}
                            onChange={(option_select)=> {
                                this.setState({
                                    fornecedor: option_select.value,
                                    fornecedor_select: option_select,
                                })
                            }}
                            value={this.state.fornecedor_select} />
                        <span 
                            ref={this.inputFornecedor} 
                            className="required-input"
                            >Campo obrigatório!</span>
                    </Col>
                </Row>
                
                <Row>
                    <Col md={12}>
                        <label htmlFor="descricao">Descrição</label>
                        <Input
                            name="descricao"
                            value={this.state.descricao}
                            type="text"
                            onChange={this.handleInputChange}
                            />
                        <span 
                            ref={this.inputDescricao} 
                            className="required-input"
                            >Campo obrigatório!</span>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <label htmlFor="vencimento">Vencimento</label>
                        <Input
                            name="vencimento"
                            value={this.state.vencimento}
                            type="date"
                            onChange={this.handleInputChange}
                            />
                        <span 
                            ref={this.inputVencimento} 
                            className="required-input"
                            >Campo obrigatório!</span>
                    </Col>
                    <Col md={6}>
                        <label htmlFor="valor">Valor</label>
                        <NumberFormat 
                            name={"valor"}
                            className="form-control"
                            displayType={'input'}
                            prefix={'R$ '}
                            decimalSeparator={','}
                            decimalScale={2}
                            thousandSeparator={'.'}
                            value={this.state.valor}
                            onChange={this.handleInputChange}
                            />
                        <span 
                            ref={this.inputValor} 
                            className="required-input"
                            >Campo obrigatório!</span>
                    </Col>
                </Row>
                
                <Row>
                    <Col md={6}>
                        <label htmlFor="data_emissao">Data da emissão</label>
                        <Input
                            name="data_emissao"
                            value={this.state.data_emissao}
                            type="date"
                            onChange={this.handleInputChange}
                            />
                        <span 
                            ref={this.inputDataEmissao} 
                            className="required-input"
                            >Campo obrigatório!</span>
                    </Col>
                    <Col md={6}>
                        <label htmlFor="forma_pagamento">Forma de pagamento</label>
                        <Select
                            name="forma_pagamento"
                            placeholder="Selecione"
                            options={this.state.options_forma_pagamento}
                            value={this.state.select_forma}
                            onChange={(select_forma) => {

                                this.setState({
                                    select_forma: select_forma
                                })                                
                            }}
                                />
                        <span 
                            ref={this.inputFormaPagamento} 
                            className="required-input"
                            >Campo obrigatório!</span>
                    </Col>
                </Row>                


                <Row>
                    <Col md={6}>
                        <label htmlFor="categoria">Categoria</label>
                        <Select
                            name="categoria"
                            placeholder="Selecione"
                            options={this.state.optionsCategorias}
                            value={this.state.categoria_select}
                            onChange={(select) => {
                                this.setState({
                                    categoria: select.value,
                                    categoria_select: select,
                                })
                            }}
                                />
                        <span 
                            ref={this.inputCategoria} 
                            className="required-input"
                            >Campo obrigatório!</span>
                    </Col>
                    <Col md={6}>
                        <label htmlFor="status_pagamento">Status Pagamento</label>
                        <Select
                            name="status_pagamento"
                            placeholder="Selecione"
                            options={
                                [
                                    {label: 'Pendente', value: 'pendente'},
                                    {label: 'Paga', value: 'paga'},
                                    {label: 'Cancelada', value: 'cancelada'},
                                    {label: 'Estornada', value: 'estornada'},
                                ]
                            }
                            value={this.state.status_pagamento_select}
                            onChange={(select) => {
                                this.setState({
                                    status_pagamento: select.value,
                                    status_pagamento_select: select,
                                })
                            }}      
                                />
                        <span 
                            ref={this.inputStatusPagamento} 
                            className="required-input"
                            >Campo obrigatório!</span>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <label htmlFor="tipo_custo">Tipo de custo</label>
                        <Select
                            name="tipo_custo"
                            placeholder="Selecione"
                            options={
                                [
                                    {'label': 'Fixo', 'value': 'F'},
                                    {'label': 'Variável', 'value': 'V'},
                                    {'label': 'Único', 'value': 'U'},
                                ]
                            }
                            value={this.state.tipo_custo_select}
                            onChange={(select) => {
                                this.setState({
                                    tipo_custo_select: select,
                                })
                            }}      
                                />
                        <span 
                            ref={this.inputTipoCusto} 
                            className="required-input"
                            >Campo obrigatório!</span>
                    </Col>
                    <Col md={6} style={this.state?.tipo_custo_select?.value == 'U' ? {display: 'none'} : {display:'block'}}>
                        <label htmlFor="ocorrencia">Ocorrência</label>
                        <Select
                            name="ocorrencia"
                            placeholder="Selecione"
                            options={this.state.options_ocorrencias}
                            value={this.state.ocorrencia_select}
                            onChange={(select)=>{
                                this.setState({
                                    ocorrencia: select.value,
                                    ocorrencia_select: select,
                                })
                            }}
                            />
                        <span 
                            ref={this.inputOcorrencia} 
                            className="required-input"
                            >Campo obrigatório!</span>
                    </Col>
                </Row>   
                <Row>
                    <Col md={6} style={this.state?.tipo_custo_select?.value == 'F' || this.state?.tipo_custo_select?.value == 'U' ? {display: 'none'} : {display:'block'}}>
                        <label htmlFor="emissao">Total de ocorrências</label>
                        <Input
                            name="total_ocorrencias"
                            value={this.state.total_ocorrencias}
                            type="tel"
                            onChange={this.handleInputChange}
                            />
                        <span 
                            ref={this.inputTotalOcorrencia} 
                            className="required-input"
                            >Campo obrigatório!</span>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <label htmlFor="anexo_conta">Anexo</label>
                        <Dropzone onDrop={(drop) => this.onDropAnexo(drop)}>
                            {({ getRootProps, getInputProps }) => (
                            <DropArea {...getRootProps({
                                onClick: event => event.stopPropagation()
                            })}>
                                <input {...getInputProps()} />
                                    {this.state.file.anexo_conta.length > 0 ? 
                                        <>
                                            <Row> 
                                                {this.state.file.anexo_conta.map((e, index) => {
                                                    return <Col key={index} md={4} style={{paddingBottom: 4}}>
                                                        <Card>
                                                            <p style={{margin: '3px'}}>
                                                                <MdAttachFile style={{width: '32px'}}/>
                                                                {e.name}&nbsp; 
                                                                <Button 
                                                                    style={{padding: 0, border: 3, float: 'right'}} 
                                                                    color="danger" 
                                                                    outline 
                                                                    onClick={
                                                                        () => this.removeAnexoAttach(index)
                                                                    }>
                                                                        <MdClear style={{padding: 0}}/>
                                                                </Button>
                                                            </p>
                                                        </Card>
                                                    </Col>
                                                })}
                                                <br/>
                                            </Row>
                                            <p style={{ margin: 0 }}>
                                            <MdAttachFile style={{ width: '32px' }} />
                                                Arraste o arquivo ou&nbsp;
                                                <ButtonUpload 
                                                    onClick={() => this.inputFileAnexo.current.click()} 
                                                    className="btn btn-dark" 
                                                    color="primary">
                                                    Clique Aqui
                                                </ButtonUpload> para adicionar mais anexos
                                                <input type="file" ref={this.inputFileAnexo} hidden onChange={this.handleFileAnexoChange} />
                                        </p>
                                    </>
                                    :
                                    <>
                                        <p style={{ margin: 0 }}>
                                            <MdAttachFile style={{ width: '32px' }} />
                                                Arraste o arquivo ou&nbsp;
                                                <ButtonUpload 
                                                    onClick={() => this.inputFileAnexo.current.click()} 
                                                    className="btn btn-dark" 
                                                    color="primary">
                                                    Clique Aqui
                                                </ButtonUpload> para adicionar anexos
                                                <input type="file" ref={this.inputFileAnexo} hidden onChange={this.handleFileAnexoChange} />
                                        </p>
                                    </>
                                }
                            </DropArea>
                        )}
                        </Dropzone>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                        <h5>Histórico</h5><hr/>
                        <ul style={{listStyleType: 'circle'}}>
                            {this.state.notas.map(p => {
                                return <li key={p.id} style={{marginBottom: '7px'}}>
                                    <b>Operador:</b> {p.operador !== null ? p.operador : 'Sem Informações'} <br/>
                                    <div style={{textTransform: 'uppercase', border: '1px solid #7f7f7f66', borderRadius: 5, padding: '15px'}}>
                                        {p.nota.split('\n').map((x, i, {length}) => (
                                            <p style={{margin: (i + 1 === length ? 0 : 3), fontSize: 14}}>{x}</p>
                                        ))}
                                    </div>
                                    <small>
                                        {moment(p.data).format('DD/MM/YYYY HH:mm')}&nbsp;
                                        {/* <ButtonRed 
                                            hidden={!this.hiddenDelete(p.admin_id)}
                                            disabled={this.state.loading_nota_id === p.id}
                                            onClick={() => this.deletarNota(p.id)}>
                                                Apagar        
                                        </ButtonRed> */}
                                    </small>
                                </li>
                            })}
                        </ul>
                        {this.state.notas.length === 0 ? <p>Nenhuma nota</p> : ''}
                        <Button 
                            color="success" 
                            onClick={() => this.setState({is_open_nota: !this.state.is_open_nota})}>
                                <FaPen /> Adicionar Nota
                        </Button>
                        <Collapse isOpen={this.state.is_open_nota}>
                            <br/>
                            <Card>
                                <CardBody>
                                    <Input
                                        name="nota_mensagem"
                                        placeholder="Descrição"
                                        value={this.state.nota_mensagem}
                                        type="textarea"
                                        style={{
                                            height: '124px',
                                            resize: 'none'
                                        }}
                                        onChange={this.handleInputChange}
                                    />
                                    {this.state.notas.length > 0 ? 
                                        <Button 
                                            className="btn btn-success btn-nota"
                                            onClick={this.handleAddNota}>
                                            {this.state.loading_btn_add_nota === true ?
                                                <>
                                                    <ClipLoader color="#fff" size={12}/>
                                                    &nbsp;Adicionando...
                                                </>
                                            : 
                                                'Adicionar.'

                                            }
                                        </Button>
                                    : 
                                    ''
                                    }

                                </CardBody>
                            </Card>
                        </Collapse>

                    </div>
                    
                </Col>
            </Row>

                <br/>
                <br/>
                <Row>
                    <Col md={12}>
                        <Button 
                            className="btn btn-success btn-save" 
                            onClick={this.handleSendSaveConta}
                            >
                            {this.state.loading_btn_save_form === true ?
                                <>
                                    <ClipLoader color="#fff" size={12}/>
                                    &nbsp;Salvando...
                                </>
                            : 
                                'Salvar conta'
                            }
                        </Button>
                    </Col>
                </Row>
            </Div>
            <br/>
            <br/>
            </Div>

        )
    }
}

export default ResumoContasPagar;