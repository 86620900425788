import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import Moment from 'moment';

export const columns = () => [
    {
      name: "Data de criação",
      selector: "created_at",
      sortable: false,
      width: '15%',
      cell: p => Moment(p.created_at).format('DD/MM/YYYY HH:mm')
    },
    {
      name: "CPF/CNPJ de origem",
      selector: "cpf_cnpj_origem",
      sortable: false,
      width: '15%'
    },
    {
      name: "CPF/CNPJ transferido",
      selector: "cpf_cnpj_transferido",
      sortable: false,
      width: '15%'
    },
    {
      name: "Modelo da Maquininha",
      selector: "modelo_maquininha",
      sortable: false,
      width: '150px'
    },
    {
      name: "Serial da Maquininha",
      selector: "serial_maquininha",
      sortable: false,
    },
    {
      name: "Motivo da Transferência",
      selector: "motivo_transferencia",
      sortable: false,
    }
];

export const getRows = (state, callbackRows) => {
    var url = ENDPOINT + "titularidade/list";

    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&cpf_cnpj="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&cpf_cnpj="+document;
        }
    }

    if(state.status !== null){
      url += '&step='+state.status.value;
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
    
    var url = ENDPOINT + "titularidade/list?search="+search+"&page="+page;

    var document = state.document.replace(/([^\d])+/gim, '');
    if(state.is_cnpj === false){
      if(document.length === 11){
        url += "&cpf_cnpj="+document;
      }
    }
    else{
        if(document.length === 14){
          url += "&cpf_cnpj="+document;
        }
    }

    if(state.status !== null){
      url += '&step='+state.status.value;
    }

    if(state.service_select !== null){
      url += '&tipo_servico='+state.service_select.value;
    }

    if(filter === true){
        url += '&date_from='+state.date_from+'&date_to='+state.date_to
    }

    axios.get(url, {
      headers: {
          Authorization: 'Bearer '+getToken()
      }}).then((response) => {
        callbackRows(response.data.data, response.data.current_page, response.data.last_page)
    });
}