import React from "react";
import ReactTooltip from "react-tooltip";

export function TotalPassIcon({ onClick, active = false }) {
  return (
    <div style={{ position: "relative" }}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="225.000000pt"
        height="225.000000pt"
        viewBox="0 0 225.000000 225.000000"
        preserveAspectRatio="xMidYMid meet"
        style={{
          cursor: active ? "pointer" : "not-allowed",
          transition: "opacity 0.3s",
        }}
        onClick={active ? onClick : () => {}}
        data-tip={active ? "Contrato Totalpass" : ""}
      >
        <style>
          {`
            svg:hover {
              opacity: 0.5;
            }
            `}
        </style>
        <g
          transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
          fill="#25E186"
          stroke="none"
        >
          <path
            d="M0 1125 l0 -1125 1125 0 1125 0 0 1125 0 1125 -1125 0 -1125 0 0
  -1125z m1299 458 c-16 -65 -29 -123 -29 -129 0 -9 72 -13 278 -17 299 -4 321
  -7 399 -60 71 -48 106 -121 105 -224 -1 -127 -45 -227 -136 -311 -94 -87 -211
  -121 -407 -122 l-97 0 -32 -137 c-17 -76 -36 -155 -41 -175 l-10 -38 -164 0
  c-91 0 -165 2 -165 3 0 2 56 239 125 527 69 287 125 524 125 526 0 2 -67 4
  -148 4 l-148 0 -95 -392 -95 -393 -162 -3 c-110 -2 -162 1 -162 8 0 6 40 181
  90 389 49 208 90 381 90 384 0 4 -70 7 -155 7 -88 0 -155 4 -155 9 0 9 46 208
  56 244 5 16 37 17 483 17 l478 0 -28 -117z"
          />
        </g>
      </svg>
      <ReactTooltip />
    </div>
  );
}
