import Moment from 'moment';
import { Col, Row } from 'reactstrap';
import GoogleMapReact from 'google-map-react';
import { FaEye, FaMapMarkerAlt, FaSearch } from "react-icons/fa";

const AnyReactComponent = ({ text }) => <div style={{width: '126px', textAlign: 'center', fontWeight: '800'}}><FaMapMarkerAlt style={{color: 'red'}}/> <br/>{text}</div>;

const changeType = (type) => {
    switch(type) {
        case 'phone':
            return 'telefone'
            break;
        case 'email':
            return 'e-mail';
            break;
        case 'address':
            return 'endereço';
            break;
        default:
            return type;
            break;
    }
}

export const columns = (openMaps) => [
    {
        name: "Resultados",
        selector: "buttons",
        sortable: false,
        width: '100%',
        cell: row => <div style={{paddingTop: '0.5rem',paddingBottom: '0.5rem', width: '100%'}}>
            <Row>
                <Col md={12} style={{fontSize: '16px'}}>
                    <h3>{Moment(new Date(row.created_at)).format('DD/MM/YYYY HH:mm')}</h3>
                    <p>Alteração de {changeType(row.type)} do cliente</p>
                    <div dangerouslySetInnerHTML={{ __html: row.description }} style={{lineHeight: '1.5'}} />
                </Col>

            </Row>
        </div>
      },
  ];

