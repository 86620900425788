
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { Card, CardBody, Row, Col, Input, CardFooter, Collapse, Button, Label } from 'reactstrap';
import { columns } from './propsReceitas';
import debounce from 'lodash/debounce';
import Pagination from '../../Pagination';
import { InputText } from '../../../InputText/InputText';
import InputMask from 'react-input-mask';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import Select from 'react-select';
import { ENDPOINT } from '../../../../auth/endpoint';
import { getToken } from '../../../../auth/set_services';

export const TableReceitas = (props) => {
    return (
        <Card>
            <CardBody>
                <Row >
                    <Col md={6}>
                        <div style={{ textAlign: 'left' }}>
                            <InputText
                                name="search"
                                placeholder="Descrição"
                                onChange={debounce((event) => props.isSearch(event), 1000)} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <Select
                            placeholder="Tipo"
                            options={props.options.tipo_options}
                            isClearable
                            value={props.state.tipo_options}
                            onChange={(value) => props.onChangeSelect('tipo_options', value)}
                        />
                    </Col>
                </Row>
            </CardBody>
            <hr style={{ marginBottom: '4px' }} />
            <DataTable
                noHeader={true}
                button={true}
                noDataComponent={props.noDataComponent}
                columns={columns(props.editCategoria, props.removeCategoria)}
                data={props.data}
                theme="default"
            />
            <CardFooter style={{ textAlign: 'center' }}>
                <Pagination
                    onPageChange={props.onPageChange}
                    activePage={props.activePage}
                    totalPage={props.totalPage}
                />
            </CardFooter>
        </Card>
    )
}