import styled from "styled-components";

export const ButtonOnboarding = styled.button`
  height: 50px;
  padding: 5px;
  border-radius: 12px;
  color: #fff;
  background-color: #28a745;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.5;
  }
`;