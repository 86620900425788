import React from "react";
import DataTable from "react-data-table-component";
import { Card, CardBody, CardFooter, Col, Row, Button } from "reactstrap";
import Pagination from "../Pagination";
import { FaSearch } from "react-icons/fa";
import { InputText } from "../../InputText/InputText";
import Select from "react-select";

const options_status = [
  { value: "", label: "Todos" },
  { value: "approved", label: "Aprovado" },
  { value: "in_analysis", label: "Em Analise" },
  { value: "processing", label: "Processando" },
  { value: "refused", label: "Rejeitado" },
];

export const TableCustomized = (props) => {
  return (
    <>
      <Card>
        <CardBody>
          {!props.isLIstCancelRequest && (
            <Row>
              <Col md={3}>
                <InputText
                  name="search"
                  placeholder={"Busca por Documento"}
                  valid={props.search?.valid}
                  invalid={props.search?.invalid}
                  value={props.search?.document}
                  onChange={props?.searchOnChange}
                />

                {props.search?.invalid && (
                  <div
                    style={{
                      color: "#DC3545",
                      fontSize: "0.75rem",
                      marginTop: "2px",
                    }}
                  >
                    {props.search.message}
                  </div>
                )}
              </Col>
              <Col md={2}>
                <Button color="info" onClick={props?.onSearchDocument}>
                  <FaSearch style={{ fontSize: "1em" }} />
                </Button>
              </Col>
              <Col md={4}>
                <Select
                  className="react-select"
                  options={options_status}
                  placeholder="Status do cliente"
                  isSearchable={false}
                  isClearable
                  value={props.status}
                  onChange={(status) => props.setStatus(status)}
                />
              </Col>
            </Row>
          )}
          <hr style={{ marginBottom: "4px" }} />
          <DataTable
            noHeader={true}
            columns={props.columns()}
            button={true}
            responsive
            noDataComponent={props.noDataComponent}
            data={props.data}
            theme="default"
          />
        </CardBody>
        <CardFooter style={{ textAlign: "center" }}>
          <Pagination
            onPageChange={props.onPageChange}
            activePage={props.activePage}
            totalPage={props.totalPage}
          />
        </CardFooter>
      </Card>
    </>
  );
};
