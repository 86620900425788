import axios from 'axios';
import { ENDPOINT } from '../../../auth/endpoint';
import { getToken } from '../../../auth/set_services';
import { MdSms } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';
import Moment from 'moment';


const getStatus = (status) => {
    if(status === 'pendente'){
        return "Pendente";
    }
    else if(status === 'PENDENTE'){
        return "Pendente";
    }
    else if(status === 'DELIVERED'){
        return "Entregue";
    }
    else{
        return status;
    }
}

const getResposta = (resposta) => {
  if(resposta === 'Message delivered to handset'){
      return "entregue ao destinatário";
  }
  else{
      return resposta;
  }
}


export const columns = () => [
  {
    name: "Número",
    selector: "numero_destino",
    sortable: false,
    width: '20%'
  },
  {
    name: "Mensagem",
    selector: "mensagem",
    sortable: false,
    width: '35%',
    cell: row => <p>{row.mensagem}</p>
  },
  {
    name: 'Status de Envio',
    selector: 'status_envio',
    sortable: false,
    cell: row => <>{getStatus(row.status_envio)}</>,
    width: '15%'
  },
  {
    name: "Status",
    selector: "opened",
    sortable: false,
    width: '30%',
    cell: row => row.respostas.length > 0 ?
      <div data-tip={getStringMaps(row.respostas)} style={{ maxHeight: '200px', overflow: 'auto', cursor: 'pointer', width: '100%', margin: '12px' }}>
        <ul style={{ listStyleType: 'none' }}>
          {row.respostas.slice(0, 1).map((p) => {
            return <li>
              <p><MdSms /> <b>{getStatus(p.tipo_resposta)}</b>
                <br />
                {p.resposta ? <><b>Mensagem:</b> {getResposta(p.resposta)}<br /></> : ''}
                {Moment(new Date(p.dataResposta).toString()).format('DD/MM/YYYY h:mm:ss a')}</p>
            </li>
          })}
        </ul>
        <ReactTooltip multiline={true} />
      </div>
      :
      <div data-tip={"Postado " + Moment(new Date(row.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')} 
            style={{ 
              maxHeight: '200px', 
              cursor: 'pointer', 
              overflow: 'auto', 
              width: '100%', 
              margin: '12px' 
            }}>
        <ul>
          <li style={{ listStyleType: 'none' }}><MdSms /> <b>Postado</b><br />{Moment(new Date(row.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')}
          </li>
        </ul>
        <ReactTooltip multiline={true} />
      </div>
  }
];
const getStringMaps = (respostas) => {
  var string_respostas = ""
  respostas.forEach((p) => {
    string_respostas += p.tipo_resposta + " - " + (p.resposta ? 'Mensagem: ' + p.resposta : '') + " " + Moment(new Date(p.dataResposta).toString()).format('DD/MM/YYYY h:mm:ss a') + "<br /> "
  })
  return string_respostas;
}

export const getRows = (state, callbackRows) => {
  
  var url = ENDPOINT + "sms/list";

  if (state.phone.replace(/([^\d])+/gim, '').length > 0) {
    url += '?phone=+55' + state.phone
  }

  if (state.cpf_cnpj.length > 0) {
    url += '&cpf_cnpj=' + state.cpf_cnpj
  }
  
  axios.get(url, {
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  }).then((response) => {
    callbackRows(response.data.data, response.data.current_page, response.data.last_page)
  });
}

export const getRowsPerPage = (page, search, filter, state, callbackRows) => {
  
  var url = ENDPOINT + "sms/list?search=" + search + "&page=" + page;

  if (filter === true) {
    url += '&date_from=' + state.date_from + '&date_to=' + state.date_to
  }

  if (state.phone.replace(/([^\d])+/gim, '').length > 0) {
    url += '&phone=+55' + state.phone
  }

  if (state.cpf_cnpj.length > 0) {
    url += '&cpf_cnpj=' + state.cpf_cnpj
  }

  axios.get(url, {
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  }).then((response) => {
    callbackRows(response.data.data, response.data.current_page, response.data.last_page)
  });
}