
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { Card, CardBody, Row, Col, Input, CardFooter, Collapse, Button, Label } from 'reactstrap';
import { columns } from './propsRegimes';
import debounce from 'lodash/debounce';
import Pagination from '../../Pagination';
import { InputText } from '../../../InputText/InputText';
import InputMask from 'react-input-mask';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import Select from 'react-select';
import { ENDPOINT } from '../../../../auth/endpoint';
import { getToken } from '../../../../auth/set_services';

const getOptionSelects = (setStatusProcessoOptions, setStatusPagamentoOptions, setReclamadoOptions) => {

}

export const TableRegimes = (props) => {
    const [status_processo_options, setStatusProcessoOptions] = useState([]);
    const [status_pagamento_options, setStatusPagamentoOptions] = useState([]);
    const [reclamado_options, setReclamadoOptions] = useState([]);

    useEffect(() => {
        getOptionSelects(setStatusProcessoOptions, setStatusPagamentoOptions, setReclamadoOptions);
    }, []);

    return (
        <Card>
            <CardBody>
                {/* <Row >
                    <Col md={8}>
                        <div style={{ textAlign: 'left' }}>
                            <InputText
                                name="search"
                                placeholder="CPF / CNPJ "
                                onChange={debounce((event) => props.isSearch(event), 1000)} />
                        </div>
                    </Col>
                    <Col md={4} style={{padding: 0, textAlign: 'right'}}>
                        <Button
                            color="warning"
                            onClick={props.filterShow}
                            style={{ marginRight: '25px', marginBottom: '4px' }}>
                            Filtrar&nbsp;
                            <AiFillCaretDown hidden={props.show_filter} />
                            <AiFillCaretUp hidden={!props.show_filter} />
                        </Button>
                    </Col>
                </Row> */}
                <Collapse isOpen={props.show_filter}>
                    <br/>
                    <Row>
                        <Col md={4}>
                            <Select
                                placeholder="Status do Processo"
                                options={status_processo_options}
                                isClearable
                                value={props.state.status_processo}
                                onChange={(value) => props.onChangeSelect('status_processo', value)}
                            />
                        </Col>
                        <Col md={4}>
                            <Select
                                placeholder="Status do Pagamento"
                                options={status_pagamento_options}
                                isClearable
                                value={props.state.status_pagamento}
                                onChange={(value) => props.onChangeSelect('status_pagamento', value)}
                            />
                        </Col>
                        <Col md={4}>
                            <Select
                                placeholder="Reclamado"
                                options={reclamado_options}
                                isClearable
                                value={props.state.reclamado}
                                onChange={(value) => props.onChangeSelect('reclamado', value)}
                            />
                        </Col>
                    </Row>
                    <br/>
                </Collapse>
            </CardBody>
            <hr style={{ marginBottom: '4px' }} />
            <DataTable
                noHeader={true}
                button={true}
                noDataComponent={props.noDataComponent}
                columns={columns(props.editRegime)}
                data={props.data}
                theme="default"
            />
            <CardFooter style={{ textAlign: 'center' }}>
                <Pagination
                    onPageChange={props.onPageChange}
                    activePage={props.activePage}
                    totalPage={props.totalPage}
                />
            </CardFooter>
        </Card>
    )
}