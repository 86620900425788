import React, { Component } from "react";
import { getToken } from "../../auth/set_services";
import { Button, Input } from "reactstrap";
import { ENDPOINT } from "../../auth/endpoint";
import { toast } from "react-toastify";
import { MdWarning } from "react-icons/md";
import { InputMasked } from "../../components/InputText/InputMasked";
import { InputText } from "../../components/InputText/InputText";
import { getStatusTaggy } from "../../components/Tables/TableAQPasso/propsAQPasso";
import moment from 'moment';
import TrocaVeiculoModal from "../../components/Modals/TrocaVeiculo/TrocaVeiculoModal";

const getTaggy = (id, callback) => {
    var url = ENDPOINT + "taggy/info/" + id;

    var myHeaders = new Headers();

    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((data) => {
        callback(data);
    })
}


const gerarRemessa = (id, formData, callback) => {
    var url = ENDPOINT + "taggy/remessa/" + id;

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        mode: 'cors',
        cache: 'default'
    };

    fetch(url, myInit)
    .then(response => response.json())
    .then((data) => {
        callback(data);
    })
}
const ativarTaggy = (id, callback) => {
    var url = ENDPOINT + "taggy/ativar/" + id;

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        //body: JSON.stringify([]),
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((data) => {
        callback(data);
    })
}
const bloquearTag = (id, formData, callback) => {
    var url = ENDPOINT + "taggy/bloquear/" + id;

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((data) => {
        callback(data);
    })
}

const liberarTag = (id, formData, callback) => {
    var url = ENDPOINT + "taggy/liberar/" + id;

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((data) => {
        callback(data);
    })
}

const cancelarTag = (id, formData, callback) => {
    var url = ENDPOINT + "taggy/cancelar/" + id;

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + getToken());

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        mode: 'cors',
        cache: 'default'
    };
    fetch(url, myInit)
    .then(response => response.json())
    .then((data) => {
        callback(data);
    })
}
const get_id = () => {
    return window.location.pathname.replace('/aqpasso-detalhes/', '')
}
class ResumoAQPasso extends Component{
    constructor(props){
        super(props);
        this.state = {
            veiculo: '',
            ano_producao: '',
            ano_modelo: '',
            placa: '',
            serial: '',
            nome_completo: '',
            categoria_veiculo: '',
            documento: '',
            status: '',
            id_remessa: null,
            codigo_rastreio: '',
            final_tag: '',
            servico_transporte: '',
            pagamento_api_transacao_id: 'Sem Informações',
            pagamento_valor: 0,
            pagamento_created_at: '',
            data_adquirida_tag: '',
            pagamento_status: 'Sem Informações',
            pagamento_forma_pagamento: 'Sem Informações',
            paagmento_cupom: '',
            giftcard_number: '',
            open_veiculo: false,
            ja_pago: false
        }
    }

    componentDidMount(){
        getTaggy(get_id(), this.callbackTaggy)
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value.toUpperCase();

        this.setState({[name]: value});
    }
    callbackTaggy = (data) => {
        if(data.success == true){
            this.setState({
                veiculo: data.data.veiculo_fabricante + ' ' + data.data.veiculo_modelo,
                ano_producao: data.data.producao_ano,
                ano_modelo: data.data.modelo_ano,
                categoria_veiculo: data.data.categoria_nome,
                placa: data.data.veiculo_placa,
                serial: data.data.tag_serial,
                nome_completo: data.data.user.nome + ' ' + data.data.user.sobrenome,
                documento: (data.data.user.cnpj ? data.data.user.cnpj : data.data.user.cpf),
                id_remessa: data.data.id_remessa,
                status: data.data.status
            });

            if(data.data.user !== null){
                if(data.data.user.etapa_cad == 'cad_completo' || data.data.user.etapa_cad == 'cad_pagamento' ){
                    this.setState({
                        ja_pago: true
                    });
                }
            }
            if(data.data.pagamento !== null){
                this.setState({
                    pagamento_api_transacao_id: data.data.pagamento.api_transacao_id,
                    pagamento_valor: data.data.pagamento.valor,
                    pagamento_created_at: data.data.pagamento.created_at,
                    pagamento_status: data.data.pagamento.status,
                    pagamento_forma_pagamento: data.data.pagamento.forma_pagamento,
                    giftcard_number: data.data.pagamento.giftcard_number,
                    paagmento_cupom: data.data.pagamento.cupom !== null ? data.data.pagamento.cupom.hash : 'Nenhum cupom Inserido'
                });
            }
            else{
                this.setState({
                    data_adquirida_tag: data.data.created_at,
                });
            }
        }
    }

    cadAtivar = () => {
        ativarTaggy(get_id(), (data) => {
            if(data.success == true){
                toast.success(data.message)
                getTaggy(get_id(), this.callbackTaggy)
            }
            else{
                toast.success(data.errors)
            }
        })
    }

    cadLiberar = () => {
        var formData = new FormData();
        formData.append('statusId', 100);
        
        liberarTag(get_id(), formData, (data) => {
            if(data.success == true){
                toast.success(data.message)
                getTaggy(get_id(), this.callbackTaggy)
            }
            else{
                toast.success(data.errors)
            }
        })
    }

    cadBloquear = () => {
        var formData = new FormData();
        formData.append('statusId', 200);

        bloquearTag(get_id(), formData, (data) => {
            if(data.success == true){
                toast.success(data.message)
                getTaggy(get_id(), this.callbackTaggy)
            }
            else{
                toast.success(data.errors)
            }
        })
    }
    cadCancelar = () => {
        var formData = new FormData();
        formData.append('statusId', 300);

        cancelarTag(get_id(), formData, (data) => {
            if(data.success == true){
                toast.success(data.message)
                getTaggy(get_id(), this.callbackTaggy)
            }
            else{
                toast.success(data.errors)
            }
        })
    }
    render(){
        return(
            <div>
                <h3>Resumo AQPasso</h3>
                <br/>
                <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                    <p><b>Serial da Tag:</b> {this.state.serial == '0000000000000000' ? 'Não atribuido' : this.state.serial}</p>
                    <p><b>Veículo:</b> {this.state.veiculo}</p>
                    <p><b>Ano Produção:</b> {this.state.ano_producao}</p>
                    <p><b>Ano do Modelo:</b> {this.state.ano_modelo}</p>
                    <p><b>Categoria do Veículo:</b> {this.state.categoria_veiculo}</p>
                    <p><b>Placa:</b> {this.state.placa}</p>
                    <p><b>Cliente AQPasso:</b> {this.state.nome_completo}</p>
                    <p><b>Documento:</b> {this.state.documento}</p>
                    <p><b>Status:</b> {getStatusTaggy(this.state.status)}</p>
                    {this.state.ja_pago == true ? 
                        <>
                            {this.state.pagamento_created_at !== '' ?
                            <>
                                <p><b>ID da Transação: </b>{this.state.pagamento_api_transacao_id}</p>
                                <p><b>Valor: </b>R$ {parseFloat(this.state.pagamento_valor).toFixed(2)} - <b>{this.state.pagamento_status.toUpperCase()}</b></p>
                                <p><b>Data da Venda: </b> {moment(this.state.pagamento_created_at).format('DD/MM/YYYY HH:mm')}</p>
                                <p><b>Forma de Pagamento: </b>{this.state.pagamento_forma_pagamento}</p>
                                <p><b>Cupom: </b>{this.state.paagmento_cupom}</p>
                                <p><b>GiftCard: </b>{this.state.giftcard_number}</p>
                            </>
                            : 
                            <>
                                <p><b>SEM INFORMAÇÕES SOBRE PAGAMENTO</b></p>
                                <p><b>Data que tag foi adquirida: </b> {moment(this.state.data_adquirida_tag).format('DD/MM/YYYY HH:mm')}</p>
                            </>
                            }
                            {this.state.id_remessa !== null ?
                                <Button color="light" onClick={() => window.open('/resumo-postagem/'+this.state.id_remessa, '_blank')}>Abrir Remessa</Button>
                                :
                                <div>
                                    <Input 
                                        type="select" 
                                        style={{borderRadius: '0'}} 
                                        value={this.state.servico_transporte}
                                        onChange={e => this.setState({ servico_transporte: e.target.value })}>
                                        <option value="totalexpress">TotalExpress</option>
                                        <option value="correios">Correios</option>
                                    </Input>
                                    <br/>
                                    <InputText
                                        placeholder="Codigo de rastreio" 
                                        value={this.state.codigo_rastreio}
                                        name="codigo_rastreio"
                                        onChange={this.handleChange}
                                    />
                                    <br/>
                                    <InputMasked 
                                        placeholder="Numeros Finais da Tag (6 últimos digitos)" 
                                        value={this.state.final_tag}
                                        name="final_tag"
                                        mask="999999"
                                        onChange={this.handleChange}
                                    />
                                    <br/>
                                    <Button onClick={() => {
                                        var formData = new FormData;
                                        formData.append('codigo_rastreio', this.state.codigo_rastreio);
                                        formData.append('final_taggy', this.state.final_tag);
                                        formData.append('servico_transporte', this.state.servico_transporte);
                                        gerarRemessa(get_id(), formData, (data) => {
                                            if(data.success == true){
                                                toast.success(data.message)
                                                getTaggy(get_id(), this.callbackTaggy)
                                            }
                                            else{
                                                toast.success(data.errors)
                                            }
                                        })}
                                    }>Gerar Remessa</Button>
                                </div>
                            }
                        </>
                        :
                        <>
                            <p style={{color: '#dc3545'}}><b>Cliente não finalizou o cadastro na parte de Pagamento</b></p>
                        </>
                    }
                    {this.state.status == 'inativo' ?
                        <div>
                            <br/>
                            <Button 
                                color="dark"
                                onClick={() => { 
                                    this.cadAtivar() 
                                }}
                            >Ativar Tag</Button>
                        </div>
                        :
                        <div className="buttons-taggy">
                            {this.state.status.toLowerCase() == 'bloqueado' ? 
                                <>
                                    <br/>
                                    <Button 
                                        color="success"
                                        onClick={() => this.cadLiberar()}
                                    >
                                        Liberar
                                    </Button>
                                </>
                            :
                            this.state.status.toLowerCase() == 'liberado' || this.state.status.toLowerCase() == 'ativo' ?
                                <>
                                    <br/>
                                    <Button 
                                        color="warning"
                                        onClick={() => this.cadBloquear()}
                                    >
                                        Bloquear
                                    </Button>
                                </>
                            :
                            <></>
                            }
                            {this.state.status.toLowerCase() != 'cancelado' && this.state.serial !== '0000' ? 
                                <>
                                    <br/>
                                    <Button 
                                        color="danger"
                                        onClick={() => this.cadCancelar()}
                                        >
                                        <MdWarning /> Cancelar
                                    </Button>
                                </>
                            :
                            <></>
                            }
                        </div>
                    }
                    
                    <Button 
                        color="info"
                        style={{
                            margin: 2
                        }}
                        onClick={() => this.setState({ open_veiculo: true })}
                        >
                        Alterar Veículo
                    </Button>
                    <TrocaVeiculoModal
                        isOpen={this.state.open_veiculo}
                        serial={this.state.serial}
                        tag_id={get_id()}
                        onRequest={() => {
                            getTaggy(get_id(), this.callbackTaggy)
                        }}
                        onClose={() => this.setState({ open_veiculo: false })} />
                </div>
            </div>
        )
    }
}

export default ResumoAQPasso;