import { Button, Input, Modal, ModalBody, ModalFooter } from "reactstrap";
import ModelReturn from "../ReturnsApiModals/ModelReturn";

const ModalDialog = (props) => {
    return (
        <Modal isOpen={props.visible}>
            <ModalBody hidden={props.loading}>
                {props.message}
                {props.input_is == true ?
                    <div>
                        <hr/>
                        <Input
                            type="textarea"
                            placeholder="Motivo do cancelamento"
                            name="motivo_cancelamento"
                            onChange={(event) => props.onChange(event)}
                        />
                    </div>
                :null
                }
            </ModalBody>
            <ModalBody style={{
                        minHeight: '114px', 
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }}  hidden={!props.loading}>
                <ModelReturn 
                    error_message={props.error_message}
                    success_message={props.success_message}
                    loading={props.loading}
                    onClose={props.onClose}
                    onError={props.onError.bind(this)}
                />
            </ModalBody>
            <ModalFooter hidden={props.loading}>
                <Button color="danger" onClick={props.onClose}>Não</Button>
                <Button color="success" onClick={props.onSave}>Sim</Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalDialog;